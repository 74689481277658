import React from 'react';
import PropTypes from 'prop-types';
import getYear from 'date-fns/getYear';
import Select from '../../atoms/Select/Select';
import styled from '@emotion/styled';

const StyledSelect = styled(Select)`
  flex: 1;
  margin-right: 16px;
`;

class DatePicker extends React.Component {
  static getYearOptions = (offset = 18, maxValues = 80) => {
    const options = [];
    const now = new Date();
    const fromYear = getYear(now) - offset;
    for (let i = fromYear; i > fromYear - maxValues; i--) {
      options.push({ value: '' + i, label: '' + i });
    }

    return options;
  };

  static getMonthOptions = () => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({ value: i, label: ('0' + i).slice(-2) });
    }
    return options;
  };

  static getDayOptions = () => {
    const options = [];
    for (let i = 1; i <= 31; i++) {
      options.push({ value: i, label: ('0' + i).slice(-2) });
    }
    return options;
  };

  state = { values: { day: '', month: '', year: '' } };

  updateBirthday = () => {
    const match = /^(\d{4})-(\d{2})-(\d{2})/.exec(this.props.initialValue);
    if (match) {
      this.setState({
        values: {
          year: parseInt(match[1]),
          month: parseInt(match[2]),
          day: parseInt(match[3]),
        },
      });
    }
  };

  componentDidMount() {
    this.updateBirthday();
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.updateBirthday();
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      values: {
        [name]: value,
      },
    });
  }

  render() {
    return (
      <div css={{ flex: 1, justifyContent: 'space-between' }}>
        <StyledSelect
          value={this.state.values['day']}
          name={this.props.name + '.day'}
          options={DatePicker.getDayOptions()}
          onChange={(event) => this.handleChange(event)}
          disabled={this.props.disabled}
        />
        <StyledSelect
          value={this.state.values['month']}
          name={this.props.name + '.month'}
          options={DatePicker.getMonthOptions()}
          onChange={(event) => this.handleChange(event)}
          disabled={this.props.disabled}
        />
        <StyledSelect
          value={this.state.values['year']}
          name={this.props.name + '.year'}
          options={DatePicker.getYearOptions()}
          onChange={(event) => this.handleChange(event)}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  name: PropTypes.string,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DatePicker;
