import { makeStyles, createStyles } from '@material-ui/core';
import { BLUE, GRAY_2 } from '../../camtool-styles';

export const useTelegramUnpinAllStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      margin: '30px 30px 20px 0',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    containerDisabled: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      margin: '30px 30px 20px 0',
    },
    icon: {
      margin: '0 5px 0 0',
    },
    text: {
      color: BLUE,
    },
    textDisabled: {
      color: GRAY_2,
    },
  })
);
