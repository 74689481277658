import React, { FC } from 'react';
import { createStyles, FormControlLabel, FormControlLabelProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DARK_GRAY } from '../../../camtool-styles';
import { CustomColor } from './types';
import { getPaletteColor } from './utils';

interface MUICheckboxFormControlLabelProps extends FormControlLabelProps {
  color?: 'default' | 'primary' | 'secondary' | CustomColor;
  indeterminate?: boolean;
  coloredLabel?: boolean;
  size?: 'small' | 'medium' | 'large';
}

const useStyles = makeStyles<
  Theme,
  Pick<MUICheckboxFormControlLabelProps, 'size' | 'color' | 'indeterminate' | 'coloredLabel'>
>((theme: Theme) =>
  createStyles({
    label: ({ size, color, indeterminate, coloredLabel }) => {
      const paletteColor = getPaletteColor(theme, color);

      return {
        color: !indeterminate && coloredLabel ? paletteColor?.main : DARK_GRAY,
        fontSize: size === 'medium' ? '14px' : size === 'small' ? '12px' : '16px',
      };
    },
  })
);

const MUICheckboxFormControlLabel: FC<MUICheckboxFormControlLabelProps> = ({
  color,
  indeterminate = false,
  coloredLabel = false,
  size = 'medium',
  control,
  ...props
}) => {
  const classes = useStyles({
    color,
    size,
    indeterminate,
    coloredLabel,
  });

  const { checked, disabled } = props;
  const checkboxProps = { color, checked, disabled, indeterminate, coloredLabel, size };

  return (
    <FormControlLabel
      classes={classes}
      control={React.cloneElement(control, checkboxProps)}
      {...props}
    />
  );
};

export default MUICheckboxFormControlLabel;
