import React, {FC, useState, useEffect} from 'react';
import {_} from '../../../../../util/translate';
import backgroundTelegram from '../Assets/backgroundTelegram.jpg';
import AutoMailGridController from './AutoMailGridController';
import {WHITE_2, WHITE_3} from '../../../../../camtool-styles';
import {BadgesBanner} from '../../../../../atoms';
import {Typography} from '@material-ui/core';
import {DetailsListItem} from '../../../../../atoms/Banner';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TelegramIcon from '@material-ui/icons/Telegram';
import IconBase from '../../../../../atoms/Icon/IconBase';
import LockedPhone from '../../../../../atoms/Icon/LockedPhone';
import SharePhone from '../../../../../atoms/Icon/SharePhone';

;
import HelpcenterLink from "../../../../../components/Helpcenter/HelpcenterLink";

interface AutoMailMobileEntryProps {
    isVip: boolean;
}

const AutoMailMobileEntry: FC<AutoMailMobileEntryProps> = ({isVip = false}) => {
    const [displayVip, setDisplayVip] = useState(isVip);
    useEffect(() => setDisplayVip(isVip), [isVip]);

    const benefitList = [
        // first benefit
        {
            icon: <AccessAlarmIcon/>,
            text: (
                <DetailsListItem>{`1. ${_(
                    'mailings:telegram.autoMail.banner.leftBadgeText'
                )}`}</DetailsListItem>
            ),
        },
        // second benefit
        {
            icon: <TelegramIcon/>,
            text: (
                <>
                    <DetailsListItem>{`2. ${_(
                        'mailings:telegram.autoMail.banner.centerBadgeText'
                    )}`}</DetailsListItem>
                </>
            ),
        },
        // third benefit
        {
            icon: displayVip ? (
                <IconBase iconElement={<LockedPhone/>}/>
            ) : (
                <IconBase iconElement={<SharePhone/>}/>
            ),
            text: (
                <DetailsListItem>{`3. ${_(
                    `mailings:telegram.autoMail.banner.rightBadgeText.${displayVip ? 'VIP' : 'free'}`
                )}`}</DetailsListItem>
            ),
        },
    ];

    return (
        <article
            css={{
                flexDirection: 'column',
                padding: '0px 8px',
            }}
        >
            <BadgesBanner
                backgroundImage={backgroundTelegram}
                backgroundImagePosition={'bottom center'}
                summaryText={
                    <Typography color="inherit">
                        {_(`mailings:telegram.autoMail.banner.summary.${displayVip ? 'VIP' : 'free'}`)}
                    </Typography>
                }
                benefitList={benefitList}
                detailsText={
                    <Typography color="inherit" align="center">
                        {_(`mailings:telegram.autoMail.banner.subTitleText.${displayVip ? 'VIP' : 'free'}`)}
                        <a href={HelpcenterLink() + '/articles/360017625959'}
                           target={'_blank'}
                           css={{marginLeft: '2px', color: WHITE_2}}>
                            {_('mailings:telegram.autoMail.subTitleLink')}
                        </a>
                    </Typography>
                }
            />
            <div css={{backgroundColor: WHITE_3}}>
                <AutoMailGridController isVip={displayVip}/>
            </div>
        </article>
    );
};

export default AutoMailMobileEntry;
