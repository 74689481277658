import React from 'react';
import { Route } from 'react-router';

const ExternalRedirect = ({ to, ...routeProps }) => {
  return (
    <Route
      {...routeProps}
      render={() => {
        window.location.replace(to);
        return null;
      }}
    />
  );
};

export default ExternalRedirect;
