import React, { FC } from 'react';
import { Column } from 'material-table';
import { useHistory } from 'react-router';

import { MaterialTable } from '../../../../atoms';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';
import { useAsyncBroadcastListQuery } from '../../hooks/useAsyncBroadcastListQuery';
import { _ } from '../../../../util/translate';
import { GRAY_4, GRAY_2, BREAKPOINT_PHONE_CONDITION } from '../../../../camtool-styles';

import RenderColumnSalesCount from './RenderColumnSalesCount';
import RenderColumnFile from './RenderColumnFile';
import RenderColumnSent from './RenderColumnSent';
import RenderColumnPinned from './RenderColumnPinned';
import { calcSource, getSourceFriendlyName } from '../../util';
import RenderColumnRecipientGroups from './RenderColumnRecipientGroups';
import RenderColumnSentCount from './RenderColumnSentCount';
import { useMediaQuery } from '@material-ui/core';
import useTelegramMaster from '../../hooks/useTelegramMaster';

interface BroadcastListProps {
  buildUrl: (id: string | number) => string;
  unpinned: boolean;
}

enum ColumnName {
  id = 'id',
  text = 'text',
  file = 'file',
  price = 'price',
  isSticky = 'isSticky',
  recipientGroups = 'userType',
  paidContent = 'paidContent',
  salesCount = 'salesCount',
  usersCount = 'usersCount',
  sentCount = 'sentCount',
  timestamp = 'timestamp',
  referenceId = 'referenceId',
  canceled = 'canceled',
}

const tableRef = React.createRef();

const BroadcastList: FC<BroadcastListProps> = ({ buildUrl, unpinned }) => {
  const isMobile = useMediaQuery(BREAKPOINT_PHONE_CONDITION);

  const fetchData = useAsyncBroadcastListQuery();
  const { push } = useHistory();

  if (unpinned) {
    tableRef.current && tableRef.current.onQueryChange();
  }

  const { data } = useTelegramMaster();
  const currency = data?.master?.currency || '';

  const columnsConfig: Column<Broadcast>[] = [
    {
      field: ColumnName.id,
      title: 'ID',
      type: 'string',
      hidden: true,
      width: 100,
    },
    {
      title: _('telegram:BroadcastList.column.sentCount.label'),
      align: 'left',
      render: RenderColumnSent,
      //quickfix to shrink table row; material-table internally sets width
      width: '0px !important',
    },
    {
      field: ColumnName.sentCount,
      title: _('telegram:BroadcastList.column.usersCount.label'),
      align: 'center',
      type: 'numeric',
      render: RenderColumnSentCount,
      width: 100,
    },
    {
      field: ColumnName.recipientGroups,
      title: _('telegram:BroadcastList.column.recipientGroups.label'),
      type: 'string',
      render: RenderColumnRecipientGroups,
      width: 130,
    },
    {
      field: ColumnName.referenceId,
      title: _('telegram:BroadcastList.column.source.label'),
      width: 110,
      render: (broadcast): string => getSourceFriendlyName(calcSource(broadcast)),
    },
    {
      field: ColumnName.text,
      title: _('telegram:BroadcastList.column.text.label'),
      type: 'string',
      emptyValue: (
        <span style={{ color: GRAY_4, fontStyle: 'italic' }}>
          {_('telegram:BroadcastList.column.text.empty')}
        </span>
      ),
    },
    {
      field: ColumnName.isSticky,
      title: 'Pin',
      type: 'string',
      render: RenderColumnPinned,
    },
    {
      field: ColumnName.timestamp,
      title: _('telegram:BroadcastList.column.timestamp.label'),
      type: 'datetime',
      align: 'left',
      width: 150,
    },
    {
      field: ColumnName.price,
      title: _('telegram:BroadcastList.column.price.label'),
      type: 'currency',
      align: 'center',
      emptyValue: <span style={{ color: GRAY_2, fontStyle: 'italic' }}>-</span>,
      currencySetting: { currencyCode: `${currency}`, locale: 'de' },
      width: 100,
    },
    {
      field: ColumnName.salesCount,
      title: _('telegram:BroadcastList.column.salesCount.label'),
      align: 'center',
      type: 'numeric',
      render: RenderColumnSalesCount,
      width: 100,
    },
    {
      field: ColumnName.file,
      title: _('telegram:BroadcastList.column.file.label'),
      align: 'center',
      render: RenderColumnFile,
      width: 115,
    },
  ];

  return (
    <MaterialTable
      onRowClick={(event, { id }: Broadcast): void => push(buildUrl(id))}
      columns={columnsConfig}
      data={fetchData}
      tableRef={tableRef}
      options={{
        toolbar: false,
        sorting: false,
        rowStyle: { height: 62, wordBreak: 'normal' },
        maxBodyHeight: 'calc(100vh - 338px)',
        headerStyle: {
          flexDirection: isMobile ? 'column' : 'row',
          wordBreak: 'normal',
        },
      }}
      onChangePage={() => (tableRef.current.tableContainerDiv.current.scrollTop = 0)}
    />
  );
};

export default BroadcastList;
