import React, { FC, Fragment } from 'react';
import { EmptyContent, FormatNumber, T } from '../../../components';
import { useQuery } from '@apollo/react-hooks';
import { Query } from '../../../graphql/VXModels/types';
import { QUERY_MODEL_DASHBOARD_MESSENGER_STAT } from '../../../graphql/VXModels/queries';
import NewButton from '../../../atoms/Button/NewButton';
import { _ } from '../../../util/translate';
import Spinner from '../../../components/Spinner/Spinner';
import styled from '@emotion/styled';
import TelegramIcon from '@material-ui/icons/Telegram';
import TheatersIcon from '@material-ui/icons/Theaters';
import { GREEN } from '../../../camtool-styles';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Grid } from '@material-ui/core';
import IconAndTextComponent from '../SalesStatistics/IconAndTextComponent';
import { useAppState } from '../../../util/AppState';
import { NEW_MESSENGER_URL, MOMENTUM_URL } from '../../../config';
import { useUserData } from '../../../util/UserData';

interface MessengerStatProps {
  loading: boolean;
}

const MessengerStatSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
`;

const useStyles = makeStyles({
  moneyBill: {
    color: GREEN,
    fontSize: '36px',
  },
  amount: {
    fontSize: '2em',
    fontWeight: 'bold',
  },
  MessengerStatContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    float: 'left',
    paddingRight: '5px',
    paddingLeft: '5px',
  },
  contentContainer: {
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
  },
});

const MessengerStat: FC<MessengerStatProps> = ({ loading }) => {
  const [{ authToken }] = useAppState();
  const userData = useUserData();
  const isVerified = userData?.model?.account.isVerified;
  const classes = useStyles();

  const {
    loading: loadingQuery,
    data,
    error,
  } = useQuery<Query>(QUERY_MODEL_DASHBOARD_MESSENGER_STAT, {
    variables: {},
  });

  return (
    <Container className={classes.MessengerStatContainer}>
      {loading || loadingQuery ? (
        <Grid
          container
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
          style={{ minHeight: '308px' }}
        >
          <Grid item xs={12}>
            <MessengerStatSpinnerContainer>
              <Spinner inline={true} size={'m'} />
            </MessengerStatSpinnerContainer>
          </Grid>
        </Grid>
      ) : error ? (
        <EmptyContent title={_('common:error.generic')} />
      ) : data ? (
        <Fragment>
          <Grid
            container
            direction={'column'}
            justify={'space-between'}
            alignItems={'center'}
            style={{ minHeight: '308px' }}
          >
            <h3 css={{ fontSize: 18, textAlign: 'center', padding: '0 10px' }}>
              <T _={'dashboard:app.messengerStat.text'} />
            </h3>
            <div>
              <IconAndTextComponent
                mobileSize={12}
                tabletSize={12}
                icon={<TelegramIcon className={classes.moneyBill} />}
                rightBoxText={_('dashboard:app.messengerStat.messages')}
                formattedNumber={
                  <FormatNumber
                    className={classes.amount}
                    value={data.model?.dashboard?.messengerStatistic?.messages}
                  />
                }
              />
            </div>
            <div>
              <IconAndTextComponent
                mobileSize={12}
                tabletSize={12}
                icon={<TheatersIcon className={classes.moneyBill} />}
                rightBoxText={_('dashboard:app.messengerStat.contentsales')}
                formattedNumber={
                  <FormatNumber
                    className={classes.amount}
                    value={data.model?.dashboard?.messengerStatistic?.contentSales}
                  />
                }
              />
            </div>
            {isVerified && (
              <div>
                <div className={classes.buttonContainer}>
                  <NewButton
                    style={{
                      height: 'fit-content',
                      minHeight: '40px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                    }}
                    color={'primary'}
                    onClick={() =>
                      window.open(`${NEW_MESSENGER_URL}?foreignSID=${authToken}`, '_blank')
                    }
                  >
                    {_('dashboard:app.messengerStat.gotoVXLive')}
                  </NewButton>
                </div>
                <div className={classes.buttonContainer}>
                  <NewButton
                    style={{
                      height: 'fit-content',
                      minHeight: '40px',
                      paddingRight: '8px',
                      paddingLeft: '8px',
                    }}
                    color={'primary'}
                    onClick={() => window.open(`${MOMENTUM_URL}`, '_blank')}
                  >
                    {_('dashboard:app.messengerStat.gotoMomentum')}
                  </NewButton>
                </div>
              </div>
            )}
          </Grid>
        </Fragment>
      ) : (
        <EmptyContent icon="icon-pie-chart" title={_('common:forbidden.text')} />
      )}
    </Container>
  );
};

export default MessengerStat;
