import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { QueryResult } from '@apollo/react-common';

import { QUERY_MASTER_USER_BROADCASTLIST } from '../graphql/queries';
import { Broadcast, BroadcastFilter, Query } from '../../../graphql/VXServicesTelegram/types';
import { useVXServicesTelegramClient } from '../../../graphql/VXServicesTelegram';
import { useVXServicesTelegramContext } from './index';

interface Variables {
  filter: BroadcastFilter;
}

type Result = Omit<QueryResult<Query, Variables>, 'data'> & {
  broadcast: Broadcast | undefined;
};

export const useBroadcast = (id: number, options?: QueryHookOptions<Query, Variables>): Result => {
  const { token } = useVXServicesTelegramContext();
  const client = useVXServicesTelegramClient();
  const { data, ...result } = useQuery<Query, Variables & { token: string }>(
    QUERY_MASTER_USER_BROADCASTLIST,
    {
      client,
      ...options,
      variables: { token: token!, filter: { ids: [id] } },
      skip: !token,
    }
  );

  return { broadcast: data?.master?.broadcastList?.broadcasts[0], ...result };
};
