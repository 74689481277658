/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '../../Tooltip/Tooltip';
import cx from 'classnames';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION, BREAKPOINT_TABLET_CONDITION } from '../../../camtool-styles';

const MenuItem = styled(NavLink)`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -ms-flex: 1 1 30.4;
    -webkit-flex: 1 1 30.4;
    flex: 1 1 30.4%;
  }
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .tooltip__frame--right {
    left: calc(100% + -240px);
    &:after {
      right: -7px;
      left: auto;
    }
  }
`;

const ExplorerNavItem = ({ children, icon, iconFlash, tooltip, ...rest }) => {
  return (
    <MenuItem
      activeClassName={'mediamanagement__explorer__nav__item--selected'}
      className={cx({
        mediamanagement__explorer__nav__item: true,
        'mediamanagement__explorer__nav__item--newentry': iconFlash,
      })}
      {...rest}
    >
      {icon && <div className={icon + ' mediamanagement__explorer__nav__item__icon'} />}
      <div className="mediamanagement__explorer__nav__item__name">{children}</div>
      {tooltip && <Tooltip text={tooltip} />}
    </MenuItem>
  );
};

ExplorerNavItem.propTypes = {
  link: PropTypes.string,
  iconFlash: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
};

ExplorerNavItem.defaultProps = {
  iconFlash: false,
};

export default ExplorerNavItem;
export { ExplorerNavItem };
