import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

interface KeyObject {
  [key: string]: string;
}

const buildDataIdFromObject = (object: object, key?: string) => {
  const idObject = {
    ...object,
    ...(key ? { id: (object as KeyObject)[key] } : {}),
  };

  const dataId = defaultDataIdFromObject(idObject);
  return dataId ? '[VXModels]' + dataId : null;
};

export const dataIdFromObject = (object) => {
  switch (object.__typename) {
    case 'TermAcceptance':
      return buildDataIdFromObject(object, 'type');
    default:
      return buildDataIdFromObject(object);
  }
};
