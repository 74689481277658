import React, { FC } from 'react';
import styled from '@emotion/styled';
import GenericModalBoxLayout from '../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { DARK_GRAY } from '../../../../camtool-styles';
import ActionItem from './ActionItem';
import { bin, diskOpen, remove } from '../../../../atoms/Icon/libraries/glyph';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { _ } from '../../../../util/translate';

const Container = styled.div`
  flex-direction: column;
  padding: 55px 0 60px;
`;

const Header = styled.h2`
  font-size: 20px;
  letter-spacing: 0.2px;
  color: ${DARK_GRAY};
  margin-bottom: 22px;
  text-align: center;
`;

interface Props {
  onClose: () => void;
  onRemoveFile: () => void;
  onReuploadFile: () => void;
  onPreviewFile: () => void;
}

const ModalEditFile: FC<Props> = ({ onClose, onRemoveFile, onReuploadFile, onPreviewFile }) => {
  // Stop event propagation to file upload label
  const stopEventPropagation = (e: React.MouseEvent): void => e.stopPropagation();

  const onDeleteClick = (): void => {
    onRemoveFile();
    onClose();
  };

  const onReuploadFileClick = (): void => {
    onReuploadFile();
    onClose();
  };

  const onPreviewFileClick = (): void => {
    onPreviewFile();
    onClose();
  };

  return (
    <GenericModalBoxLayout name="edit-file" maxWidth={458} onClose={(): void => onClose()}>
      <Container onClick={stopEventPropagation}>
        <Header>{_('components:mediaUpload.modalEditFile.header')}</Header>

        <ActionItem
          icon={faExpand}
          label={_('components:mediaUpload.modalEditFile.action.view')}
          onClick={onPreviewFileClick}
        />
        <ActionItem icon={bin} label={_('common:button.delete')} onClick={onDeleteClick} />
        <ActionItem
          icon={diskOpen}
          label={_('components:mediaUpload.modalEditFile.action.uploadNew')}
          onClick={onReuploadFileClick}
        />
        <ActionItem
          icon={remove}
          label={_('common:button.cancel')}
          onClick={(): void => onClose()}
        />
      </Container>
    </GenericModalBoxLayout>
  );
};

export default ModalEditFile;
