import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { T } from '../../../components';
import React from 'react';
import { CLASSICVXLIVE, MESSENGER_URL } from '../../../config';
import { FC } from 'react';
import VisitXIcon from '../../../atoms/Icon/VisitxIcon';
import IconBase from '../../../atoms/Icon/IconBase';

const iconBase = css`
  border-radius: 50%;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #1f93ea;
  color: #f2f2f2;
  display: flex;
  font-size: 16px;
  &::before {
    font-size: 1em;
  }
  &::after {
    font-size: 0.4em;
  }
`;

const Row = styled.div`
  border-radius: 4px;
  flex-direction: column;
  background-color: white;
  margin: 4px 0;
  padding: 24px;
  justify-content: space-between;
`;

const RowContent = styled.div``;

const Cell = styled.div`
  align-self: center;
  justify-content: center;
  align-items: center;
`;

const BorderButton = styled.button`
  border: 1px solid #999;
  background-color: transparent;
  color: #999;
  padding: 6px 24px;
  border-radius: 4px;
  &:hover {
    background-color: #999;
    color: #fff;
  }
  cursor: pointer;
`;

export enum RowType {
  helpcenter = 'helpcenter',
  download = 'download',
}

interface Props { type: RowType, title: string; link: string; description: string; } // prettier-ignore

const TableRow: FC<Props> = ({ type, title, link, description }) => {
  const handleClick = (): void => {
    if (link) {
      window.location.href = link;
    }
  };

  const icon =
    type === RowType.download ? (
      <span className="icon-disk-save" css={[iconBase]} />
    ) : (
      <IconBase fontSize="32px" iconElement={<VisitXIcon />} />
    );

  const buttonText =
    type === RowType.download
      ? link === MESSENGER_URL || link === CLASSICVXLIVE
        ? 'getOnline:text.start'
        : 'getOnline:text.download'
      : 'getOnline:text.helpcenter';

  return (
    <Row>
      <RowContent>
        <Cell css={{ flex: '0 0 auto' }}>{icon}</Cell>

        <Cell
          css={{ flex: 1, flexDirection: 'column', alignItems: 'flex-start', marginLeft: '24px' }}
        >
          <div css={{ fontSize: '1.3em', fontWeight: 'bold' }}>{title}</div>
          <div css={{ fontSize: '.9em', color: '#888888' }}>{description}</div>
        </Cell>

        <Cell css={{ flex: '0 0 auto', marginLeft: 24 }}>
          <BorderButton onClick={handleClick}>
            <T _={buttonText} />
          </BorderButton>
        </Cell>
      </RowContent>
    </Row>
  );
};

export default TableRow;
