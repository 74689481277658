import React, { FC } from 'react';
import styled from '@emotion/styled';

import { ProgressBar, T } from '../../components';
import { BLACK_1 } from '../../camtool-styles';
import ItemContainer from './ItemContainer';
import Ampel, { Level } from '../../atoms/Charts/Ampel';
import { _ } from '../../util/translate';
import { thumbsUp } from '../../atoms/Icon/libraries/glyph';
import Icon from '../../atoms/Icon/Icon';

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
  color: #888;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
`;

const PerformanceText = styled.div`
  align-items: center;
  justify-content: center;
  border: 1px solid #888;
  color: ${BLACK_1};
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
  margin-top: auto;
  min-width: 182px;
  padding: 4px 24px;
  border-radius: 4px;
  flex: 0 0 auto;
`;

const PerformanceChart = styled.div`
  align-self: stretch;
  color: ${BLACK_1};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  margin-top: auto;
  flex: 0 0 auto;
`;

interface Props {
  performance: number;
  streamQuality: number;
  loading: boolean;
}

const ItemRank: FC<Props> = (props) => {
  const getGrade = (): string => {
    if (props.performance <= 25) {
      return _('common:text.bad');
    } else if (props.performance <= 49) {
      return _('common:text.average');
    } else if (props.performance <= 74) {
      return _('common:text.good');
    } else if (props.performance <= Infinity) {
      return _('common:text.veryGood');
    } else {
      return '';
    }
  };

  const getLevel = (): Level => {
    if (props.streamQuality < -30) {
      return Level.low;
    } else if (props.streamQuality < 0) {
      return Level.medium;
    } else {
      return Level.high;
    }
  };

  return (
    <ItemContainer
      loading={props.loading}
      title={_('ranking:newRanking.item.performance.title')}
      childrenStyles={{ flexDirection: 'column' }}
    >
      <Container>
        <p css={{ width: '100%' }}>
          <T _={'ranking:newRanking.item.performance.description'} />
        </p>

        <div css={{ flexDirection: 'column', width: '100%', color: BLACK_1, marginTop: 'auto' }}>
          <p css={{ fontSize: 14, marginBottom: 4, textAlign: 'left' }}>
            <span css={{ marginRight: 4 }}>ø</span>
            <T _={'ranking:newRanking.videoQuality'} />:
          </p>
          <Ampel level={getLevel()} />
        </div>

        <PerformanceChart>
          <p css={{ marginBottom: 4 }}>
            <span css={{ marginRight: 4 }}>ø</span>
            <span>
              <T _={'ranking:newRanking.item.performance.chat'} />:
            </span>
          </p>
          <ProgressBar progress={props.performance} />
        </PerformanceChart>

        <PerformanceText>
          {props.performance >= 50 ? <Icon icon={thumbsUp} css={{ marginRight: 8 }} /> : null}

          <span>{getGrade()}</span>
        </PerformanceText>
      </Container>
    </ItemContainer>
  );
};

export default ItemRank;
