import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../atoms';

import { Container, Bottom, IconLinkContainer, IconContainer, LinkContainer } from './styles';

class LinkBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { linkCopied: false };
    this.linkContainerRef = React.createRef();
  }

  selectLink = () => {
    if (typeof global.getSelection === 'function') {
      global.getSelection().selectAllChildren(this.linkContainerRef.current);
    }
  };

  unselectLink = () => {
    if (typeof global.getSelection === 'function') {
      global.getSelection().removeAllRanges();
    }
  };

  handleCopyLinkButtonClick = () => {
    let successful = false;

    if (typeof document.execCommand === 'function') {
      this.selectLink();
      successful = document.execCommand('copy');
      this.unselectLink();
    } else {
      successful = this.props.onButtonClick();
    }

    if (!this.state.linkCopied && successful) this.setState({ linkCopied: true });
  };

  render() {
    const {
      label,
      link,
      icon,
      iconText,
      iconColor,
      iconBgColor,
      buttonLabelCopied,
      buttonLabelCopy,
    } = this.props;
    if (!link) return null;
    return (
      <Container>
        {label && <div css={{ paddingBottom: 4 }}>{label}</div>}
        <Bottom>
          <IconLinkContainer>
            {(icon || iconText) && (
              <IconContainer color={iconColor} backgroundColor={iconBgColor}>
                {icon ? <span className={icon} data-testid="icon" /> : iconText}
              </IconContainer>
            )}
            <LinkContainer onClick={this.selectLink} ref={this.linkContainerRef}>
              {link}
            </LinkContainer>
          </IconLinkContainer>
          <Button
            theme={this.state.linkCopied ? 'blue' : 'gray'}
            css={{ borderRadius: 0, flex: '0 0 auto' }}
            label={this.state.linkCopied ? buttonLabelCopied : buttonLabelCopy}
            onClick={this.handleCopyLinkButtonClick}
          />
        </Bottom>
      </Container>
    );
  }
}

LinkBox.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconText: PropTypes.string,
  iconColor: PropTypes.string,
  iconBgColor: PropTypes.string,
  buttonLabelCopy: PropTypes.string,
  buttonLabelCopied: PropTypes.string,
  onButtonClick: PropTypes.func,
};

LinkBox.defaultProps = {
  label: '',
  icon: '',
  iconText: '',
  iconColor: '',
  iconBgColor: '',
  buttonLabelCopy: 'copy link',
  buttonLabelCopied: 'link copied',
  onButtonClick: () => false,
};

export default LinkBox;
