import AppDispatcher from '../../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import ActionTypes from '../../../util/constants/ActionTypes';
import { EventEmitter } from 'events';

let CHANGE_EVENT = 'ListsVXCashUsers.change';

let defaultStore = {
  userlist: [],
  totalEntries: 0,
  showSpinner: true,
  loadMoreButtonDisabled: false,
  sorting: {
    field: 'registerDate',
    direction: 'desc',
  },
  pagination: {
    limit: 8,
    offset: 1,
  },
};

let _vxcashusers = {
  promolinkUsers: defaultStore,
  homepageUsers: defaultStore,
  recruitedHosts: defaultStore,
};

class VXCashUsersStoreClass extends EventEmitter {
  emitChange(store) {
    this.emit(CHANGE_EVENT + '.' + store);
  }

  addChangeListener(store, callback) {
    this.on(CHANGE_EVENT + '.' + store, callback);
  }

  removeChangeListener(store, callback) {
    this.removeListener(CHANGE_EVENT + '.' + store, callback);
  }

  get(store) {
    if (!_vxcashusers[store]) {
      throw new InvalidStoreException(store);
    }
    return _vxcashusers[store];
  }
}

const VXCashUsersStore = new VXCashUsersStoreClass();

function InvalidStoreException(store) {
  this.STORE = store;
  this.message = 'Invalid STORE';
  this.toString = function() {
    return this.message + ': ' + this.store;
  };
}

AppDispatcher.register(function(payload) {
  let store = payload.store;

  switch (payload.type) {
    case ActionTypes.LISTS_VXCASHUSERS_RECEIVE_DATA:
      _vxcashusers[store] = update(_vxcashusers[store], { $merge: payload.data });
      VXCashUsersStore.emitChange(store);
      break;
    case ActionTypes.LISTS_VXCASHUSERS_APPEND_DATA:
      _vxcashusers[store].userlist = update(_vxcashusers[store].userlist, { $push: payload.data });
      VXCashUsersStore.emitChange(store);
      break;
    default:
  }
});

export { VXCashUsersStore };
