import React, { FC } from 'react';
import Icon from '../Icon/Icon';
import { exclamationSign } from '../Icon/libraries/glyph';
import { GRAY_2 } from '../../camtool-styles';

interface Props {
  text: string;
  className?: string;
}

const GenericWarningText: FC<Props> = ({ text, className }) => {
  return (
    <div className={className} css={{ alignItems: 'baseline' }}>
      <Icon icon={exclamationSign} size={'10px'} css={{ color: GRAY_2, marginRight: '3px' }} />
      <p css={{ fontSize: '12px', color: GRAY_2 }}>{text}</p>
    </div>
  );
};

export default GenericWarningText;
