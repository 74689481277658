import { resolveAny } from 'dns';

export enum RequestTopicEnum {
  SESSION_ADOPTSSWSESSION_REQUEST = 'session.adoptSswSession.request',
  SESSION_KEEPALIVE_REQUEST = 'session.keepAlive.request',
  SESSION_LOGOUT_REQUEST = 'session.logout.request',
  SESSION_LOGIN_REQUEST = 'session.login.request',
  SESSION_REGISTER_REQUEST = 'session.register.request',
  USERSTORE_UPDATE_REQUEST = 'userStore.update.request',
  USERSTORE_SET_REQUEST = 'userStore.set.request',
  NOTIFICATIONS_UPDATE_REQUEST = 'notifications.update.request',
  NOTIFICATIONS_SET_REQUEST = 'notifications.set.request',
}

export enum WebsocketEventEnum {
  WEBSOCKET_OPEN = 'websocket.open',
  WEBSOCKET_CLOSE = 'websocket.closed',
  WEBSOCKET_MESSAGE = 'websocket.message',
  WEBSOCKET_ERROR = 'websocket.error',
}

export enum ResponseTopicEnum {
  ALERTS_DATA = 'alerts.data',
  CAMTOOL_RELOAD = 'camtool.reload',
  GRAPHQL_WRITE_QUERY = 'graphql.writeQuery',
  SESSION_DATA = 'session.data',
  SESSION_ERROR = 'session.error',
  SESSION_KEEPALIVE_ACK = 'session.keepAlive.ack',
  SESSION_LOGOUT_ACK = 'session.logout.ack',
  USERSTORE_UPDATE_DATA = 'userStore.update.data',
  USERSTORE_SET_ACK = 'userStore.set.ack',
  NOTIFICATIONS_UPDATE_DATA = 'notifications.update.data',
  NOTIFICATIONS_SET_ACK = 'notifications.set.ack',
  MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA = 'mediaManagement.video.transcoding.data',
}

export interface WebsocketState {
  options: { debug: boolean };
  websocket?: WebSocket;
  queue: Message[];
}

export interface Message {
  type: RequestTopicEnum | ResponseTopicEnum;
  data?: Payload;
}

export interface Payload {
  [K: string]: any;
}

export type Handler = (payload: Omit<Message, 'type'>, topic: ResponseTopicEnum) => void;
export type Listener = { topic: ResponseTopicEnum; handler: Handler };

export interface Listeners {
  [K: ResponseTopicEnum]: Handler[] | undefined;
}

export type PublishFunc = (topic: RequestTopicEnum, payload?: any) => void;
export type SubscribeFunc = (
  topic: ResponseTopicEnum | WebsocketEventEnum,
  handler: Handler
) => void;

export interface WebsocketContextValue {
  publish: PublishFunc;
  subscribe: SubscribeFunc;
}
