import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { podium } from '../../../../../atoms/Icon/libraries/glyph';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';

const CompetitionsItem: FC = () => {
  const hasRoleVXModels = useHasRole(SecurityRole.VX_USER_VXMODELS);

  return hasRoleVXModels ? (
    <LeftMenuItem
      title={_('navigation:marketing.competitions')}
      icon={podium}
      uri="/marketing/campaigns"
    />
  ) : null;
};

export default CompetitionsItem;
