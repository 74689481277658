import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import nouislider from 'nouislider';
import isEqual from 'lodash/isEqual';
import RangeTextField from './RangeTextField/RangeTextField';

import AbstractFormComponent from '../AbstractFormComponent';
import { getFormattedNumber } from '../../../util/Formatter';

class Range extends AbstractFormComponent {
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state || {}, {
      displayValue: 0,
    });
  }

  componentDidMount() {
    this.sliderElement = ReactDOM.findDOMNode(this).children[1]; // eslint-disable-line
    const currentValue = parseFloat(this.getValue());
    const options = {
      name: this.props.name,
      start: currentValue,
      step: parseFloat(this.props.step),
      range: this.props.range,
      unit: this.props.unit,
    };

    if (this.props.snap) {
      options.snap = this.props.snap;
    }

    if (currentValue < this.props.range.min) {
      super.setValue(this.props.range.min);
    }

    nouislider.create(this.sliderElement, options);

    this.sliderElement.noUiSlider.on('update', this.onUpdate); // fired for every drag-step
    this.sliderElement.noUiSlider.on('change', this.onChange); // fired for final value change (after update once, and onClick)
  }

  componentWillUnmount() {
    this.sliderElement.noUiSlider.destroy();
    super.componentWillUnmount();
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.range, nextProps.range)) {
      this.sliderElement.noUiSlider.updateOptions({
        range: nextProps.range,
      });
    }

    if (nextProps.disabled) {
      this.sliderElement.setAttribute('disabled', true);
    }

    if (!this.hasValue()) {
      this.sliderElement.noUiSlider.set(parseFloat(nextProps.value), 0);
    }

    super.componentWillReceiveProps(nextProps);
  }

  resetValue() {
    this.sliderElement.noUiSlider.set(parseFloat(this.state._pristineValue), 0);
    super.resetValue();
  }

  setValue(value) {
    this.sliderElement.noUiSlider.set(value, 0);
    super.setValue(value);
  }

  onUpdate(value) {
    const float = parseFloat(value);
    if (this.props.onChange) {
      this.props.onChange(float);
    }

    this.setState({
      displayValue: this.props.displayTransform(float),
    });
  }

  onChange(value) {
    const float = parseFloat(value[0]);
    if (this.props.onChange) {
      this.props.onChange(float);
    }
    this.setValue(float);
  }

  setValueFromTextField(v) {
    const value = typeof v === 'string' ? v.replace(',', '.') : v;
    this.sliderElement.noUiSlider.set(value);
    this.setValue(value);

    const sliderValue =
      this.sliderElement.noUiSlider.get() % 1 === 0
        ? Math.trunc(this.sliderElement.noUiSlider.get())
        : this.sliderElement.noUiSlider.get();
    return this.props.displayTransform(sliderValue);
  }

  render() {
    return (
      <div className="range">
        <div className={`range__value range__value--unit-${this.props.unit}`} style={this.style}>
          <RangeTextField
            name={this.props.name}
            size={3}
            value={this.state.displayValue}
            onEnterPress={this.setValueFromTextField}
            disabled={this.props.disabled}
            disabledInputOnly={this.props.disabledInputOnly}
          />
        </div>

        {/* DARF NICHT BEWEGT WERDEN IM DOM !!! -->
                <!-- ES DÜRFEN AUCH KEINE ELEMENTE OBERHALB DIESES ELEMENT HINZUGEFÜGT WERDEN */}
        <div className="range__slider" />
        {/* DARF NICHT BEWEGT WERDEN IM DOM !!! */}
      </div>
    );
  }
}

Range.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
  unit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  range: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  }),
  disabled: PropTypes.bool,
  disabledInputOnly: PropTypes.bool,
  showValue: PropTypes.bool,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  displayTransform: PropTypes.func,
};

Range.defaultProps = {
  ...AbstractFormComponent.defaultProps,
  value: 0,
  step: 0,
  range: {
    min: 0,
    max: 0,
  },
  unit: '',
  disabled: false,
  disabledInputOnly: false,
  showValue: true,
  displayTransform: (v) => v,
};

Range.displayTransform = {
  getToDecimalDividedBy100Transform: (lang) => (value) => getFormattedNumber(value / 100, lang, 2),
  getToDecimalTransform: (lang) => (value) => getFormattedNumber(value, lang, 2),
};

export default Range;
export { Range };
