import React, { FC } from 'react';

import { RouteComponentProps } from 'react-router';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { resolveSlug } from './util';
import { DEFAULT, Slugs } from './conf';
import { APP_SCOPE } from '../../util/env';

const JumpLinks: FC<RouteComponentProps> = ({ match: { path } }) => {
  const match = useRouteMatch<{ slug: Slugs }>(path + '/:slug');
  const url = resolveSlug(APP_SCOPE, match?.params?.slug || DEFAULT);

  return <Redirect to={url} />;
};

export default JumpLinks;
