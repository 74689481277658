import React, { FC } from 'react';
import { Column } from 'material-table';

import {
  Mailing,
  MailingRecipientsGroupsEnum,
  MailingStatusEnum,
  MailingTypeEnum,
} from '../../../../graphql/VXModels/types';
import { useMailingsList } from '../../hooks';
import { FancyError } from '../../../../components';
import { _ } from '../../../../util/translate';
import { MaterialTable } from '../../../../atoms';
import { useHistory } from 'react-router';
import { BuildUrl } from '../../types';

interface MailingsListProps {
  type: MailingTypeEnum;
  buildUrl: BuildUrl;
  columns?: ColumnName[];
  recipientsGroups?: MailingRecipientsGroupsEnum[];
  status?: MailingStatusEnum[];
}

export enum ColumnName {
  status = 'status',
  shipmentRecipientsCount = 'shipmentRecipientsCount',
  name = 'name',
  modified = 'modified',
}

const allColumns = [
  ColumnName.status,
  ColumnName.shipmentRecipientsCount,
  ColumnName.name,
  ColumnName.modified,
];

const getColumnsConfig = (columns): Column<Record<string, unknown>>[] => [
  {
    field: ColumnName.status,
    title: _('mailings:email.archiveColumn.status'),
    hidden: !columns.includes(ColumnName.status),
    lookup: {
      [MailingStatusEnum.DRAFT]: _('mailings:status.DRAFT'),
      [MailingStatusEnum.SHIPMENT_DONE]: _('mailings:status.SHIPMENT_DONE'),
      [MailingStatusEnum.SHIPMENT_FAILED]: _('mailings:status.SHIPMENT_FAILED'),
      [MailingStatusEnum.SHIPMENT_PENDING]: _('mailings:status.SHIPMENT_PENDING'),
      [MailingStatusEnum.SHIPMENT_PREPARED]: _('mailings:status.SHIPMENT_PREPARED'),
      [MailingStatusEnum.SHIPMENT_PROGRESS]: _('mailings:status.SHIPMENT_PROGRESS'),
    } as Record<MailingStatusEnum, string>,
  },
  {
    field: ColumnName.shipmentRecipientsCount,
    title: _('mailings:email.archiveColumn.recipients'),
    hidden: !columns.includes(ColumnName.shipmentRecipientsCount),
    type: 'numeric',
  },
  {
    field: ColumnName.name,
    title: _('mailings:email.archiveColumn.subject'),
    hidden: !columns.includes(ColumnName.name),
  },
  {
    field: ColumnName.modified,
    title: _('mailings:email.archiveColumn.creationDate'),
    hidden: !columns.includes(ColumnName.modified),
    type: 'datetime',
  },
];

const MailingsList: FC<MailingsListProps> = ({
  type,
  recipientsGroups,
  status,
  columns = allColumns,
  buildUrl,
}) => {
  const { push } = useHistory();
  const { mailingsList, loading, error } = useMailingsList(
    { type, recipientsGroups, status, limit: 50 },
    { fetchPolicy: 'cache-and-network', variables: { limit: 50 } }
  );

  return error ? (
    <FancyError error={error} />
  ) : (
    <MaterialTable
      isLoading={loading && !mailingsList}
      localization={{
        body: { emptyDataSourceMessage: _('mailings:MailingsList.text.emptyMessage') },
      }}
      options={{
        loadingType: 'overlay',
        search: false,
        showTitle: false,
        toolbar: false,
        draggable: false,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50],
        padding: 'dense',
        tableLayout: 'auto',
        headerStyle: {
          wordBreak: 'normal',
        },
      }}
      onRowClick={(event, mailing: Mailing): void => push(buildUrl(mailing))}
      columns={getColumnsConfig(columns)}
      data={mailingsList}
    />
  );
};

export default MailingsList;
