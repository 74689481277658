import React, { FC, useState } from 'react';
import { _ } from '../../../../../util/translate';
import GenericModalBoxLayout from '../../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { ModalWrapper } from '../../styles';
import {
  PhoneVerificationStatusEnum,
  Service0900TargetNumber,
  Maybe,
  Service0900Country,
} from '../../../../../graphql/VXModels/types';
import RequestCode from './RequestCode';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import { Service0900PhoneData, Service0900PhoneNumber } from '../../types';

interface Props {
  storeData: Service0900PhoneData;
  closeModal: () => void;
  onSuccess: () => void;
}

const ModalPhoneRequestSteps: FC<Props> = ({ storeData, closeModal, onSuccess }) => {
  const [targetNumber, setTargetNumber] = useState<
    Maybe<Pick<Service0900TargetNumber, 'phoneNumber' | 'countryCallingCode'>> | undefined
  >(storeData?.targetNumber);
  const [step, setStep] = useState<PhoneVerificationStatusEnum>(
    PhoneVerificationStatusEnum.unknown
  );
  const onEdit = (argument: PhoneVerificationStatusEnum): void => {
    setStep(argument);
  };

  return (
    <GenericModalBoxLayout name="phone-confirmation" maxWidth={700} noCloseOnESC>
      <ModalWrapper>
        <div css={{ flexDirection: 'column', alignItems: 'center' }}>
          <h2 css={[step === PhoneVerificationStatusEnum.checking && { marginBottom: '30px' }]}>
            {_('service0900:PhoneSex.modal.general.headline')}
          </h2>
          {step === PhoneVerificationStatusEnum.unknown ? (
            // request code (step1)
            <RequestCode
              targetNumber={targetNumber}
              setTargetNumber={setTargetNumber}
              allowedCountries={storeData?.allowedCountries as Service0900Country[]}
              onRequestSuccess={onEdit}
            />
          ) : (
            step === PhoneVerificationStatusEnum.checking && (
              // verify number (step2)
              <VerifyPhoneNumber
                // careful targetNumber here has different structure than in step1 (dirty hack because of "go back to change number" button in step2)
                targetNumber={targetNumber as Service0900PhoneNumber}
                onResetStep={(): void => setStep(PhoneVerificationStatusEnum.unknown)}
                onSuccess={onSuccess}
                closeModal={closeModal}
              />
            )
          )}
        </div>
      </ModalWrapper>
    </GenericModalBoxLayout>
  );
};

export default ModalPhoneRequestSteps;
