import React, { FC } from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WHITE, BLACK } from '../../../camtool-styles';
import { useLang } from "../../../util/AppState";
import { _ } from '../../../util/translate';
import VXMSwitch2DE from './VXMSwitch2DE.png';
import VXMSwitch2EN from './VXMSwitch2EN.png';

type TProps = {}

const useStyles = makeStyles(() => ({
  root: {
    color: BLACK,
    padding: 16,
    height: '100%',
  },
  heading: {
    color: BLACK,
    marginTop: 14,
    marginBottom: 30,
    textAlign: 'center',
  },
  container: {
    color: BLACK,
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    cursor: 'pointer',
    // width: '100%',
    // aspectRatio: '1161/564',
    // objectFit: 'cover',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    color: WHITE,
    marginTop: 16,
    marginBottom: 16,
  },
}));

const NewVXMSwitch2: FC<TProps> = ({}) => {
  const {
    root,
    container,
    heading,
    image,
    buttons,
    button,
  } = useStyles();

  const [lang] = useLang();

  const link = 'https://beta.vxmodels.com/dashboard';

  return (
    <Box className={root}>
      <Typography variant="h3" className={heading}>
        {_('dashboard:app.switchVXMBeta2.heading')}
      </Typography>
      <Grid container spacing={3} className={container}>
        <a href={link}>
          <img src={lang === 'de' ? VXMSwitch2DE : VXMSwitch2EN}
               alt={_('dashboard:app.switchVXMBeta2.heading')}
               className={image}/>
        </a>
      </Grid>
      <Box className={buttons}>
        <Button className={button} href={link}>
          {_('dashboard:app.switchVXMBeta2.button')}
        </Button>
      </Box>
    </Box>
  );
};

export default NewVXMSwitch2;
