import React, { FC } from 'react';
import { Markdown } from '../../../../../components';
import { Modal } from '../../../../../atoms';
import termsAndConditions2023 from '../../../Assets/terms2023.md';
import termsAndConditions2024 from '../../../Assets/terms2024.md';
import { bannerUniversal } from '../CompetitionOnboarding/assets';
import { Box, Container, Typography } from '@material-ui/core';
import { VXGAMES_TYPOGRAPHY_BLUE_PARAGRAPH } from '../../../utils';
import { useModalStyles } from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface IProps {
  onClose: () => void;
  open: boolean;
}

const onTermsClick = (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

const TermsModal: FC<IProps> = ({ onClose }) => {
  const classes = useModalStyles();

  //update terms next year :)
  const currentYear = new Date().getFullYear();

  return (
    <Modal onClose={onClose}>
      <Container className={classes.container}>
        <Box onClick={onTermsClick} className={classes.terms}>
          <Box className={classes.header}>
            <img src={bannerUniversal} />
          </Box>
          <Box className={classes.back}>
            <Typography style={VXGAMES_TYPOGRAPHY_BLUE_PARAGRAPH} onClick={onClose}>
              <ArrowBackIcon className={classes.arrowBack} /> Zurück
            </Typography>
          </Box>
          {/* update terms next year :) */}
          <Markdown
            source={currentYear === 2023 ? termsAndConditions2023 : termsAndConditions2024}
            className={classes.text}
          />
          <Box className={classes.back}>
            <Typography style={VXGAMES_TYPOGRAPHY_BLUE_PARAGRAPH} onClick={onClose}>
              <ArrowBackIcon className={classes.arrowBack} /> Zurück
            </Typography>
          </Box>
        </Box>
      </Container>
    </Modal>
  );
};

export { TermsModal };
