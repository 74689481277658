import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../components/AbstractComponent';
import Modalbox from '../../Modalbox/Modalbox';
import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import { withRouter } from 'react-router';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';

class ModalFlirtlifeReminder extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  doNotShowFLModalAgain() {
    document.cookie = `doNotShowFLModalAgain=true; expires=${new Date(
      'Wed Mar 07 2018 00:00:00'
    ).toUTCString()}`;
    this.close();
  }

  render() {
    if (document.cookie && document.cookie.indexOf('doNotShowFLModalAgain=true') > -1) return null;
    return (
      <Modalbox name="modalFL" state={this.state.isOpen} onKeyPressESC={this.close}>
        <div className="modalgrid__box">
          <div className="modalgrid__box__header">
            <div className="icon-info-sign" />
            <div>Flirtlife ab sofort in VXMODELS</div>
            <div className="seperator" />
            <div className="icon-remove options" onClick={this.close} />
          </div>

          <div
            className="modalgrid__box__content modal-survey"
            style={{ paddingLeft: 60, paddingRight: 60 }}
          >
            <div
              className="modal-survey__subtitle"
              style={{
                marginBottom: 16,
                textAlign: 'center',
                fontSize: 16,
              }}
            >
              Umsatz steigern & Rankingpunkte sichern mit Flirtlife!
            </div>

            <div className="modal-survey__title" style={{ fontSize: 14, marginBottom: 40 }}>
              <p>
                Dir fehlt zwischen Deinen Camshows manchmal ein nettes Gespräch oder der ein oder
                andere kleine Flirt? Dann wirst Du Dich über das, was jetzt kommt, freuen! Im März
                erscheint unsere Soft-Dating Plattform Flirtlife in neuem Gewand.
                <br />
                <br />
                Verdiene Geld mit soften Nachrichten und sichere Dir Rankingpunkte mit einem
                vollständig ausgefüllten Profil!
              </p>
            </div>

            <ButtonGroup position="center" className="modal-survey__buttons">
              <Button
                className="button--blue"
                label="JETZT PROFIL ANLEGEN"
                onClick={() => this.props.history.push(`${APP_BASE_PATH}/onlineprofile/flirtlife/`)}
                type="button"
              />
            </ButtonGroup>

            <ButtonGroup position="center" className="modal-survey__buttons">
              <Button
                className="link--grey"
                label="Nicht wieder anzeigen"
                onClick={this.doNotShowFLModalAgain}
                type="button"
              />
            </ButtonGroup>
          </div>
        </div>
      </Modalbox>
    );
  }
}

export default withRouter(ModalFlirtlifeReminder);
