import React, { FC } from 'react';
import { FixedSizeList as List } from 'react-window';
import { Query } from '@apollo/react-components';
// Outside Wizard
import { WHITE } from '../../../../../camtool-styles';
import { _ } from '../../../../../util/translate';
// Inside Wizard
import { VoucherType, IStepVoucherContentPros } from '../../../types';
import PreviewMedia from './PreviewMedia';
import PreviewOther from './PreviewOther';
import { pluralize, ICONS, QUERY_MODEL_AVATAR, IQueryModelAvatarProps } from '../../../utils';
import styled from '@emotion/styled';
import { BREAKPOINT_BENEFITS } from '../constants';

const LIST_WIDTH = 382;
const LIST_HEIGHT = 550;

const PreviewArticle = styled.article`
  flex-direction: column;
  max-height: 606px;
  background-color: ${WHITE};
  width: ${LIST_WIDTH}px;
  margin-left: 32px;
  margin-bottom: 32px;

  @media (max-width: ${BREAKPOINT_BENEFITS}px) {
    margin-top: 20px;
    width: 100%;
    margin-left: 0;
  }
`;

const formatDate = (data: string) => {
  const d = new Date(data);
  return `${String(d.getUTCDate()).padStart(2, '0')}.${String(d.getUTCMonth() + 1).padStart(
    2,
    '0'
  )}.${d.getUTCFullYear()}`;
};

const Preview: FC<IStepVoucherContentPros> = ({ state, dispatch, className }) => {
  const handleMediaClick = (content: any) => {
    dispatch({
      type: 'REMOVE_VOUCHER_CONTENT',
      payload: {
        voucherContent: { contentId: content.contentId },
      },
    });
  };

  const handleOtherClick = (content: any) => {
    dispatch({
      type: 'REMOVE_VOUCHER_CONTENT',
      payload: {
        voucherContent: { contentId: content.contentId },
      },
    });

    dispatch({ type: 'GO_BACK', payload: {} });
  };

  const getItemComponent = (voucherType: VoucherType, content: any, style: any, avatar: string) => {
    const voucherTypeLowerCase = voucherType.toLowerCase();

    switch (voucherType) {
      case 'CHAT_MINUTES':
      case 'VXPAGES_CREDITS':
      case 'MESSAGES':
      case 'PHOTOS':
      case 'TICKET_SHOW':
      case 'VIDEO':
        return (
          <PreviewOther
            onClick={handleOtherClick}
            content={content}
            style={style}
            css={{ padding: '0 16px' }}
            avatar={avatar}
            icon={ICONS[voucherType]}
            title={_(`benefits:voucher.${voucherTypeLowerCase}.name`)}
            subtitle={pluralize(
              content.contentData,
              _(`benefits:voucher.${voucherTypeLowerCase}.previewSubtitle`, {
                sprintf: [content.contentData],
              }),
              _(
                `benefits:voucher.${voucherTypeLowerCase}.${
                  voucherTypeLowerCase === 'video' || voucherTypeLowerCase === 'photos'
                    ? 'previewSubtitlePlural'
                    : 'previewSubtitle'
                }`,
                {
                  sprintf: [content.contentData],
                }
              )
            )}
          />
        );
      case 'SHOW_TICKET':
        const subtitle = _(`benefits:voucher.${voucherTypeLowerCase}.previewSubtitle`, {
          sprintf: [content.contentData],
        });

        return (
          <PreviewOther
            onClick={handleOtherClick}
            content={content}
            style={style}
            css={{ padding: '0 16px' }}
            avatar={avatar}
            icon={ICONS[voucherType]}
            title={_(`benefits:voucher.${voucherTypeLowerCase}.name`)}
            subtitle={pluralize(content.contentData, subtitle, subtitle)}
          />
        );
      case 'MEDIA':
        return (
          <PreviewMedia
            onClick={handleMediaClick}
            content={content}
            style={style}
            css={{ padding: '0 16px' }}
          />
        );
      default:
        throw new Error('Invalid or missing `voucherType`.');
    }
  };

  return (
    <PreviewArticle className={className}>
      <header css={{ margin: 16 }}>
        <h2 css={{ fontWeight: 'normal' }}>
          {_('benefits:voucher.texts.currentContent')}
          {state.currentVoucherType === 'MEDIA' && (
            <>
              {` `}
              <span>
                ({state.voucherContent.length}/{state.config.media.max})
              </span>
            </>
          )}
        </h2>
      </header>

      <List
        height={LIST_HEIGHT}
        itemCount={state.voucherContent.length}
        itemData={state.voucherContent}
        itemSize={60 + 8}
        width={LIST_WIDTH}
      >
        {({ data, index, style }) => (
          <Query<IQueryModelAvatarProps> query={QUERY_MODEL_AVATAR}>
            {({ loading, data: queryData, error }) => {
              const avatar =
                !loading && !error && queryData && queryData.model.avatar.picture
                  ? queryData.model.avatar.picture.url
                  : '';

              return getItemComponent(data[index]?.voucherType, data[index], style, avatar);
            }}
          </Query>
        )}
      </List>
    </PreviewArticle>
  );
};

export default Preview;
