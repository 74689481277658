import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';

class Gauge extends AbstractComponent {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.props.value;
  }

  render() {
    let value = parseInt(this.props.value);

    // calc factor for rotation of the needle
    let fac = 0;
    if (value <= 120) {
      fac = 0.2;
    } else if (value <= 150) {
      fac = 0.18;
    } else if (value <= 240) {
      fac = 0.15;
    } else if (value <= 315) {
      fac = 0.16;
    }

    value = Math.round(value - 137 - value * fac);
    if (value < -137) {
      value = -137;
    } else if (value >= 137) {
      value = 139;
    }

    return (
      <div className="ranking__gaugecontainer">
        <div className="gauge__bgimage">
          <div className="gauge__needle" style={{ transform: 'rotate(' + value + 'deg)' }} />
        </div>
      </div>
    );
  }
}

Gauge.propTypes = {
  value: PropTypes.number,
};

Gauge.defaultProps = {
  value: 0,
};

export default Gauge;
export { Gauge };
