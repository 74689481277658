import React, { FC, useMemo, ReactElement } from 'react';
import { _ } from '../../../../util/translate';
import { Field, FieldProps } from 'formik';
import { StyledNumberInput, StyledSelect } from './styles';
import { FormikUserLifeCycleSteps } from './types';
import { Box, Typography } from '@material-ui/core';
import { getDayPartOptions, getDeliveryOptions } from './utils';

interface Props {
  index: number;
}

const AutoMailDeliveryOption: FC<Props> = React.memo(({ index }) => {
  const deliveryOptions = useMemo(() => getDeliveryOptions(), []);
  const dayPartOptions = useMemo(() => getDayPartOptions(), []);

  return (
    <div>
      <Field name={`autoMailList[${index}].delayMinutes`}>
        {({
          field,
          form: { errors, touched },
        }: FieldProps<FormikUserLifeCycleSteps>): ReactElement => {
          const touchedDelayMinutes = touched?.autoMailList?.[index]?.delayMinutes;
          const errorDelayMinutes = errors?.autoMailList?.[index]?.delayMinutes;

          return (
            <Box display="flex" flexDirection="column" mr={1}>
              <StyledNumberInput
                error={!!touchedDelayMinutes && !!errorDelayMinutes}
                {...field}
                type="number"
                placeholder="0"
                min="0"
                max="999"
              />
              {touchedDelayMinutes && errorDelayMinutes && (
                <Box position="absolute" bottom="0px">
                  <Typography color={'error'}>{errorDelayMinutes}</Typography>
                </Box>
              )}
            </Box>
          );
        }}
      </Field>
      <Field name={`autoMailList[${index}].delayDisplayUnit`}>
        {({
          field,
          form: { errors, touched },
        }: FieldProps<FormikUserLifeCycleSteps>): ReactElement => {
          const touchedDisplayUnit = touched?.autoMailList?.[index]?.delayDisplayUnit;
          const errorDisplayUnit = errors?.autoMailList?.[index]?.delayDisplayUnit;

          return (
            <Box display="flex" flexDirection="column" mr={1}>
              <StyledSelect
                hasError={!!touchedDisplayUnit && !!errorDisplayUnit}
                {...field}
                options={deliveryOptions}
                placeholder={_('common:text.pleaseSelect')}
              />
              {touchedDisplayUnit && errorDisplayUnit && (
                <Box position="absolute" bottom="0px">
                  <Typography color={'error'}>{errorDisplayUnit}</Typography>
                </Box>
              )}
            </Box>
          );
        }}
      </Field>

      <Field name={`autoMailList[${index}].delaySchedule`}>
        {({
          field,
          form: { errors, touched },
        }: FieldProps<FormikUserLifeCycleSteps>): ReactElement => {
          const touchedDelaySchedule = touched?.autoMailList?.[index]?.delaySchedule;
          const errorDelaySchedule = errors?.autoMailList?.[index]?.delaySchedule;

          return (
            <Box display="flex" flexDirection="column">
              <StyledSelect
                hasError={!!touchedDelaySchedule && !!errorDelaySchedule}
                {...field}
                options={dayPartOptions}
                placeholder={_('common:text.pleaseSelect')}
              />
              {touchedDelaySchedule && errorDelaySchedule && (
                <Box position="absolute" bottom="0px">
                  <Typography color={'error'}>{errorDelaySchedule}</Typography>
                </Box>
              )}
            </Box>
          );
        }}
      </Field>
    </div>
  );
});

AutoMailDeliveryOption.displayName = 'AutoMailDeliveryOption';

export default AutoMailDeliveryOption;
