import React, { FC } from 'react';
import NavSub from '../../components/NavSub/NavSub';
import NavSubItem from '../../components/NavSubItem/NavSubItem';
import { APP_BASE_PATH } from '../../util/env';
import { _ } from '../../util/translate';

const Navigation: FC = () => {
  return (
    <NavSub>
      <NavSubItem
        link={`${APP_BASE_PATH}/newscenter/notifications`}
        icon="icon-list-alt"
        label={_('navigation:newscenter.notifications')}
      />
      <NavSubItem
        link={`${APP_BASE_PATH}/newscenter/settings`}
        icon="icon-cogwheels"
        label={_('navigation:newscenter.settings')}
      />
    </NavSub>
  );
};

export default Navigation;
