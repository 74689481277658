import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Table } from '../../../../../atoms/Table';
import { getColumns } from './columns';
import { IAbo } from '../../../types';
import AboTableRow from './AboTableRow';

interface IProps {
  abos: IAbo[];
  onShowAboDetailsClick: (abo: IAbo) => void;
  displayDeactivationDate: boolean;
}

const ScrollWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledTable = styled(Table)`
  min-width: 840px;
`;

const AboTable: FC<IProps> = ({ abos, onShowAboDetailsClick, displayDeactivationDate }) => {
  const columns = getColumns(displayDeactivationDate);

  return (
    <ScrollWrapper>
      <StyledTable columns={columns}>
        {abos.map((abo) => (
          <AboTableRow
            abo={abo}
            columns={columns}
            key={abo.id}
            onShowAboDetailsClick={onShowAboDetailsClick}
            displayDeactivationDate={displayDeactivationDate}
          />
        ))}
      </StyledTable>
    </ScrollWrapper>
  );
};

export default AboTable;
