import React, { FC } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useLang } from '../../util/AppState';

type DecimalFieldProps = TextFieldProps &
  Omit<NumberFormatProps, 'customInput' | 'isNumericString'>;

const baseLangConfig = {
  thousandSeparator: false,
  decimalScale: 2,
};

const langConfig = {
  de: { ...baseLangConfig, decimalSeparator: ',' },
  en: { ...baseLangConfig, decimalSeparator: '.' },
};

const DecimalField: FC<DecimalFieldProps> = ({ ...props }) => {
  const [lang] = useLang();
  const langProps = langConfig[lang] || langConfig.en;
  return (
    <NumberFormat
      lang={lang}
      customInput={TextField}
      isNumericString={true}
      fixedDecimalScale={true}
      allowedDecimalSeparators={[',', '.']}
      {...langProps}
      {...props}
    />
  );
};

export default DecimalField;
