import React, { FunctionComponent } from 'react';
import {
  MediaAlbum,
  MediaPicture,
  VXGChallengeTypeEnum,
  VXGChallengeUserStatusEnum,
} from '../../../graphql/VXModels/types';
import { Container, Grid } from '@material-ui/core';
import { ChatHoldingTimes, ChatRatings, Finance, OnlineTimeThisMonth } from '../WidgetsSmall';
import TelegramTile from '../Telegram/TelegramTile';
import { UserData } from '../../../util/UserData';
import { TopAmateur } from '../WidgetsLarge';
import Calendar from '../Calendar/Calendar';
import { ProfileStatus, WelcomeBack, MessengerStat } from '../WidgetsMedium';
import SalesStatistics from '../SalesStatistics/SalesStatistics';
import OnlineTimeAndUsers from '../OnlineTimeAndUsers/OnlineTimeAndUsers';
import IncomeSources from '../IncomeSources/IncomeSources';
import VXCashStatistics from '../VXCashStatistics/VXCashStatistics';
import { _ } from '../../../util/translate';
import { Spinner } from '../../../components';
import GraphIcon from '../../../atoms/Icon/VXCash/GraphIcon';
import GridTileItem from '../../../atoms/DashboardTile/GridTileItem';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import styled from '@emotion/styled';
import { useCanUseTelegram } from '../../../packages/Telegram';
import { VXGamesBanner } from '../WidgetsXL';
import NewVXMSwitch from '../Notifications/NewVXMSwitch';
import NewVXMSwitch2 from '../Notifications/NewVXMSwitch2';

interface OwnProps {
  loading: boolean;
  dashboard: {
    chatRating: number;
    chatHoldingTime: number;
    onlineTimeCurrentMonth: number;
    payableAmount: number;
  };
  userPrivileged: boolean;
  wasOnlineWithSSW: boolean;
  isOnlineWithSSW: boolean;
  picture: never | MediaPicture;
  album: never | MediaAlbum;
  commonProfileFillingPercentage: number;
  userData: UserData;
  hasTopAmateurSlots: number | boolean;
  tvshows: never;
  topAmateur: never;
  vxChallenge: {
    type: VXGChallengeTypeEnum;
    targetValue: number;
    userStatistic: {
      status: VXGChallengeUserStatusEnum;
      actualValue: number;
      isTocAccepted: boolean;
    };
    texts: { title: string; unit: string };
  };
  vxgamesAllowed: boolean;
}

const DashboardSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = OwnProps;

const dashboardSpinner = (size: string) => {
  return (
    <DashboardSpinner>
      <Spinner inline={true} size={size}/>
    </DashboardSpinner>
  );
};

const VXModelsDashboard: FunctionComponent<Props> = ({
                                                       loading,
                                                       dashboard,
                                                       userPrivileged,
                                                       wasOnlineWithSSW,
                                                       commonProfileFillingPercentage,
                                                       hasTopAmateurSlots,
                                                       tvshows,
                                                       topAmateur,
                                                       vxChallenge,
                                                       vxgamesAllowed,
                                                     }: OwnProps) => {

  const canUseTelegram = useCanUseTelegram();
  const showMessengerStat = userPrivileged;
  const tvshow = tvshows?.enabled;
  const isTopModel = hasTopAmateurSlots;

  // visible elements
  const b1VXGamesBannerVisible = vxChallenge?.userStatistic.isTocAccepted && vxgamesAllowed;

  const isFeatureFlagCoinsEnabled = true;

  const b2StatsVisible = !isFeatureFlagCoinsEnabled;
  const b3TelegramTileVisible = !isFeatureFlagCoinsEnabled && canUseTelegram;
  const b3MessengerStatTileVisible = !isFeatureFlagCoinsEnabled && wasOnlineWithSSW && showMessengerStat && !(isTopModel && tvshow);
  const b3WelcomeBackTileVisible = wasOnlineWithSSW && !showMessengerStat && !(isTopModel && tvshow);
  const b3TopAmateurTileVisible = wasOnlineWithSSW && isTopModel;
  const b3CalendarTileVisible = wasOnlineWithSSW && tvshow;
  const b3ProfileStatusTileVisible = !isFeatureFlagCoinsEnabled && (commonProfileFillingPercentage < 100);
  const b3VXModelsSwitchOldVisible = !isFeatureFlagCoinsEnabled;
  const b3VXModelsSwitchNewVisible = isFeatureFlagCoinsEnabled;
  const b4StatsVisible = !isFeatureFlagCoinsEnabled;

  // size calculation
  const b3TileCount = 0
    + (b3TelegramTileVisible ? 1 : 0)
    + (b3MessengerStatTileVisible ? 1 : 0)
    + (b3WelcomeBackTileVisible ? 1 : 0)
    + (b3TopAmateurTileVisible ? 1 : 0)
    + (b3CalendarTileVisible ? 1 : 0)
    + (b3ProfileStatusTileVisible ? 1 : 0);
  const b3XLCols = Math.max(12 / 4, 12 / b3TileCount); // max is 4 tiles per row
  const b4TilesPerLine = (12 / b3XLCols);
  let b3LastTileOffset = 12 - ((b3TileCount - 1) % b4TilesPerLine + 1) * b3XLCols;
  b3LastTileOffset = (b3LastTileOffset === 9 ? 3 : b3LastTileOffset);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="stretch">

        {/*** Block 1 ***/}

        {/* VXGames Banner */}
        {b1VXGamesBannerVisible && (
          <GridTileItem
            xs={12}
            noHeader={true}
            noSmallCardLayout={true}
            content={loading ? dashboardSpinner('s') :
              <VXGamesBanner vxChallenge={vxChallenge}/>
            }
          />
        )}

        {/*** Block 2 ***/}

        {/* Chat Ratings Tile */}
        {b2StatsVisible && (
          <GridTileItem
            xs={12}
            md={6}
            lg={3}
            content={loading ? dashboardSpinner('s') :
              <ChatRatings loading={false}
                           chatRating={dashboard.chatRating}
              />
            }
            noHeader={true}
          />
        )}

        {/* Chat Holding Times Tile */}
        {b2StatsVisible && (
          <GridTileItem
            xs={12}
            md={6}
            lg={3}
            content={loading ? dashboardSpinner('s') :
              <ChatHoldingTimes loading={false}
                                chatHoldingTime={dashboard.chatHoldingTime}
              />
            }
            noHeader={true}
          />
        )}

        {/* Online Time This Month Tile */}
        {b2StatsVisible && (
          <GridTileItem
            xs={12}
            md={6}
            lg={3}
            content={loading ? dashboardSpinner('s') :
              <OnlineTimeThisMonth loading={false}
                                   monthOnlineTime={dashboard.onlineTimeCurrentMonth}
              />
            }
            noHeader={true}
          />
        )}

        {/* Payable Amount Tile */}
        {b2StatsVisible && (
          <GridTileItem
            xs={12}
            md={6}
            lg={3}
            content={loading ? dashboardSpinner('s') :
              <Finance loading={false}
                       amount={userPrivileged && dashboard.payableAmount}
                       error={userPrivileged ? null : _('common:forbidden.text')}
              />
            }
            noHeader={true}
          />
        )}

        {/*** Block 3 ***/}

        {/* Telegram Tile */}
        {b3TelegramTileVisible && (
          <GridTileItem
            xl={b3XLCols}
            lg={6}
            md={6}
            xs={12}
            content={loading ? dashboardSpinner('m') :
              <TelegramTile loading={false}/>
            }
            title={_('dashboard:app.telegram.title')}
          />
        )}

        {/* Messenger Stat Tile */}
        {b3MessengerStatTileVisible && (
          <GridTileItem
            xl={b3XLCols}
            md={6}
            xs={12}
            title={_('dashboard:app.messengerStat.header')}
            content={loading ? dashboardSpinner('m') :
              <MessengerStat loading={false}/>
            }
          />
        )}

        {/* Welcome Back Tile */}
        {b3WelcomeBackTileVisible && (
          <GridTileItem
            xl={b3XLCols}
            md={6}
            xs={12}
            title={_('dashboard:app.welcomeBack.header')}
            content={loading ? dashboardSpinner('m') :
              <WelcomeBack loading={false}/>
            }
          />
        )}

        {/* Top Amateur Tile */}
        {b3TopAmateurTileVisible && (
          <GridTileItem
            xl={b3XLCols}
            md={6}
            xs={12}
            icon={<RecentActorsIcon fontSize="large"/>}
            title={_('dashboard:app.topAmateur.title')}
            content={loading ? dashboardSpinner('m') :
              <TopAmateur data={topAmateur}/>
            }
          />
        )}

        {/* Calendar Tile */}
        {b3CalendarTileVisible && (
          <GridTileItem
            xl={b3XLCols}
            md={6}
            xs={12}
            icon={<span className="icon-calendar"/>}
            title={_('dashboard:app.calendar.header')}
            content={loading ? dashboardSpinner('m') :
              <Calendar loading={false}
                        {...tvshows}
              />
            }
          />
        )}

        {/* Profile Status Tile */}
        {b3ProfileStatusTileVisible && (
          <GridTileItem
            xl={b3XLCols + b3LastTileOffset}
            md={6}
            xs={12}
            title={_('dashboard:app.profileStatus.header')}
            content={loading ? dashboardSpinner('m') :
              <ProfileStatus progress={commonProfileFillingPercentage}
                             loading={false}
              />
            }
          />
        )}

        {/* New VXM Switch Old and New Tile */}
        {b3VXModelsSwitchOldVisible && (
          <GridTileItem
            xl={6}
            xs={12}
            noCardLayout={true}
            content={loading ? dashboardSpinner('m') :
              <NewVXMSwitch />
            }
          />
        )}
        {b3VXModelsSwitchNewVisible && (
          <GridTileItem
            xs={12}
            noCardLayout={true}
            content={loading ? dashboardSpinner('m') :
              <NewVXMSwitch2 />
            }
          />
        )}

        {/*** Block 4 ***/}

        {/* Sales Statistics Tile */}
        {b4StatsVisible && (
          <GridTileItem
            xl={6}
            xs={12}
            icon={<span className="icon-charts"/>}
            centerItems={true}
            content={loading ? dashboardSpinner('m') :
              <SalesStatistics loading={false}
                               userAllowed={userPrivileged}
              />
            }
            title={_('dashboard:app.salesStatistics.header')}
          />
        )}

        {/* VXCash Statistics Tile */}
        {b4StatsVisible && (
          <GridTileItem
            xl={6}
            xs={12}
            icon={<GraphIcon/>}
            title={_('dashboard:app.vxCashStat.header')}
            content={loading ? dashboardSpinner('m') :
              <VXCashStatistics loading={false}
                                userAllowed={userPrivileged}
              />
            }
          />
        )}

        {/* Online Time and Users Tile */}
        {b4StatsVisible && (
          <GridTileItem
            xl={6}
            xs={12}
            icon={<span className="icon-clock"/>}
            title={_('dashboard:app.onlineTime.header')}
            content={loading ? dashboardSpinner('m') :
              <OnlineTimeAndUsers loading={false}/>
            }
          />
        )}

        {/* Income Sources Tile */}
        {b4StatsVisible && (
          <GridTileItem
            xl={6}
            xs={12}
            icon={<span className="icon-pie-chart"/>}
            title={_('dashboard:app.incomingSources.header')}
            centerItems={true}
            content={loading ? dashboardSpinner('m') :
              <IncomeSources loading={false}
                             userAllowed={userPrivileged}
              />
            }
          />
        )}

      </Grid>

    </Container>
  );
};

export default VXModelsDashboard;
