import { QueryLazyOptions, useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_DOCUMENTS_SKIPBACKSIDEUPLOAD } from '../../../graphql/VXModels/mutations';
import { DocumentUploadAssistantTypeEnum, Mutation } from '../../../graphql/VXModels/types';
import { stepEnum } from '@vxmodels/assistant-react/dist/types/types';

interface Props {
  getCurrentStep: (
    options?: QueryLazyOptions<{ flowType: DocumentUploadAssistantTypeEnum }> | undefined
  ) => void;
  documentType: stepEnum;
  userId: string;
}

interface OnSkip {
  onSkipStep: () => void;
}

const useSkipStep = ({ documentType, userId, getCurrentStep }: Props): OnSkip => {
  const [skipStep] = useMutation<Mutation>(MUTATION_MODEL_DOCUMENTS_SKIPBACKSIDEUPLOAD, {
    onCompleted: () => getCurrentStep(),
  });

  const onSkipStep = async (): Promise<void> => {
    await skipStep({
      variables: { type: documentType, userId: userId },
    });
  };

  return { onSkipStep };
};

export default useSkipStep;
