import React, { FC } from 'react';
import BaseNumberFormat, { NumberFormatProps as BaseNumberFormatProps } from 'react-number-format';
import { useLang } from '../../util/AppState';

type NumberFormatProps = Omit<BaseNumberFormatProps, 'lang'>;

const baseLangConfig = {
  thousandSeparator: false,
  decimalScale: 2,
};

const langConfig = {
  de: { ...baseLangConfig, decimalSeparator: ',' },
  en: { ...baseLangConfig, decimalSeparator: '.' },
};

const NumberFormat: FC<NumberFormatProps> = (props) => {
  const [lang] = useLang();
  const langProps = langConfig[lang] || langConfig.en;
  return <BaseNumberFormat lang={lang} {...langProps} {...props} />;
};

export default NumberFormat;
