// var Dispatcher = require('flux').Dispatcher;
// var Phx = global.Phx || {};
// Phx.Dispatcher = Phx.Dispatcher || {};
// Phx.Dispatcher.Camtool = new Dispatcher;
//
// module.exports = Phx.Dispatcher.Camtool;

import { Dispatcher } from 'flux';

class DispatcherClass extends Dispatcher {
  handleViewAction(action) {
    this.dispatch({
      source: 'VIEW_ACTION',
      action: action,
    });
  }

  handleServerAction(action) {
    this.dispatch({
      source: 'SERVER_ACTION',
      action: action,
    });
  }
}

const ServerDispatcher = new DispatcherClass();

export default ServerDispatcher;
