import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { vxOutline } from '../../../../../atoms/Icon/libraries/svg';

const VisitXProfileItem: FC = () => {
  return (
    <LeftMenuItem
      title={_('navigation:main.profile.visitx')}
      icon={vxOutline}
      uri="/onlineprofile/visitx"
    />
  );
};

export default VisitXProfileItem;
