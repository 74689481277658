import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';

class Tooltip extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    this.positionClass = 'tooltip__frame--' + this.props.position;
    return (
      <aside className={`tooltip ${this.props.className}`}>
        <div
          className={`
            tooltip__icon
            ${this.props.icon}
            ${!this.props.icon && 'tooltip__icon--standart'}`}
        />
        <article className={`tooltip__frame ${this.positionClass}`}>
          <header>{this.props.header}</header>
          {this.props.text && <pre>{this.props.text}</pre>}

          {!this.props.text && (
            <div className="tooltip__frame__htmlcontent">
              <div className="tooltip__frame__htmlcontent__column">{this.props.children}</div>
            </div>
          )}

          <footer>{this.props.footer}</footer>
        </article>
      </aside>
    );
  }
}

Tooltip.propTypes = {
  position: PropTypes.oneOf(['top-right', 'right', 'top', 'bottom']),
  header: PropTypes.string,
  text: PropTypes.string,
  footer: PropTypes.string,
};

Tooltip.defaultProps = {
  position: 'right',
  header: '',
  text: '',
  footer: '',
};

export default Tooltip;
export { Tooltip };
