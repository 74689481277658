import React from 'react';
import PropTypes from 'prop-types';
import AbstractFormComponent from '../AbstractFormComponent';
import isEqual from 'lodash/isEqual';
import { translateOptions } from '../../../atoms/Select/utils';

const objectAssign = require('object-assign');
require('@babel/polyfill');

function compare(a, b) {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
}

function prepareOptions(props) {
  let options = translateOptions(props.options);
  if (props.sortOptions) {
    options.sort(compare);
  }
  return options;
}

class AbstractFormSingleOptionComponent extends AbstractFormComponent {
  constructor(props) {
    super(props);
    this.options = prepareOptions(props);
  }

  componentWillMount() {
    this.options = prepareOptions(this.props);
    super.componentWillMount();
  }

  componentWillReceiveProps(nextProps) {
    const optionsChanged = !isEqual(this.props.options, nextProps.options);
    if (optionsChanged) {
      this.options = prepareOptions(nextProps);
    }
    return super.componentWillReceiveProps(nextProps);
  }

  isOptionSelected(option) {
    return option.value === this.getValue();
  }

  isOptionDisabled(option) {
    return this.props.disabledOptions.indexOf(option.value) !== -1;
  }
}

//AbstractFormSingleOptionComponent.propTypes = Object.assign(AbstractFormComponent.propTypes || {}, {
AbstractFormSingleOptionComponent.propTypes = objectAssign(AbstractFormComponent.propTypes || {}, {
  sortOptions: PropTypes.bool,
  disabledOptions: PropTypes.array,
});

//AbstractFormSingleOptionComponent.defaultProps = Object.assign(AbstractFormComponent.defaultProps || {}, {
AbstractFormSingleOptionComponent.defaultProps = objectAssign(
  AbstractFormComponent.defaultProps || {},
  {
    sortOptions: false,
    disabledOptions: [],
  }
);

export default AbstractFormSingleOptionComponent;
