import React, { FC } from 'react';
import styled from '@emotion/styled';
// Outside Wizard
import { GRAY_2, GRAY_3, BLUE, WHITE, WHITE_2 } from '../../../../camtool-styles';
import { AboContentType } from '../../types';
import { getIconByType } from '../../icons';

const Tile = styled.div`
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 208px;

  &:not(:last-of-type) {
    margin-right: 19px;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  height: 232px;
  border: 1px solid ${GRAY_3};
  flex-direction: column;
  align-items: stretch;
  padding: 8px;
  fill: #d9ddde;
  transition: color 250ms, border-color 250ms, fill 250ms;
  background: ${(props): string =>
    props.disabled ? `${WHITE_2} 0% 0% no-repeat padding-box;` : WHITE};
  ${(props): string => (props.disabled ? 'pointer-events: none;' : '')};
  &:hover {
    border-color: ${BLUE};
    color: ${BLUE};
    fill: ${BLUE};
    cursor: pointer;
    div:first-of-type {
      border-color: transparent;
      color: ${BLUE};
    }
    p {
      color: ${BLUE};
    }
    span {
      color: ${BLUE};
    }
  }
`;

const StyledDiv = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  background-repeat: no-repeat;
  background-position: center;
`;

interface AboContentButtonProps {
  onClick: (type: AboContentType) => void;
  type: AboContentType;
  title: string;
  description: string;
  disabled?: boolean;
  className?: string;
}

const AboContentButton: FC<AboContentButtonProps> = (props) => {
  const { onClick, type, title, description, disabled = false, className } = props;
  const handleClick = (): void => onClick(type);

  return (
    <Tile className={className}>
      <StyledButton disabled={disabled} onClick={handleClick}>
        <StyledDiv>{getIconByType(type)}</StyledDiv>

        <p css={{ textAlign: 'center', fontWeight: 'normal', fontSize: 16, marginTop: 4 }}>
          {title}
        </p>

        <p css={{ textAlign: 'center', marginTop: 4, color: GRAY_2 }}>({description})</p>
      </StyledButton>
    </Tile>
  );
};

export default AboContentButton;
