import React, { Fragment, FC } from 'react';
import { Comments, Spinner } from '../../../../components';
import NavPictureView from '../NavPictureView/NavPictureView';
import { extractTitle } from './index';
import { useQuery } from '@apollo/react-hooks';
import { MediaAlbum, Query } from '../../../../graphql/VXModels/types';
import { BLACK } from '../../../../camtool-styles';
import { MODEL_PHOTOS_ALBUMS_FEEDBACK } from '../../../../graphql/VXModels/queries';

interface Props {
  album: MediaAlbum;
  editable: boolean;
}

const AlbumComment: FC<Props> = ({ album, editable }) => {
  const { loading, data } = useQuery<Query>(MODEL_PHOTOS_ALBUMS_FEEDBACK, {
    variables: { umaId: Number(album.id) },
  });

  if (!album) return <Spinner />;

  const albumFromQuery = data?.model?.photoAlbums?.albums
    ? data.model.photoAlbums.albums[0]
    : { dislikes: 0, likes: 0, feedbacksItems: [] };
  const modelAvatar = data?.model?.avatar?.picture?.url;

  const showMenu = album && album.status !== 'rejected' && editable;

  return loading ? (
    <Spinner />
  ) : (
    <div css={{ flex: 1, flexDirection: 'column', color: BLACK }}>
      {showMenu && (
        <Fragment>
          <NavPictureView albumId={album.id} albumType={album.albumType} />
        </Fragment>
      )}

      <Comments
        umaId={album.id}
        likes={albumFromQuery.likes}
        groupedLikes={albumFromQuery.groupedLikes}
        feedbacksItems={albumFromQuery.feedbacksItems}
        modelAvatar={modelAvatar}
        type={'photo'}
        contentTitle={extractTitle(album.albumType, album.titles)}
      />
    </div>
  );
};

export default AlbumComment;
