import { MediaTypeEnum } from '../types';
import minimatch from 'minimatch';

export const mediaTypeMimeTypeMapping: Record<MediaTypeEnum, string | undefined> = {
  [MediaTypeEnum.picture]: 'image/*',
  [MediaTypeEnum.video]: 'video/*',
  [MediaTypeEnum.all]: undefined,
};

export function isMimeType(file: File, mimeType: string | undefined = '*'): boolean {
  return minimatch(file.type, mimeType);
}

export function getMimeTypeByMediaType(mediaType: MediaTypeEnum): string | undefined {
  return mediaTypeMimeTypeMapping[mediaType] || undefined;
}

export function getMediaTypeByFile(file: File): MediaTypeEnum {
  return (
    (Object.keys(mediaTypeMimeTypeMapping) as MediaTypeEnum[]).find((mediaType) =>
      isMimeType(file, getMimeTypeByMediaType(mediaType))
    ) || MediaTypeEnum.all
  );
}
