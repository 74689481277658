import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_TERMS_ACCEPT_V2 } from '../../graphql/VXModels/mutations';
import { Button, InputField } from '../../atoms';
import { _ } from '../../util/translate';
import { DARK_GRAY, GRAY_2, RED } from '../../camtool-styles';
import FancyCheckbox from '../../atoms/FancyCheckbox/FancyCheckbox';
import { useHasRole } from '../../util/UserData';
import { SecurityRole } from '../../graphql/VXModels/types';

const Container = styled.div`
  flex-direction: column;
  align-items: center;
`;

const StyledSpan = styled.span`
  font-size: 16px;
  line-height: 20px;
  color: ${DARK_GRAY};
  margin-left: 8px;
  text-align: center;
  font-weight: 300;
  flex: 0 1 auto;

  &,
  & span {
    display: inline;
    hyphens: auto;
  }
`;

const StyledLink = styled.span`
  text-decoration: underline;

  &:hover {
    color: ${GRAY_2};
  }
`;

const PasswordPrompt = styled.div`
  flex-direction: column;
  width: 100%;
  max-width: 370px;
  margin-bottom: ${props =>
    props.displayGeneralError
      ? 19
      : (props.smallMargins ? 40 : 55) - (props.displayInvalidPasswordMessage ? 19 : 0)}px;
`;

const PasswordTitle = styled.div`
  color: ${DARK_GRAY};
  margin: 0 3px 7px;
  font-weight: bold;
  font-size: 16px;
`;

const InvalidPasswordMessage = styled.div`
  font-size: 12px;
  color: ${RED};
  font-style: italic;
  margin: 5px 3px 0;
`;

const ErrorMessage = styled.div`
  color: ${RED};
  font-size: 14px;
  margin-bottom: 19px;
`;

const StyledCheckbox = styled(FancyCheckbox)`
  margin-bottom: ${props =>
    props.withPassword ? '50px' : props.displayGeneralError ? '15px' : '46px'};
`;

interface IProps {
  type: string;
  cmsArticleName: string;
  hideCheckbox?: boolean;
  requirePassword?: boolean;
  smallMargins?: boolean;
  className?: string;
  onCheckboxLinkClick?: () => void;
}

const TermsAcceptForm: FC<IProps> = ({
  type,
  cmsArticleName,
  children,
  hideCheckbox = false,
  requirePassword = false,
  smallMargins = false,
  className,
  onCheckboxLinkClick,
  ...buttonProps
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [hidePasswordErrors, setHidePasswordErrors] = useState<boolean>(false);
  const isUserMaster = useHasRole(SecurityRole.VX_USER_MASTER);

  const onCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(event.target.checked);
  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHidePasswordErrors(true);
    setPassword(event.target.value);
  };

  const [accept, { data, loading, error }] = useMutation(MUTATION_MODEL_TERMS_ACCEPT_V2, {
    variables: {
      type,
      password: password || undefined,
    },
  });

  const onSubmit = async () => {
    const { data: mutationData, errors } = await accept();

    if (errors || !mutationData?.model?.terms?.accept_v2?.wasSuccessful) {
      setHidePasswordErrors(false);
    }
  };

  const acceptData = data?.model?.terms?.accept_v2;
  const isPasswordInvalid = !!acceptData?.isPasswordInvalid;
  const displayInvalidPasswordMessage = isPasswordInvalid && !hidePasswordErrors;
  const displayGeneralError =
    !loading &&
    (!!error ||
      (!!acceptData && !acceptData.wasSuccessful && (!isPasswordInvalid || !requirePassword)));

  const acceptTextEnd = _('modalbox:terms.acceptTextEnd');

  return (
    <Container className={className}>
      {!hideCheckbox && (
        <StyledCheckbox
          checked={checked}
          type="checkbox"
          onChange={onCheckedChange}
          withPassword={requirePassword}
          displayGeneralError={displayGeneralError}
        >
          <StyledSpan>
            {_(`modalbox:${cmsArticleName}.acceptText1`)}{' '}
            <StyledLink onClick={() => onCheckboxLinkClick?.()}>
              {_(`modalbox:${cmsArticleName}.acceptText2`)}
            </StyledLink>
            {acceptTextEnd !== '.' ? ` ${acceptTextEnd}` : acceptTextEnd}
          </StyledSpan>
        </StyledCheckbox>
      )}
      {!!requirePassword && (
        <PasswordPrompt
          smallMargins={smallMargins}
          displayGeneralError={displayGeneralError}
          displayInvalidPasswordMessage={displayInvalidPasswordMessage}
        >
          <PasswordTitle>
            {isUserMaster
              ? _('modalbox:terms.passwordConfirmation.titleMaster')
              : _('modalbox:terms.passwordConfirmation.title')}
          </PasswordTitle>
          <InputField
            value={password}
            onChange={onPasswordChange}
            type="password"
            autoComplete="current-password"
          />
          {displayInvalidPasswordMessage && (
            <InvalidPasswordMessage>
              {_('modalbox:terms.passwordConfirmation.errors.invalidPassword')}
            </InvalidPasswordMessage>
          )}
        </PasswordPrompt>
      )}
      {displayGeneralError && <ErrorMessage>{_('common:error.generic')}</ErrorMessage>}
      <Button
        theme="blue"
        size="lg"
        onClick={onSubmit}
        loading={loading}
        disabled={loading || (!hideCheckbox && !checked) || (requirePassword && !password)}
        {...buttonProps}
      >
        {children || _(`modalbox:terms.button.accept`)}
      </Button>
    </Container>
  );
};

export default TermsAcceptForm;
