import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDuration } from '../../util/Formatter';

const FormatDuration = ({ className, value }) => (
  <span className={className}>{getFormattedDuration(value)}</span>
);

FormatDuration.defaultProps = {
  className: '',
  value: 0,
};

FormatDuration.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
};

export default FormatDuration;
export { FormatDuration };
