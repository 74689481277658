import React, { FC, useReducer } from 'react';
import { ApolloClient } from 'apollo-client';
// Inside Wizard
import { BenefitsWizardRoute, IBenefitsCreateAction, IBenefitsCreateState } from '../types';
import { PageContainer, Section, SectionContent } from '../styles';
import BreadCrumb from './BreadCrumb';
import StepBenefitsType from './StepBenefitsType/StepBenefitsType';
import StepBenefitsContent from './StepBenefitsContent/StepBenefitsContent';
import StepBenefitsDetails from './StepBenefitsDetails/StepBenefitsDetails';
import StepBenefitsDone from './StepBenefitsDone/StepBenefitsDone';
import { _ } from '../../../util/translate';
import { BonusCodes } from '../../../graphql/VXModels/types';

const initialState: IBenefitsCreateState = {
  config: { media: { max: 10 } },
  history: ['STEP_VOUCHER_TYPE'],
  platforms: [],
  voucherContent: [],
  code: '',
};

function reducer(state: IBenefitsCreateState, action: IBenefitsCreateAction): IBenefitsCreateState {
  switch (action.type) {
    case 'ADD_VOUCHER_CONTENT':
      // Add content only if it hasn't been added yet
      if (
        state.voucherContent.some(
          (content) => content.contentId === action.payload.voucherContent.contentId
        )
      ) {
        console.warn('This item has already been added.');
        return state;
      } else {
        return {
          ...state,
          voucherContent: [...state.voucherContent, action.payload.voucherContent],
        };
      }
    case 'REMOVE_VOUCHER_CONTENT':
      return {
        ...state,
        voucherContent: state.voucherContent.filter(
          (content) => content.contentId !== action.payload.voucherContent.contentId
        ),
      };
    case 'SET_CURRENT_VOUCHER_TYPE':
      const { currentVoucherType, platforms } = action.payload;
      return { ...state, currentVoucherType, platforms };
    case 'CHANGE_EXISTING_BENEFIT_VALUES':
      return {
        ...state,
        voucherContent: state.voucherContent.map((content) => ({
          ...content,
          ...(content.voucherType === action.payload.voucherType ? action.payload.values : {}),
        })),
      };
    case 'CREATE_VOUCHER':
      return { ...state, ...action.payload };
    case 'GO_TO_STEP_VOUCHER_TYPE':
      return { ...state, history: [...state.history, 'STEP_VOUCHER_TYPE'] };
    case 'GO_TO_STEP_VOUCHER_CONTENT':
      return { ...state, history: [...state.history, 'STEP_VOUCHER_CONTENT'] };
    case 'GO_TO_STEP_VOUCHER_DETAILS':
      return { ...state, history: [...state.history, 'STEP_VOUCHER_DETAILS'] };
    case 'GO_TO_STEP_VOUCHER_DONE':
      return { ...state, history: [...state.history, 'STEP_VOUCHER_DONE'] };
    case 'GO_BACK':
      // Reset store if the page the use will end up is `STEP_VOUCHER_TYPE`.
      // So we prevent the user from adding multiple content to a voucher
      if (
        state.history[state.history.length - 2] === 'STEP_VOUCHER_TYPE' &&
        state.voucherContent.length > 0
      ) {
        const agreed = window.confirm(_('benefits:voucher.alerts.changesReset'));

        if (agreed) {
          // Go back and reset store
          return initialState;
        } else {
          // Don't do anything
          return state;
        }
      }
      // Go back
      return { ...state, history: state.history.slice(0, state.history.length - 1) };
    case 'CREATE_NEW_VOUCHER':
      return initialState;
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BenefitsCreate: FC<{
  bonusCodeClient: ApolloClient<Record<string, never>>;
  availableContentMinAmountsArray: BonusCodes['availableContentMinAmounts'];
}> = ({ bonusCodeClient, availableContentMinAmountsArray }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const minAmountsArray = availableContentMinAmountsArray || [];

  function getCurrentRoute(): BenefitsWizardRoute {
    if (state.history && state.history.length > 0) {
      return state.history[state.history.length - 1];
    } else {
      return 'STEP_VOUCHER_TYPE';
    }
  }

  return (
    <PageContainer>
      <Section>
        <BreadCrumb currentRoute={getCurrentRoute()} />

        <SectionContent>
          {getCurrentRoute() === 'STEP_VOUCHER_TYPE' && (
            <StepBenefitsType dispatch={dispatch} state={state} bonusCodeClient={bonusCodeClient} />
          )}

          {getCurrentRoute() === 'STEP_VOUCHER_CONTENT' && (
            <StepBenefitsContent
              dispatch={dispatch}
              state={state}
              bonusCodeClient={bonusCodeClient}
              availableContentMinAmountsArray={minAmountsArray}
            />
          )}

          {getCurrentRoute() === 'STEP_VOUCHER_DETAILS' && (
            <StepBenefitsDetails
              dispatch={dispatch}
              state={state}
              bonusCodeClient={bonusCodeClient}
            />
          )}

          {getCurrentRoute() === 'STEP_VOUCHER_DONE' && (
            <StepBenefitsDone dispatch={dispatch} state={state} bonusCodeClient={bonusCodeClient} />
          )}
        </SectionContent>
      </Section>
    </PageContainer>
  );
};

export default BenefitsCreate;
