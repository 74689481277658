import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ApolloClient from 'apollo-client';
import GenericModalBoxLayout, {
  BREAKPOINT_SMALL,
} from '../../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { BLACK_1 } from '../../../../../camtool-styles';
import { Code, Redemption } from '../../../types';
import { _ } from '../../../../../util/translate';
import ModalBonusCodeHeader from './ModalBonusCodeHeader';
import ModalBonusDeactivateCheckbox from './ModalBonusDeactivateCheckbox/ModalBonusDeactivateCheckbox';
import ModalBonusButtonRow from './ModalBonusButtonRow/ModalBonusButtonRow';
import ModalBonusRedemptionsList from './ModalBonusRedemptionsList';
import useBonusCode from '../../../hooks/useBonusCode';
import { useParams, useHistory } from 'react-router-dom';
import LinkBox from '../../../../../components/LinkBox/LinkBox';

interface Props {
  bonusCodeClient: ApolloClient<Record<string, unknown>>;
}

const InnerContainer = styled.div`
  flex: none;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px 32px 32px;
  color: ${BLACK_1};
  width: 100%;
  height: 700px;
  flex: 0 0 auto;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-height: 100vh;
    height: auto;
  }
`;
/*
######################
  This code has been more or less copied over from ModalAboDetails - if you fix/change something here also check that place!
######################
*/
const ModalBonusCodeDetails: FC<Props> = ({ bonusCodeClient }) => {
  const history = useHistory();
  const [bonusCode, setBonusCode] = useState<Code>({} as Code);
  const { code }: { code: string } = useParams();
  const { getSingleCode, deactivateCode } = useBonusCode(bonusCodeClient);
  const [checked, setChecked] = useState<boolean>(false);
  const onChange = (): void => setChecked(!checked);
  const onClose = () => {
    history.go(-1);
  };
  const deactivateCodeCallback = async (code: string) => {
    await deactivateCode(code);
    history.go(-1);
  };

  const setCode = async () => {
    const singleCode = await getSingleCode(code);
    setBonusCode(singleCode);
  };

  const items: Array<Redemption> = bonusCode?.redemptions?.items || [];
  useEffect(() => {
    setCode();
  }, []);

  return Object.keys(bonusCode).length > 0 ? (
    <GenericModalBoxLayout name="code-details" onClose={onClose} maxWidth={750}>
      <InnerContainer>
        <ModalBonusCodeHeader code={bonusCode} />
        {code && bonusCode.status === 'active' && (
          <LinkBox
            iconColor="#fff"
            iconBgColor="#ff4d3c"
            link={code}
            label={_('voucher:voucher.title')}
            buttonLabelCopy={_('voucher:voucher.codeCopy')}
            buttonLabelCopied={_('voucher:voucher.codeCopied')}
          />
        )}
        <ModalBonusDeactivateCheckbox
          name="cancelCodeSubscriptions"
          title={_('benefits:voucher.archive.modal.checkbox.title')}
          subtitle={_('benefits:voucher.archive.modal.checkbox.subtitle')}
          onChange={onChange}
          checked={checked}
        />
        <ModalBonusRedemptionsList instances={items} />
        <ModalBonusButtonRow
          checked={checked}
          deactivateCode={deactivateCodeCallback}
          onClose={onClose}
          code={bonusCode}
        />
      </InnerContainer>
    </GenericModalBoxLayout>
  ) : null;
};

export default ModalBonusCodeDetails;
