/* eslint-disable react/no-unknown-property*/
import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import {
  MAPPING_PICTURE_TYPE,
  MediaManagementActionCreators,
  PICTURE_TYPES,
} from '../../stores/MediaManagement/MediaManagement';
import { MEDIA_PICTURE_UPLOAD_MIN_HEIGHT, MEDIA_PICTURE_UPLOAD_MIN_WIDTH } from '../../config';
import cx from 'classnames';
import PicturePool from './PicturePool/PicturePool';
import VideoPreviewPool from './VideoPreviewPool/VideoPreviewPool';
import Browse from './Browse/Browse';
import Webcam from './Webcam/Webcam';
import Editor from './Editor/Editor';
import { _ } from '../../util/translate';
import { withAppState } from '../../util/AppState';
import { log } from '../../util';
import { getPictureUploadUrl } from '../../packages/UploadManager/util/genericUpload';

const VALID_TABS = {
  picture: ['pool', 'browse' /*, 'webcam'*/],
  videoPreview: ['videoPreviewPool', 'pool', 'browse'],
};

function getPoolTitle(type) {
  switch (type) {
    case 'picture':
      return _('mediamanagement:singleuploader.title.unusedPictures');
    case 'videoPreview':
      return _('mediamanagement:singleuploader.title.videoPool');
    default:
      '';
  }
}

function getValidMimeTypes(type) {
  switch (type) {
    case 'picture':
      return 'image/*';
    // return JsUploaderPictureStore.state.options.allowedFileExtensions;
    case 'videoPreview':
      return 'image/*';
    // return JsUploaderVideoStore.state.options.allowedFileExtensions;
    default:
      return '*';
  }
}

function urlDataToFile(urlData) {
  // Tutorial:
  // http://blog.devteaminc.co/posting-a-canvas-image-to-twitter-using-oauth/
  // url should look like this 'data:image/png;base64...' instead of 'blob:http...'

  const blobBin = atob(urlData.split(',')[1]);
  const binary = [];

  for (let i = 0; i < blobBin.length; i++) {
    binary.push(blobBin.charCodeAt(i));
  }
  const file = new Blob([new Uint8Array(binary)], { type: 'image/png' });

  file.extension = 'png';
  return file;
}

function getWidth(height, ratio) {
  return Math.ceil((height * ratio) / 10) * 10;
}

function getMinSizes(type) {
  switch (type) {
    case 'landscape_4_3':
      return {
        width: getWidth(MEDIA_PICTURE_UPLOAD_MIN_HEIGHT, 4 / 3),
        height: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
      };
    case 'landscape_16_9':
      return {
        width: getWidth(MEDIA_PICTURE_UPLOAD_MIN_HEIGHT, 16 / 9),
        height: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
      };
    case 'landscape_25_10':
      return {
        width: getWidth(MEDIA_PICTURE_UPLOAD_MIN_HEIGHT, 25 / 10),
        height: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
      };
    case 'circle':
      return {
        width: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
        height: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
      };
    case 'vxhpmediapool':
      return {
        width: 10,
        height: 10,
      };
    default:
      return {
        width: MEDIA_PICTURE_UPLOAD_MIN_WIDTH,
        height: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
      };
  }
}

class MediaSelector extends AbstractComponent {
  constructor(props) {
    super(props);
    this.selectedTab = {};
    this.state = {
      isLoading: true,
      selectedTab: props.initialTab,
      selectedItem: {},
      uploadedItem: {},
    };
    this.poolTitle = getPoolTitle(this.props.type);
    this.validMimeTypes = getValidMimeTypes(this.props.type);

    this.getPicturePoolFilter = this.getPicturePoolFilter.bind(this);
  }

  onSubmit(data) {
    switch (this.props.type) {
      case 'picture':
      case 'videoPreview':
        if (this.props.editor && data.source !== 'editor') {
          this.setState({
            isLoading: false,
            selectedTab: 'editor',
            selectedItem: data,
          });
        } else {
          this.handleSubmit(data);
        }
        break;
    }
  }

  handleSubmit(data) {
    if (data.selectionType === 'urlData') {
      const file = urlDataToFile(data.urlData);
      const sourceAlbumId = data.sourcePicture ? data.sourcePicture.albumId : 0;
      const sourcePictureId = data.sourcePicture ? data.sourcePicture.pictureId : 0;

      this.setState({ isLoading: true }, () => {
        const targetPosition = this.props.targetParams.position;
        const data = {
          targetUmaId: this.props.targetUmaId,
          pictureType: this.props.pictureType,
          targetPosition: !targetPosition && targetPosition !== 0 ? null : targetPosition,
          file,
          sourceAlbumId,
          sourcePictureId,
        };

        this.props.onBeforeUploadPicture(data, (pictureData) => {
          const sessionId = this.props.appState[0].sessionId;

          //GET UPLOAD URL
          getPictureUploadUrl(
            pictureData.targetUmaId,
            MAPPING_PICTURE_TYPE[pictureData.pictureType],
            this.props.fsk
          )
            .then(
              function (uploadUrl) {
                MediaManagementActionCreators.uploadPicture(
                  uploadUrl,
                  sessionId,
                  pictureData.targetUmaId,
                  pictureData.pictureType,
                  pictureData.targetPosition,
                  pictureData.file,
                  pictureData.sourceAlbumId,
                  pictureData.sourcePictureId,
                  (picture) => {
                    // onSuccess
                    picture = Object.assign(picture, this.props.targetParams);
                    picture.pictureType = this.props.pictureType;
                    this.setState({ isLoading: false }, () => {
                      this.props.onSubmit([picture]);
                      this.props.onClose();
                    });
                  },
                  (errorType) => {
                    // onError
                    log('error', 'onBeforeUploadPicture failed:' + errorType, {
                      context: 'Uploader',
                    });
                    alert(
                      `${_('mediamanagement:upload.error.errorDuringUpload')}: ${_(
                        `mediamanagement:upload.error.${errorType}`
                      )}`
                    );
                    //close modal on error
                    this.setState({ isLoading: false }, () => {
                      this.props.onSubmit(data.pictures);
                      this.props.onClose();
                    });
                  }
                );
              }.bind(this)
            )
            .catch((err) => console.log('Media-Selector: Get Upload Url -> Error: ', err));
        });
      });
    } else if (data.selectionType === 'picture') {
      for (let picture of data.pictures) {
        picture = Object.assign(picture, this.props.targetParams);
        picture.pictureType = this.props.pictureType;
      }

      this.setState({ isLoading: false }, () => {
        this.props.onSubmit(data.pictures);
        this.props.onClose();
      });
    }
  }

  onSelectTab(tab) {
    this.setState({
      isLoading: false,
      selectedTab: tab,
      uploadedPicture: {},
    });
  }

  showEditor(picture) {
    this.setState({
      isLoading: false,
      selectedTab: 'editor',
      selectedPicture: picture,
    });
  }

  getTitle() {
    switch (this.state.selectedTab) {
      case 'editor':
        return _('mediamanagement:singleuploader.title.cropImage');
      default:
        return _('mediamanagement:singleuploader.title.selectSource');
    }
  }

  isSelected(tab) {
    return this.state.selectedTab === tab;
  }

  showTab(tab) {
    return VALID_TABS[this.props.type].indexOf(tab) !== -1;
  }

  selectPoolTab() {
    if (this.props.initialTab === 'videoPreviewPool') {
      this.onSelectTab('videoPreviewPool');
    } else {
      this.onSelectTab('pool');
    }
  }

  getPicturePoolFilter() {
    const minSizes = getMinSizes(this.props.editor);
    return (picture) => {
      const hasMinSize = picture.width >= minSizes.width && picture.height >= minSizes.height;
      if (!hasMinSize) {
        picture.isTooSmall = true;
      }
      if (this.props.filteredFsk) {
        return this.props.picturePoolFilter(picture) && hasMinSize && picture.fsk <= this.props.filteredFsk;
      }
      else {
        return this.props.picturePoolFilter(picture) && hasMinSize;
      }
    };
  }

  render() {
    // console.log(this.__proto__.constructor.name, 'render', this.state);
    this.showEditor = this.state.selectedTab === 'editor';
    this.showNavigation = !this.showEditor;
    this.minSizes = getMinSizes(
      this.props.pictureType === 'vxhpmediapool' ? 'vxhpmediapool' : this.props.editor
    );
    this.browseLabel = _(`mediamanagement:multiuploader.uploadfield.title${this.props.type}`);
    this.browseSubtitle = _(`mediamanagement:multiuploader.uploadfield.subtitle${this.props.type}`);

    return (
      <div className="modalgrid__box">
        <div
          className="modalgrid__box__header"
          css={{ backgroundColor: '#3c3d3e', color: '#ffffff' }}
        >
          <div className="icon-picture" />
          <div>{this.getTitle()}</div>
          <div className="seperator" />
          <div className="icon-remove options" onClick={this.props.onClose} />
        </div>

        <div className="modalgrid__box__content min-height--0">
          <div className="modalgrid__box__content__column" css={{ padding: 0, maxWidth: '100%' }}>
            {this.showNavigation && (
              <div className="single-uploader-nav">
                {this.showTab('pool') && (
                  <div
                    className={cx({
                      'single-uploader-nav-item': true,
                      active: this.isSelected('pool'),
                    })}
                    onClick={this.onSelectTab.bind(this, 'pool')}
                  >
                    <div>
                      <div className="icon-pictures icon" />
                      <div className="title">
                        {_('mediamanagement:singleuploader.title.unusedPictures')}
                      </div>
                    </div>
                  </div>
                )}
                {this.showTab('videoPreviewPool') && (
                  <div
                    className={cx({
                      'single-uploader-nav-item': true,
                      active: this.isSelected('videoPreviewPool'),
                    })}
                    onClick={this.onSelectTab.bind(this, 'videoPreviewPool')}
                  >
                    <div>
                      <div className="icon-pictures icon" />
                      <div className="title">
                        {_('mediamanagement:singleuploader.title.videoPool')}
                      </div>
                    </div>
                  </div>
                )}
                {this.showTab('browse') && (
                  <div
                    className={cx({
                      'single-uploader-nav-item': true,
                      active: this.isSelected('browse'),
                    })}
                    onClick={this.onSelectTab.bind(this, 'browse')}
                  >
                    <div>
                      <div className="icon-display icon" />
                      <div className="title">
                        {_('mediamanagement:singleuploader.title.fromComputer')}
                      </div>
                    </div>
                  </div>
                )}
                {this.showTab('webcam') && (
                  <div
                    className={cx({
                      'single-uploader-nav-item': true,
                      active: this.isSelected('webcam'),
                    })}
                    onClick={this.onSelectTab.bind(this, 'webcam')}
                  >
                    <div>
                      <div className="icon-webcam icon" />
                      <div className="title">
                        {_('mediamanagement:singleuploader.title.webcam')}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.isSelected('pool') && (
              <PicturePool
                onSubmit={this.onSubmit}
                isLoading={this.state.isLoading}
                filter={this.getPicturePoolFilter()}
                allowMultipleSelection={this.props.allowMultipleSelection}
              />
            )}

            {this.isSelected('videoPreviewPool') && (
              <VideoPreviewPool
                data={this.props.data}
                onSubmit={this.onSubmit}
                isLoading={this.state.isLoading}
                filter={this.getPicturePoolFilter()}
              />
            )}

            {this.isSelected('browse') && (
              <Browse
                onSubmit={this.onSubmit}
                isLoading={this.state.isLoading}
                acceptedMimeTypes={this.validMimeTypes}
                minWidth={this.minSizes.width}
                minHeight={this.minSizes.height}
                label={this.browseLabel}
                subtitle={this.browseSubtitle}
              />
            )}

            {this.isSelected('webcam') && (
              <Webcam onSubmit={this.onSubmit} isLoading={this.state.isLoading} />
            )}

            {/*PicEditor*/}
            {this.isSelected('editor') && (
              <Editor
                data={this.state.selectedItem}
                type={this.props.editor}
                onSubmit={this.onSubmit}
                isLoading={this.state.isLoading}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

MediaSelector.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  targetUmaId: PropTypes.number.isRequired,
  targetParams: PropTypes.shape({ position: PropTypes.number }),
  pictureType: PropTypes.oneOf(PICTURE_TYPES),
  type: PropTypes.oneOf(['picture', 'videoPreview']),
  initialTab: PropTypes.oneOf(['pool', 'browse', 'webcam', 'videoPreviewPool']),
  editor: PropTypes.oneOf(['circle', 'landscape_4_3', 'landscape_16_9', 'landscape_25_10']),
  onClose: PropTypes.func,
  data: PropTypes.object,
  onBeforeUploadPicture: PropTypes.func,
  picturePoolFilter: PropTypes.func,
};

MediaSelector.defaultProps = {
  targetUmaId: 0,
  type: 'picture',
  initialTab: 'pool',
  editor: null,
  pictureType: '',
  targetParams: {},
  data: {},
  onClose: () => {},
  onBeforeUploadPicture: (data, callback) => {
    callback(data);
  },
  picturePoolFilter: (picture) => !picture.isRejected,
};

export default withAppState(MediaSelector);
export { MediaSelector };
