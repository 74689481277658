import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import { FileType, MessageDirection } from '../../../../graphql/VXServicesTelegram/types';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PhotoIcon from '@material-ui/icons/Photo';
import MicIcon from '@material-ui/icons/Mic';
import GifIcon from '@material-ui/icons/Gif';
import { Box } from '@material-ui/core';
import { useLang } from '../../../../util/AppState';
import { ApiLang } from '../../../../graphql/VXModels/types';
import { _ } from '../../../../util/translate';
import { GRAY_3, WHITE } from '../../../../camtool-styles';
import { EventEnum } from './Desktop/ChannelItem';

const MessageContainer = styled.span<Pick<MessagePreviewProps, 'unread' | 'active'>>`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  ${(props) =>
    props.unread
      ? `
    font-weight: bold;
  `
      : `
    font-weight: normal;
    color: ${props.active ? WHITE : GRAY_3};
  `}
`;

interface MessagePreviewProps {
  active: boolean;
  unread: boolean;
  text: string;
  direction: MessageDirection;
  type?: FileType | EventEnum;
}

const MessagePreview: FC<MessagePreviewProps> = ({ active, unread, text, direction, type }) => {
  const [language] = useLang();

  const generatePreviewText = useCallback(
    (text: string, direction: MessageDirection, language: ApiLang, type?: FileType | EventEnum) => {
      const isGerman = language === ApiLang.de;
      const languageFlowStyle = {
        flexDirection: isGerman ? 'row' : 'row-reverse',
        justifyContent: isGerman ? 'flex-start' : 'flex-end',
      };

      switch (type) {
        case FileType.picture:
          return (
            <Box display="flex" alignItems="center" gridGap="5px" {...languageFlowStyle}>
              <PhotoIcon fontSize="small" />
              <span>{_(`telegram:chat.message.preview.${direction}.${type}`)}</span>
            </Box>
          );
        case FileType.video:
          return (
            <Box display="flex" alignItems="center" gridGap="5px" {...languageFlowStyle}>
              <PlayCircleOutlineIcon fontSize="small" />
              <span>{_(`telegram:chat.message.preview.${direction}.${type}`)}</span>
            </Box>
          );
        case FileType.audio:
          return (
            <Box display="flex" alignItems="center" gridGap="5px" {...languageFlowStyle}>
              <MicIcon fontSize="small" />
              <span>{_(`telegram:chat.message.preview.${direction}.${type}`)}</span>
            </Box>
          );
        case FileType.sticker:
          return (
            <Box display="flex" alignItems="center" gridGap="5px" {...languageFlowStyle}>
              <GifIcon fontSize="small" />
              <span>{_(`telegram:chat.message.preview.${direction}.${type}`)}</span>
            </Box>
          );
        case EventEnum.Message:
        case EventEnum.Tipping:
          return text;
        default:
          return null;
      }
    },
    [text, language, type]
  );

  return (
    <MessageContainer active={active} unread={unread}>
      {generatePreviewText(text, direction, language, type)}
    </MessageContainer>
  );
};

export default MessagePreview;
