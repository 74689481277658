import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import NewInputField from '../../../atoms/InputField/NewInputField';
import LanguageTabs from '../../../atoms/MUI/LanguageTabs/LanguageTabs';
import { MultiLanguageTextFieldProps } from './types';

const MultiLanguageTextField: FC<MultiLanguageTextFieldProps> = ({
  language,
  changeLanguage,
  customLanguageArray,
  label,
  error,
  textFieldProps,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <LanguageTabs
        language={language}
        changeLanguage={changeLanguage}
        label={label}
        error={error}
        customLanguageArray={customLanguageArray}
      />
      <NewInputField hasTabs={true} {...textFieldProps} />
    </Box>
  );
};

export default MultiLanguageTextField;
