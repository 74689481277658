import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import {
  VXGAMES_TYPOGRAPHY_H5,
  VXGAMES_TYPOGRAPHY_PARAGRAPH,
  VXGAMES_TYPOGRAPHY_SUBHEADING,
  convertArrayIntoSpokenArray,
} from '../../../../utils';
import { CompetitionPrizeFooterProps } from '..';

const CompetitionPrizeFooter: FC<CompetitionPrizeFooterProps> = ({ winningList }) => {
  const spokenWinningList =
    winningList?.length > 0 ? convertArrayIntoSpokenArray(winningList) : null;

  return spokenWinningList ? (
    <>
      <Typography align="center" style={VXGAMES_TYPOGRAPHY_H5}>
        {'Gehen an:'}
      </Typography>
      <Typography align="center" style={VXGAMES_TYPOGRAPHY_PARAGRAPH}>
        {spokenWinningList}
      </Typography>
    </>
  ) : (
    <Typography align="center" style={VXGAMES_TYPOGRAPHY_SUBHEADING}>
      {
        '*Die Gewinner werden binnen einer Woche nach Auslosung bekannt gegeben. Die Gewinne werden deinem Account gut geschrieben.'
      }
    </Typography>
  );
};

export { CompetitionPrizeFooter };
