import styled from '@emotion/styled';
import { BLACK_1 } from '../../../camtool-styles';

export const Container = styled.div`
  color: ${BLACK_1};
  background-color: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0%;
  position: relative;
  max-height: 76px;
  outline: none;
`;
