import React, {FC} from 'react';
import TOSContent from './TOSContent';
import {
    StyledModalbox,
    ModalContainer,
} from '../../../components/Modalboxes/ModalTerms/ModalTerms';
import {Container} from '../../../components/Modalboxes/ModalTerms/ModalTermsContentShort';
import {useUserData} from '../../../util/UserData';
import {_} from '../../../util/translate';
import {Markdown} from '../../../components';
import {css} from '@emotion/core';

const TOSContentModal: FC = () => {
    const userData = useUserData();

    const {isStudio, hasWebmasterId} = userData.model;

    if (isStudio || hasWebmasterId) return null;

    return (
        <StyledModalbox
            noCloseOnESC
            name={'cmsArticleName'}
            state={true}
            zIndex={1000} // zIndex 1000 to beat notifications zIndex of 999
        >
            <ModalContainer>
                <Container>
                    <TOSContent
                        headerText={_('vxcash:register.modal.title')}
                        subheaderText={
                            <Markdown
                                css={css`
                                    p {
                                        text-align: center;
                                    }
                                `}
                                source={_('vxcash:register.modal.text')}
                                renderers={{
                                    // eslint-disable-next-line react/display-name
                                    link: (props: Record<string, never>): JSX.Element => (
                                        <a href={props.href} target="_blank" rel="noopener noreferrer">
                                            {props.children}
                                        </a>
                                    ),
                                }}
                            />
                        }
                        labelSource={_('vxcash:register.aceptTextAlt')}
                        buttonText={_('vxcash:register.modal.button')}
                    />
                </Container>
            </ModalContainer>
        </StyledModalbox>
    );
};

export default TOSContentModal;
