import React, { PureComponent, createRef } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const iconBase = css`
  font-family: 'GLYPHICONS';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  /* vertical-align: top; */
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2em;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  border: 2px solid #fff;
  color: #fff;
`;
const PlayIcon = styled.span`
  ${iconBase};
  &::before {
    content: '\\E174';
  }
`;
const PauseIcon = styled.span`
  ${iconBase};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 200ms;
  &::before {
    content: '\\E175';
  }
`;
const Container = styled.article`
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
`;
const PlaybackButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  font-size: 48px;
  border: none;
  background-color: transparent;
  outline: none;
`;

class VideoPlayer extends PureComponent {
  state = { isPlaying: false, isMouseOverVideo: false, canPlay: false };

  video = createRef();

  handleClick = () => {
    this.setState({ isPlaying: !this.state.isPlaying }, () => {
      if (this.state.isPlaying) this.video.current.play();
      else this.video.current.pause();
    });
  };

  render() {
    const { width, height, videoSrc } = this.props;
    const { canPlay, isPlaying, isMouseOverVideo } = this.state;

    return (
      <Container width={width} height={height}>
        <video
          ref={this.video}
          src={videoSrc}
          width={width}
          height={height}
          onCanPlay={() => this.setState({ canPlay: true })}
          onPlaying={() => this.setState({ buttonHidden: true })}
          onPause={() => this.setState({ buttonHidden: false })}
          onEnded={() => this.setState({ isPlaying: false })}
        />
        <PlaybackButton
          onClick={this.handleClick}
          onMouseOver={() => this.setState({ isMouseOverVideo: true })}
          onMouseOut={() => this.setState({ isMouseOverVideo: false })}
        >
          {!isPlaying && canPlay && <PlayIcon />}
          {isPlaying && canPlay && <PauseIcon isVisible={isMouseOverVideo} />}
        </PlaybackButton>
      </Container>
    );
  }
}

export default VideoPlayer;
