import React, { FC } from 'react';
import { createStyles, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BLACK, BLACK_1 } from '../../../../camtool-styles';

interface SalesStatsItemProps {
  primary: React.ReactNode;
  secondary: React.ReactNode;
  icon: React.ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: 'center',
    },
    avatarRoot: {
      minWidth: 'inherit',
      paddingLeft: 24,
      paddingRight: 24,
      '& > span::before': {
        fontSize: 32,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 32,
      },
    },
    textRoot: {
      flex: 'none',
    },
    textPrimary: {
      fontSize: 12,
    },
    textSecondary: {
      fontSize: 16,
      fontWeight: 'bold',
      color: BLACK_1,
    },
  })
);

const SalesStatsItem: FC<SalesStatsItemProps> = ({ primary, secondary, icon }) => {
  const { root, avatarRoot, textRoot, textPrimary, textSecondary } = useStyles();
  return (
    <ListItem classes={{ root }} component={'div'}>
      <ListItemAvatar classes={{ root: avatarRoot }}>{icon}</ListItemAvatar>
      <ListItemText
        classes={{ root: textRoot, primary: textPrimary, secondary: textSecondary }}
        primary={primary}
        primaryTypographyProps={{ component: 'div' }}
        secondary={secondary}
        secondaryTypographyProps={{ component: 'div' }}
      />
    </ListItem>
  );
};

export default SalesStatsItem;
