import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { step2 } from '.';

const SecondStepChild: FC = () => {
  return (
    <Box display="flex" justifyContent="center" mt={2}>
      <img css={{ maxWidth: '100%' }} src={step2} />
    </Box>
  );
};

export default SecondStepChild;
