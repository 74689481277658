var createReactClass = require('create-react-class');

var React = require('react');
var Formsy = require('formsy-react');
var FormElement = require('../FormElement');

var InputHidden = createReactClass({
  mixins: [Formsy.Mixin, FormElement],

  render: function() {
    return <input type="hidden" name={this.props.name} value={this.props.value} />;
  },
});

export default InputHidden;
export { InputHidden };
