import React, { FC } from 'react';
import GenericEmptyContent from '../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Comments, Spinner } from '../../../../components';
import { useQuery } from '@apollo/react-hooks';
import { MODEL_VIDEOS_ALBUMS_FEEDBACK } from '../../../../graphql/VXModels/queries/index';
import { Query } from '../../../../graphql/VXModels/types';

interface Props {
  umaId: number;
}

const VideoComments: FC<Props> = ({ umaId }) => {
  const { data, loading, error } = useQuery<Query>(MODEL_VIDEOS_ALBUMS_FEEDBACK, {
    variables: { umaId: Number(umaId) },
  });
  const album = data?.model?.videos?.albums
    ? data.model.videos.albums[0]
    : { dislikes: 0, likes: 0, feedbacksItems: [], video: { title: '', origFilename: '' } };
  const modelAvatar = data?.model?.avatar?.picture?.url;
  const contentTitle = album?.video?.title || album?.video?.origFilename || '';

  return loading ? (
    <Spinner />
  ) : error ? (
    <GenericEmptyContent />
  ) : (
    <Comments
      umaId={umaId}
      likes={album.likes}
      groupedLikes={album.groupedLikes}
      feedbacksItems={album.feedbacksItems}
      modelAvatar={modelAvatar}
      type={'video'}
      contentTitle={contentTitle}
    />
  );
};

export default VideoComments;
