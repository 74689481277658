import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Service0900InactiveIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon fontSize={'large'} {...props}>
    <g id="Telefon-Icon-Export" transform="translate(-1807 514)">
      <rect
        id="Rechteck_1199"
        data-name="Rechteck 1199"
        width="25"
        height="25"
        transform="translate(1807 -514)"
        fill="none"
      />
      <path
        id="Pfad_67"
        data-name="Pfad 67"
        d="M838.249,821.192c-.156-.09-.306-.172-.463-.262-.7-.418-1.375-.835-2.08-1.224a.618.618,0,0,0-.86.136c-.386.436-.815.853-1.229,1.27a.611.611,0,0,1-.82.1,10.329,10.329,0,0,1-4.661-4.842.609.609,0,0,1,.154-.84,5.084,5.084,0,0,0,1.046-1.153.709.709,0,0,0,.1-.591c-.328-1.058-.655-2.124-.989-3.183a.739.739,0,0,0-.319-.434,2.411,2.411,0,0,0-2,.088,4.208,4.208,0,0,0-1.747,3.9,8.8,8.8,0,0,0,1.422,3.659,15.242,15.242,0,0,0,7.52,6.724,5.335,5.335,0,0,0,1.536.4,3.143,3.143,0,0,0,.96-.006c1.447-.238,2.712-.928,2.979-2.484C838.934,821.639,838.949,821.641,838.249,821.192Z"
        transform="translate(988.638 -1318.975)"
      />
    </g>
  </SvgIcon>
);
