import { useState, useEffect } from 'react';
import { onClick, stepEnum } from '@vxmodels/assistant-react/dist/types/types';
import { DocumentUploadAssistantTypeEnum } from '../../../graphql/VXModels/types';
import { useLang } from '../../../util/AppState';
import { useUserData } from '../../../util/UserData';
import useGetCurrentStep from './useGetCurrentStep';
import useSkipStep from './useSkipStep';
import { AssistantErrorTypeEnum } from '../types';
import { genericUpload } from '../util/genericUpload';
import { ApiLang } from '../../../util/constants/ApiLang';

interface InitializeResult {
  customError: { name: string; message: string } | undefined;
  customLoading: boolean;
  lang: ApiLang;
  currentStep: stepEnum;
  onSkipStep: () => void;
  resetError: () => void;
  onClick: onClick;
  downloadUrl?: string;
}

const useInitializeAssistant = (
  flowType: DocumentUploadAssistantTypeEnum
): InitializeResult | null => {
  const {
    getCurrentStep,
    data: currentStepData,
    loading: currentStepLoading,
  } = useGetCurrentStep({
    flowType,
  });
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const [customError, setCustomError] = useState<{ name: string; message: string } | undefined>();
  const resetError = (): void => {
    setCustomError(undefined);
  };
  const [lang] = useLang();

  useEffect(() => {
    getCurrentStep();
  }, []);

  const flow = currentStepData?.model?.documents?.uploadAssistant?.flow;
  const currentStep = flow?.currentStep?.name;
  let downloadUrl = '';
  if (flow?.currentStep?.templateUrl) {
    downloadUrl = flow?.currentStep?.templateUrl;
  }

  const userId = flow?.userId;
  const userData = useUserData();
  const userIdFallback = userData?.auth?.id;
  const realUserId = userId || userIdFallback;
  const documentType = flow?.currentStep?.documentType; // currentStep != documentType
  // care: onSaveStep gets parameter passed later by Assistant, onSkipStep right here already (experiment to see if additional props (userId, documentType) could be avoided)
  const { onSkipStep } = useSkipStep({
    documentType,
    userId: realUserId,
    getCurrentStep,
  });

  const uploadUrl = flow?.currentStep?.uploadUrl;
  const { upload } = genericUpload({ uploadUrl, setCustomLoading, setCustomError });

  const onClick = (file: File | undefined): void => {
    if (file) {
      upload(file).then((res) => {
        if (res?.request.readyState === 4 && res.status === 200) {
          if (res?.request.responseText.substr(0, 3) == 'OK:') {
            getCurrentStep();
          } else {
            // responseText contains "ERROR:"
            // possible handled errors: 1. Filename extension
            setCustomError({ name: AssistantErrorTypeEnum.UPLOAD, message: 'uploadType' });
          }
        }
      });
    }
  };

  // todo: Module Pattern (put these attributes in one object and return that object alone)
  return {
    customError,
    customLoading,
    lang,
    currentStep,
    onSkipStep,
    resetError,
    onClick,
    downloadUrl,
  };
};

export default useInitializeAssistant;
