import React, { FC } from 'react';
import { _ } from '../../../../../util/translate';
import { Button } from '../../../../../atoms';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';

interface Props {
  loading: boolean;
  hasError: boolean;
  disabled: boolean;
}

const RequestCodeButton: FC<Props> = ({ loading, hasError, disabled }) => {
  return hasError ? (
    <GenericEmptyContent />
  ) : (
    <Button
      type="submit"
      css={{ marginTop: '30px', alignSelf: 'center', width: '148px', whiteSpace: 'nowrap' }}
      theme={'blue'}
      disabled={disabled}
      loading={loading}
    >
      {_('service0900:PhoneSex.modal.request.code')}
    </Button>
  );
};

export default RequestCodeButton;
