import React from 'react';
import PropTypes from 'prop-types';

class EarningOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  earningDetails() {
    return this.props.earningDetails.map((item, i) => (
      <div key={i} className="earning-overview__stats__item">
        <div
          className={`${item.icon} earning-overview__stats__item__icon`}
          style={{ color: item.iconColor }}
        />
        <div className="earning-overview__stats__item__text">
          <div className="earning-overview__stats__item__label">{item.label}</div>
          <div className="earning-overview__stats__item__data">{item.data}</div>
        </div>
      </div>
    ));
  }

  render() {
    if (this.state.isLoading) return <h1>loading</h1>;

    return (
      <article className="earning-overview">
        <section className="earning-overview__stats">{this.earningDetails()}</section>

        <section className="earning-overview__earning" style={{ color: this.props.earningColor }}>
          {this.props.earning}
        </section>

        <section className="earning-overview__footnote">{this.props.footnote}</section>
      </article>
    );
  }
}

EarningOverview.propTypes = {
  earning: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  earningColor: PropTypes.string,
  earningDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      icon: PropTypes.string,
      iconColor: PropTypes.string,
    })
  ).isRequired,
  footnote: PropTypes.string,
};

EarningOverview.defaultProps = {
  earningColor: '#43B31C',
  footnote: '',
};

export default EarningOverview;
export { EarningOverview };
