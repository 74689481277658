import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../AbstractComponent';
import FileValidator from '../../../util/FileValidator';
import { MEDIA_PICTURE_UPLOAD_MIN_HEIGHT, MEDIA_PICTURE_UPLOAD_MIN_WIDTH } from '../../../config';
import Spinner from '../../Spinner/Spinner';
import UploadField from '../../MediaUploader/UploadField/UploadField';
import ButtonGroup from '../../ButtonGroup/ButtonGroup';
import Button from '../../Button/Button';
import { _ } from '../../../util/translate';
import heic2any from 'heic2any';

class Browse extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      urlData: null,
      isLoading: false,
      error: null,
    };
    this.onPictureSelect = this.onPictureSelect.bind(this);
  }

  // helperfunction to convert heic/heif last one is not rly supported
  // https://github.com/alexcorvi/heic2any/blob/master/docs/getting-started.md

  async heic2Img(file) {
    const res = await heic2any({
      blob: file,
      toType: 'image/jpg',
      quality: 0.75, // quality scale
    }).catch((e) => e);
    return res;
  }

  async onPictureSelect(files) {
    if (files.length > 0) {
      this.setState({
        isLoading: true,
      });
      const validator = new FileValidator();
      const fileName = files[0]?.name?.toLowerCase();

      validator.setConditionFileType(['jpg', 'jpeg', 'png', 'gif', 'heic']);
      validator.setConditionPictureDimension(this.props.minWidth, this.props.minHeight);
      validator.setConditionUseHash(true);

      const isHeic = fileName.includes('.heic');
      // no real support for heif...
      //const isHeif = fileName.includes('.heif');

      //if isheic else if isheif else nothing
      const renamedFileName = isHeic ? fileName.replace('.heic', '.jpg') : '';

      //convert file
      const convertedFile = isHeic
        ? new File([await this.heic2Img(files[0])], renamedFileName)
        : files[0];

      validator.validate(
        convertedFile,
        () => {
          // onValid
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({
              urlData: reader.result,
              isLoading: true,
              error: null,
            });
          };
          reader.onloadend = () => {
            this.setState({ isLoading: false });
          };
          reader.readAsDataURL(convertedFile);
        },
        (errorType) => {
          // onInvalid
          const reader = new FileReader();
          reader.onload = () => {
            this.setState({
              urlData: reader.result,
              isLoading: false,
              error: _(`mediamanagement:upload.error.${errorType}`, {
                postProcess: 'sprintf',
                sprintf: this.props,
              }), // %(minWidth)s, %(minHeight)s,
            });
          };
          reader.readAsDataURL(convertedFile);
        }
      );
    } else {
      // if we have no files array catching stupid browsers :)
      this.setState({
        isLoading: false,
        error: _(`mediamanagement:upload.error.file_type_error`, {
          postProcess: 'sprintf',
          sprintf: this.props,
        }),
      });
    }
  }

  reset() {
    this.setState({
      urlData: null,
      error: null,
      isLoading: false,
    });
  }

  onSubmit() {
    this.props.onSubmit({
      source: 'browse',
      selectionType: 'urlData',
      urlData: this.state.urlData,
    });
    this.setState({
      isLoading: true,
    });
  }

  render() {
    this.isValid = !this.state.error && this.state.urlData;

    return (
      <div className="mediaselector__browse">
        <div className="mediaselector__browse__column spinner-container">
          {this.state.isLoading && <Spinner />}

          {!this.state.urlData && (
            <UploadField
              onUpload={this.onPictureSelect}
              multiple={false}
              label={this.props.label}
              subtitle={this.props.subtitle}
              acceptedMimeTypes={this.props.acceptedMimeTypes}
            />
          )}

          {this.state.urlData && (
            <div className="mediaselector__browse__preview" onClick={this.reset}>
              <div
                className="mediaselector__browse__preview__img"
                style={{ backgroundImage: `url(${this.state.urlData})` }}
              />
            </div>
          )}

          {this.state.error && (
            <div className="mediaselector__browse__error">
              <div className="mediaselector__browse__error__text">{this.state.error}</div>
            </div>
          )}

          <ButtonGroup position="right">
            <Button
              disabled={!this.isValid || this.state.isLoading}
              label="OK"
              type="submit"
              color="green"
              onClick={this.onSubmit}
            />
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

Browse.propsTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  acceptedMimeTypes: PropTypes.string.isRequired,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  subtitle: PropTypes.string,
};

Browse.defaultProps = {
  isLoading: false,
  uploadedPicture: {},
  label: '',
  minWidth: MEDIA_PICTURE_UPLOAD_MIN_WIDTH,
  minHeight: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
  subtitle: '',
};

export default Browse;
export { Browse };
