import React, { FC, useState, Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import { ArrayHelpers, Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import { useQuery } from '@apollo/react-hooks';
import { withApollo, WithApolloClient } from '@apollo/react-hoc';
import * as Yup from 'yup';

import { Button, Flag, Main, Section } from '../../../../atoms';
import FieldLayout from '../../../../atoms/FormFIeldsCtrl/FieldLayout';
import RadioField from '../../../../atoms/FormFIeldsCtrl/Fields/RadioField';
import CheckboxField from '../../../../atoms/FormFIeldsCtrl/Fields/CheckboxField';
import { Theme } from '../../../../atoms/Button/styles';
import { Spinner, T } from '../../../../components';
import FormikTextarea from '../../../BenefitsWizard/components/Formik/FormikTextarea';
import { QUERY_MODEL_PROFILE_LANGUAGES, QUERY_MODEL_PROFILE_FETISH } from '../../../../graphql/VXModels/queries'; // prettier-ignore
import { GRAY_3, RED, WHITE, WHITE_2 } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';

import { GRAY_8 } from '../../../BenefitsWizard/styles';

import NavSubVX from '../NavSubVX';

import OnOffSwitch from './OnOffSwitch';

const StyledDiv = styled.div`
  align-self: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
  text-align: center;
  max-width: 800px;
`;

enum FetishProfileStatus {
  New,
  InReview,
  Approved,
  Rejected,
  Inactive,
}

const Fetish: FC<WithApolloClient<{}>> = props => {
  const { loading: loadingFetish, error: errorFetish, data: dataFetish } = useQuery(QUERY_MODEL_PROFILE_FETISH); // prettier-ignore
  const [status, setStatus] = useState<FetishProfileStatus>(FetishProfileStatus.New);
  const [profileLangs, setProfileLangs] = useState([]); // prettier-ignore
  const [fetishTextLanguage, setFetishTextLanguage] = useState('');
  const [fetishTaboosLanguage, setFetishTaboosLanguage] = useState('');

  useEffect(() => {
    props.client.query({ query: QUERY_MODEL_PROFILE_LANGUAGES }).then(res => {
      const languages = res.data.model.profile.languages.value;
      setProfileLangs(languages);
      setFetishTextLanguage(languages[0]);
      setFetishTaboosLanguage(languages[0]);
    });
  }, [setProfileLangs, setFetishTextLanguage, setFetishTaboosLanguage]);

  const isReady = !loadingFetish && !errorFetish && (dataFetish && dataFetish.model);

  const handleClick = () => {
    console.log('ok');
  };

  const getTheme = (): Theme => {
    switch (status) {
      case FetishProfileStatus.InReview:
        return 'gray';
      case FetishProfileStatus.Rejected:
        return 'red';
      case FetishProfileStatus.New:
      case FetishProfileStatus.Approved:
      case FetishProfileStatus.Inactive:
      default:
        return 'blue';
    }
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('event.target.value', event.target.value);
    setStatus(Number(event.target.value));
  };

  const handleSubmit = (values, formikBag) => {
    console.log('values, formikBag', values, formikBag);
  };

  const apiTextsToFormik = apiTexts => {
    return apiTexts.reduce((acc, val) => ({ [val.language]: val.text }), {});
  };

  const textDefaults = profileLangs.reduce((acc, val) => ({ ...acc, [val]: '' }), {});

  // Form initial values
  const getInitialValues = () => {
    if (isReady) {
      const {
        fetishRole,
        fetishExperiences,
        fetishPractices, // a.k.a. fetishTypes
        fetishToys,
        fetishText,
        fetishTaboos,
      } = dataFetish.model.profile;

      return {
        fetishRole: fetishRole.value,
        fetishPractices: fetishPractices.value,
        fetishExperiences: fetishExperiences.value,
        fetishToys: fetishToys.value,
        fetishText: fetishText.hasTexts ? apiTextsToFormik(fetishText.texts) : textDefaults,
        fetishTaboos: fetishTaboos.hasTexts ? apiTextsToFormik(fetishTaboos.texts) : textDefaults,
      };
    }
  };

  const validationSchema = Yup.object().shape({
    fetishRole: Yup.string().required(_('profiles:fetishRole.requiredMessage')),
    fetishText: Yup.object().test(
      'fetishText-not-empty',
      _('profiles:fetishText.requiredMessage'),
      fetishTextObj => Object.values(fetishTextObj).filter(val => !!val).length > 0
    ),
    fetishTaboos: Yup.object().test(
      'fetishText-not-empty',
      _('profiles:fetishTaboos.requiredMessage'),
      fetishTextObj => Object.values(fetishTextObj).filter(val => !!val).length > 0
    ),
  });

  return (
    <Main>
      <NavSubVX />

      <Section title={_('profiles:fetish.sectionTitle')}>
        <div
          className="spinner-container"
          css={{
            alignSelf: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '64px 0',
            minWidth: 500,
          }}
        >
          {loadingFetish && <Spinner />}

          {!errorFetish && dataFetish && dataFetish.model && dataFetish.model.profile && (
            <Fragment>
              <StyledDiv>
                <h2>
                  <T _="profiles:fetish.title" />
                </h2>

                <p css={{ marginTop: 16 }}>
                  <T _="profiles:fetish.description" />
                </p>

                <p css={{ marginTop: 16, fontWeight: 'bold' }}>
                  <T _="profiles:fetish.info" />
                </p>

                {status !== FetishProfileStatus.Approved &&
                  status !== FetishProfileStatus.Inactive && (
                    <Button
                      theme={getTheme()}
                      onClick={handleClick}
                      disabled={status !== FetishProfileStatus.New}
                      css={{
                        marginTop: 32,
                        ...(status === FetishProfileStatus.Rejected ? { '&:disabled': { opacity: 1 } } : {}) // prettier-ignore
                      }}
                    >
                      {status === FetishProfileStatus.New && <T _="profiles:fetish.buttonNew" />}

                      {status === FetishProfileStatus.InReview && (
                        <T _="profiles:fetish.buttonInReview" />
                      )}

                      {status === FetishProfileStatus.Rejected && (
                        <T _="profiles:fetish.buttonRejected" />
                      )}
                    </Button>
                  )}

                {status === FetishProfileStatus.InReview && (
                  <div css={{ flexDirection: 'column', marginTop: 16 }}>
                    <p css={{ color: RED, fontSize: 14 }}>
                      <T _="profiles:fetish.supportInfo1" />
                    </p>
                    <p css={{ color: GRAY_8, marginTop: 8 }}>
                      <T _="profiles:fetish.supportInfo2" />
                    </p>
                  </div>
                )}
              </StyledDiv>

              {(status === FetishProfileStatus.Approved ||
                status === FetishProfileStatus.Inactive) && (
                <Formik
                  onSubmit={handleSubmit}
                  onReset={() => window.scrollTo(0, 0)}
                  initialValues={getInitialValues()}
                  validationSchema={validationSchema}
                  render={({ values, handleReset, dirty, touched, errors }) => (
                    <Form css={{ flexDirection: 'column', maxWidth: 400, width: '100%' }}>
                      {/* PROFILE FETISH STATE */}
                      <FieldLayout label={`${_('profiles:profileFetishState.label')}:`}>
                        <OnOffSwitch
                          name="profileFetishState"
                          onChange={handleStatusChange}
                          labelOn={_('components:optionBoxOnOff.label.on')}
                          labelOff={_('components:optionBoxOnOff.label.off')}
                          value={String(status)}
                          valueOn={String(FetishProfileStatus.Approved)}
                          valueOff={String(FetishProfileStatus.Inactive)}
                        />
                      </FieldLayout>

                      {/* FETISH ROLE */}
                      <FieldLayout
                        label={`${_('profiles:fetishRole.label')}:`}
                        error={touched.fetishRole && errors.fetishRole ? errors.fetishRole : ''}
                        css={{ marginTop: 32 }}
                      >
                        <div css={{ flexWrap: 'wrap' }}>
                          {dataFetish.model.profile.fetishRole.formData.map((role, i) => (
                            <Field
                              key={role.value}
                              name="fetishRole"
                              render={({ field }: FieldProps) => (
                                <RadioField
                                  {...field}
                                  value={role.value}
                                  text={_(role.label)}
                                  checked={role.value === values.fetishRole}
                                  css={{
                                      flex: '0 0 194px',
                                      marginLeft: (i % 2) * 8,
                                      marginBottom: i < 2 ? 8 : 0
                                    }} // prettier-ignore
                                />
                              )}
                            />
                          ))}
                        </div>
                      </FieldLayout>

                      {/* FETISH TYPES */}
                      <FieldLayout
                        label={`${_('profiles:fetishTypes.label')}:`}
                        css={{ marginTop: 32 }}
                      >
                        <FieldArray
                          name="fetishPractices"
                          render={(arrayHelpers: ArrayHelpers) => {
                            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                              const { checked, value } = e.target;
                              if (checked) {
                                arrayHelpers.push(value);
                              } else {
                                arrayHelpers.remove(values.fetishPractices.indexOf(value));
                              }
                            };

                            return (
                              <div css={{ flexWrap: 'wrap' }}>
                                {dataFetish.model.profile.fetishPractices.formData.map(
                                  (practice, i) => (
                                    <CheckboxField
                                      key={practice.value}
                                      name="fetishPractices"
                                      value={practice.value}
                                      text={_(practice.label)}
                                      checked={values.fetishPractices.includes(practice.value)}
                                      onChange={handleChange}
                                      css={{
                                          flex: '0 0 194px',
                                          marginLeft: (i % 2) * 8,
                                          marginBottom: i < dataFetish.model.profile.fetishPractices.formData.length - 2 ? 8 : 0
                                        }} // prettier-ignore
                                    />
                                  )
                                )}
                              </div>
                            );
                          }}
                        />
                      </FieldLayout>

                      {/* FETISH EXPERIENCES */}
                      <FieldLayout
                        label={`${_('profiles:fetishExperiences.label')}:`}
                        css={{ marginTop: 32 }}
                      >
                        <FieldArray
                          name="fetishExperiences"
                          render={(arrayHelpers: ArrayHelpers) => {
                            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                              const { checked, value } = e.target;
                              if (checked) {
                                arrayHelpers.push(value);
                              } else {
                                arrayHelpers.remove(values.fetishExperiences.indexOf(value));
                              }
                            };

                            return (
                              <div css={{ flexWrap: 'wrap' }}>
                                {dataFetish.model.profile.fetishExperiences.formData.map(
                                  (practice, i) => (
                                    <CheckboxField
                                      key={practice.value}
                                      name="fetishExperiences"
                                      value={practice.value}
                                      text={_(practice.label)}
                                      checked={values.fetishExperiences.includes(practice.value)}
                                      onChange={handleChange}
                                      css={{
                                        flex: '0 0 194px',
                                        marginLeft: (i % 2) * 8,
                                        marginBottom:
                                          i <
                                          dataFetish.model.profile.fetishExperiences.formData
                                            .length -
                                            2
                                            ? 8
                                            : 0,
                                      }}
                                    />
                                  )
                                )}
                              </div>
                            );
                          }}
                        />
                      </FieldLayout>

                      {/* FETISH TOYS */}
                      <FieldLayout
                        label={`${_('profiles:fetishToys.label')}:`}
                        css={{ marginTop: 32 }}
                      >
                        <FieldArray
                          name="fetishToys"
                          render={(arrayHelpers: ArrayHelpers) => {
                            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                              const { checked, value } = e.target;
                              if (checked) {
                                arrayHelpers.push(value);
                              } else {
                                arrayHelpers.remove(values.fetishToys.indexOf(value));
                              }
                            };

                            return (
                              <div css={{ flexWrap: 'wrap' }}>
                                {dataFetish.model.profile.fetishToys.formData.map((practice, i) => (
                                  <CheckboxField
                                    key={practice.value}
                                    name="fetishToys"
                                    value={practice.value}
                                    text={_(practice.label)}
                                    checked={values.fetishToys.includes(practice.value)}
                                    onChange={handleChange}
                                    css={{
                                        flex: '0 0 194px',
                                        marginLeft: (i % 2) * 8,
                                        marginBottom: i < dataFetish.model.profile.fetishToys.formData.length - 2 ? 8 : 0
                                      }} // prettier-ignore
                                  />
                                ))}
                              </div>
                            );
                          }}
                        />
                      </FieldLayout>

                      {/* FETISH TEXT */}
                      <FieldLayout
                        label={`${_('profiles:fetishText.label')}:`}
                        error={touched.fetishText && errors.fetishText ? errors.fetishText : ''}
                        css={{ marginTop: 32 }}
                      >
                        <Field
                          name={`fetishText.${fetishTextLanguage}`}
                          render={({ field }: FieldProps) => (
                            <FormikTextarea
                              name={field.name}
                              value={field.value || ''}
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              toolbar={() => (
                                <div css={{ position: 'relative', bottom: -1 }}>
                                  {profileLangs.map(lang => (
                                    <button
                                      key={lang}
                                      type="button"
                                      onClick={() => setFetishTextLanguage(lang)}
                                      css={{
                                        cursor: lang === fetishTextLanguage ? 'default' : 'pointer',
                                        border: `1px solid ${GRAY_3}`,
                                        borderBottomWidth: lang === fetishTextLanguage ? 0 : 1,
                                        padding: 8,
                                        fontSize: 14,
                                        alignItems: 'center',
                                        backgroundColor:
                                          lang === fetishTextLanguage ? WHITE : WHITE_2,
                                        ':last-of-type': { borderLeftWidth: 0 },
                                      }}
                                    >
                                      <Flag lang={lang} />
                                      <span css={{ marginLeft: 8 }}>
                                        {_(`enum:common.languages.${lang}`)}
                                      </span>
                                    </button>
                                  ))}
                                </div>
                              )}
                            />
                          )}
                        />
                      </FieldLayout>

                      {/* FETISH TABOOS */}
                      <FieldLayout
                        label={`${_('profiles:fetishTaboos.label')}:`}
                        error={
                          touched.fetishTaboos && errors.fetishTaboos ? errors.fetishTaboos : ''
                        }
                        css={{ marginTop: 32 }}
                      >
                        <Field
                          name={`fetishTaboos.${fetishTaboosLanguage}`}
                          render={({ field }: FieldProps) => (
                            <FormikTextarea
                              name={field.name}
                              value={field.value || ''}
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              toolbar={() => (
                                <div css={{ position: 'relative', bottom: -1 }}>
                                  {profileLangs.map(lang => (
                                    <button
                                      key={lang}
                                      type="button"
                                      onClick={() => setFetishTaboosLanguage(lang)}
                                      css={{
                                        cursor:
                                          lang === fetishTaboosLanguage ? 'default' : 'pointer',
                                        border: `1px solid ${GRAY_3}`,
                                        borderBottomWidth: lang === fetishTaboosLanguage ? 0 : 1,
                                        padding: 8,
                                        fontSize: 14,
                                        alignItems: 'center',
                                        backgroundColor:
                                          lang === fetishTaboosLanguage ? WHITE : WHITE_2,
                                        ':last-of-type': { borderLeftWidth: 0 },
                                      }}
                                    >
                                      <Flag lang={lang} />
                                      <span css={{ marginLeft: 8 }}>
                                        {_(`enum:common.languages.${lang}`)}
                                      </span>
                                    </button>
                                  ))}
                                </div>
                              )}
                            />
                          )}
                        />
                      </FieldLayout>

                      {/* FETISH TABOOS */}
                      <div css={{ justifyContent: 'center', marginTop: 48 }}>
                        <Button
                          theme="gray"
                          label="Abbrechen"
                          type="button"
                          onClick={handleReset}
                          disabled={!dirty}
                        />
                        <Button
                          theme="blue"
                          label="Speichern"
                          type="submit"
                          css={{ marginLeft: 32 }}
                          disabled={!dirty}
                        />
                      </div>
                    </Form>
                  )}
                />
              )}
            </Fragment>
          )}
        </div>
      </Section>

      <div>
        <button onClick={() => setStatus(FetishProfileStatus.New)}>New</button>
        <button onClick={() => setStatus(FetishProfileStatus.InReview)}>InReview</button>
        <button onClick={() => setStatus(FetishProfileStatus.Rejected)}>Rejected</button>
        <button onClick={() => setStatus(FetishProfileStatus.Approved)}>Approved</button>
        <button onClick={() => setStatus(FetishProfileStatus.Inactive)}>Inactive</button>
      </div>
    </Main>
  );
};

export default withApollo(Fetish);
