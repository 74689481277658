import React, { ReactElement } from 'react';

const DeletedIcon = (): ReactElement => {
  return (
    <g id="Gelöscht" transform="translate(-2089 -5165)">
      <circle id="Kreis" cx="21" cy="21" r="21" transform="translate(2089 5165)" fill="#ff4d3c" />
      <g id="trash-full" transform="translate(1291 4453)">
        <g id="trash-full-2" data-name="trash-full" transform="translate(811 723)">
          <path
            id="Pfad_11715"
            data-name="Pfad 11715"
            d="M813.581,744.111a1.339,1.339,0,0,0,1.333,1.223h9.377a1.338,1.338,0,0,0,1.333-1.22L826.878,730H812.357Z"
            transform="translate(-811.452 -725.333)"
            fill="#fff"
          />
          <path
            id="Pfad_11716"
            data-name="Pfad 11716"
            d="M826.321,724.333h-4.007l-.335-.9a.669.669,0,0,0-.626-.435h-4.413a.669.669,0,0,0-.623.425l-.355.909h-4.282a.679.679,0,0,0-.679.679v1.321h16v-1.321A.679.679,0,0,0,826.321,724.333Z"
            transform="translate(-811 -723)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  );
};

export default DeletedIcon;
