import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { withAppState } from '../../util/AppState';

class NewsTicker extends React.Component {
  static firstToBack(array) {
    return [...array.slice(1), array[0]];
  }

  static lastToFront(array) {
    return [array[array.length - 1], ...array.slice(0, array.length - 1)];
  }

  static clearAllInterval() {
    for (let i = 1; i < 9999; i++) {
      window.clearInterval(i);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      itemIndex: 0,
      prevItemIndex: this.props.items.length - 1,
      items: this.props.items.reverse(),
      intervalId: null,
      queue: [], // ['back' | 'next']
    };

    this.currentItem = React.createRef();
    this.previousItem = React.createRef();

    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.moveCardToBottom = this.moveCardToBottom.bind(this);
    this.moveCardToTop = this.moveCardToTop.bind(this);
    this.handleDotClick = this.handleDotClick.bind(this);
    this.processQueue = this.processQueue.bind(this);
  }

  componentDidMount() {
    if (this.state.intervalId === null) {
      this.play();
    }
  }

  componentDidUpdate() {
    this.processQueue();
  }

  componentWillUnmount() {
    if (this.state.intervalId !== null) {
      clearInterval(this.state.intervalId);
    }
  }

  processQueue() {
    switch (this.state.queue[0]) {
      case 'next':
        this.next(0.15);
        break;
      case 'back':
        this.back(0.15);
        break;
      default:
        break;
    }
  }

  moveCardToBottom() {
    this.currentItem.current.removeEventListener('transitionend', this.moveCardToBottom);
    const { itemIndex, items, queue } = this.state;
    this.currentItem.current.removeAttribute('style');

    if (itemIndex === items.length - 1) {
      this.setState({
        itemIndex: 0,
        prevItemIndex: itemIndex,
        items: NewsTicker.lastToFront(items),
        queue: [...queue.slice(1)],
      });
    } else {
      this.setState({
        itemIndex: itemIndex + 1,
        prevItemIndex: itemIndex,
        items: NewsTicker.lastToFront(items),
        queue: [...queue.slice(1)],
      });
    }
  }

  moveCardToTop() {
    this.currentItem.current.removeEventListener('transitionend', this.moveCardToBottom);
    const { prevItemIndex, items, queue } = this.state;
    this.previousItem.current.removeAttribute('style');

    if (prevItemIndex === 0) {
      this.setState({
        itemIndex: prevItemIndex,
        prevItemIndex: items.length - 1,
        items: NewsTicker.firstToBack(items),
        queue: [...queue.slice(1)],
      });
    } else {
      this.setState({
        itemIndex: prevItemIndex,
        prevItemIndex: prevItemIndex - 1,
        items: NewsTicker.firstToBack(items),
        queue: [...queue.slice(1)],
      });
    }
  }

  next(duration = 0.5) {
    if (this.currentItem.current !== null) {
      this.currentItem.current.addEventListener('transitionend', this.moveCardToBottom, false);
      this.currentItem.current.setAttribute(
        'style',
        `transform: translateX(${this.currentItem.current.getBoundingClientRect().width}px);
        transition: transform ${duration}s;`
      );
    } else {
      NewsTicker.clearAllInterval();
    }
  }

  back(duration = 0.5) {
    if (this.previousItem.current.hasAttribute('style')) {
      return;
    }
    this.previousItem.current.setAttribute(
      'style',
      `transform: translateX(${this.currentItem.current.getBoundingClientRect().width}px);`
    );
    this.previousItem.current.addEventListener('transitionend', this.moveCardToTop, false);

    // wait until previousItem is located right next to currentItem
    const trans = `transform: translateX(${
      this.currentItem.current.getBoundingClientRect().width
    }px);`;
    while (this.previousItem.current.getAttribute('style') !== trans) {
      console.log('waiting...');
    }

    this.previousItem.current.setAttribute(
      'style',
      `z-index: 1;
        transform: translateX(${0});
        transition: transform ${duration}s;`
    );
  }

  play() {
    this.setState({ intervalId: setInterval(this.next, this.props.delay) });
  }

  pause() {
    const { intervalId } = this.state;
    if (intervalId !== null) {
      this.setState({ intervalId: null }, () => clearInterval(intervalId));
    }
  }

  handleMouseOver(event) {
    this.pause();
  }

  handleMouseOut(event) {
    this.play();
  }

  handleClickNext(event) {
    this.setState({
      queue: [...this.state.queue, 'next'],
    });
  }

  handleClickBack(event) {
    this.setState({
      queue: [...this.state.queue, 'back'],
    });
  }

  handleDotClick(event, index) {
    if (this.state.itemIndex === index) {
      return;
    }

    const steps = this.state.itemIndex + 1 - (index + 1);
    const direction = steps < 0 ? 'next' : 'back';
    const newQueueItems = `|${direction}`
      .repeat(Math.abs(steps))
      .split('|')
      .slice(1);

    this.setState({ queue: [...this.state.queue, ...newQueueItems] });
  }

  render() {
    return (
      <div
        className={`bo__container ${this.props.containerClass}`}
        style={this.props.containerStyle}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        <div className="bo">
          <button
            className="bo__button bo__button--left vxicon icon-single-arrow-left-line"
            onClick={this.handleClickBack}
          />
          <button
            className="bo__button bo__button--right vxicon icon-single-arrow-right-line"
            onClick={this.handleClickNext}
          />

          <div className="bo__items">
            {this.state.items.map((Component, i) => (
              <Component
                key={i}
                className="bo__item"
                ref={
                  i === 0
                    ? this.previousItem
                    : i === this.state.items.length - 1
                    ? this.currentItem
                    : undefined
                }
              />
            ))}
          </div>

          <div className="bo__dots">
            {this.state.items.map((_, i) => (
              <span
                key={i}
                onClick={e => {
                  this.handleDotClick(e, i);
                }}
                className={`bo__dots__dot ${
                  i === this.state.itemIndex ? 'bo__dots__dot--active' : ''
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

NewsTicker.propTypes = {
  delay: PropTypes.number,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['', 'classic', 'html-classic', 'social', 'simple']),
    })
  ),
};

NewsTicker.defaultProps = {
  delay: 5000,
  containerClass: '',
  containerStyle: {},
  items: [],
};

export default withRouter(withAppState(NewsTicker));
