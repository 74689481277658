import React from "react";
import AccountSwitchToBeta from "./AccountSwitchToBeta";

const AccountPageToBeta = () => {
    return (
        <main
            id="marketing-campaigns"
            className="grid marketing marketing-campaigns"
            style={{minWidth: '600px'}}
        >
            <div className="grid__row">
                <div className="grid__column grid__box h--pos-rel min-width--0">
                    <div className="grid__box__header">
                        <div className="grid__box__header__title">Documents</div>
                        <div className="grid__box__header__spacer"/>
                    </div>
                    <AccountSwitchToBeta/>
                </div>
            </div>
        </main>
    );
};

export default AccountPageToBeta;