import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  BLACK_2,
  BLUE,
  BREAKPOINT_TABLET_CONDITION,
  GRAY_2,
  GRAY_3,
  WHITE,
} from '../../camtool-styles';

const IMAGE_WIDTH = 480;
const IMAGE_HEIGHT = 125;
export const BREAKPOINT = 1200;

interface DotProps {
  active: boolean;
}

export const Dot = styled.span<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 8px;
  background-color: ${(props): string => (props.active ? BLUE : GRAY_3)};
`;

export const Container = styled.div`
  flex: 1;
  padding: 22px;
  color: ${BLACK_2};
  background-color: ${WHITE};
  justify-content: space-between;
  @media (max-width: ${BREAKPOINT_TABLET_CONDITION}px) {
    flex-direction: column;
    padding: 8px;
  }

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    padding: 8px;
  }
`;

export const Title = styled.h3`
  margin-bottom: 8px;
`;

export const Text = styled.div`
  line-height: 1.3em;
  letter-spacing: 0.2px;
`;

export const DatePlaceholder = styled.div`
  font-size: 1rem;
`;

export const TemplateBody = styled.div`
  flex-direction: column;
  margin-right: 32px;
  align-items: flex-start;
  flex: 1;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINT}px) {
    order: 2;
    margin-right: 0;
    //justify-content: center;
    padding: 8px;
  }
`;

export const MediaContainer = styled.div`
  align-items: flex-start;
  @media (max-width: ${BREAKPOINT}px) {
    order: 1;
    margin-right: 0;
    //justify-content: center;
    padding: 8px;
  }
`;

export const Actions = styled.div`
  margin-top: 14px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Row = styled.div`
  flex-direction: row;
  align-items: flex-start;
`;

export const Content = styled.div`
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
`;

export const TextLink = styled.span`
  display: inline-block;
  line-height: 1.3em;
  letter-spacing: 0.2px;
  margin-top: 13px;
  color: ${GRAY_2};
  cursor: pointer;
  font-size: 1rem;
  text-decoration: underline;
`;

const imageCss = css`
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT}px;
`;

export const NotificationImage = styled.img`
  ${imageCss};
  object-fit: cover;
`;

export const BigIcon = styled.div`
  ${imageCss};
  font-size: 48px;
  align-items: center;
  justify-content: center;
`;
