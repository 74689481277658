import { MediaTypeEnum } from '../types';

import { useQuery } from '@apollo/react-hooks';
import {
  QUERY_MODEL_VIDEOS_ALBUMS_BY_TYPE,
  QUERY_PHOTO_ALBUMS_BY_TYPE,
} from '../../../graphql/VXModels/queries';
import { MediaAlbum } from '../../../graphql/VXModels/types';

export const MAX_ELEMENTS = 200;

export const useCountPoolElements = (mediaType: MediaTypeEnum): number => {
  const countPictures = useCountPoolPictures(mediaType !== MediaTypeEnum.picture);
  const countVideos = useCountPoolVideos(mediaType !== MediaTypeEnum.video);
  return countPictures + countVideos;
};

export const useCountPoolPictures = (skip: boolean): number => {
  const { data, loading } = useQuery(QUERY_PHOTO_ALBUMS_BY_TYPE, {
    skip,
    variables: {
      type: 'pool',
    },
  });
  const albums = (data?.model?.photoAlbums?.albums as MediaAlbum[]) || [];
  const countPictures = albums.reduce<number>(
    (count, album): number => count + album.numberOfAllPictures,
    0
  );
  return skip || loading ? 0 : countPictures;
};

export const useCountPoolVideos = (skip: boolean): number => {
  const { data, loading } = useQuery(QUERY_MODEL_VIDEOS_ALBUMS_BY_TYPE, {
    skip,
    variables: {
      limit: 200,
      offset: 0,
      sortDirection: 'desc',
      sortField: 'uploaded',
      type: 'pool',
    },
  });
  return skip || loading ? 0 : data?.model?.videos?.albumsTotal;
};
