import React, { FC, ReactElement } from 'react';
import { _ } from '../../../../util/translate';
import { useMedia } from 'react-media';
import { Field, FieldProps } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { BREAKPOINT_PHONE_CONDITION } from '../../../../camtool-styles';
import ChannelAdvantage from './ChannelAdvantage';
import {
  MasterMessengerVipTextMutationsetBulletsArgs,
  MessengerVipBulletTextInput,
} from '../../../../graphql/VXServicesTelegram/types';

interface ChannelAdvantagesListProps {
  values: MasterMessengerVipTextMutationsetBulletsArgs;
}

const ChannelAdvantagesList: FC<ChannelAdvantagesListProps> = ({ values }) => {
  const isMobile = useMedia({ query: BREAKPOINT_PHONE_CONDITION });

  return (
    <Box display="flex" flexDirection="column" m={'10px auto 0px auto'} width="100%">
      {values.bullets.map((bullet: MessengerVipBulletTextInput, index: number) => (
        <Box key={index} display="flex" flexDirection="column" mb={3}>
          <Typography variant="caption">
            {_('telegram:channeldescription.content.caption', { sprintf: [index + 1] })}
          </Typography>
          <Field name={`bullets[${index}].text`}>
            {({
              field,
              form,
            }: FieldProps<MasterMessengerVipTextMutationsetBulletsArgs>): ReactElement => (
              <ChannelAdvantage
                error={form.errors?.bullets?.[index]?.text}
                field={field}
                isMobile={isMobile}
              />
            )}
          </Field>
        </Box>
      ))}
    </Box>
  );
};

export default ChannelAdvantagesList;
