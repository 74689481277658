import React from 'react';
import { Redirect, Switch } from 'react-router';
import ProtectedRoute from '../../../components/Routing/ProtectedRoute';
import { stripSlash } from '../../../util/urlHelper';

import ProfileFLCommon from './Common/Common';
import Profilepic from './Profilepic/Profilepic';
import Fetish from './Fetish/Fetish';
import ProfileFLAutoMails from './AutoMails/AutoMails';
import Fetish__Draft from './Fetish/Fetish__Draft';

const DEFAULT_ROUTE = `/common`;

const VX = ({ match: { url } }) => {
  const path = stripSlash(url);
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/common`} component={ProfileFLCommon} />
      <ProtectedRoute exact path={`${path}/fetish`} component={Fetish} />
      <ProtectedRoute exact path={`${path}/fetish-draft`} component={Fetish__Draft} />
      <ProtectedRoute exact path={`${path}/automails`} component={ProfileFLAutoMails} />
      <ProtectedRoute path={`${path}/profilepic`} component={Profilepic} />
      <Redirect exact to={`${path}${DEFAULT_ROUTE}`} />
    </Switch>
  );
};

export default VX;
