import React, { FC, useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import qs from 'query-string';
import { Main } from '../../atoms/Grid';
import { APP_IS_DEVELOPMENT, LANDING_PAGE_URL } from '../../util/env';
import Spinner from '../../components/Spinner/Spinner';
import ExternalRedirect from '../../components/Routing/ExternalRedirect';
import { ActionEnum, useAppState } from '../../util/AppState';
import { LOGIN_PATH } from '../Login/Login';

const getLoginPath = (redir?: string): string => {
  const queryString = redir ? `?${qs.stringify({ redir })}` : '';

  return `${LOGIN_PATH}${queryString}`;
};

const Logout: FC<RouteComponentProps> = ({ location: { search } }) => {
  const [{ authToken }, dispatch] = useAppState();

  useEffect(() => {
    if (authToken) {
      dispatch({ type: ActionEnum.clearAuthToken });
    }
  }, [authToken]);

  const { redir } = qs.parse(search, { ignoreQueryPrefix: true });

  return (
    <Main className="spinner-container">
      {authToken ? (
        <Spinner size="xl" />
      ) : redir || APP_IS_DEVELOPMENT ? (
        <Redirect to={getLoginPath(redir)} />
      ) : (
        <ExternalRedirect to={LANDING_PAGE_URL} />
      )}
    </Main>
  );
};

export default Logout;
