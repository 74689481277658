import React, { FC } from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { BLACK_2, WHITE } from '../../../../../../camtool-styles';
import { useDisplayState } from '../../../../../../hooks';
import { TermsModal } from '../../CompetitionTerms/TermsModal';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      backgroundColor: BLACK_2,
    },
    heading: {
      padding: 10,
      color: WHITE,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })
);

const Terms: FC = () => {
  const classes = useStyles();

  const {
    shouldShow: shouldShowTermsModal,
    show: showTermsModal,
    hide: hideTermsModal,
  } = useDisplayState();

  return (
    <Box className={classes.root}>
      <Typography className={classes.heading} onClick={showTermsModal}>
        Teilnahmebedingungen
      </Typography>
      {shouldShowTermsModal && <TermsModal open={shouldShowTermsModal} onClose={hideTermsModal} />}
    </Box>
  );
};

export { Terms };
