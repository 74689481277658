import { ITableColumn } from '../../../../atoms/Table/types';
import { _ } from '../../../../util/translate';

const getColumns = (): ITableColumn[] => [
  { label: _('benefits:voucher.archiveColumn.status'), width: 0.6 },
  { label: _('benefits:voucher.archiveColumn.platform'), width: 0.6 },
  { label: _('benefits:voucher.archiveColumn.name'), width: 1.2 },
  { label: _('benefits:voucher.archiveColumn.code'), width: 0.8 },
  { label: _('benefits:voucher.archiveColumn.type'), width: 0.7 },
  { label: _('benefits:voucher.texts.targetGroups'), width: 0.5 },
  { label: _('benefits:voucher.archiveColumn.validityPeriod'), width: 0.8 },
  { label: _('benefits:voucher.archiveColumn.redeemed'), width: 0.4 },
];

export { getColumns };
