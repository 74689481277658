import React, { FC } from 'react';
import { _ } from '../../../../../util/translate';
import { Service0900TargetNumberMutationResult } from '../../../../../graphql/VXModels/types';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_SERVICE0900_SEND_VERIFICATION_CODE } from '../../../../../graphql/VXModels/mutations';
import { Spinner } from '../../../../../components';
import { ExecutionResult } from 'graphql';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';

const RequestNewCodeButton: FC = () => {
  const [sendNewVerificationCode, { loading, error }] = useMutation(
    MUTATION_MODEL_SERVICE0900_SEND_VERIFICATION_CODE
  );

  return loading ? (
    <Spinner />
  ) : error ? (
    <GenericEmptyContent />
  ) : (
    <span
      css={{
        marginTop: '9px',
        textDecoration: 'underline',
        fontStyle: 'italic',
        cursor: 'pointer',
      }}
      onClick={(): Promise<ExecutionResult<Service0900TargetNumberMutationResult>> =>
        sendNewVerificationCode()
      }
    >
      {_('service0900:PhoneSex.modal.verify.newCode')}
    </span>
  );
};

export default RequestNewCodeButton;
