export const defaults = {
  model: {
    __typename: 'Model',
    mailings: {
      __typename: 'Mailings',
      draft: {
        __typename: 'EmailDraft',
        id: null,
        targetGroup: [],
        subject: '',
        textHTML: '<div />',
        releaseDate: new Date().toISOString(),
      },
    },
  },
};
