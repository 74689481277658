import gql from 'graphql-tag';
import React, { Fragment, FC } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Spinner, EmptyContent, Markdown, T } from '../../components';
import { BLUE, BLUE_HOVER } from '../../camtool-styles';
import ItemAccounting from './ItemAccounting';
import ItemPerformance from './ItemPerformance';
import ItemProfile from './ItemProfile';
import ItemRank from './ItemRank';
import { _ } from '../../util/translate';
import { Main } from '../../atoms/Grid';
import Section from '../../atoms/Grid/Section';

const RANKING_QUERY = gql`
  {
    model {
      commonProfileFillingPercentage
      visitxProfileFillingPercentage
      username
      avatar {
        picture {
          url
          id
          status
          isChecked
        }
      }
      ranking {
        isPunished
        onlineRank
        currentRank
        performancePercentage
        streamQualityPoints
        premiumCooperationStatus
        keyAccounting {
          status
          isRequestAllowed
        }
      }
      account {
        keyAccounter {
          contactEmail
          contactName
          contactPhoneNumber
        }
      }
    }
  }
`;

const defaultData = {
  avatar: { picture: { url: '' } },
  commonProfileFillingPercentage: 0,
  ranking: {
    keyAccounting: {
      isRequestAllowed: false,
      performancePercentage: 0,
      status: 'no',
    },
    premiumCooperationStatus: 'no',
  },
  username: '',
  visitxProfileFillingPercentage: 0,
  account: {
    keyAccounter: {
      contactEmail: undefined,
      contactName: undefined,
      contactPhoneNumber: undefined,
    },
  },
};

const Ranking: FC = () => {
  const { loading, error, data } = useQuery(RANKING_QUERY);

  if (loading) {
    return <Spinner />;
  }

  const {
    username,
    avatar,
    commonProfileFillingPercentage,
    visitxProfileFillingPercentage,
    ranking,
    account: { keyAccounter },
  } = data?.model || defaultData;

  return (
    <Main isMobileEnabled>
      {error ? (
        <Section>
          <EmptyContent title={_('common:error.generic')} />
        </Section>
      ) : (
        <Fragment>
          <Section
            title={_('ranking:newRanking.header')}
            styles={{ padding: '24px 8px 16px' }}
            css={{ marginBottom: 24 }}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                paddingBottom: 24,
              }}
            >
              <ItemRank
                loading={loading}
                onlineRank={ranking.onlineRank}
                modelName={username}
                pictureURL={avatar.picture?.url}
              />

              <ItemProfile
                loading={loading}
                commonProfile={commonProfileFillingPercentage}
                visitXProfile={visitxProfileFillingPercentage}
                history={undefined}
              />

              <ItemAccounting
                loading={loading}
                premiumCoopStatus={ranking.premiumCooperationStatus}
                accountingStatus={ranking.keyAccounting.status}
                accountingAvailable={ranking.keyAccounting.isRequestAllowed}
                contactEmail={keyAccounter ? keyAccounter.contactEmail : undefined}
                contactName={keyAccounter ? keyAccounter.contactName : undefined}
                contactPhoneNumber={keyAccounter ? keyAccounter.contactPhoneNumber : undefined}
              />

              <ItemPerformance
                loading={loading}
                performance={ranking.performancePercentage}
                streamQuality={ranking.streamQualityPoints}
              />
            </div>

            <footer css={{ padding: '0 16px' }}>
              <p css={{ flex: 1, textAlign: 'center', color: '#888' }}>
                <T _={'ranking:newRanking.lastUpdate'} />
              </p>
            </footer>
          </Section>

          <Section styles={{ padding: 40 }}>
            <h3 css={{ display: 'flex', marginBottom: 8 }}>
              <span className="icon-info-sign" />
              <span css={{ marginLeft: 8 }}>
                <T _={'ranking:newRanking.definitionTitle'} />
              </span>
            </h3>
            <Markdown
              source={_('ranking:newRanking.definition')}
              css={{ a: { color: BLUE, '&:hover': { color: BLUE_HOVER } } }}
            />
          </Section>
        </Fragment>
      )}
    </Main>
  );
};

export default Ranking;
