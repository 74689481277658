import React, { createRef, PureComponent } from 'react';

import {
  CompositeDecorator,
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  AtomicBlockUtils,
  ContentBlock,
} from 'draft-js';
import { convertFromHTML } from 'draft-convert';

import { linkDecorator } from './decorators';
import { ALIGNMENT_DATA_KEY } from './utils/ExtendedRichUtils';
import { ExtendedRichUtils, blockStyleFn } from './utils';
import MediaComponent from './MediaComponent';
import LinkPrompt from './Prompts/LinkPrompt';
// import ImagePrompt from './Prompts/ImagePrompt';

import {
  FONT_SIZES,
  FONT_FAMILY,
  Container,
  ToolbarContainer,
  RichButtonGroup,
  RichButton,
  StyleButton,
  EditorContainer,
} from './styles'; // prettier-ignore

import './utils/styles.css';
import 'draft-js/dist/Draft.css';

import { Modal } from '../index';
import ImagePicker from './ImagePicker/ImagePicker';
import { blockRenderMap } from './Blocks';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const decorator = new CompositeDecorator([linkDecorator]);

const styleMap = {
  STRIKETHROUGH: { textDecoration: 'line-through' },
  ...FONT_SIZES,
  ...FONT_FAMILY,
};

interface IState { editorState: EditorState; promptVisible: string; } // prettier-ignore

interface IProps { className?: string; disabled: boolean; initialHTML?: string; onChange?: (editorState: EditorState) => void; onBlur?: (editorState: EditorState) => void; } // prettier-ignore

/** A Rich text editor component. It does not take any props and handle its own state */
class TextEditor extends PureComponent<IProps, IState> {
  public state = {
    editorState: EditorState.createEmpty(decorator),
    promptVisible: '', // LINK | IMAGE
  };

  public editorRef: React.RefObject<Editor> = createRef();

  public componentDidMount() {
    if (this.props.initialHTML) {
      const state = convertFromHTML({
        htmlToBlock: (nodeName, node) => {
          if (nodeName === 'figure') {
            return { type: 'atomic' };
          }
          if (node.style.textAlign) {
            return { type: 'p', data: { textAlignment: node.style.textAlign } };
          }
        },
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === 'a') {
            return createEntity('LINK', 'MUTABLE', { url: (node as HTMLAnchorElement).href });
          }
          if (nodeName === 'img') {
            return createEntity('image', 'IMMUTABLE', { src: (node as HTMLImageElement).src });
          }
        },
      })(this.props.initialHTML);

      this.setState({
        editorState: EditorState.createWithContent(state, decorator),
      });
    }
  }

  public blockRendererFn = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();
    if (type === 'atomic') {
      return {
        component: MediaComponent,
        editable: false,
      };
    }
  };

  public focusEditor = () => {
    if (this.editorRef.current) {
      this.editorRef.current.focus();
    }
  };

  public toggleInlineStyle = (type: string) => {
    this.setState(
      { editorState: RichUtils.toggleInlineStyle(this.state.editorState, type) },
      this.focusEditor
    );
  };

  public toggleBlockType = (type: string) => {
    this.setState(
      { editorState: RichUtils.toggleBlockType(this.state.editorState, type) },
      this.focusEditor
    );
  };

  public toggleBlockTypeFromSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.toggleBlockType(event.target.value);
  };

  public toggleInlineStyleFromSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.toggleInlineStyle(event.target.value);
  };

  public addLink = (url: string) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    this.setState(
      {
        editorState: RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey),
      },
      this.focusEditor
    );
  };

  public addImage = (url: string) => {
    /** https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/media/media.html */
    const contentState = this.state.editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', { src: url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(this.state.editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState({
      editorState: AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '),
    });
  };

  public removeLink = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const currentContentState = this.state.editorState.getCurrentContent();

    const newContentState = Modifier.applyEntity(
      currentContentState,
      this.state.editorState.getSelection(),
      null
    );

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      'apply-entity'
    );

    this.setState({ editorState: newEditorState }, this.focusEditor);
  };

  public isTextSelected = () => {
    const selection = this.state.editorState.getSelection();
    return selection.getStartOffset() - selection.getEndOffset() === 0;
  };

  public handleKeyCommand = (command: string, editorState: EditorState) => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);

    if (newEditorState) {
      this.setState({ editorState: newEditorState });
      return 'handled';
    }
    return 'not-handled';
  };

  public handleEditorStateChange = (editorState: EditorState) => {
    this.setState({ editorState });
    if (this.props.onChange) {
      this.props.onChange(editorState);
    }
  };

  public toggleBlockAlign = (alignDirection: string) => {
    const newEditorState = ExtendedRichUtils.toggleAlignment(
      this.state.editorState,
      alignDirection
    );

    this.setState({ editorState: newEditorState }, this.focusEditor);
  };

  public handleUndoClick = () => {
    this.setState({ editorState: EditorState.undo(this.state.editorState) }, this.focusEditor);
  };

  public handleRedoClick = () => {
    this.setState({ editorState: EditorState.redo(this.state.editorState) }, this.focusEditor);
  };

  public render() {
    const { onBlur, disabled } = this.props;
    return (
      <Container css={{ flex: '1 0 auto' }} className={this.props.className}>
        <ToolbarContainer>
          <RichButtonGroup>
            <select
              defaultValue="FONT:ARIAL"
              onChange={this.toggleInlineStyleFromSelect}
              disabled={disabled}
              css={{ width: 140, padding: 8 }}
            >
              <option value="FONT:ARIAL">Arial</option>
              <option value="FONT:COURIER">Courier</option>
              <option value="FONT:GEORGIA">Georgia</option>
              <option value="FONT:LUCIDA_CONSOLE">Lucida Console</option>
              <option value="FONT:TIMES_NEW_ROMAN">Times New Roman</option>
              <option value="FONT:VERDANA">Verdana</option>
            </select>
          </RichButtonGroup>

          <RichButtonGroup>
            <select
              defaultValue="SIZE:12"
              onChange={this.toggleInlineStyleFromSelect}
              disabled={disabled}
              css={{ width: 62, padding: 8 }}
            >
              <option value="SIZE:8">8</option>
              <option value="SIZE:10">10</option>
              <option value="SIZE:12">12</option>
              <option value="SIZE:16">16</option>
              <option value="SIZE:24">24</option>
              <option value="SIZE:32">32</option>
              <option value="SIZE:64">64</option>
              <option value="SIZE:72">72</option>
              <option value="SIZE:104">104</option>
            </select>
          </RichButtonGroup>

          <RichButtonGroup>
            <StyleButton
              onToggle={this.toggleInlineStyle}
              disabled={disabled}
              style="BOLD"
              active={this.state.editorState.getCurrentInlineStyle().has('BOLD')}
            >
              <span className="icon-bold" />
            </StyleButton>
            <StyleButton
              onToggle={this.toggleInlineStyle}
              disabled={disabled}
              style="ITALIC"
              active={this.state.editorState.getCurrentInlineStyle().has('ITALIC')}
            >
              <span className="icon-italic" />
            </StyleButton>
            <StyleButton
              onToggle={this.toggleInlineStyle}
              disabled={disabled}
              style="UNDERLINE"
              active={this.state.editorState.getCurrentInlineStyle().has('UNDERLINE')}
            >
              <span className="icon-text-underline" />
            </StyleButton>
            <StyleButton
              onToggle={this.toggleInlineStyle}
              disabled={disabled}
              style="STRIKETHROUGH"
              active={this.state.editorState.getCurrentInlineStyle().has('STRIKETHROUGH')}
            >
              <span className="icon-text-strike" />
            </StyleButton>
          </RichButtonGroup>

          <RichButtonGroup>
            <StyleButton onToggle={this.showImagePrompt} disabled={disabled} style="blockquote">
              <span className="icon-picture" />
            </StyleButton>

            {RichUtils.currentBlockContainsLink(this.state.editorState) ? (
              <RichButton onClick={this.removeLink} disabled={disabled}>
                <span className="fas fa-unlink" />
              </RichButton>
            ) : (
              <RichButton
                type="button"
                onClick={this.showLinkPrompt}
                disabled={disabled || this.isTextSelected()}
              >
                <span className="fas fa-link" />
              </RichButton>
            )}
          </RichButtonGroup>

          <RichButtonGroup>
            <StyleButton
              onToggle={this.toggleBlockAlign}
              disabled={disabled}
              style="left"
              active={
                ExtendedRichUtils.getCurrentBlockDataByKey(
                  this.state.editorState,
                  ALIGNMENT_DATA_KEY
                ) === 'left'
              }
            >
              <span className="icon-align-left" />
            </StyleButton>

            <StyleButton
              onToggle={this.toggleBlockAlign}
              disabled={disabled}
              style="center"
              active={
                ExtendedRichUtils.getCurrentBlockDataByKey(
                  this.state.editorState,
                  ALIGNMENT_DATA_KEY
                ) === 'center'
              }
            >
              <span className="icon-align-center" />
            </StyleButton>

            <StyleButton
              onToggle={this.toggleBlockAlign}
              disabled={disabled}
              style="right"
              active={
                ExtendedRichUtils.getCurrentBlockDataByKey(
                  this.state.editorState,
                  ALIGNMENT_DATA_KEY
                ) === 'right'
              }
            >
              <span className="icon-align-right" />
            </StyleButton>

            <StyleButton
              onToggle={this.toggleBlockAlign}
              disabled={disabled}
              style="justify"
              active={
                ExtendedRichUtils.getCurrentBlockDataByKey(
                  this.state.editorState,
                  ALIGNMENT_DATA_KEY
                ) === 'justify'
              }
            >
              <span className="icon-justify" />
            </StyleButton>
          </RichButtonGroup>

          <RichButtonGroup>
            <StyleButton
              onToggle={this.handleUndoClick}
              disabled={disabled || this.state.editorState.getUndoStack().size < 1}
            >
              <span className="icon-undo" />
            </StyleButton>
            <StyleButton
              onToggle={this.handleRedoClick}
              disabled={disabled || this.state.editorState.getRedoStack().size < 1}
            >
              <span className="icon-redo" />
            </StyleButton>
          </RichButtonGroup>

          {/* THIS WORK, BUT WE DO NOT NEED IT YET */}
          {/*<RichButtonGroup>
            <StyleButton
              onToggle={this.toggleBlockType}
              disabled={disabled}
              style="blockquote"
              active={RichUtils.getCurrentBlockType(this.state.editorState) === 'blockquote'}
            >
              <span className="icon-quote" />
            </StyleButton>
          </RichButtonGroup>*/}

          {/* THIS WORK, BUT WE DO NOT NEED IT YET */}
          {/*
          <RichButtonGroup>
            <StyleButton
              onToggle={this.toggleInlineStyle}
              disabled={disabled}
              style="CODE"
              active={this.state.editorState.getCurrentInlineStyle().has('CODE')}
            >
              <span className="fas fa-code" />
            </StyleButton>
          </RichButtonGroup>
           */}

          {/* THIS KINDA WORKS BUT WE DO NOT NEED IT */}
          {/*
          <RichButtonGroup>
            <select
              defaultValue="heading-one"
              onChange={this.toggleBlockTypeFromSelect}
              disabled={disabled}
              css={{ width: 140, padding: '0 8px' }}
            >
              <option value="unstyled">Normal</option>
              <option value="header-one">Überschrift 1</option>
              <option value="header-two">Überschrift 2</option>
              <option value="header-three">Überschrift 3</option>
              <option value="paragraph">Absatz</option>
            </select>
          </RichButtonGroup>
          */}
        </ToolbarContainer>

        <EditorContainer onClick={this.focusEditor}>
          <Editor
            ref={this.editorRef}
            readOnly={disabled}
            customStyleMap={styleMap}
            blockStyleFn={blockStyleFn}
            blockRendererFn={this.blockRendererFn}
            blockRenderMap={blockRenderMap}
            editorState={this.state.editorState}
            onChange={this.handleEditorStateChange}
            onBlur={() => onBlur && onBlur(this.state.editorState)}
            handleKeyCommand={this.handleKeyCommand}
          />
        </EditorContainer>

        {this.state.promptVisible === 'LINK' && (
          <LinkPrompt onAddLink={this.addLink} onClose={this.hidePrompt} />
        )}
        {this.state.promptVisible === 'IMAGE' && (
          <Modal onBackgroundClick={() => this.showPrompt('')}>
            <ImagePicker
              onCancelClick={() => this.showPrompt('')}
              onPictureSelect={(picture) => {
                this.showPrompt('');
                this.addImage(picture.url);
              }}
              maxFileSize={MAX_FILE_SIZE}
            />
          </Modal>
        )}
      </Container>
    );
  }

  private hidePrompt = () => this.setState({ promptVisible: '' });

  private showPrompt = (type: 'LINK' | 'IMAGE' | '' = '') => this.setState({ promptVisible: type });

  private showLinkPrompt = () => this.showPrompt('LINK');

  private showImagePrompt = () => this.showPrompt('IMAGE');
}

export default TextEditor;
