import { ClientsNamespace } from './types';
import { ApolloClient } from 'apollo-client';
import { createContext, useContext } from 'react';

interface ApolloMultipleClientContextInterface {
  getClient: (namespace: ClientsNamespace) => ApolloClient<{}> | undefined;
}

export const ApolloMultipleClientsContext = createContext<ApolloMultipleClientContextInterface>({
  getClient: () => undefined,
});
ApolloMultipleClientsContext.displayName = 'ApolloMultipleClientsContext';

export const useApolloClient = (namespace: ClientsNamespace): ApolloClient<{}> => {
  const client = useContext(ApolloMultipleClientsContext).getClient(namespace);
  if (!client) throw new Error(`Could not find client for namespace "${namespace}"`);

  return client;
};
