import React, { FC, ReactNode } from 'react';

interface PageLayoutProps {
  title: string;
  subtitle: string;
  children: ReactNode;
  footer?: () => JSX.Element;
  className?: string;
}

const PageLayout: FC<PageLayoutProps> = ({ footer, title, subtitle, children, className }) => (
  <div
    className={className}
    css={{ flexDirection: 'column', flex: 1, maxWidth: 768, width: '100%' }}
  >
    <article css={{ flexDirection: 'column', flex: 1, padding: 32 }}>
      <header css={{ flexDirection: 'column', marginBottom: 32 }}>
        <h2 css={{ marginBottom: 4 }}>{title}</h2>
        <p css={{ fontWeight: 300 }}>{subtitle}</p>
      </header>

      {children}
    </article>
    {typeof footer === 'function' && footer()}
  </div>
);

export default PageLayout;
