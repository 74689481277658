import React from 'react';
import update from 'immutability-helper';
import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../util/ServerUtils';
import ActionTypes from '../../util/constants/ActionTypes';
import EventEmitter from 'events';

let CHANGE_EVENT = 'FinancesStore.change';

let storeData = {
  overview: {
    allowedYears: [],
    allowedMonths: [],
    allowedPeriods: [],
    selectedPeriodStart: '',
    selectedPeriodEnd: '',
    selectedMonthPart: '',
    selectedMonth: '',
    selectedYear: '',
    totalAmountForSelectedPeriod: 0.0,
    totalVideochatDuration: 0,
    totalContentSales: 0,
    totalPhoneServiceDuration: 0,
    totalBA6Duration: 0,
    totalVX0900Duration: 0,
    turnoverDetails: [],
    turnoverGroups: [],
    sharingChat: 0,
    sharingShop: 0,
    sharingTelegram: 0,
  },
  payout: {
    payableAmount: 0.0,
    minAmountPayable: 0.0,
    payoutList: [],
    requestInfo: [],
    billingList: [],
  },
  scheduler: {
    currentType: 0,
    currentAmount: 0,
    allowedTypes: [],
    allowedAmounts: [],
  },
  payoutrequest: {
    payableAmount: 0.0,
    minAmountPayable: 0.0,
    billingList: [],
  },
};

class FinancesStoreClass extends EventEmitter {
  emitChange(store) {
    this.emit(CHANGE_EVENT + '.' + store);
  }
  addChangeListener(store, callback) {
    this.on(CHANGE_EVENT + '.' + store, callback);
  }
  removeChangeListener(store, callback) {
    this.removeListener(CHANGE_EVENT + '.' + store, callback);
  }
  get(store) {
    if (!storeData[store]) {
      throw new InvalidStoreException(store);
    }
    return storeData[store];
  }
}

class FinancesActionCreatorsClass {
  getOverviewData(model) {
    let additionalQuery = '';
    if (model) {
      additionalQuery =
        '?finances_period=' +
        model.finances.overview.period +
        '&finances_month=' +
        model.finances.overview.month +
        '&finances_year=' +
        model.finances.overview.year;
    }
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/finances/overview' + additionalQuery,
      null,
      function (response) {
        FinancesActionCreators.receiveData('overview', response);
      }
    );
  }

  getPayoutData() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/finances/payout',
      null,
      function (response) {
        FinancesActionCreators.receiveData('payout', response);
      }
    );
  }

  getSchedulerData() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/finances/scheduler',
      null,
      function (response) {
        FinancesActionCreators.receiveData('scheduler', response);
      }
    );
  }

  getPayoutRequestData() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/finances/payoutrequest',
      null,
      function (response) {
        FinancesActionCreators.receiveData('payoutrequest', response);
      }
    );
  }

  savePayoutRequest() {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/finances/savePayoutRequest',
      { data: JSON.stringify({}) },
      function (response) {}
    );
  }

  cancelPayoutRequest() {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/finances/cancelPayoutRequest',
      { data: JSON.stringify({}) },
      function (response) {}
    );
  }

  savePayoutAssistant(data, onSuccess) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/finances/savePayoutAssistant',
      { data: JSON.stringify(data) },
      onSuccess
    );
  }

  receiveData(store, data) {
    AppDispatcher.dispatch({
      type: ActionTypes.FINANCE_RECEIVE_DATA,
      store: store,
      data: data,
    });
  }
}

function InvalidStoreException(store) {
  this.store = store;
  this.message = 'Invalid store';
  this.toString = function () {
    return this.message + ': ' + this.store;
  };
}

AppDispatcher.register(function (payload) {
  switch (payload.type) {
    case ActionTypes.FINANCE_RECEIVE_DATA:
      let store = payload.store;
      storeData[store] = update(storeData[store], { $merge: payload.data });
      FinancesStore.emitChange(store);
      break;
    default:
    // do nothing
  }
});

const FinancesStore = new FinancesStoreClass();
const FinancesActionCreators = new FinancesActionCreatorsClass();

export { FinancesStore, FinancesActionCreators };
