import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BLUE, BLUE_VARIANT, WHITE } from '../../camtool-styles';
import { Box, Typography } from '@material-ui/core';

interface TeaserBannerProps {
  background?: string;
  primaryText: string;
  secondaryText?: string;
}

const TeaserImage = styled.div<Pick<TeaserBannerProps, 'background'>>`
  position: relative;
  ${(props): string =>
    props.background
      ? `background-image: url(${props.background})`
      : `background: transparent linear-gradient(180deg, ${BLUE_VARIANT} 0%, ${BLUE} 100%) 0% 0% no-repeat padding-box`};
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 250px;
`;

const TeaserBanner: FC<TeaserBannerProps> = ({ background, primaryText, secondaryText }) => (
  <Box color={WHITE} mb={3}>
    <TeaserImage background={background}>
      <Box display="flex" flexDirection="column" width="100%" justifyContent="center" p={2}>
        <Typography variant="h6" color="inherit" align="center" gutterBottom>
          {primaryText}
        </Typography>
        <Box alignSelf="center" maxWidth={{ md: '420px' }}>
          <Typography variant="subtitle1" color="inherit" align="center">
            {secondaryText}
          </Typography>
        </Box>
      </Box>
    </TeaserImage>
  </Box>
);

export default TeaserBanner;
