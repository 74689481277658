import React from 'react';
import PropTypes from 'prop-types';
import AbstractAlbumView from '../AbstractAlbumView';
import Spinner from '../../../Spinner/Spinner';
import EmptyContent from '../../../EmptyContent/EmptyContent';
import AlbumItem from '../AlbumItem/AlbumItem';
import { _ } from '../../../../util/translate';

class AlbumViewPicturePool extends AbstractAlbumView {
  render() {
    return (
      <div
        className="mediamanagement__pic__album spinner-container min-height--0"
        onClick={this.onContentClick}
      >
        {this.props.isLoading && <Spinner />}
        {this.props.album.pictures.length <= 0 && (
          <EmptyContent
            icon="icon-folder-open"
            title={_('mediamanagement:picfolder.common.noPicturesAvailable')}
          />
        )}
        <div className="mediamanagement__pic__album__content" css={{ maxHeight: 610 }}>
          {this.props.album.pictures.map(picture => (
            <AlbumItem
              key={picture.pictureId}
              data={picture}
              album={this.props.album}
              showInChecking
              showDetails
              isSelected={this.isPictureSelected(picture)}
              isSelectable={this.props.filter(picture)}
              allowSelection={true}
              onClick={this.selectPicture}
              onClickDelete={this.removePicture}
              showNotSelectable={this.props.showNotSelectable}
            />
          ))}
        </div>
      </div>
    );
  }
}

AlbumViewPicturePool.propsTypes = Object.assign(AbstractAlbumView.propTypes, {
  showNotSelectable: PropTypes.bool,
  filter: PropTypes.func,
});

AlbumViewPicturePool.defaultProps = Object.assign(AbstractAlbumView.defaultProps, {
  showNotSelectable: true,
  filter: () => {
    return true;
  },
});

AlbumViewPicturePool.debug = false;

export default AlbumViewPicturePool;
export { AlbumViewPicturePool };
