import React, { Fragment, memo } from 'react';
import { Query } from '@apollo/react-components';

import { Footer, NotificationBox, Spinner } from '../../components';
import { useHasRole, useUserData } from '../../util/UserData';
import styled from '@emotion/styled';
import { ChatOnline } from './WidgetsLarge';
import { Main } from '../../atoms';

import { QUERY_DASHBOARD_INITIAL } from './graphql';

import { SecurityRole } from '../../graphql/VXModels/types';
import { _ } from '../../util/translate';
import { getTimezone } from '../../util/timezone';
import { Container, Grid } from '@material-ui/core';
import VXModelsDashboard from './Scopes/VXModelsDashboard';
import GridTileItem from '../../atoms/DashboardTile/GridTileItem';

const DashboardSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const DashboardMain = memo(() => {
  const userData = useUserData();
  const hasRoleMaster = useHasRole(SecurityRole.VX_USER_MASTER);
  const userPrivileged =
    useHasRole(SecurityRole.VX_USER_MASTER) ||
    userData.model.permissions.isFinancesVisibleWithoutMasterPassword;
  const hasRoleAdmin = useHasRole(SecurityRole.VX_USER_ADMIN);

  return (
    <Query
      query={QUERY_DASHBOARD_INITIAL}
      variables={{ timezone: getTimezone() }}
      fetchPolicy={'network-only'}
    >
      {({ loading, data }) => {
        let {
          tvshows,
          commonProfileFillingPercentage,
          wasOnlineWithSSW,
          dashboard,
          isOnlineWithSSW,
          topAmateur,
          media,
          vxChallenges
        } =
          !loading && data
            ? data.model
            : {
              dashboard: {},
              wasOnlineWithSSW: false,
              commonProfileFillingPercentage: 0,
              tvshows: { planned: [] },
              isOnlineWithSSW: true,
              topAmateur: {},
              avatar: {},
              media: {},
              vxChallenges: {}
            }; // prettier-ignore
        const album = media.previewPicture16?.album;
        const picture = media.previewPicture16?.picture;
        const getSlots = topAmateur?.getSlots || {};
        const hasTopAmateurSlots = getSlots.length || false;
        const currentChallenge = vxChallenges?.challenges?.[0];

        return (
          <Fragment>
            <Main isMobileEnabled>
              {!hasRoleMaster && (
                // DASHBOARD FOR STUDIO MODELS WITHOUT MASTER MASWORD
                <Container maxWidth={false}>
                  <GridTileItem
                    content={
                      loading ? (
                        <DashboardSpinner>
                          <Spinner inline={true} size={'s'} />
                        </DashboardSpinner>
                      ) : (
                        <ChatOnline />
                      )
                    }
                    title={loading ? '' : _('dashboard:app.chatOnline.header')}
                  />
                </Container>
              )}
              {hasRoleMaster && (
                <VXModelsDashboard
                  loading={loading}
                  dashboard={dashboard}
                  userPrivileged={userPrivileged}
                  wasOnlineWithSSW={wasOnlineWithSSW}
                  isOnlineWithSSW={isOnlineWithSSW}
                  album={album}
                  picture={picture}
                  commonProfileFillingPercentage={commonProfileFillingPercentage}
                  userData={userData}
                  hasTopAmateurSlots={hasTopAmateurSlots}
                  tvshows={tvshows}
                  topAmateur={topAmateur}
                  vxChallenge={currentChallenge}
                  vxgamesAllowed={userData?.model?.vxChallenges.isAllowed}
                />
              )}
              {!hasRoleAdmin && <NotificationBox />}
              <Grid container item className="grid__row grid__box">
                <Footer className="grid__box__item" />
              </Grid>
            </Main>
          </Fragment>
        );
      }}
    </Query>
  );
});

DashboardMain.displayName = 'DashboardMain';
export default DashboardMain;
