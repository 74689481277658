import React, { ReactNode } from 'react';
import { useUserData } from '../../../../../util/UserData';
import { DailyBonusOptInStatusEnum, Query } from '../../../../../graphql/VXModels/types';
import DailyBonusOptInMessage from './DailyBonusOptInMessage';

interface IProps {
  children: ReactNode;
}

const DailyBonusOptInWrapper = ({ children }: IProps): ReactNode => {
  const userData = useUserData() as Partial<Query>;

  const optInStatus = userData.model?.media?.dailyBonusOptInStatus;

  const hasOptedIn = optInStatus === DailyBonusOptInStatusEnum.optIn;

  return hasOptedIn ? children : <DailyBonusOptInMessage />;
};

export default DailyBonusOptInWrapper;
