import React from 'react';

import { T } from '../../../../components';

class NotAllowed extends React.Component {
  isTooLessRankingPoints = () => this.props.isTooLessRankingPoints;

  isPayoutAllowed = () => this.props.isPayoutAllowed;

  render() {
    return (
      <article className="admails-notallowed">
        <div className="formwidth">
          <div className="text">
            <T _={'marketing:admails.notallowed.info'} />
          </div>
          <div className="payout">
            <div className={`${this.isPayoutAllowed() ? 'icon-ok' : 'icon-ban-circle'} iconbox `} />
            <div className="textbox">
              <T _={'marketing:admails.notallowed.condition.payout'} />
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default NotAllowed;
