import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import NewButton from '../../../../atoms/Button/NewButton';
import { _ } from '../../../../util/translate';

interface Props {
  loading: boolean;
  disabled: boolean;
}

const PublishDescriptionSection: FC<Props> = ({ loading = false, disabled = false }) => {
  return (
    <>
      <Box>
        <NewButton type="submit" loading={loading} disabled={disabled}>
          <Typography variant="button">
            {_('telegram:channeldescription.content.publish')}
          </Typography>
        </NewButton>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1" align="center">
          {_('telegram:channeldescription.content.warning')}
        </Typography>
      </Box>
    </>
  );
};

export default PublishDescriptionSection;
