import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { nameplate } from '../../../../../atoms/Icon/libraries/glyph';

const CommonProfileItem: FC = () => {
  return (
    <LeftMenuItem
      title={_('navigation:main.profile.common')}
      icon={nameplate}
      uri="/onlineprofile/common"
    />
  );
};

export default CommonProfileItem;
