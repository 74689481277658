import { useQuery } from '@apollo/react-hooks';
import { useVXServicesTelegramClient } from '../../../graphql';
import { Query } from '../../../graphql/VXServicesTelegram/types';
import { QUERY_MASTER_MIMETYPES } from '../graphql/queries';
import { useVXServicesTelegramContext } from './index';

export const useTelegramGetMimeTypes = (isVip: boolean) => {
  const { token } = useVXServicesTelegramContext();
  const variables = { token, vip: isVip };
  return useQuery<Query>(QUERY_MASTER_MIMETYPES, {
    client: useVXServicesTelegramClient(),
    variables,
  });
};

export default useTelegramGetMimeTypes;
