import React from 'react';
import PropTypes from 'prop-types';
import { ClassNames } from '@emotion/core';
import AbstractFormMultiOptionsComponent from '../AbstractFormMultiOptionsComponent';
import MaxValueLength from '../../Display/MaxValueLength/MaxValueLength';
//import Button from '../../../Button/Button';
import Button from '../../../../atoms/Button/Button';

class TagListDropdown extends AbstractFormMultiOptionsComponent {
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state || {}, {
      selectedOption: {
        value: '_placeholder',
      },
    });
  }

  onSelectedOptionChange(event) {
    for (let option of this.options) {
      if (option.value === event.target.value) {
        this.setState({ selectedOption: option });
        if (this.props.hideAddButton) {
          this.addValue(event.target.value);
          this.setState({ selectedOption: { value: '_placeholder' } });
        }
        break;
      }
    }
  }

  addSelectedOption(event) {
    event.stopPropagation();
    if (this.state.selectedOption.value && this.state.selectedOption.value !== '_placeholder') {
      this.addOption(this.state.selectedOption);
      this.setState({ selectedOption: { value: '_placeholder' } });
    }
  }

  render() {
    const { maxLength, minLength, name, placeholder, iconPrefix } = this.props;
    const { selectedOption } = this.state;
    const selectedOptions = this.getSelectedOptions();
    const isRemoveable = selectedOptions.length > minLength;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <div
            className={cx('taglistdropdown', this.getClassNames(), {
              'maxvaluelength-anchorframe': maxLength > 0,
            })}
          >
            {maxLength > 0 && <MaxValueLength max={maxLength} value={selectedOptions.length} />}

            <div>
              <div className="taglistdropdown__input">
                <div>
                  <div className={cx('dropdown', { disabled: this.isDisabled() })}>
                    <div className="dropdown__column">
                      <select
                        ref={`TagListControlDropdown.Dropdown.${name}`}
                        disabled={this.isDisabled()}
                        onChange={this.onSelectedOptionChange}
                        value={selectedOption.value}
                      >
                        <option disabled value="_placeholder">
                          {placeholder}
                        </option>

                        {this.options.map(option => (
                          <option
                            key={option.value}
                            value={option.value}
                            disabled={this.isOptionDisabled(option)}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {!this.props.hideAddButton && (
                  <div>
                    <Button
                      label={this.props.labelAddButton}
                      type="button"
                      onClick={this.addSelectedOption}
                      disabled={this.isDisabled()}
                    />
                  </div>
                )}
              </div>
              <div className="taglistdropdown__view">
                <div>
                  <div className="tag-list">
                    {selectedOptions.map(option => (
                      <div key={option.value}>
                        <div>
                          <div className={iconPrefix ? `${iconPrefix + option.value} icon` : ''} />
                          <div>{option.label}</div>
                          <div
                            className="icon-remove"
                            onClick={this.removeOption.bind(this, option)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ClassNames>
    );
  }
}

TagListDropdown.propTypes = Object.assign(AbstractFormMultiOptionsComponent.propTypes, {
  hideAddButton: PropTypes.bool,
});

TagListDropdown.defaultProps = Object.assign(AbstractFormMultiOptionsComponent.defaultProps, {
  hideAddButton: false,
});

export default TagListDropdown;
export { TagListDropdown };
