import React, { FC } from 'react';
import { useLang } from '../../../../../util/AppState';
import { getFormattedDate } from '../../../../../util/Formatter';
import { _ } from '../../../../../util/translate';
import { Code } from '../../../types';
import { BACKEND_TO_FRONTEND_VOUCHER_TYPES } from '../../../utils';

interface Props {
  code: Code;
}

const ModalBonusCodeHeader: FC<Props> = ({ code }) => {
  const [lang] = useLang();

  const benefitType = code.config.benefits[0].type;
  return (
    <div css={{ flexDirection: 'column', textAlign: 'center', marginBottom: '35px' }}>
      <h2 css={{ fontSize: 24 }}>
        {benefitType
          ? `${_(
              `benefits:voucher.${BACKEND_TO_FRONTEND_VOUCHER_TYPES[
                benefitType
              ].toLowerCase()}.name`
            )} (Start ${getFormattedDate(code.config.validFrom, lang)})`
          : ''}
      </h2>
      <p css={{ fontSize: 16, marginTop: 8 }}>
        {_('benefits:voucher.archive.modal.header.duration')}{' '}
        <span>
          {code.config.validTo === null
            ? _('benefits:voucher.archive.modal.header.durationPermanent')
            : `${_('benefits:voucher.archive.modal.header.durationUntil')} ${getFormattedDate(
                code.config.validTo,
                lang
              )}`}
        </span>
      </p>
    </div>
  );
};

export default ModalBonusCodeHeader;
