import { AlertsStore } from '../Alerts/AlertsStore';
import FileValidator from '../../util/FileValidator';
import { JsUploader } from './lib/JsUploader.ts';
import ServerUtils from '../../util/ServerUtils';
import { EventEmitter } from 'events';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import { log } from '../../util/errorHandling';
import * as ActionTypes from '../../util/constants/ActionTypes';
import {
  MediaManagementActionCreators,
  MediaManagementStore,
  RESOURCE_ALBUM,
} from '../MediaManagement/MediaManagement';

import { MEDIA_PICTURE_UPLOAD_MIN_HEIGHT, MEDIA_PICTURE_UPLOAD_MIN_WIDTH } from '../../config';
import { EventEmitter2 } from 'eventemitter2';
import { _ } from '../../util/translate';

const CHECKSUM_WORKER_URL = require('file-loader!./../../lib/hashtask.min.js');

const sortMap = {
  undef: 1,
  file_ref_error: 1,
  auth_error: 1,
  response_error: 1,
  network_error: 1,
  duplicate_error: 1,
  read_error: 1,
  file_type_error: 1,
  picture_size_error: 1,
  run: 2,
  queued: 3,
  transcoding: 4,
  complete: 5,
};

function sortQueue(queue) {
  queue = queue || [];
  for (let i = 0; i < queue.length; i++) {
    queue[i].pos = i;
  }
  queue.sort((a, b) => {
    let sort;
    sort = sortMap[a.state] - sortMap[b.state];
    if (sort === 0) {
      sort = a.pos - b.pos;
    }
    return sort;
  });

  return queue;
}

const logger = (() => {
  return {
    trace: (message, context) => log('debug', message, context),
    debug: (message, context) => log('debug', message, context),
    info: (message, context) => log('info', message, context),
    warn: (message, context) => log('warning', message, context),
    error: (message, context) => log('error', message, context),
    fatal: (message, context) => log('critical', message, context),
  };
})();

/**
 * JsUploaderPictureStore
 */

const pictureFileValidator = new FileValidator({
  conditions: {
    pictureDimension: {
      minWidth: MEDIA_PICTURE_UPLOAD_MIN_WIDTH,
      minHeight: MEDIA_PICTURE_UPLOAD_MIN_HEIGHT,
    },
    useHash: false, // hash validation is done by jsUploader itself
  },
});

const JsUploaderPictureStore = new JsUploader({
  uploadType: 'picture',
  logger,
  options: {
    validator: function (entry) {
      pictureFileValidator.setConditionFileType(
        JsUploaderPictureStore.state.options.allowedFileExtensions
      );
      pictureFileValidator.validate(
        entry.file,
        () => {},
        (errorState) => {
          this.emit('updateEntryState', entry, errorState);
        }
      );
    },
    initPaused: false,
    checksumWorkerUrl: CHECKSUM_WORKER_URL,
  },
});

JsUploaderPictureStore.on('complete', () => {
  AlertsStore.add({
    type: 'info',
    message: _('common:alerts.uploadFinished'),
    duration: 5000,
  });

  const data = MediaManagementStore.get(RESOURCE_ALBUM);
  if (data.album.albumType === 'pool') {
    MediaManagementActionCreators.readResource(RESOURCE_ALBUM, {
      routeParams: {
        albumId: '_pool',
      },
    });
  }
});

JsUploaderPictureStore.on('fail', (entry) => {
  AlertsStore.add({
    type: 'error',
    message: _('common:alerts.uploadFailed'),
    duration: 5000,
  });
  console.error('JsUploaderPictureStore', 'on fail', entry);
});

JsUploaderPictureStore.on('canceled', () => {
  AlertsStore.add({
    type: 'check',
    message: _('common:alerts.uploadCanceled'),
    duration: 5000,
  });
});

/**
 * JsUploaderVideoStore
 */
class JsUploaderVideoStoreClass extends EventEmitter2 {
  constructor() {
    super();
    this.initialized = false;
    this.jsUploader = new JsUploader({
      uploadType: 'video',
      logger,
      options: {
        initPaused: false,
        checksumWorkerUrl: CHECKSUM_WORKER_URL,
        fields: {
          uma_subtype: '6', // Poolvideo
        },
      },
    });

    this.uploadType = this.jsUploader.uploadType;
    this.logger = this.jsUploader.logger;
    this.options = this.jsUploader.options;
    this.queue = [];
    this.state = {
      userId: this.jsUploader.state.userId,
      sessionId: this.jsUploader.state.sessionId,
      options: this.jsUploader.state.options,
      isActive: this.jsUploader.state.isActive,
      isPaused: this.jsUploader.state.isPaused,
      currentUploadEntry: this.jsUploader.state.currentUploadEntry,
      queue: this.queue,
    };

    this.serverQueue = [];
    this.websocketQueue = [];

    this.init = this.init.bind(this);
    this.addFiles = this.addFiles.bind(this);
    this.startUploads = this.startUploads.bind(this);
    this.pauseUploads = this.pauseUploads.bind(this);
    this.mergeQueues = this.mergeQueues.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.prioritizeEntry = this.prioritizeEntry.bind(this);
    this.setServerQueue = this.setServerQueue.bind(this);
    this.getTranscodingStatus = this.getTranscodingStatus.bind(this);
    this._updateState = this._updateState.bind(this);

    AppDispatcher.register((payload) => {
      switch (payload.type) {
        case ActionTypes.MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA:
          if (payload.data.event === 'media.video.transcoding.finished') {
            this.websocketQueue.push(payload.data.entry);
            this._updateState();
          }
          break;
      }
    });
  }

  init(userId, sessionId) {
    if (!this.initialized) {
      this.jsUploader.on('init', (userId, sessionId) => {
        this.state.userId = userId;
        this.state.sessionId = sessionId;
      });
      this.jsUploader.on('updateQueue', () => {
        this._updateState();
      });

      this.jsUploader.on('complete', (entry) => {
        var pseudoEntry = cloneDeep(entry);
        pseudoEntry.state = 'transcoding';
        this.serverQueue.push(pseudoEntry);
        this.setServerQueue(this.serverQueue);

        AlertsStore.add({
          type: 'info',
          message: _('common:alerts.uploadFinished'),
          duration: 5000,
        });

        setTimeout(() => {
          this.getTranscodingStatus();
        }, 2000);
      });

      this.jsUploader.on('fail', (entry) => {
        AlertsStore.add({
          type: 'error',
          message: _('common:alerts.uploadFailed'),
          duration: 5000,
        });
        console.error('JsUploaderVideoStore', 'on fail', entry);
      });

      this.jsUploader.on('canceled', (entry) => {
        AlertsStore.add({
          type: 'check',
          message: _('common:alerts.uploadCanceled'),
          duration: 5000,
        });
      });

      this.jsUploader.on('stateChanged', () => {
        this._updateState();
      });

      this.jsUploader.init(userId, sessionId);
      this.initialized = true;
    }
  }

  addFiles(fileList) {
    let url = window.location.href === '' ? document.URL : window.location.href;
    log('warning', 'Error: trigger URL: ' + url + ' state:' + this.state, {
      context: 'JsUploader',
    });
    this.jsUploader.addFiles(fileList);
  }

  startUploads() {
    let url = window.location.href === '' ? document.URL : window.location.href;
    log('warning', 'Error: trigger URL: ' + url + ' state:' + this.state, {
      context: 'JsUploader',
    });
    this.jsUploader.startUploads();
  }

  pauseUploads() {
    this.jsUploader.pauseUploads();
  }

  mergeQueues(queue, serverQueue, websocketQueue) {
    let key;
    const mergedQueue = [];
    const mergedMap = {};

    for (let entry of queue) {
      // if checksum has already calculated, otherwise internal key
      key = entry.key;
      mergedMap[key] = entry;
    }

    for (let entry of serverQueue) {
      // entry has always a checksum
      key = entry.key;
      mergedMap[key] = entry;
    }

    for (let entry of websocketQueue) {
      // entry has always a checksum
      key = entry.key;
      mergedMap[key] = entry;
    }

    // console.log(this.__proto__.constructor.name, 'mergeQueues', queue, serverQueue, websocketQueue, mergedMap);

    for (let prop in mergedMap) {
      mergedQueue.push(mergedMap[prop]);
    }

    return mergedQueue;
  }

  removeEntry(entry) {
    remove(this.websocketQueue, function (websocketQueueEntry) {
      return websocketQueueEntry.key === entry.key;
    });
    this.jsUploader.removeEntry(entry);
  }

  prioritizeEntry(entry) {
    this.jsUploader.prioritizeEntry(entry);
  }

  setServerQueue(nextServerQueue) {
    this.serverQueue = nextServerQueue;
    this._updateState();
  }

  getTranscodingStatus() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/media/video/transcoding',
      null,
      (response) => {
        this.setServerQueue(response);
      }
    );
  }

  _updateState() {
    this.state.isActive = this.jsUploader.state.isActive;
    this.state.isPaused = this.jsUploader.state.isPaused;
    this.state.currentUploadEntry = this.jsUploader.state.currentUploadEntry;
    this.state.queue = sortQueue(
      this.mergeQueues(this.jsUploader.queue, this.serverQueue, this.websocketQueue)
    );
    this.emit('stateChanged');
  }
}

const JsUploaderVideoStore = new JsUploaderVideoStoreClass();

class JsUploaderManagerClass extends EventEmitter {
  constructor() {
    super();
    this.busy = false;

    this.onChange = this.onChange.bind(this);
    this.isBusy = this.isBusy.bind(this);

    JsUploaderPictureStore.on('updateQueue', this.onChange);
    JsUploaderVideoStore.on('stateChanged', this.onChange);
  }

  onChange() {
    let nextBusy = this.isBusy();
    if (nextBusy !== this.busy) {
      this.busy = nextBusy;
      if (this.busy) {
        this.emit('onbusy');
      } else {
        this.emit('onidle');
      }
    }
  }

  isBusy() {
    const pictureBusy =
      JsUploaderPictureStore.state.isActive || JsUploaderPictureStore.state.isPaused;
    const videoBusy = JsUploaderVideoStore.state.isActive || JsUploaderVideoStore.state.isPaused;
    return !!(pictureBusy || videoBusy);
  }
}

const JsUploaderManager = new JsUploaderManagerClass();

export { JsUploaderVideoStore, JsUploaderPictureStore, JsUploaderManager };
