import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import IconBase from '../../../atoms/Icon/IconBase';
import LockedPhone from '../../../atoms/Icon/LockedPhone';
import SharePhone from '../../../atoms/Icon/SharePhone';
import { NavSub, NavSubItem } from '../../../components';
import { _ } from '../../../util/translate';
import { stripSlash } from '../../../util/urlHelper';

interface IAutoMail {
  isTelegramChannel: boolean;
}

const AutoMailNav: React.FC<IAutoMail> = (isTelegramChannel) => {
  const { url } = useRouteMatch();
  const path = stripSlash(url);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const indexRoute = !isMobile ? `${path}/desktop` : `${path}/mobile`;

  return (
    <NavSub>
      {!isTelegramChannel && (
        <NavSubItem
          link={`${indexRoute}/free-lifecycle`}
          icon={<IconBase iconElement={<SharePhone />} />}
          label={_('mailings:telegram.sectionTitle.autoMail.free')}
        />
      )}
      <NavSubItem
        link={`${indexRoute}/vip-lifecycle`}
        icon={<IconBase iconElement={<LockedPhone />} />}
        label={_('mailings:telegram.sectionTitle.autoMail.VIP')}
      />
    </NavSub>
  );
};

export default AutoMailNav;
