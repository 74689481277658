import { ApiLang } from './../../../util/constants/ApiLang';
import { QueryResult } from '@apollo/react-common';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { QUERY_WIDGET_BULLETTEXTLIST } from '../graphql/queries';
import { Query } from '../../../graphql/VXServicesTelegram/types';
import { useVXServicesTelegramClient } from '../../../graphql';

export interface ChannelAdvantagesArgs {
  actorId: string;
  language: string;
}

const useGetChannelAdvantages = (
  actorId: string,
  channelLanguage: ApiLang,
  options?: QueryHookOptions<Query, ChannelAdvantagesArgs>
): QueryResult => {
  const client = useVXServicesTelegramClient();

  return useQuery<Query, ChannelAdvantagesArgs>(QUERY_WIDGET_BULLETTEXTLIST, {
    ...options,
    client,
    variables: {
      actorId: actorId,
      language: channelLanguage,
    },
  });
};

export default useGetChannelAdvantages;
