import { FileDescriptor } from '../PreviewFile';
import { PreviewVideoThumbnailIconSize } from '../PreviewFile';

export enum MediaUploadInputLabelSize {
  md = 'md',
  sm = 'sm',
}

export enum MediaUploadInputLayoutDirection {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export interface MediaUploadInputProps {
  label: string;
  accept: string;
  disabled?: boolean;
  loading?: boolean;
  currentFile?: FileDescriptor;
  labelSize?: MediaUploadInputLabelSize;
  videoThumbnailIconSize?: PreviewVideoThumbnailIconSize;
  layoutDirection?: MediaUploadInputLayoutDirection;
  className?: string;
  onSelectFile: (file: File) => void;
  onRemoveFile: () => void;
}
