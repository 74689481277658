import React, { FC } from 'react';
import AudioMessageIcon from '../../../../atoms/Icon/AudioMessageIcon';
import { WHITE, BLUE_HOVER, GRAY_2, GRAY_3, GRAY_5 } from '../../../../camtool-styles';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import AudioPlayer from '../../../../components/AudioPlayer/AudioPlayer';
import { PreviewAudioOptions } from './types';
import IconBase from '../../../../atoms/Icon/IconBase';

interface Props {
  url: string;
  fontSize?: string;
  options?: PreviewAudioOptions;
  hasBorder?: boolean;
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg path': {
        fill: BLUE_HOVER,
      },
    },
  },
});

const PreviewAudio: FC<Props> = ({ url, options, fontSize = '55px', hasBorder }) => {
  const thumbnailOnly = options?.thumbnailOnly ?? false;
  const classes = useStyles();
  return thumbnailOnly ? (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor={WHITE}
      height="100%"
      width="100%"
      border={hasBorder ? 1 : 0}
      borderColor={GRAY_3}
    >
      <IconButton classes={classes} disableRipple={true}>
        <IconBase fontSize={fontSize} iconElement={<AudioMessageIcon />} viewBox="0 0 83 43.882" />
      </IconButton>
    </Box>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      bgcolor={GRAY_5}
      border={1}
      borderColor={GRAY_3}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <IconBase fontSize={fontSize} iconElement={<AudioMessageIcon />} viewBox="0 0 83 43.882" />
      </Box>
      <Box bgcolor={GRAY_2} width="100%">
        <AudioPlayer url={url} />
      </Box>
    </Box>
  );
};

export default PreviewAudio;
