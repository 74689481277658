import React, { FC } from 'react';
import styled from '@emotion/styled';
// Outside Wizard
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import Select from '../../../../../atoms/Select/Select';
import { _ } from '../../../../../util/translate';
// Inside Wizard
import { IContentOthersProps } from '../../../types';
import useContent from '../useContent';
import Layout from '../Layout';

const SelectRow = styled.div`
  align-items: center;
  flex: 0;
`;

const ContentMinutes: FC<IContentOthersProps> = ({
  state,
  dispatch,
  className,
  availableContentMinAmountsArray,
}) => {
  const [
    { contentData, redemptionType, redemptionAmount },
    { setRedemptionType, setRedemptionAmount, setContentData },
  ] = useContent('CHAT_MINUTES', state, dispatch, 10);

  const handleRedemptionTypeChange = (type: string) => {
    if (type === 'GRATIS') {
      setRedemptionType(type);
      setRedemptionAmount(0);
    } else if (type === 'ON_TOP') {
      setRedemptionType(type);
      setRedemptionAmount(-1);
    } else {
      setRedemptionType('');
      setRedemptionAmount(-1);
    }
  };

  const handleRedemptionAmountChange = (amount: string) => {
    setRedemptionAmount(Number(amount));
  };

  const handleMinutesAmountChange = (value: string) => {
    setContentData(Number(value));
  };

  return (
    <Layout
      className={className}
      title={_('benefits:voucher.chat_minutes.title')}
      subtitle={_('benefits:voucher.chat_minutes.subtitle')}
    >
      <FieldLayout
        label={_('benefits:voucher.texts.couponRedeemType')}
        error=""
        css={{ marginBottom: 24, flex: 0 }}
      >
        <Select
          value={redemptionType}
          name="voucherType"
          css={{ margin: 8, flex: '0 0 auto' }}
          placeholder={_('benefits:voucher.texts.pleaseChoose')}
          onChange={(event) => handleRedemptionTypeChange(event.target.value)}
          options={[
            { label: _('benefits:voucher.texts.free'), value: 'GRATIS' },
            { label: _('benefits:voucher.texts.onTop'), value: 'ON_TOP' },
          ]}
        />
      </FieldLayout>

      {redemptionType === 'GRATIS' && (
        <FieldLayout
          label={_('benefits:voucher.texts.specifyAmount')}
          error=""
          css={{ flex: 0, marginBottom: 24, alignItems: 'flex-start' }}
        >
          <div css={{ flex: 0, alignItems: 'center' }}>
            <Select
              value={contentData}
              name="minutesAmount"
              css={{ margin: 8, flex: '0 0 auto' }}
              onChange={(event) => handleMinutesAmountChange(event.target.value)}
              options={[
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '15', value: '15' },
                { label: '30', value: '30' },
                { label: '60', value: '60' },
              ]}
            />
            <span>{_('benefits:voucher.chat_minutes.whatUserGets')}</span>
          </div>
        </FieldLayout>
      )}

      {redemptionType === 'ON_TOP' && (
        <>
          <SelectRow>
            <span>{_('benefits:voucher.texts.from')}</span>
            <Select
              value={String(redemptionAmount)}
              css={{ maxWidth: 128, margin: 8 }}
              onChange={(event) => handleRedemptionAmountChange(event.target.value)}
              options={availableContentMinAmountsArray || []}
            />
            <span>{_('benefits:voucher.texts.userGetsOnTop')}</span>
          </SelectRow>
          <SelectRow css={{ marginBottom: 24 }}>
            <span css={{ visibility: 'hidden' }}>{_('benefits:voucher.texts.from')}</span>
            <Select
              value={contentData}
              name="minutesAmount"
              css={{ margin: 8, flex: '0 0 auto' }}
              onChange={(event) => handleMinutesAmountChange(event.target.value)}
              options={[
                { label: '5', value: '5' },
                { label: '10', value: '10' },
                { label: '15', value: '15' },
                { label: '30', value: '30' },
                { label: '60', value: '60' },
              ]}
            />
            <span>{_('benefits:voucher.chat_minutes.whatUserGets')}</span>
          </SelectRow>
        </>
      )}
    </Layout>
  );
};

export default ContentMinutes;
