import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router';
import qs from 'query-string';
import { APP_BASE_PATH } from '../../util/env';
import { LOGOUT_PATH } from '../../routes/Login/Login';

const LoginRedirect: FC = () => {
  const { pathname, search } = useLocation();
  const { redir: searchRedir } = qs.parse(search, { ignoreQueryPrefix: true });

  const redir = searchRedir || pathname;

  const queryString =
    redir && ![APP_BASE_PATH, '/'].includes(redir) && !redir.startsWith(LOGOUT_PATH)
      ? `?${qs.stringify({ redir })}`
      : '';

  return <Redirect to={`${APP_BASE_PATH}/login${queryString}`} />;
};

export default LoginRedirect;
