import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';
import { MTableBody } from 'material-table';
import { EmptyContent } from '../../components';

class Body extends MTableBody {
  renderEmpty(emptyRowCount, renderData) {
    const rowHeight = this.props.options.padding === 'default' ? 49 : 36;
    const localization = {
      ...MTableBody.defaultProps.localization,
      ...this.props.localization,
    };
    if (this.props.options.showEmptyDataSourceMessage && renderData.length === 0) {
      let addColumn = 0;
      if (this.props.options.selection) {
        addColumn++;
      }
      if (
        this.props.actions &&
        this.props.actions.filter((a) => a.position === 'row' || typeof a === 'function').length > 0
      ) {
        addColumn++;
      }
      if (this.props.hasDetailPanel) {
        addColumn++;
      }
      if (this.props.isTreeData) {
        addColumn++;
      }
      return (
        <TableRow
          style={{
            height:
              rowHeight *
              (this.props.options.paging && this.props.options.emptyRowsWhenPaging
                ? this.props.pageSize
                : 1),
          }}
          key={'empty-' + 0}
        >
          <TableCell
            style={{ paddingTop: 0, paddingBottom: 0, textAlign: 'center' }}
            colSpan={this.props.columns.reduce(
              (currentVal, columnDef) => (columnDef.hidden ? currentVal : currentVal + 1),
              addColumn
            )}
            key="empty-"
          >
            <EmptyContent title={localization.emptyDataSourceMessage} icon={'icon-edit'} />
          </TableCell>
        </TableRow>
      );
    } else if (this.props.options.emptyRowsWhenPaging) {
      return (
        <>
          {[...Array(emptyRowCount)].map((r, index) => (
            <TableRow style={{ height: rowHeight }} key={'empty-' + index} />
          ))}
          {emptyRowCount > 0 && <TableRow style={{ height: 1 }} key={'empty-last1'} />}
        </>
      );
    }
  }
}

export default Body;
