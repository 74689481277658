import React from 'react';
import PropTypes from 'prop-types';

import formatFn from 'date-fns/format';
import { parseISO } from 'date-fns';

import formatRelative from 'date-fns/formatRelative';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import { getFormattedDate as OldFormatter } from '../../util/Formatter';
import { useLang } from '../../util/AppState';
import { ApiLang } from '../../graphql/VXModels/types';

export const jsonFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";

export const format = (date, format, relative, lang) => {
  const locale = lang === ApiLang.de ? de : en;
  return relative
    ? formatRelative(date, new Date(), { locale })
    : //: formatFn(date, format, { locale });
    // we have a problem with UTC time zone in formatFn! we must use old date formatter...
    format === 'MMMM yyyy'
    ? formatFn(parseISO(date), format, { locale })
    : OldFormatter(date, lang);
};

const DateFormatter = ({ relative, format: fmtString, date }) => {
  const [lang] = useLang();

  return format(date, fmtString, relative, lang);
};

DateFormatter.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number])
    .isRequired,
  format: PropTypes.string,
  relative: PropTypes.bool,
};

DateFormatter.defaultProps = {
  format: 'Pp', // @see https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  relative: false,
};

export default DateFormatter;
