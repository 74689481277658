import React from 'react';
import {CmsArticle, T} from '../../../components';


const HOUSERULES = () => {
    return (<div className="grid__row">
        <div className="grid__column grid__box">
            <div className="grid__box__header">
                <div className="grid__box__header__title">
                    <T _={'common:legal.houserule'}/>
                </div>
            </div>
            <div className="grid__box__item">
                <div className="grid__box__item__content">
                    <CmsArticle id={'houserules'} className="legal__box --noselect" linkTarget="_blank"
                    />
                </div>
            </div>
        </div>
    </div>);
}

export default HOUSERULES;
