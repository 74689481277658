import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { FC } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { Main } from '../../../atoms';
import { ProtectedRoute } from '../../../components';
import AutoMailEntry from '../../../packages/Mailings/components/AutoMailTelegram/AutoMailEntry';
import AutoMailGridFormItem from '../../../packages/Mailings/components/AutoMailTelegram/Mobile/AutoMailGridFormItem';
import AutoMailMobileEntry from '../../../packages/Mailings/components/AutoMailTelegram/Mobile/AutoMailMobileEntry';
import useTelegramMaster from '../../../packages/Telegram/hooks/useTelegramMaster';
import { stripSlash } from '../../../util/urlHelper';
import AutoMailNav from './AutoMailNav';

const AutoMail: FC = () => {
  const { url } = useRouteMatch();
  const path = stripSlash(url);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

  const { data } = useTelegramMaster();
  const isTelegramChannel = data?.master?.channelExists && data?.master?.channelInviteUrl !== '';

  const getIndexRoute = (channelActive: boolean, isMobile: boolean) => {
    const lifecycleType = channelActive ? 'vip' : 'free';
    const deviceType = isMobile ? 'mobile' : 'desktop';
    return `${path}/${deviceType}/${lifecycleType}-lifecycle`;
  };

  return (
    <Main isMobileEnabled>
      <AutoMailNav isTelegramChannel={isTelegramChannel} />

      <Switch>
        <ProtectedRoute
          path={`${path}/desktop/free-lifecycle`}
          component={AutoMailEntry}
          componentProps={{ isVip: false }}
        />
        <ProtectedRoute
          path={`${path}/desktop/vip-lifecycle`}
          component={AutoMailEntry}
          componentProps={{ isVip: true }}
        />
        <ProtectedRoute
          path={`${path}/mobile/free-lifecycle`}
          exact
          component={AutoMailMobileEntry}
          componentProps={{ isVip: false }}
        />
        <ProtectedRoute
          path={`${path}/mobile/vip-lifecycle`}
          exact
          component={AutoMailMobileEntry}
          componentProps={{ isVip: true }}
        />

        <ProtectedRoute
          path={`${path}/mobile/free-lifecycle/:id`}
          exact
          component={AutoMailGridFormItem}
          componentProps={{ isVip: false }}
        />

        <ProtectedRoute
          path={`${path}/mobile/vip-lifecycle/:id`}
          exact
          component={AutoMailGridFormItem}
          componentProps={{ isVip: true }}
        />
        <Redirect to={getIndexRoute(isTelegramChannel, isMobile)} />
      </Switch>
    </Main>
  );
};

export default AutoMail;
