import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { BLACK, RED, WHITE, YELLOW } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';

const Overlay = styled.div`
  display: none !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: ${BLACK};
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Content = styled.div`
  width: 136px;
  height: 136px;
  border: 4px solid ${WHITE};
  border-radius: 50%;
  background-color: #34333b;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    ${Overlay} {
      display: flex !important;
    }
  }
`;

const Image = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const DefaultImage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  text-align: center;
  color: ${WHITE};
`;

const Status = styled.div`
  align-self: center;
  padding: 4px;
  font-size: 16px;
  color: ${BLACK};
  border-radius: 4px;
  position: relative;
  bottom: 8px;
`;

const ProfilePicture = ({ url, onClick, status, ageRating, className }) => {
  const getColor = () => {
    if (status === 'rejected') return RED;
    if (status === 'unverified') return YELLOW;
    return WHITE;
  };

  return (
    <div css={{ flexDirection: 'column' }} className={className}>
      <Content onClick={onClick} css={{ borderColor: getColor() }}>
        {url ? (
          <Image css={{ backgroundImage: `url('${url}')` }} />
        ) : (
          <DefaultImage>
            <div
              css={{
                width: 54,
                height: 64,
                marginBottom: 4,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url('${require(`./__assets__/fsk${ageRating}_white.svg`)}')`,
              }}
            />
            <span>{_('mediamanagement:sedcardVX.mainPictureChange')}</span>
          </DefaultImage>
        )}

        <Overlay>
          <span className="icon-disk-open" css={{ fontSize: 24 }} />
          <span css={{ fontSize: 16, marginTop: 4, textAlign: 'center' }}>
            {_('mediamanagement:sedcardVX.mainPictureChange')}
          </span>
        </Overlay>
      </Content>

      {(status === 'rejected' || status === 'unverified') && (
        <Status status={status} css={{ backgroundColor: getColor() }}>
          {status === 'rejected' && _('common:text.rejected')}
          {status === 'unverified' && _('common:text.checking')}
        </Status>
      )}
    </div>
  );
};

ProfilePicture.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(['rejected', 'unverified', 'ok']),
  ageRating: PropTypes.string,
  className: PropTypes.string,
};

ProfilePicture.defaultProps = {
  url: '',
  onClick: null,
  status: 'unverified',
  ageRating: '',
  className: '',
};

export default ProfilePicture;
