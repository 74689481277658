import React, { FC } from 'react';
import { FileDescriptor } from '../types';
import { isNativeFileDescriptor } from '../utils';
import { Box } from '@material-ui/core';
import { GRAY_3 } from '../../../../camtool-styles';

interface Props {
  file: FileDescriptor;
  className?: string;
}

const PreviewUnknown: FC<Props> = ({ file, className }) => {
  const url = isNativeFileDescriptor(file) ? file.file.name : file.url;

  return <div className={className}>{url}</div>;
};

export default PreviewUnknown;
