import React, { FC } from 'react';
import { diskOpen } from '../../../../atoms/Icon/libraries/glyph';
import { UploadLabelProps } from './types';
import { MediaUploadInputLabelSize } from '../types';
import Icon from '../../../../atoms/Icon/Icon';

type Props = Pick<UploadLabelProps, 'labelSize'>;

const getIconSize = (labelSize: MediaUploadInputLabelSize): string => {
  switch (labelSize) {
    case MediaUploadInputLabelSize.md:
      return '64px';
    case MediaUploadInputLabelSize.sm:
      return '24px';
  }
};

const UploadIcon: FC<Props> = ({ labelSize }) => (
  <Icon icon={diskOpen} size={getIconSize(labelSize)} />
);

export default UploadIcon;
