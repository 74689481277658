import React, { FC, useCallback } from 'react';
import { BLACK, WHITE } from '../../../../../camtool-styles';
import Attachment from '../Attachment';
import { DateTimeFormatter } from '../../../../../atoms/I18n';
import { Message, MessageDirection } from '../../../../../graphql/VXServicesTelegram/types';
import { _ } from '../../../../../util/translate';
import { css } from '@emotion/core';
import { Markdown } from '../../../../../components';
import { useLang } from '../../../../../util/AppState';
import { getFormattedAmount } from '../../../../../util/Formatter';

const colors = {
  out: '#7EB9E4',
  in: WHITE,
};

const timeColors = {
  out: WHITE,
  in: '#AAA',
};

const createBubbleStyle = ({
  dirIn,
  direction,
}: {
  dirIn: boolean;
  direction: MessageDirection;
}) => css`
  margin: 8px;
  ${dirIn ? 'margin-left: 16px' : 'margin-right: 16px'};
  padding: 15px;
  border-radius: 4px;
  background-color: ${colors[direction]};
  color: ${dirIn ? BLACK : WHITE};
  justify-content: ${dirIn ? 'flex-start' : 'flex-end'};
  align-self: ${dirIn ? 'flex-start' : 'flex-end'};
  position: relative;
  flex: 0 0 auto;
  max-width: 40%;
  min-width: min-content;
  flex-direction: column;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
`;

const createArrowStyle = ({
  dirIn,
  direction,
}: {
  dirIn: boolean;
  direction: MessageDirection;
}) => css`
  position: absolute;
  bottom: 8px;
  ${dirIn ? 'left: -4px' : 'right: -4px'};
  width: 16px;
  height: 16px;
  background-color: ${colors[direction]};
  transform: rotate(45deg);
`;

const MessageItem: FC<Message> = ({
  text,
  direction,
  file,
  timestamp,
  isPaidContent,
  paidContent,
  broadcast,
}) => {
  const [lang] = useLang();
  const dirIn = direction === MessageDirection.in;
  const messageText = isPaidContent ? paidContent?.text : text;
  const currency = paidContent?.currency;
  const Bubble = 'div';
  const modifiedFile = file ? { ...file, direction, isPaidContent } : null;
  const bubbleStyle = useCallback(
    () => createBubbleStyle({ dirIn, direction }),
    [dirIn, direction]
  );
  const arrowStyle = useCallback(() => createArrowStyle({ dirIn, direction }), [dirIn, direction]);
  // unfortunately text can be empty if a file is attached, but files are often loaded much later than text - so just show bubble if one of both is definitive!
  const showBubble = !!messageText || (!!modifiedFile && !!modifiedFile.url);

  return showBubble ? (
    <Bubble css={bubbleStyle}>
      {modifiedFile && <Attachment {...modifiedFile} />}
      <div css={{ zIndex: 2, marginBottom: 8, whiteSpace: 'pre-line' }}>{messageText}</div>
      {isPaidContent && (
        // This translation key has inline styles in the language manager!!!
        <Markdown
          source={_('telegram:chat.message.deliveryInformation', {
            price: getFormattedAmount(paidContent?.price, lang, currency),
            // todo: include single user userType into userType
            userType: broadcast?.userType
              ? _(`telegram:BroadcastList.column.recipientGroups.value.${broadcast?.userType}`)
              : _('telegram:chat.message.targetGroup.single'),
          })}
        />
      )}
      <div
        css={{
          marginTop: isPaidContent ? 5 : 0,
          zIndex: 2,
          textAlign: 'right',
          display: 'block !important',
          fontSize: '10px',
          color: timeColors[direction],
        }}
      >
        <DateTimeFormatter value={timestamp} format={'p'} />
      </div>
      <div css={arrowStyle} />
    </Bubble>
  ) : null;
};

export default MessageItem;
