import gql from 'graphql-tag';

export const MUTATION_MODEL_SERVICE0900_SET_STATUS = gql`
  mutation setServic0900Status($status: Service0900InputStatusEnum!) {
    model {
      service0900 {
        setStatus(status: $status) {
          status
        }
      }
    }
  }
`;
