import gql from 'graphql-tag';

export const QUERY_MODEL_SERVICE0900_TOPBAR = gql`
  query QUERY_MODEL_SERVICE0900_TOPBAR {
    model {
      service0900 {
        status
        targetNumber {
          isLocked
        }
        displayPlatform
      }
    }
  }
`;
