import React, { FC, useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_AUTH_VERIFY } from '../../graphql/VXModels/mutations';
import SplashScreen from '../../components/SplashScreen';
import { ActionEnum, useAppState } from '../../util/AppState';
import ErrorInvalidCode from './ErrorInvalidCode';
import ErrorGeneric from './ErrorGeneric';
import { getStartpageLoginUrl } from '../../util/startpage';

interface IRouteParams {
  userId: string;
  verificationCode: string;
}

const MailVerify: FC<RouteComponentProps<IRouteParams>> = ({
  match: {
    params: { userId, verificationCode },
  },
}) => {
  const [verify, { data, loading, error, called }] = useMutation(MUTATION_AUTH_VERIFY);

  const verifyData = data?.auth?.verify;
  const status = verifyData?.status;
  const autoLoginToken = verifyData?.data?.autoLoginToken;

  const [state, dispatch] = useAppState();
  const { authToken: currentAuthToken, lang } = state;

  useEffect(() => {
    if (!called) {
      if (currentAuthToken) {
        // Clear the auth token. (The auth request would fail if we sent an expired auth token.)
        // On the next rerender, we'll have a new Apollo client with an empty Authorization header.
        dispatch({ type: ActionEnum.clearAuthToken });
      } else {
        verify({ variables: { userId, verificationCode } });
      }
    }
  }, [userId, verificationCode, currentAuthToken]);

  return status === 'OK' ? (
    <Redirect
      to={autoLoginToken ? `/auth/callback?token=${autoLoginToken}` : getStartpageLoginUrl(lang)}
    />
  ) : data ? (
    <ErrorInvalidCode />
  ) : error || (!data && called && !loading) ? (
    <ErrorGeneric />
  ) : (
    <SplashScreen />
  );
};

export default MailVerify;
