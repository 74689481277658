import { Override } from './types';

export const MuiSvgIcon: Override<'MuiSvgIcon'> = {
  root: {
    fontSize: '20px',
  },
  fontSizeSmall: {
    fontSize: '16px',
  },
  fontSizeLarge: {
    fontSize: '30px',
  },
};
