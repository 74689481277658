import { useEffect, useReducer, useState } from 'react';
import { getPictureUploadUrl } from '../packages/UploadManager/util/genericUpload';
import { useAppState } from './AppState';

export enum PictureAlbumTypeEnum {
  MAIN = '50_1',
  EXTRA = '50_2',
  SHOP = '50_3',
  CONTEST = '50_4',
  CULTBABES = '50_5',
  POOL = '50_6',
  MAILINGS = '50_7',
}

// errorcodes meaning
export enum PictureUploadErrorsEnum {
  SESSION_FAILED = -1,
  NO_UMA_ID = -2,
  WRONG_FILE_EXTENSION = -3,
  FILE_SIZE_EXCEEDED = -4,
  TOO_SMALL = -5,
  DB_ERROR = -7,
  DUPLICATE = -8,
  MONOCHROME = -9,
}

enum Action {
  'UPLOAD_INIT' = 'UPLOAD_INIT',
  'UPLOAD_SUCCESS' = 'UPLOAD_SUCCESS',
  'UPLOAD_FAILURE' = 'UPLOAD_FAILURE',
}

interface IProps {
  type: PictureAlbumTypeEnum;
  umaId: number;
  onCompleted?: (res: IResult) => void;
  onError?: (err: IError) => void;
  maxFileSize?: number;
}

interface IState {
  loading: boolean;
  error: any;
  data: any;
  done: boolean;
}

interface IError {
  message: any;
  raw: string;
}

interface IResult {
  umpId: number;
}

interface IAction {
  type: Action;
  payload?: any;
}

const pictureTypeMapping: Partial<Record<PictureAlbumTypeEnum, number>> = {
  [PictureAlbumTypeEnum.MAILINGS]: 23,
};

const dataFetchReducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case Action.UPLOAD_INIT:
      return {
        ...state,
        loading: true,
        error: undefined,
        done: false,
      };
    case Action.UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        done: true,
        data: action.payload,
      };
    case Action.UPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        done: true,
        error: action.payload,
      };
    default:
      throw new Error();
  }
};

const buildPictureUploadError = (body: string): IError => {
  switch (body) {
    case 'errorcode=0':
      return {
        raw: body,
        message: {
          de: 'Bitte versuchen Sie es erneut später',
          en: 'Please try it again later',
        },
      };
    case 'errorcode=-5':
      return {
        raw: body,
        message: {
          de: 'Bild ist zu klein',
          en: 'Picture too small',
        },
      };
    case 'errorcode=-8':
      return {
        raw: body,
        message: {
          de: 'Bild existiert bereits',
          en: 'Picture already exists',
        },
      };
    default:
      return {
        raw: body,
        message: {
          de: 'Es ist ein Fehler aufgetreten',
          en: 'An error has occured',
        },
      };
  }
};

const buildPictureUploadResult = (regexExecArr: RegExpExecArray): IResult => {
  return {
    umpId: parseInt(regexExecArr[1], 10),
  };
};

export const usePictureUploader = ({
  type,
  umaId,
  onCompleted,
  onError,
  maxFileSize = 0,
}: IProps): [IState, (file: File) => void] => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: false,
    data: undefined,
    done: false,
  });

  const [uploadFile, setUploadFile] = useState();
  const [{ sessionId: sid }] = useAppState();

  useEffect(() => {
    const fetchData = async (file: File) => {
      try {
        if (maxFileSize > 0 && file.size > maxFileSize) {
          throw {
            raw: {},
            message: {
              de: `Maximale Dateigröße von ${maxFileSize / 1024}kB überschritten`,
              en: `Maximum file size of ${maxFileSize / 1024}kB exeeded`,
            },
          };
        }
        const formData = new FormData();
        formData.append('type', type.toString());
        formData.append('umaId', umaId.toString());
        formData.append('file', file);

        const uploadUrl = await getPictureUploadUrl(umaId, pictureTypeMapping[type], 0);
        const res = await fetch(uploadUrl, { method: 'POST', body: formData });
        const body = await res.text();

        // id=10176929
        const testId = /^id=(\d+)/.exec(body);
        if (testId) {
          const result = buildPictureUploadResult(testId);
          if (onCompleted) {
            onCompleted(result);
          }
          dispatch({ type: Action.UPLOAD_SUCCESS, payload: result });
        } else {
          const error = buildPictureUploadError(body);
          if (onError) {
            onError(error);
          }
          dispatch({ type: Action.UPLOAD_FAILURE, payload: error });
        }
        setUploadFile(undefined);
      } catch (error) {
        if (onError) {
          onError(error);
        }
        dispatch({ type: Action.UPLOAD_FAILURE, payload: error });
      }
    };
    if (!state.done && uploadFile) {
      fetchData(uploadFile);
    }
  }, [uploadFile]);

  const upload = (file: File) => {
    dispatch({ type: Action.UPLOAD_INIT });
    setUploadFile(file);
  };

  return [state, upload];
};
