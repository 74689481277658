import React from 'react';
import styled from '@emotion/styled';
// Outside Wizard
import { DARK_GRAY, GRAY_2, RED } from '../../../camtool-styles';
// Inside Wizard
import { IBreadCrumbProps } from '../types';
import { SectionTitle } from '../styles';
import { _ } from '../../../util/translate';

const BreadCrumbDot = styled.span`
  background-color: ${GRAY_2};
  color: ${DARK_GRAY};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export default function BreadCrumb({ currentRoute }: IBreadCrumbProps) {
  return (
    <SectionTitle css={{ justifyContent: 'space-around', textTransform: 'none' }}>
      <div css={{ alignItems: 'center', justifyContent: 'center', flex: '1 0 auto' }}>
        <BreadCrumbDot css={currentRoute === 'STEP_VOUCHER_TYPE' ? { backgroundColor: RED } : null}>
          1
        </BreadCrumbDot>
        <span css={{ color: currentRoute === 'STEP_VOUCHER_TYPE' ? RED : GRAY_2 }}>
          {_('voucher:breadCrumb.step1')}
        </span>
      </div>

      <span css={{ borderBottom: `1px solid ${GRAY_2}`, flex: 1 }} />

      <div css={{ alignItems: 'center', justifyContent: 'center', flex: '1 0 auto' }}>
        <BreadCrumbDot
          css={currentRoute === 'STEP_VOUCHER_CONTENT' ? { backgroundColor: RED } : null}
        >
          2
        </BreadCrumbDot>
        <span css={{ color: currentRoute === 'STEP_VOUCHER_CONTENT' ? RED : GRAY_2 }}>
          {_('voucher:breadCrumb.step2')}
        </span>
      </div>

      <span css={{ borderBottom: `1px solid ${GRAY_2}`, flex: 1 }} />

      <div css={{ alignItems: 'center', justifyContent: 'center', flex: '1 0 auto' }}>
        <BreadCrumbDot
          css={currentRoute === 'STEP_VOUCHER_DETAILS' ? { backgroundColor: RED } : null}
        >
          3
        </BreadCrumbDot>
        <span css={{ color: currentRoute === 'STEP_VOUCHER_DETAILS' ? RED : GRAY_2 }}>
          {_('voucher:breadCrumb.step3')}
        </span>
      </div>
    </SectionTitle>
  );
}
