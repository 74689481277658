import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ButtonSpinner: FC<SvgIconProps> = () => (
  <SvgIcon viewBox={'0 0 100 100'}>
    <g transform="translate(80,50)">
      <g transform="rotate(0)">
        <circle cx="0" cy="0" r="6" fillOpacity="1" transform="scale(1.13592 1.13592)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-1.1363636363636362s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
            values="1;0"
            begin="-1.1363636363636362s"
          />
        </circle>
      </g>
    </g>
    <g transform="translate(71.21320343559643,71.21320343559643)">
      <g transform="rotate(45)">
        <circle cx="0" cy="0" r="6" fillOpacity="0.875" transform="scale(1.19842 1.19842)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.974025974025974s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.974025974025974s"
          />
        </circle>
      </g>
    </g>
    <g transform="translate(50,80)">
      <g transform="rotate(90)">
        <circle cx="0" cy="0" r="6" fillOpacity="0.75" transform="scale(1.26092 1.26092)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.8116883116883117s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.8116883116883117s"
          />
        </circle>
      </g>
    </g>
    <g transform="translate(28.786796564403577,71.21320343559643)">
      <g transform="rotate(135)">
        <circle cx="0" cy="0" r="6" fillOpacity="0.625" transform="scale(1.32342 1.32342)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.6493506493506493s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.6493506493506493s"
          />
        </circle>
      </g>
    </g>
    <g transform="translate(20,50.00000000000001)">
      <g transform="rotate(180)">
        <circle cx="0" cy="0" r="6" fillOpacity="0.5" transform="scale(1.38592 1.38592)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.487012987012987s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.487012987012987s"
          />
        </circle>
      </g>
    </g>
    <g transform="translate(28.78679656440357,28.786796564403577)">
      <g transform="rotate(225)">
        <circle cx="0" cy="0" r="6" fillOpacity="0.375" transform="scale(1.44842 1.44842)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.3246753246753247s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.3246753246753247s"
          />
        </circle>
      </g>
    </g>
    <g transform="translate(49.99999999999999,20)">
      <g transform="rotate(270)">
        <circle cx="0" cy="0" r="6" fillOpacity="0.25" transform="scale(1.01092 1.01092)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="-0.16233766233766234s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
            values="1;0"
            begin="-0.16233766233766234s"
          />
        </circle>
      </g>
    </g>
    <g transform="translate(71.21320343559643,28.78679656440357)">
      <g transform="rotate(315)">
        <circle cx="0" cy="0" r="6" fillOpacity="0.125" transform="scale(1.07342 1.07342)">
          <animateTransform
            attributeName="transform"
            type="scale"
            begin="0s"
            values="1.5 1.5;1 1"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            keyTimes="0;1"
            dur="1.2987012987012987s"
            repeatCount="indefinite"
            values="1;0"
            begin="0s"
          />
        </circle>
      </g>
    </g>
  </SvgIcon>
);

export default ButtonSpinner;
