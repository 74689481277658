import React, { FC } from 'react';
import styled from '@emotion/styled';
import CancelCheckbox from './CancelCheckbox';
import { _ } from '../../../../util/translate';
import WarningOverlay from '../Overview/WarningOverlay';
import { IAbo } from '../../types';

interface Props {
  abo: IAbo;
  cancelSubsChecked: boolean;
  deactivateChecked: boolean;
  onCancelSubsCheckedChange: (checked: boolean) => void;
  onDeactivateCheckedChange: (checked: boolean) => void;
}

const CheckBoxesDiv = styled.div`
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  margin-top: 34px;
  flex: 0 0 auto;
`;

const CancelCheckboxes: FC<Props> = ({
  abo,
  cancelSubsChecked,
  deactivateChecked,
  onCancelSubsCheckedChange,
  onDeactivateCheckedChange,
}) => {
  const handleCancelSubsCheckedChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    onCancelSubsCheckedChange(e.target.checked);
  const handleDeactivateCheckedChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    onDeactivateCheckedChange(e.target.checked);

  const atLeastOneCheckboxSelected = cancelSubsChecked || deactivateChecked;

  return (
    <CheckBoxesDiv>
      {abo.active && (
        <CancelCheckbox
          name="deactivateAbo"
          title={_('benefits:abo.archive.detailsModal.disableAbo')}
          subtitle={_('benefits:abo.archive.detailsModal.disableAboText')}
          onChange={handleDeactivateCheckedChange}
          checked={deactivateChecked}
          css={{ marginTop: 11 }}
        />
      )}
      {abo.subscriptionCount > 0 && (
        <CancelCheckbox
          name="cancelAboSubscriptions"
          title={_('benefits:abo.archive.detailsModal.cancelAbo', {
            sprintf: [abo.subscriptionCount],
          })}
          subtitle={_('benefits:abo.archive.detailsModal.cancelAboText')}
          onChange={handleCancelSubsCheckedChange}
          checked={cancelSubsChecked}
        />
      )}
      {atLeastOneCheckboxSelected && (
        <WarningOverlay title={_('benefits:abo.archive.detailsModal.irreversibleWarning')} />
      )}
    </CheckBoxesDiv>
  );
};

export default CancelCheckboxes;
