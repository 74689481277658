import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import AbstractComponent from '../../AbstractComponent';
import { SessionStore } from '../../../stores/Session/Session';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';

const ICON_CHAT = 'Chat';
const ICON_PROFILE = 'Profil';
const ICON_MARKETING = 'Marketing';
const ICON_BONUS = 'Bonus';

class RankingPointsItem extends AbstractComponent {
  constructor(props) {
    super(props);
    this._onShowDetailsClick = this._onShowDetailsClick.bind(this);
  }

  _onShowDetailsClick() {
    const domNode = ReactDOM.findDOMNode(this.refs.ranking_arrow);
    if (this.props.rankingElement.groupName !== ICON_BONUS) {
      domNode.classList.toggle('-is-open');
    }
  }

  render() {
    const [{ lang }] = this.props.appState;
    this.rankingPointsMaxClass = 'rankingpoints__maxpoints';
    this.rankingPointsLabelClass = 'rankingpoints__pointslabel';
    this.info = '';
    this.description = '';

    switch (this.props.rankingElement.groupName) {
      case ICON_CHAT:
        this.iconClass = 'icon-webcam rankingpoints__icon';
        this.userPointsClasses = 'rankingpoints__points -color-magenta-dark';
        this.progressbarClass = 'rankingpoints__progressbar-currentpoints -bgcolor-magenta-dark';
        this.description = _('ranking:chat.descriptionText');
        this.arrowClassName = 'rankingpoints__arrow -is-open -is-before-hidden';
        break;
      case ICON_PROFILE:
        this.iconClass = 'icon-user rankingpoints__icon';
        this.userPointsClasses = 'rankingpoints__points -color-cyan';
        this.progressbarClass = 'rankingpoints__progressbar-currentpoints -bgcolor-cyan';
        this.description = _('ranking:profile.descriptionText');
        this.arrowClassName = 'rankingpoints__arrow -is-open -is-before-hidden';
        break;
      case ICON_MARKETING:
        this.iconClass = 'icon-bullhorn rankingpoints__icon';
        this.userPointsClasses = 'rankingpoints__points -color-green-light';
        this.progressbarClass = 'rankingpoints__progressbar-currentpoints -bgcolor-green-light';
        this.description = _('ranking:marketing.descriptionText');
        this.arrowClassName = 'rankingpoints__arrow -is-open -is-before-hidden';
        break;
      case ICON_BONUS:
        this.iconClass = 'icon-gift rankingpoints__icon';
        this.userPointsClasses = `rankingpoints__points${
          this.props.rankingElement.userPoints >= 0 ? ' -color-green-hover' : ' -color-red'
        }`;
        this.progressbarClass = `rankingpoints__progressbar-currentpoints${
          this.props.rankingElement.userPoints >= 0 ? ' -bgcolor-green-hover' : ' -bgcolor-red'
        }`;
        this.rankingPointsMaxClass = 'rankingpoints__maxpoints h-disp-none';
        this.rankingPointsLabelClass = 'rankingpoints__pointslabel h-visibility-hidden';
        this.description = _('ranking:bonus.descriptionText');
        this.arrowClassName = 'rankingpoints__arrow -is-open -is-before-hidden';
        break;
      default:
        this.iconClass = 'rankingpoints__icon';
        this.userPointsClasses = 'rankingpoints__points';
        this.progressbarClass = 'rankingpoints__progressbar-currentpoints';
        this.description = this.props.rankingElement.description
          ? this.props.rankingElement.description[lang]
          : '';

        if (this.props.rankingElement.info) {
          const data = {};
          this.props.rankingElement.info.split('|').forEach(x => {
            const arr = x.split(':');
            arr[1] && (data[arr[0]] = arr[1]);
          });

          this.info = _(`ranking:${this.props.rankingElement.translationKey}.infoText`, {
            postProcess: 'sprintf',
            sprintf: data,
          });
        }

        this.arrowClassName = 'rankingpoints__arrow';
        if (this.props.defaultGroupname) {
          this.title = `ranking:${this.props.defaultGroupname}.label`;
        }
        break;
    }

    if (this.props.groupName && this.props.groupName === ICON_BONUS) {
      this.rankingPointsMaxClass = 'rankingpoints__maxpoints h-disp-none';
      this.arrowClassName = 'rankingpoints__arrow h-disp-none';
      this.progressbarClass = `rankingpoints__progressbar-currentpoints${
        this.props.rankingElement.userPoints >= 0 ? ' -bgcolor-green-hover' : ' -bgcolor-red'
      }`;
    }

    const progressBarWidth =
      this.props.rankingElement.userPoints && this.props.rankingElement.maxPoints
        ? Math.round(
            (Math.abs(this.props.rankingElement.userPoints) / this.props.rankingElement.maxPoints) *
              100
          )
        : 0;
    this.progressBarStyle = { width: `${progressBarWidth}%` };
    if (!this.title) {
      this.title = this.props.rankingElement.title ? this.props.rankingElement.title[lang] : '';
    }
    return (
      <div className="rankingpoints__container">
        <h3>
          {this.props.defaultGroupname ? _(`ranking:${this.props.defaultGroupname}.label`) : null}
        </h3>
        <div className="rankingpoints__iconcontainer">
          <span className={this.iconClass} />
          <span className="rankingpoints__iconlabel">{_(this.title)}</span>
        </div>

        <div className="rankingpoints__pointscontainer">
          <span className={this.userPointsClasses}>{this.props.rankingElement.userPoints}</span>
          <span className={this.rankingPointsMaxClass}>
            / {this.props.rankingElement.maxPoints}
          </span>
          <div className={this.rankingPointsLabelClass}>{_('ranking:rankingPoints.label')}</div>
        </div>

        <div className="rankingpoints__progressbar">
          <div className={this.progressbarClass} style={this.progressBarStyle} />
        </div>
        <span
          className={this.arrowClassName}
          onClick={this._onShowDetailsClick}
          ref="ranking_arrow"
        />
        <span className="rankingpoints__toggleelements">
          <span className="rankingpoints__descriptiontext">{this.description}</span>
          <span className="rankingpoints__infotext">{this.info}</span>
          {this.props.children}
        </span>
      </div>
    );
  }
}

RankingPointsItem.propTypes = {
  rankingElement: PropTypes.object.isRequired,
  groupName: PropTypes.string,
  defaultGroupname: PropTypes.string,
};

RankingPointsItem.defaultProps = {
  rankingElement: {},
  groupName: '',
  defaultGroupname: '',
};

export default withAppState(RankingPointsItem);
