import React, { FC } from 'react';
import { Button } from '../../../../atoms';
import { _ } from '../../../../util/translate';
import { IAboWizardState } from '../../types';

interface Props {
  state: IAboWizardState;
  cannotSubmit: boolean;
  onBackClick: () => void;
}

const NavigationButtons: FC<Props> = ({ state, onBackClick, cannotSubmit }) => {
  const canGoBack = state.history.length > 1;

  return (
    <section css={{ justifyContent: canGoBack ? 'space-between' : 'flex-end', padding: 32 }}>
      {canGoBack && (
        <Button
          type="button"
          theme="border-gray"
          onClick={onBackClick}
          css={{ alignItems: 'center' }}
        >
          <span className="icon-chevron-left" css={{ fontSize: 8, marginRight: 8 }} />
          <span>{_('benefits:texts.back')}</span>
        </Button>
      )}

      <Button
        type="submit"
        theme={cannotSubmit ? 'gray' : 'blue'}
        disabled={cannotSubmit}
        css={{ alignItems: 'center' }}
      >
        <span>{_('benefits:abo.texts.createSub')}</span>
        <span className="icon-chevron-right" css={{ fontSize: 8, marginLeft: 8 }} />
      </Button>
    </section>
  );
};

export default NavigationButtons;
