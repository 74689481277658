import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Redirect, Switch } from 'react-router';
// Outside Wizard
import { Main } from '../../atoms';
import { NavSub, NavSubItem, ProtectedRoute, Spinner } from '../../components';
import { stripSlash } from '../../util/urlHelper';
// Inside Wizard
import BenefitsCreate from './BenefitsCreate/BenefitsCreate';
import BonusCodesArchive from './BenefitsArchive/BonusCodesArchive';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './vxqlFragmentTypes.json';
import { HttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_BONUS_CODE_TOKEN } from './queries';
import { _ } from '../../util/translate';
import IconBase from '../../atoms/Icon/IconBase';
import BenefitTicket from '../../atoms/Icon/BenefitTicket';
import ModalBonusCodeDetails from './BenefitsArchive/Table/ModalBonusCodeDetails/ModalBonusCodeDetails';

const VoucherWizard: FC<{ match: { url: string } }> = (props) => {
  const { loading, error, data } = useQuery(QUERY_MODEL_BONUS_CODE_TOKEN);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [bonusCodeClient, setBonusCodeClient] = useState<ApolloClient<{}>>();

  if (!bonusCodeClient && !loading && !error && data) {
    const { token } = data.model.bonuscodes;
    const fm = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
    const cache = new InMemoryCache({ fragmentMatcher: fm });
    const link = new HttpLink({ uri: 'https://bonus-code.vx-services.net', headers: { Authorization: `Bearer ${token}` } }); // prettier-ignore
    setBonusCodeClient(new ApolloClient({ cache, link }));
  }

  const availableContentMinAmountsArray = data?.model?.bonuscodes?.availableContentMinAmounts || [];

  const { match: { url } } = props; // prettier-ignore
  const path = stripSlash(url);

  useEffect(() => {
    // I do not know what the type of a beforeunload event is
    function handleBeforeunload(e: { preventDefault: () => void; returnValue: string }): void {
      e.preventDefault(); // Cancel the event
      e.returnValue = ''; // Chrome requires returnValue to be set
    }
    window.addEventListener('beforeunload', handleBeforeunload);
    return (): void => window.removeEventListener('beforeunload', handleBeforeunload);
  }, []);

  return (
    <Main>
      <NavSub>
        <NavSubItem
          link={`${path}/create`}
          icon={<IconBase iconElement={<BenefitTicket />} />}
          label={_('voucher:nav.create')}
        />
        <NavSubItem
          link={`${path}/archive`}
          icon="icon-folder-new"
          label={_('voucher:nav.archive')}
        />
      </NavSub>

      {bonusCodeClient ? (
        <Switch>
          <ProtectedRoute
            path={`${path}/create`}
            component={BenefitsCreate}
            componentProps={{ bonusCodeClient, availableContentMinAmountsArray }}
          />
          <ProtectedRoute
            path={`${path}/archive/:code`}
            component={ModalBonusCodeDetails}
            componentProps={{ bonusCodeClient }}
          />
          <ProtectedRoute
            path={`${path}/archive`}
            component={BonusCodesArchive}
            componentProps={{ bonusCodeClient }}
          />
          <Redirect to={`${path}/create`} />
        </Switch>
      ) : (
        <Spinner />
      )}
    </Main>
  );
};

export default VoucherWizard;
