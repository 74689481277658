import React, { FC } from 'react';
import { checkmark } from '../../../atoms/Icon/libraries/svg';
import styled from '@emotion/styled';
import { _ } from '../../../util/translate';
import { BREAKPOINT_PHONE_CONDITION, GRAY_2 } from '../../../camtool-styles';
import { Box } from '@material-ui/core';
import { useMedia } from 'react-media';

export const VideoDiv = styled.div`
  position: relative;
  max-width: 100%;
  flex: 0 0 auto;
  justify-content: center;
  flex-direction: column;
  gap: 7px;
`;

const VideoSubtitleDiv = styled.div`
  color: ${GRAY_2};
  margin-left: 17px;
`;

const AdvantagesSection = styled.div`
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: 'flex-start';
  flex: 0 0 auto;
  align-items: flex-start;
  width: fit-content;
`;

const UnorderedList = styled.ul`
  margin-top: 25px;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  list-style-position: inside;
  font-size: 16px;
  font-weight: normal;

  > * :not(:last-child) {
    margin-bottom: 15px;
  }
`;

const StyledListItem = styled.li`
  list-style-image: url(${checkmark});
`;

const VXCashAdvantages: FC = () => {
  const isMobile = useMedia({ query: BREAKPOINT_PHONE_CONDITION });

  const iframeSizeProps = {
    width: isMobile ? '100%' : '510',
    height: isMobile ? '' : '286',
  };

  return (
    <Box
      my={5}
      display="flex"
      flexDirection="row"
      width="100%"
      flexWrap="wrap"
      gridGap="30px"
      alignItems="center"
      justifyContent="center"
    >
      <VideoDiv>
        <iframe
          src="https://player.vimeo.com/video/260942411"
          {...iframeSizeProps}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
        <VideoSubtitleDiv>{_('vxcash:register.acceptTerms.video.description')}</VideoSubtitleDiv>
      </VideoDiv>
      <AdvantagesSection>
        <Box display="flex" flexDirection="column" ml={{ xs: '10px' }} mb={3}>
          <h2 css={{ fontSize: '18px' }}>{_('vxcash:register.acceptTerms.advantages.heading')}</h2>
          <UnorderedList>
            <StyledListItem>
              {_('vxcash:register.acceptTerms.advantages.argumentOne')}
            </StyledListItem>
            <StyledListItem>
              {_('vxcash:register.acceptTerms.advantages.argumentTwo')}
            </StyledListItem>
            <StyledListItem>
              {_('vxcash:register.acceptTerms.advantages.argumentThree')}
            </StyledListItem>
            <StyledListItem>
              {_('vxcash:register.acceptTerms.advantages.argumentFour')}
            </StyledListItem>
          </UnorderedList>
        </Box>
      </AdvantagesSection>
    </Box>
  );
};

export default VXCashAdvantages;
