import { NavLink } from 'react-router-dom';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { BLACK, GRAY_2, RED, WHITE } from '../../camtool-styles';
import Icon from '../../atoms/Icon/Icon';

export const NavSubSkelet = css`
  display: flex !important;
  flex: 1;
  background: ${WHITE};
  color: ${BLACK};
  text-decoration: none;
  margin: 8px;
  border-radius: 2px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  min-height: 30px;
`;

export const StyledNavSubLabel = styled.div`
  display: flex !important;
  flex: 0 0 auto;
  align-items: center;
  font-size: 15px;
  text-transform: uppercase;
`;

export const IconBase = css`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  min-width: 30px;
`;

export const StyledNavSubIcon = styled(Icon)`
  ${IconBase};
  align-self: center;
`;

export const NavSubLinkBase = css`
  ${NavSubSkelet};
  padding: 8px;
  grid-gap: 5px;
  align-items: center;

  /* in case of <IconBase /> WITH wrapped Avatar in normal state */
  & > div {
    border-color: ${BLACK};
    & > svg {
      color: ${BLACK};
    }
  }

  &:hover {
    background: ${GRAY_2};
    color: ${WHITE};

    /* in case of <IconBase /> WITH wrapped Avatar in hover state */
    & > div {
      border-color: ${WHITE};
      & > svg {
        color: ${WHITE};
      }
    }
  }

  &.active {
    background: ${RED};
    color: ${WHITE};
    pointer-events: none;

    /* in case of <IconBase /> WITH wrapped Avatar in active state */
    & > div {
      border-color: ${WHITE};
      & > svg {
        color: ${WHITE};
      }
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${NavSubLinkBase};
`;
