import React from 'react';
import { ClassNames } from '@emotion/core';
import AbstractFormMultiOptionsComponent from '../AbstractFormMultiOptionsComponent';
import MaxValueLength from '../../Display/MaxValueLength/MaxValueLength';

class TagListCharacterCloud extends AbstractFormMultiOptionsComponent {
  render() {
    const { maxLength } = this.props;
    const selectedOptions = this.getSelectedOptions();
    const unselectedOptions = this.getUnselectedOptions();
    const maxReached = maxLength > 0 && selectedOptions.length >= maxLength;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <div className="property-cloud maxvaluelength-anchorframe {this.getClassNames()}">
            {maxLength > 0 && <MaxValueLength max={maxLength} value={selectedOptions.length} />}
            <div className="property-cloud__circle">
              <div className="property-cloud__circle__column">
                <div className="property-cloud__circle__icon" />
                <div className="property-cloud__circle__entrys">
                  {selectedOptions.map((option, idx) => (
                    <div
                      className={cx(
                        'property-cloud__circle__entry',
                        `property-cloud__circle__entry--pos${idx}`
                      )}
                      key={option.value}
                      onClick={this.removeOption.bind(this, option)}
                    >
                      <div className="property-cloud__circle__entry__lable">{option.label}</div>
                      <div className="property-cloud__circle__entry__remove" />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={cx('property-cloud__list', { 'property-cloud__list--full': maxReached })}
            >
              <div className="property-cloud__list__row">
                {unselectedOptions.map(option => (
                  <div
                    className={'property-cloud__list__entry'}
                    key={option.value}
                    onClick={this.addOption.bind(this, option)}
                  >
                    <div className="property-cloud__list__entry__row">
                      <div className="property-cloud__list__entry__label">{option.label}</div>
                      <div className="property-cloud__list__entry__add" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </ClassNames>
    );
  }
}

export default TagListCharacterCloud;
export { TagListCharacterCloud };
