import gql from 'graphql-tag';

export const QUERY_MODEL_TVSHOWS_REPLACEMENTOFFER = gql`
  query QUERY_MODEL_TVSHOWS_REPLACEMENTOFFER {
    model {
      tvshows {
        replacementOffer
      }
    }
  }
`;
