import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

class CountdownTimer extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      distance: null,
      daysLeft: props.defaultCharacter,
      hoursLeft: props.defaultCharacter,
      minutesLeft: props.defaultCharacter,
      secondsLeft: props.defaultCharacter,
    };
  }

  tick() {
    let endDate = new Date(this.props.until);
    let distance = endDate - new Date();
    distance = distance < 0 ? 0 : distance;
    let daysLeft = Math.floor(distance / DAY);
    let hoursLeft = Math.floor((distance % DAY) / HOUR);
    let minutesLeft = Math.floor((distance % HOUR) / MINUTE);
    let secondsLeft = Math.floor((distance % MINUTE) / SECOND);

    this.setState({ daysLeft, hoursLeft, minutesLeft, secondsLeft, distance });
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 1000);
  }

  componentDidUpdate() {
    if (this.state.distance < 0) {
      clearInterval(this.timer);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    let { daysLeft, hoursLeft, minutesLeft, secondsLeft } = this.state;
    return this.props.view(daysLeft, hoursLeft, minutesLeft, secondsLeft);
  }
}

CountdownTimer.defaultProps = {
  view: (daysLeft, hoursLeft, minutesLeft, secondsLeft) => (
    <div>
      {daysLeft}:{hoursLeft}:{minutesLeft}:{secondsLeft}
    </div>
  ),
  defaultCharacter: '-',
};

CountdownTimer.propTypes = {
  until: PropTypes.string.isRequired,
  view: PropTypes.func,
  defaultCharacter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CountdownTimer;
export { CountdownTimer };
