import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import { _ } from '../../util/translate';

class LoadMoreButton extends AbstractComponent {
  state = { isLoading: false };

  componentDidUpdate(prevProps) {
    if (this.props.loadedEntries !== prevProps.loadedEntries) {
      this.setState({ isLoading: false });
    }
  }

  loadMoreData() {
    this.setState({ isLoading: true });
    this.props.onClickLoadMore();
  }

  isDisabled() {
    return this.state.isLoading || this.props.loadedEntries === this.props.totalEntries;
  }

  render() {
    if (this.props.totalEntries !== this.props.loadedEntries)
      return (
        <div
          className={`loadmore ${this.isDisabled() ? 'loadmore--disabled' : ''} ${
            this.props.className
          }`}
        >
          <div className="loadmore__column">
            <div className="loadmore__button" onClick={this.loadMoreData}>
              <div
                className={this.props.icon || 'icon-repeat'}
                css={{ flex: '0 0 auto', paddingRight: 8 }}
              />
              <div className="loadmore__button__label">
                {this.props.label || _('common:button.loadMoreContents')}
              </div>
            </div>
          </div>
        </div>
      );
    else return null;
  }
}

LoadMoreButton.propTypes = {
  onClickLoadMore: PropTypes.func.isRequired,
  loadedEntries: PropTypes.number,
  totalEntries: PropTypes.number,
  label: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
};

LoadMoreButton.defaultProps = {
  loadedEntries: 0,
  totalEntries: 0,
  label: '',
  icon: '',
  className: '',
};

export default LoadMoreButton;
