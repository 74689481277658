import { FieldArray, Form, Formik } from 'formik';
import React, { FC, ReactElement } from 'react';
import GenericEmptyContent from '../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import {
  useTelegramDeleteAutoMail,
  useTelegramGetAutoMails,
  useTelegramSendAutoMails,
} from '../../../Telegram/hooks';
import useTelegramGetMimeTypes from '../../../Telegram/hooks/useTelegramMimetypes';
import AutoMailTable from './AutoMailTable';
import { TableWrapper } from './styles';
import { FormikUserLifeCycleStep, FormikUserLifeCycleSteps } from './types';
import {
  deleteNonExistingRows,
  getInitialDummyEntry,
  saveAutoMailList,
  sortAutoMailList,
  transformLifeCycleForFormik,
  validationSchema,
} from './utils';

interface Props {
  token: string;
  isVip: boolean;
}

const AutoMailFormik: FC<Props> = ({ token, isVip }) => {
  const { loading, error, data } = useTelegramGetAutoMails(isVip);
  const {
    loading: loadingMimetypes,
    error: errorMimetypes,
    data: mimetypes,
  } = useTelegramGetMimeTypes(isVip);
  const [saveMutation] = useTelegramSendAutoMails();
  const [deleteAutoMail] = useTelegramDeleteAutoMail();
  const initialDummyEntry = getInitialDummyEntry(isVip) as FormikUserLifeCycleStep[];

  if (!loading && !loadingMimetypes) {
    const initialAutoMailList: FormikUserLifeCycleSteps = {
      autoMailList:
        data?.master?.userLifeCycle.length > 0
          ? sortAutoMailList(transformLifeCycleForFormik(data?.master?.userLifeCycle))
          : initialDummyEntry,
    };
    const allowedMimetype = mimetypes?.master?.allowedMimeTypes || [];

    return (
      <TableWrapper>
        {error && errorMimetypes ? (
          <GenericEmptyContent />
        ) : (
          <Formik
            validationSchema={validationSchema(allowedMimetype)}
            initialValues={initialAutoMailList}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, resetForm }): void => {
              deleteNonExistingRows(initialAutoMailList, values, deleteAutoMail, token);
              saveAutoMailList(values.autoMailList, saveMutation, token, isVip);
              setSubmitting(false);
              resetForm();
            }}
          >
            <Form css={{ flexDirection: 'column', overflow: 'auto' }}>
              <FieldArray name="autoMailList">
                {({ form, push, remove }): ReactElement => {
                  return (
                    <AutoMailTable
                      mimetype={allowedMimetype}
                      form={form}
                      push={push}
                      remove={remove}
                      isVip={isVip}
                    />
                  );
                }}
              </FieldArray>
            </Form>
          </Formik>
        )}
      </TableWrapper>
    );
  } else return null;
};

export default AutoMailFormik;
