import { FormikValues } from 'formik';
import AlertsStore from '../../../stores/Alerts/AlertsStore';
import { targetNumberError } from './types';
import { QueryHookOptions } from '@apollo/react-hooks';
import { Service0900PhoneCarouselMutationResult, Mutation } from '../../../graphql/VXModels/types';
import { ExecutionResult } from 'graphql';
import { mergeDeep } from 'apollo-utilities';
import { QUERY_MODEL_SERVICE0900 } from '../../../graphql/VXModels/queries';
import { FetchResult } from 'apollo-link';
import { DataProxy } from 'apollo-cache';
import { _ } from '../../../util/translate';

export const MAX_NO_OF_NUMBERS = 5;

export const onAddNumberSubmit = async (
  values: FormikValues,
  setFieldError: (field: string, message: string) => void,
  setSubmitting: (isSubmitting: boolean) => void,
  addNumber: (
    options?: QueryHookOptions<Service0900PhoneCarouselMutationResult>
  ) => Promise<ExecutionResult<Mutation>>
): Promise<void> => {
  const { data, errors } = await addNumber({
    variables: { phoneNumber: values.targetNumber.trim() },
  });

  if (errors) {
    AlertsStore.add({
      type: 'error',
      message: _('common:error.generic'),
    });
  } else if (data?.model?.service0900?.addCarouselNumber?.errorMessage) {
    setFieldError('targetNumber', data?.model?.service0900?.addCarouselNumber?.errorMessage);
  }
  setSubmitting(false);
};

export const updateAfterAddedNumber = (cache: DataProxy, { data }: FetchResult<Mutation>): void => {
  const targetNumbers = data?.model?.service0900?.addCarouselNumber?.data?.targetNumbers;
  if (targetNumbers) {
    const cachedData = cache.readQuery({ query: QUERY_MODEL_SERVICE0900 });
    const mergedData = mergeDeep(cachedData, {
      model: { service0900: { phoneCarousel: { targetNumbers } } },
    });
    cache.writeQuery({
      query: QUERY_MODEL_SERVICE0900,
      data: mergedData,
    });
  }
};
