import React, { FunctionComponent, Fragment } from 'react';
import { Box, Grid } from '@material-ui/core';
import { getFormattedDate } from '../../../util/Formatter';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { useLang } from '../../../util/AppState';

interface IconAndTextComponentProps extends WithStyles<typeof styles> {
  icon: React.ReactNode | JSX.Element;
  formattedNumber?: React.ReactNode | JSX.Element;
  rightBoxText?: string;
  date?: string;
  mobileSize: boolean | 'auto' | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  tabletSize: boolean | 'auto' | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
}

const styles = createStyles({
  salesStatisticsContainer: {},
  contentContainer: {
    display: 'flex',
    padding: '18px',
    alignItems: 'center',
  },
  iconBox: {
    textAlign: 'right',
    marginRight: '12px',
  },
  textBox: {},
  selectedAmount: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  contentSpacing: {
    margin: '16px 0',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  salesInformations: { minWidth: '110px' },
  centeredNumber: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: '0px',
  },
});

type Props = IconAndTextComponentProps;

const IconAndTextComponent: FunctionComponent<Props> = ({
  classes,
  icon,
  rightBoxText,
  mobileSize,
  tabletSize,
  date,
  formattedNumber,
}: IconAndTextComponentProps) => {
  const [lang] = useLang();

  return (
    <Grid item xs={mobileSize} md={tabletSize} zeroMinWidth={true}>
      <Box className={classes.container}>
        <Box className={classes.contentContainer}>
          <Box className={classes.iconBox}>{icon}</Box>
          <Box className={classes.salesInformations}>
            {rightBoxText ? (
              <Fragment>
                {rightBoxText + ' '}
                {getFormattedDate(date, lang, false, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  timeZone: 'UTC',
                })}
                <br />
                <Fragment>{formattedNumber}</Fragment>
              </Fragment>
            ) : (
              <Box className={classes.centeredNumber}>{formattedNumber}</Box>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default withStyles(styles)(IconAndTextComponent);
