import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';
import styled from '@emotion/styled';
import { BREAKPOINT_TABLET_CONDITION } from '../../../../camtool-styles';

const PicProfilePreviewContainer = styled.div`
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
`;

class AlbumViewProfileVXFooPreview extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.getStyle = this.getStyle.bind(this);
  }

  getStyle(idx) {
    return this.props.pictures[idx]
      ? { backgroundImage: 'url(' + this.props.pictures[idx].url + ')' }
      : {};
  }

  render() {
    return (
      <PicProfilePreviewContainer className="profile-pic__view__profile__vx__foo__preview">
        {/*Foo Bilder 1 - 4*/}
        <div
          className="profile-pic__view__profile__vx__foo__preview__item"
          style={this.getStyle(3)}
        />
        <div
          className="profile-pic__view__profile__vx__foo__preview__item"
          style={this.getStyle(2)}
        />
        <div
          className="profile-pic__view__profile__vx__foo__preview__item"
          style={this.getStyle(1)}
        />
        <div
          className="profile-pic__view__profile__vx__foo__preview__item"
          style={this.getStyle(0)}
        />

        <div className="profile-pic__view__profile__vx__foo__preview__overlay">
          <div className="profile-pic__view__profile__vx__foo__preview__overlay__status" />
          <div className="profile-pic__view__profile__vx__foo__preview__overlay__name" />
          <div className="profile-pic__view__profile__vx__foo__preview__overlay__spacer" />
          <div className="profile-pic__view__profile__vx__foo__preview__overlay__lang" />
        </div>
      </PicProfilePreviewContainer>
    );
  }
}

AlbumViewProfileVXFooPreview.propTypes = {
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      isRejected: PropTypes.bool,
    })
  ),
};

AlbumViewProfileVXFooPreview.defaultProps = {
  pictures: [],
};

export default AlbumViewProfileVXFooPreview;
export { AlbumViewProfileVXFooPreview };
