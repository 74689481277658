import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../AbstractComponent';
import cx from 'classnames';

class TableHeaderColumnItem extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortDirection: this.props.sortDirection,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sortDirection !== this.state.sortDirection) {
      this.setState({ sortDirection: nextProps.sortDirection });
    }
  }

  handleClick() {
    if (this.props.sortable) {
      switch (this.state.sortDirection) {
        case 'asc':
          this.props.onSort(this.props.name, 'desc');
          break;
        case 'desc':
        default:
          this.props.onSort(this.props.name, 'asc');
          break;
      }
    }
  }

  render() {
    const sortable = this.props.sortable;
    const sortClass = this.state.sortDirection
      ? 'table-header-column__item--' + this.state.sortDirection
      : '';

    return (
      <div
        onClick={this.handleClick}
        className={cx({
          'table-header-column__item': true,
          'table-header-column__item--sorter': sortable,
          [this.props.className]: true,
          [sortClass]: true,
        })}
      >
        <span>{this.props.label}</span>
      </div>
    );
  }
}

TableHeaderColumnItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  sortable: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['', 'asc', 'desc']),
  onSort: PropTypes.func,
};

TableHeaderColumnItem.defaultProps = {
  name: '',
  sortable: false,
  sortDirection: '',
  onSort: () => {},
};

export default TableHeaderColumnItem;
export { TableHeaderColumnItem };
