import ApolloClient from 'apollo-client';
import { useMutation } from '@apollo/react-hooks';
import {
  MUTATION_ACTOR_CANCEL_ABO_SUBSCRIPTIONS,
  MUTATION_ACTOR_DEACTIVATE_ABO,
} from '../../queries';
import { useParams } from 'react-router-dom';

const useSave = (
  apolloClient: ApolloClient<Record<string, never>>
): ((shouldCancelSubs: boolean, shouldDeactivateAbo: boolean) => Promise<void>) => {
  const { id: aboId } = useParams<{ id: string }>();
  const [deactivateAbo] = useMutation(MUTATION_ACTOR_DEACTIVATE_ABO, {
    client: apolloClient,
  });
  const [cancelAboSubscriptions] = useMutation(MUTATION_ACTOR_CANCEL_ABO_SUBSCRIPTIONS, {
    client: apolloClient,
  });

  return async (shouldCancelSubs: boolean, shouldDeactivateAbo: boolean): Promise<void> => {
    const variables = { aboId: Number(aboId) };
    const options = { variables };

    if (shouldDeactivateAbo) {
      await deactivateAbo(options);
    }
    if (shouldCancelSubs) {
      await cancelAboSubscriptions(options);
    }
  };
};

export default useSave;
