import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import ActionTypes from '../../util/constants/ActionTypes';
import ServerUtils from '../../util/ServerUtils';

const STORE_VXCASH_DASHBOARD = 'dashboard';
const STORE_VXCASH_FULLSTAT = 'fullstat';
const STORE_VXCASH_REGISTER = 'register';
const STORE_VXCASH_ADLINKS = 'adlinks';

class VXCashActionCreatorsClass {
  getVXCashStatisticsForDashboard() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/vxcash/dashboardStat', null, function (
      response
    ) {
      VXCashActionCreators.receiveData(STORE_VXCASH_DASHBOARD, response);
    });
  }

  getVXCashAdlinks() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/vxcash/adlinks', null, function (
      response
    ) {
      VXCashActionCreators.receiveData(STORE_VXCASH_ADLINKS, response);
    });
  }

  requestInstagramAdlink() {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/vxcash/requestInstagramAdlink',
      {},
      function (response) {
        VXCashActionCreators.receiveData(STORE_VXCASH_ADLINKS, response);
      }
    );
  }

  getVXCashFullStat(model) {
    let params = '';
    if (model) {
      params = '?year=' + model.year + '&month=' + model.month;
    }

    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/vxcash/fullStat' + params,
      null,
      function (response) {
        VXCashActionCreators.receiveData(STORE_VXCASH_FULLSTAT, response);
      }
    );
  }

  getVXCashRegisterData() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/vxcash/getRegisterData', null, function (
      response
    ) {
      VXCashActionCreators.receiveData(STORE_VXCASH_REGISTER, response);
    });
  }

  sendVXCashRegisterRequest(data) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/vxcash/sendRegisterRequest',
      { data: JSON.stringify(data) },
      function (response) {
        VXCashActionCreators.receiveData(STORE_VXCASH_REGISTER, response);
      }
    );
  }

  createWebmasterId(callback = null) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/vxcash/createWebmasterId',
      null,
      function (response) {
        VXCashActionCreators.receiveData(STORE_VXCASH_REGISTER, response);

        if (typeof callback === 'function') {
          callback();
        }
      }
    );
  }

  receiveData(store, data) {
    AppDispatcher.dispatch({
      type: ActionTypes.VXCASH_RECEIVE_DATA,
      store: store,
      data: data,
    });
  }
}

const VXCashActionCreators = new VXCashActionCreatorsClass();

export { VXCashActionCreators };
