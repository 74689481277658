/* eslint-disable react/prop-types */
import React from 'react';
import { useAppState, ActionEnum } from '../../../util/AppState';
import Flag from '../../../atoms/Flag';
import { ApiLang } from '../../../graphql/VXModels/types';
import * as PropTypes from 'prop-types';

export default function LanguageSelector({ className }) {
  const [appState, dispatch] = useAppState();
  const { lang } = appState;

  const setLangDE = () =>
    lang !== ApiLang.de && dispatch({ type: ActionEnum.setLang, lang: ApiLang.de });

  const setLangEN = () =>
    lang !== ApiLang.en && dispatch({ type: ActionEnum.setLang, lang: ApiLang.en });

  return (
    <div className={className}>
      <LangButton
        lang={ApiLang.de}
        onClick={setLangDE}
        css={{
          opacity: lang === ApiLang.de ? 1 : 0.2,
          cursor: lang === ApiLang.de ? 'default' : 'pointer',
          '&:hover': {
            opacity: lang === ApiLang.de ? 1 : 0.6,
          },
        }}
      />

      <LangButton
        lang={ApiLang.en}
        css={{
          marginLeft: 8,
          opacity: lang === ApiLang.en ? 1 : 0.2,
          cursor: lang === ApiLang.en ? 'default' : 'pointer',
          '&:hover': {
            opacity: lang === ApiLang.en ? 1 : 0.6,
          },
        }}
        onClick={setLangEN}
      />
    </div>
  );
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
};

LanguageSelector.defaultPropTypes = {
  className: '',
};

function LangButton({ lang, onClick, className }) {
  return (
    <button
      onClick={onClick}
      className={className}
      css={{
        padding: 4,
        background: 'transparent',
        border: '1px solid #f2f2f2',
        borderRadius: 2,
      }}
    >
      <Flag lang={lang} css={{ height: 18 }} />
    </button>
  );
}
