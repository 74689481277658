import React, { Fragment, ReactElement, ReactNode } from 'react';
import { Query } from '@apollo/react-components';
import { QueryResult } from '@apollo/react-common';
import { AllowNode, NodeType } from 'react-markdown';
import { RouteComponentProps, withRouter } from 'react-router';
import { css } from '@emotion/core';

import { QUERY_CMS_ARTICLE } from '../../graphql/VXModels/queries';
import Spinner from '../Spinner/Spinner';
import Markdown from '../Markdown/Markdown';
import { useAppState } from '../../util/AppState';

import { log } from '../../util';

interface IProps extends RouteComponentProps {
  id: string;
  className?: string;
  escapeHtml?: boolean;
  skipHtml?: boolean;
  allowNode?: (node: AllowNode, index: number, parent: NodeType) => boolean;
  allowedTypes?: NodeType[];
  disallowedTypes?: NodeType[];
  transformLinkUri?: (uri: string, children?: ReactNode, title?: string) => string;
  transformImageUri?: (uri: string, children?: ReactNode, title?: string, alt?: string) => string;
  unwrapDisallowed?: boolean;
}

const styles = css`
  min-height: 200px;
`;

const CmsArticle = withRouter(
  ({ id, location, children, ...markdownProps }: IProps & { children: ReactElement }) => {
    const revData = /#rev=(\d+)/.exec(location.hash);
    const rev = revData && revData[1] ? parseInt(revData[1], 10) : 0;
    const [appState] = useAppState();

    return (
      <Query query={QUERY_CMS_ARTICLE} variables={{ rev, lang: appState.lang, id }}>
        {({ data, loading, error }: QueryResult) => {
          if (loading) {
            return (
              <div css={styles}>
                <Spinner />
              </div>
            );
          }
          if (error) {
            return <div />;
          }
          const article = data.cms.article;

          if (article) {
            return (
              <Fragment>
                <Markdown source={article.content} {...markdownProps} />
                {children}
                {article.revision.pdfUrl !== null && (
                  <a
                    href={article.revision.pdfUrl}
                    target="_blank"
                    style={{ margin: '-10px 0 40px 40px' }}
                    rel="noreferrer"
                  >
                    Download PDF
                  </a>
                )}
              </Fragment>
            );
          }

          log('warning', `Article not found: ${id}`, {});
          return false;
        }}
      </Query>
    );
  }
);

export default CmsArticle;
