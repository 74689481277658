import React from 'react';
import styled from '@emotion/styled';
import { styled as muiStyled } from '@material-ui/core/styles';
import CancelCheckboxLabel from './CancelCheckboxLabel';
import { MUICheckbox, MUICheckboxFormControlLabel } from '../../../../../components/MUI';

interface Props {
  name: string;
  title: string;
  subtitle: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  className?: string;
}

const Container = styled.div`
  padding-left: 4px;
  margin-right: -16px;
`;

const StyledMUICheckbox = muiStyled(MUICheckbox)({
  alignSelf: 'start',
});

const CancelCheckbox: React.FC<Props> = ({
  name,
  title,
  subtitle,
  onChange,
  checked,
  className,
}) => (
  <Container className={className}>
    <MUICheckboxFormControlLabel
      color="secondary"
      checked={checked}
      control={<StyledMUICheckbox onChange={onChange} name={name} />}
      label={<CancelCheckboxLabel title={title} subtitle={subtitle} />}
    />
  </Container>
);

export default CancelCheckbox;
