import React, { FC } from 'react';
import { Fieldset } from '../../../../../atoms/Fieldset';
import FieldCheckbox from '../../../../../molecules/Formik/Fields/FieldCheckbox';
import { Translation } from '../../../../../components';
import { SectionLabel } from '../../styles';
import { FormValues } from '../FormWrapper';
import { Box, Grid, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { BLUE } from '../../../../../camtool-styles';

interface PublishScheduledProps {
  editable: boolean;
  values: FormValues;
}

const SendTippingLink: FC<PublishScheduledProps> = ({
  editable,
  values: { tipping, attachment },
}) => (
  <Fieldset
    disabled={!editable || !tipping}
    css={{ marginBottom: 0 }}
    legend={
      <FieldCheckbox
        name={'tipping'}
        disabled={!editable || attachment}
        label={<Translation t={'mailings:form.telegram.tipping.label'} />}
      />
    }
  >
    <Grid item xs={12}>
      <SectionLabel>
        <Box mt={1} style={{ paddingLeft: 12 }}>
          <Typography>
            <Translation t={'mailings:form.telegram.tipping.heading'} />
          </Typography>
        </Box>
      </SectionLabel>
    </Grid>
    <Typography style={{ marginLeft: 12 }}>
      <Translation t={'mailings:form.telegram.tipping.subheading'} />
    </Typography>
    {attachment && (
      <Typography style={{ color: BLUE, marginLeft: 12 }}>
        <br />
        <InfoIcon style={{ width: '16px', marginBottom: '-5px' }} />{' '}
        <Translation t={'mailings:form.telegram.tipping.info'} />
      </Typography>
    )}
  </Fieldset>
);
export default SendTippingLink;
