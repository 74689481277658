import gql from 'graphql-tag';

export const FRAGMENT_FILE = gql`
  fragment File on File {
    id
    uuid
    type
    exists
    url
    previewPictureUrl
    width
    height
    imgSrcSet
    transcodingFailed
    mimeType
  }
`;
