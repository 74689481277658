import React from 'react';
import { Query } from '@apollo/react-components';
import { RouteComponentProps, withRouter } from 'react-router';
import { QueryResult } from '@apollo/react-common';

import { QUERY_CMS_ARTICLE } from '../../graphql/VXModels/queries';
import { useAppState } from '../../util/AppState';

import { log } from '../../util';

interface IProps extends RouteComponentProps {
  id: string;
}

const CmsArticleTitle = withRouter(({ id, location }: IProps) => {
  const revData = /#rev=(\d+)/.exec(location.hash);
  const rev = revData && revData[1] ? parseInt(revData[1], 10) : 0;
  const [appState, _] = useAppState();

  return (
    <Query query={QUERY_CMS_ARTICLE} variables={{ rev, lang: appState.lang, id }}>
      {({ data }: QueryResult) => {
        if (data && data.cms) {
          return data.cms.article.title;
        }
        return '';
      }}
    </Query>
  );
});

export default CmsArticleTitle;
