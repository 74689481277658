import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/core';
import { useRouteMatch } from 'react-router';
import { BREAKPOINT_PHONE } from '../../camtool-styles';
import { APP_BASE_PATH } from '../../util/env';

const PHONE_PADDING = 4;
const DESKTOP_PADDING = 24;

const CONFIRM_EMAIL_ROUTE = `${APP_BASE_PATH}/confirmEmail`;

interface ContainerProps {
  noPadding: boolean;
}

export const Container = styled.div<ContainerProps>`
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative; // for the Spinner
  height: 100%;
  width: 100%;

  ${(props): SerializedStyles | string =>
    !props.noPadding
      ? css`
          padding: ${DESKTOP_PADDING}px ${DESKTOP_PADDING}px 0 ${DESKTOP_PADDING}px;

          ${BREAKPOINT_PHONE} {
            padding: ${PHONE_PADDING}px ${PHONE_PADDING}px 0 ${PHONE_PADDING}px;
          }
        `
      : ''}
`;

const InnerContentContainer: FC = ({ children }) => {
  const isConfirmEmailRoute = !!useRouteMatch(CONFIRM_EMAIL_ROUTE);

  return <Container noPadding={isConfirmEmailRoute}>{children}</Container>;
};

export default InnerContentContainer;
