import React, { FC } from 'react';
import { _ } from '../../../../../util/translate';
import PhoneNumberInput from './PhoneNumberInput';

interface Props {
  onResetStep: () => void;
}

const NonEditablePhoneNumberInput: FC<Props> = ({ onResetStep }) => {
  return (
    <div css={{ flexDirection: 'column', width: '100%' }}>
      <div css={{ flexDirection: 'column' }}>
        <PhoneNumberInput disabled={true} />
      </div>
      <span
        css={{
          margin: '9px 0 40px 7px',
          textDecoration: 'underline',
          fontStyle: 'italic',
          cursor: 'pointer',
          alignSelf: 'flex-start',
        }}
        onClick={(): void => onResetStep()}
      >
        {_('service0900:PhoneSex.modal.verify.change.number')}
      </span>
    </div>
  );
};

export default NonEditablePhoneNumberInput;
