import React from 'react';
import PropTypes from 'prop-types';
import AbstractFormComponent from '../../AbstractFormComponent';
import { RED } from '../../../../camtool-styles';

class Inputfield extends AbstractFormComponent {
  render() {
    this.hasValue = this.getValue() !== '';
    return (
      <div className={`input ${this.getClassNames()} ${this.hasValue ? 'valueisset' : ''}`}>
        <label className={`input__label ${this.props.labelClass}`}>
          <div className="input__label__text">{this.props.label}</div>
          <div className="input__label__note">{this.props.note}</div>
          <div css={{ flex: 1, flexDirection: 'column' }}>
            <div className="input__box">
              <input
                placeholder={this.props.placeholder}
                type={this.props.type}
                name={this.props.name}
                disabled={this.props.disabled ? 'disabled' : false}
                readOnly={this.props.readOnly ? 'readOnly' : false}
                onChange={this.onChange}
                onKeyDown={this.props.onKeyDown}
                onBlur={this.props.onBlur}
                value={this.getValue()}
                maxLength={this.props.maxLength ? this.props.maxLength : null}
                ref={(r) => {
                  this.props.inputRef(r);
                }}
                onFocus={this.props.onFocus}
              />
            </div>
            {this.props.errorMessage && (
              <span css={{ color: RED, marginTop: '4px' }}>{this.props.errorMessage}</span>
            )}
          </div>
        </label>
      </div>
    );
  }
}

Inputfield.propTypes = Object.assign(AbstractFormComponent.propTypes, {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  labelClass: PropTypes.string,
  onKeyDown: PropTypes.func,
  inputRef: PropTypes.func,
  onFocus: PropTypes.func,
});

Inputfield.defaultProps = Object.assign(AbstractFormComponent.defaultProps, {
  placeholder: '',
  disabled: false,
  errorMessage: '',
  readOnly: false,
  type: 'text',
  onBlur: () => {},
  labelClass: '',
  onKeyDown: () => {},
  inputRef: () => {},
  onFocus: () => {},
});

export default Inputfield;
export { Inputfield };
