import React, { FC } from 'react';
import { GRAY_2 } from '../../../../camtool-styles';
import { Button, InputField } from '../../../../atoms';
import { _ } from '../../../../util/translate';
import { Service0900PhoneData } from '../types';

interface Props {
  storeData: Service0900PhoneData;
  openModal: () => void;
}

const EditNumber: FC<Props> = ({ storeData, openModal }) => {
  const fullPhoneNumber = `${storeData?.targetNumber?.countryCallingCode}${storeData?.targetNumber?.phoneNumber}`;

  return (
    <div css={{ flexDirection: 'column' }}>
      <span css={{ marginBottom: '4px' }}>
        {_('service0900:PhoneSex.active.request.edit.headline')}
      </span>
      <div css={{ border: `0.5px solid ${GRAY_2}`, borderRadius: '5px' }} onClick={openModal}>
        <InputField css={{ flex: '1 0 auto' }} type="text" readOnly value={fullPhoneNumber} />
        <Button theme={'gray-white'} css={{ borderRadius: '0px 5px 5px 0px' }}>
          {_('common:button.edit')}
        </Button>
      </div>
      <span css={{ color: GRAY_2, fontStyle: 'italic', marginTop: '6px' }}>
        {_('service0900:PhoneSex.active.request.edit.subInfo')}
      </span>
    </div>
  );
};

export default EditNumber;
