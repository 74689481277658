import { QUERY_MODEL_MAILINGS_DRAFT } from './queries/mailings';
import { Resolvers } from 'apollo-client/core/types';

export const resolvers: Resolvers = {
  Mailings: {
    draft: (mailing, _, { cache }) => {
      const data = cache.readQuery({ query: QUERY_MODEL_MAILINGS_DRAFT });
      console.log('pegaito a la pared', data);

      return {
        targetGroup: ['TEST'],
        subject: 'none',
        textHTML: '<h1>klk papa</h1>',
        releaseDate: new Date(Date.UTC(2019, 16, 1)).toISOString(),
      };
    },
  },
};
