import React, { FC, ReactElement } from 'react';
import NewButton from '../../../../atoms/Button/NewButton';
import { makeStyles, createStyles } from '@material-ui/core';
import { GRAY_8 } from '../../../../routes/BenefitsWizard/styles';
import { BLUE } from '../../../../camtool-styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: '133px',
      fontSize: '12px',
    },
    outlinedPrimary: (props: { active: boolean | undefined }) => ({
      color: props.active ? BLUE : GRAY_8,
      borderColor: props.active ? BLUE : GRAY_8,
    }),
  })
);

interface IProps {
  label: string | ReactElement;
  onChange: () => void;
  active: boolean | undefined;
}

const FilterButton: FC<IProps> = ({ label, onChange, active }) => {
  const classes = useStyles({ active });
  return (
    <NewButton classes={classes} variant="outlined" size="small" onClick={onChange}>
      {label}
    </NewButton>
  );
};

export default FilterButton;
