import React, { FC } from 'react';
import FlagButton from '../../atoms/FlagButton';
import { useLang } from '../../util/AppState';
import styled from '@emotion/styled';
import { ApiLang } from '../../graphql/VXModels/types';

const Container = styled.div`
  flex: 0 0 40px;
  justify-content: center;
  cursor: pointer;
  background: #f5f5f5;
`;

interface Props {
  className?: string;
}

const LanguageSelector: FC<Props> = ({ className }) => {
  const [lang, setLang] = useLang();

  return (
    <Container className={className}>
      <FlagButton
        lang={ApiLang.de}
        withLabel={false}
        active={lang === ApiLang.de}
        onClick={(): void => lang !== ApiLang.de && setLang(ApiLang.de)}
      />
      <FlagButton
        lang={ApiLang.en}
        withLabel={false}
        active={lang === ApiLang.en}
        onClick={(): void => lang !== ApiLang.en && setLang(ApiLang.en)}
      />
    </Container>
  );
};

export default LanguageSelector;
