import React, {memo, createContext, ReactComponentElement, ReactNode, useContext, useReducer} from 'react';
import { APP_IS_DEVELOPMENT } from '../../util/env';

const DEBUG = APP_IS_DEVELOPMENT && true;

enum ModalboxType {
    AGB_AND_TERMS = 'AGB_AND_TERMS',
    INVITE_MODEL_CAMPAIGN = 'INVITE_MODEL_CAMPAIGN',
}

export enum ActionType {
    OPEN = 'open',
    CLOSE = 'close',
}

interface IAction {
    type: ActionType,
    modalboxType: ModalboxType,
}

interface IState {
    stack: ModalboxType[];
}

const initialState = {
  stack: [],
    current: undefined
};

export const ModalboxStateContext = createContext(initialState);

const reducer = ( state:IState, action:IAction ) => {

    const {stack} = state;

  switch (action.type) {
    case ActionType.OPEN:
        stack.push(action.modalboxType);
        return { ...state, stack, current: stack.slice(-1).pop() };

    case ActionType.CLOSE:
      return { ...state, current: state.stack.pop(), stack };

    default:
      return state;
  }
};

const ModalboxState = memo(({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ModalboxStateContext.Provider value={[state, dispatch]}>
        {children}
    </ModalboxStateContext.Provider>
  );
});

export default ModalboxState;

export const useModalboxState = () => useContext(ModalboxStateContext);
