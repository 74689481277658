import { Theme } from '@material-ui/core';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { CustomColor } from './types';

export const getPaletteColor = (
  theme: Theme,
  color: 'primary' | 'secondary' | 'default' | 'warning' | 'error' | 'success' | undefined
): PaletteColor =>
  (color && theme.palette[color as CustomColor]) || {
    main: '',
    light: '',
    contrastText: '',
    dark: '',
  };
