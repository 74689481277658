import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';

class ColorRadioButton extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPressed: false,
    };
  }

  componentWillMount() {
    if (this.props.isPressedByDefault) this.setState({ isPressed: this.props.isPressedByDefault });
  }

  toggleIsPressed() {
    this.setState({ isPressed: !this.state.isPressed });
  }

  isPressed() {
    if (this.props.isPressed !== null) return this.props.isPressed;
    else return this.state.isPressed;
  }

  handleOnClick() {
    if (this.props.isPressed !== null) this.props.onClick();
    else this.toggleIsPressed();
  }

  render() {
    return (
      <section
        className={[
          'color-radio-button',
          this.props.customClass,
          this.props.fullWidth ? 'color-radio-button__fancy-button--full-width' : null,
        ].join(' ')}
      >
        <label
          onClick={this.props.onClick}
          className={this.props.fullWidth ? 'color-radio-button__fancy-button--full-width' : null}
        >
          <div
            className={[
              'color-radio-button__fancy-button',
              'color-radio-button__fancy-button--' + this.props.pressedColor,
              this.props.fullWidth ? 'color-radio-button__fancy-button--full-width' : null,
              this.state.isPressed || this.props.isPressed
                ? 'color-radio-button__fancy-button--pressed-' + this.props.pressedColor
                : null,
            ].join(' ')}
          >
            {this.props.label}
          </div>
          <input
            type="radio"
            value={this.props.value}
            className="color-radio-button__button"
            onClick={this.handleOnClick}
            checked={this.isPressed()}
          />
        </label>
      </section>
    );
  }
}

ColorRadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  isPressedByDefault: PropTypes.bool,
  isPressed: PropTypes.bool,
  pressedColor: PropTypes.oneOf(['red', 'green', 'blue']),
  customClass: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
};

ColorRadioButton.defaultProps = {
  value: '',
  pressedColor: 'blue',
  isCheckedByDefault: false,
  isPressed: false,
  customClass: null,
  fullWidth: true,
  onClick: () => {},
};

export default ColorRadioButton;
export { ColorRadioButton };
