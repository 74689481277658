import React, { FC, ReactNode } from 'react';
import {
  Avatar,
  AvatarProps,
  createStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TypographyProps } from '@material-ui/core/Typography';

interface ShortInfoProps<
  PrimaryTypographyComponent extends React.ElementType = 'span',
  SecondaryTypographyComponent extends React.ElementType = 'p'
> {
  avatar?: ReactNode;
  avatarProps?: AvatarProps;
  primary: ReactNode;
  primaryTypographyProps?: TypographyProps<
    PrimaryTypographyComponent,
    { component?: PrimaryTypographyComponent }
  >;
  secondary?: ReactNode;
  secondaryTypographyProps?: TypographyProps<
    SecondaryTypographyComponent,
    { component?: SecondaryTypographyComponent }
  >;
}

const useStyles = makeStyles(() =>
  createStyles({
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    root: {
      minWidth: '77%',
    },
  })
);

const ShortInfo: FC<ShortInfoProps> = ({
  avatar,
  primary,
  primaryTypographyProps,
  secondary,
  secondaryTypographyProps,
}) => {
  const { gutters, root } = useStyles();
  return (
    <ListItem classes={{ gutters }} component={'div'}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText
        classes={{ root }}
        primary={primary}
        primaryTypographyProps={primaryTypographyProps}
        secondary={secondary}
        secondaryTypographyProps={secondaryTypographyProps}
      />
    </ListItem>
  );
};

export default ShortInfo;
