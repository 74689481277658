import React, { FC } from 'react';
import styled from '@emotion/styled';
import { explanation, explanationEN, explanationMobile, explanationMobileEN } from '../assets';
import { GRAY_3 } from '../../../../camtool-styles';
import { useLang } from '../../../../util/AppState';
import { ApiLang } from '../../../../graphql/VXModels/types';
import { _ } from '../../../../util/translate';
import Advantages from './Advantages';
import VIP30Explanation from './VIP30Explanation';
import { BREAKPOINT_SMALL } from '../breakpoints';

const Headline = styled.h1`
  font-size: 18px;
  margin-bottom: 32px;
  text-align: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    text-align: start;
    font-size: 14px;
    margin-bottom: 18px;
  }
`;

const MarketingParagraph = styled.p`
  margin-bottom: 15px;
  line-height: 16px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    text-align: start;
  }
`;

const LastMarketingParagraph = styled(MarketingParagraph)`
  margin-bottom: 43px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 30px;
  }
`;

const ExplanationImage = styled.img`
  width: 100%;
  display: block;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: none;
  }
`;

const ExplanationImageMobile = styled.img`
  width: calc(100% - 15px);
  max-width: 325px;
  display: none;
  margin-left: 15px;
  align-self: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    display: block;
  }
`;

const Divider = styled.div`
  width: 100%;
  background-color: ${GRAY_3};
  flex: 0 0 1px;
`;

const PromotionalContent: FC = () => {
  const [lang] = useLang();

  const explanationImage = lang === ApiLang.de ? explanation : explanationEN;
  const explanationImageMobile = lang === ApiLang.de ? explanationMobile : explanationMobileEN;

  return (
    <>
      <Headline>{_('modalbox:dailyBonus.headline')}</Headline>
      <MarketingParagraph>{_('modalbox:dailyBonus.marketingParagraph1')}</MarketingParagraph>
      <LastMarketingParagraph>
        {_('modalbox:dailyBonus.marketingParagraph2')}
      </LastMarketingParagraph>
      <ExplanationImage src={explanationImage} />
      <ExplanationImageMobile src={explanationImageMobile} />
      <Advantages />
      <Divider />
      <VIP30Explanation />
      <Divider />
    </>
  );
};

export default PromotionalContent;
