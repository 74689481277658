import React from 'react';
import { Field, FieldProps } from 'formik';
import { DatePicker } from '../../../atoms';

interface IProps {
  name: string;
  disabled?: boolean;
}

const FieldDatePicker = ({ name, disabled = false }: IProps) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps) => (
      <DatePicker
        disabled={disabled}
        single={true}
        name={field.name}
        onFromChange={(datetime: string) => form.setFieldValue(name, datetime)}
        from={field.value}
        showTime={true}
        css={{
          maxWidth: '100%',
          border: '1px solid #c0c0c0',
          borderRadius: 4,
          input: {
            borderRadius: 4,
          },
        }}
      />
    )}
  />
);

export default FieldDatePicker;
