import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { MobileUserLifeCycleStep } from '../types';
import AutoMailGridPreviewItem from './AutoMailGridPreviewItem';
interface ArrayHelpers {
  lifecycles: MobileUserLifeCycleStep[];
}

const AutoMailGrid: FC<ArrayHelpers> = ({ lifecycles }) => {
  return (
    <Grid container>
      <Grid item xs={12} style={{ overflow: 'hidden' }}>
        {lifecycles?.map((lifecycle: MobileUserLifeCycleStep, index: number) => (
          <AutoMailGridPreviewItem key={index} lifecycle={lifecycle} />
        ))}
      </Grid>
    </Grid>
  );
};

export default AutoMailGrid;
