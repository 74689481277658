import React, { FC } from 'react';
import { Box, Grid, createStyles, makeStyles } from '@material-ui/core';
import { Total } from './Total';
import { participantsicon, salesicon } from '../assets/icons';
import { GRAY_4 } from '../../../../../../camtool-styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      paddingBottom: 30,
    },
    box: {
      borderRight: `1px solid ${GRAY_4}`,
    },
  })
);

interface TotalOverviewProps {
  total: number | undefined;
  completed: number | undefined;
}

const TotalOverview: FC<TotalOverviewProps> = ({ total, completed }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Total number={total} text={'Teilnehmer Gesamt'} icon={participantsicon} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Total number={completed} text={'Abgeschlossen'} icon={salesicon} />
        </Grid>
      </Grid>
    </Box>
  );
};

export { TotalOverview };
