import React from 'react';
import PropTypes from 'prop-types';
import AbstractFormComponent from '../AbstractFormComponent';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { MUICheckbox } from '../../MUI';

class SimpleCheckbox extends AbstractFormComponent {
  render() {
    return (
      <FormControlLabel
        control={
          <MUICheckbox
            name={this.props.name}
            value={this.getValue()}
            size={'medium'}
            onChange={this.onChange}
            checked={this.props.checked}
            required={this.props.required}
            disabled={this.props.disabled}
            color={'success'}
          />
        }
        label={this.props.label}
      />
    );
  }
}

SimpleCheckbox.propTypes = Object.assign(AbstractFormComponent.propTypes, {
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
});

SimpleCheckbox.defaultProps = Object.assign(AbstractFormComponent.defaultProps, {
  name: '',
  label: '',
  checked: false,
  required: false,
  disabled: false,
});

export default SimpleCheckbox;
