import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Button extends React.Component {
  disabledClass() {
    if (this.props.disabled === true) {
      return 'disabled';
    }
    if (this.props.disabled) {
      return this.props.disabled;
    }
    return '';
  }

  render() {
    if (!this.props.label) return null;
    return (
      <div
        className={`button ${this.props.className}
          ${this.props.disabled && 'button--disabled'}`}
        style={this.props.containerStyles}
      >
        {!this.props.href && !this.props.to && (
          <button
            className={this.props.icon}
            name={this.props.label}
            type={this.props.type}
            onClick={this.props.onClick}
            disabled={this.props.disabled}
            tabIndex={this.props.tabIndex}
            style={this.props.styles}
          >
            {this.props.label}
          </button>
        )}

        {this.props.href && (
          <a
            className={this.props.icon}
            name={this.props.label}
            href={this.props.href}
            target={this.props.target}
            tabIndex={this.props.tabIndex}
            style={this.props.styles}
          >
            {this.props.label}
          </a>
        )}

        {this.props.to && (
          <Link to={this.props.to} className={this.props.icon} style={this.props.styles}>
            {this.props.label}
          </Link>
        )}
      </div>
    );
  }
}

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  target: PropTypes.string,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  containerStyles: PropTypes.object,
};

Button.defaultProps = {
  label: null,
  className: '',
  href: null,
  to: null,
  icon: null,
  type: null,
  target: null,
  tabIndex: 0,
  disabled: false,
  onClick: () => {},
  styles: {},
  containerStyles: {},
};

export default Button;
export { Button };
