import React, { FC } from 'react';

interface IImagePickerItemProps {
  url: string;
  selected: boolean;
  onClick?: (url: string) => void;
}

const ImagePickerItem: FC<IImagePickerItemProps> = ({ url, onClick, selected }) => {
  const handleClick = () => {
    if (onClick && url) onClick(url);
  };

  return (
    <div
      onClick={handleClick}
      css={{
        width: 184,
        height: 184,
        border: `3px solid ${selected ? 'blue' : 'transparent'}`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        alignContent: 'flex-start',
        backgroundImage: `url('${url}')`,
        margin: 4,
        borderRadius: 4,
        ':hover': {
          opacity: 0.6,
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default ImagePickerItem;
