import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/react-components';

import { BLUE } from '../../../camtool-styles';
import { QUERY_MODEL_MAILINGS_MAILINGS } from '../../../graphql/VXModels/queries';
import StatusTranslated from './StatusTranslated';
import { FancyError, Spinner, Translation } from '../../../components';
import {
  Mailing,
  MailingRecipientsGroupsEnum,
  MailingStatusEnum,
  MailingTypeEnum,
} from '../../../graphql/VXModels/types';
import { buildEditUrl } from '../../../routes/Mailings/utils';
import { DateTimeFormatter } from '../../../atoms/I18n';
import { _ } from '../../../util/translate';

const TableHeader = styled.header`
  border-bottom: 1px solid #c0c0c0;
  padding: 8px 16px;
`;

const TableBody = styled.section``;

const TableCell = styled.div`
  flex: 1;
  margin: 0 8px;
  padding: 8px 0;
`;

interface TableRowProps {
  onClick?: (rowIndex: number) => void;
  rowIndex: number;
  children: ReactNode;
}

const TableRow = ({ onClick, rowIndex, children }: TableRowProps) => {
  const Continer = styled.div`
    border-bottom: 1px solid #c0c0c0;
    padding: 0 16px;
    &:hover {
      opacity: 0.6;
    }
  `;

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick(rowIndex);
    }
  };

  return <Continer onClick={handleClick}>{children}</Continer>;
};

interface Props {
  type: MailingTypeEnum;
  group?: MailingRecipientsGroupsEnum;
}

const Archive = ({ type, group }: Props) => {
  // prettier-ignore
  const TABLE_DATA = {
    size: [64, 100, 300, 108, 108, 170, 118, 100].map(el => el + 8),
    header: [
      _('mailings:email.archiveColumn.status'),
      _('mailings:email.archiveColumn.recipients'),
      _('mailings:email.archiveColumn.subject'),
      _('mailings:email.archiveColumn.creationDate'),
      _('mailings:email.archiveColumn.action'),
    ],
  };

  return (
    <article css={{ overflow: 'auto' }}>
      <div css={{ flexDirection: 'column', flex: 1, padding: 32 }}>
        <TableHeader>
          {TABLE_DATA.header.map((col, i) => (
            <TableCell
              key={col}
              css={{
                minWidth: TABLE_DATA.size[i],
                justifyContent: i === 1 || i === 4 || i === 5 ? 'center' : 'flex-start',
              }}
            >
              {col}
            </TableCell>
          ))}
        </TableHeader>

        <TableBody css={{ flexDirection: 'column' }}>
          <Query
            query={QUERY_MODEL_MAILINGS_MAILINGS}
            fetchPolicy={'cache-and-network'}
            variables={{ type, recipientsGroups: group }}
          >
            {({ loading, data, error, client }) => {
              if (error) {
                return <FancyError error={error} />;
              }

              if (data && data.model) {
                return data.model.mailings.mailings.map((mailing: Mailing, i: number) => {
                  return (
                    <TableRow key={i} rowIndex={i}>
                      <TableCell css={{ minWidth: TABLE_DATA.size[0] }}>
                        <StatusTranslated status={mailing.status} />
                      </TableCell>

                      <TableCell css={{ minWidth: TABLE_DATA.size[1], justifyContent: 'center' }}>
                        {mailing.shipmentRecipientsCount}
                      </TableCell>

                      <TableCell css={{ minWidth: TABLE_DATA.size[2] }}>{mailing.name}</TableCell>

                      <TableCell css={{ minWidth: TABLE_DATA.size[3] }}>
                        <DateTimeFormatter value={mailing.modified} format={'datetime'} />
                      </TableCell>

                      <TableCell
                        css={{
                          minWidth: TABLE_DATA.size[5],
                          justifyContent: 'center',
                          color: BLUE,
                          cursor: 'pointer',
                          '&:hover': { 'span:last-of-type': { textDecoration: 'underline' } },
                        }}
                      >
                        {mailing.status === MailingStatusEnum.DRAFT ? (
                          <Link
                            to={buildEditUrl(mailing)}
                            css={{ color: BLUE, textDecoration: 'none' }}
                          >
                            <span className="icon-edit" css={{ marginRight: 4 }} />
                            <span>
                              <Translation t={'common:text.edit'} />
                            </span>
                          </Link>
                        ) : (
                          <Link
                            to={buildEditUrl(mailing)}
                            css={{ color: BLUE, textDecoration: 'none' }}
                          >
                            <span className="icon-display" css={{ marginRight: 4 }} />
                            <span>
                              <Translation t={'common:text.show'} />
                            </span>
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                });
              }

              if (loading) {
                return <Spinner />;
              }
            }}
          </Query>
        </TableBody>
      </div>
    </article>
  );
};

export default Archive;
