import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { DARK_GRAY, WHITE } from '../../../camtool-styles';
import Modalbox, { modalboxMargin } from '../../Modalbox/Modalbox';
import ModalTermsContentShort from './ModalTermsContentShort';
import ModalTermsContentLong from './ModalTermsContentLong';
import Icon from '../../../atoms/Icon/Icon';
import { remove } from '../../../atoms/Icon/libraries/glyph';
import { TermAcceptance } from '../../../graphql/VXModels/types';

interface IProps {
  termsAcceptance: TermAcceptance;
  closable?: boolean;
  onClose?: () => void;
}

const ModalContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  max-height: calc(100vh - ${modalboxMargin * 2}px);
  overflow-y: auto;
  flex-direction: column;
  border-radius: 2px;
  background: ${WHITE};
  width: 100%;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  padding: 13px;
  top: 0;
  right: 0;
  cursor: pointer;
  color: ${DARK_GRAY};
`;

const StyledModalbox = styled(Modalbox)`
  max-width: 768px;
  max-height: 100%;
`;

const ModalTerms: FC<IProps> = ({ termsAcceptance, closable = false, onClose }) => {
  const type = termsAcceptance.type;
  const cmsArticleName = termsAcceptance.cmsArticle.id;
  const requirePassword = termsAcceptance.isPasswordRequired;
  const isTermsUpdate = termsAcceptance.hasAcceptedAnyVersion;

  const [showLongVersion, setShowLongVersion] = useState(!isTermsUpdate);

  return (
    <StyledModalbox
      noCloseOnESC
      name={cmsArticleName}
      state={true}
      zIndex={1000} // zIndex 1000 to beat notifications zIndex of 999
    >
      <ModalContainer>
        {showLongVersion ? (
          <ModalTermsContentLong
            cmsArticleName={cmsArticleName}
            type={type}
            requirePassword={requirePassword}
          />
        ) : (
          <ModalTermsContentShort
            cmsArticleName={cmsArticleName}
            type={type}
            onCheckboxLinkClick={() => setShowLongVersion(true)}
            requirePassword={requirePassword}
          />
        )}
        {!!closable && <CloseIcon icon={remove} size="17px" onClick={onClose} />}
      </ModalContainer>
    </StyledModalbox>
  );
};

export default ModalTerms;
export { StyledModalbox, ModalContainer };
