import React, { FC, useEffect, useRef, useState, Ref, ChangeEvent } from 'react';
import { Box, Tab, Tabs, TabsActions } from '@material-ui/core';
import { ApiLang } from '../../../graphql/VXModels/types';
import { Flag } from '../../index';
import { LanguageTabsProps } from './types';
import { useTabsStyles } from './styles';

const LanguageTabs: FC<LanguageTabsProps> = ({
  language,
  changeLanguage,
  customLanguageArray,
  label,
  error,
}) => {
  const languageArray = customLanguageArray || [ApiLang.de, ApiLang.en];
  const [tab, setTab] = useState<number>(languageArray.indexOf(language));
  const classes = useTabsStyles({ error });
  const ref = useRef<Ref<TabsActions>>(null);

  // updates tab because underline of tab is not positioned correctly on initial render (on mobile mostly)
  useEffect(() => {
    setTimeout(() => {
      ref.current?.updateIndicator();
    }, 100);
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);
    changeLanguage(newValue);
  };

  return (
    <Tabs
      classes={{ root: classes.tabs, indicator: classes.indicator }}
      value={tab}
      onChange={handleChange}
      action={ref}
    >
      {languageArray.map((lang) => (
        <Tab
          key={label[lang]}
          classes={{ root: classes.tab, selected: classes.selected, wrapper: classes.wrapper }}
          label={
            <Box display="flex">
              <Flag className={classes.flag} lang={lang} /> <span>{label[lang]}</span>
            </Box>
          }
          // ripple effect here is buggy on desktop
          disableRipple
        />
      ))}
    </Tabs>
  );
};

export default LanguageTabs;
