import React, { createContext, FC, useEffect } from 'react';
import { EventEmitter2 } from 'eventemitter2';

import SockJS from 'sockjs-client';
import { APP_IS_DEVELOPMENT, APP_WS_HOST } from '../../util/env';
import {
  Message,
  PublishFunc,
  RequestTopicEnum,
  SubscribeFunc,
  WebsocketContextValue,
  WebsocketEventEnum,
  WebsocketState,
} from './types';
import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import { messageRouting } from './legacy/messageRouting';

const state: WebsocketState = {
  options: {
    debug: APP_IS_DEVELOPMENT || true,
  },
  websocket: undefined,
  queue: [],
};

const emitter: EventEmitter2 = new EventEmitter2({
  wildcard: true,
  delimiter: '.',
  newListener: false,
  removeListener: false,
  maxListeners: 10,
  verboseMemoryLeak: true,
  ignoreErrors: false,
});

export const WebsocketContext = createContext<WebsocketContextValue>({
  publish: (topic, payload) =>
    state.options.debug && console.log('[Websocket] > publish (dummy)', topic, payload),
  subscribe: (topic) =>
    state.options.debug && console.log('[Websocket] # subscribe (dummy)', topic),
});

export const publish: PublishFunc = (topic, payload): void => {
  if (state.websocket && state.websocket.readyState === SockJS.OPEN) {
    state.options.debug && console.log('[Websocket] > publish', topic, payload);
    const legacyFormat = { ...payload.data, type: topic }; // legacy format
    state.websocket.send(JSON.stringify(legacyFormat));
  } else {
    state.options.debug && console.warn('[Websocket] # publish queued: ', topic, payload);
    state.queue.push({ type: topic, data: payload });
  }
};

export const subscribe: SubscribeFunc = (topic, handler) => {
  state.options.debug && console.log('[Websocket] # subscribe', topic);
  emitter.on(topic, handler);
};

export const WebsocketProvider: FC = ({ children }) => {
  useEffect(() => {
    subscribe(WebsocketEventEnum.WEBSOCKET_OPEN, () => {
      while (state.queue.length > 0) {
        const message = state.queue.shift();
        if (message) {
          const { type: topic, ...payload } = message as Message;
          state.options.debug &&
            console.log('[Websocket] > publish message from queue', topic, payload);
          publish(topic as RequestTopicEnum, payload);
        }
      }
    });

    state.websocket = new SockJS(APP_WS_HOST) as WebSocket;

    state.websocket.onopen = (event: Event): void => {
      state.options.debug && console.log('[Websocket] < open', event);
      emitter.emit(WebsocketEventEnum.WEBSOCKET_OPEN, event);
    };

    state.websocket.onmessage = (event: MessageEvent): void => {
      state.options.debug && console.log('[Websocket] < message', event);
      const { data } = event;
      const { type: topic, ...payload } = JSON.parse(data) as Message;
      emitter.emit(topic, payload);
      emitter.emit(WebsocketEventEnum.WEBSOCKET_MESSAGE, event);

      // @todo: this produces a loop with high CPU load, m ust be refactored to use EventEmitter2 instead of AppDispatcher
      // const handler = messageRouting[topic];
      // if (handler && handler.dispatchType) {
      //   let data = {};
      //   if (typeof handler.dispatchData === 'function') {
      //     data = handler.dispatchData(payload);
      //   }
      //   AppDispatcher.dispatch({
      //     type: handler.dispatchType,
      //     data: data,
      //   });
      // }
    };

    state.websocket.onerror = (event: Event): void => {
      state.options.debug && console.log('[Websocket] < error', event);
      emitter.emit(WebsocketEventEnum.WEBSOCKET_ERROR, event);
    };

    state.websocket.onclose = (event: CloseEvent): void => {
      state.options.debug && console.log('[Websocket] < close', event);
      emitter.emit(WebsocketEventEnum.WEBSOCKET_CLOSE, event);
    };

    return (...args): void => {
      state.options.debug && console.log('[Websocket] # unmount WebsocketProvider', ...args);
    };
  }, []);

  return (
    <WebsocketContext.Provider value={{ publish, subscribe }}>{children}</WebsocketContext.Provider>
  );
};
