import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { bullhorn } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import { AdmailsItem, BonusCodesItem, CompetitionsItem, MarketingActionsItem } from '../items';

type TProps = {
  isFeatureFlagCoinsEnabled: boolean;
}

const MarketingGroup: FC<TProps> = ({ isFeatureFlagCoinsEnabled }) => {
  return isFeatureFlagCoinsEnabled ? (
    <>
      <BonusCodesItem/>
      <AdmailsItem/>
    </>
  ) : (
    <LeftMenuGroup title={_('navigation:main.marketing')} icon={bullhorn}>
      <BonusCodesItem/>
      <AdmailsItem/>
      <CompetitionsItem/>
      <MarketingActionsItem/>
    </LeftMenuGroup>
  );
};

export default MarketingGroup;
