import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';

import AbstractFormComponent from '../../AbstractFormComponent';
import MaxValueLength from '../../../Form/Display/MaxValueLength/MaxValueLength';

class Textareafield extends AbstractFormComponent {
  render() {
    return (
      <div
        className={cc([
          'textareafield',
          { 'maxvaluelength-anchorframe': this.props.maxLength },
          this.getClassNames(),
        ])}
      >
        {this.props.maxLength && (
          <MaxValueLength
            max={this.props.maxLength}
            value={this.getValue() ? this.getValue().length : 0}
          />
        )}

        <div className="textareafield__column">
          <textarea
            name={this.props.name}
            placeholder={this.props.placeholder}
            onChange={this.onChange}
            value={this.getValue()}
            maxLength={this.props.maxLength}
          />
        </div>
      </div>
    );
  }
}

Textareafield.propTypes = Object.assign(AbstractFormComponent.propTypes, {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
});

Textareafield.defaultProps = Object.assign(AbstractFormComponent.defaultProps, {
  placeholder: '',
  disabled: false,
  readOnly: false,
});

export default Textareafield;
