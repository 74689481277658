import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';

import IconsIndex from './IconsIndex';
import ActorIconsIndex from './ActorIconsIndex';
import Session from './Session';

import NavSub from '../../components/NavSub/NavSub';
import NavSubItem from '../../components/NavSubItem/NavSubItem';
import Footer from '../../components/Footer/Footer';
import Environment from './Environment';
import Button from '../../atoms/Button/Button';
import NW from './NW';
import Assistant from '../../components/Assistant/Assistant';
import FormElementsIndex from './FormElementsIndex';
import { MediaTypeEnum, UploadFieldAndUploadQueue } from '../../packages/UploadManager';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { GRAY_BACKGROUND } from '../../camtool-styles';
import { Main } from '../../atoms';

const URL_ENVIRONMENT = '/env';
const URL_ICONS = '/icons';
const URL_ICONS2 = '/icons2';
const URL_FORM_ELEMENTS = '/form-elements';
const URL_ASSISTENT = '/assistent';
const URL_SESSION = '/session';
const URL_ERRORBOUNDRY = '/error';
const URL_UPLOADMANAGER = '/uploadManager';
const URL_NW = '/nw';

// eslint-disable-next-line react/prop-types
const Development = ({ match }) => {
  // eslint-disable-next-line react/prop-types
  const path = stripSlash(match.url);
  const indexRoute = path + URL_ENVIRONMENT;

  return (
    <Main isMobileEnabled>
      <NavSub>
        <NavSubItem link={path + URL_ENVIRONMENT} label={'Environment'} />
        <NavSubItem link={path + URL_ICONS} label={'Icons'} />
        <NavSubItem link={path + URL_ICONS2} label={'Actor Icons'} />
        <NavSubItem link={path + URL_FORM_ELEMENTS} label={'Form Elements'} />
        <NavSubItem link={path + URL_ASSISTENT} label={'Assistent'} />
        <NavSubItem link={path + URL_SESSION} label={'Session'} />
        <NavSubItem link={path + URL_ERRORBOUNDRY} label={'ErrorBoundry'} />
        <NavSubItem link={path + URL_UPLOADMANAGER} label={'UploadManager'} />
        <NavSubItem link={path + URL_NW} label={'NW'} />
      </NavSub>

      <div className="grid__row">
        <div className="grid__column grid__box">
          <div className="grid__box__item">
            <div className="grid__box__item__content">
              <Switch>
                <Redirect exact from={`${path}/`} to={indexRoute} />
                <Route path={path + URL_ENVIRONMENT} component={Environment} />
                <Route path={path + URL_ICONS} component={IconsIndex} />
                <Route path={path + URL_ICONS2} component={ActorIconsIndex} />
                <Route path={path + URL_FORM_ELEMENTS} component={FormElementsIndex} />
                <Route path={path + URL_ASSISTENT} component={Assistant} />
                <Route path={path + URL_SESSION} component={Session} />
                <Route
                  path={path + URL_ERRORBOUNDRY}
                  component={() => (
                    <div>
                      <Button
                        onClick={() => {
                          throw new Error(`threw an error!`);
                        }}
                      >
                        Trigger Error
                      </Button>
                    </div>
                  )}
                />
                <Route
                  path={path + URL_UPLOADMANAGER}
                  component={() => (
                    <Box bgcolor={GRAY_BACKGROUND}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <UploadFieldAndUploadQueue mediaType={MediaTypeEnum.picture} />
                        </Grid>
                        <Grid item xs={12}>
                          <UploadFieldAndUploadQueue mediaType={MediaTypeEnum.video} />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                />
                <Route path={path + URL_NW} component={NW} />
                <Redirect to={indexRoute} />
              </Switch>
            </div>
          </div>
        </div>
      </div>

      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </Main>
  );
};

export default Development;
