import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled from '@emotion/styled';

type FontIcon = string;
type FontAweseomIcon = IconProp;
type Path = string;

export type IconType = FontIcon | FontAweseomIcon | Path;

const DEFAULT_FONT_SIZE = '20px';

interface IconProps {
  icon: IconType;
  className?: string;
  size?: string;
  onClick?: (event: React.MouseEvent) => void;
}

// classname strings targeting all fonts in src/styles/icons
const fontLibraries = ['icon-', 'flag-', 'vxactor-', 'vxicon-'];
const isFontIcon = (icon: IconType): boolean =>
  fontLibraries.some((currentValue) => typeof icon === 'string' && icon.startsWith(currentValue));

const isFontAwesomeIcon = (icon: IconType): boolean =>
  typeof icon === 'object' &&
  !!icon &&
  typeof icon.prefix === 'string' &&
  icon.prefix.startsWith('fa');

const FontIcon = styled.span`
  &::before {
    font-size: ${(props): string => props.size};
  }
`;

const FAIcon = styled(FontAwesomeIcon)`
  font-size: ${(props): string => props.fasize};
`;

const StyledImg = styled.img`
  height: ${(props): string => props.size};
`;

const Icon: FC<IconProps> = ({ icon, className, size = DEFAULT_FONT_SIZE, ...props }) => {
  return isFontIcon(icon) ? (
    <FontIcon className={`${icon} ${className || ''}`} size={size} {...props} />
  ) : isFontAwesomeIcon(icon) ? (
    <FAIcon icon={icon} className={className} fasize={size} {...props} />
  ) : (
    <StyledImg src={icon} className={className} size={size} {...props} />
  );
};

export default Icon;
