import React, { FC } from 'react';
import LikesOverview from './LikesOverview/LikesOverview';
import CommentsOverview from './CommentsOverview/CommentsOverview';
import { GroupedLikes, Maybe, MediaFeedback } from '../../graphql/VXModels/types';

interface Props {
  umaId: number;
  likes: number;
  groupedLikes: GroupedLikes[];
  feedbacksItems: Maybe<MediaFeedback>[];
  modelAvatar: string | null | undefined;
  type: 'photo' | 'video';
  contentTitle: string | null;
}

const Comments: FC<Props> = ({
  umaId,
  likes,
  groupedLikes,
  feedbacksItems,
  modelAvatar,
  type,
  contentTitle,
}) => {
  const feedbackComments = feedbacksItems.filter((comment) => comment?.text);
  const commentAmount = feedbackComments.length;

  return (
    <div css={{ flexDirection: 'column', width: '100%' }}>
      <div style={{ paddingBottom: 20, paddingTop: 20 }}>
        <h2>{contentTitle}</h2>
      </div>
      <LikesOverview
        likes={likes}
        feedbackComments={feedbackComments}
        groupedLikes={groupedLikes}
        type={type}
      />
      <div css={{ flexDirection: 'column' }}>
        <CommentsOverview
          commentAmount={commentAmount}
          feedbackComments={feedbackComments}
          modelAvatar={modelAvatar}
          umaId={umaId}
        />
      </div>
    </div>
  );
};

export default Comments;
