import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import PhotoGalleryStore from '../../stores/PhotoGallery/PhotoGalleryStore';

class MediaWrapper extends AbstractComponent {
  onClickPicture(photoURL) {
    PhotoGalleryStore.openPhotoGallery(photoURL);
  }

  render() {
    this.rankIconClass = 'vxactor-award-user-ranking-' + this.props.rank;
    const { contentType, source, likes } = this.props;

    return (
      <div
        onClick={contentType === 'photo' ? () => this.onClickPicture([source]) : null}
        className={`media-wrapper__item__${contentType}`}
      >
        {contentType === 'photo' && source && (
          <img className="media-wrapper__item__photo__image" src={source} />
        )}

        {contentType === 'video' && this.props.children}

        <div className="media-wrapper__item__info">
          <div>
            <span className={`icon-fsk-${this.props.ageRating} media-wrapper__item__info__fsk`} />
            <span className="media-wrapper__item__info__rank" />
          </div>

          {likes && (
            <span className="media-wrapper__item__info__likes">
              {likes} <span className="icon-thumbs-up icon spacer" />
            </span>
          )}
        </div>

        <div className={`media-wrapper__item__${contentType}__watermark`}>
          <span className={this.props.isTopPhoto ? this.rankIconClass : ''} />
        </div>
      </div>
    );
  }
}

MediaWrapper.propTypes = {
  contentType: PropTypes.string.isRequired,
  source: (props, propName) => {
    if (props['contentType'] === 'photo') {
      if (!props[propName])
        return new Error('For contentType === "photo", you need to provide a source prop.');
      if (typeof props[propName] !== 'string')
        return new Error('source must be a non empty string.');
    }
  },
  rank: PropTypes.number,
  likes: PropTypes.number,
  ageRating: PropTypes.number,
};

MediaWrapper.defaultProps = {};

export default MediaWrapper;
export { MediaWrapper };
