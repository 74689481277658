import React, { FC } from 'react';
import { AboContentType } from '../../types';
import { getIconByType } from '../../icons';

interface Props {
  pictureUrl: string | undefined;
  aboContentType: AboContentType;
}

const AboReviewImage: FC<Props> = ({ pictureUrl, aboContentType }) => {
  return (
    <figure
      css={{
        alignSelf: 'center',
        padding: 16,
        fill: '#999',
      }}
    >
      {pictureUrl ? (
        <img css={{ objectFit: 'contain' }} width={'160px'} height={'160px'} src={pictureUrl} />
      ) : (
        getIconByType(aboContentType)
      )}
    </figure>
  );
};

export default AboReviewImage;
