import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { VXFavouritesStore } from '../../../stores/Lists/VXFavourites/VXFavouritesStore';
import { VXFavouritesActionCreators } from '../../../stores/Lists/VXFavourites/VXFavouritesActionCreators';

import { EmptyContent, FormatDate, Spinner, T } from '../../../components';
import { _ } from '../../../util/translate';

class VXFavourites extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getStoreData();
  }

  componentDidMount() {
    VXFavouritesStore.addChangeListener(this.onStoreChange);
    this.loadData();
  }

  componentWillUnmount() {
    VXFavouritesStore.removeChangeListener(this.onStoreChange);
  }

  getStoreData = () => cloneDeep(VXFavouritesStore.get());

  loadData = () => {
    this.state.loadMoreButtonDisabled = true;
    this.setState(this.state);
    VXFavouritesActionCreators.getVXFavourites(
      this.state.sorting.field,
      this.state.sorting.direction,
      this.state.pagination.limit,
      this.state.pagination.offset
    );
  };

  onStoreChange = () => {
    let storeData = this.getStoreData();
    this.state.loadMoreButtonDisabled = false;
    this.state.showSpinner = false;
    this.state.userlist = storeData.userlist;
    this.state.totalEntries = storeData.totalEntries;
    this.state.pagination.offset = this.state.userlist.length + 1;
    this.setState(this.state);
  };

  getSortClass = name => {
    if (this.state.sorting.field === name) return name + ' ' + this.state.sorting.direction;
    else return name;
  };

  onClickSort = event => {
    let sortOptions = [
      'username',
      'status',
      'isVip',
      'lastOnline',
      'lastChat',
      'chats',
      'chatMinutes',
    ];
    let classNames = event.currentTarget.className.split(' ');
    this.state.showSpinner = true;
    this.state.pagination.offset = 1;
    this.state.sorting.field = 'lastOnline';
    this.state.sorting.direction = 'desc';
    for (let cn in classNames) {
      if (sortOptions.indexOf(classNames[cn]) !== -1) {
        this.state.sorting.field = classNames[cn];
        if (classNames.indexOf('desc') !== -1) {
          this.state.sorting.direction = 'asc';
        }
        break;
      }
    }
    this.setState(this.state);
    this.loadData();
  };

  render() {
    return (
      <div className="grid__box__column grid__box__sub min-width--0">
        <div className="grid__box__sub__header">
          <div className="icon-heart grid__box__sub__header__item grid__box__sub__header__item--active">
            <T _={'lists:vxfavourites.head.title'} />
          </div>
        </div>

        <div className="grid__box__sub__item vxfavourites-list list">
          <div className="grid__box__sub__item__content spinner-container">
            {/* - SPINNER */}
            {this.state.showSpinner && <Spinner />}

            {/* - SHOW DATA LIST */}
            {this.state.userlist.length > 0 && (
              <Fragment>
                <header className="list-header">
                  <div className={`${this.getSortClass('username')} sortable column-username`}>
                    <div className={this.getSortClass('username')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:vxfavourites.fieldname.username'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div className={`${this.getSortClass('status')} sortable column-status`}>
                    <div className={this.getSortClass('status')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:vxfavourites.fieldname.status'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div className={`${this.getSortClass('isVip')} sortable column-vip`}>
                    <div className={this.getSortClass('isVip')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:vxfavourites.fieldname.vipguest'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div
                    className={`${this.getSortClass(
                      'lastOnline'
                    )} sortable column-last-online-date`}
                  >
                    <div className={this.getSortClass('lastOnline')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:vxfavourites.fieldname.lastOnline'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div
                    className={`${this.getSortClass('lastChat')} sortable column-last-chat-date`}
                  >
                    <div className={this.getSortClass('lastChat')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:vxfavourites.fieldname.lastChat'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div className={`${this.getSortClass('chats')} sortable column-chats`}>
                    <div className={this.getSortClass('chats')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:vxfavourites.fieldname.chats'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div
                    className={`${this.getSortClass('chatMinutes')} sortable column-chatminutes`}
                  >
                    <div className={this.getSortClass('chatMinutes')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:vxfavourites.fieldname.chatminutes'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                </header>
                {this.state.userlist.map(item => (
                  <article className="list-entry" key={item.id}>
                    <div className="column-username">{item.username}</div>
                    <div
                      className={`${item.status === 'active' ? 'icon-ok' : ''}${
                        item.status === 'inactive' ? 'icon-remove' : ''
                      } column-status`}
                    >
                      <T _={'lists:vxfavourites.statusname.' + item.status} />
                    </div>
                    <div className={`column-vip ${item.isVip ? 'yes' : 'no'}`} />
                    <div className="column-last-online-date">
                      <FormatDate value={item.lastOnline} />
                    </div>
                    <div className="column-last-chat-date">
                      <FormatDate value={item.lastChat} />
                    </div>
                    <div className="column-chats">{item.chats}</div>
                    <div className="column-chatminutes">{item.chatMinutes}</div>
                  </article>
                ))}

                {this.state.userlist.length < this.state.totalEntries && (
                  <div
                    className={`list-load-more ${
                      this.state.loadMoreButtonDisabled ? 'disabled' : ''
                    }`}
                  >
                    <div>
                      <div onClick={this.loadData}>
                        <div className="icon" />
                        <div className="label">
                          <T _={'common:button.loadMoreContents'} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}

            {/* - DATA NOT FOUND */}
            {this.state.userlist.length < 1 && (
              <EmptyContent title={_('marketing:admails.archive.archiveIsEmpty')} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default VXFavourites;
