import React from 'react';
import PropTypes from 'prop-types';
import AbstractAlbumView from '../AbstractAlbumView';
import {
  Camera,
  DraggablePictures,
  MediaSelector,
  Modalbox,
  ProfilePictureInfobox,
} from '../../../../components';
import { MediaManagementActionCreators } from '../../../../stores/MediaManagement/MediaManagement';
import AlbumViewProfileVXFooPreview from './AlbumViewProfileVXFooPreview';
import ProfileInfobox from '../../../ProfilePictureInfobox/ProfilePictureInfobox';
import Spinner from '../../../Spinner/Spinner';
import NavSubItem from '../../../NavSubItem/NavSubItem';
import ProfilePicture from './ProfilePicture';
import AlbumViewProfileRejectedPictures from '../AlbumViewProfileRejectedPictures';
import TitelPicture from './TitelPicture';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import { BREAKPOINT_TABLET_CONDITION } from '../../../../camtool-styles';

const ProfileNavRow = styled.div`
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-wrap: wrap;
  }
`;

const getStyle = (picture) => {
  if (picture.url) {
    return {
      backgroundImage: `url(${picture.url})`,
    };
  }
  return {};
};

const getEditorType = (type) => {
  switch (type) {
    case 'titlePicture':
      return 'landscape_25_10';
    case 'mainPicture':
      return 'circle';
    case 'fooPicture0':
    case 'fooPicture1':
    case 'fooPicture2':
    case 'fooPicture3':
    default:
      return 'landscape_4_3';
  }
};

class AlbumViewProfileVX extends AbstractAlbumView {
  constructor(props, context) {
    super(props, context);
    this.state = { showModal: '' };

    this.showMediaSelectorTitlePicture = this.showMediaSelectorTitlePicture.bind(this);
    this.showMediaSelectorMainPicture = this.showMediaSelectorMainPicture.bind(this);
    this.showMediaSelectorFooPicture = this.showMediaSelectorFooPicture.bind(this);
    this.showMediaSelectorFooPicture0 = this.showMediaSelectorFooPicture0.bind(this);
    this.showMediaSelectorFooPicture1 = this.showMediaSelectorFooPicture1.bind(this);
    this.showMediaSelectorFooPicture2 = this.showMediaSelectorFooPicture2.bind(this);
    this.showMediaSelectorFooPicture3 = this.showMediaSelectorFooPicture3.bind(this);
    this.closeMediaSelector = this.closeMediaSelector.bind(this);
    this.refreshAlbum = this.refreshAlbum.bind(this);
    this.preparePicture = this.preparePicture.bind(this);
    this.getPicturePoolFilter = this.getPicturePoolFilter.bind(this);
  }

  onClick(event) {
    event.stopPropagation();
    this.setState({ showModal: true });
  }

  showMediaSelectorTitlePicture() {
    this.setState({ showModal: 'titlePicture' });
  }

  showMediaSelectorMainPicture() {
    this.setState({ showModal: 'mainPicture' });
  }

  showMediaSelectorFooPicture0() {
    this.setState({ showModal: 'fooPicture0' });
  }

  showMediaSelectorFooPicture1() {
    this.setState({ showModal: 'fooPicture1' });
  }

  showMediaSelectorFooPicture2() {
    this.setState({ showModal: 'fooPicture2' });
  }

  showMediaSelectorFooPicture3() {
    this.setState({ showModal: 'fooPicture3' });
  }

  showMediaSelectorFooPicture(index) {
    switch (index) {
      case 0:
        this.showMediaSelectorFooPicture0();
        break;
      case 1:
        this.showMediaSelectorFooPicture1();
        break;
      case 2:
        this.showMediaSelectorFooPicture2();
        break;
      case 3:
        this.showMediaSelectorFooPicture3();
        break;
      default:
        console.error(`\`index\` must be a number between 0 and 3. You entered ${index}`);
    }
  }

  closeMediaSelector() {
    Camera.resetCamera();
    this.setState({ showModal: '' });
  }

  getTitle(type) {
    return type;
  }

  refreshAlbum() {
    this.props.refetchAlbum();
  }

  preparePicture(data, callback) {
    switch (data.pictureType) {
      case 'titleProfilePicture':
        if (this.titlePicture.pictureId) {
          this.deletePicture(this.titlePicture, () => {
            callback(data);
          });
        } else {
          callback(data);
        }
        break;
      case 'mainProfilePicture':
        if (this.mainPicture.pictureId) {
          this.deletePicture(this.mainPicture, () => {
            callback(data);
          });
        } else {
          callback(data);
        }
        break;
      case 'sedcard':
        if (this.fooPictures[data.targetPosition]) {
          this.deletePicture(this.fooPictures[data.targetPosition], () => {
            callback(data);
          });
        } else {
          callback(data);
        }
        break;
    }
  }

  getTargetParams(type) {
    switch (type) {
      case 'fooPicture0':
        return { position: 0 };
      case 'fooPicture1':
        return { position: 1 };
      case 'fooPicture2':
        return { position: 2 };
      case 'fooPicture3':
        return { position: 3 };
      case 'titlePicture':
      case 'mainPicture':
      default:
        return {};
    }
  }

  getPictureType(type) {
    switch (type) {
      case 'titlePicture':
        return 'titleProfilePicture';
      case 'mainPicture':
        return 'mainProfilePicture';
      case 'fooPicture0':
      case 'fooPicture1':
      case 'fooPicture2':
      case 'fooPicture3':
      default:
        return 'sedcard';
    }
  }

  getPicturePoolFilter(type) {
    switch (this.getMediaTypeName()) {
      case 'sedcardVX12':
        return (picture) =>
          picture.status !== 'rejected' && (picture.fsk <= 12 || !picture.isChecked);
      case 'sedcardVX16':
        return (picture) =>
          picture.status !== 'rejected' && (picture.fsk <= 16 || !picture.isChecked);
      case 'sedcardVX18':
        return (picture) =>
          picture.status !== 'rejected' && (picture.fsk <= 18 || !picture.isChecked);
      default:
        return (picture) => picture.status !== 'rejected';
    }
  }

  getMediaTypeName() {
    switch (this.props.album.mediaType) {
      case '51_1':
        return 'sedcardVX16';
      case '51_2':
        return 'sedcardVX18';
      case '51_3':
        return 'sedcardVX12';
      default:
        return this.props.album.mediaType;
    }
  }

  movePictureToPosition(pictureId, newPosition, onSuccess) {
    MediaManagementActionCreators.movePictureToPosition(
      this.props.album.id,
      pictureId,
      newPosition,
      () => {
        this.props.refetchAlbum();
        if (typeof onSuccess === 'function') onSuccess();
      }
    );
  }

  handleRejectedPictureClick(picture) {
    this.deletePicture(picture, this.props.refetchAlbum);
  }

  getPictureStatus = (picture) => {
    if (!picture.url) return null;
    if (picture.status === 'rejected') return 'rejected';
    if (!picture.isChecked) return 'unverified';
    return 'ok';
  };

  getAlbumFSK = (albumType) => {
    switch (albumType) {
      case 'profile12':
        return 12;
      case 'profile16':
        return 16;
      case 'profile18':
      default:
        return 18;
    }
  };

  render() {
    this.isRejected = this.props.album.status === 'rejected';
    this.isNotRejected = this.props.album.status !== 'rejected';

    this.mainPicture = {};
    this.titlePicture = {};
    this.fooPictures = [];

    this.description = _(`mediamanagement:${this.getMediaTypeName()}.description`);

    this.rejectReasonTitlePicture = '';
    for (let picture of this.props.album.pictures) {
      if (picture.isMainProfilePicture) {
        this.mainPicture = picture;
      } else if (picture.isTitleProfilePicture) {
        this.titlePicture = picture;
        if (picture.status === 'rejected') {
          this.rejectReasonTitlePicture = picture.rejectionReason;
        }
      } else {
        this.fooPictures[picture.position] = picture;
      }
    }

    const currentPath = this.getCurrentPath();

    return (
      <div className="grid__box__column grid__box__sub profile-pic__view__profile__vx grow__no-shrink__auto">
        {/*Navigation zwischen den einzelnen FSK Stufen*/}
        <ProfileNavRow className="profile-pic__view__profile__vx__nav">
          <NavSubItem
            link={`${currentPath}/_sedcardVX12`}
            label={_('mediamanagement:sedcardVX12.navLabel')}
          />
          <NavSubItem
            link={`${currentPath}/_sedcardVX16`}
            label={_('mediamanagement:sedcardVX16.navLabel')}
          />
          <NavSubItem
            link={`${currentPath}/_sedcardVX18`}
            label={_('mediamanagement:sedcardVX18.navLabel')}
          />
        </ProfileNavRow>

        <ProfilePictureInfobox
          text={this.description}
          className="profile-pic__view__profile__vx__profile-info-1"
        />

        <div className="grid__box__sub__item profile-pic__view__profile__vx__content spinner-container">
          {this.props.isLoading && <Spinner align="top" />}

          <div className="grid__box__sub__item__content">
            <div className="profile-pic__view__profile__vx__content__row">
              <div className="profile-pic__view__profile__vx__item">
                {/*Titlebild*/}
                <TitelPicture
                  url={this.titlePicture.url}
                  onClick={this.showMediaSelectorTitlePicture}
                  status={this.getPictureStatus(this.titlePicture)}
                  ageRating={this.getAlbumFSK(this.props.album.albumType)}
                  info={this.rejectReasonTitlePicture}
                />

                {/*Profilbild*/}
                <div className="profile-pic__view__profile__vx__item__border">
                  <ProfilePicture
                    url={this.mainPicture.url}
                    onClick={this.showMediaSelectorMainPicture}
                    status={this.getPictureStatus(this.mainPicture)}
                    ageRating={this.getAlbumFSK(this.props.album.albumType)}
                    css={{ position: 'absolute', bottom: 16, left: 16 }}
                  />
                  <div className="profile-pic__view__profile__vx__item__name" />
                </div>
              </div>
            </div>

            <div className="profile-pic__view__profile__vx__foo__container">
              <ProfileInfobox
                className="pofile-pic__view__profile__vx__profile-info-2"
                text={_('mediamanagement:sedcardVX.fooPictureDescription')}
              />

              <div className="profile-pic__view__profile__vx__foo">
                {/*Dummy der VX Foo*/}
                <AlbumViewProfileVXFooPreview pictures={this.fooPictures} />

                <DraggablePictures
                  pictures={this.fooPictures.slice(0, 4)}
                  onPictureClick={this.showMediaSelectorFooPicture}
                  movePictureTo={this.movePictureToPosition}
                />
              </div>
            </div>

            <AlbumViewProfileRejectedPictures
              rejectedPictures={this.fooPictures.slice(100)}
              onPictureClick={this.handleRejectedPictureClick}
            />
          </div>
        </div>

        <Modalbox
          name="modalsingleuploader"
          state={!!this.state.showModal}
          onKeyPressESC={this.closeMediaSelector}
          onClose={this.onModalClose}
          title={this.getTitle(this.state.showModal)}
        >
          <MediaSelector
            type="picture"
            initialTab="pool"
            editor={getEditorType(this.state.showModal)}
            targetUmaId={this.props.album.id}
            targetParams={this.getTargetParams(this.state.showModal)}
            pictureType={this.getPictureType(this.state.showModal)}
            fsk={this.getAlbumFSK(this.props.album.albumType)}
            onClose={this.closeMediaSelector}
            onSubmit={this.refreshAlbum}
            onBeforeUploadPicture={this.preparePicture}
            picturePoolFilter={this.getPicturePoolFilter(this.state.showModal)}
          />
        </Modalbox>
      </div>
    );
  }
}

AlbumViewProfileVX.debug = false;

AlbumViewProfileVX.propTypes = {
  description: PropTypes.string,
};

AlbumViewProfileVX.defaultProps = {
  description: '',
};

export default AlbumViewProfileVX;
