import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Query } from '../../../graphql/VXModels/types';
import { QueryResult } from '@apollo/react-common';
import { QueueItem } from '../types';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';

const QUERY_MODEL_TRANCODING = gql`
  query QUERY_MODEL_TRANCODING {
    model {
      transcoding {
        id
        displayName
        fileId
        state
        mediaType
        md5hash
        preview
        verified
        progress
        errorMessage
      }
    }
  }
`;

type Result = Omit<QueryResult<Query>, 'data'> & {
  transcodingQueue: QueueItem[];
};

export const useTranscodingQueue = (options?: QueryHookOptions<Query>): Result => {
  const { data, ...result } = useQuery<Query>(QUERY_MODEL_TRANCODING, options);
  return {
    transcodingQueue: data?.model?.transcoding || [],
    ...result,
  };
};
