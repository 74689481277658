import styled from '@emotion/styled';
import React, { Component } from 'react';

import { Tag } from '../../atoms';
import Select from '../../atoms/Select/Select';
import Icon from '../../atoms/Icon/Icon';
import * as flagIcons from '../../atoms/Icon/libraries/flag';
import { IOption, IOptions } from './MultipleOptions';

class LanguagesPicker extends Component<IProps> {
  public isOptionAvailable = (value: string) => {
    for (const opt of this.props.currentOptions) {
      if (opt.value === value || this.props.currentOptions.length >= 3) {
        return false;
      }
    }
    return true;
  };

  public handleSelectChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLSelectElement;
    this.props.addOption(this.props.allOptions.filter((opt) => opt.value === target.value)[0]);
  };

  public handleButtonClick = (optionId: string) => {
    this.props.removeOption(this.props.allOptions.filter((opt) => opt.value === optionId)[0]);
  };

  public render() {
    return (
      <Container>
        <Select
          onChange={this.handleSelectChange}
          disabled={this.props.disabled || this.props.currentOptions.length >= 3}
          options={this.props.allOptions.map((opt) => ({
            ...opt,
            disabled: !this.isOptionAvailable(opt.value),
          }))}
          value={this.props.placeholder}
          placeholder={this.props.placeholder}
        />

        <div>
          {this.props.currentOptions.map((opt, i) => (
            <Tag
              css={{
                '&:hover': {
                  '.icon-remove': {
                    color: '#ff4d3c',
                  },
                },
                '.icon-remove': {
                  color: '#bcbcbc',
                  marginLeft: 8,
                },
                marginLeft: i > 0 ? '8px' : 0,
              }}
              onClick={this.handleButtonClick}
              tagId={opt.value}
              key={opt.value}
              disabled={this.props.disabled}
            >
              <Icon icon={flagIcons[opt.value]} css={{ marginRight: 8 }} />
              <div>{opt.label}</div>
              <div className="icon-remove" />
            </Tag>
          ))}
        </div>
      </Container>
    );
  }
}

interface IProps {
  allOptions: IOptions;
  currentOptions: IOptions;
  addOption: (option: IOption) => void;
  removeOption: (option: IOption) => void;
  placeholder: string;
  disabled: boolean;
}

const Container = styled.article`
  flex: auto; // instead of flex:1 because ie11
  flex-direction: column;
`;

export default LanguagesPicker;
