import React, { FC } from 'react';
import { FieldUploadAttachmentBase, FieldUploadAttachmentBaseProps } from './Base';
import { AttachmentInputOrFile, fileToInputAttachment } from '../../../../../../util/FileUtils';

type Props = Omit<FieldUploadAttachmentBaseProps, 'transformFile'>;

/**
 * Formik field for inline (i.e. base64) Attachment upload, with Attachment referring to the Attachment type from the VXModels GraphQL API
 *
 * The word "inline" in the component name refers to the image being sent to the backend "inline" as base64 as part of a GraphQL mutation
 *
 * Wraps FieldUploadAttachmentBase and makes it work with the VXModels GraphQL API for attachments (should not do anything else!)
 */
const FieldInlineAttachment: FC<Props> = (props) => {
  const transformFile = (file: File): Promise<AttachmentInputOrFile> => fileToInputAttachment(file);

  return <FieldUploadAttachmentBase {...props} transformFile={transformFile} />;
};

export default FieldInlineAttachment;
