import React from 'react';
import cc from 'classcat';

const MaxValueLength = ({ value, max, min }) => {
  return (
    <div
      className={cc({
        maxvaluelength: true,
        goodvalue: parseInt(value) >= parseInt(min) && parseInt(value) <= parseInt(max),
        badvalue: parseInt(value) < parseInt(min) || parseInt(value) > parseInt(max),
        maxreached: parseInt(value) === parseInt(max),
      })}
    >
      <span>{value}</span>
      <span className="seperator"> / </span>
      <span>{max}</span>
    </div>
  );
};

MaxValueLength.propTypes = {};

MaxValueLength.defaultProps = {};

export default MaxValueLength;
