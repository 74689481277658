import ApolloClient from 'apollo-client';
import { useEffect, useState } from 'react';
import { InstantMessagingPlatformEnum, Query } from './VXModels/types';
import { QUERY_MODEL_INSTANTMESSAGING_ACCOUNT } from './VXModels/queries';
import { GET_JWT_AUTHTOKEN } from './VXModels/queries/queries';
import { ClientsDictionary } from './types';
import { useAppState } from '../util/AppState';
import { useCreateVXModelsClient } from './VXModels';
import { useCreateVXServicesTelegramClient } from './VXServicesTelegram';
import { useCreateVXServicesNotificationsClient } from './VXServicesNotifications';

const useTelegramToken = (
  authToken: string | null,
  clientVXModels: ApolloClient<unknown>
): string | undefined => {
  const [telegramToken, setTelegramToken] = useState<string | null | undefined>();
  useEffect(() => {
    if (authToken) {
      clientVXModels
        .query<Pick<Query, 'model'>>({
          query: QUERY_MODEL_INSTANTMESSAGING_ACCOUNT,
          variables: { platform: InstantMessagingPlatformEnum.TELEGRAM },
        })
        .then(({ data }) => {
          setTelegramToken(data.model?.instantMessaging?.account?.token);
        });
    }
  }, [clientVXModels, authToken]);

  return telegramToken || undefined;
};

// TODO refresh after 2 hour expiry
const useJWTAuthToken = (
  authToken: string | null,
  clientVXModels: ApolloClient<unknown>
): string | undefined => {
  const [telegramToken, setTelegramToken] = useState<string | null | undefined>();
  useEffect(() => {
    if (authToken) {
      clientVXModels
        .query<Pick<Query, 'auth'>>({
          query: GET_JWT_AUTHTOKEN,
        })
        .then(({ data }) => {
          setTelegramToken(data.auth?.accessToken);
        })
        .catch((error) => {
          console.error('Error fetching JWT auth token:', error);
        });
    }
  }, [clientVXModels, authToken]);

  return telegramToken || undefined;
};

export const useClients = (): ClientsDictionary => {
  const [{ authToken, lang }] = useAppState();

  const clientVXModels = useCreateVXModelsClient(authToken, lang);
  const telegramToken = useTelegramToken(authToken, clientVXModels);
  const clientVXServicesTelegram = useCreateVXServicesTelegramClient(telegramToken);
  const jwtToken = useJWTAuthToken(authToken, clientVXModels);
  const clientVXServicesNotifications = useCreateVXServicesNotificationsClient(jwtToken);

  return {
    VXModels: clientVXModels,
    VXServicesTelegram: clientVXServicesTelegram,
    VXServicesNotifications: clientVXServicesNotifications,
  };
};
