import React, { FC, useState } from 'react';
import { TableCell, TableRow } from '../../../../atoms/Table';
import { ITableColumn } from '../../../../atoms/Table/types';
import { Master } from '../../../../graphql/VXServicesTelegram/types';
import { useFileStorageSettings } from '../../../Telegram/hooks/useFileStorageSettings';
import AutoMailDelete from './AutoMailDelete';
import AutoMailDeliveryOption from './AutoMailDeliveryOption';
import AutoMailFileUploadInput from './AutoMailFileUploadInput';
import AutoMailMessage from './AutoMailMessage';

interface Props {
  columns: ITableColumn[];
  index: number;
  deleteAutoMailRow: (index: number) => void;
  mimetype: Master['allowedMimeTypes'];
}

const AutoMailListItem: FC<Props> = ({ columns, index, deleteAutoMailRow, mimetype }) => {
  const fileStorageSettings = useFileStorageSettings();
  const [showModalTextEditor, setShowModalTextEditor] = useState(false);

  const openModalTextEditor = (): void => {
    setShowModalTextEditor(true);
  };

  const closeModalTextEditor = (): void => {
    setShowModalTextEditor(false);
  };

  return (
    <TableRow css={{ padding: '20px 8px' }} hoverEffect={false}>
      {/*deliveryOptions*/}
      <TableCell columns={columns} index={0}>
        <AutoMailDeliveryOption index={index} />
      </TableCell>

      {/*message*/}
      <TableCell columns={columns} index={1}>
        <AutoMailMessage
          showModalTextEditor={showModalTextEditor}
          closeModalTextEditor={closeModalTextEditor}
          openModalTextEditor={openModalTextEditor}
          index={index}
        />
      </TableCell>

      {/*attachment*/}
      <TableCell columns={columns} index={2} flexWrap={true}>
        <AutoMailFileUploadInput
          name={`autoMailList[${index}].file`}
          fileStorageSettings={fileStorageSettings}
          mimetype={mimetype}
          index={index}
        />
      </TableCell>

      {/*delete*/}
      <TableCell columns={columns} index={3}>
        <AutoMailDelete deleteAutoMailRow={deleteAutoMailRow} index={index} />
      </TableCell>
    </TableRow>
  );
};

export default AutoMailListItem;
