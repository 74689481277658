import React, { FC, useState } from 'react';
import { FieldUploadAttachmentBase, FieldUploadAttachmentBaseProps } from './Base';
import {
  AttachmentInputOrFile,
  fileToUploadedInputAttachment,
} from '../../../../../../util/FileUtils';
import { FileStorageSettings } from '../../../../../../graphql/VXModels/types';

type Props = Omit<FieldUploadAttachmentBaseProps, 'transformFile'>;

/**
 * Formik field for Generic Upload Attachment upload, with Attachment referring to the Attachment type from the VXModels GraphQL API
 *
 * Wraps FieldUploadAttachmentBase and makes it work with the Generic Upload API (should not do anything else!)
 */
const FieldGenericUploadAttachment: FC<Props> = ({ fileStorageSettings, ...rest }) => {
  const transformFile = (file: File): Promise<AttachmentInputOrFile> =>
    fileToUploadedInputAttachment(fileStorageSettings as FileStorageSettings, file);

  return (
    <FieldUploadAttachmentBase
      {...rest}
      transformFile={transformFile}
      fileStorageSettings={fileStorageSettings}
    />
  );
};

export default FieldGenericUploadAttachment;
