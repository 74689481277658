import styled from '@emotion/styled';
import React from 'react';
import { BLACK_1 } from '../../camtool-styles';

interface IProps { title?: any; children: any; className?: string; css?: object; contentContanierClass?: string; styles?: any; } // prettier-ignore

export const sectionMargin = 8;

const StyledSection = styled.section`
  flex-direction: column;
  flex: 0 0 auto;
  margin: ${sectionMargin}px;
`;

const StyledHeader = styled.header`
  background-color: #3c3d3e;
  color: #ffffff;
  padding: 0 24px;
  min-height: 48px;
  max-height: 48px;
  align-items: center;
  font-size: 16px;
  text-transform: uppercase;
`;

const StyledDiv = styled.div`
  background-color: #f5f5f5;
  color: ${BLACK_1};
  position: relative;
  flex-direction: column;
  flex: 1 0 auto;
`;

const Section = ({ title, children, className, contentContanierClass, styles }: IProps) => (
  <StyledSection className={className}>
    {!!title ? <StyledHeader>{typeof title === 'function' ? title() : title}</StyledHeader> : null}
    <StyledDiv css={styles} className={contentContanierClass}>
      {typeof children === 'function' ? children() : children}
    </StyledDiv>
  </StyledSection>
);

export default Section;
