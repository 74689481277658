export const de = 'flag-de';
export const en = 'flag-en';
export const cz = 'flag-cz';
export const da = 'flag-da';
export const es = 'flag-es';
export const fi = 'flag-fi';
export const fr = 'flag-fr';
export const hu = 'flag-hu';
export const it = 'flag-it';
export const nl = 'flag-nl';
export const pl = 'flag-pl';
export const pt = 'flag-pt';
export const ro = 'flag-ro';
export const ru = 'flag-ru';
export const tr = 'flag-tr';
export const ch = 'flag-ch';
export const at = 'flag-at';
