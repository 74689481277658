import React from 'react';
import { css } from '@emotion/core';
import { T } from '../../../components';

import {
  ArrowContainer,
  BREAKPOINT,
  Content,
  TelSexItem,
  TelSexItemContainer,
} from '../PhoneSex/styles';
import { _ } from '../../../util/translate';

const styleTelSexItem = css`
  width: 224px;
  height: 132px;
  padding: 8px 12px;
`;

const PhoneCarrouselDescription = () => {
  return (
    <div css={{ alignItems: 'center', flexDirection: 'column', padding: 32 }}>
      <header
        css={{ flexDirection: 'column', textAlign: 'center', marginBottom: 24, maxWidth: 800 }}
      >
        <h2 css={{ marginBottom: 8 }}>
          <T _={'service0900:carousel.title'} />
        </h2>
        <p>
          <T _={'service0900:carousel.subtitle'} />
        </p>
      </header>

      <div css={{ flexDirection: 'column', alignItems: 'center', marginTop: '30px' }}>
        <h2>{_('service0900:PhoneSex.inactive.description.headline')}</h2>
        <Content>
          <TelSexItemContainer>
            <TelSexItem theme="BLUE" css={styleTelSexItem}>
              <span className="icon-ok" css={{ fontSize: 16, marginBottom: 8 }} />
              <div css={{ width: '100%', flexDirection: 'column' }}>
                <h3 css={{ fontWeight: 'normal', fontSize: '18px !important' }}>
                  <T _={'service0900:carousel.step11'} />
                </h3>
                <small css={{ color: '#E2E2E2', fontSize: '100%' }}>
                  {_('service0900:carousel.step12')}
                </small>
              </div>
            </TelSexItem>
          </TelSexItemContainer>

          <ArrowContainer css={{ justifyContent: 'center', height: 40 }}>
            <span
              className="icon-share"
              css={{ transform: 'rotate(26deg)', [BREAKPOINT]: { transform: 'rotate(128deg)' } }}
            />
          </ArrowContainer>

          <TelSexItemContainer>
            <TelSexItem theme="BLUE" css={styleTelSexItem}>
              <span className="icon-earphone" css={{ fontSize: 16, marginBottom: 8 }} />
              <div css={{ width: '100%', flexDirection: 'column' }}>
                <h3 css={{ fontWeight: 'normal', fontSize: '18px !important' }}>
                  <T _={'service0900:carousel.step21'} />
                </h3>
                <small css={{ color: '#E2E2E2', fontSize: '100%' }}>{`(${_(
                  'common:text.free'
                )})`}</small>
              </div>
            </TelSexItem>
          </TelSexItemContainer>

          <ArrowContainer css={{ justifyContent: 'center', height: 40 }}>
            <span
              className="icon-share"
              css={{ transform: 'rotate(26deg)', [BREAKPOINT]: { transform: 'rotate(128deg)' } }}
            />
          </ArrowContainer>

          <TelSexItemContainer>
            <TelSexItem theme="BLUE" css={styleTelSexItem}>
              <span className="icon-tv" css={{ fontSize: 16, marginBottom: 8 }} />
              <div css={{ width: '100%', flexDirection: 'column' }}>
                <h3 css={{ fontWeight: 'normal', fontSize: '18px !important' }}>
                  <T _={'service0900:carousel.step31'} />
                </h3>
                <small css={{ color: '#E2E2E2', fontSize: '100%' }}>
                  (<T _={'service0900:carousel.step32'} />)
                </small>
              </div>
            </TelSexItem>
          </TelSexItemContainer>

          <ArrowContainer css={{ justifyContent: 'center', height: 40 }}>
            <span
              className="icon-share"
              css={{ transform: 'rotate(26deg)', [BREAKPOINT]: { transform: 'rotate(128deg)' } }}
            />
          </ArrowContainer>

          <TelSexItemContainer>
            <TelSexItem theme="BLUE" css={styleTelSexItem}>
              <span className="icon-earphone" css={{ fontSize: 16, marginBottom: 8 }} />
              <div css={{ width: '100%', flexDirection: 'column' }}>
                <h3 css={{ fontWeight: 'normal', fontSize: '18px !important' }}>
                  <T _={'service0900:carousel.step4'} />
                </h3>
              </div>
            </TelSexItem>
          </TelSexItemContainer>
        </Content>
      </div>
    </div>
  );
};

export default PhoneCarrouselDescription;
