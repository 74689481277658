import React, { FC, ReactElement, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { AttachmentInputOrFile } from '../../../../../../../util/FileUtils';
import { Attachment } from '../../../../../../../graphql/VXModels/types';
import AttachmentUploadInput from './AttachmentUploadInput';
import { FieldUploadAttachmentBaseProps } from './types';
import { _ } from '../../../../../../../util/translate';

/**
 * Generic Formik field for Attachment upload
 *
 * Wraps AttachmentUploadInput and makes integrates it with Formik (should not do anything else!)
 */
const FieldUploadAttachmentBase: FC<FieldUploadAttachmentBaseProps> = ({
  name,
  onPreCheck,
  onUploadFinished,
  transformFile,
  fileStorageSettings,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }: FieldProps): ReactElement => {
        const attachment = value as AttachmentInputOrFile | Attachment | undefined;

        const onRemoveFile = (): void => {
          setFieldValue(name, null, true);
        };

        const onSelectFile = async (file: File): Promise<void> => {
          setLoading(true);

          try {
            onPreCheck?.(file);

            const attachmentInputOrFile = await transformFile(file);
            setFieldValue(name, attachmentInputOrFile, true);

            onUploadFinished?.();
          } catch (err) {
            console.error(err);
            window.alert(_('common:error.generic'));
          } finally {
            setLoading(false);
          }
        };

        return (
          <AttachmentUploadInput
            {...rest}
            loading={loading}
            attachment={attachment}
            onSelectFile={onSelectFile}
            onRemoveFile={onRemoveFile}
            fileStorageSettings={fileStorageSettings}
          />
        );
      }}
    </Field>
  );
};

export default FieldUploadAttachmentBase;
