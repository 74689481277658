import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { VXCashBillingsStore } from '../../../stores/Lists/VXCashBillings/VXCashBillingsStore';
import { VXCashBillingsActionCreators } from '../../../stores/Lists/VXCashBillings/VXCashBillingsActionCreators';

import Spinner from '../../../components/Spinner/Spinner';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import { FormatDate, FormatCurrency, FormatDatePeriod } from '../../../components/Formatter';
import Translation from '../../../components/Translation/Translation';
import { _ } from '../../../util/translate';

class VXCashBillings extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getStoreData();
  }

  componentDidMount() {
    VXCashBillingsStore.addChangeListener(this.onStoreChange);
  }

  componentWillUnmount() {
    VXCashBillingsStore.removeChangeListener(this.onStoreChange);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.year && newProps.year !== this.props.year) {
      this.state.showSpinner = true;
      this.setState(this.state);
      VXCashBillingsActionCreators.getVXCashBillings(newProps.year);
    }
  }

  getStoreData = () => cloneDeep(VXCashBillingsStore.get());

  onStoreChange = () => {
    let storeData = this.getStoreData();
    this.state.showSpinner = false;
    this.state.billingList = storeData.billingList;
    this.setState(this.state);
  };

  openPDF = billingId => {
    VXCashBillingsActionCreators.getVXCashBillingPDF(billingId);
  };

  render() {
    return (
      <article className="vxcash-billings-list list">
        <div className="spinner-container">
          {/*  SPINNER */}
          {this.state.showSpinner && <Spinner size="m" />}

          {/*  DATA NOT FOUND */}
          {this.state.billingList.length < 1 && (
            <EmptyContent title={_('lists:vxcashbillings.info.noBillingFound')} />
          )}

          {/*  SHOW DATA LIST */}
          {this.state.billingList.length > 0 && (
            <Fragment>
              <header className="list-header">
                <div className="column-period">
                  <Translation _={'lists:vxcashbillings.tablefields.period'} />
                </div>
                <div className="column-date">
                  <Translation _={'lists:vxcashbillings.tablefields.date'} />
                </div>
                <div className="column-payoutNumber">
                  <Translation _={'lists:vxcashbillings.tablefields.payoutNumber'} />
                </div>
                <div className="column-status">
                  <Translation _={'lists:vxcashbillings.tablefields.status'} />
                </div>
                <div className="column-amount">
                  <Translation _={'lists:vxcashbillings.tablefields.amount'} />
                </div>
                <div className="column-download">
                  {/*<Translation _={'lists:vxcashbillings.tablefields.download'} />*/}
                </div>
              </header>
              {this.state.billingList.map(item => (
                <article className="list-entry" key={item.id}>
                  <div className="column-period">
                    <FormatDatePeriod from={item.dateFrom} to={item.dateTo} />
                  </div>
                  <div className="column-date">
                    <FormatDate value={item.date} />
                  </div>
                  <div className="column-payoutNumber">{item.payoutNumber}</div>
                  <div className="column-status">
                    <Translation _={'lists:vxcashbillings.status.' + item.status} />
                  </div>
                  <div className="column-amount">
                    <FormatCurrency value={item.amount} />
                  </div>
                  <div
                    className="icon-download-alt column-download"
                    onClick={() => this.openPDF(item.id)}
                  />
                </article>
              ))}
            </Fragment>
          )}
        </div>
      </article>
    );
  }
}

export default VXCashBillings;
