import React, { FC } from 'react';
import NavSub from '../../components/NavSub/NavSub';
import NavSubItem from '../../components/NavSubItem/NavSubItem';
import { _ } from '../../util/translate';
import { useQuery } from '@apollo/react-hooks';
import { APP_BASE_PATH } from '../../util/env';
import { QUERY_MODEL_TVSHOWS_ENABLED } from '../../graphql/VXModels/queries';

const NavSubService0900: FC = () => {
  const { data } = useQuery(QUERY_MODEL_TVSHOWS_ENABLED);

  return (
    <NavSub>
      <NavSubItem
        link={`${APP_BASE_PATH}/service0900/phone-sex`}
        icon="icon-call-record"
        label={_('service0900:phoneSex.menuLabel')}
      />
      {data?.model?.tvshows?.enabled ? (
        <NavSubItem
          link={`${APP_BASE_PATH}/service0900/carrousel`}
          icon="icon-call-record"
          label={_('service0900:carrousel.menuLabel')}
        />
      ) : null}
    </NavSub>
  );
};

export default NavSubService0900;
