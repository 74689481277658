import React, { FC, ReactElement } from 'react';

const FailedIcon: FC = (): ReactElement => {
  return (
    <g xmlns="http://www.w3.org/2000/svg" id="Fehlgeschlagen" transform="translate(-1642 -4528)">
      <circle id="Kreis" cx="21" cy="21" r="21" transform="translate(1642 4528)" fill="#ff4d3c" />
      <path
        id="Achtung"
        d="M9.073,3h1.158a.581.581,0,0,0,.394-.193,1.618,1.618,0,0,0,.327-.446,1.972,1.972,0,0,0,.171-.46L12.459-5.18a7.191,7.191,0,0,0,.149-1.47v-5.941a.717.717,0,0,0-.215-.527.717.717,0,0,0-.527-.215H7.409a.717.717,0,0,0-.527.215.717.717,0,0,0-.215.527V-6.65a8.514,8.514,0,0,0,.149,1.663L8.182,1.9a1.933,1.933,0,0,0,.342.728A.722.722,0,0,0,9.073,3ZM7.409,10.429h4.455a.735.735,0,0,0,.743-.743V5.231a.735.735,0,0,0-.743-.743H7.409a.735.735,0,0,0-.743.743V9.687a.735.735,0,0,0,.743.743Z"
        transform="translate(1653.333 4550.333)"
        fill="#fff"
      />
    </g>
  );
};

export default FailedIcon;
