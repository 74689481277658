import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import 'video.js/dist/video-js.min.css'; // https://vjs.zencdn.net/5.19.2/video-js.min.css

class VideoPlayer extends Component {
  videoNode = createRef();

  componentDidMount() {
    if (this.props.url && this.videoNode.current) {
      this.player = videojs(this.videoNode.current, {
        controls: this.props.controls,
        autoplay: this.props.autoplay,
        preload: this.props.preload,
        muted: this.props.muted,
        controlBar: this.props.controlBar,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.poster !== this.props.poster) {
      document
        .querySelector('.vjs-poster')
        .setAttribute('style', `background-image: url('${this.props.poster}')`);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    const { url, type, poster, className } = this.props;
    return (
      <div className={`videoplayer ${className}`}>
        <video className="video-js" ref={this.videoNode} poster={poster}>
          <source src={url} type={type} />
        </video>
      </div>
    );
  }
}

VideoPlayer.debug = false;

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool,
  preload: PropTypes.oneOf(['auto']),
  muted: PropTypes.bool,
  poster: PropTypes.string,
  className: PropTypes.string,
  controlBar: PropTypes.shape({
    durationDisplay: PropTypes.bool,
    remainingTimeDisplay: PropTypes.bool,
    currentTimeDisplay: PropTypes.bool,
    volumePanel: PropTypes.bool,
    liveDisplay: PropTypes.bool,
    timeDivider: PropTypes.bool,
    progressControl: PropTypes.bool,
  }),
};

VideoPlayer.defaultProps = {
  id: 'videoplayer',
  type: 'video/mp4',
  controls: true,
  autoplay: false,
  preload: 'auto',
  muted: false,
  className: '',
  poster: '',
  controlBar: {
    durationDisplay: true,
    remainingTimeDisplay: false,
    currentTimeDisplay: true,
    volumePanel: true,
    liveDisplay: false,
    timeDivider: true,
    progressControl: true,
  },
};

export default VideoPlayer;
export { VideoPlayer };
