import gql from 'graphql-tag';
import { FRAGMENT_FILE } from '../fragments';

export const QUERY_MASTER_USERLIFECYCLE = gql`
  query VXM_CL_QUERY_MASTER_USERLIFECYCLE($token: String!, $vip: Boolean!, $id: Int) {
    master(token: $token) {
      userLifeCycle(vip: $vip, id: $id) {
        id
        active
        vip
        text
        file {
          ...File
        }
        delayMinutes
        delayDisplayUnit
        delaySchedule
      }
    }
  }
  ${FRAGMENT_FILE}
`;
