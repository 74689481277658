import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import { FieldArray, FormikProps } from 'formik';
import { GRAY_3 } from '../../../../../camtool-styles';
import { DatePicker } from '../../../../../atoms';
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import { IFormData } from '../../../types';
import { _ } from '../../../../../util/translate';
import RadioCircleButton from '../../../../../atoms/RadioCircleButton/RadioCircleButton';
import { Box } from '@material-ui/core';

const VoucherExpiration: FC<Pick<FormikProps<IFormData>, 'setFieldValue'>> = ({
  setFieldValue,
}) => {
  const [validityPickerVisible, setValidityPickerVisible] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === 'hideExpirationPicker') {
      setValidityPickerVisible(false);
      setFieldValue('validityDateRange', ['', '']);
    }

    if (e.target.value === 'showExpirationPicker') {
      setValidityPickerVisible(true);
    }
  };

  return (
    <FieldArray
      name="validityDateRange"
      render={(arrayHelpers): ReactElement => {
        const handleFromChange = (startDate: string): void => arrayHelpers.replace(0, startDate);
        const handleToChange = (endDate: string): void => arrayHelpers.replace(1, endDate);

        return (
          <FieldLayout
            label={_('benefits:voucher.texts.validityDate')}
            info={_('benefits:voucher.texts.validityDateInfo')}
            error=""
            css={{ maxWidth: 574 }}
          >
            <Box display="flex" gridGap="40px" mt={1} pl={0.5}>
              <RadioCircleButton
                name="expirationPickerSwitch"
                value={'hideExpirationPicker'}
                onChange={handleChange}
                checked={!validityPickerVisible}
              >
                {_('benefits:voucher.texts.permanent')}
              </RadioCircleButton>
              <RadioCircleButton
                name="expirationPickerSwitch"
                value={'showExpirationPicker'}
                onChange={handleChange}
                checked={validityPickerVisible}
              >
                {_('benefits:voucher.texts.limited')}
              </RadioCircleButton>
            </Box>
            {validityPickerVisible && (
              <Box mt={1}>
                <DatePicker
                  onFromChangeWithoutTime={handleFromChange}
                  onToChangeWithoutTime={handleToChange}
                  disablePastDate={true}
                  css={{ borderRadius: 4, border: `1px solid ${GRAY_3}` }}
                />
              </Box>
            )}
          </FieldLayout>
        );
      }}
    />
  );
};

export default VoucherExpiration;
