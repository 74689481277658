export enum BroadcastStatus {
  pending = 'pending', // 0%
  running = 'running',
  failed = 'failed',
  revoked = 'revoked',
  canceled = 'canceled',
  done = 'done',
}

export enum BroadcastSource {
  vxmodels = 'vxmodels',
  telegrambot = 'telegrambot',
}
