import React from 'react';
import PropTypes from 'prop-types';

const ControlledCheckbox = props => (
  <label className={`${props.labelClass}`}>
    <input className={`${props.inputClass}`} type="checkbox" onChange={props.onChange} />
    {props.label}
    {props.children}
  </label>
);

ControlledCheckbox.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
};

ControlledCheckbox.defaultProps = {
  onChange: () => {},
  label: '',
  labelClass: '',
  inputClass: '',
};

export default ControlledCheckbox;
