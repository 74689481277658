import React, { FC } from 'react';
import { Redirect } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_TVSHOWS } from '../graphql';
import { Query } from '../../../graphql/VXModels/types';
import ExternalRedirect from '../../../components/Routing/ExternalRedirect';
import { _ } from '../../../util/translate';
import { EmptyContent, Spinner } from '../../../components';

const CalendarRedirect: FC = () => {
  const { data, loading, error } = useQuery<Query>(QUERY_MODEL_TVSHOWS, {
    fetchPolicy: 'network-only',
  });

  const tvShowsData = data?.model?.tvshows;
  const enabled = tvShowsData?.enabled;
  const calendarLink = tvShowsData?.links?.calendar;

  const displayError = !!(error || (enabled && !calendarLink));

  return loading ? (
    <Spinner size="xl" />
  ) : displayError ? (
    <EmptyContent icon="icon-warning-sign" title={_('common:error.generic')} />
  ) : enabled && calendarLink ? (
    <>
      <Spinner size="xl" />
      <ExternalRedirect to={calendarLink} />
    </>
  ) : (
    <Redirect to="/" />
  );
};

export default CalendarRedirect;
