import React, { FC } from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { getCurrentScopeSupportMail } from '../../../../../../util/emailAdresses';
import { VXGAMES_TYPOGRAPHY_PARAGRAPH, VXGAMES_TYPOGRAPHY_H3 } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '80%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
  })
);

const Heading: FC = () => {
  const supportMail = getCurrentScopeSupportMail();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography style={VXGAMES_TYPOGRAPHY_H3} gutterBottom>
        Du benötigst Hilfe?
      </Typography>
      <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH}>
        Wir helfen Dir gerne dabei mit den VXGames deinen Umsatz zu steigern, Kunden/Models zu
        werben und noch mehr Spaß beim Chatten zu haben.
        <br />
        Melde dich gerne unter:{' '}
        <a href={`mailto:${supportMail}`} style={{ textDecoration: 'none', color: '#000000' }}>
          {supportMail}
        </a>
      </Typography>
    </Box>
  );
};

export { Heading };
