import React, { FC } from 'react';
import { tableIcons } from './tableIcons';
import BaseMaterialTable, {
  Components,
  MaterialTableProps as BaseMaterialTableProps,
  Options,
} from 'material-table';
import { getLocalization } from './i18n';
import { useLang } from '../../util/AppState';
import OverlayLoading from './OverlayLoading';
import Body from './Body';
import Header from './Header';
import Pagination from './Pagination';
import Toolbar from './Toolbar';
import Container from './Container';

const components: Components = {
  Container: Container,
  OverlayLoading: OverlayLoading,
  Header: Header,
  // OverlayError:
  Body: Body,
  Pagination: Pagination,
  Toolbar: Toolbar,
};

const defaultOptions: Options<Record<string, unknown>> = {
  loadingType: 'overlay',
  search: false,
  showTitle: false,
  toolbar: false,
  draggable: false,
  pageSize: 10,
  pageSizeOptions: [10, 20, 50],
  padding: 'dense',
  tableLayout: 'auto',
};

type MaterialTableProps<RowData extends Record<string, unknown>> = BaseMaterialTableProps<RowData>;

const MaterialTable: FC<MaterialTableProps<Record<string, unknown>>> = ({
  localization,
  options,
  ...props
}) => {
  const [lang] = useLang();
  return (
    <BaseMaterialTable
      className={'spinner-container'}
      components={components}
      icons={tableIcons}
      localization={getLocalization(lang, localization)}
      options={{ ...defaultOptions, ...options }}
      {...props}
    />
  );
};

export default MaterialTable;
