import format from 'date-fns/format';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import toDate from 'date-fns/toDate';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import addSeconds from 'date-fns/addSeconds';
import subYears from 'date-fns/subYears';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMinutes from 'date-fns/subMinutes';
import subSeconds from 'date-fns/subSeconds';
import parseISO from 'date-fns/parseISO';

const dateFunctions = {
  addYears,
  addMonths,
  addWeeks,
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  subYears,
  subMonths,
  subWeeks,
  subDays,
  subHours,
  subMinutes,
  subSeconds,
};

const ISO_PERIOD = /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;

export function isWithin24Hours(date) {
  return Math.abs(differenceInSeconds(parseISO(date), new Date())) <= 24 * 60 * 60;
}

export function isToday(date) {
  return format(parseISO(date), 'YYYY-MM-DD') === format(new Date(), 'YYYY-MM-DD');
}

export function addDuration(date, duration) {
  date = toDate(date);
  if (isNaN(date.getTime())) {
    throw new TypeError(`date is invalid: ${date}`);
  }
  duration = duration.trim();
  const matches = ISO_PERIOD.exec(duration);
  if (!matches || duration.length < 3) {
    throw new TypeError(
      `duration invalid: "${duration}". Must be a ISO 8601 duration. See https://en.wikipedia.org/wiki/ISO_8601#Durations`
    );
  }
  const [, sign, years, months, weeks, days, hours, minutes, seconds] = matches;
  const prefix = sign === '-' ? 'sub' : 'add';
  return ['Years', 'Months', 'Weeks', 'Days', 'Hours', 'Minutes', 'Seconds'].reduce(
    (result, part, index) => {
      const value = matches[index + 2]; // +2 for match and sign parts
      return value ? dateFunctions[prefix + part](result, value) : result;
    },
    date
  );
}
