import React from 'react';
import AbstractComponent from '../../../components/AbstractComponent';
import Modalbox from '../../Modalbox/Modalbox';

import image from './images/Pop-Up-neuer-Promolink-Model.jpg';
import { APP_BASE_PATH } from '../../../util/env';

class ModalShortLinkChanges extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillMount() {
    if (this.props.isOpen) this.open();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) this.open();
      else this.close();
    }
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <Modalbox
        name="modalShortLinkChanges"
        state={this.state.isOpen}
        onKeyPressESC={this.close}
        className="modalShortLinkChanges__container"
      >
        <div className="modalgrid__box">
          <div className="modalgrid__box__header vxmodels-academy__header">
            Wichtiger Hinweis - Promolink auf Socials geblockt
            <div className="seperator" />
            <div
              className="icon-remove options vxmodels-academy__header__btn"
              onClick={this.close}
            />
          </div>
          <a
            onClick={() => this.props.history.push(`${APP_BASE_PATH}/vxcash/invite-users`)}
            style={{ cursor: 'pointer' }}
          >
            <div className="modalShortLinkChanges__box__content">
              <div className="modalShortLinkChanges__modalgrid__box__content__column">
                <h2 className="modalShortLinkChanges__title">
                  Der VISIT-X Promolink ist derzeit auf den Socials geblockt und leitet nicht mehr
                  weiter.
                </h2>

                <p className="modalShortLinkChanges__paragraph">
                  Bitte passe alle Deine aktiven Links umgehend an den neuen Promolink an (und
                  vermeide Spamming).
                </p>
                <img className="modalShortLinkChanges__image" src={image} />
              </div>
            </div>
          </a>
        </div>
      </Modalbox>
    );
  }
}

ModalShortLinkChanges.propTypes = {};

ModalShortLinkChanges.defaultProps = {};

export default ModalShortLinkChanges;
export { ModalShortLinkChanges };
