import gql from 'graphql-tag';

export const FRAGMENT_CHALLENGE = gql`
  fragment VXGChallenges on VXGChallenges {
    id
    type
    status
    startDate
    endDate
    targetValue
    targetValueSteps {
      value
      label
    }
    numberOfWinners
    participantsTotal
    participantsCompleted
    participantsList(status: chosenAsWinner) {
      userName
    }
    rewardValue
    redirectUri
    texts {
      title
      subTitle
      description
      reward
      condition
      trophyDescription
      unit
    }
    userStatistic {
      challengeId
      status
      registerDate
      completeDate
      awardDate
      actualValue
    }
  }
`;
