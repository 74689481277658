import React, { FC, ReactElement } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { StyledNavLink } from './styles';
import NavSubItemIcon from './NavSubItemIcon';
import NavSubItemLabel from './NavSubItemLabel';
import { BREAKPOINT_PHONE_CONDITION } from '../../camtool-styles';
import { useRouteMatch } from 'react-router-dom';

interface NavSubItemProps {
  link: string;
  label: string;
  icon?: string | ReactElement;
}

const NavSubItem: FC<NavSubItemProps> = ({ link, label, icon }) => {
  const match = useRouteMatch(link);
  const isActive = match?.url === link;
  const isMobile = useMediaQuery(BREAKPOINT_PHONE_CONDITION);
  const hasIcon = !!icon;
  const hideLabel = isMobile && hasIcon && !isActive;

  return (
    <Box display="flex" flex="0 0 auto" flexDirection="column">
      <StyledNavLink exact={isActive} to={link}>
        {hasIcon ? <NavSubItemIcon icon={icon} /> : null}
        {hideLabel ? null : <NavSubItemLabel label={label} />}
      </StyledNavLink>
    </Box>
  );
};

export default NavSubItem;
