import styled from '@emotion/styled';

export const Ul = styled.ul`
  padding: 16px 0;
`;
export const Li = styled.li`
  margin: 0 0 8px 32px;
  font-size: 14px;
`;

export const Heading = styled.h2`
  color: #000;
  padding: 8px 0;
  font-size: 18px;
`;

export const CopyButton = styled.div`
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  &:active {
    border-color: transparent;
  }
`;

export const RedCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  background-color: #ff4d3c;
  color: white;
  text-align: center;
  align-items: center;
  width: 9em;
  height: 9em;
  padding: 1em;
  font-size: 14px;
  box-shadow: 0 0 3px rgba(0, 0, 05);
  border: 1px solid rgba(0, 0, 0, 0.2);
  left: 16px;
  top: 16px;
`;
