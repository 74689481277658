import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const DefaultWidget = React.memo((props) => {
  const getIcon = () => {
    switch (props.iconName) {
      case 'stopwatch':
        return <span className="icon-stopwatch" />;
      case 'star':
        return <span className="icon-star" />;
      case 'cup':
        return <span className="icon-cup " />;
      case 'money':
        return <span className="icon-money" />;
      default:
        return null;
    }
  };

  if (props.loading) return <Container css={{ height: 76 }} />;
  if (props.error)
    return <Container css={{ height: 76, wordBreak: 'keep-all' }}>{props.error}</Container>; //word-break solves this task: https://phabricator.x/T18609

  return (
    <Container tabIndex={props.tabIndex} onClick={props.onClick} className={props.className}>
      <div>
        <div css={{ color: props.iconColor, fontSize: 32, alignItems: 'center', marginRight: 16 }}>
          {getIcon()}
        </div>

        <div css={{ flexDirection: 'column' }}>
          <div css={{ whiteSpace: 'nowrap' }}>{props.label}</div>
          <div css={{ fontSize: 32, whiteSpace: 'nowrap' }}>{props.data}</div>
        </div>
      </div>

      <div css={{ color: '#525158', fontSize: 10, position: 'absolute', right: 8, bottom: 4 }}>
        {props.footnote}
      </div>
    </Container>
  );
});

DefaultWidget.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  iconName: PropTypes.oneOf(['stopwatch', 'star', 'cup', 'money']).isRequired,
  iconColor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  footnote: PropTypes.string,
  error: PropTypes.string,
};

DefaultWidget.defaultProps = {
  loading: false,
  className: '',
  onClick: null,
  tabIndex: -1,
  footnote: '',
};

DefaultWidget.displayName = 'DefaultWidget';

export default DefaultWidget;
