var React = require('react');
var PropTypes = require('prop-types');

var classNames = require('classnames');

var Phx = global.Phx || {};
Phx.Mixin = Phx.Mixin || {};
Phx.Mixin.FormElement = {
  value: null,

  propTypes: {
    onChange: PropTypes.func,
  },

  getDefaultProps: function() {
    return {
      name: '',
      // placeholder: ''
    };
  },

  // componentWillReceiveProps: function(nextProps){
  //   if(this.value === null){
  //     this.value = Phx.Utils.Helper.getValueFromProps(nextProps, this.props.name);
  //     this.value = cloneDeep(this.value);
  //     this.setValue(this.value || '');
  //   }
  // },

  componentWillReceiveProps: function(nextProps) {
    this.setValidations(nextProps.validations, nextProps.required);
    if (nextProps.value !== this.state._pristineValue) {
      this.setState({
        _pristineValue: nextProps,
      });
    }
  },

  changeValue: function(event) {
    this.setValue(event.currentTarget.value);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  },

  getCustomErrorMessage: function() {
    var errorMessage;
    if (this.showError()) {
      errorMessage = this.getErrorMessage();
      var errorMessages = this.getErrorMessages();
      if (errorMessages.length > 0) {
        errorMessage = errorMessages.join(', ');
      }
    }

    if (this.props.requiredMessage && this.showRequired()) {
      errorMessage = this.props.requiredMessage;
    }

    return errorMessage;
  },

  getFormsyClassNames: function() {
    var classes = {
      disabled: this.props.disabled,
      valid: !this.showError(),
      invalid: this.showError(),
      required: this.showRequired(),
    };

    if (this.props.className) {
      classes[this.props.className] = true;
    }

    return classNames(classes);
  },

  // addInputfieldValidationRule: function(){
  //   Formsy.addValidationRule('isAlphanumericWithUmlaute', function(form, value){
  //     if(value){
  //       //keine leerzeichen
  //       if(value.trim() !== value){
  //         return false;
  //       }
  //       //a-z, A-Z, 0-9, äöüÄÖÜß
  //       if(value.match(/[^a-zA-Z0-9äöüÄÖÜß]/g) === null){
  //         return true;
  //       }
  //       return false;
  //     }else{
  //       return true; //damit beim laden der form keine errormessage angezeigt
  //     }
  //   });
  // },
};

module.exports = Phx.Mixin.FormElement;
