
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "UserInterface",
        "possibleTypes": [
          {
            "name": "Model"
          },
          {
            "name": "Originator"
          },
          {
            "name": "AuthUser"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "MailingIContent",
        "possibleTypes": [
          {
            "name": "MailingContentEmailPort25"
          },
          {
            "name": "MailingContentInstantMessenger"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "LoginResult",
        "possibleTypes": [
          {
            "name": "LoginSuccessResult"
          },
          {
            "name": "LoginInvalidCredentialsResult"
          },
          {
            "name": "LoginAccountLockedResult"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "LoginResultInterface",
        "possibleTypes": [
          {
            "name": "LoginSuccessResult"
          },
          {
            "name": "LoginInvalidCredentialsResult"
          },
          {
            "name": "LoginAccountLockedResult"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SignupResult",
        "possibleTypes": [
          {
            "name": "SignupSuccessResult"
          },
          {
            "name": "SignupInvalidFieldsResult"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "SignupResultInterface",
        "possibleTypes": [
          {
            "name": "SignupSuccessResult"
          },
          {
            "name": "SignupInvalidFieldsResult"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ContestUploadedMedia",
        "possibleTypes": [
          {
            "name": "Picture"
          },
          {
            "name": "Video"
          }
        ]
      }
    ]
  }
};
      export default result;
    