import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AbstractFormSingleOptionComponent from '../AbstractFormSingleOptionComponent';
import isEqual from 'lodash/isEqual';
import { APP_IS_DEVELOPMENT } from '../../../../util/env';

class Dropdown extends AbstractFormSingleOptionComponent {
  constructor(props) {
    super(props);
    this.optionsExposedGroup = [];
    if (this.props.optionsExposedGroup.length > 0) {
      this.extractOptionsExposedGroup();
    }
  }

  extractOptionsExposedGroup() {
    for (let optionIdx in this.options) {
      const option = this.options[optionIdx];
      const groupIdx = this.props.optionsExposedGroup.indexOf(option.value);
      if (groupIdx !== -1) {
        this.optionsExposedGroup[groupIdx] = option;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    const optionsChanged = !isEqual(this.props.options, nextProps.options);
    const hasOptionsExposedGroup = this.props.optionsExposedGroup.length > 0;
    if (optionsChanged && hasOptionsExposedGroup) {
      this.extractOptionsExposedGroup();
    }
  }

  render() {
    if (Dropdown.debug && APP_IS_DEVELOPMENT) {
      console.log(this.__proto__.constructor.name, 'render', this.getValue(), this.options);
    }

    this.hasOptionsExposedGroup = this.optionsExposedGroup.length > 0;
    return (
      <div className={`dropdown ${this.getClassNames()}`}>
        <div className="dropdown__column">
          <select
            name={this.props.name}
            onChange={this.onChange}
            value={this.getValue()}
            disabled={this.props.disabled}
          >
            {this.props.placeholder && <option value={''}>{this.props.placeholder}</option>}

            {!this.hasOptionsExposedGroup &&
              this.options.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={this.isOptionDisabled(option)}
                >
                  {option.label}
                </option>
              ))}

            {this.hasOptionsExposedGroup && (
              <Fragment>
                <optgroup>
                  {this.optionsExposedGroup.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                      disabled={this.isOptionDisabled(option)}
                    >
                      {option.label}
                    </option>
                  ))}
                </optgroup>
                <optgroup>
                  {this.options.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                      disabled={this.isOptionDisabled(option)}
                    >
                      {option.label}
                    </option>
                  ))}
                </optgroup>
              </Fragment>
            )}
          </select>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  ...AbstractFormSingleOptionComponent.propTypes,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  optionsExposedGroup: PropTypes.array,
};

Dropdown.defaultProps = {
  ...AbstractFormSingleOptionComponent.defaultProps,
  value: '',
  placeholder: '',
  optionsExposedGroup: [],
};

Dropdown.debug = false;

export default Dropdown;
export { Dropdown };
