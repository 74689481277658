import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import Icon from '../../Icon/Icon';
import { BLACK_1, BLUE, BLUE_HOVER } from '../../../camtool-styles';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const StyledLabel = styled.label`
  display: flex;
  flex: 1;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  min-width: 25px;
`;

interface IProps {
  accept: string;
  selectedFile?: boolean;
  selectFile: (file: File) => void;
  clearInputFieldAfterSubmit?: boolean;
  className?: string;
  disabled?: boolean;
}

const UploadPicker: FC<IProps> = ({
  className,
  disabled,
  selectFile,
  clearInputFieldAfterSubmit,
  selectedFile,
  accept = '*',
}) => {
  const inputEl = useRef<HTMLInputElement>(null);
  if (clearInputFieldAfterSubmit === true && inputEl && inputEl.current) {
    inputEl.current.value = '';
  }
  return (
    <StyledLabel className={className}>
      <Icon
        css={{
          cursor: 'pointer',
          color: selectedFile ? BLUE : BLACK_1,
          ':hover': { color: BLUE_HOVER },
        }}
        icon={faPaperclip}
        size={'lg'}
      />
      <input
        ref={inputEl}
        css={{ display: 'none' }}
        type="file"
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault();
          const [file] = event.currentTarget.files;
          if (file) selectFile(file);
          event.stopPropagation();
        }}
        accept={accept}
      />
    </StyledLabel>
  );
};

export default UploadPicker;
