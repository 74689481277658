import React from 'react';
import { T } from '../../../../components';
import NavSubLA from '../NavSubLA';
import Footer from '../../../../components/Footer/Footer';

const Profilepic = ({ children }) => {
  return (
    <main id="la-profile-pic" className="la-profile-pic grid">
      <NavSubLA />
      <section className="grid__row">
        <section className="grid__column grid__box">
          <header className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'profiles:la.profilepic.pagetitle.label'} />
            </div>
          </header>

          <div className="grid__box__item spinner-container">
            <div className="grid__box__item__content">{children}</div>
          </div>
        </section>
      </section>
      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </main>
  );
};

export default Profilepic;
