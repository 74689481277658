import React, {FC, useState} from 'react';
import {_} from '../../util/translate';
import {Box, Grid, Typography} from '@material-ui/core';
import {Fieldset} from '../../atoms';
import {
    FieldCheckbox,
    FieldMultiLanguageTextField,
    FieldSlider,
} from '../../molecules/Formik/Fields';
import {T} from '../../components';
import {SectionLabel} from '../Mailings/components/styles';
import {makeStyles, Theme} from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {sanitizeLang} from '../../util/Translator';
import {ApiLang, ChatSetting, Heatmap, LocalizedText, Prices} from '../../graphql/VXModels/types';
import NewButton from '../../atoms/Button/NewButton';
import {FormikErrors} from 'formik';
import ErrorIcon from '@material-ui/icons/Error';
import ServerUtils from '../../util/ServerUtils';
import HelpcenterLink from "../../components/Helpcenter/HelpcenterLink";

const useStyles = makeStyles((theme: Theme) => ({
    success: {
        marginTop: '2px',
        marginRight: '4px',
        color: theme.palette.success.main,
    },
    error: {
        marginTop: '2px',
        marginRight: '4px',
        color: theme.palette.error.main,
    },
    subHeadline: {
        fontSize: '13px',
    },
    checkboxLabel: {
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    subTitle2: {
        fontSize: '12px',
    },
    testChatButton: {
        [theme.breakpoints.down('sm')]: {
            padding: '0 14px',
            fontSize: '14px',
        },
    },
    infoText: {
        fontSize: '12px',
        color: theme.palette.warning.main,
    },
    textArea: {
        minHeight: '400px',
    },
    warningWrapper: {
        fontSize: '12px',
        color: theme.palette.warning.main,
        marginTop: '10px',
        backgroundColor: '#f5f5f5',
        width: 'fit-content',
        paddingLeft: '4px',
        paddingRight: '10px',
        marginLeft: '-4px',
    },
    warningLabel: {
        fontSize: '12px',
        color: theme.palette.warning.main,
        marginTop: '-2px',
        marginLeft: '8px',
    },
}));

interface Props {
    name: string;
    data?: ChatSetting | Prices | Heatmap;
    error?: FormikErrors<string>;
    hasSliderInputGroup?: boolean;
    hasFieldSetCheckboxInput?: boolean;
    hasOnlyButton?: boolean;
    hasNestedFieldSetInput?: boolean;
    hasNestedFieldSetCheckbox?: boolean;
    nestedFieldName?: string;
    nestedData?: LocalizedText | ChatSetting;
    isActive?: boolean;
}

const FieldEntry: FC<Props> = ({
                                   name,
                                   data,
                                   hasFieldSetCheckboxInput,
                                   hasSliderInputGroup,
                                   hasNestedFieldSetCheckbox,
                                   nestedFieldName,
                                   error,
                                   hasNestedFieldSetInput,
                                   isActive,
                               }) => {
    const classes = useStyles();
    const languageArray: ApiLang[] = [ApiLang.de, ApiLang.en];
    const [language, setLanguage] = useState<ApiLang>(ApiLang.de);
    const [tab, setTab] = useState<number>(0);
    const changeLanguage = (nthLanguage: number): void => {
        setLanguage(languageArray[nthLanguage]);
    };

    const handleTabChange = (newValue: number): void => {
        setTab(newValue);
    };
    const openTestChat = () => {
        ServerUtils.request(
            'GET',
            `/v1/camtool/user/{userId}/testchat/url`,
            null,
            function (response) {
                window.open(response.testChatUrl, 'testChat');
            },
            function (error) {
                new Error(`${error}: failed to get testChat`);
            }
        );
    };

    return (
        <Grid item xs={12} key={name}>
            {/*testChat*/}
            {name === 'testChat' && (
                <>
                    <Box mb={2}>
                        <Typography variant="h5">{_(`settings:chatSettings.${name}.title`)}</Typography>
                        <T t={`settings:chatSettings.${name}.description`}/>
                    </Box>
                    {!isActive ? (
                        <Box mb={4}>
                            <NewButton color="default" disabled={!isActive} className={classes.testChatButton}>
                                {_(`settings:chatSettings.${name}.button`)}
                            </NewButton>
                            <Typography variant="subtitle2" className={classes.infoText}>
                                {_('settings:chatSettings.testChat.infoIfOffline')}
                            </Typography>
                        </Box>
                    ) : (
                        <Box mb={4}>
                            <NewButton
                                color="default"
                                variant={'outlined'}
                                onClick={openTestChat}
                                className={classes.testChatButton}
                            >
                                {_(`settings:chatSettings.${name}.button`)}
                            </NewButton>
                        </Box>
                    )}
                </>
            )}
            {hasSliderInputGroup && name && (
                <Box my={1}>
                    {name !== 'heatmap' && (
                        <Typography variant="h5">
                            <Box lineHeight={1.7}>{_(`settings:chatSettings.${name}.subTitle`)}</Box>
                        </Typography>
                    )}
                    <Typography variant="subtitle2" className={classes.subTitle2}>
                        {_(`settings:chatSettings.${name}.subDescription`)}
                    </Typography>
                </Box>
            )}
            {/*MessengerChatPrice*/}
            {hasSliderInputGroup && name === 'messengerUserPrice' && (
                <Box mb={2}>
                    <FieldSlider
                        style={{width: '100%'}}
                        name={'sliders.messengerUserPrice'}
                        min={data.messengerMinPrice}
                        max={data.messengerMaxPrice}
                        step={0.01}
                        marks={data.messengerPriceRange}
                        initValue={
                            data.messengerUserPrice ? data.messengerUserPrice : data.messengerDefaultPrice
                        }
                    />
                </Box>
            )}
            {/*singleChatPrice*/}
            {hasSliderInputGroup && name === 'singleChatUserPrice' && (
                <Box mb={2}>
                    <FieldSlider
                        style={{width: '100%'}}
                        name={'sliders.singleChatUserPrice'}
                        min={data?.singleChatMinPrice}
                        max={data.singleChatMaxPrice}
                        step={0.01}
                        initValue={
                            data.singleChatUserPrice ? data.singleChatUserPrice : data.singleChatDefaultPrice
                        }
                        disabled={isActive}
                    />
                </Box>
            )}
            {/*VideoChatPrice*/}
            {hasSliderInputGroup && name === 'videoChatUserPrice' && (
                <Box>
                    <FieldSlider
                        style={{width: '100%'}}
                        name={'sliders.videoChatUserPrice'}
                        min={data.videoChatMinPrice}
                        max={data.videoChatMaxPrice}
                        step={0.01}
                        initValue={data.videoChatPrice ? data.videoChatPrice : data.videoChatDefaultPrice}
                        disabled={isActive}
                    />
                </Box>
            )}
            {/* ConversionChat */}
            {hasFieldSetCheckboxInput && name === 'conversionChat' && !isActive && (
                <Fieldset
                    disabled={data.isEditable === false}
                    margin="20px 0px"
                    legend={
                        <div className={classes.warningWrapper}>
                            <ErrorIcon/>{' '}
                            <Typography variant="subtitle1" gutterBottom className={classes.warningLabel}>
                                {_(`settings:chatSettings.${name}.title`)}
                                {':'}
                                {_(`settings:chatSettings.${name}.inactive`)}
                            </Typography>
                        </div>
                    }
                >
                    <Grid item xs={12}>
                        <Box my={2}>
                            <Typography variant="subtitle2" className={classes.subTitle2}>
                                {_(`settings:chatSettings.${name}.description`)}{' '}
                                <a href={HelpcenterLink()} target={'_blank'}
                                   css={{textDecoration: 'none', color: '#1f93e9', hover: {color: '#3bacf7'}}}>
                                    {_(`settings:chatSettings.${name}.linkText`)}
                                </a>
                            </Typography>
                        </Box>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <FiberManualRecordIcon
                                    fontSize="small"
                                    className={isActive ? classes.success : classes.error}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {'Status: '}
                                    {isActive ? _(`common:onOff.enabled`) : _(`common:onOff.disabled`)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fieldset>
            )}
            {/*other settings*/}
            {hasFieldSetCheckboxInput && name !== 'conversionChat' && (
                <Fieldset
                    disabled={data.isEditable === false}
                    margin="20px 0px"
                    legend={
                        <FieldCheckbox
                            name={`settings.${name}.status`}
                            disabled={data.isEditable === false}
                            label={
                                <Typography variant="body1" className={classes.checkboxLabel}>
                                    {_(`settings:chatSettings.${name}.title`)}
                                </Typography>
                            }
                            checkboxColor="secondary"
                        />
                    }
                >
                    {name !== 'freeChat' && name !== 'partyChat' && (
                        <Grid item xs={12}>
                            <SectionLabel>
                                {data.status}
                                <Box mt={1}>
                                    <Typography variant="subtitle2" className={classes.subTitle2}>
                                        {_(`settings:chatSettings.${name}.description`)}
                                        {name === 'softChat' && (
                                            <a href={HelpcenterLink() + '/articles/360011989219'} target={'_blank'}
                                               css={{
                                                   textDecoration: 'none',
                                                   color: '#1f93e9',
                                                   hover: {color: '#3bacf7'}
                                               }}>
                                                {_(`settings:chatSettings.${name}.linkText`)}
                                            </a>
                                        )}
                                    </Typography>
                                </Box>
                            </SectionLabel>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <FiberManualRecordIcon
                                    fontSize="small"
                                    className={data.status === true ? classes.success : classes.error}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {'Status: '}
                                    {data.status === true ? _(`common:onOff.enabled`) : _(`common:onOff.disabled`)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {hasNestedFieldSetCheckbox && nestedFieldName && !hasNestedFieldSetInput && (
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <FieldCheckbox
                                    name={`settings.${nestedFieldName}.status`}
                                    disabled={
                                        data.isEditable === false ||
                                        (data.status === false && typeof data.status === 'boolean')
                                    }
                                    label={<T t={`settings:chatSettings.${nestedFieldName}.title`}/>}
                                    checkboxColor="secondary"
                                />
                            </Grid>
                        </Grid>
                    )}
                    {hasFieldSetCheckboxInput && hasNestedFieldSetInput && (
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={12}>
                                <Box mt={2} mb={4}>
                                    <Typography variant="h5">{_(`settings:chatSettings.${name}.subInfo`)}</Typography>
                                    <Typography variant="subtitle2" className={classes.subTitle2}>
                                        {_(`settings:chatSettings.${name}.subDescription`)}
                                    </Typography>
                                </Box>
                                <Box mt={1}>
                                    <FieldMultiLanguageTextField
                                        name={`settings.${name}.texts[${language === 'de' ? 0 : 1}].text`}
                                        maxValue={1000}
                                        tabTitle={'settings:chatSettings.heatmap.hintTitle'}
                                        disabled={data.isEditable === false}
                                        error={error}
                                        rows={8}
                                        tab={tab}
                                        setTab={handleTabChange}
                                        placeholderDe={
                                            data.texts[language] && data.texts[language].text.length
                                                ? `${data.texts[language].text}`
                                                : _(`settings:chatSettings.${name}.text`, {
                                                    lng: sanitizeLang('de'),
                                                })
                                        }
                                        placeholderEn={
                                            data.texts[language] && data.texts[language].text.length
                                                ? `${data.texts[language].text}`
                                                : _(`settings:chatSettings.${name}.text`, {
                                                    lng: sanitizeLang('en'),
                                                })
                                        }
                                        changeLanguage={changeLanguage}
                                        language={language}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Fieldset>
            )}
        </Grid>
    );
};

export default FieldEntry;
