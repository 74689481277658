import React from 'react';
import { _ } from '../../../util/translate';
import { useLang } from '../../../util/AppState';
import BannerDE from '../../../assets/images/Banner-DE.png';
import BannerEN from '../../../assets/images/Banner-EN.png';
import { ApiLang, VXModelsMobileTargetEnum } from '../../../graphql/VXModels/types';
import { T } from '../../../components';
import { useDeepLinkBeta } from '../../../hooks';
import NewButton from '../../../atoms/Button/NewButton';

const Campaigns = () => {
  return (
    <main
      id="marketing-campaigns"
      className="grid marketing marketing-campaigns"
      style={{ minWidth: '600px' }}
    >
      <div className="grid__row">
        <div className="grid__column grid__box h--pos-rel min-width--0">
          <div className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'marketing:campaigns.title'} />
            </div>
            <div className="grid__box__header__spacer" />
          </div>
          <ContestSwitchToBeta />
        </div>
      </div>
    </main>
  );
};

export default Campaigns;

export const ContestSwitchToBeta = () => {
  const [lang] = useLang();

  const { data: betaDeepLinkData, refetch } = useDeepLinkBeta(VXModelsMobileTargetEnum.dashboard);

  const goToBeta = () => {
    refetch();
    const dashboardLink = betaDeepLinkData?.auth?.betaDeeplink || '';
    window.open(dashboardLink, '_blank');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '100px',
        fontSize: '16px',
      }}
    >
      <b style={{ fontWeight: 'bolder' }}>
        {lang === ApiLang.de
          ? 'Du möchtest am Wettbewerb teilnehmen?'
          : 'Do you want to participate in the contest?'}
      </b>
      <a onClick={goToBeta} style={{ color: '#f32466', cursor: 'pointer', padding: '10px' }}>
        {lang === ApiLang.de ? 'Wechsel zu Beta ↗' : 'Switch to Beta ↗'}
      </a>
      <img
        src={lang === ApiLang.de ? BannerDE : BannerEN}
        style={{ width: 'fit-content', margin: 'auto' }}
      />
      <span style={{ padding: '20px 20px 50px 20px', fontSize: '12px', color: '#777' }}>
        {lang === ApiLang.de
          ? 'Beispiel, um das aktuelle Thema zu sehen, gehe zu'
          : 'Example, to see the current theme, go to'}
        &nbsp;
        <a
          onClick={goToBeta}
          style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline' }}
        >
          Beta.
        </a>
      </span>
      <NewButton onClick={goToBeta}>
        {lang === ApiLang.de ? 'Jetzt Teilnehmen' : 'Join Now'}
      </NewButton>
    </div>
  );
};
