import { PropTypes, Theme } from '@material-ui/core';
import { CustomColor } from '../atoms/Checkbox/NewCheckbox';
import { BETA_PURPLE, BETA_PURPLE_HOVER, BLACK, GRAY_2, GRAY_3, WHITE } from '../camtool-styles';
import { ValidationType } from './BuildValidationObject';

export const getPaletteColor = (theme: Theme, color?: string): ValidationType['color'] => {
  if (color === 'beta') {
    return {
      main: BETA_PURPLE,
      light: BETA_PURPLE_HOVER,
      contrastText: WHITE,
    };
  }
  return (
    (color && theme.palette[color as PropTypes.Color & CustomColor]) || {
      // default color
      main: GRAY_2,
      light: GRAY_3,
      contrastText: BLACK,
    }
  );
};
