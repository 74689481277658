import React, { Fragment } from 'react';
import { css } from '@emotion/core';
import { withApollo } from '@apollo/react-hoc';

import {
  ProfileStore,
  ProfileActionCreators,
  PROFILE_COMMON_ABOUTYOU as STORE,
} from '../../../../stores/Profile/Profile';
import { T } from '../../../../components';

import { SessionStore } from '../../../../stores/Session/Session';
import { Button, InputField, RadioButton, TextareaField } from '../../../../atoms';
import Select from '../../../../atoms/Select/Select';
import {
    Markdown,
    Spinner,
    LanguagesPicker,
    AttributesPicker,
    DatePicker,
    GroupRow,
    GeoMap
} from '../../../../components'; // prettier-ignore
import { AlertsStore } from '../../../../stores/Alerts/AlertsStore';
import isEU from '../../../../util/isEU';
import It from './It';
import {
  GeoServiceActionCreators,
  GeoServiceStore,
} from '../../../../stores/GeoService/GeoService';
import 'formdata-polyfill';
import { QUERY_MODEL_PROFILE_LANGUAGES } from '../../../../graphql/VXModels/queries';
import { withAppState } from '../../../../util/AppState';
import { _ } from '../../../../util/translate';
import { translateOptions, sortOptions } from '../../../../atoms/Select/utils';
import { scrollIntoView } from '../../../../util/scrollIntoView';
import InReviewMessage from './InReviewMessage';
import DeepLTextArea from '../../../../components/DeepL/DeepLTextArea/DeepLTextArea';

class AboutYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      session: SessionStore.get(),
      storeValues: ProfileStore.get(STORE),
      currentSpokenLangs: [],
      textInputLanguages: [],
      currentProfileTags: [],
      currentProfileOccupation: '',
      currentProfileCountry: '',
      currentProfileZipCode: '',
      currentProfileCity: '',
      validationErrors: {},
      isProfileAboutMeInReview: false,
      geoData: {},
    };
    this.jumpedToHash = false;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    const errors = { ...this.state.validationErrors };
    const formData = new FormData(event.target);

    // validate modelGender
    const modelGender = formData.get('modelGender');
    if (modelGender) {
      delete errors.modelGender;
      data.modelGender = modelGender;
    } else {
      errors.modelGender = _('profiles:modelGender.requiredMessage');
    }

    // validate profileSexuality
    const profileSxuality = formData.get('profileSexuality');
    if (It.isEmpty(profileSxuality)) {
      errors.profileSexuality = _('profiles:profileSexuality.requiredMessage');
    } else {
      delete errors.profileSexuality;
      data.profileSexuality = profileSxuality;
    }

    // validate profileLanguages
    if (this.state.currentSpokenLangs.length === 0) {
      errors.profileLanguages = _('profiles:profileLanguages.requiredMessage');
    } else {
      delete errors.profileLanguages;
      data.profileLanguages = this.state.currentSpokenLangs.map((l) => l.value);
    }

    let profileAboutMe = formData.get('profileAboutMe_deepl');
    if (profileAboutMe !== undefined && profileAboutMe !== '') {
      const aboutMe = { translate: profileAboutMe };
      // now only 1 text is required in any langunage!
      if (aboutMe.translate === '') {
        errors.profileAboutMe = _('profiles:profileAboutMe.requiredMessage');
      } else {
        delete errors.profileAboutMe;
        data.profileAboutMe = aboutMe;
      }
    } else {
      let reducer = (a, v) => ({ ...a, [v.value]: formData.get('profileAboutMe_' + v.value) });
      const aboutMe = this.state.textInputLanguages.reduce(reducer, {});
      // now only 1 text is required: de OR en!
      if (aboutMe.de === '' && aboutMe.en === '') {
        errors.profileAboutMe = _('profiles:profileAboutMe.requiredMessage');
      } else {
        delete errors.profileAboutMe;
        data.profileAboutMe = aboutMe;
      }
    }

    // validate profileTags
    if (this.state.currentProfileTags.length < 3) {
      errors.profileTags = _('profiles:profileTags.requiredMessage');
    } else {
      delete errors.profileTags;
      data.profileTags = this.state.currentProfileTags.map((t) => t.value);
    }

    // validate profileCharacteristics
    const profileCharacteristics = new Array(7).fill('').map((_, i) => formData.get('profileCharacteristics_' + i)); // prettier-ignore
    if (profileCharacteristics.every((val) => !!val === true)) {
      delete errors.profileCharacteristics;
      data.profileCharacteristics = profileCharacteristics;
    } else {
      errors.profileCharacteristics = _('profiles:profileCharacteristics.requiredMessage'); // prettier-ignore
    }

    // validate profileCountry
    const profileCountry = formData.get('profileCountry');
    if (!!profileCountry) {
      delete errors.profileCountry;
      data.profileCountry = profileCountry;
    } else {
      errors.profileCountry = _('profiles:profileCountry.requiredMessage');
    }

    // validate profileCity
    const profileCity = formData.get('profileCity');
    if (It.isEmpty(profileCity)) {
      errors.profileCity = _('profiles:profileCity.requiredMessage');
    } else {
      delete errors.profileCity;
      data.profileCity = profileCity;
    }

    // validate profileZipCode
    const profileZipCode = formData.get('profileZipCode');
    if (formData.get('profileCountry') === 'DE') {
      if (
        It.minStringLength(profileZipCode, 5) &&
        It.maxStringLength(profileZipCode, 5) &&
        It.isNumber(profileZipCode)
      ) {
        delete errors.profileZipCode;
        data.profileZipCode = profileZipCode;
      } else {
        errors.profileZipCode = _('profiles:profileZipCode.requiredMessage');
      }
    } else {
      delete errors.profileZipCode;
      data.profileZipCode = profileZipCode;
    }

    data.profileRelationshipState = formData.get('profileRelationshipState');
    data.profileOccupation = formData.get('profileOccupation');
    data.modelBirthdate = new Date(
      Date.UTC(
        formData.get('modelBirthdate.year'),
        +formData.get('modelBirthdate.month') - 1, // month is zero-based
        formData.get('modelBirthdate.day')
      )
    ).toISOString();

    if (Object.values(errors).length < 1) {
      window.scrollTo(0, 0);
      this.setState({ validationErrors: errors, isLoading: true }, () => {
        ProfileActionCreators.updateResource(
          STORE,
          { data: data },
          (res) => {
            window.scrollTo(0, 0);
            AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
            this.setState({ isLoading: false });
          },
          (err, res) => {
            AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
            console.warn('save failed', err, res);
            this.setState({ isLoading: false });
          }
        );
      });
    } else {
      this.setState({ validationErrors: errors }, () => {
        AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
        window.scrollTo(0, 0);
      });
    }
  };

  addOption = (option) => {
    this.setState({ currentSpokenLangs: [...this.state.currentSpokenLangs, option] });
  };

  removeOption = (option) => {
    this.setState({
      currentSpokenLangs: this.state.currentSpokenLangs.filter((opt) => {
        return opt.value !== option.value;
      }),
    });
  };

  addTag = (tag) => {
    this.setState({ currentProfileTags: [...this.state.currentProfileTags, tag] });
  };

  removeTag = (tag) => {
    this.setState({
      currentProfileTags: this.state.currentProfileTags.filter((opt) => {
        return opt.value !== tag.value;
      }),
    });
  };

  handleProfileSexualityChange = (event) => {
    const { value } = event.target;
    this.setState((currentState) => ({
      storeValues: { ...currentState.storeValues, profileSexuality: value },
    }));
  };

  handleProfileRelationshipChange = (event) => {
    const { value } = event.target;
    this.setState((currentState) => ({
      storeValues: { ...currentState.storeValues, profileRelationshipState: value },
    }));
  };

  handleCurrentProfileOccupationChange = (event) => {
    this.setState({ currentProfileOccupation: event.target.value });
  };

  handleProfileZipCodeChange = (event) => {
    this.setState({ currentProfileZipCode: event.target.value });
  };

  handleCurrentProfileCityChange = (event) => {
    this.setState({ currentProfileCity: event.target.value });
  };

  handleProfileCountryChange = (event) => {
    const { value } = event.target;
    this.setState((currentState) => ({
      storeValues: { ...currentState.storeValues, profileCountry: value },
      currentProfileCountry: value,
      currentProfileZipCode: '',
      currentProfileCity: '',
    }));
  };

  onStoreChange = () => {
    // Update profile languages in the apollo cache as some LeftMenu items are only displayed if the model speaks German
    // eslint-disable-next-line react/prop-types
    this.props.client.query({
      query: QUERY_MODEL_PROFILE_LANGUAGES,
      fetchPolicy: 'network-only',
    });

    const newStoreValues = ProfileStore.get(STORE);
    const profileAboutMe = newStoreValues.profileAboutMe;

    const isProfileAboutMeInReview = !!(
      profileAboutMe?.hasTexts && profileAboutMe?.ratingStatus === 'new'
    );

    const backendErrors = {};

    if (profileAboutMe?.hasTexts && profileAboutMe?.ratingStatus === 'rejected') {
      backendErrors.profileAboutMe = _('profiles:textRatingStatus.rejected');
      if (profileAboutMe.rejectionReason) {
        const key = 'profiles:textRejectionReason.' + profileAboutMe.rejectionReason;
        backendErrors.profileAboutMe += ` (${_(key)})`;
      }
    }

    this.setState({
      isLoading: false,
      storeValues: newStoreValues,
      currentProfileOccupation:
        this.state.currentProfileOccupation || newStoreValues.profileOccupation,
      currentProfileCountry: this.state.currentProfileCountry || newStoreValues.profileCountry,
      currentProfileZipCode: this.state.currentProfileZipCode || newStoreValues.profileZipCode,
      currentProfileCity: this.state.currentProfileCity || newStoreValues.profileCity,
      currentSpokenLangs: newStoreValues.profileLanguages.map((lang) => {
        for (let option of newStoreValues.formValues.profileLanguages) {
          if (option.value === lang) {
            return option;
          }
        }
      }),
      textInputLanguages: newStoreValues.textInputLanguages.map((lang) => {
        for (let option of newStoreValues.formValues.profileLanguages) {
          if (option.value === lang) {
            return option;
          }
        }
      }),
      currentProfileTags: newStoreValues.profileTags.map((tag) => {
        for (let option of newStoreValues.formValues.profileTags) {
          if (option.value === tag) {
            return option;
          }
        }
      }),
      validationErrors: backendErrors,
      isProfileAboutMeInReview,
    });
  };

  onUpdateGeoData = () => {
    const geoData = GeoServiceStore.get();
    const searchCountry = ((geoData.search && geoData.search.country) || '').toLowerCase();
    const searchPlz = (geoData.search && geoData.search.postalCode) || '';
    const foundCountry = (
      (geoData.result[0] && geoData.result[0].country && geoData.result[0].country.countryCode) ||
      ''
    ).toLowerCase();
    const foundPlz = (geoData.result[0] && geoData.result[0].postcode) || '';
    //const requestedPlz = geoData.search.country
    if (searchCountry === foundCountry && searchPlz === foundPlz) {
      this.setState({
        geoData: geoData,
        currentProfileCity: (geoData.result[0] && geoData.result[0].city) || '',
      });
    }
  };

  componentDidMount() {
    ProfileStore.addChangeListener(STORE, this.onStoreChange);
    GeoServiceStore.addChangeListener(this.onUpdateGeoData);
    ProfileActionCreators.readResource(STORE);
    GeoServiceActionCreators.readResource(
      this.state.currentProfileCity,
      this.state.currentProfileZipCode
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-next-line react/prop-types
    const hash = this.props.location.hash;
    const element = hash ? document.querySelector(hash) : null;
    if (!this.jumpedToHash && !this.state.isLoading && element) {
      scrollIntoView(element);
      this.jumpedToHash = true;
    }

    if (
      this.state.currentProfileZipCode !== prevState.currentProfileZipCode ||
      this.state.currentProfileCountry !== prevState.currentProfileCountry
    ) {
      GeoServiceActionCreators.readResource(
        this.state.currentProfileCountry || this.state.storeValues.profileCountry,
        this.state.currentProfileZipCode || this.state.storeValues.currentProfileZipCode
      );
    }
  }

  componentWillUnmount() {
    ProfileStore.removeChangeListener(STORE, this.onStoreChange);
    GeoServiceStore.removeChangeListener(this.onUpdateGeoData);
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const [{ lang }] = this.props.appState;

    return (
      <div className="grid__row">
        <div className="grid__column grid__box min-width--0">
          <div className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'profiles:common.aboutYou.pagetitle.label'} />
            </div>
          </div>

          <div className="grid__box__item">
            <div className="grid__box__item__content">
              <div className="warning-text">
                <div className="warning-text__column">
                  <div className="title">
                    <T _={'profiles:aboutYou.description.title'} />
                  </div>
                  <Markdown className="subtitle" source={_('profiles:aboutYou.description.text')} />
                </div>
              </div>
            </div>
          </div>

          <form
            id="aboutyou-form"
            action=""
            method="post"
            onSubmit={this.handleSubmit}
            className="grid__box__item spinner-container"
          >
            {this.state.isLoading && <Spinner size="l" align="top" />}

            <div
              className="grid__box__item__content about-you__formwidth"
              style={{ color: '#0b0b0b' }}
            >
              {/* USERNAME */}
              <GroupRow css={{ padding: 16 }} label={_('profiles:profileUserName.label')}>
                <InputField value={this.state.session.userName || ''} readOnly={true} />
              </GroupRow>

              {/* GENDER */}
              <GroupRow
                id="gender"
                css={{ padding: 16 }}
                label={_('profiles:modelGender.label')}
                className={`${this.state.validationErrors.modelGender ? 'error' : ''}`}
                errorMessage={this.state.validationErrors.modelGender}
              >
                <div>
                  {this.state.storeValues.formValues.modelGender.map((gender, i) => (
                    <RadioButton
                      key={gender.value}
                      name="modelGender"
                      value={gender.value}
                      css={{ flex: 1, marginLeft: i > 0 ? 16 : 0 }}
                      defaultChecked={gender.value === this.state.storeValues.modelGender}
                      disabled={this.state.storeValues.isGenderDisabled}
                    >
                      <span className={`icon-gender-${gender.value}`} />
                      <span style={{ marginLeft: 4 }}>
                        <T _={gender.label} />
                      </span>
                    </RadioButton>
                  ))}
                </div>
              </GroupRow>

              {/* SEXUALITY */}
              <GroupRow
                id="sexuality"
                css={{ padding: 16 }}
                label={_('profiles:profileSexuality.label')}
                className={`${this.state.validationErrors.profileSexuality ? 'error' : ''}`}
                errorMessage={this.state.validationErrors.profileSexuality}
              >
                <Select
                  value={this.state.storeValues.profileSexuality}
                  name="profileSexuality"
                  options={translateOptions(this.state.storeValues.formValues.profileSexuality)}
                  onChange={this.handleProfileSexualityChange}
                  placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                />
              </GroupRow>

              {/* SPOKEN LANGUAGES */}
              <GroupRow
                id="languages"
                css={{ padding: 16 }}
                label={_('profiles:profileLanguages.label')}
                tooltipPosition="right"
                className={`${this.state.validationErrors.profileLanguages ? 'error' : ''}`}
                errorMessage={this.state.validationErrors.profileLanguages}
              >
                <LanguagesPicker
                  placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                  name="profileLanguages"
                  allOptions={translateOptions(this.state.storeValues.formValues.profileLanguages)}
                  currentOptions={translateOptions(this.state.currentSpokenLangs)}
                  addOption={this.addOption}
                  removeOption={this.removeOption}
                  disabled={this.state.storeValues.isProfileLanguagesDisabled}
                />
              </GroupRow>

              {/* PROFILE ABOUT ME  */}
              {this.state.textInputLanguages.length > 0 && (
                <GroupRow
                  id="aboutme"
                  css={{ padding: 16 }}
                  label={_('profiles:profileAboutMe.label')}
                  tooltipPosition="right"
                  className={`${this.state.validationErrors.profileAboutMe ? 'error' : ''}`}
                  errorMessage={this.state.validationErrors.profileAboutMe}
                >
                  <DeepLTextArea
                    name="profileAboutMe"
                    lang={lang}
                    initialValues={this.state.storeValues.profileAboutMe.texts}
                    translated={this.state.storeValues.profileAboutMe.texts['translated']}
                    maxLength={1000}
                    placeholder={_('profiles:profileAboutMe.placeholder')}
                    className={css({ flex: 'auto' })}
                  >
                    {(props) => <TextareaField {...props} roundedUpperLeftCorner={false} />}
                  </DeepLTextArea>

                  {this.state.isProfileAboutMeInReview && <InReviewMessage />}
                </GroupRow>
              )}

              {/* PROFILE TAGS  */}
              {this.state.storeValues.formValues.profileTags.length > 0 && (
                <GroupRow
                  id="tags"
                  css={{ padding: 16 }}
                  label={_('profiles:profileTags.label')}
                  className={`${this.state.validationErrors.profileTags ? 'error' : ''}`}
                  errorMessage={this.state.validationErrors.profileTags}
                >
                  <AttributesPicker
                    name="profileTags"
                    allOptions={this.state.storeValues.formValues.profileTags}
                    currentOptions={this.state.currentProfileTags}
                    addOption={this.addTag}
                    removeOption={this.removeTag}
                    translator={_}
                  />
                </GroupRow>
              )}

              {/* PROFILE CHARACTERISTICS */}
              <GroupRow
                id="characteristics"
                css={{ padding: 16 }}
                label={_('profiles:profileCharacteristics.label')}
                tooltipPosition="right"
                className={`${this.state.validationErrors.profileCharacteristics ? 'error' : ''}`}
                errorMessage={this.state.validationErrors.profileCharacteristics}
              >
                {this.state.storeValues.formValues.profileCharacteristics.map(
                  (group, groupIndex) => (
                    <div key={'group_' + groupIndex} style={{ margin: '8px 0' }}>
                      {Object.keys(group).map((member, memberIndex) => (
                        <Fragment key={'separator_' + groupIndex + '_' + memberIndex}>
                          <RadioButton
                            label={_(group[member])}
                            key={'profileCharacteristics_' + groupIndex + '_' + memberIndex}
                            name={'profileCharacteristics_' + groupIndex}
                            value={member}
                            css={{ flex: 1 }}
                            defaultChecked={this.state.storeValues.profileCharacteristics.includes(
                              member
                            )}
                          />
                          {memberIndex === 0 && (
                            <div style={{ padding: '0 8px', alignItems: 'center' }}>oder</div>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  )
                )}
              </GroupRow>

              {/* RELATIONSHIP STATE */}
              <GroupRow
                id="relationship"
                css={{ padding: 16 }}
                label={_('profiles:profileRelationshipState.label')}
              >
                <Select
                  value={this.state.storeValues.profileRelationshipState}
                  name="profileRelationshipState"
                  options={translateOptions(
                    this.state.storeValues.formValues.profileRelationshipState
                  )}
                  onChange={this.handleProfileRelationshipChange}
                  placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                />
              </GroupRow>

              {/* BIRTHDAY */}
              <GroupRow
                css={{ padding: 16 }}
                id="birthdate"
                label={_('profiles:modelBirthdate.label')}
                tooltipPosition="right"
                className={`${this.state.validationErrors.modelBirthdate ? 'error' : ''}`}
                errorMessage={this.state.validationErrors.modelBirthdate}
              >
                <DatePicker
                  name="modelBirthdate"
                  initialValue={this.state.storeValues.modelBirthdate}
                  disabled={this.state.storeValues.isBirthdayDisabled}
                />
              </GroupRow>

              {/* OCUPATION */}
              <GroupRow
                id="occupation"
                css={{ padding: 16 }}
                label={_('profiles:profileOccupation.label')}
              >
                <InputField
                  type="text"
                  name="profileOccupation"
                  value={this.state.currentProfileOccupation}
                  onChange={this.handleCurrentProfileOccupationChange}
                  placeholder={_('profiles:profileOccupation.placeholder')}
                />
              </GroupRow>

              {/* COUNTRY */}
              <GroupRow
                id="country"
                css={{ padding: 16 }}
                label={_('profiles:profileCountry.label')}
                tooltipPosition="right"
                className={`${this.state.validationErrors.profileCountry ? 'error' : ''}`}
                errorMessage={this.state.validationErrors.profileCountry}
              >
                <Select
                  value={this.state.storeValues.profileCountry}
                  name="profileCountry"
                  placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                  options={sortOptions(
                    translateOptions(this.state.storeValues.formValues.profileCountry),
                    true
                  )}
                  onChange={this.handleProfileCountryChange}
                />
              </GroupRow>

              {/* ZIP CODE & CITY */}
              {this.state.currentProfileCountry && (
                <GroupRow
                  id="city"
                  css={{ padding: 16 }}
                  label={`${_('profiles:profileZipCode.label')} - ${_(
                    'profiles:profilePlaceOfResidence.label'
                  )}`}
                  className={`${
                    this.state.validationErrors.profileZipCode ||
                    this.state.validationErrors.profileCity
                      ? 'error'
                      : ''
                  }`}
                  errorMessage={
                    this.state.validationErrors.profileZipCode ||
                    this.state.validationErrors.profileCity
                  }
                >
                  <div style={{ alignItems: 'center' }}>
                    <InputField
                      css={{ marginRight: 8 }}
                      type="text"
                      name="profileZipCode"
                      value={this.state.currentProfileZipCode}
                      onChange={this.handleProfileZipCodeChange}
                      placeholder={_('profiles:profileZipCode.label')}
                    />
                    {' - '}
                    <InputField
                      css={{ marginLeft: 8 }}
                      type="text"
                      name="profileCity"
                      value={this.state.currentProfileCity}
                      onChange={this.handleCurrentProfileCityChange}
                      placeholder={_('profiles:profileCity.label')}
                    />
                  </div>
                </GroupRow>
              )}

              {/* MAP */}
              {isEU(
                document.querySelector('[name="profileCountry"]') &&
                  document.querySelector('[name="profileCountry"]').value
              ) && (
                <GroupRow
                  id="map"
                  css={{ padding: 16 }}
                  label={_('profiles:profileGeotag.label')}
                  type="list"
                >
                  <GeoMap geoData={this.state.geoData} />
                </GroupRow>
              )}

              {/* FORM BUTONS */}
              <div style={{ margin: '32px 0', justifyContent: 'flex-end' }}>
                <Button type="submit" theme="blue" label={_('common:button.save')} />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withApollo(withAppState(AboutYou));
