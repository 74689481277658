import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getFormattedDuration } from '../../../../util/Formatter';
import FancyTable from '../../../../components/FancyTable/FancyTable';
import MediaSaleOverview from '../../../../components/MediaSaleOverview/MediaSaleOverview';
import Spinner from '../../../../components/Spinner/Spinner';
import { _ } from '../../../../util/translate';
import { QUERY_MODEL_VIDEOS_ALBUM } from '../../../../graphql/VXModels/queries';
import { useQuery } from '@apollo/react-hooks';
import { EmptyContent } from '../../../../components';

const sortVideoSales = (data, field, direction) => {
  switch (field) {
    case 'guestlogin':
      data.sort((a, b) => {
        let a_ = a[field].toLowerCase(),
          b_ = b[field].toLowerCase();

        if (a_ < b_) return direction === 'asc' ? -1 : 1;
        else if (a_ > b_) return direction === 'asc' ? 1 : -1;
        return 0;
      });
      break;
    case 'date':
      data.sort((a, b) => {
        if (direction === 'asc') return Date.parse(a[field]) - Date.parse(b[field]);
        else return Date.parse(b[field]) - Date.parse(a[field]);
      });
      break;
    case 'guestAmount':
    case 'hostAmount':
      data.sort((a, b) => {
        if (direction === 'asc') return a[field] - b[field];
        else return b[field] - a[field];
      });
      break;
    default:
      break;
  }
};

const VideoSales = ({ umaId }) => {
  const { loading, error, data } = useQuery(QUERY_MODEL_VIDEOS_ALBUM, {
    variables: {
      id: umaId,
    },
  });

  const videoData = data?.model?.videos?.albums?.[0];
  const salesList = videoData?.salesItems;
  const videoLength = videoData?.video.duration;
  const originalFilename = videoData?.video?.originalFilename;
  const previewUrl16 = videoData?.previewPicture16?.url;
  const previewUrl18 = videoData?.previewPicture18?.url;
  const salesStatItems = videoData?.salesStatItems;
  const title = videoData?.video?.title;

  const previewURL = previewUrl18 || previewUrl16 || '';
  const contentTitle = title || originalFilename || '';

  const videoDuration = `${_('mediamanagement:video.lengthLabel')}: ${getFormattedDuration(
    videoLength
  )}`;

  return loading ? (
    <Spinner />
  ) : !!error ? (
    <EmptyContent icon="icon-warning-sign" title={_('common:error.generic')} />
  ) : (
    <div className="grid__box__sub__item__content">
      <div className="mediamanagement__video__sales__info">
        <MediaSaleOverview
          coverImageURL={previewURL}
          title={contentTitle}
          subtitle={videoDuration}
          totalSalesLabel={_('mediamanagement:album.sales.table.sale')}
          netLabel={_('mediamanagement:album.sales.table.net')}
          salesStatItems={salesStatItems}
        />
      </div>

      <FancyTable
        styles={{ margin: 0 }}
        rowsData={salesList}
        columnFields={[
          {
            name: 'guestlogin',
            label: _('mediamanagement:album.sales.head.username'),
            width: 200,
            sortable: true,
          },
          {
            name: 'date',
            label: _('mediamanagement:album.sales.head.salesDate'),
            width: 200,
            sortable: true,
          },
          {
            name: 'guestAmount',
            label: _('mediamanagement:album.sales.head.guestPrice'),
            width: 200,
            sortable: true,
          },
          {
            name: 'hostAmount',
            label: _('mediamanagement:album.sales.head.hostPrice'),
            width: 200,
            sortable: true,
          },
        ]}
        sortCallback={sortVideoSales}
      />
    </div>
  );
};

VideoSales.propTypes = {
  data: PropTypes.object,
  umaId: PropTypes.number.isRequired,
};

VideoSales.defaultProps = {
  data: {},
};

export default VideoSales;
