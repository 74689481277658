import React, { FC } from 'react';
import { RedirectProps } from 'react-router';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { GRAY_2, RED } from '../../../../../camtool-styles';
import { useLeftMenuRootContext } from '../context';
import { APP_BASE_PATH } from '../../../../../util/env';

const StyledNavLink = styled(NavLink)`
  color: ${GRAY_2};
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  font-size: 12px;
  line-height: 17px;
  font-family: 'Roboto Condensed', sans-serif;

  &:hover {
    color: white;
  }

  &.active {
    font-weight: bold;
    color: ${RED} !important;
  }
`;

const FooterNavLink: FC<Omit<RedirectProps, 'onClick'>> = ({ to, ...props }) => {
  const { closeAllGroups } = useLeftMenuRootContext();

  return <StyledNavLink {...props} to={`${APP_BASE_PATH}${to}`} onClick={closeAllGroups} />;
};

export default FooterNavLink;
