import { EventEmitter } from 'events';
import * as ActionTypes from '../../util/constants/ActionTypes';
import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import cloneDeep from 'lodash/cloneDeep';
import { _ } from '../../util/translate';

const CHANGE_EVENT = 'AlertsStore.change';

let _alerts = {
  entries: [],
};

let timeouts = {};

class AlertsStoreClass extends EventEmitter {
  constructor() {
    super();
    AppDispatcher.register(payload => {
      switch (payload.type) {
        case ActionTypes.ALERTS_SHOW_DATA:
          this.add(payload.data);
          this.emitChange();
          break;
        default:
        // do nothing
      }
    });
  }

  init(data) {
    _alerts = data;
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  get() {
    return { entries: cloneDeep(_alerts.entries) };
  }

  add(data) {
    if (data.translate) {
      data.message = _(data.message);
    }

    var entry = {
      id: new Date().getTime().toString() + _alerts.entries.length.toString(),
      type: data.type || 'check', //type = [check,error,info]
      message: data.message || '',
      duration: data.duration || 3000,
    };

    _alerts.entries.unshift(entry);

    //3 Entries maximum
    if (_alerts.entries.length > 3) {
      _alerts.entries.splice(3);
    }

    this.emitChange();
    this.startRemoveTimeout(entry);
  }

  startRemoveTimeout(item) {
    if (item.duration > 0) {
      var me = this;
      timeouts[item.id] = setTimeout(function() {
        me.removeById(item.id);
      }, item.duration);
    }
  }

  removeById(id) {
    for (var e in _alerts.entries) {
      if (_alerts.entries[e].id === id) {
        _alerts.entries.splice(e, 1);
        this.emitChange();
        break; //only one item
      }
    }
  }
}

const AlertsStore = new AlertsStoreClass();

export default AlertsStore;
export { AlertsStore };
