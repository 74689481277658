import React, { FC } from 'react';
import { Main } from '../../atoms';
import { _ } from '../../util/translate';
import { ChannelDescription } from '../../packages/Telegram';

const ChannelDescriptionRoutes: FC = () => (
  <Main isMobileEnabled>
    <ChannelDescription />
  </Main>
);

export default ChannelDescriptionRoutes;
