import React from 'react';
import PropTypes from 'prop-types';

import { RED } from '../../../camtool-styles';
import styled from '@emotion/styled';
import { MediaManagementActionCreators } from '../../../stores/MediaManagement/MediaManagement';

const Image = styled.div`
  min-width: 235px;
  min-height: 192px;
  margin: 8px;
  background-image: url('${props => props.src}');
  background-position: center;
  background-size: cover;
`;

const TextContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 8px;
  padding: 8px 4px;
  color: #ffffff;
  align-items: center;
`;

const TrashButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  padding: 8px;
  font-size: 14px;
  transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
`;

const StyledItem = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    ${TrashButton} {
      color: rgba(255, 255, 255, 1);
      background-color: black;
    }
  }
`;

const Item = ({ picture, onClick }) => {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick(picture);
    }
  };

  return (
    <StyledItem onClick={handleClick}>
      <TrashButton>
        <span className="icon-bin" />
      </TrashButton>
      <Image src={picture.url} />
      <TextContainer>
        <span className="icon-ban-circle" css={{ color: RED, marginRight: 8 }} />
        <p>{picture.rejectionReason}</p>
      </TextContainer>
    </StyledItem>
  );
};

const AlbumViewProfileRejectedPictures = ({ rejectedPictures, onPictureClick }) => {
  if (rejectedPictures.length > 0) {
    return (
      <div
        className="profile-pic__view__profile__vx__foo__container"
        css={{ marginTop: 32, padding: 8, flexDirection: 'row', flexWrap: 'wrap !important' }}
      >
        {rejectedPictures.map(picture => (
          <Item picture={picture} onClick={onPictureClick} key={picture.pictureId} />
        ))}
      </div>
    );
  }

  return null;
};

AlbumViewProfileRejectedPictures.propTypes = {
  rejectedPictures: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      rejectionReason: PropTypes.string.isRequired,
    })
  ),
};

AlbumViewProfileRejectedPictures.defaultProps = { rejectedPictures: [] };

export default AlbumViewProfileRejectedPictures;
