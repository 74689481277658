/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import styled from '@emotion/styled';

import { CmsArticleRevisionList, Markdown, Spinner, T } from '../../../components';
import CmsArticle from '../../../components/CmsArticle';
import { useLang } from '../../../util/AppState';
import { ApiLang } from '../../../graphql/VXModels/types';

const QUERY_GDPR = gql`
  query QUERY_GDPR($rev: Int = 0) {
    cms {
      article(id: "gdpr", rev: $rev) {
        title
        revision {
          rev
          published
        }
        type
        content
        revisions {
          rev
          title
          published
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 12px 40px;
  > ul {
    padding-left: 24px;
  }
`;

const GDPR = ({ location }) => {
  const data = /#rev=(\d+)/.exec(location.hash);
  const rev = data && data[1] ? parseInt(data[1]) : 0;
  const [lang] = useLang();

  return (
    <div className="grid__row">
      <div className="grid__column grid__box">
        <div className="grid__box__header">
          <div className="grid__box__header__title">
            <T _={'common:legal.privacy'} />
          </div>
        </div>
        <div className="grid__box__item spinner-container">
          <div className="grid__box__item__content" style={{ minHeight: 200 }}>
            <CmsArticle
              id={'gdpr'}
              className="legal__box"
              linkTarget="_blank"
              scripts={{
                gaOptout: () => window.cookieconsent.showSettings(),
              }}
            />
            <CmsArticleRevisionList id={'gdpr'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPR;
