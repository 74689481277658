import React, { FC } from 'react';
import styled from '@emotion/styled';
import { VideoPlayer } from '../../../../components';
import { PreviewVideoOptions } from './types';

const Container = styled.div`
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 100%;
`;

interface Props {
  url: string;
  options?: PreviewVideoOptions;
  className?: string;
}

const PreviewVideoPlayer: FC<Props> = ({ url, options, className }) => {
  const autoPlay = options?.autoPlay ?? false;

  return (
    <Container>
      <InnerContainer className={className}>
        <VideoPlayer url={url} autoplay={autoPlay} />
      </InnerContainer>
    </Container>
  );
};

export default PreviewVideoPlayer;
