import React, { FC } from 'react';
import NewInputField from '../../../atoms/InputField/NewInputField';
import NewButton from '../../../atoms/Button/NewButton';
import GenericEmptyContent from '../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { ExecutionResult } from 'graphql';
import { Form, FormikErrors } from 'formik';
import { GRAY_2 } from '../../../camtool-styles';
import BuildValidationObject from '../../../util/BuildValidationObject';
import { Mutation } from '../../../graphql/VXModels/types';
import { createStyles, makeStyles } from '@material-ui/core';
import { ApolloError } from 'apollo-client';
import { MUTATION_MODEL_SENDWELCOMEMAIL } from '../../../graphql/VXModels/mutations/mutations';
import { useMutation } from '@apollo/react-hooks';
import { _ } from '../../../util/translate';

const useButtonStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      maxHeight: '32px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    resendButton: {
      fontSize: '12px',
    },
    label: {
      whiteSpace: 'nowrap',
    },
    input: {
      '& .MuiInputBase-adornedEnd': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  })
);

interface Props {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: { [email: string]: string };
  errors: FormikErrors<{ email: string }>;
  loading: boolean;
  error: ApolloError | undefined;
  responseErrorMessage: string;
  responseSuccessMessage: string;
}

const ResendEmailForm: FC<Props> = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  loading,
  error,
  responseErrorMessage,
  responseSuccessMessage,
}) => {
  const [resendVerificationEmail] = useMutation<Mutation>(MUTATION_MODEL_SENDWELCOMEMAIL); // new mutation in order to prevent updating error/success message of inputfield
  const classes = useButtonStyles();
  const validationSuccess = BuildValidationObject('secondary', responseSuccessMessage);
  const validationError = BuildValidationObject('error', responseErrorMessage);

  return (
    <Form css={{ flexDirection: 'column' }} onSubmit={handleSubmit}>
      <>
        <h3 css={{ marginBottom: '6px' }}>
          {_('register:confirmationPage.changeEmail.input.placeholder')}
        </h3>
        <div css={{ marginBottom: '5px', display: 'flex' }}>
          <NewInputField
            classes={{ root: classes.input }}
            placeholder={_('components:verifyButton.changeData.email')}
            validation={validationSuccess || validationError}
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          <NewButton
            type={'submit'}
            disabled={!!errors.email || !values.email}
            classes={{ root: classes.submitButton, label: classes.label }}
            color={'primary'}
            loading={loading}
          >
            {_('register:confirmationPage.changeEmail.button.label.submit')}
          </NewButton>
        </div>
        {!!error && <GenericEmptyContent />}
        <div
          css={{
            marginBottom: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span css={{ color: GRAY_2 }}>{_('register:confirmationPage.missingLink')}</span>
          <NewButton
            disabled={loading}
            classes={{ root: classes.resendButton }}
            variant={'text'}
            onClick={(): Promise<ExecutionResult<Mutation>> => resendVerificationEmail()}
          >
            {_('register:stepCompleteRegistration.action.sendEmailAgain')}
          </NewButton>
        </div>
      </>
    </Form>
  );
};

export default ResendEmailForm;
