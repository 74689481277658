import React, { FC, MouseEvent, useState } from 'react';
import { Box, Typography, Snackbar } from '@material-ui/core';
import {
  MUICheckbox,
  MUICheckboxFormControlLabel,
} from '../../../../../../components/MUI/Checkbox';
import { useDisplayState } from '../../../../../../hooks';
import NewButton from '../../../../../../atoms/Button/NewButton';
import { useMutation } from '@apollo/react-hooks';
import {
  Mutation,
  VXGChallengesisTocAcceptedArgs,
  VXGChallengeTocEnum,
} from '../../../../../../graphql/VXModels/types';
import {
  MUTATION_MODEL_VXCHALLENGE_ACCEPT_TOC,
  QUERY_MODEL_VXCHALLENGE_CHALLENGES,
} from '../../../../graphql';
import { TermsModal } from '../../CompetitionTerms/TermsModal';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

type TOCArgKeyType = keyof typeof VXGChallengeTocEnum;

const LastStepChild: FC = () => {
  const currentYear = new Date().getFullYear();
  const isTocAcceptedEnumArg = `games${currentYear}TOC` as TOCArgKeyType; // e.g. games2022TOC
  const [acceptTOC] = useMutation<Mutation, VXGChallengesisTocAcceptedArgs>(
    MUTATION_MODEL_VXCHALLENGE_ACCEPT_TOC,
    {
      variables: {
        tocName: VXGChallengeTocEnum[isTocAcceptedEnumArg] ?? `games${currentYear}TOC`,
      },
      refetchQueries: [{ query: QUERY_MODEL_VXCHALLENGE_CHALLENGES }],
    }
  );
  const [checked, setChecked] = useState(false);
  const { shouldShow, show, hide } = useDisplayState();

  const [open, setOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onShow = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    show();
  };

  const onClick = () => {
    acceptTOC();
    setOpen(true);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <>
      <Box mt={1} padding={1}>
        <Box
          display="flex"
          alignItems="center"
          py={2}
          borderTop={`1px solid #ccc`}
          borderBottom={`1px solid #ccc`}
        >
          <MUICheckboxFormControlLabel
            style={{ margin: '0 auto' }}
            color="secondary"
            checked={checked}
            control={
              <MUICheckbox
                value={checked}
                onChange={() => setChecked((prevChecked) => !prevChecked)}
              />
            }
            label={
              <Typography>
                {`Ja, ich möchte bei den VXGames ${currentYear} teilnehmen und akzeptiere die `}
                <span css={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onShow}>
                  {'Teilnahmebedingungen'}
                </span>
              </Typography>
            }
          />
        </Box>
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <NewButton disabled={!checked} onClick={onClick}>
          {'Jetzt teilnehmen'}
        </NewButton>
      </Box>
      {shouldShow && <TermsModal open={shouldShow} onClose={hide} />}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {'Erfolgreich teilgenommen!'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LastStepChild;
