import gql from 'graphql-tag';

export const QUERY_GET_ACTOR_ABOS = gql`
  query QUERY_GET_ACTOR_ABOS {
    actor {
      abos {
        id
        name
        description
        picture
        price
        priceDiscounted
        benefitInterval
        paymentInterval
        renew
        active
        trialIntervalCount
        trialPrice
        actorId
        pfmId
        creatorId
        createdAt
        deactivatedAt
        subscriptionCount
        benefits {
          id
          type {
            id
            name
            translation {
              id
              de
              en
            }
          }
          subType {
            id
            name
            translation {
              id
              de
              en
            }
          }
          singlePayout
          json
        }
      }
    }
  }
`;

export const MUTATION_CREATE_ABO = gql`
  mutation MUTATION_BUILDER_CREATE_ABO($abo: AboInput!) {
    builder {
      createAbo(abo: $abo) {
        id
        name
        nameTranslation {
          de
          en
        }
        description
        descriptionTranslation {
          de
          en
        }
        picture
        price
        priceDiscounted
        benefitInterval
        paymentInterval
        renew
        active
        trialIntervalCount
        trialPrice
        actorId
        pfmId
        creatorId
        createdAt
        deactivatedAt
        benefits {
          id
          type {
            id
            name
            translation {
              id
              de
              en
            }
          }
          subType {
            id
            name
            translation {
              id
              de
              en
            }
          }
          singlePayout
          json
        }
      }
    }
  }
`;

export const QUERY_ACTOR_INSTANCES = gql`
  query QUERY_ACTOR_INSTANCES($aboId: Int!) {
    actor {
      instances(aboId: $aboId) {
        id
        aboId
        start
        end
        status
        guestId
        guestName
        nextRefresh
        lastRefresh
        totalRefreshes
      }
    }
  }
`;

export const MUTATION_ACTOR_DEACTIVATE_ABO = gql`
  mutation MUTATION_ACTOR_DEACTIVATE_ABO($aboId: Int!) {
    actor {
      deactivateAbo(aboId: $aboId) {
        id
        active
      }
    }
  }
`;

export const MUTATION_ACTOR_CANCEL_ABO_SUBSCRIPTIONS = gql`
  mutation MUTATION_ACTOR_CANCEL_ABO_SUBSCRIPTIONS($aboId: Int!) {
    actor {
      cancelAboSubscriptions(aboId: $aboId)
    }
  }
`;

export const MUTATION_BUILDER_REQUEST_PICTURE_UPLOAD = gql`
  mutation MUTATION_BUILDER_REQUEST_PICTURE_UPLOAD {
    builder {
      requestPictureUpload
    }
  }
`;
