import React, { FC, ReactElement } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Mutation, ResponseStatus } from '../../../graphql/VXModels/types';
import { MUTATION_MODEL_SENDWELCOMEMAIL } from '../../../graphql/VXModels/mutations/mutations';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ResendEmailForm from './ResendEmailForm';
import { _ } from '../../../util/translate';

const validationSchema = Yup.object().shape({
  email: Yup.string().email(''),
});

const ResendEmailSection: FC = () => {
  const [resendVerificationEmail, { loading, data, error }] = useMutation<Mutation>(
    MUTATION_MODEL_SENDWELCOMEMAIL
  );

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={({ email }): void => {
        resendVerificationEmail({ variables: { email: email } });
      }}
    >
      {(props): ReactElement => (
        <ResendEmailForm
          loading={loading}
          error={error}
          responseErrorMessage={data?.model?.sendWelcomeMail?.error ?? ''}
          responseSuccessMessage={
            data?.model?.sendWelcomeMail.status === ResponseStatus.OK
              ? _('common:alerts.emailSendingSuccess')
              : ''
          }
          {...props}
        />
      )}
    </Formik>
  );
};

export default ResendEmailSection;
