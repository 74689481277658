import React, { FC } from 'react';
import { ErrorBox, WindowCompilation } from '../../../components/Layout/DarkCentered';

interface IProps {
  title: string;
  message: string;
}

const ErrorWindowForbidden: FC<IProps> = ({ title, message }) => (
  <WindowCompilation title={title} name={'error'}>
    <ErrorBox>{message}</ErrorBox>
  </WindowCompilation>
);

export default ErrorWindowForbidden;
