import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';
import AlbumItemDetails from '../AlbumItemDetails/AlbumItemDetails';
import AlbumItemStatus from '../AlbumItemStatus/AlbumItemStatus';
import Spinner from '../../../Spinner/Spinner';
import cx from 'classnames';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import {
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_TABLET_CONDITION,
} from '../../../../camtool-styles';

const Item = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -ms-flex: 1 1 170px;
    -webkit-flex: 1 1 170px;
    flex: 1 1 170px;
  }
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -ms-flex: 1 1 170px;
    -webkit-flex: 1 1 170px;
    flex: 1 1 100%;
  }
`;

class AlbumItem extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      isDetailsVisible: false,
      isMenuVisible: false,
    };
  }

  componentWillMount() {
    window.addEventListener('mouseup', this.onPageClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onPageClick, false);
  }

  toggleDetails() {
    this.setState({
      isMenuVisible: false,
      isDetailsVisible: !this.state.isDetailsVisible,
    });
  }

  toggleMenu() {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
      isDetailsVisible: false,
    });
  }

  onPageClick(event) {
    if (this.state.isMenuVisible) {
      this.setState({
        isMenuVisible: false,
      });
    }
  }

  onClick(event) {
    event.stopPropagation();
    if (this.props.isSelectable) {
      this.props.onClick(this.props.data);
    }
  }

  onClickDelete() {
    if (confirm(_('mediamanagement:picture.album.content.confirmDelete'))) {
      this.setState(
        {
          isDeleting: true,
          isMenuVisible: false,
          isDetailsVisible: false,
        },
        () => {
          this.props.onClickDelete({
            albumId: this.props.album.id,
            pictureId: this.props.data.pictureId,
          });
        }
      );
    }
  }

  onClickMovePictureToPos0() {
    this.props.onClickMovePictureToPos0(this.props.data);
  }

  onClickMoveToPool() {
    if (confirm(_('mediamanagement:picture.album.content.confirmRemoveFromAlbum'))) {
      this.setState(
        {
          isDeleting: true,
          isMenuVisible: false,
          isDetailsVisible: false,
        },
        () => {
          this.props.onClickMoveToPool({
            sourceAlbumId: this.props.data.album.id,
            pictures: [{ id: this.props.data.pictureId }],
          });
        }
      );
    }
  }

  onClickSetAsPreview16() {
    this.props.onClickSetAsPreview16({
      albumId: this.props.data.album.id,
      pictureId: this.props.data.pictureId,
    });
  }

  onClickSetAsPreview18() {
    this.props.onClickSetAsPreview18({
      albumId: this.props.data.album.id,
      pictureId: this.props.data.pictureId,
    });
  }

  render() {
    const {
      album,
      allowSelection,
      containerClass,
      data,
      isSelected,
      isSelectable,
      sequentialNumber,
      showDelete,
      showDetails,
      showInChecking,
      showMoveToPool,
      showMoveToPos0,
      showNotSelectable,
      showPreview,
      showSetAsPreview,
    } = this.props;
    const { isDeleting, isDetailsVisible, isMenuVisible } = this.state;

    return showNotSelectable || isSelectable ? (
      <Item
        className={cx({
          'mediamanagement__pic__album__content__item spinner-container': true,
          [containerClass]: true,
          'mediamanagement__pic__album__content__item--details': isDetailsVisible,
          'mediamanagement__pic__album__content__item--selected': isSelected,
          'mediamanagement__pic__album__content__item--selectable': isSelectable,
          'mediamanagement__pic__album__content__item--notselectable':
            allowSelection && !isSelectable,
        })}
      >
        <div className="mediamanagement__pic__album__content__item__pic" onClick={this.onClick}>
          <div
            className="mediamanagement__pic__album__content__item__pic__url"
            style={{ backgroundImage: 'url(' + data.url + ')' }}
          />
        </div>

        {isDeleting && <Spinner size="s" />}

        <AlbumItemStatus
          data={data}
          album={album}
          isMenuVisible={isMenuVisible}
          showDelete={showDelete}
          showMoveToPool={showMoveToPool}
          showDetails={showDetails}
          showPreview={showPreview}
          showInChecking={showInChecking}
          showSetAsPreview={showSetAsPreview}
          showMoveToPos0={showMoveToPos0}
          onClickMenu={this.toggleMenu}
          onClickDetails={this.toggleDetails}
          onClickDelete={this.onClickDelete}
          onClickMoveToPool={this.onClickMoveToPool}
          onClickPreview={this.onClickPreview}
          onClickMovePictureToPos0={this.onClickMovePictureToPos0}
          onClickSetAsPreview16={this.onClickSetAsPreview16}
          onClickSetAsPreview18={this.onClickSetAsPreview18}
          sequentialNumber={sequentialNumber}
        />

        {isDetailsVisible && (
          <AlbumItemDetails data={data} onClickHideDetails={this.toggleDetails} />
        )}
      </Item>
    ) : (
      <Item />
    );
  }
}

AlbumItem.propTypes = {
  data: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  showMoveToPool: PropTypes.bool,
  showCheckState: PropTypes.bool,
  showDetails: PropTypes.bool,
  showSetAsPreview: PropTypes.bool,
  allowSelection: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSelectable: PropTypes.bool,
  onClick: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickSetAsPreview16: PropTypes.func,
  onClickSetAsPreview18: PropTypes.func,
  onClickMovePictureToPos0: PropTypes.func,
  onClickMoveToPool: PropTypes.func,
};

AlbumItem.defaultProps = {
  showDelete: false,
  showMoveToPool: false,
  showCheckState: false,
  showDetails: false,
  showSetAsPreview: false,
  showNotSelectable: true,
  isSelected: false,
  isSelectable: true,
  allowSelection: false,
  onClick: () => {},
  onClickDelete: () => {},
  onClickSetAsPreview16: () => {},
  onClickSetAsPreview18: () => {},
  onClickMovePictureToPos0: () => {},
  onClickMoveToPool: () => {},
};

AlbumItem.contextTypes = {
  routeDispatcher: PropTypes.object,
};

export default AlbumItem;
export { AlbumItem };
