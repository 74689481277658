import { Override } from './types';
import { GRAY_2, WHITE_3 } from '../../../camtool-styles';

export const MuiTableCell: Override<'MuiTableCell'> = {
  root: {},
  sizeSmall: {
    padding: '8px 8px',
    fontSize: 12,
    '&:first-child': {
      paddingLeft: 16,
    },
    '&:last-child': {
      paddingRight: 16,
    },
    '&$head': {
      backgroundColor: WHITE_3,
      color: GRAY_2,
      padding: '8px 8px',
    },
    '&$head:first-child': {
      padding: '8px 8px 8px 16px',
    },
    '&$head:last-child': {
      padding: '8px 16px 8px 8px',
    },
  },
  head: {
    padding: '8px 16px',
  },
};
