import React, { FC, Fragment } from 'react';
import styled from '@emotion/styled';
import { DARK_GRAY, GRAY_2, RED } from '../../../../camtool-styles';
import { AboRoute } from '../../types';
import { _ } from '../../../../util/translate';

const Container = styled.div`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  text-transform: none;
`;

const BreadcrumbElement = styled.div`
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
`;

const Divider = styled.span`
  border-bottom: 1px solid ${GRAY_2};
  flex: 1;
`;

const BreadCrumbDot = styled.span`
  background-color: ${GRAY_2};
  color: ${DARK_GRAY};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

interface AboWizardBreadcrumbProps {
  currentPage: AboRoute;
}

interface BreadcrumbPage {
  title: string;
  route: AboRoute;
}

const Breadcrumb: FC<AboWizardBreadcrumbProps> = ({ currentPage }) => {
  const pages: BreadcrumbPage[] = [
    { title: _('benefits:abo.nav.chooseAboType'), route: AboRoute.AboContentPage },
    { title: _('benefits:abo.nav.settings'), route: AboRoute.AboRedeemPage },
    { title: _('benefits:abo.nav.details'), route: AboRoute.AboDetailsPage },
  ];

  return (
    <Container>
      {pages.map((page, index) => (
        <Fragment key={page.route}>
          <BreadcrumbElement>
            <BreadCrumbDot css={currentPage === page.route ? { backgroundColor: RED } : null}>
              {index + 1}
            </BreadCrumbDot>
            <span css={{ color: currentPage === page.route ? RED : GRAY_2 }}>{page.title}</span>
          </BreadcrumbElement>

          {index < pages.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Container>
  );
};

export default Breadcrumb;
