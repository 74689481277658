import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const StyledForm = styled.form`
  background-color: #fff;
  border: 1px solid #bcbcbc;
  border-radius: 2px;
  border-right: none;
  flex: 1;
`;

const StyledButton = styled.button`
  background-color: #bcbcbc;
  border-radius: 2px;
  border: none;
  padding: 4px;
  transition: background-color 200ms;
  &:hover {
    background-color: #e5e5e5;
  }
`;

class LikesItemRenderer extends PureComponent {
  state = { comment: '', commentCharsLeft: 120 };

  handleCommentChange = e => {
    if (e.target.value.length <= 120) {
      this.setState({ comment: e.target.value, commentCharsLeft: 120 - e.target.value.length });
    }
  };

  handleFormSubmit = e => {
    e.preventDefault();
    if (this.state.comment.length > 1) {
      console.log('COMMENT: ', this.state.comment);
    }
  };

  renderComment = () => {
    const { like } = this.props;
    if (like.comment)
      return (
        <div css={{ paddingLeft: 8 }}>
          <span className={`icon-chat ${css({ marginRight: 4 })}`} />
          <span>{like.comment}</span>
        </div>
      );

    return (
      <Fragment>
        <StyledForm onSubmit={this.handleFormSubmit}>
          <label css={{ flex: 1, alignItems: 'center', padding: '0 8px' }}>
            <span className={`icon-chat ${css({ marginRight: 4 })}`} />
            <input
              type="text"
              value={this.state.comment}
              onChange={this.handleCommentChange}
              placeholder={`Schreibe ${like.user} eine Nachricht`}
              css={{ flex: 1 }}
            />
          </label>
          <StyledButton type="submit">
            <span className={`fas fa-reply ${css({ color: '#fff' })}`} />
          </StyledButton>
        </StyledForm>

        {this.state.commentCharsLeft < 120 && (
          <span css={{ paddingLeft: 4, width: 25, justifyContent: 'center' }}>
            {this.state.commentCharsLeft}
          </span>
        )}
      </Fragment>
    );
  };

  render() {
    const { like } = this.props;

    return (
      <div
        css={{
          flexDirection: 'column',
          padding: '8px 16px 8px 0',
          justifyContent: 'flex-start',
        }}
      >
        <div css={{ alignSelf: 'stretch', marginBottom: 6 }}>
          <span className={`icon-heart ${css({ color: '#ff4d3c', paddingRight: 6 })}`} />
          <span>{like.user} gefällt Deine Story</span>
        </div>

        <div css={{ alignSelf: 'stretch', alignItems: 'center' }}>{this.renderComment()}</div>
      </div>
    );
  }
}

LikesItemRenderer.propTypes = {
  like: PropTypes.shape({
    user: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
};

export default LikesItemRenderer;
