import React, { FC } from 'react';
// Outside Wizard
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import { _ } from '../../../../../util/translate';
// Inside Wizard
import { IContentOthersProps } from '../../../types';
import useContent from '../useContent';
import Layout from '../Layout';
import Select from '../../../../../atoms/Select/Select';

const ContentCredits: FC<IContentOthersProps> = ({
  state,
  dispatch,
  className,
  availableContentMinAmountsArray,
}) => {
  const [
    { contentData, redemptionType, redemptionAmount },
    { setRedemptionType, setRedemptionAmount, setContentData },
  ] = useContent('VXPAGES_CREDITS', state, dispatch, 1000);

  const handleRedemptionTypeChange = (type: string) => {
    if (type === 'GRATIS') {
      setRedemptionType(type);
      setRedemptionAmount(0);
    } else if (type === 'ON_TOP') {
      setRedemptionType(type);
      setRedemptionAmount(-1);
    } else {
      setRedemptionType('');
      setRedemptionAmount(-1);
    }
  };

  const handleRedemptionAmountChange = (amount: string) => {
    setRedemptionAmount(Number(amount));
  };

  const handleCreditsAmountChange = (value: string) => {
    setContentData(Number(value));
  };

  return (
    <Layout
      className={className}
      title={_('benefits:voucher.vxpages_credits.title')}
      subtitle={_('benefits:voucher.vxpages_credits.subtitle')}
    >
      <FieldLayout
        label={_('benefits:voucher.texts.couponRedeemType')}
        error=""
        css={{ marginBottom: 24, flex: 0 }}
      >
        <Select
          onChange={(event) => handleRedemptionTypeChange(event.target.value)}
          options={[
            { label: _('benefits:voucher.texts.free'), value: 'GRATIS' },
            { label: _('benefits:voucher.texts.onTop'), value: 'ON_TOP' },
          ]}
          placeholder={_('benefits:voucher.texts.pleaseChoose')}
          name="voucherType"
          value={redemptionType}
        />
      </FieldLayout>

      {redemptionType === 'GRATIS' && (
        <FieldLayout
          label={_('benefits:voucher.texts.specifyAmount')}
          error=""
          css={{ flex: 0, marginBottom: 24, alignItems: 'flex-start' }}
        >
          <div css={{ flex: 0, alignItems: 'center' }}>
            <Select
              onChange={(event) => handleCreditsAmountChange(event.target.value)}
              options={[
                { label: '500', value: '500' },
                { label: '1000', value: '1000' },
                { label: '2000', value: '2000' },
                { label: '5000', value: '5000' },
              ]}
              name="creditsAmount"
              value={contentData}
              css={{ margin: 8, flex: '0 0 auto' }}
            />
            <span>{_('benefits:voucher.vxpages_credits.whatUserGets')}</span>
          </div>
        </FieldLayout>
      )}

      {redemptionType === 'ON_TOP' && (
        <div css={{ alignItems: 'center', marginBottom: 24, flex: 0 }}>
          <span>{_('benefits:voucher.texts.from')}</span>
          <Select
            onChange={(event) => handleRedemptionAmountChange(event.target.value)}
            options={availableContentMinAmountsArray || []}
            name="redemptionAmount"
            value={String(redemptionAmount)}
            css={{ maxWidth: 128, margin: 8 }}
          />
          <span>{_('benefits:voucher.texts.userGetsOnTop')}</span>
          <Select
            value={contentData}
            css={{ margin: 8, flex: '0 0 auto' }}
            onChange={(event) => handleCreditsAmountChange(event.target.value)}
            options={[
              { label: '500', value: '500' },
              { label: '1000', value: '1000' },
              { label: '2000', value: '2000' },
              { label: '5000', value: '5000' },
            ]}
            name="creditsAmount"
          />
          <span>{_('benefits:voucher.vxpages_credits.whatUserGets')}</span>
        </div>
      )}
    </Layout>
  );
};

export default ContentCredits;
