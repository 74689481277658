import styled from '@emotion/styled';
import { BLACK_1, WHITE } from '../../camtool-styles';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const IconLinkContainer = styled.div`
  display: flex;
  border: 1px solid #dcdcde;
  background-color: transparent;
  color: ${BLACK_1};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 32px;
  cursor: default;
`;

export const LinkContainer = styled.div`
  white-space: nowrap;
  padding: 7px 16px 5px 8px;
  flex: 1;
  font-size: 13.3333px;
  cursor: text;
  overflow: hidden;
  max-width: 500px;
  background: ${WHITE};
`;
