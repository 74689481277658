import { makeStyles, createStyles } from '@material-ui/core';
import { BLUE_HOVER, GRAY_3, RED, WHITE } from '../../../camtool-styles';

interface TabProps {
  error?: boolean;
}

export const useTabsStyles = makeStyles((theme) =>
  createStyles({
    flag: {
      height: '15px',
      marginTop: '6px',
      marginRight: '6px',
    },
    tabs: {
      height: '40px',
      minHeight: '0px',
    },
    indicator: {
      backgroundColor: BLUE_HOVER,
    },
    tab: {
      padding: '0px 7px',
      minHeight: '40px',
      maxWidth: 'fit-content',
      display: 'flex',
      borderLeft: `1px solid ${GRAY_3}`,
      '&:not(:first-child)': {
        borderLeft: `none`,
      },
      borderTop: `1px solid ${GRAY_3}`,
      borderRight: `1px solid ${GRAY_3}`,
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        width: '120px',
        overflow: 'hidden',
        padding: '8px',
        minWidth: '120px',
        textAlign: 'initial',
      },
    },
    selected: ({ error }: TabProps) => ({
      backgroundColor: WHITE,
      borderLeft: error ? `1px solid ${RED}` : `1px solid ${GRAY_3}`,
      borderTop: error ? `1px solid ${RED}` : `1px solid ${GRAY_3}`,
      borderBottom: error ? `1px solid ${RED}` : `1px solid ${GRAY_3}`,
      borderRight: error ? `1px solid ${RED}` : `1px solid ${GRAY_3}`,
    }),
    wrapper: {
      display: 'flex',
      flexFlow: 'row',
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: '8px',
        left: '14px',
      },
    },
  })
);
