import gql from 'graphql-tag';

export const MUTATION_MODEL_TVSHOWS_SETREPLACEMENTOFFER = gql`
  mutation MUTATION_MODEL_TVSHOWS_SETREPLACEMENTOFFER($value: Boolean!) {
    model {
      tvshows {
        setReplacementOffer(active: $value) {
          status
          data
          error
        }
      }
    }
  }
`;
