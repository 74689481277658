import { useEffect, useReducer, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { buildRestEndpoint } from '../util/apiHelper';
import { APP_IS_DEVELOPMENT } from '../util/env';
import { useAppState } from '../util/AppState';

// const debug = true;
const debug = false;

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        success: undefined,
        error: false,
      };
    case 'FETCH_SUCCESS':
      const { reducer } = action;
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: reducer(state.data, action),
      };

    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.response,
        success: false,
      };

    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    default:
      throw new Error();
  }
};

export const useVXModelsRestApi = ({
  url,
  method = 'GET',
  requestData,
  queryParams = {},
  initialData = {},
  reducer = (data, action) => action.payload,
  onSuccess,
  onFailure,
}) => {
  const defaults = { url, method, requestData, queryParams, reducer, onSuccess, onFailure };
  const [params, setParams] = useState(defaults);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: false,
    error: undefined,
    data: initialData,
  });

  const [{ authToken }] = useAppState();

  useEffect(() => {
    let didCancel = false;
    const {
      url,
      method,
      requestData,
      queryParams,
      reducer,
      onSuccess = () => {},
      onFailure = () => {},
    } = params;

    APP_IS_DEVELOPMENT &&
      debug &&
      console.log('useVXModelsRestApi', method, url, queryParams, requestData);

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const response = await fetch(buildRestEndpoint(url, queryParams), {
          method: method.toUpperCase(),
          mode: 'cors', // no-cors, cors, *same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer', // no-referrer, *client
          body: requestData ? JSON.stringify(requestData) : undefined,
        });

        const responseData = await response.json();
        if (response.ok) {
          onSuccess({ response });
          dispatch({
            type: `FETCH_SUCCESS`,
            payload: responseData,
            reducer,
            response,
            queryParams,
          });
        } else {
          onFailure({ response });
          dispatch({
            type: `FETCH_FAILURE`,
            payload: responseData,
            response,
            queryParams,
          });
        }
      } catch (error) {
        onFailure({ error });
        if (!didCancel) {
          dispatch({ type: 'FETCH_ERROR', error });
        }
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchData();

    return () => {
      didCancel = true;
    };
  }, [params]);

  const doGet = (next) => {
    const nextParams = { ...defaults, method: 'GET', ...next };
    const hasChanges = !isEqual(nextParams, params);
    hasChanges && setParams(nextParams);
  };

  const doPost = (next) => {
    const nextParams = { ...defaults, method: 'POST', ...next };
    const hasChanges = !isEqual(nextParams, params);
    hasChanges && setParams(nextParams);
  };

  const doPut = (next) => {
    const nextParams = { ...defaults, method: 'PUT', ...next };
    const hasChanges = !isEqual(nextParams, params);
    hasChanges && setParams(nextParams);
  };

  const doDelete = (next) => {
    const reducer = () => ({});
    setParams({ ...defaults, method: 'DELETE', reducer, ...next });
  };

  return { ...state, doGet, doPost, doPut, doDelete };
};
