import React from 'react';
import { Main } from '../../atoms/Grid';
import { APP_BASE_PATH } from '../../util/env';
import Button from '../../atoms/Button/Button';
import NavButton from '../../components/Routing/NavButton';
import { useAppState, ActionEnum } from '../../util/AppState';

const Debug = () => {
  const [_, dispatch] = useAppState();

  const invalidateAuthToken = () => {
    dispatch({
      type: ActionEnum.setAuthToken,
      authToken: 'invalidtoken',
    });
  };

  return (
    <Main>
      <div>
        <Button onClick={invalidateAuthToken}>invalidate auth token</Button>
        <NavButton to={APP_BASE_PATH + '/login'}>to Login</NavButton>
      </div>
    </Main>
  );
};

export default Debug;
