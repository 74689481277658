import React, { FC, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useAppState } from '../../../util/AppState';
import { MUTATION_AUTH_LOGIN_V2 } from '../../../graphql/VXModels/mutations';
import useOnSuccessfulAuthAppStateEffects from '../hooks/useOnSuccessfulAuthAppStateEffects';
import SplashScreen from '../../../components/SplashScreen';
import {
  ErrorBox,
  Form,
  Input,
  Label,
  Main,
  WindowCompilation,
} from '../../../components/Layout/DarkCentered';
import { clearAuthTokenCookieAsync } from '../../../util/cookies';
import Spinner from '../../../components/Spinner/Spinner';
import { Translation } from '../../../components';
import Button from '../../../atoms/Button/Button';
import { _ } from '../../../util/translate';
import { getStartpagePasswordResetUrl, getStartpageSignupUrl } from '../../../util/startpage';

const LoginFormView: FC = () => {
  const [{ lang }, dispatch] = useAppState();

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const [login, { error, loading, data }] = useMutation(MUTATION_AUTH_LOGIN_V2);

  const loginData = data?.auth?.login_v2;
  const wasSuccessful = loginData?.wasSuccessful;
  const loginResultType = loginData?.__typename;
  const authUserData = loginData?.user;
  const token = authUserData?.token;
  const signupLanguage = authUserData?.signupLanguage;

  useOnSuccessfulAuthAppStateEffects(dispatch, token, signupLanguage);

  return token ? (
    <SplashScreen />
  ) : (
    <Main className="spinner-container">
      <WindowCompilation name={'login-window'} title={_('common:text.logInNow')}>
        <Form
          name="login-form"
          accept-charset="UTF-8"
          onSubmit={e => {
            e.preventDefault();
            clearAuthTokenCookieAsync().then(() => {
              login({
                variables: {
                  username: usernameRef.current.value,
                  password: passwordRef.current.value,
                },
              });
            });
          }}
        >
          {wasSuccessful === false &&
            (loginResultType === 'LoginInvalidCredentialsResult' ? (
              <ErrorBox>{loginData.message}</ErrorBox>
            ) : loginResultType === 'LoginAccountLockedResult' ? (
              <ErrorBox>
                {loginData.title} {loginData.message} {_('startpage:loginlayer.hotlineCode')}:{' '}
                {loginData.hotlineCode}
              </ErrorBox>
            ) : (
              <ErrorBox>
                <Translation _="startpage:loginlayer.error" />
              </ErrorBox>
            ))}
          {error && (
            <ErrorBox>
              <Translation _="startpage:loginlayer.error" />
            </ErrorBox>
          )}
          {loading && <Spinner size={'m'} />}

          <Label htmlFor="login-username">
            <span>
              <Translation _="login2page:content.placeholderlogin" />
            </span>
            <Input
              ref={usernameRef}
              id="login-username"
              name="username"
              type="text"
              className="window__input"
            />
            <span className="validation-error" />
          </Label>
          <Label htmlFor="login-password">
            <span>{`${_('register:placeholder.label.password')} / ${_(
              'common:text.masterPassword'
            )}`}</span>
            <Input
              ref={passwordRef}
              id="login-password"
              name="password"
              type="password"
              className="window__input"
            />
            <span className="validation-error" />
          </Label>
          <Button type={'submit'} label={_('common:text.logIn')} css={{ marginTop: 8 }} />

          <Button
            label={_('login2page:content.labelforgotpw')}
            theme="link-black"
            css={{ marginTop: 4, alignSelf: 'flex-start' }}
            href={getStartpagePasswordResetUrl(lang)}
          />

          <Button
            label={_('register:button.registeraccount')}
            theme="link-black"
            css={{ marginTop: 4, alignSelf: 'flex-start' }}
            href={getStartpageSignupUrl(lang)}
          />
        </Form>
      </WindowCompilation>
    </Main>
  );
};

export default LoginFormView;
