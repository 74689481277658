import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../AbstractComponent';
import UploadQueueItem from '../UploadQueueItem/UploadQueueItem';
import UploadFTP from '../UploadFTP/UploadFTP';
import { _ } from '../../../util/translate';

class UploadQueue extends AbstractComponent {
  render() {
    const queue = this.props.queue;
    const isQueueEmpty = queue.length <= 0;
    const type = this.props.type;
    const showFtpData = type === 'video';

    const textCurrentlyUploading = _(
      'mediamanagement:multiuploader.uploadQueue.currentlyUploading'
    );
    const textType = _('mediamanagement:multiuploader.uploadQueue.title' + type);
    return (
      <div className="grid__column grid__box">
        <div className="grid__box__header">
          <div className="grid__box__header__title">
            {`${textCurrentlyUploading}: ${queue.length} ${textType}`}
          </div>
        </div>

        <div className="grid__box__row">
          <div className="grid__box__column">
            <div className="grid__box__row">
              <div className="grid__box__column mediamultiuploader__queue">
                {queue.map(item => (
                  <UploadQueueItem
                    key={item.key}
                    item={item}
                    isActive={this.isActive}
                    onClick={this.props.onClickQueueItem}
                    onClickRemove={this.props.onClickRemoveQueueItem}
                    onClickControl={this.props.onClickControlQueueItem}
                  />
                ))}
                {isQueueEmpty && (
                  <div className="mediamultiuploader__queue__empty">
                    {_('mediamanagement:multiuploader.uploadQueue.noFilesInQueue')}
                  </div>
                )}
              </div>
            </div>

            {showFtpData && <UploadFTP />}
          </div>
        </div>
      </div>
    );
  }
}

UploadQueue.propTypes = {
  type: PropTypes.string.isRequired,
  queue: PropTypes.array.isRequired,
  onClickQueueItem: PropTypes.func,
  onClickRemoveQueueItem: PropTypes.func,
  onClickControlQueueItem: PropTypes.func,
};

UploadQueue.defaultProps = {
  onClickQueueItem: () => {},
  onClickRemoveQueueItem: () => {},
  onClickControlQueueItem: () => {},
};

UploadQueue.debug = false;

export default UploadQueue;
export { UploadQueue };
