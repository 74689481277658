import { QueueFilter, QueueItem, MediaTypeEnum, ActionEnum } from '../types';
import { useEffect, useLayoutEffect, useState } from 'react';
import { UploadManagerStore } from '../store/UploadManagerStore';

/**
 * @param filter e.g. only videos
 * @param isTus are we using tus client for upload -> if yes -> we are not interested in queue items
 * with a state after upload
 * @returns only the queueItems we are interested in
 */
export const useFilteredQueue = (filter: QueueFilter): QueueItem[] => {
  const [queue, setQueue] = useState<QueueItem[]>([]);
  useLayoutEffect(() => {
    const subscription = UploadManagerStore.queueSubscribe((queue: QueueItem[] = []) => {
      setQueue(
        queue.filter(
          (item: QueueItem) =>
            item.mediaType === filter.mediaType ||
            filter.mediaType === MediaTypeEnum.all ||
            filter.mediaType === undefined
        )
      );
    });
    UploadManagerStore.queueInit();

    return () => subscription.unsubscribe();
  }, []);

  return queue;
};

export const useOnUploadFinished = (callback: () => void): void => {
  useEffect(() => {
    const subscription = UploadManagerStore.filesSubscribe((action: any) => {
      if (action.type === ActionEnum.uploadFinished) {
        callback();
      }
    });

    return () => subscription.unsubscribe();
  }, [callback]);
};
