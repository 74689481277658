import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { NumberInput, Slider } from '../../../atoms';
import { SliderProps } from '../../../atoms/MUI/Slider/Slider';
import { NumberInputProps } from '../../../atoms/MUI/NumberInput/NumberInput';

type InputSliderProps = {
  inputProps: NumberInputProps;
  sliderProps: SliderProps;
};

const InputSlider: FC<InputSliderProps> = ({ inputProps, sliderProps }) => {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <NumberInput style={{ maxWidth: 80 }} {...inputProps} />
      <Box display={{ xs: 'none', md: 'flex' }} ml={2} width="100%">
        <Slider {...sliderProps} />
      </Box>
    </Box>
  );
};

export default InputSlider;
