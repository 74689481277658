/* eslint-disable  react/prop-types*/
import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import Spinner from '../../../../components/Spinner/Spinner';
import NavSubItem from '../../../../components/NavSubItem/NavSubItem';
import { _ } from '../../../../util/translate';
import { stripSlash } from '../../../../util/urlHelper';
import {
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_PHONE_CONDITION,
} from '../../../../camtool-styles';

import VideoForm from '../../../../components/MediaManagement/ExplorerVideo/VideoForm/VideoForm';
import { useVideo } from '../../../../hooks/useMediaManagementVideos';
import resolvePathname from 'resolve-pathname';
import VideoComments from './VideoComments';
import VideoSales from './VideoSales';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const VideosWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
  margin: 0 24px 24px 24px;
  background-color: #f5f5f5;
  border-radius: 2px;
  color: #141316;

  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0 24px 24px 24px;
    > form {
      flex: 1 1 100%;
      width: 100%;
      padding: 16px;
      margin: 0;
    }
    > div {
      width: 100%;
      overflow: hidden;
    }
    .fancy-table__header__item {
      width: 160px !important;
      justify-content: center;
      padding: 10px 0;
    }
    .fancy-table__header {
      flex-wrap: wrap;
      flex: 1 1 100%;
    }
    .fancy-table__row {
      flex-wrap: wrap;
    }
  }

  @media ${BREAKPOINT_PHONE_CONDITION} {
    .fancy-table__header__item {
      width: 100% !important;
    }
  }
`;

const Wrapper = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const URL_EDIT = '/edit';
const URL_SALES = '/sales';
const URL_COMMENTS = '/comments';

const View = memo(({ match }) => {
  const path = stripSlash(match.url);
  const indexRoute = path + URL_EDIT;
  const { umaId } = match.params;
  const { data, loading, error, success, deleteVideo, updateVideo, reloadVideo } = useVideo({
    umaId,
  });
  const { albumType: collection, firstTitle: title } = data;
  const isDataEmpty = success && isEmpty(data);

  if (isDataEmpty) {
    return <Redirect to={resolvePathname('.', path)} />;
  }

  const showSales = collection === 'shop' || collection === 'contest';

  const handleFormSubmit = (data) => updateVideo({ umaId, data });

  return (
    <Wrapper className="grid__box__column spinner-container">
      {loading && <Spinner />}
      <Wrapper className="mediamanagement__video__edit__nav">
        <NavSubItem link={path + URL_EDIT} label={_('mediamanagement:video.nav.edit')} />
        <NavSubItem link={path + URL_COMMENTS} label={_('mediamanagement:video.nav.reviews')} />
        {showSales && (
          <NavSubItem link={path + URL_SALES} label={_('mediamanagement:video.nav.sales')} />
        )}
      </Wrapper>

      <VideosWrapper>
        <Switch>
          <Redirect exact from={`${path}/`} to={indexRoute} />
          <Route
            exact
            path={path + URL_EDIT}
            component={() => (
              <VideoForm
                className="grid__box__sub__item__content"
                data={data}
                onSubmit={handleFormSubmit}
                onDelete={() => deleteVideo({ umaId })}
                onReset={() => reloadVideo({ umaId })}
                isLoading={loading}
              />
            )}
          />
          <Route
            exact
            path={path + URL_COMMENTS}
            component={() => (data ? <VideoComments umaId={Number(umaId)} /> : false)}
          />
          <Route
            exact
            path={path + URL_SALES}
            component={() => (data ? <VideoSales umaId={Number(umaId)} /> : false)}
          />
          <Redirect to={indexRoute} />
        </Switch>
      </VideosWrapper>
    </Wrapper>
  );
});

View.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      umaId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  }),
};

export default View;
