import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { step1 } from '.';

const FirstStepChild: FC = () => {
  return (
    <Box display="flex" justifyContent="center" mt={2}>
      <img css={{ maxWidth: '100%' }} src={step1} />
    </Box>
  );
};

export default FirstStepChild;
