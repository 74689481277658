import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ClassNames } from '@emotion/core';

import { T, ProgressCircle } from '../../../../components';
import { APP_BASE_PATH } from '../../../../util/env';
import { _ } from '../../../../util/translate';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profiles: any;
}

const Profiles: FC<Props> = ({ profiles }) => (
  <div className="grid__row">
    <div className="grid__column grid__box">
      <div className="grid__box__header profiles-overview__header">
        <div className="grid__box__header__title profiles-overview__header__text">
          <T _={'profiles:overview.profiles.heading'} />
        </div>
      </div>

      <article className="grid__box__row profiles-overview">
        <div className="profiles-overview__content">
          <div className="profiles-overview__grid__header">
            <h2>
              <T _={'profiles:overview.profilesHeading'} />
            </h2>
          </div>

          <ClassNames>
            {({ cx, css }) => (
              <div className="profiles-overview__profiles__charts">
                <Link to={`${APP_BASE_PATH}/onlineprofile/common/aboutyou`}>
                  <ProgressCircle
                    containerClass={cx(
                      'profiles-overview__profiles__charts__item',
                      css({ '&:hover': { opacity: 0.6 } })
                    )}
                    doubleChart
                    width={270}
                    height={270}
                    data={
                      profiles.common
                        ? [profiles.common.filledItems, profiles.common.totalItems]
                        : [0, 0]
                    }
                    label={profiles.common ? _(profiles.common.profileNameTranslationKey) : ''}
                    warning={
                      profiles.common && !profiles.common.areRequiredFieldsCompleted
                        ? _('profiles:overview.warning')
                        : ''
                    }
                    data2={
                      profiles.partner && profiles.partner.isProfileActive
                        ? [profiles.partner.filledItems, profiles.partner.totalItems]
                        : null
                    }
                    label2={profiles.partner ? _(profiles.partner.profileNameTranslationKey) : ''}
                    warning2={
                      profiles.partner && !profiles.partner.areRequiredFieldsCompleted
                        ? _('profiles:overview.warning')
                        : ''
                    }
                  />
                </Link>

                <Link to={`${APP_BASE_PATH}/onlineprofile/visitx/common`}>
                  <ProgressCircle
                    containerClass={cx(
                      'profiles-overview__profiles__charts__item',
                      css({ '&:hover': { opacity: 0.6 } })
                    )}
                    doubleChart
                    width={270}
                    height={270}
                    data={
                      profiles.visitx
                        ? [profiles.visitx.filledItems, profiles.visitx.totalItems]
                        : [0, 0]
                    }
                    label={profiles.visitx ? _(profiles.visitx.profileNameTranslationKey) : ''}
                    warning={
                      profiles.visitx && !profiles.visitx.areRequiredFieldsCompleted
                        ? _('profiles:overview.warning')
                        : ''
                    }
                    data2={
                      profiles.fetish && profiles.fetish.isProfileActive
                        ? [profiles.fetish.filledItems, profiles.fetish.totalItems]
                        : null
                    }
                    label2={profiles.fetish ? _(profiles.fetish.profileNameTranslationKey) : ''}
                    warning2={
                      profiles.fetish && !profiles.fetish.areRequiredFieldsCompleted
                        ? _('profiles:overview.warning')
                        : ''
                    }
                  />
                </Link>

                {profiles.lustagenten && profiles.lustagenten.isProfileActive ? (
                  <Link to={`${APP_BASE_PATH}/onlineprofile/lustagenten/settings`}>
                    <ProgressCircle
                      containerClass={cx(
                        'profiles-overview__profiles__charts__item',
                        css({ '&:hover': { opacity: 0.6 } })
                      )}
                      doubleChart
                      width={270}
                      height={270}
                      data={
                        profiles.lustagenten
                          ? [profiles.lustagenten.filledItems, profiles.lustagenten.totalItems]
                          : [0, 0]
                      }
                      label={
                        profiles.lustagenten
                          ? _(profiles.lustagenten.profileNameTranslationKey)
                          : ''
                      }
                      warning={
                        profiles.lustagenten && !profiles.lustagenten.areRequiredFieldsCompleted
                          ? _('profiles:overview.warning')
                          : ''
                      }
                    />
                  </Link>
                ) : null}
              </div>
            )}
          </ClassNames>

          <p className="profiles-overview__profiles__note">
            <T _={'profiles:overview.description'} />
          </p>
        </div>
      </article>
    </div>
  </div>
);

export default Profiles;
