import React from 'react';
import PropTypes from 'prop-types';
import Camera from '../../../Camera/Camera';
import AbstractComponent from '../../../AbstractComponent';
import { PICTURE_TYPES } from '../../../../stores/MediaManagement/MediaManagement';
import BigIconButton from '../../../BigIconButton/BigIconButton';
import Modalbox from '../../../Modalbox/Modalbox';
import MediaSelector from '../../../MediaSelector/MediaSelector';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION } from '../../../../camtool-styles';

const Container = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
`;

class AlbumAdd extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  onClick(event) {
    event.stopPropagation();
    this.setState({ showModal: true });
  }

  onClickModalClose(event) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({ showModal: false });
  }

  onModalClose() {
    Camera.resetCamera();
  }

  render() {
    const title = this.props.title || _('mediamanagement:picfolder.contentItemAdd.title');

    const {
      editor,
      targetUmaId,
      targetParams,
      pictureType,
      onSubmit,
      allowMultipleSelection,
      picturePoolFilter,
    } = this.props;

    const { showModal } = this.state;

    return (
      <Container
        className={`mediamanagement__pic__album__content__itemadd ${this.props.containerClass}`}
      >
        <BigIconButton
          label={title}
          info={_('mediamanagement:picfolder.contentItemAdd.subtitle')}
          iconMain="icon-disk-open"
          iconLeft="icon-picture"
          iconRight="icon-camera"
          onClick={this.onClick}
        />

        <Modalbox
          name="modalsingleuploader"
          state={showModal}
          onKeyPressESC={this.onClickModalClose}
          onClose={this.onModalClose}
          title={title}
        >
          <MediaSelector
            type="picture"
            initialTab="pool"
            editor={editor}
            targetUmaId={targetUmaId}
            targetParams={targetParams}
            pictureType={pictureType}
            onClose={this.onClickModalClose}
            onSubmit={onSubmit}
            allowMultipleSelection={allowMultipleSelection}
            picturePoolFilter={picturePoolFilter}
          />
        </Modalbox>
      </Container>
    );
  }
}

AlbumAdd.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  targetUmaId: PropTypes.number.isRequired,
  targetParams: PropTypes.object,
  pictureType: PropTypes.oneOf(PICTURE_TYPES).isRequired,
  title: PropTypes.string,
  editor: PropTypes.oneOf(['circle', 'landscape_16_9', 'landscape_4_3', 'landscape_25_10']),
  picturePoolFilter: PropTypes.func,
};

AlbumAdd.defaultProps = {
  title: '',
  editor: null,
  targetParams: {},
  picturePoolFilter: (picture) => {
    return !picture.isRejected;
  },
};

export default AlbumAdd;
export { AlbumAdd };
