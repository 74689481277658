import { ITableColumn, TableColumnAlignmentEnum } from './types';
import { CSSObject } from '@emotion/core';

const DEFAULT_ALIGNMENT = TableColumnAlignmentEnum.start;

const getTableColumnWidth = ({ width = 1 }: ITableColumn) => width;

const getTableTotalWidth = (columns: ITableColumn[]) =>
  columns.reduce((total: number, column: ITableColumn) => total + getTableColumnWidth(column), 0);

const getTableColumnPadding = (column: ITableColumn): string => {
  const { horizontalPadding = 8 } = column;
  const { horizontalPaddingStart = horizontalPadding, horizontalPaddingEnd = horizontalPadding } =
    column;

  return `0 ${horizontalPaddingEnd}px 0 ${horizontalPaddingStart}px`;
};

const getTableColumnFlexBasis = (columns: ITableColumn[], index: number): string =>
  `${(getTableColumnWidth(columns[index]) / getTableTotalWidth(columns)) * 100}%`;

const getTableColumnAlignment = ({ align = DEFAULT_ALIGNMENT }: ITableColumn): string =>
  align === TableColumnAlignmentEnum.center ? align : `flex-${align}`;

const getTableColumnTextAlignment = ({
  align = DEFAULT_ALIGNMENT,
}: ITableColumn): TableColumnAlignmentEnum => align;

export const getTableColumnStyles = (
  columns: ITableColumn[],
  index: number,
  header: boolean
): CSSObject => {
  const column = columns[index];
  const columnAlignment = getTableColumnAlignment(column);

  return {
    ...(header ? { justifyContent: columnAlignment } : { alignItems: columnAlignment }),
    textAlign: getTableColumnTextAlignment(column),
    flexGrow: 0,
    flexShrink: 1, // Browser width calculations are sometimes off by a few pixels. This makes the row fit.
    flexBasis: getTableColumnFlexBasis(columns, index),
    padding: getTableColumnPadding(column),
    position: 'relative',
  };
};
