import React, { Fragment } from 'react';
import { ClassNames } from '@emotion/core';

import AbstractProfile from '../../AbstractProfile';
import { PROFILE_PARTNER } from '../../../../stores/Profile/Profile';

import { T } from '../../../../components';

import Range from '../../../../components/Form/Range/Range';
import Spinner from '../../../../components/Spinner/Spinner';
import OptionSexSelect from '../../../../components/Form/SingleOption/OptionSexSelect/OptionSexSelect';
import Dropdown from '../../../../components/Form/SingleOption/Dropdown/Dropdown';
import OptionBoxRadio from '../../../../components/Form/SingleOption/OptionBoxRadio/OptionBoxRadio';
import OptionBoxCheckbox from '../../../../components/Form/MultiOptions/OptionBoxCheckbox/OptionBoxCheckbox';
import DateSelectorDropdown from '../../../../components/Form/Date/DateSelectorDropdown/DateSelectorDropdown';
import GroupColumn from '../../../../components/GroupColumn/GroupColumn';
import GroupRow from '../../../../components/GroupRow/GroupRow';
import Form from '../../../../components/Form/Form';
import Markdown from '../../../../components/Markdown/Markdown';
import { Button } from '../../../../atoms';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Separator from '../../../../components/Separator/Separator';
import { withAppState } from '../../../../util/AppState';
import { _ } from '../../../../util/translate';

class Partners extends AbstractProfile {
  constructor(props) {
    super(props);
    this.onChangeCoupleState = this.onChangeCoupleState.bind(this);
  }

  getResource() {
    return PROFILE_PARTNER;
  }

  getInputNames() {
    return ['partnerBirthdate'];
  }

  onChangeCoupleState(event) {
    event.stopPropagation();
    if (event.target.value === 'solo') {
      this.submitForm({ profileCoupleState: 'solo' });
    }
    this.forceUpdate();
  }

  onChangeGender(event) {
    event.stopPropagation();
    this.currentValues.partnerGender = event.target.value;
    this.forceUpdate();
  }

  onChangeHairLength(event) {
    event.stopPropagation();
    this.currentValues.partnerHairLength = event.target.value;
    this.forceUpdate();
  }

  render() {
    const currentValues = this.currentValues;
    this.showPartnerForm = currentValues.profileCoupleState === 'couple';
    this.showHairColor = !(
      currentValues.partnerHairLength === '' || currentValues.partnerHairLength === 'baldHead'
    );
    this.showFemaleTransFields =
      currentValues.partnerGender === 'female' || currentValues.partnerGender === 'transgender';
    this.showMaleTransFields =
      currentValues.partnerGender === 'male' || currentValues.partnerGender === 'transgender';

    const [{ lang }] = this.props.appState;

    return (
      <section className="grid__row">
        <section className="grid__column grid__box min-width--0">
          <header className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'profiles:common.partner.pagetitle.label'} />
            </div>
          </header>

          {!this.showPartnerFormVisible && (
            <div className="grid__box__item">
              <div className="grid__box__item__content">
                <div className="warning-text">
                  <div className="warning-text__column">
                    <div className="title">
                      <T _={'profiles:partnerCoupleState.title'} />
                    </div>
                    <Markdown
                      className="subtitle"
                      source={_('profiles:partnerCoupleState.description')}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <Form
            className="grid__box__item spinner-container"
            ref="form"
            onValidSubmit={this.submitForm}
            onInvalidSubmit={this.showErrorFields}
            onValid={this.onValid}
            onInvalid={this.onInvalid}
            onChange={this.onChange}
          >
            {this.state.isLoading && <Spinner align="top" />}

            <div className="grid__box__item__content profile-partners__formwidth">
              <GroupRow
                id="coupleState"
                label={`${_('profiles:profile.coupleState.label')}:`}
                tooltipText={_('profiles:profile.coupleState.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('profileCoupleState') ? 'error' : ''}
              >
                <OptionBoxRadio
                  required
                  name="profileCoupleState"
                  value={this.storeValues.profileCoupleState}
                  options={this.storeValues.formValues.profileCoupleState}
                  iconPrefix="icon-"
                  onChange={this.onChangeCoupleState}
                />
              </GroupRow>

              {/*  Separator  */}
              <GroupRow tooltip="none">
                <Separator className="transparent" />
              </GroupRow>

              {this.showPartnerForm && (
                <Fragment>
                  <GroupRow
                    id="partnerGender"
                    label={_('profiles:partnerGender.label')}
                    tooltipText={_('profiles:partnerGender.tooltipText')}
                    tooltipPosition="right"
                    className={this.hasInputError('partnerGender') ? 'error' : ''}
                    errorMessage={this.state.displayErrorInputs['partnerGender']}
                  >
                    <OptionSexSelect
                      required
                      requiredMessage={_('profiles:partnerGender.requiredMessage')}
                      name="partnerGender"
                      value={this.storeValues.partnerGender}
                      options={this.storeValues.formValues.partnerGender}
                      onChange={this.onChangeGender}
                    />
                  </GroupRow>

                  <ClassNames>
                    {({ css }) => (
                      <GroupColumn
                        id="partnerBirthDate"
                        label={_('profiles:partnerBirthdate.label')}
                        labelClass={css({ marginLeft: '0 !important' })}
                        tooltipText={_('profiles:partner.birthdate.tooltipText')}
                        tooltipPosition="right"
                        className={this.hasInputError('partnerBirthdate') ? 'error' : ''}
                        errorMessage={this.state.displayErrorInputs['partnerBirthdate']}
                      >
                        <DateSelectorDropdown
                          required
                          requiredMessage={_('profiles:partnerBirthdate.requiredMessage')}
                          validations="isValidDate,isBeforePeriod:P18Y"
                          validationErrors={{
                            isValidDate: _('common:validationMessage.isValidDate'),
                            isBeforePeriod: _('profiles:partnerBirthdate.validationMessage.minAge'),
                          }}
                          name="partnerBirthdate"
                          value={this.storeValues.partnerBirthdate}
                          yearOffset={18}
                          maxYears={80}
                        />

                        {/* Zodiac date={this.currentValues.partnerBirthdate} /> */}
                      </GroupColumn>
                    )}
                  </ClassNames>

                  {/*  Erscheinungsbild : fuer Alle  */}
                  <GroupRow
                    id="partnerAppearance"
                    label={`${_('profiles:partnerAppearance.label')}:`}
                    tooltipText={_('profiles:partnerAppearance.tooltipText')}
                    tooltipPosition="right"
                  >
                    <Dropdown
                      name="partnerAppearance"
                      value={this.storeValues.partnerAppearance}
                      options={this.storeValues.formValues.partnerAppearance}
                      placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                    />
                  </GroupRow>

                  {/*  Hauttyp : fuer Alle  */}
                  <GroupRow
                    id="partnerSkinType"
                    label={`${_('profiles:partnerSkinType.label')}:`}
                    tooltipText={_('profiles:partnerSkinType.tooltipText')}
                    tooltipPosition="right"
                  >
                    <Dropdown
                      name="partnerSkinType"
                      value={this.storeValues.partnerSkinType}
                      options={this.storeValues.formValues.partnerSkinType}
                      placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                    />
                  </GroupRow>

                  {/*  Figur : fuer Alle  */}
                  <GroupRow
                    id="partnerFigure"
                    label={`${_('profiles:partnerFigure.label')}:`}
                    tooltipText={_('profiles:partnerFigure.tooltipText')}
                    tooltipPosition="right"
                  >
                    <Dropdown
                      name="partnerFigure"
                      value={this.storeValues.partnerFigure}
                      options={this.storeValues.formValues.partnerFigure}
                      placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                    />
                  </GroupRow>

                  {/*  Koerpergroesse : fuer Alle  */}
                  <GroupRow
                    id="partnerBodySize"
                    label={`${_('profiles:partnerBodySize.label')}:`}
                    tooltipText={_('profiles:partnerBodySize.tooltipText')}
                    tooltipPosition="right"
                    className={this.hasInputError('partnerHeight') ? 'error' : ''}
                  >
                    <Range
                      required
                      requiredMessage={_('common:formRequiredMessage.common')}
                      name="partnerHeight"
                      value={this.storeValues.partnerHeight}
                      range={{ min: 1.3, max: 2.2 }}
                      step={0.01}
                      unit="m"
                      displayTransform={Range.displayTransform.getToDecimalTransform(lang)}
                    />
                  </GroupRow>

                  {/*  Koerpergewicht : fuer Alle  */}
                  <GroupRow
                    id="partnerBodyWeight"
                    label={`${_('profiles:partnerBodyWeight.label')}:`}
                    tooltipText={_('profiles:partnerBodyWeight.tooltipText')}
                    tooltipPosition="right"
                    className={this.hasInputError('partnerWeight') ? 'error' : ''}
                  >
                    <Range
                      required
                      requiredMessage={_('common:formRequiredMessage.common')}
                      name="partnerWeight"
                      value={this.storeValues.partnerWeight}
                      range={{ min: 45, max: 220 }}
                      step={1}
                      unit="kg"
                    />
                  </GroupRow>

                  {/*  Separator  */}
                  <GroupRow tooltip="none">
                    <Separator className="transparent" />
                  </GroupRow>

                  {/*  Haarlaenge : fuer Alle  */}
                  <GroupRow
                    id="partnerHairLength"
                    label={`${_('profiles:partnerHairLength.label')}:`}
                    tooltipText={_('profiles:partnerHairLength.tooltipText')}
                    tooltipPosition="right"
                  >
                    <Dropdown
                      name="partnerHairLength"
                      value={this.storeValues.partnerHairLength}
                      options={this.storeValues.formValues.partnerHairLength}
                      placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                      onChange={this.onChangeHairLength}
                    />
                  </GroupRow>

                  {/*  Haarfarbe : fuer Alle  */}
                  {this.currentValues.partnerHairLength !== 'baldHead' && (
                    <GroupRow
                      id="partnerHairColor"
                      label={`${_('profiles:partnerHairColor.label')}:`}
                      tooltipText={_('profiles:partnerHairColor.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxRadio
                        className="option-haircolorselect-std"
                        name="partnerHairColor"
                        value={this.storeValues.partnerHairColor}
                        options={this.storeValues.formValues.partnerHairColor}
                        iconPrefix="icon-"
                      />
                    </GroupRow>
                  )}

                  {/*  Separator  */}
                  <GroupRow tooltip="none">
                    <Separator className="transparent" />
                  </GroupRow>

                  {/*  Augenfarbe : fuer Alle  */}
                  <GroupRow
                    id="partnerEyeColor"
                    label={`${_('profiles:partnerEyeColor.label')}:`}
                    tooltipText={_('profiles:partnerEyeColor.tooltipText')}
                    tooltipPosition="right"
                  >
                    <OptionBoxRadio
                      className="option-eyecolorselect-std"
                      name="partnerEyeColor"
                      value={this.storeValues.partnerEyeColor}
                      options={this.storeValues.formValues.partnerEyeColor}
                      iconPrefix="icon-"
                    />
                  </GroupRow>

                  {/*  Separator  */}
                  <GroupRow tooltip="none">
                    <Separator className="transparent" />
                  </GroupRow>

                  {/*  Koerbchengroesse : fuer Female only  */}
                  {this.showFemaleTransFields && (
                    <GroupRow
                      id="partnerPubicHair"
                      label={`${_('profiles:partnerPubicHair.label')}:`}
                      tooltipText={_('profiles:partnerPubicHair.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxRadio
                        name="partnerPubicHair"
                        value={this.storeValues.partnerPubicHair}
                        options={this.storeValues.formValues.partnerPubicHair}
                      />
                    </GroupRow>
                  )}

                  {/*  Separator  */}
                  {this.showFemaleTransFields && (
                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>
                  )}

                  {/*  Koerbchengroesse : fuer Female only  */}
                  {this.showFemaleTransFields && (
                    <GroupRow
                      id="partnerBreastSize"
                      label={`${_('profiles:partnerBreastSize.label')}:`}
                      tooltipText={_('profiles:partnerBreastSize.tooltipText')}
                      tooltipPosition="right"
                      className={this.hasInputError('partnerBreastSize') ? 'error' : ''}
                      errorMessage={this.state.displayErrorInputs['partnerBreastSize']}
                    >
                      <OptionBoxRadio
                        required
                        requiredMessage={_('common:formRequiredMessage.common')}
                        name="partnerBreastSize"
                        options={this.storeValues.formValues.partnerBreastSize}
                        value={this.storeValues.partnerBreastSize}
                      />
                    </GroupRow>
                  )}

                  {/*  Separator  */}
                  {this.showFemaleTransFields && (
                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>
                  )}

                  {/*  Koerperbehaarung : fuer Male und Transsexuell only  */}
                  {this.showMaleTransFields && (
                    <GroupRow
                      id="partnerHairness"
                      label={`${_('profiles:model.hairness.label')}:`}
                      tooltipText={_('profiles:partnerHairness.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxRadio
                        name="partnerHairness"
                        value={this.storeValues.partnerHairness}
                        forceTranslate={true}
                        options={this.storeValues.formValues.partnerHairness}
                      />
                    </GroupRow>
                  )}

                  {/*  Separator  */}
                  {this.showMaleTransFields && (
                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>
                  )}

                  {/*  Penisgroesse : fuer Male und Transsexuell only  */}
                  {this.showMaleTransFields && (
                    <GroupRow
                      id="partnerPenisSize"
                      label={`${_('profiles:model.penisSize.label')}:`}
                      tooltipText={_('profiles:partnerPenisSize.tooltipText')}
                      tooltipPosition="right"
                      className={this.hasInputError('partnerPenisSize') ? 'error' : ''}
                      errorMessage={this.state.displayErrorInputs['partnerPenisSize']}
                    >
                      <OptionBoxRadio
                        required
                        requiredMessage={_('common:formRequiredMessage.common')}
                        name="partnerPenisSize"
                        value={this.storeValues.partnerPenisSize}
                        forceTranslate={true}
                        options={this.storeValues.formValues.partnerPenisSize}
                      />
                    </GroupRow>
                  )}

                  {/*  Separator  */}
                  {this.showMaleTransFields && (
                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>
                  )}

                  {/*  Koerperschmuck : fuer Alle  */}
                  <GroupRow
                    id="partnerBodyModification"
                    label={`${_('profiles:model.bodyModification.label')}:`}
                    tooltipText={_('profiles:partnerBodyModification.tooltipText')}
                    tooltipPosition="right"
                  >
                    <OptionBoxCheckbox
                      name="partnerBodyModification"
                      value={this.storeValues.partnerBodyModification}
                      forceTranslate={true}
                      options={this.storeValues.formValues.partnerBodyModification}
                    />
                  </GroupRow>

                  {/*  Separator  */}
                  <GroupRow tooltip="none">
                    <Separator className="transparent" />
                  </GroupRow>
                  <ButtonGroup position="right">
                    <Button
                      theme="gray"
                      label={_('common:button.cancel')}
                      onClick={this.rejectChanges}
                      disabled={!this.state.hasChanges}
                      css={{ marginRight: 16 }}
                    />
                    <Button type="submit" theme="blue" label={_('common:button.save')} />
                  </ButtonGroup>
                </Fragment>
              )}
            </div>
          </Form>
        </section>
      </section>
    );
  }
}

export default withAppState(Partners);
