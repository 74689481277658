import React, { FC, ReactElement } from 'react';
import { _ } from '../../../../../util/translate';
import { Field, FieldProps } from 'formik';
import Select from '../../../../../atoms/Select/Select';
import { ProfileSexualityEnum, Service0900 } from '../../../../../graphql/VXModels/types';

const BA6SexualOrientation: FC = () => {
  return (
    <div css={{ marginBottom: '24px', width: '100%', flexDirection: 'column' }}>
      <h2 css={{ fontSize: '16px' }}>{_('profiles:profile.sexuality.label')}</h2>
      <Field name="settings.sexuality">
        {({ field, form: { errors } }: FieldProps<Service0900>): ReactElement => (
          <Select
            css={{ width: '100%', marginTop: '8px' }}
            hasError={!!errors?.settings?.sexuality}
            options={[
              {
                label: _('enum:profile.sexuality.heterosexual'),
                value: ProfileSexualityEnum.heterosexual,
              },
              {
                label: _('enum:profile.sexuality.homosexual'),
                value: ProfileSexualityEnum.homosexual,
              },
              {
                label: _('enum:profile.sexuality.bisexual'),
                value: ProfileSexualityEnum.bisexual,
              },
            ]}
            {...field}
            placeholder={_('common:dropdown.placeholder.pleaseSelect')}
          />
        )}
      </Field>
    </div>
  );
};

export default BA6SexualOrientation;
