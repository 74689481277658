import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import AbstractComponent from '../../AbstractComponent';
import { RED, GREEN, BLUE, MAGENTA, DARK_GRAY } from '../../../camtool-styles';
import { _ } from '../../../util/translate';

const STATUS_CLASS_MAPPING = {
  queued: 'waiting',
  transcoding: 'transcoding',
  run: 'active',
  file_ref_error: 'error',
  auth_error: 'error',
  network_error: 'error',
  response_error: 'error',
  duplicate_error: 'error',
  monochrome_error: 'error',
  read_error: 'error',
  picture_size_error: 'error',
  file_type_error: 'error',
  undef: 'error',
  complete: 'done',
};

const COLORS = {
  error: RED,
  done: GREEN,
  active: BLUE,
  transcoding: MAGENTA,
  waiting: DARK_GRAY,
};

class UploadQueueItem extends AbstractComponent {
  onClickControl(event) {
    event.stopPropagation();
    this.props.onClickControl(this.props.item);
  }

  onClickItem(event) {
    event.stopPropagation();
    this.props.onClick(this.props.item);
  }

  onClickRemove(event) {
    event.stopPropagation();
    this.props.onClickRemove(this.props.item);
  }

  render() {
    this.status = STATUS_CLASS_MAPPING[this.props.item.state] || 'waiting';
    this.progress = parseInt(this.props.item.uploadProgress * 10000) / 100;
    this.infoText = _(
      `mediamanagement:multiuploader.uploadQueueItem.tooltipText_${this.props.item.state}`
    );
    this.isClickable = this.props.item.state === 'done';
    const progressStyle = (100 - this.progress).toString() + '%';
    const isControlDisabled = this.props.isActive && this.status !== 'active';
    const isRemoveDisabled = this.props.item.state === 'transcoding';

    return (
      <div css={{ flexDirection: 'column' }}>
        <div
          className={`mediamultiuploader__queue__item mediamultiuploader__queue__item--${this.status}`}
          onClick={this.onClickItem}
        >
          <div className="mediamultiuploader__queue__item__icon" />
          <div
            className="mediamultiuploader__queue__item__content"
            css={{
              borderColor: COLORS[STATUS_CLASS_MAPPING[this.props.item.state]],
              backgroundColor:
                STATUS_CLASS_MAPPING[this.props.item.state] === 'error'
                  ? 'rgb(255,201,196)'
                  : 'transparent',
            }}
          >
            <div className="mediamultiuploader__queue__item__content__row">
              <div className="mediamultiuploader__queue__item__name">
                {this.props.item.state === 'run' ? (
                  <span>
                    {this.props.item.fileName} ({this.progress}%)
                  </span>
                ) : (
                  <span>{this.props.item.fileName} </span>
                )}

                <div className="mediamultiuploader__queue__item__name__progressbar" />
                <div
                  className="mediamultiuploader__queue__item__name__progress"
                  style={{ right: progressStyle }}
                />
              </div>
              <div
                className={cx({
                  mediamultiuploader__queue__item__control: true,
                  'mediamultiuploader__queue__item--disabled': isControlDisabled,
                })}
                onClick={this.onClickControl}
              />
              <div
                className={cx({
                  mediamultiuploader__queue__item__remove: true,
                  'mediamultiuploader__queue__item--disabled': isRemoveDisabled,
                })}
                onClick={this.onClickRemove}
              />
            </div>
          </div>
        </div>
        <div
          css={{
            padding: '0 4px',
            fontWeight: 'bold',
            color: COLORS[STATUS_CLASS_MAPPING[this.props.item.state]],
          }}
        >
          {this.infoText}
        </div>
      </div>
    );
  }
}

UploadQueueItem.propTypes = {
  item: PropTypes.shape({
    state: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  onClickControl: PropTypes.func,
  onClickRemove: PropTypes.func,
};

UploadQueueItem.defaultProps = {
  isActive: false,
  onClick: () => {},
  onClickControl: () => {},
  onClickRemove: () => {},
};

UploadQueueItem.debug = false;

export default UploadQueueItem;
export { UploadQueueItem };
