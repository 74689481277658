import React, { FC } from 'react';
import styled from '@emotion/styled';
import Icon from '../../../../atoms/Icon/Icon';
import { videoThumbnail } from '../assets';
import { play } from '../../../../atoms/Icon/libraries/svg';
import { PreviewVideoThumbnailIconSize } from './types';

const Thumbnail = styled.div<Pick<Props, 'url'>>`
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  background-image: url(${(props): string => props.url ?? videoThumbnail});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  background-blend-mode: overlay;
  transition: 0.25s background-color;

  &:hover {
    background-color: #707070;
  }
`;

const PlayIcon = styled(Icon)`
  filter: drop-shadow(0 1px 8px #000000cc);
`;

const getIconPixelSize = (size: PreviewVideoThumbnailIconSize): number => {
  switch (size) {
    case PreviewVideoThumbnailIconSize.md:
      return 57;
    case PreviewVideoThumbnailIconSize.sm:
      return 40;
  }
};

interface Props {
  iconSize: PreviewVideoThumbnailIconSize;
  url?: string;
  className?: string;
}

const PreviewVideoThumbnail: FC<Props> = ({ url, iconSize, className }) => (
  <Thumbnail url={url} className={className}>
    <PlayIcon icon={play} size={`${getIconPixelSize(iconSize)}px`} />
  </Thumbnail>
);

export default PreviewVideoThumbnail;
