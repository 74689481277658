import gql from 'graphql-tag';

export const FRAGMENT_AUTH_USER = gql`
    fragment AuthUser on AuthUser {
        # FRAGMENT_AUTH_USER
        id
        username
        screenname
        scope
        roles
        token
        ts
        exp
        extra
        hotlineCode
        signupLanguage        
    }
`;

export const FRAGMENT_NOTIFICATION_ITEMS = gql`
    fragment NotificationItems on Notification {
        # FRAGMENT_NOTIFICATION_ITEMS
        id
        type {
            name
            category {
                name
            }
            meta {
                icon
                iconStyle
                action {
                    link
                }
                ribbon {
                    hide
                    color
                }
                picture {
                    de
                    en
                }
                button {
                    label
                    href
                    target
                }
            }
            deletable
        }
        group
        role
        title
        text
        date
        params
        priority
        read
    }
`;

export const FRAGMENT_MAILING = gql`
    fragment Mailing on Mailing {
        id
        name
        type
        status
        progress
        editable
        content {
            __typename
            ... on MailingContentEmailPort25 {
                subject
                text
                textHtml
                lang
                template
            }
            ... on MailingContentInstantMessenger {
                lang
                text
                attachment {
                    type
                    payload
                    url
                    meta {
                        filename
                        type
                        size
                        hash
                        previewUrl
                    }
                }
            }
        }
        shipmentRecipientsCount
        recipientsGroups {
            options {
                label
                value
            }
            selected
        }
        recipientsFilters {
            options {
                label
                value
            }
            selected
        }
        price
        created
        modified
        published
        isTipping
        isSticky
    }
`;

export const FRAGMENT_VIDEO_PREVIEW = gql`
    fragment VideoPreview on MediaAlbum {
        created
        discountStart
        discountEnd
        discountPercent
        feedbacksTotal
        id
        mediaTypeName
        price
        salesTotal
        salesTotalVX
        salesAmountVX
        titles {
            language
            text
        }
        video {
            previewPicture {
                isAllowedToSetAsPreview16
                isAllowedToSetAsPreview18
                url
            }
            status
            url
        }
    }
`;

export const FRAGMENT_ALBUM_PREVIEW_PICTURES = gql`
    fragment AlbumPreviewPictures on MediaAlbum {
        previewPicture16 {
            url(size: w320)
        }
        previewPicture18 {
            url(size: w320)
        }
    }
`;

export const FRAGMENT_ALBUM_SALES_DATA = gql`
    fragment AlbumSalesData on MediaAlbum {
        salesTotal
        salesItems {
            date
            guestId
            guestlogin
            guestAmount
            hostAmount
            credits
            isProductVXPages
            isTypeVoucher
        }
        salesStatItems {
            product
            salesTotal
            amountTotal
        }
        #deprecated
        salesTotalVX
        #deprecated
        salesAmountVX
    }
`;

export const FRAGMENT_MEDIA_ALBUM = gql`
    fragment MediaAlbumItem on MediaAlbum {
        numberOfAllPictures
        numberOfRejectedPictures
        numberOfReleasedPictures
        numberOfUncheckedPictures
        #         rating
        #         title
        hasFlag365daysCalendar
        allIDCardsUploaded
        allModelReleaseFormsUploaded
        albumType: mediaTypeName
        descriptions {
            language
            text
        }
        isDeletable
        id
        isDeletable
        maxPrice
        mediaType
        numberOfAlbumDislikes: dislikes
        numberOfAlbumLikes: likes
        status
        tags
        totalFeedbacks: feedbacksTotal
        unansweredFeedbacks: feedbacksNew
        uploaded: created
        price
        rejectionReason
        tags
        feedbacks: feedbacksItems {
            id
            date
            answerText: answer
            feedback: text
            answerId: guestId
            login: guestLogin
            isAnswered
        }
        titles {
            language
            text
        }
        pictures {
            album {
                albumType: mediaTypeName
                id
            }
            fsk: ageRating
            height
            isChecked
            pictureId: id
            pictureType: type
            position
            rejectionReason
            status
            uploaded: created
            url
            isAllowedToSetAsPreview16
            isAllowedToSetAsPreview18
            isMainProfilePicture
            isPreviewPicture
            isTitleProfilePicture
            isSocial
        }
        contest {
            id
            uploadLimit
            isUploadActive
        }
        ...AlbumPreviewPictures
        ...AlbumSalesData
    }
    ${FRAGMENT_ALBUM_PREVIEW_PICTURES}
    ${FRAGMENT_ALBUM_SALES_DATA}
`;

export const FRAGMENT_CMS_ARTICLE = gql`
    fragment CmsArticle on CmsArticle {
        id
        title
        revision {
            rev
            published
            pdfUrl
        }
        type
        content
        revisions {
            rev
            title
            published
        }
    }
`;

export const FRAGMENT_TERM_ACCEPTANCE = gql`
    fragment TermAcceptance on TermAcceptance {
        type
        hasAccepted
        hasAcceptedAnyVersion
        consentRequired
        isPasswordRequired
        cmsArticle {
            ...CmsArticle
        }
    }

    ${FRAGMENT_CMS_ARTICLE}
`;

export const FRAGMENT_LOCALIZED_TEXT = gql`
    fragment LocalizedText on LocalizedText {
        language
        text
    }
`;

export const FRAGMENT_AVATAR = gql`
    fragment Avatar on Avatar {
        album {
            id
        }
        picture {
            id
            status
            url
            isChecked
        }
    }
`;

export const FRAGMENT_PROFILE_PREVIEW_PICTURE = gql`
    fragment PreviewPicture on PreviewPicture {
        album {
            id
        }
        picture {
            id
            status
            url
            isChecked
        }
    }
`;

export const FRAGMENT_BA6_CAROUSEL_DATA = gql`
    fragment Service0900PhoneCarouselNumber on Service0900PhoneCarouselNumber {
        id
        countryCallingCode
        phoneNumber
        fullPhoneNumber
    }
`;

export const FRAGMENT_SERVICE_0900_TARGETNUMBER_MUTATIONRESULT = gql`
    fragment Service0900PhoneCarouselMutationResult on Service0900PhoneCarouselMutationResult {
        data {
            targetNumbers {
                ...Service0900PhoneCarouselNumber
            }
        }
        errorMessage
    }
    ${FRAGMENT_BA6_CAROUSEL_DATA}
`;

export const FRAGMENT_BA6_DATA = gql`
    fragment Service0900TargetNumber on Service0900TargetNumber {
        isLocked
        countryCallingCode
        phoneNumber
        verificationStatus
    }
`;

export const FRAGMENT_BA6DATA_MUTATIONRESULT = gql`
    fragment Service0900TargetNumberMutationResult on Service0900TargetNumberMutationResult {
        data {
            ...Service0900TargetNumber
        }
        errorMessage
    }
    ${FRAGMENT_BA6_DATA}
`;

export const FRAGMENT_GENERIC_RESPONSE = gql`
    fragment GenericResponse on GenericResponse {
        status
        data
        error
    }
`;