import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Markdown from '../../components/Markdown/Markdown';
import { _ } from '../../util/translate';

class FAQList extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ show: !this.state.show });
  }

  render() {
    const { show } = this.state;
    return (
      <article className="faq">
        <section>
          <header className={classNames({ show: show, hide: !show })} onClick={this.toggle}>
            <div />
            <div>{this.props.label}</div>
          </header>
          <article>
            <section>
              <article className="question">
                <div>{_('help:faq.question.label')}</div>
                <div>
                  <Markdown className="faq__markdown" source={this.props.question} />
                </div>
              </article>
              <article className="answer">
                <div>{_('help:faq.answer.label')}</div>
                <div>
                  <Markdown className="faq__markdown" source={this.props.answer} />
                </div>
              </article>
            </section>
          </article>
        </section>
      </article>
    );
  }
}

FAQList.propTypes = {
  label: PropTypes.string,
  question: PropTypes.string,
  answer: PropTypes.string,
};

FAQList.defaultProps = {
  label: '',
  question: '',
  answer: '',
};

export default FAQList;
