import React, { FC } from 'react';
import { Popover as MUIPopover, PopoverProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    paper: ({ style }: Partial<PopoverProps>) => {
      return {
        boxShadow: '0px 3px 5px rgba(0,0,0,.2)',
        borderRadius: 0,
        minWidth: 260,
        padding: 20,
        flexDirection: 'column',
        ...style,
      };
    },
  })
);

const Popover: FC<PopoverProps> = ({ style, ...props }) => {
  const classes = useStyles({ style });
  return (
    <MUIPopover
      classes={classes}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      {...props}
    />
  );
};

export default Popover;
