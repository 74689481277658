import { useMutation } from '@apollo/react-hooks';
import { AlertsStore } from '../../../stores/Alerts/AlertsStore';
import { _ } from '../../../util/translate';
import { MUTATION_MASTER_USERLIFECYCLE_SETSTEP } from '../graphql/mutations/masterUserLifeCycle';
import { useVXServicesTelegramClient } from '../../../graphql';

export const useTelegramSendAutoMail = () => {
  return useMutation(MUTATION_MASTER_USERLIFECYCLE_SETSTEP, {
    client: useVXServicesTelegramClient(),
    update: (proxyfefe, mutationResult) => {
      if (mutationResult.data?.master?.userLifeCycle?.setStep.success === false) {
        AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
      } else {
        AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
      }
    },
    /*onCompleted() {
      AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
    },*/
    onError() {
      AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
    },
  });
};

export default useTelegramSendAutoMail;
