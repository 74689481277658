import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Markdown from '../../../../components/Markdown/Markdown';
import { T } from '../../../../components';
import { Heading, Ul, Li } from '../styles';
import Spinner from '../../../../components/Spinner/Spinner';
import LinkBox from '../../../../components/LinkBox/LinkBox';
import { APP_BASE_PATH } from '../../../../util/env';
import { _ } from '../../../../util/translate';
import { Section } from '../../../../atoms';
import { Box } from '@material-ui/core';
import { VideoDiv } from '../../Register/VXCashAdvantages';
import { useMedia } from 'react-media';
import { BREAKPOINT_PHONE_CONDITION } from '../../../../camtool-styles';

interface VisitXProps {
  link: string;
  dataLoading: boolean;
}

const VisitX: FC<VisitXProps> = ({ link = '', dataLoading = false }) => {
  const isMobile = useMedia({ query: BREAKPOINT_PHONE_CONDITION });

  const iframeSizeProps = {
    width: isMobile ? '100%' : '510',
    height: isMobile ? '' : '286',
  };

  return (
    <Section title={_('vxcash:inviteGuest.vx.heading0')} styles={{ padding: '16px' }}>
      <Box position="relative">
        {dataLoading && <Spinner />}
        <Box display="flex" flexDirection="column">
          {/* 1 */}
          <div css={{ flexDirection: 'column' }}>
            <Link to={`${APP_BASE_PATH}/vxcash/statistics`} style={{ textDecoration: 'none' }}>
              <Heading className="invite-user__heading">
                <T _={'vxcash:inviteGuest.vx.heading1'} />
              </Heading>
            </Link>

            <Markdown css={{ p: { padding: 0 } }} source={_('vxcash:inviteGuest.vx.intro')} />
          </div>

          {/* 2 */}
          <div css={{ padding: '32px 0', flexWrap: 'wrap', gap: '10px' }}>
            <VideoDiv>
              <iframe
                src="https://player.vimeo.com/video/260942411"
                {...iframeSizeProps}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </VideoDiv>

            <Box>
              <div css={{ flexDirection: 'column' }}>
                <Heading>
                  <T _={'vxcash:inviteGuest.vx.heading2'} />
                </Heading>
                <Ul>
                  <Li>
                    <T _={'vxcash:inviteGuest.vx.bulletPoint1'} />
                  </Li>
                  <Li>
                    <T _={'vxcash:inviteGuest.vx.bulletPoint2'} />
                  </Li>
                  <Li>
                    <T _={'vxcash:inviteGuest.vx.bulletPoint3'} />
                  </Li>
                </Ul>
              </div>

              <div>
                {link && (
                  <LinkBox
                    iconColor="#fff"
                    iconBgColor="#ff4d3c"
                    iconText="VX"
                    link={link}
                    label={_('vxcash:inviteGuest.vx.linkBoxLabel')}
                    buttonLabelCopy={_('common:text.linkCopy')}
                    buttonLabelCopied={_('common:text.linkCopied')}
                  />
                )}
              </div>
            </Box>
          </div>

          {/* 3 */}
          <div>
            <Markdown>{_('vxcash:inviteGuest.vx.description')}</Markdown>
          </div>
        </Box>
      </Box>
    </Section>
  );
};

export default VisitX;
