import { ReArrangeMarksArrayFn } from './';

export const reArrangeMarksArray: ReArrangeMarksArrayFn = (isMobile, marks, value) => {
  const newMarksArray = [];
  if (isMobile) {
    const markLength = marks.length;
    const overMarkArrayLength = marks.filter((mark) => mark.value >= value).length;
    const indexToGrab = markLength - overMarkArrayLength;

    newMarksArray.push(marks[indexToGrab]);
    newMarksArray.push(marks.slice(-1)[0]);

    return newMarksArray;
  } else {
    return marks;
  }
};
