import { QueryHookOptions, useMutation } from '@apollo/react-hooks';
import { Mutation } from '../../../graphql/VXServicesTelegram/types';
import { MUTATION_MASTER_GET_BOT_PIN } from '../graphql/mutations';
import { useVXServicesTelegramContext } from './index';
import { useVXServicesTelegramClient } from '../../../graphql';

export const useTelegramBotPin = (options?: QueryHookOptions<Mutation>) => {
  const { token } = useVXServicesTelegramContext();
  const variables = { token };
  return useMutation(MUTATION_MASTER_GET_BOT_PIN, {
    ...options,
    client: useVXServicesTelegramClient(),
    variables,
    update: (mutationResult) => {
      return mutationResult;
    },
  });
};

export default useTelegramBotPin;
