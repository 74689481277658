import React, { FC } from 'react';
import { MediaFeedback, Maybe, GroupedLikes } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';
import { user } from '../../../atoms/Icon/libraries/glyph';
import UserAvatar from '../../../atoms/UserAvatar/UserAvatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { Avatar, Box } from '@material-ui/core';

interface Props {
  likes: number;
  feedbackComments: Maybe<MediaFeedback>[];
  groupedLikes: GroupedLikes[];
  type: 'photo' | 'video';
}

const LikesDetails: FC<Props> = ({ likes, groupedLikes, type }) => {
  const calculateNameDisplay = (): string => {
    const firstThreeFans = groupedLikes.length;
    const leftoverLikes = _('mediamanagement:comments.likes.overview', {
      sprintf: [likes - groupedLikes.length],
    });
    switch (firstThreeFans) {
      case 1:
        return `${groupedLikes[0]?.userName} ${leftoverLikes}`;
      case 2:
        return `${groupedLikes[0]?.userName}, ${groupedLikes[1]?.userName} ${leftoverLikes}`;
      case 3:
        return `${groupedLikes[0]?.userName}, ${groupedLikes[1]?.userName}, ${groupedLikes[2]?.userName} ${leftoverLikes}`;
      default:
        return '';
    }
  };

  return groupedLikes?.length > 0 ? (
    <div css={{ marginBottom: '30px' }}>
      <AvatarGroup max={3}>
        {groupedLikes.map((liker) => (
          <UserAvatar key={liker.userId} src={liker.avatarUrl ?? user} />
        ))}
      </AvatarGroup>
      {likes > 3 && (
        <Box ml={-1}>
          <Avatar>
            <small>{`+ ${likes - groupedLikes.length}`}</small>
          </Avatar>
        </Box>
      )}
      <div css={{ flexDirection: 'column', marginLeft: '5px', justifyContent: 'center' }}>
        <span css={{ fontWeight: 'bold' }}>{calculateNameDisplay()}</span>
        <span>{_(`mediamanagement:video.comments.likes.${type}`)}</span>
      </div>
    </div>
  ) : null;
};

export default LikesDetails;
