import React, {FC} from 'react';
import {Button} from '../index';
import {APP_IS_DEVELOPMENT} from '../../util/env';
import {ButtonProps} from '../Button/Button';
import {useHistory} from 'react-router-dom';

const DEBUG = APP_IS_DEVELOPMENT && false;

interface Props extends ButtonProps {
    to: string;
}

const ButtonLink: FC<Props> = ({to, theme, onClick, children, ...rest}) => {
    const history = useHistory();

    function handleClick(
        e: React.MouseEvent<HTMLButtonElement>,
        to?: string,
        onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    ): void {
        if (typeof onClick === 'function') onClick(e);
        if (to) return history.push(to);
    }

    return (
        <Button
            {...rest}
            theme={theme}
            onClick={(e): void => {
                DEBUG && console.log('<ButtonLink> to', to);
                return handleClick(e, to, onClick);
            }}
        >
            {children}
        </Button>
    );
};

export default ButtonLink;
