import React from 'react';

import NavSub from '../../../components/NavSub/NavSub';
import NavSubItem from '../../../components/NavSubItem/NavSubItem';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';

class NavSubLA extends React.Component {
  render() {
    return (
      <NavSub>
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/lustagenten/common`}
          icon="icon-nameplate"
          label={_('profiles:la.navigation.profile')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/lustagenten/profilepic`}
          icon="icon-pictures"
          label={_('profiles:la.navigation.pictures')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/lustagenten/automails`}
          icon="icon-envelope"
          label={_('profiles:la.navigation.automails')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/onlineprofile/lustagenten/settings`}
          icon="icon-settings"
          label={_('profiles:la.navigation.settings')}
        />
      </NavSub>
    );
  }
}

export default NavSubLA;
export { NavSubLA };
