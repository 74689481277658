import * as Yup from 'yup';
import { _ } from '../../../../util/translate';

export const getVoucherValidationSchema = (username: string) =>
  Yup.object().shape({
    name: Yup.object({
      en: Yup.string(),
      de: Yup.string().when('en', {
        is: (descriptionEN) => descriptionEN.length < 1,
        then: Yup.string().required(_('benefits:voucher.detailsPage.formErrors.nameRequired')),
        otherwise: Yup.string().min(0),
      }),
    }),
    description: Yup.object({ de: Yup.string(), en: Yup.string() }),
    code: Yup.string()
      .trim()
      .matches(/^[A-Za-z0-9-]+$/, _('benefits:voucher.detailsPage.formErrors.codeInvalid_1'))
      .test(
        'has-prefix',
        _('benefits:voucher.detailsPage.formErrors.codeInvalid_4', {
          sprintf: [`"${username.toUpperCase()}-"`],
        }),
        function (value) {
          return value.toUpperCase().startsWith(`${username.toUpperCase()}-`);
        }
      )
      .test(
        'has-postfix',
        _('benefits:voucher.detailsPage.formErrors.codeInvalid_2'),
        (value) => !value.endsWith('-')
      )
      .max(24, _('benefits:voucher.detailsPage.formErrors.codeInvalid_3'))
      .required(_('benefits:voucher.detailsPage.formErrors.codeRequired')),
  });
