import React, { FC, useMemo, ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { MobileUserLifeCycleStep } from '../types';
import { Box, FormControlLabel, RadioGroup } from '@material-ui/core';
import { NewRadioButton } from '../../../../../atoms';
import { getDayPartOptions } from '../utils';

const AutoMailGridFormItemDelayUnit: FC = () => {
  const dayParts = useMemo(() => getDayPartOptions(), []);

  return (
    <Box display="flex" justifyContent="center" p={2}>
      <Field name="delaySchedule">
        {({ field }: FieldProps<Pick<MobileUserLifeCycleStep, 'delaySchedule'>>): ReactElement => {
          return (
            <RadioGroup {...field} row>
              {dayParts.map((dayPart, index) => (
                <FormControlLabel
                  key={index}
                  value={dayPart.value}
                  control={<NewRadioButton />}
                  label={dayPart.label}
                />
              ))}
            </RadioGroup>
          );
        }}
      </Field>
    </Box>
  );
};

export default AutoMailGridFormItemDelayUnit;
