import { EventEmitter2 } from 'eventemitter2';
import { log } from '../../../util/errorHandling';
import { PictureUploadErrorsEnum } from '../../../util/Uploader';
import { Entry } from './Entry';
import { EntryState } from './JsUploader';
import { ILogger, NullLogger } from './Logger';
import { Utils } from './Utils';

export interface IUploadWorkerProps {
  uploadType: string;
  uri: string;
  userId: number;
  sessionId: string;
  logger: ILogger;
  isPaused?: boolean;
  fields: any;
}

export class UploadWorker extends EventEmitter2 {
  /**
   *
   * @returns {string}
   */
  public static generateBoundary() {
    let boundary = '';
    const source = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ*#+-';
    const sourceLength = source.length;
    for (let i = 26; i > 0; i--) {
      boundary += source[Math.floor(Math.random() * sourceLength)];
    }

    return boundary;
  }

  public currentEntry: Entry;
  public isActive = false;
  public isPaused: boolean;

  private uploadType: string;
  private logger: ILogger;
  private isInterrupted: boolean;

  private dataBegin: string;
  private dataEnd: string;
  private isHead: boolean;
  private isTail: boolean;

  private dataFieldsBytes: number[];
  private fileReader: any;
  private request: any;
  private tLog: number[] = [];
  private bytesToRead: number;
  private bufferSize: number = 256 * 1024;
  private fileTimer: any;
  private bytes: any;
  private bytesRead: number;
  private partLength: number;
  private uri: any;
  private errorCounter: number;
  private repeatTimer: any;
  private sessionId: string;
  private fields: any = {};

  /**
   *
   * @param props IUploadWorkerProps
   */
  public constructor(props: IUploadWorkerProps) {
    super();
    this.logger = props.logger || new NullLogger();
    this.uri = props.uri.replace(/\{(\w+)\}/g, (match: string, s0: string) => {
      switch (s0) {
        case 'sid':
          return props.sessionId;
        default:
          return props.fields[s0] || '{' + s0 + '}';
      }
    });
    this.isPaused = props.isPaused || false;
    this.sessionId = props.sessionId;
    this.fields = props.fields;
    this.uploadType = props.uploadType;
  }

  /**
   *
   */
  public startUploads() {
    if (this.isPaused) {
      this.logger.debug('UploadWorker: start uploads', {
        context: 'JsUploader.UploadWorker.startUploads',
      });
      this.isPaused = false;
      this.emit('statePauseChanged');
    }
  }

  /**
   *
   */
  public pauseUploads() {
    if (!this.isPaused) {
      this.logger.debug('UploadWorker: pause uploads', {
        context: 'JsUploader.UploadWorker.pauseUploads',
      });
      this.isPaused = true;
      if (this.currentEntry) {
        this.interrupt(false);
      }
      this.emit('statePauseChanged');
    }
  }

  /**
   *
   * @param queue Entry[]
   */
  public processQueue(queue: Entry[]) {
    if (!this.isPaused && !this.isActive) {
      for (let i = 0; i < queue.length; i++) {
        const entry = queue[i];
        if (entry.state === EntryState.QUEUED || entry.state === EntryState.NETWORK_ERROR) {
          this.processEntry(entry);
        }
      }
      this.emit('stateChanged');
    }
  }

  public interrupt(instantly?: boolean): void {
    this.logger.debug(`UploadWorker: interrupt ${this.currentEntry.fileName}`);

    this.emit('updateEntryState', this.currentEntry, EntryState.QUEUED);
    this.isInterrupted = true;

    if (instantly) {
      clearTimeout(this.fileTimer);
      clearTimeout(this.repeatTimer);
      if (this.fileReader) {
        this.logger.debug(
          `UploadWorker: interrupt instantly ${this.currentEntry.fileName} (aborting fileReader)`,
          { context: 'JsUploader.UploadWorker.interrupt' }
        );
        this.cleanupReader();
        try {
          this.fileReader.abort();
        } catch (err) {
          this.logger.warn(`UploadWorker: interrupt ${this.currentEntry.fileName} (ignore)`, {
            context: 'JsUploader.UploadWorker.interrupt',
            err,
          });
        }
        this.fileReader = null;
      }

      if (this.request) {
        this.logger.debug(`UploadWorker: interrupt ${this.currentEntry.fileName} (aborting send)`, {
          context: 'JsUploader.UploadWorker.interrupt',
        });
        this.request.abort();
        this.cleanupRequest();
      }
    }

    this.isActive = false;
    this.emit('stateChanged');
  }

  /**
   *
   * @param entry Entry
   */
  private processEntry(entry: Entry) {
    if (!this.isActive) {
      this.logger.debug(`UploadWorker: process entry ${entry.fileName}`, {
        context: 'JsUploader.UploadWorker.processEntry',
      });

      entry.fields = this.fields;
      this.isActive = true;
      this.isInterrupted = false;
      this.currentEntry = entry;
      this.emit('stateChanged');
      this.s0prepare();
    }
  }

  private cleanup() {
    this.cleanupReader();
    this.fileReader = null;

    this.cleanupRequest();
    this.request = null;

    this.isActive = false;
  }

  /**
   *
   */
  private s0prepare() {
    const entry: Entry = this.currentEntry;
    this.logger.debug(`UploadWorker: prepare ${entry.fileName}`, {
      context: 'JsUploader.UploadWorker.s0prepare',
    });

    if (!entry.boundary) {
      entry.boundary = UploadWorker.generateBoundary();
    }

    this.dataBegin = `--${entry.boundary}\r\nContent-Disposition: form-data; name="file"; filename="${entry.fileName}"\r\n\r\n`;
    this.dataEnd = `\r\n--${entry.boundary}--\r\n`;

    this.isHead = !entry.etag;
    this.isTail = false;

    if (this.isHead) {
      entry.written = 0;
      entry.binaryPos = 0;

      this.dataFieldsBytes = Utils.toUTF8Array(this.getFieldsString(entry));
      entry.contentLength =
        this.dataFieldsBytes.length +
        this.dataBegin.length +
        entry.binaryLength +
        this.dataEnd.length;
    }

    this.emit('updateEntryState', entry, EntryState.RUN);
    this.emit('updateEntry', entry);

    this.s1check();
  }

  private getFieldsString(entry: Entry) {
    return Object.getOwnPropertyNames(entry.fields)
      .map((fieldName) => {
        let fieldString = '';
        let fieldValue: string = entry.fields[fieldName] || null;

        if (fieldValue) {
          fieldValue = fieldValue
            .toString()
            .replace(/\{entry\.(\w+)\}/g, (match: string, entryField: string) => {
              return entry[entryField] || null;
            });
          fieldString =
            '--' +
            entry.boundary +
            '\r\n' +
            'Content-Disposition: form-data; name="' +
            fieldName +
            '"' +
            '\r\n\r\n' +
            fieldValue +
            '\r\n';
        }

        return fieldString;
      })
      .reduce((previousValue: string, currentVal: string) => {
        return previousValue + currentVal;
      });
  }

  private s1check(): void {
    const entry = this.currentEntry;

    if (this.isInterrupted) {
      if (entry.state === EntryState.RUN) {
        this.emit('updateEntryState', entry, EntryState.QUEUED);
        this.cleanup();
        this.logger.info(`UploadWorker: upload interruppted: ${entry.fileName}`, {
          context: `JsUploader.UploadWorker.s1check.${entry.state}`,
        });
      }
    } else if (entry.state === EntryState.RUN) {
      if (entry.written < entry.contentLength) {
        // EntryState.RUN
        this.logger.debug(`UploadWorker: uploading file: ${entry.fileName}`, {
          context: `JsUploader.UploadWorker.s1check.${entry.state}`,
        });
        this.s2read();
      } else {
        // EntryState.COMPLETE
        this.logger.info(`UploadWorker: file upload completed: ${entry.fileName}`, {
          context: `JsUploader.UploadWorker.s1check.${entry.state}`,
        });
        this.cleanup();
        this.emit('complete', entry);
        this.emit('updateEntryState', entry, EntryState.COMPLETE);
      }
    } else if (entry.state === EntryState.MONOCHROME_ERROR) {
      this.emit('error', entry);
      this.logger.info(`UploadWorker: file is monochrome: ${entry.fileName}`, {
        context: `JsUploader.UploadWorker.s1check.${entry.state}`,
      });
    } else if (entry.state === EntryState.DUPLICATE_ERROR) {
      this.emit('error', entry);
      this.logger.info(`UploadWorker: already uploaded: ${entry.fileName}`, {
        context: `JsUploader.UploadWorker.s1check.${entry.state}`,
      });
    } else if (entry.state === EntryState.NETWORK_ERROR) {
      // if we got a timeout before, e.g. the file was really huge before and the upload server need time to concat all chunks
      if (entry.written >= entry.contentLength) {
        // EntryState.COMPLETE
        this.logger.info(`UploadWorker: file upload completed: ${entry.fileName}`, {
          context: `JsUploader.UploadWorker.s1check.${entry.state}`,
        });
        this.cleanup();
        this.emit('complete', entry);
        this.emit('updateEntryState', entry, EntryState.COMPLETE);
      }

      this.logger.error(`UploadWorker: network error while uploading ${entry.fileName}`, {
        context: 'JsUploader.UploadWorker.s1check.' + entry.state,
      });
    } else {
      // other errors besides errorcode -9, -8, -7 (see handleTailResponse switch case)
      // typical:
      //   State.COMPLETE || State.AUTH_ERROR ||
      //   State.DUPLICATE_ERROR || State.READ_ERROR ||
      //   State.RESPONSE_ERROR
      //
      // unexpected:
      //   State.FILE_REF_ERROR ||
      //   State.QUEUED || State.REMOVED
      this.cleanup();
      this.logger.error(`UploadWorker: other error, state now ${entry.state}: ${entry.fileName}`, {
        context: `JsUploader.UploadWorker.s1check.${entry.state}`,
      });
    }

    // this.emit('stateChanged');
    this.emit('updateEntry', entry);
  }

  private s2read() {
    // reading file
    const entry = this.currentEntry;
    this.tLog.push(Date.now(), entry.written);

    const binaryPosStart = entry.binaryPos;
    const binaryRestLength: number = entry.binaryLength - binaryPosStart;

    if (this.isHead) {
      this.bytesToRead =
        this.bufferSize - this.dataFieldsBytes.length - this.dataBegin.length - this.dataEnd.length;
      const binaryPosEnd = binaryPosStart + this.bytesToRead;

      if (this.bytesToRead < 0) {
        // seldom case that string fields are very large
        this.bytesToRead = 0;
        this.logger.info(`UploadWorker: read ${entry.fileName} as one chunk`, {
          context: 'JsUploader.UploadWorker.s2read.oneChunk',
        });
      }
      // if binary fits complete in the first part
      if (this.bytesToRead > entry.binaryLength) {
        this.bytesToRead = entry.binaryLength;
        this.isTail = true;
        this.logger.info(`UploadWorker: read ${entry.fileName} as one chunk`, {
          context: 'JsUploader.UploadWorker.s2read.oneChunk',
        });
      } else {
        this.logger.info(
          `UploadWorker: read first chunk of ${entry.fileName} (${binaryPosStart} - ${binaryPosEnd})`,
          { context: 'JsUploader.UploadWorker.s2read.firstChunk' }
        );
      }
    } else {
      this.isTail = binaryRestLength <= this.bufferSize;
      this.bytesToRead = this.isTail ? binaryRestLength : this.bufferSize;
      const binaryPosEnd = binaryPosStart + this.bytesToRead;

      if (this.isTail) {
        this.logger.info(
          `UploadWorker: read last chunk of ${entry.fileName} (${binaryPosStart} - ${binaryPosEnd})`,
          { context: 'JsUploader.UploadWorker.s2read.lastChunk' }
        );
      } else {
        this.logger.info(
          `UploadWorker: read chunk of ${entry.fileName} (${binaryPosStart} - ${binaryPosEnd})`,
          { context: 'JsUploader.UploadWorker.s2read.chunk' }
        );
      }
    }

    if (this.bytesToRead > 0) {
      const binaryPosEnd = binaryPosStart + this.bytesToRead;
      if (!this.fileReader) {
        this.fileReader = new FileReader();

        this.fileReader.onloadend = (event: any) => {
          clearTimeout(this.fileTimer);

          if (!this.isInterrupted) {
            const error = event.target.error;

            if (!error && this.fileReader.readyState === 2) {
              this.bytes = this.fileReader.result;
              this.bytesRead = this.bytes.byteLength;
              if (this.bytes && this.bytesRead) {
                this.s3send();
                return; // success
              }
            }

            // error handling
            this.bytesRead = 0;
            if (error) {
              this.logger.error(
                `UploadWorker: error ${error.message} while reading ${entry.fileName} ()`,
                { context: 'JsUploader.UploadWorker.s2read', err: error }
              );
            }

            // file read error
            this.emit('updateEntryState', entry, EntryState.READ_ERROR);
          }

          this.s1check();
        };

        this.fileReader.onabort = (event: UIEvent) => {
          this.emit('abort', event);
        };

        this.fileReader.onerror = (event: ErrorEvent) => {
          this.emit('error', event);
        };
      }

      const blob: Blob = entry.file.slice(binaryPosStart, binaryPosEnd);
      this.fileReader.readAsArrayBuffer(blob);

      this.fileTimer = setTimeout(() => {
        // console.log('UploadWorker::s2read timeout');
        this.fileReader.abort();
      }, 2000);
    } else {
      this.bytesRead = 0;
    }
  }

  private s3send() {
    // sending data

    const entry = this.currentEntry;

    this.partLength =
      (this.isHead ? this.dataFieldsBytes.length + this.dataBegin.length : 0) +
      this.bytesRead +
      (this.isTail ? this.dataEnd.length : 0);

    let contentSize: number = this.partLength;
    if (!this.isHead) {
      contentSize += this.dataBegin.length;
    }
    if (!this.isTail) {
      contentSize += this.dataEnd.length;
    }

    // prepare binary data
    const uint8array: Uint8Array = new Uint8Array(contentSize);
    let i = 0,
      j: number;
    if (this.isHead) {
      for (; i < this.dataFieldsBytes.length; i++) {
        uint8array[i] = this.dataFieldsBytes[i];
      }
    }

    for (j = 0; j < this.dataBegin.length; j++, i++) {
      uint8array[i] = this.dataBegin.charCodeAt(j) & 0xff;
    }

    const bytes8 = new Uint8Array(this.bytes);
    for (j = 0; j < this.bytesRead; j++, i++) {
      uint8array[i] = bytes8[j];
    }

    for (j = 0; j < this.dataEnd.length; j++, i++) {
      uint8array[i] = this.dataEnd.charCodeAt(j) & 0xff;
    }

    // init request
    const request: XMLHttpRequest = new XMLHttpRequest();
    this.request = request;

    request.onload = this.handleLoad.bind(this);
    request.onabort = this.handleAbort.bind(this);
    request.onerror = this.handleError.bind(this);
    request.ontimeout = this.handleTimeout.bind(this);

    request.open('POST', this.uri);
    request.timeout = 30 * 1000; // 3sec

    if (this.isTail) {
      request.timeout = 5 * 1000; // 5sec
    }

    request.setRequestHeader('Content-Type', 'multipart/form-data; boundary=' + entry.boundary);

    if (!(this.isHead && this.isTail)) {
      request.setRequestHeader(
        'Content-Range',
        'bytes ' +
          entry.written +
          '-' +
          (entry.written + this.partLength - 1) +
          '/' +
          entry.contentLength
      );
    }

    if (!this.isHead && entry.etag) {
      request.setRequestHeader('if-range', entry.etag);
    }

    request.send(uint8array);
  }

  /**
   *
   * @param event
   */
  private handleLoad(event: Event) {
    const entry = this.currentEntry;
    const request: XMLHttpRequest = event.target as XMLHttpRequest;

    // TODO possible error
    // request.onload 400 result=failed: The process cannot access the file '\\ams1-store\vxstreams\transcoder\partialupload\0266e8db-d20c-4d43-9e25-5a63a6f5af71.bin' because it is being used by another process.

    if (entry.state === EntryState.NETWORK_ERROR) {
      // reset network error state if set
      this.emit('updateEntryState', entry, EntryState.RUN);
    }
    this.errorCounter = 0;

    if (request.status === 200 || request.status === 201) {
      entry.binaryPos += this.bytesRead;
      entry.written += this.partLength;
      entry.uploadProgress = entry.binaryPos / entry.binaryLength;

      if (this.tLog.length) {
        // assert
        const now = Date.now();
        const len = this.tLog.length;

        for (let i = 0; i < len; i += 2) {
          if (now - this.tLog[i] < 4000) {
            break;
          }

          if (i >= len) {
            i = this.tLog.length - 2;
          }

          entry.uploadRate = (entry.written - this.tLog[i + 1]) / ((now - this.tLog[i]) * 0.001);

          if (i > 256) {
            // reduce array periodically
            this.tLog.splice(0, i);
          }
        }
      } else {
        entry.uploadRate = 0;
      }

      const speed = entry.uploadRate >> 10;
      // console.log('entry.binaryPos: ' + entry.binaryPos + ' entry.written: ' + entry.written + ' speed: ' + speed + ' KB/s');

      if (this.isHead) {
        const etag = request.getResponseHeader('ETag');
        if (etag) {
          entry.etag = etag;
          // this.dataFieldsBytes = null;
          this.isHead = false;
          // console.log('etag = ' + etag);
        } else {
          // console.log('etag not found');
        }
      }

      if (this.isTail) {
        this.handleTailResponse(request.response);
      }
    } else if (request.status === 400) {
      this.logger.warn(`Bad Request: while uploading ${JSON.stringify(request.response)}`, {
        context: 'JsUploader.UploadWorker.handleLoad',
        data: {
          sessionId: this.sessionId,
          uri: this.uri,
          isHead: this.isHead,
          isTail: this.isTail,
        },
      });
    } else if (request.status === 403) {
      this.logger.warn(`UploadWorker: unauthorized while uploading ${entry.fileName}`, {
        context: 'JsUploader.UploadWorker.handleLoad',
        data: {
          sessionId: this.sessionId,
          uri: this.uri,
          isHead: this.isHead,
          isTail: this.isTail,
        },
      });
      this.emit('updateEntryState', entry, EntryState.AUTH_ERROR);
    }
    this.emit('updateEntry', entry);
    this.cleanupRequest();
    this.s1check();
  }

  /**
   *
   * @param event
   */
  private handleAbort(event: Event) {
    log('info', `UploadWorker: Upload aborted "${entry.fileName}"`, {
      context: 'JsUploader.UploadWorker.s3send.handleAbort',
    });
    this.cleanupRequest();
    this.s1check();
  }

  /**
   *
   * @param event
   */
  private handleError(event: Event) {
    const entry = this.currentEntry;
    const message = `UploadWorker: Error while uploading "${entry.fileName}"`;
    log('error', message, { context: 'JsUploader.UploadWorker.s3send.handleError' });
    console.log(message, event);

    // network error
    this.emit('updateEntryState', entry, EntryState.NETWORK_ERROR);

    this.errorCounter++;
    if (!this.repeatTimer && !this.isInterrupted) {
      this.repeatTimer = setTimeout(
        () => {
          this.repeatTimer = 0;
          if (entry.state === EntryState.NETWORK_ERROR) {
            this.s3send();
          }
        },
        this.errorCounter < 10 ? 500 * this.errorCounter : 5000
      );
    }

    this.cleanupRequest();
    this.s1check();
  }

  /**
   *
   * @param event
   */
  private handleTimeout(event: Event) {
    this.currentEntry.binaryPos += this.bytesRead;
    this.currentEntry.written += this.partLength;
    this.currentEntry.uploadProgress = this.currentEntry.binaryPos / this.currentEntry.binaryLength;

    const message = `UploadWorker: Timeout uploading "${this.currentEntry.fileName}"`;
    log('warning', message, { context: 'JsUploader.UploadWorker.s3send.handleTimeout' });

    this.emit('updateEntry', this.currentEntry);
    this.s1check();
  }

  /**
   *
   */
  private cleanupRequest() {
    const request = this.request;
    if (request) {
      request.onload = null;
      request.onabort = null;
      request.onerror = null;
      request.ontimeout = null;
    }
    this.request = null;
  }

  /**
   *
   */
  private cleanupReader() {
    const reader = this.fileReader;
    if (reader) {
      reader.onabort = null;
      reader.onerror = null;
      reader.onload = null;
      reader.onloadend = null;
    }
    this.fileReader = null;
  }

  private handleTailResponse(requestResponse: any) {
    const entry: Entry = this.currentEntry;
    let success = false;
    switch (this.uploadType) {
      case 'video':
        // {"success":"true","umaID":988376}

        try {
          const response = JSON.parse(requestResponse);
          if (response.success === 'true') {
            success = true;
            entry.umaId = response.umaID;
          } else {
            const exception: string = response.exception;
            const newState =
              exception.indexOf('expected+umaID+not') === 0
                ? EntryState.DUPLICATE_ERROR
                : EntryState.RESPONSE_ERROR;
            this.emit('updateEntryState', entry, newState);
            entry.umaId = 0;
          }
        } catch (e) {
          // response parsing error
          entry.umaId = -1;
          this.emit('updateEntryState', entry, EntryState.RESPONSE_ERROR);
        }
        break;

      case 'picture':
        const parsed: any = requestResponse.split('=');
        switch (parsed[0]) {
          case 'id':
            break;
          case 'errorcode':
            switch (parsed[1]) {
              case PictureUploadErrorsEnum.MONOCHROME:
                this.emit('updateEntryState', entry, EntryState.MONOCHROME_ERROR);
                break;
              case PictureUploadErrorsEnum.DUPLICATE:
                this.emit('updateEntryState', entry, EntryState.DUPLICATE_ERROR);
                break;
              case PictureUploadErrorsEnum.DB_ERROR:
                this.emit('updateEntryState', entry, EntryState.RESPONSE_ERROR);
                break;
            } // rest of errorcodes like -5 (picture_size_error) etc. are detected in s1check()
            break;
        }
        break;
    }
  }
}
