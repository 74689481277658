import AppDispatcher from '../../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../../util/ServerUtils';
import ActionTypes from '../../../util/constants/ActionTypes';

class VXCashUsersActionCreatorsClass {
  getData(type, sort, sortDirection, limit, offset) {
    let params =
      'type=' +
      type +
      '&sort=' +
      sort +
      '&sortDirection=' +
      sortDirection +
      '&limit=' +
      limit +
      '&offset=' +
      offset;
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/lists/vxcashusers/getdata?' + params,
      null,
      function(response) {
        if (offset > 1) {
          VXCashUsersActionCreators.appendData(type, response.userlist);
        } else {
          VXCashUsersActionCreators.receiveData(type, {
            userlist: response.userlist,
            totalEntries: response.totalEntries,
          });
        }
      }
    );
  }

  receiveData(store, data) {
    AppDispatcher.dispatch({
      type: ActionTypes.LISTS_VXCASHUSERS_RECEIVE_DATA,
      store: store,
      data: data,
    });
  }

  appendData(store, data) {
    AppDispatcher.dispatch({
      type: ActionTypes.LISTS_VXCASHUSERS_APPEND_DATA,
      store: store,
      data: data,
    });
  }
}

const VXCashUsersActionCreators = new VXCashUsersActionCreatorsClass();

export { VXCashUsersActionCreators };
