import React, { FC } from 'react';
import { infoSign } from '../../../../atoms/Icon/libraries/glyph';
import HelpcenterLink from "../../../Helpcenter/HelpcenterLink";
import { LeftMenuItem } from '../../components';

const HelpItem: FC = () => {
  const link = HelpcenterLink();

  return (
    <LeftMenuItem
      title={'Helpcenter'}
      icon={infoSign}
      onClick={() => {
        window.open(link, '_blank');
      }}
    />
  );
}

export default HelpItem;
