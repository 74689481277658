import React, { FC } from 'react';
import { de } from 'date-fns/locale';
import { createStyles, makeStyles, Paper, Typography } from '@material-ui/core';
import { BLACK_1, BLUE, GREEN, RED, WHITE } from '../../camtool-styles';
import { _ } from '../../util/translate';
import { useLang } from '../../util/AppState';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import useCountdown from '../../hooks/useCountdown';
import { format } from 'date-fns-tz';
import { roundMinutes } from '../../util/Formatter';
import { Scalars, TopAmateurSlotStatusEnum } from '../../graphql/VXModels/types';

const useStyles = makeStyles(() =>
  createStyles({
    subTitle1: {
      fontSize: '14px',
      width: '100%',
      marginBottom: '-6px',
      marginTop: '-2px',
    },
    accessIcon: {
      marginBottom: '-2px',
    },
    subTitle2: {
      color: BLACK_1,
      fontWeight: 'bold',
      fontSize: '14px',
      width: '100%',
      margin: '3px 0',
    },
    subTitle3: {
      fontSize: '10px',
      width: '100%',
      color: '#888',
      marginTop: '2px',
    },
    headline1: {
      fontSize: '22px',
      width: '100%',
      textAlign: 'center',
    },
    customPaper: {
      minWidth: '175px',
      width: '100%',
      padding: '10px 16px',
    },
    greenBorder: {
      borderColor: `${GREEN}`,
    },
    colorGreen: {
      color: `${GREEN}`,
    },
    redBorder: {
      borderColor: `${RED}`,
    },
    blueBorderAndBackground: {
      borderColor: `${BLUE}`,
      backgroundColor: `${BLUE}`,
      color: `${WHITE}`,
    },
    colorWhite: {
      color: `${WHITE}`,
    },
  })
);

export enum LabelStatusEnum {
  //TODO implement cancelled in backend
  CANCELLED = 'cancelled',
  ACTIVE = 'active',
  UPCOMING = 'upcoming',
  PLANNED = 'planned',
}

interface LabelBannerProps {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  status: TopAmateurSlotStatusEnum;
}

const TimeLabelBanner: FC<LabelBannerProps> = ({ start, end, status }) => {
  const [lang] = useLang();
  const isNotDe = lang !== 'de';

  const startDay =
    lang === 'de'
      ? format(new Date(start), 'EEEEEE dd.MM.yyyy |', { locale: de })
      : format(new Date(start), 'EEEEEE MM/dd/yyyy |', { locale: de });

  const endDate =
    lang === 'de'
      ? format(roundMinutes(new Date(end)), 'HH:mm').replace(' ', 'T')
      : format(roundMinutes(new Date(end)), 'HH:mm aaaa');

  const startDate =
    lang === 'de'
      ? format(new Date(start), 'HH:mm').replace(' ', 'T')
      : format(new Date(start), 'HH:mm aaaa');

  const startTime = lang === 'de' ? `${startDate} Uhr` : `${startDate}`;
  const endTime = lang === 'de' ? `${endDate} Uhr` : `${endDate}`;

  const endD = new Date(Date.parse(end)).toUTCString();

  const [days, hours, minutes, seconds] = useCountdown(endD);

  const {
    accessIcon,
    subTitle1,
    subTitle2,
    subTitle3,
    customPaper,
    greenBorder,
    redBorder,
    colorGreen,
    colorWhite,
    blueBorderAndBackground,
  } = useStyles();

  const getStatusElement = (status: LabelStatusEnum) => {
    switch (status) {
      case LabelStatusEnum.CANCELLED:
        return 'cancelled';
      case LabelStatusEnum.ACTIVE:
        return 'active';
      case LabelStatusEnum.UPCOMING:
        return 'upcoming';
      default:
        return 'planned';
    }
  };

  const getStyle = (status: LabelStatusEnum) => {
    switch (status) {
      case LabelStatusEnum.CANCELLED:
        return redBorder;
      case LabelStatusEnum.ACTIVE:
        return greenBorder;
      case LabelStatusEnum.UPCOMING:
        return blueBorderAndBackground;
      default:
        return null;
    }
  };

  const getTitle = (status: LabelStatusEnum) => {
    switch (status) {
      case LabelStatusEnum.CANCELLED:
        return 'cancelled';
      case LabelStatusEnum.ACTIVE:
        return 'active';
      case LabelStatusEnum.UPCOMING:
        return 'title';
      default:
        return 'description';
    }
  };

  return (
    <Paper variant="outlined" className={`${customPaper} ${getStyle(getStatusElement(status))}`}>
      <Typography
        className={`${subTitle1} ${status === LabelStatusEnum.UPCOMING && colorWhite}`}
        variant="subtitle1"
      >
        {_(`dashboard:app.topAmateur.${getTitle(status)}`)}
      </Typography>
      <Typography
        className={`${subTitle2} ${status === LabelStatusEnum.ACTIVE && colorGreen}  ${
          status === LabelStatusEnum.UPCOMING && colorWhite
        }`}
        variant="body1"
      >
        {status === LabelStatusEnum.ACTIVE ? (
          <>
            {status === LabelStatusEnum.ACTIVE && (
              <AccessAlarmsIcon fontSize="small" className={accessIcon} />
            )}{' '}
            {_(`dashboard:app.topAmateur.timeTitle`)}{' '}
            {_('dashboard:app.topAmateur.timeCountdown', {
              hours: hours,
              minutes: minutes,
              seconds: seconds,
            })}
          </>
        ) : (
          <>
            {startDay} {startTime} - {endTime}
          </>
        )}
      </Typography>
      {isNotDe && status !== LabelStatusEnum.ACTIVE && (
        <Typography
          className={`${subTitle3} ${status === LabelStatusEnum.ACTIVE && colorGreen}  ${
            status === LabelStatusEnum.UPCOMING && colorWhite
          }`}
          variant="h3"
        >
          Timezone Europe/Berlin
        </Typography>
      )}
    </Paper>
  );
};

export default TimeLabelBanner;
