import React, { FC, useRef } from 'react';
import { Spinner } from '../../../components';
import HiddenFileInput from './HiddenFileInput';
import { Preview } from './Preview';
import { StyledLabel } from './styles';
import {
  MediaUploadInputLabelSize,
  MediaUploadInputLayoutDirection,
  MediaUploadInputProps,
} from './types';
import { UploadLabel } from './UploadLabel';

/**
 * Reusable media upload component
 */
const MediaUploadInput: FC<MediaUploadInputProps> = ({
  label,
  accept,
  disabled: disabledProp = false,
  loading = false,
  currentFile,
  labelSize = MediaUploadInputLabelSize.md,
  videoThumbnailIconSize,
  layoutDirection = MediaUploadInputLayoutDirection.vertical,
  className,
  onSelectFile,
  onRemoveFile,
}) => {
  const disabled = disabledProp || loading;
  const inputRef = useRef<HTMLInputElement>(null);
  const onReuploadFile = (): void => inputRef.current?.click();

  return (
    <StyledLabel disabled={disabled} className={className}>
      {currentFile && !loading ? (
        <Preview
          file={currentFile}
          isEditable={!disabled}
          videoThumbnailIconSize={videoThumbnailIconSize}
          onRemoveFile={onRemoveFile}
          onReuploadFile={onReuploadFile}
        />
      ) : loading ? (
        <Spinner size={'s'} />
      ) : (
        <UploadLabel label={label} labelSize={labelSize} layoutDirection={layoutDirection} />
      )}
      <HiddenFileInput
        ref={inputRef}
        accept={accept}
        disabled={disabled}
        onSelectFile={onSelectFile}
      />
    </StyledLabel>
  );
};

export default MediaUploadInput;
