import React, { FC, ReactElement } from 'react';

const DoneIcon: FC = (): ReactElement => {
  return (
    <g xmlns="http://www.w3.org/2000/svg" id="Zugestellt" transform="translate(-1642 -4716)">
      <circle id="Kreis" cx="21" cy="21" r="21" transform="translate(1642 4716)" fill="#43b31c" />
      <path
        id="Häkchen"
        d="M6.75,13.052,1.688,7.881,0,9.6l6.75,6.9L21.215,1.724,19.527,0Z"
        transform="translate(1653 4728.5)"
        fill="#fff"
      />
    </g>
  );
};

export default DoneIcon;
