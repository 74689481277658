import React from 'react';
import PropTypes from 'prop-types';

const ZendeskRenderContainer = props => {
  return (
    <div
      className={'zendesk-render-container ' + (props.className || '')}
      dangerouslySetInnerHTML={{ __html: props.htmlContent }}
    />
  );
};

ZendeskRenderContainer.propTypes = {
  htmlContent: PropTypes.string,
};

export default ZendeskRenderContainer;
export { ZendeskRenderContainer };
