import styled from '@emotion/styled';
import GroupRow from '../GroupRow/GroupRow';
import {
  GRAY_2,
  BREAKPOINT_SPHONE_CONDITION,
  BREAKPOINT_TABLET_CONDITION,
  BREAKPOINT_DESKTOP_CONDITION,
} from '../../camtool-styles';
import ButtonGroup from '../ButtonGroup/ButtonGroup';

export const MediaFormGroupRow = styled(GroupRow)`
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
  > .label {
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    .tooltip {
      position: absolute;
      left: -5px;
      margin: 0;
    }
    .tag-list {
      -ms-flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
      flex: 1 1 auto;
    }
  }
  @media ${BREAKPOINT_SPHONE_CONDITION} {
    .maxvaluelength-anchorframe .maxvaluelength {
      top: 42px;
      right: 12px;
    }
    .range {
      -ms-flex: 1 1 100%;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
      flex-wrap: wrap;
      .range__value {
        flex: 1 1 100%;
        height: 40px;
        margin-bottom: 20px;
        > input {
          width: 100%;
        }
      }
    }
    .language-box {
      > div > div {
        display: none;
      }
    }
    .multilangtextareafield__language__entry__name {
      display: none !important;
    }
    .multilangtextareafield__column > textarea {
      padding: 15px 10px;
    }
    .multi-lang-input-std .inputbox > input {
      padding: 10px 10px;
    }
  }
`;

export const MediaFormButtonGroup = styled(ButtonGroup)`
  margin-top: 40px;
  @media ${BREAKPOINT_TABLET_CONDITION} {
    .button {
      -ms-flex: 1 1 100%;
      -webkit-flex: 1 1 100%;
      flex: 1 1 100%;
    }
  }
`;

const DESCRIPTION_MARGIN = 4;

const DescriptionBase = styled.span`
  margin-top: ${DESCRIPTION_MARGIN}px;
  color: ${GRAY_2};
`;

export const MediaFormDateDescription = styled(DescriptionBase)`
  margin-top: ${DESCRIPTION_MARGIN + 4}px;
`;

export const MediaFormOptionDescription = styled(DescriptionBase)`
  margin-left: 8px;
`;
