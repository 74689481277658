import { Dispatch } from 'react';
import { ApolloClient } from 'apollo-client';

interface IAboRedeemDetails {
  type: AboBenefitSubType;
  data: any;
}

export interface IAboWizardState {
  aboContentType: AboContentType;
  aboRedeemDetails: IAboRedeemDetails;
  formValues?: IAboDetailsPageForm;
  history: AboRoute[];
}

export interface IAboWizardAction {
  type: AboWizardActionType;
  payload: IAboWizardActionPayload;
}

export enum AboWizardActionType {
  SetAboContentType,
  SetAboRedeemDetails,
  GoBack,
  GoToRoute,
  SaveFormValues,
  CreateNewAbo,
}

export interface IAboWizardActionPayload {
  aboContentType?: AboContentType;
  aboRedeemDetails?: IAboRedeemDetails;
  formValues?: IAboDetailsPageForm;
  route?: AboRoute;
  replace?: boolean;
}

/****************************************/
export interface IPageProps {
  state: IAboWizardState;
  dispatch: Dispatch<IAboWizardAction>;
  apolloClientAbo?: ApolloClient<{}>;
}

export interface IAboDetailsPageForm {
  nameTranslation: {
    de: string;
    en: string;
  };
  descriptionTranslation: {
    de: string;
    en: string;
  };
  billingPeriod: BillingPeriod;
  autoRenew: boolean;
  price: number;
  priceDiscounted: number;
  picture?: AboPicture;
}

export interface AboPicture {
  uuid: string;
  url: string;
}

/****************************************/
export enum AboRoute {
  AboContentPage,
  AboRedeemPage,
  AboDetailsPage,
  AboReviewPage,
}

export enum AboContentType {
  VXPagesCredits = 'VXPagesCredits',
  FreeMessages = 'FreeMessages',
  WhatsApp = 'WhatsApp', // can no longer be created (old abos exist)
  Telegram = 'Telegram',
  Videos = 'Videos',
  Photos = 'Photos',
  Media = 'AllMedia',
  Custom = 'Custom',
  None = '',
}

export enum AboRedeemType {
  MediaNewContent = 'MEDIA_NEW_CONTENT',
  MediaDownload = 'MEDIA_DOWNLOAD',
  MediaFlat = 'MEDIA_FLAT',
  VXPagesCreditsPercent = 'VXPAGES_CREDITS_PERCENT',
  VXPagesCreditsFlat = 'VXPAGES_CREDITS_FLAT',
  FreeMessagesFlat = 'FREE_MESSAGES_FLAT',
  FreeMessagesUnlimited = 'FREE_MESSAGES_UNLIMITED',
  None = '',
}

export enum BillingPeriod {
  Every1Days = 1,
  Every7Days = 7,
  Every1Months = 30,
  Every3Months = 90,
  Every6Months = 180,
  Every1Year = 365,
}

// GRAPHQL
export enum AboBenefitSubType {
  Flat = 'FLAT',
  Percent = 'PERCENT',
  FlatRate = 'FLATRATE',
  Download = 'DOWNLOAD',
  Custom = 'CUSTOM',
  New = 'NEW',
  None = '',
}

export interface IAboBenefitInput {
  type: AboContentType;
  subType: AboBenefitSubType;
  json?: string;
}

export interface IAboInput {
  name: string;
  price: number;
  priceDiscounted?: number;
  paymentInterval: number;
  benefitInterval: BillingPeriod;
  renew: boolean;
  active: boolean;
  creatorId: number;
  actorId?: number;
  description?: string;
  trialIntervalCount?: number;
  trialPrice?: number;
  benefits: any;
  pictureUUID?: string;
}

// Archive
export interface IAbo {
  id: number;
  name: string;
  description: string;
  picture: string;
  price: number;
  priceDiscounted: number;
  benefitInterval: number;
  paymentInterval: number;
  renew: boolean;
  active: boolean;
  trialIntervalCount: number;
  trialPrice: number;
  actorId: number;
  pfmId: 1503 | 1502;
  creatorId: number;
  createdAt: string;
  deactivatedAt?: string | undefined | null;
  subscriptionCount: number;
  benefits: any[];
}

export enum AboInstanceStatus {
  Running = 'RUNNING',
  Stopped = 'STOPPED',
  Paused = 'PAUSED',
}

export interface IAboInstance {
  aboId: number;
  end?: string;
  guestId: number;
  guestName: string;
  id: number;
  lastRefresh?: string;
  nextRefresh?: string;
  start?: string;
  status: AboInstanceStatus;
  totalRefreshes: number;
}

export interface IncludedAboTypesFilter {
  includedAboContentTypes?: [AboContentType];
  excludedAboContentTypes?: undefined;
}

export interface ExcludedAboTypesFilter {
  includedAboContentTypes?: undefined;
  excludedAboContentTypes?: [AboContentType];
}

export type AboContentTypesFilter = IncludedAboTypesFilter | ExcludedAboTypesFilter;
