import createBreakpoints, { Breakpoints } from '@material-ui/core/styles/createBreakpoints';

const breakpoints: Breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1400, // was MUI default 1280, but we use 1400 (IMHO too large)
    xl: 1920,
  },
});

export default breakpoints;
