import styled from '@emotion/styled';
import {
  Box,
  createStyles,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import { Field, FieldProps } from 'formik';
import React, { FC } from 'react';
import { InputAdornmentIcon, NewRadioButton } from '..';
import { WHITE } from '../../camtool-styles';
import { MediaUploadInputLabelSize } from '../../molecules/FileUpload/MediaUploadInput';
import { PreviewVideoThumbnailIconSize } from '../../molecules/FileUpload/PreviewFile';
import { FieldNumberInput } from '../../molecules/Formik/Fields';
import { FieldGenericUploadAttachment } from '../../packages/Mailings/components/Form/Fields/Upload';
import { useFileStorageSettings } from '../../packages/Telegram';
import { _ } from '../../util/translate';

const ContainerRow = styled.div`
  z-index: 20;
  word-break: break-all;
  color: #b0b0b0;
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  font-size: 16px;
  width: 100%;
  left: 0px;
  bottom: 56px;
  background-color: ${WHITE};
`;

const CloseButton = styled.button`
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const useStyles = makeStyles(() =>
  createStyles({
    group: {
      gridGap: '5px',
      marginBottom: '5px',
    },
  })
);

interface PreviewProps {
  onClose: () => void;
  name: string;
  disablePrice?: boolean;
}

const Preview: FC<PreviewProps> = ({ name, onClose, disablePrice }) => {
  const fileStorageSettings = useFileStorageSettings();
  const classes = useStyles();

  const priceProps = {
    min: 0,
    max: 150,
    step: 1,
    disabled: disablePrice,
  };

  return (
    <ContainerRow>
      <Box display="flex" width="100%" height="200px" alignItems="center" p={{ md: 5 }}>
        {/* picture upload */}
        <FieldGenericUploadAttachment
          css={{ width: '180px', height: '180px' }}
          name={name}
          label={_('mailings:telegram.autoMail.addFile')}
          accept="image/*, video/*, audio/*"
          fileStorageSettings={fileStorageSettings}
          labelSize={MediaUploadInputLabelSize.md}
          videoThumbnailIconSize={PreviewVideoThumbnailIconSize.md}
        />

        {/* Content Price */}
        <Box display="flex" flexDirection="column" ml={2}>
          <Typography variant="h3">{_('telegram:chat.preview.price')}</Typography>
          <Field name="price">
            {({ field }: FieldProps) => (
              <Box maxWidth="100%">
                <RadioGroup
                  classes={{ root: classes.group }}
                  {...field}
                  value={String(field.value)}
                  row
                >
                  <FormControlLabel
                    disabled={disablePrice}
                    value="0"
                    control={<NewRadioButton />}
                    label={_('common:text.free')}
                  />
                  <FormControlLabel
                    disabled={disablePrice}
                    value="5"
                    control={<NewRadioButton />}
                    label="5 €"
                  />
                  <FormControlLabel
                    disabled={disablePrice}
                    value="20"
                    control={<NewRadioButton />}
                    label="20 €"
                  />
                </RadioGroup>
              </Box>
            )}
          </Field>

          <Box maxWidth="100px" whiteSpace="nowrap">
            <FieldNumberInput
              name="price"
              {...priceProps}
              InputProps={{
                endAdornment: (
                  <InputAdornmentIcon>
                    <EuroSymbolIcon />
                  </InputAdornmentIcon>
                ),
              }}
            />
          </Box>
        </Box>

        <CloseButton type="button" title={_('common:button.upload.remove')} onClick={onClose}>
          <span className={'icon-remove'} />
        </CloseButton>
      </Box>
    </ContainerRow>
  );
};

export default Preview;
