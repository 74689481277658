import React, { FC } from 'react';
import GenericModalBoxLayout from '../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import Footer from './Footer';
import styled from '@emotion/styled';
import { BREAKPOINT_SPHONE_CONDITION, DARK_GRAY } from '../../../camtool-styles';
import SendMail from '../../../atoms/Icon/SendMail';
import { useUserData } from '../../../util/UserData';
import { useRouteMatch } from 'react-router-dom';
import { REACT_APP_BASE_PATH as basePath } from '../../../util/env';
import Markdown from '../../Markdown/Markdown';
import AccountOverviewList from './AccountOverviewList';
import ResendEmailSection from './ResendEmailSection';
import LogOut from './LogOut';
import { _ } from '../../../util/translate';

const useIsForbiddenModalTermsRoute = (): boolean => {
  const matches = [
    useRouteMatch(`${basePath}/legal/terms`),
    useRouteMatch(`${basePath}/legal/gdpr`),
    useRouteMatch(`${basePath}/legal/houserule`),
    useRouteMatch(`${basePath}/legal/imprint`),
    useRouteMatch(`${basePath}/legal/terms-of-use`),
  ];

  return matches.reduce<boolean>((accumulator, match) => accumulator || !!match, false);
};

const ModalDiv = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  color: ${DARK_GRAY};

  @media ${BREAKPOINT_SPHONE_CONDITION} {
    p {
      font-size: 1rem;
    }
  }
`;

const MailRow = styled.div`
  margin: 15px;
`;

const ResendEmailRow = styled.div`
  display: flex;

  flex-direction: 'column';
  margin: 0 15px;
`;

const ModalVerifyEmail: FC = () => {
  const userData = useUserData();
  const screenname = userData?.auth?.screenname;
  const modelId = userData?.auth?.id;
  const hotlineCode = userData?.auth?.hotlineCode;
  const email = userData?.model?.account?.email;
  const isForbiddenModalRoute = useIsForbiddenModalTermsRoute();

  return !isForbiddenModalRoute ? (
    <GenericModalBoxLayout name={'verify-email'} maxWidth={784} noCloseOnESC={true}>
      <ModalDiv>
        <div css={{ marginTop: '35px', marginBottom: '0' }}>
          <AccountOverviewList username={screenname} modelId={modelId} hotlineCode={hotlineCode} />
        </div>
        <MailRow>
          <SendMail width={80} height={50} />
        </MailRow>
        <div css={{ width: '100%', justifyContent: 'center', marginBottom: '30px' }}>
          <div css={{ flex: '0 1 85%', textAlign: 'center', fontSize: '18px' }}>
            <Markdown source={_('register:confirmationPage.instructions', { sprintf: [email] })} />
          </div>
        </div>
        <div>
          <ResendEmailRow>
            <ResendEmailSection />
          </ResendEmailRow>
        </div>
        <LogOut />
        <Footer />
      </ModalDiv>
    </GenericModalBoxLayout>
  ) : null;
};

export default ModalVerifyEmail;
