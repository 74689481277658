import { makeStyles, createStyles } from '@material-ui/core';

export const useAchievementSliderStyles = makeStyles(() =>
  createStyles({
    root: {
      scrollSnapType: 'x mandatory',
      /* IE and Edge */
      msOverflowStyle: 'none',
      /* Firefox */
      scrollbarWidth: 'none',
      /* Chrome, Safari */
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  })
);
