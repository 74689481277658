import React, { FC } from 'react';
import { FormatDate } from '../../../components';
import { GRAY_1 } from '../../../camtool-styles';
import UserComment from './UserComment';
import { user } from '../../../atoms/Icon/libraries/glyph';
import UserAvatar from '../../../atoms/UserAvatar/UserAvatar';

interface Props {
  guestLogin: string;
  guestAvatar: string;
  text: string;
  date: string;
}

const Comment: FC<Props> = ({ guestLogin, guestAvatar, text, date }) => {
  return (
    <div css={{ marginBottom: '15px' }}>
      <div css={{ flex: '1 1 auto', alignItems: 'center' }}>
        <div>
          <UserAvatar src={guestAvatar ?? user} size={'40px'} />
        </div>
        <div
          css={{
            flex: '1 1 auto',
          }}
        >
          <UserComment guestLogin={guestLogin} text={text} />
        </div>
      </div>
      <div
        css={{
          flex: '1 0 auto',
          justifyContent: 'flex-end',
        }}
      >
        <FormatDate css={{ color: GRAY_1 }} value={date} />
      </div>
    </div>
  );
};

export default Comment;
