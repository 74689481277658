import React, { FC, ReactElement, useState } from 'react';
import { _ } from '../../../../../util/translate';
import { Field, FieldProps, FormikValues } from 'formik';
import MultiLangTextAreaField from '../../../../../atoms/TextareaField/MultiLangTextAreaField';
import { ApiLang, Service0900 } from '../../../../../graphql/VXModels/types';
import { MAX_CHARACTERS } from '../../utils';

const langOrder: ApiLang[] = [ApiLang.de, ApiLang.en];
const getLangIndex = (lang: ApiLang): number => langOrder.indexOf(lang);

interface Props {
  values?: FormikValues;
}

const BA6ProfileDescription: FC<Props> = ({ values }) => {
  const [descriptionLanguage, setDescriptionLanguage] = useState(ApiLang.de); // no english in BA6!

  return (
    <div css={{ flexDirection: 'column', marginBottom: '24px' }}>
      <Field name={`settings.description.texts[${getLangIndex(descriptionLanguage)}].text`}>
        {({ field, form: { errors } }: FieldProps<Service0900>): ReactElement => (
          <MultiLangTextAreaField
            css={{
              width: '100%',
              flexDirection: 'column',
            }}
            {...field}
            title={'Profilbeschreibung BA6'}
            selectedLang={descriptionLanguage}
            onSelectedLangChange={setDescriptionLanguage}
            languages={values?.settings?.description?.languages}
            placeholder={_('service0900:premium.text0900.placeholder')}
            errorMessage={
              errors?.settings?.description?.texts?.[getLangIndex(descriptionLanguage)]?.text
            }
            maxLength={MAX_CHARACTERS}
          />
        )}
      </Field>
    </div>
  );
};

export default BA6ProfileDescription;
