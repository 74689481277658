import React, { ReactElement, FC } from 'react';
import { _ } from '../../../../../util/translate';
import { Select } from '../../../../../atoms';
import { Field, FormikValues } from 'formik';
import { Service0900Country } from '../../../../../graphql/VXModels/types';
import { Option } from '../../../../../atoms/Select/Select';

const getOptions = (allowedCountries: Service0900Country[]): Option[] =>
  allowedCountries.map((country: Service0900Country) => {
    return {
      label: `${country.countryCodeAlpha2.toUpperCase()} (${country.countryCallingCode})`,
      value: country.countryCallingCode,
    };
  });

interface Props {
  allowedCountries: Service0900Country[];
}

const CountryCodeSelect: FC<Props> = ({ allowedCountries }) => {
  const options = getOptions(allowedCountries);

  return (
    <div css={{ flexDirection: 'column' }}>
      <span css={{ marginBottom: '6px', whiteSpace: 'nowrap' }}>
        {_('service0900:PhoneSex.modal.request.country.prefix')}
      </span>
      <Field name={'targetNumber.countryCallingCode'}>
        {({ field }: FormikValues): ReactElement => <Select options={options} {...field} />}
      </Field>
    </div>
  );
};

export default CountryCodeSelect;
