import React from 'react';
import cc from 'classcat';

import AbstractMultiLangComponent from '../AbstractMultiLangComponent';
import MaxValueLength from '../../Display/MaxValueLength/MaxValueLength';
import * as flagIcons from '../../../../../src/atoms/Icon/libraries/flag';
import Icon from '../../../../atoms/Icon/Icon';

class MultiLangInputfield extends AbstractMultiLangComponent {
  render() {
    const { maxLength, minLength, name } = this.props;
    const { selectedLang, validLangs } = this.state;
    const currentLangValue = this.getLangValue(selectedLang) || '';
    const placeholder = `${this.props.placeholder} [${
      selectedLang ? selectedLang.toUpperCase() : selectedLang
    }]`;

    return (
      <div
        className={cc([
          'multi-lang-input-std',
          this.getClassNames(),
          {
            'maxvaluelength-anchorframe': maxLength > 0,
          },
        ])}
      >
        <div className="language-box">
          {Object.keys(validLangs).map((lang) => (
            <div
              key={lang}
              onClick={this.changeSelectedLang.bind(this, lang)}
              className={cc({ active: lang === selectedLang })}
            >
              <Icon css={{ marginRight: '3px' }} icon={flagIcons[lang]} title={validLangs[lang]} />
              <div>{validLangs[lang]}</div>
            </div>
          ))}
        </div>

        <div className="inputbox">
          <input
            name={name ? this.props.name : ''}
            placeholder={placeholder}
            onChange={this.onChange}
            value={currentLangValue}
            maxLength={maxLength}
          />
        </div>

        {maxLength > 0 && (
          <MaxValueLength min={minLength} max={maxLength} value={currentLangValue.length} />
        )}
      </div>
    );
  }
}

MultiLangInputfield.propTypes = AbstractMultiLangComponent.propTypes;
MultiLangInputfield.defaultProps = AbstractMultiLangComponent.defaultProps;

export default MultiLangInputfield;
export { MultiLangInputfield };
