import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';

class FancyHeaderItem extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortDirection: '',
    };
    this.onClickSort = this.onClickSort.bind(this);
  }

  // Update the state based on props only if the change is relevant to this component
  componentWillReceiveProps(nextProps) {
    if (this.props.name === nextProps.sortedBy) {
      if (nextProps.sortDirection !== this.state.sortDirection) {
        this.setState({ sortDirection: nextProps.sortDirection });
      }
    } else {
      this.setState({ sortDirection: '' });
    }
  }

  // Set default sorted column.
  componentWillMount() {
    if (this.props.name === this.props.sortedBy) {
      this.setState({ sortDirection: this.props.sortDirection });
    }
  }

  onClickSort() {
    if (this.props.sortable) {
      switch (this.state.sortDirection) {
        case 'asc':
          this.props.onSort(this.props.name, 'desc');
          this.setState({ sortDirection: 'desc' });
          break;
        case 'desc':
        default:
          this.props.onSort(this.props.name, 'asc');
          this.setState({ sortDirection: 'asc' });
          break;
      }
    }
  }

  render() {
    this.sortClass = this.state.sortDirection
      ? 'fancy-table__header__item--active fancy-table__header__item--' + this.state.sortDirection
      : '';

    if (this.props.type === 'competition') {
      if (
        this.props.label.toLowerCase() === 'rang' ||
        this.props.label.toLowerCase() === 'points'
      ) {
        return (
          <div
            className={[
              'fancy-table__header__item',
              this.props.sortable ? 'fancy-table__header__item--sortable' : '',
              this.sortClass,
            ].join(' ')}
            style={{ width: this.props.styles.width, justifyContent: 'center' }}
            onClick={this.onClickSort}
          >
            <span>{this.props.label}</span>
          </div>
        );
      } else if (this.props.name === 'modelNickname') {
        return (
          <div
            className={[
              'fancy-table__header__item',
              this.props.sortable ? 'fancy-table__header__item--sortable' : '',
              this.sortClass,
            ].join(' ')}
            style={{ flex: 1 }}
            onClick={this.onClickSort}
          >
            <span>{this.props.label}</span>
          </div>
        );
      } else if (this.props.name === 'prize') {
        return (
          <div
            className={[
              'fancy-table__header__item',
              this.props.sortable ? 'fancy-table__header__item--sortable' : '',
              this.sortClass,
            ].join(' ')}
            style={{ flex: 2 }}
            onClick={this.onClickSort}
          >
            <span>{this.props.label}</span>
          </div>
        );
      }
    }

    if (this.props.type === 'stats') {
      return (
        <div
          className={[
            'fancy-table__header__item',
            'vxgames__stats_table-col-width',
            this.props.sortable ? 'fancy-table__header__item--sortable' : '',
            this.sortClass,
          ].join(' ')}
          onClick={this.onClickSort}
        >
          <span>{this.props.label}</span>
        </div>
      );
    }

    return (
      <div
        className={[
          'fancy-table__header__item',
          this.props.sortable ? 'fancy-table__header__item--sortable' : '',
          this.sortClass,
        ].join(' ')}
        style={this.props.styles}
        onClick={this.onClickSort}
      >
        <span>{this.props.label}</span>
      </div>
    );
  }
}

export default FancyHeaderItem;
export { FancyHeaderItem };

FancyHeaderItem.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  sortable: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['', 'asc', 'desc']),
  onSort: PropTypes.func,
};

FancyHeaderItem.defaultProps = {};
