import { useMutation } from '@apollo/react-hooks';
import { AlertsStore } from '../../../stores/Alerts/AlertsStore';
import { _ } from '../../../util/translate';
import { MUTATION_MASTER_USERLIFECYCLE_UNSETSTEP } from '../graphql/mutations/masterUserLifeCycle';
import { useVXServicesTelegramClient } from '../../../graphql';

export const useTelegramDeleteAutoMail = () => {
  return useMutation(MUTATION_MASTER_USERLIFECYCLE_UNSETSTEP, {
    client: useVXServicesTelegramClient(),
    onError() {
      AlertsStore.add({ type: 'error', message: _('common:alerts.deleteFail') });
    },
  });
};

export default useTelegramDeleteAutoMail;
