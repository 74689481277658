import { Dispatch, useEffect } from 'react';
import { AboContentType, AboRoute, IAboWizardAction } from '../../../types';
import { getSelectContentTypeCallback } from '../contentTypes';

const useSkipContentPage = (
  aboContentTypes: AboContentType[],
  currentPage: AboRoute,
  dispatch: Dispatch<IAboWizardAction>
): void => {
  const skipContentPage = aboContentTypes.length === 1;

  useEffect(() => {
    if (currentPage === AboRoute.AboContentPage && skipContentPage) {
      const selectContentType = getSelectContentTypeCallback(dispatch);

      selectContentType(aboContentTypes[0], true);
    }
  }, [currentPage, skipContentPage, aboContentTypes]);
};

export default useSkipContentPage;
