import React, { PureComponent } from 'react';
import { AlertsStore } from '../../stores/Alerts/AlertsStore';

class Alert extends PureComponent {
  state = AlertsStore.get();

  componentDidMount() {
    AlertsStore.addChangeListener(this.onStoreChange);
  }

  componentWillUnmount() {
    AlertsStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange = () => {
    this.setState(AlertsStore.get());
  };

  onClickRemove = entry => AlertsStore.removeById(entry.id);

  render() {
    return (
      <aside className={`alert${this.state.entries.length > 0 ? ' show' : ''}`}>
        <div className="alert__column">
          {this.state.entries.map(entry => (
            <div className="alert__item" key={entry.id}>
              <div
                className={`alert__item__iconbox${
                  entry.type === 'info' ? ' alert__item__iconbox--info' : ''
                }${entry.type === 'error' ? ' alert__item__iconbox--error' : ''}`}
              />
              <div className="alert__item__title">{entry.message}</div>
              <div className="alert__item__remove" onClick={() => this.onClickRemove(entry)} />
            </div>
          ))}
        </div>
      </aside>
    );
  }
}

export default Alert;
export { Alert };
