import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';
import {
  BREAKPOINT_SPHONE_CONDITION,
  BREAKPOINT_PHONE_CONDITION,
  GRAY_2,
  WHITE_2,
} from '../../../camtool-styles';
import styled from '@emotion/styled';
import LanguageSelector from '../../TopBar/Profile/ProfileLanguageSelector';

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${GRAY_2};
`;

const TermsSection = styled.div`
  color: ${GRAY_2};
  flex: 1 0 auto;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  word-break: break-word;

  @media ${BREAKPOINT_PHONE_CONDITION} {
    flex-wrap: wrap;
    max-width: 87%;
  }

  @media ${BREAKPOINT_SPHONE_CONDITION} {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const LanguageSwitchContainer = styled.div`
  flex: 0.1 0 auto;
  justify-content: flex-end;
  position: absolute;
  right: 0;

  @media ${BREAKPOINT_SPHONE_CONDITION} {
    flex: 0 0 100%;
    top: -30px;
  }
`;

const Footer: FC = () => {
  return (
    <div
      css={{
        backgroundColor: WHITE_2,
        width: '100%',
        padding: '8px',
        alignItems: 'center',
        position: 'relative',
        display: 'flex',
        boxSizing: 'border-box',
      }}
    >
      <TermsSection>
        <StyledNavLink to={`${APP_BASE_PATH}/legal/houserule`}>
          {_('navigation:main.legal.houserule')}
        </StyledNavLink>
        &nbsp;|&nbsp;
        <StyledNavLink to={`${APP_BASE_PATH}/legal/terms`}>
          {_('navigation:main.legal.terms')}
        </StyledNavLink>
        &nbsp;|&nbsp;
        <StyledNavLink to={`${APP_BASE_PATH}/legal/gdpr`}>
          {_('common:legal.privacy')}
        </StyledNavLink>
        &nbsp;|&nbsp;
        <StyledNavLink to={`${APP_BASE_PATH}/legal/imprint`}>
          {_('navigation:main.legal.imprint')}
        </StyledNavLink>
      </TermsSection>
      <LanguageSwitchContainer>
        <LanguageSelector />
      </LanguageSwitchContainer>
    </div>
  );
};

export default Footer;
