import React, {FC} from 'react';
import styled from '@emotion/styled';
import {_} from '../../../util/translate';
import HelpcenterLink from "../../../components/Helpcenter/HelpcenterLink";

const DynamicAdvantageContainer = styled.div`
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 75%;
    align-self: center;
`;

const AdvantagesSection = styled.div`
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: center;
    height: 150px;
    width: 460px;
    flex: 0 0 auto;
    align-items: flex-start;
`;

const AdvantageContent = styled.div`
    flex-direction: column;
    height: 70%;
`;

const UnorderedList = styled.ul`
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    list-style-position: inside;
`;

const StyledListItem = styled.li`
    list-style-type: none;
    padding-bottom: 12px;
`;

const ToyControlRequirements: FC = () => {

    return (
        <DynamicAdvantageContainer>
            <AdvantagesSection>
                <AdvantageContent>
                    <h2 css={{marginBottom: '20px'}}>{_('settings:toycontrol.requirements.heading')}</h2>
                    <UnorderedList>
                        <StyledListItem>{_('settings:toycontrol.requirements.text')}</StyledListItem>
                        <StyledListItem>
                            {_('settings:toycontrol.requirements.helpcenter')}
                            &nbsp;
                            <a
                                href={HelpcenterLink() + '/articles/4547089268370'}
                                target="_blank"
                                rel="noreferrer"
                                css={{textDecoration: 'none', color: '#1f93e9', hover: {color: '#3bacf7'}}}
                            >
                                Link
                            </a>
                        </StyledListItem>
                    </UnorderedList>
                </AdvantageContent>
            </AdvantagesSection>
        </DynamicAdvantageContainer>
    );
};

export default ToyControlRequirements;
