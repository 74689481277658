export function splitStringIntoLine(maxLineLength, arr, narr = []) {
  /** Check if params have are of the required data type */
  if (typeof maxLineLength !== 'number' || !arr.forEach || !narr.forEach)
    return console.error('error@splitStringIntoLine');
  /** Index `0` of arr is null/undefined means all words were processed */
  if (!arr[0]) return narr;
  /** Index `0` of narr is null/undefinede means no word has been processed yet */
  if (!narr[0]) return splitStringIntoLine(maxLineLength, arr.slice(1), [arr[0]]);
  /** Current word CAN be put together with last word processed */
  // (maxLineLength - 1): - 1 is the space that goes between the two words
  if (arr[0].length + narr[narr.length - 1].length <= maxLineLength - 1) {
    return splitStringIntoLine(maxLineLength, arr.slice(1), [
      ...narr.slice(0, narr.length - 1),
      `${narr[narr.length - 1]} ${arr[0]}`,
    ]);
  }
  /** Current word CANNOT be put together with last word prosecced. */
  return splitStringIntoLine(maxLineLength, arr.slice(1), [...narr, arr[0]]);
}
