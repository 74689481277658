import React from 'react';
import PropTypes from 'prop-types';

class PopUpMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: props.initialState || false,
      toggleStatus: 'hide',
    };
  }

  toggleOpen = () => {
    if (this.state.toggleStatus === 'hide') {
      this.setState({ toggleStatus: 'show' });
    }
  };

  toggleClose = () => {
    if (this.state.toggleStatus === 'show') {
      this.setState({ toggleStatus: 'hide' });
    }
  };

  onClick = () => {
    if (this.props.closebtn) {
      this.props.closebtn();
    }
  };

  render() {
    const { position, headericon, headertext, closebtn, type, children, innerWrapper } = this.props;

    if (children)
      return (
        <section className={`popupframe ${position}`}>
          {headertext && (
            <header>
              {headericon && <div className={headericon} />}
              <div className="title">{headertext}</div>
              <div className="seperator" />
              {closebtn && <div className="icon-remove options" onClick={this.onClick} />}
            </header>
          )}
          <article className={type === undefined || type === true ? 'menu' : type}>
            {React.createElement(innerWrapper, {}, children)}
          </article>
        </section>
      );
    else return null;
  }
}

PopUpMenu.propTypes = {
  innerWrapper: PropTypes.oneOfType([PropTypes.symbol, PropTypes.string]),
  closebtn: PropTypes.func,
};

PopUpMenu.defaultProps = {
  innerWrapper: 'div',
  closebtn: () => {},
};

export default PopUpMenu;
export { PopUpMenu };
