import React, { FC } from 'react';
import styled from '@emotion/styled';

import { GRAY_2, GREEN, WHITE, BLACK_1 } from '../../../../camtool-styles';

const StyledDiv = styled.div`
  input[type='radio'] {
    display: none;
  }
`;

const Label = styled.label<{ active?: boolean }>`
  color: ${({ active }) => (active ? WHITE : BLACK_1)};
  background-color: ${({ active }) => (active ? GREEN : '#E1E4E5')};
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 8px 16px;
  justify-content: center;
  flex: 1;
  white-space: nowrap;
  &:last-of-type {
    margin-left: 8px;
  }
  &:hover :not(:disabled) {
    cursor: pointer;
    opacity: 0.8;
  }
`;

interface IOnOffSwitchProps {
  className?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelOn: string;
  labelOff: string;
  value: string;
  valueOn: string;
  valueOff: string;
  disabled?: boolean;
}

const OnOffSwitch: FC<IOnOffSwitchProps> = (props) => {
  return (
    <StyledDiv className={props.className}>
      <input
        type="radio"
        id={`${props.name}-on`}
        name={`${props.name}-on`}
        value={props.valueOn}
        checked={props.value === props.valueOn}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <Label htmlFor={`${props.name}-on`} active={props.value === props.valueOn}>
        {props.labelOn}
      </Label>

      <input
        type="radio"
        id={`${props.name}-off`}
        name={`${props.name}-off`}
        value={props.valueOff}
        checked={props.value === props.valueOff}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <Label htmlFor={`${props.name}-off`} active={props.value === props.valueOff}>
        {props.labelOff}
      </Label>
    </StyledDiv>
  );
};

export default OnOffSwitch;
