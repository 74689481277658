import React, { FC, useState } from 'react';
import { _ } from '../../util/translate';
import { YELLOW } from '../../camtool-styles';
import Icon from '../Icon/Icon';
import { camera } from '../Icon/libraries/glyph';
import MediaSelector from '../../components/MediaSelector/MediaSelector';
import GenericModalBoxLayout from '../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { QUERY_MODEL_MEDIA_FSK_PREVIEW_PICTURES } from '../../graphql/VXModels/queries';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Mutation } from '../../graphql/VXModels/types';
import { MUTATION_MODEL_MEDIA_DELETE_PICTURE } from '../../graphql/VXModels/mutations';
import AlertsStore from '../../stores/Alerts/AlertsStore';
import {
  OverlayWrapper,
  Title,
  HoverOverlay,
  Overlay,
  OverlayStatus,
  OverlayName,
  OverlaySpacer,
  OverlayLang,
  ModalSingleUploader,
} from './Styles';
import WarningModalBox from './WarningModalBox';

export interface Props {
  title: string;
  albumId: number | undefined;
  isChecked: boolean | undefined;
  platformContextInfo: string;
  backgroundUrl?: string | null | undefined;
  pictureId?: number;
  className?: string;
  filteredFsk?: number;
}

// component for uploading and displaying flirtcore(fsk12), softcore(fsk16) and hardcore(fsk18) sedcard at position 0
const CorePictureWidget: FC<Props> = ({
  title,
  backgroundUrl,
  albumId,
  pictureId,
  isChecked,
  platformContextInfo,
  className,
  filteredFsk,
}) => {
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [showMediaSelector, setShowMediaSelector] = useState<boolean>(false);

  const [refreshAlbum] = useLazyQuery(QUERY_MODEL_MEDIA_FSK_PREVIEW_PICTURES, {
    fetchPolicy: 'network-only',
  });

  const [deleteSedcard] = useMutation<Mutation>(MUTATION_MODEL_MEDIA_DELETE_PICTURE);

  const awaitDeleteSedcard = async (data, callback): Promise<void> => {
    const { data: idData, errors } = await deleteSedcard({ variables: { albumId, pictureId } });
    if (errors) {
      AlertsStore.add({ type: 'error', message: 'common:error.generic' });
    } else if (idData?.model?.media?.deletePicture?.id) {
      // delete was successfull if mutation delivers id
      callback(data);
    }
  };

  const prepareSedcard = (data, callback): void => {
    if (pictureId) {
      awaitDeleteSedcard(data, callback);
    } else {
      callback(data);
    }
  };

  return (
    <>
      <div
        css={{ flexDirection: 'column' }}
        className={className}
        onClick={(): void => setShowWarningModal(true)}
      >
        <OverlayWrapper backgroundUrl={backgroundUrl}>
          <Title>{title}</Title>
          <HoverOverlay>
            <Icon icon={camera} size="20px" />
            <span css={{ fontSize: 16, marginTop: 4, textAlign: 'center' }}>
              {_('mediamanagement:sedcardVX.mainPictureChange')}
            </span>
          </HoverOverlay>
          <Overlay>
            <OverlayStatus />
            <OverlayName />
            <OverlaySpacer />
            <OverlayLang />
          </Overlay>
        </OverlayWrapper>
        {!!pictureId && !isChecked && (
          <span css={{ justifyContent: 'center', color: YELLOW }}>{_('common:text.checking')}</span>
        )}
      </div>

      {showWarningModal && (
        <WarningModalBox
          platformContextInfo={platformContextInfo}
          pictureExists={!!pictureId}
          setShowWarningModal={setShowWarningModal}
          setShowMediaSelector={setShowMediaSelector}
        />
      )}

      {showMediaSelector && (
        <GenericModalBoxLayout noCloseOnESC={true} name="service0900-mediaselector" maxWidth={938}>
          {/* modalsingleuploader required for correct visual and scroll behaviour */}
          <ModalSingleUploader id="modalsingleuploader">
            <MediaSelector
              type="picture"
              editor="landscape_4_3"
              targetUmaId={albumId}
              targetParams={{ position: 0 }}
              pictureType="sedcard"
              onClose={(): void => setShowMediaSelector(false)}
              onSubmit={async (): Promise<void> => {
                await refreshAlbum();
              }}
              onBeforeUploadPicture={(data, callback): void => {
                prepareSedcard(data, callback);
              }}
              filteredFsk={filteredFsk}
            />
          </ModalSingleUploader>
        </GenericModalBoxLayout>
      )}
    </>
  );
};

export default CorePictureWidget;
