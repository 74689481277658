import styled from '@emotion/styled';
import { WHITE_3 } from '../../camtool-styles';

export const ERROR_RED = '#ff4d3c';
export const MATRIX_GREEN = '#43B31C';

export const NOT_THAT_WHITE = '#f2f2f2';
export const GRAY_8 = '#888888';

export const PageContainer = styled.article`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  background-color: #3c3d3e;
  color: #ffffff;
  padding: 0 24px;
  min-height: 48px;
  max-height: 48px;
  font-size: 16px;
  margin: 8px 8px 0 8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const SectionContent = styled.div`
  margin: 0 8px 24px;
  padding: 64px 16px;
  background-color: ${WHITE_3};
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
