import React, { FC, ReactNode, RefObject, useRef } from 'react';
import styled from '@emotion/styled';
import { ITableColumn } from './types';
import { TableHeader } from './components';
import { DARK_GRAY } from '../../camtool-styles';
import { SerializedStyles } from '@emotion/core';
import useBodyScrollbarWidth from './hooks/useBodyScrollbarWidth';

interface IProps {
  columns: ITableColumn[];
  toggleSort?: (column: ITableColumn, index: number) => void;
  children: ReactNode;
  className?: string;
  tableBodyRef?: RefObject<HTMLDivElement>;
  headerItemStyles?: SerializedStyles;
  hideHeader?: boolean;
}

const TableDiv = styled.div`
  flex: 1;
  flex-direction: column;
  color: ${DARK_GRAY};
  width: 100%;
`;

const TableBody = styled.div`
  flex-direction: column;
  overflow-y: auto;
`;

const Table: FC<IProps> = ({
  children,
  className,
  columns,
  toggleSort,
  tableBodyRef,
  headerItemStyles,
  hideHeader = false,
}) => {
  const ourTableBodyRef = useRef<HTMLDivElement>(null);
  const getTableBodyRef = (): RefObject<HTMLDivElement> => tableBodyRef || ourTableBodyRef;

  const bodyScrollbarWidth = useBodyScrollbarWidth(getTableBodyRef);

  return (
    <TableDiv className={className}>
      {!hideHeader && (
        <TableHeader
          columns={columns}
          toggleSort={toggleSort}
          headerItemStyles={headerItemStyles}
          bodyScrollbarWidth={bodyScrollbarWidth}
        />
      )}
      <TableBody ref={getTableBodyRef()}>{children}</TableBody>
    </TableDiv>
  );
};

export default Table;
