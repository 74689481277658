import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { heart } from '../../../../../atoms/Icon/libraries/glyph';
import { useSpeaksGerman } from '../../../../../util/UserData';

const LustagentenProfileItem: FC = () => {
  const speaksGerman = useSpeaksGerman();

  return (
    speaksGerman && (
      <LeftMenuItem
        title={_('navigation:main.profile.lustagenten')}
        icon={heart}
        uri="/onlineprofile/lustagenten"
      />
    )
  );
};

export default LustagentenProfileItem;
