/* eslint-disable  react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import StarRating from '../../../StarRating/StarRating';
import FormatDate from '../../../Formatter/FormatDate';
import FormatCurrency from '../../../Formatter/FormatCurrency';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import {
  BREAKPOINT_SPHONE_CONDITION,
  BREAKPOINT_DESKTOP_CONDITION,
} from '../../../../camtool-styles';

const LinkStyled = styled(Link)`
  .name {
    min-width: 70px;
  }
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -ms-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
    > div {
      -ms-flex: 1 1 50%;
      -webkit-flex: 1 1 50% !important;
      flex: 1 1 50% !important;
      margin: 5px 0;
      justify-content: center !important;
    }
    .preview {
      min-height: 30vh;
      -ms-flex: 1 1 100% !important;
      -webkit-flex: 1 1 100% !important;
      flex: 1 1 100% !important;
    }
  }
  @media ${BREAKPOINT_SPHONE_CONDITION} {
    -ms-flex: 1 1 100% !important;
    -webkit-flex: 1 1 100% !important;
    flex: 1 1 100% !important;
    margin: 16px 0 !important;
    > div {
      -webkit-flex: 0 0 auto !important;
      flex: 0 0 auto !important;
      width: 100%;
      max-width: 100%;
      margin: 16px 0 !important;
    }
  }
`;

const formatRating = (rating) => {
  if (rating > 0) {
    return Math.round(rating);
  }
  return 0;
};

const VideoListItem = (props) => {
  const { data, path, collection } = props;

  const hasPreview = data.video && data.video.previewPicture && data.video.previewPicture.url;

  const isLighter = data.status === 'rejected';
  const isRejected = data.status === 'rejected';
  const isDisabled = data.status === 'move' || data.status === 'transcoding';
  const showShopColumns = collection === 'shop';

  return (
    <LinkStyled
      to={`${path}/${data.id}`}
      className={cx({
        'video-content-item': true,
        disabled: isDisabled,
        lighter: isLighter,
        'has-banner': false,
      })}
    >
      <div className="preview">
        <div
          className="preview-image"
          style={hasPreview ? { backgroundImage: `url('${data.video.previewPicture.url}')` } : null}
        >
          {!hasPreview ? _('mediamanagement:picfolder.common.preview.noPreview') : ''}
        </div>
      </div>

      <div className={`status ${data.video.status}`}>
        <div>{_('mediamanagement:video.common.status' + data.video.status)}</div>
        {isRejected && (
          <article className="frame">
            <pre>{rejectionReason}</pre>
          </article>
        )}
      </div>

      <div className={`type ${data.mediaTypeName}`}>
        <div>{_(`mediamanagement:video.type.${data.mediaTypeName}`)}</div>
      </div>

      <div className="name">
        <div>{data.titles.length > 0 && data.titles[0].text}</div>
      </div>

      <div className="uploaded">
        <div>
          <FormatDate value={data.created} />
        </div>
      </div>

      <div className="ratings">
        <StarRating id={data.id} value={formatRating(data.rating)} />
        <div>{data.feedbacksTotal}</div>
      </div>

      {showShopColumns && (
        <div className="sales">
          <div>{data.salesTotal}</div>
        </div>
      )}
      {showShopColumns && (
        <div className="price">
          <span className="icon-money price__icon" />
          <FormatCurrency
            className={cx({ price__discounted: data.discountPercent > 0 })}
            value={data.price}
          />
        </div>
      )}
      {showShopColumns && (
        <div className="discount">
          {data.discountPercent > 0 && (
            <div className="discount__badge">- {data.discountPercent} %</div>
          )}
          {data.discountPercent > 0 && (
            <div className="discount__end-date">
              {data.discountEnd && <span style={{ marginRight: 4 }}>{_('common:text.to')}</span>}
              <FormatDate value={data.discountEnd} />
            </div>
          )}
          {data.discountPercent <= 0 && <div>—</div>}
        </div>
      )}
    </LinkStyled>
  );
};

VideoListItem.propTypes = {
  path: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
};

VideoListItem.defaultProps = {};

export default VideoListItem;
export { VideoListItem };
