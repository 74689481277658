import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BREAKPOINT_PHONE_CONDITION, GRAY_2 } from '../../../camtool-styles';
import { _ } from '../../../util/translate';
import { hasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';

const ListSection = styled.ul`
  display: inline-flex;
  list-style: none;
  width: 100%;
  color: ${GRAY_2};

  & > li:not(:first-of-type) {
    margin-left: 5px;
    &::before {
      content: '\u2022';
      margin-right: 2px;
    }
  }

  @media ${BREAKPOINT_PHONE_CONDITION} {
    display: block;
    & > li::before {
      content: '\u2022';
      margin-right: 2px;
    }
    & > li:not(:first-of-type) {
      margin-left: 0px;
    }
  }
`;

interface Props {
  username: string | undefined;
  modelId: string | undefined;
  hotlineCode: string | undefined;
}

const AccountOverviewList: FC<Props> = ({ username, modelId, hotlineCode }) => {
  return (
    <ListSection>
      <li>
        <span>
          {`${_('common:text.account')}:`} {username}
        </span>
      </li>
      <li>
        <span>
          {`${_('common:modelid.label')}:`} {modelId}
        </span>
      </li>
      {hasRole(SecurityRole.VX_USER_MASTER) && (
        <li>
          <span>
            {`${_('startpage:loginlayer.hotlineCode')}:`} {hotlineCode}
          </span>
        </li>
      )}
    </ListSection>
  );
};

export default AccountOverviewList;
