import { makeStyles, createStyles } from '@material-ui/core';
import { VXGAMES_PRIMARY } from '../../../utils';

export const useStepperStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '16px',
      border: 'none',
      background: 'none',
    },
    dot: {
      width: '12px',
      height: '12px',
    },
    dots: {
      gridGap: '15px',
    },
    dotActive: {
      backgroundColor: VXGAMES_PRIMARY,
    },
  })
);
