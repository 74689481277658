import { useMutation, MutationTuple } from '@apollo/react-hooks';
import { MUTATION_MASTER_CANCEL_BROADCAST } from '../graphql/mutations';
import { useVXServicesTelegramClient } from '../../../graphql';
import { useVXServicesTelegramContext } from './index';
import { Mutation } from '../../../graphql/VXServicesTelegram/types';
import { QUERY_MASTER_USER_BROADCASTLIST } from '../graphql/queries';

const useCancelBroadcast = (
  broadcastId: number,
  shouldDelete?: boolean
): MutationTuple<
  Mutation,
  {
    token: string | null | undefined;
    broadcastId: number;
  }
> => {
  const { token } = useVXServicesTelegramContext();
  const client = useVXServicesTelegramClient();
  return useMutation<Mutation>(MUTATION_MASTER_CANCEL_BROADCAST, {
    client,
    variables: {
      token,
      broadcastId,
      revoke: shouldDelete,
    },
    refetchQueries: [{ query: QUERY_MASTER_USER_BROADCASTLIST, variables: { token } }],
    awaitRefetchQueries: true,
  });
};

export default useCancelBroadcast;
