import Formsy from 'formsy-react';

class Form extends Formsy.Form {
  constructor(props) {
    super(props);
    this.getDisplayErrorInputs = this.getDisplayErrorInputs.bind(this);
    this.getErrorInputNames = this.getErrorInputNames.bind(this);
  }

  getErrorInputNames() {
    const errorInputs = [];
    for (let input of this.inputs) {
      if (!input.isValid()) {
        errorInputs.push(input.props.name);
      }
    }
    return errorInputs;
  }

  getDisplayErrorInputs(errorInputs) {
    const displayErrorInputs = {};
    for (let input of this.inputs) {
      if (!input.isValid() && errorInputs.indexOf(input.props.name) !== -1) {
        displayErrorInputs[input.props.name] = input.getCustomErrorMessage();
      }
    }
    return displayErrorInputs;
  }
}

export default Form;
export { Form };
