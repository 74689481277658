import { Override } from './types';
import { GRAY_ULTRATHIN } from '../../../camtool-styles';

export const MuiTableRow: Override<'MuiTableRow'> = {
  root: {
    '&$hover:hover': {
      backgroundColor: GRAY_ULTRATHIN,
    },
  },
};
