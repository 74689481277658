import React from 'react';
import styled from '@emotion/styled';

import AbstractComponent from '../../AbstractComponent';

import { SessionStore } from '../../../stores/Session/Session';
import { MESSENGER_URL } from '../../../config';
import { withAppState } from '../../../util/AppState';
import { NEW_MESSENGER_URL } from '../../../config';

const StyledArticle = styled.article`
  font-size: 17px;
`;

class Chat extends AbstractComponent {
  static formatValue(value) {
    return value > 99 ? '+99' : value;
  }

  constructor(props) {
    super(props);
    const sessionData = SessionStore.get();
    this.state = {
      hasNewMessages: sessionData.messenger.hasNewMessage || false,
      unseenChannels: sessionData.messenger.unseenChannels || 0,
      unansweredChannels: sessionData.messenger.unansweredChannels || 0,
    };
  }

  componentDidMount() {
    SessionStore.addChangeListener(this.onStoreChange);
  }

  componentWillUnmount() {
    SessionStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange() {
    const sessionData = SessionStore.get();
    this.setState({
      hasNewMessages: sessionData.messenger.hasNewMessage,
      unseenChannels: sessionData.messenger.unseenChannels,
      unansweredChannels: sessionData.messenger.unansweredChannels,
    });
  }

  render() {
    const [{ authToken }] = this.props.appState;
    const isVerified = this.props.isVerified;

    return (
      <StyledArticle
        className="topbar__option topbar__chat"
        css={!isVerified && { pointerEvents: 'none' }}
      >
        <a
          className="topbar__chat__link"
          css={!isVerified && { color: 'gray' }}
          href={`${NEW_MESSENGER_URL}?foreignSID=${authToken}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {this.state.hasNewMessages && (
            <div className="topbar__option__dot">
              {Chat.formatValue(this.state.unansweredChannels)}
            </div>
          )}
        </a>
      </StyledArticle>
    );
  }
}

export default withAppState(Chat);
