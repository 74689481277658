import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { isWithin24Hours } from '../../../util/DateAndTime';

const StyledFooter = styled.footer`
  height: 30px;
  position: absolute;
  color: #6c6c6c;
  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  cursor: default;
`;

const Footer = ({ timestamp, rejectedReason, likes, views }) => {
  if (rejectedReason) {
    return (
      <StyledFooter>
        <span className={`icon-info-sign ${css({ color: '#ffb033', paddingRight: 4 })}`} />
        <span>Abgelehnt</span>
      </StyledFooter>
    );
  }

  if (isWithin24Hours(timestamp)) {
    return (
      <StyledFooter>
        <span className={`icon-ok ${css({ color: '#43b31c', paddingRight: 4 })}`} />
        <span>Zur Story hinzugefügt</span>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter>
      <div>
        <span className={`icon-heart ${css({ color: '#ff4d3c', paddingRight: 4 })}`} />
        <span>{likes} Likes</span>
      </div>

      <div>
        <span className={`icon-eye-open ${css({ paddingRight: 4 })}`} />
        <span>{views} Views</span>
      </div>
    </StyledFooter>
  );
};

export default Footer;
