import React, { Fragment } from 'react';

import { Spinner, T } from '../../../../components';
import OverviewProfilesVX from '../../../../components/MediaManagement/ExplorerPicture/OverviewProfilesVX/OverviewProfilesVX';
import OverviewProfilesLA from '../../../../components/MediaManagement/ExplorerPicture/OverviewProfilesLA/OverviewProfilesLA';
import { QUERY_PHOTO_ALBUMS_BY_TYPE } from '../../../../graphql/VXModels/queries';
import { Query } from '@apollo/react-components';
import { APP_BASE_PATH } from '../../../../util/env';
import PropTypes from 'prop-types';

const AlbumsProfiles = ({ setCurrentAlbumType }) => {
  setCurrentAlbumType('profile');

  return (
    <Query
      query={QUERY_PHOTO_ALBUMS_BY_TYPE}
      fetchPolicy="network-only"
      variables={{ type: 'profile' }}
    >
      {({ loading, data, error }) => {
        if (loading) return <Spinner />;
        if (error) return console.error(error);

        const albums = (data.model.photoAlbums && data.model.photoAlbums.albums) || null;

        const selectOverviewItem = () => console.log('ey');
        const relativePath = `${APP_BASE_PATH}/mediamanagement/picture/album`;

        let hasVxProfiles = false;
        let hasLaProfiles = false;

        for (let album of albums) {
          hasLaProfiles = hasLaProfiles || album.albumType === 'lustagenten';
          hasVxProfiles =
            hasVxProfiles || album.albumType === 'profile18' || 'profile16' || 'profile12';
        }

        return (
          <div className="grid__box__column grid__box__sub">
            {hasVxProfiles && (
              <Fragment>
                <div className="grid__box__sub__header">
                  <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                    <T _={'mediamanagement:sedcardOverview.sedcardVX.header'} />
                  </div>
                </div>
                <OverviewProfilesVX
                  albums={albums}
                  isLoading={loading}
                  onSelectItem={selectOverviewItem}
                  relativePath={relativePath}
                />
              </Fragment>
            )}

            {hasLaProfiles && (
              <Fragment>
                <div className="grid__box__sub__header">
                  <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                    <T _={'mediamanagement:sedcardOverview.sedcardLA.header'} />
                  </div>
                </div>
                <OverviewProfilesLA
                  albums={albums}
                  isLoading={loading}
                  onSelectItem={selectOverviewItem}
                  relativePath={relativePath}
                />
              </Fragment>
            )}
          </div>
        );
      }}
    </Query>
  );
};

AlbumsProfiles.propTypes = {
  setCurrentAlbumType: PropTypes.function,
};
export default AlbumsProfiles;
