import AppDispatcher from '../../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import ActionTypes from '../../../util/constants/ActionTypes';
import { EventEmitter } from 'events';

let CHANGE_EVENT = 'ListsVXFavourites.change';

let _vxfavourites = {
  userlist: [],
  totalEntries: 0,
  showSpinner: true,
  loadMoreButtonDisabled: false,
  sorting: {
    field: 'lastOnline',
    direction: 'desc',
  },
  pagination: {
    limit: 8,
    offset: 1,
  },
};

class VXFavouritesStoreClass extends EventEmitter {
  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  get() {
    return _vxfavourites;
  }
}

const VXFavouritesStore = new VXFavouritesStoreClass();

AppDispatcher.register(function(payload) {
  switch (payload.type) {
    case ActionTypes.LISTS_VXFAVOURITES_RECEIVE_DATA:
      _vxfavourites = update(_vxfavourites, { $merge: payload.data });
      VXFavouritesStore.emitChange();
      break;
    case ActionTypes.LISTS_VXFAVOURITES_APPEND_DATA:
      _vxfavourites.userlist = update(_vxfavourites.userlist, { $push: payload.data });
      VXFavouritesStore.emitChange();
      break;
    default:
  }
});

export { VXFavouritesStore };
