import { convertArrayIntoSpokenArray } from '../../../utils';

export const convertParticipatingModels = (
  participants: VXGChallengeUserStatistic[] | undefined,
  username: string | undefined
) => {
  const filteredArray = participants
    .slice(0, 10)
    .filter((participant) => participant.userName !== username);

  return convertArrayIntoSpokenArray(filteredArray);
};
