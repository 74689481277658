import styled from '@emotion/styled';
import { WHITE_2 } from '../../camtool-styles';

export const DAY_SIZE = 40;

export const Container = styled('section')`
  width: ${DAY_SIZE * 7}px;
  display: flex;
  flex-direction: column;
  & input {
    padding: 4px 8px;
  }
`;

export const DayLabel = styled('div')`
  width: ${DAY_SIZE}px;
  height: ${DAY_SIZE}px;
  justify-content: center;
  align-items: center;
`;

export const ChangeMonthButton = styled('button')`
  outline: none;
  border: none;
  background: none;
  width: ${DAY_SIZE}px;
  height: ${DAY_SIZE}px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  color: ${WHITE_2};
`;

export const DayContainer = styled('div')`
  width: ${DAY_SIZE}px;
  height: ${DAY_SIZE}px;
  border-bottom: 1px solid #dcdcde;
`;

export const DayContent = styled('button')`
  outline: none;
  border: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 2px 0;
  border-radius: ${({ first, last }) =>
    first && last ? '16px' : first ? '16px 0 0 16px' : last ? '0 16px 16px 0' : 0};
  background-color: ${(props) => (props.selected ? '#ff4d3c' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#3c3d3e')};
  border: ${({ highlighted }) => (highlighted ? '1px dashed #ff4d3c' : 'none')};
  &:hover {
    &:not([disabled]) {
      cursor: pointer;
      opacity: 0.6;
    }
  }
  &[disabled] {
    color: #dcdcde;
  }
`;
