import React, { FC, useEffect } from 'react';
import {
  MailingRecipientsGroupsEnum,
  MailingRecipientsGroupsFiltersEnum,
  MailingTypeEnum,
} from '../../../../graphql/VXModels/types';
import { useMailingsRecipientsCount } from '../../hooks/useMailingsRecipientsCount';
import { RED, YELLOW } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';
import { SpinnerInline } from '../../../../components';
import WarningIcon from '@material-ui/icons/Warning';

interface FilterReceiversCountProps {
  type: MailingTypeEnum;
  recipientsGroup: MailingRecipientsGroupsEnum;
  filter: MailingRecipientsGroupsFiltersEnum[];
  count?: number;
  onUpdate?: (count: number) => void;
}

export const FilterRecipientsCount: FC<FilterReceiversCountProps> = ({
  type,
  recipientsGroup,
  filter = [],
  onUpdate,
  count: countProps,
}) => {
  const {
    count = countProps,
    error,
    loading,
  } = useMailingsRecipientsCount(type, recipientsGroup, filter, {
    skip: countProps !== undefined,
    onCompleted: (data): void =>
      onUpdate && onUpdate(data?.model?.mailings?.recipients?.count || 0),
  });

  useEffect(() => {
    if (loading && !!onUpdate) onUpdate(0);
  }, [loading]);

  const getChannelOrCount = (count: number | undefined): string => {
    if (count === 0) {
      return `${_('components:Routes.Mailings.FieldMailingsRecipientsFilter.channelSubscribers')}`;
    } else {
      return `${_(
        'components:Routes.Mailings.FieldMailingsRecipientsFilter.recipientsCount'
      )}: ${count}`;
    }
  };

  return error ? (
    <span style={{ color: RED }}>
      {_('components:Routes.Mailings.FieldMailingsRecipientsFilter.error')}
    </span>
  ) : loading || count === -1 ? (
    <>
      <span>{`${_('components:spinner.text')}`}</span>
      <SpinnerInline />
    </>
  ) : (
    <span>
      {getChannelOrCount(count)}
      {count < 1 && <WarningIcon style={{ color: YELLOW }} />}
    </span>
  );
};
