import React, { FC } from 'react';
import { Maybe, MediaFeedback } from '../../../graphql/VXModels/types';
import CommentsDisplay from './CommentsDisplay';
import CommentsDetails from './CommentsDetails';
interface Props {
  commentAmount: number;
  feedbackComments: Maybe<MediaFeedback>[];
  umaId: number;
  modelAvatar: string | null | undefined;
}

const CommentsOverview: FC<Props> = ({ commentAmount, feedbackComments, modelAvatar, umaId }) => {
  return (
    <>
      <CommentsDisplay commentAmount={commentAmount} />
      <CommentsDetails
        feedbackComments={feedbackComments}
        umaId={umaId}
        modelAvatar={modelAvatar}
      />
    </>
  );
};

export default CommentsOverview;
