import React, { FC } from 'react';
import { useMedia } from 'react-media';
import { css } from '@emotion/core';
import {
  MediaUploadInputLabelSize,
  MediaUploadInputLayoutDirection,
  MediaUploadInputProps,
} from '../MediaUploadInput';
import {
  RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY,
  RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL,
  RESPONSIVE_UPLOAD_STYLES_INPUT_HEIGHT,
} from './constants';

const withResponsiveUploadStyles = <Props extends {}>(
  WrappedComponent: FC<
    Props & Pick<MediaUploadInputProps, 'className' | 'labelSize' | 'layoutDirection'>
  >
): FC<Props> => {
  const styles = css`
    height: ${RESPONSIVE_UPLOAD_STYLES_INPUT_HEIGHT}px;
    width: ${RESPONSIVE_UPLOAD_STYLES_INPUT_HEIGHT}px;

    ${RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL} {
      width: auto;
    }
  `;

  const Result: FC<Props> = ({ ...props }) => {
    const isMobile = useMedia({ query: RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY });

    return (
      <WrappedComponent
        {...props}
        css={styles}
        labelSize={isMobile ? MediaUploadInputLabelSize.sm : MediaUploadInputLabelSize.md}
        layoutDirection={
          isMobile
            ? MediaUploadInputLayoutDirection.horizontal
            : MediaUploadInputLayoutDirection.vertical
        }
      />
    );
  };

  Result.displayName = `withResponsiveUploadStyles(${WrappedComponent.displayName})`;

  return Result;
};

export default withResponsiveUploadStyles;
