import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../util/ServerUtils';
import ActionTypes from '../../util/constants/ActionTypes';

class SettingsActionCreatorsClass {
  getChatSettings() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/settings/chatSettings', null, function(
      response
    ) {
      SettingsActionCreators.receiveData('chatSettings', response);
    });
  }
  saveChatSettings(data) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/settings/chatSettings',
      { data: JSON.stringify(data) },
      function(response) {
        SettingsActionCreators.receiveData('chatSettings', response);
      }
    );
  }
  getNotificationSettings() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/settings/notifications', null, function(
      response
    ) {
      SettingsActionCreators.receiveData('notifications', response);
    });
  }
  saveNotificationSettings(data) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/settings/notifications',
      { data: JSON.stringify(data) },
      function(response) {
        SettingsActionCreators.receiveData('notifications', response);
      }
    );
  }
  receiveData(store, data) {
    AppDispatcher.dispatch({
      type: ActionTypes.SETTINGS_RECEIVE_DATA,
      store: store,
      data: data,
    });
  }
}

const SettingsActionCreators = new SettingsActionCreatorsClass();

export { SettingsActionCreators };
