import React, { FC } from 'react';
import styled from '@emotion/styled';
import Flag, { getLabel } from '../../atoms/Flag';
import { Lang } from '../../util/constants/Lang';

import { BLACK_1, WHITE, GRAY_1, GRAY_3, RED, YELLOW } from '../../camtool-styles';
import { ApiLang } from '../../graphql/VXModels/types';
import { css, SerializedStyles } from '@emotion/core';

const LanguageButton = styled.button<
  Pick<Props, 'active' | 'disabled' | 'hasError' | 'hasWarning'>
>`
  border: 1px solid ${GRAY_3};
  ${({ active }): SerializedStyles =>
    active
      ? css`
          border-bottom: none;
          background-color: ${WHITE};
          color: ${BLACK_1};
        `
      : css`
          background-color: transparent;
          color: ${GRAY_1};
        `};
  height: 32px;
  border-bottom: none;
  padding: 8px;
  align-items: center;
  outline: none;
  border-radius: 2px;
  &:not(:first-of-type) {
    border-left: none;
  };
  border-color: ${(props): string => (props.hasError ? RED : props.hasWarning ? YELLOW : '')};
  &:hover {
    background-color: ${({ disabled, active }): string => {
      if (disabled) {
        return 'auto';
      }
      return active ? WHITE : GRAY_3;
    }};
`;

interface Props {
  lang: Lang;
  title?: string;
  active?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  hasWarning?: boolean;
  onClick: () => void;
}

const FlagButton: FC<Props> = ({
  lang = ApiLang.de,
  title,
  hasError = false,
  hasWarning = false,
  active = false,
  disabled = false,
  onClick,
}) => {
  return (
    <LanguageButton
      type="button"
      active={active}
      disabled={disabled}
      hasError={hasError}
      hasWarning={hasWarning}
      onClick={onClick}
    >
      <Flag lang={lang} css={{ marginRight: '4px' }} />
      {title ? <div>{title}</div> : <div>{getLabel(lang)}</div>}
    </LanguageButton>
  );
};

export default FlagButton;
