import React, {FC} from 'react';
import {_} from '../../../../../util/translate';
import HelpcenterLink from "../../../../../components/Helpcenter/HelpcenterLink";

const BA6SettingsHeader: FC = () => {
    const scopedPhoneSexUrl = HelpcenterLink() + '/articles/360010946640';
    return (
        <header css={{justifyContent: 'center', marginBottom: '24px'}}>
            <div
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '5px',
                }}
            >
        <span css={{fontSize: '24px', fontWeight: 'bold', marginBottom: '7px'}}>
          {_('service0900:PhoneSex.active.profile.title')}
        </span>
                <span css={{fontSize: '16px', marginBottom: '7px'}}>
          {_('service0900:PhoneSex.active.profile.headerText')}
        </span>
                <a
                    href={scopedPhoneSexUrl}
                    css={{
                        fontSize: 12,
                        textDecoration: 'none',
                        fontWeight: 'normal',
                        justifyContent: 'center',
                    }}
                >
                    <span css={{fontSize: '14px'}}>{_('service0900:PhoneSex.active.profile.tips')}</span>
                    <span css={{marginLeft: 8}} className="icon-arrow-right"/>
                </a>
            </div>
        </header>
    );
};

export default BA6SettingsHeader;
