import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedAmount } from '../../../../../util/Formatter';
import { useLang } from '../../../../../util/AppState';
import { T } from '../../../../../components/Translation/Translation';

const FormatPreviewPrice = ({ className, value, currency }) => {
  const [lang] = useLang();

  return (
    <span className={className}>
      <T _={'mediamanagement:video.content.price'} />
      :&nbsp;
      {getFormattedAmount(value, lang, currency)}
    </span>
  );
};

FormatPreviewPrice.defaultProps = {
  className: '',
  value: 0,
  currency: 'EUR',
};

FormatPreviewPrice.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  currency: PropTypes.oneOf(['EUR', 'USD']),
};

export default FormatPreviewPrice;
export { FormatPreviewPrice };
