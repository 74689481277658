import React from 'react';
import AbstractComponent from '../AbstractComponent';
import { PhotoGalleryStore } from '../../stores/PhotoGallery/PhotoGalleryStore';

import Spinner from '../Spinner/Spinner';

class PhotoGallery extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataFromStore: PhotoGalleryStore.get(),
    };
  }

  closePhotoGallery() {
    PhotoGalleryStore.closePhotoGallery();
  }

  handleStoreChange() {
    this.setState({
      dataFromStore: PhotoGalleryStore.get(),
    });
  }

  handleKeyDown(e) {
    if (e.key === 'Escape') {
      this.closePhotoGallery();
    }
  }

  handleClickOnParent(e) {
    e.preventDefault();
    // close the photo gallery if user clicks outside the modal dialog
    if (e.target.classList.contains('photo-gallery')) {
      this.closePhotoGallery();
    }
  }

  imageDidLoad() {
    PhotoGalleryStore.setIsGalleryOpen(false);
  }

  componentWillUnmount() {
    PhotoGalleryStore.removeChangeListener(this.handleStoreChange);
  }

  componentDidMount() {
    PhotoGalleryStore.addChangeListener(this.handleStoreChange);
  }

  componentDidUpdate() {
    this.refs.photoGallery.focus(); // We need to focus the gallery to be able to detect keyPress when it is open.
  }

  render() {
    return (
      <article
        className={[
          'photo-gallery',
          this.state.dataFromStore.photos.length > 0 ? 'photo-gallery--show' : '',
        ].join(' ')}
        ref="photoGallery"
        tabIndex="0"
        onKeyDown={this.handleKeyDown}
        onClick={this.handleClickOnParent}
      >
        <section className="photo-gallery__content">
          <section className="photo-gallery__image-container">
            <section className="photo-gallery__image-left">
              <div className="photo-gallery__image-left__item" />

              <div className="photo-gallery__image-left__item">
                {/* For PhotoGallery v2
                                    <div className="vxicon icon-single-arrow-left-line photo-gallery__icon" onClick="{console.log('prev')}"></div>
                                */}
              </div>

              <div className="photo-gallery__image-left__item" />
            </section>

            <section className="photo-gallery__image-middle">
              <img
                className="photo-gallery__image-middle__photo"
                src={this.state.dataFromStore.photos[0]}
                onLoad={this.imageDidLoad}
              />
              {this.state.dataFromStore.isGalleryOpen ? <Spinner /> : null}
            </section>

            <section className="photo-gallery__image-right">
              <div className="photo-gallery__image-right__item">
                <div
                  className="vxicon icon-close-line photo-gallery__icon"
                  onClick={this.closePhotoGallery}
                />
              </div>

              <div className="photo-gallery__image-right__item">
                {/* For PhotoGallery v2
                                    <div className="vxicon icon-single-arrow-right-line photo-gallery__icon" onClick="{console.log('next')}"></div>
                                */}
              </div>

              <div className="photo-gallery__image-right__item" />
            </section>
          </section>

          <footer className="photo-gallery__footer" />
        </section>
      </article>
    );
  }
}

PhotoGallery.propTypes = {};

PhotoGallery.defaultProps = {};

export default PhotoGallery;
export { PhotoGallery };
