import { Override } from './types';
import {
  BLUE,
  BLUE_HOVER,
  GRAY_2,
  GRAY_3,
  GRAY_5,
  GREEN,
  GREEN_HOVER,
  BLACK,
  DARK_GRAY,
} from '../../../camtool-styles';

export const MuiButton: Override<'MuiButton'> = {
  root: {
    border: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    justifyContent: 'center',
    textDecoration: 'none',
    color: GRAY_2,
    '&$disabled': {
      pointerEvents: 'none',
    },

    // medium size
    height: '40px',
    padding: '0px 30px',
    fontSize: '16px',
  },

  outlined: {
    border: '1px solid' + GRAY_2,

    '&$disabled': {
      backgroundColor: 'transparent',
      borderColor: GRAY_3,
      color: GRAY_3,
    },
  },

  contained: {
    '&$disabled': {
      color: DARK_GRAY,
      backgroundColor: GRAY_5,
      pointerEvents: 'none',
    },
  },

  sizeLarge: {
    height: '55px',
    padding: '0px 45px',
    fontSize: '21px',

    BREAKPOINT_PHONE: {
      fontSize: '16px',
    },
  },

  containedPrimary: {
    '&:hover': {
      backgroundColor: BLUE_HOVER,
    },
  },

  containedSecondary: {
    '&:hover': {
      backgroundColor: GREEN_HOVER,
    },
  },

  outlinedPrimary: {
    color: BLUE,
    border: '1px solid ' + BLUE,
    '&:hover': {
      color: BLUE_HOVER,
      border: '1px solid ' + BLUE_HOVER,
    },
  },

  outlinedSecondary: {
    color: GREEN,
    border: '1px solid ' + GREEN,
    '&:hover': {
      color: GREEN_HOVER,
      border: '1px solid ' + GREEN_HOVER,
    },
  },

  text: {
    color: BLACK,
    '&$disabled': {
      color: GRAY_2,
    },
  },

  iconSizeLarge: {
    '& > *:first-child': {
      fontSize: '25px',
    },
  },
};
