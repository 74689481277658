import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    app: App!
  }

  extend type App {
    uploadManager: UploadManager!
  }

  type UploadManager {
    queue: UploadManagerQueue!
    fileMeta(id: ID!): UploadManagerFileMeta!
  }

  type UploadManagerQueue {
    items: [UploadManagerQueueItem!]!
  }

  type UploadManagerQueueItem {
    id: ID!
    displayName: String!
    #    file: JavascriptFile!
  }

  type UploadManagerFileMeta {
    md5Hash: String
  }
`;
