import React, { FC } from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { VXGAMES_TYPOGRAPHY_H2, VXGAMES_TYPOGRAPHY_PARAGRAPH } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      paddingBottom: 30,
    },
  })
);

const Heading: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Typography style={VXGAMES_TYPOGRAPHY_H2} gutterBottom>
        Teilnehmer der Herausforderung
      </Typography>
      <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH}>
        Hier kannst du sehen wer bereits an der Herausforderung teilgenommen hat.
      </Typography>
    </Box>
  );
};

export { Heading };
