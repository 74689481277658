import { Box } from '@material-ui/core';
import React, { FC, useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { Slider } from '../../atoms';

const MAX_ZOOM = 2;

interface NewCropperProps {
  url: string;
  aspect: number;
  setCroppedAreaPixels: (croppedAreaPixels: Area) => void;
  cropShape: 'circle' | string;
  disable?: boolean;
}

const NewCropper: FC<NewCropperProps> = ({
  url,
  aspect,
  setCroppedAreaPixels,
  cropShape,
  disable,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number | number[]>(1);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <>
      <Box display="flex" position="relative" flexDirection="column" width="100%" height="400px">
        <Cropper
          image={url}
          crop={crop}
          zoom={zoom as number}
          maxZoom={MAX_ZOOM}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          // transform shape name convention here instead of rewriting data structure of many components
          cropShape={cropShape === 'circle' ? 'round' : 'rect'}
        />
      </Box>
      <Box maxWidth="100%">
        <Slider
          disabled={disable}
          value={zoom}
          min={1}
          max={MAX_ZOOM}
          step={0.1}
          onChange={setZoom}
        />
      </Box>
    </>
  );
};

export default NewCropper;
