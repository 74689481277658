import gql from 'graphql-tag';

export const MUTATION_SAVE_CONFIGURATION = gql`
  mutation MUTATION_SAVE_CONFIGURATION(
    $id: Int
    $comment: String
    $limitConfig: Int
    $limitInstance: Int
    $validFrom: String
    $validTo: String
    $subrefs: [String]
    $conditions: InputConditions
    $benefits: [InputBenefit]
    $texts: [InputText]
  ) {
    config {
      save(
        config: {
          id: $id
          name: "CREATED_BY_MODEL"
          comment: $comment
          limitConfig: $limitConfig
          limitInstance: $limitInstance
          validFrom: $validFrom
          validTo: $validTo
          subrefs: $subrefs
          conditions: $conditions
          benefits: $benefits
          texts: $texts
        }
      ) {
        id
        name
      }
    }
  }
`;

export const MUTATION_CREATE_CODE = gql`
  mutation MUTATION_CREATE_CODE($configId: Int!, $code: String!) {
    config {
      createCode(configId: $configId, code: $code)
    }
  }
`;

export const MUTATION_DEACTIVATE_CODE = gql`
  mutation MUTATION_DEACTIVATE_CODE($code: String!) {
    config {
      deactivateCode(code: $code) {
        code
        status
      }
    }
  }
`;
