import React, { FC, useMemo } from 'react';
import {
  AttachmentInputOrFile,
  getPreviewFileTypeFromAttachment,
  isAttachment,
} from '../../../../../../../util/FileUtils';
import {
  MediaUploadInput,
  MediaUploadInputProps,
} from '../../../../../../../molecules/FileUpload/MediaUploadInput';
import { Attachment, FileStorageSettings } from '../../../../../../../graphql/VXModels/types';
import { FileDescriptor } from '../../../../../../../molecules/FileUpload/PreviewFile';
import { useAttachmentPreviewUrl } from '../../../../../hooks/useAttachmentPreviewUrl';

type Props = Omit<MediaUploadInputProps, 'currentFile'> & {
  attachment?: AttachmentInputOrFile | Attachment;
  fileStorageSettings: FileStorageSettings;
};

/**
 * Reusable Attachment upload input
 *
 * Wraps MediaUploadInput and makes it work with our Attachment types (should not do anything else!)
 */
const AttachmentUploadInput: FC<Props> = ({ attachment, fileStorageSettings, ...rest }) => {
  const { previewUrl } = useAttachmentPreviewUrl(fileStorageSettings, attachment, {
    skip: !attachment,
  });

  const currentFile = useMemo(
    (): FileDescriptor | undefined =>
      attachment
        ? {
            previewUrl: previewUrl || undefined,
            ...(isAttachment(attachment)
              ? {
                  url: (attachment.url || attachment.payload) as string,
                  type: getPreviewFileTypeFromAttachment(attachment),
                }
              : { file: attachment.file }),
          }
        : undefined,
    [attachment, previewUrl]
  );

  return <MediaUploadInput {...rest} currentFile={currentFile} />;
};

export default AttachmentUploadInput;
