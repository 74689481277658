import React, { FC } from 'react';
import { _ } from '../../util/translate';
import { ApiLang } from '../../graphql/VXModels/types';
import FlagButton from '../FlagButton';
import { TextareaField } from '..';

interface Props {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  showEditIcon?: boolean;
  languages: ApiLang[];
  title?: string;
  selectedLang: ApiLang;
  onSelectedLangChange: (lang: ApiLang) => void;
  maxLength?: number;
  showCharCount?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  warningMessage?: string;
  className?: string;
}

const MultiLangTextAreaField: FC<Props> = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  languages,
  title,
  selectedLang,
  onSelectedLangChange,
  maxLength = 500,
  showCharCount = !!maxLength,
  disabled = false,
  errorMessage,
  warningMessage,
  className,
}) => {
  return (
    <div css={className}>
      <div css={{ position: 'relative', bottom: -1 }}>
        {languages.map((lang: ApiLang) => (
          <FlagButton
            hasError={!!errorMessage}
            hasWarning={!!warningMessage}
            active={lang === selectedLang}
            key={lang}
            title={title}
            lang={lang}
            onClick={(): void => onSelectedLangChange(lang)}
          />
        ))}
      </div>

      <TextareaField
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        rows={8}
        maxLength={maxLength}
        disabled={disabled}
        roundedTopLeftCorner={false}
        showCharCount={showCharCount}
        errorMessage={errorMessage}
        hasError={!!errorMessage}
        warningMessage={warningMessage}
        hasWarning={!!warningMessage}
      />
    </div>
  );
};

export default MultiLangTextAreaField;
