import React, { FC, Fragment } from 'react';

import { IBenefitsCreateState, VoucherType } from '../../types';
import { getIconByType } from '../../Icons';
import { dateTimeISOtoATOM, ICONS } from '../../utils';
import { getFormattedDate } from '../../../../util/Formatter';
import { useLang } from '../../../../util/AppState';
import { _ } from '../../../../util/translate';
import { WHITE_2, WHITE, GRAY_4 } from '../../../../camtool-styles';

interface IVoucherPreviewProps {
  state: IBenefitsCreateState;
  type?: VoucherType;
  textLanguage: 'de' | 'en';
  className?: string;
}

const VoucherPreview: FC<IVoucherPreviewProps> = (props) => {
  const { name, description, validityDateRange, targetGroups, currentVoucherType, voucherContent } =
    props.state;

  const [lang] = useLang();

  return (
    <div
      className={props.className}
      css={{ border: `1px solid ${GRAY_4}`, padding: 16, backgroundColor: WHITE }}
    >
      {props.type && (
        <figure
          css={{
            backgroundColor: WHITE_2,
            padding: 16,
            fill: '#999',
            display: 'flex',
            alignSelf: 'flex-start',
          }}
        >
          {getIconByType(props.type)}
        </figure>
      )}

      <div
        css={{
          flexDirection: 'column',
          justifyContent: 'center',
          marginLeft: 16,
          textAlign: 'left',
        }}
      >
        {name && (name.de || name.en) && (
          <p>
            <span css={{ fontWeight: 'bold', marginRight: 8 }}>
              {_('benefits:voucher.texts.voucherName')}:
            </span>
            {name[props.textLanguage] || name[props.textLanguage === 'de' ? 'en' : 'de']}
          </p>
        )}

        {description && (description.de || description.en) && (
          <p css={{ marginTop: 8, whiteSpace: 'pre-wrap' }}>
            <span css={{ fontWeight: 'bold', marginRight: 8, display: 'flex' }}>
              {_('benefits:voucher.texts.voucherDescription')}:
            </span>
            {description[props.textLanguage].replace('\\n', '\n') ||
              description[props.textLanguage === 'de' ? 'en' : 'de'].replace('\\n', '\n')}
          </p>
        )}

        {validityDateRange && (
          <p css={{ marginTop: 8 }}>
            <span css={{ fontWeight: 'bold', marginRight: 8 }}>
              {_('benefits:voucher.texts.validityDate')}:
            </span>

            {!validityDateRange[1] && <span>{_(`benefits:voucher.texts.from`)}&nbsp;</span>}

            <span>{getFormattedDate(dateTimeISOtoATOM(validityDateRange[0]), lang)}</span>

            {validityDateRange[1] && validityDateRange[0] && (
              <Fragment>
                <span>&nbsp;-&nbsp;</span>
                <span>{getFormattedDate(dateTimeISOtoATOM(validityDateRange[1]), lang)}</span>
              </Fragment>
            )}
          </p>
        )}

        {targetGroups && (
          <p css={{ marginTop: 8 }}>
            <span css={{ fontWeight: 'bold', marginRight: 8 }}>
              {_(`benefits:voucher.texts.redeemableBy`)}:
            </span>
            {targetGroups.map((groupId) => (
              <span
                key={groupId}
                css={{ textDecoration: 'underline', ':not(:first-of-type)': { marginRight: 6 } }}
              >
                {_(`benefits:voucher.targetGroup.${groupId}`)}
              </span>
            ))}
          </p>
        )}

        {currentVoucherType === 'MEDIA' && (
          <div css={{ marginTop: 8, flexDirection: 'column' }}>
            <p css={{ fontWeight: 'bold', marginRight: 8 }}>
              {_(`benefits:voucher.texts.content`)}
            </p>
            <ul>
              {voucherContent.map((media) => (
                <li key={media.contentData.id} css={{ marginTop: 4, listStyle: 'none' }}>
                  <p>
                    <span
                      className={ICONS[media.contentData.isVideoAlbum ? 'VIDEO' : 'PHOTOS']}
                      css={{ marginRight: 4 }}
                    />
                    {media.contentData.titles[0]?.text || ''}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default VoucherPreview;
