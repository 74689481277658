import React, { FC, ReactElement } from 'react';
import { Code } from '../../../types';

interface Props {
  code: Code;
}

const NameLabel: FC<Props> = ({ code }): ReactElement => {
  if (!code) return <span>no code -&gt; no name</span>;
  const names = code.config.texts.filter((text) => text.type === 'codeName');
  return names[0] ? <span>{names[0].value}</span> : <span>-</span>;
};

export default NameLabel;
