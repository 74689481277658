import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isFuture, isToday } from '../../util/DateHelper';
import { FormatDate } from '../../components/Formatter';

const ChartBarValueContainer = props => {
  return (
    <div
      className={classNames({
        bar: true,
        selected: props.isSelected,
        today: isToday(props.entry.date),
        future: isFuture(props.entry.date),
      })}
      onMouseOver={() => props.onMouseOver(props.entry)}
    >
      <div className="bg-entry">
        {Object.keys(props.entry.percent).map(type => (
          <div
            key={props.entry.id + '.' + type}
            style={{ top: 100 - props.entry.percent[type] + '%' }}
          />
        ))}
      </div>
      <div className="datebox">
        <FormatDate value={props.entry.date} />
      </div>
    </div>
  );
};

ChartBarValueContainer.propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    percent: PropTypes.object,
  }).isRequired,
  isSelected: PropTypes.bool,
  onMouseOver: PropTypes.func,
};

ChartBarValueContainer.defaultProps = {
  isSelected: false,
  onMouseOver: () => {},
};

export default ChartBarValueContainer;
