import gql from 'graphql-tag';

export const QUERY_MODEL_VXCHALLENGE_CHALLENGE = gql`
  query QUERY_MODEL_VXCHALLENGE_CHALLENGE($id: Int!) {
    model {
      vxChallenges {
        challenge(id: $id) {
          id
          type
          status
          startDate
          endDate
          targetValue
          targetValueSteps {
            value
            label
          }
          numberOfWinners
          participantsTotal
          participantsCompleted
          participantsList(status: chosenAsWinner) {
            userName
          }
          rewardValue
          redirectUri
          texts {
            title
            subTitle
            description
            reward
            condition
            trophyDescription
            unit
          }
          userStatistic {
            challengeId
            status
            registerDate
            completeDate
            awardDate
            actualValue
          }
        }
      }
    }
  }
`;

export const QUERY_MODEL_VXCHALLENGE_CHALLENGES = gql`
  query QUERY_MODEL_VXCHALLENGE_CHALLENGES($status: VXGChallengeStatusEnum) {
    model {
      vxChallenges {
        challenges(status: $status) {
          id
          type
          status
          startDate
          endDate
          targetValue
          targetValueSteps {
            value
            label
          }
          numberOfWinners
          participantsTotal
          participantsCompleted
          participantsList(status: chosenAsWinner) {
            userName
          }
          rewardValue
          redirectUri
          texts {
            title
            subTitle
            description
            reward
            condition
            trophyDescription
            unit
          }
          userStatistic {
            challengeId
            status
            registerDate
            completeDate
            awardDate
            actualValue
            isTocAccepted
          }
        }
      }
    }
  }
`;

export const QUERY_MODEL_VXCHALLENGE_INCOMPLETE_PARTICIPANTS = gql`
  query QUERY_MODEL_VXCHALLENGE_CHALLENGE($id: Int!) {
    model {
      vxChallenges {
        challenge(id: $id) {
          participantsList(status: uncomplete) {
            userName
          }
        }
      }
    }
  }
`;
