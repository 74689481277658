import React, { Fragment, useState } from 'react';
import { Query } from '@apollo/react-components';

import Overview from '../../../../components/MediaManagement/ExplorerPicture/Overview/Overview';
import { Spinner, Tooltip } from '../../../../components';
import { APP_BASE_PATH } from '../../../../util/env';

import { QUERY_PHOTO_ALBUMS_BY_TYPE } from '../../../../graphql/VXModels/queries';
import { _ } from '../../../../util/translate';

const AlbumOverview = ({ albumType, setCurrentAlbumType, match }) => {
  const [sortField, setSortField] = useState('uploaded'); // status | type | name | uploaded | rating | id
  const [sortDirection, setSortDirection] = useState('desc'); // asc | desc

  setCurrentAlbumType(albumType);

  const title = _('mediamanagement:picfolder.nav.' + albumType);
  const relativePath = `${APP_BASE_PATH}/mediamanagement/picture/album`;

  const album = { status: 'asd' };
  const titleTooltip = '';
  const editable = false;

  const isNotRejected = album && album.status !== 'rejected';
  const isRejected = album && album.status === 'rejected';

  const sorter = newSortField => {
    if (newSortField === sortField) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortField(newSortField);
    }
  };

  const selectOverviewItem = () => console.log('@@@@@ selectOverviewItem');
  const onClickLoadMore = () => console.log('@@@@@ onClickLoadMore');
  const onClickDeleteAlbum = () => {};
  const setEditMode = () => {};

  return (
    <div className="grid__box__column grid__box__sub spinner-container">
      <div className="grid__box__sub__header">
        <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
          {title}
          {isRejected && (
            <Fragment>
              (<T _={`mediamanagement:picfolder.common.status${album.status}`} />)
            </Fragment>
          )}
        </div>

        {titleTooltip && <Tooltip position="right" text={titleTooltip} />}

        <div className="grid__box__sub__header__spacer" />

        {isRejected && editable && (
          <div
            className="icon-bin grid__box__sub__header__item--option"
            onClick={onClickDeleteAlbum}
          >
            <T _={'common:button.delete'} />
          </div>
        )}
        {isNotRejected && editable && (
          <div className="icon-pencil grid__box__sub__header__item--option" onClick={setEditMode}>
            <T _={'mediamanagement:picfolder.title.edit'} />
          </div>
        )}
      </div>

      <Query
        query={QUERY_PHOTO_ALBUMS_BY_TYPE}
        fetchPolicy="cache-and-network"
        variables={{
          type: albumType === 'campaign' ? 'contest' : albumType,
          offset: 0,
          limit: 20,
          sortField,
          sortDirection,
        }}
      >
        {({ loading, data, error, fetchMore }) => {
          if (error) return console.error(error);
          if (loading) return <Spinner />;
          const { albums, albumsTotal } = data.model.photoAlbums;
          return (
            <Overview
              albumType={albumType}
              albums={albums}
              totalAlbums={albumsTotal}
              isLoading={loading}
              onSelectItem={selectOverviewItem}
              sorter={sorter}
              onClickLoadMore={() =>
                fetchMore({
                  variables: { offset: 1 + albums.length },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                      model: {
                        ...prev.model,
                        photoAlbums: {
                          ...prev.model.photoAlbums,
                          albums: [
                            ...prev.model.photoAlbums.albums,
                            ...fetchMoreResult.model.photoAlbums.albums,
                          ],
                        },
                      },
                    });
                  },
                })
              }
              currentSort={sortField} //{this.state.filter.sort}
              currentSortDirection={sortDirection} //{this.state.filter.sortDirection}
              relativePath={relativePath}
            />
          );
        }}
      </Query>
    </div>
  );
};

export default AlbumOverview;
