import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import Footer from '../../components/Footer/Footer';
import { stripSlash } from '../../util/urlHelper';
import { useHasRole } from '../../util/UserData';
import { SecurityRole } from '../../graphql/VXModels/types';
import HouseRule from './HouseRule/HouseRule';
import Terms from './Terms/Terms';
import Imprint from './Imprint/Imprint';
import GDPRComponent from './GDPR/GDPR';
import { TermsOfUse, TelegramTerms } from './GenericTerms/Derivatives';

const HOUSERULE = '/houserule';
const TERMS = '/terms'; // terms and conditions
const TELEGRAMTERMS = '/telegram-terms';
const GDPR = '/gdpr';
const IMPRINT = '/imprint';
const TERMS_OF_USE = '/terms-of-use';

const Legal: FC<RouteComponentProps> = ({ match: { url } }) => {
  const path = stripSlash(url);
  const indexRoute = path + HOUSERULE;

  return (
    <main className="legal-imprint grid">
      <Switch>
        <Redirect exact from={`${path}/`} to={indexRoute} />
        <Route path={path + HOUSERULE} component={HouseRule} />
        <Route path={path + TERMS} component={Terms} />
        <Route path={path + TELEGRAMTERMS} component={TelegramTerms} />
        <Route path={path + GDPR} component={GDPRComponent} />
        <Route path={path + IMPRINT} component={Imprint} />
        <Route path={path + TERMS_OF_USE} component={TermsOfUse} />
        <Redirect to={indexRoute} />
      </Switch>

      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </main>
  );
};

export default Legal;
