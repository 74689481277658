import gql from 'graphql-tag';
import { FRAGMENT_USER } from '../fragments';

export const QUERY_MASTER_ACCOUNTS = gql`
  query VXM_CL_QUERY_MASTER_ACCOUNTS($token: String!, $filter: UserFilter) {
    master(token: $token) {
      userList(filter: $filter, order: relevance, limit: 50) {
        users {
          ...User
        }
      }
      # to show tippings of user in correct currency
      currency
    }
  }
  ${FRAGMENT_USER}
`;
