import { FormikProps } from 'formik';
import React, { FC, useMemo, useRef } from 'react';
import Button from '../../../../atoms/Button/Button';
import Icon from '../../../../atoms/Icon/Icon';
import { floppyDisk, plusSign } from '../../../../atoms/Icon/libraries/glyph';
import PromptAlert from '../../../../atoms/PromptAlert/PromptAlert';
import Table from '../../../../atoms/Table/Table';
import { ITableColumn } from '../../../../atoms/Table/types';
import { BLUE, DARK_GRAY, WHITE } from '../../../../camtool-styles';
import { Master } from '../../../../graphql/VXServicesTelegram/types';
import AlertsStore from '../../../../stores/Alerts/AlertsStore';
import { _ } from '../../../../util/translate';
import AutoMailListItem from './AutoMailListItem';
import { AddMailItem, ButtonWrapper, tableHeaderItemStyles } from './styles';
import { FormikUserLifeCycleStep, FormikUserLifeCycleSteps } from './types';
import { getInitialDummyEntry } from './utils';

const getColumns = (isVip: boolean): ITableColumn[] => [
  {
    label: _(`mailings:telegram.autoMail.th.deliveryOptions.${isVip ? 'VIP' : 'free'}`),
    width: 0.3,
  },
  { label: _(`mailings:telegram.autoMail.th.message.${isVip ? 'VIP' : 'free'}`), width: 0.4 },
  { label: _('mailings:telegram.autoMail.th.attachment'), width: 0.1 },
  { label: '', width: 0.1 },
];

interface ArrayHelpers {
  form: FormikProps<FormikUserLifeCycleSteps>;
  push: (obj: FormikUserLifeCycleStep) => void;
  remove<FormikUserLifeCycleStep>(index: number): FormikUserLifeCycleStep | undefined;
  isVip: boolean;
  mimetype: Master['allowedMimeTypes'];
}

const AutoMailTable: FC<ArrayHelpers> = ({ push, remove, form, isVip = false, mimetype }) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const columns: ITableColumn[] = useMemo(() => getColumns(isVip), [isVip]);
  const isDisabled = form.isSubmitting || !form.dirty || !!form.errors?.autoMailList;
  const dynamicColor = isDisabled ? DARK_GRAY : WHITE;

  const deleteAutoMailRow = (index: number): void => {
    remove(index);
  };

  const addNewAutoMailRow = (): void => {
    if (form.values.autoMailList.length < 10) {
      const initialDummyEntry = getInitialDummyEntry(isVip)[0] as FormikUserLifeCycleStep;

      push(initialDummyEntry);
    } else {
      AlertsStore.add({
        type: 'error',
        message: _('mailings:telegram.autoMail.restriction'),
      });
    }
  };

  return (
    <>
      <PromptAlert when={form.dirty} message={_('mailings:telegram.autoMail.leave.alert')} />
      <Table
        headerItemStyles={tableHeaderItemStyles}
        css={{ width: '100%', minWidth: 1222 }}
        tableBodyRef={tableRef}
        columns={columns}
      >
        {form.values.autoMailList.map((item: FormikUserLifeCycleStep, index: number) => (
          <AutoMailListItem
            key={index}
            index={index}
            columns={columns}
            deleteAutoMailRow={deleteAutoMailRow}
            mimetype={mimetype}
          />
        ))}
      </Table>
      <ButtonWrapper>
        {form.values?.autoMailList.length < 10 ? (
          <AddMailItem onClick={addNewAutoMailRow}>
            <Icon icon={plusSign} size={'14px'} css={{ color: BLUE, marginRight: '8px' }} />
            <span css={{ color: BLUE, fontWeight: 'bold', fontSize: '15px' }}>
              {_('mailings:telegram.autoMail.addMessage')}
            </span>
          </AddMailItem>
        ) : null}

        <Button type="submit" theme={isDisabled ? 'gray' : 'blue'} disabled={isDisabled}>
          <Icon
            icon={floppyDisk}
            size={'14px'}
            css={{
              color: dynamicColor,
              marginRight: '5px',
            }}
          />
          <span css={{ color: dynamicColor }}>{_('common:button.save')}</span>
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default AutoMailTable;
