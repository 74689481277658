import axios from 'axios';
import { ApiLang } from '../../../graphql/VXModels/types';
import { getAuthTokenCookie } from '../../../util/cookies';
import { REACT_APP_API_HOST } from '../../../util/env';
import { log } from '../../../util/errorHandling';

type GenericUploadProps = {
  query: string;
  umaId?: number;
  type?: number;
  fsk?: number;
  uploadRequestId?: string;
};

const getGenericUploadUrl = async ({
  query,
  fsk,
  type,
  umaId = 0,
  uploadRequestId,
}: GenericUploadProps): Promise<string> => {
  const endpoint = REACT_APP_API_HOST + '/graphql?lang=' + ApiLang.de;
  const headers = {
    'content-type': 'application/json',
    Authorization: 'Bearer ' + getAuthTokenCookie(),
  };

  const vars = umaId !== 0 ? { umaId: umaId, pictureType: type } : { umaSubType: type };
  vars.fsk = 18;
  if (fsk === 12 || fsk === 16) {
    vars.fsk = fsk;
  }
  if (uploadRequestId) {
    vars.uploadRequestId = uploadRequestId;
  }

  const graphqlQuery = {
    query: query,
    variables: vars,
  };

  try {
    const response = await axios({
      url: endpoint,
      method: 'post',
      headers: headers,
      data: graphqlQuery,
    });

    return response.data?.data?.model?.media;
  } catch (err) {
    const errors = err;
    if (axios.isAxiosError(errors) || err instanceof Error) {
      log('error', 'POST: query ' + graphqlQuery + ' result code:' + err, {
        context: 'UploadManager',
      });
    } else {
      interface Error {
        statusCode: number;
        response: string;
      }
      const { response } = err as Error;
      log('error', 'POST: query ' + graphqlQuery + ' result code:' + response, {
        context: 'UploadManager',
      });
    }
    return '';
  }
};
/**
 *
 * @param umaId number
 * @param type number
 * @param fsk number
 * @returns
 */
export const getPictureUploadUrl = async (
  umaId: number,
  type: number,
  fsk: number
): Promise<string> => {
  const data = await getGenericUploadUrl({
    query: `query getPictureUploadUrl($umaId: Int!, $pictureType: Int!, $fsk: Int) {
    model {
      media {
        pictureUploadUrl(umaId: $umaId, pictureType: $pictureType, fsk: $fsk)
      }
    }
  }`,
    umaId,
    type,
    fsk,
  });

  return data?.pictureUploadUrl;
};

export const getTusVideoUploadUrl = async (): Promise<{
  url: string;
  metaData: Record<string, unknown>;
}> => {
  const data = await getGenericUploadUrl({
    query: `query getTusVideoUploadUrl {
        model {
          media {
            videoUploadTus {
              url
              metaData
            } 
          }
        }
      }`,
  });

  return data?.videoUploadTus;
};

export const getUploadedTusVideo = async (uploadRequestId: string): Promise<{ id: number }> => {
  const data = await getGenericUploadUrl({
    query: `query getUploadedTusVideo($uploadRequestId: String) {
    model {
      media {
        video (uploadRequestId: $uploadRequestId){
          id
        }
      }
    }
  }`,
    uploadRequestId,
  });

  return data?.video;
};
