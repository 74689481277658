import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import BigIconButton from '../../../components/BigIconButton/BigIconButton';
import { BREAKPOINT_PHONE_CONDITION } from '../../../camtool-styles';
import { getMimeTypeByMediaType } from '../util';
import { MediaTypeEnum } from '../types';
import { _ } from '../../../util/translate';
import { DropZone } from './DropZone';
import { useAddFiles } from '../hooks';
import { MAX_ELEMENTS, useCountPoolElements } from '../hooks/useCountPoolElements';

interface UploadFieldProps {
  multiple: boolean;
  mediaType: MediaTypeEnum;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: () => ({
      flex: '1 1 auto !important',
      [`@media ${BREAKPOINT_PHONE_CONDITION}`]: {
        flex: '1 1 auto !important',
      },
    }),
  })
);

const UploadField: FC<UploadFieldProps> = ({ mediaType, multiple }) => {
  const { root } = useStyles();
  const countElements = useCountPoolElements(mediaType);

  return (
    <DropZone
      accept={getMimeTypeByMediaType(mediaType)}
      acceptMessage={_(`UploadManager:uploadfield.${mediaType}.acceptMessage`)}
      rejectMessage={_(`UploadManager:uploadfield.${mediaType}.rejectMessage`)}
      onDrop={useAddFiles()}
      multiple={multiple}
      isVisible={countElements < MAX_ELEMENTS}
      mediaType={mediaType}
    >
      <BigIconButton
        className={root}
        label={_(`UploadManager:uploadfield.${mediaType}.title`)}
        info={_(`UploadManager:uploadfield.${mediaType}.subtitle`)}
        sizeInfo={
          mediaType === MediaTypeEnum.video
            ? _(`mediamanagement:multiuploader.uploadfield.videoDataInfo`)
            : ''
        }
        iconMain="icon-disk-open"
        iconLeft="icon-picture"
        iconRight="icon-camera"
      />
    </DropZone>
  );
};

export default UploadField;
