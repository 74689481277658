import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_VXCHALLENGE_CHALLENGES } from '../../../graphql';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Spinner } from '../../../../../components';
import {
  Query,
  VXGChallengeschallengesArgs,
  VXGChallengeUserStatusEnum,
} from '../../../../../graphql/VXModels/types';
import { Footer } from '../CompetitionFooter/index';
import { CompetitionStatus } from '../CompetitionStatus/index';
import { Main } from '../../../../../atoms';
import {
  CompetitionAchievements,
  CompetitionBanner,
  CompetitionOverview,
  CompetitionPrizePreview,
} from '..';
import { Box, Grid } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const VXGamesChallenge: FC = () => {
  const { challengeId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  const { loading, error, data } = useQuery<Query, VXGChallengeschallengesArgs>(
    QUERY_MODEL_VXCHALLENGE_CHALLENGES
  );

  if (loading) return <Spinner />;
  if (error) return <GenericEmptyContent />;

  const challenges = data?.model?.vxChallenges?.challenges;
  if (challenges) {
    const challengeExists = challenges.some((challenge) => challenge.id === Number(challengeId));
    if (!challengeExists) {
      history.push(path);
    }
  }
  const achievements = challenges
    ?.map((challenge) => ({
      type: challenge.type,
      title: challenge.texts.title,
      trophyDescription: challenge.texts.trophyDescription,
      completeDate: challenge.userStatistic?.completeDate,
      participationStatus: challenge.userStatistic?.status,
    }))
    .sort(function (a) {
      if (
        a.participationStatus === VXGChallengeUserStatusEnum.winner ||
        a.participationStatus === VXGChallengeUserStatusEnum.complete
      ) {
        return -1;
      } else if (a.participationStatus === VXGChallengeUserStatusEnum.notParticipated) {
        return 1;
      } else return 0;
    });

  const currentChallenge = challenges?.find((challenge) => challenge.id === Number(challengeId));
  const texts = currentChallenge?.texts;
  const userStatistic = currentChallenge?.userStatistic;

  return (
    <Main isMobileEnabled>
      <CompetitionBanner
        type={currentChallenge?.type}
        title={texts?.title}
        subTitle={texts?.subTitle}
        description={texts?.description}
        reward={texts?.reward}
        redirectUri={currentChallenge?.redirectUri}
        startDate={currentChallenge?.startDate}
        endDate={currentChallenge?.endDate}
      />
      <CompetitionOverview
        conditionText={texts?.condition}
        competitionName={texts?.title}
        unit={texts?.unit}
        completeDate={userStatistic?.completeDate}
        currentValue={userStatistic?.actualValue}
        awardDate={userStatistic?.awardDate}
        status={userStatistic?.status}
        targetValue={currentChallenge?.targetValue}
        targetValueSteps={currentChallenge?.targetValueSteps}
        configModuleUri={currentChallenge?.redirectUri}
      />
      <Box my={2}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item sm={12} md={6}>
            <CompetitionPrizePreview
              winningList={currentChallenge?.participantsList}
              reward={texts?.reward}
              endDate={currentChallenge?.endDate}
              participationStatus={userStatistic?.status}
              numberOfWinners={currentChallenge?.numberOfWinners}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <CompetitionStatus
              total={currentChallenge?.participantsTotal}
              completed={currentChallenge?.participantsCompleted}
              challengeId={Number(challengeId)}
            />
          </Grid>
        </Grid>
      </Box>
      <CompetitionAchievements achievements={achievements} />
      <Box mt={2}>
        <Footer />
      </Box>
    </Main>
  );
};

export { VXGamesChallenge };
