import React from 'react';
import PropTypes from 'prop-types';

import AbstractComponent from '../AbstractComponent';
import RankingPointsItem from '../../components/RankingPoints/RankingPointsItem/RankingPointsItem';

class RankingPoints extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = { rankingElement: props.rankingElement };
  }

  componentWillReceiveProps(props) {
    if (props && props.rankingElement) {
      this.setState({ rankingElement: props.rankingElement });
    }
  }

  renderRankingPointsItem() {
    return this.state.rankingElement.details.map(detailedRankingPoints => (
      <RankingPointsItem
        key={detailedRankingPoints.id}
        rankingElement={detailedRankingPoints}
        groupName={this.state.rankingElement.groupName}
      />
    ));
  }

  render() {
    return (
      <div className="grid__box__sub__item ranking-overview__col__item">
        <div className="grid__box__sub__item__content">
          <RankingPointsItem
            defaultGroupname={this.props.defaultGroupname}
            rankingElement={this.state.rankingElement}
          >
            <div className="rankingpoints__rankingdetails">
              {this.props.rankingElement.details ? this.renderRankingPointsItem() : null}
            </div>
          </RankingPointsItem>
        </div>
      </div>
    );
  }
}

RankingPoints.propTypes = {
  rankingElement: PropTypes.object,
  defaultGroupname: PropTypes.string,
};

RankingPoints.defaultProps = {
  rankingElement: {},
  defaultGroupname: '',
};

export default RankingPoints;
export { RankingPoints };
