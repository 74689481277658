import React, { FC } from 'react';
import { Avatar as MUIAvatar, AvatarProps as MUIAvatarProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

interface Props {
  src?: string;
  size?: string;
}

type AvatarProps = Props & Omit<MUIAvatarProps, keyof Props>;

const useStyles = makeStyles(() =>
  createStyles({
    root: (props: AvatarProps) => ({
      marginRight: 5,
      width: props.size,
      height: props.size,
    }),
  })
);

const UserAvatar: FC<AvatarProps> = ({ src, size, ...props }) => {
  const classes = useStyles({ size });
  return <MUIAvatar className={classes.root} src={src} {...props} />;
};

export default UserAvatar;
