import { Scopes } from '../../util/scope';
import { DEFAULT, links, Slugs } from './conf';
import { AppScope } from '../../graphql/VXModels/types';

export const resolveSlug = (
  scope: AppScope | Scopes,
  slug: Slugs | '_default'
): string | undefined =>
  links[scope]?.[slug] ||
  links[DEFAULT]?.[slug] ||
  links[scope]?.['_default'] ||
  links[DEFAULT]?.[DEFAULT];
