import styled from '@emotion/styled';
import { BLACK_1 } from '../../camtool-styles';

export interface MainProps {
  isMobileEnabled?: boolean;
}

export const Main = styled.main<MainProps>`
  font-family: 'Roboto', sans-serif;
  flex: 1;
  flex-direction: column;
  position: relative;
  max-width: 1400px;
  z-index: 10;
  background-color: transparent;
  transition: margin 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  color: ${BLACK_1};
  min-width: ${(props): number => (props?.isMobileEnabled ? 200 : 650)}px;
`;
