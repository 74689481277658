import { Entry } from './Entry';
import { EntryState, QueueStorageProps } from './JsUploader';
import { ILogger } from './Logger';

export interface QueueStorageProps {
  uploadType: string;
  userId: number;
  logger?: ILogger;
}

export class QueueStorage {
  private ls: Storage = window.localStorage;
  private uploadType: string;
  private keyQueue: string;
  private keyEntry: string;
  private logger: ILogger;

  public constructor(props: QueueStorageProps) {
    this.logger = props.logger;
    const lsKeyPrefix = 'jsuploader.' + props.uploadType + '.' + props.userId;
    this.uploadType = props.uploadType;
    this.keyQueue = lsKeyPrefix + '.q';
    this.keyEntry = lsKeyPrefix + '.e.';
  }

  public getQueueKeys() {
    let keys: string[] = [];
    const keysStr = this.ls.getItem(this.keyQueue);

    if (keysStr) {
      try {
        keys = JSON.parse(keysStr);
      } catch (e) {
        // do nothing
      }
    }

    return keys;
  }

  public getQueue() {
    const queue: Entry[] = [];
    let entry: Entry;

    const keys = this.getQueueKeys();

    for (let i = 0; i < keys.length; i++) {
      try {
        entry = this.getEntry(keys[i]);
        if (
          entry.state === EntryState.COMPLETE ||
          entry.state === EntryState.DUPLICATE_ERROR ||
          entry.state === EntryState.FILE_TYPE_ERROR ||
          entry.state === EntryState.PICTURE_SIZE_ERROR ||
          entry.state === EntryState.RESPONSE_ERROR ||
          entry.state === EntryState.REMOVED
        ) {
          continue;
        }
        if (!entry.file.type) {
          entry.state = EntryState.FILE_REF_ERROR;
        }

        queue.push(entry);
      } catch (e) {
        // do nothing
      }
    }

    return queue;
  }

  public storeQueueKeys(queue: Entry[]) {
    const keys: string[] = [];
    for (let i = 0; i < queue.length; i++) {
      keys.push(queue[i].key);
    }
    this.ls.setItem(this.keyQueue, JSON.stringify(keys));
    if (this.logger) {
      this.logger.debug('QueueStorage[' + this.uploadType + ']::storeQueue');
    }
  }

  /**
   *
   * @param key
   * @returns {Entry}
   */
  public getEntry(key: string): Entry {
    let storedEntry: any;
    let entry: Entry;

    const entryStr = this.ls.getItem(this.keyEntry + key);
    if (entryStr) {
      storedEntry = JSON.parse(entryStr);
      entry = objectAssign(new Entry(), storedEntry);
      if (
        !entry.file.type &&
        !(
          entry.state === EntryState.COMPLETE ||
          entry.state === EntryState.DUPLICATE_ERROR ||
          entry.state === EntryState.RESPONSE_ERROR ||
          entry.state === EntryState.REMOVED
        )
      ) {
        entry.state = EntryState.FILE_REF_ERROR;
      }
    } else {
      throw new InvalidKeyException('Unknown Storage key: ' + key);
    }

    return entry;
  }

  /**
   *
   * @param entry
   */
  public storeEntry(entry: Entry) {
    // if (this.logger) this.logger.debug('QueueStorage[' + this.uploadType + ']::storeEntry', entry.key);
    this.ls.setItem(this.keyEntry + entry.key, JSON.stringify(entry));
  }

  /**
   *
   * @param key
   */
  public removeEntry(key: string) {
    this.ls.removeItem(this.keyEntry + key);
  }
}
