import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';
import EmptyContent from '../../../EmptyContent/EmptyContent';
import LoadMoreButton from '../../../LoadMoreButton/LoadMoreButton';
import TableHeaderColumnItem from '../../../Table/TableHeaderColumnItem/TableHeaderColumnItem';
import Spinner from '../../../Spinner/Spinner';
import OverviewItem from '../OverviewItem/OverviewItem';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import {
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_PHONE_CONDITION,
  BREAKPOINT_TABLET_CONDITION,
} from '../../../../camtool-styles';

const SORT_OPTIONS = ['status', 'name', 'rating', 'sales', 'price', 'uploaded'];
const DEFAULT_SORT = 'name';
const DEFAULT_SORT_DIRECTION = 'asc';

const Header = styled('header')`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-direction: row;
    flex-wrap: wrap;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
  }
`;

const TableHeaderColumnItemStyled = styled(TableHeaderColumnItem)`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -ms-flex: 1 1 30.4%;
    -webkit-flex: 1 1 30.4%;
    flex: 1 1 30.4%;
    justify-content: space-between;
  }
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 20px 0;
  }
  @media ${BREAKPOINT_PHONE_CONDITION} {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 10px 0;
  }
`;

class Overview extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSort(name) {
    if (this.props.currentSort === name) {
      return this.props.currentSortDirection;
    }
    return '';
  }

  onClickLoadMore() {
    this.props.onClickLoadMore();
  }

  onClickItem(item) {
    this.props.onSelectItem(item);
  }

  render() {
    const { albums, albumType, isLoading, relativePath, sorter, totalAlbums } = this.props;

    return (
      <div className="grid__box__sub__item">
        <div className="grid__box__sub__item__content mediamanagement__pic__overview spinner-container">
          {isLoading && <Spinner />}

          <Header className="table-header-column">
            <TableHeaderColumnItemStyled
              label={_('mediamanagement:picfolder.overview.preview')}
              className="mediamanagement__pic__overview__preview"
            />

            <TableHeaderColumnItemStyled
              label={_('mediamanagement:picfolder.overview.status')}
              name="status"
              sortable
              className="mediamanagement__pic__overview__status"
              sortDirection={this.getSort('status')}
              onSort={sorter}
            />

            <TableHeaderColumnItemStyled
              label={_('mediamanagement:picfolder.overview.name')}
              name="name"
              sortable
              className="mediamanagement__pic__overview__name"
              sortDirection={this.getSort('name')}
              onSort={sorter}
            />

            <TableHeaderColumnItemStyled
              label={_('mediamanagement:picfolder.overview.uploaded')}
              name="uploaded"
              sortable
              className="mediamanagement__pic__overview__uploaded"
              sortDirection={this.getSort('uploaded')}
              onSort={sorter}
            />

            <TableHeaderColumnItemStyled
              label={_('mediamanagement:picfolder.overview.ratings')}
              name="rating"
              sortable
              className="mediamanagement__pic__overview__ratings"
              sortDirection={this.getSort('ratings')}
              onSort={sorter}
            />

            {albumType === 'shop' && (
              <TableHeaderColumnItemStyled
                label={_('mediamanagement:picfolder.overview.sales')}
                name="sales"
                sortable
                className="mediamanagement__pic__overview__sales"
                sortDirection={this.getSort('sales')}
                onSort={sorter}
              />
            )}

            {albumType === 'shop' && (
              <TableHeaderColumnItemStyled
                label={_('mediamanagement:picfolder.overview.price')}
                name="price"
                sortable
                className="mediamanagement__pic__overview__price"
                sortDirection={this.getSort('price')}
                onSort={sorter}
              />
            )}
          </Header>

          {albums.map((item) => (
            <OverviewItem
              key={item.id}
              album={item}
              albumType={albumType}
              onClick={this.onClickItem}
              relativePath={relativePath}
            />
          ))}

          <LoadMoreButton
            loadedEntries={albums.length}
            totalEntries={totalAlbums}
            onClickLoadMore={this.onClickLoadMore}
          />

          {albums.length === 0 && (
            <EmptyContent
              icon="icon-folder-open"
              title={_('mediamanagement:picfolder.common.noPicturesAvailable')}
            />
          )}
        </div>
      </div>
    );
  }
}

Overview.propTypes = {
  albums: PropTypes.array.isRequired,
  albumType: PropTypes.string.isRequired,
  relativePath: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  currentSort: PropTypes.oneOf(SORT_OPTIONS).isRequired,
  currentSortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onSelectItem: PropTypes.func,
  onClickSort: PropTypes.func,
  onClickLoadMore: PropTypes.func,
};

Overview.defaultProps = {
  isLoading: false,
  currentSort: DEFAULT_SORT,
  currentSortDirection: DEFAULT_SORT_DIRECTION,
  onSelectItem: () => {},
  onClickSort: () => {},
  onClickLoadMore: () => {},
};

export default Overview;
export { Overview };
