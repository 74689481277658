import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedAmount } from '../../util/Formatter';
import { useLang } from '../../util/AppState';

const FormatCurrency = ({ className, value, currency }) => {
  const [lang] = useLang();

  return <span className={className}>{getFormattedAmount(value, lang, currency)}</span>;
};

FormatCurrency.defaultProps = {
  className: '',
  value: 0,
  currency: 'EUR',
};

FormatCurrency.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  currency: PropTypes.oneOf(['EUR', 'USD']),
};

export default FormatCurrency;
export { FormatCurrency };
