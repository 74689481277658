import React from 'react';
import AbstractProfile from '../../AbstractProfile';
import { PROFILE_INTERVIEW } from '../../../../stores/Profile/Profile';

import MultiLangTextareafield from '../../../../components/Form/MultiLang/MultiLangTextareafield/MultiLangTextareafield';

import { T } from '../../../../components';
import GroupRow from '../../../../components/GroupRow/GroupRow';
import Spinner from '../../../../components/Spinner/Spinner';
import Form from '../../../../components/Form/Form';
import Markdown from '../../../../components/Markdown/Markdown';
import { Button } from '../../../../atoms';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import { _ } from '../../../../util/translate';

class Interview extends AbstractProfile {
  constructor(props) {
    super(props);
    this.leftfields = [
      'interviewParty',
      'interviewCity',
      'interviewBook',
      'interviewIsland',
      'interviewPersonalAdmiration',
      'interviewMusic',
      'interviewSexGo',
      'interviewHotExperience',
      'interviewSexualCuriosity',
      'interviewSexualFantasies',
      'interviewEroticExperience',
    ];

    this.rightfields = [
      'interviewHobbies',
      'interviewSport',
      'interviewDate',
      'interviewEnterprise',
      'interviewPartner',
      'interviewTraits',
      'interviewFood',
      'interviewSexNoGo',
      'interviewSexualEsteem',
      'interviewSexualPosition',
      'interviewExtraordinarySex',
    ];
  }

  getResource() {
    return PROFILE_INTERVIEW;
  }

  render() {
    super.render();
    this.spokenLangs = this.storeValues.profileLanguages;

    return (
      <section className="grid__row">
        <Form
          className="grid__column grid__box"
          ref="form"
          onValidSubmit={this.submitForm}
          onInvalidSubmit={this.showErrorFields}
          onValid={this.onValid}
          onInvalid={this.onInvalid}
          onChange={this.onChange}
        >
          <header className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'profiles:interviewPagetitle.label'} />
            </div>
          </header>

          <div className="grid__box__item interview__temp">
            <div className="grid__box__item__content">
              <div className="warning-text">
                <div className="warning-text__column">
                  <div className="title">
                    <T _={'profiles:interview.description.title'} />
                  </div>
                  <Markdown
                    className="subtitle"
                    source={_('profiles:interview.description.text')}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid__row spinner-container" style={{ flexWrap: 'wrap' }}>
            {this.state.isLoading && <Spinner size="l" align="top" />}

            <div className="grid__box__column grid__box__sub">
              <div className="grid__box__sub__item grid__box__sub__item--noheader">
                <div className="grid__box__sub__item__content">
                  {this.leftfields.map(fieldname => (
                    <GroupRow
                      label={_('profiles:' + fieldname + '.label')}
                      tooltip="none"
                      key={fieldname}
                    >
                      <MultiLangTextareafield
                        name={fieldname}
                        value={this.storeValues[fieldname].texts}
                        placeholder={_('profiles:' + fieldname + '.placeholder')}
                        langs={this.spokenLangs}
                        maxLength={250}
                        rows={4}
                      />
                    </GroupRow>
                  ))}
                </div>
              </div>
            </div>

            <div className="grid__box__column grid__box__sub" style={{ minWidth: 600 }}>
              <div className="grid__box__sub__item grid__box__sub__item--noheader">
                <div className="grid__box__sub__item__content">
                  {this.rightfields.map(fieldname => (
                    <GroupRow
                      label={_('profiles:' + fieldname + '.label')}
                      tooltip="none"
                      key={fieldname}
                    >
                      <MultiLangTextareafield
                        name={fieldname}
                        value={this.storeValues[fieldname].texts}
                        placeholder={_('profiles:' + fieldname + '.placeholder')}
                        langs={this.spokenLangs}
                        maxLength={250}
                        rows={4}
                      />
                    </GroupRow>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <ButtonGroup position="right">
            <Button
              theme="gray"
              label={_('common:button.cancel')}
              onClick={this.rejectChanges}
              disabled={!this.state.hasChanges}
              css={{ margin: 8 }}
            />
            <Button
              type="submit"
              theme="blue"
              label={_('common:button.save')}
              css={{ margin: 8 }}
            />
          </ButtonGroup>
        </Form>
      </section>
    );
  }
}

export default Interview;
