import React, { FC } from 'react';
import { _ } from '../../util/translate';
import EmptyContent from '../../components/EmptyContent/EmptyContent';

interface Props {
  className?: string;
}

const GenericEmptyContent: FC<Props> = ({ className }) => (
  <EmptyContent className={className} icon="icon-warning-sign" title={_('common:error.generic')} />
);

export default GenericEmptyContent;
