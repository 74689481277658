import React, { FC } from 'react';
import { PreviewVideoOptions, PreviewVideoThumbnailIconSize } from './types';
import PreviewVideoThumbnail from './PreviewVideoThumbnail';
import PreviewVideoPlayer from './PreviewVideoPlayer';

interface Props {
  url: string;
  thumbnailUrl?: string;
  options?: PreviewVideoOptions;
  className?: string;
}

const PreviewVideo: FC<Props> = ({ url, thumbnailUrl, options, className }) => {
  const thumbnailOnly = options?.thumbnailOnly ?? false;
  const thumbnailIconSize = options?.thumbnailIconSize ?? PreviewVideoThumbnailIconSize.md;

  return thumbnailOnly ? (
    <PreviewVideoThumbnail url={thumbnailUrl} iconSize={thumbnailIconSize} className={className} />
  ) : (
    <PreviewVideoPlayer url={url} options={options} className={className} />
  );
};

export default PreviewVideo;
