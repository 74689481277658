export enum FileType {
  image = 'image',
  video = 'video',
  audio = 'audio',
  unknown = 'unknown',
}

export interface FileDescriptorBase {
  previewUrl?: string;
}

export interface UrlFileDescriptor extends FileDescriptorBase {
  url: string;
  type: FileType;
}

export interface NativeFileDescriptor extends FileDescriptorBase {
  file: File;
}

export type FileDescriptor = UrlFileDescriptor | NativeFileDescriptor;
