import * as React from 'react';
import styled from '@emotion/styled';

interface IListItem {
  hightlight: boolean;
}

interface IAutocompleteUL {
  matches: string[];
  selectedMatch: number;
}

const UnorderedList = styled('ul')`
  border: 1px solid #dcdcde;
  background-color: #fff;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
`;

const ListItem = styled('li')`
  list-style: none;
  & a {
    border-bottom: 1px solid #f0f0f0;
    text-decoration: none;
    display: flex;
    padding: 8px;
    background-color: ${(props: IListItem) => (props.hightlight ? '#1f93e9' : '#fff')};
    color: ${(props: IListItem) => (props.hightlight ? '#fff' : '#b2b2b2')};
    &:hover {
      background-color: #f2f2f2;
      color: #b2b2b2;
    }
  }
`;

const AutocompleteUL = (props: IAutocompleteUL) => (
  <UnorderedList>
    {props.matches.map((match: string, i: number) => (
      <ListItem key={match} hightlight={i === props.selectedMatch}>
        <a className="option" href="" tabIndex={-1}>
          {match}
        </a>
      </ListItem>
    ))}
  </UnorderedList>
);

export default AutocompleteUL;
