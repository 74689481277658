import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '../Tooltip/Tooltip';

const renderErrorMsg = errorMessage => <div className="grouprow__errorMessage">{errorMessage}</div>;

const renderLabel = (label, labelClass) => (
  <div className={`label ${labelClass}`}>
    <div className="label-text">{label}</div>
    <div className="spacer" />
  </div>
);

const GroupRow = ({
  errorMessage,
  className,
  containerClass,
  label,
  labelClass,
  tooltip,
  children,
  tooltipPosition,
  tooltipText,
  tooltipClass,
  id,
}) => (
  <div {...(id ? { id } : {})} className={`grouprow__outside-container ${containerClass}`}>
    {!!errorMessage && renderErrorMsg(errorMessage)}

    <article className={`grouprow__content ${className}`}>
      {!!label && renderLabel(label, labelClass)}

      <section className={`${tooltip === 'clear' ? tooltip : ''} ${tooltipClass}`}>
        {children}
      </section>

      {!!tooltipText && <Tooltip position={tooltipPosition} text={tooltipText} />}
    </article>
  </div>
);

GroupRow.propTypes = {
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.oneOf(['top-right', 'right', 'top', 'bottom']),
  className: PropTypes.string,
  tooltip: PropTypes.oneOf(['clear', 'none']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tooltipClass: PropTypes.string,
  containerClass: PropTypes.string,
  id: PropTypes.string,
};

GroupRow.defaultProps = {
  errorMessage: '',
  label: '',
  labelClass: '',
  tooltipText: '',
  tooltipPosition: null,
  className: '',
  tooltip: 'none',
  children: [],
  tooltipClass: '',
  containerClass: '',
};

export default GroupRow;
export { GroupRow };
