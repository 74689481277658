import React, { ReactElement } from 'react';

const VXLogo = (): ReactElement => {
  return (
    <g id="Ebene_2_1_">
      <g id="Ebene_1-2">
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="18.9753"
          y1="30.5478"
          x2="24.1167"
          y2="26.5071"
          gradientTransform="matrix(1 0 0 -1 0 44)"
        >
          <stop offset="0" stopColor="#9E3026" />
          <stop offset="2.000000e-02" stopColor="#A93329" />
          <stop offset="9.000000e-02" stopColor="#C43B2F" />
          <stop offset="0.17" stopColor="#DA4234" />
          <stop offset="0.27" stopColor="#EB4737" />
          <stop offset="0.38" stopColor="#F64A3A" />
          <stop offset="0.55" stopColor="#FD4C3C" />
          <stop offset="1" stopColor="#FF4D3C" />
        </linearGradient>
        <path
          css={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: 'url(#SVGID_1_)' }}
          d="M18.4,14.2l1.7,2.4h3.3L20,11.8l1.3-1.9l6.1,8.9h-8.6L17,16.1L18.4,14.2z"
        />

        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="16.7915"
          y1="39.8979"
          x2="12.4177"
          y2="43.1566"
          gradientTransform="matrix(1 0 0 -1 0 44)"
        >
          <stop offset="0" stopColor="#9E3026" />
          <stop offset="0.1" stopColor="#BC392D" />
          <stop offset="0.21" stopColor="#D44032" />
          <stop offset="0.33" stopColor="#E74637" />
          <stop offset="0.47" stopColor="#F54A3A" />
          <stop offset="0.65" stopColor="#FD4C3B" />
          <stop offset="1" stopColor="#FF4D3C" />
        </linearGradient>
        <polygon
          css={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: 'url(#SVGID_2_)' }}
          points="14.6,0.2 17,3.7 15.7,5.6 13.5,2.4 		"
        />
        <polygon
          css={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FF4D3C' }}
          points="28.1,0.2 19.4,0.2 14.1,8 11.7,4.6 9.1,4.6 14.1,11.8 20.6,2.4 23.9,2.4 14.1,16.6 4.2,2.4 13.5,2.4
			13.6,2.5 14.7,0.3 14.6,0.2 0.1,0.2 14.1,20.4 		"
        />
      </g>
    </g>
  );
};
export default VXLogo;
