import { useMutation } from '@apollo/react-hooks';
import { ApolloError, MutationUpdaterFn } from 'apollo-client';
import { MUTATION_MODEL_MEDIA_SET_DAILY_BONUS_OPT_IN_STATUS } from '../../../graphql/VXModels/mutations';
import { DailyBonusOptInStatusEnum, Model, Query } from '../../../graphql/VXModels/types';
import { QUERY_MODEL_MEDIA_DAILY_BONUS_OPT_IN_STATUS } from '../../../graphql/VXModels/queries';

type SetOptInStatus = (status: DailyBonusOptInStatusEnum) => Promise<void>;

interface IReturnValue {
  loading: boolean;
  error?: ApolloError | undefined;
  setOptInStatus: SetOptInStatus;
}

const mutationUpdaterFn: MutationUpdaterFn = (cache, mutationResult) => {
  const { data, errors } = mutationResult;

  const newStatus = data?.model?.media?.setDailyBonusOptInStatus;

  if ((errors && errors.length > 0) || !newStatus) return;

  const cacheContents = cache.readQuery({
    query: QUERY_MODEL_MEDIA_DAILY_BONUS_OPT_IN_STATUS,
  }) as Partial<Query>;

  cache.writeQuery({
    query: QUERY_MODEL_MEDIA_DAILY_BONUS_OPT_IN_STATUS,
    data: {
      ...cacheContents,
      model: {
        ...cacheContents.model,
        media: {
          ...(cacheContents.model as Partial<Model>).media,
          dailyBonusOptInStatus: newStatus,
        },
      },
    },
  });
};

const getOptimisticResponse = (status: DailyBonusOptInStatusEnum): object => ({
  __typename: 'Mutation',
  model: {
    __typename: 'Model',
    media: {
      __typename: 'ModelMedia',
      setDailyBonusOptInStatus: status,
    },
  },
});

const useSetOptInStatus = (): IReturnValue => {
  const [doSetOptInStatus, { loading, error }] = useMutation(
    MUTATION_MODEL_MEDIA_SET_DAILY_BONUS_OPT_IN_STATUS
  );

  const setOptInStatus: SetOptInStatus = async (status) => {
    await doSetOptInStatus({
      variables: { status },
      optimisticResponse: getOptimisticResponse(status),
      update: mutationUpdaterFn,
    });
  };

  return {
    loading,
    error,
    setOptInStatus,
  };
};

export default useSetOptInStatus;
