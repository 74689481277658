import gql from 'graphql-tag';

export const QUERY_MODEL_TVSHOWS = gql`
  query QUERY_MODEL_TVSHOWS {
    model {
      tvshows {
        enabled
        links {
          calendar
        }
      }
    }
  }
`;
