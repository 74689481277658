import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_PHONE_CONDITION,
  BREAKPOINT_SPHONE_CONDITION,
} from '../../../../camtool-styles';

const LinkStyled = styled(Link)`
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;

  @media ${BREAKPOINT_SPHONE_CONDITION} {
    margin: 16px 0;
  }
`;

const getRating = (rating) => {
  if (rating > 0) {
    return rating;
  }
  return 0;
};

const getStyle = (picture) => {
  const style = {};
  if (picture && picture.url) {
    style.backgroundImage = 'url(' + picture.url + ')';
  }
  return style;
};

class OverviewProfilesVXItem extends AbstractComponent {
  onClick(event) {
    event.stopPropagation();
    this.props.onClick(this.props.album);
  }

  render() {
    let mainPicture = {};
    let titlePicture = {};
    const fooPictures = [];

    const album = this.props.album;

    for (let picture of album.pictures) {
      if (picture.isMainProfilePicture) {
        mainPicture = picture;
      } else if (picture.isTitleProfilePicture) {
        titlePicture = picture;
      } else {
        fooPictures[picture.position] = picture;
      }
    }

    return (
      <LinkStyled className="profile-pic__preview__vx__item" to={album.link}>
        <div className="profile-pic__preview__vx__item__editoverlay">
          <div className="profile-pic__preview__vx__item__editoverlay__text">
            {album.changeText}
          </div>
        </div>

        <div className="profile-pic__preview__vx__item__title">{album.title}</div>
        <div className="profile-pic__preview__vx__item__titlepic" style={getStyle(titlePicture)} />
        <div className="profile-pic__preview__vx__item__border">
          <div
            className="profile-pic__preview__vx__item__profilpic"
            style={getStyle(mainPicture)}
          />
          <div className="profile-pic__preview__vx__item__name" />
        </div>
        <div className="profile-pic__preview__vx__item__foo">
          <div
            className="profile-pic__preview__vx__item__foo__item"
            style={getStyle(fooPictures[0])}
          >
            <div className="profile-pic__preview__vx__item__foo__item__overlay">
              <div className="profile-pic__preview__vx__item__foo__item__overlay__status" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__name" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__spacer" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__lang" />
            </div>
          </div>
          <div
            className="profile-pic__preview__vx__item__foo__item"
            style={getStyle(fooPictures[1])}
          >
            <div className="profile-pic__preview__vx__item__foo__item__overlay">
              <div className="profile-pic__preview__vx__item__foo__item__overlay__status" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__name" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__spacer" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__lang" />
            </div>
          </div>
          <div
            className="profile-pic__preview__vx__item__foo__item"
            style={getStyle(fooPictures[2])}
          >
            <div className="profile-pic__preview__vx__item__foo__item__overlay">
              <div className="profile-pic__preview__vx__item__foo__item__overlay__status" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__name" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__spacer" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__lang" />
            </div>
          </div>
          <div
            className="profile-pic__preview__vx__item__foo__item"
            style={getStyle(fooPictures[3])}
          >
            <div className="profile-pic__preview__vx__item__foo__item__overlay">
              <div className="profile-pic__preview__vx__item__foo__item__overlay__status" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__name" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__spacer" />
              <div className="profile-pic__preview__vx__item__foo__item__overlay__lang" />
            </div>
          </div>
        </div>
      </LinkStyled>
    );
  }
}

OverviewProfilesVXItem.propTypes = {
  album: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

OverviewProfilesVXItem.defaultProps = {
  onClick: () => {},
};

export default OverviewProfilesVXItem;
export { OverviewProfilesVXItem };
