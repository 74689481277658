import { MutableRefObject, useEffect } from 'react';

type SomeElement = HTMLDivElement | HTMLButtonElement | HTMLInputElement;

/**
 * Hook that triggers callback upon clicks outside of the passed ref
 */
const useClickOutsideCallback = (ref: MutableRefObject<SomeElement>, callback: () => void): void =>
  useEffect(() => {
    /**
     * Callback if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        (ref as MutableRefObject<SomeElement>).current &&
        !(ref as MutableRefObject<SomeElement>).current.contains(event.target as Element)
      ) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

export default useClickOutsideCallback;
