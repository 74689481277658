import React from 'react';
import cc from 'classcat';
import AbstractDeepLComponent from '../AbstractDeepLComponent';
import MaxValueLength from '../../Form/Display/MaxValueLength/MaxValueLength';

class DeepLInputField extends AbstractDeepLComponent {
  render() {
    const { maxLength, minLength, name } = this.props;
    const currentTranslateValue = this.getTranslateValue() || '';
    const placeholder = `${this.props.placeholder} `;

    return (
      <div
        className={cc([
          'multi-lang-input-std',
          this.getClassNames(),
          {
            'maxvaluelength-anchorframe': maxLength > 0,
          },
        ])}
      >
        <div className="inputbox">
          <input
            name={name ? this.props.name : ''}
            placeholder={placeholder}
            onChange={this.onChange}
            value={currentTranslateValue}
            maxLength={maxLength}
            type="text"
          />
        </div>

        {maxLength > 0 && (
          <MaxValueLength min={minLength} max={maxLength} value={currentTranslateValue.length} />
        )}
      </div>
    );
  }
}

DeepLInputField.propTypes = AbstractDeepLComponent.propTypes;
DeepLInputField.defaultProps = AbstractDeepLComponent.defaultProps;

export default DeepLInputField;
export { DeepLInputField };
