import React from 'react';
import PropTypes from 'prop-types';

import AbstractComponent from '../AbstractComponent';

class ClassicTable extends AbstractComponent {
  renderHeader() {
    return (
      <tr>
        {this.props.cols.map(col => (
          <th key={col.id} onClick={col.onClick}>
            <span className={col.onClick ? 'classic-table__table__header__cell--clickable' : null}>
              {col.label}
            </span>
          </th>
        ))}
      </tr>
    );
  }

  renderRow(row) {
    if (!this.props.data) return null;
    return this.props.cols.map(col => <td key={col.id}>{row[col.id]}</td>);
  }

  renderRows() {
    if (!this.props.data) return null;
    return this.props.data.map((row, i) => (
      <tr className="classic-table__table__row" key={i}>
        {this.renderRow(row)}
      </tr>
    ));
  }

  render() {
    return (
      <article className="classic-table">
        <table className="classic-table__table">
          <thead>{this.renderHeader()}</thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </article>
    );
  }
}

ClassicTable.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
};

export default ClassicTable;
export { ClassicTable };
