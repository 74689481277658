import React from 'react';
import { RenderColumn } from '../../../../atoms/MaterialTable/types';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';
import { Icon } from '../../../../atoms';
import { pinFilled, pin } from './assets/icons';

const RenderColumnPinned: RenderColumn<Broadcast> = ({ isSticky }) => {
  return isSticky ? <Icon icon={pinFilled} /> : <Icon icon={pin} />;
};

export default RenderColumnPinned;
