import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash/clone';

import {
  AbstractComponent,
  EmptyContent,
  FormatCurrency,
  FormatDate,
  FormatDatePeriod,
  T,
} from '../../../components';
import { _ } from '../../../util/translate';
import { WHITE } from '../../../camtool-styles';

class PayoutDataList extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = { displayAll: false };
  }

  toggleShowAll() {
    this.setState({
      displayAll: !this.state.displayAll,
    });
  }

  getIcon(status) {
    switch (status) {
      case 'ok':
        return 'icon-ok';
      case 'error':
        return 'icon-ban-circle';
      case 'waiting':
        return 'icon-clock';
      default:
        return '';
    }
  }

  render() {
    this.data = clone(this.props.data);
    this.data = this.state.displayAll ? this.data : this.data.slice(0, 10);

    return (
      <article className="drop-down-table__item finances-payout__history-list">
        <section style={{ overflowX: 'hidden' }}>
          <header>
            <div>
              <T _={'finances:payout.datalist.column.date'} />
            </div>
            <div>
              <T _={'finances:payout.datalist.column.period'} />
            </div>
            <div>
              <T _={'finances:payout.datalist.column.status'} />
            </div>
            <div>
              <T _={'finances:payout.datalist.column.amount'} />
            </div>
          </header>
          <article>
            <section>
              {this.data.length > 0 && (
                <Fragment>
                  <header />
                  {this.data.map((item) => (
                    <article key={item.invoiceId}>
                      <div>
                        <FormatDate value={item.realPayoutDate} />
                      </div>
                      <div>
                        <FormatDatePeriod from={item.billingStart} to={item.billingEnd} />
                      </div>
                      <div className={this.getIcon(item.status)}>
                        <T _={'finances:PayoutStatus.' + item.statusMessage} />
                      </div>
                      <div>
                        <FormatCurrency value={item.amount} />
                      </div>
                    </article>
                  ))}

                  {this.props.data.length > this.data.length && (
                    <div
                      className={`list-load-more ${
                        this.state.loadMoreButtonDisabled ? 'disabled' : ''
                      }`}
                    >
                      <div>
                        <div onClick={this.toggleShowAll}>
                          <div className="icon" />
                          <div className="label">
                            <T _={'common:button.loadMoreContents'} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}

              {this.data.length < 1 && (
                <EmptyContent title={_('finances:payout.datalist.payoutsNotFound')} />
              )}
            </section>
          </article>
        </section>
      </article>
    );
  }
}

PayoutDataList.propTypes = { data: PropTypes.array.isRequired };
PayoutDataList.defaultProps = { data: [] };

export default PayoutDataList;
export { PayoutDataList };
