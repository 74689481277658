import gql from 'graphql-tag';

export const QUERY_MASTER_FILE_PREVIEW_PICTURE_URL = gql`
  query VXM_CL_QUERY_MASTER_FILE_PREVIEW_PICTURE_URL($token: String!, $uuid: String!) {
    master(token: $token) {
      file(uuid: $uuid) {
        previewPictureUrl
      }
    }
  }
`;
