import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../AbstractComponent';
import { FormatCurrency, FormatDate } from '../../Formatter';

import FancyTextItem from './FancyRowItems/FancyTextItem';

import imgGold from './price-gold.png';
import { _ } from '../../../util/translate';

class FancyRow extends AbstractComponent {
  // used only for competition
  getPrizeImg() {
    if (this.props.prizeVisible) {
      if (this.props.prizeType === 'gold') return <img src={imgGold} style={{ height: 34 }} />;
      else return <img src={imgCash} style={{ height: 34 }} />;
    }
  }

  render() {
    if (this.props.type === 'competition') {
      return (
        <section
          className={[
            'fancy-table__row fancy-table__row--dark',
            this.props.hightlightRow ? this.props.hightlightClassName : '',
          ].join(' ')}
          style={{
            minHeight: 68,
            color: this.props.hightlightRow ? 'rgb(40, 43, 52)' : 'rgb(245, 245, 245)',
          }}
        >
          <FancyTextItem
            styles={{
              width: this.props.width[0] + 'px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.props.rowData.rank}
          </FancyTextItem>

          <FancyTextItem styles={{ flex: 1, backgorund: 'yellow', alignItems: 'center' }}>
            {this.props.rowData.screenName}
          </FancyTextItem>

          <FancyTextItem styles={{ flex: 2, alignItems: 'center' }}>
            {this.getPrizeImg()}
            <span
              style={
                this.props.rowData.prize.toLowerCase() === 'none'
                  ? { paddingLeft: 22 }
                  : { paddingLeft: 8 }
              }
            >
              {_(
                'vxgames:_2018.prize.' +
                  (this.props.prizeType === 'gold' ? 'gold' : 'cash') +
                  '.' +
                  this.props.rowData.prize
              )}
            </span>
          </FancyTextItem>

          <FancyTextItem
            styles={{
              width: this.props.width[3] + 'px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.props.rowData.totalPoints}
          </FancyTextItem>
        </section>
      );
    }

    if (this.props.type === 'stats') {
      return (
        <section
          className="fancy-table__row vxmages18__stats__table-row"
          style={{ paddingLeft: 24, paddingRight: 24 }}
        >
          <FancyTextItem customClass="vxgames__stats_table-col-width">
            {this.props.rowData.category}
          </FancyTextItem>

          <FancyTextItem customClass="vxgames__stats_table-col-width">
            {this.props.rowData.provision}
          </FancyTextItem>

          <FancyTextItem customClass="vxgames__stats_table-col-width">
            {this.props.rowData.factor}
          </FancyTextItem>

          <FancyTextItem customClass="vxgames__stats_table-col-width">
            {this.props.rowData.points}
          </FancyTextItem>
        </section>
      );
    }

    return (
      <section className="fancy-table__row">
        <FancyTextItem styles={{ width: this.props.width[0] + 'px' }}>
          {this.props.rowData.guestlogin}
        </FancyTextItem>

        <FancyTextItem styles={{ width: this.props.width[1] + 'px' }}>
          <FormatDate value={this.props.rowData.date} />
        </FancyTextItem>

        <FancyTextItem styles={{ width: this.props.width[2] + 'px' }}>
          {this.props.rowData.isProductVXPages ? (
            this.props.rowData.credits + ' Credits'
          ) : this.props.rowData.isTypeVoucher ? (
            'Voucher'
          ) : (
            <FormatCurrency value={this.props.rowData.guestAmount} />
          )}
        </FancyTextItem>

        <FancyTextItem styles={{ width: this.props.width[3] + 'px' }}>
          {this.props.rowData.isProductVXPages ? (
            '-'
          ) : (
            <FormatCurrency value={this.props.rowData.hostAmount} />
          )}
        </FancyTextItem>
      </section>
    );
  }
}

FancyRow.propTypes = {
  columsWidth: PropTypes.arrayOf(PropTypes.number),
};

FancyRow.defaultProps = {};

export default FancyRow;
export { FancyRow };
