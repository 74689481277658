import React, { FC } from 'react';
import { Grid, Box } from '@material-ui/core';
import { _ } from '../../../../util/translate';
import { Mailing } from '../../../../graphql/VXModels/types';
import { RadioButton } from '../../../../atoms';
import { MailingRecipientsFilter, SectionLabel } from '../../../Mailings/components';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';

interface SectionRecipientGroupsProps {
  broadcast: Broadcast;
  mailing?: Mailing;
}

const SectionMailingInfo: FC<SectionRecipientGroupsProps> = ({
  broadcast: { sentCount },
  mailing,
}) =>
  mailing ? (
    <Grid container item spacing={3}>
      {mailing.recipientsGroups.options.length > 1 && (
        <Grid item xs={12}>
          <SectionLabel>{_('telegram:BroadcastShow.recipientGroups.label')}</SectionLabel>
          <Box>
            {mailing.recipientsGroups.options.map((option, idx) => (
              <RadioButton
                debug={console.log(
                  mailing.recipientsGroups.selected,
                  option.value,
                  mailing.recipientsGroups.selected === option.value
                )}
                onChange={() => undefined}
                key={idx}
                name={name}
                label={option.label}
                render={'div'}
                disabled
                checked={mailing.recipientsGroups.selected === option.value}
                css={{ marginLeft: idx > 0 ? 16 : 0 }}
              />
            ))}
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <MailingRecipientsFilter
          type={mailing.type}
          recipientsGroup={mailing.recipientsGroups.selected}
          selected={mailing.recipientsFilters.selected}
          count={sentCount}
        />
      </Grid>
    </Grid>
  ) : null;

export default SectionMailingInfo;
