import React from 'react';
import AbstractProfile from '../../AbstractProfile';
import { PROFILE_COMMON_APPEARANCE } from '../../../../stores/Profile/Profile';

import {
  ButtonGroup,
  Dropdown,
  Form,
  GroupRow,
  Markdown,
  OptionBoxCheckbox,
  OptionBoxRadio,
  Range,
  Separator,
  Spinner,
  T,
} from '../../../../components';

import { Button } from '../../../../atoms';
import { withAppState } from '../../../../util/AppState';
import { _ } from '../../../../util/translate';

class Appearance extends AbstractProfile {
  getResource() {
    return PROFILE_COMMON_APPEARANCE;
  }

  getInputNames() {
    return ['modelGender'];
  }

  onChangeHairLength(event) {
    event.stopPropagation();
    this.currentValues.modelHairLength = event.target.value;
    this.forceUpdate();
  }

  render() {
    const currentValues = this.currentValues;
    this.showHairColor = currentValues.modelHairLength !== 'baldHead';
    this.showFemaleTransFields =
      currentValues.modelGender === 'female' || currentValues.modelGender === 'transgender';
    this.showMaleTransFields =
      currentValues.modelGender === 'male' || currentValues.modelGender === 'transgender';

    const [{ lang }] = this.props.appState;

    return (
      <section className="grid__row">
        <section className="grid__column grid__box min-width--0">
          <header className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'profiles:common.appearance.pagetitle.label'} />
            </div>
          </header>

          <div className="grid__box__item">
            <div className="grid__box__item__content">
              <div className="warning-text">
                <div className="warning-text__column">
                  <div className="title">
                    <T _={'profiles:appearance.description.title'} />
                  </div>
                  <Markdown
                    className="subtitle"
                    source={_('profiles:appearance.description.text')}
                  />
                </div>
              </div>
            </div>
          </div>

          <Form
            className="grid__box__item spinner-container"
            // eslint-disable-next-line
            ref="form"
            onValidSubmit={this.submitForm}
            onInvalidSubmit={this.showErrorFields}
            onValid={this.onValid}
            onInvalid={this.onInvalid}
            onChange={this.onChange}
          >
            {this.state.isLoading && <Spinner align="top" />}

            <div className="grid__box__item__content appearance__formwidth">
              {/* Erscheinungsbild : fuer Alle */}
              <GroupRow
                id="style"
                label={`${_('profiles:modelAppearance.label')}:`}
                tooltipText={_('profiles:modelAppearance.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('modelAppearance') ? 'error' : ''}
              >
                <Dropdown
                  name="modelAppearance"
                  value={this.storeValues.modelAppearance}
                  options={this.storeValues.formValues.modelAppearance}
                  placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                />
              </GroupRow>

              {/* Hauttyp : fuer Alle */}
              <GroupRow
                id="skinType"
                label={`${_('profiles:modelSkinType.label')}:`}
                tooltipText={_('profiles:modelSkinType.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('modelSkinType') ? 'error' : ''}
              >
                <Dropdown
                  name="modelSkinType"
                  value={this.storeValues.modelSkinType}
                  options={this.storeValues.formValues.modelSkinType}
                  placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                />
              </GroupRow>

              {/* Figur : fuer Alle */}
              <GroupRow
                id="figure"
                label={`${_('profiles:modelFigure.label')}:`}
                tooltipText={_('profiles:modelFigure.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('modelFigure') ? 'error' : ''}
              >
                <Dropdown
                  name="modelFigure"
                  value={this.storeValues.modelFigure}
                  options={this.storeValues.formValues.modelFigure}
                  placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                />
              </GroupRow>

              {/* Groesse : fuer Alle */}
              <GroupRow
                id="bodySize"
                label={`${_('profiles:modelBodySize.label')}:`}
                tooltipText={_('profiles:modelBodySize.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('modelHeight') ? 'error' : ''}
              >
                <Range
                  required
                  requiredMessage={_('profiles:modelHeight.requiredMessage')}
                  name="modelHeight"
                  value={this.storeValues.modelHeight}
                  range={{ min: 1.3, max: 2.2 }}
                  step={0.01}
                  unit="m"
                  displayTransform={Range.displayTransform.getToDecimalTransform(lang)}
                />
              </GroupRow>

              {/* Gewicht : fuer Alle */}
              <GroupRow
                id="weight"
                label={`${_('profiles:modelBodyWeight.label')}:`}
                tooltipText={_('profiles:modelBodyWeight.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('modelWeight') ? 'error' : ''}
              >
                <Range
                  required
                  requiredMessage={_('profiles:modelBodyWeight.requiredMessage')}
                  name="modelWeight"
                  value={this.storeValues.modelWeight}
                  range={{ min: 45, max: 220 }}
                  step={1}
                  unit="kg"
                />
              </GroupRow>

              {/* Separator */}
              <GroupRow tooltip="none">
                <Separator className="transparent" />
              </GroupRow>

              {/* Haarlaenge : fuer Alle */}
              <GroupRow
                id="hairLength"
                label={`${_('profiles:modelHairLength.label')}:`}
                tooltipText={_('profiles:modelHairLength.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('modelHairLength') ? 'error' : ''}
              >
                <Dropdown
                  name="modelHairLength"
                  value={this.storeValues.modelHairLength}
                  options={this.storeValues.formValues.modelHairLength}
                  placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                  onChange={this.onChangeHairLength}
                />
              </GroupRow>

              {/* Haarfarbe : fuer Alle */}
              {this.showHairColor && (
                <GroupRow
                  id="hairColor"
                  label={`${_('profiles:modelHairColor.label')}:`}
                  tooltipText={_('profiles:modelHairColor.tooltipText')}
                  tooltipPosition="right"
                  className={this.hasInputError('modelHairColor') ? 'error' : ''}
                >
                  <OptionBoxRadio
                    className="option-haircolorselect-std"
                    name="modelHairColor"
                    value={this.storeValues.modelHairColor}
                    options={this.storeValues.formValues.modelHairColor}
                    iconPrefix="icon-"
                    disabled={this.storeValues.isHairColorDisabled}
                  />
                </GroupRow>
              )}

              {/* Separator */}
              <GroupRow tooltip="none">
                <Separator className="transparent" />
              </GroupRow>

              {/* Augenfarbe : fuer Alle */}
              <GroupRow
                id="eyeColor"
                label={`${_('profiles:modelEyeColor.label')}:`}
                tooltipText={_('profiles:modelEyeColor.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('modelEyeColor') ? 'error' : ''}
              >
                <OptionBoxRadio
                  className="option-eyecolorselect-std"
                  name="modelEyeColor"
                  value={this.storeValues.modelEyeColor}
                  options={this.storeValues.formValues.modelEyeColor}
                  iconPrefix="icon-"
                />
              </GroupRow>

              {/* Separator */}
              {this.showFemaleTransFields && (
                <GroupRow tooltip="none">
                  <Separator className="transparent" />
                </GroupRow>
              )}

              {/* Intimrasur : fuer Alle */}
              {this.showFemaleTransFields && (
                <GroupRow
                  id="pubicHair"
                  label={`${_('profiles:modelPubicHair.label')}:`}
                  tooltipText={_('profiles:modelPubicHair.tooltipText')}
                  tooltipPosition="right"
                  className={this.hasInputError('modelPubicHair') ? 'error' : ''}
                >
                  <OptionBoxRadio
                    name="modelPubicHair"
                    value={this.storeValues.modelPubicHair}
                    options={this.storeValues.formValues.modelPubicHair}
                  />
                </GroupRow>
              )}

              {/* Separator */}
              <GroupRow tooltip="none">
                <Separator className="transparent" />
              </GroupRow>

              {/* Koerbchengroesse : fuer Female only */}
              {this.showFemaleTransFields && (
                <GroupRow
                  id="breastSize"
                  label={`${_('profiles:modelBreastSize.label')}:`}
                  tooltipText={_('profiles:modelBreastSize.tooltipText')}
                  tooltipPosition="right"
                  className={this.hasInputError('modelBreastSize') ? 'error' : ''}
                  errorMessage={this.state.displayErrorInputs['modelBreastSize']}
                >
                  <OptionBoxRadio
                    required
                    requiredMessage={_('profiles:modelAppearance.requiredMessage')}
                    name="modelBreastSize"
                    options={this.storeValues.formValues.modelBreastSize}
                    value={this.storeValues.modelBreastSize}
                    disabled={this.storeValues.isBreastSizeDisabled}
                  />
                </GroupRow>
              )}

              {/* Separator */}
              {this.showMaleTransFields && (
                <GroupRow
                  tooltip="none"
                  className={this.hasInputError('modelBeard') ? 'error' : ''}
                >
                  <Separator className="transparent" />
                </GroupRow>
              )}

              {/* Bart : fuer Male und Transsexuell only */}
              {this.showMaleTransFields && (
                <GroupRow
                  id="beard"
                  label={`${_('profiles:modelBeard.label')}:`}
                  tooltipText={_('profiles:modelBeard.tooltipText')}
                  tooltipPosition="right"
                  className={this.hasInputError('modelBeard') ? 'error' : ''}
                >
                  <OptionBoxRadio
                    name="modelBeard"
                    value={this.storeValues.modelBeard}
                    options={this.storeValues.formValues.modelBeard}
                  />
                </GroupRow>
              )}

              {/* Separator */}
              {this.showMaleTransFields && (
                <GroupRow
                  tooltip="none"
                  className={this.hasInputError('modelHairness') ? 'error' : ''}
                >
                  <Separator className="transparent" />
                </GroupRow>
              )}

              {/* Koerperbehaarung : fuer Male und Transsexuell only */}
              {this.showMaleTransFields && (
                <GroupRow
                  id="bodyHair"
                  label={`${_('profiles:modelHairness.label')}:`}
                  tooltipText={_('profiles:modelHairness.tooltipText')}
                  tooltipPosition="right"
                  className={this.hasInputError('modelHairness') ? 'error' : ''}
                >
                  <OptionBoxRadio
                    name="modelHairness"
                    value={this.storeValues.modelHairness}
                    options={this.storeValues.formValues.modelHairness}
                  />
                </GroupRow>
              )}

              {/* Separator */}
              {this.showMaleTransFields && (
                <GroupRow tooltip="none">
                  <Separator className="transparent" />
                </GroupRow>
              )}

              {/* Penisgroesse : fuer Male und Transsexuell only */}
              {this.showMaleTransFields && (
                <GroupRow
                  id="penisSize"
                  label={`${_('profiles:modelPenisSize.label')}:`}
                  tooltipText={_('profiles:modelPenisSize.tooltipText')}
                  tooltipPosition="right"
                  className={this.hasInputError('modelPenisSize') ? 'error' : ''}
                  errorMessage={this.state.displayErrorInputs['modelPenisSize']}
                >
                  <OptionBoxRadio
                    required
                    requiredMessage={_('profiles:modelPenisSize.requiredMessage')}
                    name="modelPenisSize"
                    value={this.storeValues.modelPenisSize}
                    options={this.storeValues.formValues.modelPenisSize}
                  />
                </GroupRow>
              )}

              {/* Separator */}
              <GroupRow tooltip="none">
                <Separator className="transparent" />
              </GroupRow>

              {/* Koerperschmuck : fuer Alle */}
              <GroupRow
                id="bodyModification"
                label={`${_('profiles:modelBodyModification.label')}:`}
                tooltipText={_('profiles:modelBodyModification.tooltipText')}
                tooltipPosition="right"
                className={this.hasInputError('modelBodyModification') ? 'error' : ''}
              >
                <OptionBoxCheckbox
                  name="modelBodyModification"
                  value={this.storeValues.modelBodyModification}
                  options={this.storeValues.formValues.modelBodyModification}
                />
              </GroupRow>

              {/* Separator */}
              <GroupRow tooltip="none">
                <Separator className="transparent" />
              </GroupRow>

              <ButtonGroup position="right">
                <Button
                  theme="gray"
                  label={_('common:button.cancel')}
                  onClick={this.rejectChanges}
                  disabled={!this.state.hasChanges}
                  css={{ marginRight: 16 }}
                />
                <Button type="submit" theme="blue" label={_('common:button.save')} />
              </ButtonGroup>
            </div>
          </Form>
        </section>
      </section>
    );
  }
}

export default withAppState(Appearance);
