import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BLACK_1, RED, GRAY_ULTRATHIN, WHITE, GRAY_3 } from '../../camtool-styles';
import Icon from '../Icon/Icon';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const InputRow = styled.div`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const InputErrorIcon = styled(Icon)`
  margin-left: 8px;
  color: ${RED};
`;

const InputError = styled.div`
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
  color: ${RED};
  font-size: 1rem;
  font-style: italic;
`;

const StyledInput = styled.input<Pick<IProps, 'disabled'> & { hasError: boolean }>`
  padding: 0 10px;
  min-height: 32px;
  align-items: center;
  width: 100%;
  background-color: ${(props): string => (props.disabled ? GRAY_3 : WHITE)};
  color: ${(props): string => (!!props.hasError ? RED : BLACK_1)};
  border: 1px solid;
  border-color: ${(props): string => (!!props.hasError ? RED : GRAY_ULTRATHIN)};
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  &[readonly] {
    background-color: transparent;
  }
`;

export interface InputFieldProps {
  label?: string;
  maxLength?: number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value?: string;
  elRef?: React.RefObject<HTMLInputElement>;
  readOnly?: boolean;
  disabled?: boolean;
  name?: string;
  errorMessage?: string;
  className?: string;
  containerStyles?: object;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<InputFieldProps> = ({
  className = '',
  label = '',
  maxLength = Infinity,
  name = '',
  placeholder = '',
  readOnly = false,
  disabled = false,
  type = 'text',
  errorMessage,
  elRef,
  onBlur,
  onChange,
  onKeyDown,
  onClick,
  value,
  containerStyles,
}) => {
  const hasError = !!errorMessage;
  return (
    <>
      <label css={{ flex: 1, ...containerStyles }}>
        {label}
        <InputRow>
          <StyledInput
            ref={elRef}
            name={name}
            value={value}
            type={type}
            onChange={onChange}
            maxLength={maxLength}
            placeholder={placeholder}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            className={className}
            onClick={onClick}
            disabled={disabled}
            hasError={hasError}
          />
          {hasError && <InputErrorIcon size="16px" icon={faExclamationCircle} />}
        </InputRow>
      </label>
      {hasError && <InputError>{errorMessage}</InputError>}
    </>
  );
};

export default InputField;
