import React, { FC, RefObject, useEffect, useState, useRef } from 'react';
import { ContentState, Editor, EditorState, Modifier, RichUtils, DraftHandleValue } from 'draft-js';
import styled from '@emotion/styled';
import { BLACK_1 } from '../../../../../camtool-styles';
import EmojiPicker from '../../../../../atoms/TextEditor/EmojiPicker/EmojiPicker';

const Container = styled.div<Pick<Props, 'disabled'>>`
  position: relative;
  flex-direction: column;
  color: ${(props): string => (props.disabled ? '#b0b0b0' : BLACK_1)};
  width: 100%;
  height: 100%;
  overfow: hidden;

  div {
    display: block;
  }

  & > .DraftEditor-root {
    overflow: auto;
  }
  // cursorfix draftjs not working with flexbox props
  & .public-DraftStyleDefault-block > span {
    display: block;
  }
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
  emoji?: boolean;
  disabled?: boolean;
}
const MessengerEditor: FC<Props> = ({ value, onChange, emoji = false, disabled = false }) => {
  const [editorState, setEditorState] = useState(
    // in case editor has not been focused before - text should be appended to end
    value
      ? EditorState.moveSelectionToEnd(
          EditorState.createWithContent(ContentState.createFromText(value))
        )
      : EditorState.moveFocusToEnd(EditorState.createEmpty())
  );

  const editorRef: RefObject<Editor> = useRef(null);

  const focusEditor = (): void => {
    editorRef.current?.focus();
  };

  useEffect(() => {
    focusEditor();
  }, []);

  const handleKeyCommand = (command: string, editorState: EditorState): DraftHandleValue => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);
    if (newEditorState) {
      setEditorState(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleInsertEmoji = (emoji: string): void => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      emoji
    );

    const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
    setEditorState(newEditorState);
  };

  useEffect(() => {
    onChange(editorState.getCurrentContent().getPlainText());
  }, [editorState]);

  return (
    <Container onClick={focusEditor} disabled={disabled}>
      <Editor
        ref={editorRef}
        readOnly={disabled}
        editorState={editorState}
        onChange={setEditorState}
        handleKeyCommand={handleKeyCommand}
      />

      {emoji && !disabled && (
        <EmojiPicker
          onInsertEmoji={handleInsertEmoji}
          disabled={disabled}
          css={{ position: 'absolute', right: 18, bottom: 0, zIndex: 1 }}
        />
      )}
    </Container>
  );
};

export default MessengerEditor;
