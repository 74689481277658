import React, { FC, ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import { TextFieldProps } from '@material-ui/core';
import { NumberInput } from '../../../atoms';

const FieldNumberInput: FC<TextFieldProps> = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form: { errors, touched, setFieldValue } }: FieldProps): ReactElement => {
      const fieldErrors = errors[field.name] && touched[field.name];
      const helperText = errors[field.name];

      return (
        <NumberInput
          {...field}
          error={fieldErrors}
          helperText={helperText}
          onChange={(value: number): void => setFieldValue(field.name, value)}
          {...props}
        />
      );
    }}
  </Field>
);

export default FieldNumberInput;
