import React, { FC } from 'react';
import styled from '@emotion/styled';
import Icon, { IconType as IconDefinition } from '../../../../atoms/Icon/Icon';
import { BLACK_2, GRAY_2, WHITE_2 } from '../../../../camtool-styles';

interface Props {
  icon: IconDefinition;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const containerBorder = `1px solid ${WHITE_2}`;

const Container = styled.div`
  padding: 15px 15px;
  color: ${BLACK_2};
  font-size: 16px;
  letter-spacing: 0.16px;
  justify-content: center;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: ${GRAY_2};
  }

  border-top: ${containerBorder};

  &:last-of-type {
    border-bottom: ${containerBorder};
  }
`;

const Action = styled.div`
  width: 128px;
  align-items: center;
`;

/**
 * Not all of our icons are exactly 12px wide, so we wrap them in a container that is exactly 12px wide
 */
const IconContainer = styled.div`
  justify-content: center;
  width: 12px;
  margin-right: 6px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

const Label = styled.div`
  flex: 1 0 0;
  display: block !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ActionItem: FC<Props> = ({ icon, label, onClick }) => (
  <Container onClick={onClick}>
    <Action>
      <IconContainer>
        <StyledIcon icon={icon} size="12px" />
      </IconContainer>
      <Label>{label}</Label>
    </Action>
  </Container>
);

export default ActionItem;
