import React, { FC } from 'react';
import { TableCell, TableRow } from '../../../../atoms/Table';
import { ITableColumn } from '../../../../atoms/Table/types';
import { Code } from '../../types';
import StatusIconLabel from './Cells/StatusIconLabel';
import PlatformLabel from './Cells/PlatformLabel';
import NameLabel from './Cells/NameLabel';
import TypeLabel from './Cells/TypeLabel';
import TargetGroupsLabel from './Cells/TargetGroupsLabel';
import ValidPeriodLabel from './Cells/ValidPeriodLabel';
import { useHistory, useRouteMatch } from 'react-router-dom';
interface Props {
  active: boolean;
  benefit: Code;
  columns: ITableColumn[];
}

const BonusCodesTableRow: FC<Props> = ({ active, benefit, columns }) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const show = () => {
    history.push(`${path}/${benefit.code}`);
  };

  return (
    <TableRow {...(active ? { onClick: show, css: { cursor: 'pointer' } } : undefined)}>
      <TableCell columns={columns} index={0}>
        <StatusIconLabel status={benefit.status} />
      </TableCell>
      <TableCell columns={columns} index={1}>
        <PlatformLabel code={benefit} />
      </TableCell>
      <TableCell columns={columns} index={2}>
        <NameLabel code={benefit} />
      </TableCell>
      <TableCell columns={columns} index={3}>
        {benefit.code}
      </TableCell>
      <TableCell columns={columns} index={4}>
        <TypeLabel code={benefit} />
      </TableCell>
      <TableCell columns={columns} index={5}>
        <TargetGroupsLabel code={benefit} />
      </TableCell>
      <TableCell columns={columns} index={6}>
        <ValidPeriodLabel from={benefit.config.validFrom} to={benefit.config.validTo} />
      </TableCell>
      <TableCell columns={columns} index={7}>
        {benefit.redeemCount}
      </TableCell>
    </TableRow>
  );
};

export default BonusCodesTableRow;
