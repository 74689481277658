import React, { FC } from 'react';
import { Main } from '../../atoms';
import { TelegramBot } from '../../packages/Telegram';

const BotRoute: FC = () => (
  <Main isMobileEnabled>
    <TelegramBot />
  </Main>
);

export default BotRoute;
