import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Button from '../Button/Button';
import ProfilePictureInfobox from '../ProfilePictureInfobox/ProfilePictureInfobox';
import { APP_BASE_PATH } from '../../util/env';

// TODO: Replace this function with ultil function that does this.
const sendLinkToPhoneNumber = (link, phoneNumber) => {
  console.warn(`${link} sent to ${phoneNumber}.`);
};

const StyledInput = styled.input`
  color: #6c6c6c;
  background-color: #dcdcde;
  border: 1px solid #dcdcde;
  border-radius: 5px;
  padding: 8px;
  margin-top: 4px;
`;

const LinkToPhone = props => {
  const { label, link, phoneNumber, containerClass, buttonText, editButtonText, children } = props;

  if (!phoneNumber) {
    return (
      <ProfilePictureInfobox
        iconSize={1}
        css={{
          color: '#e67e22',
          fontSize: 16,
          backgroundColor: 'transparent',
          padding: '8px 0',
          flex: 'auto',
        }}
      >
        {children}
      </ProfilePictureInfobox>
    );
  }

  return (
    <div className={containerClass} css={{ flexDirection: 'column' }}>
      <label htmlFor="number" css={{ padding: '4px 0' }}>
        {label}
      </label>

      <div>
        <StyledInput id="number" disabled type="text" value={phoneNumber} />

        <Button
          className="button button--blue"
          label={buttonText}
          onClick={() => sendLinkToPhoneNumber(link, phoneNumber)}
          css={{ margin: '0 4px', padding: '4px 0 0 0', alignSelf: 'flex-start' }}
        />
      </div>

      <Button
        className="button link--grey"
        label={editButtonText}
        to={`${APP_BASE_PATH}/account/personaldata`}
        css={{ padding: 0, alignSelf: 'flex-start' }}
      />
    </div>
  );
};

LinkToPhone.propTypes = {
  link: PropTypes.string,
  phoneNumber: PropTypes.string,
  buttonText: PropTypes.string,
  editButtonText: PropTypes.string,
  label: PropTypes.string,
  containerClass: PropTypes.string,
};

LinkToPhone.defaultProps = {
  buttonText: 'Send',
  editButtonText: 'Edit',
  label: 'Your Handynummer',
  containerClass: '',
};

export default LinkToPhone;
