import React, { FC } from 'react';
import styled from '@emotion/styled';
import { _ } from '../../../util/translate';
import { Typography } from '@material-ui/core';
import Section from '../../../atoms/Grid/Section';
import VXCashAdvantages from './VXCashAdvantages';
import Teaser from './Assets/teaser.jpg';
import Header from './Assets/header.jpg';
import { Main } from '../../../atoms/Grid';
import TeaserBanner from '../../../components/TeaserBanner/TeaserBanner';
import { BadgesBanner } from '../../../atoms';
import { DetailsListItem } from '../../../atoms/Banner';
import UserGroup from '../../../atoms/Icon/UserGroup';
import SignUpCheckmark from '../../../atoms/Icon/SignUpCheckmark';
import Euro from '../../../atoms/Icon/Euro';
import TOSContent from './TOSContent';
import { WHITE_3 } from '../../../camtool-styles';
import { APP_BASE_PATH } from '../../../util/env';

const TOSContentStyle = styled.div`
  background-color: ${WHITE_3};
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const Register: FC = () => {
  return (
    <Main isMobileEnabled>
      <Section>
        <BadgesBanner
          defaultExpanded
          backgroundImage={Header}
          backgroundImagePosition={'bottom center'}
          summaryText={
            <Typography color="inherit">
              {_('vxcash:register.acceptTerms.header.primaryTitle')}
            </Typography>
          }
          benefitList={[
            {
              icon: <UserGroup />,
              text: (
                <DetailsListItem>{`1. ${_(
                  'vxcash:register.acceptTerms.header.leftBadgePrimaryText'
                )}`}</DetailsListItem>
              ),
            },
            {
              icon: <SignUpCheckmark />,
              text: (
                <>
                  <DetailsListItem>{`2. ${_(
                    'vxcash:register.acceptTerms.header.centerBadgePrimaryText'
                  )}`}</DetailsListItem>
                  <DetailsListItem>
                    {_('vxcash:register.acceptTerms.header.centerBadgeSecondaryText')}
                  </DetailsListItem>
                </>
              ),
            },
            {
              icon: <Euro />,
              text: (
                <DetailsListItem>{`3. ${_(
                  'vxcash:register.acceptTerms.header.rightBadgePrimaryText'
                )}`}</DetailsListItem>
              ),
            },
          ]}
          detailsText={
            <Typography color="inherit" align="center">
              {_('vxcash:register.acceptTerms.header.secondaryTitle')}
            </Typography>
          }
        />
        <VXCashAdvantages />
        <TeaserBanner
          background={Teaser}
          primaryText={_(`vxcash:register.acceptTerms.teaser.primaryTitle`)}
          secondaryText={_(`vxcash:register.acceptTerms.teaser.secondaryTitle`)}
        />
        <TOSContentStyle>
          <TOSContent
            headerText={_(`vxcash:register.acceptTerms.actionCall.title`)}
            labelSource={_('vxcash:register.aceptText')}
            buttonText={_(`vxcash:register.acceptTerms.actionCall.submit`)}
            onSuccess={() => (window.location.href = `${APP_BASE_PATH}/vxcash/invite-users`)}
          />
        </TOSContentStyle>
      </Section>
    </Main>
  );
};

export default Register;
