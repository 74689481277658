import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import AbstractComponent from '../AbstractComponent';
import { SessionStore } from '../../stores/Session/Session';
import { JsUploaderPictureStore, JsUploaderVideoStore } from '../../stores/JsUploader/JsUploader';
import { MediaManagementStore } from '../../stores/MediaManagement/MediaManagement';
import * as ActionTypes from '../../util/constants/ActionTypes';
import AlertsStore from '../../stores/Alerts/AlertsStore';
import UploadField from '../MediaUploader/UploadField/UploadField';
import UploadQueue from './UploadQueue/UploadQueue';
import { _ } from '../../util/translate';
import { APP_BASE_PATH } from '../../util/env';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION, BREAKPOINT_TABLET_CONDITION } from '../../camtool-styles';
import { NavLink } from 'react-router-dom';

const Row = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Col = styled.div`
  min-height: 216px;
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -webkit-flex: 1 1 100% !important;
    flex: 1 1 100%;
  }
`;

class MediaMultiUploader extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      queue: [],
      isActive: false,
      isPaused: false,
    };

    switch (props.type) {
      case 'video':
        this.jsUploaderStore = JsUploaderVideoStore;
        break;
      case 'picture':
        this.jsUploaderStore = JsUploaderPictureStore;
        break;
      default:
        throw {
          error: 'Invalid JsUploader type: ' + this.props.type,
          type: 'InvalidJsUploaderType',
        };
    }
    this.acceptedMimeTypes = this.jsUploaderStore.options.allowedMimeTypes;
  }

  componentWillMount() {
    this.jsUploaderStore.on('stateChanged', this.onJsUploaderStoreChange);
    SessionStore.addChangeListener(this.requestExternalUpdate);
    MediaManagementStore.addServerEventListener(
      ActionTypes.MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA,
      this.requestExternalUpdate
    );
    this.updateState();
    this.requestExternalUpdate();
  }

  componentWillUnmount() {
    this.jsUploaderStore.removeListener('stateChanged', this.onJsUploaderStoreChange);
    SessionStore.removeChangeListener(this.requestExternalUpdate);
    MediaManagementStore.removeServerEventListener(
      ActionTypes.MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA,
      this.requestExternalUpdate
    );
  }

  onJsUploaderStoreChange() {
    this.updateState();
  }

  onUpload(files) {
    try {
      this.jsUploaderStore.addFiles(files);
    } catch (err) {
      console.warn(err);
      switch (err.type) {
        case 'InvalidMimeTypeException':
          AlertsStore.add({
            type: 'error',
            message:
              _('mediamanagement:multiuploader.uploadfield.errorMessageFileExtension') +
              ': ' +
              this.jsUploaderStore.options.allowedFileExtensions.join(', '),
          });
          break;
        default:
          AlertsStore.add({
            type: 'error',
            message: _('mediamanagement:multiuploader.uploadfield.errorMessageCommon'),
          });
          break;
      }
    }
  }

  onClickQueueItem(entry) {
    // console.log(this.__proto__.constructor.name, 'onClickQueueItem', entry, this.props);
    if (entry.state === 'complete') {
      switch (this.props.type) {
        case 'picture':
          this.props.history.push(APP_BASE_PATH + '/mediamanagement/picture/pool/');
          break;
        case 'video':
          this.props.history.push(APP_BASE_PATH + '/mediamanagement/video/edit/' + entry.umaId);
          break;
      }
    }
  }

  onClickRemoveQueueItem(entry) {
    this.jsUploaderStore.removeEntry(entry);
  }

  onClickControlQueueItem(entry) {
    //Toggle Play/Pause
    const currentUploadEntry = this.jsUploaderStore.state.currentUploadEntry;
    if (currentUploadEntry && currentUploadEntry.key === entry.key) {
      if (this.jsUploaderStore.state.isPaused) {
        this.jsUploaderStore.startUploads();
      } else {
        this.jsUploaderStore.pauseUploads();
      }
    } else {
      this.jsUploaderStore.prioritizeEntry(entry);
      this.jsUploaderStore.startUploads();
    }
  }

  updateState() {
    this.setState({
      queue: cloneDeep(this.jsUploaderStore.state.queue) || [],
      isActive: this.jsUploaderStore.state.isActive,
      isPaused: this.jsUploaderStore.state.isPaused,
    });
    this.forceUpdate();
  }

  requestExternalUpdate() {
    switch (this.props.type) {
      case 'video':
        JsUploaderVideoStore.getTranscodingStatus();
        break;
    }
  }

  render() {
    return (
      <Row className="grid__row mediamultiuploader">
        <Col className="grid__column grid__box">
          <div className="grid__box__row">
            <div className="grid__box__column">
              <div className="grid__box__item">
                <div className="grid__box__item__content">
                  <UploadField
                    label={_(`mediamanagement:multiuploader.uploadfield.title${this.props.type}`)}
                    subtitle={_(
                      `mediamanagement:multiuploader.uploadfield.subtitle${this.props.type}`
                    )}
                    type={this.props.type}
                    onUpload={this.onUpload}
                    acceptedMimeTypes={this.acceptedMimeTypes}
                    multiple={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <UploadQueue
          type={this.props.type}
          queue={this.state.queue}
          onClickQueueItem={this.onClickQueueItem}
          onClickRemoveQueueItem={this.onClickRemoveQueueItem}
          onClickControlQueueItem={this.onClickControlQueueItem}
        />
      </Row>
    );
  }
}

MediaMultiUploader.propTypes = {
  type: PropTypes.oneOf(['video', 'picture']),
};

MediaMultiUploader.defaultProps = {};

MediaMultiUploader.debug = false;

export default withRouter(MediaMultiUploader);
