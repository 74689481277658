import React, { FC } from 'react';
import styled from '@emotion/styled';
import { DARK_GRAY, WHITE } from '../../../camtool-styles';
import Icon from '../../../atoms/Icon/Icon';
import Modalbox, { modalboxMargin } from '../../Modalbox/Modalbox';
import { remove } from '../../../atoms/Icon/libraries/glyph';
import { Button } from '../../../atoms';
import { _ } from '../../../util/translate';
import { BREAKPOINT_LARGE, BREAKPOINT_SMALL } from './breakpoints';

interface IProps {
  onConfirm: () => void;
  onAbort: () => void;
}

const ModalContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  border-radius: 2px;
  background: ${WHITE};
  width: 100%;
  max-width: 100vw;
  color: ${DARK_GRAY};
  margin: ${modalboxMargin}px;
  padding: 81px 150px 98px;
  text-align: center;

  @media (max-width: ${BREAKPOINT_LARGE}px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0;
  }
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  padding: 20px;
  top: 0;
  right: 0;
  cursor: pointer;
  color: ${DARK_GRAY};
`;

const StyledModalbox = styled(Modalbox)`
  max-width: 784px;
  max-height: 100%;
  padding: 0;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 11px;
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 46px;
`;

const ButtonContainer = styled.div`
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column-reverse;
    justify-content: initial;
  }
`;

const ConfirmButton = styled(Button, {
  shouldForwardProp: (): boolean => true,
})`
  margin-left: 25px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

export const ModalDailyBonusOptOutConfirmation: FC<IProps> = ({ onConfirm, onAbort }) => {
  return (
    <StyledModalbox
      noCloseOnEsc
      name="daily-bonus-opt-out-confirmation"
      state={true}
      zIndex={1001} // zIndex 1001 to beat ModalDailyBonusOptIn zIndex of 1000
    >
      <ModalContainer>
        <Title>{_('modalbox:dailyBonusConfirm.title')}</Title>
        <Description>{_('modalbox:dailyBonusConfirm.description')}</Description>

        <ButtonContainer>
          <Button theme="border-gray" size="lg" onClick={onAbort}>
            {_('common:button.cancel')}
          </Button>
          <ConfirmButton theme="border-gray" size="lg" onClick={onConfirm}>
            {_('modalbox:dailyBonusConfirm.buttonOptOut')}
          </ConfirmButton>
        </ButtonContainer>

        <CloseIcon icon={remove} size="17px" onClick={onAbort} />
      </ModalContainer>
    </StyledModalbox>
  );
};

export default ModalDailyBonusOptOutConfirmation;
