const isLegacyBrowser = !!window.attachEvent; // IE < 11

export const hasConfirmExit = (): boolean =>
  isLegacyBrowser ? !!window.beforeunload : !!window.onbeforeunload;

export const registerConfirmExit = (msg: string) => {
  if (isLegacyBrowser) {
    window.beforeunload = () => msg;
  } else {
    window.onbeforeunload = () => msg;
  }
};

export const unregisterConfirmExit = () => {
  if (isLegacyBrowser) {
    window.beforeunload = null;
  } else {
    window.onbeforeunload = null;
  }
};
