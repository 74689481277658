import { Mailing } from '../../graphql/VXModels/types';

export enum ActionEnum {
  SAVE = 'save',
  PUBLISH = 'publish',
  DELETE = 'delete',
  TEST = 'test',
}

export type BuildUrl = (mailing: Mailing) => string;
