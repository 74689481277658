import React from 'react';
import PropTypes from 'prop-types';
import AbstractFormComponent from '../Form/AbstractFormComponent';

class Checkbox extends AbstractFormComponent {
  render() {
    const { name, noLabel, label, disabled, className = '', vxGamesBox } = this.props;
    return (
      <div
        className={`option-checkbox-std ${className} ${
          noLabel ? 'option-checkbox-std--inline' : ''
        }`}
      >
        {!vxGamesBox && (
          <div>
            <input
              type="checkbox"
              id={name}
              name={name}
              checked={this.isChecked()}
              disabled={disabled}
              onChange={this.handleChange}
            />
            <label htmlFor={name}>
              <div className="checkbox">
                <div />
              </div>
              {!noLabel && <div className="textbox">{label}</div>}
            </label>
          </div>
        )}

        {vxGamesBox && (
          <div>
            <input
              type="checkbox"
              id={name}
              name={name}
              checked={this.isChecked()}
              disabled={disabled}
              onChange={this.handleChange}
            />
            <label htmlFor={name}>
              <div className="checkbox" style={{ width: '100%' }}>
                <div
                  className="checkbox-games"
                  style={{
                    borderColor: '#282828',
                    width: 24,
                    height: 24,
                    flex: '0 0 auto',
                    fontSize: 20,
                  }}
                />
              </div>
              {!noLabel && <div className="textbox">{label}</div>}
            </label>
          </div>
        )}
      </div>
    );
  }

  handleChange(event) {
    event.stopPropagation();
    this.setValue(event.currentTarget.checked);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  }

  isChecked() {
    return this.getValue();
  }
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  styles: PropTypes.object,
};

export default Checkbox;
export { Checkbox };
