import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { T } from '../../../components';
import Layout from './Layout';
import affiliate from './img/vxmodels-affiliate.jpg';
import videos from './img/vxmodels-amateur-videos.jpg';
import flirtMsgs from './img/vxmodels-flirtnachrichten.jpg';
import phoneService from './img/vxmodels-telefonservice.jpg';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';

interface IncomeSourceItemProps {
  picture: string;
  title: string;
  description: string;
  url: string;
}

const IncomeSourceItem = ({
  picture,
  title,
  description,
  url,
}: IncomeSourceItemProps): ReactElement => (
  <div
    css={{
      background: `url('${picture}')`,
      backgroundPositionX: 'center',
      backgroundSize: 'cover',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      margin: '32px',
      width: 207,
      height: 200,
    }}
  >
    <div
      css={{
        backgroundColor: 'rgba(255, 255, 255, .7)',
        flexDirection: 'column',
        padding: 4,
        a: { color: '#525156' },
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          cursor: 'default',
          a: {
            color: '#1f93e9',
          },
        },
      }}
    >
      <h3 data-testid="income-source-item-title">{title}</h3>
      <p data-testid="income-source-item-description">{description}</p>
      <Link to={url}>{_('common:text.learnMore')}</Link>
    </div>
  </div>
);

const Container = styled.div`
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
  padding: 24px 16px;
  justify-content: space-between;
  text-align: center;
`;

interface IncomeSourceProps {
  loading: boolean;
}

const IncomeSource = ({ loading }: IncomeSourceProps): ReactElement => {
  const INCOME_SOURCE_DATA = [
    {
      description: _('dashboard:app.incomeSourcesDescription.flirtMsgDescription'),
      picture: flirtMsgs,
      title: _('dashboard:app.incomeSourcesDescription.flirtMsgTitle'),
      url: `${APP_BASE_PATH}/onlineprofile/lustagenten`,
    },
    {
      description: _('dashboard:app.incomeSourcesDescription.videosDescription'),
      picture: videos,
      title: _('dashboard:app.incomeSourcesDescription.videosTitle'),
      url: `${APP_BASE_PATH}/mediamanagement/video/all`,
    },
    {
      description: _('dashboard:app.incomeSourcesDescription.telDescription'),
      picture: phoneService,
      title: _('dashboard:app.incomeSourcesDescription.telTitle'),
      url: `${APP_BASE_PATH}/service0900/beianrufsex`,
    },
    {
      description: _('dashboard:app.incomeSourcesDescription.affiliateDescription'),
      picture: affiliate,
      title: _('dashboard:app.incomeSourcesDescription.affiliateTitle'),
      url: `${APP_BASE_PATH}/vxcash/invite-users`,
    },
  ];

  return (
    <Layout
      title={_('dashboard:app.incomeSourcesDescription.header')}
      loading={loading}
      css={{ flex: '1 1 auto' }}
    >
      <Container css={{ flex: 1 }}>
        <h3 css={{ fontSize: 18 }}>
          <T _={'dashboard:app.incomeSourcesDescription.title'} />
        </h3>
        <p css={{ maxWidth: 365, textAlign: 'center', color: '#8e8e8e', fontSize: 14 }}>
          <T _={'dashboard:app.incomeSourcesDescription.subTitle'} />
        </p>

        <div css={{ flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
          <div css={{ justifyContent: 'center' }}>
            <IncomeSourceItem {...INCOME_SOURCE_DATA[0]} />
            <IncomeSourceItem {...INCOME_SOURCE_DATA[1]} />
          </div>

          <div css={{ justifyContent: 'center' }}>
            <IncomeSourceItem {...INCOME_SOURCE_DATA[2]} />
            <IncomeSourceItem {...INCOME_SOURCE_DATA[3]} />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default IncomeSource;
