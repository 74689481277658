import React, { FC, useEffect, useRef } from 'react';
import LottieWeb, {
  AnimationConfigWithData,
  AnimationConfigWithPath,
  AnimationDirection,
  AnimationItem,
} from 'lottie-web';

import { getSize } from './util';

type LottieProps = {
  height?: number | string;
  width?: number | string;
  stopped?: boolean;
  paused?: boolean;
  speed?: number;
  direction?: AnimationDirection;
  ariaRole?: string;
  ariaLabel?: string;
  isClickToPauseDisabled?: boolean;
  title?: string;
  style?: CSSStyleDeclaration;
} & Omit<AnimationConfigWithPath | AnimationConfigWithData, 'container'>;

const Lottie: FC<LottieProps> = (props) => {
  const {
    width,
    height,
    style,
    title = '',
    ariaRole = 'button',
    ariaLabel = 'animation',
    path,
    animationData,
    renderer = 'svg',
    loop = true,
    autoplay = true,
    initialSegment,
    name,
    assetsPath,
    rendererSettings,
    stopped = false,
    paused = false,
    speed = 1,
    direction = 1,
  } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const animRef = useRef<AnimationItem | undefined>(undefined);

  useEffect(() => {
    const params = {
      container: containerRef.current!,
      path,
      animationData,
      renderer,
      loop,
      autoplay,
      initialSegment,
      name,
      assetsPath,
      rendererSettings,
    } as AnimationConfigWithPath | AnimationConfigWithData;
    animRef.current = LottieWeb.loadAnimation(params);

    return (): void => {
      const anim = animRef.current;
      anim?.destroy();
    };
  }, [
    path,
    animationData,
    renderer,
    loop,
    autoplay,
    initialSegment,
    name,
    assetsPath,
    rendererSettings,
  ]);

  useEffect(() => {
    const anim = animRef.current;
    if (anim) {
      if (stopped) {
        anim.stop();
      } else {
        anim.play();
      }

      if (paused !== anim.isPaused) {
        anim.pause();
      }

      anim.setSpeed(speed);
      anim.setDirection(direction as AnimationDirection);
    }
  }, [stopped, paused, speed, direction]);

  const lottieStyles = {
    width: getSize(width),
    height: getSize(height),
    overflow: 'hidden',
    margin: '0 auto',
    outline: 'none',
    ...style,
  };

  return (
    <div
      ref={containerRef}
      css={lottieStyles}
      title={title}
      role={ariaRole}
      aria-label={ariaLabel}
      tabIndex="0"
    />
  );
};

export default Lottie;
