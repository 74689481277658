export class InvalidMimeTypeException {
    private mimeType: string;
    private type: string;

    constructor(mimeType: string) {
        this.mimeType = mimeType;
        this.type = 'InvalidMimeTypeException';
    }

    public toString() {
        return this.type + ': ' + 'MimeType ' + this.mimeType + ' not allowed here.';
    }
}