import cloneDeep from 'lodash/cloneDeep';

export function getDateOptions(opt) {
  let options = opt;
  if (!options) {
    options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    };
  }
  return options;
}

export function checkDate(dateAsISO8601string) {
  let output = false;
  if (
    dateAsISO8601string.match(
      /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})[+-](\d{2})\:(\d{2})/
    )
  ) {
    output = true;
  }
  return output;
}

export function getFormattedDate(dateAsISO8601string, lang, displayTime, options) {
  let output = '';

  if (dateAsISO8601string && checkDate(dateAsISO8601string)) {
    const val = new Date(dateAsISO8601string);
    output = val.toLocaleDateString(lang, getDateOptions(options));
    if (displayTime === true) {
      output += ` ${val.toLocaleTimeString(lang, {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
      })}`;
    }
  }
  return output;
}

export function roundMinutes(date) {
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
  return date;
}

export function getFormattedPeriod(
  fromDateAsISO8601string,
  untilDateAsISO8601string,
  lang,
  displayTime = false,
  separator,
  options
) {
  let output = '';
  if (
    fromDateAsISO8601string &&
    checkDate(fromDateAsISO8601string) &&
    untilDateAsISO8601string &&
    checkDate(untilDateAsISO8601string)
  ) {
    const startDate = new Date(fromDateAsISO8601string);
    const endDate = new Date(untilDateAsISO8601string);
    output = startDate.toLocaleDateString(lang, getDateOptions(options));
    if (displayTime === true) {
      output += ` ${startDate.toLocaleTimeString(lang, {
        timeZone: 'UTC',
      })}`;
    }
    output += ` ${separator && separator.trim() !== '' ? separator : '-'} `;
    output += endDate.toLocaleDateString(lang, getDateOptions(options));
    if (displayTime === true) {
      output += ` ${startDate.toLocaleTimeString(lang, {
        timeZone: 'UTC',
      })}`;
    }
  }
  return output;
}

export function getFormattedAmount(amount, lang, amountCurrency) {
  let currency = amountCurrency;
  if (currency && currency === 'USD') {
    currency = 'USD';
  } else {
    currency = 'EUR';
  }
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency,
  }).format(amount);
}

export function getFormattedFilesize(sizeInBytes) {
  if (sizeInBytes >= 1024 * 1024 * 1024) {
    const val = sizeInBytes / 1024 / 1024 / 1024;
    return `${Math.round(val * 10) / 10} GB`;
  }

  if (sizeInBytes >= 1024 * 1024) {
    const val = sizeInBytes / 1024 / 1024;
    return `${Math.round(val * 10) / 10} MB`;
  }

  if (sizeInBytes >= 1024) {
    const val = sizeInBytes / 1024;
    return `${Math.round(val)} KB`;
  }

  return `${Math.round(sizeInBytes)} B`;
}

export function getFormattedNumber(number, lang, decimals) {
  let minimumFractionDigits = 0;
  if (decimals && decimals >= 0) {
    minimumFractionDigits = decimals;
  }

  const ret = new Intl.NumberFormat(lang, {
    style: 'decimal',
    minimumFractionDigits,
  }).format(number);

  return cloneDeep(ret);
}

export function getFormattedDuration(durationInSeconds) {
  let value = '';
  if (Number(durationInSeconds) === durationInSeconds && durationInSeconds % 1 === 0) {
    const secNum = parseInt(durationInSeconds, 10);
    const hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;

    value = `${(hours > 0 ? `${hours}h ` : '') + minutes}m ${seconds}s`;
  }
  return value;
}
