import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedFilesize } from '../../util/Formatter';

const FormatFileSize = ({ className, value }) => (
  <span className={className}>{getFormattedFilesize(value)}</span>
);

FormatFileSize.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormatFileSize.defaultProps = {
  className: '',
  value: '',
};

export default FormatFileSize;
export { FormatFileSize };
