import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import subHours from 'date-fns/subHours';

import { Modal } from '../../atoms';
import StoryPlayer from '../StoryPlayer/StoryPlayer';

import Footer from './subcomponents/Footer';
import ActionsWindow from './subcomponents/ActionsWindow';
import DeleteStoryModal from './subcomponents/DeleteStoryModal';
import StoryDetailModal from './subcomponents/StoryDetailModal';

const StyledContainer = styled.div`
  outline: none;
  background-image: url(${props => props.foto});
  background-size: cover;
  background-position: center;
  width: 205px;
  height: 300px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  flex-direction: column;
  position: relative;
  margin: 0 30px 32px 30px;
  cursor: pointer;
`;

const StyledActionButton = styled.button`
  margin: 4px;
  padding: 4px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 30px;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0);
  transition: border-color 0.2s;

  &:hover {
    border-color: rgba(255, 255, 255, 1);
  }
`;

class StoryThumbnail extends Component {
  state = { stories: [], actionWindowOpen: false, deleteModalOpen: false, deitalsModalOpen: false };

  handleKeyPress = event => {
    if (event.target === event.currentTarget && event.key === 'Enter') {
      this.props.onClick();
    }
  };

  handleActionButtonClick = () => {
    this.setState({ actionWindowOpen: true });
  };

  handleActionWindowClick = event => {
    if (event.target === event.currentTarget) {
      this.setState({ actionWindowOpen: false });
    }
  };

  handleClick = event => (event.target === event.currentTarget ? this.openDeitalsModal() : null);

  openDeitalsModal = () => this.setState({ deitalsModalOpen: true });

  closeDeitalsModal = () => this.setState({ deitalsModalOpen: false });

  openDeleteModal = () => this.setState({ deleteModalOpen: true });

  closeDeleteModal = () => this.setState({ deleteModalOpen: false });

  deleteStory = () => {
    this.props.onDeleteStory();
    this.closeDeleteModal();
  };

  render() {
    const {
      timestamp,
      rejectedReason,
      views,
      likes,
      status,
      delStoryButtonLabel,
      optionsButtonLabel,
      media,
    } = this.props;

    return (
      <StyledContainer
        role="button"
        tabIndex={0}
        onClick={this.handleClick}
        onKeyPress={this.handleKeyPress}
        foto={media.previewPicture.url}
        data-testid="story-thumbnail"
      >
        <ActionsWindow
          open={this.state.actionWindowOpen}
          timestamp={timestamp}
          status={status}
          rejectedReason={rejectedReason}
          delStoryButtonLabel={delStoryButtonLabel}
          clickHandler={this.handleActionWindowClick}
          keyPressHandler={this.handleKeyPress}
          buttonClickHandler={this.openDeleteModal}
          buttonLabel={delStoryButtonLabel}
        />

        {!this.state.actionWindowOpen && (
          <StyledActionButton onClick={this.handleActionButtonClick}>
            <span>{optionsButtonLabel}</span>
            <span className="icon-more" />
          </StyledActionButton>
        )}

        <Footer
          timestamp={timestamp}
          rejectedReason={rejectedReason}
          likes={likes.length}
          views={views.length}
        />

        {this.state.deleteModalOpen && (
          <Modal onBackgroundClick={this.closeDeleteModal}>
            <DeleteStoryModal
              message="Möchtest du diese Story wirklich löschen?"
              onClickDelete={this.deleteStory}
              onClickCloseButton={this.closeDeleteModal}
            />
          </Modal>
        )}

        {this.state.deitalsModalOpen && (
          <Modal onBackgroundClick={this.closeDeitalsModal}>
            <StoryDetailModal onClickCloseButton={this.closeDeitalsModal}>
              <StoryPlayer
                mediaURL={media.url}
                timestamp={subHours(new Date(), 7)}
                views={views}
                likes={likes}
              />
            </StoryDetailModal>
          </Modal>
        )}
      </StyledContainer>
    );
  }
}

StoryThumbnail.defaultProps = {
  rejectedReason: '',
  views: [],
  likes: [],
  optionsButtonLabel: 'More',
  delStoryButtonLabel: 'Story löschen',
};

StoryThumbnail.propTypes = {
  status: PropTypes.oneOf(['online', 'offline']).isRequired,
  media: PropTypes.object.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rejectedReason: PropTypes.string,
  views: PropTypes.arrayOf(PropTypes.string),
  likes: PropTypes.arrayOf(PropTypes.shape({ user: PropTypes.string, comment: PropTypes.string })),
  onDeleteStory: PropTypes.func.isRequired,
  optionsButtonLabel: PropTypes.string,
  delStoryButtonLabel: PropTypes.string,
};

export default StoryThumbnail;
