import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { folderNew } from '../../../../../atoms/Icon/libraries/glyph';

const ArchiveItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:telegram.archive')}
    icon={folderNew}
    uri="/telegram/broadcast/archive"
  />
);

export default ArchiveItem;
