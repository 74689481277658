import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SessionStore } from '../../../../stores/Session/Session';
import MediaSaleOverview from '../../../../components/MediaSaleOverview/MediaSaleOverview';
import FancyTable from '../../../../components/FancyTable/FancyTable';

import NavPictureView from '../NavPictureView/NavPictureView';

import { extractTitle } from './index';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import {
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_PHONE_CONDITION,
} from '../../../../camtool-styles';

const PicWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
  margin: 0 24px 24px 24px;
  background-color: #f5f5f5;
  border-radius: 2px;
  color: #141316;

  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0 24px 24px 24px;
    > form {
      flex: 1 1 100%;
      width: 100%;
      padding: 0;
      margin: 0;
    }
    > div {
      width: 100%;
      overflow: hidden;
    }
    .fancy-table__header__item {
      width: 160px !important;
      justify-content: center;
      padding: 10px 0;
    }
    .fancy-table__header {
      flex-wrap: wrap;
      flex: 1 1 100%;
    }
    .fancy-table__row {
      flex-wrap: wrap;
    }
  }

  @media ${BREAKPOINT_PHONE_CONDITION} {
    .fancy-table__header__item {
      width: 100% !important;
    }
  }
`;

const Row = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
`;

const sortAlbumSales = (data, field, direction) => {
  switch (field) {
    case 'guestlogin':
      data.sort((a, b) => {
        let a_ = a[field].toLowerCase(),
          b_ = b[field].toLowerCase();

        if (a_ < b_) return direction === 'asc' ? -1 : 1;
        else if (a_ > b_) return direction === 'asc' ? 1 : -1;
        return 0;
      });
      break;
    case 'date':
      data.sort((a, b) => {
        if (direction === 'asc') return Date.parse(a[field]) - Date.parse(b[field]);
        else return Date.parse(b[field]) - Date.parse(a[field]);
      });
      break;
    case 'guestAmount':
    case 'hostAmount':
      data.sort((a, b) => {
        if (direction === 'asc') return a[field] - b[field];
        else return b[field] - a[field];
      });
      break;
    default:
      break;
  }
};

const AlbumSales = ({ album, editable }) => {
  const showMenu = album && album.status !== 'rejected' && editable;
  const spokenLangs = SessionStore.get().spokenLangs;
  const contentTitle = extractTitle(album.albumType, album.titles);
  const coverImageURL =
    (album.previewPicture16 && album.previewPicture16.url) ||
    (album.previewPicture18 && album.previewPicture18.url) ||
    '';
  const salesStatItems = album?.salesStatItems;

  return (
    <div className="grid__box__column grid__box__sub">
      {showMenu && (
        <Fragment>
          <NavPictureView albumId={album.id} albumType={album.albumType} />
        </Fragment>
      )}
      <PicWrapper>
        <Row className="grid__box__sub__item">
          <div className="grid__box__sub__item__content">
            <div className="mediamanagement__photoalbum__sales__info">
              <MediaSaleOverview
                coverImageURL={coverImageURL}
                title={contentTitle}
                totalSalesLabel={_('mediamanagement:album.sales.table.sale')}
                netLabel={_('mediamanagement:album.sales.table.net')}
                salesStatItems={salesStatItems}
              />
            </div>

            <FancyTable
              styles={{ margin: 0 }}
              rowsData={album.salesItems}
              columnFields={[
                {
                  name: 'guestlogin',
                  label: _('mediamanagement:album.sales.head.username'),
                  width: 200,
                  sortable: true,
                },
                {
                  name: 'date',
                  label: _('mediamanagement:album.sales.head.salesDate'),
                  width: 200,
                  sortable: true,
                },
                {
                  name: 'guestAmount',
                  label: _('mediamanagement:album.sales.head.guestPrice'),
                  width: 200,
                  sortable: true,
                },
                {
                  name: 'hostAmount',
                  label: _('mediamanagement:album.sales.head.hostPrice'),
                  width: 200,
                  sortable: true,
                },
              ]}
              sortCallback={sortAlbumSales}
            />
          </div>
        </Row>
      </PicWrapper>
    </div>
  );
};

AlbumSales.propTypes = {
  album: PropTypes.object,
  editable: PropTypes.bool,
};

AlbumSales.defaultProps = {
  album: {},
  editable: false,
};

export default AlbumSales;
