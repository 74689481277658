import React, { FC } from 'react';
import { WHITE, GRAY_3, BREAKPOINT_PHONE_CONDITION } from '../../../../camtool-styles';
import { parseISO } from 'date-fns';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { useLang } from '../../../../util/AppState';
import { ApiLang } from '../../../../graphql/VXModels/types';
import deLocale from 'date-fns/locale/de';
import enUsLocale from 'date-fns/locale/en-US';
import { useMediaQuery } from '@material-ui/core';

interface IProps {
  active: boolean;
  onlineUntil?: string;
}

const generateSpeakingDate = (pastDate: string, lang: string): string =>
  formatDistanceStrict(parseISO(pastDate), new Date(), {
    addSuffix: true,
    roundingMethod: 'floor',
    locale: lang === ApiLang.de ? deLocale : enUsLocale,
  });

const OfflineStatus: FC<IProps> = ({ active, onlineUntil }) => {
  const isMobile = useMediaQuery(BREAKPOINT_PHONE_CONDITION);
  const [lang] = useLang();
  return (
    <span
      css={{
        fontSize: '12px',
        textAlign: 'end',
        whiteSpace: isMobile ? 'pre' : 'nowrap',
        maxWidth: '85px',
        color: active ? WHITE : GRAY_3,
      }}
    >
      {!!onlineUntil && generateSpeakingDate(onlineUntil, lang)}
    </span>
  );
};

export default OfflineStatus;
