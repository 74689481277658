import { useTelegramUser } from './useTelegramUser';
import { QueryHookOptions, useMutation } from '@apollo/react-hooks';
import { Message } from '../../../graphql/VXServicesTelegram/types';
import { FRAGMENT_USER } from '../graphql/fragments/User';
import { MUTATION_MASTER_SET_MESSAGES_READ } from '../graphql/mutations';
import { useVXServicesTelegramClient } from '../../../graphql';
import { dataIdFromObject } from '../../../graphql/VXServicesTelegram/util/dataIdFromObject';
import { useVXServicesTelegramContext } from './index';

interface IMarkUserRead {
  userId: number;
}

export const useTelegramSetMessagesRead = (
  userId: number,
  options?: QueryHookOptions<Message, IMarkUserRead>
) => {
  const { token } = useVXServicesTelegramContext();
  const variables = { token, userId };
  const { data: user } = useTelegramUser(userId);

  const [setMessagesRead] = useMutation(MUTATION_MASTER_SET_MESSAGES_READ, {
    ...options,
    client: useVXServicesTelegramClient(),
    variables,
    update: (cache) => {
      const fragmentId = dataIdFromObject(user);
      const fragment = FRAGMENT_USER;
      cache.writeFragment({
        id: fragmentId,
        fragment,
        fragmentName: 'User',
        data: {
          ...cache.readFragment({ id: fragmentId, fragment }),
          unreadByMaster: 0,
          unreadTips: 0,
        },
      });
    },
  });

  return () =>
    new Promise((resolve) => {
      if (user.id && (user.unreadByMaster > 0 || user.unreadTips > 0)) {
        setMessagesRead().then(() => resolve(true));
      } else {
        resolve(false);
      }
    });
};

export default useTelegramSetMessagesRead;
