import React from 'react';

import { StyledA } from './styles';

export const linkDecorator = {
  // prettier-ignore
  strategy: (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
    }, callback);
  },
  component: (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return <StyledA href={url}>{props.children}</StyledA>;
  },
};
