import gql from 'graphql-tag';
import { FRAGMENT_MESSAGE, FRAGMENT_TIPPING } from '../fragments';
import { FRAGMENT_BROADCAST } from '../fragments/Broadcast';

export const QUERY_MASTER_USER_MESSAGELIST = gql`
  query VXM_CL_QUERY_MASTER_USER_MESSAGELIST(
    $token: String!
    $userId: Int!
    $lastMessageId: Int = null
    $limit: Int = 50
  ) {
    master(token: $token) {
      user(id: $userId) {
        id
        messageList(lastMessageId: $lastMessageId, limit: $limit) {
          limit
          total
          count
          messages {
            ...Message
          }
        }
      }
    }
  }
  ${FRAGMENT_MESSAGE}
`;

export const QUERY_MASTER_USER_GROUPED_CHAT = gql`
  query VXM_CL_QUERY_MASTER_USER_GROUPED_MESSAGELIST(
    $token: String!
    $userId: Int!
    $lastMessageId: Int = null
    $limit: Int = 50
  ) {
    master(token: $token) {
      user(id: $userId) {
        id
        messageList(lastMessageId: $lastMessageId, limit: $limit) {
          limit
          total
          count
        }
        groupedChat(limit: 30) {
          date
          events {
            ...Tipping
            ...Message
          }
        }
      }
    }
  }
  ${FRAGMENT_TIPPING}
  ${FRAGMENT_MESSAGE}
`;

export const QUERY_MASTER_USER_BROADCASTLIST = gql`
  query VXM_CL_QUERY_MASTER_BROADCAST(
    $token: String!
    $limit: Int = 50
    $offset: Int
    $filter: BroadcastFilter
  ) {
    master(token: $token) {
      broadcastList(order: newest, limit: $limit, offset: $offset, filter: $filter) {
        limit
        offset
        total
        count
        broadcasts {
          ...Broadcast
        }
      }
    }
  }
  ${FRAGMENT_BROADCAST}
`;

export const QUERY_MASTER_IS_UNPINABLE = gql`
  query VXM_CL_QUERY_MASTER_IS_UNPINABLE($token: String!) {
    master(token: $token) {
      isUnpinable
    }
  }
`;
