import React, { FC, ReactElement, useState } from 'react';
import styled from '@emotion/styled';
import ApolloClient from 'apollo-client';
import { Field, FieldProps } from 'formik';
import { useMedia } from 'react-media';
import { _ } from '../../../../../../util/translate';
import FieldLayout from '../../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import { MediaUploadInput } from '../../../../../../molecules/FileUpload/MediaUploadInput';
import { FileType } from '../../../../../../molecules/FileUpload/PreviewFile';
import { getPictureUuid, uploadFile } from './util';
import {
  RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY,
  RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL,
  withResponsiveUploadStyles,
} from '../../../../../../molecules/FileUpload/withResponsiveUploadStyles';
import { AboContentType } from '../../../../types';
import { Box } from '@material-ui/core';

const ResponsiveMediaUploadInput = withResponsiveUploadStyles(MediaUploadInput);

const StyledFieldLayout = styled(FieldLayout)`
  margin-bottom: 24px;
  margin-left: 8px;
  width: initial;
  flex: 0 0 auto;

  ${RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL} {
    margin-left: 0;
  }
`;

interface Props {
  apolloClientAbo: ApolloClient<{}>;
  aboContentType: AboContentType;
}

/**
 * Formik field around MediaUploadInput for abo picture upload
 */
const FieldPictureUpload: FC<Props> = ({ apolloClientAbo, aboContentType }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = useMedia({ query: RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY });

  return (
    <Box mt={{ xs: '0px', md: '13px' }}>
      <Field name="picture">
        {({ field, form: { touched, errors, setFieldValue } }: FieldProps): ReactElement => {
          const onRemoveFile = (): void => {
            setFieldValue(field.name, undefined);
          };

          const onSelectFile = async (file: File): Promise<void> => {
            setLoading(true);

            try {
              const uuid = await getPictureUuid(apolloClientAbo);
              const { url } = await uploadFile(uuid, file);

              setFieldValue(field.name, { uuid, url });
            } catch (err) {
              console.error(err);
              window.alert(_('common:error.generic'));
            } finally {
              setLoading(false);
            }
          };

          return (
            <StyledFieldLayout
              label={isMobile ? '' : '\u00A0'}
              error={touched.picture && errors.picture ? errors.picture : ''}
            >
              <ResponsiveMediaUploadInput
                label={_(`benefits:abo.detailsPage.pictureLabel.${aboContentType.toLowerCase()}`)}
                accept="image/*"
                loading={loading}
                currentFile={
                  field.value ? { url: field.value.url, type: FileType.image } : undefined
                }
                onSelectFile={onSelectFile}
                onRemoveFile={onRemoveFile}
              />
            </StyledFieldLayout>
          );
        }}
      </Field>
    </Box>
  );
};

export default FieldPictureUpload;
