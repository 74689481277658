import React from 'react';
import AbstractFormSingleOptionsComponent from '../AbstractFormSingleOptionComponent';
import PropTypes from 'prop-types';

class OptionSexSelect extends AbstractFormSingleOptionsComponent {
  render() {
    const { name, componentLabel } = this.props;

    return (
      <div className={`option-sexselect ${this.getClassNames()}`}>
        <div className="option-sexselect__label">{componentLabel}</div>
        {this.options.map(option => (
          <div className="option-sexselect__entry" key={option.value}>
            <input
              type="radio"
              name={name}
              value={option.value}
              id={`${name} -${option.value}`}
              checked={this.isOptionSelected(option)}
              onChange={this.onChange}
            />

            <label htmlFor={`${name} -${option.value}`} className="option-sexselect__entry__label">
              <div className={`icon-gender-${option.value} option-sexselect__entry__label__icon`} />
              <div className="option-sexselect__entry__label__textbox">{option.label}</div>
            </label>
          </div>
        ))}
      </div>
    );
  }
}

OptionSexSelect.propTypes = {
  ...AbstractFormSingleOptionsComponent.propTypes,
  iconPrefix: PropTypes.string,
};

OptionSexSelect.defaultProps = { ...AbstractFormSingleOptionsComponent.defaultProps };

export default OptionSexSelect;
export { OptionSexSelect };
