import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { Mailing, Query } from '../../../graphql/VXModels/types';
import { useVXModelsClient } from '../../../graphql/VXModels';
import { QueryResult } from '@apollo/react-common';
import { QUERY_MODEL_MAILINGS_MAILING } from '../graphql/queries/mailings';

type Variables = { id: number };

type Result = Omit<QueryResult<Query, Variables>, 'data'> & { mailing: Mailing | undefined | null };

export const useMailing = (id: number, options?: QueryHookOptions<Query, Variables>): Result => {
  const client = useVXModelsClient();
  const { data, ...result } = useQuery<Query, Variables>(QUERY_MODEL_MAILINGS_MAILING, {
    ...options,
    client,
    variables: { id },
  });

  return { mailing: data?.model?.mailings?.mailing, ...result };
};
