import { Box, Typography } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import React, { FC, ReactElement } from 'react';
import { pencil } from '../../../../atoms/Icon/libraries/glyph';
import { WHITE } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';
import ModalAutoMailTextEditor from './ModalAutoMailTextEditor';
import { MessageWrapper, StyledIcon, StyledInputField } from './styles';
import { FormikUserLifeCycleSteps } from './types';

interface Props {
  showModalTextEditor: boolean;
  closeModalTextEditor: () => void;
  openModalTextEditor: () => void;
  index: number;
}

const AutoMailMessage: FC<Props> = React.memo(
  ({ showModalTextEditor, closeModalTextEditor, openModalTextEditor, index }) => {
    return (
      <>
        <MessageWrapper onClick={openModalTextEditor}>
          <Field name={`autoMailList[${index}].text`}>
            {({
              field,
              form: { errors, touched },
            }: FieldProps<FormikUserLifeCycleSteps>): ReactElement => {
              const touchedText = touched?.autoMailList?.[2]?.text;
              const errorText = errors?.autoMailList?.[2]?.text;

              return (
                <Box display="flex" flexDirection="column" width="100%">
                  <StyledInputField
                    containerStyles={{ backgroundColor: `${WHITE}` }}
                    hasError={!!touchedText && !!errorText}
                    {...field}
                    readOnly
                    placeholder={_('mailings:telegram.autoMail.messagePlaceholder')}
                  />
                  {touchedText && errorText && (
                    <Box position="absolute" bottom="0px">
                      <Typography color={'error'}>{errorText}</Typography>
                    </Box>
                  )}
                </Box>
              );
            }}
          </Field>
          <StyledIcon icon={pencil} />
        </MessageWrapper>
        {showModalTextEditor && (
          <ModalAutoMailTextEditor closeModalTextEditor={closeModalTextEditor} index={index} />
        )}
      </>
    );
  }
);

AutoMailMessage.displayName = 'AutoMailMessage';

export default AutoMailMessage;
