export class InvalidArgumentException {
    private message: string;
    private type: string;

    constructor(message: string) {
        this.message = message;
        this.type = 'InvalidArgumentException';
    }

    public toString() {
        return this.type + ': ' + this.message;
    }
}