import { isMuiElement } from '@material-ui/core/utils';
import React, { FC, ReactElement } from 'react';
import { IconType as IconDef } from '../../atoms/Icon/Icon';
import { StyledNavSubIcon } from './styles';

interface IProps {
  icon: IconDef | ReactElement;
}

const NavSubItemIcon: FC<IProps> = ({ icon = '' }) => {
  // Make sure to use <IconBase /> to have muiName = 'SvgIcon'
  const isMuiComponent = isMuiElement(icon, ['SvgIcon']);

  return (
    <>
      {isMuiComponent ? (
        icon
      ) : (
        // IMPORTANT: please make sure to not use font icons anymore so this line can be deleted in the future
        <StyledNavSubIcon icon={icon} />
      )}
    </>
  );
};

export default NavSubItemIcon;
