import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Button, InputField, Tag } from '../../atoms';
import AutocompleteUL from './AutocompleteUL';
import {APP_API_HOST} from "../../util/env";

const apiEndpoint = `${APP_API_HOST}/v1/public/tags/media`;

class Tagger extends React.PureComponent<IProps, IState> {
  public static defaultProps: Partial<IProps> = { className: '' };

  public state = { tags: [], value: '', matches: [], selectedMatch: 0, skipNextFetch: false };

  public inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  public componentDidMount() {
    if (this.props.initialTags.length > 0) {
      this.setState({ tags: this.props.initialTags });
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!!this.state.value && this.state.value !== prevState.value) {
      if (this.state.skipNextFetch) {
        this.setState({ skipNextFetch: false });
      } else {
        fetch(`${apiEndpoint}?search=${this.state.value}`)
          .then(response => response.json())
          .then(json =>
            this.setState({ matches: json.data.map((obj: { value: string }) => obj.value) })
          );
      }
    }
  }

  public focusInput = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  public addValueToTags = () => {
    if (this.state.value.length > 0 && !this.state.tags.includes(this.state.value)) {
      this.setState({
        matches: [],
        selectedMatch: 0,
        tags: [...this.state.tags, this.state.value],
        value: '',
      });
    }

    this.focusInput();
  };

  public resetAutocomplete = () => {
    this.setState({ matches: [], selectedMatch: 0 });
  };

  public setValue(value: string, skipNextFetch: boolean = false) {
    this.setState({ value, skipNextFetch }, this.resetAutocomplete);
  }

  public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setValue(event.target.value);
  };

  public handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const relatedTarget: HTMLAnchorElement = event.relatedTarget as HTMLAnchorElement;

    if (relatedTarget && relatedTarget.classList.contains('option')) {
      this.setValue(relatedTarget.text, true);
    } else {
      this.resetAutocomplete();
    }
  };

  public handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { matches, selectedMatch } = this.state;
    if (matches.length < 1) {
      /** keyDown handler when there are NO matches */
      switch (event.key) {
        case 'ArrowUp':
          // todo: open autocomplete and select the last item
          break;
        case 'ArrowDown':
          // todo: open autocomplete and select the first item
          break;
        case ',':
          event.preventDefault();
          this.addValueToTags();
          break;
        case 'Enter':
          event.preventDefault();
          this.addValueToTags();
          break;
      }
    } else {
      /** keyDown handler when there are matches */
      switch (event.key) {
        case 'ArrowUp':
          this.setState({
            selectedMatch: selectedMatch === 0 ? matches.length - 1 : selectedMatch - 1,
          });
          break;
        case 'ArrowDown':
          this.setState({
            selectedMatch: selectedMatch === matches.length - 1 ? 0 : selectedMatch + 1,
          });
          break;
        case ',':
          event.preventDefault();
          this.addValueToTags();
          break;
        case 'Enter':
          event.preventDefault();
          this.setValue(matches[selectedMatch], true);
          break;
        case 'Escape':
          this.resetAutocomplete();
          break;
        default:
          break;
      }
    }
  };

  public handleTagClick = (tagId: string) => {
    this.setState({ tags: this.state.tags.filter(tag => tag !== tagId) });
    this.focusInput();
  };

  public render() {
    return (
      <Container className={this.props.className}>
        <div css={{ alignItems: 'flex-start' }}>
          <div css={{ marginRight: 8, flex: 1, flexDirection: 'column' }}>
            <InputField
              elRef={this.inputRef}
              onBlur={this.handleBlur}
              onChange={this.handleTextChange}
              onKeyDown={this.handleKeyDown}
              value={this.state.value}
            />
            {this.state.matches.length > 0 && (
              <AutocompleteUL
                matches={this.state.matches}
                selectedMatch={this.state.selectedMatch}
              />
            )}
          </div>
          <Button css={{ height: 34 }} theme="gray" label="ADD TAG" onClick={this.addValueToTags} />
        </div>
        {this.state.tags.length > 0 && (
          <div
            css={{
              '& :not(:last-child)': {
                marginRight: 8,
              },
              flexWrap: 'wrap',
              padding: '8px 0',
            }}
          >
            {this.state.tags.map(tag => (
              <Tag
                tagId={tag}
                onClick={this.handleTagClick}
                key={tag}
                className={css`
                  margin-bottom: 8px;
                  .icon-remove {
                    color: #bcbcbc;
                    margin-left: 8px;
                  }
                  &:hover {
                    .icon-remove {
                      color: #ff4d3c;
                    }
                  }
                `}
              >
                {tag}
                <div className="icon-remove" />
              </Tag>
            ))}
          </div>
        )}
      </Container>
    );
  }
}

interface IProps {
  autocompleteRenderer: (props: object) => any;
  className: string;
  inputRenderer: (props: object) => any;
  initialTags: string[];
}

interface IState {
  tags: string[];
  matches: string[];
  value: string;
  selectedMatch: number;
  skipNextFetch: boolean;
}

const Container = styled('div')`
  position: relative;
  flex: 1;
  flex-direction: column;
`;

export default Tagger;
