import React, { FC } from 'react';
import styled from '@emotion/styled';
// Outside Wizard
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
// Inside Wizard
import { IContentOthersProps } from '../../../types';
import useContent from '../useContent';
import Layout from '../Layout';
import { _ } from '../../../../../util/translate';
import Select from '../../../../../atoms/Select/Select';

const SelectRow = styled.div`
  align-items: center;
  flex: 0;
`;

const ContentMessages: FC<IContentOthersProps> = ({
  state,
  dispatch,
  className,
  availableContentMinAmountsArray,
}) => {
  const [
    { contentData, redemptionType, redemptionAmount },
    { setRedemptionType, setRedemptionAmount, setContentData },
  ] = useContent('MESSAGES', state, dispatch, 25);

  const handleRedemptionTypeChange = (type: string) => {
    if (type === 'GRATIS') {
      setRedemptionType(type);
      setRedemptionAmount(0);
    } else if (type === 'ON_TOP') {
      setRedemptionType(type);
      setRedemptionAmount(-1);
    } else {
      setRedemptionType('');
      setRedemptionAmount(-1);
    }
  };

  const handleRedemptionAmountChange = (amount: string) => {
    setRedemptionAmount(Number(amount));
  };

  const handleMessagesAmountChange = (value: string) => {
    setContentData(Number(value));
  };

  return (
    <Layout
      className={className}
      title={_('benefits:voucher.messages.title')}
      subtitle={_('benefits:voucher.messages.subtitle')}
    >
      <FieldLayout
        label={_('benefits:voucher.texts.couponRedeemType')}
        error=""
        css={{ marginBottom: 24, flex: 0 }}
      >
        <Select
          value={redemptionType}
          name="voucherType"
          css={{ margin: 8, flex: '0 0 auto' }}
          placeholder={_('benefits:voucher.texts.pleaseChoose')}
          onChange={(event) => handleRedemptionTypeChange(event.target.value)}
          options={[
            { label: _('benefits:voucher.texts.free'), value: 'GRATIS' },
            { label: _('benefits:voucher.texts.onTop'), value: 'ON_TOP' },
          ]}
        />
      </FieldLayout>

      {redemptionType === 'GRATIS' && (
        <FieldLayout
          label={_('benefits:voucher.texts.specifyAmount')}
          error=""
          css={{ flex: 0, marginBottom: 24, alignItems: 'flex-start' }}
        >
          <div css={{ flex: 0, alignItems: 'center' }}>
            <Select
              value={contentData}
              name="messagesAmount"
              css={{ margin: 8, flex: '0 0 auto' }}
              onChange={(event) => handleMessagesAmountChange(event.target.value)}
              options={[
                { label: '10', value: '10' },
                { label: '25', value: '25' },
                { label: '50', value: '50' },
                { label: '75', value: '75' },
                { label: '100', value: '100' },
              ]}
            />
            <span>{_('benefits:voucher.messages.whatUserGets')}</span>
          </div>
        </FieldLayout>
      )}

      {redemptionType === 'ON_TOP' && (
        <>
          <SelectRow>
            <span>{_('benefits:voucher.texts.from')}</span>
            <Select
              value={String(redemptionAmount)}
              name="redemptionAmount"
              css={{ maxWidth: 128, margin: 8 }}
              onChange={(event) => handleRedemptionAmountChange(event.target.value)}
              options={availableContentMinAmountsArray || []}
            />
            <span>{_('benefits:voucher.texts.userGetsOnTop')}</span>
          </SelectRow>
          <SelectRow css={{ marginBottom: 24 }}>
            <span css={{ visibility: 'hidden' }}>{_('benefits:voucher.texts.from')}</span>
            <Select
              value={contentData}
              name="messagesAmount"
              css={{ margin: 8, flex: '0 0 auto' }}
              onChange={(event) => handleMessagesAmountChange(event.target.value)}
              options={[
                { label: '10', value: '10' },
                { label: '25', value: '25' },
                { label: '50', value: '50' },
                { label: '75', value: '75' },
                { label: '100', value: '100' },
              ]}
            />
            <span>{_('benefits:voucher.messages.whatUserGets')}</span>
          </SelectRow>
        </>
      )}
    </Layout>
  );
};

export default ContentMessages;
