import React, { FC } from 'react';
import styled from '@emotion/styled';
import { DARK_GRAY, GRAY_3, RED, WHITE, WHITE_2 } from '../../camtool-styles';

const Container = styled.div<Pick<FancySelectProps, 'hasError'>>`
  position: relative;
  display: flex;
  border: 1px solid;
  border-color: ${(props): string => (props.hasError ? RED : GRAY_3)};
  border-radius: 4px;
  overflow: hidden;
`;

const StyledSelect = styled.select<Pick<FancySelectProps, 'compact'>>`
  flex: 1;
  background-color: ${WHITE};
  padding: ${(props): string => (props.compact ? `5px 35px 5px 5px` : `8px 40px 8px 8px`)};
  appearance: none;
  cursor: pointer;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${DARK_GRAY};

  &:disabled {
    background-color: ${WHITE_2};
  }
  ${(props): string => (props.compact ? 'font-size: 12px' : '')}
`;

const ChevronDiv = styled.div<Pick<FancySelectProps, 'compact'>>`
  display: flex;
  width: ${(props): number => (props.compact ? 25 : 32)}px;
  align-items: center;
  justify-content: center;
  background-color: ${GRAY_3};
  color: ${WHITE};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;

export interface Option {
  label?: string;
  value: string;
  disabled?: boolean;
}

interface FancySelectProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  className?: string;
  disabled?: boolean;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  compact?: boolean;
  hasError?: boolean;
}

const Select: FC<FancySelectProps> = ({
  className,
  disabled,
  name,
  onChange,
  onBlur,
  options,
  placeholder,
  value,
  compact = false,
  hasError = false,
}) => {
  return (
    <Container className={className} hasError={hasError}>
      <StyledSelect
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        compact={compact}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map(({ value, label, disabled = false }) => (
          <option key={value} value={value} disabled={disabled}>
            {label || value}
          </option>
        ))}
      </StyledSelect>

      <ChevronDiv compact={compact}>
        <span className="icon-chevron-down" />
      </ChevronDiv>
    </Container>
  );
};

export default Select;
