export const NEW_MESSENGER_URL = 'https://www.vxlive.net';
export const NEW_MESSENGER_WINDOW_NAME = 'VX_MODELS_LIVE_CHAT';
export const MOMENTUM_URL = 'https://momentumchat.app/';
export const MOMENTUM_WINDOW_NAME = 'VX_MODELS_MOMENTUM_CHAT';
export const MESSENGER_URL = 'https://www.visit-x.net/vxsoftware'; // previously https://www.vxlive.net/
export const SSW_URL = 'https://www.visit-x.net/downloads/VXTools/VX-Software.Latest.Setup.exe';
export const CLASSICVXLIVE = 'https://classic.vxlive.net';
export const MEDIA_PICTURE_UPLOAD_MIN_WIDTH = 520;
export const MEDIA_PICTURE_UPLOAD_MIN_HEIGHT = 520;
export const COMMUNIPAY_MERCHANT = '222';
