import React, { FC } from 'react';
import { WHITE } from '../../../../camtool-styles';

interface ILayoutProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
}

const Layout: FC<ILayoutProps> = ({ children, className, title, subtitle }) => (
  <article
    className={className}
    css={{
      flexDirection: 'column',
      maxHeight: 606,
      backgroundColor: WHITE,
      padding: 32,
      width: 600,
      marginBottom: 32,
    }}
  >
    <header css={{ marginBottom: 32, flexDirection: 'column', flex: '0 0 auto' }}>
      {title && <h2 css={{ marginBottom: 8 }}>{title}</h2>}
      {subtitle && <p css={{ maxWidth: 400 }}>{subtitle}</p>}
    </header>

    <section css={{ flex: '1 0 auto', flexDirection: 'column', justifyContent: 'stretch' }}>
      {children}
    </section>
  </article>
);

export default Layout;
