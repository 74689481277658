import React from 'react';
import PropTypes from 'prop-types';

import {
  Place1,
  Place2,
  Place3,
} from '../../../routes/Marketing/Campaigns/__assets__/PlaceImageComponent';
import { _ } from '../../../util/translate';

export const PropContestToplistEntry = {
  rank: PropTypes.number.isRequired,
  umxId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  username: PropTypes.string,
  likes: PropTypes.number.isRequired,
  title: PropTypes.string,
  previewPictureUrl: PropTypes.string.isRequired,
};

const RankingListItem = props => {
  const username = props.username ? props.username : '(' + _('common:text.accountDeleted') + ')';
  return (
    <div className="ranking-list__container">
      <div className="ranking-list__item">
        <div className="ranking-list__item__image-container">
          <div
            css={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url('${props.previewPictureUrl}')`,
              opacity: 0.6,
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            }}
          />
          {props.rank < 4 && (
            <div className="ranking-list__item__image-watermark">
              {props.rank === 1 && <Place1 color="#fff" css={{ width: 74 }} />}
              {props.rank === 2 && <Place2 color="#fff" css={{ width: 74 }} />}
              {props.rank === 3 && <Place3 color="#fff" css={{ width: 74 }} />}
            </div>
          )}
        </div>
        <div className="ranking-list__item__information-container">
          <div className="ranking-list-item__information-item">{username}</div>
          {props.likes > 0 && (
            <div className="ranking-list-item__information-item">
              {_('marketing:campaigns.ranking.likes.label')}: {props.likes}
              <span className="icon-thumbs-up icon spacer" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

RankingListItem.propTypes = PropContestToplistEntry;

RankingListItem.defaultProps = {};

export default RankingListItem;
