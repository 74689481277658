import React, { FC, ReactElement } from 'react';
import { _ } from '../../../../../util/translate';
import { Field, FormikValues } from 'formik';
import { FancyCheckbox } from '../../../../../atoms';

const KnockMailCheckbox: FC = () => {
  return (
    <Field name={'deactivateMailAndKnock'}>
      {({ field }: FormikValues): ReactElement => (
        <FancyCheckbox {...field} checked={field.value}>
          <span css={{ maxWidth: '200px', marginLeft: '8px' }}>
            {_('service0900:PhoneSex.modal.verify.knock.text')}
          </span>
        </FancyCheckbox>
      )}
    </Field>
  );
};

export default KnockMailCheckbox;
