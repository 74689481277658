import React from 'react';

import { AbstractComponent, EmptyContent, FormatDatePeriod, T } from '../../../components';

import { IncomeSourcesStore } from '../../../stores/IncomeSources/IncomeSourcesStore';
import { IncomeSourcesActionCreators } from '../../../stores/IncomeSources/IncomeSourcesActionCreators';
import { _ } from '../../../util/translate';
import { Grid } from '@material-ui/core';
import styled from '@emotion/styled';

const Container = styled.div`
  align-items: center;

  flex-direction: column;
  height: 100%;
`;

class IncomeSources extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      ...IncomeSourcesStore.get(),
    };
  }

  componentWillMount() {
    IncomeSourcesStore.addChangeListener(this.onStoreChange);
  }

  componentWillUnmount() {
    IncomeSourcesStore.removeChangeListener(this.onStoreChange);
  }

  componentDidMount() {
    IncomeSourcesActionCreators.getIncomeSourcesData();
    this.renderAmountyStats();
    this.renderQuantityStats();
  }

  onStoreChange() {
    this.setState(IncomeSourcesStore.get());
  }

  componentDidUpdate() {
    this.renderAmountyStats();
    this.renderQuantityStats();
  }

  renderAmountyStats() {
    if (this.refs.amountChart) {
      let node = this.refs.amountChart;
      let data = [];
      for (let key in this.state.amountDetails) {
        data.push({
          name: _('finances:TurnoverGroup.' + key),
          color: this.state.amountDetails[key].color,
          y: Math.abs(this.state.amountDetails[key].value),
          realAmount: this.state.amountDetails[key].value,
          opacity: this.state.amountDetails[key].value < 0 ? 0.1 : 1,
        });
      }

      let chart = new Highcharts.Chart({
        accessibility: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          type: 'pie',
          renderTo: node,
          backgroundColor: 'transparent',
          style: {
            fontFamily: 'Roboto, sans-serif',
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: _('dashboard:app.incomingSources.charts.amount.name'),
          style: {
            fontFamily: 'Roboto, sans-serif',
            color: '#000',
            fontSize: '24px',
          },
        },
        tooltip: {
          pointFormat: '<b>{point.realAmount:.2f} €</b> ({point.percentage:.1f}%)',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '100%'],
            borderWidth: 0,
            size: 180,
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
          layout: 'vertical',
          itemStyle: {
            color: '#000',
            fontWeight: 'normal',
          },
          itemHoverStyle: {
            color: '#000',
            fontWeight: 'bold',
          },
          labelFormat: '{name}',
          y: 0,
        },
        series: [
          {
            name: 'Brands',
            innerSize: '50%',
            colorByPoint: true,
            data: data,
          },
        ],
      });
    }
  }

  renderQuantityStats() {
    if (this.refs.quantityChart) {
      let node = this.refs.quantityChart;
      let data = [];
      for (let key in this.state.quantityDetails) {
        data.push({
          name: _('finances:TurnoverGroup.' + key),
          color: this.state.quantityDetails[key].color,
          y: this.state.quantityDetails[key].value,
        });
      }

      let chart = new Highcharts.Chart({
        accessibility: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          type: 'pie',
          renderTo: node,
          backgroundColor: 'transparent',
          style: {
            fontFamily: 'Roboto, sans-serif',
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: _('dashboard:app.incomingSources.charts.quantity.name'),
          style: {
            fontFamily: 'Roboto, sans-serif',
            color: '#000',
            fontSize: '24px',
          },
        },
        tooltip: {
          pointFormat:
            _('dashboard:app.incomingSources.charts.quantity.name') +
            ' <b>{point.y:.0f} </b> ({point.percentage:.1f}%)',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '100%'],
            borderWidth: 0,
            size: 180,
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
          layout: 'vertical',
          itemStyle: {
            color: '#000',
            fontWeight: 'normal',
          },
          itemHoverStyle: {
            color: '#000',
            fontWeight: 'bold',
          },
          labelFormat: '{name}',
          y: 0,
        },
        series: [
          {
            name: 'Brands',
            innerSize: '50%',
            colorByPoint: true,
            data: data,
          },
        ],
      });
    }
  }

  render() {
    this.isEmpty = this.state.amountDetails.length === 0;

    return (
      <Container>
        {this.props.userAllowed ? (
          <Grid container>
            {this.isEmpty && (
              <Grid container item className="grid__box__item">
                <Grid item>
                  <EmptyContent
                    icon="icon-pie-chart"
                    title={_('dashboard:app.incomingSources.emptyContent.title')}
                  />
                </Grid>
              </Grid>
            )}

            {!this.isEmpty && (
              <Grid container item>
                <Grid container item xs={12} md={6}>
                  <div
                    // eslint-disable-next-line react/no-string-refs
                    ref="amountChart"
                    style={{ minWidth: 200, height: 300, maxWidth: '50%', margin: '0 auto' }}
                  />
                </Grid>
                <Grid container item xs={12} md={6}>
                  <div
                    // eslint-disable-next-line react/no-string-refs
                    ref="quantityChart"
                    style={{ minWidth: 200, height: 300, maxWidth: '50%', margin: '0 auto' }}
                  />
                </Grid>
              </Grid>
            )}

            <footer className="grid__box__item">
              <span>
                <T _={'dashboard:app.incomingSources.footer'} />
              </span>
              <FormatDatePeriod from={this.state.startDate} to={this.state.endDate} />
            </footer>
          </Grid>
        ) : (
          <div className="grid__box__item">
            <EmptyContent icon="icon-pie-chart" title={_('common:forbidden.text')} />
          </div>
        )}
      </Container>
    );
  }
}

export default IncomeSources;
