/* eslint-disable react/prop-types */

import React, { Component, Fragment } from 'react';
import styled from '@emotion/styled';

import { T, ProgressCircle } from '../../../../components';
import Select from '../../../../atoms/Select/Select';

import LegendItem from '../LegendItem/LegendItem';
import ProfilesOverviewIcon from '../ProfilesOverviewIcon/ProfilesOverviewIcon';

import { Modal } from '../../../../atoms/';
import { _ } from '../../../../util/translate';
import getIcon from './getIcon';
import { withRouter } from 'react-router';
import { getCurrentScopeSupportMail } from '../../../../util/emailAdresses';

const ModalContentContainer = styled.div`
  margin: auto;
  height: 208px; // need height because of IE11
  padding: 48px;
  flex-direction: column;
  background-color: #fff;
  color: #3c3d3e;
  position: relative;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.4);
  align-items: center;
  max-width: 600px;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  font-size: 16px;
  background-color: #fff;
  color: #6c6c6c;
  border: none;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    background-color: #6c6c6c;
    color: #fff;
  }
`;

class Profile extends Component {
  /** filter := [all || completed || missing || required || evaluating ] */
  state = { modalOpen: false, filter: 'all', displayEvalNote: false };

  openModal = () => this.setState({ modalOpen: true });

  closeModal = () => this.setState({ modalOpen: false });

  handleFilterChanged = (newValue) => this.setState({ filter: newValue.currentTarget.value });

  handleIconClick = (event, url) => {
    if (url) this.props.history.push(url);
    else this.openModal();
  };

  renderEvalNote = (traits) => {
    for (let i = 0; i < traits.length; i++) {
      if (traits[i].status === 'evaluating') {
        return (
          <div className="grid__row">
            <div className="grid__column grid__box" style={{ marginTop: 0 }}>
              <div className="grid__box__row profiles-overview__note">
                <span className="fas fa-info-circle fa-2x profiles-overview__note__icon" />
                <p className="profiles-overview__content__text">
                  <T _={'profiles:overview.note'} />
                </p>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="grid__row">
        <div className="grid__column grid__box" style={{ marginTop: 0 }} />
      </div>
    );
  };

  filterTraitStatus = (trait) => {
    const { status } = trait;
    const { filter } = this.state;

    if (filter === 'all') return true;
    else if (filter === 'missing') return status === 'missing' || status === 'required';
    else return filter === status;
  };

  renderTraitsIcons = (traits) => {
    const filteredTraits = traits.filter(this.filterTraitStatus);
    if (filteredTraits) {
      const icons = filteredTraits.map((trait) => {
        const icon = getIcon(trait.icon, 'female', trait.status);
        return (
          <ProfilesOverviewIcon
            key={trait.label}
            className="profiles-overview__grid__item profiles-overview__grid__item--icon"
            icon={icon}
            status={trait.status}
            label={_(trait.label)}
            onClick={this.handleIconClick}
            url={trait.url}
          />
        );
      });
      return icons;
    }
  };

  render() {
    if (!this.props.profile1) return null;

    const { profile1, profile2 } = this.props;
    const profile1Traits = profile1 ? profile1.traits : [];
    const profile2Traits = profile2 ? profile2.traits : [];
    const supportMail = getCurrentScopeSupportMail();

    return (
      <Fragment>
        <div className="grid__row">
          <div className="grid__column grid__box min-width--0" style={{ marginBottom: 2 }}>
            <div className="grid__box__header profiles-overview__header">
              <div className="grid__box__header__title profiles-overview__header__text">
                {profile1 ? _(profile1.profileNameTranslationKey) : ''}
              </div>

              <Select
                name="profile-overview-filter"
                value={this.state.filter}
                onChange={(event) => this.handleFilterChanged(event)}
                options={[
                  { label: _('profiles:overview.filter.all'), value: 'all' },
                  {
                    label: _('profiles:overview.legendItem.filled'),
                    value: 'completed',
                  },
                  {
                    label: _('profiles:overview.legendItem.unfilled'),
                    value: 'missing',
                  },
                  {
                    label: _('profiles:overview.legendItem.unfilledRequired'),
                    value: 'required',
                  },
                  {
                    label: _('profiles:overview.legendItem.inReview'),
                    value: 'evaluating',
                  },
                ]}
              />
            </div>

            <div className="grid__box__row profiles-overview__content">
              <article className="profiles-overview__grid-container">
                <div className="profiles-overview__grid__legend">
                  <LegendItem status="completed" text={_('profiles:overview.legendItem.filled')} />
                  <LegendItem
                    status="evaluating"
                    text={_('profiles:overview.legendItem.inReview')}
                  />
                  <LegendItem status="missing" text={_('profiles:overview.legendItem.unfilled')} />
                  <LegendItem
                    status="required"
                    text={_('profiles:overview.legendItem.unfilledRequired')}
                  />
                </div>

                <section className="profiles-overview__grid">
                  {this.state.filter === 'all' && (
                    <div className="profiles-overview__grid__overview">
                      <ProgressCircle
                        width={280}
                        height={280}
                        data={profile1 ? [profile1.filledItems, profile1.totalItems] : [0, 0]}
                        label={profile1 ? _(profile1.profileNameTranslationKey) : ''}
                        warning={
                          profile1 && !profile1.areRequiredFieldsCompleted
                            ? _('profiles:overview.warning')
                            : ''
                        }
                      />
                    </div>
                  )}

                  {this.renderTraitsIcons(profile1Traits)}
                </section>

                {profile2 && profile2.isProfileActive ? (
                  <Fragment>
                    <section className="profiles-overview__grid__header">
                      <h2>{profile2 ? _(profile2.profileNameTranslationKey) : ''}</h2>
                    </section>

                    <section className="profiles-overview__grid">
                      {this.state.filter === 'all' && (
                        <div className="profiles-overview__grid__overview">
                          <ProgressCircle
                            width={280}
                            height={280}
                            data={profile2 ? [profile2.filledItems, profile2.totalItems] : [0, 0]}
                            label={profile2 ? _(profile2.profileNameTranslationKey) : ''}
                            warning={
                              profile2 && !profile2.areRequiredFieldsCompleted
                                ? _('profiles:overview.warning')
                                : ''
                            }
                          />
                        </div>
                      )}

                      {this.renderTraitsIcons(profile2Traits)}
                    </section>
                  </Fragment>
                ) : null}
              </article>
            </div>
          </div>
        </div>

        {this.renderEvalNote([...profile1Traits, ...profile2Traits])}

        {this.state.modalOpen && (
          <Modal onBackgroundClick={this.closeModal}>
            <ModalContentContainer>
              <ModalCloseButton onClick={this.closeModal}>
                <span className="vxicon icon-close-line" />
              </ModalCloseButton>
              <div css={{ flexDirection: 'column', alignItems: 'center' }}>
                <h2 css={{ fontWeight: 'normal', marginBottom: 16, textAlign: 'center' }}>
                  {_('help:support.mail.prompt')}
                </h2>

                <span css={{ fontSize: 18 }}>0800 80 11 840</span>
                <a css={{ fontSize: 18 }} href={`mailto:${supportMail}`}>
                  {supportMail}
                </a>
              </div>
            </ModalContentContainer>
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default withRouter(Profile);
