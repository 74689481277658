import React from 'react';
import styled from '@emotion/styled';
import { RED, WHITE } from '../../../../../../camtool-styles';

const BorderDiv = styled.div`
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 24px);
  top: -11px;
  left: -10px;
  background: none;
  border-color: ${RED};
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  pointer-events: none;
`;

const Title = styled.div`
  position: absolute;
  transform: translateY(-50%);
  color: ${RED};
  top: 0;
  left: 27px;
  padding: 0 10px;
  background-color: ${WHITE};
  margin-right: 70px; // fixes too much white space right of text on mobile
`;

interface Props {
  title: string;
}

const ModalBonusWarningOverlay: React.FC<Props> = ({ title }) => (
  <BorderDiv>
    <Title>{title}</Title>
  </BorderDiv>
);

export default ModalBonusWarningOverlay;
