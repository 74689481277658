import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../components/AbstractComponent';
import Button from '../../../components/Button/Button';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import Modalbox from '../../Modalbox/Modalbox';
import { AlertsStore } from '../../../stores/Alerts/AlertsStore';
import ServerUtils from '../../../util/ServerUtils';
import { _ } from '../../../util/translate';

class ModalSendFeedback extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
    };
  }

  handleCommentChange(e) {
    this.setState({ comment: e.target.value });
  }

  sendFeedback() {
    ServerUtils.request(
      'POST',
      '/v1/camtool/feedback/{userId}',
      {
        data: JSON.stringify({
          subject: 'Feedback VXLive',
          message: this.state.comment,
        }),
      },
      () => {
        this.setState({ comment: '' });
        this.props.closeFeedbackModal();
        AlertsStore.add({ type: 'info', message: _('download:vxlive.feedback.alertMsg') });
      },
      () => {
        this.setState({ comment: '' });
        this.props.closeFeedbackModal();
      }
    );
  }

  render() {
    return (
      <Modalbox
        name="modalSurvey"
        state={this.props.isOpen}
        onKeyPressESC={this.props.closeFeedbackModal}
      >
        <div className="modalgrid__box">
          <div className="modalgrid__box__header">
            <div className="icon-info-sign" />
            <div>{_('download:vxlive.feedback.modalTitle')}</div>
            <div className="seperator" />
            <div className="icon-remove options" onClick={() => this.props.closeFeedbackModal()} />
          </div>

          <div className="modalgrid__box__content modal-survey">
            <div className="modal-survey__subtitle">{_('download:vxlive.feedback.actionCall')}</div>

            <div className="modal-survey__title">{_('download:vxlive.feedback.prompt')}</div>

            <div className="modal-survey__textarea">
              <textarea
                value={this.state.comment}
                placeholder={_('download:vxlive.feedback.commentPlaceholder').toUpperCase()}
                onChange={this.handleCommentChange}
                ref="surveyComment"
                name="comment"
                cols="80"
                rows="8"
                style={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#CCCCCC',
                  padding: 16,
                }}
              />
            </div>

            <ButtonGroup position="center" className="modal-survey__buttons">
              <Button
                className="button--blue"
                label={_('download:vxlive.feedback.button')}
                type="button"
                onClick={this.sendFeedback}
                disabled={this.state.comment.length < 3}
              />
            </ButtonGroup>
          </div>
        </div>
      </Modalbox>
    );
  }
}

ModalSendFeedback.propTypes = {};

ModalSendFeedback.defaultProps = {};

export default ModalSendFeedback;
export { ModalSendFeedback };
