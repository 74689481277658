import React, { Fragment } from 'react';
import AbstractProfile from '../../AbstractProfile';
import { PROFILE_VX_FETISH } from '../../../../stores/Profile/Profile';

import NavSubVX from '../NavSubVX';

import {
  Button,
  ButtonGroup,
  Footer,
  Form,
  GroupRow,
  Markdown,
  MultiLangTextareafield,
  Notice,
  OptionBoxCheckbox,
  OptionBoxOnOff,
  OptionBoxRadio,
  Separator,
  Spinner,
  T,
} from '../../../../components';

import { _ } from '../../../../util/translate';

export default class Fetish extends AbstractProfile {
  constructor(props) {
    super(props);
    this.onChangeFetishState = this.onChangeFetishState.bind(this);
  }

  getResource() {
    return PROFILE_VX_FETISH;
  }

  onChangeFetishState(event) {
    if (event.target.value === 'off') {
      this.submitForm({ profileFetishState: 'off' });
    }
  }

  render() {
    super.render();
    const currentValues = this.currentValues;

    this.computedFetishState = currentValues.fetishRole ? 'on' : 'off';
    this.showFetishForm = currentValues.profileFetishState === 'on';
    this.isHomosexual = this.storeValues.profileSexuality === 'homosexual';
    this.spokenLangs = this.storeValues.profileLanguages;

    this.debugData = Object.assign(this.debugData, {
      computedFetishState: this.computedFetishState,
      showFetishForm: this.showFetishForm,
      isHomosexual: this.isHomosexual,
    });

    return (
      <main id="fetish" className="fetish grid">
        <NavSubVX />

        <section className="grid__row">
          <section className="grid__column grid__box min-width--0">
            <header className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'profiles:visitx.fetish.pagetitle.label'} />
              </div>
            </header>

            {!this.showPartnerFormVisible && (
              <div className="grid__box__item">
                <div className="grid__box__item__content">
                  <div className="warning-text">
                    <div className="fetish__warning-text__column">
                      {this.storeValues.profileFetishState === 'on' &&
                        !this.storeValues.isFetishUnlockedBySupport && (
                          <Notice
                            icon="icon-warning-sign"
                            text={_('profiles:profileFetishInfo.notChecked')}
                          />
                        )}

                      <div className="title">
                        <T _={'profiles:profileFetishState.title'} />:
                      </div>
                      <Markdown
                        className="subtitle"
                        source={_('profiles:profileFetishState.description')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Form
              className="grid__box__item spinner-container"
              ref="form"
              onValidSubmit={this.submitForm}
              onInvalidSubmit={this.showErrorFields}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              onChange={this.onChange}
            >
              {this.state.isLoading && <Spinner />}

              <div className="grid__box__item__content profile-partners__formwidth">
                <GroupRow
                  id="fetishState"
                  label={`${_('profiles:profileFetishState.label')}:`}
                  tooltipText={_('profiles:profileFetishState.tooltipText')}
                  tooltipPosition="right"
                >
                  <OptionBoxOnOff
                    name="profileFetishState"
                    value={this.storeValues.profileFetishState}
                    options={this.storeValues.formValues.profileFetishState}
                    onChange={this.onChangeFetishState}
                    labelOn={_('components:optionBoxOnOff.label.on')}
                    labelOff={_('components:optionBoxOnOff.label.off')}
                  />
                </GroupRow>

                {this.showFetishForm && (
                  <Fragment>
                    <GroupRow
                      id="fetishRole"
                      label={`${_('profiles:fetishRole.label')}:`}
                      tooltipText={_('profiles:fetishRole.tooltipText')}
                      tooltipPosition="right"
                      className={this.hasInputError('fetishRole') ? 'error' : ''}
                      errorMessage={this.state.displayErrorInputs['fetishRole']}
                    >
                      <OptionBoxRadio
                        required
                        requiredMessage={_('profiles:fetishRole.requiredMessage')}
                        value={this.storeValues.fetishRole}
                        name="fetishRole"
                        forceTranslate={true}
                        options={this.storeValues.formValues.fetishRole}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="fetishType"
                      label={`${_('profiles:fetishTypes.label')}:`}
                      tooltipText={_('profiles:fetishTypes.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxCheckbox
                        value={this.storeValues.fetishTypes}
                        name="fetishTypes"
                        options={this.storeValues.formValues.fetishTypes}
                        forceTranslate={true}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="fetishExperience"
                      label={`${_('profiles:fetishExperiences.label')}:`}
                      tooltipText={_('profiles:fetishExperiences.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxCheckbox
                        value={this.storeValues.fetishExperiences}
                        name="fetishExperiences"
                        options={this.storeValues.formValues.fetishExperiences}
                        forceTranslate={true}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="fetishToys"
                      label={`${_('profiles:fetishToys.label')}:`}
                      tooltipText={_('profiles:fetishToys.tooltipText')}
                      tooltipPosition="right"
                    >
                      <OptionBoxCheckbox
                        value={this.storeValues.fetishToys}
                        name="fetishToys"
                        forceTranslate={true}
                        options={this.storeValues.formValues.fetishToys}
                      />
                    </GroupRow>

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    {this.isHomosexual && (
                      <GroupRow
                        id="fetishGaySpecial"
                        label={`${_('profiles:fetishGaySpecials.label')}:`}
                        tooltipText={_('profiles:fetishGaySpecials.tooltipText')}
                        tooltipPosition="right"
                      >
                        <OptionBoxCheckbox
                          value={this.storeValues.fetishGaySpecials}
                          name="fetishGaySpecials"
                          forceTranslate={true}
                          options={this.storeValues.formValues.fetishGaySpecials}
                        />
                      </GroupRow>
                    )}

                    <GroupRow tooltip="none">
                      <Separator className="transparent" />
                    </GroupRow>

                    <GroupRow
                      id="fetishText"
                      label={`${_('profiles:fetishText.label')}:`}
                      tooltipText={_('profiles:fetishText.tooltipText')}
                      tooltipPosition="right"
                      className={this.hasInputError('fetishText') ? 'error' : ''}
                      errorMessage={this.state.displayErrorInputs['fetishText']}
                    >
                      <MultiLangTextareafield
                        required
                        requiredMessage={_('profiles:fetishText.requiredMessage')}
                        validations="isValidMultiLangField"
                        validationError={_('common:validationMessage.isValidMultiLangField')}
                        name="fetishText"
                        value={this.storeValues.fetishText.texts}
                        placeholder={_('profiles:fetish.text.placeholder')}
                        langs={this.spokenLangs}
                        maxLength={1000}
                        displayErrors={this.state.displayErrors}
                      />
                    </GroupRow>

                    <GroupRow
                      id="fetishTaboos"
                      label={`${_('profiles:fetishTaboos.label')}:`}
                      tooltipText={_('profiles:fetishTaboos.tooltipText')}
                      tooltipPosition="right"
                      className={this.hasInputError('fetishTaboos') ? 'error' : ''}
                      errorMessage={this.state.displayErrorInputs['fetishTaboos']}
                    >
                      <MultiLangTextareafield
                        required
                        requiredMessage={_('profiles:fetishTaboos.requiredMessage')}
                        validations="isValidMultiLangField"
                        validationError={_('common:validationMessage.isValidMultiLangField')}
                        name="fetishTaboos"
                        value={this.storeValues.fetishTaboos.texts}
                        placeholder={_('profiles:fetish.taboos.placeholder')}
                        langs={this.spokenLangs}
                        maxLength={1000}
                        displayErrors={this.state.displayErrors}
                      />
                    </GroupRow>

                    {this.showFetishForm && (
                      <GroupRow tooltip="none">
                        <Separator className="transparent" />
                      </GroupRow>
                    )}

                    <ButtonGroup position="right">
                      <Button
                        label={_('common:button.cancel')}
                        onClick={this.rejectChanges}
                        type="button"
                        disabled={!this.activateResetButton}
                      />
                      <Button
                        className="button--blue"
                        label={_('common:button.save')}
                        type="submit"
                      />
                    </ButtonGroup>
                  </Fragment>
                )}
              </div>
            </Form>
          </section>
        </section>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}
