import React, { FC, ReactElement } from 'react';
import { css } from '@emotion/core';
import { ApolloClient } from 'apollo-client';
import { AboContentType, AboContentTypesFilter, AboRoute } from '../types';
import AboContentPage from './AboContentPage/AboContentPage';
import AboDetailsPage from './AboDetailsPage/AboDetailsPage';
import AboReviewPage from './AboReviewPage/AboReviewPage';
import AboRedeemPageMedia from './AboRedeemPage/AboRedeemPageMedia';
import AboRedeemPageVXPagesCredits from './AboRedeemPage/AboRedeemPageVXPagesCredits';
import AboRedeemPageFreeMessages from './AboRedeemPage/AboRedeemPageFreeMessages';
import { WHITE_3 } from '../../../camtool-styles';
import { getFilteredContentTypes, useAboReducer, useSkipContentPage } from './util';
import { Section } from '../../../atoms';
import { SectionTitle } from './components';

const sectionCss = css`
  flex: 1 0 auto;
`;

const sectionStyles = css`
  padding: 64px;
  background-color: ${WHITE_3};
  flex-direction: column;
  align-items: center;
`;

interface Props {
  apolloClientAbo: ApolloClient<Record<string, unknown>>;
  typesFilter?: AboContentTypesFilter;
}

const AboCreate: FC<Props> = ({ apolloClientAbo, typesFilter }) => {
  const [state, dispatch] = useAboReducer();
  const currentPage = state.history[state.history.length - 1];

  const aboContentTypes = getFilteredContentTypes(typesFilter);

  useSkipContentPage(aboContentTypes, currentPage, dispatch);

  return (
    <Section
      css={sectionCss}
      styles={sectionStyles}
      title={(): ReactElement => (
        <SectionTitle currentPage={currentPage} aboContentTypes={aboContentTypes} />
      )}
    >
      {/* AboContentPage */}
      {currentPage === AboRoute.AboContentPage && (
        <AboContentPage state={state} dispatch={dispatch} aboContentTypes={aboContentTypes} />
      )}

      {/* AboRedeemPage  */}
      {currentPage === AboRoute.AboRedeemPage && state.aboContentType === AboContentType.Media && (
        <AboRedeemPageMedia state={state} dispatch={dispatch} />
      )}

      {currentPage === AboRoute.AboRedeemPage && state.aboContentType === AboContentType.Photos && (
        <AboRedeemPageMedia state={state} dispatch={dispatch} />
      )}

      {currentPage === AboRoute.AboRedeemPage && state.aboContentType === AboContentType.Videos && (
        <AboRedeemPageMedia state={state} dispatch={dispatch} />
      )}

      {currentPage === AboRoute.AboRedeemPage &&
        state.aboContentType === AboContentType.VXPagesCredits && (
          <AboRedeemPageVXPagesCredits state={state} dispatch={dispatch} />
        )}

      {currentPage === AboRoute.AboRedeemPage &&
        state.aboContentType === AboContentType.FreeMessages && (
          <AboRedeemPageFreeMessages state={state} dispatch={dispatch} />
        )}

      {/* AboDetailsPage */}
      {currentPage === AboRoute.AboDetailsPage && (
        <AboDetailsPage state={state} dispatch={dispatch} apolloClientAbo={apolloClientAbo} />
      )}

      {/* AboReviewPage */}
      {currentPage === AboRoute.AboReviewPage && (
        <AboReviewPage state={state} dispatch={dispatch} />
      )}
    </Section>
  );
};
export default AboCreate;
