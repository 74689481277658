import React, { FC } from 'react';
import { Maybe, MediaFeedback } from '../../../graphql/VXModels/types';
import Comment from './Comment';
import ReplyComment from './ReplyComment';
import Answer from './Answer';

interface Props {
  feedbackComments: Maybe<MediaFeedback>[];
  modelAvatar: string | null | undefined;
  umaId: number;
}

const CommentsDetails: FC<Props> = ({ feedbackComments, modelAvatar, umaId }) => {
  return (
    <div css={{ flexDirection: 'column', marginTop: '20px', marginRight: '20px' }}>
      {feedbackComments.map((comment) => {
        const { id: commentId, guestLogin, guestAvatar, answer, text, date, isAnswered } = comment;
        return (
          <div css={{ flexDirection: 'column' }} key={commentId}>
            <Comment guestLogin={guestLogin} guestAvatar={guestAvatar} text={text} date={date} />
            <div css={{ alignItems: 'baseline', marginBottom: '40px' }}>
              {guestLogin && (
                <div css={{ width: '100%', minHeight: '20px' }}>
                  {isAnswered ? (
                    <Answer answer={answer} modelAvatar={modelAvatar} />
                  ) : (
                    <div css={{ position: 'relative', minWidth: '100%', alignItems: 'center' }}>
                      <ReplyComment umaId={umaId} commentId={commentId} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommentsDetails;
