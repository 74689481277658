import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { APP_BASE_PATH } from '../../util/env';
import { _ } from '../../util/translate';
import { useCanUseTelegram } from '../../packages/Telegram';
import styled from '@emotion/styled';
import { BREAKPOINT_SPHONE_CONDITION, BREAKPOINT_TABLET_CONDITION } from '../../camtool-styles';

const FooterContainer = styled.div`
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-wrap: wrap;
  }
  @media ${BREAKPOINT_SPHONE_CONDITION} {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    -ms-flex: 1 1 auto;
  }
`;

const Footer = (props) => {
  const canUseTelegram = useCanUseTelegram();

  return (
    <section
      className={`${props.className} footer`}
      style={props.showOnlyOnTablet ? { ...props.styles } : { ...props.styles, display: 'flex' }}
    >
      <FooterContainer className="footer__content">
        <NavLink
          className="footer__item"
          to={`${APP_BASE_PATH}/legal/houserule`}
          activeClassName="footer__item--active"
        >
          {_('navigation:main.legal.houserule')}
        </NavLink>
        &nbsp;|&nbsp;
        <NavLink
          className="footer__item"
          to={`${APP_BASE_PATH}/legal/terms`}
          activeClassName="footer__item--active"
        >
          {_('navigation:main.legal.terms')}
        </NavLink>
        {canUseTelegram && (
          <>
            &nbsp;|&nbsp;
            <NavLink
              className="footer__item"
              to={`${APP_BASE_PATH}/legal/telegram-terms`}
              activeClassName="footer__item--active"
            >
              {_('navigation:main.legal.telegram.terms')}
            </NavLink>
          </>
        )}
        &nbsp;|&nbsp;
        <NavLink
          className="footer__item"
          to={`${APP_BASE_PATH}/legal/gdpr`}
          activeClassName="footer__item--active"
        >
          {_('common:legal.privacy')}
        </NavLink>
        &nbsp;|&nbsp;
        <NavLink
          className="footer__item"
          to={`${APP_BASE_PATH}/legal/imprint`}
          activeClassName="footer__item--active"
        >
          {_('navigation:main.legal.imprint')}
        </NavLink>
      </FooterContainer>
    </section>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  showOnlyOnTablet: PropTypes.bool,
  styles: PropTypes.object,
};

Footer.defaultProps = {
  className: '',
  showOnlyOnTablet: true,
  styles: {},
};

export default Footer;
export { Footer };
