import React, { createContext, FC, useContext } from 'react';
import { LeftMenuGroupId } from '../types';
import { useLeftMenuRootContext } from '../Root';

interface LeftMenuGroupContextValue {
  /* Whether the component accessing the context is part of a group */
  isPartOfGroup: boolean;
  isOpen: boolean;
  openGroup: () => void;
  toggleGroup: () => void;
}

const LeftMenuGroupContext = createContext<LeftMenuGroupContextValue>({
  isPartOfGroup: false,
  isOpen: false,
  openGroup: () => undefined,
  toggleGroup: () => undefined,
});

interface ProviderProps {
  groupId: LeftMenuGroupId;
}

export const LeftMenuGroupContextProvider: FC<ProviderProps> = ({ groupId, children }) => {
  const {
    openGroupId,
    openGroup: doOpenGroup,
    toggleGroup: doToggleGroup,
  } = useLeftMenuRootContext();

  const isOpen = openGroupId === groupId;
  const openGroup = (): void => doOpenGroup(groupId);
  const toggleGroup = (): void => doToggleGroup(groupId);

  const value: LeftMenuGroupContextValue = {
    isPartOfGroup: true,
    isOpen,
    openGroup,
    toggleGroup,
  };

  return <LeftMenuGroupContext.Provider value={value}>{children}</LeftMenuGroupContext.Provider>;
};

export const useLeftMenuGroupContext = (): LeftMenuGroupContextValue =>
  useContext(LeftMenuGroupContext);
