import React, { FC, useEffect, useState } from 'react';
import { useBroadcast } from '../../hooks/useBroadcast';
import { EmptyContent, FancyError, Spinner } from '../../../../components';
import { Article } from '../../../Mailings/components';
import SectionTextAndAttachment from './SectionTextAndAttachment';
import SectionSalesStats from './SectionSalesStats';
import { Grid } from '@material-ui/core';
import SectionBroadcastStats from './SectionBroadcastStats';
import { getMailingId } from '../../util';
import { BroadcastStatus } from '../../types';
import { _ } from '../../../../util/translate';
import { useLazyMailing } from '../../../Mailings/hooks';
import SectionMailingInfo from './SectionMailingInfo';
import SectionCancelBroadcast from './SectionCancelBroadcast';
import { differenceInHours } from 'date-fns';

const DAY_HOURS = 24;
interface BroadcastShowProps {
  id: number;
}

const POLL_INTERVAL_RUNNING = 5000;
const POLL_INTERVAL_DONE = 60000;

const BroadcastShow: FC<BroadcastShowProps> = ({ id }) => {
  const [pollInterval, setPollInterval] = useState<number | undefined>();
  const [getMailing, { mailing }] = useLazyMailing();
  const { broadcast, loading, error } = useBroadcast(id, {
    fetchPolicy: pollInterval ? 'cache-and-network' : 'cache-first',
    pollInterval,
  });
  const { sentCount, usersCount, canceled, referenceId, revoked, timestamp, status } =
    broadcast || {};
  const mailingId = getMailingId(referenceId);
  const deliveredPercentage = sentCount / usersCount || 0;
  const isWithin24Hours = differenceInHours(new Date(), new Date(timestamp)) < DAY_HOURS;
  const canCancel =
    !canceled && (status === BroadcastStatus.pending || status === BroadcastStatus.running);
  const canDelete =
    !revoked &&
    isWithin24Hours &&
    (status === BroadcastStatus.done ||
      (canceled && deliveredPercentage > 0 && deliveredPercentage < 100));

  useEffect(() => {
    if (status) {
      const nextPollInterval =
        status === BroadcastStatus.running ? POLL_INTERVAL_RUNNING : POLL_INTERVAL_DONE;
      console.log('status updated', status, nextPollInterval);
      setPollInterval(nextPollInterval);
    }
  }, [status]);
  useEffect(() => mailingId && getMailing(mailingId), [mailingId]);

  return error ? (
    <FancyError error={error} />
  ) : !broadcast && loading ? (
    <Spinner />
  ) : broadcast ? (
    <div>
      <Article>
        <Grid container spacing={3}>
          <SectionBroadcastStats broadcast={broadcast} />
          <SectionMailingInfo broadcast={broadcast} mailing={mailing} />
          {canCancel && <SectionCancelBroadcast broadcastId={broadcast.id} shouldDelete={false} />}
          {canDelete && <SectionCancelBroadcast broadcastId={broadcast.id} shouldDelete={true} />}
          <SectionTextAndAttachment broadcast={broadcast} />
          <SectionSalesStats broadcast={broadcast} />
        </Grid>
      </Article>
    </div>
  ) : (
    <EmptyContent title={_('telegram:BroadcastShow.broadcastNotFound')} />
  );
};

export default BroadcastShow;
