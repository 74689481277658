import gql from 'graphql-tag';
import { FRAGMENT_FILE } from './File';

export const FRAGMENT_BROADCAST = gql`
  fragment Broadcast on Broadcast {
    id
    text
    file {
      ...File
    }
    usersCount
    sentCount
    timestamp
    referenceId
    canceled
    revoked
    salesCount
    price
    paidContent
    status
    userType
    failedCount
    cancelable
    progress
    isSticky
  }
  ${FRAGMENT_FILE}
`;
