import React, { FC } from 'react';
import { Section } from '../../../../atoms';
import { _ } from '../../../../util/translate';
import { Service0900PremiumNumber, Maybe } from '../../../../graphql/VXModels/types';
import NumbersList0900 from './NumbersList0900';
import styled from '@emotion/styled';
import { GRAY_4 } from '../../../../camtool-styles';

const Headline = styled.h2`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${GRAY_4};
`;

interface Props {
  visitX0900Numbers: Maybe<Service0900PremiumNumber>[];
}

const Numbers0900Section: FC<Props> = ({ visitX0900Numbers }) => {
  return (
    <Section css={{ flex: '1 0 auto' }} title={_('service0900:PhoneSex.active.visitx0900.title')}>
      <div
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px',
          flexDirection: 'column',
        }}
      >
        <header css={{ width: '100%' }}>
          <Headline>{_('service0900:phoneSex.yourNumbers')}</Headline>
        </header>
        <NumbersList0900 visitX0900Numbers={visitX0900Numbers} />
      </div>
    </Section>
  );
};

export default Numbers0900Section;
