import React, { FC } from 'react';
import { BLUE, GREEN, RED } from '../../../../../camtool-styles';
import { _ } from '../../../../../util/translate';
import { CodeStatus } from '../../../types';
import styled from '@emotion/styled';

interface Props {
  status: string;
}

interface StatusIconProps {
  color: string;
}

const StatusIcon = styled.div<StatusIconProps>`
  margin-right: 4px;
  color: ${(props): string => props.color};
`;

const StatusIconLabel: FC<Props> = ({ status }) => {
  return (
    <span>
      {status === CodeStatus.Active && <StatusIcon className="icon-ok" color={GREEN} />}
      {status === CodeStatus.Inactive && <StatusIcon className="icon-ban-circle" color={RED} />}
      {status === CodeStatus.Planned && <StatusIcon className="icon-clock" color={BLUE} />}
      <span>{_(`benefits:voucher.status.${status}`)}</span>
    </span>
  );
};

export default StatusIconLabel;
