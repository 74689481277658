import React, { FC, ReactNode } from 'react';
import { makeStyles, createStyles, Avatar } from '@material-ui/core';
import { Box, Accordion, Typography, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import backgroundTelegram from './Assets/backgroundTelegram.jpg';
import { WHITE, WHITE_2 } from '../../camtool-styles';

type BannerStyleProps = Pick<BadgesBannerProps, 'backgroundImage' | 'backgroundImagePosition'>;

const useStyles = makeStyles(() =>
  createStyles({
    accordion: (props: BannerStyleProps) => ({
      backgroundImage: `url(${props.backgroundImage || backgroundTelegram})`,
      backgroundSize: 'cover',
      backgroundPosition: props.backgroundImagePosition,
      textAlign: 'center',
      color: WHITE,
    }),
    accordionDetail: {
      padding: '8px 25px 16px',
      color: WHITE_2,
      width: '100%',
      justifyContent: 'center',
    },
    accordionSummary: {
      justifyContent: 'center',
    },
    expandIcon: {
      fontSize: '30px',
    },
    avatar: {
      backgroundColor: 'transparent',
      border: `1px solid ${WHITE}`,
    },
  })
);

interface BadgesBannerProps {
  summaryText: ReactNode;
  benefitList: { icon: ReactNode; text: ReactNode }[];
  detailsText: ReactNode;
  backgroundImage?: string;
  backgroundImagePosition?: string;
  defaultExpanded?: boolean;
  square?: boolean;
}

const BadgesBanner: FC<BadgesBannerProps> = ({
  backgroundImage,
  backgroundImagePosition = 'center right',
  summaryText,
  benefitList,
  detailsText,
  square = true,
  defaultExpanded = false,
}) => {
  const classes = useStyles({ backgroundImage, backgroundImagePosition });

  return (
    <Box>
      <Accordion
        classes={{ root: classes.accordion }}
        defaultExpanded={defaultExpanded}
        square={square}
      >
        <AccordionSummary
          classes={{ content: classes.accordionSummary, expandIcon: classes.expandIcon }}
          IconButtonProps={{ color: 'inherit' }}
          expandIcon={<ExpandMoreIcon classes={{ root: classes.expandIcon }} />}
        >
          {summaryText}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetail }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              gridGap={'20px'}
              justifyContent={{ sm: 'space-around' }}
            >
              {benefitList.map((listItem, index) => {
                return (
                  <Box
                    key={index}
                    display="flex"
                    alignItems={'center'}
                    gridGap={'10px'}
                    flexDirection={{ sm: 'column' }}
                  >
                    <Avatar classes={{ root: classes.avatar }}>{listItem.icon}</Avatar>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                      <Typography variant="subtitle1" color="inherit" align="left">
                        {listItem.text}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box mt={3} maxWidth={{ sm: '450px' }}>
              {detailsText}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default BadgesBanner;
