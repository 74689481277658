import React, { FC } from 'react';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { Switch } from '../../../atoms/Switch';
import { _ } from '../../../util/translate';
import { useSetService0900Status } from '../hooks';
import { Service0900StatusEnum } from '../../../graphql/VXModels/types';
import { createStyles, makeStyles } from '@material-ui/styles';
import { BLACK } from '../../../camtool-styles';
import { isActive, isDisabled } from '../util';

interface StatusSwitchProps {
  status: Service0900StatusEnum;
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: BLACK,
      fontWeight: 'bolder',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
  })
);

const StatusSwitch: FC<StatusSwitchProps> = ({ status }) => {
  const classes = useStyles();
  const active = isActive(status);
  const disabled = isDisabled(status);
  const [setStatus] = useSetService0900Status();

  const toggleService0900Status = (): void =>
    setStatus(active ? Service0900StatusEnum.inactive : Service0900StatusEnum.active);

  return (
    <FormGroup row>
      <FormControlLabel
        classes={classes}
        control={
          <Switch
            checked={!disabled && active}
            onChange={toggleService0900Status}
            disabled={disabled}
          />
        }
        label={
          disabled
            ? _('service0900:StatusSwitch.status.notSet')
            : active
            ? _('service0900:StatusSwitch.status.active')
            : _('service0900:StatusSwitch.status.inactive')
        }
      />
    </FormGroup>
  );
};

export default StatusSwitch;
