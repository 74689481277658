import React from 'react';
import PropTypes from 'prop-types';
import AbstractFormComponent from '../AbstractFormComponent';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import * as Formsy from 'formsy-react';
import { _ } from '../../../util/translate';

function getValidTranslatedLangs(langs) {
  let validTranslatedLangs = {};
  for (let lang of langs) {
    validTranslatedLangs[lang] = _('enum:common.languages.' + lang);
  }
  return validTranslatedLangs;
}

class AbstractMultiLangComponent extends AbstractFormComponent {
  constructor(props) {
    props.value._langs = cloneDeep(props.langs);
    super(props);

    this.state = Object.assign(this.state, {
      selectedLang: this.props.langs[0] || this.props.defaultLang,
      validLangs: getValidTranslatedLangs(this.props.langs),
    });

    this.onChange = this.onChange.bind(this);
    this.getValue = this.getValue.bind(this);
    this.setLangValue = this.setLangValue.bind(this);
    this.getLangValue = this.getLangValue.bind(this);
    this.changeSelectedLang = this.changeSelectedLang.bind(this);
    this.onChange = this.onChange.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    nextProps.value._langs = cloneDeep(nextProps.langs);
    if (!isEqual(this.props.langs, nextProps.langs)) {
      this.setState({
        selectedLang: nextProps.langs[0],
        validLangs: getValidTranslatedLangs(nextProps.langs),
      });
    }

    if (!this.state._value._langs) {
      this.setState({
        _value: cloneDeep(nextProps.value),
      });
    }

    super.componentWillReceiveProps.bind(this)(nextProps);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdateProps = !isEqual(this.props, nextProps);
    const shouldUpdateState = !isEqual(this.state, nextState);

    return shouldUpdateProps || shouldUpdateState;
  }

  getValue() {
    let langValues = super.getValue();
    return typeof langValues === 'object' ? langValues : {};
  }

  setLangValue(lang, value) {
    const langValues = this.getValue();
    langValues[lang] = langValues[lang] || {};
    langValues._langs = langValues._langs || this.props.langs;
    if (langValues[lang] !== value) {
      langValues[lang] = value;
      this.setValue(
        Object.entries(langValues).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
      );
      this.forceUpdate(); // because of shouldComponentUpdate, we have to forceUpdate here
    }
  }

  getLangValue(lang) {
    const langValues = this.getValue();
    return langValues[lang] ? langValues[lang] : '';
  }

  changeSelectedLang(lang) {
    this.setState({ selectedLang: lang });
    this.props.onSelectedLangChange(lang);
  }

  onChange(event) {
    this.setLangValue(this.state.selectedLang, event.target.value);
    this.props.onChange(event);
  }
}

AbstractMultiLangComponent.propTypes = {
  langs: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  requiredMessage: PropTypes.string,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  defaultLang: PropTypes.string,
  onSelectedLangChange: PropTypes.func,
  onChange: PropTypes.func,
};

AbstractMultiLangComponent.defaultProps = Object.assign(AbstractFormComponent.defaultProps || {}, {
  value: {},
  placeholder: 'default multilang placeholder',
  minLength: 0,
  maxLength: 0,
  defaultLang: 'de',
  onSelectedLangChange: () => {},
  onChange: () => {},
});

// Validators
Formsy.addValidationRule('isValidMultiLangField', function (values, value) {
  return !!value && !!value._langs && !!value._langs[0] && !!value[value._langs[0]];
});

export default AbstractMultiLangComponent;
