import MD5 from 'crypto-js/md5';
import globToRegExp from 'glob-to-regexp';
import isString from 'lodash/isString';

const objectAssign = require('object-assign');

function validateFileType(file, allowedFileTypes) {
  return new Promise(function (resolve, reject) {
    const fileExt = file.name.substr((~-file.name.lastIndexOf('.') >>> 0) + 2).toLowerCase();
    if (Array.isArray(allowedFileTypes)) {
      if (allowedFileTypes.indexOf(fileExt) !== -1) {
        resolve(fileExt);
      } else {
        reject('file_type_error');
      }
    } else if (isString(allowedFileTypes)) {
      const re = globToRegExp(allowedFileTypes, { flags: 'i' });
      if (re.test(fileExt)) {
        resolve(fileExt);
      } else {
        reject('file_type_error');
      }
    } else {
      reject('file_type_error');
    }
  });
}
function validatePictureDimension(file, pictureDimension) {
  return new Promise(function (resolve, reject) {
    // Found at:
    // http://stackoverflow.com/questions/8903854/check-image-width-and-height-before-upload-with-javascript
    const URL = window.URL || window.webkitURL;
    const img = new Image();

    img.onload = function () {
      const hasMinDimension =
        this.width >= pictureDimension.minWidth && this.height >= pictureDimension.minHeight;
      const hasHighDeimension = this.width <= 9000 && this.height <= 9000;
      if (hasMinDimension && hasHighDeimension) {
        resolve({ pictureDimension: { width: this.width, height: this.height } });
      } else if (hasMinDimension && !hasHighDeimension) {
        resolve({ pictureDimension: { width: this.width / 1.4, height: this.height / 1.4 } });
      } else {
        reject('picture_size_error');
      }
      URL.revokeObjectURL(this.src);
    };
    img.src = URL.createObjectURL(file);
  });
}

function validateFileHash(file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.onloadend = function () {
      var hash = MD5(reader.result).toString();
      resolve(hash);
    };
    reader.readAsBinaryString(file);
  });
}

class FileValidator {
  constructor(options) {
    options = options || {};
    options.conditions = options.conditions || {};

    this.conditions = objectAssign(
      {
        allowedFileTypes: null,
        pictureDimension: null,
        useHash: false,
      },
      options.conditions
    );
  }

  setConditionFileType(allowedFileTypes) {
    this.conditions.allowedFileTypes = allowedFileTypes;
  }

  setConditionPictureDimension(minWidth, minHeight) {
    this.conditions.pictureDimension = {
      minWidth: minWidth,
      minHeight: minHeight,
    };
  }

  setConditionUseHash(useHash) {
    this.conditions.useHash = useHash;
  }

  validate(file, onValid, onInvalid) {
    const promises = [];
    if (this.conditions.allowedFileTypes) {
      promises.push(validateFileType(file, this.conditions.allowedFileTypes));
    }

    if (this.conditions.pictureDimension) {
      promises.push(validatePictureDimension(file, this.conditions.pictureDimension));
    }

    if (this.conditions.useHash) {
      promises.push(validateFileHash(file));
    }

    Promise.all(promises)
      .then((result) => {
        onValid(result);
      })
      .catch((error) => {
        onInvalid(error);
      });
  }
}

export default FileValidator;
