import { ErrorMessageEnum, UserData } from '../types';
import axios from 'axios';
import { APP_API_HOST } from '../../../util/env';

export async function verifyPictureMd5hash(
  userData: UserData,
  md5hash: string
): Promise<{ verified: boolean; errorMessage: ErrorMessageEnum | undefined }> {
  const response = await axios({
    method: 'get',
    url: `${APP_API_HOST}/v1/camtool/user/${userData.userId}/util/pictureHashExists/${md5hash}?s=uploadmanager`,
    responseType: 'json',
  });
  const verified = response.status === 200 ? !response.data.data?.hashExists : false;
  return {
    verified,
    errorMessage: !verified ? ErrorMessageEnum.pictureDuplicateContent : undefined,
  };
}

export async function verifyVideoMd5hash(
  userData: UserData,
  md5hash: string
): Promise<{ verified: boolean; errorMessage: ErrorMessageEnum | undefined }> {
  const response = await axios({
    method: 'get',
    url: `${APP_API_HOST}/v1/camtool/user/${userData.userId}/util/videoHashExists/${md5hash}?s=uploadmanager`,
    responseType: 'json',
  });
  const verified = response.status === 200 ? !response.data.data?.hashExists : false;
  return { verified, errorMessage: !verified ? ErrorMessageEnum.videoDuplicateContent : undefined };
}
