import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/core';
import Media from 'react-media';
import { BREAKPOINT_TABLET, BREAKPOINT_TABLET_CONDITION } from '../../../../camtool-styles';
import { IconType } from '../../../../atoms/Icon/Icon';
import { LeftMenuItem } from '../Item';
import { leftMenuItemHeight } from '../Item/constants';
import LeftMenuGroupChevron from './LeftMenuGroupChevron';
import { useLeftMenuGroupContext } from './context';

interface ContainerProps {
  isOpen: boolean;
}

const Container = styled.div<ContainerProps>`
  background-color: ${(props): string => (props.isOpen ? '#333333' : 'transparent')};
  flex-direction: column;
  flex-shrink: 0;
`;

interface SubMenuProps {
  isOpen: boolean;
}

const SubMenu = styled.div<SubMenuProps>`
  flex-direction: column;
  margin-left: 27.5px;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: max-height 600ms, opacity 600ms;
  overflow: hidden;

  ${BREAKPOINT_TABLET} {
    margin-left: 0;
  }

  ${(props): SerializedStyles | '' =>
    props.isOpen
      ? css`
          opacity: 1;
          max-height: ${14 * leftMenuItemHeight}px;
          pointer-events: auto;
        `
      : ''}
`;

export interface LeftMenuGroupContentProps {
  title: string;
  icon: IconType;
}

const LeftMenuGroupContent: FC<LeftMenuGroupContentProps> = ({ title, icon, children }) => {
  const { isOpen, toggleGroup } = useLeftMenuGroupContext();

  return (
    <Media query={BREAKPOINT_TABLET_CONDITION}>
      {(matches): ReactNode =>
        matches ? (
          children
        ) : (
          <Container isOpen={isOpen}>
            <LeftMenuItem title={title} icon={icon} onClick={toggleGroup} isGroupItem>
              <LeftMenuGroupChevron isOpen={isOpen} />
            </LeftMenuItem>

            <SubMenu isOpen={isOpen}>{children}</SubMenu>
          </Container>
        )
      }
    </Media>
  );
};

export default LeftMenuGroupContent;
