import { useEffect, useState } from 'react';

// Type aliases so that the return value is cleared documented
type Days = number;
type Hours = number;
type Minutes = number;
type Seconds = number;
type Milliseconds = number;

const SECOND = 1000; // 1000 milliseconds
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const calculateDiff = (timestamp: string, backwards: boolean): number => {
  const parsed = Date.parse(timestamp);
  return isNaN(parsed) ? 0 : Math.max((parsed - Date.now()) * (backwards ? -1 : 1), 0);
};
const calculateDiffCET = (timestamp: string, backwards: boolean): number => {
  const parsed = Date.parse(timestamp);
  return isNaN(parsed) ? 0 : Math.max((parsed - Date.now()) * (backwards ? -1 : 1), 0);
};

function useCountdown(
  timestamp: string,
  backwards = false,
  instantUpdates = false,
  cet = false
): [Days, Hours, Minutes, Seconds, Milliseconds] {
  const currentDiff = !cet
    ? calculateDiff(timestamp, backwards)
    : calculateDiffCET(timestamp, backwards);
  const [storedDiff, setStoredDiff] = useState(currentDiff);
  const diff = instantUpdates ? currentDiff : storedDiff;

  useEffect(() => {
    const interval = setInterval(() => {
      const newDiff = !cet
        ? calculateDiff(timestamp, backwards)
        : calculateDiffCET(timestamp, backwards);
      setStoredDiff(newDiff);

      if (newDiff <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return interval ? () => clearInterval(interval) : undefined;
  }, [timestamp, backwards]);

  // Time left
  const days = Math.trunc(diff / DAY);
  // TODO DONT DO THIS KIDS :D
  let hours = Math.trunc((diff % DAY) / HOUR);
  cet ? (hours -= 2) : hours;
  const minutes = Math.trunc((diff % HOUR) / MINUTE);
  const seconds = Math.trunc((diff % MINUTE) / SECOND);
  const milliseconds = diff % SECOND;

  return [days, hours, minutes, seconds, milliseconds];
}

export default useCountdown;
