import { makeStyles, createStyles } from '@material-ui/core';

const COUNTDOWN_GREY = '#757167';
const COUNTDOWN_LIGHTGREY = '#888';
const COUNTDOWN_BLUE = '#5c62e1';

export const useVXCountdownStyles = makeStyles((theme) =>
  createStyles({
    countdownPosition: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        bottom: '20px',
      },
      [theme.breakpoints.up('md')]: {
        right: '2em',
        top: '3em',
      },
    },
    countdownContainer: {
      minWidth: '280px',
    },
    challengeInfo: {
      backgroundColor: COUNTDOWN_BLUE,
      height: '30px',
      transform: 'skewX(-15deg)',
      position: 'absolute',
      zIndex: 1,
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    dateContainer: {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      height: '75px',
      transform: 'skewX(-15deg)',
      marginRight: '30px',
      marginTop: '25px',
    },
    body1: { color: '#fff', fontSize: '16px', transform: 'skewX(15deg)' },
    body2: { fontSize: '25px', fontWeight: 'bold', textAlign: 'center' },
    subtitle1: { color: COUNTDOWN_GREY, textAlign: 'center', fontSize: '14px' },
    digits: {
      transform: 'skewX(15deg)',
      padding: '0 15px',
    },
    countdownBorder: {
      borderLeft: '1px solid ' + COUNTDOWN_LIGHTGREY,
      height: '30px',
      transform: 'skewX(15deg)',
      bottom: '10px',
      position: 'relative',
    },
  })
);
