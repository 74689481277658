import { Box, Typography } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import React, { FC } from 'react';
import { Master } from '../../../../../graphql/VXServicesTelegram/types';
import { _ } from '../../../../../util/translate';
import { useFileStorageSettings } from '../../../../Telegram';
import AutoMailFileUploadInput from '../AutoMailFileUploadInput';
import { MobileUserLifeCycleStep } from '../types';

interface AutoMailGridFormItemAttachmentProps {
  mimetypes?: Master['allowedMimeTypes'];
}

const AutoMailGridFormItemAttachment: FC<AutoMailGridFormItemAttachmentProps> = ({ mimetypes }) => {
  const fileStorageSettings = useFileStorageSettings();

  return (
    <Box p={2}>
      <Typography variant="caption">{_('telegram:BroadcastList.column.file.label')}</Typography>
      <Field name="file">
        {({ field, form: { errors } }: FieldProps<Pick<MobileUserLifeCycleStep, 'file'>>) => {
          const hasError = errors?.file === 'image and video only';
          return (
            <>
              <AutoMailFileUploadInput
                css={
                  hasError
                    ? { width: '100%', height: '250px', color: 'red', borderColor: 'red' }
                    : { width: '100%', height: '250px' }
                }
                mimetype={mimetypes}
                name={field.name}
                fileStorageSettings={fileStorageSettings}
              />
              {hasError && (
                <div css={{ width: '100%', alignItems: 'center', color: 'red', marginTop: '10px' }}>
                  {_('UploadManager:errorMessage.pictureInvalidFilenameExtension.text')}
                </div>
              )}
            </>
          );
        }}
      </Field>
    </Box>
  );
};

export default AutoMailGridFormItemAttachment;
