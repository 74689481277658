import update from 'immutability-helper';
import * as ActionTypes from '../util/constants/ActionTypes';
import ServerUtils from '../util/ServerUtils';
import AppDispatcher from '../util/dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import cloneDeep from 'lodash/cloneDeep';
import { APP_IS_DEVELOPMENT } from '../util/env';

const CHANGE_EVENT = 'change';

function cleanRecursive(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key.charAt(0) === '_') {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        obj[key] = cleanRecursive(obj[key]);
      }
    }
  }

  return obj;
}

class AbstractRestApiActionCreatorsClass {
  constructor(config) {
    this.config = config || { name: '-notSet-', resources: {} };

    this.createResource = this.createResource.bind(this);
    this.readResource = this.readResource.bind(this);
    this.updateResource = this.updateResource.bind(this);
    this.deleteResource = this.deleteResource.bind(this);
    this.receiveData = this.receiveData.bind(this);
  }

  // CRUD start
  createResource(resource, params, onSuccess, onError) {
    const config = this.getConfig(resource);
    const debug = this.__proto__.constructor.debug && APP_IS_DEVELOPMENT;
    params = params || {};
    params.data = params.data ? JSON.stringify(this.mapToModel(resource, params.data)) : '{}';
    params.options = params.options || config.options;
    if (debug) console.log(this.__proto__.constructor.name, 'createResource', params);
    ServerUtils.request(
      'POST',
      config.uri.POST,
      params,
      (response) => {
        this.receiveData(resource, response);
        if (onSuccess) onSuccess(response);
      },
      (err, response) => {
        if (onError) onError(err, response);
      }
    );
  }

  readResource(resource, params, append) {
    const config = this.getConfig(resource);
    const debug = this.__proto__.constructor.debug && APP_IS_DEVELOPMENT;
    params = params || {};
    params.options = params.options || config.options;
    if (debug) console.log(this.__proto__.constructor.name, 'readResource', params);
    ServerUtils.request('GET', config.uri.GET, params, (response) => {
      if (debug)
        console.log(
          this.__proto__.constructor.name,
          'readResource response',
          resource,
          response,
          append
        );
      this.receiveData(resource, response, append);
    });
  }

  updateResource(resource, params, onSuccess, onError) {
    const config = this.getConfig(resource);
    const debug = this.__proto__.constructor.debug && APP_IS_DEVELOPMENT;
    params = params || {};
    params.data = params.data ? JSON.stringify(this.mapToModel(resource, params.data)) : '{}';
    params.options = params.options || config.options;
    if (debug) console.log(this.__proto__.constructor.name, 'updateResource', params);
    ServerUtils.request(
      'PUT',
      config.uri.PUT,
      params,
      (response) => {
        this.receiveData(resource, response);
        if (onSuccess) onSuccess(response);
      },
      (err, response) => {
        if (onError) onError(err, response);
      }
    );
  }

  deleteResource(resource, params, onSuccess, onError) {
    const config = this.getConfig(resource);
    const debug = this.__proto__.constructor.debug && APP_IS_DEVELOPMENT;
    params = params || {};
    params.options = params.options || config.options;
    if (debug) console.log(this.__proto__.constructor.name, 'readResource', params);
    ServerUtils.request(
      'DELETE',
      config.uri.DELETE,
      params,
      (response) => {
        // this.receiveData(resource, response); // commented out since this line caused crash and prevented onSuccess callback
        if (onSuccess) onSuccess(response);
      },
      (err, response) => {
        if (onError) onError(err, response);
      }
    );
  }

  // CRUD end

  mapToModel(resource, data) {
    return cleanRecursive(data);
  }

  hasResource(resource) {
    return this.config.resources && this.config.resources[resource];
  }

  getConfig(resource) {
    if (!this.hasResource(resource)) {
      throw new ResourceNotFoundException(resource);
    }

    return this.config.resources[resource];
  }

  receiveData(resource, data, append) {
    AppDispatcher.dispatch({
      type: ActionTypes.RECEIVE_DATA + '_' + this.config.name.toUpperCase(),
      resource: resource,
      data: data,
      append: !!append,
    });
  }
}

class AbstractRestApiStoreClass extends EventEmitter {
  constructor(config) {
    super();
    this.config = config || { name: '-notSet-', resources: {} };
    this.data = {};
    for (let resource of Object.keys(this.config.resources)) {
      this.data[resource] = config.resources[resource].defaultData || {};
    }

    AppDispatcher.register((payload) => {
      const resource = payload.resource;
      switch (payload.type) {
        case ActionTypes.RECEIVE_DATA + '_' + this.config.name.toUpperCase():
          this.handleReceiveData(payload);
          this.emitChange(resource);
          break;
      }
    });
  }

  handleReceiveData(payload) {
    const debug = this.__proto__.constructor.debug && process.env && APP_IS_DEVELOPMENT;
    const resource = payload.resource;
    if (payload.append) {
      const config = this.config.resources[resource];
      if (config.appendFunc) {
        this.data[resource] = config.appendFunc(this.data[resource], payload.data);
        this.emitChange(resource);
      } else {
        this.data[resource] = update(this.data[resource], { $push: payload.data });
        this.emitChange(resource);
      }
    } else {
      this.data[resource] = update(this.data[resource], { $merge: payload.data });
      this.emitChange(resource);
    }
    if (debug) console.log(this.__proto__.constructor.name, 'receiveData', this.data[resource]);
  }

  emitChange(resource) {
    this.emit(CHANGE_EVENT + '::' + resource);
  }

  addChangeListener(resource, callback) {
    this.on(CHANGE_EVENT + '::' + resource, callback);
  }

  removeChangeListener(resource, callback) {
    this.removeListener(CHANGE_EVENT + '::' + resource, callback);
  }

  get(resource) {
    if (!this.hasResource(resource)) {
      throw new ResourceNotFoundException(resource, this.config);
    }

    return cloneDeep(this.data[resource]);
  }

  reset(resource) {
    if (!this.hasResource(resource)) {
      throw new ResourceNotFoundException(resource, this.config);
    }
    this.data[resource] = this.config.resources[resource].defaultData || {};
  }

  hasResource(resource) {
    return this.config.resources && this.config.resources[resource];
  }

  getConfig(resource) {
    if (!this.hasResource(resource)) {
      throw new ResourceNotFoundException(resource);
    }

    return this.config.resource[resource];
  }
}

class ResourceNotFoundException {
  constructor(resource, config) {
    this.resource = resource;
    this.message = `Resource "${resource}" not found`;
    this.config = config;
  }
}

export { AbstractRestApiActionCreatorsClass, AbstractRestApiStoreClass };
