import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { WHITE, GRAY_3, BLACK_2, RED } from '../../../../camtool-styles';
import Icon from '../../../../atoms/Icon/Icon';
import { InputField, Select } from '../../../../atoms';

export const TableWrapper = styled.main`
  margin: 0 30px;
  width: 100%;
  flex-direction: column;
`;

export const tableHeaderItemStyles = css`
  color: ${BLACK_2};
  opacity: 1;
  letter-spacing: 0.16px;
  font-size: 14px;
  font-weight: bold;
`;

export const ButtonWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
`;

export const AddMailItem = styled.div`
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
`;

export const GrayTimeBox = styled.div`
  border-radius: 0px 4px 4px 0px;
  background: ${GRAY_3} 0% 0% no-repeat padding-box;
  align-items: center;
  color: ${WHITE};
  cursor: default;

  min-width: 43px;
  justify-content: center;

  span {
    margin: 0 3px;
  }
`;

export const StyledNumberInput = styled.input<{ error: boolean }>`
  text-align: center;
  width: 45px;
  height: 32px;
  border: 0.1px solid;
  border-color: ${(props): string => (props.error ? RED : GRAY_3)};
  border-radius: 4px;
  position: relative;
`;

export const StyledSelect = styled(Select)`
  position: relative;
`;

export const StyledInputField = styled(InputField)<{ hasError: boolean }>`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  position: relative;
  ${(props): string => (props.hasError ? `color: ${RED}` : '')}
`;

export const FixedWrapper = styled.div`
  align-items: center;
  width: 100%;
  min-width: 268px;
  justify-content: space-evenly;
`;

export const RegistrationWrapper = styled.div`
  justify-content: flex-start;
  flex: 1;
`;

export const MessageWrapper = styled.div`
  width: 90%;
`;

export const StyledIcon = styled(Icon)`
  background: ${GRAY_3};
  border-radius: 0px 4px 4px 0px;
  font-size: 20px;
  cursor: pointer;
  color: ${WHITE};
  flex: 0 0 30px;
  justify-content: center;
  align-items: center;
`;

export const StyledDiv = styled.div`
  margin-right: 20px;
`;

export const FieldMessengerWrapper = styled.div`
  border: 1px solid ${GRAY_3};
  min-height: 200px;
  max-height: 200px;
  min-width: 330px;
  max-width: 330px;
  padding: 16px;
  margin: 10px 0 40px 0;
`;

export const ModalContainer = styled.div`
  flex: 1 0 auto;
  flex-direction: column;
  padding: 52px 12px;
  border-radius: 2px;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

export const PreviewImageContainer = styled.div<{ url: string }>`
  background-image: url(${(props): string => (props.url ? props.url : '')});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;
