import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Badge as MUIBadge, BadgeProps as MUIBadgeProps } from '@material-ui/core';

interface BadgeProps extends Omit<MUIBadgeProps, 'color'> {
  color: string;
  textColor?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    badge: (props: BadgeProps) => ({
      backgroundColor: props.color,
      marginRight: 4,
      color: props.textColor || 'inherit',
    }),
  })
);

const Badge: FC<BadgeProps> = ({ color, textColor = 'inherit', ...props }) => {
  const classes = useStyles({ color, textColor });

  return <MUIBadge classes={classes} {...props} />;
};

export default Badge;
