import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { Mutation, Service0900InputStatusEnum } from '../../../graphql/VXModels/types';
import { mergeDeep } from 'apollo-utilities';
import { QUERY_MODEL_SERVICE0900_TOPBAR } from '../graphql/queries';
import { MUTATION_MODEL_SERVICE0900_SET_STATUS } from '../graphql/mutations';

export const useSetService0900Status = (
  options?: MutationHookOptions<Mutation, { status: Service0900InputStatusEnum }>
) => {
  const [mutation, mutationResult] = useMutation<Mutation, { status: Service0900InputStatusEnum }>(
    MUTATION_MODEL_SERVICE0900_SET_STATUS,
    options
  );

  const setStatus = (status: Service0900InputStatusEnum) => {
    return mutation({
      variables: { status },
      update: (cache, { data }) => {
        const status = data?.model?.service0900?.setStatus?.status || undefined;
        const cachedData = cache.readQuery({ query: QUERY_MODEL_SERVICE0900_TOPBAR });
        const mergedData = mergeDeep(cachedData, { model: { service0900: { status } } });
        cache.writeQuery({
          query: QUERY_MODEL_SERVICE0900_TOPBAR,
          data: mergedData,
        });
      },
      optimisticResponse: ({ status }) => ({
        __typename: 'Mutation',
        model: {
          __typename: 'ModelMutation',
          service0900: {
            __typename: 'Service0900Mutation',
            setStatus: {
              __typename: 'Service0900',
              status,
            },
          },
        },
      }),
    });
  };

  return [setStatus, mutationResult];
};
