import { _ } from '../../util/translate';
import { Option } from './Select';

export const translateOptions = (options: Option[]) => {
  return options.map((opt) => ({
    ...opt,
    label: _(opt.label),
  }));
};

export const sortOptions = (options: Option[], sortAsc = true) => {
  const sortedOptions = [...options];

  return sortAsc
    ? sortedOptions.sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
    : sortedOptions.sort((a, b) => {
        return -1 * a.label.localeCompare(b.label);
      });
};
