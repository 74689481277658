import React, { FC } from 'react';
import styled from '@emotion/styled';
import Footer from './Footer/Footer';
import { BREAKPOINT_TABLET, DARK_GRAY, WHITE_2 } from '../../../../camtool-styles';
import { LeftMenuRootContextProvider } from './context';
import { leftMenuWidth } from './constants';

const StyledNav = styled.aside`
  background-color: ${DARK_GRAY};
  color: ${WHITE_2};
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 48px;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 19;
  padding: 6px 0;
  width: ${leftMenuWidth}px;

  ${BREAKPOINT_TABLET} {
    width: auto;
  }
`;

const NavInner = styled.div`
  flex-direction: column;
  flex: 0 0 auto; // Prevents the menu from collapsing on macOS Safari
`;

const LeftMenuRoot: FC = ({ children }) => (
  <LeftMenuRootContextProvider>
    <StyledNav>
      <NavInner>{children}</NavInner>

      <Footer />
    </StyledNav>
  </LeftMenuRootContextProvider>
);

export default LeftMenuRoot;
