import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { publish } from '../../../util/PubSub';
import { GRAY_2 } from '../../../camtool-styles';
import { _ } from '../../../util/translate';

const LogOut: FC = () => {
  return (
    <div
      css={{
        marginBottom: '50px',
        alignItems: 'center',
        cursor: 'pointer',
        color: GRAY_2,
        display: 'flex',
      }}
      onClick={(): void => publish('session.close', {})}
    >
      <Box mr={1}>
        <PowerSettingsNewIcon />
      </Box>
      <span css={{ color: GRAY_2, fontSize: '14px' }}>{_('navigation:main.logout')}</span>
    </div>
  );
};

export default LogOut;
