import { Box } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import NewButton from '../../../../../atoms/Button/NewButton';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import Icon from '../../../../../atoms/Icon/Icon';
import { plusSign } from '../../../../../atoms/Icon/libraries/glyph';
import { Spinner } from '../../../../../components';
import { _ } from '../../../../../util/translate';
import { stripSlash } from '../../../../../util/urlHelper';
import { useTelegramGetAutoMails } from '../../../../Telegram/hooks';
import { MobileUserLifeCycleStep } from '../types';
import {
  getInitialDummyEntry,
  MAX_LIFECYCLE_LENGTH,
  sortMobileAutoMailList,
  transformMobileLifeCycleForOverview,
} from '../utils';
import AutoMailGrid from './AutoMailGrid';

interface AutoMailGridControllerProps {
  isVip: boolean;
}

const AutoMailGridController: FC<AutoMailGridControllerProps> = ({ isVip }) => {
  const { url } = useRouteMatch();
  const path = stripSlash(url);
  const history = useHistory();
  const { loading, error, data } = useTelegramGetAutoMails(isVip);
  const initialDummyEntry = getInitialDummyEntry(isVip) as MobileUserLifeCycleStep[];

  const [lifecycles, setLifecycles] = useState<MobileUserLifeCycleStep[]>(initialDummyEntry);

  useEffect(() => {
    if (!loading && data) {
      setLifecycles(
        sortMobileAutoMailList(transformMobileLifeCycleForOverview(data?.master?.userLifeCycle))
      );
    }
  }, [loading, data]);

  const addNewLifeCycle = () => {
    // navigate to 'create lifecycle' route with clean slate (therefore no id -> null)
    history.push(`${path}/null`);
  };

  if (loading) return <Spinner />;
  if (error) return <GenericEmptyContent />;

  return (
    <Box display="flex" flexDirection="column" gridGap={'30px'}>
      <AutoMailGrid lifecycles={lifecycles} />
      {lifecycles?.length < MAX_LIFECYCLE_LENGTH ? (
        <Box mb={4} display="flex" justifyContent="center" position="sticky" bottom="29px">
          <NewButton
            style={{ width: '100%', minHeight: '60px' }}
            icon={<Icon icon={plusSign} size={'14px'} />}
            onClick={addNewLifeCycle}
          >
            <span css={{ fontWeight: 'bold', fontSize: '20px' }}>
              {_('mailings:telegram.autoMail.addMessage')}
            </span>
          </NewButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default AutoMailGridController;
