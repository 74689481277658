import React, { FC } from 'react';
import styled from '@emotion/styled';
import GenericModalBoxLayout from '../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { FileDescriptor, PreviewFile } from '../../PreviewFile';
import { modalboxMargin } from '../../../../components/Modalbox/Modalbox';

const padding = 50;

const Container = styled.div`
  flex-direction: column;
  padding: ${padding}px;
  flex: 0 0 auto;
  height: calc(100vh - ${2 * modalboxMargin}px);
`;

interface Props {
  file: FileDescriptor;
  onClose: () => void;
}

const ModalPreviewFile: FC<Props> = ({ file, onClose }) => {
  // Stop event propagation to file upload label
  const stopEventPropagation = (e: React.MouseEvent): void => e.stopPropagation();

  return (
    <GenericModalBoxLayout name="preview-file" maxWidth={1134} onClose={(): void => onClose()}>
      <Container onClick={stopEventPropagation}>
        <PreviewFile
          file={file}
          imageOptions={{ backgroundBlur: false }}
          videoOptions={{ autoPlay: true }}
        />
      </Container>
    </GenericModalBoxLayout>
  );
};

export default ModalPreviewFile;
