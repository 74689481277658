import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { _ } from '../../../../../util/translate';
import { FormikErrors } from 'formik';
import { NewInputField } from '../../../../../atoms';
import BuildValidationObject from '../../../../../util/BuildValidationObject';

interface AutoMailGridFormItemTextValidationProps {
  errorMessage: string | FormikErrors<any> | undefined;
  value: string;
  changeText: (text: string) => void;
}

const AutoMailGridFormItemTextValidation: FC<AutoMailGridFormItemTextValidationProps> = ({
  errorMessage,
  value,
  changeText,
}) => {
  const validation = BuildValidationObject('error', errorMessage as string);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography variant="caption">
        {_('telegram:BroadcastArchive.sectionTitle.archiveMessage')}
      </Typography>
      <NewInputField
        value={value}
        validation={validation}
        multiline={true}
        rows={6}
        onChange={(e) => {
          changeText(e.target.value);
        }}
        type="text"
        fullWidth={true}
        placeholder={_('atom:TextEditor.text.writeMessage')}
      />
    </Box>
  );
};

export default AutoMailGridFormItemTextValidation;
