import React from 'react';
import PropTypes from 'prop-types';
import RankingListItem from './RankingListItem/RankingListItem';
import { PropContestToplistEntry } from './RankingListItem/RankingListItem';

export const PropContestToplist = PropTypes.arrayOf(PropTypes.shape(PropContestToplistEntry));

const RankingList = props => {
  const list = props.list
    .slice(0)
    .sort((a, b) => a.rank - b.rank)
    .slice(0, props.showMax);

  return (
    <div className="ranking-list">
      <div className="grid__box__column">
        {list.map(item => (
          <RankingListItem key={item.rank + '_' + item.userId} {...item} />
        ))}
      </div>
    </div>
  );
};

RankingList.propTypes = {
  list: PropContestToplist,
  showMax: PropTypes.number,
};

RankingList.defaultProps = {};

export default RankingList;
