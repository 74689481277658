import React from 'react';
import { Spinner } from '../index';

const SpinnerInline = (props) => (
  <div className="spinner-container" style={{ display: 'inline-block !important', minWidth: 20 }}>
    <Spinner {...props} size={undefined} />
  </div>
);

export default SpinnerInline;
