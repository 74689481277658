import { Dispatch } from 'react';
import ApolloClient from 'apollo-client';
import { FormData } from '../../graphql/VXModels/types';

export type BenefitsWizardRoute =
  | 'STEP_VOUCHER_TYPE'
  | 'STEP_VOUCHER_CONTENT'
  | 'STEP_VOUCHER_DETAILS'
  | 'STEP_VOUCHER_DONE';

export type VoucherType =
  | 'MEDIA'
  | 'VIDEO'
  | 'PHOTOS'
  | 'MESSAGES'
  | 'CHAT_MINUTES'
  | 'SHOW_TICKET'
  | 'VXPAGES_CREDITS'
  | 'TICKET_SHOW';

export type CustomerGroup =
  | 'allCustomers'
  | 'newCustomers'
  | 'regularCustomers'
  | 'activeCustomers'
  | 'noCreditCustomers';

export type IText = { [key in 'de' | 'en']: string };

/*export interface IText {
  de?: string;
  en?: string;
}*/

export interface IVoucher {
  type: VoucherType;
  title: string;
  description: string;
}

/** VoucherCreate component */
export interface IBenefitsCreateState {
  history: BenefitsWizardRoute[];
  voucherContent: unknown[];
  currentVoucherType?: VoucherType;
  code?: string;
  name?: IText;
  description?: IText;
  targetGroups?: CustomerGroup[];
  validityDateRange?: [string, string]; // [from, to]
  voucherUseMax?: number;
  voucherUseUnlimited?: boolean;
  platforms: string[];
  config: {
    media: { max: number };
  };
}

export interface IBenefitsCreateAction {
  payload: unknown;
  type:
    | 'ADD_VOUCHER_CONTENT'
    | 'REMOVE_VOUCHER_CONTENT'
    | 'SET_CURRENT_VOUCHER_TYPE'
    | 'CHANGE_EXISTING_BENEFIT_VALUES'
    | 'CREATE_VOUCHER'
    | 'GO_TO_STEP_VOUCHER_TYPE'
    | 'GO_TO_STEP_VOUCHER_CONTENT'
    | 'GO_TO_STEP_VOUCHER_DETAILS'
    | 'GO_TO_STEP_VOUCHER_DONE'
    | 'GO_BACK'
    | 'CREATE_NEW_VOUCHER';
}

/** StepVoucherContent component */
export interface IStepVoucherContentPros {
  state: IBenefitsCreateState;
  dispatch: Dispatch<IBenefitsCreateAction>;
  className?: string;
}

// PreviewItem prop
export interface IPreviewItemProps {
  content: { contentData: IMediaAlbum };
  onClick: (content: unknown) => void;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: object;
}

/** StepVoucherContent component */
export interface IContentOthersProps extends IStepVoucherContentPros {
  className?: string;
  availableContentMinAmountsArray?: Array<FormData>;
}

export interface IMediaAlbum {
  id: number;
  isVideoAlbum: boolean;
  numberOfReleasedPictures: number;
  price: number;
  titles: Array<{ language: string; text: string }>;
  created: string;
  previewPicture16: { url: string };
  previewPicture18: { url: string };
  video: { duration: number };
  status: MediaStatus;
}

export enum MediaStatus {
  Online = 'online',
}

export interface IQueryData {
  model: {
    photoAlbums?: {
      albumsTotal: number;
      albums: IMediaAlbum[];
    };
    videos?: {
      albumsTotal: number;
      albums: IMediaAlbum[];
    };
  };
}

export enum BenefitsTypes {
  Media = 'media',
  VoucherVideo = 'voucherVideo',
  VoucherPhoto = 'voucherPhoto',
  FreeMessages = 'freeMessages',
  FreeMinutes = 'freeMinutes',
}

/** StepVoucherDetails component */
export interface IStepProps {
  state: IBenefitsCreateState;
  dispatch: Dispatch<IBenefitsCreateAction>;
  className?: string;
  bonusCodeClient: ApolloClient<Record<string, never>>;
  availableContentMinAmountsArray?: Array<FormData>;
}

/** VoucherTypeButton component */
export interface IVoucherTypeButtonProps {
  type: VoucherType;
  title: string;
  description: string;
  onClick: (voucherType: VoucherType) => void;
  className?: string;
}

/** BreadCrumb components */
export interface IBreadCrumbProps {
  currentRoute: BenefitsWizardRoute;
}

/** Form data */
export interface IFormData {
  /** The group of users that will be able to use the voucher */
  targetGroups: CustomerGroup[];
  /** Voucher code. It can contain uppercase letters, numbers and dashes. But cannot start with a dash */
  code: string;
  /** Voucher name in German and in English  */
  name?: IText;
  /** Voucher description in German and in English  */
  description?: IText;
  /** Whether the voucher can be used by an unlimited number of users or not */
  voucherUseUnlimited: boolean;
  /** Number or users allowed to use the voucher */
  voucherUseMax: number;
  /** The date range within the voucher is valid: [from, until]. ['', ''] means: it does not expire */
  validityDateRange: [string, string];
}

export enum CodeStatus {
  Active = 'active',
  Inactive = 'inactive',
  Planned = 'planned',
  None = 'none',
}

export interface Redemption {
  usageId: number;
  user: { id: string; name: string };
  date: Date;
  pfmId: number;
}

export interface Code {
  redemptions: {
    items: Array<Redemption>;
  };
  code: string;
  status: CodeStatus;
  valid: boolean;
  redeemCount: number;
  config: {
    id: number;
    validFrom: string;
    validTo: string;
    redeemCount: number;
    limitInstance: number;
    texts: Array<{ type: 'codeName' | 'codeDescription'; language: 'de' | 'en'; value: string }>;
    benefits: [
      {
        type: BenefitsTypes;
        __typename: string;
      }
    ];
    conditions: {
      firstPayment: boolean;
      platforms: Array<{
        pfmId: number;
        pfmSubref?: string;
      }> | null;
    };
  };
}

export type RedemptionType = 'GRATIS' | 'ON_TOP' | '';
export type RedemptionAmount = number;
