import React from 'react';
import AbstractFormSingleOptionsComponent from '../AbstractFormSingleOptionComponent';
import PropTypes from 'prop-types';

class OptionBoxOnOff extends AbstractFormSingleOptionsComponent {
  isChecked() {
    return this.getValue() === this.props.valueOn;
  }

  render() {
    const { displaySelectedOptionOnly } = this.props;

    const isChecked = this.isChecked();
    const isOff = this.getValue() === this.props.valueOff;

    return (
      <div className={`option-box-onoff-std ${this.getClassNames()}`}>
        {(!displaySelectedOptionOnly || !isOff) && (
          <div className={this.props.buttonContainer}>
            <input
              type="radio"
              name={this.props.name}
              value={this.props.valueOn}
              id={`${this.props.name}-${this.props.valueOn}`}
              checked={isChecked}
              onChange={this.onChange}
              disabled={this.props.disabled}
            />
            <label htmlFor={`${this.props.name}-${this.props.valueOn}`} className="on">
              <div className="textbox">
                <div className="label">{this.props.labelOn}</div>
              </div>
            </label>
          </div>
        )}

        {(!displaySelectedOptionOnly || !isChecked) && (
          <div className={this.props.buttonContainer}>
            <input
              type="radio"
              name={this.props.name}
              value={this.props.valueOff}
              id={`${this.props.name}-${this.props.valueOff}`}
              checked={isOff}
              onChange={this.onChange}
              disabled={this.props.disabled}
            />
            <label htmlFor={`${this.props.name}-${this.props.valueOff}`} className="off">
              <div className="textbox">
                <div className="label">{this.props.labelOff}</div>
              </div>
            </label>
          </div>
        )}
      </div>
    );
  }
}

OptionBoxOnOff.propTypes = {
  ...AbstractFormSingleOptionsComponent.propTypes,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  valueOn: PropTypes.string,
  valueOff: PropTypes.string,
  disabled: PropTypes.bool,
  displaySelectedOptionOnly: PropTypes.bool,
};

OptionBoxOnOff.defaultProps = {
  ...AbstractFormSingleOptionsComponent.defaultProps,
  labelOn: 'on',
  labelOff: 'off',
  valueOn: 'on',
  valueOff: 'off',
  disabled: false,
  displaySelectedOptionOnly: false,
};

export default OptionBoxOnOff;
export { OptionBoxOnOff };
