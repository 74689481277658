import {
  Service0900,
  PhoneVerificationStatusEnum,
  Service0900Country,
  ProfileSexualityEnum,
  Service0900VoiceEnum,
  Service0900TargetNumberMutationResult,
  Mutation,
  Service0900Settings,
} from '../../../graphql/VXModels/types';
import { Service0900PhoneData, Service0900TargetNumberRequestCode } from './types';
import * as Yup from 'yup';
import { _ } from '../../../util/translate';
import { FormikValues } from 'formik';
import { QueryHookOptions } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import { SetStateAction } from 'react';
import AlertsStore from '../../../stores/Alerts/AlertsStore';

export const MAX_CHARACTERS = 1000;

export const transform0900ForFormik = (storeData: Service0900): Service0900PhoneData => {
  const { targetNumber, allowedCountries, settings, status, visitx0900Numbers } = storeData;
  return {
    settings,
    status,
    visitx0900Numbers,
    allowedCountries,
    targetNumber: {
      isLocked: !targetNumber || targetNumber.isLocked,
      verificationStatus: targetNumber?.verificationStatus || PhoneVerificationStatusEnum.unknown,
      countryCallingCode: targetNumber
        ? targetNumber.countryCallingCode
        : (allowedCountries[0] as Service0900Country).countryCallingCode,
      phoneNumber: targetNumber ? targetNumber.phoneNumber : '',
    },
  };
};

export const getRequestValidationSchema = () =>
  Yup.object().shape({
    targetNumber: Yup.object().shape({
      phoneNumber: Yup.string()
        .matches(/^\d+$/, {
          message: _('service0900:error.message.invalid_phone_number'),
          excludeEmptyString: true,
        })
        .required(_('service0900:error.message.invalid_phone_number')),
    }),
  });

export const onRequestSubmit = async (
  values: FormikValues,
  setStatus: (arg: { globalError: string }) => void,
  setFieldError: (field: string, message: string) => void,
  setSubmitting: (isSubmitting: boolean) => void,
  onRequestSuccess: (step: PhoneVerificationStatusEnum) => void,
  savePhoneNumber: (
    options?: QueryHookOptions<Service0900TargetNumberMutationResult>
  ) => Promise<ExecutionResult<Mutation>>,
  setTargetNumber: (arg: SetStateAction<Service0900TargetNumberRequestCode>) => void
): Promise<void> => {
  const { data, errors } = await savePhoneNumber({
    variables: {
      countryCallingCode: values?.targetNumber?.countryCallingCode,
      phoneNumber: values?.targetNumber?.phoneNumber,
    },
  });
  const saveTargetNumberData = data?.model?.service0900?.saveTargetNumber;
  const numberData = saveTargetNumberData?.data;
  const errorMessage = saveTargetNumberData?.errorMessage;

  if (errors) {
    setStatus({
      globalError: _('service0900:PhoneSex.modal.request.errors.saveFailed'),
    });
  } else if (numberData?.verificationStatus === PhoneVerificationStatusEnum.verified) {
    setFieldError(
      'targetNumber.phoneNumber',
      _('service0900:PhoneSex.modal.request.errors.verified')
    );
  } else if (errorMessage) {
    setFieldError('targetNumber.phoneNumber', errorMessage);
  } else {
    setTargetNumber({
      phoneNumber: values?.targetNumber?.phoneNumber,
      countryCallingCode: values?.targetNumber?.countryCallingCode,
    });
    onRequestSuccess(numberData?.verificationStatus as PhoneVerificationStatusEnum);
  }
  setSubmitting(false);
};

export const onVerifySubmit = async (
  values: FormikValues,
  setStatus: (status?: any) => void,
  setFieldError: (field: string, message: string) => void,
  onSuccess: () => void,
  closeModal: () => void,
  verifyPhoneNumber: (
    options?: QueryHookOptions<Service0900TargetNumberMutationResult>
  ) => Promise<ExecutionResult<Mutation>>
): Promise<void> => {
  const { data, errors } = await verifyPhoneNumber({
    variables: {
      verificationCode: values?.verificationCode,
    },
  });
  const errorMessage = data?.model?.service0900?.verifyTargetNumber?.errorMessage;
  if (errors) {
    setStatus({
      globalError: _('service0900:PhoneSex.modal.verify.errors.verifyFailed'),
    });
  } else if (errorMessage) {
    setFieldError('verificationCode', errorMessage);
  } else {
    onSuccess();
    closeModal();
  }
};

export const getSettingsValidationSchema = () => {
  const getSexualityRegExp = (): RegExp =>
    new RegExp(
      `${ProfileSexualityEnum.heterosexual}|${ProfileSexualityEnum.homosexual}|${ProfileSexualityEnum.bisexual}`
    );

  const getVoiceRegExp = (): RegExp =>
    new RegExp(`${Service0900VoiceEnum.female}|${Service0900VoiceEnum.male}`);

  return Yup.object().shape({
    settings: Yup.object().shape({
      voice: Yup.string().matches(getVoiceRegExp()),
      sexuality: Yup.string().matches(getSexualityRegExp()).required(),
      description: Yup.object().shape({
        languages: Yup.array().of(Yup.string()),
        texts: Yup.array().of(
          Yup.object().shape({
            language: Yup.string(),
            text: Yup.string().max(MAX_CHARACTERS, _('common:alerts.characterLimit.exceeded')),
          })
        ),
      }),
    }),
  });
};

export const onSettingsSubmit = async (
  values: FormikValues,
  saveSettings: (
    options?: QueryHookOptions<Service0900Settings>
  ) => Promise<ExecutionResult<Mutation>>,
  setSubmitting: (isSubmitting: boolean) => void
): Promise<void> => {
  const { errors } = await saveSettings({
    variables: {
      voice: values?.settings?.voice,
      sexuality: values?.settings?.sexuality,
      description: [
        {
          language: values?.settings?.description?.texts?.[0]?.language,
          text: values?.settings?.description?.texts?.[0]?.text,
        },
      ],
    },
  });
  if (errors) {
    AlertsStore.add({ type: 'error', message: _('common:alerts.profileSaveFail') });
  } else {
    AlertsStore.add({ type: 'info', message: _('common:info.successfullySaved') });
  }
  setSubmitting(false);
};
