import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useDisplayState } from '../../../../hooks';
import { FileDescriptor, PreviewFile, PreviewVideoThumbnailIconSize } from '../../PreviewFile';
import { ModalConfirmDelete } from '../ModalConfirmDelete';
import { ModalEditFile } from '../ModalEditFile';
import { ModalPreviewFile } from '../ModalPreviewFile';
import getAudioOptions from './getAudioOptions';
import getVideoOptions from './getVideoOptions';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: column;
  cursor: pointer;
`;

interface Props {
  file: FileDescriptor;
  isEditable: boolean;
  videoThumbnailIconSize?: PreviewVideoThumbnailIconSize;
  onRemoveFile: () => void;
  onReuploadFile: () => void;
}

const Preview: FC<Props> = ({
  file,
  videoThumbnailIconSize,
  isEditable,
  onRemoveFile,
  onReuploadFile,
}) => {
  const {
    shouldShow: shouldShowEditModal,
    show: showEditModal,
    hide: hideEditModal,
  } = useDisplayState();

  const {
    shouldShow: shouldShowPreviewModal,
    show: showPreviewModal,
    hide: hidePreviewModal,
  } = useDisplayState();

  const {
    shouldShow: shouldShowConfirmDeleteModal,
    show: showConfirmDeleteModal,
    hide: hideConfirmDeleteModal,
  } = useDisplayState();

  const onClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    // Stop propagation to file input label
    e.preventDefault();
    e.stopPropagation();

    if (isEditable) showEditModal();
    else showPreviewModal();
  };

  return (
    <Container onClick={onClick}>
      <PreviewFile
        file={file}
        videoOptions={getVideoOptions(videoThumbnailIconSize)}
        audioOptions={getAudioOptions()}
      />

      {shouldShowEditModal && (
        <ModalEditFile
          onClose={hideEditModal}
          onRemoveFile={showConfirmDeleteModal}
          onReuploadFile={onReuploadFile}
          onPreviewFile={showPreviewModal}
        />
      )}

      {shouldShowPreviewModal && <ModalPreviewFile file={file} onClose={hidePreviewModal} />}

      {shouldShowConfirmDeleteModal && (
        <ModalConfirmDelete onClose={hideConfirmDeleteModal} onConfirmDelete={onRemoveFile} />
      )}
    </Container>
  );
};

export default Preview;
