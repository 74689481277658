import _ from 'lodash';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import { MessageList, Query } from '../../../graphql/VXServicesTelegram/types';
import { QUERY_MASTER_USER_GROUPED_CHAT } from '../graphql/queries';
import { useVXServicesTelegramClient } from '../../../graphql';
import { useVXServicesTelegramContext } from './index';

interface IUserChannel {
  userId: number;
  limit?: number;
  lastMessageId?: number | null;
}

export const useTelegramUserMessages = (
  { userId, lastMessageId = null, limit = 50 }: IUserChannel,
  options?: QueryHookOptions<MessageList & { initial: boolean }, IUserChannel>
) => {
  const { token } = useVXServicesTelegramContext();
  const client = useVXServicesTelegramClient();
  const { data, ...rest } = useQuery(QUERY_MASTER_USER_GROUPED_CHAT, {
    ...options,
    client,
    variables: { token, userId, lastMessageId, limit },
  });

  const { data: cachedData } = useQuery<Query>(QUERY_MASTER_USER_GROUPED_CHAT, {
    ...options,
    client,
    fetchPolicy: 'cache-only',
    pollInterval: undefined,
  });

  const initial = !_.isEqual(data, cachedData);

  const groupedChat = data?.master?.user?.groupedChat;

  return { data: groupedChat, ...rest, initial };
};

export default useTelegramUserMessages;
