import { EventEmitter } from 'events';
import * as ActionTypes from '../../util/constants/ActionTypes';
import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import cloneDeep from 'lodash/cloneDeep';
import { _ } from '../../util/translate';

const CHANGE_EVENT = 'AlertsStore.change';

let _photoGalleryData = {
  photos: [],
  isGalleryOpen: false,
};

class PhotoGalleryStoreClass extends EventEmitter {
  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  get() {
    return {
      photos: cloneDeep(_photoGalleryData.photos),
      isGalleryOpen: cloneDeep(_photoGalleryData.isGalleryOpen),
    };
  }

  openPhotoGallery(data) {
    _photoGalleryData = {
      photos: data,
      isGalleryOpen: true,
    };

    this.emitChange();
  }

  closePhotoGallery() {
    _photoGalleryData = {
      photos: [],
      isGalleryOpen: false,
    };

    this.emitChange();
  }

  setIsGalleryOpen(isGalleryOpen) {
    _photoGalleryData = {
      photos: this.get().photos,
      isGalleryOpen: isGalleryOpen,
    };

    this.emitChange();
  }
}

const PhotoGalleryStore = new PhotoGalleryStoreClass();

export default PhotoGalleryStore;
export { PhotoGalleryStore };
