import { makeStyles, createStyles } from '@material-ui/core';
import { GRAY_4 } from '../../../../../camtool-styles';
import { VXGAMES_PRIMARY, VXGAMES_GRADIENT, VXGAMES_GREY } from '../../../utils';
import { competition_completed_icon } from '../../../Assets/';

export const useProgressBarStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '33px',
      pointerEvents: 'none',
    },
    rail: {
      height: '33px',
      color: VXGAMES_GREY,
      borderRadius: '25px',
      opacity: 1,
    },
    track: {
      height: '33px',
      borderRadius: '25px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      background: VXGAMES_GRADIENT,
    },
    valueLabel: {
      color: GRAY_4,
      whiteSpace: 'nowrap',
      background: 'transparent',
      left: 'calc(-50% + 12px)',
      top: -18,
      fontSize: '12px',
      '& *': {
        background: 'transparent',
        color: GRAY_4,
      },
    },
    thumb: {
      height: '33px',
      width: '33px',
      marginTop: '0',
      transform: 'translateX(-10px)',
      color: 'unset',
      background: VXGAMES_GRADIENT,

      '&[style="left: 0%;"]': {
        transform: 'translateX(0)',
      },
      '&[style="left: 100%;"]': {
        backgroundColor: VXGAMES_PRIMARY,
        backgroundImage: `url(${competition_completed_icon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '75%',
        backgroundPosition: '4px 4px',
      },
    },
    mark: {
      bottom: '13px',
      // MUI sets extra padding on touch devices with pointer: coarse media query - therefore repositioning
      '@media (pointer: coarse)': {
        bottom: '20px',
      },
      width: '0px',
      height: '0px',
      // down arrow styles
      '&::after': {
        content: '""',
        width: 0,
        height: 0,
        border: 0,
        position: 'absolute',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: `9px solid ${VXGAMES_GREY}`,
        transform: 'rotate(180deg) translateX(30%)',
      },
      // style last arrow element to a thumb placeholder
      '&[style="left: 100%;"]::after': {
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        top: '-30px',
        left: '-20px',
        border: `1px solid ${VXGAMES_PRIMARY}`,
      },
    },
    markLabel: {
      top: '60px',
      color: GRAY_4,
      fontSize: '15px',
      '&[style="left: 100%;"]': {
        color: VXGAMES_PRIMARY,
      },
    },
    markActive: {
      '&::after': {
        borderBottomColor: VXGAMES_PRIMARY,
      },
    },
  })
);
