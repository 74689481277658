import gql from 'graphql-tag';
import { FRAGMENT_USER } from '../fragments';

export const QUERY_MASTER = gql`
  query VXM_CL_QUERY_MASTER($token: String!) {
    master(token: $token) {
      actorName
      unreadTips
      unreadTippingAmount
      countVipUser
      countUser
      currency
      channelInviteUrl
      channelExists
    }
  }
`;
export const QUERY_MASTER_USER = gql`
  query VXM_CL_QUERY_MASTER_USER($token: String!, $id: Int!) {
    master(token: $token) {
      user(id: $id) {
        ...User
      }
      currency
    }
  }
  ${FRAGMENT_USER}
`;
export const QUERY_MASTER_USERLIST = gql`
  query VXM_CL_QUERY_MASTER_USERLIST(
    $token: String!
    $filter: UserFilter
    $order: UsersOrder = relevance
    $limit: Int = 50
    $offset: Int = 0
  ) {
    master(token: $token) {
      userList(filter: $filter, order: $order, limit: $limit, offset: $offset) {
        limit
        offset
        total
        count
        users {
          ...User
        }
      }
      # to show tippings of user in correct currency
      currency
    }
  }
  ${FRAGMENT_USER}
`;
