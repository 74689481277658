import React, { Fragment } from 'react';
import AbstractProfile from '../../AbstractProfile';
import { PROFILE_LA } from '../../../../stores/Profile/Profile';

import NavSubLA from '../NavSubLA';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Footer,
  Form,
  GroupRow,
  Markdown,
  Separator,
  Spinner,
  T,
} from '../../../../components';

import { _ } from '../../../../util/translate';

class Settings extends AbstractProfile {
  getResource() {
    return PROFILE_LA;
  }

  getInputNames() {
    return [
      'laSettingsActionDatesActive',
      'laSettingsAutomessagingActive',
      'laSettingsLiveDatesActive',
      'laSettingsProfileActive',
    ];
  }

  render() {
    super.render();
    return (
      <main id="la-profile-settings" className="la-profile-settings grid">
        {this.storeValues.laSettingsProfileActive && <NavSubLA />}

        <section className="grid__row">
          <section className="grid__column grid__box min-width--0">
            <header className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'profiles:la.settings.pagetitle.label'} />
              </div>
            </header>

            {!this.showPartnerFormVisible && (
              <div className="grid__box__item">
                <div className="grid__box__item__content">
                  <div className="warning-text">
                    <div className="warning-text__column">
                      <div className="title">Info:</div>
                      <Markdown
                        className="subtitle"
                        source={_('profiles:la.settings.info.text1')}
                      />
                      <Markdown
                        className="subtitle"
                        source={_('profiles:la.settings.info.text2')}
                      />
                      <Markdown
                        className="subtitle"
                        source={_('profiles:la.settings.info.text3')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Form
              className="grid__box__item spinner-container"
              ref="form"
              onValidSubmit={this.submitForm}
              onInvalidSubmit={this.showErrorFields}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              onChange={this.onChange}
            >
              {this.state.isLoading && <Spinner size="m" />}

              <div className="grid__box__item__content la-profile__formwidth">
                <GroupRow
                  tooltipText={_('profiles:la.settings.formfield.profileActive.tooltip')}
                  tooltipPosition="right"
                >
                  <Checkbox
                    name="laSettingsProfileActive"
                    label={_('profiles:la.settings.formfield.profileActive.label')}
                    value={this.storeValues.laSettingsProfileActive}
                  />
                </GroupRow>

                {this.currentValues.laSettingsProfileActive && (
                  <Fragment>
                    <GroupRow
                      tooltipText={_('profiles:la.settings.formfield.automessagingActive.tooltip')}
                      tooltipPosition="right"
                    >
                      <Checkbox
                        name="laSettingsAutomessagingActive"
                        label={_('profiles:la.settings.formfield.automessagingActive.label')}
                        value={this.storeValues.laSettingsAutomessagingActive}
                      />
                    </GroupRow>
                    <GroupRow
                      tooltipText={_('profiles:la.settings.formfield.liveDatesActive.tooltip')}
                      tooltipPosition="right"
                    >
                      <Checkbox
                        name="laSettingsLiveDatesActive"
                        label={_('profiles:la.settings.formfield.liveDatesActive.label')}
                        value={this.storeValues.laSettingsLiveDatesActive}
                      />
                    </GroupRow>
                    <GroupRow
                      tooltipText={_('profiles:la.settings.formfield.actionDatesActive.tooltip')}
                      tooltipPosition="right"
                    >
                      <Checkbox
                        name="laSettingsActionDatesActive"
                        label={_('profiles:la.settings.formfield.actionDatesActive.label')}
                        value={this.storeValues.laSettingsActionDatesActive}
                      />
                    </GroupRow>
                  </Fragment>
                )}

                <GroupRow tooltip="none">
                  <Separator className="transparent" />
                </GroupRow>

                <ButtonGroup position="right">
                  <Button
                    label={_('common:button.cancel')}
                    onClick={this.rejectChanges}
                    type="button"
                    disabled={!this.activateResetButton}
                  />
                  <Button className="button--blue" label={_('common:button.save')} type="submit" />
                </ButtonGroup>
              </div>
            </Form>
          </section>
        </section>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default Settings;
