import {
  Action,
  ActionEnum,
  FileWrapper,
  FileWrapperPayload,
  MediaTypeEnum,
  StateEnum,
} from '../../types';
import { verifyPictureMd5hash, verifyVideoMd5hash } from '../../util';
import {
  dispatch,
  getState,
  triggerUpdateQueue,
  updateFileWrapper,
  updateQueueItem,
} from '../UploadManagerStore';

async function handlePictureMd5hashCalculated(fileWrapper: FileWrapper): Promise<void> {
  const { userData } = getState();
  const { id, md5hash } = fileWrapper;
  const { errorMessage, verified } = await verifyPictureMd5hash(userData, md5hash as string);
  const nextFileWrapper = { ...fileWrapper, verified, errorMessage };
  updateFileWrapper(nextFileWrapper);
  updateQueueItem({ id, md5hash, state: !errorMessage ? StateEnum.waiting : StateEnum.error });
  dispatch(ActionEnum.verified, { fileWrapper: nextFileWrapper });
}

async function handleVideoMd5hashCalculated(fileWrapper: FileWrapper): Promise<void> {
  const { userData } = getState();
  const { id, md5hash } = fileWrapper;
  const { errorMessage, verified } = await verifyVideoMd5hash(userData, md5hash as string);
  const nextFileWrapper = { ...fileWrapper, verified, errorMessage };
  updateFileWrapper(nextFileWrapper);
  updateQueueItem({ id, md5hash, state: !errorMessage ? StateEnum.waiting : StateEnum.error });
  dispatch(ActionEnum.verified, { fileWrapper: nextFileWrapper });
}

export async function handleMd5hashCalculated({ type, payload }: Action): Promise<void> {
  if (type === ActionEnum.md5hashCalculated) {
    const { fileWrapper } = payload as FileWrapperPayload;
    const { id, md5hash, mediaType } = fileWrapper;

    if (md5hash) {
      if (fileWrapper.verified === undefined) {
        switch (mediaType) {
          case MediaTypeEnum.picture:
            return await handlePictureMd5hashCalculated(fileWrapper);
          case MediaTypeEnum.video:
            return await handleVideoMd5hashCalculated(fileWrapper);
          default:
            return new Promise<void>(() => updateQueueItem({ id, state: StateEnum.waiting }));
        }
      } else {
        updateQueueItem({
          id,
          md5hash,
          state: !fileWrapper.errorMessage ? StateEnum.waiting : StateEnum.error,
        });
        dispatch(ActionEnum.verified, { fileWrapper });
      }
      triggerUpdateQueue();
    }
  }
}
