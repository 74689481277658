import React, { FC } from 'react';

import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createStyles, lighten, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GRAY_2 } from '../../camtool-styles';

type LinearProgressWithLabelProps = Omit<LinearProgressProps, 'color'> & {
  value: number;
  color: 'primary' | 'secondary' | 'warning' | 'error' | string;
  showText: boolean;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: ({ color }: LinearProgressWithLabelProps) => ({
      backgroundColor: theme.palette[color]
        ? lighten(theme.palette[color].main, 0.8)
        : lighten(color, 0.8),
      borderRadius: '2px',
    }),
    bar: ({ color }: LinearProgressWithLabelProps) => ({
      backgroundColor: theme.palette[color] ? theme.palette[color].main : color,
    }),
    text: {
      color: GRAY_2,
    },
  });
});

const LinearProgressWithLabel: FC<LinearProgressWithLabelProps> = ({
  color,
  showText = true,
  variant,
  ...props
}) => {
  const classes = useStyles({ color });

  return (
    <Box display="flex !important" alignItems="center">
      <Box width="100%" mr={0.5}>
        <LinearProgress
          classes={{ root: classes.root, bar: classes.bar }}
          variant={variant}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        {showText && variant !== 'indeterminate' ? (
          <Typography className={classes.text} variant="body2">{`${Math.round(
            props.value
          )}%`}</Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
