import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { DARK_GRAY, WHITE } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';

const PaidContentStamp: FC = () => {
  return (
    <Box
      position="absolute"
      left="10px"
      top="10px"
      display="flex"
      alignItems="center"
      gridGap="3px"
      p="4px 6px"
      borderRadius="10px"
      bgcolor={fade(WHITE, 0.6)}
      color={DARK_GRAY}
    >
      <LockIcon fontSize="small" />
      <span>{_('telegram:chat.message.buyContent')}</span>
    </Box>
  );
};

export default PaidContentStamp;
