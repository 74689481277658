import styled from '@emotion/styled';
import * as React from 'react';
import { BLACK_1, BLUE, BLUE_HOVER, WHITE } from '../../camtool-styles';
import PropTypes from 'prop-types';
import { ReactElement } from 'react';

const StyledInput = styled('input')`
  display: none;
  
  &:disabled {
    & + div {
      color: #e5e5e5;
      border: 1px solid #e5e5e5;
      cursor: default;
      &:hover {
        background-color: ${WHITE};
      }
    }
    &:checked + div {
      color: ${BLACK_1};
      background-color: #e5e5e5;
      &:hover {
        background-color: #e5e5e5;
      }
    }
  }
  
  &:checked {
    & + div {
      background-color: ${BLUE};
      color: #fff;
      &:hover {
        background-color: ${BLUE_HOVER};
      }
    }
`;

const StyledDiv = styled('div')`
  padding: 0 24px;
  background-color: #fff;
  color: ${BLACK_1};
  height: 32px;
  border: 1px solid #dcdcde;
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #e5e5e5;
  }
`;

interface Props {
  children: object;
  checked: boolean;
  className: string;
  defaultChecked: boolean;
  disabled?: boolean;
  id: string;
  label: string;
  name: string;
  value: string;
  render: (text: string) => JSX.Element;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = (props: Props): ReactElement => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.onChange) {
      props.onChange(event);
    }
  };
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  if (props.defaultChecked || !props.onChange) {
    return (
      <label className={props.className}>
        <StyledInput
          type="radio"
          value={props.value}
          name={props.name}
          onBlur={handleBlur}
          id={props.id}
          disabled={props.disabled}
          defaultChecked={props.defaultChecked}
          className={props.className}
        />
        <StyledDiv>{props.children || props.label}</StyledDiv>
      </label>
    );
  }

  return (
    <label className={props.className}>
      <StyledInput
        type="radio"
        value={props.value}
        checked={props.checked}
        onChange={handleChange}
        name={props.name}
        disabled={props.disabled}
        onBlur={handleBlur}
        id={props.id}
        className={props.className}
      />
      <StyledDiv css={{ borderColor: props.checked ? 'transparent' : '#c0c0c0' }}>
        {props.children || props.label}
      </StyledDiv>
    </label>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  checked: false,
  children: null,
  className: '',
  id: '',
  name: '',
  value: '',
  disabled: false,
};

export default RadioButton;
