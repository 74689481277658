import gql from 'graphql-tag';
import {
  FRAGMENT_AUTH_USER,
  FRAGMENT_MAILING,
  FRAGMENT_TERM_ACCEPTANCE,
  FRAGMENT_LOCALIZED_TEXT,
  FRAGMENT_SERVICE_0900_TARGETNUMBER_MUTATIONRESULT,
  FRAGMENT_BA6DATA_MUTATIONRESULT,
  FRAGMENT_GENERIC_RESPONSE,
} from '../fragments';

export const MUTATION_AUTH_LOGIN_V2 = gql`
  mutation MUTATION_AUTH_LOGIN_V2($username: String!, $password: String!) {
    auth {
      login_v2(username: $username, password: $password) {
        ... on LoginResultInterface {
          wasSuccessful
        }
        ... on LoginSuccessResult {
          user {
            ...AuthUser
          }
        }
        ... on LoginInvalidCredentialsResult {
          message
        }
        ... on LoginAccountLockedResult {
          title
          message
          hotlineCode
        }
      }
    }
  }
  ${FRAGMENT_AUTH_USER}
`;

export const MUTATION_AUTH_TOKENAUTH = gql`
  mutation MUTATION_AUTH_TOKENAUTH($token: String!, $scope: AppScope) {
    auth {
      tokenAuth(token: $token, scope: $scope) {
        ...AuthUser
      }
    }
  }
  ${FRAGMENT_AUTH_USER}
`;

export const MUTATION_AUTH_VERIFY = gql`
  mutation MUTATION_AUTH_VERIFY($userId: UserId!, $verificationCode: String!) {
    auth {
      verify(userId: $userId, verificationCode: $verificationCode) {
        status
        data
        error
      }
    }
  }
`;

export const MUTATION_AUTH_SET_PASSWORD = gql`
  mutation ($password: String!, $hash: String) {
    auth {
      setPassword(password: $password, hash: $hash) {
        status
      }
    }
  }
`;

export const MUTATION_MODEL_MEDIA_DELETE_PICTURE = gql`
  mutation deletePicture($albumId: Int!, $pictureId: Int!) {
    model {
      media {
        deletePicture(albumId: $albumId, pictureId: $pictureId) {
          id
        }
      }
    }
  }
`;

export const MUTATION_MODEL_MEDIA_SET_PREVIEW_PICTURE = gql`
  mutation setPreviewPicture($albumId: Int!, $pictureId: Int!, $fsk: Int!) {
    model {
      media {
        setPreviewPicture(albumId: $albumId, pictureId: $pictureId, fsk: $fsk) {
          id
        }
      }
    }
  }
`;

export const MUTATION_MODEL_MEDIA_MOVE_PICTURES_TO_ALBUM = gql`
  mutation movePicturesToAlbum(
    $sourceAlbumId: Int!
    $targetAlbumId: Int!
    $pictures: [MovedPicture]!
  ) {
    model {
      media {
        movePicturesToAlbum(
          sourceAlbumId: $sourceAlbumId
          targetAlbumId: $targetAlbumId
          pictures: $pictures
        ) {
          id
        }
      }
    }
  }
`;

export const MUTATION_MODEL_MAILINGS_SAVE = gql`
  mutation MUTATION_MODEL_MAILINGS_SAVE(
    $id: Int
    $type: MailingTypeEnum!
    $recipientsGroup: MailingRecipientsGroupsEnum!
    $recipientsGroupFilters: [MailingRecipientsGroupsFiltersEnum!]!
    $lang: ApiLang
    $subject: String
    $text: String!
    $textHtml: String
    $template: MailingTemplateEnum
    $published: DateTime
    $attachment: AttachmentInput
    $tipping: Boolean
    $sticky: Boolean
    $price: Float
  ) {
    model {
      mailings {
        save(
          mailing: {
            id: $id
            type: $type
            recipientsGroup: $recipientsGroup
            recipientsGroupFilters: $recipientsGroupFilters
            content: {
              lang: $lang
              subject: $subject
              text: $text
              textHtml: $textHtml
              template: $template
              attachment: $attachment
              tipping: $tipping
              sticky: $sticky
            }
            published: $published
            price: $price
          }
        ) {
          ...Mailing
        }
      }
    }
  }
  ${FRAGMENT_MAILING}
`;

export const MUTATION_MODEL_MAILINGS_PUBLISH = gql`
  mutation MUTATION_MODEL_MAILINGS_PUBLISH(
    $id: Int
    $type: MailingTypeEnum!
    $recipientsGroup: MailingRecipientsGroupsEnum!
    $recipientsGroupFilters: [MailingRecipientsGroupsFiltersEnum!]!
    $lang: ApiLang
    $subject: String
    $text: String!
    $textHtml: String
    $template: MailingTemplateEnum
    $published: DateTime
    $attachment: AttachmentInput
    $tipping: Boolean
    $sticky: Boolean
    $price: Float
  ) {
    model {
      mailings {
        publish(
          mailing: {
            id: $id
            type: $type
            recipientsGroup: $recipientsGroup
            recipientsGroupFilters: $recipientsGroupFilters
            content: {
              lang: $lang
              subject: $subject
              text: $text
              textHtml: $textHtml
              template: $template
              attachment: $attachment
              tipping: $tipping
              sticky: $sticky
            }
            published: $published
            price: $price
          }
        ) {
          ...Mailing
        }
      }
    }
  }
  ${FRAGMENT_MAILING}
`;

export const MUTATION_MODEL_MAILINGS_TEST = gql`
  mutation MUTATION_MODEL_MAILINGS_TEST(
    $id: Int
    $type: MailingTypeEnum!
    $recipientsGroup: MailingRecipientsGroupsEnum!
    $recipientsGroupFilters: [MailingRecipientsGroupsFiltersEnum!]!
    $lang: ApiLang
    $subject: String
    $text: String!
    $textHtml: String!
    $template: MailingTemplateEnum!
    $recipient: String!
    $tipping: Boolean
    $sticky: Boolean
  ) {
    model {
      mailings {
        test(
          mailing: {
            id: $id
            type: $type
            recipientsGroup: $recipientsGroup
            recipientsGroupFilters: $recipientsGroupFilters
            content: {
              lang: $lang
              subject: $subject
              text: $text
              textHtml: $textHtml
              template: $template
              tipping: $tipping
              sticky: $sticky
            }
          }
          recipient: $recipient
        ) {
          success
          message
        }
      }
    }
  }
`;

export const MUTATION_MODEL_TERMS_ACCEPT_V2 = gql`
  mutation MUTATION_MODEL_TERMS_ACCEPT_V2($type: TermsTypeEnum!, $password: String) {
    model {
      terms {
        accept_v2(type: $type, password: $password) {
          wasSuccessful
          isPasswordInvalid
          acceptance {
            ...TermAcceptance
          }
        }
      }
    }
  }
  ${FRAGMENT_TERM_ACCEPTANCE}
`;

export const MUTATION_MODEL_SETTINGS_REQUEST_LOVENSE_TOY = gql`
  mutation MUTATION_MODEL_SETTINGS_REQUEST_LOVENSE_TOY {
    model {
      settings {
        requestLovenseToy {
          success
          data {
            status
          }
        }
      }
    }
  }
`;

export const MUTATION_MODEL_SETTINGS_SETVXMARKETING = gql`
  mutation MUTATION_MODEL_SETTINGS_SETVXMARKETING($active: Boolean!) {
    model {
      settings {
        setVXMarketing(active: $active) {
          ...GenericResponse
        }
      }
    }
  }
  ${FRAGMENT_GENERIC_RESPONSE}
`;

export const MUTATION_MODEL_VXCASH_CREATE_WEBMASTER_ID = gql`
  mutation MUTATION_MODEL_VXCASH_CREATE_WEBMASTER_ID {
    model {
      vxcash {
        createWebmaster {
          status
          data
          error
        }
      }
    }
  }
`;

export const MUTATION_MODEL_MEDIA_SET_DAILY_BONUS_OPT_IN_STATUS = gql`
  mutation MUTATION_MODEL_MEDIA_SET_DAILY_BONUS_OPT_IN_STATUS($status: DailyBonusOptInStatusEnum!) {
    model {
      media {
        setDailyBonusOptInStatus(status: $status)
      }
    }
  }
`;

export const MUTATION_MODEL_INSTANTMESSAGING_REQUESTTELEGRAM = gql`
  mutation MUTATION_MODEL_INSTANTMESSAGING_REQUESTTELEGRAM {
    model {
      instantMessaging {
        requestTelegram
      }
    }
  }
`;

export const MUTATION_MODEL_SERVICE0900_SEND_VERIFICATION_CODE = gql`
  mutation MUTATION_MODEL_SERVICE0900_SEND_VERIFICATION_CODE {
    model {
      service0900 {
        sendVerificationCode {
          ...Service0900TargetNumberMutationResult
        }
      }
    }
  }
  ${FRAGMENT_BA6DATA_MUTATIONRESULT}
`;

export const MUTATION_MODEL_SERVICE0900_SAVE_TARGETNUMBER = gql`
  mutation MUTATION_MODEL_SERVICE0900_SAVE_TARGETNUMBER(
    $countryCallingCode: String!
    $phoneNumber: String!
  ) {
    model {
      service0900 {
        saveTargetNumber(countryCallingCode: $countryCallingCode, phoneNumber: $phoneNumber) {
          ...Service0900TargetNumberMutationResult
        }
      }
    }
  }
  ${FRAGMENT_BA6DATA_MUTATIONRESULT}
`;

export const MUTATION_MODEL_SERVICE0900_VERIFY_TARGETNUMBER = gql`
  mutation MUTATION_MODEL_SERVICE0900_VERIFY_TARGETNUMBER($verificationCode: String!) {
    model {
      service0900 {
        verifyTargetNumber(verificationCode: $verificationCode) {
          ...Service0900TargetNumberMutationResult
        }
      }
    }
  }
  ${FRAGMENT_BA6DATA_MUTATIONRESULT}
`;

export const MUTATION_MODEL_SERVICE0900_SETSTATUS = gql`
  mutation MUTATION_MODEL_SERVICE0900_SETSTATUS($status: Service0900InputStatusEnum!) {
    model {
      service0900 {
        setStatus(status: $status) {
          status
        }
      }
    }
  }
`;

export const MUTATION_MODEL_SERVICE0900_SAVE_SETTINGS = gql`
  mutation MUTATION_MODEL_SERVICE0900_SAVE_SETTINGS(
    $voice: Service0900VoiceEnum
    $sexuality: ProfileSexualityEnum!
    $description: [LocalizedTextInput]
  ) {
    model {
      service0900 {
        saveSettings(voice: $voice, sexuality: $sexuality, description: $description) {
          voice
          sexuality
          description {
            texts {
              ...LocalizedText
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_LOCALIZED_TEXT}
`;

export const MUTATION_MODEL_TOOLTIPSETTINGS_SETSEEN = gql`
  mutation MUTATION_MODEL_TOOLTIPSETTINGS_SETSEEN($type: TooltipType) {
    model {
      tooltipSettings {
        setSeen(type: $type) {
          success
          data {
            hasSeen(type: $type)
          }
        }
      }
    }
  }
`;

export const MUTATION_MODEL_SERVICE0900_ADD_CAROUSEL_NUMBER = gql`
  mutation MUTATION_MODEL_SERVICE0900_ADD_CAROUSEL_NUMBER($phoneNumber: String!) {
    model {
      service0900 {
        addCarouselNumber(phoneNumber: $phoneNumber) {
          ...Service0900PhoneCarouselMutationResult
        }
      }
    }
  }
  ${FRAGMENT_SERVICE_0900_TARGETNUMBER_MUTATIONRESULT}
`;

export const MUTATION_MODEL_SERVICE0900_DELETE_CAROUSEL_NUMBER = gql`
  mutation MUTATION_BA6_DELETE_CARROUSEL_NUMBER($phoneNumber: String!) {
    model {
      service0900 {
        deleteCarouselNumber(phoneNumber: $phoneNumber) {
          ...Service0900PhoneCarouselMutationResult
        }
      }
    }
  }
  ${FRAGMENT_SERVICE_0900_TARGETNUMBER_MUTATIONRESULT}
`;

export const MUTATION_MODEL_SENDWELCOMEMAIL = gql`
  mutation MUTATION_MODEL_SENDWELCOMEMAIL($email: String) {
    model {
      sendWelcomeMail(email: $email) {
        status
        data
        error
      }
    }
  }
`;

export const MUTATION_MODEL_DOCUMENTS_UPDATEDOCUMENTSAFTERUPLOAD = gql`
  mutation MUTATION_MODEL_DOCUMENTS_UPDATEDOCUMENTSAFTERUPLOAD(
    $id: Int!
    $type: DocumentTypeEnum!
    $userId: Int!
    $side: DocumentSideEnum
  ) {
    model {
      documents {
        updateDocumentAfterUpload(id: $id, type: $type, userId: $userId, side: $side) {
          success
          document {
            id
            ownerId
            uploaderId
            type
            status
          }
        }
      }
    }
  }
`;

export const MUTATION_MODEL_DOCUMENTS_SKIPBACKSIDEUPLOAD = gql`
  mutation MUTATION_MODEL_DOCUMENTS_SKIPBACKSIDEUPLOAD($type: DocumentTypeEnum!, $userId: Int!) {
    model {
      documents {
        skipBacksideUpload(type: $type, userId: $userId)
      }
    }
  }
`;

export const MUTATION_MODEL_CHAT_SETTINGS = gql`
  mutation MUTATION_MODEL_CHAT_SETTINGS(
    $videoChatPrice: Float!
    $singleChatPrice: Float!
    $messengerPrice: Float!
    $referrer: String
    $deDescription: String!
    $enDescription: String!
    $heatMapStatus: CommonSwitchStatusEnum!
    $livePreviewStatus: CommonSwitchStatusEnum!
    $voyeurInSingleChatStatus: CommonSwitchStatusEnum!
    $softChatStatus: CommonSwitchStatusEnum!
    $soundForVoyeurInSingleChatStatus: CommonSwitchStatusEnum!
    $freeChatStatus: CommonSwitchStatusEnum!
    $partyChatStatus: CommonSwitchStatusEnum!
  ) {
    model {
      prices {
        savePrices(
          input: {
            videoChatPrice: $videoChatPrice
            singleChatPrice: $singleChatPrice
            messengerPrice: $messengerPrice
            referrer: $referrer
          }
        ) {
          videoChatUserPrice
          singleChatUserPrice
          messengerUserPrice
        }
      }
      settings {
        setChatSettings(
          input: {
            heatmap: {
              status: $heatMapStatus
              texts: [
                { language: "de", text: $deDescription }
                { language: "en", text: $enDescription }
              ]
            }
            livePreview: $livePreviewStatus
            voyeurInSingleChat: $voyeurInSingleChatStatus
            soundForVoyeurInSingleChat: $soundForVoyeurInSingleChatStatus
            softChat: $softChatStatus
            freeChat: $freeChatStatus
            partyChat: $partyChatStatus
          }
        ) {
          status
        }
      }
    }
  }
`;
