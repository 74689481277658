import gql from 'graphql-tag';

export const QUERY_MODEL_MAILINGS_RECIPIENTS_COUNT = gql`
  query QUERY_MODEL_MAILINGS_RECIPIENTS_COUNT(
    $type: MailingTypeEnum!
    $recipientsGroup: MailingRecipientsGroupsEnum!
    $filter: [MailingRecipientsGroupsFiltersEnum!]!
  ) {
    model {
      mailings {
        recipients(type: $type, group: $recipientsGroup, filter: $filter)
          @connection(key: "recipientsCount", filter: ["type", "group", "filter"]) {
          count
        }
      }
    }
  }
`;

export const QUERY_MODEL_MAILINGS_RECIPIENTS_FILTER = gql`
  query QUERY_MODEL_MAILINGS_RECIPIENTS_FILTER($recipientsGroup: MailingRecipientsGroupsEnum!) {
    model {
      mailings {
        recipientsFilters(group: $recipientsGroup)
          @connection(key: "recipientsFilters", filter: ["group"]) {
          options {
            label
            value
          }
          selected
        }
      }
    }
  }
`;
