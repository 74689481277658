import React, { FC, Fragment } from 'react';
import { RED } from '../../camtool-styles';

interface IProps {
  errors: IErrorMessage[];
}

interface IErrorMessage {
  field?: string;
  message: string;
}

const ValidationErrorMessage: FC<IProps> = ({ errors }) => {
  return errors.length > 0 ? (
    <span css={{ color: RED }} className="validation-error">
      {errors.map((error, index, messages) => (
        <Fragment key={index}>
          {error.message}
          {index < messages.length - 1 && <br />}
        </Fragment>
      ))}
    </span>
  ) : null;
};

export default ValidationErrorMessage;
