import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BREAKPOINT_TABLET, WHITE_2 } from '../../../../camtool-styles';
import Icon, { IconType as IconDefinition } from '../../../../atoms/Icon/Icon';

interface Props {
  title: string;
  icon: IconDefinition;
  iconSize?: string;
  isInSubMenu: boolean;
}

const Container = styled.div`
  width: 100%;
  align-items: center;
  flex: 1 0 0;
`;

const IconContainer = styled.div`
  width: 15px;
  flex-direction: row;
  text-align: center;
  justify-content: center;
`;

interface TitleProps {
  withFontWeightLight: boolean;
}

const Title = styled.div<TitleProps>`
  margin-left: 7px;
  padding-right: 11px;
  white-space: nowrap;
  font-size: 13px;
  font-family: 'Roboto Condensed', sans-serif;
  color: ${WHITE_2};

  ${(props): string => (props.withFontWeightLight ? 'font-weight: 300;' : '')}

  ${BREAKPOINT_TABLET} {
    display: none !important;
  }
`;

const RightContent = styled.div`
  ${BREAKPOINT_TABLET} {
    display: none !important;
  }
`;

const LinkContent: FC<Props> = ({ title, icon, iconSize = '15px', isInSubMenu, children }) => (
  <Container>
    <IconContainer>
      <Icon icon={icon} size={iconSize} />
    </IconContainer>
    <Title withFontWeightLight={isInSubMenu}>{title}</Title>
    {!!children && <RightContent>{children}</RightContent>}
  </Container>
);

export default LinkContent;
