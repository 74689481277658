import React from 'react';
import { Field, FieldProps, FormikErrors } from 'formik';
import { CharLimit } from '../../../atoms';
import { _ } from '../../../util/translate';
import { sanitizeLang } from '../../../util/Translator';
import { MultiLanguageTextField } from '../../MUI';
import { ApiLang } from '../../../graphql/VXModels/types';
import { IAboDetailsPageForm } from '../../../routes/AboWizard/types';

interface InProps {
  name: string;
  error: FormikErrors<IAboDetailsPageForm>;
  maxValue: number;
  tabTitle: string;
  placeholderDe: string;
  placeholderEn: string;
  changeLanguage: () => void;
  language: string;
  rows: number;
  validation?: string;
  tab?: number;
  setTab?: (tab: number) => void;
}
const FieldMultiLanguageTextField = ({
  name,
  error,
  tabTitle,
  maxValue,
  placeholderDe,
  placeholderEn,
  language,
  rows,
  changeLanguage,
  validation,
}: InProps) => {
  return (
    <Field
      name={name}
      key={name}
      render={({ field, form }: FieldProps) => {
        return (
          <MultiLanguageTextField
            language={language}
            changeLanguage={changeLanguage}
            label={{
              de: _(`${tabTitle}`, {
                lng: sanitizeLang('de'),
              }),
              en: _(`${tabTitle}`, {
                lng: sanitizeLang('en'),
              }),
            }}
            error={!!error}
            textFieldProps={{
              ...field,
              charLimitHelper: (
                <CharLimit
                  key={name}
                  valueLength={field.value ? field.value.length : 0}
                  limit={maxValue}
                />
              ),
              validation: validation,
              multiline: true,
              placeholder: language === 'de' ? placeholderDe : placeholderEn,
              value: field.value,
              rows: rows ? rows : 1,
              onChange: (e) => {
                form.setFieldValue(field.name, field.value);
                field.onChange(e);
              },
            }}
          />
        );
      }}
    />
  );
};

export default FieldMultiLanguageTextField;
