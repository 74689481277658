import { AbstractRestApiStoreClass, AbstractRestApiActionCreatorsClass } from '../AbstractRestApi';

const PROFILE_ASSISTANT = 'PROFILE_ASSISTANT';

let config = {
  name: 'ProfileAssistant',
  resources: {
    PROFILE_ASSISTANT: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/assistant',
      },
      defaultData: {},
    },
  },
};

class ProfileAssistantStoreClass extends AbstractRestApiStoreClass {}
class ProfileAssistantActionCreatorsClass extends AbstractRestApiActionCreatorsClass {}

const ProfileAssistantStore = new ProfileAssistantStoreClass(config);
const ProfileAssistantActionCreators = new ProfileAssistantActionCreatorsClass(config);

export { ProfileAssistantStore, ProfileAssistantActionCreators, PROFILE_ASSISTANT };
