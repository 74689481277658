import * as ActionTypes from '../../util/constants/ActionTypes';
import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import SockJS from 'sockjs-client';
import { APP_IS_DEVELOPMENT, APP_WS_HOST } from '../../util/env';

const DEBUG = APP_IS_DEVELOPMENT && false;

const PING_INTERVAL = 60000;

const RequestTopicEnum = {
  SESSION_ADOPTSSWSESSION_REQUEST: 'session.adoptSswSession.request',
  SESSION_KEEPALIVE_REQUEST: 'session.keepAlive.request',
  SESSION_LOGOUT_REQUEST: 'session.logout.request',
  SESSION_LOGIN_REQUEST: 'session.login.request',
  SESSION_REGISTER_REQUEST: 'session.register.request',
  USERSTORE_UPDATE_REQUEST: 'userStore.update.request',
  USERSTORE_SET_REQUEST: 'userStore.set.request',
  NOTIFICATIONS_UPDATE_REQUEST: 'notifications.update.request',
  NOTIFICATIONS_SET_REQUEST: 'notifications.set.request',
};

const ReceiveTopicEnum = {
  ALERTS_DATA: 'alerts.data',
  CAMTOOL_RELOAD: 'camtool.reload',
  SESSION_DATA: 'session.data',
  SESSION_ERROR: 'session.error',
  SESSION_KEEPALIVE_ACK: 'session.keepAlive.ack',
  SESSION_LOGOUT_ACK: 'session.logout.ack',
  USERSTORE_UPDATE_DATA: 'userStore.update.data',
  USERSTORE_SET_ACK: 'userStore.set.ack',
  NOTIFICATIONS_UPDATE_DATA: 'notifications.update.data',
  NOTIFICATIONS_SET_ACK: 'notifications.set.ack',
  MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA: 'mediaManagement.video.transcoding.data',
};

const MessageRouting = {
  'graphql.writeQuery': {
    dispatchType: ActionTypes.GRAPHQL_WRITE_QUERY,
    dispatchData: (payload) => {
      return payload.data;
    },
  },
  'alerts.data': {
    dispatchType: ActionTypes.ALERTS_SHOW_DATA,
    dispatchData: (payload) => {
      return payload.data;
    },
  },
  'camtool.reload': {
    dispatchType: ActionTypes.CAMTOOL_RELOAD,
  },
  'mediaManagement.video.transcoding.data': {
    dispatchType: ActionTypes.MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA,
    dispatchData: (payload) => {
      return payload.data;
    },
  },
  'session.data': {
    dispatchType: ActionTypes.SESSION_DATA_RECEIVE,
    dispatchData: (payload) => {
      return payload.data;
    },
  },
  'session.error': {
    dispatchType: ActionTypes.SESSION_ERROR,
    dispatchData: (payload) => {
      return payload;
    },
  },
  'session.keepAlive.ack': {}, // ignore
  'session.logout.ack': {
    dispatchType: ActionTypes.SESSION_KILL,
  },
  'userStore.set.ack': {}, // ignore
  'userStore.update.data': {
    dispatchType: ActionTypes.USERSTORE_DATA_RECEIVE,
    dispatchData: (payload) => {
      return payload.data;
    },
  },
  'notifications.update.data': {
    dispatchType: ActionTypes.NOTIFICATIONS_DATA_RECEIVE,
    dispatchData: (payload) => {
      return payload.data;
    },
  },
};

function onConnect() {
  DEBUG && console.log('Websocket', 'connected');
  window.setInterval(() => {
    this.sendMessage(RequestTopicEnum.SESSION_KEEPALIVE_REQUEST, {});
  }, PING_INTERVAL);

  AppDispatcher.dispatch({
    type: ActionTypes.WEBSOCKET_CONNECTED,
    data: {},
  });
}

function onMessage(msg) {
  let payload = JSON.parse(msg.data);
  const messageRouting = MessageRouting[payload.type];
  if (messageRouting !== undefined) {
    if (messageRouting.dispatchType) {
      let data = {};
      if (typeof messageRouting.dispatchData === 'function') {
        data = messageRouting.dispatchData(payload);
      }
      AppDispatcher.dispatch({
        type: messageRouting.dispatchType,
        data: data,
      });
    }
  } else {
    //console.warn(`No routing for message type ${payload.type} configured`, payload);
  }
}

function onDisconnect() {
  AppDispatcher.dispatch({
    type: ActionTypes.WEBSOCKET_DISCONNECTED,
    data: {},
  });

  setTimeout(() => this.init(), 5000);
}

class WebsocketClass {
  constructor() {
    this.socket = null;
    this.queue = [];
    this.reconnect = true;
  }

  init() {
    if (!this.socket) {
      this.socket = new SockJS(APP_WS_HOST);
      this.socket.onopen = onConnect.bind(this);
      this.socket.onmessage = onMessage.bind(this);
      this.socket.onclose = onDisconnect.bind(this);

      this.sendMessagesFromQueue = this.sendMessagesFromQueue.bind(this);

      DEBUG && console.log('Websocket initialized');
    }
  }

  sendMessagesFromQueue() {
    while (this.queue.length > 0) {
      const payload = this.queue.shift();
      DEBUG && console.log('Websocket', 'payload from queue', payload.type, payload);
      this.sendMessage(payload.type, payload);
    }
  }

  sendMessage(type, payload) {
    payload = payload || {};
    payload.type = type;

    if (this.isReady()) {
      DEBUG && console.log('Websocket', 'send', payload.type, payload);
      this.socket.send(JSON.stringify(payload));
    } else {
      DEBUG && console.warn('Websocket', 'no open boomer connection, put to queue');
      this.queue.push(payload);
    }
  }

  isReady() {
    return this.socket && this.socket.readyState === SockJS.OPEN;
  }
}

const Websocket = new WebsocketClass();

export { Websocket, RequestTopicEnum, ReceiveTopicEnum };
