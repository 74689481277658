import React from 'react';
import { Redirect, Switch } from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { stripSlash } from '../../util/urlHelper';
import Overview from './Overview/Overview';
import Payout from './Payout/Payout';
import Billing from './Billing/Billing';

const Finances = ({ match: { url } }) => {
  const path = stripSlash(url);
  return (
    <Switch>
      <ProtectedRoute path={`${path}/overview`} component={Overview} />
      <ProtectedRoute path={`${path}/payout`} component={Payout} />
      <ProtectedRoute path={`${path}/invoice`} component={Billing} />
      <Redirect to={`${path}/overview`} />
    </Switch>
  );
};
export default Finances;
