import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';

const NewTelegramItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:telegram.newTelegram')}
    icon={faTelegramPlane}
    uri="/telegram/broadcast/new"
  />
);

export default NewTelegramItem;
