import React, { FC, ReactElement } from 'react';
import { Button } from '../../../../../atoms';
import { _ } from '../../../../../util/translate';
import { Formik, Form, FormikProps } from 'formik';
import BA6VoiceSetting from './BA6VoiceSetting';
import BA6ProfileDescription from './BA6ProfileDescription';
import { ButtonGroup } from '../../../../../components';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_SERVICE0900_SAVE_SETTINGS } from '../../../../../graphql/VXModels/mutations';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Service0900Settings, Mutation } from '../../../../../graphql/VXModels/types';
import BA6SexualOrientation from './BA6SexualOrientation';
import BA6ProfilePictureWidgets from './BA6ProfilePictureWidgets';
import { Service0900ProfileSettings } from '../../types';
import { getSettingsValidationSchema, onSettingsSubmit } from '../../utils';

interface Props {
  settings: Service0900Settings;
}

const BA6ProfileSettings: FC<Props> = ({ settings }) => {
  const intialSettings: { settings: Service0900Settings } = {
    settings,
  };
  const [saveSettings, { loading, error }] = useMutation<Mutation>(
    MUTATION_MODEL_SERVICE0900_SAVE_SETTINGS
  );

  return (
    <Formik
      initialValues={intialSettings}
      validationSchema={getSettingsValidationSchema}
      onSubmit={async (values, { setSubmitting }): Promise<void> =>
        onSettingsSubmit(values, saveSettings, setSubmitting)
      }
    >
      {({
        values,
        isSubmitting,
        dirty,
        resetForm,
      }: FormikProps<Service0900ProfileSettings>): ReactElement => (
        <div css={{ flexDirection: 'column' }}>
          <Form css={{ flexDirection: 'column' }}>
            <BA6VoiceSetting />
            <BA6SexualOrientation />
            <BA6ProfilePictureWidgets />
            <BA6ProfileDescription values={values} />
            {error ? (
              <GenericEmptyContent />
            ) : (
              <ButtonGroup position="center">
                <Button
                  theme="gray"
                  label={_('common:button.reset')}
                  disabled={!dirty || isSubmitting}
                  css={{ marginRight: 20 }}
                  onClick={(): void => resetForm()}
                />
                <Button
                  theme="blue"
                  label={_('common:button.save')}
                  type="submit"
                  disabled={!dirty || isSubmitting || loading}
                  loading={isSubmitting || loading}
                />
              </ButtonGroup>
            )}
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default BA6ProfileSettings;
