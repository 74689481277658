import React, { FC } from 'react';
import { Button } from '../../../../../atoms';
import { _ } from '../../../../../util/translate';
import GenericModalBoxLayout from '../../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import Icon from '../../../../../atoms/Icon/Icon';
import { remove, bin } from '../../../../../atoms/Icon/libraries/glyph';
import { ModalContainer } from '../styles';
import { ButtonGroup } from '../../../../../components';
import { DARK_GRAY, WHITE, GRAY_3 } from '../../../../../camtool-styles';
import { useTelegramDeleteAutoMail } from '../../../../Telegram';
import { useHistory } from 'react-router';
import { useTelegramAccount } from '../../../../Telegram/hooks/useTelegramAccount';
import { QUERY_MASTER_USERLIFECYCLE } from '../../../../Telegram/graphql/queries';

interface AutoMailGridFormItemDeleteModalProps {
  id: number;
  isVip: boolean;
  closeModal: () => void;
}

const AutoMailGridFormItemDeleteModal: FC<AutoMailGridFormItemDeleteModalProps> = ({
  id,
  isVip,
  closeModal,
}) => {
  const history = useHistory();
  const { account, loading } = useTelegramAccount();
  const token = account?.token;
  const [deleteAutoMail] = useTelegramDeleteAutoMail();

  return (
    <GenericModalBoxLayout maxWidth={700} name={'delete-automail'} onClose={closeModal}>
      <ModalContainer css={{ padding: '90px 12px' }}>
        <div
          css={{
            color: DARK_GRAY,
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <h2 css={{ marginBottom: '6px' }}>{_('mailings:telegram.autoMail.delete.headline')}</h2>
          <p css={{ marginBottom: '50px' }}>{_('mailings:telegram.autoMail.delete.subHeadline')}</p>

          <ButtonGroup position={'space-between'}>
            <Button theme={'border-gray'} onClick={closeModal} css={{ marginRight: '20px' }}>
              <Icon icon={remove} size={'12px'} css={{ marginRight: '3px', color: GRAY_3 }} />
              <span>{_('common:button.cancel')}</span>
            </Button>
            <Button
              theme={'blue'}
              loading={loading}
              disabled={loading}
              onClick={(): void => {
                if (id) {
                  deleteAutoMail({
                    variables: { token, id },
                    refetchQueries: [
                      { query: QUERY_MASTER_USERLIFECYCLE, variables: { token, vip: isVip } },
                    ],
                    awaitRefetchQueries: true,
                  });
                }
                closeModal();
                // links to parent route
                history.push('.');
              }}
            >
              <Icon icon={bin} size={'12px'} css={{ color: WHITE, marginRight: '3px' }} />
              <span css={{ color: WHITE }}>{_('mailings:telegram.autoMail.delete.message')}</span>
            </Button>
          </ButtonGroup>
        </div>
      </ModalContainer>
    </GenericModalBoxLayout>
  );
};

export default AutoMailGridFormItemDeleteModal;
