import React, { FC } from 'react';
import { StyledNavSubLabel } from './styles';

interface IProps {
  label: string;
}

const NavSubItemLabel: FC<IProps> = ({ label = '' }) => {
  return <StyledNavSubLabel>{label}</StyledNavSubLabel>;
};

export default NavSubItemLabel;
