import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { QUERY_APP_INITIAL } from '../graphql/VXModels/queries';
import { NOTIFICATIONS_LIMIT } from './NotificationsHelper';
import SplashScreen from '../components/SplashScreen';
import AppUtils from './AppUtils';
import { ApiLang, Query, SecurityRole } from '../graphql/VXModels/types';
import { Lang } from './constants/Lang';
import { getTimezone } from './timezone';
import { useQuery } from '@apollo/react-hooks';
import { isServerErrorWithStatusCode } from '../graphql/util';
import { LoginRedirect } from '../components/LoginRedirect';
import { mergeDeep } from 'apollo-utilities';

export interface UserData {
  auth: Required<Query['auth']>;
  model: Required<Query['model']>;
  setOverrideData: () => void;
}

const defaultUserData: UserData = {
  auth: undefined,
  model: undefined,
  setOverrideData: () => undefined,
};

export const UserDataContext = createContext<UserData>(defaultUserData);

let userData: UserData = defaultUserData;

export const UserDataProvider: FC = (props) => {
  const { children: Children, ...rest } = props;
  const [overrideData, setOverrideData] = useState({});

  const { loading, error, data } = useQuery<UserData>(QUERY_APP_INITIAL, {
    variables: {
      notificationsOffset: 0,
      notificationsLimit: NOTIFICATIONS_LIMIT,
      timezone: getTimezone(),
    },
  });

  // @note !!! CANNOT BE DONE IN useEffect DUE LEGACY SHIT !!!
  userData = data || defaultUserData; // todo: ugly, needs refactoring, but we need for legacy REST in stores userData without context
  const userId = userData?.auth?.id || null;

  useEffect(() => {
    if (error) {
      const { networkError } = error;
      // 401 is handled in onError handler, 403 is handled here, when APP_INITIAL return roles which results in a forbidden state
      if (isServerErrorWithStatusCode(networkError, 403) || userId === null) {
        AppUtils.forceLogout();
      }
    }
  }, [error]);

  return loading ? (
    <SplashScreen />
  ) : error ? (
    <LoginRedirect />
  ) : (
    <UserDataContext.Provider value={{ ...mergeDeep(userData, overrideData), setOverrideData }}>
      <Children {...rest} userData={data} />
    </UserDataContext.Provider>
  );
};

export const useUserData: () => UserData = () => useContext(UserDataContext);

export const useRoles: () => SecurityRole[] = () => useContext(UserDataContext)?.auth?.roles || [];

export const useHasRole = (role: SecurityRole): boolean => useRoles().includes(role);

export const useSpokenLangs = (): Lang[] => useUserData()?.model?.profile?.languages?.value || [];

export const useSpeaksGerman = (): boolean => useSpokenLangs().includes(ApiLang.de);

/**
 * @deprecated use this only if not useUserData does not work, i.e. you are not in a functional component
 */
export const getUserData: () => UserData = () => userData;

/**
 * @deprecated use this only if not useHasRole does not work, i.e. you are not in a functional component
 */
export const hasRole = (role: SecurityRole): boolean =>
  (userData?.auth?.roles || []).includes(role);

/**
 * @deprecated use this only if not useSpokenLangs does not work, i.e. you are not in a functional component
 */
export const getSpokenLangs: () => Lang[] = () => userData.model?.profile?.languages?.value || [];
