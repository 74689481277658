import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const StarRating = ({ id, value }) => {
  const isChecked = checkValue => value === checkValue;

  return (
    <div className="starrating">
      {[5, 4, 3, 2, 1].map(i => (
        <Fragment key={i}>
          <input
            name={`ratingstars_${id}`}
            id={`ratingstar${i}_${id}`}
            type="radio"
            checked={isChecked(i)}
            readOnly={true}
          />
          <label htmlFor={`ratingstar${i}_${id}`} id={`ratingstar${i}label_${id}`} />
        </Fragment>
      ))}
    </div>
  );
};

StarRating.propTypes = {
  value: PropTypes.number,
  editable: PropTypes.bool,
};

StarRating.defaultProps = {
  value: 5,
  editable: false,
};

export default StarRating;
export { StarRating };
