import React, { FC, useMemo } from 'react';
import { PreviewAudio } from './PreviewAudio';
import { PreviewImage, PreviewImageOptions } from './PreviewImage';
import { PreviewUnknown } from './PreviewUnknown';
import { PreviewVideo, PreviewVideoOptions } from './PreviewVideo';
import { FileDescriptor, FileType, UrlFileDescriptor } from './types';
import { createUrlFileDescriptorFromNative, isNativeFileDescriptor } from './utils';

interface Props {
  file: FileDescriptor;
  imageOptions?: PreviewImageOptions;
  videoOptions?: PreviewVideoOptions;
  audioOptions?: () => void;
  className?: string;
}

const PreviewFile: FC<Props> = ({ file, imageOptions, audioOptions, videoOptions, className }) => {
  const urlFileDescriptor: UrlFileDescriptor = useMemo(
    (): UrlFileDescriptor =>
      isNativeFileDescriptor(file) ? createUrlFileDescriptorFromNative(file) : file,
    [file]
  );

  switch (urlFileDescriptor.type) {
    case FileType.image:
      return (
        <PreviewImage
          url={urlFileDescriptor.previewUrl || urlFileDescriptor.url}
          options={imageOptions}
          className={className}
        />
      );
    case FileType.video:
      return (
        <PreviewVideo
          url={urlFileDescriptor.url}
          thumbnailUrl={urlFileDescriptor.previewUrl}
          options={videoOptions}
          className={className}
        />
      );
    case FileType.audio:
      return (
        <PreviewAudio
          url={urlFileDescriptor.url}
          fontSize="115px"
          options={audioOptions}
          width={120}
          height={60}
        />
      );
    default:
      return <PreviewUnknown file={file} className={className} />;
  }
};

export default PreviewFile;
