import React, { FC } from 'react';
import { FormikHandlers } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { NewInputField } from '../../../../atoms';
import BuildValidationObject from '../../../../util/BuildValidationObject';
import { CHAR_LIMIT } from './ChannelDescriptionContent';

interface Props {
  field: {
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    value: string;
    name: string;
  };
  isMobile: boolean;
  error: string | undefined;
}

const ChannelAdvantage: FC<Props> = ({ field, isMobile, error }) => {
  const validationError = BuildValidationObject('error', error || '');

  return (
    <>
      <NewInputField
        multiline={isMobile}
        validation={validationError}
        charLimitHelper={
          <Box key={field.name} mr={'3px'}>
            <Typography>{`${field.value.length}/${CHAR_LIMIT}`}</Typography>
          </Box>
        }
        inputProps={{ maxLength: CHAR_LIMIT }}
        {...field}
      />
    </>
  );
};

export default ChannelAdvantage;
