import React, { FC } from 'react';
import { WHITE, GRAY_3 } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';
import BlockIcon from '@material-ui/icons/Block';

interface IProps {
  active: boolean;
}

const BlockedStatus: FC<IProps> = ({ active }) => {
  return (
    <div
      css={{
        color: active ? WHITE : GRAY_3,
        marginTop: '3px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'end',
        maxWidth: '64px',
      }}
    >
      <BlockIcon fontSize="small" />
      <span css={{ fontSize: '12px' }}>{_('telegram:chat.status.blocked')}</span>
    </div>
  );
};

export default BlockedStatus;
