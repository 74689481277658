import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GRAY_2, BLACK_2 } from '../../camtool-styles';

const StyledLabel = styled.label<Pick<Props, 'disabled'>>`
  white-space: nowrap;
  color: ${(props): string => (props.disabled ? GRAY_2 : BLACK_2)};
  user-select: none;
`;

interface Props {
  value: string;
  label?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  className?: string;
  disabled?: boolean;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: any;
}

const RadioCircleButton: FC<Props> = ({
  value,
  label,
  checked,
  name,
  disabled,
  onBlur,
  onChange,
  className,
  children,
}) => {
  return (
    <StyledLabel className={className}>
      <input
        type="radio"
        value={value}
        checked={checked}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        css={{ marginRight: '6px' }}
      />
      <span>{children || label}</span>
    </StyledLabel>
  );
};

export default RadioCircleButton;
