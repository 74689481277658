import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import {withApollo} from '@apollo/react-hoc';

import AbstractComponent from '../../../AbstractComponent';
import {_} from '../../../../util/translate';
import {
    CampaignActionCreators,
    CAMPAIGNS,
    CampaignStore,
} from '../../../../stores/Marketing/Campaign';
import AlertsStore from '../../../../stores/Alerts/AlertsStore';
import Notice from '../../../Notice/Notice';
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';
import InfoField from '../../../InfoField/InfoField';
import FormatDuration from '../../../Formatter/FormatDuration';
import FormatFileSize from '../../../Formatter/FormatFileSize';
import Modalbox from '../../../Modalbox/Modalbox';
import MediaSelector from '../../../MediaSelector/MediaSelector';
import TagListInputAutocomplete from '../../../Form/MultiOptions/TagListInputAutocomplete/TagListInputAutocomplete';
import Range from '../../../Form/Range/Range';
import OptionBoxOnOff from '../../../Form/SingleOption/OptionBoxOnOff/OptionBoxOnOff';
import Button from '../../../Button/Button';
import MultiLangTextareafield from '../../../Form/MultiLang/MultiLangTextareafield/MultiLangTextareafield';
import MultiLangInputfield from '../../../Form/MultiLang/MultiLangInputfield/MultiLangInputfield';
import OptionBoxRadio from '../../../Form/SingleOption/OptionBoxRadio/OptionBoxRadio';
import Dropdown from '../../../Form/SingleOption/Dropdown/Dropdown';
import DatePicker from '../../../../atoms/DatePicker/DatePicker';
import {APP_IS_DEVELOPMENT} from '../../../../util/env';
import {getSpokenLangs, hasRole} from '../../../../util/UserData';
import {withAppState} from '../../../../util/AppState';
import {ApiLang, SecurityRole} from '../../../../graphql/VXModels/types';
import {
    MediaFormButtonGroup,
    MediaFormDateDescription,
    MediaFormGroupRow,
    MediaFormOptionDescription,
} from '../../styles';
import DailyBonusOptInWrapper from './DailyBonusOptInWrapper/DailyBonusOptInWrapper';
import {DailyBonusHelpcenterLink, FetishHelpcenterLink} from './HelpcenterLinks';
import {QUERY_MODEL_PHOTOALBUMS_SCHEDULING} from '../../../../graphql/VXModels/queries';
import {BREAKPOINT_TABLET_CONDITION, GREEN} from '../../../../camtool-styles';
import {format} from 'date-fns';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import styled from '@emotion/styled';
import {Box} from '@material-ui/core';
import SimpleCheckbox from '../../../Form/Checkbox/SimpleCheckbox';
import InfoIcon from '@material-ui/icons/Info';
import {Fieldset} from '../../../../atoms';
import * as Formsy from 'formsy-react';

const DatePickerContainer = styled.div`
    @media ${BREAKPOINT_TABLET_CONDITION} {
        -webkit-flex: 1 1 100%;
        flex: 1 1 100%;
        section {
            width: 100%;
        }
    }
`;

const LabelStyled = styled(Box)`
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -11px;
    margin-right: 16px;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    background-color: rgb(245, 245, 245);
    margin-left: -4px;
    padding-right: 4px;
    padding-left: 4px;
`;

const TextInBorderSpan = styled('span')`
    color: #3c3d3e;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding: 7px;
`;

const SectionLabelBox = styled(Box)`
    display: flex;
    flex-direction: column;
`;

function getCampaignById(id) {
    const data = CampaignStore.get(CAMPAIGNS);
    const campaigns = data.campaigns;
    for (let campaign of campaigns) {
        if (campaign.campaignId === id) {
            return campaign;
        }
    }
    return null;
}

class VideoForm extends AbstractComponent {
    constructor(props) {
        super(props);
        this.state = {
            albumType: this.props.data.albumType,
            hasChanges: false,
            isFormValid: false,
            isLoading: true,
            isAlbumLocked: false,
            previewUrl16: this.props.data.previewUrl16 || '',
            previewUrl18: this.props.data.previewUrl18 || '',
            vip30Scheduling: null,
            checked: false,
            allIDCardsUploadedState: this.props.data.allIDCardsUploaded,
            allModelReleaseFormsUploadedState: this.props.data.allModelReleaseFormsUploaded,
        };
        this.campaignOptions = [];
        this.currentValues = this.getCurrentValuesFromStoreValues(props.data);
        this.autocompleteUrl = '/v1/public/tags/media?search={search}';
    }

    componentWillMount() {
        CampaignStore.addChangeListener(CAMPAIGNS, this.onStoreChange);
        CampaignStore.reset(CAMPAIGNS);
        CampaignActionCreators.readResource(CAMPAIGNS, {
            query: {video: 1, activity: 'uploadActive'},
        });
        this.videoTypeOptions = [
            {value: 'shop', label: _('mediamanagement:video.nav.shop')},
            {value: 'free', label: _('mediamanagement:video.nav.free')},
            {value: 'profile', label: _('mediamanagement:video.nav.profile')},
            {value: 'campaign', label: _('mediamanagement:video.nav.campaign')},
        ];
        this.publicationOptions = [
            {value: 'now', label: _('mediamanagement:video.publication.rightAfterRating')},
            {value: 'never', label: _('mediamanagement:video.publication.dontPublish')},
            {
                value: 'date',
                label: _('mediamanagement:video.publication.afterRatingAndDate'),
            },
        ];
    }

    componentWillUnmount() {
        CampaignStore.reset(CAMPAIGNS);
        CampaignStore.removeChangeListener(CAMPAIGNS, this.onStoreChange);
    }

    componentDidMount() {
        this.formsyRef = this.refs.form;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (prevProps.data.albumId && this.props.data.albumId > 0) ||
            prevProps.data.albumId !== this.props.data.albumId
        ) {
            this.props.client
                .query({
                    query: QUERY_MODEL_PHOTOALBUMS_SCHEDULING,
                    variables: {id: this.props.data.albumId},
                })
                .then((res) => {
                    const {dailyBonus, vip30Scheduling} = res.data.model.photoAlbums.albums[0];
                    const isScheduledFor365 = dailyBonus.modelContent.some(
                        (item) => item.selectedFor || item.preSelectedFor?.from
                    );
                    const isScheduledForVip30 = !!vip30Scheduling?.month;
                    const isSharedAlbum = this.props.data.hasFlagSharedAlbum === 'yes';

                    this.setState({
                        isAlbumLocked: isScheduledFor365 || isScheduledForVip30 || isSharedAlbum,
                        vip30Scheduling: vip30Scheduling,
                    });
                });
        }
    }

    componentWillReceiveProps(nextProps) {
        this.currentValues = this.getCurrentValuesFromStoreValues(nextProps.data);
        // console.log(this.__proto__.constructor.name, 'componentWillReceiveProps', nextProps.data.albumType, this.currentValues.albumType);
    }

    onClickPreviewPicture16() {
        this.setState({showModal: 16});
    }

    onClickPreviewPicture18() {
        this.setState({showModal: 18});
    }

    onClickModalClose() {
        this.setState({showModal: null});
    }

    onStoreChange() {
        const data = CampaignStore.get(CAMPAIGNS);
        const campaigns = data.campaigns;
        const [{lang}] = this.props.appState;

        for (let campaign of campaigns) {
            if (campaign.states.uploadedMedias < campaign.uploadLimit) {
                if (!this.campaignOptions.some((item) => campaign.campaignId === item.value)) {
                    this.campaignOptions.push({
                        value: campaign.campaignId,
                        label: campaign.texts.text_title[lang],
                    });
                }
            }
        }

        this.setState({isLoading: false});
    }

    onClickReset() {
        this.formsyRef.reset(this.props.data);
    }

    onSubmit(currentValues) {
        currentValues.previewPicId16 = this.currentValues.previewPicId16;
        currentValues.previewPicId18 = this.currentValues.previewPicId18;
        if (currentValues.albumType) {
            currentValues.type = currentValues.albumType;
        }

        const discountStart = document.querySelector('[name="discountStart"]')
            ? document.querySelector('[name="discountStart"]').value
            : '';
        const discountEnd = document.querySelector('[name="discountEnd"]')
            ? document.querySelector('[name="discountEnd"]').value
            : '';
        if (discountStart !== '' && discountEnd !== '') {
            currentValues.discountStart = new Date(Number(discountStart)).toISOString();
            currentValues.discountEnd = new Date(Number(discountEnd)).toISOString();
        }
        const releaseDate = document.querySelector('[name="releaseDate"]')
            ? document.querySelector('[name="releaseDate"]').value
            : '';
        if (releaseDate !== '') {
            currentValues.releaseDate = new Date(Number(releaseDate)).toISOString();
        }

        // filter _lang property out (php doesn't want it)
        const {_langs: _langs_titles, ...titles} = currentValues.titles || {};
        const {_langs: _langs_descriptions, ...descriptions} = currentValues.descriptions || {};

        this.props.onSubmit({...currentValues, titles, descriptions});
    }

    onClickDelete() {
        if (confirm(_('mediamanagement:video.form.confirmDelete'))) {
            this.props.onDelete(this.props.data);
        }
    }

    onChange(currentValues, hasChanges) {
        const nextState = {
            albumType: currentValues.albumType,
            hasChanges: hasChanges,
        };
        this.currentValues = Object.assign(this.currentValues, currentValues);
        this.setState(nextState);
    }

    onCheckboxChange = (event) => {
        if (event.target.name === 'allIDCardsUploaded') {
            this.setState({allIDCardsUploadedState: event.target.checked});
        }
        if (event.target.name === 'allModelReleaseFormsUploaded') {
            this.setState({allModelReleaseFormsUploadedState: event.target.checked});
        }
    };

    setVideoPreviewPicture(pictures) {
        if (pictures.length > 0) {
            let picture = pictures[0];
            switch (picture.pictureType) {
                case 'videoPreview16':
                    this.currentValues.previewPicId16 = picture.pictureId;
                    this.currentValues.previewUrl16 = picture.url;
                    this.setState({previewUrl16: picture.urlOriginal || picture.url});
                    break;
                case 'videoPreview18':
                    this.currentValues.previewPicId18 = picture.pictureId;
                    this.currentValues.previewUrl18 = picture.url;
                    this.setState({previewUrl18: picture.urlOriginal || picture.url});
                    break;
            }
            this.forceUpdate();
        }
    }

    getCurrentValuesFromStoreValues(storeValues) {
        let currentValues = {};
        if (this.formsyRef) {
            const inputNames = this.getInputNames().concat(this.formsyRef.prevInputNames);
            for (let inputName of inputNames) {
                if (storeValues[inputName] !== undefined) {
                    currentValues[inputName] = storeValues[inputName];
                }
            }
        } else {
            currentValues = cloneDeep(storeValues);
        }
        return currentValues;
    }

    getInputNames() {
        return ['albumType', 'previewUrl16', 'previewUrl18', 'previewPicId16', 'previewPicId18'];
    }

    showErrorFields() {
        if (this.state.displayErrors === false) {
            this.setState({displayErrors: true});
        }
        window.scrollTo(0, 0);
        AlertsStore.add({
            type: 'error',
            message: _('common:alerts.profile.formInvalid'),
        });
    }

    //@todo translation
    previewPicWarningText(fsk) {
        if (this.currentValues['previewPicId' + fsk] > 0) {
            for (let picture of this.props.data.pictures) {
                if (picture.pictureId === this.props.data['previewPicId' + fsk]) {
                    if (picture.isChecked) {
                        if (picture.isRejected) {
                            return _('mediamanagement:video.previewPicture.stateRejected');
                        } else {
                            return ''; //Preview Picture is set and successfully rated
                        }
                    } else {
                        return ''; //'Wird geprüft'; wurde entfernt 2016-11-29
                    }
                }
            }
        } else {
            return _('mediamanagement:video.previewPicture.stateNotExists');
        }
    }

    onStatusChange(ev) {
        let ret = true;
        if (ev.target.value === 'offline') {
            ret = confirm(_('mediamanagement:video.status.confirmOffline'));
        } else if (ev.target.value === 'online' && this.isWelcomeclip) {
            ret = confirm(_('mediamanagement:video.status.confirmOnlineWC'));
        }
        return ret;
    }

    getPicturePoolFilter = (fsk) => {
        return (picture) => {
            return !picture.isChecked || picture.fsk <= fsk;
        };
    };

    render() {
        const spokenLangs = getSpokenLangs();
        const [{lang}] = this.props.appState;

        this.isDevelopment = APP_IS_DEVELOPMENT;

        this.debugInfo = {
            albumId: this.props.data.albumId,
            albumType: this.props.data.albumType,
            currentValues: this.currentValues,
        };

        this.isPool = this.currentValues.albumType === 'pool';
        this.isCampaign = this.currentValues.albumType === 'campaign';
        this.isFree = this.currentValues.albumType === 'free';
        this.isShop = this.currentValues.albumType === 'shop';
        this.isWelcomeclip = this.currentValues.albumType === 'profile';

        this.isOnline = this.props.data.status === 'online';
        this.isOffline = this.props.data.status === 'offline';
        this.isRejected = this.props.data.status === 'rejected';
        this.isRatingDelayed = this.props.data.status === 'ratingDelayed';
        this.isRating = this.props.data.status === 'rating';

        this.showModalBoxFsk16 = this.state.showModal === 16;
        this.showModalBoxFsk18 = this.state.showModal === 18;
        this.showPreviewPictures = !this.isWelcomeclip;

        this.previewPictureStyleFsk16 = {
            backgroundImage: this.currentValues.previewUrl16
                ? 'url(' + this.currentValues.previewUrl16 + ')'
                : '',
        };
        this.previewPictureStyleFsk18 = {
            backgroundImage: this.currentValues.previewUrl18
                ? 'url(' + this.currentValues.previewUrl18 + ')'
                : '',
        };
        this.showAlbumType = this.props.data.albumType === 'pool';
        this.showTitle = true;
        this.showDescription = this.isFree || this.isShop || this.isCampaign;
        this.showCampaignSelection = this.isCampaign && this.showAlbumType;
        this.showCampaignTextfield = this.isCampaign && !this.showAlbumType;
        this.showResetButton = !this.isRejected;
        this.showSaveButton = !this.isRejected;
        this.showDeleteButton = this.props.data.albumId && this.props.data.isDeletable;
        this.showPrice =
            (this.props.data.albumType === 'pool' || this.props.data.price > 0) &&
            (this.isShop || this.isCampaign);
        this.showTags = this.isShop || this.isFree;
        this.showStatus =
            (this.isShop || this.isFree || this.isWelcomeclip) && (this.isOnline || this.isOffline);
        this.selectedCampaign = this.campaignOptions[0] ? this.campaignOptions[0].value : '';
        this.disabledAlbumTypeOptions = [];
        this.isAlbumTypeShopDisabled = false;
        if (this.props.data.videoLength < 60) {
            this.disabledAlbumTypeOptions.push('shop');
            this.isAlbumTypeShopDisabled = true;
        }
        if (!this.props.data.isApprovedAsWelcomeclip) {
            this.disabledAlbumTypeOptions.push('profile');
        }
        if (this.campaignOptions.length === 0) {
            this.disabledAlbumTypeOptions.push('campaign');
        }

        this.rejectionReason = '';
        if (this.props.data.status === 'rejected' || this.props.data.status === 'ratingDelayed') {
            if (this.props.data.rejectionReason[lang]) {
                this.rejectionReason = this.props.data.rejectionReason[lang];
            } else {
                this.rejectionReason =
                    this.props.data.rejectionReason[Object.keys(this.props.data.rejectionReason)[0]];
            }
        }
        const hasVXModelsRole = hasRole(SecurityRole.VX_USER_VXMODELS);

        return (
            <Formsy.Form
                className={`video-edit__formwidth ${this.props.className}`}
                onValidSubmit={this.onSubmit}
                onInvalidSubmit={this.showErrorFields}
                onValid={this.onValid}
                onInvalid={this.onInvalid}
                onChange={this.onChange}
                // eslint-disable-next-line react/no-string-refs
                ref="form"
                onClick={this.onClickForm}
            >
                {(this.props.data.status === 'rejected' || this.props.data.status === 'ratingDelayed') && (
                    <MediaFormGroupRow tooltip="clear">
                        <Notice
                            text={_(
                                'mediamanagement:video.edit.' +
                                (this.props.data.status === 'rejected'
                                    ? 'videoRejectedText'
                                    : 'ratingDelayedTitle')
                            )}
                            icon="icon-warning-sign"
                        >
                            <div className="rejected-reason">
                                <div className="label">{_('mediamanagement:video.reasonLabel')}:</div>
                                <div className="text">
                                    {this.props.data.status === 'rejected'
                                        ? this.rejectionReason
                                        : _('mediamanagement:video.edit.ratingDelayedText')}
                                </div>
                            </div>
                        </Notice>
                    </MediaFormGroupRow>
                )}

                {this.props.data.hasFlagSharedAlbum === 'yes' &&
                    !(
                        this.props.data.status === 'rejected' || this.props.data.status === 'ratingDelayed'
                    ) && (
                        <MediaFormGroupRow tooltip="clear">
                            <Notice
                                text={_('mediamanagement:video.edit.sharedVideoInfo')}
                                icon="icon-warning-sign"
                            ></Notice>
                        </MediaFormGroupRow>
                    )}

                {this.props.data.status === 'offline' && this.props.data.hasFlagSuspectedContent && (
                    <MediaFormGroupRow tooltip="clear">
                        <Notice
                            text={_('mediamanagement:video.edit.videoOfflineText')}
                            icon="icon-warning-sign"
                        >
                            <div className="rejected-reason">
                                <div className="label">{_('mediamanagement:video.reasonLabel')}:</div>
                                <div className="text">{_('mediamanagement:video.edit.suspectedContent')}</div>
                            </div>
                        </Notice>
                    </MediaFormGroupRow>
                )}

                <MediaFormGroupRow>
                    {this.props.data.videoURL && (
                        <VideoPlayer
                            url={this.props.data.videoURL}
                            poster={this.state.previewUrl16 || this.state.previewUrl18 || null}
                        />
                    )}
                </MediaFormGroupRow>

                <MediaFormGroupRow tooltip="clear">
                    <InfoField
                        label={`${_('mediamanagement:video.filenameLabel')}:`}
                        info={this.props.data.originalFilename}
                    />

                    <InfoField label={_('mediamanagement:video.lengthLabel') + ':'}>
                        <FormatDuration value={this.props.data.videoLength}/>
                    </InfoField>

                    <InfoField label={_('mediamanagement:video.filesizeLabel') + ':'}>
                        <FormatFileSize value={this.props.data.fileFize}/>
                    </InfoField>

                    <InfoField
                        label={_('mediamanagement:video.idLabel') + ':'}
                        info={this.props.data.albumId}
                    />
                </MediaFormGroupRow>

                {this.props.data.status !== 'rejected' && (
                    <Fragment>
                        {this.showAlbumType && (
                            <MediaFormGroupRow
                                label={`${_('mediamanagement:video.albumType.label')}:`}
                                tooltipText={_('mediamanagement:video.albumType.tooltipText')}
                            >
                                {this.isAlbumTypeShopDisabled && (
                                    <Notice
                                        text={_('mediamanagement:video.albumType.noticeVideoTooShort')}
                                        icon="icon-warning-sign"
                                    />
                                )}
                                <OptionBoxRadio
                                    required
                                    requiredMessage={_('mediamanagement:video.tooltipText')}
                                    name="albumType"
                                    iconPrefix="icon-"
                                    options={this.videoTypeOptions.filter((option) => option.value !== 'campaign')}
                                    value={''}
                                    disabledOptions={this.disabledAlbumTypeOptions}
                                />
                            </MediaFormGroupRow>
                        )}

                        {this.showCampaignSelection && (
                            <MediaFormGroupRow
                                label={`${_('mediamanagement:video.campaign.label')}:`}
                                tooltipText={_('mediamanagement:video.campaign.tooltipText')}
                            >
                                <Dropdown
                                    required
                                    requiredMessage={_('mediamanagement:video.chooseCampaign')}
                                    name="campaign"
                                    value=""
                                    options={this.campaignOptions}
                                    placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                                />
                            </MediaFormGroupRow>
                        )}

                        {this.showTitle && (
                            <MediaFormGroupRow
                                label={`${_('mediamanagement:video.titles.tooltipText')}:`}
                                tooltipText={_('mediamanagement:video.titles.tooltipText')}
                            >
                                <MultiLangInputfield
                                    required
                                    requiredMessage={_('mediamanagement:video.titles.requiredMessage')}
                                    validations="isValidMultiLangField"
                                    validationError={_('common:validationMessage.isValidMultiLangField')}
                                    name="titles"
                                    value={this.props.data.titles || {}}
                                    placeholder={_('mediamanagement:video.titles.placeholder')}
                                    langs={spokenLangs}
                                    maxLength={60}
                                />
                            </MediaFormGroupRow>
                        )}

                        {this.showDescription && (
                            <MediaFormGroupRow
                                label={`${_('mediamanagement:video.descriptions.label')}:`}
                                tooltipText={_('mediamanagement:video.descriptions.tooltipText')}
                            >
                                <MultiLangTextareafield
                                    required
                                    requiredMessage={_('mediamanagement:video.descriptions.requiredMessage')}
                                    validations="isValidMultiLangField"
                                    validationError={_('common:validationMessage.isValidMultiLangField')}
                                    name="descriptions"
                                    value={this.props.data.descriptions}
                                    placeholder={_('mediamanagement:video.descriptions.placeholder')}
                                    langs={spokenLangs}
                                    maxLength={2000}
                                />
                            </MediaFormGroupRow>
                        )}

                        {this.showPreviewPictures && (
                            <MediaFormGroupRow
                                label={_('mediamanagement:video.previewPics.label') + ':'}
                                tooltipText={_('mediamanagement:video.previewPics.tooltipText')}
                            >
                                <div className="previewpic-container">
                                    <div className="previewpic-box" onClick={this.onClickPreviewPicture16}>
                                        <div className="pic">
                                            <div className="pic__url" style={this.previewPictureStyleFsk16}/>
                                            {this.previewPicWarningText(16) && (
                                                <div className="warning-box">
                                                    <div className="icon-warning-sign iconbox"/>
                                                    <div className="warningtext">{this.previewPicWarningText(16)}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="status">
                                            <div className="icon-fsk-16"/>
                                            <div className="title">{_('mediamanagement:video.softcore')}</div>
                                        </div>
                                    </div>

                                    <div className="previewpic-box" onClick={this.onClickPreviewPicture18}>
                                        <div className="pic">
                                            <div className="pic__url" style={this.previewPictureStyleFsk18}/>
                                            {this.previewPicWarningText(18) && (
                                                <div className="warning-box">
                                                    <div className="icon-warning-sign iconbox"/>
                                                    <div className="warningtext">{this.previewPicWarningText(18)}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="status">
                                            <div className="icon-fsk-18"/>
                                            <div className="title">{_('mediamanagement:video.hardcore')}</div>
                                        </div>
                                    </div>

                                    <Modalbox
                                        name="modalsingleuploader"
                                        state={this.showModalBoxFsk16}
                                        onKeyPressESC={this.onClickModalClose}
                                    >
                                        <MediaSelector
                                            type="videoPreview"
                                            initialTab="videoPreviewPool"
                                            editor="landscape_16_9"
                                            targetUmaId={this.props.data.albumId}
                                            pictureType="videoPreview16"
                                            onClose={this.onClickModalClose}
                                            onSubmit={this.setVideoPreviewPicture}
                                            data={this.props.data}
                                            picturePoolFilter={this.getPicturePoolFilter(16)}
                                        />
                                    </Modalbox>

                                    <Modalbox
                                        name="modalsingleuploader"
                                        state={this.showModalBoxFsk18}
                                        onKeyPressESC={this.onClickModalClose}
                                    >
                                        <MediaSelector
                                            type="videoPreview"
                                            initialTab="videoPreviewPool"
                                            editor="landscape_16_9"
                                            targetUmaId={this.props.data.albumId}
                                            targetParams={this.props.targetParams}
                                            pictureType="videoPreview18"
                                            onClose={this.onClickModalClose}
                                            onSubmit={this.setVideoPreviewPicture}
                                            data={this.props.data}
                                            picturePoolFilter={this.getPicturePoolFilter(18)}
                                        />
                                    </Modalbox>
                                </div>
                            </MediaFormGroupRow>
                        )}

                        {this.showTags && (
                            <MediaFormGroupRow
                                type="list"
                                label={`${_('mediamanagement:video.tags.label')}:`}
                                tooltipText={_('mediamanagement:video.tags.tooltipText')}
                            >
                                <TagListInputAutocomplete
                                    name="tags"
                                    value={this.props.data.tags}
                                    autocompleteUrl={this.autocompleteUrl}
                                    maxLength={10}
                                    placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                                />
                            </MediaFormGroupRow>
                        )}

                        {this.showPrice && (
                            <MediaFormGroupRow
                                label={`${_('mediamanagement:video.price.label')}:`}
                                tooltipText={_('mediamanagement:video.price.tooltipText')}
                            >
                                <Range
                                    name="price"
                                    start={2.49}
                                    step={0.5}
                                    range={{min: 0.49, max: this.props.data.maxPrice}}
                                    unit="euro"
                                    value={Math.max(0.49, this.props.data.price)}
                                    onChange={this.onChangePrice}
                                    disabledInputOnly={true}
                                    disabled={this.state.isAlbumLocked}
                                />
                            </MediaFormGroupRow>
                        )}

                        {this.showPrice && (
                            <MediaFormGroupRow
                                label={_('mediamanagement:video.discountPercent.label')}
                                tooltipText={_('mediamanagement:video.discountPercent.tooltipText')}
                            >
                                <Range
                                    name="discountPercent"
                                    start={0}
                                    step={1}
                                    range={{min: 0, max: 80}}
                                    unit="percent"
                                    value={this.props.data.discountPercent}
                                    disabledInputOnly={true}
                                />
                            </MediaFormGroupRow>
                        )}

                        {this.showPrice && (
                            <MediaFormGroupRow label={`${_('mediamanagement:video.discountPercent.dateLabel')}:`}>
                                <DatePickerContainer style={{padding: 8}}>
                                    <DatePicker
                                        name="discount"
                                        from={this.props.data.discountStart}
                                        to={this.props.data.discountEnd}
                                    />
                                </DatePickerContainer>
                            </MediaFormGroupRow>
                        )}

                        {this.isShop && (
                            <MediaFormGroupRow label={`${_('mediamanagement:video.releaseDate.label')}:`}>
                                <DatePickerContainer style={{padding: 8, flexDirection: 'column'}}>
                                    <DatePicker
                                        name="releaseDate"
                                        from={this.props.data.releaseDate}
                                        single={true}
                                        disabled={!this.props.data.isReleaseDateEditable}
                                        showTime={true}
                                    />
                                    <MediaFormDateDescription>
                                        {_('mediamanagement:video.releaseDate.note')}
                                    </MediaFormDateDescription>
                                </DatePickerContainer>
                            </MediaFormGroupRow>
                        )}

                        <MediaFormGroupRow label={`${_('mediamanagement:video.flagFetish.label')}:`}>
                            <div style={{flexDirection: 'column'}}>
                                <div style={{alignItems: 'center'}}>
                                    <OptionBoxOnOff
                                        name="flagFetish"
                                        value={this.props.data.hasFlagFetish}
                                        valueOn="yes"
                                        valueOff="no"
                                        labelOn={_('components:yesNoPool.yes')}
                                        labelOff={_('components:yesNoPool.no')}
                                        disabled={!this.props.data.isFlagFetishEditable}
                                        css={{flex: '0 1 auto'}}
                                    />
                                </div>
                                <FetishHelpcenterLink/>
                                <MediaFormOptionDescription>
                                    {_('mediamanagement:video.flagFetish.note')}
                                </MediaFormOptionDescription>
                            </div>
                        </MediaFormGroupRow>

                        {this.isShop && hasVXModelsRole && (
                            <MediaFormGroupRow
                                label={`${_('mediamanagement:video.flag365days.label')}:`}
                                tooltipText={_('mediamanagement:video.flag365days.tooltipText')}
                            >
                                <DailyBonusOptInWrapper>
                                    <div style={{flexDirection: 'column'}}>
                                        <div style={{alignItems: 'center'}}>
                                            <OptionBoxOnOff
                                                name="flag365daysCalendar"
                                                value={this.props.data.hasFlag365daysCalendar}
                                                valueOn="yes"
                                                valueOff="no"
                                                labelOn={_('components:yesNoPool.yes')}
                                                labelOff={_('components:yesNoPool.no')}
                                                disabled={!this.state.previewUrl16}
                                                style={{flex: '0 1 auto'}}
                                                displaySelectedOptionOnly={this.state.isAlbumLocked}
                                            />
                                            <DailyBonusHelpcenterLink/>
                                        </div>
                                        <MediaFormOptionDescription
                                            css={this.state.vip30Scheduling ? {color: GREEN} : {}}
                                        >
                                            {this.state.vip30Scheduling
                                                ? _('mediamanagement:video.vip30.scheduleNote', {
                                                    sprintf: [
                                                        format(
                                                            new Date(
                                                                this.state.vip30Scheduling.year,
                                                                this.state.vip30Scheduling.month - 1
                                                            ),
                                                            _('mediamanagement:video.vip30.monthFormat'),
                                                            {locale: lang === ApiLang.de ? de : en}
                                                        ),
                                                    ],
                                                })
                                                : _('mediamanagement:video.releaseDate.note365')}
                                        </MediaFormOptionDescription>
                                    </div>
                                </DailyBonusOptInWrapper>
                            </MediaFormGroupRow>
                        )}

                        {this.showStatus && (
                            <MediaFormGroupRow
                                label={`${_('mediamanagement:video.status.label')}:`}
                                tooltipText={_('mediamanagement:video.status.tooltipText')}
                            >
                                <OptionBoxOnOff
                                    name="status"
                                    value={this.props.data.status}
                                    valueOn="online"
                                    valueOff="offline"
                                    labelOn={_('mediamanagement:picfolder.status.button.online')}
                                    labelOff={_('mediamanagement:picfolder.status.button.offline')}
                                    onChange={this.onStatusChange}
                                    disabled={this.state.isAlbumLocked}
                                />
                            </MediaFormGroupRow>
                        )}
                    </Fragment>
                )}

                <Fieldset
                    margin="20px 0"
                    legend={
                        <LabelStyled>
                            <TextInBorderSpan>
                                {' '}
                                {_('mediamanagement:mediamanagement:actorRequest.headline')}
                            </TextInBorderSpan>
                        </LabelStyled>
                    }
                >
                    <Box display={'flex'} flexDirection={'column'} style={{marginLeft: '9px'}}>
                        <SectionLabelBox>
                            <Box
                                style={{
                                    fontSize: '16px',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontWeight: 'bold',
                                    color: '#3C3D3E',
                                }}
                            >
                                {_('mediamanagement:mediamanagement:actorRequest.intro')}
                            </Box>
                            <Box style={{fontSize: '12px', fontFamily: 'Roboto, sans-serif', color: '#888888'}}>
                                {_('mediamanagement:mediamanagement:actorRequest.pleaseUpload')}
                                <br/>
                                {_('mediamanagement:mediamanagement:actorRequest.reasoning')}
                            </Box>
                        </SectionLabelBox>

                        <Box pt={2}>
                            <SimpleCheckbox
                                name="allIDCardsUploaded"
                                label={_('mediamanagement:mediamanagement:actorRequest.idsText')}
                                value={this.state.allIDCardsUploadedState}
                                onChange={this.onCheckboxChange}
                                required={true}
                                disabled={this.isRatingDelayed}
                                checked={this.state.allIDCardsUploadedState}
                            />
                        </Box>
                        <Box pt={2}>
                            <SimpleCheckbox
                                name="allModelReleaseFormsUploaded"
                                label={_('mediamanagement:mediamanagement:actorRequest.modelReleaseFormText')}
                                value={this.state.allModelReleaseFormsUploadedState}
                                onChange={this.onCheckboxChange}
                                required={true}
                                disabled={this.isRatingDelayed}
                                checked={this.state.allModelReleaseFormsUploadedState}
                            />
                        </Box>

                        {this.isRating && (
                            <Box
                                style={{
                                    fontSize: '12px',
                                    color: '#FFB033',
                                    marginLeft: '-11px',
                                    marginRight: ' 16px',
                                    verticalAlign: 'middle',
                                }}
                                display={'inline-flex'}
                                alignItems={'center'}
                                pt={2}
                            >
                                <Box padding={'9px'}>
                                    <InfoIcon/>
                                </Box>
                                <Box>{_('mediamanagement:mediamanagement:actorRequest.checkBySupport')}</Box>
                            </Box>
                        )}

                        {this.isRatingDelayed && (
                            <Box
                                style={{
                                    fontSize: '12px',
                                    color: '#FF4D3C',
                                    marginLeft: '-11px',
                                    marginRight: ' 16px',
                                    verticalAlign: 'middle',
                                }}
                                display={'inline-flex'}
                                alignItems={'center'}
                                pt={2}
                            >
                                <Box padding={'9px'}>
                                    <InfoIcon/>
                                </Box>
                                <Box>
                                    <strong>
                                        {_('mediamanagement:mediamanagement:actorRequest.rejectedPrefix')}
                                    </strong>
                                    <br/>
                                    {_('mediamanagement:mediamanagement:actorRequest.rejectedSuffix')}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Fieldset>

                <MediaFormButtonGroup position="right">
                    {(this.showDeleteButton || this.props.data.hasFlagPornmeMigration === 'yes') &&
                        !this.state.isAlbumLocked && (
                            <Button
                                label={_('common:button.delete')}
                                type="button"
                                className="button--red"
                                onClick={this.onClickDelete}
                            />
                        )}
                    {this.showResetButton && (
                        <Button label={_('common:button.cancel')} type="button" onClick={this.onClickReset}/>
                    )}
                    {this.showSaveButton && (
                        <Button
                            className="button--blue"
                            disabled={
                                !(
                                    this.state.allIDCardsUploadedState && this.state.allModelReleaseFormsUploadedState
                                ) || this.state.isAlbumLocked
                            }
                            label={_('common:button.save')}
                            type="submit"
                        />
                    )}
                </MediaFormButtonGroup>
            </Formsy.Form>
        );
    }
}

VideoForm.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number,
        albumType: PropTypes.string,
        campaign: PropTypes.string,
        titles: PropTypes.object,
        description: PropTypes.object,
        tags: PropTypes.array,
        price: PropTypes.number,
        isDeletable: PropTypes.bool,
    }),
    isLoading: PropTypes.bool,
    showAlbumType: PropTypes.bool,
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
};

VideoForm.defaultProps = {
    data: {
        id: 0,
        albumType: '',
        campaign: '',
        titles: {},
        descriptions: {},
        tags: [],
        price: 0.0,
        isDeletable: false,
    },
    isLoading: false,
    onSubmit: () => {
    },
    onDelete: () => {
    },
    showAlbumType: false,
};

VideoForm.debug = false;

export default withApollo(withAppState(VideoForm));
