import React from 'react';

import useCountdown from '../../../hooks/useCountdown';
import { _ } from '../../../util/translate';
import { useDeepLinkBeta } from '../../../hooks';
import { VXModelsMobileTargetEnum } from '../../../graphql/VXModels/types';
import { ButtonLink } from '../../../atoms';

function CurrentCampaignCountdown(props: { timestamp: string }) {
  const [days, hours, minutes] = useCountdown(props.timestamp);
  const { data: betaDeepLinkData, refetch } = useDeepLinkBeta(VXModelsMobileTargetEnum.dashboard);

  const goToBeta = () => {
    refetch();
    const dashboardLink = betaDeepLinkData?.auth?.betaDeeplink || '';
    window.open(dashboardLink, '_blank');
  };

  if ((days === 0 && hours === 0 && minutes === 0) || days < 0 || hours < 0 || minutes < 0) {
    return null;
  }

  const paddedHours = String(hours).padStart(2, '0');
  const paddedMinutes = String(minutes).padStart(2, '0');

  return (
    <div css={{ fontSize: 14, alignItems: 'center', padding: '0 32px', '*': { color: '#f9af38' } }}>
      <div css={{ alignItems: 'flex-end' }}>
        <div css={{ marginRight: 8 }}>
          <span className="icon-clock" css={{ marginRight: 4 }} />
          <span>{_('marketing:texts.competitionInProgress')}</span>
        </div>

        <div>
          {_('common:countdown.abbreviated', { days, hours: paddedHours, minutes: paddedMinutes })}
        </div>
      </div>

      <ButtonLink
        to=""
        onClick={goToBeta}
        label={_('common:texts.joinNow')}
        theme="link-yellow"
        css={{ marginLeft: 8, fontSize: 14 }}
      />
    </div>
  );
}

export default CurrentCampaignCountdown;
