import { Broadcast } from '../../graphql/VXServicesTelegram/types';
import { BroadcastSource, BroadcastStatus } from './types';

const mappingFriendlyName = {
  [BroadcastSource.vxmodels]: 'VXModels',
  [BroadcastSource.telegrambot]: 'Telegram Bot',
};

export const calcSource = ({ referenceId }: Pick<Broadcast, 'referenceId'>): BroadcastSource => {
  if (referenceId) {
    return BroadcastSource.vxmodels;
  }
  return BroadcastSource.telegrambot;
};

export const getMailingId = (referenceId: string): number | undefined => {
  // vxm_{mailingId}_{recipientId}
  const result = /^vxm_(\d+)_(\d+)/.exec(referenceId);

  return result ? Number(result[1]) : undefined;
};

export const getSourceFriendlyName = (source: BroadcastSource): string =>
  mappingFriendlyName[source];

export const getStatusColor = (
  status: BroadcastStatus
): 'primary' | 'secondary' | 'error' | 'warning' => {
  switch (status) {
    case BroadcastStatus.done:
      return 'secondary';
    case BroadcastStatus.pending:
      return 'warning';
    case BroadcastStatus.canceled:
    case BroadcastStatus.revoked:
    case BroadcastStatus.failed:
    case BroadcastStatus.revoked:
      return 'error';
    case BroadcastStatus.running:
    default:
      return 'primary';
  }
};
