import React, { FC, memo, useState, useEffect } from 'react';

import { InstantMessagingPlatformEnum, Query } from '../../../../../graphql/VXModels/types';
import { FancyError, Spinner } from '../../../../../components';
import { useQuery } from '@apollo/react-hooks';
import { _ } from '../../../../../util/translate';
import { QUERY_MODEL_INSTANTMESSAGING_ACCOUNT } from '../../../../../graphql/VXModels/queries';
import { WHITE_2, WHITE_3 } from '../../../../../camtool-styles';
import ChannelList from './ChannelList';
import { useRouteMatch } from 'react-router';
import { useTelegramUser, useTelegramUserlist } from '../../../hooks';
import { UserFilter } from '../../../../../graphql/VXServicesTelegram/types';
import ChannelFilter from './ChannelFilter';
import { useInterval } from '../../../../../hooks';
import { INITIAL_USER_LIMIT } from '../../../hooks/useTelegramUserlist';
import { CHAT_FETCH_INTERVAL } from '../utils';
import FilterBar from '../FilterBar';

// todo: dynamically generate types out of actual object
export type Filters =
  | 'online'
  | 'unread'
  | 'blocked'
  | 'premium'
  | 'onlyTipping'
  | 'lastInteraction'
  | 'offline'
  | 'vipSubscribedSince'
  | 'subscribedSince';
export interface FilterData {
  key: number;
  label: Filters;
}

// creates array of FilterData objects without "subscribed" and "name" label and containing only truthy attributes (active filters)
const convertFilterToArray = (filter: UserFilter) =>
  Object.keys(filter)
    .filter(
      (key) =>
        (key !== 'subscribed' && key !== 'name' && filter[key]) ||
        (key === 'online' && filter[key] !== undefined)
    )
    .map((k, i) => ({ key: i, label: k }));

export const defaultChatFilter = {
  name: '',
  subscribed: true,
  online: undefined,
  premium: undefined,
  blocked: undefined,
  unread: undefined,
  onlyTipping: undefined,
};

const buildFilterFromParams = () => {
  const param = new URLSearchParams(window.location.search.split('?')[1]);
  if (param) {
    const paramFilter = {};
    for (const [key, value] of param) {
      if (['subscribed', 'premium', 'online', 'blocked', 'unread', 'onlyTipping'].includes(key)) {
        paramFilter[key] = value === 'true';
      }
    }
    const defaultFilter = { ...defaultChatFilter, ...paramFilter };

    return defaultFilter;
  } else return defaultChatFilter;
};

const ChatsMobile: FC = memo(() => {
  const defaultFilter = buildFilterFromParams();
  const [userFilter, setUserFilter] = useState<UserFilter>(defaultFilter);
  // uiFilter = filter that reflects UI since not every "active" filter in UI is actually active (only when applying/sending request)
  const [uiFilter, setUiFilter] = useState<UserFilter>(defaultFilter);
  const userFilterUI = convertFilterToArray(userFilter) as FilterData[];
  const { path } = useRouteMatch();
  const match = useRouteMatch<{ userId: string }>(path + '/:userId([0-9]+)'); // @note: \d+ does not work here ???
  const userId = Number(match ? match.params.userId : 0);
  const { data: user } = useTelegramUser(userId, { skip: userId === 0 });
  const { loadUsers, data: userList, currency } = useTelegramUserlist();
  const users = userList?.users;

  const { data, loading } = useQuery<Query>(QUERY_MODEL_INSTANTMESSAGING_ACCOUNT, {
    variables: { platform: InstantMessagingPlatformEnum.TELEGRAM },
  });

  const handleDelete = (chipToDelete: Filters) => () => {
    setUiFilter((prevUserFilter) => ({
      ...prevUserFilter,
      [chipToDelete]: prevUserFilter[chipToDelete] === undefined ? true : undefined,
    }));

    setUserFilter((prevUserFilter) => ({
      ...prevUserFilter,
      [chipToDelete]: prevUserFilter[chipToDelete] === undefined ? true : undefined,
    }));
  };

  // setting up loadUsers function for ChannelFilter, Searchbox and ChannelList
  // todo: only call search function at 3 or more characters for name
  useEffect(
    () =>
      loadUsers({
        variables: {
          filter: userFilter,
          limit: INITIAL_USER_LIMIT,
        },
      }),
    [userFilter]
  );

  useInterval(
    () =>
      loadUsers({
        variables: {
          filter: userFilter,
          limit: INITIAL_USER_LIMIT,
        },
      }),
    CHAT_FETCH_INTERVAL // every subsequential call
  );

  if (loading) {
    return <Spinner />;
  }

  const accountData = data?.model?.instantMessaging?.account;
  const isInitialized = accountData?.isInitialized;
  const active = accountData?.active;

  if (!(isInitialized && active)) {
    return (
      <FancyError message={_('telegram:chat.needsActivation')}>
        {_('telegram:chat.support.text')}
      </FancyError>
    );
  }

  return (
    <article css={{ width: '100%', overflow: 'auto', minHeight: '100%' }}>
      <div
        css={{
          flexDirection: 'column',
          flex: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <div
          css={{
            backgroundColor: WHITE_2,
            border: `1px solid ${WHITE_3}`,
            borderRadius: 2,
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <ChannelFilter
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            uiFilter={uiFilter}
            setUiFilter={setUiFilter}
          />
          {userFilterUI.length > 0 && (
            <FilterBar uiFilter={uiFilter} filterData={userFilterUI} handleDelete={handleDelete} />
          )}
          <ChannelList users={users} selectedUser={user} currency={currency} path={path} />
        </div>
      </div>
    </article>
  );
});

export default ChatsMobile;
