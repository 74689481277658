import queryString from 'query-string';
import { APP_IS_DEVELOPMENT, REACT_APP_DEV_PHPSTORM_DEBUG, APP_API_HOST, APP_SCOPE } from './env';
import { getUserData } from './UserData';

export const getGraphQLEndpoint = (lang) => {
  const xdebug =
    APP_IS_DEVELOPMENT && REACT_APP_DEV_PHPSTORM_DEBUG
      ? `&XDEBUG_SESSION_START=${REACT_APP_DEV_PHPSTORM_DEBUG}`
      : '';

  return `${APP_API_HOST}/graphql?lang=${lang}&scope=${APP_SCOPE}${xdebug}`;
};

export const buildRestEndpoint = (uri, queryParams = {}) => {
  const enrichedQueryParams = {
    scope: APP_SCOPE,
    ...(APP_IS_DEVELOPMENT && REACT_APP_DEV_PHPSTORM_DEBUG
      ? { XDEBUG_SESSION_START: REACT_APP_DEV_PHPSTORM_DEBUG }
      : {}),
    ...queryParams,
  };

  const queryStr = queryString.stringify(enrichedQueryParams);

  const {
    auth: { id: userId },
  } = getUserData();

  //e.g. https://api.vxmodels.com/v1/camtool/user/4692886/vxcash/adlinks

  return `${APP_API_HOST}/v1/camtool/user/${userId}${uri}?${queryStr}`;
};
