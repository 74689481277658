import React, { FC, ReactNode } from 'react';
import { share } from '../Icon/libraries/glyph';
import styled from '@emotion/styled';
import { Icon } from '../../atoms';
import { GRAY_2, GRAY_1, BLUE, BLUE_VARIANT } from '../../camtool-styles';
import { IconType } from '../Icon/Icon';

const HeaderContainer = styled.div`
  flex-direction: column;
  flex: 0 0 315px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const HeaderImage = styled.div<Pick<ThreeBadgesBannerProps, 'background'>>`
  position: absolute;
  ${(props): string =>
    props.background
      ? `background-image: url(${props.background})`
      : `background: transparent linear-gradient(180deg, ${BLUE_VARIANT} 0%, ${BLUE} 100%) 0% 0% no-repeat padding-box`};
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const HeaderContent = styled.header<Pick<ThreeBadgesBannerProps, 'primaryThemeColor'>>`
  flex-direction: column;
  align-items: center;
  z-index: 1;
  justify-content: center;
  min-width: 430px;
  flex: 1 0 auto;
  color: ${(props): string | undefined => props.primaryThemeColor};
`;

const ImageRow = styled.div`
  min-width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 28px;
  width: 550px;
`;

const BadgePrimaryText = styled.p`
  max-width: 200px;
  text-align: center;
  font-size: 17px;
`;

const BadgeSecondaryText = styled.p`
  margin-top: 5px;
  max-width: 200px;
  text-align: center;
  font-size: 15px;
`;

const IconWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  span,
  img,
  svg {
    margin-bottom: 6px;
  }
`;

const ShareIcon = styled(Icon)<Pick<ThreeBadgesBannerProps, 'iconColor'>>`
  margin-top: 20px;
  color: ${(props): string | undefined => props.iconColor};
`;

const HeaderText = styled.p<Pick<ThreeBadgesBannerProps, 'secondaryThemeColor'>>`
  margin-top: 11px;
  width: 95%;
  max-width: 815px;
  text-align: center;
  line-height: 1.5;
  color: ${(props): string | undefined => props.secondaryThemeColor};
  font-size: 14px;
`;

interface ThreeBadgesBannerProps {
  background?: string;
  leftBadge?: IconType;
  centerBadge?: IconType;
  rightBadge?: IconType;
  primaryHeadline?: string;
  secondaryHeadline?: ReactNode;
  primaryThemeColor?: string;
  secondaryThemeColor?: string;
  iconColor?: string;
  leftBadgePrimaryText?: string;
  leftBadgeSecondaryText?: string;
  centerBadgePrimaryText?: string;
  centerBadgeSecondaryText?: string;
  rightBadgePrimaryText?: string;
  rightBadgeSecondaryText?: string;
}

const ThreeBadgesBanner: FC<ThreeBadgesBannerProps> = ({
  background,
  leftBadge,
  centerBadge,
  rightBadge,
  primaryHeadline = '',
  secondaryHeadline = null,
  primaryThemeColor = GRAY_1,
  secondaryThemeColor = GRAY_2,
  iconColor = primaryThemeColor,
  leftBadgePrimaryText = '',
  leftBadgeSecondaryText = '',
  centerBadgePrimaryText = '',
  centerBadgeSecondaryText = '',
  rightBadgePrimaryText = '',
  rightBadgeSecondaryText = '',
}) => {
  return (
    <HeaderContainer>
      <HeaderImage background={background} />
      <HeaderContent primaryThemeColor={primaryThemeColor}>
        <h2 style={{ fontSize: 25 }}>{primaryHeadline && primaryHeadline}</h2>
        <HeaderText secondaryThemeColor={secondaryThemeColor}>
          {secondaryHeadline && secondaryHeadline}
        </HeaderText>
        {leftBadge && centerBadge && rightBadge && (
          <ImageRow>
            <IconWrapper>
              <Icon icon={leftBadge} size="50px" />
              {leftBadgePrimaryText && (
                <BadgePrimaryText> {leftBadgePrimaryText} </BadgePrimaryText>
              )}
              {leftBadgeSecondaryText && (
                <BadgeSecondaryText> {leftBadgeSecondaryText} </BadgeSecondaryText>
              )}
            </IconWrapper>
            <ShareIcon iconColor={iconColor} icon={share} size="32px" />
            <IconWrapper>
              <Icon icon={centerBadge} size="50px" />
              {centerBadgePrimaryText && (
                <BadgePrimaryText> {centerBadgePrimaryText} </BadgePrimaryText>
              )}
              {centerBadgeSecondaryText && (
                <BadgeSecondaryText> {centerBadgeSecondaryText} </BadgeSecondaryText>
              )}
            </IconWrapper>
            <ShareIcon iconColor={iconColor} icon={share} size="32px" />
            <IconWrapper>
              <Icon icon={rightBadge} size="50px" />
              {rightBadgePrimaryText && (
                <BadgePrimaryText> {rightBadgePrimaryText} </BadgePrimaryText>
              )}
              {rightBadgeSecondaryText && (
                <BadgeSecondaryText> {rightBadgeSecondaryText} </BadgeSecondaryText>
              )}
            </IconWrapper>
          </ImageRow>
        )}
      </HeaderContent>
    </HeaderContainer>
  );
};

export default ThreeBadgesBanner;
