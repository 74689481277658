import React, { FC } from 'react';
import { GRAY_2 } from '../../../../camtool-styles';
import { Button } from '../../../../atoms';
import { _ } from '../../../../util/translate';

interface Props {
  openModal: () => void;
}

const InsertNumber: FC<Props> = ({ openModal }) => {
  return (
    <div css={{ flexDirection: 'column', alignItems: 'center' }}>
      <Button css={{ width: '160px' }} theme={'blue'} onClick={openModal}>
        {_('service0900:PhoneSex.inactive.request.insert.headline')}
      </Button>
      <span css={{ marginTop: '9px', color: GRAY_2, fontStyle: 'italic' }}>
        {_('service0900:PhoneSex.inactive.request.insert.subInfo')}
      </span>
    </div>
  );
};

export default InsertNumber;
