import gql from 'graphql-tag';

export const MUTATION_MODEL_VXCHALLENGE_PARTICIPATE = gql`
  mutation MUTATION_MODEL_VXCHALLENGE_PARTICIPATE($challengeId: Int!) {
    model {
      vxChallenges {
        participateInChallenge(challengeId: $challengeId) {
          id
          type
          status
          startDate
          endDate
          targetValue
          targetValueSteps {
            value
            label
          }
          numberOfWinners
          participantsTotal
          participantsCompleted
          participantsList(status: chosenAsWinner) {
            userName
          }
          rewardValue
          redirectUri
          texts {
            title
            subTitle
            description
            reward
            condition
            trophyDescription
            unit
          }
          userStatistic {
            challengeId
            status
            registerDate
            completeDate
            awardDate
            actualValue
            isTocAccepted
          }
        }
      }
    }
  }
`;

export const MUTATION_MODEL_VXCHALLENGE_ACCEPT_TOC = gql`
  mutation MUTATION_MODEL_VXCHALLENGE_ACCEPT_TOC($tocName: VXGChallengeTocEnum!) {
    model {
      vxChallenges {
        acceptTOC(tocName: $tocName)
      }
    }
  }
`;
