import React, { FC, useRef, useState, useEffect } from 'react';
import { UserFilter } from '../../../../../graphql/VXServicesTelegram/types';
import { DARK_GRAY, WHITE, BLACK } from '../../../../../camtool-styles';
import { _ } from '../../../../../util/translate';
import styled from '@emotion/styled';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { defaultChatFilter } from './Chats';
import TelegramSearchField from './../TelegramSearchField';
import useClickOutsideCallback from '../../../../../hooks/useClickOutsideCallback';
import NewButton from '../../../../../atoms/Button/NewButton';
import FilterButton from '../FilterButton';
import { Crown } from '../../../../../atoms/Icon';
import BlockIcon from '@material-ui/icons/Block';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import IconBase from '../../../../../atoms/Icon/IconBase';
import { useTelegramUserlist } from '../../../hooks';
import { INITIAL_USER_LIMIT } from '../../../hooks/useTelegramUserlist';

const Container = styled.div<{ show: boolean }>`
  z-index: 2;
  background: ${WHITE};
  padding: 17px 20px;
  flex-direction: column;
  color: ${DARK_GRAY};
  flex: 0 0 158px;
  display: ${(props) => (props.show ? 'flex' : 'none !important')};
  position: absolute;
  left: 0;
  top: 44px;
  width: 100%;
  box-shadow: -3px 1px 6px 0px ${BLACK};

  // todo: implement min-width global constants in camtool-styles
  @media (min-width: 767px) {
    width: 320px;
    top: 76px;
  }
`;

export interface IProps {
  userFilter: UserFilter;
  setUserFilter: React.Dispatch<React.SetStateAction<UserFilter>>;
  uiFilter: UserFilter;
  setUiFilter: React.Dispatch<React.SetStateAction<UserFilter>>;
}

const ChannelFilter: FC<IProps> = ({ userFilter, setUserFilter, uiFilter, setUiFilter }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  // won't work anymore if filter object structure becomes nested
  const filterNotDefault =
    Object.entries(defaultChatFilter).toString() !== Object.entries(userFilter).toString();

  const toggleOptions = () => setShowOptions(!showOptions);
  useClickOutsideCallback(ref, () => setShowOptions(false));
  const { loadUsers, data: userList, loading } = useTelegramUserlist();
  const totalUsersPreview = userList.total;

  useEffect(() => {
    loadUsers({
      variables: {
        filter: uiFilter,
        limit: INITIAL_USER_LIMIT,
      },
      // don't actually reinitialize/rerender new user list
      fetchPolicy: 'no-cache',
    });
  }, [uiFilter]);

  return (
    <Box display="flex" flex="1">
      <Box display="flex" alignItems="center" width="338px" ml={{ xs: '5px', md: '0' }}>
        <TelegramSearchField userFilter={userFilter} setUserFilter={setUserFilter} />
        <Box ref={ref} color={{ xs: BLACK, sm: WHITE }}>
          <IconButton
            color={showOptions || filterNotDefault ? 'primary' : 'inherit'}
            onClick={toggleOptions}
          >
            <FilterListIcon />
          </IconButton>

          <Container show={showOptions}>
            <Box display="flex" alignItems="center">
              <FilterListIcon />
              <Typography variant="caption">{_('common:text.product')}</Typography>
            </Box>
            <Box my={3}>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <Box display="flex" gridGap={15}>
                    <FilterButton
                      label={_('common:text.online')}
                      onChange={() =>
                        setUiFilter((prevUserFilter) => ({
                          ...prevUserFilter,
                          online: !prevUserFilter.online ? true : undefined,
                        }))
                      }
                      active={!!uiFilter.online}
                    />
                    <FilterButton
                      label={<>{_('telegram:chat.filterbar.offline')}</>}
                      onChange={() =>
                        setUiFilter((prevUserFilter) => ({
                          ...prevUserFilter,
                          online: prevUserFilter.online
                            ? false
                            : prevUserFilter.online === undefined
                            ? false
                            : undefined,
                        }))
                      }
                      active={uiFilter.online === false}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box display="flex" gridGap={15}>
                    <FilterButton
                      label={<>{_('telegram:chat.filter.lastInteraction')}</>}
                      onChange={() =>
                        setUiFilter((prevUserFilter) => ({
                          ...prevUserFilter,
                          lastInteraction: !prevUserFilter.lastInteraction ? true : undefined,
                        }))
                      }
                      active={!!uiFilter.lastInteraction}
                    />

                    <FilterButton
                      label={
                        <>
                          <MonetizationOnIcon viewBox="0 0 24 26" />
                          {_('telegram:chat.filter.tipping')}
                        </>
                      }
                      onChange={() =>
                        setUiFilter((prevUserFilter) => ({
                          ...prevUserFilter,
                          onlyTipping: !prevUserFilter.onlyTipping ? true : undefined,
                        }))
                      }
                      active={!!uiFilter.onlyTipping}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box display="flex" gridGap={15}>
                    <FilterButton
                      label={
                        <>
                          <IconBase iconElement={<Crown />} viewBox="0 0 25.21 29" />
                          {_('telegram:chat.filter.premiumAbo')}
                        </>
                      }
                      onChange={() =>
                        setUiFilter((prevUserFilter) => ({
                          ...prevUserFilter,
                          premium: !prevUserFilter.premium ? true : undefined,
                        }))
                      }
                      active={!!uiFilter.premium}
                    />

                    <FilterButton
                      label={_('telegram:chat.filter.unread')}
                      onChange={() =>
                        setUiFilter((prevUserFilter) => ({
                          ...prevUserFilter,
                          unread: !prevUserFilter.unread ? true : undefined,
                        }))
                      }
                      active={!!uiFilter.unread}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box display="flex" gridGap={15}>
                    <FilterButton
                      label={<>{_('telegram:chat.filter.newVIPUser')}</>}
                      onChange={() =>
                        setUiFilter((prevUserFilter) => ({
                          ...prevUserFilter,
                          vipSubscribedSince: !prevUserFilter.vipSubscribedSince ? true : undefined,
                        }))
                      }
                      active={!!uiFilter.vipSubscribedSince}
                    />
                    <FilterButton
                      label={<>{_('telegram:chat.filter.newFreeUser')}</>}
                      onChange={() =>
                        setUiFilter((prevUserFilter) => ({
                          ...prevUserFilter,
                          subscribedSince: !prevUserFilter.subscribedSince ? true : undefined,
                        }))
                      }
                      active={!!uiFilter.subscribedSince}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <FilterButton
                    label={<>{_('telegram:chat.filter.blockedCustomers')}</>}
                    onChange={() =>
                      setUiFilter((prevUserFilter) => ({
                        ...prevUserFilter,
                        blocked: !prevUserFilter.blocked ? true : undefined,
                      }))
                    }
                    active={!!uiFilter.blocked}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box display="flex" flexDirection="column" maxWidth="100%">
                <NewButton
                  loading={loading}
                  onClick={() => {
                    setUserFilter(uiFilter);
                    setShowOptions(false);
                  }}
                >
                  {_('common:button.apply') +
                    ` (${!loading ? totalUsersPreview : '?'} ${_('common:text.customers')})`}
                </NewButton>
                <NewButton
                  variant="text"
                  onClick={() => {
                    setUserFilter(defaultChatFilter);
                    setShowOptions(false);
                    setUiFilter(defaultChatFilter);
                  }}
                >
                  {_('common:button.reset')}
                </NewButton>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default ChannelFilter;
