import React from 'react';
import PropTypes from 'prop-types';
import { FinancesStore, FinancesActionCreators } from '../../../stores/Finance/Finances';
import ModalPayoutApplyDataList from './ModalPayoutApplyDataList';
import { FormatCurrency, Spinner, T } from '../../../components';

const STORE = 'payoutrequest';

function getStateFromStores() {
  return {
    payout: FinancesStore.get(STORE),
  };
}

class ModalPayoutApply extends React.Component {
  constructor(props) {
    super(props);
    this.state = getStateFromStores();
    this.loading = true;
  }

  componentDidMount() {
    FinancesStore.addChangeListener(STORE, this.onStoreChange);
    FinancesActionCreators.getPayoutRequestData();
  }

  componentWillUnmount() {
    FinancesStore.removeChangeListener(STORE, this.onStoreChange);
  }

  onStoreChange = () => {
    this.loading = false;
    let state = getStateFromStores();
    this.setState(state);
  };

  savePayoutRequest = () => {
    FinancesActionCreators.savePayoutRequest();
    this.props.closeRequest(true);
  };

  goToPayoutData = () => this.props.history.push('/camtool/account/payoutdata');

  close = () => {
    this.props.closeRequest(false);
  };

  render = () => {
    return (
      <div className="modalgrid__box">
        <div className="modalgrid__box__header">
          <div className="icon-money" />
          <div>
            <T _={'finances:payout.request.header'} />
          </div>
          <div className="seperator" />
          <div className="icon-remove options" onClick={this.close} />
        </div>

        <div className="modalgrid__box__content">
          <div className="modalgrid__box__content__column spinner-container">
            {this.loading && <Spinner />}

            <div className="payoutamount">
              <FormatCurrency value={this.state.payout.payableAmount} />
            </div>

            {this.state.payout.payableAmount < this.state.payout.minAmountPayable && (
              <div className="payoutnotificationbox payoutminamountnotification">
                <div onClick={this.goToPayoutData}>
                  <div className="notification">
                    <div className="text">
                      <T _={'finances:payout.request.error.minamountTitle'} />
                    </div>
                  </div>
                  <div className="sub-notification">
                    <div className="text" css={{ justifyContent: 'center' }}>
                      <T _={'finances:payout.request.error.minamountInfo'} />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={`payoutnotificationbox payout${this.state.payout.requestStatus}`}>
              <div onClick={this.savePayoutRequest}>
                <div className="notification">
                  <div className="icon-money icon" />
                  <div className="text">
                    <T _={'finances:payout.request.action.sendRequest'} />
                  </div>
                </div>
              </div>
            </div>

            <ModalPayoutApplyDataList data={this.state.payout.billingList} />

            <article className="modalpayoutapplydatalistsum">
              <section>
                <article>
                  <section>
                    <article>
                      <div>Summe</div>
                      <div>
                        <FormatCurrency value={this.state.payout.payableAmount} />
                      </div>
                    </article>
                  </section>
                </article>
              </section>
            </article>
          </div>
        </div>
      </div>
    );
  };
}

ModalPayoutApply.propTypes = {
  closeRequest: PropTypes.func.isRequired,
};

export default ModalPayoutApply;
