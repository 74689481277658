import { Dispatch, useEffect, useState } from 'react';
import {
  IBenefitsCreateAction,
  IBenefitsCreateState,
  RedemptionAmount,
  RedemptionType,
  VoucherType,
} from '../../types';

type ContentData = any;

interface IData {
  redemptionType: RedemptionType;
  redemptionAmount: RedemptionAmount;
  contentData: ContentData;
}

interface IMethods {
  setRedemptionType: (params: RedemptionType) => void;
  setRedemptionAmount: (params: RedemptionAmount) => void;
  setContentData: (params: ContentData) => void;
}

const useContent = (
  voucherType: VoucherType,
  state: IBenefitsCreateState,
  dispatch: Dispatch<IBenefitsCreateAction>,
  initialContentData?: any
): [IData, IMethods] => {
  const initialState = {
    redemptionType: state.voucherContent.length > 0 ? state.voucherContent[0].redemptionType : '',
    redemptionAmount: state.voucherContent.length > 0 ? state.voucherContent[0].redemptionAmount : -1, // prettier-ignore
    contentData: state.voucherContent.length > 0 ? state.voucherContent[0].contentData : initialContentData || '' // prettier-ignore
  };

  const [redemptionType, setRedemptionType] = useState<RedemptionType>(initialState.redemptionType);
  const [redemptionAmount, setRedemptionAmount] = useState<RedemptionAmount>(initialState.redemptionAmount); // prettier-ignore
  const [contentData, setContentData] = useState<ContentData>(initialState.contentData);

  useEffect(() => {
    if (contentData && redemptionType && redemptionAmount > -1) {
      if (state.voucherContent.length > 0) {
        dispatch({
          type: 'REMOVE_VOUCHER_CONTENT',
          payload: {
            voucherContent: { contentId: 1 },
          },
        });
      }

      dispatch({
        type: 'ADD_VOUCHER_CONTENT',
        payload: {
          voucherContent: {
            contentId: 1,
            voucherType: voucherType,
            contentData,
            redemptionType,
            redemptionAmount,
          },
        },
      });
    } else {
      if (state.voucherContent.length > 0) {
        dispatch({
          type: 'REMOVE_VOUCHER_CONTENT',
          payload: {
            voucherContent: { contentId: 1 },
          },
        });
      }
    }
  }, [contentData, redemptionType, redemptionAmount]);

  return [
    { contentData, redemptionType, redemptionAmount },
    { setRedemptionType, setRedemptionAmount, setContentData },
  ];
};

export default useContent;
