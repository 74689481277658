import React from 'react';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import Day from './Day';
import { ChangeMonthButton, Container, DayLabel } from './styles';
import { GRAY_3 } from '../../camtool-styles';
import { AppStateContextValue, withAppState } from '../../util/AppState';
import { ApiLang } from '../../graphql/VXModels/types';
import { _ } from '../../util/translate';

const WEEK_DAYS = 7;
const TOTAL_DAYS = 42;

interface IProps {
  children: (props: any) => any;
  from: string;
  name: string;
  render?: (props: any) => any;
  to: string;
  single?: boolean /** if true, only one date (instead of two) can be picked */;
  disabled?: boolean /** if true, DatePicker can't be used */;
  showTime?: boolean /** if true, show a time picker */;
  className?: string;
  onFromChange?: (datetime: string) => void;
  onFromChangeWithoutTime?: (datetime: string) => void;
  onToChangeWithoutTime?: (datetime: string) => void;
  onRangeChange?: (datetime: string) => void;
  disablePastDate?: boolean;
  showBorder?: boolean;
  appState: AppStateContextValue;
}

enum SelectionStep {
  firstDate = 'firstDate',
  secondDate = 'secondDate',
  none = 'none',
}

interface IState {
  current: number;
  from: number;
  to: number;
  lastSelectionStep: SelectionStep;
  visible: boolean;
  hr: number;
  min: number;
  sec: number;
}

class DatePicker extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = { disabled: false, from: '', single: false, to: '', showTime: false, showBorder: false }; // prettier-ignore

  private static getWeekdayLabels = () => [
    _('common:date.weekdays.short.sunday'),
    _('common:date.weekdays.short.monday'),
    _('common:date.weekdays.short.tuesday'),
    _('common:date.weekdays.short.wednesday'),
    _('common:date.weekdays.short.thursday'),
    _('common:date.weekdays.short.friday'),
    _('common:date.weekdays.short.saturday'),
  ];

  private static getMonthLabels = () => [
    _('common:date.month.january'),
    _('common:date.month.february'),
    _('common:date.month.march'),
    _('common:date.month.april'),
    _('common:date.month.may'),
    _('common:date.month.june'),
    _('common:date.month.july'),
    _('common:date.month.august'),
    _('common:date.month.september'),
    _('common:date.month.october'),
    _('common:date.month.november'),
    _('common:date.month.december'),
  ];

  public containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    const fromFromProps = props.from !== '' ? new Date(props.from).getTime() : -1;
    const to = props.to !== '' ? new Date(props.to).getTime() : -1;

    // prettier-ignore
    const newDate = (d => new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds())))(new Date(Date.now()));
    // for time
    const dateObjForTime = fromFromProps > -1 ? new Date(fromFromProps) : newDate;

    const current = fromFromProps > 0 ? fromFromProps : newDate.getTime();

    this.state = {
      current,
      from: fromFromProps,
      hr: Number(format(dateObjForTime, 'HH', { timeZone: 'Europe/Berlin' })),
      min: Number(format(dateObjForTime, 'mm', { timeZone: 'Europe/Berlin' })),
      sec: Number(format(dateObjForTime, 'ss', { timeZone: 'Europe/Berlin' })),
      to,
      lastSelectionStep: SelectionStep.none,
      visible: false,
    };
    this.containerRef = React.createRef();
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.from !== prevProps.from || this.props.to !== prevProps.to) {
      this.setState({
        from: this.props.from === '' ? -1 : new Date(this.props.from).getTime(),
        to: this.props.to === '' ? -1 : new Date(this.props.to).getTime(),
      });
    }

    if (
      typeof this.props.onFromChange === 'function' &&
      (this.state.from !== prevState.from ||
        this.state.hr !== prevState.hr ||
        this.state.min !== prevState.min ||
        this.state.sec !== prevState.sec)
    ) {
      this.props.onFromChange(new Date(this.getFromDateWithTime()).toISOString());
    }

    // prettier-ignore
    if (typeof this.props.onFromChangeWithoutTime === 'function' && this.state.from !== prevState.from) {
      this.props.onFromChangeWithoutTime(new Date(this.state.from).toISOString());
    }

    if (typeof this.props.onToChangeWithoutTime === 'function' && this.state.to !== prevState.to) {
      this.props.onToChangeWithoutTime(new Date(this.state.to).toISOString());
    }

    // prettier-ignore
    if (typeof this.props.onRangeChange === 'function' && (this.state.from !== prevState.from || this.state.to !== prevState.to)) {
      if (this.state.from === -1 || this.state.to === -1) this.props.onRangeChange('');
      else this.props.onRangeChange(new Date(this.state.from).toISOString() + ',' + new Date(this.state.to).toISOString()); // prettier-ignore
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  public padDate = (num: number) => String(num).padStart(2, '0');

  public handleClick = (event: MouseEvent) => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target as HTMLDivElement)
    ) {
      this.hideCalendar();
    }
  };

  public getInputFieldValue = () => {
    const includeTime = this.props.single && this.props.showTime;

    let from = this.state.from > 0 ? new Date(this.state.from) : _('common:datepicker.from');
    let to = this.state.to > 0 ? new Date(this.state.to) : _('common:datepicker.to');

    if (!(from instanceof Date) && (this.props.single || !(to instanceof Date))) {
      return _('common:datepicker.pleaseChoose');
    }

    const [{ lang }] = this.props.appState;

    if (lang === ApiLang.de) {
      if (from instanceof Date) {
        // prettier-ignore
        from = `${this.padDate(from.getDate())}.${this.padDate(
          from.getMonth() + 1
        )}.${this.padDate(from.getFullYear())} ${includeTime ? this.getTime() + ' (MEZ)' : ''}`;
      }

      if (to instanceof Date) {
        to = `${this.padDate(to.getDate())}.${this.padDate(to.getMonth() + 1)}.${this.padDate(
          to.getFullYear()
        )}`;
      }
    } else {
      if (from instanceof Date) {
        // prettier-ignore
        from = `${from.getDate()}/${from.getMonth() + 1}/${from.getFullYear()} ${includeTime ? this.getTime() + ' (CET)' : ''}`;
      }

      if (to instanceof Date) {
        to = `${to.getDate()}/${to.getMonth() + 1}/${to.getFullYear()}`;
      }
    }

    return this.props.single ? from : `${from} - ${to}`;
  };

  public prevMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const date = new Date(this.state.current);
    date.setMonth(date.getMonth() - 1);
    this.setState({ current: date.getTime() });
  };

  public nextMonth = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const date = new Date(this.state.current);
    date.setHours(3);
    date.setMonth(date.getMonth() + 1);
    this.setState({ current: date.getTime() });
  };

  public getMonthYearLabel = () => {
    const currentDate = new Date(this.state.current);
    // prettier-ignore
    return `${
      DatePicker.getMonthLabels()[currentDate.getMonth()]
    } ${currentDate.getFullYear()}`;
  };

  public getTotalDaysPrevMonth = () => {
    const year = new Date(this.state.current).getUTCFullYear();
    const month = new Date(this.state.current).getUTCMonth();
    return new Date(Date.UTC(year, month, 0)).getUTCDate();
  };

  public getDaysWantedFromPrevMonth = () => {
    return new Array(this.getFirstDayThisMonth() === 0 ? WEEK_DAYS : this.getFirstDayThisMonth())
      .fill(null)
      .map((el, i) => this.getTotalDaysPrevMonth() - i)
      .reverse();
  };

  public getTotalDaysThisMonth = () => {
    const year = new Date(this.state.current).getUTCFullYear();
    const month = new Date(this.state.current).getUTCMonth();
    return new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
  };

  public getFirstDayThisMonth = () => {
    const year = new Date(this.state.current).getUTCFullYear();
    const month = new Date(this.state.current).getUTCMonth();
    return new Date(Date.UTC(year, month)).getDay();
  };

  public getCalViewDays = () => {
    const year = new Date(this.state.current).getUTCFullYear();
    const month = new Date(this.state.current).getUTCMonth();

    const PREV_MONTH_DAYS = new Date(Date.UTC(year, month, 0)).getUTCDate();
    const THIS_MONTH_DAYS = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
    const FIRST_DAY_THIS_MONTH = this.getFirstDayThisMonth(); // new Date(year, month).getDay();
    const DAYS_PREV_MONTH = new Array(FIRST_DAY_THIS_MONTH === 0 ? WEEK_DAYS : FIRST_DAY_THIS_MONTH)
      .fill(null)
      .map((el, i) => PREV_MONTH_DAYS - i)
      .reverse();
    const DAYS_NEXT_MONTH = new Array(TOTAL_DAYS - THIS_MONTH_DAYS - DAYS_PREV_MONTH.length)
      .fill(null)
      .map((el, i) => i + 1);

    const DAYS_THIS_MONTH = new Array(THIS_MONTH_DAYS).fill(null).map((el, i) => i + 1);

    return [...DAYS_PREV_MONTH, ...DAYS_THIS_MONTH, ...DAYS_NEXT_MONTH];
  };

  /**
   * Returns true if `dayIndex` is a day of the current month.
   * @param {number} dayIndex
   * @return {boolean}
   */
  public isValidDay = (dayIndex: number) => {
    if (this.props.disablePastDate) {
      const year = new Date(this.state.current).getUTCFullYear();
      const month = new Date(this.state.current).getUTCMonth();

      const now = new Date();
      const nowYear = now.getUTCFullYear();
      const nowMonth = now.getUTCMonth();
      const nowDay = now.getUTCDate();

      const today = this.getCalViewDays()[dayIndex];

      if (
        year < nowYear ||
        (year === nowYear && month < nowMonth) ||
        // since the visible days of the previous month are disable bei the code bellow anyway
        // we can just check `today < nowDay`
        (year === nowYear && month === nowMonth && today < nowDay)
      ) {
        return false;
      }
    }

    const iToSkip = this.getDaysWantedFromPrevMonth().length - 1;
    const iToLeftOut = iToSkip + 1 + this.getTotalDaysThisMonth();
    return dayIndex > iToSkip && dayIndex < iToLeftOut;
  };

  public setDate = (dayIndex: number) => {
    const day = this.getCalViewDays()[dayIndex];
    const current = new Date(this.state.current);

    const selectedDate = new Date(Date.UTC(current.getUTCFullYear(), current.getUTCMonth(), day));
    const selectedTime = selectedDate.getTime();

    this.setState((state, props): Pick<IState, 'from' | 'to' | 'lastSelectionStep'> => {
      const from = new Date(state.from);
      // prettier-ignore
      const fromDate = new Date(
            Date.UTC(from.getUTCFullYear(), from.getUTCMonth(), from.getUTCDate())
        );
      const to = new Date(state.to);
      const toDate = new Date(Date.UTC(to.getUTCFullYear(), to.getUTCMonth(), to.getUTCDate()));

      if (
        state.lastSelectionStep === SelectionStep.secondDate &&
        selectedTime === fromDate.getTime() &&
        selectedTime === toDate.getTime()
      ) {
        return { from: -1, to: -1, lastSelectionStep: SelectionStep.none };
      } else if (props.single || state.lastSelectionStep !== SelectionStep.firstDate) {
        return {
          from: selectedTime,
          to: selectedTime,
          lastSelectionStep: SelectionStep.firstDate,
        };
      } else if (selectedTime >= fromDate.getTime()) {
        return {
          from: state.from,
          to: selectedTime,
          lastSelectionStep: SelectionStep.secondDate,
        };
      } else {
        return { from: selectedTime, to: state.to, lastSelectionStep: SelectionStep.secondDate };
      }
    });
  };

  public multiSelected = (dayIndex: number) => {
    const day = this.getCalViewDays()[dayIndex];
    const current = new Date(this.state.current);
    const from = new Date(this.state.from);
    const to = new Date(this.state.to);

    const selectedDate = new Date(Date.UTC(current.getFullYear(), current.getMonth(), day));
    const fromDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())); // prettier-ignore

    return (
      (fromDate.getTime() <= selectedDate.getTime() && selectedDate.getTime() <= to.getTime()) ||
      (fromDate.getTime() === selectedDate.getTime() && to.getTime() === -1)
    );
  };

  public isFirstDate = (dayIndex: number) => {
    const day = this.getCalViewDays()[dayIndex];
    const current = new Date(this.state.current);
    const selectedDate = new Date(Date.UTC(current.getFullYear(), current.getMonth(), day));

    const from = new Date(this.state.from);
    const fromDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())); // prettier-ignore

    return selectedDate.getTime() === fromDate.getTime();
  };

  public isLastDate = (dayIndex: number) => {
    const day = this.getCalViewDays()[dayIndex];
    const current = new Date(this.state.current);
    const selectedDate = new Date(Date.UTC(current.getFullYear(), current.getMonth(), day));

    const to = new Date(this.state.to);
    const toDate = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate()));

    const from = new Date(this.state.from);
    const fromDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())); // prettier-ignore

    return (
      selectedDate.getTime() === toDate.getTime() ||
      (fromDate.getTime() === selectedDate.getTime() && to.getTime() === -1)
    );
  };

  /**
   * Returns true if `dayIndex` is a day of the current month
   * @param {number} dayIndex
   * @return {boolean}
   */
  public isDayToday = (dayIndex: number) => {
    const day = this.getCalViewDays()[dayIndex];
    const current = new Date(this.state.current);
    const now = new Date();

    return (
      this.isValidDay(dayIndex) &&
      now.getFullYear() === current.getFullYear() &&
      now.getMonth() === current.getMonth() &&
      now.getDate() === day
    );
  };

  public isDayCurrent = (dayIndex: number) => {
    if (!this.props.single) {
      return false;
    } else {
      const day = this.getCalViewDays()[dayIndex];
      const from = new Date(this.state.from);
      const current = new Date(this.state.current);

      return (
        this.isValidDay(dayIndex) &&
        from.getUTCFullYear() === current.getUTCFullYear() &&
        from.getUTCMonth() === current.getUTCMonth() &&
        from.getUTCDate() === day
      );
    }
  };

  public displayCalendar = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ visible: true });
  };

  public hideCalendar = () => {
    this.setState({ visible: false });
  };

  public handleTimeChange = (event) => {
    this.setState({ [event.target.name]: Number(event.target.value) });
  };

  public getFromDateWithTime = () => {
    const date = new Date(this.state.from);

    const dateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      this.state.hr,
      this.state.min,
      this.state.sec
    );

    const utcDateTime = zonedTimeToUtc(dateTime, 'Europe/Berlin');
    return utcDateTime.getTime();
  };

  public getTime = () => {
    const { hr, min, sec } = this.state;

    const formatedHr = hr < 10 ? `0${hr}` : hr;
    const formatedMin = min < 10 ? `0${min}` : min;
    const formatedSec = sec < 10 ? `0${sec}` : sec;

    return `${formatedHr}:${formatedMin}:${formatedSec}`;
  };

  public render() {
    const { from, to } = this.state;
    return (
      <Container
        onClick={this.displayCalendar}
        className={this.props.className}
        css={{
          opacity: this.props.disabled ? 0.5 : 1,
          pointerEvents: this.props.disabled ? 'none' : 'auto',
          position: 'relative',
          ...(this.props.showBorder
            ? { borderRadius: 4, border: `1px solid ${GRAY_3}`, width: 300 }
            : {}),
        }}
        ref={this.containerRef}
      >
        {this.props.single ? (
          <input
            type="hidden"
            name={this.props.name}
            value={from < 1 ? '' : this.props.showTime ? this.getFromDateWithTime() : from}
          />
        ) : (
          <React.Fragment>
            <input type="hidden" name={`${this.props.name}Start`} value={from < 1 ? '' : from} />
            <input type="hidden" name={`${this.props.name}End`} value={to < 1 ? '' : to} />
          </React.Fragment>
        )}

        <div css={{ cursor: 'pointer' }}>
          <div
            css={{
              alignItems: 'center',
              backgroundColor: '#cdd4d6',
              color: '#fff',
              fontSize: 16,
              height: 32,
              justifyContent: 'center',
              width: 32,
            }}
          >
            <span className="icon-calendar" />
          </div>
          <input
            id="date"
            type="text"
            value={this.getInputFieldValue()}
            readOnly
            onFocus={this.displayCalendar}
            css={{ flex: 1, cursor: 'pointer' }}
            disabled={this.props.disabled}
          />
        </div>

        {this.state.visible && (
          <div
            css={{
              boxShadow: '0 8px 10px 0px rgba(0,0,0,.5)',
              flexDirection: 'column',
              maxWidth: 280,
              position: 'absolute',
              top: 32,
              zIndex: 99,
            }}
          >
            <header css={{ color: '#f0f0f0', flexDirection: 'column' }}>
              <div
                css={{
                  backgroundColor: '#3c3d3e',
                  height: 32,
                  left: 'calc(50% - 16px)',
                  position: 'absolute',
                  top: 2,
                  transform: 'rotate(45deg)',
                  width: 32,
                }}
              />
              <div
                style={{
                  alignItems: 'center',
                  backgroundColor: '#3c3d3e',
                  display: 'flex',
                  justifyContent: 'space-between',
                  zIndex: 2,
                }}
              >
                <ChangeMonthButton onClick={this.prevMonth}>
                  <span className="fas fa-angle-left" />
                </ChangeMonthButton>
                <span>{this.getMonthYearLabel()}</span>
                <ChangeMonthButton onClick={this.nextMonth}>
                  <span className="fas fa-angle-right" />
                </ChangeMonthButton>
              </div>

              <div css={{ backgroundColor: '#3c3d3e' }}>
                {DatePicker.getWeekdayLabels().map((day) => (
                  <DayLabel key={day}>{day}</DayLabel>
                ))}
              </div>
            </header>

            <div css={{ flexWrap: 'wrap', backgroundColor: 'white' }}>
              {this.getCalViewDays().map((day, i) => {
                return (
                  <Day
                    key={String(day) + String(i)}
                    selected={this.multiSelected(i) && this.isValidDay(i)}
                    highlighted={this.isDayToday(i)}
                    callerId={i}
                    onClick={this.setDate}
                    disabled={!this.isValidDay(i)}
                    first={this.isFirstDate(i)}
                    last={this.isLastDate(i)}
                  >
                    {day}
                  </Day>
                );
              })}
            </div>

            {this.props.showTime && this.props.single && (
              <div
                css={{
                  backgroundColor: '#fff',
                  flex: '0 0 auto',
                  justifyContent: 'center',
                  padding: '16px 0',
                  alignItems: 'center',
                }}
              >
                <span
                  className="icon-clock"
                  css={{ marginRight: 8, color: 'rgb(60, 61, 62)', fontSize: 16 }}
                />
                <div>
                  <select
                    name="hr"
                    value={this.state.hr}
                    onChange={this.handleTimeChange}
                    css={{ padding: 4, border: '1px solid #f0f0f0' }}
                  >
                    {new Array(24).fill(0).map((_, i) => (
                      <option value={i} key={'hour' + i}>
                        {i < 10 ? `0${i}` : i}
                      </option>
                    ))}
                  </select>

                  <select
                    name="min"
                    value={this.state.min}
                    onChange={this.handleTimeChange}
                    css={{ padding: 4, border: '1px solid #f0f0f0' }}
                  >
                    {new Array(60).fill(0).map((_, i) => (
                      <option value={i} key={'minutes' + i}>
                        {i < 10 ? `0${i}` : i}
                      </option>
                    ))}
                  </select>

                  <select
                    name="sec"
                    value={this.state.sec}
                    onChange={this.handleTimeChange}
                    css={{ padding: 4, border: '1px solid #f0f0f0' }}
                  >
                    {new Array(60).fill(0).map((_, i) => (
                      <option value={i} key={'seconds' + i}>
                        {i < 10 ? `0${i}` : i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        )}
      </Container>
    );
  }
}

export default withAppState(DatePicker);
