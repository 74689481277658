import React, { FC } from 'react';
import { Fieldset } from '../../../../../atoms/Fieldset';
import FieldCheckbox from '../../../../../molecules/Formik/Fields/FieldCheckbox';
import { Translation } from '../../../../../components';
import { SectionLabel } from '../../styles';
import { FormValues } from '../FormWrapper';
import { Box, Grid, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { BLUE } from '../../../../../camtool-styles';

interface PublishScheduledProps {
  editable: boolean;
  values: FormValues;
}

const SendStickyMessage: FC<PublishScheduledProps> = ({ editable, values: { sticky } }) => (
  <Fieldset
    disabled={!editable || !sticky}
    css={{ marginBottom: 0 }}
    legend={
      <FieldCheckbox
        name={'sticky'}
        disabled={!editable}
        label={<Translation t={'mailings:telegram.sticky.heading'} />}
      />
    }
  >
    <Grid item xs={12}>
      <SectionLabel>
        <Box mt={1} style={{ paddingLeft: 12 }}>
          <Typography>
            <Translation t={'mailings:telegram.sticky.text'} />
          </Typography>
        </Box>
      </SectionLabel>
    </Grid>
    <Typography style={{ marginLeft: 12 }}>
      <Translation t={'mailings:telegram.sticky.descriptionWithoutRemove'} />
    </Typography>
    {sticky && (
      <Typography style={{ color: BLUE, marginLeft: 12 }}>
        <br />
        <InfoIcon style={{ width: '16px', marginBottom: '-5px' }} />{' '}
        <Translation t={'mailings:telegram.sticky.note'} />
      </Typography>
    )}
  </Fieldset>
);
export default SendStickyMessage;
