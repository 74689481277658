import { css, SerializedStyles } from '@emotion/core';
import { BLACK_1, ORANGE, WHITE, YELLOW, GRAY_3, BLUE_HOVER } from '../../camtool-styles';

export type Theme =
  | 'none'
  | 'blue'
  | 'gray'
  | 'gray-white'
  | 'green'
  | 'red'
  | 'gold'
  | 'link-gray'
  | 'link-blue'
  | 'link-black'
  | 'link-orange'
  | 'link-white'
  | 'link-yellow'
  | 'border-white'
  | 'border-gray';

export type Size = 'md' | 'lg';

const BUTTON_SIZE_MD = css`
  padding: 8px 20px;
`;

const BUTTON_SIZE_LG = css`
  padding: 10px 20px;
  font-size: 1.25rem;
`;

const BUTTON_NAKED = css`
  background: none;
  border: none;
  text-decoration: none;
  text-transform: none;
`;

const BUTTON_BASE = css`
  border: none;
  border-radius: 4px;
  text-transform: none;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const BUTTON_RED = css`
  ${BUTTON_BASE};
  background-color: #ff4d3c;
  color: #ffffff;
  &:hover {
    background-color: #ff9389;
  }
`;

// Normal themes
const BUTTON_GREEN = css`
  ${BUTTON_BASE};
  background-color: #43b31c;
  color: #ffffff;
  &:hover {
    background-color: #66e03b;
  }
`;

const BUTTON_BLUE = css`
  ${BUTTON_BASE};
  background-color: #1f93e9;
  color: #ffffff;
  &:hover {
    background-color: #3bacf7;
  }
`;

const BUTTON_GRAY = css`
  ${BUTTON_BASE};
  background-color: #cdd4d6;
  color: ${BLACK_1};
  &:hover {
    background-color: ${BLUE_HOVER};
  }
`;

const BUTTON_GRAY_WHITE = css`
  ${BUTTON_BASE};
  background-color: #cdd4d6;
  color: ${WHITE};
  &:hover {
    background-color: #b5bbbd;
  }
`;

const BUTTON_GOLD = css`
  ${BUTTON_BASE};
  background-color: #b48f61;
  color: #ffffff;
  &:hover {
    background-color: #bf9869;
  }
`;

// Link themes
const LINK_BASE = css`
  border: none;
  text-transform: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
`;

const LINK_GRAY = css`
  ${LINK_BASE};
  color: #bcbcbc;
  &:hover {
    color: #525158;
  }
`;

const LINK_BLUE = css`
  ${LINK_BASE};
  color: #1f93e9;
  &:hover {
    color: #3bacf7;
  }
`;

const LINK_BLACK = css`
  ${LINK_BASE};
  color: #262728;
  &:hover {
    color: #bcbcbc;
  }
`;

const LINK_YELLOW = css`
  ${LINK_BASE};
  color: #f9af38;
  &:hover {
    color: #f9af38;
  }
`;

const LINK_ORANGE = css`
  ${LINK_BASE};
  color: ${ORANGE};
  &:hover {
    color: ${YELLOW};
  }
`;

const LINK_WHITE = css`
  ${LINK_BASE};
  color: ${WHITE};
  &:hover {
    opacity: 0.8;
  }
`;

// Border themes
const BORDER_BASE = css`
  ${BUTTON_BASE};
  background-color: transparent;
  border: 1px solid;
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
`;

const BORDER_WHITE = css`
  ${BORDER_BASE};
  color: ${WHITE};
  border-color: ${WHITE};
`;

const BORDER_GRAY = css`
  ${BORDER_BASE};
  color: ${GRAY_3};
  border-color: ${GRAY_3};
`;

function getThemeStyles(theme: Theme): SerializedStyles {
  switch (theme) {
    case 'none':
      return BUTTON_NAKED;
    case 'blue':
      return BUTTON_BLUE;
    case 'gray':
      return BUTTON_GRAY;
    case 'gray-white':
      return BUTTON_GRAY_WHITE;
    case 'green':
      return BUTTON_GREEN;
    case 'red':
      return BUTTON_RED;
    case 'gold':
      return BUTTON_GOLD;
    case 'link-gray':
      return LINK_GRAY;
    case 'link-blue':
      return LINK_BLUE;
    case 'link-black':
      return LINK_BLACK;
    case 'link-orange':
      return LINK_ORANGE;
    case 'link-yellow':
      return LINK_YELLOW;
    case 'link-white':
      return LINK_WHITE;
    case 'border-white':
      return BORDER_WHITE;
    case 'border-gray':
      return BORDER_GRAY;
    default:
      throw new Error(`Invalid \`theme\` provided: ${theme}`); // prettier-ignore
  }
}

function getSizeStyles(size: Size): SerializedStyles {
  switch (size) {
    case 'md':
      return BUTTON_SIZE_MD;
    case 'lg':
      return BUTTON_SIZE_LG;
    default:
      throw new Error(`Invalid \`size\` provided: ${size}`); // prettier-ignore
  }
}

export function getStyles(theme: Theme, size: Size = 'md'): SerializedStyles {
  let styles = getThemeStyles(theme);

  if (theme !== 'none' && !theme.startsWith('link-')) {
    styles = [getSizeStyles(size), styles];
  }

  return styles;
}
