import { useQuery } from '@apollo/react-hooks';
import {
  Query,
  Service0900DisplayPlatformEnum,
  Service0900StatusEnum,
} from '../../../graphql/VXModels/types';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { QUERY_MODEL_SERVICE0900_TOPBAR } from '../graphql/queries';
import { QueryResult } from '@apollo/react-common';

export const useService0900ShortInfo = (
  options?: QueryHookOptions
): Omit<QueryResult, 'data'> & {
  data: {
    status: Service0900StatusEnum | undefined;
    isTargetNumberLocked?: boolean;
    displayPlatform: Service0900DisplayPlatformEnum[];
  };
} => {
  const { data, ...rest } = useQuery<Query>(QUERY_MODEL_SERVICE0900_TOPBAR, options);
  const status = data?.model?.service0900?.status || Service0900StatusEnum.notSet;
  const isTargetNumberLocked = data?.model?.service0900?.targetNumber?.isLocked;
  const displayPlatform = data?.model?.service0900?.displayPlatform || [];

  return { data: { status, isTargetNumberLocked, displayPlatform }, ...rest };
};
