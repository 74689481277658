import React, { FC } from 'react';
import { TableCell, TableRow } from '../../../../../atoms/Table';
import { _ } from '../../../../../util/translate';
import { APP_BASE_PATH } from '../../../../../util/env';
import { ITableColumn } from '../../../../../atoms/Table/types';

interface Props {
  instance: { usageId: number; user: { id: string; name: string }; date: Date; pfmId: number };
  columns: ITableColumn[];
}

const InstanceTableRow: FC<Props> = ({ instance, columns }) => {
  const { date, pfmId } = instance;

  // Deconstructuring the user object the clean way instead of an nested object in an object
  const { id, name } = instance?.user || { id: '0', name: `${_('common:user.unavailable')}` };

  return (
    <TableRow
      // only link to customer page if platform id is 'VXPages' (edit: apparently not reliable, sometimes pfmId is 0 with same user e.g. petr001)
      {...(pfmId === 1503 ? { to: `${APP_BASE_PATH}/customers/${id}` } : undefined)}
      css={{ flexBasis: 36 }}
    >
      <TableCell columns={columns} index={0}>
        {name || _('common:user.unavailable')}
      </TableCell>

      <TableCell columns={columns} index={1}>
        {date ? new Date(date).toLocaleDateString() : ''}
      </TableCell>
    </TableRow>
  );
};

export default InstanceTableRow;
