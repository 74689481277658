import React, { createContext, FC, useContext } from 'react';
import { ContextFunctions, ContextState } from '../types';
import { UploadManagerStore } from '../store/UploadManagerStore';

export const UploadManagerContext = createContext<ContextState & ContextFunctions>(
  UploadManagerStore
);

export const DebugContext: FC = () => {
  const context = useContext(UploadManagerContext);

  return (
    <div>
      <h3>Form Context Debug:</h3>
      <pre>{JSON.stringify(context, null, 2)}</pre>
    </div>
  );
};
