import React, { FC, useEffect } from 'react';
import qs from 'query-string';
import { Redirect, RouteComponentProps } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { ActionEnum, useAppState } from '../../util/AppState';
import { APP_BASE_PATH, APP_SCOPE } from '../../util/env';
import { MUTATION_AUTH_TOKENAUTH } from '../../graphql/VXModels/mutations';
import SplashScreen from '../../components/SplashScreen/index';
import { Main } from '../../components/Layout/DarkCentered';
import { LOGIN_PATH } from './Login';
import ErrorWindowForbidden from './components/ErrorWindowForbidden';
import useOnSuccessfulAuthAppStateEffects from './hooks/useOnSuccessfulAuthAppStateEffects';
import { getBackurlMobileCookie, setBackurlMobileCookie } from '../../util/cookies';

const DEFAULT_REDIR_URL = `${APP_BASE_PATH}/`;

const getLoginPath = (redir?: string): string => {
  const loginQueryStringArgs = qs.stringify(redir && redir !== DEFAULT_REDIR_URL ? { redir } : {});

  return `${LOGIN_PATH}${loginQueryStringArgs ? `?${loginQueryStringArgs}` : ''}`;
};

const AuthCallback: FC<RouteComponentProps> = ({ location: { search } }) => {
  const [state, dispatch] = useAppState();
  const { authToken: currentAuthToken } = state;
  const { redir = DEFAULT_REDIR_URL, token } = qs.parse(search, { ignoreQueryPrefix: true });

  const loginPath = getLoginPath(redir);

  const [tokenAuth, { error, called, loading, data }] = useMutation(MUTATION_AUTH_TOKENAUTH, {
    context: { headers: { Authorization: undefined } },
  });

  useEffect(() => {
    if (token && !called) {
      if (currentAuthToken) {
        // Clear the auth token. (The auth request would fail if we sent an expired auth token.)
        // On the next rerender, we'll have a new Apollo client with an empty Authorization header.
        dispatch({ type: ActionEnum.clearAuthToken });
      } else {
        tokenAuth({ variables: { token, scope: APP_SCOPE } });
      }
    }
  }, [token, currentAuthToken]);

  const tokenAuthData = data?.auth?.tokenAuth;
  const tokenAuthExtraData = tokenAuthData?.extra;
  const tokenAuthExtraTitle = tokenAuthExtraData?.title;
  const tokenAuthExtraMessage = tokenAuthExtraData?.message;
  const tokenAuthExtraComesFromMobile = tokenAuthExtraData?.comesFromMobile;
  const authToken = tokenAuthData?.token;
  const signupLanguage = tokenAuthData?.signupLanguage;

  if (
    getBackurlMobileCookie() === undefined &&
    tokenAuthExtraComesFromMobile &&
    document.referrer
  ) {
    setBackurlMobileCookie(document.referrer);
  }

  useOnSuccessfulAuthAppStateEffects(dispatch, authToken, signupLanguage);

  return !token ? (
    <Redirect to={loginPath} />
  ) : !called || loading ? (
    <SplashScreen />
  ) : error ? (
    <Redirect to={loginPath} />
  ) : authToken ? (
    <Redirect to={redir} />
  ) : tokenAuthExtraTitle || tokenAuthExtraMessage ? (
    <Main className="spinner-container">
      <ErrorWindowForbidden title={tokenAuthExtraTitle} message={tokenAuthExtraMessage} />
    </Main>
  ) : (
    <Redirect to={loginPath} />
  );
};

export default AuthCallback;
