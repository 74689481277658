import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ModalContentContainer = styled.div`
  margin: auto;
  height: 600px; // need height because of IE11
  padding: 16px;
  flex-direction: column;
  background-color: #fff;
  color: #3c3d3e;
  position: relative;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.4);
`;

const ModalFrame = styled.div`
  padding: 16px;
  background-color: #f5f5f5;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  font-size: 16px;
  background-color: #fff;
  color: #6c6c6c;
  border: none;
  transition: color 0.2s, background-color 0.2s;
  &:hover {
    background-color: #6c6c6c;
    color: #fff;
  }
`;

const StoryDetailModal = ({ onClickCloseButton, children }) => (
  <ModalContentContainer data-testid="story-detail-modal">
    <ModalFrame>
      <ModalCloseButton onClick={onClickCloseButton}>
        <span className="vxicon icon-close-line" />
      </ModalCloseButton>

      {children}
    </ModalFrame>
  </ModalContentContainer>
);

export default StoryDetailModal;

StoryDetailModal.propTypes = {
  onClickCloseButton: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
