import React, {FC} from 'react';
import {_} from '../../../../util/translate';
import {Section} from '../../../../atoms';
import {keyVisual} from './assets/images';
import {Box, Typography} from '@material-ui/core';
import {useTelegramBotStyles} from './styles';
import {useTelegramBotPin} from '../../hooks';
import CodeBox from './CodeBox';
import HelpcenterLink from "../../../../components/Helpcenter/HelpcenterLink";

const TelegramBot: FC = () => {
    const classes = useTelegramBotStyles();
    const [getTelegramBotPin, {data}] = useTelegramBotPin();
    const botPin = data ? data.master?.botPin : 0;
    const help = HelpcenterLink();

    return (
        <Section title={'VXModels Bot'} css={{height: '100%'}}>
            <Box className={classes.root}>
                <Box className={classes.container}>
                    <img src={keyVisual} width={'101px'} height={'101px'}/>
                    <Typography className={classes.heading}>
                        {_('mailings:telegram.vxmbot.heading')}
                    </Typography>
                    <CodeBox onClick={getTelegramBotPin} code={botPin}/>
                    <Typography>{_('mailings:telegram.vxmbot.codeInstructions')}</Typography>
                    <Box className={classes.helpcenterBox}>
                        <Typography>{_('mailings:telegram.vxmbot.needHelp')}</Typography>
                        <Typography
                            className={classes.helpcenterLink}
                            onClick={() =>
                                window.open(help + '/articles/4414676857490', 'help')
                            }
                        >
                            {_('mailings:telegram.vxmbot.helpcenter')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Section>
    );
};

export default TelegramBot;
