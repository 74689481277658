import { useVXServicesTelegramClient } from '../../../graphql/VXServicesTelegram';
import { useVXServicesTelegramContext } from './index';
import {
  Broadcast,
  MasterbroadcastListArgs,
  Query,
} from '../../../graphql/VXServicesTelegram/types';
import { QUERY_MASTER_USER_BROADCASTLIST } from '../graphql/queries';
import { QueryResult, Query as MaterialTableQuery } from 'material-table';

type AsyncQueryResolver = (query: MaterialTableQuery<Broadcast>) => Promise<QueryResult<Broadcast>>;

export const useAsyncBroadcastListQuery = (): AsyncQueryResolver => {
  const client = useVXServicesTelegramClient();
  const { token } = useVXServicesTelegramContext();

  return (query): Promise<QueryResult<Broadcast>> =>
    new Promise((resolve, reject) => {
      client
        .query<Query, Partial<MasterbroadcastListArgs> & { token: string }>({
          query: QUERY_MASTER_USER_BROADCASTLIST,
          fetchPolicy: 'network-only',
          variables: {
            token: token,
            limit: query.pageSize,
            offset: query.page * query.pageSize,
          },
        })
        .then(({ data, errors }) => {
          const broadcastList = data?.master?.broadcastList;
          if (errors) {
            reject(errors);
          }
          if (broadcastList) {
            resolve({
              data: broadcastList.broadcasts,
              page: broadcastList.offset / broadcastList.limit,
              totalCount: broadcastList.total,
            });
          } else {
            reject(new Error('Could not get a broadcast list'));
          }
        });
    });
};
