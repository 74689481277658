import React from 'react';

import { EmptyContent, FormatCurrency, FormatDatePeriod, T } from '../../../components';
import { _ } from '../../../util/translate';

class ModalPayoutApplyDataList extends React.Component {
  //Old do not use, use switch
  toggle = () => {
    let newState = !this.state.isOpened;
    this.setState({ isOpened: newState });

    let domNode = this.refs.toggleElement;
    if (domNode) {
      if (newState) {
        domNode.classList.add('show');
        domNode.classList.remove('hide');
      } else {
        domNode.classList.add('hide');
        domNode.classList.remove('show');
      }
    }
  };

  toggleOpen = () => {
    if (this.state.toggleStatus === 'hide') {
      this.setState({ toggleStatus: 'show' });
    }
  };

  toggleClose = () => {
    if (this.state.toggleStatus === 'show') {
      this.setState({ toggleStatus: 'hide' });
    }
  };

  render() {
    this.data = this.props.data;
    return (
      <article className="modalpayoutapplydatalist">
        <section>
          <header>
            <div>
              <T _={'finances:payout.request.datalist.header'} />
            </div>
          </header>
          <article>
            <section>
              <header>
                <div>
                  <T _={'finances:payout.request.datalist.column.period'} />
                </div>
                <div>
                  <T _={'finances:payout.request.datalist.column.amount'} />
                </div>
              </header>

              {this.data.length > 0 &&
                this.data.map(item => (
                  <article key={item.id}>
                    <div>
                      <FormatDatePeriod from={item.startDate} to={item.endDate} />
                    </div>
                    <div>
                      <FormatCurrency value={item.balance} />
                    </div>
                  </article>
                ))}

              {this.data.length <= 0 && (
                <EmptyContent title={_('finances:payout.request.datalist.payoutsNotFound')} />
              )}
            </section>
          </article>
        </section>
      </article>
    );
  }
}

export default ModalPayoutApplyDataList;
