import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { MediaManagementActionCreators } from '../../../../stores/MediaManagement/MediaManagement';
import { PROFILE_LA } from '../../../../stores/Profile/Profile';
import AbstractProfile from '../../AbstractProfile';

import { SessionStore } from '../../../../stores/Session/Session';

import {
  Button,
  ButtonGroup,
  Footer,
  Form,
  GroupRow,
  MultiLangTextareafield,
  Separator,
  Spinner,
  T,
} from '../../../../components';
import InputHidden from '../../../../components/Form/InputHidden/InputHidden';
import GroupColumnNew from '../../../../components/GroupColumnNew/GroupColumnNew';
import AlbumAdd from '../../../../components/MediaManagement/ExplorerPicture/AlbumAdd/AlbumAdd';
import AlbumItem from '../../../../components/MediaManagement/ExplorerPicture/AlbumItem/AlbumItem';
import NavSubLA from '../NavSubLA';

import { APP_API_HOST } from '../../../../util/env';
import { _ } from '../../../../util/translate';

class AutoMails extends AbstractProfile {
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state || {}, {
      detailsPicture: {},
      menuPicture: {},
      poolAlbum: {
        id: 'pool',
        storeType: 'album',
        albumType: 'pool',
        hidePicPreviewMenu: true,
        title: 'unused',
        editable: false,
        pictures: [],
        reload: false,
      },
    });

    this.selectItem = this.selectItem.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.hideDetails = this.hideDetails.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.onContentClick = this.onContentClick.bind(this);

    this.setPicture = this.setPicture.bind(this);
    this.setPicture1 = (picture) => {
      return this.setPicture(1, picture);
    };
    this.setPicture2 = (picture) => {
      return this.setPicture(2, picture);
    };
    this.setPicture3 = (picture) => {
      return this.setPicture(3, picture);
    };

    this.removePicture = this.removePicture.bind(this);
    this.removePicture1 = (picture) => {
      return this.removePicture(1, picture);
    };
    this.removePicture2 = (picture) => {
      return this.removePicture(2, picture);
    };
    this.removePicture3 = (picture) => {
      return this.removePicture(3, picture);
    };
  }

  getResource() {
    return PROFILE_LA;
  }

  componentWillMount() {
    if (!SessionStore.get().profile.isLAProfileActive) {
      this.props.history.push(`${APP_API_HOST}/onlineprofile/lustagenten/settings`);
    }
    super.componentWillMount();
  }

  submitForm(currentValues) {
    currentValues.laWelcomeText1Picture = {
      albumId: currentValues.laWelcomeText1Picture
        ? currentValues.laWelcomeText1Picture.albumId
        : 0,
      pictureId: currentValues.laWelcomeText1Picture
        ? currentValues.laWelcomeText1Picture.pictureId
        : 0,
    };
    currentValues.laWelcomeText2Picture = {
      albumId: currentValues.laWelcomeText2Picture
        ? currentValues.laWelcomeText2Picture.albumId
        : 0,
      pictureId: currentValues.laWelcomeText2Picture
        ? currentValues.laWelcomeText2Picture.pictureId
        : 0,
    };
    currentValues.laWelcomeText3Picture = {
      albumId: currentValues.laWelcomeText3Picture
        ? currentValues.laWelcomeText3Picture.albumId
        : 0,
      pictureId: currentValues.laWelcomeText3Picture
        ? currentValues.laWelcomeText3Picture.pictureId
        : 0,
    };

    super.submitForm(currentValues);
    //super hotfix x)))) i love them old files
    setTimeout(() => {
      this.forceUpdate();
    }, 2500);
  }

  getItemStatus(item) {
    return item && item.pictureId === this.state.detailsPicture.pictureId ? 'details' : '';
  }

  getItemMenuStatus(item) {
    return item && item.pictureId === this.state.menuPicture.pictureId ? 'show' : '';
  }

  selectItem() {
    this.setState({
      menuPicture: {},
      detailsPicture: {},
    });
  }

  showDetails(item) {
    this.setState({
      menuPicture: {},
      detailsPicture: item,
    });
  }

  hideDetails() {
    this.setState({
      menuPicture: {},
      detailsPicture: {},
    });
  }

  showMenu(item) {
    this.setState({
      menuPicture: item,
      detailsPicture: {},
    });
  }

  isPictureInUse(pictureId) {
    let inUse = false;
    for (let pos of [1, 2, 3]) {
      const field = 'laWelcomeText' + pos + 'Picture';
      inUse = inUse || this.currentValues[field].pictureId === pictureId;
    }

    return inUse;
  }

  setPicture(pos, picture) {
    if (picture[0]) {
      picture = picture[0];
    }

    const field = 'laWelcomeText' + pos + 'Picture';
    let nextValues = {};

    if (!this.currentValues[field].pictureId && !this.isPictureInUse(picture.pictureId)) {
      picture.notPermanentlyDelete = true;
      nextValues[field] = picture;
      this.updateFormWithValues(nextValues);
    }
  }

  removePicture(pos, picture) {
    if (picture.hasOwnProperty('albumId')) {
      picture['album'] = { id: picture.albumId };
    }

    const field = 'laWelcomeText' + pos + 'Picture';
    let nextValues = {};
    if (!picture.notPermanentlyDelete) {
      MediaManagementActionCreators.deletePictureFromAlbum(picture);
    }
    nextValues[field] = {};
    this.updateFormWithValues(nextValues);
    this.setState({
      menuPicture: {},
      detailsPicture: {},
    });
  }

  onContentClick(event) {
    event.stopPropagation();
    this.setState({
      menuPicture: {},
      detailsPicture: {},
    });
  }

  render() {
    const currentValues = this.currentValues;

    super.render();
    this.spokenLangs = this.state.profileLanguages;
    this.germanLangOnly = ['de'];

    this.hasPicture1 = !isEmpty(currentValues.laWelcomeText1Picture);
    this.hasPicture2 = !isEmpty(currentValues.laWelcomeText2Picture);
    this.hasPicture3 = !isEmpty(currentValues.laWelcomeText3Picture);

    return (
      <main id="la-automails" className="la-automails grid" onClick={this.onContentClick}>
        {/* todo this.onContentClick soll die Menüs der Bilder wieder schließen, sollte eine saubere möglichkeit kommen */}
        <NavSubLA />

        <section className="grid__row">
          <section className="grid__column grid__box min-width--0">
            <header className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'profiles:la.automails.pagetitle.label'} />
              </div>
            </header>

            <Form
              className="grid__box__item spinner-container"
              // eslint-disable-next-line react/no-string-refs
              ref="form"
              onValidSubmit={this.submitForm}
              onInvalidSubmit={this.showErrorFields}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              onChange={this.onChange}
            >
              {this.state.isLoading && <Spinner />}

              <div className="grid__box__item__content la-automails__formwidth">
                {/* WELCOME TEXT 1 */}
                <GroupColumnNew
                  label={`${_('profiles:la.welcomeText1.label')}:`}
                  tooltipText={_('profiles:la.welcomeText1.tooltipText')}
                >
                  <GroupRow
                    className={this.getTextErrorMessage('laWelcomeText1') !== '' ? 'error' : ''}
                    errorMessage={this.getTextErrorMessage('laWelcomeText1')}
                  >
                    <MultiLangTextareafield
                      name="laWelcomeText1"
                      value={this.storeValues.laWelcomeText1.texts}
                      placeholder={_('profiles:la.welcomeText1.placeholder')}
                      langs={this.germanLangOnly}
                      maxLength={1000}
                      onChange={this.changeText}
                      onBlur={this.updateFulfillmentAfterTextChanges}
                      className="w360"
                    />
                  </GroupRow>

                  {!this.hasPicture1 && (
                    <AlbumAdd
                      targetUmaId={this.storeValues.laMailAttachmentUmaId}
                      data={this.state.poolAlbum}
                      pictureType="communityProfile"
                      onSubmit={this.setPicture1}
                      title={_('mediamanagement:picfolder.contentItemAdd.title')}
                    />
                  )}

                  {this.hasPicture1 && (
                    <AlbumItem
                      data={{
                        ...this.currentValues.laWelcomeText1Picture,
                        album: { id: this.currentValues.laWelcomeText1Picture.albumId },
                      }}
                      album={{ id: this.currentValues.laWelcomeText1Picture.albumId }}
                      status={this.getItemStatus(this.currentValues.laWelcomeText1Picture)}
                      showDelete={true}
                      showInChecking={true}
                      showDetails={false}
                      showSetAsPreview={false}
                      onClick={this.selectItem}
                      onClickDetails={this.showDetails}
                      onClickMenu={this.showMenu}
                      onClickHideDetails={this.hideDetails}
                      onClickDelete={this.removePicture1}
                    />
                  )}

                  <InputHidden
                    name="laWelcomeText1Picture"
                    value={this.storeValues.laWelcomeText1Picture}
                  />
                </GroupColumnNew>

                {/* WELCOME TEXT 2 */}
                <GroupColumnNew
                  label={`${_('profiles:la.welcomeText2.label')}:`}
                  tooltipText={_('profiles:la.welcomeText2.tooltipText')}
                >
                  <GroupRow
                    className={this.getTextErrorMessage('laWelcomeText2') !== '' ? 'error' : ''}
                    errorMessage={this.getTextErrorMessage('laWelcomeText2')}
                  >
                    <MultiLangTextareafield
                      name="laWelcomeText2"
                      value={this.storeValues.laWelcomeText2.texts}
                      placeholder={_('profiles:la.welcomeText2.placeholder')}
                      langs={this.germanLangOnly}
                      maxLength={1000}
                      onChange={this.changeText}
                      onBlur={this.updateFulfillmentAfterTextChanges}
                      className="w360"
                    />
                  </GroupRow>

                  {!this.hasPicture2 && (
                    <AlbumAdd
                      targetUmaId={this.storeValues.laMailAttachmentUmaId}
                      data={this.state.poolAlbum}
                      pictureType="communityProfile"
                      onSubmit={this.setPicture2}
                      title={_('mediamanagement:picfolder.contentItemAdd.title')}
                    />
                  )}

                  {this.hasPicture2 && (
                    <AlbumItem
                      data={{
                        ...this.currentValues.laWelcomeText2Picture,
                        album: { id: this.currentValues.laWelcomeText2Picture.albumId },
                      }}
                      album={{ id: this.currentValues.laWelcomeText2Picture.albumId }}
                      status={this.getItemStatus(this.currentValues.laWelcomeText2Picture)}
                      showDelete={true}
                      showInChecking={true}
                      showDetails={false}
                      showSetAsPreview={false}
                      onClick={this.selectItem}
                      onClickDetails={this.showDetails}
                      onClickMenu={this.showMenu}
                      onClickHideDetails={this.hideDetails}
                      onClickDelete={this.removePicture2}
                    />
                  )}

                  <InputHidden
                    name="laWelcomeText2Picture"
                    value={this.storeValues.laWelcomeText2Picture}
                  />
                </GroupColumnNew>

                {/*  WELCOME TEXT 3 */}
                <GroupColumnNew
                  label={`${_('profiles:la.welcomeText3.label')}:`}
                  tooltipText={_('profiles:la.welcomeText3.tooltipText')}
                >
                  <GroupRow
                    className={this.getTextErrorMessage('laWelcomeText3') !== '' ? 'error' : ''}
                    errorMessage={this.getTextErrorMessage('laWelcomeText3')}
                  >
                    <MultiLangTextareafield
                      name="laWelcomeText3"
                      value={this.storeValues.laWelcomeText3.texts}
                      placeholder={_('profiles:la.welcomeText3.placeholder')}
                      langs={this.germanLangOnly}
                      maxLength={1000}
                      onChange={this.changeText}
                      onBlur={this.updateFulfillmentAfterTextChanges}
                      className="w360"
                    />
                  </GroupRow>

                  {!this.hasPicture3 && (
                    <AlbumAdd
                      targetUmaId={this.storeValues.laMailAttachmentUmaId}
                      data={this.state.poolAlbum}
                      pictureType="communityProfile"
                      onSubmit={this.setPicture3}
                      title={_('mediamanagement:picfolder.contentItemAdd.title')}
                    />
                  )}

                  {this.hasPicture3 && (
                    <AlbumItem
                      data={{
                        ...this.currentValues.laWelcomeText3Picture,
                        album: { id: this.currentValues.laWelcomeText3Picture.albumId },
                      }}
                      album={{ id: this.currentValues.laWelcomeText3Picture.albumId }}
                      status={this.getItemStatus(this.currentValues.laWelcomeText3Picture)}
                      showDelete={true}
                      showInChecking={true}
                      showDetails={false}
                      showSetAsPreview={false}
                      onClick={this.selectItem}
                      onClickDetails={this.showDetails}
                      onClickMenu={this.showMenu}
                      onClickHideDetails={this.hideDetails}
                      onClickDelete={this.removePicture3}
                    />
                  )}

                  <InputHidden
                    name="laWelcomeText3Picture"
                    value={this.storeValues.laWelcomeText3Picture}
                  />
                </GroupColumnNew>

                <GroupRow tooltip="none">
                  <Separator className="transparent" />
                </GroupRow>

                <ButtonGroup position="right">
                  <Button
                    label={_('common:button.cancel')}
                    onClick={this.rejectChanges}
                    type="button"
                    disabled={!this.activateResetButton}
                  />
                  <Button className="button--blue" label={_('common:button.save')} type="submit" />
                </ButtonGroup>
              </div>
            </Form>
          </section>
        </section>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default AutoMails;
