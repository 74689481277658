import gql from 'graphql-tag';
import { FRAGMENT_MESSAGE } from '../fragments';

export const MUTATION_MASTER_BLOCK_USER = gql`
  mutation VXM_CL_MUTATION_MASTER_BLOCK_USER($token: String!, $userId: Int!) {
    master(token: $token) {
      blockUser(userId: $userId)
    }
  }
`;

export const MUTATION_MASTER_SEND = gql`
  mutation VXM_CL_MUTATION_MASTER_SEND(
    $token: String!
    $userId: Int!
    $text: String = ""
    $fileUUID: String = ""
    $price: Float = 0
  ) {
    master(token: $token) {
      send(toUserId: $userId, text: $text, fileUUID: $fileUUID, price: $price) {
        ...Message
      }
    }
  }
  ${FRAGMENT_MESSAGE}
`;

export const MUTATION_MASTER_SET_MESSAGES_READ = gql`
  mutation VXM_CL_MUTATION_MASTER_SET_MESSAGES_READ($token: String!, $userId: Int!) {
    master(token: $token) {
      setMessagesRead(userId: $userId)
    }
  }
`;

export const MUTATION_MASTER_UNBLOCK_USER = gql`
  mutation VXM_CL_MUTATION_MASTER_UNBLOCK_USER($token: String!, $userId: Int!) {
    master(token: $token) {
      unblockUser(userId: $userId)
    }
  }
`;

export const MUTATION_MASTER_CANCEL_BROADCAST = gql`
  mutation VXM_CL_MUTATION_MASTER_CANCEL_BROADCAST(
    $token: String!
    $broadcastId: Int!
    $revoke: Boolean
  ) {
    master(token: $token) {
      cancelBroadcast(broadcastId: $broadcastId, revoke: $revoke)
    }
  }
`;

export const MUTATION_MASTER_GET_BOT_PIN = gql`
  mutation VXM_CL_MUTATION_MASTER_GET_BOT_PIN($token: String!) {
    master(token: $token) {
      botPin
    }
  }
`;

export const MUTATION_MASTER_UNPINALL_MESSAGES = gql`
  mutation VXM_CL_MUTATION_MASTER_UNPINALL_MESSAGES($token: String!) {
    master(token: $token) {
      unpinMessages
    }
  }
`;
