import React, { FC } from 'react';
import styled from '@emotion/styled';
import CmsArticle from '../../../components/CmsArticle';
import { CmsArticleTitle } from '../../../components';
import { Section } from '../../../atoms';

export const TermsArticle = styled.article`
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  padding: 40px;
`;

interface IProps {
  cmsArticleName: string;
}

const GenericTerms: FC<IProps> = ({ cmsArticleName }) => (
  <Section title={<CmsArticleTitle id={cmsArticleName} />}>
    <TermsArticle>
      <CmsArticle id={cmsArticleName} />
    </TermsArticle>
  </Section>
);

export default GenericTerms;
