import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { envelope } from '../../../../../atoms/Icon/libraries/glyph';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';

const AdmailsItem: FC = () => {
  const hasRoleVXModels = useHasRole(SecurityRole.VX_USER_VXMODELS);

  return hasRoleVXModels ? (
    <LeftMenuItem
      title={_('navigation:marketing.admails')}
      icon={envelope}
      uri="/marketing/admails"
    />
  ) : null;
};

export default AdmailsItem;
