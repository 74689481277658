import { Override } from './types';

export const MuiCssBaseline: Override<'MuiCssBaseline'> = {
  // '@global': {
  //   '@keyframes rotation': {
  //     from: {
  //       transform: 'rotate(0deg)',
  //     },
  //     to: {
  //       transform: 'rotate(359deg)',
  //     },
  //   },
  // },
};
