import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PreviewImageOptions } from './types';

interface Props {
  url: string;
  options?: PreviewImageOptions;
  className?: string;
}

const Container = styled.div`
  position: relative;
  flex: 1 0 0;
`;

interface BackgroundImageDivProps {
  url: string;
}

const BackgroundImageDiv = styled.div<BackgroundImageDivProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props): string => props.url});
`;

const MainImageDiv = styled(BackgroundImageDiv)`
  background-size: contain;
`;

const BlurredImageDiv = styled(BackgroundImageDiv)`
  background-size: cover;
  opacity: 0.5;
  filter: blur(5px);
`;

const PreviewImage: FC<Props> = ({ url, options, className }) => {
  const hasBackgroundBlur = options?.backgroundBlur ?? true;

  return (
    <Container className={className}>
      {hasBackgroundBlur && <BlurredImageDiv url={url} />}
      <MainImageDiv url={url} />
    </Container>
  );
};

export default PreviewImage;
