import React, { FC } from 'react';

import { Select, Button } from '../../../../atoms';

import { AboBenefitSubType, AboRoute, AboWizardActionType, IPageProps } from '../../types';

import PageLayout from '../PageLayout';
import { _ } from '../../../../util/translate';

const AboRedeemPageFreeMessages: FC<IPageProps> = ({ dispatch, state }) => {
  const handleBackClick = () => {
    dispatch({ type: AboWizardActionType.GoBack, payload: {} });
  };

  const handleContinueClick = () => {
    dispatch({ type: AboWizardActionType.GoToRoute, payload: { route: AboRoute.AboDetailsPage } });
  };

  const canContinue = () => {
    return (
      state.aboRedeemDetails.type === AboBenefitSubType.FlatRate ||
      (state.aboRedeemDetails.type === AboBenefitSubType.Flat && state.aboRedeemDetails.data.amount)
    );
  };

  const handleRedeemTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: AboWizardActionType.SetAboRedeemDetails,
      payload: {
        aboRedeemDetails: {
          type: event.target.value as AboBenefitSubType,
          data: { amount: '' },
        },
      },
    });
  };

  const handleRedeemDetailsDataChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: AboWizardActionType.SetAboRedeemDetails,
      payload: {
        aboRedeemDetails: {
          type: state.aboRedeemDetails.type,
          data: { amount: event.target.value },
        },
      },
    });
  };

  const optionsSubscriptionType = [
    {
      label: _('benefits:abo.freemessages.options.freeMessagesFlat'),
      value: AboBenefitSubType.Flat,
    },
    {
      label: _('benefits:abo.freemessages.options.freeMessagesFlatRate'),
      value: AboBenefitSubType.FlatRate,
    },
  ];

  return (
    <PageLayout
      title={_('benefits:abo.freemessages.title')}
      subtitle={_('benefits:abo.freemessages.subtitle')}
      footer={() => (
        <section css={{ justifyContent: 'space-between', marginTop: 32 }}>
          <Button theme="border-gray" onClick={handleBackClick} css={{ alignItems: 'center' }}>
            <span className="icon-chevron-left" css={{ fontSize: 8, marginRight: 8 }} />
            <span>{_('benefits:texts.back')}</span>
          </Button>
          <Button
            theme={canContinue() ? 'blue' : 'gray'}
            onClick={handleContinueClick}
            disabled={!canContinue()}
            css={{ alignItems: 'center' }}
          >
            <span>{_('benefits:texts.continue')}</span>
            <span className="icon-chevron-right" css={{ fontSize: 8, marginLeft: 8 }} />
          </Button>
        </section>
      )}
    >
      <section css={{ flexDirection: 'column' }}>
        <label css={{ flexDirection: 'column' }}>
          <span css={{ fontSize: 14, margin: '0 0 2px 2px' }}>
            {_('benefits:abo.texts.subscriptionType')}
          </span>

          <Select
            placeholder={_('common:dropdown.placeholder.pleaseSelect')}
            options={optionsSubscriptionType}
            onChange={handleRedeemTypeChange}
            value={state.aboRedeemDetails.type}
            css={{ width: 448 }}
          />
        </label>

        {state.aboRedeemDetails.type === AboBenefitSubType.Flat && (
          <label css={{ flexDirection: 'column', marginTop: 16 }}>
            <span css={{ fontSize: 14, margin: '0 0 2px 2px' }}>
              {_('benefits:abo.texts.enterQuantity')}
            </span>
            <div css={{ alignItems: 'center' }}>
              <Select
                placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                options={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' },
                  { value: '250', label: '250' },
                  { value: '500', label: '500' },
                  { value: '1000', label: '1000' },
                  { value: '2500', label: '2500' },
                ]}
                onChange={handleRedeemDetailsDataChange}
                value={state.aboRedeemDetails.data.amount}
                css={{ width: 190 }}
              />

              <p css={{ marginLeft: 8 }}>
                {_('benefits:abo.freemessages.texts.freeMessagesPeriod')}
              </p>
            </div>
          </label>
        )}
      </section>
    </PageLayout>
  );
};

export default AboRedeemPageFreeMessages;
