import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { Service0900PremiumNumber, Maybe } from '../../../../graphql/VXModels/types';
import GenericWarningText from '../../../../atoms/GenericWarningText/GenericWarningText';
import { RED } from '../../../../camtool-styles';

interface Props {
  visitX0900Numbers: Maybe<Service0900PremiumNumber>[];
}

const NumbersList0900: FC<Props> = ({ visitX0900Numbers }) => {
  return visitX0900Numbers?.length > 0 ? (
    <>
      <ul css={{ listStyle: 'none', marginBottom: '20px' }}>
        {visitX0900Numbers.map((item) => (
          <li
            key={item?.countryCodeAlpha2}
            css={{
              alignItems: 'center',
              display: 'flex',
              marginTop: 24,
              fontSize: 16,
              whiteSpace: 'nowrap',
            }}
          >
            <span className={`flag-${item?.countryCodeAlpha2}`} />
            <span css={{ marginLeft: 4, fontWeight: 'bold', flex: '0 0 108px' }}>
              {_(`enum:common.country.${item?.countryCodeAlpha2.toUpperCase()}`) + ':'}
            </span>
            <span css={{ marginLeft: 4 }}>
              {`${item?.baseNumber.slice(0, 4)} ${item?.baseNumber.slice(
                4,
                7
              )} ${item?.baseNumber.slice(7)} ${item?.countryCodeAlpha2 === 'ch' ? '' : item?.ddi}`}
            </span>
          </li>
        ))}
      </ul>
      <GenericWarningText text={_('service0900:phoneSex.pleaseNote2')} />
    </>
  ) : (
    <span css={{ marginTop: '8px', color: RED }}>{_('common:text.warning.locked')}</span>
  );
};

export default NumbersList0900;
