import React, { FunctionComponent } from 'react';
import useVXCountdown from '../../../hooks/VXGamesCountdown/useVXCountdown';
import { Box, Typography } from '@material-ui/core';
import { VXCountdownProps } from './types';
import { useVXCountdownStyles } from './styles';

const VXCountdown: FunctionComponent<VXCountdownProps> = ({
  startDateISOString,
  endDateISOString,
}) => {
  const countdownTilStart = useVXCountdown(startDateISOString);
  const countdown = useVXCountdown(endDateISOString);
  const classes = useVXCountdownStyles();

  const countdownUntilDays = countdownTilStart?.days || '0';
  const countdownDays = countdown?.days || '0';
  const countdownHours = countdown?.hours || '0';
  const countdownMinutes = countdown?.minutes || '0';

  const isFinished =
    Number(countdownDays) === 0 && Number(countdownHours) === 0 && Number(countdownMinutes) === 0;
  return (
    <Box className={classes.countdownPosition}>
      <Box display={'flex'} flexDirection={'column'} className={classes.countdownContainer}>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Box display={'flex'} alignItems={'center'} px={3} className={classes.challengeInfo}>
            <Box>
              {countdownUntilDays > 0 ? (
                <Typography className={classes.body1}>Challenge beginnt in:</Typography>
              ) : isFinished ? (
                <Typography className={classes.body1}>Challenge ist:</Typography>
              ) : (
                <Typography className={classes.body1}>Herausforderungszeitraum:</Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          className={classes.dateContainer}
        >
          {countdownUntilDays > 0 ? (
            <Box className={classes.digits} display={'flex'} flexDirection={'column'}>
              <Typography className={classes.body2}>{countdownUntilDays}</Typography>
              <Typography className={classes.subtitle1}>Tagen</Typography>
            </Box>
          ) : isFinished ? (
            <Box className={classes.digits} display={'flex'} flexDirection={'column'}>
              <Typography className={classes.body2}>Beendet</Typography>
              <Typography className={classes.subtitle1}></Typography>
            </Box>
          ) : (
            <>
              <Box className={classes.digits} display={'flex'} flexDirection={'column'}>
                <Typography className={classes.body2}>{countdownDays}</Typography>
                <Typography className={classes.subtitle1}>Tage</Typography>
              </Box>
              <Box className={classes.countdownBorder}></Box>
              <Box className={classes.digits} display={'flex'} flexDirection={'column'}>
                <Typography className={classes.body2}>{countdownHours}</Typography>
                <Typography className={classes.subtitle1}>Stunden</Typography>
              </Box>
              <Box className={classes.countdownBorder}></Box>
              <Box className={classes.digits} display={'flex'} flexDirection={'column'}>
                <Typography className={classes.body2}>{countdownMinutes}</Typography>
                <Typography className={classes.subtitle1}>Minuten</Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VXCountdown;
