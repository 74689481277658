import React, { memo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Emojis from './emojis';
import Button from '../../Button/Button';
import { useClickOutsideCallback } from '../../../hooks';

const StyledDiv = styled.div`
  position: relative;
  width: 10% !important;
  // todo: declare global min-width breakpoint variables in camtool-styles and import from there
  @media (min-width: 767px) {
    width: 3% !important;
  }
`;

const EmojisContainer = styled.div`
  display: flex !important;
  // bottom: 0;
  top: 0px;
  transform: translate(-100%, -100%);
  width: 258px !important;
  position: absolute;
  flex-wrap: wrap;
  overflow: auto;
  height: 176px;
  padding: 4px;
  background-color: rgba(255, 255, 228, 0.9);
  z-index: 999;
`;

const Emoji = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  width: 29px;
  height: 29px;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

interface IEmojiPickerProps {
  onInsertEmoji: (emoji: string) => void;
  className?: string;
  disabled: boolean;
}

const EmojiPicker = memo(({ onInsertEmoji, className, disabled }: IEmojiPickerProps) => {
  const ref = useRef();
  const [visible, setVisible] = useState(false);

  const showPicker = () => {
    setVisible(true);
  };

  const hidePicker = () => {
    setVisible(false);
  };

  useClickOutsideCallback(ref, hidePicker);

  const insertEmoji = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onInsertEmoji(event.currentTarget.innerHTML);
  };

  return (
    <StyledDiv ref={ref} className={className}>
      <Button theme="none" disabled={disabled} onMouseEnter={showPicker}>
        🙂
      </Button>
      {visible && (
        <EmojisContainer>
          {/* onMouseLeave={hidePicker} */}
          {Emojis.map((emoji) => (
            <Emoji onClick={insertEmoji} key={emoji}>
              {emoji}
            </Emoji>
          ))}
        </EmojisContainer>
      )}
    </StyledDiv>
  );
});

EmojiPicker.displayName = 'EmojiPicker';

export default EmojiPicker;
