import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Mailings {
    draft: MailingMailDraft
  }

  type EmailDraft {
    id: Int
    targetGroup: [MailingRecipientsGroupsFiltersEnum!]!
    subject: String!
    textHTML: String
    releaseDate: DateTime!
  }
`;
