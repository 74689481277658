import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { VXCashUsersActionCreators } from '../../../stores/Lists/VXCashUsers/VXCashUsersActionCreators';
import { VXCashUsersStore } from '../../../stores/Lists/VXCashUsers/VXCashUsersStore';

import { EmptyContent, FormatDate, Spinner, T } from '../../../components';

import { _ } from '../../../util/translate';
import { WHITE } from '../../../camtool-styles';

const STORE = 'recruitedHosts';

class VXCashRecruitedHosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getStoreData();
  }

  componentDidMount() {
    VXCashUsersStore.addChangeListener(STORE, this.onStoreChange);
    this.loadData();
  }

  componentWillUnmount() {
    VXCashUsersStore.removeChangeListener(STORE, this.onStoreChange);
  }

  getStoreData = () => cloneDeep(VXCashUsersStore.get(STORE));

  loadData = () => {
    this.setState({ loadMoreButtonDisabled: true });
    VXCashUsersActionCreators.getData(
      STORE,
      this.state.sorting.field,
      this.state.sorting.direction,
      this.state.pagination.limit,
      this.state.pagination.offset
    );
  };

  onStoreChange = () => {
    let storeData = this.getStoreData();

    this.setState((state) => ({
      loadMoreButtonDisabled: false,
      showSpinner: false,
      userlist: storeData.userlist,
      totalEntries: storeData.totalEntries,
      pagination: { ...state.pagination, offset: state.userlist.length + 1 },
    }));
  };

  getSortClass = (name) => {
    if (this.state.sorting.field === name) {
      return name + ' ' + this.state.sorting.direction;
    }
    return name;
  };

  onClickSort = (event, fieldname) => {
    let thisField = 'registerDate';
    let thisSort = 'desc';
    let sortOptions = ['username', 'registerDate', 'isNewHost', 'hasPayout'];
    let classNames = event.currentTarget.className.split(' ');
    for (let cn in classNames) {
      if (sortOptions.indexOf(classNames[cn]) !== -1) {
        thisField = classNames[cn];
        thisSort =
          this.state.sorting.field === thisField
            ? this.state.sorting.direction === 'asc'
              ? 'desc'
              : 'asc'
            : 'asc';
        break;
      }
    }
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.showSpinner = true;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.pagination.offset = 1;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.sorting.field = thisField;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.sorting.direction = thisSort;
    this.setState(this.state);
    this.loadData();
  };

  render() {
    return (
      <div className="grid__box__column grid__box__sub vxcash-recruited-hosts min-width--0">
        <div className="grid__box__sub__header">
          <div className="icon-user-structure grid__box__sub__header__item grid__box__sub__header__item--active">
            <T _={'lists:userlist.recruitedHosts.info.boxTitle'} />
          </div>
        </div>

        <div className="grid__box__sub__item vxcash-recruited-hosts-list list">
          <div
            className="grid__box__sub__item__content spinner-container"
            style={{ backgroundColor: WHITE }}
          >
            {/*  SPINNER */}
            {this.state.showSpinner && <Spinner />}

            {/* SHOW DATA LIST */}
            {this.state.userlist.length > 0 && (
              <Fragment>
                <header className="list-header">
                  <div className={`${this.getSortClass('username')} sortable column-username`}>
                    <div className={this.getSortClass('username')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:userlist.tablefields.username'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div
                    className={`${this.getSortClass('registerDate')} sortable column-registerDate`}
                  >
                    <div className={this.getSortClass('registerDate')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:userlist.tablefields.registerDate'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div className={`${this.getSortClass('isNewHost')} sortable column-isNewHost`}>
                    <div className={this.getSortClass('isNewHost')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:userlist.tablefields.isNewHost'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div className={`${this.getSortClass('hasPayout')} sortable column-hasPayout`}>
                    <div className={this.getSortClass('hasPayout')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'lists:userlist.tablefields.hasPayout'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                </header>

                {this.state.userlist.map((item) => (
                  <article className="list-entry" key={item.id}>
                    <div className="column-username">{item.username}</div>
                    <div className="column-registerDate">
                      <FormatDate value={item.registerDate} />
                    </div>
                    <div className={`column-isNewHost ${item.isNewHost}`} />
                    <div className={`column-hasPayout ${item.hasPayout ? 'yes' : 'no'}`} />
                  </article>
                ))}

                {this.state.userlist.length < this.state.totalEntries && (
                  <div
                    className={`list-load-more ${
                      this.state.loadMoreButtonDisabled ? 'disabled' : ''
                    }`}
                  >
                    <div>
                      <div onClick={this.loadData}>
                        <div className="icon" />
                        <div className="label">
                          <T _={'common:button.loadMoreContents'} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}

            {/*  DATA NOT FOUND */}
            {this.state.userlist.length < 1 && (
              <EmptyContent title={_('lists:userlist.recruitedHosts.info.noUserFound')} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default VXCashRecruitedHosts;
