import React from 'react';

import AbstractComponent from '../../../components/AbstractComponent';
import { VXCashStore } from '../../../stores/VXCash/VXCashStore';
import { VXCashActionCreators } from '../../../stores/VXCash/VXCashActionCreators';
import Footer from '../../../components/Footer/Footer';

import VisitX from './components/VisitX';
import Instagram from './components/Instagram';
import Contact from '../../../atoms/Contact/Contact';
import { getMarketingMail } from '../../../util/emailAdresses';
import { Main } from '../../../atoms/Grid';
import { SessionStore } from '../../../stores/Session/Session';

const STORE = 'adlinks';

class InviteUser extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getStateFromStores(),
    };
  }

  getStateFromStores() {
    return VXCashStore.get(STORE);
  }

  onStoreChange() {
    const stateFromStore = this.getStateFromStores();
    stateFromStore.isDataLoading = false;
    this.setState(stateFromStore);
  }

  componentWillMount() {
    VXCashStore.addChangeListener(STORE, this.onStoreChange);
    VXCashActionCreators.getVXCashAdlinks();
  }

  componentWillUnmount() {
    VXCashStore.removeChangeListener(STORE, this.onStoreChange);
  }

  render() {
    const context = { userData: SessionStore.get() };
    const userName = context.userData.userName.toLowerCase();
    const urlStart = 'https://';
    const urlEnd = '.vxsocials.net';

    return (
      <Main isMobileEnabled>
        <VisitX dataLoading={this.state.isDataLoading} link={this.state.adlinks.visitx} />
        <Instagram
          dataLoading={this.state.isDataLoading}
          link={urlStart.concat(userName, urlEnd)}
          status={this.state.instagram.status}
          rejectedUntil={this.state.instagram.rejectedUntil}
        />
        <Contact
          msgTranslationKey={'vxcash:inviteGuest.contactMsg'}
          noteTranslationKey={'vxcash:inviteGuest.note'}
          email={getMarketingMail()}
        />

        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </Main>
    );
  }
}

export default InviteUser;
export { InviteUser };
