import gql from 'graphql-tag';

export const FRAGMENT_TIPPING = gql`
  fragment Tipping on Tipping {
    id
    amount
    currency
    read
    date
  }
`;
