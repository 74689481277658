import { useApolloContext } from './useApolloContext';
import { useMutation } from '@apollo/react-hooks';
import { updateUserBlockStatus } from '../graphql/util';
import { MUTATION_MASTER_BLOCK_USER } from '../graphql/mutations/master';
import { useVXServicesTelegramClient } from '../../../graphql';

export const useTelegramBlockUser = ({ userId }: { userId: number }) => {
  const { token } = useApolloContext();
  const variables = { token, userId };
  return useMutation(MUTATION_MASTER_BLOCK_USER, {
    client: useVXServicesTelegramClient(),
    variables,
    optimisticResponse: {
      __typename: 'Mutation',
      master: {
        blockUser: true,
        __typename: 'MasterMutation',
      },
    },
    update: (
      cache,
      {
        data: {
          master: { blockUser: isBlocked },
        },
      }
    ) => updateUserBlockStatus(isBlocked, cache, token, userId),
  });
};

export default useTelegramBlockUser;
