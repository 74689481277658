import { ITableColumn } from '../../../../../atoms/Table/types';
import { _ } from '../../../../../util/translate';

const getColumns = (displayDeactivationDate: boolean): ITableColumn[] => [
  { label: _('benefits:abo.archiveColumn.status'), width: 0.5 },
  { label: _('benefits:abo.archiveColumn.type'), width: 0.95 },
  { label: _('benefits:abo.archiveColumn.name'), width: 0.7 },
  { label: _('benefits:abo.archiveColumn.subscribers'), width: 0.48 },
  { label: _('benefits:abo.archiveColumn.price'), width: 0.48 },
  {
    label: _(
      displayDeactivationDate
        ? 'benefits:abo.archiveColumn.deactivationDate'
        : 'benefits:abo.archiveColumn.startDate'
    ),
    width: 0.6,
  },
  { label: _('benefits:abo.archiveColumn.autoRenew'), width: 0.7 },
  // { label: _('common:button.edit'), width: 0.6 },
];

export { getColumns };
