import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import {
  CardHeader as MUICardHeader,
  CardHeaderProps as MUICardHeaderProps,
} from '@material-ui/core';
import { DARK_GRAY, WHITE } from '../../camtool-styles';

interface CardHeaderProps extends MUICardHeaderProps {
  inverted?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: () => ({
      backgroundColor: DARK_GRAY,
      color: WHITE,
      padding: '14px 24px', // @todo Design check with CAG
      textTransform: 'uppercase',
    }),
  })
);

export const CardHeaderInverted: FC<CardHeaderProps> = ({ children, ...props }) => (
  <MUICardHeader classes={useStyles()} {...props}>
    {children}
  </MUICardHeader>
);
