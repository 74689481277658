import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import ActionTypes from '../../util/constants/ActionTypes';
import { EventEmitter } from 'events';

const CHANGE_EVENT = 'VXCashStore.change';

let storeData = {
  dashboard: {
    hasWebmasterId: false,
    turnoverDetails: [],
    vxcashAutologinLink: '',
  },
  register: {
    hasWebmasterId: false,
    webmasterId: 0,
    isDataLoading: true,
    isSubmitButtonDisabled: true,
  },
  fullstat: {
    vxcashAutologinLink: '',
    isDataLoading: true,
    isSubmitButtonDisabled: true,
    months: [{ label: '', value: '' }],
    years: [{ label: '', value: '' }],
    selectedMonth: 0,
    selectedYear: 0,
    promolink: {
      turnover: {
        visitors: 0,
        freeSignups: 0,
        paidSignups: 0,
        amountTotal: 0,
      },
      dailyStat: [],
    },
  },
  adlinks: {
    provision: 0.0,
    isDataLoading: true,
    vxcashAutologinLink: '',
    userId: 0,
    userName: '',
    webmasterId: 0,
    adlinks: {},
    instagram: {
      link: null,
      status: null,
      rejectedUntil: null,
    },
  },
};

class VXCashStoreClass extends EventEmitter {
  init(data) {
    storeData = data;
  }

  emitChange(store) {
    this.emit(CHANGE_EVENT + '.' + store);
  }

  addChangeListener(store, callback) {
    this.on(CHANGE_EVENT + '.' + store, callback);
  }

  removeChangeListener(store, callback) {
    this.removeListener(CHANGE_EVENT + '.' + store, callback);
  }

  get(store) {
    if (!storeData[store]) {
      throw new InvalidStoreException(store);
    }
    return storeData[store];
  }
}

const VXCashStore = new VXCashStoreClass();

function InvalidStoreException(store) {
  this.STORE = store;
  this.message = 'Invalid STORE';
  this.toString = function () {
    return this.message + ': ' + this.store;
  };
}

AppDispatcher.register(function (payload) {
  let store = payload.store;
  switch (payload.type) {
    case ActionTypes.VXCASH_RECEIVE_DATA:
      storeData[store] = update(storeData[store], { $merge: payload.data });
      VXCashStore.emitChange(store);
      break;
    default:
  }
});

export { VXCashStore };
