import React, { FC, useEffect, useState } from 'react';

import { UploadManagerContext } from './UploadManagerContext';
import { UploadManagerStore } from '../store/UploadManagerStore';
import { useUserData } from '../../../util/UserData';
import { useAppState } from '../../../util/AppState';
import { QueueItem, StateEnum, UserData } from '../types';
import { hasConfirmExit, registerConfirmExit, unregisterConfirmExit } from '../util';
import { _ } from '../../../util/translate';

/**
 * This Provider is mainly used for the generic uploader where
 * also the states after the upload (e.g. transcoding, finished) are presented in the queue item.
 *
 * For the tus client this is not important anymore since there is only the uploading (+verify, waiting) state visible
 * in the queue item -> the refetching of the transcoding items happens in the component itself.
 */
export const UploadManagerProvider: FC = ({ children }) => {
  const [{ sessionId }] = useAppState();
  const { auth, model } = useUserData();
  const picturePoolUmaId = model?.extra?.picturePoolUmaId;
  const userData: UserData = { sessionId: sessionId as string, userId: auth?.id, picturePoolUmaId };
  const [queue, setQueue] = useState<QueueItem[]>([]);

  useEffect(() => {
    UploadManagerStore.queueSubscribe((queue: QueueItem[] = []) => setQueue(queue));
  }, []);

  useEffect(() => UploadManagerStore.updateUserData(userData), [userData]);

  useEffect(() => {
    const hasActiveUploads = !!queue.find((i) =>
      [StateEnum.uploading, StateEnum.verifying, StateEnum.waiting, StateEnum.afterUpload].includes(
        i.state
      )
    );
    const confirmExit = hasConfirmExit();

    if (hasActiveUploads && !confirmExit) {
      registerConfirmExit(_('mediamanagement:multiuploader.reloadMessage'));
    }
    if (!hasActiveUploads && confirmExit) {
      unregisterConfirmExit();
    }
  }, [queue]);

  return (
    <UploadManagerContext.Provider value={UploadManagerStore}>
      {children}
    </UploadManagerContext.Provider>
  );
};

UploadManagerProvider.displayName = 'UploadManagerProvider';
