import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.04);
  font-size: 182px;
`;

const Quote = ({ title, quote, author, url, backgroundImg, children }) => (
  <div css={{ color: '#878787', position: 'relative', padding: '52px 0' }}>
    <StyledDiv>
      <span className="icon-chat" />
    </StyledDiv>
    <blockquote cite={url}>
      {children}
      <cite>{author}</cite>
    </blockquote>
  </div>
);

Quote.defaultProps = {
  title: '',
  author: '',
  url: '',
  backgroundImg: '',
};

Quote.propType = {
  title: PropTypes.string,
  author: PropTypes.string,
  url: PropTypes.string,
  backgroundImg: PropTypes.string,
};

export default Quote;
