import React, { FC } from 'react';
import { StyledSection } from '../../styles';
import { MailingStatusEnum } from '../../../../../graphql/VXModels/types';
import FieldActionButton from '../../../../../molecules/Formik/Fields/FieldActionButton';
import { ActionEnum } from '../../../types';
import { _ } from '../../../../../util/translate';
import { FormValues } from '../FormWrapper';

interface ButtonGroupProps {
  status: MailingStatusEnum;
  values: FormValues;
  isPublishDisabled: boolean;
  isSaveDisabled: boolean;
}

const SubmitButtonGroup: FC<ButtonGroupProps> = ({
  status,
  values: { publishScheduled },
  isPublishDisabled,
  isSaveDisabled,
}) => (
  <StyledSection css={{ flexDirection: 'row' }}>
    {status === MailingStatusEnum.DRAFT && (
      <>
        <FieldActionButton
          name={'action'}
          action={ActionEnum.SAVE}
          theme={'border-gray'}
          label={_('mailings:text.saveDraft')}
          disabled={isSaveDisabled}
        />
        <FieldActionButton
          name={'action'}
          action={ActionEnum.PUBLISH}
          theme={'blue'}
          label={
            publishScheduled
              ? _('mailings:text.approveSendScheduled')
              : _('mailings:text.approveSend')
          }
          disabled={isPublishDisabled}
        />
      </>
    )}
    {status === MailingStatusEnum.SHIPMENT_PENDING && (
      <FieldActionButton
        name={'action'}
        action={ActionEnum.SAVE}
        theme={'border-gray'}
        label={_('mailings:text.approveEdit')}
      />
    )}
  </StyledSection>
);

export default SubmitButtonGroup;
