import React from 'react';

import { getFormattedNumber } from '../../../util/Formatter';

import DefaultWidget from './DefaultWidget';
import { useLang } from '../../../util/AppState';
import { _ } from '../../../util/translate';
import PropTypes from 'prop-types';

const ChatRatings = React.memo(({ loading, className, chatRating }) => {
  const [lang] = useLang();

  return (
    <DefaultWidget
      loading={loading}
      className={className}
      iconName="star"
      iconColor="#ffb033"
      label={_('dashboard:app.chatRatings.header')}
      data={getFormattedNumber(!!chatRating ? chatRating : 0, lang)}
      footnote={_('dashboard:app.chatRatings.footer')}
    />
  );
});

ChatRatings.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.any,
  chatRating: PropTypes.any,
};

ChatRatings.displayName = 'ChatRatings';

export default ChatRatings;
