import styled from '@emotion/styled';
import { WHITE, GRAY_1, BLACK, DARK_GRAY } from '../../camtool-styles';
import Background from '../../style/img/profile_title_pic_bg.jpg';
import { css } from '@emotion/core';
import { Props } from './CorePictureWidget';

export const HoverOverlay = styled.div`
  display: flex;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: ${GRAY_1};
  color: ${WHITE};
  position: absolute;
  top: 38px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  text-decoration: none;
`;

export const OverlayWrapper = styled.div<Pick<Props, 'backgroundUrl'>>`
  display: flex;
  margin: 8px 8px 8px 0;
  position: relative;
  background-image: url(${(props): string =>
    props.backgroundUrl ? props.backgroundUrl : Background});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    display: flex;
    padding-top: 75%;
  }

  &:hover {
    ${HoverOverlay} {
      transition: opacity 0.3s ease-in;
      cursor: pointer;
      opacity: 0.9;
    }
  }
`;

export const Title = styled.span`
  color: ${WHITE};
  background-color: ${BLACK};
  padding: 8px 10px;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  height: fit-content;
  width: 100%;
  text-align: center;
  justify-content: center;
  cursor: default;
`;

export const Overlay = styled.div`
  align-items: center;
  position: absolute;
  top: 80%;
  right: 0px;
  left: 0px;
  bottom: 0px;
  padding: 0 8px;
  background-color: rgba(255, 255, 255, 0.3);
`;

export const OverlayBase = css`
  height: 13px;
  border-radius: 10px;
  background-color: ${WHITE};
`;

export const OverlayStatus = styled.div`
  flex: 0 0 13px;
  margin-right: 4px;
  ${OverlayBase}
`;

export const OverlayName = styled.div`
  flex: 0 1 60px;
  ${OverlayBase}
`;

export const OverlaySpacer = styled.div`
  flex: 1;
`;

export const OverlayLang = styled.div`
  flex: 0 0 21px;
  ${OverlayBase}
`;

export const ModalContainer = styled.div`
  flex-direction: column;
  min-height: 376px;
  color: ${DARK_GRAY};
  justify-content: center;
  align-items: center;
`;

export const WarningContent = styled.div`
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  max-width: 400px;
  text-align: center;
  justify-content: space-evenly;
`;

export const ModalSingleUploader = styled.div`
  @media (max-width: 768px) {
    min-width: 550px !important;
  }
`;
