import React, { FC } from 'react';
import { ApiLang } from '../../../../graphql/VXModels/types';
import { _ } from '../../../../util/translate';
import { Menu, MenuItem, Box, Typography, Theme } from '@material-ui/core';
import NewButton from '../../../../atoms/Button/NewButton';
import Flag from '../../../../atoms/Flag';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: 'none',
      boxShadow: `3px 3px 5px 6px ${theme.palette.grey[400]}`,
    },
  })
);

interface Props {
  language: ApiLang;
  changeLanguage: (lang: ApiLang) => void;
  disabled?: boolean;
}

const LanguageToggle: FC<Props> = ({ language, changeLanguage, disabled = false }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onSelect = (lang: ApiLang): void => {
    changeLanguage(lang);
    handleClose();
  };

  return (
    <>
      <NewButton variant="text" color="default" onClick={handleClick} disabled={disabled}>
        <Flag lang={language} css={{ marginRight: '4px', height: '13px' }} />
        <Typography>{_(`enum:common.languages.${language}`)}</Typography>
        <KeyboardArrowDownIcon />
      </NewButton>
      <Menu classes={classes} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {[ApiLang.de, ApiLang.en].map((lang, index) => (
          <MenuItem key={index} selected={lang === language} onClick={(): void => onSelect(lang)}>
            <Box mr={1}>
              <Flag lang={lang} css={{ marginRight: '4px', height: '13px' }} />
            </Box>
            <Typography>{_(`enum:common.languages.${lang}`)}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageToggle;
