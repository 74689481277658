import React, { ReactElement } from 'react';

const VisitXIcon = (): ReactElement => {
  return (
    <g>
      <polygon points="17.8,14.8 19.9,17.8 17.1,17.8 16.4,16.8 15.3,18.5 16.1,19.7 23.5,19.7 19,13.1  " />
      <polygon points="12,10.5 10,7.5 7.7,7.5 12,13.8 17.6,5.7 20.4,5.7 12,17.8 3.6,5.7 11.5,5.7 12.7,7.4 13.9,5.8    12.5,3.8 0,3.8 12,21.1 24,3.8 16.6,3.8  " />
    </g>
  );
};

export default VisitXIcon;
