import styled from '@emotion/styled';
import React from 'react';

import { BLUE, BLUE_HOVER, BLACK_1, WHITE, WHITE_2, GRAY_3 } from '../../camtool-styles';

export const Container = styled.div`
  flex-direction: column;
  color: #0b0b0b;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_3};
  border-radius: 4px;
  position: relative;
`;

export const ToolbarContainer = styled.div`
  border-bottom: 1px solid ${GRAY_3};
  padding: 8px 8px 0 8px;
  flex-wrap: wrap;
  flex: 0 0 auto;
  justify-content: space-between;
`;

export const EditorContainer = styled.div`
  overflow: auto;
  font-family: 'Arial', sans-serif;
  color: ${BLACK_1};
  padding: 16px;
  height: 100%;
  div {
    display: block;
    width: 100%;
  }
  // cursorfix draftjs not working with flexbox props
  // VXM-426
  & .public-DraftStyleDefault-block > span {
    display: block;
  }
`;

export const RichButtonGroup = styled.div`
  color: ${BLACK_1};
  overflow: hidden;
  border: 1px solid ${GRAY_3};
  border-radius: 4px;
  margin: 0 8px 8px 0;
`;

interface IRichButtonProps {
  active: boolean;
}

export const RichButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: ${({ active }: { active: IRichButtonProps }) => (active ? WHITE_2 : WHITE)};
  padding: 8px 4px;
  &:disabled {
    color: #c0c0c0;
  }
  &:hover {
    &:not(:disabled) {
      background-color: ${WHITE_2};
    }
  }
  &:first-of-type,
  &:last-child {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

interface IStyleButton {
  active?: boolean;
  style?: string;
  onToggle: (style: string) => void;
  children: JSX.Element;
  disabled?: boolean;
}

export const StyleButton = (props: IStyleButton) => {
  const onToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.style) {
      props.onToggle(props.style);
    } else {
      props.onToggle('');
    }
  };

  return (
    <RichButton onClick={onToggle} active={props.active} disabled={props.disabled}>
      {props.children}
    </RichButton>
  );
};

export const StyledA = styled.a`
  color: ${BLUE};
  display: inline-flex;
  &:hover {
    ${BLUE_HOVER}
  }
`;

// prettier-ignore
export const FONT_SIZES = {
  'SIZE:8'  : { fontSize: 8   },
  'SIZE:10' : { fontSize: 10  },
  'SIZE:12' : { fontSize: 12  },
  'SIZE:16' : { fontSize: 16  },
  'SIZE:24' : { fontSize: 24  },
  'SIZE:32' : { fontSize: 32  },
  'SIZE:64' : { fontSize: 64  },
  'SIZE:72' : { fontSize: 72  },
  'SIZE:104': { fontSize: 104 },
};

// prettier-ignore
export const FONT_FAMILY = {
  "FONT:ARIAL":            { fontFamily: `'Arial', sans-serif` },
  "FONT:COURIER":          { fontFamily: `'Courier', sans-serif` },
  "FONT:GEORGIA":          { fontFamily: `'Georgia', serif` },
  "FONT:LUCIDA_CONSOLE":   { fontFamily: `'Lucida Console', sans-serif` },
  "FONT:TIMES_NEW_ROMAN":  { fontFamily: `'Times New Roman', serif` },
  "FONT:VERDANA":          { fontFamily: `'Verdana', sans-serif` },
};
