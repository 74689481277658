import React from 'react';
import { Field, FieldArray } from 'formik';
import {
  MailingRecipientsGroupsEnum,
  MailingTypeEnum,
} from '../../../../../graphql/VXModels/types';
import { MailingRecipientsFilter } from '../../MailingRecipientsFilter/MailingRecipientsFilter';
import { Grid } from '@material-ui/core';

interface FieldMailingsRecipientsFilterProps {
  name: string;
  disabled: boolean;
  type: MailingTypeEnum;
  recipientsGroup: MailingRecipientsGroupsEnum;
  recipientsCount: number;
  onUpdateCount: (recipientsCount: number) => void;
}

const FieldMailingsRecipientsFilter = ({ name, ...props }: FieldMailingsRecipientsFilterProps) => {
  return (
    <Field
      name={name}
      render={() => (
        <FieldArray
          name={name}
          render={({ push, remove, form: { values } }) => {
            const selected = values[name] || [];

            const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.checked) {
                push(event.target.value);
              } else {
                remove(selected.indexOf(event.target.value));
              }
            };

            return (
              <Grid container spacing={3} style={{ marginBottom: 16 }}>
                <MailingRecipientsFilter selected={selected} onChange={handleChange} {...props} />
              </Grid>
            );
          }}
        />
      )}
    />
  );
};

export default FieldMailingsRecipientsFilter;
