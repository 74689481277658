import React, { FC, useState, ReactElement } from 'react';
import { Button } from '../../../../atoms';
import { _ } from '../../../../util/translate';
import { DARK_GRAY } from '../../../../camtool-styles';
import GenericModalBoxLayout from '../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import Icon from '../../../../atoms/Icon/Icon';
import { floppyDisk } from '../../../../atoms/Icon/libraries/glyph';
import { ModalContainer, FieldMessengerWrapper } from './styles';
import { Field, FieldProps } from 'formik';
import MessengerEditor from '../Form/MessengerEditor/MessengerEditor';

interface Props {
  closeModalTextEditor: () => void;
  index: number;
}

const ModalAutoMailTextEditor: FC<Props> = ({ closeModalTextEditor, index }) => {
  const [text, setText] = useState('');

  return (
    <Field name={`autoMailList[${index}].text`}>
      {({ field, form }: FieldProps): ReactElement => {
        const isTextNotDirty =
          form?.initialValues?.autoMailList?.[index]?.text === text ||
          form?.values?.autoMailList?.[index]?.text === text;
        return (
          <GenericModalBoxLayout maxWidth={500} name={'text-editor'} onClose={closeModalTextEditor}>
            <ModalContainer>
              <span
                css={{
                  color: DARK_GRAY,
                }}
              >
                {_('modalbox:autoMail.message.title')}
              </span>

              <FieldMessengerWrapper>
                <MessengerEditor {...field} onChange={setText} emoji={true} disabled={false} />
              </FieldMessengerWrapper>
              <Button
                type="submit"
                theme={isTextNotDirty ? 'gray' : 'blue'}
                onClick={(): void => {
                  form?.setFieldValue(field.name, text);
                  closeModalTextEditor();
                }}
              >
                <Icon
                  icon={floppyDisk}
                  size={'14px'}
                  css={{
                    marginRight: '5px',
                  }}
                />
                <span>{_('common:button.save')}</span>
              </Button>
            </ModalContainer>
          </GenericModalBoxLayout>
        );
      }}
    </Field>
  );
};

export default ModalAutoMailTextEditor;
