import React, { FC, RefObject, useRef } from 'react';
import { Button } from '../../../atoms';
import { ERROR_RED, GRAY_8, NOT_THAT_WHITE } from '../styles';
import { _ } from '../../../util/translate';
import FieldCodeInputType from './FieldCodeInputType';
import { copyToClipboard } from '../../../util/copyToClipboard';

interface IFieldCodeProps {
  className?: string;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  error?: string;
  placeholder?: string;
  autoComplete?: string;
  readOnly?: boolean;
  ref?: RefObject<HTMLInputElement>;
}

const FieldCode: FC<IFieldCodeProps> = (props) => {
  const {
    className,
    error,
    name,
    onChange,
    onBlur,
    value,
    autoComplete,
    placeholder,
    readOnly,
  } = props;
  const codeInputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={className}
      css={{
        alignSelf: 'stretch',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        css={[
          // readOnly if StepBenefitsDone
          readOnly
            ? {
                marginBottom: '20px',
                flexDirection: 'column',
              }
            : {
                marginTop: '20px',
                backgroundColor: NOT_THAT_WHITE,
                borderRadius: 4,
                border: `1px solid`,
                borderColor: error ? ERROR_RED : NOT_THAT_WHITE,
              },
          {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
      >
        <FieldCodeInputType
          codeInputRef={codeInputRef}
          readOnly={readOnly}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          autoComplete={autoComplete}
          placeholder={placeholder}
        />
        {readOnly && (
          <Button
            onClick={() => copyToClipboard(codeInputRef.current.textContent)}
            theme="link-gray"
            css={{ color: GRAY_8 }}
          >
            {_('benefits:voucher.texts.copyCode')}
          </Button>
        )}
      </div>

      {error && (
        <div css={{ position: 'absolute', right: -24, top: '50%' }}>
          <span className="icon-exclamation-sign" css={{ color: ERROR_RED }} />
        </div>
      )}
    </div>
  );
};

export default FieldCode;
