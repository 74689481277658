import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { VXLogo } from '../../atoms/Logo';

class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.update = this._update.bind(this);
    this.stop = false;
  }

  componentDidMount() {
    window.requestAnimationFrame(this.update);
  }

  componentWillUnmount() {
    this.stop = true;
  }

  _update() {
    const pause = 500;
    const duration = 1400;
    if (!this.startTime) this.startTime = new Date().getTime() - duration - pause;
    const offset = new Date().getTime() - this.startTime;
    const pos = (offset % (2 * duration + pause)) - duration - pause;
    const val = pos > 0 ? pos : pos < -1400 ? 1400 : -pos;
    const el = this.refs['myPoly'];
    if (el) el.style.strokeDashoffset = val;
    if (!this.stop) window.requestAnimationFrame(this.update);
  }

  render() {
    const className = classNames(
      'spinner',
      '--align-' + this.props.align,
      this.props.size ? `--size-${this.props.size}` : '',
      this.props.noBackground ? '--no-background' : null,
      this.props.inline ? '--inline' : null
    );

    return (
      <div className={className} style={{ backgroundColor: this.props.bgColor }}>
        <VXLogo isAnimated={true} />
      </div>
    );
  }
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  align: PropTypes.oneOf(['top', 'bottom', 'center']),
  bgColor: PropTypes.string,
  inline: PropTypes.bool,
  noBackground: PropTypes.bool,
};

Spinner.defaultProps = {
  size: 'l',
  align: 'center',
  bgColor: '',
  inline: false,
  noBackground: false,
};

export default Spinner;
export { Spinner };
