import { makeStyles, createStyles } from '@material-ui/core';
import { WHITE, BLUE, BLACK_2, GRAY_5 } from '../../../../camtool-styles';

export const useTelegramBotStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      background: WHITE,
      textAlign: 'center',
      fontFamily: 'Roboto, sans-serif',
      padding: '0 18px',
    },
    container: {
      maxWidth: 400,
      margin: '100px auto',
      [theme.breakpoints.down(550)]: {
        margin: '50px auto',
      },
    },
    heading: {
      paddingTop: 25,
      fontWeight: 'bold',
      fontSize: '18px',
      color: BLACK_2,
    },
    codeBoxOuter: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 20,
    },
    codeBoxInner: {
      border: `1px solid ${GRAY_5}`,
      borderRadius: '3px',
      fontWeight: 'bold',
      fontSize: '18px',
      color: BLACK_2,
      padding: '10px 4px 10px 14px',
      letterSpacing: 10,
    },
    showCode: {
      color: BLUE,
      display: 'flex',
      justifyContent: 'center',
      margin: '15px 0 25px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    showCodeText: {
      color: BLUE,
      fontWeight: 'bold',
      fontSize: '14px',
      paddingLeft: 5,
    },
    helpcenterBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: 10,
      [theme.breakpoints.down(550)]: {
        flexDirection: 'column',
      },
    },
    helpcenterLink: {
      color: BLUE,
      textDecoration: 'underline',
      paddingLeft: 5,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);
