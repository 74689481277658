import { FileDescriptor, FileType, NativeFileDescriptor, UrlFileDescriptor } from './types';

export const isNativeFileDescriptor = (
  fileDescriptor: FileDescriptor
): fileDescriptor is NativeFileDescriptor => fileDescriptor.hasOwnProperty('file');

export const isFileType = (type: string): type is FileType =>
  Object.values(FileType).includes(type as FileType);

export const createUrlFileDescriptorFromNative = (
  descriptor: NativeFileDescriptor
): UrlFileDescriptor => {
  const { file, previewUrl } = descriptor;
  const fileType: string = file.type.split('/')[0];

  return {
    type: isFileType(fileType) ? fileType : FileType.unknown,
    url: window.URL.createObjectURL(file),
    previewUrl,
  };
};
