import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { Section } from '../../../../atoms';
import ChannelDescriptionHeader from './ChannelDescriptionHeader';
import ChannelDescriptionContent from './ChannelDescriptionContent';

const ChannelDescription: FC = () => {
  return (
    <Section title={_('navigation:telegram.channelDescription')} css={{ height: '100%' }}>
      <ChannelDescriptionHeader />
      <ChannelDescriptionContent />
    </Section>
  );
};

export default ChannelDescription;
