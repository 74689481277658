import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { groupChat } from '../../../../../atoms/Icon/libraries/glyph';

const ChannelDescriptionItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:telegram.channelDescription')}
    icon={groupChat}
    uri={`/telegram/channel-description`}
  />
);

export default ChannelDescriptionItem;
