import React, { FC } from 'react';

export const IconCommentSubmitInactive: FC<{}> = () => (
  <svg
    id="Senden-Button"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    {<circle id="Kreis" cx="14" cy="14" r="14" fill="#e1e4e5" />}
    <path
      id="Pfad_2"
      data-name="Pfad 2"
      d="M145.478,136.63l13.463,5.507-13.478,5.493,1.239-5.507Z"
      transform="translate(-136.794 -127.63)"
      fill="#3c3d3e"
    />
  </svg>
);

export const IconCommentSubmitActive: FC<{}> = () => (
  <svg
    id="Senden-Button"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <circle id="Kreis" cx="14" cy="14" r="14" fill="#2699fb" />
    <path
      id="Pfad_2"
      data-name="Pfad 2"
      d="M145.478,136.63l13.463,5.507-13.478,5.493,1.239-5.507Z"
      transform="translate(-136.794 -127.63)"
      fill="#fff"
    />
  </svg>
);
