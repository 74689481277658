import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';
import PhoneSex from './PhoneSex/PhoneSex';
import PhoneCarrousel from './PhoneCarrousel/PhoneCarrousel';

// eslint-disable-next-line react/prop-types
const Service0900 = ({ match: { url } }) => {
  const path = stripSlash(url);

  return (
    <Switch>
      <Redirect exact from={`${path}/`} to={`${path}/phone-sex`} />
      <Redirect from={`${path}/beianrufsex`} to="phone-sex" />
      <Redirect from={`${path}/premium`} to="phone-sex" />
      <Route path={`${path}/phone-sex`} component={PhoneSex} />
      <Route path={`${path}/carrousel`} component={PhoneCarrousel} />
      <Redirect to={`${path}/phone-sex`} />
    </Switch>
  );
};
export default Service0900;
