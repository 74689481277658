import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../AbstractComponent';
import {
  MediaManagementActionCreators,
  MediaManagementStore,
  RESOURCE_ALBUM_PICTURE_POOL,
} from '../../../stores/MediaManagement/MediaManagement';
import AlbumViewPicturePool from '../../MediaManagement/ExplorerPicture/AlbumViewPicturePool/AlbumViewPicturePool';
import ButtonGroup from '../../ButtonGroup/ButtonGroup';
import Button from '../../Button/Button';

class PicturePool extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      album: {
        albumType: 'pool',
        pictures: [],
      },
      selectedPictures: [],
      canSubmit: false,
    };
  }

  componentWillMount() {
    MediaManagementStore.addChangeListener(RESOURCE_ALBUM_PICTURE_POOL, this.onStoreChange);
    MediaManagementActionCreators.readResource(RESOURCE_ALBUM_PICTURE_POOL);
  }

  componentWillUnmount() {
    MediaManagementStore.removeChangeListener(RESOURCE_ALBUM_PICTURE_POOL, this.onStoreChange);
  }

  onStoreChange() {
    const storeData = MediaManagementStore.get(RESOURCE_ALBUM_PICTURE_POOL);

    this.setState({ isLoading: false });
    this.setState(storeData);
  }

  getStateFromStores() {
    var storeData = MediaManagementStore.get(RESOURCE_ALBUM_PICTURE_POOL);
    this.state.album = storeData.album;
    this.state.album.albumType = 'pool';
    this.state.album.pictures = storeData.album.pictures || [];

    return this.state;
  }

  onSubmit() {
    // console.log('onSave', this.state.selectedPicture);
    this.props.onSubmit({
      source: 'picturePool',
      selectionType: 'picture',
      pictures: this.state.selectedPictures,
    });
  }

  onSelectPicture(pictures) {
    this.setState({
      selectedPictures: pictures,
    });
  }

  onUnselectPicture(pictures) {
    this.setState({
      selectedPictures: pictures,
    });
  }

  arePicturesSelected() {
    if (!!this.state.selectedPictures) {
      for (let key of Object.keys(this.state.selectedPictures)) {
        if (this.state.selectedPictures[key]) {
          return true;
        }
      }

      return false;
    }
  }

  render() {
    this.canSubmit = this.state.pictureId || this.arePicturesSelected();
    return (
      <div className="mediaselector__picpool min-height--0">
        <div className="mediaselector__picpool__column">
          <AlbumViewPicturePool
            album={this.state.album}
            isLoading={this.state.isLoading}
            allowDetails
            allowSelection
            allowMultipleSelection={this.props.allowMultipleSelection}
            onPictureSelect={this.onSelectPicture}
            onPictureUnselect={this.onUnselectPicture}
            showNotSelectable={true}
            filter={this.props.filter}
          />

          <ButtonGroup position="right">
            <Button
              disabled={!this.canSubmit}
              label="OK"
              type="submit"
              onClick={this.onSubmit}
              class="button--blue"
            />
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

PicturePool.propsTypes = {
  filter: PropTypes.func,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
};

PicturePool.defaultProps = {
  filter: picture => {
    return !picture.isRejected;
  },
};

export default PicturePool;
export { PicturePool };
