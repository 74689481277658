import { RefObject, useEffect, useState } from 'react';

const useBodyScrollbarWidth = (getTableBodyRef: () => RefObject<HTMLDivElement>): number => {
  const [bodyScrollbarWidth, setBodyScrollbarWidth] = useState<number>(0);

  useEffect(() => {
    const bodyElement = getTableBodyRef()?.current;

    if (bodyElement) setBodyScrollbarWidth(bodyElement.offsetWidth - bodyElement.clientWidth);
  });

  return bodyScrollbarWidth;
};

export default useBodyScrollbarWidth;
