import React, { FC, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

interface Props {
  when: boolean;
  message: string;
}
const PromptAlert: FC<Props> = ({ when, message }) => {
  useEffect(() => {
    if (when) {
      function handleBeforeunload(event: BeforeUnloadEvent): void {
        event.preventDefault(); // Cancel the event
        event.returnValue = ''; // Chrome and few other browsers require returnValue to be set
      }
      window.addEventListener('beforeunload', handleBeforeunload);
      return (): void => window.removeEventListener('beforeunload', handleBeforeunload);
    }
  }, [when]);
  return <Prompt when={when} message={message} />;
};

export default PromptAlert;
