import { useState } from 'react';
import { ApolloClient } from 'apollo-client';
import useSave from './useSave';
import { _ } from '../../../../util/translate';

interface SubmitReturnValue {
  isSubmitting: boolean;
  submit: () => Promise<void>;
}

const useSubmit = (
  apolloClient: ApolloClient<Record<string, never>>,
  cancelSubsChecked: boolean,
  deactivateChecked: boolean,
  onClose: () => void
): SubmitReturnValue => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const save = useSave(apolloClient);

  const submit = async (): Promise<void> => {
    try {
      setIsSubmitting(true);

      await save(cancelSubsChecked, deactivateChecked);

      setIsSubmitting(false);

      onClose();
    } catch (err) {
      setIsSubmitting(false);

      console.error(err);
      window.alert(_('common:error.generic'));
    }
  };

  return { isSubmitting, submit };
};

export default useSubmit;
