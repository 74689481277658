import { APP_IS_DEVELOPMENT } from './env';

const MSG_NAMESPACE = 'pubsub';
const CLIENT_ID = parseInt(Date.now() * Math.random(), 10);

const debug = false;
const eventHandlers = {};

const buildTopic = topic => {
  return `${MSG_NAMESPACE}[${topic}]`;
};

const parseTopic = rawTopic => {
  const regex = new RegExp(`^${MSG_NAMESPACE}\\[(.+)\\]$`);
  const match = regex.exec(rawTopic);
  if (match) {
    return match[1];
  }
  return null;
};

const buildMsg = data => {
  return JSON.stringify({ clientId: CLIENT_ID, data });
};

const parseMsg = msg => {
  return JSON.parse(msg);
};

const isOwnMsg = msg => {
  return msg.clientId === CLIENT_ID;
};

const handleUpdate = (key, data) => {
  if (key && eventHandlers[key]) {
    if (debug && APP_IS_DEVELOPMENT)
      console.log('PubSub', 'handle update', key, data, eventHandlers);
    for (let i = 0; i < eventHandlers[key].length; i++) eventHandlers[key][i](data);
  }
};

const localChange = (topic, data) => {
  if (debug && APP_IS_DEVELOPMENT) console.log('PubSub', 'local change', topic, data);
  handleUpdate(topic, data);
};

const remoteChange = evt => {
  const topic = parseTopic(evt.key);
  if (topic) {
    const msg = parseMsg(evt.newValue);
    if (debug && APP_IS_DEVELOPMENT) console.log('PubSub', 'remote change', topic, msg.data);
    if (!isOwnMsg(msg)) handleUpdate(topic, msg.data);
  }
};

if (window.addEventListener) {
  if (debug && APP_IS_DEVELOPMENT) console.log('PubSub', 'register event listener');
  window.addEventListener('storage', remoteChange, false);
} else {
  if (debug && APP_IS_DEVELOPMENT) console.log('PubSub', 'register event listener');
  window.attachEvent('onstorage', remoteChange);
}

const publish = (topic, data) => {
  if (debug && APP_IS_DEVELOPMENT) console.log('PubSub', 'publish', topic, data);
  localChange(topic, data); // reflect the change locally
  localStorage.setItem(buildTopic(topic), buildMsg(data)); // ... then broadcast it
};

const subscribe = (topic, fn) => {
  if (debug && APP_IS_DEVELOPMENT) console.log('PubSub', 'subscribe', topic);
  (eventHandlers[topic] || (eventHandlers[topic] = [])).push(fn);
};

const unsubscribe = (topic, fn) => {
  if (debug && APP_IS_DEVELOPMENT) console.log('PubSub', 'unsubscribe', topic);
  let idx;

  if (eventHandlers[topic]) {
    if ((idx = eventHandlers[topic].indexOf(fn) !== -1)) {
      eventHandlers[topic].splice(idx, 1);
    }
  }
};

export { publish, subscribe, unsubscribe };
