import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION } from '../../camtool-styles';

const InfoFieldItem = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex: 1 1 100%;
    width: 100%;
  }
`;
const InfoFieldRow = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

class InfoField extends AbstractComponent {
  render() {
    return (
      <div className="info-field">
        <InfoFieldRow className="info-field__item">
          <InfoFieldItem className="info-field__item__label">{this.props.label}</InfoFieldItem>
          <InfoFieldItem className="info-field__item__information">
            {this.props.info ? this.props.info : this.props.children}
          </InfoFieldItem>
        </InfoFieldRow>
      </div>
    );
  }
}

InfoField.propTypes = {
  label: PropTypes.string,
  info: PropTypes.node,
};

export default InfoField;
export { InfoField };
