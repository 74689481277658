import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../AbstractComponent';
import Select from '../../../atoms/Select/Select';
import Modalbox from '../../Modalbox/Modalbox';
import Button from '../../Button/Button';
import Notice from '../../Notice/Notice';
import { _ } from '../../../util/translate';

class ModalPhoneConfirmation extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      number: '',
      code: '',
      stack: [],
      codeError: false,
      codeChecking: false,
      countryCode: '',
    };
  }

  componentWillMount() {
    this.setState({
      number: this.props.currentNumber,
      stack: this.getStartScreen(),
      countryCode: this.props.defaultCountry,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.number !== nextProps.currentNumber) {
      this.setState({ number: nextProps.currentNumber });
    }

    if (!this.state.codeError && nextProps.errorMessage !== '') {
      this.setState({ codeError: true });
    }

    if (
      (this.props.verificationStatus === 'check' ||
        this.props.verificationStatus === 'checkingerror') &&
      nextProps.verificationStatus === 'checked'
    ) {
      this.setState({
        codeChecking: false,
        stack: [...this.state.stack, 'confirmation-screen'],
      });
    }

    if (this.props.defaultCountry === '' && nextProps.defaultCountry !== '') {
      this.setState({ countryCode: nextProps.defaultCountry });
    }
  }

  getNumberType() {
    if (this.props.verificationMode === 'fixedPhoneVerification') {
      return 'phoneNumber';
    }
    if (this.props.verificationMode === 'mobilePhoneVerification') {
      return 'mobilephoneNumber';
    }
    if (this.props.verificationMode === 'service0900PhoneVerification') {
      return 'number0900';
    }
    return 'number';
  }

  getScreenId() {
    if (this.state.stack[this.state.stack.length - 1] === 'change-screen') {
      return 'changeNumber';
    }
    if (this.state.stack[this.state.stack.length - 1] === 'verify-screen') {
      return 'verifyNumber';
    }
    if (this.state.stack[this.state.stack.length - 1] === 'change-screen-0900') {
      return 'changeNumber0900';
    }
    return 'confirmationScreen';
  }

  getStartScreen() {
    const { startScreen } = this.props;
    if (startScreen === 'change-screen') {
      return ['change-screen'];
    }
    if (startScreen === 'change-screen-0900') {
      return ['change-screen-0900'];
    }

    if (this.props.verificationMode === 'service0900PhoneVerification') {
      return ['change-screen-0900', startScreen];
    }
    return ['change-screen', startScreen];
  }

  open() {
    // Reset state to initial state initial state and open modalbox
    this.setState({
      isOpen: true,
      number: this.props.currentNumber,
      code: '',
      stack: this.getStartScreen(),
      codeError: false,
      countryCode: this.props.defaultCountry || '',
    });
  }

  close() {
    this.setState({ isOpen: false });
  }

  handleOnNumberChange(e) {
    this.setState({ number: e.target.value });
  }

  handleOnCodeChange(e) {
    this.setState({ code: e.target.value, codeError: false });
  }

  handleEditNumber() {
    this.setState({
      stack: this.state.stack.slice(0, this.state.stack.length - 1),
      codeError: false,
    });
  }

  handleRequestVerificationCode() {
    const lastScreen = this.state.stack[this.state.stack.length - 1];
    if (
      this.state.countryCode !== this.props.defaultCountry ||
      this.state.number !== this.props.currentNumber
    ) {
      // Save new number and send verification code to it
      this.props.saveNumber(this.props.verificationMode, this.state.number, this.state.countryCode);
      if (lastScreen !== 'verify-screen') {
        this.setState({ stack: [...this.state.stack, 'verify-screen'] });
      }
    } else {
      // Send verification number to number in database
      this.props.requestVerificationCode(this.props.verificationMode);
      if (lastScreen !== 'verify-screen') {
        this.setState({ stack: [...this.state.stack, 'verify-screen'] });
      }
    }
  }

  handleSaveNumber() {
    if (!this.state.code) {
      this.setState({ codeError: true });
    } else {
      this.setState(
        { codeChecking: true },
        this.props.checkVerificationCode(this.props.verificationMode, this.state.code)
      );
    }
  }

  handleCountryCodeChange(event) {
    this.setState({ countryCode: event.target.value });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleRequestVerificationCode();
    }
  }

  handleKeyPress2 = (event) => {
    if (event.key === 'Enter') {
      this.handleSaveNumber();
    }
  };

  renderScreen() {
    switch (this.state.stack[this.state.stack.length - 1]) {
      case 'change-screen':
        return (
          <div className="modal-phone-confirmation__screen">
            <h3 className="modal-phone-confirmation__screen__title">
              {_('modalbox:phoneConfirmation.changeNumber.title', {
                postProcess: 'sprintf',
                sprintf: [_(`common:text.${this.getNumberType()}`)],
              }).toUpperCase()}
            </h3>
            <p className="modal-phone-confirmation__screen__description">
              {_('modalbox:phoneConfirmation.changeNumber.instructions', {
                postProcess: 'sprintf',
                sprintf: [_(`common:text.${this.getNumberType()}`)],
              })}
            </p>
            <div className="modal-phone-confirmation__screen__form">
              <div className="modal-phone-confirmation__screen__form__item">
                <label htmlFor="number" className="modal-phone-confirmation__screen__form__label">
                  <span>
                    {_(`modalbox:phoneConfirmation.${this.getScreenId()}.textFieldLabel`, {
                      postProcess: 'sprintf',
                      sprintf: [_(`common:text.${this.getNumberType()}`)],
                    })}
                  </span>
                  <input
                    type="text"
                    name="number"
                    className=""
                    placeholder="+49177852532"
                    value={this.state.number}
                    onChange={this.handleOnNumberChange}
                    onKeyPress={this.handleKeyPress}
                  />
                </label>
              </div>
            </div>
            <Button
              className="button--blue"
              label={_('modalbox:phoneConfirmation.changeNumber.button').toUpperCase()}
              onClick={this.handleRequestVerificationCode}
            />
          </div>
        );
      case 'change-screen-0900':
        return (
          <div className="modal-phone-confirmation__screen">
            <h3 className="modal-phone-confirmation__screen__title">
              {_('modalbox:phoneConfirmation.changeNumber.title', {
                postProcess: 'sprintf',
                sprintf: [_(`common:text.${this.getNumberType()}`)],
              }).toUpperCase()}
            </h3>
            <p className="modal-phone-confirmation__screen__description">
              {_('modalbox:phoneConfirmation.changeNumber.instructions', {
                postProcess: 'sprintf',
                sprintf: [_(`common:text.${this.getNumberType()}`)],
              })}
            </p>

            <div className="modal-phone-confirmation__screen__form modal-phone-confirmation__screen__form--vertical">
              <div className="modal-phone-confirmation__screen__form__item">
                <label htmlFor="number" className="modal-phone-confirmation__screen__form__label">
                  <span />
                  <Select
                    name="countryCode"
                    value={this.state.countryCode}
                    onChange={(event) => this.handleCountryCodeChange(event)}
                    options={this.props.allowedCountries}
                    placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                  />
                </label>
              </div>

              <div className="modal-phone-confirmation__screen__form__item">
                <label htmlFor="number" className="modal-phone-confirmation__screen__form__label">
                  <span>
                    {_(`modalbox:phoneConfirmation.${this.getScreenId()}.textFieldLabel`, {
                      postProcess: 'sprintf',
                      sprintf: [_(`common:text.${this.getNumberType()}`)],
                    })}
                  </span>
                  <input
                    type="text"
                    name="number"
                    className=""
                    placeholder="+49177852532"
                    value={this.state.number}
                    onChange={this.handleOnNumberChange}
                    onKeyPress={this.handleKeyPress}
                  />

                  {this.state.codeError && this.props.errorMessage && (
                    <Notice
                      className="confirmation__screen__form__item--err"
                      text={this.props.errorMessage}
                      icon="icon-warning-sign"
                    />
                  )}
                </label>
              </div>
            </div>
            <Button
              className="button--blue"
              label={_('modalbox:phoneConfirmation.changeNumber.button').toUpperCase()}
              onClick={this.handleRequestVerificationCode}
            />
          </div>
        );
      case 'verify-screen':
        return (
          <div className="modal-phone-confirmation__screen">
            <h3 className="modal-phone-confirmation__screen__title">
              {_('modalbox:phoneConfirmation.verifyNumber.title', {
                postProcess: 'sprintf',
                sprintf: [_(`common:text.${this.getNumberType()}`)],
              }).toUpperCase()}
            </h3>
            <p className="modal-phone-confirmation__screen__description">
              {_('modalbox:phoneConfirmation.verifyNumber.instructions', {
                postProcess: 'sprintf',
                sprintf: [_(`common:text.${this.getNumberType()}`)],
              })}
            </p>

            {this.props.errorMessage && this.props.errorMessage !== 'invalidFixedPhoneNumber' && (
              <Notice
                className="confirmation__screen__form__item--err"
                text={this.props.errorMessage}
                icon="icon-warning-sign"
              />
            )}

            {!this.props.errorMessage && this.props.errorMessage2 && (
              <Notice
                className="confirmation__screen__form__item--err"
                text={this.props.errorMessage2}
                icon="icon-warning-sign"
              />
            )}

            <div className="modal-phone-confirmation__screen__form">
              <div className="modal-phone-confirmation__screen__form__item">
                <label htmlFor="number" className="modal-phone-confirmation__screen__form__label">
                  <span>
                    {_('modalbox:phoneConfirmation.verifyNumber.textFieldLabel1', {
                      postProcess: 'sprintf',
                      sprintf: [_(`common:text.${this.getNumberType()}`)],
                    })}
                  </span>
                  <input
                    type="text"
                    name="number"
                    className=""
                    value={this.state.countryCode + this.state.number}
                    readOnly
                  />
                </label>

                <Button
                  className="
                    link--grey button--max-width
                    modal-phone-confirmation__screen__form__2ndarybtn"
                  label={_('modalbox:phoneConfirmation.verifyNumber.button1', {
                    postProcess: 'sprintf',
                    sprintf: [_(`common:text.${this.getNumberType()}`)],
                  }).toUpperCase()}
                  onClick={this.handleEditNumber}
                />
              </div>

              <div className="modal-phone-confirmation__screen__form__item">
                <label htmlFor="code" className="modal-phone-confirmation__screen__form__label">
                  <span>
                    {_('modalbox:phoneConfirmation.verifyNumber.textFieldLabel2', {
                      postProcess: 'sprintf',
                      sprintf: [_(`common:text.${this.getNumberType()}`)],
                    })}
                  </span>
                  <input
                    type="text"
                    name="code"
                    className=""
                    placeholder="758944"
                    value={this.state.code}
                    onChange={this.handleOnCodeChange}
                    onKeyPress={this.handleKeyPress2}
                  />
                </label>

                <Button
                  className="
                    link--grey button--max-width
                    modal-phone-confirmation__screen__form__2ndarybtn"
                  label={_('modalbox:phoneConfirmation.verifyNumber.button2').toUpperCase()}
                  onClick={this.handleRequestVerificationCode}
                />
              </div>
            </div>
            <Button
              className="button--blue"
              label={_('modalbox:phoneConfirmation.verifyNumber.button3').toUpperCase()}
              onClick={this.handleSaveNumber}
            />
          </div>
        );
      case 'confirmation-screen':
        return (
          <div className="modal-phone-confirmation__screen">
            <h3
              className="
                modal-phone-confirmation__screen__title
                modal-phone-confirmation__screen__title--big"
            >
              {_('modalbox:phoneConfirmation.confirmationScreen.instructionsTitle').toUpperCase()}
            </h3>
            <p
              className="
            modal-phone-confirmation__screen__description
            modal-phone-confirmation__screen__description--big"
            >
              {_('modalbox:phoneConfirmation.confirmationScreen.instructions', {
                postProcess: 'sprintf',
                sprintf: [_(`common:text.${this.getNumberType()}`)],
              })}
            </p>
            <Button
              className="button--blue"
              label={_('modalbox:phoneConfirmation.confirmationScreen.button').toUpperCase()}
              onClick={this.close}
            />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <Modalbox name="modalPhoneConfirmation" state={this.state.isOpen} onKeyPressESC={this.close}>
        <div className="modalgrid__box">
          <div className="modalgrid__box__header">
            <div className="icon-info-sign" />
            <div>
              {_(`modalbox:phoneConfirmation.${this.getScreenId()}.title`, {
                postProcess: 'sprintf',
                sprintf: [_(`common:text.${this.getNumberType()}`)],
              })}
            </div>
            <div className="seperator" />
            <div className="icon-remove options" onClick={this.close} />
          </div>
          {this.renderScreen()}
        </div>
      </Modalbox>
    );
  }
}

ModalPhoneConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  currentNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startScreen: PropTypes.oneOf([
    'change-screen',
    'change-screen-0900',
    'verify-screen',
    'confirmation-screen',
  ]),
  verificationMode: PropTypes.oneOf([
    'fixedPhoneVerification',
    'mobilePhoneVerification',
    'service0900PhoneVerification',
  ]),
  requestVerificationCode: PropTypes.func.isRequired,
  checkVerificationCode: PropTypes.func.isRequired,
  saveNumber: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  verificationStatus: PropTypes.oneOf(['new', 'check', 'checked', 'checkingerror']),
  defaultCountry: PropTypes.string,
};

ModalPhoneConfirmation.defaultProps = {
  isOpen: false,
  currentNumber: '',
  startScreen: 'change-screen',
  errorMessage: '',
  defaultCountry: '',
};

export default ModalPhoneConfirmation;
export { ModalPhoneConfirmation };
