export interface ILogger {
  /**
   * Logs one or more messages and optionally an error at level TRACE.
   */
  trace(message: string, context: object | null): void;

  /**
   * Logs one or more messages and optionally an error at level DEBUG.
   */
  debug(message: string, context: object | null): void;

  /**
   * Logs one or more messages and optionally an error at level INFO.
   */
  info(message: string, context: object | null): void;

  /**
   * Logs one or more messages and optionally an error at level WARN.
   */
  warn(message: string, context: object | null): void;

  /**
   * Logs one or more messages and optionally an error at level ERROR.
   */
  error(message: string, context: object | null): void;

  /**
   * Logs one or more messages and optionally an error at level FATAL.
   */
  fatal(message: string, context: object | null): void;
}

export class NullLogger implements ILogger {
  public trace(message: string, context: object | null) {}

  public debug(message: string, context: object | null) {}

  public info(message: string, context: object | null) {}

  public warn(message: string, context: object | null) {}

  public error(message: string, context: object | null) {}

  public fatal(message: string, context: object | null) {}
}
