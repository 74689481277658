import Cookies, { Cookie } from 'universal-cookie';
import { APP_IS_DEVELOPMENT } from '../env';

const cookies = new Cookies();

const DEBUG = APP_IS_DEVELOPMENT && true;

type SameSiteValue = boolean | 'none' | 'lax' | 'strict';

export const getCookieDomain = () => {
  // for local development
  if (
    window.location.hostname === 'localhost' ||
    /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(window.location.hostname)
  ) {
    return window.location.hostname;
  }

  const parts = window.location.hostname.split('.');
  return `.${parts.slice(Math.max(parts.length - 2, 1)).join('.')}`;
};

export const getCookie = (name: string, doNotParse: boolean = false) =>
  cookies.get(name, { doNotParse });

export const setCookieAsync = (
  name: string,
  value: Cookie,
  expirationSeconds?: number,
  path: string = '/',
  domain: string = getCookieDomain(),
  sameSite: SameSiteValue = 'lax'
) =>
  new Promise(resolve => {
    if (DEBUG) {
      console.log('setCookieAsync', name, value, expirationSeconds, path, domain, sameSite);
    }
    cookies.addChangeListener(resolve);
    cookies.set(name, value, { maxAge: expirationSeconds, path, domain, sameSite });
    cookies.removeChangeListener(resolve);
  });

export const setCookie = (
  name: string,
  value: Cookie,
  expirationSeconds?: number,
  path: string = '/',
  domain: string = getCookieDomain(),
  sameSite: SameSiteValue = 'lax'
) => {
  if (DEBUG) console.log('setCookie', name, value, expirationSeconds, path, domain, sameSite);
  cookies.set(name, value, { maxAge: expirationSeconds, path, domain, sameSite });
};

export const clearCookieAsync = (
  name: string,
  path: string = '/',
  domain: string = getCookieDomain()
) =>
  new Promise(resolve => {
    if (DEBUG) console.log('clearCookieAsync', name, path, domain);
    cookies.addChangeListener(resolve);
    cookies.remove(name, { path, domain });
    cookies.removeChangeListener(resolve);
  });

export const clearCookie = (
  name: string,
  path: string = '/',
  domain: string = getCookieDomain()
) => {
  if (DEBUG) console.log('clearCookie', name, path, domain);
  cookies.remove(name, { path, domain });
};
