import React, { FC, useState } from 'react';
import { Button, Modal } from '../../atoms';
import { MediaSelector, T } from '..';
import styled from '@emotion/styled';
import { useLazyQuery } from '@apollo/react-hooks';
import { _ } from '../../util/translate';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { QUERY_MODEL_AVATAR, QUERY_MODEL_PREVIEW_PICTURE16 } from '../../graphql/VXModels/queries';
import { Size } from '../../atoms/Button/styles';
import { GRAY_1, GRAY_2, GREEN, YELLOW, WHITE, BLACK_1 } from '../../camtool-styles';
import { MediaAlbum, MediaPicture } from '../../graphql/VXModels/types';
import { ProfilePictureType } from './ProfilePictureType';
import Box from '@material-ui/core/Box';

const StyledDiv = styled.div`
  width: ${(props): string => props.imgWidth};
  height: ${(props): string =>
    props.type === ProfilePictureType.avatar16 ? props.imgWidth : '105px'};
  border-radius: ${(props): string => (props.type === ProfilePictureType.avatar16 ? '50%' : '0%')};
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 16px;
  color: ${WHITE};
  overflow: hidden;
  background-color: ${GRAY_1};
`;

const Title = styled.h3`
  font-size: 18px;
  max-width: 200px;
  text-align: center;
  margin-bottom: 16px;
  color: ${BLACK_1};
`;

const ReviewStatus = styled.p`
  margin-bottom: 3px;
  font-size: 14px;
  ${(props): string => (props.forQuickStart ? `font-family: 'Roboto Condensed', sans-serif;` : '')}
`;

const ReviewStatusInReview = styled(ReviewStatus)`
  margin-bottom: 10px;
  color: ${YELLOW};
`;

const ReviewStatusAccepted = styled(ReviewStatus)`
  margin-bottom: 10px;
  color: ${GREEN};
`;

const StyledP = styled.p`
  text-align: center;
  max-width: 236px;
  font-size: 14px;
  color: ${GRAY_2};
  margin: ${(props): string =>
    props.forQuickStart
      ? props.type === ProfilePictureType.avatar16
        ? '19px 0 25px'
        : '10px 0 16px'
      : '16px 0'};
  font-weight: ${(props): number => (props.forQuickStart ? 300 : 400)};
`;

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  height: 100%;
  ${(props): string =>
    props.fixReviewStatusOffset
      ? 'margin-top: -20px;' /* Height of ReviewStatus incl. margins */
      : ''}
`;

const modalWidth = 600;

const ModalContainer = styled.div`
  @media (max-width: ${modalWidth}px) {
    overflow: auto;
    justify-content: start;
  }
`;

const ModalInner = styled.div`
  align-self: center;
  min-width: ${modalWidth}px;
`;

interface Props {
  type: ProfilePictureType;
  album?: MediaAlbum;
  picture?: MediaPicture;

  /**
   * Could be solved way better by building separate reusable components for image, button, review status, etc.
   * Then they could be freely composed where this component is currently being used and could be styled independently as necessary.
   * we should do this...
   */
  forQuickStart?: boolean;
}

const ProfilePictureContent: FC<Props> = ({ type, album, picture, forQuickStart = false }) => {
  const [showMediaSelector, setShowMediaSelector] = useState(false);
  const [refreshAlbum] = useLazyQuery(
    type === ProfilePictureType.avatar16 ? QUERY_MODEL_AVATAR : QUERY_MODEL_PREVIEW_PICTURE16,
    {
      fetchPolicy: 'network-only',
    }
  );

  const albumId = album?.id,
    pictureUrl = picture?.url,
    isChecked = picture?.isChecked,
    displayReviewStatus = !!pictureUrl,
    buttonSize: Size = 'lg';

  const imgWidth =
    type === ProfilePictureType.avatar16 ? (forQuickStart ? '87px' : '112px') : '140px';

  return (
    <>
      <Container fixReviewStatusOffset={forQuickStart && displayReviewStatus}>
        {displayReviewStatus && !isChecked && (
          <Box mb={1} mt={2} css={{ color: `${!isChecked ? YELLOW : GREEN}` }}>
            <AddPhotoAlternateIcon fontSize="large" />
          </Box>
        )}
        {displayReviewStatus &&
          (isChecked ? (
            <ReviewStatusAccepted forQuickStart={forQuickStart}>
              <T _={'dashboard:app.profilePicture.yourProfilePic'} />
            </ReviewStatusAccepted>
          ) : (
            <ReviewStatusInReview forQuickStart={forQuickStart}>
              <T _={'dashboard:app.profilePicture.reviewingPicture'} />
            </ReviewStatusInReview>
          ))}
        {pictureUrl ? (
          <StyledDiv
            imgWidth={imgWidth}
            type={type}
            forQuickStart={forQuickStart}
            css={{ border: `2px solid ${!isChecked ? YELLOW : GREEN}` }}
          >
            <img src={pictureUrl} css={{ width: imgWidth }} />
          </StyledDiv>
        ) : (
          <>
            <Box mb={1}>
              <AddPhotoAlternateIcon fontSize="large" />
            </Box>
            <Title>{_('welcome:assistent.pictureSubTitle')}</Title>
          </>
        )}
        <StyledP type={type} forQuickStart={forQuickStart}>
          {isChecked && <T _={'dashboard:app.profilePicture.pictureApproved'} />}
          {!pictureUrl && <T _={'dashboard:app.profilePicture.uploadPicText'} />}
          {!isChecked && <T _={'welcome:assistent.pictureCheckingTitle'} />}
        </StyledP>
        {!pictureUrl ? (
          <Button
            theme="blue"
            size={buttonSize}
            label={_('dashboard:app.profilePicture.buttonText')}
            onClick={(): void => setShowMediaSelector(true)}
            css={{ textTransform: 'none' }}
          />
        ) : undefined}
      </Container>
      {showMediaSelector && (
        <Modal>
          <ModalContainer>
            <ModalInner>
              <MediaSelector
                type="picture"
                // initialTab="browse"
                editor={type === ProfilePictureType.avatar16 ? 'circle' : 'landscape_4_3'}
                targetUmaId={albumId}
                targetParams={type === ProfilePictureType.avatar16 ? {} : { position: 0 }}
                pictureType={
                  type === ProfilePictureType.avatar16 ? 'mainProfilePicture' : 'sedcard'
                }
                onClose={() => setShowMediaSelector(false)}
                onSubmit={() => refreshAlbum()}
                onBeforeUploadPicture={(data, callback) => {
                  callback(data);
                }}
              />
            </ModalInner>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default ProfilePictureContent;
