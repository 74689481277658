import styled from '@emotion/styled';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import TelegramIcon from '@material-ui/icons/Telegram';
import { makeStyles } from '@material-ui/styles';
import React, { Fragment, FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import NewButton from '../../../atoms/Button/NewButton';
import { Icon } from '../../../atoms/Icon';
import useTelegramMaster from '../../../packages/Telegram/hooks/useTelegramMaster';
import { APP_BASE_PATH } from '../../../util/env';
import { getFormattedAmount } from '../../../util/Formatter';
import { _ } from '../../../util/translate';

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const telegramTileStyles = makeStyles({
  modelHeadline: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  modelNoDataHeadline: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  modelNoData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modelHint: {
    fontSize: '14px',
    color: '#262728',
  },
  groupedStats: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px auto',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconSize: {
    fontSize: '30px',
    marginRight: '8px',
  },
  amountOf: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  hugeIcon: {
    backgroundColor: '#1F93E9',
    color: common.white,
    width: '39px',
    height: '39px',
    border: '5px solid #1F93E9',
    borderRadius: '100%',
  },
  circle: {
    backgroundColor: '#1F93E9',
    color: common.white,
    width: '25px',
    height: '25px',
    border: '5px solid #1F93E9',
    borderRadius: '100%',
  },
  amountInEuro: {
    fontSize: '12px',
    alignItems: 'center',
    marginLeft: '5px',
    fontWeight: 'normal',
  },
  iconContainer: {
    textAlign: 'center',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    flexGrow: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
  telegramButton: {
    '& .MuiButton-label': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'center',
      display: 'block',
    },
  },
  telegramContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',
  },
});

interface TelegramTileProps {
  actorName?: string;
  loading: boolean;
}

type Props = TelegramTileProps;

const TelegramTile: FunctionComponent<Props> = (TelegramTileProps) => {
  const theme = useTheme();
  const classes = telegramTileStyles();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const route = !isMobile ? '/telegram/chat/desktop' : '/telegram/chat/mobile';
  const handleClick = () => history.push(`${APP_BASE_PATH}${route}`);
  const { data, loading: masterloading } = useTelegramMaster();
  const modelName = data?.master?.actorName;
  const unreadTips = data?.master?.unreadTips;
  const amountOfUnreadTips = getFormattedAmount(data?.master?.unreadTippingAmount);
  const countVipUser = data?.master?.countVipUser || 0;
  const countUser = data?.master?.countUser || 0;
  const isTelegramChannel = data?.master?.channelExists && data?.master?.channelInviteUrl !== '';

  const noData = countVipUser === 0 && !isTelegramChannel;

  return (
    <Container>
      {noData ? (
        <Box className={classes.modelNoData}>
          <Box style={{ margin: '18px' }}>
            <TelegramIcon className={classes.hugeIcon} />
          </Box>
          <Box className={classes.modelNoDataHeadline}>Hallo @{modelName}</Box>
          <Box className={classes.modelHint}>Schreibe nun mit Usern in Deinem Telegram-Chat.</Box>

          <Box style={{ margin: '18px' }}>
            <NewButton onClick={handleClick} className={classes.telegramButton}>
              {_('dashboard:app.telegram.buttonText')}
            </NewButton>
          </Box>
        </Box>
      ) : (
        <Fragment>
          <h3 css={{ fontSize: 18, textAlign: 'center', padding: '0 16px' }}>
            {_('dashboard:app.telegram.greetingPrefix')} @{modelName},{' '}
            {_('dashboard:app.telegram.greetingSuffix')}
          </h3>
          <Box className={classes.telegramContentContainer}>
            {unreadTips > 0 && (
              <Box className={classes.infoContainer}>
                <MonetizationOnIcon style={{ color: '#43B31C' }} className={classes.iconSize} />
                <Box className={classes.statsContainer}>
                  <span>{_('dashboard:app.telegram.newTips')}</span>
                  <span className={classes.amountOf}>
                    {unreadTips}
                    <span className={classes.amountInEuro}>({amountOfUnreadTips})</span>
                  </span>
                </Box>
              </Box>
            )}

            <Box className={classes.infoContainer}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '8px',
                  width: '30px',
                  height: '30px',
                }}
              >
                <Icon
                  icon={faCrown}
                  css={{ color: 'rgb(255, 176, 51)', width: '100%', height: '100%' }}
                  size={'23px'}
                />
              </Box>
              <Box className={classes.statsContainer}>
                <span>{_('dashboard:app.telegram.VIPUser')}</span>
                <span className={classes.amountOf}>{countVipUser}</span>
              </Box>
            </Box>
            {!isTelegramChannel && !masterloading && (
              <Box className={classes.infoContainer}>
                <PersonOutlineIcon className={classes.iconSize} />
                <Box className={classes.statsContainer}>
                  <span>{_('dashboard:app.telegram.Subscribers')}</span>
                  <span className={classes.amountOf}>{countUser}</span>
                </Box>
              </Box>
            )}
          </Box>

          <Box className={classes.buttonContainer}>
            <NewButton onClick={handleClick} className={classes.telegramButton}>
              {_('dashboard:app.telegram.buttonText')}
            </NewButton>
          </Box>
        </Fragment>
      )}
    </Container>
  );
};

TelegramTile.displayName = 'TelegramTile';

export default TelegramTile;
