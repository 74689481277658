import React, { FC } from 'react';
import { _ } from '../../util/translate';
import Icon from '../Icon/Icon';
import { diskOpen } from '../Icon/libraries/glyph';
import GenericModalBoxLayout from '../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { ModalContainer, WarningContent } from './Styles';
import Button from '../Button/Button';

export interface Props {
  platformContextInfo: string;
  pictureExists?: boolean;
  setShowMediaSelector: (arg: boolean) => void;
  setShowWarningModal: (arg: boolean) => void;
}

const WarningModalBox: FC<Props> = ({
  pictureExists,
  platformContextInfo,
  setShowMediaSelector,
  setShowWarningModal,
}) => {
  return (
    <GenericModalBoxLayout
      noCloseOnESC={true}
      name="service0900-upload-warning"
      maxWidth={738}
      onClose={(): void => setShowWarningModal(false)}
    >
      <ModalContainer>
        <WarningContent>
          {pictureExists ? (
            <>
              <h2 css={{ fontSize: '20px' }}>
                {_('service0900:PhoneSex.active.modal.sedcard.headline')}
              </h2>
              <p css={{ fontSize: '16px' }}>
                {_('service0900:PhoneSex.active.modal.sedcard.paragraph2')}
              </p>
              <div css={{ marginTop: '40px', width: '100%', justifyContent: 'center' }}>
                <Button
                  css={{ marginRight: '20px' }}
                  theme={'border-gray'}
                  onClick={(): void => setShowWarningModal(false)}
                >
                  {_('common:button.cancel')}
                </Button>
                <Button
                  theme={'blue'}
                  onClick={(): void => {
                    setShowMediaSelector(true);
                    setShowWarningModal(false);
                  }}
                >
                  <Icon icon={diskOpen} css={{ marginRight: '6px' }} size={'16px'} />
                  <span css={{ fontSize: '16px' }}>{_('common:button.upload.newPicture')}</span>
                </Button>
              </div>
            </>
          ) : (
            <>
              <h2 css={{ fontSize: '20px' }}>{_('common:text.privacy.title')}</h2>
              <p css={{ fontSize: '16px' }}>
                {_('service0900:PhoneSex.active.modal.sedcard.paragraph1', {
                  sprintf: [platformContextInfo],
                })}
              </p>
              <Button
                theme={'blue'}
                css={{ marginTop: '40px' }}
                onClick={(): void => {
                  setShowMediaSelector(true);
                  setShowWarningModal(false);
                }}
              >
                <Icon icon={diskOpen} css={{ marginRight: '6px' }} size={'16px'} />
                <span css={{ fontSize: '16px' }}>{_('common:button.upload.newPicture')}</span>
              </Button>
            </>
          )}
        </WarningContent>
      </ModalContainer>
    </GenericModalBoxLayout>
  );
};

export default WarningModalBox;
