import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { EmptyContent, FormatDate, Markdown, T } from '../../../../components';

import MarketingStore from '../../../../stores/Marketing/MarketingStore';
import MarketingActionCreators from '../../../../stores/Marketing/MarketingActionCreators';

import { _ } from '../../../../util/translate';

const STORE = 'marketing.admailsarchive';

class Archive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalEntries: -1,
      data: this.getStoreData().data,
      sorting: {
        field: 'createdDate',
        direction: 'desc',
      },
      pagination: {
        loading: false,
        limit: 8,
        offset: 0,
      },
      showAdmailPreview: false,
      showAdmailPreviewMessage: '',
    };
  }

  componentDidMount() {
    MarketingStore.addChangeListener(STORE, this.onStoreChange);
    MarketingActionCreators.getNumberOfAdmailsInArchive();
    this.loadData();
  }

  componentWillUnmount() {
    MarketingStore.removeChangeListener(STORE, this.onStoreChange);
  }

  componentWillReceiveProp(nextProps) {
    this.state.sorting.field = nextProps.sortField;
    this.state.sorting.direction = nextProps.sortDirection;
    this.setState(this.state);
  }

  loadData = () => {
    this.state.pagination.loading = true;
    MarketingActionCreators.getAdmailArchive(
      this.state.sorting.field,
      this.state.sorting.direction,
      this.state.pagination.limit,
      this.state.pagination.offset
    );
    this.setState(this.state);
  };

  getStoreData = () => cloneDeep(MarketingStore.get(STORE));

  getSortClass = name => {
    if (this.state.sorting.field === name) {
      return name + ' ' + this.state.sorting.direction;
    }
    return name;
  };

  onClickSort = event => {
    const sortOptions = ['createdDate', 'sentDate', 'receiver', 'receiverStatus', 'receiverAmount'];

    const classNames = event.currentTarget.className.split(' ');
    this.state.sorting.field = 'createdDate';
    this.state.sorting.direction = 'desc';

    for (let cn in classNames) {
      if (sortOptions.indexOf(classNames[cn]) !== -1) {
        this.state.sorting.field = classNames[cn];
        if (classNames.indexOf('asc') !== -1) {
          this.state.sorting.direction = 'desc';
        } else if (classNames.indexOf('desc') !== -1) {
          this.state.sorting.direction = 'asc';
        } else {
          //Default sort: asc
          this.state.sorting.direction = 'asc';
        }
        this.state.pagination.offset = 0;
        this.setState(this.state);
        this.loadData();
      }
    }
  };

  onStoreChange = () => {
    this.state.pagination.loading = false;
    this.state.data = this.getStoreData().data;
    this.state.totalEntries = this.getStoreData().totalEntries;
    this.state.pagination.offset = this.state.data.length;
    this.setState(this.state);
  };

  onClickModalClose = () => {
    this.state.showAdmailPreview = false;
    this.setState(this.state);
  };

  onClickAdmailPreview = item => {
    this.state.showAdmailPreview = true;
    this.state.showAdmailPreviewMessage = item.message;
    this.setState(this.state);
  };

  receiverIconClass = item => {
    switch (item.receiver) {
      case 'vxFavourites':
        return 'icon-heart';
      case 'homepageUser':
        return 'icon-home';
      case 'promolinkUser':
        return 'icon-wifi-alt';
    }
  };

  receiverStatusIconClass = item => {
    switch (item.receiverStatus) {
      case 'done':
        return 'icon-ok';
      case 'rejected':
        return 'icon-remove';
      case 'inProgress':
      case 'new':
        return 'icon-refresh';
      default:
        return '';
    }
  };

  isLoadMoreButtonDisabled = () => this.state.pagination.loading;

  isLoadMoreButtonVisible = () => {
    return this.state.totalEntries > -1 && this.state.data.length < this.state.totalEntries;
  };

  render() {
    return (
      <div className="grid__box__column grid__box__sub min-width--0">
        <div className="grid__box__sub__header">
          <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
            <T _={'marketing:admails.archive.title'} />
          </div>
        </div>

        {this.state.showAdmailPreview === true && (
          <aside className="admails-archiv-details">
            <div>
              <div className="icon-remove" onClick={this.onClickModalClose} />
              <div className="textbox">
                <Markdown source={this.state.showAdmailPreviewMessage} css={{ flex: '1 1 0%' }} />
              </div>
            </div>
          </aside>
        )}

        <div className="grid__box__sub__item admails-archiv-list list">
          <div className="grid__box__sub__item__content">
            {this.state.data.length < 1 ? (
              <EmptyContent title={_('marketing:admails.archive.archiveIsEmpty')} />
            ) : (
              <Fragment>
                <header className="list-header">
                  <div
                    className={`${this.getSortClass(
                      'receiverStatus'
                    )} sortable column-reciver-status`}
                  >
                    <div className={this.getSortClass('receiverStatus')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'marketing:admails.archive.status'} />
                      </div>
                      <div className="icoadmails.promolinkuser.tablefieldn" />
                    </div>
                  </div>
                  <div
                    className={`${this.getSortClass(
                      'receiverAmount'
                    )} sortable column-reciver-count`}
                  >
                    <div className={this.getSortClass('receiverAmount')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'marketing:admails.archive.receivers'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div className="column-message">
                    <div>
                      <div className="label">
                        <T _={'marketing:admails.archive.content'} />
                      </div>
                    </div>
                  </div>
                  <div className={`${this.getSortClass('createdDate')} sortable column-create`}>
                    <div className={this.getSortClass('createdDate')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'marketing:admails.archive.createdDate'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div className={`${this.getSortClass('sentDate')} sortable column-shipped`}>
                    <div className={this.getSortClass('sentDate')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'marketing:admails.archive.sentDate'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                  <div className={`${this.getSortClass('receiver')} sortable column-reciver-group`}>
                    <div className={this.getSortClass('receiver')} onClick={this.onClickSort}>
                      <div className="label">
                        <T _={'marketing:admails.archive.receiver'} />
                      </div>
                      <div className="icon" />
                    </div>
                  </div>
                </header>

                {this.state.data.map(item => (
                  <article
                    className="list-entry"
                    key={item.id}
                    onClick={this.onClickAdmailPreview.bind(this, item)}
                  >
                    <div className={`${this.receiverStatusIconClass(item)} column-reciver-status`}>
                      <T _={'marketing:admail.status.' + item.receiverStatus} />
                    </div>
                    <div className="column-reciver-count">{item.receiverAmount}</div>
                    <div className="column-message">{item.message.substr(0, 35)}</div>
                    <div className="column-create">
                      <FormatDate value={item.createdDate} />
                    </div>
                    <div className="column-shipped">
                      <FormatDate value={item.sentDate} />
                    </div>
                    <div className={`${this.receiverIconClass(item)} column-reciver-group`}>
                      <T _={'marketing:admails.receiver.' + item.receiver} />
                    </div>
                  </article>
                ))}

                {this.isLoadMoreButtonVisible() && (
                  <div
                    className={`list-load-more ${
                      this.isLoadMoreButtonDisabled() ? 'disabled' : ''
                    }`}
                  >
                    <div>
                      <div onClick={this.loadData}>
                        <div className="icon" />
                        <div className="label">
                          <T _={'common:button.loadMoreContents'} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Archive;
