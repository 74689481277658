import { Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Query, VXGChallengeschallengeArgs } from '../../../../../graphql/VXModels/types';
import { QUERY_MODEL_VXCHALLENGE_INCOMPLETE_PARTICIPANTS } from '../../../graphql';
import { Heading } from './components/Heading';
import { TotalOverview } from './components/TotalOverview';
import { Participants } from './components/Participants';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Spinner } from '../../../../../components';
import { WHITE } from '../../../../../camtool-styles';
import { CompetitionStatusProps } from '.';

const CompetitionStatus: FC<CompetitionStatusProps> = ({ total, completed, challengeId }) => {
  const { loading, error, data } = useQuery<Query, VXGChallengeschallengeArgs>(
    QUERY_MODEL_VXCHALLENGE_INCOMPLETE_PARTICIPANTS,
    {
      variables: { id: challengeId },
    }
  );

  if (loading) return <Spinner />;
  if (error) return <GenericEmptyContent />;

  const participants = data?.model?.vxChallenges?.challenge?.participantsList;

  return (
    <Paper
      variant="elevation"
      style={{
        backgroundColor: WHITE,
        padding: '40px',
        height: 'calc(100% - 80px)',
      }}
    >
      {' '}
      <Heading />
      <TotalOverview total={total} completed={completed} />
      {/* todo[VXGAMES]: check this logic for competitions in the future (not current active one) */}
      {participants?.length > 1 && <Participants participants={participants} />}
    </Paper>
  );
};

export { CompetitionStatus };
