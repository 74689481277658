import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { edit } from '../../../../../atoms/Icon/libraries/glyph';

const DraftsItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:telegram.drafts')}
    icon={edit}
    uri="/telegram/broadcast/drafts"
  />
);

export default DraftsItem;
