import React, { FC, ReactElement } from 'react';
import { Form, Formik } from 'formik';
import styled from '@emotion/styled';
import { _ } from '../../../../util/translate';
import { AboWizardActionType, BillingPeriod, IAboDetailsPageForm, IPageProps } from '../../types';
import PageLayout from '../PageLayout';
import NavigationButtons from './NavigationButtons';
import { getValidationSchema } from './validationSchema';
import useOnSubmit from './useOnSubmit';
import {
  FieldAutoRenew,
  FieldBillingPeriod,
  FieldDescription,
  FieldName,
  FieldPictureUpload,
  FieldPrice,
} from './Fields';
import { MIN_PRICE } from './constants';
import { isContentTypeWithPictureUpload } from '../util';
import { RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL } from '../../../../molecules/FileUpload/withResponsiveUploadStyles';
import useTelegramMaster from '../../../../packages/Telegram/hooks/useTelegramMaster';

const StyledForm = styled(Form)`
  flex-direction: column;
  max-width: 768px;
  width: 100%;
`;

const DescriptionRow = styled.div`
  ${RESPONSIVE_UPLOAD_STYLES_MEDIA_QUERY_FULL} {
    flex-direction: column;
  }
`;

const getInitialValues = (): IAboDetailsPageForm => ({
  nameTranslation: {
    de: '',
    en: '',
  },
  descriptionTranslation: {
    de: '',
    en: '',
  },
  billingPeriod: BillingPeriod.Every1Months,
  autoRenew: true,
  price: MIN_PRICE,
  priceDiscounted: 0,
  picture: undefined,
});

type Props = IPageProps & Required<Pick<IPageProps, 'apolloClientAbo'>>;

const AboDetailsPage: FC<Props> = ({ dispatch, state, apolloClientAbo }) => {
  const { aboContentType } = state;
  const showPictureUpload = isContentTypeWithPictureUpload(aboContentType);
  const { data } = useTelegramMaster();
  const currencyLabel =
    aboContentType === 'Telegram' && data?.master?.currency === 'USD' ? '$' : '€';
  const isTelegram = aboContentType === 'Telegram';

  const handleBackClick = (values: IAboDetailsPageForm): void => {
    dispatch({
      type: AboWizardActionType.SaveFormValues,
      payload: { formValues: values },
    });
    dispatch({ type: AboWizardActionType.GoBack, payload: {} });
  };
  const onSubmit = useOnSubmit(state, dispatch, apolloClientAbo);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={state.formValues || getInitialValues()}
      validationSchema={getValidationSchema()}
      render={({ errors, dirty, values }): ReactElement => {
        const hasErrors = Object.keys(errors).length > 0;
        const cannotSubmit = state.formValues ? hasErrors : !dirty || hasErrors;
        return (
          <StyledForm>
            <PageLayout
              title={_(`benefits:abo.${aboContentType.toLowerCase()}.title`)}
              subtitle={_(`benefits:abo.${aboContentType.toLowerCase()}.subtitle`)}
            >
              <FieldName aboContentType={aboContentType} />
              <DescriptionRow>
                <FieldDescription />
                {showPictureUpload && (
                  <FieldPictureUpload
                    apolloClientAbo={apolloClientAbo}
                    aboContentType={aboContentType}
                  />
                )}
              </DescriptionRow>
              <FieldBillingPeriod isTelegram={isTelegram} />
              <FieldAutoRenew />
              <FieldPrice currencyLabel={currencyLabel} />
            </PageLayout>

            <NavigationButtons
              state={state}
              cannotSubmit={cannotSubmit}
              onBackClick={(): void => handleBackClick(values)}
            />
          </StyledForm>
        );
      }}
    />
  );
};

export default AboDetailsPage;
