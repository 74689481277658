import React from 'react';
import { Query } from '@apollo/react-components';

import { Spinner } from '../../../../components';
import PhotoGalleryStore from '../../../../stores/PhotoGallery/PhotoGalleryStore';

import AlbumViewStandard from './AlbumViewStandard';
import { QUERY_PHOTO_ALBUMS_BY_TYPE } from '../../../../graphql/VXModels/queries';
import { _ } from '../../../../util/translate';

const PicturesPool = ({ setCurrentAlbumType }) => {
  return (
    <div className="grid__box__column grid__box__sub spinner-container">
      <Query
        query={QUERY_PHOTO_ALBUMS_BY_TYPE}
        variables={{ type: 'pool' }}
        fetchPolicy="network-only"
      >
        {({ loading, data, error, refetch }) => {
          if (error) return console.error(error);
          if (loading) return <Spinner />;
          return (
            <AlbumViewStandard
              title={_('mediamanagement:picfolder.title.pool')}
              album={data.model.photoAlbums.albums[0]}
              albumType="pool"
              isLoading={loading}
              titleTooltip={null}
              editable={false}
              onPictureClick={showGallery}
              refetchAlbum={refetch}
              setCurrentAlbumType={setCurrentAlbumType}
            />
          );
        }}
      </Query>
    </div>
  );
};

function showGallery(picture) {
  PhotoGalleryStore.openPhotoGallery([picture.url]);
}

export default PicturesPool;
