import React from 'react';

import NewButton from '../../atoms/Button/NewButton';
import {useLang} from '../../util/AppState';
import {useDeepLinkBeta} from '../../hooks';
import {VXModelsMobileTargetEnum, ApiLang} from '../../graphql/VXModels/types';
import BannerDE from '../../assets/images/Dokumentenseite-de.png';
import BannerEN from '../../assets/images/Dokumentenseite-en.png';


const AccountSwitchToBeta = ({isStartpage = false}) => {
    const [lang] = useLang();
    const isDE = lang === ApiLang.de;

    const {refetch} = useDeepLinkBeta(VXModelsMobileTargetEnum.account);

    const goToBeta = (target) => {
        refetch({target}).then((data) => {
            let dashboardLink = data?.data?.auth?.betaDeeplink || '';
            const langAbb = isDE ? 'de' : 'en';
            dashboardLink = dashboardLink.replace('?token=', `?lang=${langAbb}&token=`);
            window.open(dashboardLink, '_blank');
        });
    };

    const blueButton = isStartpage ? VXModelsMobileTargetEnum.dashboard : VXModelsMobileTargetEnum.account;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '100px',
                marginTop: '20px',
                fontSize: '16px',
            }}
        >
            {!isStartpage && (
                <b style={{fontWeight: 'bolder'}}>
                    {isDE
                        ? 'Du möchtest deine Daten aktualisieren oder bearbeiten?'
                        : 'Would you like to update or edit your data?'}
                </b>
            )}
            {!isStartpage && (
                <b style={{fontWeight: 'bolder'}}>
                    {isDE ? 'Wechsel dafür zum neuen VXModels.' : 'Switch to the new VXModels.'}
                </b>
            )}
            <img
                src={isDE ? BannerDE : BannerEN}
                style={{width: '70%', margin: 'auto', marginTop: '20px'}}
            />
            <NewButton
                onClick={() => goToBeta(blueButton)}
                style={{marginTop: '20px'}}
            >
                {isDE ? 'Zum neuen VXModels wechseln' : 'Go to the new VXModels'}
            </NewButton>
            {!isStartpage && (
                <span style={{marginTop: '24px', marginBottom: '16px', fontSize: '12px', color: '#777'}}>
                    {isDE
                        ? 'Benötigst du Hilfe? Nutze folgende Quick-Links:'
                        : 'Do you need help? Use the following quick links:'}
                    &nbsp;
                </span>

            )}
            {!isStartpage && (
                <div style={{display: 'flex', flexDirection: 'row', gap: '16px'}}>
                    <NewButton
                        onClick={() => goToBeta(VXModelsMobileTargetEnum.account)}
                        style={{
                            minWidth: '190px',
                            fontWeight: '400',
                            fontSize: '12px',
                            borderRadius: '8px',
                            borderColor: '#DBE5EE',
                        }}
                        variant={'outlined'}
                        color={'inherit'}
                    >
                        {isDE ? 'Zu meinen Accountdaten ↗' : 'To my account data ↗'}
                    </NewButton>
                    <NewButton
                        onClick={() => goToBeta(VXModelsMobileTargetEnum.documents_payout)}
                        style={{
                            minWidth: '190px',
                            fontWeight: '400',
                            fontSize: '12px',
                            borderRadius: '8px',
                            borderColor: '#DBE5EE',
                        }}
                        variant={'outlined'}
                        color={'inherit'}
                    >
                        {isDE ? 'Auszahlungsdaten ändern ↗' : 'Change payout data ↗'}
                    </NewButton>
                    <NewButton
                        onClick={() => goToBeta(VXModelsMobileTargetEnum.documents_actors)}
                        style={{
                            minWidth: '190px',
                            fontWeight: '400',
                            fontSize: '12px',
                            borderRadius: '8px',
                            borderColor: '#DBE5EE',
                        }}
                        variant={'outlined'}
                        color={'inherit'}
                    >
                        {isDE ? 'Ausweisdokumente ändern ↗' : 'Change documents ↗'}
                    </NewButton>
                    <NewButton
                        onClick={() => goToBeta(VXModelsMobileTargetEnum.account)}
                        style={{
                            minWidth: '190px',
                            fontWeight: '400',
                            fontSize: '12px',
                            borderRadius: '8px',
                            borderColor: '#DBE5EE',
                        }}
                        variant={'outlined'}
                        color={'inherit'}
                    >
                        {isDE ? 'Passwort ändern ↗' : 'Change password ↗'}
                    </NewButton>
                </div>
            )}
        </div>
    );
};

export default AccountSwitchToBeta;