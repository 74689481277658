import React from 'react';
import ReactDOM from 'react-dom';

import { initErrorHandling } from './util';
import AppUtils from './util/AppUtils';
import { App } from './app';
import * as serviceWorker from './serviceWorker';
import { APP_IS_DEVELOPMENT } from './util/env';

console.log(`starting App (${process.env.NODE_ENV}) ...`);

if (!APP_IS_DEVELOPMENT && window.location.protocol === 'http:') {
  console.log('redirecting from `http://` to `https://`');
  window.location.href = window.location.href.replace('http', 'https');
}

const DEBUG = APP_IS_DEVELOPMENT && false;

initErrorHandling();

AppUtils.init();

ReactDOM.render(<App />, document.getElementById('wrapper'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
