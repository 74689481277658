import React, { FC } from 'react';
import styled from '@emotion/styled';
import RedemptionList from './RedemptionList';
import { EmptyContent } from '../../../../../components';
import { _ } from '../../../../../util/translate';

interface Props {
  instances: Array<{
    usageId: number;
    user: { id: string; name: string };
    date: Date;
    pfmId: number;
  }>;
}

const ContainerBase = styled.div`
  margin-top: 40px;
  padding-right: 12px;
  max-width: 550px;
  width: 100%;
`;

const EmptyContentContainer = styled(ContainerBase)`
  flex: 0 0 auto;
  margin: auto 0px;
  padding-right: 0px;
`;

interface InstancesContainerProps {
  instanceCount: number;
}

const InstancesContainer = styled(ContainerBase)<InstancesContainerProps>`
  flex-direction: column;
  overflow: hidden;
  flex: ${({ instanceCount }): string => (instanceCount > 5 ? '1 1 250px' : '1 1 auto')};
`;

const ModalBonusRedemptionsList: FC<Props> = ({ instances }) =>
  Array.isArray(instances) && instances.length > 0 ? (
    <InstancesContainer instanceCount={instances.length}>
      <RedemptionList instances={instances} />
    </InstancesContainer>
  ) : (
    <EmptyContentContainer>
      <EmptyContent
        title={_('benefits:abo.text.noSubs')}
        icon="icon-group"
        css={{ marginTop: 38 }}
      />
    </EmptyContentContainer>
  );

export default ModalBonusRedemptionsList;
