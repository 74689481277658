import React, { FC, MouseEvent } from 'react';
import { css } from '@emotion/core';
import { NavLink } from 'react-router-dom';
import {
  WHITE_2,
  DARK_GRAY,
  RED,
  RED_HOVER,
  BREAKPOINT_TABLET,
  DARK_GRAY_HOVER,
} from '../../../../camtool-styles';
import LeftMenuItemBadge from './LeftMenuItemBadge';
import { leftMenuItemHeight } from './constants';

interface Props {
  uri?: string;
  onClick: (event: MouseEvent) => void;
}

const bgColor = 'transparent';
const bgColorHover = DARK_GRAY_HOVER;
const bgColorActive = RED;
const bgColorActiveHover = RED_HOVER;

const styles = css`
  flex: 0 0 ${leftMenuItemHeight}px;
  text-decoration: none;
  color: ${WHITE_2};
  font-size: 1rem;
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
  background-color: ${bgColor};
  border: none;
  display: flex;
  flex-direction: column;

  &.active {
    background-color: ${bgColorActive};

    ${LeftMenuItemBadge} {
      background-color: ${DARK_GRAY};
    }
  }

  &:hover {
    background-color: ${bgColorHover};
    cursor: pointer;

    &.active {
      background-color: ${bgColorActiveHover};
    }

    @media (hover: none) {
      background-color: ${bgColor};

      &.active {
        background-color: ${bgColorActive};
      }
    }
  }

  &:focus {
    outline: 0;
  }

  ${BREAKPOINT_TABLET} {
    padding: 0 16px;
  }
`;

const LinkElement: FC<Props> = ({ uri, onClick, children }) =>
  uri ? (
    <NavLink to={uri} onClick={onClick} css={styles}>
      {children}
    </NavLink>
  ) : (
    <button type="button" onClick={onClick} css={styles}>
      {children}
    </button>
  );

export default LinkElement;
