import React, { ReactElement } from 'react';

const LockedPhone = (): ReactElement => {
  return (
    <>
      <path id="Pfad_11715" data-name="Pfad 11715" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Pfad_11716"
        data-name="Pfad 11716"
        d="M20.362,1H9.454A2.188,2.188,0,0,0,7.272,3.182V6.454H9.454V4.272H20.362V21.725H9.454V19.543H7.272v3.272A2.188,2.188,0,0,0,9.454,25H20.362a2.188,2.188,0,0,0,2.182-2.182V3.182A2.188,2.188,0,0,0,20.362,1ZM11.417,11.908V10.272A3,3,0,0,0,8.363,7.545a3,3,0,0,0-3.054,2.727v1.636A1.408,1.408,0,0,0,4,13.217v3.818a1.428,1.428,0,0,0,1.309,1.418h6a1.428,1.428,0,0,0,1.418-1.309V13.326A1.428,1.428,0,0,0,11.417,11.908Zm-1.418,0H6.727V10.272A1.5,1.5,0,0,1,8.363,8.854,1.5,1.5,0,0,1,10,10.272Z"
        transform="translate(-1.273 -1)"
        fill="currentColor"
      />
    </>
  );
};

export default LockedPhone;
