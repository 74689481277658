import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { Link, Typography } from '@material-ui/core';
import { useGetTelegramVipUrl } from '../..';

interface PreviewChannelProps {
  actorId: number;
}

const PreviewChannel: FC<PreviewChannelProps> = ({ actorId }) => {
  const { data, loading } = useGetTelegramVipUrl(actorId);
  const vipUrl = data?.widget?.hostname;
  const onClick = (href: string): void => {
    window.open(href, '_blank', 'noopener noreferrer', true);
  };

  return loading || !vipUrl ? null : (
    <Typography align="center">
      {_('telegram:channeldescription.content.preview')}{' '}
      <Link onClick={(): void => onClick(`https://${vipUrl}/vip/`)}>{`${vipUrl}/vip/`}</Link>
    </Typography>
  );
};

export default PreviewChannel;
