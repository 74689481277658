import React, { FC, ReactElement, useState, Fragment } from 'react';
import { ChartBar, EmptyContent, FormatCurrency, FormatDuration, T } from '../../../components';
import { useQuery } from '@apollo/react-hooks';
import { Query } from '../../../graphql/VXModels/types';
import { QUERY_MODEL_VXCASH_DASHBOARDSTATS } from '../../../graphql/VXModels/queries';
import NewButton from '../../../atoms/Button/NewButton';
import { _ } from '../../../util/translate';
import { APP_BASE_PATH } from '../../../util/env';
import { useHistory } from 'react-router';
import Spinner from '../../../components/Spinner/Spinner';
import styled from '@emotion/styled';
import MoneyBillIcon from '../../../atoms/Icon/VXCash/MoneyBillIcon';
import { GREEN } from '../../../camtool-styles';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid } from '@material-ui/core';
import IconAndTextComponent from '../SalesStatistics/IconAndTextComponent';

interface VXCashStatisticsProps {
  loading: boolean;
  userAllowed: boolean;
}

interface VXCashDashboardStatItemProps {
  id: number;
  date: string;
  turnover: number;
  percent: { turnover: number };
}

const VXCashSalesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 336px;

  > * {
    flex: 1;
  }
`;

const EarningsSumContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
`;

const VXCashSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
`;

const useStyles = makeStyles({
  moneyBill: {
    color: GREEN,
    fontSize: '36px',
  },
  amount: {
    fontSize: '2em',
    fontWeight: 'bold',
  },
  centeredButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  section: {
    // width: 'calc(50% - 16px)',
    // minWidth: '500px',
    display: 'flex',
    flex: '1 1 auto',
  },
  vxCashStatisticsContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    ['@media (max-width:900px)']: {
      alignItems: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
});

const VXCashStatistics: FC<VXCashStatisticsProps> = ({ loading, userAllowed }) => {
  const history = useHistory();
  const classes = useStyles();
  const [dashboardStat, setDashboardStat] = useState<Array<VXCashDashboardStatItemProps>>([]);
  const [state, setState] = useState({
    selectedAmount: 0,
    applyCssClassToday: false,
    autologinLink: '',
  });

  const {
    loading: loadingQuery,
    data,
    error,
  } = useQuery<Query>(QUERY_MODEL_VXCASH_DASHBOARDSTATS, {
    variables: {},
    onCompleted: (data) => {
      let dashboardStat: Array<VXCashDashboardStatItemProps>;
      if (data?.model?.vxcash?.dashboardStat) {
        dashboardStat = data.model.vxcash.dashboardStat
          .filter(
            (item) =>
              item !== null &&
              typeof item.id !== 'undefined' &&
              typeof item.date !== 'undefined' &&
              typeof item.percentTurnover !== 'undefined' &&
              typeof item.turnover !== 'undefined'
          )
          .map((item) => ({
            id: item.id || 0,
            date: item.date || '',
            turnover: item.turnover || 0,
            percent: { turnover: item.percentTurnover || 0 },
          }));
      } else {
        dashboardStat = [];
      }
      setDashboardStat(dashboardStat);
      setState({ ...state, autologinLink: data.model?.vxcash.autologinLink || '' });
    },
  });

  const showValueForDate = (entry: { turnover: number }): void => {
    setState({ ...state, selectedAmount: entry.turnover, applyCssClassToday: false });
  };

  const wmId = data?.model?.vxcash?.wmid;

  return (
    <Container className={classes.vxCashStatisticsContainer}>
      {loading || loadingQuery ? (
        <Grid
          container
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
          style={{ minHeight: '308px' }}
        >
          <Grid item xs={12}>
            <VXCashSpinnerContainer>
              <Spinner inline={true} size={'m'} />
            </VXCashSpinnerContainer>
          </Grid>
        </Grid>
      ) : error ? (
        <EmptyContent title={_('common:error.generic')} />
      ) : data && userAllowed ? (
        <Fragment>
          {wmId && (
            <Grid
              container
              direction={'column'}
              justify={'space-between'}
              alignItems={'center'}
              style={{ minHeight: '308px' }}
            >
              <Grid
                container
                item
                xs={12}
                direction={'row'}
                justify={'center'}
                alignItems={'center'}
              >
                <IconAndTextComponent
                  mobileSize={12}
                  tabletSize={12}
                  icon={<MoneyBillIcon className={classes.moneyBill} />}
                  formattedNumber={
                    <FormatCurrency className={classes.amount} value={state.selectedAmount} />
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction={'row'}
                justify={'center'}
                alignItems={'center'}
              >
                <Grid item xs={12} zeroMinWidth={true}>
                  <Box className={classes.chartContainer}>
                    <ChartBar name="vxcash" onSelect={showValueForDate} values={dashboardStat} />
                  </Box>
                </Grid>
              </Grid>
              {state.autologinLink && (
                <span className={classes.centeredButton}>
                  <NewButton
                    color={'primary'}
                    onClick={() => window.open(state.autologinLink, '_blank')}
                  >
                    {_('dashboard:app.vxCashStat.button.gotoVXCash')}
                  </NewButton>
                </span>
              )}
            </Grid>
          )}
          {!wmId && (
            <Fragment>
              <EmptyContent
                icon="icon-webcam"
                title={_('dashboard:app.vxCashStat.beWebmaster.title')}
                subtitle={_('dashboard:app.vxCashStat.beWebmaster.subtitle')}
                css={{ flex: 2 }}
              />
              <span className={classes.centeredButton}>
                <NewButton
                  color={'primary'}
                  onClick={(): void => history.push(`${APP_BASE_PATH}/vxcash`)}
                >
                  {_('dashboard:app.vxCashStat.button.register')}
                </NewButton>
              </span>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <EmptyContent icon="icon-pie-chart" title={_('common:forbidden.text')} />
      )}
    </Container>
  );
};

export default VXCashStatistics;
