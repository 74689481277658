import React, { FC } from 'react';
// Outside Wizard
import { useAppState } from '../../../../util/AppState';
import { Button } from '../../../../atoms';
// Inside Wizard
import { IStepProps } from '../../types';
import { MATRIX_GREEN } from '../../styles';
import FieldCode from '../FieldCode';
import VoucherPreview from './VoucherPreview';
import { ApiLang } from '../../../../graphql/VXModels/types';
import { _ } from '../../../../util/translate';
import Hintsection from '../../components/Hint/HintSection';

const StepBenefitsDone: FC<IStepProps> = ({ dispatch, state }) => {
  const [appState] = useAppState();
  const modelLang = appState.lang === ApiLang.de ? ApiLang.de : ApiLang.en;

  const goToStart = () => {
    dispatch({ type: 'CREATE_NEW_VOUCHER', payload: {} });
  };

  return (
    <article css={{ flexDirection: 'column', alignSelf: 'stretch' }}>
      <header css={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <h2 css={{ display: 'flex' }}>
          <span className="icon-ok-sign" css={{ color: MATRIX_GREEN, marginRight: 8 }} />
          <span>{_('benefits:voucher.texts.voucherCreated')}</span>
        </h2>
      </header>

      <section css={{ marginTop: 48, justifyContent: 'center' }}>
        <div
          css={{
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: 24,
            maxWidth: 596, // avoid stuff getting too big because of long text/error-messages
            flex: 1,
          }}
        >
          <VoucherPreview
            state={state}
            type={state.currentVoucherType}
            textLanguage={modelLang}
            css={{ marginBottom: 40 }}
          />

          <div css={{ flexDirection: 'column' }}>
            <p css={{ fontSize: 16 }}>{_('benefits:voucher.texts.voucherCode')}</p>
          </div>

          <FieldCode name="voucherCode" value={state.code} readOnly />

          {state.currentVoucherType === 'TICKET_SHOW' && (
            <div css={{ flexDirection: 'column', width: '100%', maxWidth: '440px' }}>
              <Hintsection />
            </div>
          )}
          <div css={{ flexDirection: 'column', alignItems: 'center', marginBottom: 64 }}>
            <Button
              label={_('benefits:voucher.texts.createNewVoucher')}
              theme="green"
              type="button"
              onClick={goToStart}
              css={{ marginTop: 16 }}
            />
          </div>
        </div>
      </section>
    </article>
  );
};

export default StepBenefitsDone;
