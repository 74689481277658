/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';

import { AlertsStore } from '../../../../stores/Alerts/AlertsStore';

import {
  MediaManagementActionCreators,
  RESOURCE_ALBUM,
} from '../../../../stores/MediaManagement/MediaManagement';
import AlbumForm from '../../../../components/MediaManagement/ExplorerPicture/AlbumForm/AlbumForm';

import NavPictureView from '../NavPictureView/NavPictureView';

import { extractTitle } from './index';
import { _ } from '../../../../util/translate';
import PropTypes from 'prop-types';
import { T } from '../../../../components';

const AlbumEdit = ({ history, editable, album }) => {
  const [loading, setLoading] = useState(false);
  const [currentTitles, setCurrentTitles] = useState(album.titles);

  const onSubmit = (currentValues, event) => {
    setLoading(true);
    MediaManagementActionCreators.updateResource(
      RESOURCE_ALBUM,
      {
        data: currentValues,
        routeParams: { albumId: album.id },
      },
      () => {
        AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
        setLoading(false);
        setCurrentTitles(currentValues.titles);
      },
      () => {
        AlertsStore.add({ type: 'error', message: _('common:alerts.saveFail') });
        setLoading(false);
      }
    );
  };

  const onDelete = (album) => {
    setLoading(true);
    MediaManagementActionCreators.deleteResource(
      RESOURCE_ALBUM,
      {
        routeParams: { albumId: album.id },
      },
      () => {
        AlertsStore.add({ type: 'info', message: _('common:alerts.deleteSuccess') });
        setLoading(false);
        history.push(`/camtool/mediamanagement/picture/${album.albumType}`);
      },
      () => {
        AlertsStore.add({ type: 'error', message: _('common:alerts.deleteFail') });
        setLoading(false);
      }
    );
  };

  const onReset = (album) => {
    history.push(`/camtool/mediamanagement/picture/album/${album.id}`);
  };

  const showMenu = album && album.status !== 'rejected' && editable;
  const isRejected = album && album.status === 'rejected';

  return (
    <div className="grid__box__column grid__box__sub">
      {showMenu && (
        <Fragment>
          <NavPictureView albumId={album.id} albumType={album.albumType} />
        </Fragment>
      )}

      <div className="grid__box__sub__header">
        <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
          {extractTitle(album.albumType, currentTitles)}
          {isRejected && (
            <Fragment>
              (<T _={`mediamanagement:picfolder.common.status${album.status}`} />)
            </Fragment>
          )}
        </div>

        <div className="grid__box__sub__header__spacer" />
        {isRejected && editable && (
          <div
            className="icon-bin grid__box__sub__header__item--option"
            onClick={this.onClickDeleteAlbum}
          >
            <T _={'common:button.delete'} />
          </div>
        )}
      </div>

      <AlbumForm
        title="Qwertz"
        album={formatForLegacyCode(album)}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onReset={onReset}
        isLoading={loading || !album}
      />
    </div>
  );
};

function formatForLegacyCode(album) {
  if (Array.isArray(album.titles)) {
    const titles = { _langs: [] };
    album.titles.forEach((title) => {
      titles._langs.push(title.language);
      titles[title.language] = title.text;
    });
    album.titles = titles;
  }

  if (Array.isArray(album.descriptions)) {
    const descriptions = { _langs: [] };
    album.descriptions.forEach((description) => {
      descriptions._langs.push(description.language);
      descriptions[description.language] = description.text;
    });
    album.descriptions = descriptions;
  }
  return album;
}

AlbumEdit.propTypes = {
  id: PropTypes.string,
};

export default AlbumEdit;
