import gql from 'graphql-tag';
import {
  FRAGMENT_NOTIFICATION_ITEMS,
  FRAGMENT_AVATAR,
  FRAGMENT_PROFILE_PREVIEW_PICTURE,
} from '../../graphql/VXModels/fragments';

export const QUERY_DASHBOARD_INITIAL = gql`
  query QUERY_DASHBOARD_INITIAL($timezone: String!, $fromDate: DateTime, $limit: Int) {
    auth {
      id
      username
      roles
    }
    model {
      avatar {
        ...Avatar
      }
      media {
        previewPicture16 {
          ...PreviewPicture
        }
      }
      tvshows {
        enabled
        planned {
          start
        }
        links {
          chat
          calendar
        }
      }
      topAmateur {
        hasSlots
        getSlots(fromDate: $fromDate, limit: $limit) {
          start
          end
          status
        }
      }
      isPayoutAllowed
      commonProfileFillingPercentage
      isOnlineWithSSW
      wasOnlineWithSSW
      settings {
        blockedCountries
      }
      dashboard {
        onlineTimeCurrentMonth
        chatRating
        chatHoldingTime
        payableAmount
        prices {
          videoChatMinPrice
          videoChatMaxPrice
          videoChatDefaultPrice
          videoChatUserPrice
          singleChatMinPrice
          singleChatMaxPrice
          singleChatDefaultPrice
          singleChatUserPrice
          messengerMinPrice
          messengerMaxPrice
          messengerDefaultPrice
          messengerUserPrice
        }
      }
      snagbarNotifications: notifications(
        unreadOnly: true
        category: [system, snagbar, vxgames2020, vxchallenges]
        timezone: $timezone
      ) {
        count
        items(sort: PRIO_DESC__DATE_ASC, limit: 5) {
          ...NotificationItems
        }
      }
      vxChallenges {
        challenges(status: active) {
          type
          targetValue
          userStatistic {
            actualValue
            status
            isTocAccepted
          }
          texts {
            title
            unit
          }
        }
      }
    }
  }
  ${FRAGMENT_NOTIFICATION_ITEMS}
  ${FRAGMENT_AVATAR}
  ${FRAGMENT_PROFILE_PREVIEW_PICTURE}
`;

export const CHANGE_CHAT_PRICE = gql`
  mutation CHANGE_CHAT_PRICE(
    $videoChatUserPrice: Float
    $singleChatUserPrice: Float
    $referrer: String
  ) {
    model {
      prices {
        savePrices(
          input: {
            videoChatPrice: $videoChatUserPrice
            singleChatPrice: $singleChatUserPrice
            referrer: $referrer
          }
        ) {
          videoChatUserPrice
          singleChatUserPrice
        }
      }
    }
  }
`;
