import AppDispatcher from '../../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../../util/ServerUtils';
import ActionTypes from '../../../util/constants/ActionTypes';
import {getUserData} from "../../../util/UserData";

class VXCashBillingsActionCreatorsClass {
  getVXCashBillings(year) {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/lists/vxcashbillings/getdata?year=' + year,
      null,
      function(response) {
        VXCashBillingsActionCreators.receiveData(response);
      }
    );
  }

  getVXCashBillingPDF(billingId) {
    ServerUtils.request(
      'GET',
      `/v1/camtool/user/{userId}/vxcash/billing/${billingId}`,
      { options: { blob: true } },
      function(response) {
        ServerUtils.download(
          response,
          'CG' + getUserData().auth.id + '_' + billingId + '.pdf',
          'application/pdf'
        );
      }
    );
  }

  receiveData(data) {
    AppDispatcher.dispatch({
      type: ActionTypes.LISTS_VXCASHBILLINGS_RECEIVE_DATA,
      data: data,
    });
  }
}

const VXCashBillingsActionCreators = new VXCashBillingsActionCreatorsClass();

export { VXCashBillingsActionCreators };
