import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash/clone';

import { AbstractComponent, T } from '../../../components';

import Tooltip from '../../../components/Tooltip/Tooltip';
import FormatDateTime from '../../../components/Formatter/FormatDateTime';
import FormatDuration from '../../../components/Formatter/FormatDuration';
import FormatCurrency from '../../../components/Formatter/FormatCurrency';
import { _ } from '../../../util/translate';

class OverviewDataList extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayAll: false,
      isOpened: this.props.initialState || false,
      toggleStatus: 'hide',
    };
  }

  toggleShowAll() {
    this.setState({
      displayAll: !this.state.displayAll,
    });
  }

  //Old do not use, use switch
  toggle() {
    const newState = !this.state.isOpened;
    this.setState({ isOpened: newState });

    const domNode = this.refs.toggleElement;
    if (domNode) {
      if (newState) {
        domNode.classList.add('show');
        domNode.classList.remove('hide');
      } else {
        domNode.classList.add('hide');
        domNode.classList.remove('show');
      }
    }
  }

  toggleSwitch() {
    if (this.state.toggleStatus === 'hide') {
      this.setState({ toggleStatus: 'show' });
    } else if (this.state.toggleStatus === 'show') {
      this.setState({ toggleStatus: 'hide' });
    }
  }

  toggleOpen() {
    if (this.state.toggleStatus === 'hide') {
      this.setState({ toggleStatus: 'show' });
    }
  }

  toggleClose() {
    if (this.state.toggleStatus === 'show') {
      this.setState({ toggleStatus: 'hide' });
    }
  }

  render() {
    this.data = clone(this.props.data);
    this.data.items = this.state.displayAll ? this.data.items : this.data.items.slice(0, 10);
    this.toggleStatus = this.state.isOpened ? 'show' : 'hide';
    return (
      <article className="drop-down-table__item overviewdatalist">
        <section className="min-width--0">
          <header
            // eslint-disable-next-line react/no-string-refs
            ref="toggleElement"
            onClick={this.toggle}
          >
            <div />
            <div style={{ color: this.props.color }}>
              <T _={'finances:TurnoverGroup.' + this.props.label} />
            </div>
            <div>
              <FormatCurrency value={this.data.provision} />
            </div>
            <Tooltip position="bottom" text={_('finances:TurnoverGroupInfo.' + this.props.label)} />
          </header>
          <article>
            <section>
              <header>
                {!this.props.data.isShop && (
                  <Fragment>
                    <div>
                      <T _={'finances:overview.datalist.column.id'} />
                    </div>
                    <div>
                      <T _={'finances:overview.datalist.column.login'} />
                    </div>
                    <div>
                      <T _={'finances:overview.datalist.column.chatbegin'} />
                    </div>
                    <div>
                      <T _={'finances:overview.datalist.column.duration'} />
                    </div>
                    <div>
                      <T _={'finances:overview.datalist.column.share'} />
                    </div>
                    <div>
                      <T _={'finances:overview.datalist.column.salesType'} />
                    </div>
                  </Fragment>
                )}

                {this.props.data.isShop && (
                  <Fragment>
                    <div>
                      <T _={'finances:overview.datalist.column.login'} />
                    </div>
                    <div>
                      <T _={'finances:overview.datalist.column.contentTitle'} />
                    </div>
                    <div>
                      <T _={'finances:overview.datalist.column.soldAt'} />
                    </div>
                    <div />
                    <div>
                      <T _={'finances:overview.datalist.column.share'} />
                    </div>
                    <div>
                      <T _={'finances:overview.datalist.column.salesType'} />
                    </div>
                  </Fragment>
                )}
              </header>

              {/*  TRANSACTION LIST */}
              {this.state.isOpened &&
                this.data.items.map((item) => (
                  <article key={item.id}>
                    {!this.props.data.isShop && (
                      <Fragment>
                        <div>{item.id}</div>
                        <div>{item.guestName}</div>
                        <div>
                          <FormatDateTime value={item.date} />
                        </div>
                        <div>
                          {!(this.props.data.group === 'FEEDS') && item.duration > 0 && (
                            <FormatDuration value={item.duration} />
                          )}
                        </div>
                        <div>
                          <FormatCurrency value={item.provision} />
                        </div>
                        <div>
                          <T _={'finances:BT_types.' + item.type} />
                          {item.isVoyeur && (
                            <span>
                              , <T _={'finances:overview.datalist.chatflags.voyeur'} />
                            </span>
                          )}
                          {item.is5MinForFree && (
                            <span>
                              , <T _={'finances:overview.datalist.chatflags.action5min'} />
                            </span>
                          )}
                          {item.isSingleSession && (
                            <span>
                              , <T _={'finances:overview.datalist.chatflags.singleSession'} />
                            </span>
                          )}
                          {item.isChatDollar && (
                            <span>
                              , <T _={'finances:overview.datalist.chatflags.chatdollar'} />
                            </span>
                          )}
                        </div>
                      </Fragment>
                    )}

                    {this.props.data.isShop && (
                      <Fragment>
                        <div>{item.guestName}</div>
                        <div>{item.contentTitle}</div>
                        <div>
                          <FormatDateTime value={item.date} />
                        </div>
                        <div />
                        <div>
                          <FormatCurrency value={item.provision} />
                        </div>
                        <div>{_('finances:overview.datalist.contenttype.' + item.contentType)}</div>
                      </Fragment>
                    )}
                  </article>
                ))}

              {/*  FACTORING FEE */}
              {this.data.factoringFee > 0 &&
                this.state.isOpened &&
                this.props.data.items.length === this.data.items.length && (
                  <article>
                    <div />
                    <div>
                      <T _={'finances:TurnoverGroup.FACTORINGFEE'} />
                    </div>
                    <div />
                    <div />
                    <div>
                      <FormatCurrency value={`-${this.data.factoringFee}`} />
                    </div>
                    <div />
                  </article>
                )}

              {/*  LOAD MORE BUTTON */}
              {this.props.data.items.length > this.data.items.length && (
                <div
                  className={`${
                    this.state.loadMoreButtonDisabled ? 'disabled' : ''
                  } list-load-more`}
                >
                  <div>
                    <div onClick={this.toggleShowAll}>
                      <div className="icon" />
                      <div className="label">
                        <T _={'common:button.loadMoreContents'} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </article>
        </section>
      </article>
    );
  }
}

OverviewDataList.propTypes = { data: PropTypes.object.isRequired };

export default OverviewDataList;
