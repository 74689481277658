import React from 'react';
import { SessionStore } from '../../stores/Session/Session';
import GroupRow from '../../components/GroupRow/GroupRow';

export default class Session extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeData: SessionStore.get(),
    };
    this.onStoreChange = this.onStoreChange.bind(this);
  }

  componentWillMount() {
    SessionStore.addChangeListener(this.onStoreChange);
  }

  componentWillUnmount() {
    SessionStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange() {
    this.setState({
      storeData: SessionStore.get(),
    });
  }

  render() {
    return (
      <main id="content-example" className="content">
        <section className="row-lvl-1">
          <section className="column-lvl-1">
            <section className="row-lvl-2">
              <section className="column-lvl-2">
                <header className="icon-nameplate">
                  <div>Session Data</div>
                </header>
                <article className="row-lvl-3">
                  <section className="column-lvl-3">
                    <section className="row-lvl-4">
                      <section className="box column-lvl-4 spinner-container">
                        <GroupRow>
                          <pre>{JSON.stringify(this.state.storeData, null, 2)}</pre>
                        </GroupRow>
                      </section>
                    </section>
                  </section>
                </article>
              </section>
            </section>
          </section>
        </section>
      </main>
    );
  }
}

Session.propTypes = {};
