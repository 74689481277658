import React, { FC } from 'react';
import styled from '@emotion/styled';
import linuxLogo from './img/linux.svg';
import windowsLogo from './img/windows-10.svg';
import appleLogo from './img/apple.svg';
import chromeLogo from './img/chrome.png';
import firefoxLogo from './img/firefox.png';
import safariLogo from './img/safari.png';
import edgeLogo from './img/edge.png';
import { _ } from '../../util/translate';

const GreenDive = styled.div`
  background-color: #43b31c;
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 4px 94px;
  position: absolute;
  top: 24px;
  right: -85px;
  transform: rotate(20deg);
`;

const StyledDiv = styled.div`
  width: 100%;
  padding: 32px 0 32px 32px;
  position: relative;
  overflow: hidden;
  min-height: 100%;
`;

interface GetOnlineCardProps {
  logo?: string;
  image: string;
  className?: string;
  note?: string;
  windows?: boolean;
  ios?: boolean;
  linux?: boolean;
  firefox?: boolean;
  chrome?: boolean;
  safari?: boolean;
  edge?: boolean;
  webcam?: boolean;
  imageSize?: 'sm' | 'md';
}

const GetOnlineCard: FC<GetOnlineCardProps> = ({
  className,
  logo,
  children,
  image,
  windows,
  ios,
  linux,
  firefox,
  chrome,
  safari,
  edge,
  webcam,
  note,
  imageSize = 'md',
}) => {
  const size = {
    height: { sm: '160', md: '360' },
    width: { sm: '228', md: '528' },
  };

  return (
    <StyledDiv className={className}>
      <div css={{ width: '100%', alignItems: 'center', flexWrap: 'wrap' }}>
        {note ? <GreenDive>{note}</GreenDive> : null}
        <div css={{ flexDirection: 'column', alignItems: 'center', flex: 1 }}>
          <div
            css={[
              { height: 48, alignItems: 'flex-start', margin: note ? 16 : 0, width: '100%' },
              ,
              imageSize === 'md' ? { justifyContent: 'center' } : {},
            ]}
          >
            {logo && <img src={logo} alt="" />}
          </div>

          <div css={{ flexDirection: 'column', alignItems: 'flex-start' }}>{children}</div>
        </div>

        <div css={{ flexDirection: 'column', flex: 1, alignItems: 'center' }}>
          <img src={image} alt="" width={size.width[imageSize]} height={size.height[imageSize]} />
        </div>
      </div>
      {(windows || ios || linux || firefox || chrome || webcam) && (
        <div
          css={{
            flexDirection: 'column',
            marginTop: 16,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flex: 0.3,
            marginRight: '40px',
            minWidth: '106px',
            minHeight: '100%',
          }}
        >
          <p css={{ color: '#888', marginBottom: 8, textAlign: 'center' }}>
            {_('getOnline:text.requirements')}
          </p>
          <div css={{ justifyContent: 'flex-end' }}>
            {windows && <img src={windowsLogo} alt="" />}
            {ios && <img src={appleLogo} alt="" css={{ marginLeft: 16 }} />}
            {linux && <img src={linuxLogo} alt="" css={{ marginLeft: 16 }} />}
            {chrome && <img src={chromeLogo} alt="" css={{ marginLeft: 16 }} />}
            {firefox && <img src={firefoxLogo} alt="" css={{ marginLeft: 16 }} />}
            {safari && <img src={safariLogo} alt="" css={{ marginLeft: 16 }} />}
            {edge && <img src={edgeLogo} alt="" css={{ marginLeft: 16 }} />}
            {webcam && (
              <span className="icon-webcam" css={{ marginLeft: 16, fontSize: 18, color: '#888' }} />
            )}
          </div>
        </div>
      )}
    </StyledDiv>
  );
};

export default GetOnlineCard;
