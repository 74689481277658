import React, { FC } from 'react';
import { Slider } from '@material-ui/core';
import { ProgressBarProps } from './';
import { useProgressBarStyles } from './';

const ProgressBar: FC<ProgressBarProps> = ({ value, max, marks, leftOver }) => {
  const marksLength = Array.isArray(marks) ? marks.length : 0;
  const classes = useProgressBarStyles();

  return (
    <Slider
      classes={classes}
      value={value}
      max={max}
      marks={Array.isArray(marks) ? marks.filter((v) => v !== undefined) : marks}
      valueLabelDisplay={marksLength && leftOver ? 'on' : 'off'}
      valueLabelFormat={leftOver}
    />
  );
};

export { ProgressBar };
