import React, { FC, memo, ReactNode } from 'react';
import { BLUE, BORDER_RADIUS, GRAY_3, GREEN, RED, YELLOW } from '../../camtool-styles';
import Icon from '../Icon/Icon';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle,
  faExclamation,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

export enum InfoBoxTypeEnum {
  ERROR = 'error',
  WARNING = 'warning',
  NOTICE = 'notice',
  INFO = 'info',
}

interface IProps {
  type: InfoBoxTypeEnum;
  children: ReactNode;
  color?: string;
  icon?: IconProp;
  textColor?: string;
}

interface IMapping {
  color: string;
  icon: IconProp;
  textColor: string;
}

const defaultsMapping: { [p: InfoBoxTypeEnum]: IMapping } = {
  [InfoBoxTypeEnum.ERROR]: {
    color: RED,
    icon: faExclamationTriangle,
  },
  [InfoBoxTypeEnum.WARNING]: {
    color: YELLOW,
    icon: faExclamation,
  },
  [InfoBoxTypeEnum.NOTICE]: {
    color: BLUE,
    icon: faExclamationCircle,
  },
  [InfoBoxTypeEnum.INFO]: {
    color: GREEN,
    icon: faInfoCircle,
  },
};

const StyledDiv = styled.div<IProps>`
  border: 1px solid ${({ color }) => color || GRAY_3};
  border-radius: ${BORDER_RADIUS};
  padding: 12px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoBox: FC<IProps> = memo(({ type, children, ...props }) => {
  const defaults: IMapping = defaultsMapping[type] || {};
  const color = props.color || defaults.color;
  const icon = props.icon || defaults.icon;
  const textColor = props.textColor || defaults.textColor;

  return (
    <StyledDiv textColor={textColor} color={color} {...props}>
      {icon && (
        <div
          css={{
            alignItems: 'center',
            color,
            fontSize: 24,
            width: 24,
            height: 14,
            marginRight: 8,
            textAlign: 'center',
          }}
        >
          <Icon icon={icon} />
        </div>
      )}
      {children}
    </StyledDiv>
  );
});

export default InfoBox;
