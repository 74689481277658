import React, { FC, ReactElement } from 'react';
import { _ } from '../../../../../util/translate';
import { Field, FormikValues } from 'formik';
import { InputField } from '../../../../../atoms';

const VerificationCodeInput: FC = () => {
  return (
    <div
      css={{
        flexDirection: 'column',
        marginBottom: '30px',
        width: '100%',
      }}
    >
      <span css={{ fontWeight: 'bold', marginBottom: '6px' }}>
        {_('service0900:PhoneSex.modal.verify.code.headline')}
      </span>
      <Field name={'verificationCode'}>
        {({ field, form: { status, errors } }: FormikValues): ReactElement => (
          <InputField
            {...field}
            errorMessage={status?.errors?.verificationCode || errors?.verificationCode}
            type="text"
            placeholder={_('service0900:PhoneSex.modal.verify.code.placeholder')}
          />
        )}
      </Field>
    </div>
  );
};

export default VerificationCodeInput;
