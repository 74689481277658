import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import ChartBarValueContainer, { Ientry } from './ChartBarValueContainer';
import { isToday } from '../../util/DateHelper';

class ChartBar extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedEntry: {},
      todayEntry: ChartBar.getTodayEntryFromValues(this.props.values),
    };
    this.legend = {};
  }

  static getTodayEntryFromValues(values) {
    for (let entry of values) {
      if (isToday(entry.date)) {
        return entry;
      }
    }

    return null;
  }

  setMarkerOnEntry(entry) {
    this.setState(
      {
        selectedEntry: entry,
      },
      () => {
        this.props.onSelect(entry);
      }
    );
  }

  setMarkerOnToday() {
    if (this.state.todayEntry) {
      this.setMarkerOnEntry(this.state.todayEntry);
    }
  }

  getLegend(interval) {
    if (this.legend[interval]) {
      return this.legend[interval];
    }

    const month = new Date().getMonth() + 1;
    const year = new Date().getYear();
    const days = new Date(year, month, 0).getDate();

    this.legend[interval] = [];

    for (let i = 1; i <= days; i++) {
      if (i === days) {
        this.legend[interval][i - 1] = { id: i, label: i };
      } else if ((i % interval === 0 || i === 1) && i !== 30) {
        this.legend[interval][i - 1] = { id: i, label: i };
      } else {
        this.legend[interval][i - 1] = { id: i, label: '' };
      }
    }
    return this.legend[interval];
  }

  componentDidMount() {
    this.setMarkerOnToday();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.values !== this.props.values) {
      this.setState(
        {
          todayEntry: ChartBar.getTodayEntryFromValues(nextProps.values),
        },
        () => {
          this.setMarkerOnToday();
        }
      );
    }
  }

  render() {
    return (
      <div className="chartbar">
        <div className="y-axis-lables" />
        <div className="">
          <div className="bars" onMouseOut={this.setMarkerOnToday}>
            {this.props.values.map((entry) => (
              <ChartBarValueContainer
                key={entry.id}
                entry={entry}
                isSelected={entry.id === this.state.selectedEntry.id}
                onMouseOver={this.setMarkerOnEntry}
              />
            ))}
          </div>
          <div className="x-axis-lables">
            {this.getLegend(this.props.interval).map((entry) => (
              <div key={entry.id}>{entry.label}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

ChartBar.propTypes = {
  onSelect: PropTypes.func,
  values: PropTypes.array,
  interval: PropTypes.number,
};

ChartBar.defaultProps = {
  onSelect: () => {},
  values: [],
  interval: 5,
};

export default ChartBar;
export { ChartBar };
