import { QueryHookOptions, useMutation } from '@apollo/react-hooks';
import { Mutation } from '../../../graphql/VXServicesTelegram/types';
import { MUTATION_MASTER_UNPINALL_MESSAGES } from '../graphql/mutations';
import { QUERY_MASTER_USER_BROADCASTLIST, QUERY_MASTER_IS_UNPINABLE } from '../graphql/queries';
import { useVXServicesTelegramContext } from './index';
import { useVXServicesTelegramClient } from '../../../graphql';

export const useTelegramUnpinAll = (options?: QueryHookOptions<Mutation>) => {
  const { token } = useVXServicesTelegramContext();
  const variables = { token };
  return useMutation(MUTATION_MASTER_UNPINALL_MESSAGES, {
    ...options,
    client: useVXServicesTelegramClient(),
    variables,
    refetchQueries: [
      { query: QUERY_MASTER_USER_BROADCASTLIST, variables: { token } },
      { query: QUERY_MASTER_IS_UNPINABLE, variables: { token } },
    ],
    awaitRefetchQueries: true,
  });
};

export default useTelegramUnpinAll;
