import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ITableColumn } from './types';
import { getTableColumnStyles } from './utils';

interface IProps extends ITableCellDivProps {
  children?: ReactNode;
  className?: string;
  to?: string;
  href?: string;
  onClick?: () => void;
  flexWrap?: boolean;
}

interface ITableCellDivProps {
  columns: ITableColumn[];
  index: number;
}

const staticCssTableCellDiv = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TableCellDiv = styled.div(({ columns, index }: ITableCellDivProps) => [
  staticCssTableCellDiv,
  getTableColumnStyles(columns, index, false),
]);

const TableCell: FC<IProps> = ({
  children,
  columns,
  index,
  className,
  to,
  href,
  onClick,
  flexWrap,
}) => {
  const linkProps: { to?: string; href?: string; onClick?: () => void } = {};
  let ColComponent = 'div';

  if (typeof to !== 'undefined') {
    ColComponent = Link;
    linkProps.to = to;
  } else if (typeof href !== 'undefined') {
    ColComponent = 'a';
    linkProps.href = href;
  } else if (typeof onClick === 'function') {
    linkProps.onClick = onClick;
  }

  return (
    <TableCellDiv columns={columns} index={index} className={className}>
      <ColComponent
        css={flexWrap ? { width: '100%', flexWrap: 'wrap' } : { width: '100%' }}
        {...linkProps}
      >
        {children}
      </ColComponent>
    </TableCellDiv>
  );
};

export default TableCell;
