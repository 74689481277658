import axios, { AxiosResponse } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import * as tus from 'tus-js-client';

import { log } from '../../../../util/errorHandling';
import { Action, ActionEnum, ErrorMessageEnum, FileId, QueueItem, StateEnum } from '../../types';
import { UPLOAD_CANCELED } from '../../util/constants';
import { parsePictureErrorcode } from '../../util/errorHandling';
import {
  getPictureUploadUrl,
  getTusVideoUploadUrl,
  getUploadedTusVideo,
} from '../../util/genericUpload';
import {
  dispatch,
  getCurrentUpload,
  getFileWrapper,
  getNextUploadQueueItem,
  getUserData,
  removeQueueItem,
  updateCurrentUpload,
  updateQueueItem,
  updateUploader,
} from '../UploadManagerStore';
import { getTusAuthTokenCookie } from '../../../../util/cookies';

type ResponseHandler = (res: AxiosResponse<any>) => {
  successful: boolean;
  errorMessage: ErrorMessageEnum | undefined;
};

type UploadOptions = {
  url: string;
  formData: FormData;
  responseHandler: ResponseHandler;
  nextState: StateEnum;
};

type TusUploadOptions = {
  url: string;
  metaData: unknown;
};

// This sets the mock adapter on the default instance
const mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });
mock.onPost('/upload').reply(function (config) {
  return new Promise(function (resolve) {
    let i = 0;
    const iv = setInterval(() => {
      const { onUploadProgress } = config;
      if (onUploadProgress) {
        onUploadProgress({ lengthComputable: true, total: 100, loaded: i });
      }
      if (i >= 100) {
        clearInterval(iv);
        if (Math.random() > 0.1) {
          resolve([200, { id: 4, name: 'foo' }]);
        } else {
          // reject() reason will be passed as-is.
          // Use HTTP error status code to simulate server failure.
          resolve([500, { success: false }]);
        }
      }
      i = i + 5;
    }, 500);
  });
});

const getPictureUploadOptions = async (id: FileId): Promise<UploadOptions> => {
  const type = 14; // fixed value, pool
  const { picturePoolUmaId } = getUserData();
  const fileWrapper = getFileWrapper(id);

  if (!picturePoolUmaId) {
    throw new Error('picture pool umaId not set');
  }
  if (!fileWrapper) {
    throw new Error('invalid file wrapper with id: ' + id);
  }

  const formData = new FormData();
  formData.append('type', type.toString());
  formData.append('umaId', picturePoolUmaId.toString());
  formData.append('file', fileWrapper.file);

  const url = await getPictureUploadUrl(picturePoolUmaId, type, 0);
  return {
    url,
    formData,
    responseHandler: ({ data }: AxiosResponse) => ({
      successful: /id=(\d+)/.test(data),
      errorMessage: parsePictureErrorcode(data),
    }),
    nextState: StateEnum.finished,
  };
};

const getTusVideoUploadOptions = async (): Promise<TusUploadOptions> => {
  const urlData = await getTusVideoUploadUrl();
  const url = urlData.url;
  //here we also receive the requestId
  const metaData = JSON.parse(urlData.metaData as any);

  const tusUploadOptions = {
    url,
    metaData,
  };

  return tusUploadOptions;
};

const getTusUploaderFile = (id: FileId) => {
  const fileWrapper = getFileWrapper(id);

  if (!fileWrapper) {
    throw new Error('invalid file wrapper with id: ' + id);
  }
  const { file } = fileWrapper;
  return file;
};

const getTusUploaderOptions = async (nextUploadQueueItem: QueueItem) => {
  const { displayName, mediaType } = nextUploadQueueItem;
  const { url, metaData } = await getTusVideoUploadOptions();

  const buildMetaData = Object.assign(metaData as any, {
    filename: displayName,
    filetype: mediaType + '/' + displayName.slice(((displayName.lastIndexOf('.') - 1) >>> 0) + 2),
  });

  const tusToken = getTusAuthTokenCookie();

  return {
    endpoint: url,
    chunkSize: 8388608,
    retryDelays: [0, 1000, 3000, 5000],
    metadata: buildMetaData,
    removeFingerprintOnSuccess: true,
    parallelUploads: 4,
    headers: {
      Authorization: `Bearer ${tusToken}`,
    },
  } as tus.UploadOptions;
};

export const getTusUploaderByFileId = async (
  id: FileId,
  nextUploadQueueItem: QueueItem
): Promise<tus.Upload> => {
  const file = getTusUploaderFile(id);

  const options = await getTusUploaderOptions(nextUploadQueueItem);
  const uploader = new tus.Upload(file, options);

  //if we uploaded the file before we should use the same requestId
  let previousRequestId: string;
  uploader.options.onAfterResponse = (req, res) => {
    if (req.getMethod() !== 'HEAD') {
      return;
    }

    const responseUrl = res.getUnderlyingObject().responseURL;

    uploader.findPreviousUploads().then((previousUploads) => {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        const foundPrevUpload = previousUploads.find(
          (prevUpload) => (prevUpload as any)?.uploadUrl === responseUrl
        );
        if (foundPrevUpload) {
          previousRequestId = foundPrevUpload.metadata.requestId;
        }
      }
    });
  };

  uploader.options.onProgress = (bytesUploaded: number, bytesTotal: number) => {
    const percentage = (bytesUploaded / bytesTotal) * 100;
    updateQueueItem({
      id,
      progress: Math.round(percentage),
      state: StateEnum.uploading,
    });
  };
  uploader.options.onError = () => {
    const queueItem = {
      ...nextUploadQueueItem,
      progress: 0,
      state: StateEnum.error,
    } as QueueItem;
    updateCurrentUpload(null);
    updateQueueItem(queueItem);
    dispatch(ActionEnum.uploadFailed, { queueItem });
  };
  uploader.options.onSuccess = async () => {
    const queueItem = {
      ...nextUploadQueueItem,
      progress: 100,
      state: StateEnum.afterUpload,
    };

    updateCurrentUpload(null);
    updateQueueItem(queueItem);

    //!!!there is a latency between the UI and the BE when it comes to an upload being finished
    //check if video is already listed and fully uploaded in BE
    const requestId = previousRequestId ?? options?.metadata?.requestId;
    if (requestId) {
      const interval = setInterval(async () => {
        const video = await getUploadedTusVideo(requestId);
        if (video) {
          const queueItem = {
            ...nextUploadQueueItem,
            progress: 100,
            //when a queueItem is finished it isnt shown in the queue anymore
            state: StateEnum.finished,
          };
          updateQueueItem(queueItem);
          dispatch(ActionEnum.uploadFinished, { queueItem });
          removeQueueItem(id);
          clearInterval(interval);
        }
      }, 5000);
    }
  };

  return uploader;
};

const TusUploadingProcess = async (nextUploadQueueItem: QueueItem) => {
  const { id } = nextUploadQueueItem;

  updateCurrentUpload(id);
  updateQueueItem({ id, state: StateEnum.uploading });

  const uploader = await getTusUploaderByFileId(id, nextUploadQueueItem);

  updateUploader(uploader, id);
  startOrResumeUpload(uploader);
};

const UploadingProcess = async (nextUploadQueueItem: QueueItem) => {
  const { id } = nextUploadQueueItem;
  const cancelTokenSource = axios.CancelToken.source();

  updateCurrentUpload(id);
  updateQueueItem({ id, state: StateEnum.uploading, cancelTokenSource });

  const { url, formData, responseHandler, nextState } = await getPictureUploadOptions(
    nextUploadQueueItem.id
  );
  axios
    .post(url, formData, {
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (progressEvent: ProgressEvent) =>
        updateQueueItem({
          id,
          progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
        }),
    })
    .then((res) => {
      const { successful, errorMessage } = responseHandler(res);
      const queueItem = {
        ...nextUploadQueueItem,
        progress: 100,
        state: successful ? nextState : StateEnum.error,
        errorMessage,
        cancelTokenSource: undefined,
      } as QueueItem;

      updateCurrentUpload(null);
      updateQueueItem(queueItem);
      dispatch(ActionEnum.uploadFinished, { queueItem });
    })
    .catch((err) => {
      const { message } = err;
      log('error', `Store handler error: ${JSON.stringify(err)}`, {
        context: 'UploadManager',
      });
      // handle manual cancel upload
      const canceled = message === UPLOAD_CANCELED;
      const queueItem = {
        ...nextUploadQueueItem,
        state: canceled ? StateEnum.canceled : StateEnum.error,
        cancelTokenSource: undefined,
        errorMessage: canceled ? undefined : { de: message, en: message },
      };

      updateCurrentUpload(null);
      updateQueueItem(queueItem);
      dispatch(ActionEnum.uploadFailed, { queueItem });
    });
};

export function startOrResumeUpload(upload: tus.Upload): void {
  // Check if there are any previous uploads to continue.
  upload.findPreviousUploads().then((previousUploads) => {
    // Found previous uploads so we select the first one.
    if (previousUploads.length) {
      const prevUpload = previousUploads[0];
      upload.resumeFromPreviousUpload(prevUpload);
    }

    // Start the upload
    upload.start();
  });
}

export const handleUpload = async ({ type }: Action): Promise<void> => {
  if ([ActionEnum.verified, ActionEnum.uploadFinished, ActionEnum.uploadFailed].includes(type)) {
    if (getCurrentUpload() === null) {
      const nextUploadQueueItem = getNextUploadQueueItem();
      if (nextUploadQueueItem) {
        if (nextUploadQueueItem.mediaType === 'video') {
          TusUploadingProcess(nextUploadQueueItem);
        } else {
          UploadingProcess(nextUploadQueueItem);
        }
      }
    }
  }
};
