import { hasScope, Scopes } from './scope';
import { ApiLang } from '../graphql/VXModels/types';
import { getLangCookie } from './cookies';

export const getBestMatchForLanguage = (signupLanguage?: string | undefined | null): ApiLang => {
  const cookieLanguage = getLangCookie();
  const validLanguages = Object.keys(ApiLang);

  const systemLanguage: string = (navigator.language || navigator.userLanguage) // can be different from the Accept-Language HTTP header, userLanguage for IE
    .substr(0, 2)
    .toLowerCase();

  return validLanguages.includes(cookieLanguage)
    ? cookieLanguage
    : typeof signupLanguage === 'string' && validLanguages.includes(signupLanguage)
    ? signupLanguage
    : validLanguages.includes(systemLanguage)
    ? systemLanguage
    : hasScope([Scopes.VXModels])
    ? ApiLang.de
    : ApiLang.en;
};

export const langOrder: ApiLang[] = [ApiLang.de, ApiLang.en];
export const getLangIndex = (lang: ApiLang): number => langOrder.indexOf(lang);
