import React, { FC, ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import MessengerEditor from '../MessengerEditor/MessengerEditor';

interface Props {
  name: string;
  disabled?: boolean;
  emoji?: boolean;
}

const FieldMessengerEditor: FC<Props> = ({ name, emoji = false, disabled = false }) => {
  return (
    <Field
      name={name}
      render={({ field, form: { setFieldValue } }: FieldProps): ReactElement => (
        <MessengerEditor
          {...field}
          onChange={(value: string): void => setFieldValue(field.name, value)}
          emoji={emoji}
          disabled={disabled}
        />
      )}
    />
  );
};

export default FieldMessengerEditor;
