import React from 'react';
import PropTypes from 'prop-types';
import { AbstractComponent, DateFormatter, T } from '../../../components';

class ModalPayoutTable extends AbstractComponent {
  closePayoutTimeTable() {
    this.props.closeRequest(false);
  }

  render() {
    return (
      <div className="modalgrid__box">
        <div className="modalgrid__box__header">
          <div className="icon-alarm" />
          <div>
            <T _={'finances:payout.sideMenu.timeTable'} />
          </div>
          <div className="seperator" />
          <div className="icon-remove options" onClick={this.closePayoutTimeTable} />
        </div>

        {/* ---------------------------------- start modal box ------------------------------------ */}
        <div className="payout-table__content">
          <div className="container_table__title box5">
            <T _={'finances:payout.sideMenu.timeTable'} />
          </div>
          <div className="container_table__content box20">
            <T _={'finances:payout.timeTable.info'} />
          </div>

          <div className="container_table_width">
            {/* ---------------------------------- colum 1 ------------------------------------ */}
            <div className="container_table">
              <div className="container_table_row">
                <p />
              </div>

              {Object.keys(this.props.payoutTimeTable).map(key => (
                <div className="container_table_colum container_mrg_top" key={key}>
                  <p className="container_table_subline_colum">
                    <DateFormatter date={key} format="MMMM yyyy" />
                  </p>
                </div>
              ))}
            </div>

            {/* ---------------------------------- colum 2 ------------------------------------ */}
            <div className="container_table">
              <div className="container_table_row">
                <p className="container_table_headline_colum">
                  <T _={'finances:payout.timeTable.columnMinPayoutRequestDate'} />
                </p>
              </div>

              {Object.keys(this.props.payoutTimeTable).map(itemKey => (
                <div className="container_table_colum container_mrg_top" key={itemKey}>
                  {this.props.payoutTimeTable[itemKey].minRequestDate.map(minRequestDate => (
                    <p className="container_table_subline_row" key={minRequestDate}>
                      <DateFormatter date={minRequestDate} format="P" />
                    </p>
                  ))}
                </div>
              ))}
            </div>

            {/* ---------------------------------- colum 3 ------------------------------------ */}
            <div className="container_table">
              <div className="container_table_row">
                <p className="container_table_headline_colum">
                  <T _={'finances:payout.timeTable.columnPeriod'} />
                </p>
              </div>

              {Object.keys(this.props.payoutTimeTable).map(itemKey => (
                <div className="container_table_colum container_mrg_top" key={itemKey}>
                  {this.props.payoutTimeTable[itemKey].billingPeriod.map(billingPeriod => (
                    <p
                      className="container_table_subline_row"
                      key={billingPeriod.from + '-' + billingPeriod.to}
                    >
                      <DateFormatter date={billingPeriod.from} format="P" /> -{' '}
                      <DateFormatter date={billingPeriod.to} format="P" />
                    </p>
                  ))}
                </div>
              ))}
            </div>

            {/* ---------------------------------- colum 4 ------------------------------------ */}
            <div className="container_table">
              <div className="container_table_row">
                <p className="container_table_headline_colum">
                  <T _={'finances:payout.timeTable.columnPayoutDate'} />
                </p>
              </div>
              {Object.keys(this.props.payoutTimeTable).map(itemKey => (
                <div className="container_table_colum container_mrg_top" key={itemKey}>
                  {this.props.payoutTimeTable[itemKey].payoutDate.map(payoutDate => (
                    <p className="container_table_subline_row" key={payoutDate}>
                      <DateFormatter date={payoutDate} format="P" />
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModalPayoutTable.propTypes = {
  payoutTimeTable: PropTypes.object,
  closeRequest: PropTypes.func,
};

ModalPayoutTable.defaultProps = {
  payoutTimeTable: {},
  closeRequest: () => {},
};

export default ModalPayoutTable;
export { ModalPayoutTable };
