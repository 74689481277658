import React, { FC } from 'react';
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router';
import { CalendarRedirect } from '../../packages/TV';
import { stripSlash } from '../../util/urlHelper';

const TV: FC<RouteComponentProps> = ({ match: { url } }) => {
  const path = stripSlash(url);

  return (
    <Switch>
      <Route path={`${path}/calendar`} exact component={CalendarRedirect} />
      <Redirect to="/" />
    </Switch>
  );
};

export default TV;
