import React, { FC } from 'react';
import { GREEN, RED, YELLOW } from '../../camtool-styles';
import { _ } from '../../util/translate';

export enum Level {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

interface Props {
  level: Level;
}

const SECTION_WIDTH = 100 / 3;
const SECTION_WIDTH_PERCENT = `${SECTION_WIDTH}%`;
const RECT_HEIGHT = 8;
const POINTER_WIDTH = 32;

const COLORS = { [Level.low]: RED, [Level.medium]: YELLOW, [Level.high]: GREEN };
const LEVEL_ORDER: Level[] = [Level.low, Level.medium, Level.high];

const Ampel: FC<Props> = ({ level }) => {
  const LABELS = {
    [Level.low]: _('ranking:videoQuality.label.low'),
    [Level.medium]: _('ranking:videoQuality.label.medium'),
    [Level.high]: _('ranking:videoQuality.label.high'),
  };

  const lvlFactor = LEVEL_ORDER.indexOf(level);
  const pointerColor = COLORS[level];
  const pointerTipOffset = `${SECTION_WIDTH * (lvlFactor + 0.5)}%`;

  return (
    <svg width="100%" css={{ maxHeight: RECT_HEIGHT * 4 + 1 }}>
      <g>
        <rect x="0" width={SECTION_WIDTH_PERCENT} height={RECT_HEIGHT} fill={COLORS[Level.low]} />
        <rect
          x={SECTION_WIDTH_PERCENT}
          width={SECTION_WIDTH_PERCENT}
          height={RECT_HEIGHT}
          fill={COLORS[Level.medium]}
        />
        <rect
          x={`${SECTION_WIDTH * 2}%`}
          width={SECTION_WIDTH_PERCENT}
          height={RECT_HEIGHT}
          fill={COLORS[Level.high]}
        />
      </g>
      <polygon
        points={`0,0 ${POINTER_WIDTH / 2},${RECT_HEIGHT * 2} ${POINTER_WIDTH},0`}
        fill={pointerColor}
        css={{ transform: `translateX(calc(${pointerTipOffset} - ${POINTER_WIDTH / 2}px))` }}
      />
      <text x={pointerTipOffset} y={RECT_HEIGHT * 4 - 2} textAnchor="middle" fill={pointerColor}>
        {LABELS[level]}
      </text>
    </svg>
  );
};

export default Ampel;
