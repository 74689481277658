import AppDispatcher from '../../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import ActionTypes from '../../../util/constants/ActionTypes';
import { EventEmitter } from 'events';

let CHANGE_EVENT = 'ListsCampointBillings.change';

let _data = {
  showSpinner: true,
  billingList: [],
};

class CampointBillingsStoreClass extends EventEmitter {
  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  get() {
    return _data;
  }
}

const CampointBillingsStore = new CampointBillingsStoreClass();

AppDispatcher.register(function(payload) {
  switch (payload.type) {
    case ActionTypes.LISTS_CAMPOINTBILLINGS_RECEIVE_DATA:
      _data = update(_data, { $merge: payload.data });
      CampointBillingsStore.emitChange();
      break;
    default:
  }
});

export { CampointBillingsStore };
