import React, { FC, RefObject } from 'react';
import { css } from '@emotion/core';

const BaseStyle = css({
  textAlign: 'center',
  fontSize: '24px !important',
  textTransform: 'uppercase',
});

interface IProps {
  className?: string;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  error?: string;
  placeholder?: string;
  autoComplete?: string;
  readOnly?: boolean;
  codeInputRef?: RefObject<HTMLInputElement>;
}

const FieldCodeInputType: FC<IProps> = ({
  readOnly,
  codeInputRef,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  autoComplete,
}) => {
  return readOnly ? (
    <span
      ref={codeInputRef}
      css={css`
        flex: 0 0 auto;
        padding: 10px 0;
        ${BaseStyle};
      `}
    >
      {value}
    </span>
  ) : (
    <input
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type="text"
      placeholder={placeholder}
      autoComplete={autoComplete}
      css={css`
        flex: 1;
        padding: 30px 0;
        ${BaseStyle};
      `}
    />
  );
};

export default FieldCodeInputType;
