import React, { FC, memo, useState, useEffect } from 'react';

import { InstantMessagingPlatformEnum, Query } from '../../../../../graphql/VXModels/types';
import { EmptyContent, FancyError, Spinner } from '../../../../../components';
import { useQuery } from '@apollo/react-hooks';
import { _ } from '../../../../../util/translate';
import { QUERY_MODEL_INSTANTMESSAGING_ACCOUNT } from '../../../../../graphql/VXModels/queries';
import ChannelList from './ChannelList';
import ThreadContainer from './ThreadContainer';
import { useRouteMatch } from 'react-router';
import { useTelegramUser, useTelegramUserlist } from '../../../hooks';
import { DARK_GRAY, WHITE, WHITE_2 } from '../../../../../camtool-styles';
import { UserFilter } from '../../../../../graphql/VXServicesTelegram/types';
import ChannelFilter from './ChannelFilter';
import { Box, Typography } from '@material-ui/core';
import { INITIAL_USER_LIMIT } from '../../../hooks/useTelegramUserlist';
import { useInterval } from '../../../../../hooks';
import { CHAT_FETCH_INTERVAL } from '../utils';
import FilterBar from '../FilterBar';

// todo: dynamically generate types out of actual object
export type Filters =
  | 'online'
  | 'unread'
  | 'blocked'
  | 'premium'
  | 'onlyTipping'
  | 'lastInteraction'
  | 'offline'
  | 'vipSubscribedSince'
  | 'subscribedSince';

export interface FilterData {
  key: number;
  label: Filters;
}

// creates array of FilterData objects without "subscribed" and "name" label and containing only truthy attributes (active filters)
const convertFilterToArray = (filter: UserFilter) =>
  Object.keys(filter)
    .filter(
      (key) =>
        (key !== 'subscribed' && key !== 'name' && filter[key]) ||
        (key === 'online' && filter[key] !== undefined)
    )
    .map((k, i) => {
      return { key: i, label: k };
    });

export const defaultChatFilter = {
  name: '',
  subscribed: true,
  online: undefined,
  premium: undefined,
  blocked: undefined,
  unread: undefined,
  onlyTipping: undefined,
};

const Chats: FC = memo(() => {
  const [userFilter, setUserFilter] = useState<UserFilter>(defaultChatFilter);
  // uiFilter = filter that reflects UI since not every "active" filter in UI is actually active (only when applying/sending request)
  const [uiFilter, setUiFilter] = useState<UserFilter>(defaultChatFilter);
  const userFilterUI = convertFilterToArray(userFilter) as FilterData[];
  const { path } = useRouteMatch();
  const match = useRouteMatch<{ userId: string }>(path + '/:userId([0-9]+)'); // @note: \d+ does not work here ???
  const userId = Number(match ? match.params.userId : 0);
  const { data: selectedUser } = useTelegramUser(userId, { skip: userId === 0 });
  const { loadUsers, data: userList, currency } = useTelegramUserlist();
  const users = userList?.users;

  const { data, loading } = useQuery<Query>(QUERY_MODEL_INSTANTMESSAGING_ACCOUNT, {
    variables: { platform: InstantMessagingPlatformEnum.TELEGRAM },
  });

  const handleDelete = (chipToDelete: Filters) => () => {
    setUiFilter((prevUserFilter) => ({
      ...prevUserFilter,
      [chipToDelete]: prevUserFilter[chipToDelete] === undefined ? true : undefined,
    }));
    setUserFilter((prevUserFilter) => ({
      ...prevUserFilter,
      [chipToDelete]: prevUserFilter[chipToDelete] === undefined ? true : undefined,
    }));
  };

  // setting up loadUsers function for ChannelFilter, Searchbox and ChannelList
  // todo: only call search function at 3 or more characters for name
  useEffect(
    () =>
      loadUsers({
        variables: {
          filter: userFilter,
          limit: INITIAL_USER_LIMIT,
        },
      }),
    [userFilter]
  );

  useInterval(
    () =>
      loadUsers({
        variables: {
          filter: userFilter,
          limit: INITIAL_USER_LIMIT,
        },
      }),
    CHAT_FETCH_INTERVAL
    // every subsequential call
  );

  if (loading) {
    return <Spinner />;
  }

  const accountData = data?.model?.instantMessaging?.account;
  const isInitialized = accountData?.isInitialized;
  const active = accountData?.active;

  if (!(isInitialized && active)) {
    return (
      <FancyError message={_('telegram:chat.needsActivation')}>
        {_('telegram:chat.support.text')}
      </FancyError>
    );
  }

  //check selectedUser exist for thread
  return (
    <article css={{ overflow: 'auto' }}>
      <div
        css={{
          flexDirection: 'column',
          flex: 1,
          width: '100%',
        }}
      >
        <Box display="flex" position="relative" alignItems="center" p={2} bgcolor={DARK_GRAY}>
          <ChannelFilter
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            uiFilter={uiFilter}
            setUiFilter={setUiFilter}
          />
          <Box px={2}>
            <Typography style={{ color: WHITE }} variant="h3">
              {_('mailings:telegram.sectionTitle.chats')}
            </Typography>
          </Box>
        </Box>
        <div
          css={{
            overflow: 'hidden',
            height: '80vh',
            backgroundColor: WHITE_2,
            border: '1px solid #c0c0c0',
            borderRadius: 2,
          }}
        >
          <Box display="flex" flexDirection="column" maxWidth="320px">
            {userFilterUI.length > 0 && (
              <FilterBar
                uiFilter={uiFilter}
                filterData={userFilterUI}
                handleDelete={handleDelete}
              />
            )}
            <ChannelList
              selectedUser={selectedUser}
              currency={currency}
              path={path}
              users={users}
            />
          </Box>
          {selectedUser && selectedUser?.id ? (
            <ThreadContainer selectedUser={selectedUser} currency={currency} />
          ) : (
            <EmptyContent icon={'icon-chat'} title={_('telegram:chat.channel.select')} />
          )}
        </div>
      </div>
    </article>
  );
});

export default Chats;
