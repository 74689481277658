import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { GRAY_2, RED } from '../../../camtool-styles';
import useSetOptInStatus from './useSetOptInStatus';
import { DailyBonusOptInStatusEnum } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';
import { Button } from '../../../atoms';
import ModalDailyBonusOptOutConfirmation from './ModalDailyBonusOptOutConfirmation';
import { BREAKPOINT_SMALL } from './breakpoints';

interface IProps {
  closable?: boolean;
  onClose?: () => void;
}

const Container = styled.div`
  flex-direction: column;
  padding-top: 34px;
  text-align: center;
  align-items: center;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-top: 30px;
  }
`;

const OptOutWarning = styled.p`
  max-width: 525px;
  margin-bottom: 25px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    text-align: start;
  }
`;

const OptOutNote = styled.p`
  color: ${GRAY_2};
  margin-top: 35px;
  margin-bottom: 0;
`;

const ErrorMessage = styled.div`
  margin-bottom: 25px;
  color: ${RED};
  font-size: 18px;
`;

const OptInSection: FC<IProps> = ({ closable, onClose }) => {
  const { setOptInStatus, loading, error } = useSetOptInStatus();

  const optIn = (): Promise<void> => setOptInStatus(DailyBonusOptInStatusEnum.optIn);
  const optOut = (): Promise<void> => setOptInStatus(DailyBonusOptInStatusEnum.optOut);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const openConfirmationModal = (): void => setIsConfirmationModalOpen(true);
  const closeConfirmationModal = (): void => setIsConfirmationModalOpen(false);

  const onConfirmOptOut = (): void => {
    closeConfirmationModal();
    optOut();
  };

  return (
    <Container>
      <OptOutWarning dangerouslySetInnerHTML={{ __html: _('modalbox:dailyBonus.optOutWarning') }} />
      {!!error && <ErrorMessage>{_('common:error.generic')}</ErrorMessage>}
      <Button size="lg" onClick={optIn} disabled={loading} css={{ marginBottom: 12 }}>
        {_('modalbox:dailyBonus.buttonParticipate')}
      </Button>
      <Button
        theme="link-gray"
        onClick={closable ? onClose : openConfirmationModal}
        disabled={loading}
      >
        {_('modalbox:dailyBonus.buttonAbort')}
      </Button>
      <OptOutNote>{_('modalbox:dailyBonus.optOutNote')}</OptOutNote>
      {isConfirmationModalOpen && (
        <ModalDailyBonusOptOutConfirmation
          onConfirm={onConfirmOptOut}
          onAbort={closeConfirmationModal}
        />
      )}
    </Container>
  );
};

export default OptInSection;
