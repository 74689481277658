import React, { memo, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { BLUE, WHITE_2 } from '../../../camtool-styles';

interface ProgressBarProps { progress: number; } // prettier-ignore

const RuleDiv = styled.div`
  position: relative;
  height: 14px;
  left: 100%;
  top: -3px;
  width: 2px;
  background-color: ${BLUE};
`;

const ProgressDiv = styled.div`
  background-color: ${BLUE};
  height: 8px;
  position: relative;
  transition: 0.5s width;
`;

const LabelDiv = styled.div`
  color: ${BLUE};
  margin-top: 4px;
  min-width: 18px;
  text-align: right;
  transition: 0.5s width;
`;

const ProgressBar = memo((props: ProgressBarProps) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (props.progress > 100) {
      setProgress(100);
    } else if (props.progress < 0) {
      setProgress(0);
    } else {
      setProgress(props.progress);
    }
  }, [props.progress]);

  return (
    <div css={{ display: 'block !important', width: '100%', div: { display: 'block !important' } }}>
      <div css={{ backgroundColor: WHITE_2 }}>
        <ProgressDiv css={{ width: `${progress}%` }}>
          <RuleDiv />
        </ProgressDiv>
      </div>

      <LabelDiv css={{ width: `${progress}%` }}>
        <span css={{ fontSize: 16 }}>{progress}%</span>
      </LabelDiv>
    </div>
  );
});

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
