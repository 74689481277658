import React, { FC } from 'react';
import { WHITE, GRAY_4 } from '../../../../camtool-styles';
import { AboWizardActionType, IPageProps } from '../../types';
import { _ } from '../../../../util/translate';
import DisplayAboContextContent from './DisplayAboContextContent';
import AboReviewImage from './AboReviewImage';
import AboReviewOverview from './AboReviewOverview';
import GenericEmptyContent from '../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Icon } from '../../../../atoms/Icon';
import { backgroundCheckmark } from '../../../../atoms/Icon/libraries/svg';

const AboReviewPage: FC<IPageProps> = ({ state, dispatch }) => {
  if (!state.formValues) {
    return <GenericEmptyContent />;
  }
  const aboContentType = state.aboContentType;
  const pictureUrl = state.formValues?.picture?.url;

  const handleCreateNewAboClick = (): void => {
    dispatch({ type: AboWizardActionType.CreateNewAbo, payload: {} });
  };

  return (
    <article css={{ flexDirection: 'column', alignItems: 'center' }}>
      <header
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <div css={{ alignItems: 'center', marginBottom: 16 }}>
          <Icon css={{ marginRight: 8 }} icon={backgroundCheckmark} size={'20px'} />
          <h1>{_(`benefits:abo.reviewPage.${aboContentType}.title`)}</h1>
        </div>
        <p>{_(`benefits:abo.reviewPage.${aboContentType}.subtitle`)}</p>
      </header>
      <section
        css={{
          margin: '36px 0',
          border: `1px solid ${GRAY_4}`,
          padding: 16,
          backgroundColor: WHITE,
        }}
      >
        <div css={{ marginRight: 16 }}>
          {<AboReviewImage pictureUrl={pictureUrl} aboContentType={aboContentType} />}
        </div>
        <AboReviewOverview formValues={state.formValues} />
      </section>

      {DisplayAboContextContent('creatorButton', aboContentType)}

      {DisplayAboContextContent('newAboButton', aboContentType, handleCreateNewAboClick)}
    </article>
  );
};

export default AboReviewPage;
