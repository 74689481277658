import React from 'react';
import PropTypes from 'prop-types';
import { FinancesStore, FinancesActionCreators } from '../../../stores/Finance/Finances';
import { AlertsStore } from '../../../stores/Alerts/AlertsStore';
import { Button, ButtonGroup, Dropdown, GroupRow, Spinner, T } from '../../../components';
import { getFormattedDate } from '../../../util/Formatter';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';

const STORE = 'scheduler';

function getStateFromStores() {
  return {
    scheduler: FinancesStore.get(STORE),
  };
}

class ModalPayoutAssistent extends React.Component {
  constructor(props) {
    super(props);
    this.state = getStateFromStores();
    this.loading = true;
  }

  componentDidMount() {
    FinancesStore.addChangeListener(STORE, this.onStoreChange);
    FinancesActionCreators.getSchedulerData();
  }

  componentWillUnmount() {
    FinancesStore.removeChangeListener(STORE, this.onStoreChange);
  }

  onStoreChange = () => {
    this.loading = false;
    this.setState(getStateFromStores());
  };

  cancelAssistant = () => {
    this.props.closeRequest(false);
  };

  onChangeSchedulerType = event => {
    let state = getStateFromStores();
    state.scheduler.currentType = event.currentTarget.value;
    this.setState(state);
  };

  onChangeMinAmount = event => {
    let state = getStateFromStores();
    state.scheduler.currentAmount = event.currentTarget.value;
    this.setState(state);
  };

  submitForm = model => {
    let alert =
      model.schedulerType > 0
        ? 'common:alerts.autoPaymentActivated'
        : 'common:alerts.autoPaymentDectivated';

    FinancesActionCreators.savePayoutAssistant(model, response => {
      AlertsStore.add({ type: 'info', message: _(alert) });
    });

    this.props.closeRequest(true);
  };

  isActive = () => this.state.scheduler.currentType !== 0;

  getPayoutDate = () => {
    let payoutDate = '';
    const [{ lang }] = this.props.appState;

    if (this.state.scheduler.currentType === 4) {
      payoutDate = getFormattedDate(this.state.scheduler.payoutDateMonthMiddle, lang);
    } else if (this.state.scheduler.currentType === 3) {
      payoutDate = getFormattedDate(this.state.scheduler.payoutDateMonthBegin, lang);
    } else if (this.state.scheduler.currentType === 2) {
      payoutDate = getFormattedDate(this.state.scheduler.payoutDateBiweekly, lang);
    }
    return payoutDate;
  };

  render() {
    return (
      <div className="modalgrid__box">
        <div className="modalgrid__box__header">
          <div className="icon-sunglasses" />
          <div>
            <T _={'finances:payout.sideMenu.payoutAssistant'} />
          </div>
          <div className="seperator" />
          <div className="icon-remove options" onClick={this.cancelAssistant} />
        </div>

        <div className="modalgrid__box__content">
          <Formsy.Form
            ref="payoutAssistantForm"
            className="modalgrid__box__content__column payoutrequest spinner-container"
            onValidSubmit={this.submitForm}
          >
            {this.loading && <Spinner />}

            {/*  IF SCHEDULER ACTIVE */
            this.isActive() ? (
              <div className="payoutnotificationbox payoutassistant">
                <div>
                  <div className="notification">
                    <div className="icon-ok icon" />
                    <div className="text">
                      <T _={'finances:payout.assistant.active'} />
                    </div>
                  </div>
                  <div className="sub-notification">
                    <div className="text">
                      {_('finances:payout.assistant.nextPayoutInfo', {
                        postProcess: 'sprintf',
                        sprintf: [this.getPayoutDate()],
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              /*  IF SCHEDULER INACTIVE */
              <div className="payoutnotificationbox payoutassistantinaktive">
                <div>
                  <div className="notification">
                    <div className="icon-ban-circle icon" />
                    <div className="text">
                      <T _={'finances:payout.assistant.inactive'} />
                    </div>
                  </div>
                  <div className="sub-notification">
                    <div className="text" />
                  </div>
                </div>
              </div>
            )}

            {/*  SCHEDULER TYPE SELECTOR */}
            <GroupRow
              label={_('finances:payout.assistant.selectbox.scheduler.label')}
              tooltip="none"
            >
              <Dropdown
                name="schedulerType"
                value={this.state.scheduler.currentType.toString()}
                forceTranslate={true}
                options={this.state.scheduler.allowedTypes}
                onChange={this.onChangeSchedulerType}
              />
            </GroupRow>

            {/*  MIN AMOUNT SELECTOR */}
            <GroupRow
              label={_('finances:payout.assistant.selectbox.minamount.label')}
              tooltip="none"
              className={!this.isActive() ? 'disabled' : null}
            >
              <Dropdown
                name="minAmount"
                value={this.state.scheduler.currentAmount.toString()}
                options={this.state.scheduler.allowedAmounts}
                disabled={!this.isActive()}
                onChange={this.onChangeMinAmount}
              />
            </GroupRow>

            <ButtonGroup position="right">
              {/*  CANCEL BUTTON */}
              <Button
                type="button"
                label={_('common:button.cancel')}
                onClick={this.cancelAssistant}
              />

              {/*  SAVE BUTTON */}
              <Button type="submit" label={_('common:button.save')} class="button--blue" />
            </ButtonGroup>
          </Formsy.Form>
        </div>
      </div>
    );
  }
}

ModalPayoutAssistent.propTypes = {
  closeRequest: PropTypes.func.isRequired,
};

export default withAppState(ModalPayoutAssistent);
