export const copyToClipboard = (text: string): void => {
  if (text) {
    const inp = document.createElement('input');
    document.body.appendChild(inp);
    inp.setAttribute('readonly', '');
    inp.style = { position: 'absolute', left: '-9999px' };
    inp.value = text;
    // element has to be touched/selected in order to make copy command work
    inp.select();
    document.execCommand('copy');
    inp.remove();
  }
};
