import React, { FC } from 'react';
import styled from '@emotion/styled';
import { _ } from '../../../../util/translate';
import { BREAKPOINT_SMALL } from '../breakpoints';

const Header = styled.h2`
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  margin-top: 46px;
  margin-bottom: 15px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 35px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 14px;
  }
`;

const List = styled.ul`
  align-self: center;
  padding-left: ${62 + 17}px;
  margin-bottom: 34px;
  list-style-position: outside;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding-left: 17px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 21px;
  }
`;

const Item = styled.li`
  font-size: 16px;
  margin-bottom: 11px;
  letter-spacing: 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 12px;
  }
`;

const Advantages: FC = () => (
  <>
    <Header>{_('modalbox:dailyBonus.advantagesHeader')}</Header>
    <List>
      <Item>{_('modalbox:dailyBonus.advantages.item1')}</Item>
      <Item>{_('modalbox:dailyBonus.advantages.item2')}</Item>
      <Item>{_('modalbox:dailyBonus.advantages.item3')}</Item>
      <Item>{_('modalbox:dailyBonus.advantages.item4')}</Item>
      <Item>{_('modalbox:dailyBonus.advantages.item5')}</Item>
    </List>
  </>
);

export default Advantages;
