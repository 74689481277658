import React, { FC } from 'react';
import { SecurityRole, VXModelsMobileTargetEnum } from '../../../../graphql/VXModels/types';
import useDeepLinkBeta from '../../../../hooks/useDeepLinkBeta';
import { LeftMenuRoot } from '../../components';
import {
  AffiliateGroup,
  MarketingGroup,
  ProfileGroup,
  TelegramGroup,
} from '../groups';
import {
  DashboardItem,
  FeedItem,
  FinancesItem,
  HelpItem,
  NewscenterItem,
  RankingItem,
  Service0900Item,
  VXGamesItem,
} from '../items';
import { useUserData, useHasRole } from '../../../../util/UserData';

const LeftMenuVXModels: FC = () => {
  const {
    data: feedData,
    loading: feedLoading,
    error: feedError,
  } = useDeepLinkBeta(VXModelsMobileTargetEnum.feed);
  const isFeedLinkReady = feedData && !feedLoading && !feedError;
  const hasRole = useHasRole(SecurityRole.VX_USER_MASTER);
  const { model } = useUserData();
  const isFinancesAllowed = hasRole || model?.permissions.isFinancesVisibleWithoutMasterPassword;
  const isFeatureFlagCoinsEnabled = true;
  const vxgamesAllowed = model?.vxChallenges.isAllowed;

  return (
    <LeftMenuRoot>
      <DashboardItem />
      <ProfileGroup isFeatureFlagCoinsEnabled={isFeatureFlagCoinsEnabled} />
      <TelegramGroup />
      <MarketingGroup isFeatureFlagCoinsEnabled={isFeatureFlagCoinsEnabled} />
      {!isFeatureFlagCoinsEnabled && isFinancesAllowed && <FinancesItem />}
      {!isFeatureFlagCoinsEnabled && <AffiliateGroup />}
      <Service0900Item />
      {!isFeatureFlagCoinsEnabled && <RankingItem />}
      {vxgamesAllowed && <VXGamesItem />}
      {!isFeatureFlagCoinsEnabled && isFeedLinkReady && <FeedItem />}
      {!isFeatureFlagCoinsEnabled && <NewscenterItem />}
      <HelpItem />
    </LeftMenuRoot>
  );
};

export default LeftMenuVXModels;
