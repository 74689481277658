import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { conversation } from '../../../../../atoms/Icon/libraries/glyph';
import { useMediaQuery, useTheme } from '@material-ui/core';

const ChatsItem: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const indexRoute = !isMobile ? '/telegram/chat/desktop' : '/telegram/chat/mobile';

  return (
    <LeftMenuItem title={_('navigation:telegram.chats')} icon={conversation} uri={indexRoute} />
  );
};

export default ChatsItem;
