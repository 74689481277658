import styled from '@emotion/styled';
import { TableSortOrderEnum } from '../types';
import { BLUE } from '../../../camtool-styles';
import { css } from '@emotion/core';

const styles = css`
  padding: 1px 5px;
`;

const TableHeaderItemSort = styled.span(
  ({ sort, isSortSelected }: { sort: TableSortOrderEnum; isSortSelected: boolean }) => [
    styles,
    {
      color: isSortSelected ? BLUE : 'inherit',
      opacity: isSortSelected ? 1 : 'inherit',
      transform: sort === TableSortOrderEnum.asc ? 'rotateX(180deg)' : 'none',
    },
  ]
);

export default TableHeaderItemSort;
