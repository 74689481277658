import { createStyles, makeStyles } from '@material-ui/core';
import { BLACK_2, WHITE, RED } from '../../../../../camtool-styles';

export const useLastGamesTeaserStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      margin: '8px 8px 20px 8px',
      height: 261,
      flexDirection: 'row-reverse',
      background: WHITE,
      color: BLACK_2,
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      [theme.breakpoints.down(1100)]: {
        height: 180,
      },
      [theme.breakpoints.down(900)]: {
        height: 150,
      },
      [theme.breakpoints.down(700)]: {
        flexDirection: 'column',
        height: 350,
      },
      [theme.breakpoints.down(450)]: {
        height: 300,
      },
    },
    tilted: {
      transform: 'rotate(30deg)',
      background: WHITE,
      height: '1000px',
      width: 'calc(100% - 760px)',
      position: 'absolute',
      top: -300,
      left: 0,
      [theme.breakpoints.down(1500)]: {
        width: 'calc(100% - 500px)',
      },
      [theme.breakpoints.down(1100)]: {
        width: 'calc(100% - 400px)',
      },
      [theme.breakpoints.down(700)]: {
        position: 'relative',
        background: 'none',
        width: '100%',
      },
    },
    tiltedBack: {
      transform: 'rotate(-30deg)',
      marginTop: 340,
      [theme.breakpoints.down(1100)]: {
        marginTop: 320,
        marginLeft: -30,
      },
      [theme.breakpoints.down(900)]: {
        marginTop: 310,
        marginLeft: -30,
      },
      [theme.breakpoints.down(700)]: {
        marginTop: 310,
        marginLeft: 125,
      },
    },
    image: {
      height: 261,
      width: 759,
      [theme.breakpoints.down(1100)]: {
        height: 178,
        width: 524,
      },
      [theme.breakpoints.down(900)]: {
        height: 150,
        width: 433,
      },
      [theme.breakpoints.down(700)]: {
        height: 150,
        width: 650,
        backgroundPosition: 'top',
      },
      [theme.breakpoints.down(450)]: {
        height: 100,
        width: 450,
      },
    },
    button: {
      marginTop: 10,
      [theme.breakpoints.down(900)]: {
        marginTop: 5,
        height: 30,
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    badge: {
      background: RED,
      borderRadius: 3,
      paddingLeft: 11,
      paddingRight: 11,
      marginBottom: 13,
      width: 'fit-content',
      [theme.breakpoints.down(700)]: {
        paddingTop: 2,
      },
    },
    sub: {
      fontSize: '20px',
      color: BLACK_2,
      [theme.breakpoints.down(1100)]: {
        fontSize: '14px',
      },
    },
    h2: {
      fontSize: '30px',
      fontWeight: 'bold',
      color: BLACK_2,
      [theme.breakpoints.down(1100)]: {
        fontSize: '20px',
      },
    },
  })
);
