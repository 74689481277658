import gql from 'graphql-tag';
import { FRAGMENT_FILE } from './File';

export const FRAGMENT_MESSAGE = gql`
  fragment Message on Message {
    id
    direction
    in
    out
    type
    system
    text
    file {
      ...File
    }
    timestamp
    read
    broadcast {
      userType
    }
    isPaidContent
    paidContent {
      fileType
      price
      text
      currency
    }
  }
  ${FRAGMENT_FILE}
`;
