import React, { ChangeEvent, FC } from 'react';
import BigIconButton from '../../../components/BigIconButton/BigIconButton';
import { _ } from '../../../util/translate';

interface IImagePickerAddProps {
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const ImagePickerAdd: FC<IImagePickerAddProps> = ({ url, onChange, selected }) => (
  <div
    css={{
      width: 184,
      height: 184,
      border: '1px solid rgb(20, 19, 22)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignContent: 'flex-start',
      backgroundImage: `url('${url}')`,
      margin: 4,
      color: 'rgb(20, 19, 22)',
      borderRadius: 4,
    }}
  >
    <BigIconButton
      labelFor={'browse'}
      label={_('mediamanagement:picfolder.contentItemAdd.title')}
      info={''}
      iconMain="icon-disk-open"
      iconLeft="icon-picture"
      iconRight="icon-camera"
    />
    <input id={'browse'} type="file" onChange={onChange} css={{ display: 'none' }} />
  </div>
);

export default ImagePickerAdd;
