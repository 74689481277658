import { BLACK_1, GRAY_3, WHITE_2 } from '../../../camtool-styles';
import { Override } from './types';

export const MuiInputLabel: Override<'MuiInputLabel'> = {
  root: {
    fontSize: 14,
    marginBottom: 8,
    color: BLACK_1,
    '&$disabled': {
      color: GRAY_3,
      backgroundColor: WHITE_2,
    },
    '&$focused': {
      color: undefined,
    },
  },
  outlined: {
    transform: 'none',
  },
  formControl: {
    top: 'inherit',
    left: 'inherit',
    position: 'inherit',
    transform: 'none',
  },
};
