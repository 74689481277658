import React, { FC, ReactElement } from 'react';
import NonEditablePhoneNumberInput from './NonEditablePhoneNumberInput';
import VerificationCodeInput from './VerificationCodeInput';
import KnockMailCheckbox from './KnockMailCheckbox';
import SaveNumberButton from './SaveNumberButton';
import { FormikProps, Form, Formik, FormikActions, FormikValues } from 'formik';
import { MUTATION_MODEL_SERVICE0900_VERIFY_TARGETNUMBER } from '../../../../../graphql/VXModels/mutations';
import { useMutation } from '@apollo/react-hooks';
import { QUERY_MODEL_SERVICE0900 } from '../../../../../graphql/VXModels/queries';
import { mergeDeep } from 'apollo-utilities';
import { Service0900VerifyFormikValues, Service0900PhoneNumber } from '../../types';
import { onVerifySubmit } from '../../utils';

interface Props {
  targetNumber: Service0900PhoneNumber;
  onResetStep: () => void;
  onSuccess: () => void;
  closeModal: () => void;
}

const VerifyPhoneNumber: FC<Props> = ({ targetNumber, onResetStep, onSuccess, closeModal }) => {
  const verifyInitialData: Service0900VerifyFormikValues = {
    targetNumber,
    verificationCode: '',
    deactivateMailAndKnock: false,
  };
  const [verifyPhoneNumber, { loading, error }] = useMutation(
    MUTATION_MODEL_SERVICE0900_VERIFY_TARGETNUMBER,
    {
      update: (cache, { data }) => {
        const targetNumber = data?.model?.service0900?.verifyTargetNumber?.data;
        if (targetNumber) {
          const cachedData = cache.readQuery({ query: QUERY_MODEL_SERVICE0900 });
          const mergedData = mergeDeep(cachedData, { model: { service0900: { targetNumber } } });
          cache.writeQuery({
            query: QUERY_MODEL_SERVICE0900,
            data: mergedData,
          });
        }
      },
    }
  );

  return (
    <Formik
      initialValues={verifyInitialData}
      enableReinitialize={true}
      onSubmit={(
        values,
        { setStatus, setFieldError }: FormikValues & FormikActions<Service0900VerifyFormikValues>
      ): Promise<void> =>
        onVerifySubmit(values, setStatus, setFieldError, onSuccess, closeModal, verifyPhoneNumber)
      }
    >
      {({ values }: FormikProps<Service0900VerifyFormikValues>): ReactElement => (
        <Form css={{ flexDirection: 'column' }}>
          <div css={{ flexDirection: 'column' }}>
            <div css={{ flexDirection: 'column', alignItems: 'center' }}>
              <NonEditablePhoneNumberInput onResetStep={onResetStep} />
              <VerificationCodeInput />
              <KnockMailCheckbox />
            </div>
            <SaveNumberButton loading={loading} hasError={!!error} values={values} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default VerifyPhoneNumber;
