import React, { ReactElement } from 'react';

export const Crown = (): ReactElement => {
  return (
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="person-vip">
        <path
          id="person-vip-2"
          fill="currentColor"
          d="M19.54,8.32a1.52,1.52,0,0,0-.27,1,1.3,1.3,0,0,0-.91-.38,1.85,1.85,0,0,0-1.58,2,2.42,2.42,0,0,0,.43,1.38h0a1.18,1.18,0,0,1,.18.63c0,.87-1,1.57-2.19,1.57S13,13.84,13,13a1.14,1.14,0,0,1,.23-.69l.48-.49h0a1.53,1.53,0,0,0,.43-1.07v-.3a1.52,1.52,0,0,0-1.5-1.54h-.13a3.18,3.18,0,0,0,.38-1.34s0-2.08-1.79-2.09S9.31,7.6,9.31,7.6a2.64,2.64,0,0,0,.37,1.28H9.6a1.52,1.52,0,0,0-1.5,1.54v.31a1.56,1.56,0,0,0,.73,1.32l0,0a1.21,1.21,0,0,1,.5.92c0,.82-1,1.48-2.28,1.48a3.56,3.56,0,0,1-.91-.12,2.21,2.21,0,0,1-.55-.23A1.36,1.36,0,0,1,4.8,13a1,1,0,0,1,.14-.5v0A2.31,2.31,0,0,0,5.47,11a1.84,1.84,0,0,0-1.57-2,1.32,1.32,0,0,0-.92.4c0-.25-.29-1.73-1.53-1.53l1.46,8.67a1.41,1.41,0,0,0,1,1.13,26.13,26.13,0,0,0,7.41.95,22.11,22.11,0,0,0,7.09-1,1.37,1.37,0,0,0,.91-1.11l1.47-8.72A1.63,1.63,0,0,0,19.54,8.32Z"
        />
        <rect fill="none" width="22.21" height="24" />
      </g>
    </g>
  );
};
