import React, { useEffect } from 'react';
import NewPasswordForm from './NewPasswordForm';
import SplashScreen from '../../components/SplashScreen';
import { ErrorBox, WindowCompilation } from '../../components/Layout/DarkCentered';
import { Translation } from '../../components';
import ButtonLink from '../../atoms/ButtonLink/ButtonLink';
import { stripSlash } from '../../util/urlHelper';
import { QUERY_VERIFY_PASSWORD_HASH } from '../../graphql/VXModels/queries';
import { _ } from '../../util/translate';
import { ActionEnum, useAppState } from '../../util/AppState';
import { useLazyQuery } from '@apollo/react-hooks';

const Verify = ({
  match: {
    url,
    params: { hash },
  },
}) => {
  const path = stripSlash(url);
  const [state, dispatch] = useAppState();
  const { authToken: currentAuthToken } = state;

  const [verifyPasswordHash, { loading, called, data }] = useLazyQuery(QUERY_VERIFY_PASSWORD_HASH, {
    variables: { hash },
  });

  useEffect(() => {
    if (hash && !called) {
      if (currentAuthToken) {
        // Clear the auth token. (The verify request would fail if we sent an expired auth token.)
        // On the next rerender, we'll have a new Apollo client with an empty Authorization header.
        dispatch({ type: ActionEnum.clearAuthToken });
      } else {
        verifyPasswordHash();
      }
    }
  }, [hash, currentAuthToken]);

  const verifyPasswordResetHash = data?.verifyPasswordResetHash;

  return !called || loading ? (
    <SplashScreen />
  ) : verifyPasswordResetHash ? (
    <NewPasswordForm />
  ) : (
    <WindowCompilation title={_('account:passwords.box.normalPass.title')}>
      <ErrorBox>
        <Translation _={'passwordreset:error.linkExpired'} />{' '}
      </ErrorBox>

      <ButtonLink to={path + '/../..'}>
        <Translation _={'passwordforget:content.labelresetpw'} />
      </ButtonLink>
    </WindowCompilation>
  );
};

export default Verify;
