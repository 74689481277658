import React, { ChangeEvent, FC } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { _ } from '../../../../../../util/translate';
import { sanitizeLang } from '../../../../../../util/Translator';
import { IAboDetailsPageForm } from '../../../../types';
import { MultiLanguageTextField } from '../../../../../../molecules/MUI';
import { MAX_ABO_NAME_LENGTH } from '../../validationSchema';
import BuildValidationObject from '../../../../../../util/BuildValidationObject';
import { ApiLang } from '../../../../../../graphql/VXModels/types';
import { CharLimit } from '../../../../../../atoms';
interface FieldNameValidationProps {
  errors: FormikErrors<IAboDetailsPageForm>;
  touched: FormikTouched<IAboDetailsPageForm>;
  field: {
    onChange: {
      (e: React.ChangeEvent<any>): void;
      <T = unknown>(field: T): T extends React.ChangeEvent<any> ? void : (e: unknown) => void;
    };
    onBlur: {
      (e: React.FocusEvent<any>): void;
      <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    value: any;
    name: string;
  };
  language: ApiLang;
  changeLanguage: (nthLanguage: number) => void;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const FieldNameValidation: FC<FieldNameValidationProps> = ({
  language,
  changeLanguage,
  placeholder,
  onChange,
  field,
  errors,
  touched,
}) => {
  const error =
    touched.nameTranslation?.[language] && errors.nameTranslation?.[language]
      ? errors.nameTranslation?.[language]
      : '';
  const validation = BuildValidationObject('error', error);

  return (
    // todo: get rid of missing key warning?
    <MultiLanguageTextField
      language={language}
      changeLanguage={changeLanguage}
      label={{
        de: _(`benefits:abo.detailsPage.nameLabel`, { lng: sanitizeLang('de') }),
        en: _(`benefits:abo.detailsPage.nameLabel`, { lng: sanitizeLang('en') }),
      }}
      error={!!error}
      textFieldProps={{
        ...field,
        onChange: onChange,
        charLimitHelper: <CharLimit valueLength={field.value.length} limit={MAX_ABO_NAME_LENGTH} />,
        validation: validation,
        placeholder: placeholder,
      }}
    />
  );
};

export default FieldNameValidation;
