import React from 'react';

import NavSubVX from '../NavSubVX';
import { Footer, T } from '../../../../components';
import View from '../../../MediaManagement/Picture/View/';
import { Redirect, Route, Switch } from 'react-router';
import { stripSlash } from '../../../../util/urlHelper';
import ProtectedRoute from '../../../../components/Routing/ProtectedRoute';

const Profilepic = ({ match }) => {
  const path = stripSlash(match.url);
  return (
    <main id="vx-profile-pic" className="vx-profile-pic grid">
      <NavSubVX />
      <section className="grid__row grow__no-shrink__auto">
        <section className="grid__column grid__box">
          <header className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'profiles:visitx.profilepics.pagetitle.label'} />
            </div>
          </header>

          <div className="grid__box__item spinner-container">
            <div className="grid__box__item__content">
              <Switch>
                <ProtectedRoute
                  path={`${path}/:umaId`}
                  component={props => <View {...props} />}
                />
                <Redirect to={`${path}/_sedcardVX12`} />
              </Switch>
            </div>
          </div>
        </section>
      </section>
      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </main>
  );
};

// Profilepic.debug = true;

export default Profilepic;
export { Profilepic };
