import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { QueryResult } from '@apollo/react-common';

import { QUERY_MODEL_MAILINGS_MAILINGS } from '../../../graphql/VXModels/queries';
import {
  Mailing,
  MailingRecipientsGroupsEnum,
  MailingStatusEnum,
  MailingTypeEnum,
  Query,
} from '../../../graphql/VXModels/types';

interface Variables {
  type: MailingTypeEnum;
  recipientsGroups?: MailingRecipientsGroupsEnum[];
  status?: MailingStatusEnum[];
  limit?: number;
}

type Result = Omit<QueryResult<Query, Variables>, 'data'> & {
  mailingsList: Mailing[] | undefined;
};

export const useMailingsList = (
  variables: Variables,
  options?: QueryHookOptions<Query, Variables>
): Result => {
  const { data, ...result } = useQuery<Query, Variables>(QUERY_MODEL_MAILINGS_MAILINGS, {
    ...options,
    variables,
  });
  const mailingsList = data?.model?.mailings?.mailings;

  return { mailingsList, ...result };
};
