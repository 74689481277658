import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedNumber } from '../../util/Formatter';
import { useLang } from '../../util/AppState';

const FormatNumber = ({ className, value, decimals }) => {
  const [lang] = useLang();

  return <span className={className}>{getFormattedNumber(value, lang, decimals)}</span>;
};

FormatNumber.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  decimals: PropTypes.number,
};

FormatNumber.defaultProps = {
  className: '',
  value: 0,
  decimals: 0,
};

export default FormatNumber;
