import React, { createContext, FC, useContext, useState } from 'react';
import { LeftMenuGroupId } from '../types';

interface LeftMenuRootContextValue {
  openGroupId?: LeftMenuGroupId;
  openGroup: (groupId: LeftMenuGroupId) => void;
  toggleGroup: (groupId: LeftMenuGroupId) => void;
  closeAllGroups: () => void;
}

const LeftMenuRootContext = createContext<LeftMenuRootContextValue>({
  openGroupId: undefined,
  openGroup: () => undefined,
  toggleGroup: () => undefined,
  closeAllGroups: () => undefined,
});

export const LeftMenuRootContextProvider: FC = ({ children }) => {
  const [openGroupId, setOpenGroupId] = useState<LeftMenuGroupId>();

  const openGroup = (groupId: LeftMenuGroupId): void => setOpenGroupId(groupId);
  const toggleGroup = (groupId: LeftMenuGroupId): void =>
    setOpenGroupId((current) => (current === groupId ? undefined : groupId));
  const closeAllGroups = (): void => setOpenGroupId(undefined);

  const value: LeftMenuRootContextValue = {
    openGroupId,
    openGroup,
    toggleGroup,
    closeAllGroups,
  };

  return <LeftMenuRootContext.Provider value={value}>{children}</LeftMenuRootContext.Provider>;
};

export const useLeftMenuRootContext = (): LeftMenuRootContextValue =>
  useContext(LeftMenuRootContext);
