import React, { FC, ReactElement } from 'react';
import { ArrayHelpers, Field, FieldArray, FieldProps, FormikProps } from 'formik';
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import { CustomerGroup, IFormData } from '../../../types';
import { _ } from '../../../../../util/translate';
import RadioCircleButton from '../../../../../atoms/RadioCircleButton/RadioCircleButton';
import { Box } from '@material-ui/core';

// type CustomerGroup = 'allCustomers' | 'newCustomers' | 'regularCustomers' | 'activeCustomers' | 'noCreditCustomers'; // prettier-ignore
const TARGET_GROUPS: CustomerGroup[] = [
  'allCustomers',
  'newCustomers',
  // 'regularCustomers',
  // 'activeCustomers',
  // 'noCreditCustomers',
];

const TargetGroups: FC<Pick<FormikProps<IFormData>, 'values' | 'setFieldValue'>> = ({
  values,
  setFieldValue,
}) => {
  return (
    <FieldLayout label={_('benefits:voucher.texts.targetGroups')}>
      <FieldArray
        name="targetGroups"
        render={(arrayHelpers: ArrayHelpers): ReactElement => {
          const handleTargetGroupsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { checked, value } = event.target;
            if (checked) {
              if (value === 'allCustomers') {
                // Uncheck all checkboxes
                setFieldValue('targetGroups', []);
              } else {
                // Uncheck `allCustomers` checkbox
                // prettier-ignore
                setFieldValue('targetGroups', values.targetGroups.filter(group => group !== 'allCustomers'));
              }
              // Check checked checkbox
              arrayHelpers.push(value);
            } else {
              if (
                (value === 'newCustomers' ||
                  value === 'regularCustomers' ||
                  value === 'activeCustomers' ||
                  value === 'noCreditCustomers') &&
                values.targetGroups.length > 1
              ) {
                arrayHelpers.remove(values.targetGroups.indexOf(value));
              }
            }
          };

          return (
            <Box display="flex" flexDirection="column" gridGap="20px" mt={1} pl={0.5}>
              {TARGET_GROUPS.map((item, i) => (
                <Field
                  key={item}
                  name={`targetGroups.${item}`}
                  render={({ field }: FieldProps): ReactElement => (
                    <RadioCircleButton
                      name={field.name}
                      value={item}
                      onChange={handleTargetGroupsChange}
                      onBlur={field.onBlur}
                      checked={values.targetGroups.includes(item)}
                    >
                      <span>{_(`benefits:voucher.targetGroups.detailsPage.${item}`)}</span>
                    </RadioCircleButton>
                  )}
                />
              ))}
            </Box>
          );
        }}
      />
    </FieldLayout>
  );
};

export default TargetGroups;
