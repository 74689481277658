import React, { FC, useState } from 'react';
import { _ } from '../../../../../util/translate';
import ModalPhoneRequestSteps from '../../PhoneSexInactive/ModalPhoneConfirmation/ModalPhoneRequestSteps';
import { InputField, Button } from '../../../../../atoms';
import { Service0900PhoneData } from '../../types';

interface Props {
  storeData: Service0900PhoneData;
  onSuccess: () => void;
}

const BA6EditNumber: FC<Props> = ({ storeData, onSuccess }) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = (): void => {
    setShowModal(false);
  };
  const openModal = (): void => {
    setShowModal(true);
  };

  return (
    <div css={{ flexDirection: 'column', marginTop: 64, marginBottom: 30 }}>
      <h2 css={{ padding: '6px 0px', fontSize: '16px' }}>
        {_('service0900:phoneSex.settings.targetNumberLabel')}
      </h2>

      <div>
        <InputField
          onClick={openModal}
          value={`${storeData?.targetNumber?.countryCallingCode}${storeData?.targetNumber?.phoneNumber}`}
          readOnly={true}
          css={{
            flex: 1,
            width: 256,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <Button
          onClick={openModal}
          theme="gray"
          label={_('common:button.edit')}
          css={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        />
      </div>
      {showModal && (
        <ModalPhoneRequestSteps
          storeData={storeData}
          closeModal={closeModal}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
};

export default BA6EditNumber;
