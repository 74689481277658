import React, { FC } from 'react';
import { MediaAlbum, MediaPicture } from '../../../graphql/VXModels/types';
import ProfilePictureContent from '../../../components/ProfilePicture/ProfilePictureContent';
import { ProfilePictureType } from '../../../components/ProfilePicture/ProfilePictureType';
import styled from '@emotion/styled';

interface Props {
  type: ProfilePictureType;
  album?: MediaAlbum;
  picture?: MediaPicture;
  loading?: boolean;
}

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const ProfilePicture: FC<Props> = ({ type, album, picture }) => {
  {
    return (
      <Container>
        <ProfilePictureContent
          type={type}
          album={album}
          picture={picture}
          css={{ height: '100%' }}
        />
      </Container>
    );
  }
};

export default ProfilePicture;
