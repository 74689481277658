import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { useUserData } from '../../../../../util/UserData';
import { ticket } from '../../../../../atoms/Icon/libraries/svg';

const BonusCodesItem: FC = () => {
  const userData = useUserData();
  const canCreateVisitXBonusCodes = !!userData?.model?.bonuscodes?.canCreateVisitXBonusCodes;
  return canCreateVisitXBonusCodes ? (
    <LeftMenuItem title={_('navigation:marketing.bonusCodes')} icon={ticket} uri="/vouchers" />
  ) : null;
};

export default BonusCodesItem;
