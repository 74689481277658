import React, { FC } from 'react';
// Outside Wizard
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import { DatePicker } from '../../../../../atoms';
import Select from '../../../../../atoms/Select/Select';
// Inside Wizard
import { IContentOthersProps } from '../../../types';
import { WHITE } from '../../../../../camtool-styles';
import useContent from '../useContent';
import Layout from '../Layout';
import { _ } from '../../../../../util/translate';

const ContentFreeShow: FC<IContentOthersProps> = ({
  state,
  dispatch,
  className,
  availableContentMinAmountsArray,
}) => {
  const [
    { contentData, redemptionType, redemptionAmount },
    { setRedemptionType, setRedemptionAmount, setContentData },
  ] = useContent('SHOW_TICKET', state, dispatch, new Date(Date.now() + 1000 * 60 * 60 * 24));

  const handleShowDate = (value: string) => setContentData(value);

  const handleRedemptionTypeChange = (type: string) => {
    if (type === 'GRATIS') {
      setRedemptionType(type);
      setRedemptionAmount(0);
    } else if (type === 'ON_TOP') {
      setRedemptionType(type);
      setRedemptionAmount(-1);
    } else {
      setRedemptionType('');
      setRedemptionAmount(-1);
    }
  };

  const handleRedemptionAmountChange = (amount: string) => {
    setRedemptionAmount(Number(amount));
  };

  return (
    <Layout
      className={className}
      title="Free-Show Ticket"
      subtitle="Lade Deine User zu einer Free-Show ein, halte dabei zwingend die vereinbarten Termine ein um den User nicht zu verärgern."
    >
      <FieldLayout
        label="Datum der nächsten Free-Show wählen"
        error=""
        css={{ marginBottom: 24, flex: 0 }}
      >
        <DatePicker
          name="freeShowDate"
          from={contentData}
          onFromChange={handleShowDate}
          single={true}
          showTime={true}
          disablePastDate={true}
          css={{ border: '1px solid #f2f2f2', borderRadius: 4 }}
        />
      </FieldLayout>

      <FieldLayout label="Gutscheinart" error="" css={{ marginBottom: 24, flex: 0 }}>
        <Select
          value={redemptionType}
          name="voucherType"
          placeholder={_('common:dropdown.placeholder.pleaseSelect')}
          options={[
            { label: _('benefits:voucher.texts.free'), value: 'GRATIS' },
            { label: _('benefits:voucher.texts.onTop'), value: 'ON_TOP' },
          ]}
          onChange={(event) => handleRedemptionTypeChange(event.target.value)}
        />
      </FieldLayout>

      {redemptionType === 'ON_TOP' && (
        <div css={{ alignItems: 'center', marginBottom: 24, flex: 0 }}>
          <span>Ab</span>
          <Select
            value={String(redemptionAmount)}
            name="redemptionAmount"
            css={{ maxWidth: 128, margin: 8 }}
            options={availableContentMinAmountsArray || []}
            onChange={(event) => handleRedemptionAmountChange(event.target.value)}
          />
          <span>{_('benefits:voucher.texts.userGetsOnTop')}</span>
          <span css={{ padding: '8px 16px', margin: 8, backgroundColor: WHITE }}>1 Free-Show</span>
          <span>gratis.</span>
        </div>
      )}
    </Layout>
  );
};

export default ContentFreeShow;
