import React, { FC, ReactNode } from 'react';
import { Box, createStyles, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface FieldsetProps {
  disabled?: boolean;
  form?: string;
  name?: string;
  color?: string;
  backgroundColor?: string;
  legend?: ReactNode;
  children?: ReactNode;
  noAccess?: boolean;
  margin?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: (props) => ({
      borderColor: props.color ? props.color : '',
      backgroundColor: props.backgroundColor ? props.backgroundColor : 'transparent',
      margin: props.margin ? props.margin : '0 0 24px',
    }),
    root: {
      display: 'block !important',
      padding: 12,
    },
    fieldsetLegend: (props: FieldsetProps) => ({
      display: 'block !important',
      color: props.color ? props.color : '',
      marginTop: -32,
    }),
    fieldsetInner: (props: FieldsetProps) => ({
      opacity: props.disabled ? 0.7 : 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
      },
    }),
  })
);

const Fieldset: FC<FieldsetProps> = ({ legend, children, ...props }) => {
  const { paper, root, fieldsetLegend, fieldsetInner } = useStyles(props);

  return (
    <Paper className={paper}>
      <Box className={root}>
        {legend && <Box className={fieldsetLegend}> {legend} </Box>}
        <Box display="flex" flexDirection="column" className={fieldsetInner}>
          {children}
        </Box>
      </Box>
    </Paper>
  );
};

export default Fieldset;
