/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Route, Switch } from 'react-router';
import { Query } from '@apollo/react-components';

import { stripSlash } from '../../../../util/urlHelper';
import { Spinner } from '../../../../components';
import AlbumViewProfileVX from '../../../../components/MediaManagement/ExplorerPicture/AlbumViewProfileVX/AlbumViewProfileVX';
import AlbumViewProfileLA from '../../../../components/MediaManagement/ExplorerPicture/AlbumViewProfileLA/AlbumViewProfileLA';
import AlbumViewProfileFL from '../../../../components/MediaManagement/ExplorerPicture/AlbumViewProfileFL/AlbumViewProfileFL';

import AlbumView from './AlbumView';
import AlbumEdit from './AlbumEdit';
import AlbumComment from './AlbumComment';
import AlbumSales from './AlbumSales';
import {
  QUERY_PHOTO_ALBUMS_BY_ID,
  QUERY_PHOTO_ALBUMS_BY_TYPE,
} from '../../../../graphql/VXModels/queries';
import { _ } from '../../../../util/translate';

const URL_EDIT = '/edit';
const URL_COMMENTS = '/comment';
const URL_SALES = '/sales';

const umaIdToMediaType = (umaId) => {
  switch (umaId) {
    case '_sedcardVX12':
      return 'profile12';
    case '_sedcardVX16':
      return 'profile16';
    case '_sedcardVX18':
      return 'profile18';
    case '_sedcardFL':
      return 'flirtlife';
    case '_sedcardLA':
      return 'lustagenten';
    default:
      return '';
  }
};

const View = memo(({ match, setCurrentAlbumType }) => {
  const path = stripSlash(match.url);

  const umaId = Number(match.params.umaId);

  if (isNaN(umaId)) {
    return (
      <Query
        query={QUERY_PHOTO_ALBUMS_BY_TYPE}
        variables={{ type: umaIdToMediaType(match.params.umaId) }}
      >
        {({ loading, data, error, refetch }) => {
          if (loading) return <Spinner />;
          if (error) return <p>{error}</p>;

          if (typeof setCurrentAlbumType === 'function') setCurrentAlbumType('profile');

          const album = data.model.photoAlbums.albums[0];

          switch (umaIdToMediaType(match.params.umaId)) {
            case 'profile12':
            case 'profile16':
            case 'profile18':
              return (
                <AlbumViewProfileVX
                  album={album}
                  refetchAlbum={refetch}
                  isLoading={loading}
                  match={match}
                />
              );
            case 'lustagenten':
              return (
                <AlbumViewProfileLA
                  album={album}
                  refetchAlbum={refetch}
                  isLoading={loading}
                  match={match}
                />
              );
            case 'flirtlife':
              return (
                <AlbumViewProfileFL
                  album={album}
                  refetchAlbum={refetch}
                  isLoading={loading}
                  match={match}
                />
              );
          }
        }}
      </Query>
    );
  } else
    return (
      <Query query={QUERY_PHOTO_ALBUMS_BY_ID} variables={{ umaId }}>
        {({ loading, data, error, refetch }) => {
          if (loading) return <Spinner />;
          if (error) return console.error(error);

          const album =
            data.model && data.model.photoAlbums ? data.model.photoAlbums.albums[0] : null;
          return (
            <Switch>
              <Route
                exact
                path={path}
                component={(props) => (
                  <AlbumView
                    setCurrentAlbumType={setCurrentAlbumType}
                    album={album}
                    refetchAlbum={refetch}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path={path + URL_EDIT}
                component={(props) => (
                  <AlbumEdit album={album} editable={true} {...props} refetchAlbum={refetch} />
                )}
              />
              <Route
                exact
                path={path + URL_COMMENTS}
                component={(props) => <AlbumComment album={album} editable={true} {...props} />}
              />
              <Route
                exact
                path={path + URL_SALES}
                component={(props) => <AlbumSales album={album} editable={true} {...props} />}
              />
            </Switch>
          );
        }}
      </Query>
    );
});

export function extractTitle(albumType, titles) {
  const albumTypeLabel = _('mediamanagement:picfolder.title.' + albumType);
  if (titles.hasOwnProperty('_langs')) {
    for (let i = 0; i < titles._langs.length; i++) {
      if (titles[titles._langs[i]]) {
        return albumTypeLabel + ': ' + titles[titles._langs[i]];
      }
    }
  } else {
    let title = titles[0]
      ? titles[0]
        ? ': ' + titles[0].text
        : ''
      : ': ' + Object.values(titles)[0];
    return albumTypeLabel + title;
  }

  return albumTypeLabel;
}

View.displayName = 'View';
export default View;
