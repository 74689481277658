import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GRAY_2 } from '../../../../../../camtool-styles';

interface Props {
  title: string;
  subtitle: string;
}

const Container = styled.div`
  flex-direction: column;
  letter-spacing: normal;
  margin-top: 9px;
  user-select: none;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const Subtitle = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: ${GRAY_2};
  margin-top: -1px;
`;

const ModalBonusDeactivateLabel: FC<Props> = ({ title, subtitle }) => (
  <Container>
    <Title>{title}</Title>
    <Subtitle>({subtitle})</Subtitle>
  </Container>
);

export default ModalBonusDeactivateLabel;
