import { BLACK_1, BLUE, WHITE, WHITE_2, DARK_GRAY } from '../../../camtool-styles';
import styled from '@emotion/styled';

export const BREAKPOINT = '@media (max-width: 1430px)';

/** PhoneSexDescription */

interface ITelSexItem { theme?: string | undefined; children: any; } // prettier-ignore
export const TelSexItem = styled.div`
  background-color: ${({ theme }: ITelSexItem) => (theme === 'BLUE' ? BLUE : 'transparent')};
  color: ${({ theme }: ITelSexItem) => (theme === 'BLUE' ? WHITE : BLACK_1)};
  padding: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: ${({ theme }: ITelSexItem) => (theme === 'BLUE' ? 'none' : `1px solid #c0c0c0`)};
  border-radius: 24px;
  width: 165px;
  height: 108px;
  &:not(:first-of-type) {
    margin-top: 94px;
  }
  ${BREAKPOINT} {
    &:not(:first-of-type) {
      margin-top: 0;
      margin-left: 94px;
    }
  }
}
`;

export const TelSexItemContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  ${BREAKPOINT} {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  margin: 40px 0 64px 0;
  ${BREAKPOINT} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ArrowContainer = styled.div`
  align-self: center;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  color: #888;
  padding: 0 16px;
  ${BREAKPOINT} {
    flex-direction: row;
    height: 40px;
    align-items: center;
  }
`;

export const Label = styled.span`
  position: absolute;
  font-size: 16px;
  white-space: nowrap;
  ${BREAKPOINT} {
    top: -26px;
  }
`;

export const CodeIcon = styled.div`
  padding: 4px;
  border: 2px solid #c0c0c0;
  font-weight: bold;
  color: #888888;
  margin-bottom: 8px;
`;

export const Badge = styled.div`
  width: 82px;
  height: 82px;
  background: #000000;
  border-radius: 50%;
  color: #f2f2f2;
  text-align: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0.5px;
  position: absolute;
  right: -34px;
  top: 42px;
  ${BREAKPOINT} {
    top: -40px;
  }
`;

export const Service0900Item = styled.section`
  flex-direction: column;
  background-color: ${WHITE};
  width: 400px;
  padding: 32px;
  margin-top: 32px;
  margin-left: 32px;
  align-items: center;
  header {
    border-bottom: 2px solid ${WHITE_2};
    padding-bottom: 4px;
    min-width: 300px;
    justify-content: center;
  }
`;

export const RequestWrapper = styled.div`
  background-color: ${WHITE};
  flex-direction: column;
  padding: 40px 0;
  margin: 36px;
  height: auto;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  margin: 50px 0 50px 0;
  min-height: 238px;
  justify-content: center;
  align-items: center;
  color: ${DARK_GRAY};
`;
