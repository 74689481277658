import React from 'react';
import { Mutation, Query } from '@apollo/react-components';

import header from './banner-bg.jpg';
import {
  VXMN_QUERY_NOTIFICATIONS,
  NOTIFICATIONS_LIMIT,
  updateQueryAfterMarkRead,
  VXMN_MUTATION_NOTIFICATIONS_MARKREAD,
  optimisticMarkReadResponse,
} from '../../util/NotificationsHelper';
import Footer from '../../components/Footer/Footer';
import NotificationContent from '../../components/NewsCenter/NotificationContent';
import { EmptyContent, Spinner, T } from '../../components';
import { MessageContainer } from './styles';
import { _ } from '../../util/translate';
import Navigation from './Navigation';
import { useVXServicesNotificationsClient } from '../../graphql';
import { useLang } from '../../util/AppState';

const buildNewsCenterNotificationVariables = (after, lang) => {
  return {
    lang,
    filter: { preset: 'NEWS_CENTER', sort: 'updated_at_desc' },
    count: NOTIFICATIONS_LIMIT,
    after,
  };
};

const NewsCenter = () => {
  const client = useVXServicesNotificationsClient();
  const [lang] = useLang();

  return (
    <main className="grid">
      <Navigation />

      <div className="grid__row">
        <div className="grid__column grid__box">
          <div
            className="--noselect"
            style={{
              display: 'block !important',
              width: '100%',
              height: 'auto',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <header
              style={{
                color: '#000',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h1 style={{ textTransform: 'uppercase', fontSize: '3.7vw', margin: 0 }}>
                <T _={'notifications:newscenter.banner.title'} />
                {/*News Center*/}
              </h1>
              <h1 style={{ fontSize: '1.15vw' }}>
                <T _={'notifications:newscenter.banner.subtitle'} />
              </h1>
            </header>
            <img style={{ width: '100%' }} src={header} alt="Postfach banner" />
          </div>

          <article
            style={{
              fontSize: 14,
              flexDirection: 'column',
              color: 'black',
              overflow: 'scroll',
            }}
          >
            {/*<div css={{ justifyContent: 'flex-end', alignItems: 'center', margin: '0 16px' }}>*/}
            {/*<div css={{ marginRight: 32, color: '#999' }}>*/}
            {/*<span className={cx('icon-sorting', css({ marginRight: 4 }))} />*/}
            {/*<p>NACH DATUM SORTIEREN</p>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<input*/}
            {/*css={{ border: '1px solid #ccc', padding: '4px 8px' }}*/}
            {/*type="text"*/}
            {/*placeholder="Suchen"*/}
            {/*/>*/}
            {/*</div>*/}
            {/*</div>*/}
            <Query
              client={client}
              query={VXMN_QUERY_NOTIFICATIONS}
              variables={buildNewsCenterNotificationVariables('', lang)}
            >
              {({ loading, error, data, fetchMore }) => {
                const edges = data?.vxmnQueryNotifications?.pagination?.edges ?? [];
                const items = edges.map((edge) => edge.node) ?? [];

                return (
                  <Mutation
                    client={client}
                    mutation={VXMN_MUTATION_NOTIFICATIONS_MARKREAD}
                    update={updateQueryAfterMarkRead(
                      'vxmnQueryNotifications',
                      VXMN_QUERY_NOTIFICATIONS,
                      buildNewsCenterNotificationVariables('', lang)
                    )}
                  >
                    {(markRead) => {
                      return (
                        <div
                          className="grid__box__item__content"
                          style={{ marginBottom: loading ? 0 : 25 }}
                        >
                          {loading && <Spinner size="s" />}
                          {error && (
                            <EmptyContent
                              icon="icon-bell"
                              title={_('dashboard:app.notification.emptyContent.title')}
                            />
                          )}
                          {items.length > 0 &&
                            items.map((item) => {
                              return (
                                <MessageContainer key={item.id}>
                                  <NotificationContent
                                    notification={{
                                      ...item,
                                      onMarkRead: item.readAt
                                        ? null
                                        : (data) => {
                                            markRead(data);
                                          },
                                    }}
                                    css={{
                                      width: '100%',
                                      borderRadius: 0,
                                      border: 'none',
                                    }}
                                  />
                                </MessageContainer>
                              );
                            })}
                          {!error && items.length === 0 && (
                            <EmptyContent
                              icon="icon-bell"
                              title={_('dashboard:app.notification.emptyContent.title')}
                            />
                          )}
                        </div>
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          </article>
        </div>
      </div>

      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </main>
  );
};

export default NewsCenter;
export { buildNewsCenterNotificationVariables };
