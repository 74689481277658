import React from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { Field, FieldProps } from 'formik';

import { TextEditor } from '../../../atoms';
//import { TextEditor } from '../../../atoms/TextEditorSlate';
import { ALIGNMENT_DATA_KEY } from '../../../atoms/TextEditor/utils/ExtendedRichUtils';
import { FONT_FAMILY } from '../../../atoms/TextEditor/styles';

const textfieldConfig = {
  blockStyleFn: (block: any) => {

    let style = {};

    if (block.getData().get(ALIGNMENT_DATA_KEY)) {
      style = { ...style, textAlign: block.getData().get(ALIGNMENT_DATA_KEY) };
    }

    switch (block.getType()) {
      case 'blockquote':
        style = {
          ...style,
          font: '14px/22px normal helvetica, sans-serif',
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 50,
          paddingLeft: 15,
          borderLeft: '3px solid #ccc',
        };
        break;
    }

    return { style };
  },
  entityStyleFn: (entity: any) => {
    const entityType = entity.get('type').toLowerCase();
    const data = entity.getData();

    switch (entityType) {
      case 'link':
        return {
          element: 'a',
          attributes: {
            href: data.url,
          },
          style: {
            color: '#1f93e9',
          },
        };
      case 'image':
        return {
          element: 'img',
          attributes: {
            src: data.src,
          },
          style: {
            maxWidth: 470,
          },
        };
    }

  },
  inlineStyleFn: (styles: any) => {
    const fontSize = styles.filter((value: string) => value.startsWith('SIZE:')).first();
    const font = styles.filter((value: string) => value.startsWith('FONT:')).first();
    let style = {};

    if (fontSize) {
      style = { ...style, fontSize: fontSize.replace('SIZE:', '') };
    }

    if (font) {
      style = { ...style, ...FONT_FAMILY[font] };
    }

    return { element: 'span', style };
  },
};

interface IProps {
  name: string;
  disabled?: boolean;
}

const FieldHTMLEditor = ({ name, disabled = false }: IProps) => (
  <Field
    name={name}
    render={({ field: { value, onChange } }: FieldProps) => (
      <TextEditor
        disabled={disabled}
        initialHTML={value}
        // readOnly={disabled} // TextEditorSlate
        // initialValue={value} // TextEditorSlate
        onBlur={editorState =>
          onChange({
            target: {
              name,
              value: stateToHTML(editorState.getCurrentContent(), textfieldConfig),
            },
          })
        }
        css={{ maxWidth: '100%', height: 300 }}
      />
    )}
  />
);

export default FieldHTMLEditor;
