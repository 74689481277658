import React, { FC } from 'react';
import { File, FileType } from '../../../../graphql/VXServicesTelegram/types';
import { AudioPlayer } from '../../../../components';
import { useHasRole } from '../../../../util/UserData';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { BREAKPOINT_PHONE_CONDITION, GRAY_1, YELLOW } from '../../../../camtool-styles';
import { Lottie } from '../../../../atoms';
import { Box, useMediaQuery } from '@material-ui/core';
import PaidContentStamp from './PaidContentStamp';
import paidAudioPlaceholder from './Assets/paidAudioPlaceholder.jpg';
import CustomVideoPlayer from './CustomVideoPlayer';

interface IProps extends File {
  direction: string;
  isPaidContent: boolean;
}

const Attachment: FC<IProps> = ({
  type,
  imgSrcSet,
  url,
  direction,
  previewPictureUrl,
  isPaidContent,
  ...rest
}) => {
  const isMobile = useMediaQuery(BREAKPOINT_PHONE_CONDITION);
  const isInternalAccount = useHasRole(SecurityRole.VX_USER_INTERNAL_ACCOUNT);

  switch (type) {
    case FileType.picture:
      return (
        <div css={{ zIndex: 2, marginBottom: 8, position: 'relative' }}>
          {isPaidContent && <PaidContentStamp />}
          <img
            srcSet={imgSrcSet}
            src={url}
            css={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </div>
      );

    case FileType.video:
      return (
        <div
          css={{
            zIndex: 2,
            marginBottom: 8,
            position: 'relative',
            ...(isMobile ? { minWidth: '200px' } : { minWidth: '400px' }),
          }}
        >
          {isPaidContent && <PaidContentStamp />}
          <CustomVideoPlayer url={url} previewPictureUrl={previewPictureUrl} isMobile={isMobile} />
        </div>
      );
    case FileType.audio:
      if (url) {
        return (
          <>
            {isPaidContent && (
              <Box position="relative">
                <img
                  src={paidAudioPlaceholder}
                  css={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    marginBottom: '10px',
                  }}
                />
                <PaidContentStamp />
              </Box>
            )}
            <div
              css={{ zIndex: 2, marginBottom: 8, ...(!isMobile ? { minWidth: '250px' } : null) }}
            >
              <AudioPlayer url={url} direction={direction} />
            </div>
          </>
        );
      } else {
        return false;
      }
    case FileType.sticker:
      return (
        <div css={{ zIndex: 2, marginBottom: 8 }}>
          <Lottie width={160} height={160} path={url} />
        </div>
      );
    case FileType.undefined:
    default:
      return isInternalAccount ? (
        <div css={{ flexDirection: 'column', backgroundColor: YELLOW, padding: 8 }}>
          <h3>Unsupported Attachment Type: {type}</h3>
          <pre>{JSON.stringify({ type, imgSrcSet, url, ...rest }, null, 2)}</pre>
          <div css={{ color: GRAY_1, fontSize: '0.8em' }}>
            This debug information is displayed to internal users only.
          </div>
        </div>
      ) : null;
  }
};

export default Attachment;
