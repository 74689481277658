import React, { FC, ReactNode } from 'react';
import { Checkbox, CheckboxProps, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { GRAY_2, GRAY_5 } from '../../../camtool-styles';
import { CustomColor } from './types';
import { getPaletteColor } from './utils';

interface MUICheckboxProps extends Omit<CheckboxProps, 'color' | 'size'> {
  color?: 'default' | 'primary' | 'secondary' | CustomColor;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  coloredLabel?: boolean;
  label?: string | ReactNode;
  size?: 'small' | 'medium' | 'large';
  icon?: string | ReactNode;
  checkedIcon?: string | ReactNode;
}

const useStyles = makeStyles<
  Theme,
  Pick<
    MUICheckboxProps,
    'color' | 'size' | 'checked' | 'indeterminate' | 'disabled' | 'coloredLabel'
  >
>((theme: Theme) =>
  createStyles({
    root: ({ indeterminate, checked, color, coloredLabel }) => {
      const paletteColor = getPaletteColor(theme, color);
      return { color: !indeterminate && !checked && coloredLabel ? paletteColor?.main : GRAY_2 };
    },
    checked: ({ color }) => {
      const paletteColor = getPaletteColor(theme, color);
      return { color: color === 'default' ? GRAY_2 : paletteColor?.main };
    },
    svgSize: ({ size }) => ({
      fontSize: size === 'medium' ? '1.25em' : size === 'small' ? '1em' : '1.5em',
      'input:disabled ~ &': {
        color: GRAY_5,
      },
    }),
  })
);

const MUICheckbox: FC<MUICheckboxProps> = ({
  color,
  checked,
  disabled = false,
  indeterminate = false,
  coloredLabel = false,
  size = 'medium',
  icon = undefined,
  checkedIcon = undefined,
  ...props
}) => {
  const { svgSize, ...classes } = useStyles({
    color,
    size,
    checked,
    indeterminate,
    disabled,
    coloredLabel,
  });

  return (
    <Checkbox
      {...props}
      checked={checked}
      disabled={disabled}
      indeterminate={indeterminate}
      classes={classes}
      icon={icon ? icon : <CheckBoxOutlineBlankIcon className={svgSize} />}
      checkedIcon={checkedIcon ? checkedIcon : <CheckBoxIcon className={svgSize} />}
      indeterminateIcon={<IndeterminateCheckBoxIcon className={svgSize} />}
      color={
        color && ['warning', 'error'].includes(color)
          ? 'default'
          : color
          ? (color as CheckboxProps['color'])
          : 'primary'
      }
    />
  );
};

export default MUICheckbox;
