import React, { FC } from 'react';
import { Fieldset } from '../../../../../atoms/Fieldset';
import FieldCheckbox from '../../../../../molecules/Formik/Fields/FieldCheckbox';
import { FieldNumberInput } from '../../../../../molecules/Formik/Fields';
import { RED } from '../../../../../camtool-styles';
import { ErrorMessage } from 'formik';
import { FormValues } from '../FormWrapper';
import { _ } from '../../../../../util/translate';
import InputAdornmentIcon from '../../../../../atoms/InputAdornmentIcon/InputAdornmentIcon';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { PAID_CONTENT_PRICE_MAX, PAID_CONTENT_PRICE_MIN } from '../FormInstantMessenger';
import { makeStyles, createStyles } from '@material-ui/core';
import useTelegramMaster from '../../../../Telegram/hooks/useTelegramMaster';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      textAlign: 'center',
      padding: 0,
    },
    // fix weird border in front of center of endAdornment either via <fieldset> with notchedOutline class or via adornedEnd class with paddingRight...
    notchedOutline: {
      right: '-6px',
    },
  })
);
interface PaidContentProps {
  editable: boolean;
  values: FormValues;
}

const PaidContent: FC<PaidContentProps> = ({ editable, values: { isPaidContent, attachment } }) => {
  const classes = useStyles();
  const { data } = useTelegramMaster();
  const currency = data?.master?.currency;

  return (
    <Fieldset
      disabled={!editable || !attachment || !isPaidContent}
      css={{ marginBottom: 24 }}
      legend={
        <FieldCheckbox
          name={'isPaidContent'}
          disabled={!editable || !attachment}
          label={_(
            !editable || !attachment
              ? 'mailings:form.isPaidContent.label.disabled'
              : 'mailings:form.isPaidContent.label.enabled'
          )}
        />
      }
    >
      <div css={{ flexDirection: 'column' }}>
        <span css={{ marginBottom: '8px' }}>
          {_('mailings:form.isPaidContent.label.description')}
        </span>
        <FieldNumberInput
          id={'price'}
          label={_('mailings:form.price.label')}
          name={'price'}
          disabled={!editable || !isPaidContent || !attachment}
          required={true}
          style={{ maxWidth: 80 }}
          inputProps={{
            min: PAID_CONTENT_PRICE_MIN,
            max: PAID_CONTENT_PRICE_MAX,
            step: 0.01,
            pattern: '^d+(?:[.|,]d{1,2})?$',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornmentIcon>
                {currency === 'USD' ? <AttachMoneyIcon /> : <EuroSymbolIcon />}
              </InputAdornmentIcon>
            ),
            classes: {
              notchedOutline: classes.notchedOutline,
              input: classes.input,
            },
          }}
        >
          <ErrorMessage name={'price'} component="span" css={{ color: RED }} />
        </FieldNumberInput>
      </div>
    </Fieldset>
  );
};

export default PaidContent;
