import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cc from 'classcat';

import AbstractFormComponent from '../../AbstractFormComponent';
import SeverUtils from '../../../../util/ServerUtils';

const LOOKUP_DELAY = 200;

class InputfieldAutocomplete extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timeout = null;

    this.state = {
      isLoading: false,
      selected: -1,
      data: [],
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onEntryClick = this.onEntryClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.deleteList = this.deleteList.bind(this);
    this.updateList = this.updateList.bind(this);
    this.updateSelectionByCursor = this.updateSelectionByCursor.bind(this);
    this.updateSelectionByMouse = this.updateSelectionByMouse.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this.refs.autocomplete);
    this.currentValue = this.node.value;
    this.node.addEventListener('keypress', this.handleKeyPress, false);
    this.node.addEventListener('keyup', this.handleKeyPress, false);
  }

  componentWillUnmount() {
    this.node = ReactDOM.findDOMNode(this.refs.autocomplete);
    // console.log(this.__proto__.constructor.name, 'componentWillUnmount', this.node);
    const removeEvent = this.node.removeEventListener || this.node.detachEvent;

    removeEvent('keypress', this.handleKeyPress);
    removeEvent('keyup', this.handleKeyPress);
  }

  handleKeyPress = event => {
    const nextValue = this.node.value;
    if (document.activeElement === this.node) {
      const keyCode = event.keyCode;
      if (keyCode === 13) {
        // prevent submit on enter
        event.preventDefault();
        this.onSubmit(nextValue);
      } else if (keyCode === 27) {
        // close list on ESC
        this.deleteList();
      } else if (keyCode === 38 || keyCode === 40) {
        // update selection on up/down
        this.updateSelectionByCursor(keyCode);
      } else {
        if (nextValue.length >= 3) {
          this.updateList(nextValue);
        } else {
          this.deleteList();
        }
      }
    }
  };

  onSubmit = nextValue => {
    if (nextValue) {
      this.node.value = '';
      this.deleteList();
      this.props.onSelect(nextValue);
    }
  };

  onEntryClick = ev => {
    ev.stopPropagation();
    const idx = ev.target.value;
    this.onSubmit(this.state.data[idx].value);
  };

  onChange = ev => {
    ev.stopPropagation();
    this.props.onChange(ev.target.value);
  };

  deleteList = () => {
    this.setState({ data: [] });
  };

  isSelected = idx => {
    return this.state.selected === idx;
  };

  updateList = nextValue => {
    if (this.currentValue !== nextValue) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.currentValue = nextValue;
        this.fetchData(nextValue);
      }, LOOKUP_DELAY);
    }
  };

  updateSelectionByCursor = keyCode => {
    const updateNode = () => {
      this.currentValue = this.state.data[this.state.selected].value;
      this.node.value = this.currentValue.toString();
    };

    if (keyCode === 40 && this.state.selected < this.state.data.length - 1) {
      this.setState({ selected: this.state.selected + 1 }, updateNode);
    }
    if (keyCode === 38 && this.state.selected > 0) {
      this.setState({ selected: this.state.selected - 1 }, updateNode);
    }
  };

  updateSelectionByMouse = ev => {
    const idx = ev.target.value;
    this.currentValue = this.state.data[idx].value;
    this.node.value = this.currentValue.toString();
    this.setState({ selected: idx });
  };

  fetchData = search => {
    this.setState({ isLoading: true, data: [], selected: -1 });
    SeverUtils.request(
      'GET',
      this.props.autocompleteUrl,
      { routeParams: { search: search } },
      response => {
        this.setState({ isLoading: false, data: response });
      }
    );
  };

  render() {
    const { placeholder, type, name, disabled, readOnly, checked } = this.props;
    const { isLoading, data } = this.state;
    const displayList = data.length > 0;

    return (
      <div className="input">
        <div className="input__box">
          <input
            ref="autocomplete"
            {...{ placeholder, type, name, disabled, readOnly, checked }}
            onChange={this.onChange}
            className={cc({ loading: isLoading })}
            aria-autocomplete="list"
          />
          {displayList && (
            <ul className="input__example-list">
              {data.map((entry, idx) => (
                <li
                  className={cc('input__example-list__item', { active: this.isSelected(idx) })}
                  key={idx}
                  value={idx}
                  aria-selected={this.isSelected(idx)}
                  onClick={this.onEntryClick}
                  onMouseOver={this.updateSelectionByMouse}
                >
                  {entry.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

InputfieldAutocomplete.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  checked: PropTypes.bool,
  type: PropTypes.string,
  autocompleteUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
};

InputfieldAutocomplete.defaultProps = Object.assign(AbstractFormComponent.defaultProps, {
  placeholder: '',
  disabled: false,
  readOnly: false,
  type: 'text',
  name: '',
  checked: false,
  onChange: () => {},
  onSelect: () => {},
});

export default InputfieldAutocomplete;
export { InputfieldAutocomplete };
