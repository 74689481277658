import React from 'react';
import AbstractProfile from '../../AbstractProfile';
import { PROFILE_LA } from '../../../../stores/Profile/Profile';
import { SessionStore } from '../../../../stores/Session/Session';

import NavSubLA from '../NavSubLA';
import {
  Button,
  ButtonGroup,
  Footer,
  Form,
  GroupRow,
  Inputfield,
  MultiLangTextareafield,
  Separator,
  Spinner,
  T,
} from '../../../../components';

import { APP_BASE_PATH } from '../../../../util/env';
import { _ } from '../../../../util/translate';

export default class Common extends AbstractProfile {
  getResource() {
    return PROFILE_LA;
  }

  componentWillMount() {
    if (!SessionStore.get().profile.isLAProfileActive) {
      this.props.history.push(`${APP_BASE_PATH}/onlineprofile/lustagenten/settings`);
    }
    super.componentWillMount();
  }

  render() {
    super.render();
    this.spokenLangs = this.storeValues.profileLanguages;
    this.germanLangOnly = ['de'];

    return (
      <main id="la-profile" className="la-profile grid">
        <NavSubLA />

        <section className="grid__row">
          <section className="grid__column grid__box min-width--0">
            <header className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'profiles:la.profile.pagetitle.label'} />
              </div>
            </header>

            <Form
              className="grid__box__item spinner-container"
              ref="form"
              onValidSubmit={this.submitForm}
              onInvalidSubmit={this.showErrorFields}
              onValid={this.onValid}
              onInvalid={this.onInvalid}
              onChange={this.onChange}
            >
              {this.state.isLoading && <Spinner />}

              <div className="grid__box__item__content la-profile__formwidth">
                <GroupRow
                  id="flirtName"
                  label={`${_('profiles:la.nickname.label')}:`}
                  tooltipText={_('profiles:la.nickname.tooltipText')}
                  tooltipPosition="right"
                  className={this.getPropertyErrorMessage('laNickname') !== '' ? 'error' : ''}
                  errorMessage={this.getPropertyErrorMessage('laNickname')}
                >
                  <Inputfield
                    required
                    requiredMessage={_('profiles:laNickname.requiredMessage')}
                    validations="isAlphanumericOrDash,maxLength:31,minLength:4"
                    validationError={_('profiles:la.nickname.validationError')}
                    validationErrors={{
                      isAlphanumeric: _('common:validationMessage.isAlphanumeric'),
                      minLength: _('common:validationMessage.minLength4'),
                    }}
                    displayErrors={this.state.displayErrors}
                    value={this.storeValues.laNickname}
                    name="laNickname"
                    placeholder={_('profiles:la.nickname.label')}
                    type="text"
                    minLength="4"
                    readOnly={
                      this.storeValues.laNickname !== '' &&
                      this.storeValues.laNicknameStatus !== 'rejected'
                    }
                    onChange={this.changeNickname}
                  />
                </GroupRow>

                <GroupRow
                  id="LAPreferences"
                  label={`${_('profiles:laPreferences.label')}:`}
                  tooltipText={_('profiles:laPreferences.tooltipText')}
                  tooltipPosition="right"
                  className={this.getTextErrorMessage('laPreferences') !== '' ? 'error' : ''}
                  errorMessage={this.getTextErrorMessage('laPreferences')}
                >
                  <MultiLangTextareafield
                    required
                    requiredMessage={_('profiles:laPreferences.requiredMessage')}
                    validations="isValidMultiLangField"
                    validationError={_('common:validationMessage.isValidMultiLangField')}
                    displayErrors={this.state.displayErrors}
                    name="laPreferences"
                    value={this.storeValues.laPreferences.texts}
                    placeholder={_('profiles:laPreferences.placeholder')}
                    langs={this.germanLangOnly}
                    maxLength={1000}
                  />
                </GroupRow>

                <GroupRow
                  id="perfectDate"
                  label={`${_('profiles:la.perfectDate.label')}:`}
                  tooltipText={_('profiles:la.perfectDate.tooltipText')}
                  tooltipPosition="right"
                  className={this.getTextErrorMessage('laPerfectDate') !== '' ? 'error' : ''}
                  errorMessage={this.getTextErrorMessage('laPerfectDate')}
                >
                  <MultiLangTextareafield
                    required
                    requiredMessage={_('profiles:laPerfectDate.requiredMessage')}
                    validations="isValidMultiLangField"
                    validationError={_('common:validationMessage.isValidMultiLangField')}
                    displayErrors={this.state.displayErrors}
                    name="laPerfectDate"
                    value={this.storeValues.laPerfectDate.texts}
                    placeholder={_('profiles:la.perfectDate.placeholder')}
                    langs={this.germanLangOnly}
                    maxLength={1000}
                  />
                </GroupRow>

                <GroupRow
                  id="LAsexualExperience"
                  label={`${_('profiles:la.sexualExperiences.label')}:`}
                  tooltipText={_('profiles:la.sexualExperiences.tooltipText')}
                  tooltipPosition="right"
                  className={this.getTextErrorMessage('laSexualExperiences') !== '' ? 'error' : ''}
                  errorMessage={this.getTextErrorMessage('laSexualExperiences')}
                >
                  <MultiLangTextareafield
                    required
                    requiredMessage={_('profiles:laSexualExperiences.requiredMessage')}
                    validations="isValidMultiLangField"
                    validationError={_('common:validationMessage.isValidMultiLangField')}
                    displayErrors={this.state.displayErrors}
                    name="laSexualExperiences"
                    value={this.storeValues.laSexualExperiences.texts}
                    placeholder={_('profiles:la.sexualExperiences.placeholder')}
                    langs={this.germanLangOnly}
                    maxLength={1000}
                  />
                </GroupRow>

                <GroupRow tooltip="none">
                  <Separator className="transparent" />
                </GroupRow>

                <ButtonGroup position="right">
                  <Button
                    label={_('common:button.cancel')}
                    onClick={this.rejectChanges}
                    type="button"
                    disabled={!this.activateResetButton}
                  />
                  <Button className="button--blue" label={_('common:button.save')} type="submit" />
                </ButtonGroup>
              </div>
            </Form>
          </section>
        </section>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}
