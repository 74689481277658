import React from 'react';
import { Field, FieldProps } from 'formik';
import { RadioButton } from '../../../atoms';

interface IOptionNode {
  label: string;
  value: string;
}

interface IProps {
  name: string;
  disabled: boolean;
  options: IOptionNode[];
  render: (text: string) => JSX.Element;
}

const FieldRadioButtonGroup = ({ name, options, disabled, render }: IProps) => {
  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps) => (
        <div>
          {options.map((option, idx) => (
            <RadioButton
              key={idx}
              name={name}
              label={option.label}
              onChange={e => form.setFieldValue(name, option.value)}
              render={render}
              disabled={disabled}
              checked={field.value === option.value}
              css={{ marginLeft: idx > 0 ? 16 : 0 }}
            />
          ))}
        </div>
      )}
    />
  );
};

export default FieldRadioButtonGroup;
