/* eslint-disable   react/prop-types*/
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Query } from '@apollo/react-components';

import Spinner from '../../../../components/Spinner/Spinner';
import { stripSlash } from '../../../../util/urlHelper';
import { EmptyContent, LoadMoreButton, T } from '../../../../components';
import VideoListItem from '../../../../components/MediaManagement/ExplorerVideo/OverviewItem/VideoListItem';
import { QUERY_MODEL_VIDEOS_ALBUMS_BY_TYPE } from '../../../../graphql/VXModels/queries';

import { JsUploaderVideoStore } from '../../../../stores/JsUploader/JsUploader';
import { MediaManagementStore } from '../../../../stores/MediaManagement/MediaManagement';
import * as ActionTypes from '../../../../util/constants/ActionTypes';
import { _ } from '../../../../util/translate';
import Select from '../../../../atoms/Select/Select';
import styled from '@emotion/styled';
import {
  BREAKPOINT_TABLET_CONDITION,
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_SPHONE_CONDITION,
} from '../../../../camtool-styles';
import { StateEnum } from '../../../../packages/UploadManager/types';
import { useOnUploadFinished } from '../../../../packages/UploadManager/hooks/useFilteredQueue';

const TableHeader = ({ children }) => (
  <MainTableHeader
    className={'videoheaderbox'}
    css={{
      webkitFlex: '1 1 100%',
      flex: '1 1 100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
    }}
  >
    {children}
  </MainTableHeader>
);

const MainTableHeader = styled.div`
  @media ${BREAKPOINT_SPHONE_CONDITION} {
    -webkit-flex: 0 0 auto !important;
    flex: 0 0 auto !important;
    margin: 16px 0 !important;
  }
`;

const TableHeaderItemStyled = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -webkit-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    margin: 5px 0;
  }
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -webkit-flex: 1 1 50% !important;
    flex: 1 1 50% !important;
    justify-content: center !important;
  }
  @media ${BREAKPOINT_SPHONE_CONDITION} {
    -webkit-flex: 1 1 100% !important;
    flex: 1 1 100% !important;
    margin: 16px 0 !important;
  }
`;

const LabelTextFilter = styled.span`
  @media ${BREAKPOINT_SPHONE_CONDITION} {
    -webkit-flex: 1 1 100% !important;
    flex: 1 1 100% !important;
  }
`;

// eslint-disable-next-line react/display-name
const TableHeaderItem = memo(({ name, children, sorter, sortField, sortDirection }) => {
  const handleClick = () => {
    if (typeof sorter === 'function') {
      sorter(name);
    }
  };

  return (
    <TableHeaderItemStyled
      className={`${name} ${name === sortField ? sortDirection : ''} ${sorter ? 'sortable' : ''}`}
      onClick={handleClick}
    >
      <span>{children}</span>
      {sorter && <span className="sorter" />}
    </TableHeaderItemStyled>
  );
});

// eslint-disable-next-line react/display-name
const VideoListTableHeader = memo(({ collection, sorter, sortField, sortDirection }) => {
  const showShopHeader = collection === 'shop';

  return (
    <TableHeader>
      <TableHeaderItem name={'preview'} sortField={sortField} sortDirection={sortDirection}>
        <T _={'mediamanagement:video.content.preview'} />
      </TableHeaderItem>

      <TableHeaderItem
        name={'status'}
        sortField={sortField}
        sortDirection={sortDirection}
        sorter={sorter}
      >
        <T _={'mediamanagement:video.content.status'} />
      </TableHeaderItem>

      <TableHeaderItem
        name={'type'}
        sortField={sortField}
        sortDirection={sortDirection}
        sorter={sorter}
      >
        <T _={'mediamanagement:video.content.type'} />
      </TableHeaderItem>

      <TableHeaderItem
        name={'name'}
        sortField={sortField}
        sortDirection={sortDirection}
        sorter={sorter}
      >
        <T _={'mediamanagement:video.content.name'} />
      </TableHeaderItem>

      <TableHeaderItem
        name={'uploaded'}
        sortField={sortField}
        sortDirection={sortDirection}
        sorter={sorter}
      >
        <T _={'mediamanagement:video.content.uploaded'} />
      </TableHeaderItem>

      <TableHeaderItem
        name={'rating'}
        sortField={sortField}
        sortDirection={sortDirection}
        sorter={sorter}
      >
        <T _={'mediamanagement:video.content.ratings'} />
      </TableHeaderItem>

      {showShopHeader && (
        <TableHeaderItem
          name={'sales'}
          sortField={sortField}
          sortDirection={sortDirection}
          sorter={sorter}
        >
          <T _={'mediamanagement:video.content.sales'} />
        </TableHeaderItem>
      )}

      {showShopHeader && (
        <TableHeaderItem
          name={'price'}
          sortField={sortField}
          sortDirection={sortDirection}
          sorter={sorter}
        >
          <T _={'mediamanagement:video.content.price'} />
        </TableHeaderItem>
      )}

      {showShopHeader && (
        <TableHeaderItem
          name={'discount'}
          sortField={sortField}
          sortDirection={sortDirection}
          sorter={sorter}
        >
          <T _={'common:text.discount'} />
        </TableHeaderItem>
      )}
    </TableHeader>
  );
});

// eslint-disable-next-line react/display-name
const VideoListTableBody = memo(
  ({ collection, data, loading, loadMore, path, refetch, loadAll }) => {
    const { entries, totalEntries } = data;

    /**
     * we refetch the list if:
     * 1. a queue item has finished uploading and the BE also confirmed this (once)
     * 2. there are items in the video list which are being transcoded (repeated)
     */

    const refetchVideoList = useCallback(async () => {
      refetch();
    }, []);
    useOnUploadFinished(refetchVideoList);

    const isTranscoding = entries.some((entry) => entry.video.status === StateEnum.transcoding);
    useEffect(() => {
      if (isTranscoding) {
        const interval = setInterval(() => {
          refetch();
        }, 30000);

        return () => {
          clearInterval(interval);
        };
      }

      return () => {};
    }, [isTranscoding]);

    // useEffect(() => {
    //   if (collection === 'all' || collection === 'pool') {
    //     JsUploaderVideoStore.jsUploader.addListener('complete', refetch);
    //     MediaManagementStore.addServerEventListener(
    //       ActionTypes.MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA,
    //       refetch
    //     );
    //   }
    //   return () => {
    //     if (collection === 'all' || collection === 'pool') {
    //       JsUploaderVideoStore.jsUploader.removeListener('complete', refetch);
    //       MediaManagementStore.removeServerEventListener(
    //         ActionTypes.MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA,
    //         refetch
    //       );
    //     }
    //   };
    // }, []);

    return (
      <Fragment>
        {entries && (
          <Fragment>
            {entries.map((album) => (
              <VideoListItem key={album.id} data={album} collection={collection} path={path} />
            ))}

            <div css={{ justifyContent: 'center', marginTop: 32 }}>
              <LoadMoreButton
                css={{ flex: '0 0 auto' }}
                loadedEntries={entries.length}
                totalEntries={totalEntries}
                onClickLoadMore={loadMore}
              />

              {loadAll && (
                <LoadMoreButton
                  css={{ flex: '0 0 auto', marginLeft: 16 }}
                  loadedEntries={entries.length}
                  totalEntries={totalEntries}
                  onClickLoadMore={loadAll}
                  icon="icon-sort"
                  label={_('mediamanagement:video.list.loadAllVideos')}
                />
              )}
            </div>

            {entries.length === 0 && (
              <EmptyContent
                icon="icon-folder-open"
                title={_('mediamanagement:video.common.noVideosAvailable')}
                subtitle={_('mediamanagement:video.common.noVideosAvailableSubtitle')}
              />
            )}
          </Fragment>
        )}
        {loading && <Spinner />}
      </Fragment>
    );
  }
);

const getImportedFilterFromOptionValue = (value) => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'undefined':
    default:
      return undefined;
  }
};

const VideosList = memo(({ collection, match }) => {
  const [sortField, setSortField] = useState('uploaded'); // status | type | name | uploaded | rating | id
  const [sortDirection, setSortDirection] = useState('desc'); // asc | desc
  const [importedFilter, setImportedFilter] = useState(undefined); // undefined | true | false
  const [{ offset, limit }, setOffsetLimit] = useState({ offset: 0, limit: 20 });

  const sorter = (newSortField) => {
    if (newSortField === sortField) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setSortField(newSortField);
    }
  };

  const handleFilterChange = (event) =>
    setImportedFilter(getImportedFilterFromOptionValue(event.target.value));

  const videosFilterOptions = [
    {
      value: 'undefined',
      label: _('mediamanagement:video.filter.all'),
    },
    {
      value: 'false',
      label: _('mediamanagement:video.filter.uploaded'),
    },
    {
      value: 'true',
      label: _('mediamanagement:video.filter.imported'),
    },
  ];

  return (
    <div className="grid__box__column grid__box__sub">
      <div className="grid__box__sub__header">
        <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
          {_(`mediamanagement:video.type.${collection}`)}
        </div>
      </div>
      <div className="grid__box__sub__item">
        <Query
          query={QUERY_MODEL_VIDEOS_ALBUMS_BY_TYPE}
          fetchPolicy="cache-and-network"
          variables={{
            type: collection,
            offset,
            limit,
            sortField,
            sortDirection,
            imported: importedFilter,
          }}
        >
          {({ loading, data, error, fetchMore, refetch }) => {
            if (loading) {
              return <Spinner />;
            }
            if (error) {
              return console.error(error);
            }

            const { albums, albumsTotal } = data.model.videos;

            return (
              <div className={`video-content spinner-container grid__box__sub__item__content`}>
                <div css={{ justifyContent: 'flex-end', alignItems: 'center', marginBottom: 32 }}>
                  {(collection === 'shop' || collection === 'all') && (
                    <label
                      css={{
                        alignItems: 'center',
                        alignSelf: 'flex-end',
                        flexWrap: 'wrap',
                      }}
                    >
                      <LabelTextFilter css={{ marginRight: 8 }}>
                        <T _={'common:text.product'} />
                      </LabelTextFilter>

                      <Select
                        value={`${importedFilter}`}
                        onChange={(event) => handleFilterChange(event)}
                        options={videosFilterOptions}
                      />
                    </label>
                  )}
                </div>

                <VideoListTableHeader
                  collection={collection}
                  sorter={sorter}
                  sortField={sortField}
                  sortDirection={sortDirection}
                />
                <VideoListTableBody
                  collection={collection}
                  data={{ entries: albums, totalEntries: albumsTotal }}
                  loading={loading}
                  refetch={refetch}
                  loadMore={() =>
                    fetchMore({
                      variables: { offset: albums.length },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                          return prev;
                        }
                        return Object.assign({}, prev, {
                          model: {
                            ...prev.model,
                            videos: {
                              ...prev.model.videos,
                              albums: [
                                ...prev.model.videos.albums,
                                ...fetchMoreResult.model.videos.albums,
                              ],
                            },
                          },
                        });
                      },
                    })
                  }
                  // loadAll={() => setOffsetLimit({ offset: 0, limit: albumsTotal })}
                  path={stripSlash(match.url)}
                />
              </div>
            );
          }}
        </Query>
      </div>
    </div>
  );
});

export default VideosList;
