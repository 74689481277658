import styled from '@emotion/styled';
import React, { FC } from 'react';
import ModalBonusDeactivateButton from './ModalBonusDeactivateButton';
import ModalBonusCancelButton from './ModalBonusCancelButton';
import { Code } from '../../../../types';

interface Props {
  checked: boolean;
  deactivateCode: (code: string) => Promise<void>;
  onClose: (event: React.MouseEvent) => void;
  code: Code;
}

const Row = styled.div`
  padding-top: 32px;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const ModalBonusButtonRow: FC<Props> = ({ checked, onClose, code, deactivateCode }) => {
  return (
    <Row>
      <ModalBonusDeactivateButton
        deactivate={checked}
        code={code}
        deactivateCode={deactivateCode}
      />
      <ModalBonusCancelButton onClose={onClose} />
    </Row>
  );
};

export default ModalBonusButtonRow;
