import styled from '@emotion/styled';

export const StyledInputRange = styled.input`
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /************************ THUMB ************************/
  /* Special styling for WebKit/Blink */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #dcdcde;
    height: 30px;
    width: 18px;
    border-radius: 2px;
    background: #cdd4d6;
    cursor: pointer;
    margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  /* All the same stuff for Firefox */
  &::-moz-range-thumb {
    border: 1px solid #dcdcde;
    height: 30px;
    width: 18px;
    border-radius: 2px;
    background: #cdd4d6;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  &::-ms-thumb {
    border: 1px solid #dcdcde;
    height: 30px;
    width: 18px;
    border-radius: 2px;
    background: #cdd4d6;
    cursor: pointer;
  }

  /************************ TRACK ************************/
  // CHROME
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
    border: 1px solid #dcdcde;
  }

  &:focus::-webkit-slider-runnable-track {
    outline: none;
  }

  // FIREFOX
  &::-moz-range-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    background: #fff;
    border-radius: 1.3px;
    border: 1px solid #dcdcde;

    &:focus {
      outline: none;
    }
  }

  // IE
  &::-ms-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: #fff;
    border: 1px solid #dcdcde;
    border-radius: 2.6px;
  }
  &::-ms-fill-upper {
    background: #fff;
    border: 1px solid #dcdcde;
    border-radius: 2.6px;
  }
`;

export const StyledInputText = styled.input`
  border: 1px solid #dcdcde;
  padding: 8px;
  width: 32px;
`;

export const StyledLabel = styled.div`
  background-color: #dcdcde;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 0 2px 2px 0;
  white-space: nowrap;
`;
