import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from '../../util/Formatter';
import { useLang } from '../../util/AppState';

const FormatDateTime = ({ className, value, showTime }) => {
  const [lang] = useLang();

  return <span className={className}>{getFormattedDate(value, lang, showTime || false)}</span>;
};

FormatDateTime.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

FormatDateTime.defaultProps = {
  className: '',
  value: '',
};

export default FormatDateTime;
export { FormatDateTime };
