import { ClassNames } from '@emotion/core';
import styled from '@emotion/styled';
import React, { Fragment } from 'react';

import { ProgressCircle, T } from '../../../components';

import { APP_BASE_PATH } from '../../../util/env';
import { withRouter } from 'react-router';
import { _ } from '../../../util/translate';
import { RED } from '../../../camtool-styles';
import { Box, CardActions, CardContent } from '@material-ui/core';
import NewButton from '../../../atoms/Button/NewButton';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

const profileStatusStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
  },
});

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

interface IProps {
    progress: number;
    loading: boolean;
} // prettier-ignore

const ProfileStatus = ({ progress, loading }: IProps) => {
  const history = useHistory();
  const classes = profileStatusStyles();
  const handleClick = () => history.push(`${APP_BASE_PATH}/onlineprofile/overview`);

  return (
    <Container>
      {loading ? (
        <Fragment></Fragment>
      ) : (
        <Fragment>
          <p
            css={{
              textAlign: 'center',
              color: '#8e8e8e',
              fontSize: 14,
              padding: '0 16px',
            }}
          >
            <T _={'dashboard:app.profileStatus.text'} />
          </p>
          <Box
            style={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1, display: 'flex' }}
          >
            <ClassNames>
              {({ css }) => (
                <ProgressCircle
                  label={_('dashboard:app.profileStatus.chartText')}
                  width={132}
                  height={132}
                  data={[progress, 100]}
                  progressColor={progress !== 100 ? RED : null}
                  progressBgColor={progress !== 100 ? '#707070' : null}
                  containerClass={css({ margin: '16px 0' })}
                />
              )}
            </ClassNames>
          </Box>
          <Box className={classes.buttonContainer}>
            <NewButton onClick={handleClick}>
              {_('dashboard:app.profileStatus.buttonText')}
            </NewButton>
          </Box>
        </Fragment>
      )}
    </Container>
  );
};

export default withRouter(ProfileStatus);
