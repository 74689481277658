import React from 'react';
import AbstractComponent from '../../../components/AbstractComponent';
import Modalbox from '../../Modalbox/Modalbox';
import { _ } from '../../../util/translate';

class ModalVideotutorial extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillMount() {
    if (this.props.isOpen) this.open();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) this.open();
      else this.close();
    }
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <Modalbox
        name="addlinkVideoModal"
        state={this.state.isOpen}
        onKeyPressESC={this.close}
        className="addlinkVideoModal__container"
      >
        <div className="modalgrid__box">
          <div className="modalgrid__box__header vxmodels-academy__header">
            {`${_('academy:modalBox.name')} ${_('academy:adlink.video.inviteUsers.modalBoxTitle')}`}
            <div className="seperator" />
            <div
              className="icon-remove options vxmodels-academy__header__btn"
              onClick={this.close}
            />
          </div>

          <div className="addlinkVideoModal__box__content">
            <div className="addlinkVideoModal__modalgrid__box__content__column">
              <h2 className="addlinkVideoModal__title">
                {_('academy:adlink.video.inviteUsers.description')}
              </h2>
              <iframe
                src="https://player.vimeo.com/video/260942411"
                width={540}
                height={540 * (360 / 640)}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </Modalbox>
    );
  }
}

ModalVideotutorial.propTypes = {};

ModalVideotutorial.defaultProps = {};

export default ModalVideotutorial;
export { ModalVideotutorial };
