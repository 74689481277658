import { createStyles, makeStyles } from '@material-ui/core';
import { BLACK_2, WHITE, BLUE } from '../../../../../camtool-styles';

export const useModalStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
      flexDirection: 'column',
      color: BLACK_2,
      alignItems: 'center',
      maxWidth: 'none',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      backgroundSize: 'cover',
      flexShrink: 0,
      position: 'relative',
    },
    terms: {
      flexDirection: 'column',
      width: 700,
      maxWidth: '100%',
      margin: 35,
      cursor: 'initial',
      borderRadius: 10,
      backgroundColor: WHITE,
      backgroundPosition: 'center',
    },
    back: {
      paddingLeft: 24,
      marginBottom: 24,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    text: {
      display: 'block',
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 16,
      fontSize: 14,
    },
    arrowBack: {
      height: 14,
      fill: BLUE,
    },
  })
);
