import React, { FC } from 'react';
import { AboInstanceStatus, IAboInstance } from '../../../types';
import { TableCell, TableRow } from '../../../../../atoms/Table';
import { DARK_GRAY, GREEN } from '../../../../../camtool-styles';
import { _ } from '../../../../../util/translate';
import { APP_BASE_PATH } from '../../../../../util/env';
import { ITableColumn } from '../../../../../atoms/Table/types';

interface Props {
  instance: IAboInstance;
  columns: ITableColumn[];
}

const InstanceTableRow: FC<Props> = ({ instance, columns }) => {
  const { status, guestId, guestName, start, end } = instance;

  const isRunning = status === AboInstanceStatus.Running;

  return (
    <TableRow to={`${APP_BASE_PATH}/customers/${guestId}`} css={{ flexBasis: 36 }}>
      <TableCell columns={columns} index={0}>
        <div>
          <span
            className={isRunning ? 'icon-ok' : 'icon-clock'}
            css={{ color: isRunning ? GREEN : DARK_GRAY, marginRight: 4 }}
          />
          <span>{_(`benefits:abo.archive.status.${status.toLowerCase()}`)}</span>
        </div>
      </TableCell>

      <TableCell columns={columns} index={1}>
        {guestName}
      </TableCell>

      <TableCell columns={columns} index={2}>
        {start ? new Date(start).toLocaleDateString() : ''}
      </TableCell>

      <TableCell columns={columns} index={3}>
        {end ? new Date(end).toLocaleDateString() : ''}
      </TableCell>
    </TableRow>
  );
};

export default InstanceTableRow;
