import Translator from './Translator';

export const _ = (_, { sprintf, ...rest } = {}) => {
  let opts = { ...rest };
  if (sprintf) {
    opts.postProcess = 'sprintf';
    opts.sprintf = sprintf;
  }
  return Translator.t(_, opts);
};
