import { MutationFunctionOptions } from '@apollo/react-common';
import { ExecutionResult } from 'graphql';
import cloneDeep from 'lodash/cloneDeep';
import * as Yup from 'yup';
import { AttachmentTypeEnum } from '../../../../graphql/VXModels/types';
import {
  FileType,
  Master,
  UserLifeCycleDelayDisplayUnit,
  UserLifeCycleDelaySchedule,
  UserLifeCycleStep,
  UserLifeCycleStepInput,
} from '../../../../graphql/VXServicesTelegram/types';
import { getPreviewFileTypeFromAttachment } from '../../../../util/FileUtils';
import { _ } from '../../../../util/translate';
import { QUERY_MASTER_USERLIFECYCLE } from '../../../Telegram/graphql/queries';
import {
  dayPartOptions,
  FormikUserLifeCycleStep,
  FormikUserLifeCycleSteps,
  MobileUserLifeCycleStep,
} from './types';

export const MAX_LIFECYCLE_LENGTH = 10;

export const getInitialDummyEntry = (isVip: boolean) => [
  {
    id: null,
    active: true,
    vip: isVip,
    delayMinutes: '',
    delayDisplayUnit: '',
    delaySchedule: '',
    text: '',
    file: null,
  },
];

const translateType: (type: FileType) => string | undefined = (type) => {
  switch (type) {
    case FileType.picture:
      return 'image/*';
    case FileType.video:
      return 'video/*';
    case FileType.audio:
      return 'audio/*';
    default:
      return undefined;
  }
};

const convertDelayUnit = (
  delayMinutes: number,
  delayUnit: UserLifeCycleDelayDisplayUnit
): number => {
  switch (delayUnit) {
    case UserLifeCycleDelayDisplayUnit.minutes:
      return delayMinutes;
    case UserLifeCycleDelayDisplayUnit.hours:
      return delayMinutes / 60;
    case UserLifeCycleDelayDisplayUnit.days:
      return delayMinutes / (60 * 24);
  }
};

const convertDelayUnitToMinutes = (
  delayMinutes: number,
  delayUnit: UserLifeCycleDelayDisplayUnit
): number => {
  switch (delayUnit) {
    case UserLifeCycleDelayDisplayUnit.minutes:
      return delayMinutes;
    case UserLifeCycleDelayDisplayUnit.hours:
      return delayMinutes * 60;
    case UserLifeCycleDelayDisplayUnit.days:
      return delayMinutes * (60 * 24);
  }
};

export const sortAutoMailList = (
  autoMailList: FormikUserLifeCycleStep[]
): FormikUserLifeCycleStep[] => {
  // sort time units "days", "hours" and "minutes" alphabetically (ascending) and reverse list. Output -> "minutes", "hours" "days"
  // todo: implement actual time unit sorting function - sorting english terms by alphabet is a lucky solution that works for now.
  const compareValues = (sortAttribute: string, sortAsc: boolean) => {
    return (a: FormikUserLifeCycleStep, b: FormikUserLifeCycleStep): number => {
      if (!a.hasOwnProperty(sortAttribute) || !b.hasOwnProperty(sortAttribute)) return 0;
      const comparison = a[sortAttribute].localeCompare(b[sortAttribute]);

      return sortAsc ? comparison * -1 : comparison;
    };
  };
  return autoMailList.sort(compareValues('delayDisplayUnit', true));
};

export const sortMobileAutoMailList = (
  autoMailList: MobileUserLifeCycleStep[]
): MobileUserLifeCycleStep[] => {
  // sort time units "days", "hours" and "minutes" alphabetically (ascending) and reverse list. Output -> "minutes", "hours" "days"
  // todo: implement actual time unit sorting function - sorting english terms by alphabet is a lucky solution that works for now.
  const compareValues = (sortAttribute: string, sortAsc: boolean) => {
    return (a: MobileUserLifeCycleStep, b: MobileUserLifeCycleStep): number => {
      if (!a.hasOwnProperty(sortAttribute) || !b.hasOwnProperty(sortAttribute)) return 0;
      const comparison = a[sortAttribute].localeCompare(b[sortAttribute]);

      return sortAsc ? comparison * -1 : comparison;
    };
  };
  return autoMailList.sort(compareValues('delayDisplayUnit', true));
};

// transformation for Formik (and PreviewFile)!
export const transformLifeCycleForFormik: (
  list: UserLifeCycleStep[]
) => FormikUserLifeCycleStep[] = (list) => {
  return cloneDeep(list).map((step) => {
    const { file, delayMinutes, delayDisplayUnit, ...rest } = step;

    return {
      ...rest,
      delayMinutes: convertDelayUnit(delayMinutes, delayDisplayUnit),
      delayDisplayUnit: delayDisplayUnit,
      file: file
        ? {
            type: AttachmentTypeEnum.UUID,
            payload: file.uuid,
            meta: {
              type: translateType(file.type),
              dimensions: {
                width: file.width,
                height: file.height,
              },
            },
            url: file.url || file.previewPictureUrl,
          }
        : undefined,
    } as FormikUserLifeCycleStep;
  });
};

// transformation for Formik (and PreviewFile)!
export const transformMobileLifeCycleForOverview: (
  list: UserLifeCycleStep[]
) => MobileUserLifeCycleStep[] = (list) => {
  return cloneDeep(list).map((step) => {
    const { file, delayMinutes, delayDisplayUnit, ...rest } = step;
    const attachment = file
      ? {
          type: AttachmentTypeEnum.UUID,
          payload: file.uuid,
          meta: {
            type: translateType(file.type),
            dimensions: {
              width: file.width,
              height: file.height,
            },
          },
          url: file.url || file.previewPictureUrl,
        }
      : undefined;

    return {
      ...rest,
      delayMinutes: convertDelayUnit(delayMinutes, delayDisplayUnit),
      delayDisplayUnit: delayDisplayUnit,
      file: file
        ? {
            url: (file.url || file.previewPictureUrl) as string,
            type: getPreviewFileTypeFromAttachment(attachment),
          }
        : undefined,
    } as MobileUserLifeCycleStep;
  });
};

// transformation for Formik (and PreviewFile)!
export const transformMobileLifeCycleForFormik: (
  list: UserLifeCycleStep[]
) => FormikUserLifeCycleStep[] = (list) => {
  return cloneDeep(list).map((step) => {
    const { file, delayMinutes, delayDisplayUnit, ...rest } = step;

    return {
      ...rest,
      delayMinutes: convertDelayUnit(delayMinutes, delayDisplayUnit),
      delayDisplayUnit: delayDisplayUnit,
      file: file
        ? {
            type: AttachmentTypeEnum.UUID,
            payload: file.uuid,
            meta: {
              type: translateType(file.type),
              dimensions: {
                width: file.width,
                height: file.height,
              },
            },
            url: file.url || file.previewPictureUrl,
          }
        : undefined,
    } as FormikUserLifeCycleStep;
  });
};

export const transformLifeCyclesForMutation = (
  currentAutoMailList: FormikUserLifeCycleStep[]
): UserLifeCycleStepInput[] => {
  const clonedLifeCycle = cloneDeep(currentAutoMailList);

  const mutatedLifeCycleList = clonedLifeCycle.map((formikEntryRow: FormikUserLifeCycleStep) => {
    const mutationObject: UserLifeCycleStepInput = {
      id: formikEntryRow.id,
      active: formikEntryRow.active,
      vip: formikEntryRow.vip,
      text: formikEntryRow.text,
      delayMinutes: convertDelayUnitToMinutes(
        formikEntryRow.delayMinutes,
        formikEntryRow.delayDisplayUnit
      ),
      delayDisplayUnit: formikEntryRow.delayDisplayUnit,
      delaySchedule: formikEntryRow.delaySchedule,
      fileUUID: formikEntryRow?.file?.payload,
    };
    return mutationObject;
  });

  return mutatedLifeCycleList;
};

export const transformLifeCycleForMutation = (
  currentAutoMail: MobileUserLifeCycleStep
): UserLifeCycleStepInput => {
  const mutatedLifeCycle = {
    id: currentAutoMail.id,
    active: currentAutoMail.active,
    vip: currentAutoMail.vip,
    text: currentAutoMail.text,
    delayMinutes: convertDelayUnitToMinutes(
      currentAutoMail.delayMinutes,
      currentAutoMail.delayDisplayUnit
    ),
    delayDisplayUnit: currentAutoMail.delayDisplayUnit,
    delaySchedule: currentAutoMail.delaySchedule,
    fileUUID: currentAutoMail?.file?.payload,
  };

  return mutatedLifeCycle as UserLifeCycleStepInput;
};

// ids of last saved session who cant be found in current autoMailList will be deleted
export const deleteNonExistingRows = (
  initialAutoMailList: FormikUserLifeCycleSteps,
  currentAutoMailList: FormikUserLifeCycleSteps,
  deleteMutation: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined
  ) => Promise<ExecutionResult<boolean>>,
  token: string
): void => {
  const missMatchedIds: number[] = [];
  initialAutoMailList.autoMailList.map((initialEntry) => {
    const initialId = initialEntry.id;

    if (initialId !== null) {
      const found = currentAutoMailList.autoMailList.some(
        (currentEntry) => currentEntry.id === initialId
      );
      if (!found) missMatchedIds.push(initialId);
    }
  });

  if (missMatchedIds.length > 0) {
    missMatchedIds.map((id) => {
      deleteMutation({
        variables: {
          token,
          id,
        },
      });
    });
  }
};

export const saveAutoMailList = async (
  currentAutoMailList: FormikUserLifeCycleStep[],
  saveMutation: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined
  ) => Promise<ExecutionResult<UserLifeCycleStepInput>>,
  token: string,
  isVip: boolean
): Promise<void> => {
  const mutatedList = transformLifeCyclesForMutation(currentAutoMailList);
  await saveMutation({
    variables: {
      token,
      steps: mutatedList,
    },
    refetchQueries: [{ query: QUERY_MASTER_USERLIFECYCLE, variables: { token, vip: isVip } }],
    awaitRefetchQueries: true,
  });
};

export const saveAutoMail = async (
  currentAutoMail: MobileUserLifeCycleStep,
  saveMutation: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined
  ) => Promise<ExecutionResult<UserLifeCycleStepInput>>,
  token: string,
  isVip: boolean,
  id?: number
): Promise<void> => {
  const mutatedAutoMail = transformLifeCycleForMutation(currentAutoMail);
  await saveMutation({
    variables: {
      token,
      step: mutatedAutoMail,
    },
    refetchQueries: [
      {
        query: QUERY_MASTER_USERLIFECYCLE,
        variables: { token, vip: isVip, id },
      },
    ],
    awaitRefetchQueries: true,
  }).then();
};

export const getDayPartOptions = (): dayPartOptions[] => [
  {
    label: _('mailings:telegram.autoMail.deliverySchedule.immediately'),
    value: UserLifeCycleDelaySchedule.immediately,
  },
  {
    label: _('mailings:telegram.autoMail.deliverySchedule.morning'),
    value: UserLifeCycleDelaySchedule.morning,
  },
  {
    label: _('mailings:telegram.autoMail.deliverySchedule.midday'),
    value: UserLifeCycleDelaySchedule.midday,
  },
  {
    label: _('mailings:telegram.autoMail.deliverySchedule.evening'),
    value: UserLifeCycleDelaySchedule.evening,
  },
];

export const getDeliveryOptions = (): deliveryOptions[] => [
  {
    label: _('mailings:telegram.autoMail.delayUnit.minutes'),
    value: UserLifeCycleDelayDisplayUnit.minutes,
  },
  {
    label: _('mailings:telegram.autoMail.delayUnit.hours'),
    value: UserLifeCycleDelayDisplayUnit.hours,
  },
  {
    label: _('mailings:telegram.autoMail.delayUnit.days'),
    value: UserLifeCycleDelayDisplayUnit.days,
  },
];

const getDisplayUnitRegExp = (): RegExp =>
  new RegExp(
    `${UserLifeCycleDelayDisplayUnit.minutes}|${UserLifeCycleDelayDisplayUnit.hours}|${UserLifeCycleDelayDisplayUnit.days}`
  );

const getDelayScheduleRegExp = (): RegExp =>
  new RegExp(
    `${UserLifeCycleDelaySchedule.immediately}|${UserLifeCycleDelaySchedule.morning}|${UserLifeCycleDelaySchedule.midday}|${UserLifeCycleDelaySchedule.evening}`
  );

export const validationSchema = (allowedMimetypes: Master['allowedMimeTypes']) =>
  Yup.object().shape({
    autoMailList: Yup.array().of(
      Yup.object().shape({
        delayMinutes: Yup.number()
          .typeError(_('common:formValidationMessage.invalid'))
          .min(0, _('common:formValidationMessage.minimum', { sprintf: [0] }))
          .max(999, _('common:formValidationMessage.maximum', { sprintf: [999] }))
          .required(_('common:formValidationMessage.required')),
        delayDisplayUnit: Yup.string()
          .matches(getDisplayUnitRegExp(), _('common:formValidationMessage.maximum'))
          .required(_('common:formValidationMessage.required')),
        delaySchedule: Yup.string()
          .matches(getDelayScheduleRegExp(), _('common:formValidationMessage.maximum'))
          .required(_('common:formValidationMessage.required')),
        text: Yup.string().trim().required(_('common:formValidationMessage.required')),
        //check if file is null if file ist not null check filetype / mimetype
        file: Yup.object()
          .nullable()
          .notRequired()
          .test('fileFormat', 'image and video only', (value) => {
            const mimeType = value?.meta?.type?.includes('/*')
              ? value?.meta?.type.split('/')[0]
              : value?.meta?.type;
            return !value || (mimeType && allowedMimetypes.toString().includes(mimeType));
          }),
      })
    ),
  });

export const validationSchemaMobile = (allowedMimetypes: Master['allowedMimeTypes']) =>
  Yup.object().shape({
    delayMinutes: Yup.number()
      .typeError(_('common:formValidationMessage.invalid'))
      .min(0, _('common:formValidationMessage.minimum', { sprintf: [0] }))
      .max(999, _('common:formValidationMessage.maximum', { sprintf: [999] }))
      .required(_('common:formValidationMessage.required')),
    delayDisplayUnit: Yup.string()
      .matches(getDisplayUnitRegExp(), _('common:formValidationMessage.maximum'))
      .required(_('common:formValidationMessage.required')),
    delaySchedule: Yup.string()
      .matches(getDelayScheduleRegExp(), _('common:formValidationMessage.maximum'))
      .required(_('common:formValidationMessage.required')),
    text: Yup.string().trim().required(_('common:formValidationMessage.required')),
    //check if file is null if file ist not null check filetype / mimetype
    file: Yup.object()
      .nullable()
      .notRequired()
      .test('fileFormat', 'image and video only', (value) => {
        const mimeType = value?.meta?.type?.includes('/*')
          ? value?.meta?.type.split('/')[0]
          : value?.meta?.type;
        return !value || (mimeType && allowedMimetypes.toString().includes(mimeType));
      }),
  });
