import React, { FC } from 'react';
import { user } from '../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../util/translate';
import UserAvatar from '../../../atoms/UserAvatar/UserAvatar';

interface Props {
  answer: string;
  modelAvatar: string | null | undefined;
}

const Answer: FC<Props> = ({ answer, modelAvatar }) => {
  return (
    <div css={{ marginLeft: '30px', alignItems: 'center' }}>
      <div css={{ flex: '1 1 auto' }}>
        <UserAvatar src={modelAvatar ?? user} size={'40px'} />
      </div>
      <div
        css={{
          flex: '1 1 100%',
          flexDirection: 'column',
        }}
      >
        <span css={{ fontWeight: 'bold', marginBottom: '5px' }}>
          {_('mediamanagement:comments.comment.answer')}
        </span>
        <span>{answer}</span>
      </div>
    </div>
  );
};

export default Answer;
