import React, { FC, ReactElement } from 'react';
import { Code } from '../../../types';

interface Props {
  code: Code;
}

const PlatformLabel: FC<Props> = ({ code }): ReactElement => {
  const platforms = code?.config?.conditions?.platforms || [];

  const platform =
    platforms
      .map((pfm) => {
        if (pfm.pfmId === 1503) return 'VXPAGES';
        else if (pfm.pfmId === 1502) return 'VISIT-X';
        else return '-';
      })
      .join(', ') || '-';

  return <>{platform}</>;
};

export default PlatformLabel;
