import * as React from 'react';

import { DayContainer, DayContent } from './styles';

interface IProps {
  children?: number;
  callerId?: number;
  onClick?: (callerId: number) => void;
  disabled?: boolean;
  selected?: boolean;
  first?: boolean;
  last?: boolean;
  highlighted?: boolean;
}

const Day = ({
  children,
  disabled,
  onClick,
  callerId,
  selected,
  first,
  last,
  highlighted,
}: IProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (typeof onClick === 'function' && callerId) {
      onClick(callerId);
    }
  };

  return (
    <DayContainer>
      <DayContent
        selected={selected}
        onClick={handleClick}
        disabled={disabled}
        first={first}
        last={last}
        highlighted={highlighted}
      >
        {children}
      </DayContent>
    </DayContainer>
  );
};

export default Day;
