import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { donateHeart } from '../../../../../atoms/Icon/libraries/glyph';

const TelegramAbosItem: FC = () => (
  <LeftMenuItem title={_('navigation:telegram.abos')} icon={donateHeart} uri="/telegram/abos" />
);

export default TelegramAbosItem;
