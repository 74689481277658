import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  formatDate,
  VXGAMES_PRIMARY,
  VXGAMES_TYPOGRAPHY_H3,
  VXGAMES_TYPOGRAPHY_PARAGRAPH,
  VXGAMES_TYPOGRAPHY_SUBHEADING,
} from '../../../../utils';
import { AchievementProps, competitionAchievementMapping } from '.';
import { notCompleted } from './Assets';
import { VXGChallengeUserStatusEnum } from '../../../../../../graphql/VXModels/types';

const invalidateTrophyStyle = {
  opacity: 0.5,
  filter: 'grayscale(1)',
};

const Achievement: FC<AchievementProps> = ({
  type,
  participationStatus,
  title,
  trophyDescription,
  completedDate,
  isMobile,
}) => {
  const completedChallenge =
    participationStatus === VXGChallengeUserStatusEnum.complete ||
    participationStatus === VXGChallengeUserStatusEnum.winner;

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={`0 0 ${isMobile ? '100%' : 'calc(25% - 50px)'}`}
      minWidth="120px"
      style={{
        ...(completedChallenge ? null : invalidateTrophyStyle),
        ...{ scrollSnapAlign: 'start' },
      }}
    >
      <Box maxWidth="120px" height="120px" mb={0.5}>
        {completedChallenge ? (
          <img src={competitionAchievementMapping[type]} />
        ) : (
          <img src={notCompleted} />
        )}
      </Box>
      <Typography gutterBottom style={{ ...VXGAMES_TYPOGRAPHY_H3, ...{ color: VXGAMES_PRIMARY } }}>
        {title}
      </Typography>
      <Typography gutterBottom style={VXGAMES_TYPOGRAPHY_PARAGRAPH}>
        {trophyDescription}
      </Typography>
      {completedChallenge && (
        <Typography style={{ ...VXGAMES_TYPOGRAPHY_SUBHEADING, ...{ fontSize: '14px' } }}>
          {formatDate(completedDate, 'dd.MM.yyyy')}
        </Typography>
      )}
    </Box>
  );
};

export { Achievement };
