import React, { FC } from 'react';
import { BLACK, WHITE } from '../../../../camtool-styles';
import styled from '@emotion/styled';
import { SpinnerInline } from '../../../../components';

interface IProps {
  label: string;
  onClick: () => void;
  loading: boolean;
}

const Divider = styled.span`
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin: 8px;
`;

const FetchMoreLine: FC<IProps> = ({ label, onClick, loading }) => {
  return (
    <div
      css={{
        padding: 8,
        backgroundColor: WHITE,
        flex: '0 0 auto',
        flexDirection: 'column',
      }}
    >
      <div
        onClick={onClick}
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Divider />
        {loading ? (
          <SpinnerInline />
        ) : (
          <a
            href={'#'}
            onClick={(e) => {
              e.preventDefault();
              onClick(e);
            }}
            css={{ display: 'inline-block', padding: '0 8px', background: 'white' }}
          >
            {label}
          </a>
        )}
        <Divider />
      </div>
    </div>
  );
};

export default FetchMoreLine;
