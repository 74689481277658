import styled from '@emotion/styled';
import { MediaUploadInputLabelSize, MediaUploadInputLayoutDirection } from '../types';
import { UploadLabelProps } from './types';

const getHorizontalPaddingByLabelSize = (labelSize: MediaUploadInputLabelSize): number => {
  switch (labelSize) {
    case MediaUploadInputLabelSize.md:
      return 20;
    case MediaUploadInputLabelSize.sm:
      return 5;
  }
};

const Container = styled.div<Pick<UploadLabelProps, 'labelSize' | 'layoutDirection'>>`
  flex-direction: ${(props): string =>
    props.layoutDirection === MediaUploadInputLayoutDirection.vertical ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
  padding: 0 ${(props): number => getHorizontalPaddingByLabelSize(props.labelSize)}px;
`;

export default Container;
