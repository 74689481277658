import React, { FC } from 'react';
import { Spinner } from '../../components';

const OverlayLoading: FC = () => (
  <div css={{ '.spinner': { display: 'flex' } }}>
    <Spinner />
  </div>
);

export default OverlayLoading;
