import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { _ } from '../../../../../../util/translate';
import { AboContentType } from '../../../../types';
import { Box } from '@material-ui/core';
import FieldNameValidation from './FieldNameValidation';
import { ApiLang } from '../../../../../../graphql/VXModels/types';
import { sanitizeLang } from '../../../../../../util/Translator';

interface Props {
  aboContentType: AboContentType;
}

const FieldName: FC<Props> = ({ aboContentType }) => {
  const languageArray: ApiLang[] = [ApiLang.de, ApiLang.en];
  const [language, setLanguage] = useState<ApiLang>(ApiLang.de);

  const changeLanguage = (nthLanguage: number): void => {
    setLanguage(languageArray[nthLanguage]);
  };

  return (
    <Box mb="20px">
      <Field type="text" name={`nameTranslation[${language}]`}>
        {({ field, form: { errors, touched, setFieldValue } }: FieldProps): ReactElement => {
          const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
            setFieldValue(field.name, event.target.value);
          return (
            <FieldNameValidation
              language={language}
              changeLanguage={changeLanguage}
              placeholder={_(
                `benefits:abo.detailsPage.${aboContentType.toLowerCase()}.namePlaceholder`,
                { lng: sanitizeLang(language) }
              )}
              onChange={onChange}
              field={field}
              errors={errors}
              touched={touched}
            />
          );
        }}
      </Field>
    </Box>
  );
};

export default FieldName;
