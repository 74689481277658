import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedPeriod } from '../../util/Formatter';
import { useLang } from '../../util/AppState';

const FormatDateTimePeriod = ({ className, from, to, separator }) => {
  const [lang] = useLang();

  return <span className={className}>{getFormattedPeriod(from, to, lang, true, separator)}</span>;
};

FormatDateTimePeriod.propTypes = {
  className: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  separator: PropTypes.string,
};

FormatDateTimePeriod.defaultProps = {
  className: '',
  from: '',
  to: '',
  separator: '',
};

export default FormatDateTimePeriod;
