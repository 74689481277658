import { createStyles, makeStyles, SvgIcon } from '@material-ui/core';
import React, { FC } from 'react';

const useIconStyles = makeStyles(() =>
  createStyles({
    root: ({ width, height }: Pick<Props, 'width' | 'height'>) => ({
      width: `${width}px`,
      height: `${height}px`,
    }),
  })
);

interface Props {
  width?: number;
  height?: number;
}

const SendMail: FC<Props> = ({ width = 80, height = 50 }) => {
  const iconStyles = useIconStyles({ width, height });

  return (
    <SvgIcon classes={iconStyles} viewBox={'0 0 124.773 63.251'}>
      <g id="mail-send" transform="translate(0 -85.26)">
        <path
          id="Pfad_30"
          data-name="Pfad 30"
          d="M153.49,148.459c.047-.009.093-.022.139-.034s.079-.018.118-.03.089-.031.133-.047.078-.027.117-.043.082-.038.122-.057.079-.036.118-.057.075-.044.113-.066.078-.045.115-.07.077-.056.116-.085.066-.047.1-.073c.058-.048.114-.1.168-.152l.03-.027c.01-.01.019-.021.029-.032.052-.053.1-.108.15-.166.028-.034.052-.069.078-.1s.055-.071.08-.109.05-.082.075-.123.043-.069.062-.1.041-.084.061-.127.036-.075.053-.113.031-.084.046-.126.031-.082.043-.124.022-.086.033-.129.023-.084.031-.127.016-.1.023-.153c.005-.036.012-.072.016-.108a2.7,2.7,0,0,0,.014-.27V87.966a2.706,2.706,0,0,0-2.706-2.706H67.726c-.024,0-.047,0-.071,0-.052,0-.1,0-.154.008s-.081.008-.122.013-.092.013-.138.021-.086.017-.128.026-.086.021-.128.033-.084.025-.125.039-.084.03-.126.046-.078.031-.116.049-.085.04-.127.061-.071.037-.105.057-.083.049-.124.075-.066.044-.1.067-.076.055-.113.085-.067.055-.1.083-.065.057-.1.087-.07.07-.1.106c-.017.018-.035.034-.051.052s-.017.023-.027.034c-.034.039-.066.08-.1.122s-.047.061-.069.092-.054.082-.08.124-.043.068-.063.1-.043.082-.063.123-.038.076-.055.114-.033.083-.049.124-.031.081-.045.122-.024.084-.036.126-.024.086-.034.13-.015.082-.022.123-.016.094-.022.141-.007.079-.01.118-.007.1-.008.152c0,.015,0,.029,0,.044v16.687a2.706,2.706,0,0,0,5.412,0V93.975l24.058,21.3a2.732,2.732,0,0,0-.359.3l-23.7,23.7V121.49a2.706,2.706,0,1,0-5.412,0V124.8H51.039a2.706,2.706,0,1,0,0,5.412H65.02v15.6a2.706,2.706,0,0,0,2.706,2.706h85.236c.089,0,.178,0,.267-.013.041,0,.082-.012.122-.018S153.444,148.468,153.49,148.459Zm-3.234-9.186-23.7-23.7c-.015-.015-.031-.028-.047-.042l17.817-15.116a2.706,2.706,0,0,0-3.5-4.126l-30.444,25.829L74.864,90.672h75.392v48.6ZM97.958,119.4a2.7,2.7,0,0,0,.472-.637l10.12,8.96a2.706,2.706,0,0,0,3.545.038l10.314-8.751a2.72,2.72,0,0,0,.321.39l23.7,23.7H74.259Z"
          transform="translate(-30.895)"
          fill="#1f93e9"
        />
        <path
          id="Pfad_31"
          data-name="Pfad 31"
          d="M2.706,161.088h45.7a2.706,2.706,0,1,0,0-5.412H2.706a2.706,2.706,0,0,0,0,5.412Z"
          transform="translate(0 -45.011)"
          fill="#1f93e9"
        />
        <path
          id="Pfad_32"
          data-name="Pfad 32"
          d="M15.831,119.757h20.52a2.706,2.706,0,0,0,0-5.412H15.831a2.706,2.706,0,1,0,0,5.412Z"
          transform="translate(-8.39 -18.591)"
          fill="#1f93e9"
        />
        <path
          id="Pfad_33"
          data-name="Pfad 33"
          d="M22.549,226.51H2.706a2.706,2.706,0,1,0,0,5.412H22.549a2.706,2.706,0,1,0,0-5.412Z"
          transform="translate(0 -90.289)"
          fill="#1f93e9"
        />
      </g>
    </SvgIcon>
  );
};

export default SendMail;
