import React, { FC } from 'react';
import { Alert as MUIAlert, AlertProps as MUIAlertProps } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: '12px',
      border: 'none',
      padding: '0px 16px',
      ' & > .MuiAlert-icon': {
        margin: 'auto 10px auto auto',
        padding: '0',
      },
    },
  })
);

const Alert: FC<MUIAlertProps> = ({ severity = 'error', children, ...props }) => {
  const classes = useStyles();

  return (
    <MUIAlert className={classes.root} severity={severity} {...props}>
      {children}
    </MUIAlert>
  );
};

export default Alert;
