import { keyframes } from '@emotion/core';
import { makeStyles } from '@material-ui/core';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const textGlow = keyframes`
  from {
    text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #e60073, 0 0 5px #e60073, 0 0 5px #e60073, 0 0 5px #e60073, 0 0 5px #e60073;
  }

  to {
    text-shadow: 0 0 10px #fff, 0 0 10px #ff4da6, 0 0 10px #ff4da6, 0 0 10px #ff4da6, 0 0 10px #ff4da6, 0 0 10px #ff4da6, 0 0 10px #ff4da6;
  }
`;

export const muiBorderGlow = {
  '@global': {
    '@keyframes border-glow': {
      from: {
        borderColor: '#e60073',
        boxShadow: '0 0 5px #e60073',
      },
      to: {
        borderColor: '#ff4da6',
        boxShadow: '0 0 5px #ff4da6',
      },
    },
  },
};
