import { EventEmitter2 } from 'eventemitter2';
import { Entry } from './Entry';
import { EntryState } from './JsUploader';
import { ILogger } from './Logger';

export interface IChecksumWorkerProps {
  userId: number;
  workerUrl: string;
  checksumValidateUrl: string;
  validator: any;
  logger?: ILogger;
  isEnabled?: boolean;
}

export class ChecksumWorker extends EventEmitter2 {
  public currentEntry: Entry;
  private userId: number;
  private workerUrl: string;
  private logger?: ILogger;
  private validator?: any;
  private checksumWorker: Worker;
  private checksumValidateUrl: string;
  private isEnabled?: boolean;

  /**
   *
   * @param props MD5FileHashHelperProps
   */
  public constructor(props: IChecksumWorkerProps) {
    super();
    this.userId = props.userId;
    this.isEnabled = !!props.checksumValidateUrl;
    this.logger = props.logger || null;
    this.workerUrl = props.workerUrl;
    this.checksumValidateUrl = props.checksumValidateUrl;
    this.validator = props.validator;
    this.validationStates = {};
  }

  /**
   *
   * @param queue Entry[]
   */
  public processQueue(queue: Entry[]) {
    if (this.isEnabled && !this.currentEntry) {
      for (let i = 0; i < queue.length; i++) {
        this.processEntry(queue[i]);
      }
    }
  }

  /**
   *
   */
  public abortCurrentCalculation() {
    if (this.checksumWorker) {
      this.checksumWorker.onerror = null;
      this.checksumWorker.terminate();
      this.checksumWorker = null;
    }
    this.validationStates[this.currentEntry.key] = 'abort';
    this.currentEntry = null;
  }

  /**
   *
   * @param entry Entry
   */
  private processEntry(entry: Entry) {
    if (entry.file.name && this.validator) {
      this.validator.bind(this);
      this.validator(entry);
    }

    if (
      !this.currentEntry &&
      !entry.checksum &&
      entry.state !== EntryState.FILE_REF_ERROR &&
      entry.state !== EntryState.PICTURE_SIZE_ERROR &&
      entry.state !== EntryState.FILE_TYPE_ERROR
    ) {
      if (!this.checksumWorker) {
        // a note why not to use the inline worker:
        // Internet Explorer 10,11 does not allow Blob URIs as a valid script for workers
        // and Opera Presto doesn't have window.URL
        this.checksumWorker = new Worker(this.workerUrl);
        this.checksumWorker.onmessage = this.onWorkerMessage.bind(this);
        this.checksumWorker.onerror = this.onWorkerError.bind(this);
        this.checksumWorker.postMessage({ command: 'init' });
      }
      this.currentEntry = entry;
      this.checksumWorker.postMessage({ command: 'start', value: entry.file });
    }
  }

  /**
   *
   * @param entry Entry
   */
  private validateChecksum(entry: Entry) {
    const { fileName, checksum } = entry;

    this.logger.debug(`ChecksumWorker: validate checksum for file ${fileName} [${checksum}]`, {
      context: 'JsUpload.ChecksumWorker.validateChecksum.calc',
      data: { checksum },
    });

    const request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status === 200) {
          let respJson: any = null;
          try {
            respJson = JSON.parse(request.response);
          } catch (e) {
            this.logger.warn('ChecksumWorker: parse response error', {
              context: 'JsUpload.ChecksumWorker.validateChecksum.parseError',
              data: { checksum },
            });
          }
          if (respJson && respJson.data && respJson.data.hashExists === true) {
            this.logger.info(
              `ChecksumWorker: duplicate: file ${fileName} already exists for user`,
              { context: 'JsUpload.ChecksumWorker.validateChecksum.fileExists', data: { checksum } }
            );
            this.emit('updateEntryState', entry, EntryState.DUPLICATE_ERROR);
            this.emit('error', entry);
          } else {
            this.logger.info(`ChecksumWorker: file ${fileName} is not a duplicate`, {
              context: 'JsUpload.ChecksumWorker.validateChecksum.valid',
              data: { checksum },
            });
          }
        }
      }
    };

    let checksumValidateUrl = this.checksumValidateUrl.replace(/\{userId\}/, '' + this.userId);
    checksumValidateUrl = checksumValidateUrl.replace(/\{checksum\}/, entry.checksum);

    request.open('GET', checksumValidateUrl);
    request.timeout = 30000;
    request.send();
  }

  /**
   *
   * @param event
   */
  private onWorkerMessage(event: any) {
    const command = event.data.command;
    const value = event.data.value;
    switch (command) {
      case 'progress':
        this.currentEntry.checksumProgress = value;
        this.emit('progress', this.currentEntry);
        break;
      case 'complete':
        this.currentEntry.checksum = value;
        this.validateChecksum(this.currentEntry);
        this.emit('complete', this.currentEntry);
        break;
      case 'abort':
        this.currentEntry = null;
        this.emit('abort', this.currentEntry);
        break;
    }
  }

  /**
   *
   * @param event
   */
  private onWorkerError(event: any) {
    event.preventDefault();
    this.isEnabled = false;
    this.currentEntry = null;
    this.checksumWorker = null;
    throw new Error(event.message + ' (' + event.filename + ':' + event.lineno + ')');
  }
}
