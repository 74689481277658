import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { VXGAMES_TYPOGRAPHY_H2, VXGAMES_TYPOGRAPHY_PARAGRAPH } from '../../../../utils';
import { VXGChallengeUserStatusEnum } from '../../../../../../graphql/VXModels/types';
import { CompetitionPrizeHeaderProps } from '.';

const CompetitionPrizeHeader: FC<CompetitionPrizeHeaderProps> = ({
  competitionFinished,
  participationStatus,
  competitionDeadline,
}) => {
  const isWinner = participationStatus === VXGChallengeUserStatusEnum.winner;
  const event = new Date(competitionDeadline);
  const humanReadableDate = event.toLocaleDateString();

  return (
    <>
      <Typography align="center" style={VXGAMES_TYPOGRAPHY_H2} gutterBottom>
        {competitionFinished
          ? 'Herausforderung beendet'
          : isWinner
          ? 'Herzlichen Glückwunsch'
          : 'Preisverlosung bei Abschluss der Herausforderung'}
      </Typography>
      <Typography align="center" style={VXGAMES_TYPOGRAPHY_PARAGRAPH} gutterBottom>
        {competitionFinished
          ? 'Diese Models hatten das Losglück auf Ihrer Seite.'
          : isWinner
          ? 'Das Los hat entschieden und Du gehörst zu den Glücklichen Gewinnerinnen. Dein Gewinn wird Dir autm. mit der nächsten Abrechnung gutgeschrieben.'
          : `Nach Teilnahme und Abschluss der Herausforderung werden bis zum ${humanReadableDate} unter allen Teilnehmern folgende Preise verlost:`}
      </Typography>
    </>
  );
};

export { CompetitionPrizeHeader };
