import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';
import { Link } from 'react-router-dom';
import FormatCurrency from '../../../Formatter/FormatCurrency';
import StarRating from '../../../StarRating/StarRating';
import FormatDate from '../../../Formatter/FormatDate';
import { withAppState } from '../../../../util/AppState';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import {
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_PHONE_CONDITION,
  BREAKPOINT_TABLET_CONDITION,
} from '../../../../camtool-styles';

const getRating = (rating) => {
  if (rating > 0) {
    return rating;
  }
  return 0;
};

const LinkStyled = styled(Link)`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const Col = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    min-height: 20vh;
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: 20px 0;
  }
`;

const FlexCol = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    -ms-flex: 1 1 30.4%;
    -webkit-flex: 1 1 30.4%;
    flex: 1 1 30.4%;
  }

  @media ${BREAKPOINT_TABLET_CONDITION} {
    -ms-flex: 1 1 50%;
    -webkit-flex: 1 1 50%;
    flex: 1 1 50%;
    margin: 20px 0rem;
  }

  @media ${BREAKPOINT_PHONE_CONDITION} {
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: 20px 0rem;
  }
`;

class OverviewItem extends AbstractComponent {
  onClick(event) {
    window.sessionStorage.setItem('currentCollection', this.props.album.albumType);
    event.stopPropagation();
    this.props.onClick(this.props.album);
  }

  getPreviewStyle() {
    const { previewPicture16, previewPicture18 } = this.props.album;
    const previewPicture =  (previewPicture18 && previewPicture18.url) || (previewPicture16 && previewPicture16.url); // prettier-ignore
    return previewPicture ? { backgroundImage: `url('${previewPicture}')` } : {};
  }

  render() {
    const { lang } = this.props.appState;

    const album = this.props.album;
    const relativePath = this.props.relativePath;
    const albumTitle =
      album.albumType === 'campaign'
        ? album.photoCampaign.titles[lang]
        : album.titles[Object.keys(album.titles)[0]];
    const hasPreview = album.previewPicture18 || album.previewPicture16;
    const statusClass = `mediamanagement__pic__overview__status--${album.status}`;

    return (
      <LinkStyled
        className="mediamanagement__pic__overview__item"
        to={`${relativePath}/${album.id}`}
        onClick={this.onClick}
      >
        <Col className="mediamanagement__pic__overview__preview">
          <div
            className="mediamanagement__pic__overview__preview__row"
            style={this.getPreviewStyle()}
          >
            {!hasPreview && (
              <Fragment>{_('mediamanagement:picfolder.common.preview.noPreview')}</Fragment>
            )}
          </div>
        </Col>

        <FlexCol className={`mediamanagement__pic__overview__status ${statusClass}`}>
          <div className="mediamanagement__pic__overview__status__row">
            {_(`mediamanagement:picfolder.common.status${album.status}`)}
          </div>

          {album.status === 'rejected' && (
            <article className="mediamanagement__pic__overview__status__rejected__frame">
              <pre>{album.rejectionReason[Object.keys(album.rejectionReason)[0]]}</pre>
            </article>
          )}
        </FlexCol>

        <FlexCol className="mediamanagement__pic__overview__name">
          <div className="mediamanagement__pic__overview__name__row">
            {typeof albumTitle !== 'undefined' ? albumTitle.text : ''}
          </div>
        </FlexCol>

        <FlexCol className="mediamanagement__pic__overview__uploaded">
          <div className="mediamanagement__pic__overview__uploaded__row">
            <FormatDate value={album.uploaded} />
          </div>
        </FlexCol>

        <FlexCol className="mediamanagement__pic__overview__ratings">
          <StarRating id={album.id} value={Math.round(getRating(album.rating))} />
          <div className="mediamanagement__pic__overview__ratings__row">{album.totalFeedbacks}</div>
        </FlexCol>

        {this.props.albumType === 'shop' && (
          <FlexCol className="mediamanagement__pic__overview__sales">
            <div className="mediamanagement__pic__overview__sales__row">{album.salesTotal}</div>
          </FlexCol>
        )}
        {this.props.albumType === 'shop' && (
          <FlexCol className="mediamanagement__pic__overview__price">
            <div className="mediamanagement__pic__overview__price__row">
              <FormatCurrency value={album.price} />
            </div>
          </FlexCol>
        )}
      </LinkStyled>
    );
  }
}

OverviewItem.propTypes = {
  album: PropTypes.object.isRequired,
  relativePath: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

OverviewItem.defaultProps = {
  onClick: () => {},
};

export default withAppState(OverviewItem);
