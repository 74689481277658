import React, { FC } from 'react';
import { _ } from '../../../util/translate';
import InputField from '../../../atoms/InputField/InputField';
import Button from '../../../atoms/Button/Button';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_SERVICE0900_DELETE_CAROUSEL_NUMBER } from '../../../graphql/VXModels/mutations';
import { Mutation } from '../../../graphql/VXModels/types';
import { QUERY_MODEL_SERVICE0900 } from '../../../graphql/VXModels/queries';
import AlertsStore from '../../../stores/Alerts/AlertsStore';
import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'apollo-link';

const updateAfterDeletedNumber = (cache: DataProxy, { data }: FetchResult<Mutation>): void => {
  const targetNumbers = data?.model?.service0900?.deleteCarouselNumber?.data?.targetNumbers;
  if (targetNumbers) {
    const cachedData = cache.readQuery({ query: QUERY_MODEL_SERVICE0900 });
    const mergedData = {
      // no mergeDeep or else old (deleted) Number survives in cache
      model: {
        service0900: {
          ...cachedData?.model?.service0900,
          phoneCarousel: { targetNumbers, __typename: 'Service0900PhoneCarouselNumber' },
          __typename: 'Service0900PhoneCarousel',
        },
        __typename: 'Service0900',
      },
      __typename: 'Model',
    };
    cache.writeQuery({
      query: QUERY_MODEL_SERVICE0900,
      data: mergedData,
    });
  }
};

interface Props {
  targetNumber: string;
  index: number;
}

const UneditablePhoneNumbers: FC<Props> = ({ targetNumber, index }) => {
  const [deleteCarouselNumber, { loading }] = useMutation(
    MUTATION_MODEL_SERVICE0900_DELETE_CAROUSEL_NUMBER,
    {
      update: updateAfterDeletedNumber,
    }
  );

  const onDelete = async (): Promise<void> => {
    const { errors } = await deleteCarouselNumber({ variables: { phoneNumber: targetNumber } });
    if (Array.isArray(errors) && errors.length > 0) {
      AlertsStore.add({
        type: 'error',
        message: _('common:alerts.deleteFail'),
      });
    }
  };

  return (
    <>
      <div css={{ marginTop: '6px' }}>
        <p css={{ padding: 4, fontWeight: 'bold' }}>
          {_('service0900:PhoneSex.carousel.number', {
            sprintf: [index + 1],
          })}
        </p>
      </div>
      <div css={{ width: '100%' }}>
        <InputField
          value={targetNumber}
          disabled={true}
          placeholder="+491234567890"
          css={{
            padding: '2px 10px',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <Button
          css={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, whiteSpace: 'nowrap' }}
          theme={'blue'}
          onClick={(): Promise<void> => onDelete()}
          loading={loading}
        >
          {_('common:button.delete')}
        </Button>
      </div>
    </>
  );
};

export default UneditablePhoneNumbers;
