import cloneDeep from 'lodash/cloneDeep';
import AbstractComponent from '../../components/AbstractComponent';
import { AlertsStore } from '../../stores/Alerts/AlertsStore';
import {
  ProfileActionCreators,
  ProfileStore,
  PROFILE_COMMON_ABOUTYOU,
} from '../../stores/Profile/Profile';
import { SessionStore } from '../../stores/Session/Session';
import { scrollIntoView } from '../../util/scrollIntoView';
import { _ } from '../../util/translate';

class AbstractProfile extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      isFormValid: false,
      isLoading: true,
      displayErrors: false,
      errorInputNames: [],
      displayErrorInputs: [],
    };

    this.storeValues = ProfileStore.get(this.getResource());
    this.currentValues = this.getCurrentValuesFromStoreValues(this.storeValues);
    this.jumpedToHash = false;

    this.onValid = this.onValid.bind(this);
    this.onInvalid = this.onInvalid.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.rejectChanges = this.rejectChanges.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.showErrorFields = this.showErrorFields.bind(this);
    this.hasInputError = this.hasInputError.bind(this);
    this.getFormsyInput = this.getFormsyInput.bind(this);
  }

  componentWillMount() {
    this.getData();
  }

  componentWillUnmount() {
    ProfileStore.removeChangeListener(this.getResource(), this.onStoreChange);
  }

  componentDidMount() {
    this.formsyRef = this.refs.form;
  }

  componentDidUpdate() {
    const hash = this.props.location.hash;
    const element = hash ? document.querySelector(hash) : null;
    if (!this.jumpedToHash && !this.state.isLoading && element) {
      scrollIntoView(element);
      this.jumpedToHash = true;
    }
  }

  getData() {
    const resource = this.getResource();
    ProfileStore.addChangeListener(resource, this.onStoreChange);
    ProfileActionCreators.readResource(resource);
  }

  onStoreChange() {
    this.storeValues = ProfileStore.get(this.getResource());
    this.currentValues = this.getCurrentValuesFromStoreValues(this.storeValues);
    this.setState({ isLoading: false });
  }

  getResource() {
    return PROFILE_COMMON_ABOUTYOU;
  }

  onValid() {
    this.setState({
      isFormValid: true,
      displayErrorInputs: this.formsyRef.getDisplayErrorInputs(this.state.errorInputNames),
    });
  }

  onInvalid() {
    this.setState({
      isFormValid: false,
      displayErrorInputs: this.formsyRef.getDisplayErrorInputs(this.state.errorInputNames),
    });
  }

  onChange(currentValues, hasChanges) {
    this.currentValues = Object.assign(this.currentValues, currentValues);
    this.setState({
      hasChanges: hasChanges,
    });
  }

  showErrorFields() {
    this.displayErrorFields();
    window.scrollTo(0, 0);
    AlertsStore.add({
      type: 'error',
      message: _('common:alerts.profile.formInvalid'),
    });
  }

  displayErrorFields() {
    const errorInputNames = this.formsyRef.getErrorInputNames();
    const nextState = {
      errorInputNames: errorInputNames,
      displayErrorInputs: this.formsyRef.getDisplayErrorInputs(errorInputNames),
    };

    if (this.state.displayErrors === false) {
      nextState.displayErrors = true;
    }

    this.setState(nextState);
  }

  getTextErrorMessage(textType) {
    let errMessage = '';
    if (
      this.state.displayErrorInputs[textType] != '' &&
      this.state.displayErrorInputs[textType] != undefined
    ) {
      errMessage = this.state.displayErrorInputs[textType];
    } else {
      if (this.storeValues[textType].hasTexts && this.storeValues[textType].ratingStatus == 'new') {
        errMessage = _('profiles:textRatingStatus.new');
      }
      if (
        this.storeValues[textType].hasTexts &&
        this.storeValues[textType].ratingStatus == 'rejected'
      ) {
        errMessage = _('profiles:textRatingStatus.rejected');
        if (this.storeValues[textType].rejectionReason) {
          errMessage +=
            ' (' +
            _('profiles:textRejectionReason.' + this.storeValues[textType].rejectionReason) +
            ')';
        }
      }
    }
    return errMessage;
  }

  getPropertyErrorMessage(propType) {
    let errMessage = '';
    if (
      this.state.displayErrorInputs[propType] != '' &&
      this.state.displayErrorInputs[propType] != undefined
    ) {
      errMessage = this.state.displayErrorInputs[propType];
    } else {
      if (propType == 'flNickname' || propType == 'laNickname') {
        if (this.storeValues[propType] != '') {
          if (this.storeValues[propType + 'Status'] == 'new') {
            errMessage = _('profiles:nicknameStatus.new');
          }
          if (this.storeValues[propType + 'Status'] == 'rejected') {
            errMessage = _('profiles:nicknameStatus.rejected');
          }
        }
      }
    }
    return errMessage;
  }

  hasInputError(inputName) {
    return this.state.displayErrorInputs[inputName] !== undefined;
  }

  submitForm(currentValues) {
    // modelHeight/partnerHeight ist in Meter ABER Backend erwartet es in Centimeter.
    if (currentValues.modelHeight) {
      currentValues.modelHeight = currentValues.modelHeight * 100;
    }

    if (currentValues.partnerHeight) {
      currentValues.partnerHeight = currentValues.partnerHeight * 100;
    }
    //DeepL
    if (currentValues?.vxShowText?.translated !== undefined) {
      currentValues.vxShowText = { translate: currentValues?.vxShowText?.translated };
    }

    this.setState({ isLoading: true, displayErrors: false });
    // console.log(this.__proto__.constructor.name,'submitForm', currentValues);
    ProfileActionCreators.updateResource(
      this.getResource(),
      { data: currentValues },
      (response) => {
        window.scrollTo(0, 0);
        AlertsStore.add({
          type: 'info',
          message: _('common:alerts.saveSuccess'),
        });

        // update SessionStore after successful saving of LA Settings
        if (currentValues.laSettingsProfileActive !== undefined) {
          SessionStore.update({ profile: { isLAProfileActive: response.laSettingsProfileActive } });
        }

        // update SessionStore after successful saving of FL Settings
        if (currentValues.flSettingsProfileActive !== undefined) {
          SessionStore.update({ profile: { isFLProfileActive: response.flSettingsProfileActive } });
        }
        this.getData();
        this.setState({ isLoading: false });
      },
      (err, response) => {
        AlertsStore.add({
          type: 'error',
          message: _('common:alerts.saveFail'),
        });
        this.setState({ isLoading: false });
        console.warn('save failed', err, response);
      }
    );
  }

  rejectChanges() {
    window.scrollTo(0, 0);
    this.formsyRef.reset(
      this.getCurrentValuesFromStoreValues(ProfileStore.get(this.getResource()))
    );
  }

  updateFormWithValues(nextValues) {
    let hasUpdates = false;
    for (let input of this.formsyRef.inputs) {
      const name = input.props.name;
      if (Object.keys(nextValues).indexOf(name) !== -1) {
        input.setValue(nextValues[name]);
        this.currentValues[name] = nextValues[name];
        hasUpdates = true;
      }
    }
    if (hasUpdates) {
      this.forceUpdate();
    }
  }

  getCurrentValuesFromStoreValues(storeValues) {
    // Backend liefert modelHeight/partnerHeight in Centimeter ABER wir wollen Meter anzeigen.
    storeValues.modelHeight = storeValues.modelHeight / 100;
    storeValues.partnerHeight = storeValues.partnerHeight / 100;

    let currentValues = {};
    if (this.formsyRef) {
      const inputNames = this.getInputNames().concat(this.formsyRef.prevInputNames);
      for (let inputName of inputNames) {
        if (storeValues[inputName] !== undefined) {
          currentValues[inputName] = storeValues[inputName];
        }
      }
    } else {
      currentValues = cloneDeep(storeValues);
    }
    return currentValues;
  }

  getInputNames() {
    return [];
  }

  getFormsyInput(inputName) {
    if (this.formsyRef) {
      for (let input of this.formsyRef.inputs) {
        if (input.props.name === inputName) {
          return input;
        }
      }
    }
  }

  render() {
    // console.log(this.__proto__.constructor.name, 'render', this);
    this.activateSaveButton = this.state.isFormValid && this.state.hasChanges;
    this.activateResetButton = this.state.hasChanges;
    this.debugData = {
      hasChanges: this.state.hasChanges,
      isFormValid: this.state.isFormValid,
      isLoading: this.state.isLoading,
      activateSaveButton: this.activateSaveButton,
      activateResetButton: this.activateResetButton,
    };
  }
}

export default AbstractProfile;
