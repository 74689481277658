import React, { FC, ReactElement } from 'react';
import { _ } from '../../../../../util/translate';
import { Field, FormikValues } from 'formik';
import { InputField } from '../../../../../atoms';

interface Props {
  disabled?: boolean;
  className?: string;
}

const PhoneNumberInput: FC<Props> = ({ disabled = false, className }) => {
  return (
    <div css={{ flexDirection: 'column' }} className={className}>
      <span css={{ fontWeight: 'bold', marginBottom: '6px' }}>
        {_('service0900:PhoneSex.modal.request.number.target')}
      </span>
      <Field name={'targetNumber.phoneNumber'}>
        {({ field, form: { status, errors } }: FormikValues): ReactElement => (
          <InputField
            type="text"
            {...field}
            disabled={disabled}
            errorMessage={
              [status?.globalError, errors?.targetNumber?.phoneNumber]
                .filter((msg) => !!msg)
                .join(' ') || undefined
            }
          />
        )}
      </Field>
    </div>
  );
};

export default PhoneNumberInput;
