import React, { FC } from 'react';
import { LeftMenuGroup } from '../../components';
import { user } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';
import {
  CommonProfileItem,
  LustagentenProfileItem,
  ProfileOverviewItem,
  VisitXProfileItem,
} from '../items';

type TProps = {
  isFeatureFlagCoinsEnabled: boolean;
}

const ProfileGroup: FC<TProps> = ({ isFeatureFlagCoinsEnabled }) => {
  return isFeatureFlagCoinsEnabled ? (
    <LustagentenProfileItem/>
  ) : (
    <LeftMenuGroup title={_('navigation:main.profile.header')} icon={user}>
      {!isFeatureFlagCoinsEnabled && <ProfileOverviewItem/>}
      {!isFeatureFlagCoinsEnabled && <CommonProfileItem/>}
      {!isFeatureFlagCoinsEnabled && <VisitXProfileItem/>}
      <LustagentenProfileItem/>
    </LeftMenuGroup>
  );
};

export default ProfileGroup;
