import React, { ReactElement } from 'react';

const PendingIcon = (): ReactElement => {
  return (
    <g id="ausstehend" transform="translate(-1642 -4404)">
      <g
        id="Kreis"
        transform="translate(1642 4404)"
        fill="#ffb033"
        stroke="#ffb033"
        strokeWidth="2"
      >
        <circle cx="21" cy="21" r="21" stroke="none" />
        <circle cx="21" cy="21" r="20" fill="none" />
      </g>
      <path
        id="Pfad_11713"
        data-name="Pfad 11713"
        d="M1004.088,990.118v16.345a1.12,1.12,0,0,0,1.172,1.166h11.67a1.154,1.154,0,0,0,.845-.327,1.11,1.11,0,0,0,.327-.839v-2.338a1.128,1.128,0,0,0-.327-.839,1.156,1.156,0,0,0-.845-.327h-8.172V990.118a1.122,1.122,0,0,0-.327-.839,1.137,1.137,0,0,0-.845-.327h-2.326a1.12,1.12,0,0,0-1.172,1.166Z"
        transform="translate(654.912 3424.048)"
        fill="#fff"
      />
    </g>
  );
};

export default PendingIcon;
