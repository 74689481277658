import React, { FC } from 'react';
import GenericModalBoxLayout, {
  BREAKPOINT_SMALL,
} from '../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import PromotionalContent from './PromotionalContent/PromotionalContent';
import OptInSection from './OptInSection';
import styled from '@emotion/styled';
import { DARK_GRAY } from '../../../camtool-styles';

const ModalContainer = styled.div`
  padding: 40px 57px 68px;
  flex-direction: column;
  color: ${DARK_GRAY};

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 29px 23px 47px;
  }
`;

interface IProps {
  closable?: boolean;
  onClose?: () => void;
}

const ModalDailyBonusOptIn: FC<IProps> = ({ closable, onClose }) => (
  <GenericModalBoxLayout
    name={'daily-bonus-opt-in'}
    zIndex={1000} // zIndex 1000 to beat notifications zIndex of 999
    onClose={closable ? onClose : undefined}
    noCloseOnESC={true}
  >
    <ModalContainer>
      <PromotionalContent />
      <OptInSection closable={closable} onClose={onClose} />
    </ModalContainer>
  </GenericModalBoxLayout>
);

export default ModalDailyBonusOptIn;
