import React, { FC } from 'react';
import Modalbox from '../Modalbox/Modalbox';
import styled from '@emotion/styled';
import Icon from '../../atoms/Icon/Icon';
import { WHITE, DARK_GRAY } from '../../camtool-styles';
import { remove } from '../../atoms/Icon/libraries/glyph';
import { modalboxMargin } from '../Modalbox/Modalbox';

export const BREAKPOINT_SMALL = 640;
export const BREAKPOINT_MD = 960;

const ModalContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  border-radius: 2px;
  background: ${WHITE};
  width: 100%;
  max-width: 100vw;
  margin: ${modalboxMargin}px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 0;
  }
`;

const ModalContainerBackgroundTransparent = styled.div`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  border-radius: 2px;
  background: transparent;
  width: 100%;
  max-width: 100vw;
  margin: ${modalboxMargin}px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin: 0;
  }
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  padding: 13px;
  top: 0;
  right: 0;
  cursor: pointer;
  color: ${DARK_GRAY};

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 8px;
  }
  @media (max-width: ${BREAKPOINT_MD}px) {
    color: white;
  }
`;

const StyledModalbox = styled(Modalbox)`
  max-width: ${(props): number => props.maxWidth + modalboxMargin * 2}px;
  padding: 0;
  overflow-y: auto;
  display: flex;
`;

interface Props {
  name: string;
  maxWidth?: number;
  noCloseOnESC?: boolean;
  zIndex?: number;
  hasTransparentBackground?: boolean;
  onClose?: () => void;
}

const GenericModalBoxLayout: FC<Props> = ({
  name,
  maxWidth = 830,
  noCloseOnESC = false,
  zIndex,
  onClose,
  hasTransparentBackground,
  children,
}) => {
  const onCloseClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    onClose?.();
  };

  const Content = React.useMemo(() => {
    return (
      <>
        {children}
        {!!onClose && <CloseIcon icon={remove} size="17px" onClick={onCloseClick} />}
      </>
    );
  }, [children, onClose]);

  return (
    <StyledModalbox
      noCloseOnESC={noCloseOnESC}
      onKeyPressESC={onClose}
      maxWidth={maxWidth}
      name={`modal-${name}`}
      state={true}
      zIndex={zIndex}
    >
      {hasTransparentBackground ? (
        <ModalContainerBackgroundTransparent>{Content}</ModalContainerBackgroundTransparent>
      ) : (
        <ModalContainer>{Content}</ModalContainer>
      )}
    </StyledModalbox>
  );
};

export default GenericModalBoxLayout;
