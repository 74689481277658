import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IAboInstance } from '../../types';
import { _ } from '../../../../util/translate';
import { EmptyContent } from '../../../../components';
import InstancesTable from './Table/InstancesTable';

interface Props {
  instances: IAboInstance[] | undefined;
}

const ContainerBase = styled.div`
  margin-top: 40px;
  padding-right: 12px;
  max-width: 550px;
  width: 100%;
`;

const EmptyContentContainer = styled(ContainerBase)`
  flex: 0 0 auto;
  margin: auto 0px;
  padding-right: 0px;
`;

interface InstancesContainerProps {
  instanceCount: number;
}

const InstancesContainer = styled(ContainerBase)<InstancesContainerProps>`
  flex-direction: column;
  overflow: hidden;
  flex: ${({ instanceCount }): string => (instanceCount > 5 ? '1 1 250px' : '1 1 auto')};
`;

const Instances: FC<Props> = ({ instances }) =>
  Array.isArray(instances) && instances.length > 0 ? (
    <InstancesContainer instanceCount={instances.length}>
      <InstancesTable instances={instances} />
    </InstancesContainer>
  ) : (
    <EmptyContentContainer>
      <EmptyContent
        title={_('benefits:abo.text.noSubs')}
        icon="icon-group"
        css={{ marginTop: 38 }}
      />
    </EmptyContentContainer>
  );

export default Instances;
