import { ApolloClient } from 'apollo-client';

import {
  GENERATE_BONUS_CODE_CODE,
  QUERY_BONUS_CODE,
  QUERY_BONUS_CODE_AVAILABLE,
  QUERY_BONUS_CODE_LIST,
} from '../queries';
import {
  MUTATION_CREATE_CODE,
  MUTATION_DEACTIVATE_CODE,
  MUTATION_SAVE_CONFIGURATION,
} from '../mutations';

const useBonusCode = (bonusCodeClient: ApolloClient<Record<string, unknown>>) => {
  /** Check if a given code is available */
  const isCodeAvailable = async (code: string) => {
    const response = await bonusCodeClient.query({
      query: QUERY_BONUS_CODE_AVAILABLE,
      variables: { code },
      fetchPolicy: 'network-only',
    });

    return response.data.builder.isCodeAvailable;
  };

  /** Create the bonus-code config
   * CAREFUL: It has 'create' in its name but this can also save and delete bonus-codes!
   */
  const createConfig = async (config: any) => {
    const response = await bonusCodeClient.mutate({
      mutation: MUTATION_SAVE_CONFIGURATION,
      variables: { ...config },
      fetchPolicy: 'no-cache',
    });

    // return config id
    return (
      (response.data &&
        response.data.config &&
        response.data.config.save &&
        response.data.config.save.id) ||
      -1
    );
  };

  /** Create a bonus-code */
  const createCode = async (configId: number, code: string) => {
    const response = await bonusCodeClient.mutate({
      mutation: MUTATION_CREATE_CODE,
      variables: { configId, code },
      fetchPolicy: 'no-cache',
    });
    return response.data && response.data.config && response.data.config.createCode;
  };

  /** Request a list of all the vouchers of the logged-in user */
  const getCodesList = async () => {
    const response = await bonusCodeClient.query({
      query: QUERY_BONUS_CODE_LIST,
      fetchPolicy: 'network-only',
    });
    return response.data && response.data.bonusCodes;
  };

  /** Request a specific voucher of the logged-in user */
  const getSingleCode = async (code: string) => {
    const response = await bonusCodeClient.query({
      query: QUERY_BONUS_CODE,
      variables: {
        code: code,
      },
      fetchPolicy: 'network-only',
    });
    return response.data && response.data.bonusCode;
  };

  /** Request a `random` but valid bonus-code */
  const generateCode = async (length?: number) => {
    const response = await bonusCodeClient.query({
      query: GENERATE_BONUS_CODE_CODE,
      variables: { length },
      fetchPolicy: 'network-only',
    });
    return response.data.builder.generateCode;
  };

  /** deactivate bonus code by changing status to inactive */
  const deactivateCode = async (code: string) => {
    const response = await bonusCodeClient.mutate({
      mutation: MUTATION_DEACTIVATE_CODE,
      variables: { code },
      fetchPolicy: 'no-cache',
    });
    return response?.data?.config?.deactivateCode;
  };

  return {
    generateCode,
    getCodesList,
    getSingleCode,
    createCode,
    createConfig,
    isCodeAvailable,
    deactivateCode,
  };
};

export default useBonusCode;
