import React, { FC, ReactNode } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { NavSub, NavSubItem } from '../../components';
import { SELECT_ID } from './constants';
import { _ } from '../../util/translate';
import { CardHeaderInverted, Icon } from '../../atoms';
import { BroadcastList, BroadcastShow } from '../../packages/Telegram';
import { Card, CardContent, Container, Grid, Box, Typography } from '@material-ui/core';
import { useStyleFix } from '../../app/Theme/stylefix';
import {
  pinFilledBlue,
  pinFilledGrey,
} from '../../packages/Telegram/components/BroadcastList/assets/icons/index';
import { useTelegramUnpinAll, useUnpinable } from '../../packages/Telegram/hooks/index';
import { useTelegramUnpinAllStyles } from './styles';

const SPACING = 3;

const BroadcastArchive: FC = () => {
  const { path } = useRouteMatch();
  const [unpinAllMessages, { data }] = useTelegramUnpinAll();
  const isUnpinable = useUnpinable();
  const buildUrl = (id: number) => `${path}/${id}`;
  const { gridBoundries, containerBoundries } = useStyleFix({ spacing: SPACING });
  const classes = useTelegramUnpinAllStyles();
  const unpinButtonEnabled = isUnpinable.isUnpinable;

  return (
    <>
      <NavSub>
        <NavSubItem
          link={path}
          icon="icon-folder-new"
          label={_('telegram:BroadcastArchive.nav.archive')}
        />
        <Route path={path + SELECT_ID}>
          {({ match }): ReactNode =>
            match ? (
              <NavSubItem
                link={path + '/' + match.params.id}
                icon="icon-inbox"
                label={_('telegram:BroadcastArchive.nav.archiveMessage', {
                  sprintf: { id: match.params.id },
                })}
              />
            ) : null
          }
        </Route>
      </NavSub>
      <Container
        disableGutters
        classes={{ root: containerBoundries }}
        style={{ marginBottom: '100px' }}
      >
        <Switch>
          <Route exact path={path}>
            <Card square variant={'elevation'} elevation={0}>
              <CardHeaderInverted title={_('telegram:BroadcastArchive.sectionTitle.archive')} />
              {unpinButtonEnabled ? (
                <Box className={classes.container} onClick={() => unpinAllMessages()}>
                  <Icon className={classes.icon} icon={pinFilledBlue} />
                  <Typography className={classes.text}>
                    {_('mailings:telegram.sticky.unpinAll')}
                  </Typography>
                </Box>
              ) : (
                <Box className={classes.containerDisabled}>
                  <Icon className={classes.icon} icon={pinFilledGrey} />
                  <Typography className={classes.textDisabled}>
                    {_('mailings:telegram.sticky.unpinAll')}
                  </Typography>
                </Box>
              )}
              <CardContent classes={{ root: gridBoundries }} style={{ minHeight: 300 }}>
                <Grid xs>
                  <BroadcastList
                    buildUrl={buildUrl}
                    unpinned={data?.master?.unpinMessages === true}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Route>

          <Route path={path + SELECT_ID}>
            {({
              match: {
                params: { id: rawId },
              },
            }) => {
              const id = Number(rawId);
              return (
                <Card square variant={'elevation'} elevation={0}>
                  <CardHeaderInverted
                    title={_('telegram:BroadcastArchive.sectionTitle.archiveMessage', {
                      sprintf: { id },
                    })}
                  />
                  <CardContent classes={{ root: gridBoundries }} style={{ minHeight: 300 }}>
                    <BroadcastShow id={id} />
                  </CardContent>
                </Card>
              );
            }}
          </Route>

          <Redirect to={path} />
        </Switch>
      </Container>
    </>
  );
};

export default BroadcastArchive;
