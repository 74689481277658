/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import TagManager from 'react-gtm-module';
import { APP_IS_DEVELOPMENT } from './env';

const DEBUG = APP_IS_DEVELOPMENT && true;

const GoogleTagManager = memo(({ gtmId, userId }) => {
  if (!gtmId) {
    DEBUG && console.warn("Google Tag Manager ID not set, won't init it.");
    return;
  }

  TagManager.initialize({
    gtmId: gtmId,
    dataLayer: {
      user_id: userId,
    },
  });

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };

  // set consent defaults
  gtag('consent', 'default', {
    necessary_storage: 'granted',
    functional_storage: 'denied',
    analytics_storage: 'denied',
  });

  return false;
});

GoogleTagManager.displayName = 'GoogleTagManager';

const setGtmConsent = (values) => {
  DEBUG && console.log('Set GTM consent:', values);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  gtag('consent', 'update', values);
};

export default GoogleTagManager;
export { setGtmConsent };
