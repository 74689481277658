import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import * as React from 'react';
import * as L from 'leaflet';
import random from 'lodash/random';
import 'leaflet/dist/leaflet.css';

const DEFAULT_GEOPOINT = {
  boundingBox: {
    latSouth: 45.0,
    latNorth: 71.5,
    lonWest: -25.5,
    lonEast: 34.5,
  },
  latitude: 51.35149,
  longitude: 10.45412,
  rendered: true,
};

class GeoMap extends AbstractComponent {
  constructor(props) {
    super(props);
    this.mapName = '';
    this.maps = {};
    this.state = {
      showMap: false,
    };
  }

  componentWillMount() {
    this.mapName = 'geomap_' + random(0, 5000);
  }

  componentDidMount() {
    const layers = [
      L.tileLayer('//maps.maptilehoster.com/' + this.props.layer + '/{z}/{x}/{y}.png'),
    ];

    this.maps[this.mapName] = L.map(this.mapName, {
      center: [DEFAULT_GEOPOINT.latitude, DEFAULT_GEOPOINT.longitude],
      zoom: 6,
      maxZoom: 9,
      layers: layers,
    });

    // for display rendered rectagle
    // L.rectangle([
    //     [DEFAULT_GEOPOINT.boundingBox.latNorth, DEFAULT_GEOPOINT.boundingBox.lonEast],
    //     [DEFAULT_GEOPOINT.boundingBox.latSouth, DEFAULT_GEOPOINT.boundingBox.lonWest]
    // ], {color: "#ff7800", weight: 1}).addTo(this.maps[this.mapName]);

    const map = this.maps[this.mapName];
    const postalCodeSearch =
      this.props.geoData.search &&
      this.props.geoData.search.postalCode &&
      this.props.geoData.result.length > 0;

    let geoPoint = DEFAULT_GEOPOINT;
    if (postalCodeSearch) {
      geoPoint = this.props.geoData.result[0].geoPoint || DEFAULT_GEOPOINT;
    } else if (this.props.geoData.result) {
      geoPoint = this.props.geoData.result.geoPoint || DEFAULT_GEOPOINT;
    }

    if (map) {
      const bounds = L.latLngBounds(
        L.latLng(geoPoint.boundingBox.latNorth, geoPoint.boundingBox.lonEast),
        L.latLng(geoPoint.boundingBox.latSouth, geoPoint.boundingBox.lonWest)
      );

      if (!!this.marker) {
        map.removeLayer(this.marker);
      }

      if (postalCodeSearch) {
        this.marker = L.circle(L.latLng(geoPoint.latitude, geoPoint.longitude), 1000, {
          color: 'red',
          fillColor: '#f03',
          fillOpacity: 0.5,
        }).addTo(map);
      }

      map.setView(L.latLng(geoPoint.latitude, geoPoint.longitude), map.getBoundsZoom(bounds));
      // map.fitBounds(bounds);
    }
  }

  componentWillReceiveProps(nextProps) {
    const map = this.maps[this.mapName];
    const postalCodeSearch =
      nextProps.geoData.search &&
      nextProps.geoData.search.postalCode &&
      nextProps.geoData.result.length > 0;

    if (this.props.geoData !== nextProps.geoData) {
      let geoPoint;
      if (postalCodeSearch) {
        geoPoint = nextProps.geoData.result[0].geoPoint;
      } else if (nextProps.geoData.result) {
        geoPoint = nextProps.geoData.result.geoPoint || DEFAULT_GEOPOINT;
      }

      if (map) {
        const bounds = L.latLngBounds(
          L.latLng(geoPoint.boundingBox.latNorth, geoPoint.boundingBox.lonEast),
          L.latLng(geoPoint.boundingBox.latSouth, geoPoint.boundingBox.lonWest)
        );

        if (!!this.marker) {
          map.removeLayer(this.marker);
        }

        if (postalCodeSearch) {
          this.marker = L.circle(L.latLng(geoPoint.latitude, geoPoint.longitude), 1000, {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.5,
          }).addTo(map);
        }

        map.setView(L.latLng(geoPoint.latitude, geoPoint.longitude), map.getBoundsZoom(bounds));
        // map.fitBounds(bounds);
      }
    }
  }

  render() {
    const style = { width: '100%', height: '350px' };
    return (
      <div className="geomap-country-postalcode">
        <div id={this.mapName} style={style} />
      </div>
    );
  }
}

GeoMap.propTypes = {
  geoData: PropTypes.object.isRequired,
  layer: PropTypes.oneOf(['osm', 'vxone']),
};

GeoMap.defaultProps = {
  layer: 'vxone',
};

GeoMap.debug = false;

export default GeoMap;
export { GeoMap };
