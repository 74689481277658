import React, { FC, useState } from 'react';
import { _ } from '../../../../util/translate';
import Icon from '../../../../atoms/Icon/Icon';
import { bin } from '../../../../atoms/Icon/libraries/glyph';
import AutoMailDeleteModal from './AutoMailDeleteModal';
import Button from '../../../../atoms/Button/Button';
import { WHITE, GRAY_3 } from '../../../../camtool-styles';

interface Props {
  deleteAutoMailRow: (index: number) => void;
  index: number;
}

const AutoMailDelete: FC<Props> = React.memo(({ deleteAutoMailRow, index }) => {
  const [isModalOpen, setIsOpenModal] = useState(false);
  const openModal = (): void => {
    setIsOpenModal(true);
  };
  const closeModal = (): void => {
    setIsOpenModal(false);
  };

  return (
    <div css={{ color: GRAY_3 }}>
      <Button
        css={{ background: WHITE, borderColor: GRAY_3 }}
        theme={'border-gray'}
        onClick={openModal}
      >
        <Icon icon={bin} size="10px" css={{ marginRight: '6px', color: GRAY_3 }} />
        <span>{_('common:button.delete')}</span>
      </Button>
      {isModalOpen && (
        <AutoMailDeleteModal
          deleteAutoMailRow={deleteAutoMailRow}
          index={index}
          closeModal={closeModal}
        />
      )}
    </div>
  );
});

AutoMailDelete.displayName = 'AutoMailDelete';

export default AutoMailDelete;
