import React, { FC } from 'react';
import { IAboDetailsPageForm } from '../../types';
import { useLang } from '../../../../util/AppState';
import { _ } from '../../../../util/translate';

interface Props {
  formValues: IAboDetailsPageForm;
}

const AboReviewOverview: FC<Props> = ({ formValues }) => {
  const [lang] = useLang();

  return (
    <div
      css={{
        flexDirection: 'column',
        paddingTop: 12,
        justifyContent: 'center',
        maxWidth: '310px',
      }}
    >
      <p css={{ marginBottom: 8 }}>
        <strong>{_('benefits:abo.reviewPage.nameLabel')}:&nbsp;</strong>
        <span>{formValues.nameTranslation[lang]}</span>
      </p>

      <p css={{ marginBottom: 8 }}>
        <strong>{_('benefits:abo.reviewPage.descriptionLabel')}:&nbsp;</strong>
        <span>{formValues.descriptionTranslation[lang] || '-'}</span>
      </p>
      <p css={{ marginBottom: 8 }}>
        <strong>{_('benefits:abo.reviewPage.priceLabel')}:&nbsp;</strong>
        <span>
          {formValues.price} € (
          {formValues.billingPeriod > 1
            ? _('benefits:abo.reviewPage.forLength', {
                sprintf: [formValues.billingPeriod],
              })
            : formValues.billingPeriod + ' ' + _('benefits:texts.day')}
          )
        </span>
      </p>

      <p css={{ marginBottom: 8 }}>
        <strong>{_('benefits:abo.reviewPage.noteLabel')}:&nbsp;</strong>
        <span>
          {formValues.autoRenew
            ? _('benefits:abo.reviewPage.renewable', {
                sprintf: [formValues.billingPeriod],
              })
            : _('benefits:abo.reviewPage.nonRenewable')}
        </span>
      </p>
    </div>
  );
};

export default AboReviewOverview;
