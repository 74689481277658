import AppUtils from './AppUtils';
import * as http from 'superagent';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';
import AppDispatcher from './dispatcher/AppDispatcher';
import * as ActionTypes from './constants/ActionTypes';
import { APP_API_HOST, APP_IS_DEVELOPMENT, APP_SCOPE, REACT_APP_DEV_PHPSTORM_DEBUG } from './env';

class ServerUtilsClass {
  constructor() {
    this.initialized = false;
    this.options = {
      baseUrl: APP_API_HOST,
      token: '',
    };
    this.routeParams = {};
    this.resolveUriPlaceholders = this.resolveUriPlaceholders.bind(this);
    this.setRouteParams = this.setRouteParams.bind(this);
    this.updateToken = this.updateToken.bind(this);
  }

  updateToken(token) {
    this.options = { ...this.options, token };
  }

  resolveUriPlaceholders(uri, routeParams) {
    templateSettings.interpolate = /{([\s\S]+?)}/g;
    if (!this.routeParams.userId) {
      throw { msg: 'userId not set!!!', params: this.routeParams, uri: uri };
    }
    uri = template(uri)({ ...routeParams, ...this.routeParams });

    return uri;
  }

  setRouteParams(routeParams) {
    this.routeParams = { ...routeParams, routeParams };
  }

  init(options) {
    if (!this.initialized) {
      this.options = Object.assign(this.options, options);
      AppDispatcher.register(({ type }) => {
        switch (type) {
          case ActionTypes.CAMTOOL_RELOAD:
            AppUtils.forceReload();
            break;
        }
      });
      this.initialized = true;
    }
  }

  request(method, uri, params, onSuccess, onError) {
    let request;

    // normalise params
    params = params || {};
    params.options = params.options || { useResponseData: true, blob: false };
    params.query = { scope: APP_SCOPE, ...params.query };
    params.routeParams = params.routeParams || {};
    params.data = params.data || {};

    uri = this.resolveUriPlaceholders(uri, params.routeParams);

    if (REACT_APP_DEV_PHPSTORM_DEBUG && APP_IS_DEVELOPMENT) {
      params.query['XDEBUG_SESSION_START'] = REACT_APP_DEV_PHPSTORM_DEBUG;
    }

    try {
      switch (method.toString().toUpperCase()) {
        case 'GET':
          request = http.get(this.options.baseUrl + uri);
          break;
        case 'PUT':
          request = http
            .put(this.options.baseUrl + uri, params.data)
            .set('Content-Type', 'application/json');
          break;
        case 'DELETE':
          request = http.del(this.options.baseUrl + uri).set('Content-Type', 'application/json');
          break;
        case 'POST':
          request = http
            .post(this.options.baseUrl + uri, params.data)
            .set('Content-Type', 'application/json');
          break;
      }

      if (params) {
        request.query(params.query);
      }

      const token = this.options.token;

      if (!token) {
        throw new Error('Token not set yet');
      }

      if (params.options.blob) {
        request.responseType('blob');
      } else {
        request.set('Accept', 'application/json');
      }

      request.set('Authorization', 'Bearer ' + token).withCredentials();

      request.end((err, res) => {
        if (!err) {
          if (onSuccess) {
            if (params.options.useResponseData === true) {
              onSuccess(res.body && res.body.data);
            } else {
              onSuccess(res.body);
            }
          }
        } else {
          if (res) {
            if (res.status === 304) {
              err = null;
              onSuccess({});
            }
            if (res.status === 401 || res.status === 403) {
              console.info('got 401|403 -> logout');
              AppUtils.forceLogout();
            }
          }

          if (err && onError) {
            if (params.options.useResponseData === true) {
              onError(err, res && res.body.data);
            } else {
              onError(err, res.body);
            }
          }
        }
      });
    } catch (e) {
      console.info('userId undefined -> logout', e);
      AppUtils.forceLogout();
    }
  }

  download(data, filename, type) {
    let file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) {
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      // Others
      let a = document.createElement('a'),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
}

const ServerUtils = new ServerUtilsClass();

export default ServerUtils;
