import gql from 'graphql-tag';

// This user fragment originally contained tipping and message fragments but this nesting of fragments (based on other types) caused an error
// for readFragment in update function of setMessagesRead mutation. Providing fragmentName did not help either since fragments are based on different types
// therefore types that could have been fragments are manually written out now
export const FRAGMENT_USER = gql`
  fragment User on User {
    id
    # peerId
    # guestId
    # accountId
    # phoneNumber
    firstName
    lastName
    subscribed
    # subscribedSince
    premium
    # firstSeen
    # lastSeen
    online
    onlineUntil
    # typing
    unreadByMaster
    # unreadByUser
    blocked
    groupedChat(limit: 1) {
      date
      events {
        ... on Tipping {
          id
          amount
          currency
          read
          date
        }
        ... on Message {
          id
          direction
          in
          out
          type
          system
          text
          file {
            id
            uuid
            type
            exists
            url
            previewPictureUrl
            width
            height
            imgSrcSet
            transcodingFailed
            mimeType
          }
          timestamp
          read
          broadcast {
            userType
          }
          isPaidContent
          paidContent {
            fileType
            price
            text
          }
        }
      }
    }
    unreadTips
    tippingAmount(firstOfMonth: true)
    unreadTippingAmount
    rankBadge
  }
`;
