import React from 'react';
import { RenderColumn } from '../../../../atoms/MaterialTable/types';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';
import { GRAY_4 } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';

const RenderColumnRecipientGroups: RenderColumn<Broadcast> = ({ userType }) => {
  return userType ? (
    <span>{_('telegram:BroadcastList.column.recipientGroups.value.' + userType)}</span>
  ) : (
    <span css={{ color: GRAY_4, fontStyle: 'italic' }}>
      {_('telegram:BroadcastList.column.recipientGroups.empty')}
    </span>
  );
};
export default RenderColumnRecipientGroups;
