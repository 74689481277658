import React, { FC } from 'react';
import { User } from '../../../../graphql/VXServicesTelegram/types';
import Icon from '../../../../atoms/Icon/Icon';
import { banCircle } from '../../../../atoms/Icon/libraries/glyph';
import { RED, DARK_GRAY } from '../../../../camtool-styles';
import { useTelegramUnblockUser } from '../../hooks';
import { _ } from '../../../../util/translate';
import AlertsStore from '../../../../stores/Alerts/AlertsStore';

interface IProps {
  showModalWindow: () => void;
  selectedUser: User;
}

const ToolTipOptions: FC<IProps> = ({ showModalWindow, selectedUser }) => {
  const [unblockUser] = useTelegramUnblockUser({
    userId: selectedUser.id,
  });

  const onUnblock = async (): Promise<void> => {
    const { errors } = await unblockUser();
    if (Array.isArray(errors) && errors.length > 0) {
      AlertsStore.add({ type: 'error', message: _('telegram:chat.error.unblock') });
    }
  };

  const onClick = () => {
    if (!selectedUser.blocked) {
      showModalWindow();
    } else {
      onUnblock();
    }
  };

  return (
    <ul css={{ listStyle: 'none', color: DARK_GRAY }}>
      <li css={{ margin: '5px 11px', whiteSpace: 'nowrap' }} onClick={onClick}>
        {!selectedUser.blocked && (
          <Icon size="11px" css={{ color: RED, marginRight: '3px' }} icon={banCircle} />
        )}
        <span>
          {selectedUser.blocked
            ? _('telegram:chat.options.unblock')
            : _('telegram:chat.modal.button.block')}
        </span>
      </li>
    </ul>
  );
};

export default ToolTipOptions;
