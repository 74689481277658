import AppDispatcher from '../../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../../util/ServerUtils';
import ActionTypes from '../../../util/constants/ActionTypes';

class VXFavouritesActionCreatorsClass {
  getVXFavourites(sort, sortDirection, limit, offset) {
    let params =
      'sort=' + sort + '&sortDirection=' + sortDirection + '&limit=' + limit + '&offset=' + offset;
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/lists/vxfavourites/getdata?' + params,
      null,
      function(response) {
        if (offset > 1) {
          VXFavouritesActionCreators.appendData(response.userlist);
        } else {
          VXFavouritesActionCreators.receiveData({
            userlist: response.userlist,
            totalEntries: response.totalEntries,
          });
        }
      }
    );
  }

  receiveData(data) {
    AppDispatcher.dispatch({
      type: ActionTypes.LISTS_VXFAVOURITES_RECEIVE_DATA,
      data: data,
    });
  }

  appendData(data) {
    AppDispatcher.dispatch({
      type: ActionTypes.LISTS_VXFAVOURITES_APPEND_DATA,
      data: data,
    });
  }
}

const VXFavouritesActionCreators = new VXFavouritesActionCreatorsClass();

export { VXFavouritesActionCreators };
