import { Component } from 'react';

export interface IOption {
  value: string;
  label: string;
}

export type IOptions = IOption[];

interface IProps {
  children: any;
  allOptions: IOptions;
  currentOptions: IOptions;
}

interface IState {
  allOptions: IOptions;
  currentOptions: IOptions;
}

class MultipleOptions extends Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    allOptions: [],
    currentOptions: [],
  };

  public state = { allOptions: this.props.allOptions, currentOptions: this.props.currentOptions };

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    // update initial state when we get the data AFTER first render
    if (
      (prevProps.allOptions.length === 0 || prevProps.currentOptions.length === 0) &&
      (this.props.allOptions.length > 0 || prevProps.currentOptions.length > 0)
    ) {
      this.setState({
        allOptions: this.props.allOptions,
        currentOptions: this.props.currentOptions,
      });
    }
  }

  public addOption = (option: IOption, callback?: (currentOptions: IOptions) => void) => {
    for (const opt of this.state.allOptions) {
      if (opt.value === option.value) {
        this.setState({ currentOptions: [...this.state.currentOptions, option] }, () => {
          if (typeof callback === 'function') {
            callback(this.state.currentOptions);
          }
        });
        break;
      }
    }
  };

  public removeOption = (option: IOption, callback?: (currentOptions: IOptions) => void) => {
    if (this.state.currentOptions.length > 0) {
      this.setState(
        {
          currentOptions: this.state.currentOptions.filter(opt => opt.value !== option.value),
        },
        () => {
          if (typeof callback === 'function') {
            callback(this.state.currentOptions);
          }
        }
      );
    }
  };

  public render() {
    return this.props.children({
      addOption: this.addOption,
      allOptions: this.state.allOptions,
      currentOptions: this.state.currentOptions,
      removeOption: this.removeOption,
    });
  }
}

export default MultipleOptions;
