import { useQuery } from '@apollo/react-hooks';
import { QUERY_MASTER_USERLIFECYCLE } from '../graphql/queries';
import { useVXServicesTelegramClient } from '../../../graphql';
import { useVXServicesTelegramContext } from './index';
import { Query } from '../../../graphql/VXServicesTelegram/types';

export const useTelegramGetAutoMails = (isVip: boolean, id?: number = undefined) => {
  const { token } = useVXServicesTelegramContext();
  const variables = { token, vip: isVip, id };
  return useQuery<Query>(QUERY_MASTER_USERLIFECYCLE, {
    client: useVXServicesTelegramClient(),
    variables,
  });
};

export default useTelegramGetAutoMails;
