import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Table } from '../../../../../atoms/Table';
import RedemptionRow from './RedemptionRow';
import { _ } from '../../../../../util/translate';

interface Props {
  instances: Array<{
    usageId: number;
    user: { id: string; name: string };
    date: Date;
    pfmId: number;
  }>;
}

const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  max-height: 100%;
`;

const HorizontalScroll = styled.div`
  display: block !important;
  overflow-x: auto;
  flex: 0 1 auto;
  max-height: 100%;
`;

const StyledTable = styled(Table)`
  min-width: 415px;
  flex: 1 0 0;
  max-height: 100%;
`;

const getColumns = (): Array<{ label: string }> => [
  { label: _('benefits:abo.texts.username') },
  { label: _('benefits:voucher.archiveColumn.redeemed') },
];

const RedemptionList: FC<Props> = ({ instances }) => {
  const columns = useMemo(() => getColumns(), []);

  return (
    <Container>
      <HorizontalScroll>
        <StyledTable columns={columns}>
          {instances.map((instance) => (
            <RedemptionRow instance={instance} columns={columns} key={instance?.usageId} />
          ))}
        </StyledTable>
      </HorizontalScroll>
    </Container>
  );
};

export default RedemptionList;
