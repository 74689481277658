import React from 'react';
import PropTypes from 'prop-types';
import AbstractFormComponent from '../../AbstractFormComponent';
import getYear from 'date-fns/getYear';
import { _ } from '../../../../util/translate';

function getYearOptions(offset, maxValues) {
  const options = [];
  const fromYear = getYear(new Date()) - offset;
  for (let i = fromYear; i > fromYear - maxValues; i--) {
    options.push({ value: i, label: i });
  }

  return options;
}

function getMonthOptions() {
  const options = [];
  for (let i = 1; i <= 12; i++) {
    options.push({ value: i, label: ('0' + i).slice(-2) });
  }
  return options;
}

function getDayOptions() {
  const options = [];
  for (let i = 1; i <= 31; i++) {
    options.push({ value: i, label: ('0' + i).slice(-2) });
  }
  return options;
}

class DateSelectorDropdown extends AbstractFormComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      currentDay: 0,
      currentMonth: 0,
      currentYear: 0,
    };

    this.options = {
      year: getYearOptions(this.props.yearOffset, this.props.maxYears),
      month: getMonthOptions(),
      day: getDayOptions(),
    };
  }

  setValueFromProps() {
    const date = new Date(this.props.value);
    this.setState({
      currentDay: date.getDate(),
      currentMonth: date.getMonth() + 1, // month is zero-based
      currentYear: date.getFullYear(),
    });
  }

  componentDidMount() {
    if (this.props.value) this.setValueFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value === '' && this.props.value) {
      // if it takes a while for the server to sent date
      this.setValueFromProps();
    } else {
      let { currentDay, currentMonth, currentYear } = this.state;
      if (currentDay && currentMonth && currentYear) {
        // correct a last day for months Feb, Apr, Jun, Sep, Nov
        const lastDayInMonth = new Date(currentYear, currentMonth, 0, 12, 0, 0).getDate();
        if (currentDay > lastDayInMonth) {
          currentDay = lastDayInMonth;
          this.setState({ currentDay: currentDay });
        }

        const nextValue = new Date(
          currentYear,
          currentMonth - 1,
          currentDay,
          12,
          0,
          0
        ).toISOString();
        if (nextValue !== this.state._value)
          this.setValue(nextValue, () => this.props.onChange(nextValue));
      }
    }
  }

  onChange(event) {
    switch (event.target.name) {
      case this.props.name + '.day':
        this.setState({ currentDay: event.target.value });
        break;
      case this.props.name + '.month':
        this.setState({ currentMonth: event.target.value });
        break;
      case this.props.name + '.year':
        this.setState({ currentYear: event.target.value });
        break;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="dropdown date-d-m-y {this.getClassNames()}">
        <div className="dropdown__column">
          <select
            name={`${this.props.name}.day`}
            onChange={this.onChange}
            value={this.state.currentDay}
            ref="dayField"
          >
            <option value="0">{_('common:date.label.day')}</option>
            {this.options.day.map(option => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="dropdown__column">
          <select
            name={`${this.props.name}.month`}
            onChange={this.onChange}
            value={this.state.currentMonth}
            ref="monthField"
          >
            <option value="0">{_('common:date.label.month')}</option>
            {this.options.month.map(option => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="dropdown__column">
          <select
            name={`${this.props.name}.year`}
            onChange={this.onChange}
            value={this.state.currentYear}
            ref="yearField"
          >
            <option value="0">{_('common:date.label.year')}</option>
            {this.options.year.map(option => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

DateSelectorDropdown.propTypes = {
  ...AbstractFormComponent.propTypes,
  yearOffset: PropTypes.number,
  maxYears: PropTypes.number,
};

DateSelectorDropdown.defaultProps = {
  ...AbstractFormComponent.defaultProps,
  yearOffset: 0,
  maxYears: 100,
};

export default DateSelectorDropdown;
export { DateSelectorDropdown };
