import React, { FC } from 'react';
import { getFormattedAmount } from '../../util/Formatter';
import { MediaSalesStatItem } from '../../graphql/VXModels/types';
import { useLang } from '../../util/AppState';

interface Props {
  currency: string;
  productName: string;
  productStats?: MediaSalesStatItem;
}
const MediaSalesRow: FC<Props> = ({ currency, productName, productStats }) => {
  const [lang] = useLang();
  return (
    <tr css={{ whiteSpace: 'nowrap', lineHeight: '30px' }}>
      <td>{productName}:</td>
      <td>{productStats?.salesTotal ? productStats?.salesTotal : 0}</td>
      {productStats && productStats.amountTotal > 0 && (
        <>
          <td>{productName}:</td>
          <td>{getFormattedAmount(productStats.amountTotal, lang, currency)}</td>
        </>
      )}
    </tr>
  );
};

export default MediaSalesRow;
