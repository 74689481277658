import { ErrorMessageEnum } from '../types';

const pictureErrorcodeMapping: Record<string, ErrorMessageEnum | undefined> = {
  '-1': ErrorMessageEnum.sessionCheckFailed, // SessionCheck failed (not applicable in this context)
  '-2': ErrorMessageEnum.pictureAlbumNotFound, // uma_id not found (not applicable in this context)
  '-3': ErrorMessageEnum.pictureInvalidFilenameExtension, // Filename extension not accepted
  '-4': ErrorMessageEnum.pictureMaxFilesizeExceeded, // upload aborted due to max upload size reached
  '-5': ErrorMessageEnum.pictureDimensionsTooSmall, // dimensions too small (min 520px)
  '-6': undefined, // missing ¯\_(ツ)_/¯
  '-7': ErrorMessageEnum.dbError, // oracle error 20100, duplicate content (?)
  '-8': ErrorMessageEnum.pictureDuplicateContent, // duplicate content
  '-9': ErrorMessageEnum.pictureTooHomogeneous, // pictures with to many pixels of one color, like plain black
  '-10': ErrorMessageEnum.pictureRatioWrong, // Wrong picture ratio only in 4:3.
  '-11': ErrorMessageEnum.videoLengthTooShort, // Video length too short (min. 10s)
};

export const getPictureErrorMessage = (errorcode: string | number): ErrorMessageEnum | undefined =>
  pictureErrorcodeMapping[errorcode.toString()] || undefined;

export const parsePictureErrorcode = (body: string): ErrorMessageEnum | undefined => {
  const test = /^errorcode=(.+)/.exec(body);
  if (test) {
    return getPictureErrorMessage(test[1]);
  }

  return undefined;
};
