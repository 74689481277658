export class FileHelper {
    /**
     * @param file {File}
     * @param allowedMimeTypes {string[]}
     * @returns {boolean}
     */
    public static isValidateMimeType(file: File, allowedMimeTypes: string): boolean {
        if (file.type.match(allowedMimeTypes)) {
            return true;
        }
        return false;
    }
}