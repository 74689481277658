import React, { FC } from 'react';
import MUIAudioPlayer from 'material-ui-audio-player';
import { makeStyles } from '@material-ui/styles';
import { BLUE, WHITE } from '../../camtool-styles';
import { TimeOption } from 'material-ui-audio-player/dist/components/AudioPlayer';
import { MessageDirection } from '../../graphql/VXServicesTelegram/types';

interface IProps {
  url: string;
  hasDownload?: boolean;
  hasVolume?: boolean;
  time?: TimeOption;
  direction?: string;
}

const useStylesOut = makeStyles(() => {
  return {
    root: {
      width: '100%',
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: 'none',
      position: 'relative',
    },
    playIcon: {
      fontSize: '26px',
      color: WHITE,
      '&:hover': {
        color: WHITE,
      },
    },
    replayIcon: {
      fontSize: '26px',
      color: WHITE,
      '&:hover': {
        color: WHITE,
      },
    },
    pauseIcon: {
      fontSize: '26px',
      color: WHITE,
      '&:hover': {
        color: WHITE,
      },
    },
    progressTime: {
      color: WHITE,
      position: 'absolute',
      right: '-4px',
      top: '18px',
      fontSize: '12px',
      '&:after': {
        content: 'min',
      },
    },
    mainSlider: {
      maxWidth: '80%',
      color: WHITE,
      '& .MuiSlider-rail': {
        color: WHITE,
      },
      '& .MuiSlider-track': {
        color: WHITE,
      },
      '& .MuiSlider-thumb': {
        color: WHITE,
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  };
});

const useStylesIn = makeStyles(() => {
  return {
    root: {
      width: '100%',
      borderRadius: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: 'none',
      position: 'relative',
    },
    playIcon: {
      fontSize: '26px',
      color: BLUE,
      '&:hover': {
        color: BLUE,
      },
    },
    replayIcon: {
      fontSize: '26px',
      color: BLUE,
      '&:hover': {
        color: BLUE,
      },
    },
    pauseIcon: {
      fontSize: '26px',
      color: BLUE,
      '&:hover': {
        color: BLUE,
      },
    },
    progressTime: {
      color: BLUE,
      position: 'absolute',
      right: '-4px',
      top: '18px',
      fontSize: '12px',
    },
    mainSlider: {
      maxWidth: '85%',
      color: BLUE,
      '& .MuiSlider-rail': {
        color: BLUE,
      },
      '& .MuiSlider-track': {
        color: BLUE,
      },
      '& .MuiSlider-thumb': {
        color: BLUE,
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  };
});

const AudioPlayer: FC<IProps> = ({
  url,
  direction,
  hasDownload = false,
  hasVolume = false,
  time = TimeOption.single,
}) => {
  const dirIn = direction === MessageDirection.in;
  return (
    <MUIAudioPlayer
      useStyles={dirIn ? useStylesIn : useStylesOut}
      src={url}
      download={hasDownload}
      volume={hasVolume}
      time={time}
    />
  );
};

export default AudioPlayer;
export { AudioPlayer };
