import React, { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { _ } from '../../../util/translate';
import { Box, createStyles, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { threeStars } from '../../../atoms/Icon/libraries/svg';
import Icon from '../../../atoms/Icon/Icon';
import TimeLabelBanner from '../../../atoms/LabelBanner/TimeLabelBanner';
import { TopAmateurSlot } from '../../../graphql/VXModels/types';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import NewButton from '../../../atoms/Button/NewButton';
import { BLUE, DARK_GRAY } from '../../../camtool-styles';
import { InView } from 'react-intersection-observer';

const TopAmateurItem = styled.div`
  width: 100%;
`;

const useStyles = makeStyles(() =>
  createStyles({
    headline: {
      width: '100%',
      textAlign: 'center',
    },
    subHeadline: {
      fontSize: '12px',
      width: '100%',
      textAlign: 'center',
    },
    linkText: {
      width: '100%',
      textAlign: 'center',
    },
    slotContainer: {
      scrollPadding: '5px',
      maxHeight: '150px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      scrollBehavior: 'smooth',
      overflowY: 'auto',
      /* Chrome, Safari */
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      /* IE and Edge */
      msOverflowStyle: 'none',
      /* Firefox */
      scrollbarWidth: 'none',
      scrollSnapType: 'y mandatory',
    },
    slotItem: {
      scrollSnapAlign: 'start',
      width: '100%', // because of InView wrapper
    },
  })
);

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const SLOT_HEIGHT = 90; // CHANGE THIS VALUE IF HEIGHT OF SLOTS CHANGES (due to additional subheadline, padding etc..)
const SNAP_SCROLL_THRESHOLD = SLOT_HEIGHT / 2;

enum ScrollDirectionEnum {
  UP = 'up',
  DOWN = 'down',
}

type TopAmateur = {
  getSlots: Array<TopAmateurSlot>;
  hasSlots: boolean;
};

interface Props {
  data: TopAmateur;
}

const TopAmateur: FC<Props> = (data) => {
  const { headline, subHeadline, linkText, slotContainer, slotContainerBorder, slotItem } =
    useStyles();
  const [reachedTop, setReachedTop] = useState(true);
  const [reachedBottom, setReachedBottom] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const getSlots = data?.data?.getSlots || [];
  const hasSlots = getSlots.length || false;
  const slots = getSlots.filter((slot) => slot.status !== 'past').slice(0, 6);

  const scrollBy = (direction: ScrollDirectionEnum) => {
    switch (direction) {
      case ScrollDirectionEnum.UP:
        if (!reachedTop) {
          ref?.current?.scrollBy({ top: -SNAP_SCROLL_THRESHOLD, behavior: 'smooth' });
        }
        break;
      case ScrollDirectionEnum.DOWN:
        if (!reachedBottom) {
          ref?.current?.scrollBy({ top: SNAP_SCROLL_THRESHOLD, behavior: 'smooth' });
        }
        break;
    }
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <Box mb={1}>
          <Typography className={headline} variant="h3">
            <RecentActorsIcon fontSize="large" />
          </Typography>
          <Typography className={headline} variant="h3">
            {_('dashboard:app.topAmateur.title')}
          </Typography>
        </Box>
      </Grid>
      {hasSlots && (
        <Box display="flex" justifyContent="center">
          <NewButton
            variant="text"
            style={{
              color: reachedTop ? DARK_GRAY : BLUE,
              fontSize: '12px',
            }}
            disabled={reachedTop}
            onClick={() => scrollBy(ScrollDirectionEnum.UP)}
          >
            <KeyboardArrowUpIcon fontSize="small" viewBox="0 0 24 20" />
            <Typography color="inherit">{_('dashboard:app.topAmateur.previous')}</Typography>
          </NewButton>
        </Box>
      )}
      <Grid ref={ref} item xs={12} className={slotContainer}>
        {!hasSlots ? (
          <Box width={1} textAlign="center">
            <Icon icon={threeStars} size="26px" css={{ margin: '30px 0 4px' }} />
            <Typography className={subHeadline} variant="body1">
              {_('dashboard:app.topAmateur.empty')}
            </Typography>
            <Link href="mailto:support@vxmodels.com" className={linkText}>
              {_('dashboard:app.topAmateur.request')}
            </Link>
          </Box>
        ) : (
          slots.map((slot: TopAmateurSlot, index: number) => {
            const observeRequired = index === 0 || index === slots.length - 1;

            return observeRequired ? (
              <InView
                key={index}
                as="div"
                // trigger inView if 20% of component is visible
                threshold={0.2}
                css={{ display: 'block !important' }}
                onChange={(inView: any) => {
                  if (slots.length < 2) {
                    setReachedTop(true);
                    setReachedBottom(true);
                  }
                  if (index === 0) {
                    if (inView) {
                      setReachedTop(true);
                    } else setReachedTop(false);
                  } else if (index === slots.length - 1) {
                    if (inView) {
                      setReachedBottom(true);
                    } else setReachedBottom(false);
                  }
                }}
              >
                <Box className={slotItem}>
                  <TopAmateurItem>
                    <TimeLabelBanner start={slot.start} end={slot.end} status={slot.status} />
                  </TopAmateurItem>
                </Box>
              </InView>
            ) : (
              <Box key={index} className={slotItem}>
                <TopAmateurItem>
                  <TimeLabelBanner start={slot.start} end={slot.end} status={slot.status} />
                </TopAmateurItem>
              </Box>
            );
          })
        )}
      </Grid>
      {hasSlots && (
        <Box display="flex" justifyContent="center">
          <NewButton
            variant="text"
            style={{
              color: !reachedBottom ? BLUE : DARK_GRAY,
              fontSize: '12px',
            }}
            disabled={reachedBottom}
            onClick={() => scrollBy(ScrollDirectionEnum.DOWN)}
          >
            <KeyboardArrowDownIcon fontSize="small" viewBox="0 0 24 20" />
            <Typography color="inherit">{_('dashboard:app.topAmateur.future')}</Typography>
          </NewButton>
        </Box>
      )}
    </Grid>
  );
};

export default TopAmateur;
