import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';
import OverviewProfilesVXItem from '../OverviewProfilesVXItem/OverviewProfilesVXItem';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION } from '../../../../camtool-styles';

const Row = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

class OverviewProfilesVX extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickItem(item) {
    this.props.onSelectItem(item);
  }

  render() {
    const { relativePath } = this.props;

    let flirtcore = {};
    let softcore = {};
    let hardcore = {};

    for (let album of this.props.albums) {
      switch (album.mediaType) {
        case '51_1':
          softcore = album;
          softcore.link = relativePath + '/_sedcardVX16';
          softcore.title = _('mediamanagement:sedcardOverview.sedcardVX16.title');
          softcore.changeText = _('mediamanagement:sedcardOverview.sedcardVX16.changeText');
          break;
        case '51_2':
          hardcore = album;
          hardcore.link = relativePath + '/_sedcardVX18';
          hardcore.title = _('mediamanagement:sedcardOverview.sedcardVX18.title');
          hardcore.changeText = _('mediamanagement:sedcardOverview.sedcardVX18.changeText');
          break;
        case '51_3':
          flirtcore = album;
          flirtcore.link = relativePath + '/_sedcardVX12';
          flirtcore.title = _('mediamanagement:sedcardOverview.sedcardVX12.title');
          flirtcore.changeText = _('mediamanagement:sedcardOverview.sedcardVX12.changeText');
          break;
      }
    }

    return (
      <div className="grid__box__sub__item">
        <div className="grid__box__sub__item__content profile-pic__preview__vx">
          <Row className="profile-pic__preview__vx__row">
            <OverviewProfilesVXItem album={flirtcore} />
            <OverviewProfilesVXItem album={softcore} />
            <OverviewProfilesVXItem album={hardcore} />
          </Row>
        </div>
      </div>
    );
  }
}

OverviewProfilesVX.propTypes = {
  albums: PropTypes.array.isRequired,
  relativePath: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onSelectItem: PropTypes.func,
};

OverviewProfilesVX.defaultProps = {
  isLoading: false,
  onSelectItem: () => {},
};

export default OverviewProfilesVX;
export { OverviewProfilesVX };
