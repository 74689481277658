import ApolloClient from 'apollo-client';
import { Dispatch } from 'react';
import { useUserData } from '../../../../util/UserData';
import { MUTATION_CREATE_ABO } from '../../queries';
import {
  AboBenefitSubType,
  AboContentType,
  AboRoute,
  AboWizardActionType,
  IAboBenefitInput,
  IAboDetailsPageForm,
  IAboWizardAction,
  IAboWizardState,
} from '../../types';

const getBenefit = (state: IAboWizardState): IAboBenefitInput => {
  switch (state.aboContentType) {
    case AboContentType.VXPagesCredits:
      return {
        type: state.aboContentType,
        subType: state.aboRedeemDetails.type,
        json: JSON.stringify({ amount: Number(state.aboRedeemDetails.data.amount) }),
      };
    case AboContentType.FreeMessages:
      return {
        type: state.aboContentType,
        subType: state.aboRedeemDetails.type,
        json:
          state.aboRedeemDetails.type === AboBenefitSubType.Flat
            ? JSON.stringify({ amount: Number(state.aboRedeemDetails.data.amount) })
            : undefined,
      };
    case AboContentType.Media:
    case AboContentType.Videos:
    case AboContentType.Photos:
      return {
        type: state.aboContentType,
        subType: state.aboRedeemDetails.type,
        json: undefined,
      };
    case AboContentType.Telegram:
    case AboContentType.Custom:
      return {
        type: state.aboContentType,
        subType: state.aboRedeemDetails.type,
        json: undefined,
      };
    default:
      throw new Error(`Invalid "aboContentType" -> ${state.aboContentType}`);
  }
};

const useOnSubmit = (
  state: IAboWizardState,
  dispatch: Dispatch<IAboWizardAction>,
  apolloClientAbo?: ApolloClient<any>
): ((values: IAboDetailsPageForm) => void) => {
  const { auth } = useUserData();

  return (values: IAboDetailsPageForm): void => {
    if (apolloClientAbo) {
      apolloClientAbo
        .mutate({
          mutation: MUTATION_CREATE_ABO,
          variables: {
            abo: {
              pfmId: 1503,
              creatorId: auth?.id,
              // name: values.nameTranslation['de'], // uncomment
              // description: values.descriptionTranslation['de'], // uncomment
              nameTranslation: {
                de: values.nameTranslation['de'],
                en: values.nameTranslation['en'],
              },
              descriptionTranslation: {
                de: values.descriptionTranslation['de'],
                en: values.descriptionTranslation['en'],
              },
              price: values.price,
              priceDiscounted: values.priceDiscounted,
              paymentInterval: parseInt(values.billingPeriod),
              benefitInterval: parseInt(values.billingPeriod), // DO WE WANT THIS?
              renew: values.autoRenew,
              active: true,
              trialIntervalCount: null,
              trialPrice: null,
              benefits: [getBenefit(state)],
              pictureUUID: values.picture?.uuid,
            },
          },
        })
        .then(({ data }) => {
          if (data.builder && data.builder.createAbo) {
            dispatch({
              type: AboWizardActionType.SaveFormValues,
              payload: { formValues: values },
            });

            dispatch({
              type: AboWizardActionType.GoToRoute,
              payload: { route: AboRoute.AboReviewPage },
            });
          }
        });
    } else {
      throw new Error('`apolloClientAbo` is `undefined`. Cannot submit the form.');
    }
  };
};

export default useOnSubmit;
