import React, { FC } from 'react';
import { chevronDown, chevronUp } from '../../../../atoms/Icon/libraries/glyph';
import Icon from '../../../../atoms/Icon/Icon';

interface Props {
  isOpen: boolean;
}

const LeftMenuGroupChevron: FC<Props> = ({ isOpen }) => (
  <Icon icon={isOpen ? chevronUp : chevronDown} size="8px" />
);

export default LeftMenuGroupChevron;
