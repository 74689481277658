import React, {FC} from 'react';
import VXModelsIcon from '../../atoms/Icon/VXModelsIcon';
import {DocumentTypeEnum, DocumentUploadAssistantTypeEnum} from '../../graphql/VXModels/types';
import {BLACK} from '../../camtool-styles';
import useInitializeAssistant from './hooks/useInitializeAssistant';
import {UploadAssistant} from '@vxmodels/assistant-react';
import {LanguageEnum} from '@vxmodels/assistant-react/dist/types/types';
import HelpcenterLink from "../Helpcenter/HelpcenterLink";

export const AssistantDocs = [DocumentTypeEnum.idShot, DocumentTypeEnum.identityProof]; // list of implemented documents so far

interface AssistantProps {
    flowType?: DocumentUploadAssistantTypeEnum;
}

// todo: change passed props depending on which step we are on (steps without upload do not need joerg Upload function) -> Factory Pattern
const Assistant: FC<AssistantProps> = ({
                                           flowType = DocumentUploadAssistantTypeEnum.actorVerification,
                                       }) => {
    const module = useInitializeAssistant(flowType);

    return (
        <UploadAssistant
            language={module?.lang as LanguageEnum}
            scopeLogo={<VXModelsIcon color={BLACK}/>}
            currentStep={module?.currentStep}
            supportUrl={HelpcenterLink()}
            onClick={module?.onClick}
            skipStep={module?.onSkipStep}
            loading={module?.customLoading}
            error={module?.customError}
            resetError={module?.resetError}
            downloadUrl={module?.downloadUrl}
        />
    );
};

export default Assistant;
