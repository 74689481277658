import React from 'react';
import { AbstractComponent, ChartBar, FormatDuration, T } from '../../../components';

import { OnlineTimeAndUsersStore } from '../../../stores/OnlineTimeAndUsers/OnlineTimeAndUsersStore';
import { OnlineTimeAndUsersActionCreators } from '../../../stores/OnlineTimeAndUsers/OnlineTimeAndUsersActionCreators';
import IconAndTextComponent from '../SalesStatistics/IconAndTextComponent';
import { createStyles, withStyles } from '@material-ui/styles';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Box, Container, Grid } from '@material-ui/core';
import { webcam } from '../../../atoms/Icon/libraries/glyph';
import { Icon } from '../../../atoms';

const styles = createStyles({
  onlineTimeAndUsersWebcamIcon: {
    '&::before': {
      fontSize: '36px',
      color: '#ffb033',
    },
  },
  onlineTimeAndUsersClockIcon: {
    fontSize: '36px',
    color: '#e67e22',
  },
  formattedNumber: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    ['@media (max-width:900px)']: {
      alignItems: 'center',
    },
  },
});

class OnlineTimeAndUsers extends AbstractComponent {
  state = OnlineTimeAndUsersStore.get();

  componentWillUnmount() {
    OnlineTimeAndUsersStore.removeChangeListener(this.onStoreChange);
  }

  componentDidMount() {
    OnlineTimeAndUsersStore.addChangeListener(this.onStoreChange);
    OnlineTimeAndUsersActionCreators.getOnlineTimeAndUsersData();
  }

  onStoreChange() {
    this.setState(OnlineTimeAndUsersStore.get());
  }

  showValueForDate(entry) {
    this.setState({
      selectedChatTime: entry.onlineTime,
      selectedUsers: entry.users,
      applyCssClassToday: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth={false}>
        <Grid
          container
          direction={'column'}
          justify={'space-between'}
          alignItems={'center'}
          style={{ minHeight: '308px' }}
        >
          <Grid container item xs={12} justify={'center'} alignItems={'center'} direction={'row'}>
            <IconAndTextComponent
              mobileSize={12}
              tabletSize={6}
              icon={
                <QueryBuilderIcon
                  className={classes.onlineTimeAndUsersClockIcon}
                ></QueryBuilderIcon>
              }
              formattedNumber={
                <FormatDuration
                  className={classes.formattedNumber}
                  value={this.state.selectedChatTime}
                />
              }
            />
            <IconAndTextComponent
              mobileSize={12}
              tabletSize={6}
              icon={<Icon className={classes.onlineTimeAndUsersWebcamIcon} icon={webcam} />}
              formattedNumber={
                <Box className={classes.formattedNumber}>{this.state.selectedUsers}</Box>
              }
            />
          </Grid>
          <Grid container item xs={12} justify={'center'} alignItems={'center'} direction={'row'}>
            <Grid item xs={12}>
              <Box className={classes.chartContainer}>
                <ChartBar
                  name="online"
                  onSelect={this.showValueForDate}
                  values={this.state.statDetails}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withStyles(styles)(OnlineTimeAndUsers);
