import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { dashboard } from '../../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../../util/translate';

const DashboardItem: FC = () => (
  <LeftMenuItem title={_('navigation:main.dashboard')} icon={dashboard} uri="/dashboard" />
);

export default DashboardItem;
