import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { QueryResult } from '@apollo/react-common';
import { QUERY_MASTER_IS_UNPINABLE } from '../graphql/queries';
import { Query } from '../../../graphql/VXServicesTelegram/types';
import { useVXServicesTelegramContext } from './index';
import { useVXServicesTelegramClient } from '../../../graphql/VXServicesTelegram';

type Result = Omit<QueryResult<Query>, 'data'> & {
  isUnpinable: boolean;
};

export const useUnpinable = (options?: QueryHookOptions<Query>): Result => {
  const { token } = useVXServicesTelegramContext();
  const client = useVXServicesTelegramClient();
  const { data } = useQuery<Query & { token: string }>(QUERY_MASTER_IS_UNPINABLE, {
    client,
    ...options,
    variables: { token: token! },
    skip: !token,
  });

  return { isUnpinable: data?.master?.isUnpinable };
};
