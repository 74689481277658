import React, { FC } from 'react';
import { _ } from '../../util/translate';
import { Main, WindowCompilation } from '../../components/Layout/DarkCentered';

const ErrorGeneric: FC = () => {
  return (
    <Main>
      <div>
        <WindowCompilation title={_('common:errorPage.commonError.title')}>
          <div>{_('common:errorPage.commonError.body')}</div>
        </WindowCompilation>
      </div>
    </Main>
  );
};

export default ErrorGeneric;
