import gql from 'graphql-tag';
import { FRAGMENT_FILE } from '../fragments';

export const MUTATION_MASTER_USERLIFECYCLE_SETSTEPS = gql`
  mutation VXM_CL_MUTATION_MASTER_USERLIFECYCLE_SETSTEPS(
    $token: String!
    $steps: [UserLifeCycleStepInput!]!
  ) {
    master(token: $token) {
      userLifeCycle {
        setSteps(steps: $steps) {
          success
          reason
          step {
            id
            active
            vip
            text
            file {
              ...File
            }
            delayMinutes
            delayDisplayUnit
            delaySchedule
          }
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const MUTATION_MASTER_USERLIFECYCLE_SETSTEP = gql`
  mutation VXM_CL_MUTATION_MASTER_USERLIFECYCLE_SETSTEP(
    $token: String!
    $step: UserLifeCycleStepInput!
  ) {
    master(token: $token) {
      userLifeCycle {
        setStep(step: $step) {
          success
          reason
          step {
            id
            active
            vip
            text
            file {
              ...File
            }
            delayMinutes
            delayDisplayUnit
            delaySchedule
          }
        }
      }
    }
  }
  ${FRAGMENT_FILE}
`;

export const MUTATION_MASTER_USERLIFECYCLE_UNSETSTEP = gql`
  mutation VXM_CL_MUTATION_MASTER_USERLIFECYCLE_UNSETSTEP($token: String!, $id: Int!) {
    master(token: $token) {
      userLifeCycle {
        unsetStep(id: $id)
      }
    }
  }
`;
