import { useMutation } from '@apollo/react-hooks';
import { updateUserBlockStatus } from '../graphql/util';
import { MUTATION_MASTER_UNBLOCK_USER } from '../graphql/mutations';
import { useVXServicesTelegramClient } from '../../../graphql';
import { useVXServicesTelegramContext } from './index';

export const useTelegramUnblockUser = ({ userId }: { userId: number }) => {
  const { token } = useVXServicesTelegramContext();
  const variables = { token, userId };
  return useMutation(MUTATION_MASTER_UNBLOCK_USER, {
    client: useVXServicesTelegramClient(),
    variables,
    optimisticResponse: {
      __typename: 'Mutation',
      master: {
        unblockUser: true,
        __typename: 'MasterMutation',
      },
    },
    update: (
      cache,
      {
        data: {
          master: { unblockUser: isUnblocked },
        },
      }
    ) => updateUserBlockStatus(!isUnblocked, cache, token, userId),
  });
};

export default useTelegramUnblockUser;
