import React from 'react';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';

import PopUpMenu from '../PopUpMenu/PopUpMenu';
import GroupColumn from '../GroupColumn/GroupColumn';
import Inputfield from '../Form/Text/Inputfield/Inputfield';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Button from '../Button/Button';
import GroupRow from '../GroupRow/GroupRow';
import Notice from '../Notice/Notice';
import Spinner from '../Spinner/Spinner';

import AbstractComponent from '../AbstractComponent';
import { _ } from '../../util/translate';

class VerifyButton extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      status: props.verificationStatus,
      verificationCode: '',
      closeVerifyBox: true,
    };

    this.onClickVerifyButton = this.onClickVerifyButton.bind(this);
    this.makeValueEditable = this.makeValueEditable.bind(this);
    this.checkVerificationCode = this.checkVerificationCode.bind(this);
    this.requestVerificationCode = this.requestVerificationCode.bind(this);
    this.closeModalBox = this.closeModalBox.bind(this);
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.onPageClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onPageClick);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.state.status = nextProps.verificationStatus;
      this.setState(this.state);

      if (nextProps.showModalboxOnStart) {
        this.setState({ isOpen: true });
      } else {
        this.setState({ isOpen: false });
      }
    }
  }

  onClickVerifyButton(event) {
    if (this.state.status === 'new') {
      if (this.props.onSaveValue) {
        this.props.onSaveValue(this.props.verificationMode);
      }
    } else {
      this.setState({ isOpen: true });
    }
  }

  makeValueEditable() {
    if (this.props.onChangeDisability) {
      this.props.onChangeDisability(this.props.verificationMode, false);
    }
    this.setState({ isOpen: false });
  }

  checkVerificationCode() {
    const verificationCode =
      this.refs.authcode.getValue() !== undefined ? this.refs.authcode.getValue() : '';
    if (this.props.onCheckVerificationCode) {
      this.props.onCheckVerificationCode(this.props.verificationMode, verificationCode);
    }
  }

  requestVerificationCode() {
    if (this.props.onRequestVerificationCode) {
      this.props.onRequestVerificationCode(this.props.verificationMode);
    }
    this.setState({ status: 'checking' });

    setTimeout(() => {
      set.setState({ status: 'check' });
    }, 5000);
  }

  onPageClick(e) {
    const path = e.path || (e.composedPath && e.composedPath());
    const targetIsPopupframe = path
      ? path.reduce((targetIsPopupframe, el) => {
          return el.nodeName === 'SECTION' && el.className.indexOf('popupframe') !== -1
            ? true
            : targetIsPopupframe;
        }, false)
      : true;
    if (this.state.isOpen && !targetIsPopupframe) {
      this.setState({ isOpen: false });
    }
  }

  closeModalBox() {
    this.setState({ isOpen: false });
  }

  render() {
    const { verificationMode } = this.props;
    const { status, verificationCode } = this.state;

    this.classProductIcon = cx('icon-' + (status === 'checked' ? 'ok' : 'keys'));
    this.isChecking = status === 'checking';

    // this.props.verificationStatus =
    //     new                 neue Verifikation
    //     check               Wartet auf Code
    //     checking            Code wird geprüft
    //     checkingerror       Prüfung fehlerhaft / wartet auf Wiederholung
    //     checked             Prüfung abgeschlossen
    //
    // this.props.verificationMode
    //     emailAddressVerification
    //     fixedPhoneVerification
    //     mobilePhoneVerification
    //     service0900PhoneVerification

    return (
      <div>
        <div className="verifybutton-std {this.props.className ? this.props.className : ''}">
          <div
            // eslint-disable-next-line react/no-string-refs
            ref="toggleDiv"
            className={`btnbox popupmenu ${this.state.status} ${
              this.state.isOpen ? 'show' : 'hide'
            }`}
            rel={this.state.status}
          >
            {/*Verification BUTTON*/}
            <div onClick={this.onClickVerifyButton}>
              <div className={`${this.classProductIcon} icon`} />
              <div className="text">{_(`components:verifyButton.valueByStatus.${status}`)}</div>
            </div>

            {/*POPUP MENU TO VERIFY VALUE (IF STATUS == CHECK, CHECKING OR CHECKINGERROR)*/}
            {(status === 'check' || status === 'checking' || status === 'checkingerror') && (
              <PopUpMenu
                position="bottom"
                headertext={_('components:verifyButton.verification.boxTitle')}
                headericon="icon-keys"
                closebtn={this.closeModalBox}
                type="window"
              >
                <GroupColumn tooltip="none">
                  <Inputfield
                    // eslint-disable-next-line react/no-string-refs
                    ref="authcode"
                    name="authcode"
                    placeholder={_('components:verifyButton.verification.inputPlaceholder')}
                    type="text"
                    value={verificationCode}
                  />
                  <ButtonGroup position="center">
                    <Button
                      label={_('components:verifyButton.verification.buttonLabel')}
                      type="button"
                      onClick={this.checkVerificationCode}
                    />
                  </ButtonGroup>
                </GroupColumn>

                {/*ERROR MESSAGE: INVALID AUTH CODE*/}
                {status === 'checkingerror' && (
                  <GroupRow tooltip="none">
                    <Notice
                      text={
                        this.props.errorMessage === 'invalidFormat' ||
                        this.props.errorMessage === 'chooseAnother' ||
                        this.props.errorMessage === 'invalidDataType'
                          ? _(`validation:email.${this.props.errorMessage}`)
                          : _(`components:verifyButton.errormessage.${this.props.errorMessage}`)
                      }
                      icon="icon-warning-sign"
                    />
                  </GroupRow>
                )}

                {/*ADDITIONAL LINKS*/}
                <div>
                  <span className="coderequest" onClick={this.requestVerificationCode}>
                    {_('components:verifyButton.verification.sendNewCode')}
                  </span>
                </div>

                {this.isChecking && <Spinner size="s" />}
              </PopUpMenu>
            )}
          </div>
        </div>
        <Button label={_('common:button.edit')} onClick={this.makeValueEditable} />
      </div>
    );
  }
}

export default VerifyButton;
export { VerifyButton };
