import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { splitStringIntoLine } from '../chartsUtils';
import { BLUE, GRAY_4, RED } from '../../../camtool-styles';

const START_ANGLE = -Math.PI / 2;
const HOLE_SIZE = 0.95;
const PROGRESS_COLORS = BLUE;
const PROGRESS_BACKGROUND_COLOR = GRAY_4;
const PROGRESS_WARNING = RED;
const FONT_RATIO_1 = 12 / 202.5; // default font size divided by default big circle width
const FONT_RATIO_2 = 18 / 202.5; // default font size divided by default big circle width
const FONT_RATIO_3 = 44 / 202.5; // default font size divided by default big circle width

export default class ProgressCircle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      progressEndAngle: 0,
      progress2: 0,
      progressEndAngle2: 0,
    };

    this.updateProgress = this.updateProgress.bind(this);
    this.drawPieSlice = this.drawPieSlice.bind(this);
    this.draw = this.draw.bind(this);
    this.cancelAnimation = this.cancelAnimation.bind(this);
  }

  componentDidMount() {
    // Set up canvas
    this.canvas.width = this.props.width;
    this.canvas.height = this.props.height;
    this.ctx = this.canvas.getContext('2d');
    this.updateProgress(this.props.data, this.props.data2);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, data2 } = this.props;

    // Handle data change
    if (data[0] !== nextProps.data[0] || data[1] !== nextProps.data[1]) {
      if (nextProps.data && nextProps.data[0] <= nextProps.data[1]) {
        this.updateProgress(nextProps.data);
      }
    }

    // Handle data2 change
    if (!data2 || data2[0] !== nextProps.data2[0] || data2[1] !== nextProps.data2[1]) {
      if (nextProps.data2 && nextProps.data2[0] <= nextProps.data2[1]) {
        this.updateProgress(null, nextProps.data2);
      }
    }
  }

  componentWillUnmount() {
    this.cancelAnimation();
    this.setState({
      progress: 0,
      progressEndAngle: 0,
      progress2: 0,
      progressEndAngle2: 0,
    });
  }

  cancelAnimation() {
    window.cancelAnimationFrame(this.animationCallbackId);
    clearTimeout(this.timeOutId);
  }

  /** Set progress, progressEndAngle and draw chart */
  updateProgress(data, data2) {
    if (data && this.props.doubleChart && data2) {
      this.setState(
        {
          progress: data[1] > 0 ? Math.round((100 * data[0]) / data[1]) : 0,
          progressEndAngle: data[1] > 0 ? (2 * Math.PI * data[0]) / data[1] : 0,
          progress2: data2[1] ? Math.round((100 * data2[0]) / data2[1]) : 0,
          progressEndAngle2: data2[1] > 0 ? (2 * Math.PI * data2[0]) / data2[1] : 0,
        },
        () => this.draw()
      );
    } else if (data && (!this.props.doubleChart || !data2)) {
      this.setState(
        {
          progress: data[1] > 0 ? Math.round((100 * data[0]) / data[1]) : 0,
          progressEndAngle: data[1] > 0 ? (2 * Math.PI * data[0]) / data[1] : 0,
        },
        () => this.draw()
      );
    } else if (!data && this.props.doubleChart && data2) {
      this.setState(
        {
          progress2: data2[1] > 0 ? Math.round((100 * data2[0]) / data2[1]) : 0,
          progressEndAngle2: data2[1] ? (2 * Math.PI * data2[0]) / data2[1] : 0,
        },
        () => this.draw()
      );
    }
  }

  drawPieSlice(centerX, centerY, radius, startAngle, endAngle, color) {
    this.ctx.fillStyle = color;
    this.ctx.beginPath();
    this.ctx.moveTo(centerX, centerY);
    this.ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    this.ctx.closePath();
    this.ctx.fill();
  }

  drawText(
    xCenter,
    yCenter,
    originalText,
    wrapAt,
    font,
    fillStyle = '#003300',
    verticalOffset,
    verticalDirection = 1
  ) {
    this.ctx.font = font;
    this.ctx.fillStyle = fillStyle;
    const lineSpace = 5;
    const textLineHeight = this.ctx.measureText('M').width;
    const baseTextLineHeight = verticalDirection < 0 ? this.ctx.measureText('M').width : 0;
    const text = splitStringIntoLine(wrapAt, originalText);
    if (verticalDirection < 0 && text.forEach) text.reverse();

    if (text.forEach) {
      text.forEach((txt, i) => {
        const txtWidth = this.ctx.measureText(txt).width;
        this.ctx.fillText(
          txt,
          xCenter - txtWidth / 2,
          yCenter +
            (verticalOffset > 0
              ? (verticalOffset + lineSpace * i + textLineHeight * i) * verticalDirection
              : 0)
        );
      });
    } else {
      const textWidth = this.ctx.measureText(text).width;
      this.ctx.fillText(
        text,
        xCenter - textWidth / 2,
        yCenter + (verticalOffset > 0 ? verticalOffset * verticalDirection : 0)
      );
    }
  }

  drawBigChart(bigCircleWidth, bigCircleHeight, currentProgress, currentAngle) {
    // Draw background arc
    this.drawPieSlice(
      bigCircleWidth / 2,
      bigCircleHeight / 2,
      Math.min(bigCircleWidth / 2, bigCircleHeight / 2),
      START_ANGLE,
      2 * Math.PI,
      this.props.progressBgColor || PROGRESS_BACKGROUND_COLOR
    );

    // Draw progress arc
    this.drawPieSlice(
      bigCircleWidth / 2,
      bigCircleHeight / 2,
      Math.min(bigCircleWidth / 2, bigCircleHeight / 2),
      START_ANGLE,
      START_ANGLE + currentAngle,
      this.props.warning ? PROGRESS_WARNING : this.props.progressColor || PROGRESS_COLORS
    );

    // Draw chart's hole
    this.drawPieSlice(
      bigCircleWidth / 2,
      bigCircleHeight / 2,
      HOLE_SIZE * Math.min(bigCircleWidth / 2, bigCircleHeight / 2),
      0,
      2 * Math.PI,
      '#ffffff'
    );

    const textVOffset = 0;
    this.drawText(
      bigCircleWidth / 2,
      bigCircleHeight / 2 + 5,
      [` ${Math.floor(currentProgress)}%`],
      4,
      `${FONT_RATIO_3 * bigCircleWidth}px sans-serif`,
      '#000000',
      textVOffset,
      1
    );

    const textVOffset1 = this.ctx.measureText('M').width * (3 / 4);
    const textVOffset2 = this.ctx.measureText('M').width;
    this.drawText(
      bigCircleWidth / 2,
      bigCircleHeight / 2,
      this.props.label.split(' '),
      17,
      `${FONT_RATIO_2 * bigCircleWidth}px sans-serif`,
      '#000000',
      textVOffset1,
      1
    );

    if (this.props.warning) {
      this.drawText(
        bigCircleWidth / 2,
        bigCircleHeight / 2,
        `${this.props.warning}`.split(' '),
        17,
        `${FONT_RATIO_1 * bigCircleWidth}px sans-serif`,
        PROGRESS_WARNING,
        textVOffset2,
        -1
      );
    }
  }

  drawSmallChart(centerX, centerY, currentProgress, currentAngle) {
    // Draw chart if there is data available
    if (!this.props.data2) return;

    const smallCircleWidth = (2 / 4) * this.canvas.width;
    const smallCircleHeight = (2 / 4) * this.canvas.height;

    // Draw background arc
    this.drawPieSlice(
      centerX,
      centerY,
      Math.min(smallCircleWidth / 2, smallCircleHeight / 2),
      START_ANGLE,
      2 * Math.PI,
      this.props.progressBgColor || PROGRESS_BACKGROUND_COLOR
    );

    // Draw progress arc
    this.drawPieSlice(
      centerX,
      centerY,
      Math.min(smallCircleWidth / 2, smallCircleHeight / 2),
      START_ANGLE,
      START_ANGLE + currentAngle,
      this.props.warning2 ? PROGRESS_WARNING : this.props.progressColor || PROGRESS_COLORS
    );

    // Draw chart's hole
    this.drawPieSlice(
      centerX,
      centerY,
      HOLE_SIZE * Math.min(smallCircleWidth / 2, smallCircleHeight / 2),
      0,
      2 * Math.PI,
      '#ffffff'
    );

    // Draw texts
    this.drawText(
      centerX,
      centerY,
      [` ${currentProgress}%`],
      17,
      `${FONT_RATIO_3 * smallCircleWidth}px sans-serif`,
      '#000000',
      -10
    );
    this.drawText(
      centerX,
      centerY,
      this.props.label2.split(' '),
      17,
      `${FONT_RATIO_2 * smallCircleWidth}px sans-serif`,
      '#000000',
      15
    );
  }

  draw() {
    if (!this.canvas) {
      this.cancelAnimation();
      return;
    }

    const { doubleChart, data, data2 } = this.props;
    const { progress, progressEndAngle, progress2, progressEndAngle2 } = this.state;

    this.timeOutId = setTimeout(() => {
      // we don't do shit if there is no canvas
      if (!this.canvas) {
        this.cancelAnimation();
        return;
      }

      if (doubleChart) {
        const bigCircleWidth = (3 / 4) * this.canvas.width;
        const bigCircleHeight = (3 / 4) * this.canvas.height;

        let currentData0 = 0;
        let currentAngle = 0;
        let currentProgress = 0;
        let currentData0SMALL = 0;
        let currentAngleSMALL = 0;
        let currentProgressSMALL = 0;

        this.drawBigChart(bigCircleWidth, bigCircleHeight, currentProgress, currentAngle);
        this.drawSmallChart(
          bigCircleWidth,
          bigCircleHeight,
          currentProgressSMALL,
          currentAngleSMALL
        );

        const drawIt = () => {
          // we don't do shit if there is no canvas
          if (!this.canvas) {
            this.cancelAnimation();
            return;
          }

          if (currentData0 <= data[0] || (data2 && currentData0SMALL <= data2[0])) {
            this.animationCallbackId = window.requestAnimationFrame(drawIt);
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            if (currentData0 <= data[0]) {
              this.drawBigChart(bigCircleWidth, bigCircleHeight, currentProgress, currentAngle);
              currentData0 += 1;
              currentAngle = data[1] > 0 ? (2 * Math.PI * currentData0) / data[1] : 0;
              currentProgress = data[1] > 0 ? Math.round((100 * currentData0) / data[1]) : 0;
            } else {
              this.drawBigChart(bigCircleWidth, bigCircleHeight, progress, progressEndAngle);
            }

            if (data2 && currentData0SMALL <= data2[0]) {
              this.drawSmallChart(
                bigCircleWidth,
                bigCircleHeight,
                currentProgressSMALL,
                currentAngleSMALL
              );
              currentData0SMALL += 1;
              currentAngleSMALL = data2[1] > 0 ? (2 * Math.PI * currentData0SMALL) / data2[1] : 0;
              currentProgressSMALL =
                data2[1] > 0 ? Math.round((100 * currentData0SMALL) / data2[1]) : 0;
            } else {
              this.drawSmallChart(bigCircleWidth, bigCircleHeight, progress2, progressEndAngle2);
            }
          } else {
            this.drawBigChart(bigCircleWidth, bigCircleHeight, progress, progressEndAngle);
            this.drawSmallChart(bigCircleWidth, bigCircleHeight, progress2, progressEndAngle2);
          }
        };
        this.animationCallbackId = window.requestAnimationFrame(drawIt);
      } else {
        let currentData0 = 0;
        let currentAngle = 0;
        let currentProgress = 0;

        this.drawBigChart(this.canvas.width, this.canvas.height, progress, currentAngle);
        const drawIt = () => {
          if (currentData0 <= data[0]) {
            this.animationCallbackId = window.requestAnimationFrame(drawIt);
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.drawBigChart(this.canvas.width, this.canvas.height, currentProgress, currentAngle);
            currentData0 += 1;
            currentAngle = data[1] > 0 ? (2 * Math.PI * currentData0) / data[1] : 0;
            currentProgress = data[1] > 0 ? Math.round((100 * currentData0) / data[1]) : 0;
          }
        };
        this.animationCallbackId = window.requestAnimationFrame(drawIt);
      }
    }, 500);
  }

  render() {
    return (
      <section className={`progress-chart-circle ${this.props.containerClass}`}>
        <canvas
          ref={(r) => {
            this.canvas = r;
          }}
        />
      </section>
    );
  }
}

ProgressCircle.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  data2: PropTypes.arrayOf(PropTypes.number),
  label: PropTypes.string,
  label2: PropTypes.string,
  containerClass: PropTypes.string,
  warning: PropTypes.string,
  warning2: PropTypes.string,
  doubleChart: PropTypes.bool,
  progressColor: PropTypes.string,
  progressBgColor: PropTypes.string,
};

ProgressCircle.defaultProps = {
  label: '',
  label2: '',
  containerClass: '',
  data2: null,
  warning: '',
  doubleChart: false,
  progressColor: '',
  progressBgColor: '',
};
