import React from 'react';
import AbstractComponent from '../../components/AbstractComponent'

class IconsIndex extends AbstractComponent {

    render() {
        return (
            <main className="vxgames2017-overview grid">

                <div className="grid__row">
                    <div className="grid__column grid__box">

                        <div className="grid__box__item">
                            <div className="grid__box__item__content">

                                <div className="glyphicon-index">
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-glass"/>
                                            <h4>icon-glass</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-glass"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-leaf"/>
                                            <h4>icon-leaf</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-leaf"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dog"/>
                                            <h4>icon-dog</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dog"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user"/>
                                            <h4>icon-user</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-girl"/>
                                            <h4>icon-girl</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-girl"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-car"/>
                                            <h4>icon-car</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-car"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-add"/>
                                            <h4>icon-user-add</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-add"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-remove"/>
                                            <h4>icon-user-remove</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-remove"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-film"/>
                                            <h4>icon-film</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-film"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-magic"/>
                                            <h4>icon-magic</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-magic"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-envelope"/>
                                            <h4>icon-envelope</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-envelope"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-camera"/>
                                            <h4>icon-camera</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-camera"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-heart"/>
                                            <h4>icon-heart</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-heart"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-beach-umbrella"/>
                                            <h4>icon-beach-umbrella</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-beach-umbrella"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-train"/>
                                            <h4>icon-train</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-train"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-print"/>
                                            <h4>icon-print</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-print"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bin"/>
                                            <h4>icon-bin</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bin"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-music"/>
                                            <h4>icon-music</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-music"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-music-alt"/>
                                            <h4>icon-music-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-music-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-heart-empty"/>
                                            <h4>icon-heart-empty</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-heart-empty"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-home"/>
                                            <h4>icon-home</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-home"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-snowflake"/>
                                            <h4>icon-snowflake</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-snowflake"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fire"/>
                                            <h4>icon-fire</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fire"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-magnet"/>
                                            <h4>icon-magnet</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-magnet"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-parents"/>
                                            <h4>icon-parents</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-parents"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-binoculars"/>
                                            <h4>icon-binoculars</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-binoculars"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-road"/>
                                            <h4>icon-road</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-road"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-search"/>
                                            <h4>icon-search</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-search"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cars"/>
                                            <h4>icon-cars</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cars"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-notes-2"/>
                                            <h4>icon-notes-2</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-notes-2"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pencil"/>
                                            <h4>icon-pencil</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pencil"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bus"/>
                                            <h4>icon-bus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-wifi-alt"/>
                                            <h4>icon-wifi-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-wifi-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-luggage"/>
                                            <h4>icon-luggage</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-luggage"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-old-man"/>
                                            <h4>icon-old-man</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-old-man"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-woman"/>
                                            <h4>icon-woman</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-woman"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file"/>
                                            <h4>icon-file</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-coins"/>
                                            <h4>icon-coins</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-coins"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-plane"/>
                                            <h4>icon-plane</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-plane"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-notes"/>
                                            <h4>icon-notes</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-notes"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-stats"/>
                                            <h4>icon-stats</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-stats"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-charts"/>
                                            <h4>icon-charts</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-charts"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pie-chart"/>
                                            <h4>icon-pie-chart</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pie-chart"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-group"/>
                                            <h4>icon-group</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-group"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-keys"/>
                                            <h4>icon-keys</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-keys"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-calendar"/>
                                            <h4>icon-calendar</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-calendar"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-router"/>
                                            <h4>icon-router</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-router"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-camera-small"/>
                                            <h4>icon-camera-small</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-camera-small"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-star-empty"/>
                                            <h4>icon-star-empty</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-star-empty"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-star"/>
                                            <h4>icon-star</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-star"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-link"/>
                                            <h4>icon-link</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-link"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-eye-open"/>
                                            <h4>icon-eye-open</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-eye-open"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-eye-close"/>
                                            <h4>icon-eye-close</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-eye-close"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-alarm"/>
                                            <h4>icon-alarm</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-alarm"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-clock"/>
                                            <h4>icon-clock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-clock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-stopwatch"/>
                                            <h4>icon-stopwatch</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-stopwatch"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-projector"/>
                                            <h4>icon-projector</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-projector"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-history"/>
                                            <h4>icon-history</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-history"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-truck"/>
                                            <h4>icon-truck</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-truck"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cargo"/>
                                            <h4>icon-cargo</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cargo"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-compass"/>
                                            <h4>icon-compass</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-compass"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-keynote"/>
                                            <h4>icon-keynote</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-keynote"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-paperclip"/>
                                            <h4>icon-paperclip</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-paperclip"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-power"/>
                                            <h4>icon-power</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-power"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-lightbulb"/>
                                            <h4>icon-lightbulb</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-lightbulb"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tag"/>
                                            <h4>icon-tag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tags"/>
                                            <h4>icon-tags</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tags"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cleaning"/>
                                            <h4>icon-cleaning</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cleaning"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ruler"/>
                                            <h4>icon-ruler</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ruler"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gift"/>
                                            <h4>icon-gift</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gift"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-umbrella"/>
                                            <h4>icon-umbrella</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-umbrella"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-book"/>
                                            <h4>icon-book</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-book"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bookmark"/>
                                            <h4>icon-bookmark</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bookmark"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-wifi"/>
                                            <h4>icon-wifi</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-wifi"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cup"/>
                                            <h4>icon-cup</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cup"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-stroller"/>
                                            <h4>icon-stroller</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-stroller"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-headphones"/>
                                            <h4>icon-headphones</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-headphones"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-headset"/>
                                            <h4>icon-headset</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-headset"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-warning-sign"/>
                                            <h4>icon-warning-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-warning-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-signal"/>
                                            <h4>icon-signal</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-signal"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-retweet"/>
                                            <h4>icon-retweet</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-retweet"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-refresh"/>
                                            <h4>icon-refresh</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-refresh"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-roundabout"/>
                                            <h4>icon-roundabout</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-roundabout"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-random"/>
                                            <h4>icon-random</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-random"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-heat"/>
                                            <h4>icon-heat</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-heat"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-repeat"/>
                                            <h4>icon-repeat</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-repeat"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-display"/>
                                            <h4>icon-display</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-display"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-log-book"/>
                                            <h4>icon-log-book</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-log-book"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-address-book"/>
                                            <h4>icon-address-book</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-address-book"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-building"/>
                                            <h4>icon-building</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-building"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-eyedropper"/>
                                            <h4>icon-eyedropper</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-eyedropper"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-adjust"/>
                                            <h4>icon-adjust</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-adjust"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tint"/>
                                            <h4>icon-tint</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tint"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-crop"/>
                                            <h4>icon-crop</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-crop"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vector-path-square"/>
                                            <h4>icon-vector-path-square</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vector-path-square"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vector-path-circle"/>
                                            <h4>icon-vector-path-circle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vector-path-circle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vector-path-polygon"/>
                                            <h4>icon-vector-path-polygon</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vector-path-polygon"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vector-path-line"/>
                                            <h4>icon-vector-path-line</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vector-path-line"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vector-path-curve"/>
                                            <h4>icon-vector-path-curve</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vector-path-curve"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vector-path-all"/>
                                            <h4>icon-vector-path-all</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vector-path-all"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-font"/>
                                            <h4>icon-font</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-font"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-italic"/>
                                            <h4>icon-italic</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-italic"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bold"/>
                                            <h4>icon-bold</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bold"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-underline"/>
                                            <h4>icon-text-underline</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-underline"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-strike"/>
                                            <h4>icon-text-strike</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-strike"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-height"/>
                                            <h4>icon-text-height</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-height"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-width"/>
                                            <h4>icon-text-width</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-width"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-resize"/>
                                            <h4>icon-text-resize</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-resize"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-left-indent"/>
                                            <h4>icon-left-indent</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-left-indent"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-right-indent"/>
                                            <h4>icon-right-indent</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-right-indent"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-align-left"/>
                                            <h4>icon-align-left</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-align-left"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-align-center"/>
                                            <h4>icon-align-center</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-align-center"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-align-right"/>
                                            <h4>icon-align-right</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-align-right"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-justify"/>
                                            <h4>icon-justify</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-justify"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-list"/>
                                            <h4>icon-list</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-list"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-smaller"/>
                                            <h4>icon-text-smaller</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-smaller"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-bigger"/>
                                            <h4>icon-text-bigger</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-bigger"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-embed"/>
                                            <h4>icon-embed</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-embed"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-embed-close"/>
                                            <h4>icon-embed-close</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-embed-close"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-table"/>
                                            <h4>icon-table</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-table"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-full"/>
                                            <h4>icon-message-full</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-full"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-empty"/>
                                            <h4>icon-message-empty</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-empty"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-in"/>
                                            <h4>icon-message-in</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-in"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-out"/>
                                            <h4>icon-message-out</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-out"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-plus"/>
                                            <h4>icon-message-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-minus"/>
                                            <h4>icon-message-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-ban"/>
                                            <h4>icon-message-ban</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-ban"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-flag"/>
                                            <h4>icon-message-flag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-flag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-lock"/>
                                            <h4>icon-message-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-new"/>
                                            <h4>icon-message-new</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-new"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-inbox"/>
                                            <h4>icon-inbox</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-inbox"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-inbox-plus"/>
                                            <h4>icon-inbox-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-inbox-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-inbox-minus"/>
                                            <h4>icon-inbox-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-inbox-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-inbox-lock"/>
                                            <h4>icon-inbox-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-inbox-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-inbox-in"/>
                                            <h4>icon-inbox-in</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-inbox-in"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-inbox-out"/>
                                            <h4>icon-inbox-out</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-inbox-out"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cogwheel"/>
                                            <h4>icon-cogwheel</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cogwheel"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cogwheels"/>
                                            <h4>icon-cogwheels</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cogwheels"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-picture"/>
                                            <h4>icon-picture</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-picture"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-adjust-alt"/>
                                            <h4>icon-adjust-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-adjust-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-database-lock"/>
                                            <h4>icon-database-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-database-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-database-plus"/>
                                            <h4>icon-database-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-database-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-database-minus"/>
                                            <h4>icon-database-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-database-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-database-ban"/>
                                            <h4>icon-database-ban</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-database-ban"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-folder-open"/>
                                            <h4>icon-folder-open</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-folder-open"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-folder-plus"/>
                                            <h4>icon-folder-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-folder-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-folder-minus"/>
                                            <h4>icon-folder-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-folder-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-folder-lock"/>
                                            <h4>icon-folder-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-folder-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-folder-flag"/>
                                            <h4>icon-folder-flag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-folder-flag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-folder-new"/>
                                            <h4>icon-folder-new</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-folder-new"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-edit"/>
                                            <h4>icon-edit</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-edit"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-new-window"/>
                                            <h4>icon-new-window</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-new-window"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-check"/>
                                            <h4>icon-check</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-check"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-unchecked"/>
                                            <h4>icon-unchecked</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-unchecked"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-more-windows"/>
                                            <h4>icon-more-windows</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-more-windows"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-show-big-thumbnails"/>
                                            <h4>icon-show-big-thumbnails</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-show-big-thumbnails"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-show-thumbnails"/>
                                            <h4>icon-show-thumbnails</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-show-thumbnails"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-show-thumbnails-with-lines"/>
                                            <h4>icon-show-thumbnails-with-lines</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-show-thumbnails-with-lines"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-show-lines"/>
                                            <h4>icon-show-lines</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-show-lines"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-playlist"/>
                                            <h4>icon-playlist</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-playlist"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-imac"/>
                                            <h4>icon-imac</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-imac"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-macbook"/>
                                            <h4>icon-macbook</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-macbook"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ipad"/>
                                            <h4>icon-ipad</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ipad"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-iphone"/>
                                            <h4>icon-iphone</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-iphone"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-iphone-transfer"/>
                                            <h4>icon-iphone-transfer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-iphone-transfer"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-iphone-exchange"/>
                                            <h4>icon-iphone-exchange</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-iphone-exchange"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ipod"/>
                                            <h4>icon-ipod</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ipod"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ipod-shuffle"/>
                                            <h4>icon-ipod-shuffle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ipod-shuffle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ear-plugs"/>
                                            <h4>icon-ear-plugs</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ear-plugs"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-record"/>
                                            <h4>icon-record</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-record"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-step-backward"/>
                                            <h4>icon-step-backward</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-step-backward"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fast-backward"/>
                                            <h4>icon-fast-backward</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fast-backward"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-rewind"/>
                                            <h4>icon-rewind</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-rewind"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-play"/>
                                            <h4>icon-play</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-play"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pause"/>
                                            <h4>icon-pause</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pause"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-stop"/>
                                            <h4>icon-stop</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-stop"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-forward"/>
                                            <h4>icon-forward</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-forward"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fast-forward"/>
                                            <h4>icon-fast-forward</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fast-forward"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-step-forward"/>
                                            <h4>icon-step-forward</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-step-forward"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-eject"/>
                                            <h4>icon-eject</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-eject"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-facetime-video"/>
                                            <h4>icon-facetime-video</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-facetime-video"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-download-alt"/>
                                            <h4>icon-download-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-download-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mute"/>
                                            <h4>icon-mute</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mute"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-volume-down"/>
                                            <h4>icon-volume-down</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-volume-down"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-volume-up"/>
                                            <h4>icon-volume-up</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-volume-up"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-screenshot"/>
                                            <h4>icon-screenshot</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-screenshot"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-move"/>
                                            <h4>icon-move</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-move"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-more"/>
                                            <h4>icon-more</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-more"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-brightness-reduce"/>
                                            <h4>icon-brightness-reduce</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-brightness-reduce"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-brightness-increase"/>
                                            <h4>icon-brightness-increase</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-brightness-increase"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-plus-sign"/>
                                            <h4>icon-plus-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-plus-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-minus-sign"/>
                                            <h4>icon-minus-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-minus-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-remove-sign"/>
                                            <h4>icon-remove-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-remove-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ok-sign"/>
                                            <h4>icon-ok-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ok-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-question-sign"/>
                                            <h4>icon-question-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-question-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-info-sign"/>
                                            <h4>icon-info-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-info-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-exclamation-sign"/>
                                            <h4>icon-exclamation-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-exclamation-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-remove-circle"/>
                                            <h4>icon-remove-circle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-remove-circle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ok-circle"/>
                                            <h4>icon-ok-circle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ok-circle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ban-circle"/>
                                            <h4>icon-ban-circle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ban-circle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-download"/>
                                            <h4>icon-download</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-download"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-upload"/>
                                            <h4>icon-upload</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-upload"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shopping-cart"/>
                                            <h4>icon-shopping-cart</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shopping-cart"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-lock"/>
                                            <h4>icon-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-unlock"/>
                                            <h4>icon-unlock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-unlock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-electricity"/>
                                            <h4>icon-electricity</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-electricity"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ok"/>
                                            <h4>icon-ok</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ok"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-remove"/>
                                            <h4>icon-remove</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-remove"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cart-in"/>
                                            <h4>icon-cart-in</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cart-in"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cart-out"/>
                                            <h4>icon-cart-out</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cart-out"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-arrow-left"/>
                                            <h4>icon-arrow-left</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-arrow-left"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-arrow-right"/>
                                            <h4>icon-arrow-right</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-arrow-right"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-arrow-down"/>
                                            <h4>icon-arrow-down</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-arrow-down"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-arrow-up"/>
                                            <h4>icon-arrow-up</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-arrow-up"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-resize-small"/>
                                            <h4>icon-resize-small</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-resize-small"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-resize-full"/>
                                            <h4>icon-resize-full</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-resize-full"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-circle-arrow-left"/>
                                            <h4>icon-circle-arrow-left</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-circle-arrow-left"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-circle-arrow-right"/>
                                            <h4>icon-circle-arrow-right</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-circle-arrow-right"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-circle-arrow-top"/>
                                            <h4>icon-circle-arrow-top</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-circle-arrow-top"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-circle-arrow-down"/>
                                            <h4>icon-circle-arrow-down</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-circle-arrow-down"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-play-button"/>
                                            <h4>icon-play-button</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-play-button"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-unshare"/>
                                            <h4>icon-unshare</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-unshare"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-share"/>
                                            <h4>icon-share</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-share"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-chevron-right"/>
                                            <h4>icon-chevron-right</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-chevron-right"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-chevron-left"/>
                                            <h4>icon-chevron-left</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-chevron-left"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bluetooth"/>
                                            <h4>icon-bluetooth</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bluetooth"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-euro"/>
                                            <h4>icon-euro</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-euro"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-usd"/>
                                            <h4>icon-usd</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-usd"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gbp"/>
                                            <h4>icon-gbp</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gbp"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-retweet-2"/>
                                            <h4>icon-retweet-2</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-retweet-2"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-moon"/>
                                            <h4>icon-moon</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-moon"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sun"/>
                                            <h4>icon-sun</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sun"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cloud"/>
                                            <h4>icon-cloud</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cloud"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-direction"/>
                                            <h4>icon-direction</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-direction"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-brush"/>
                                            <h4>icon-brush</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-brush"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pen"/>
                                            <h4>icon-pen</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pen"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-zoom-in"/>
                                            <h4>icon-zoom-in</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-zoom-in"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-zoom-out"/>
                                            <h4>icon-zoom-out</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-zoom-out"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pin"/>
                                            <h4>icon-pin</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pin"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-albums"/>
                                            <h4>icon-albums</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-albums"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-rotation-lock"/>
                                            <h4>icon-rotation-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-rotation-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-flash"/>
                                            <h4>icon-flash</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-flash"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-map-marker"/>
                                            <h4>icon-map-marker</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-map-marker"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-anchor"/>
                                            <h4>icon-anchor</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-anchor"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-conversation"/>
                                            <h4>icon-conversation</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-conversation"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-chat"/>
                                            <h4>icon-chat</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-chat"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-note-empty"/>
                                            <h4>icon-note-empty</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-note-empty"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-note"/>
                                            <h4>icon-note</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-note"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-asterisk"/>
                                            <h4>icon-asterisk</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-asterisk"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-divide"/>
                                            <h4>icon-divide</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-divide"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-snorkel-diving"/>
                                            <h4>icon-snorkel-diving</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-snorkel-diving"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-scuba-diving"/>
                                            <h4>icon-scuba-diving</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-scuba-diving"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-oxygen-bottle"/>
                                            <h4>icon-oxygen-bottle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-oxygen-bottle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fins"/>
                                            <h4>icon-fins</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fins"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fishes"/>
                                            <h4>icon-fishes</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fishes"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-boat"/>
                                            <h4>icon-boat</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-boat"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-delete"/>
                                            <h4>icon-delete</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-delete"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sheriffs-star"/>
                                            <h4>icon-sheriffs-star</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sheriffs-star"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-qrcode"/>
                                            <h4>icon-qrcode</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-qrcode"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-barcode"/>
                                            <h4>icon-barcode</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-barcode"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pool"/>
                                            <h4>icon-pool</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pool"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-buoy"/>
                                            <h4>icon-buoy</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-buoy"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-spade"/>
                                            <h4>icon-spade</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-spade"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bank"/>
                                            <h4>icon-bank</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bank"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vcard"/>
                                            <h4>icon-vcard</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vcard"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-electrical-plug"/>
                                            <h4>icon-electrical-plug</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-electrical-plug"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-flag"/>
                                            <h4>icon-flag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-flag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-credit-card"/>
                                            <h4>icon-credit-card</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-credit-card"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-keyboard-wireless"/>
                                            <h4>icon-keyboard-wireless</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-keyboard-wireless"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-keyboard-wired"/>
                                            <h4>icon-keyboard-wired</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-keyboard-wired"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shield"/>
                                            <h4>icon-shield</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shield"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ring"/>
                                            <h4>icon-ring</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ring"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cake"/>
                                            <h4>icon-cake</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cake"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-drink"/>
                                            <h4>icon-drink</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-drink"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-beer"/>
                                            <h4>icon-beer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-beer"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fast-food"/>
                                            <h4>icon-fast-food</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fast-food"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cutlery"/>
                                            <h4>icon-cutlery</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cutlery"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pizza"/>
                                            <h4>icon-pizza</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pizza"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-birthday-cake"/>
                                            <h4>icon-birthday-cake</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-birthday-cake"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tablet"/>
                                            <h4>icon-tablet</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tablet"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-settings"/>
                                            <h4>icon-settings</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-settings"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bullets"/>
                                            <h4>icon-bullets</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bullets"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cardio"/>
                                            <h4>icon-cardio</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cardio"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-t-shirt"/>
                                            <h4>icon-t-shirt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-t-shirt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pants"/>
                                            <h4>icon-pants</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pants"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sweater"/>
                                            <h4>icon-sweater</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sweater"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fabric"/>
                                            <h4>icon-fabric</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fabric"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-leather"/>
                                            <h4>icon-leather</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-leather"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-scissors"/>
                                            <h4>icon-scissors</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-scissors"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bomb"/>
                                            <h4>icon-bomb</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bomb"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-skull"/>
                                            <h4>icon-skull</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-skull"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-celebration"/>
                                            <h4>icon-celebration</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-celebration"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tea-kettle"/>
                                            <h4>icon-tea-kettle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tea-kettle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-french-press"/>
                                            <h4>icon-french-press</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-french-press"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-coffee-cup"/>
                                            <h4>icon-coffee-cup</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-coffee-cup"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pot"/>
                                            <h4>icon-pot</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pot"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-grater"/>
                                            <h4>icon-grater</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-grater"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-kettle"/>
                                            <h4>icon-kettle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-kettle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hospital"/>
                                            <h4>icon-hospital</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hospital"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hospital-h"/>
                                            <h4>icon-hospital-h</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hospital-h"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-microphone"/>
                                            <h4>icon-microphone</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-microphone"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-webcam"/>
                                            <h4>icon-webcam</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-webcam"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temple-christianity-church"/>
                                            <h4>icon-temple-christianity-church</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temple-christianity-church"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temple-islam"/>
                                            <h4>icon-temple-islam</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temple-islam"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temple-hindu"/>
                                            <h4>icon-temple-hindu</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temple-hindu"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temple-buddhist"/>
                                            <h4>icon-temple-buddhist</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temple-buddhist"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bicycle"/>
                                            <h4>icon-bicycle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bicycle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-life-preserver"/>
                                            <h4>icon-life-preserver</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-life-preserver"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-share-alt"/>
                                            <h4>icon-share-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-share-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-comments"/>
                                            <h4>icon-comments</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-comments"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-flower"/>
                                            <h4>icon-flower</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-flower"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-baseball"/>
                                            <h4>icon-baseball</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-baseball"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-rugby"/>
                                            <h4>icon-rugby</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-rugby"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ax"/>
                                            <h4>icon-ax</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ax"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-table-tennis"/>
                                            <h4>icon-table-tennis</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-table-tennis"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bowling"/>
                                            <h4>icon-bowling</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bowling"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tree-conifer"/>
                                            <h4>icon-tree-conifer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tree-conifer"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tree-deciduous"/>
                                            <h4>icon-tree-deciduous</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tree-deciduous"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-more-items"/>
                                            <h4>icon-more-items</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-more-items"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sort"/>
                                            <h4>icon-sort</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sort"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-filter"/>
                                            <h4>icon-filter</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-filter"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gamepad"/>
                                            <h4>icon-gamepad</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gamepad"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-playing-dices"/>
                                            <h4>icon-playing-dices</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-playing-dices"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-calculator"/>
                                            <h4>icon-calculator</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-calculator"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tie"/>
                                            <h4>icon-tie</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tie"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-wallet"/>
                                            <h4>icon-wallet</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-wallet"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-piano"/>
                                            <h4>icon-piano</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-piano"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sampler"/>
                                            <h4>icon-sampler</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sampler"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-podium"/>
                                            <h4>icon-podium</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-podium"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-soccer-ball"/>
                                            <h4>icon-soccer-ball</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-soccer-ball"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-blog"/>
                                            <h4>icon-blog</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-blog"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dashboard"/>
                                            <h4>icon-dashboard</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dashboard"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-certificate"/>
                                            <h4>icon-certificate</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-certificate"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bell"/>
                                            <h4>icon-bell</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bell"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-candle"/>
                                            <h4>icon-candle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-candle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pushpin"/>
                                            <h4>icon-pushpin</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pushpin"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-iphone-shake"/>
                                            <h4>icon-iphone-shake</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-iphone-shake"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pin-flag"/>
                                            <h4>icon-pin-flag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pin-flag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-turtle"/>
                                            <h4>icon-turtle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-turtle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-rabbit"/>
                                            <h4>icon-rabbit</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-rabbit"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-globe"/>
                                            <h4>icon-globe</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-globe"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-briefcase"/>
                                            <h4>icon-briefcase</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-briefcase"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hdd"/>
                                            <h4>icon-hdd</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hdd"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-thumbs-up"/>
                                            <h4>icon-thumbs-up</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-thumbs-up"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-thumbs-down"/>
                                            <h4>icon-thumbs-down</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-thumbs-down"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hand-right"/>
                                            <h4>icon-hand-right</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hand-right"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hand-left"/>
                                            <h4>icon-hand-left</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hand-left"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hand-up"/>
                                            <h4>icon-hand-up</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hand-up"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hand-down"/>
                                            <h4>icon-hand-down</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hand-down"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fullscreen"/>
                                            <h4>icon-fullscreen</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fullscreen"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shopping-bag"/>
                                            <h4>icon-shopping-bag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shopping-bag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-book-open"/>
                                            <h4>icon-book-open</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-book-open"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-nameplate"/>
                                            <h4>icon-nameplate</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-nameplate"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-nameplate-alt"/>
                                            <h4>icon-nameplate-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-nameplate-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vases"/>
                                            <h4>icon-vases</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vases"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bullhorn"/>
                                            <h4>icon-bullhorn</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bullhorn"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dumbbell"/>
                                            <h4>icon-dumbbell</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dumbbell"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-suitcase"/>
                                            <h4>icon-suitcase</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-suitcase"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file-import"/>
                                            <h4>icon-file-import</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file-import"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file-export"/>
                                            <h4>icon-file-export</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file-export"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bug"/>
                                            <h4>icon-bug</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bug"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-crown"/>
                                            <h4>icon-crown</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-crown"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-smoking"/>
                                            <h4>icon-smoking</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-smoking"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cloud-upload"/>
                                            <h4>icon-cloud-upload</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cloud-upload"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cloud-download"/>
                                            <h4>icon-cloud-download</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cloud-download"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-restart"/>
                                            <h4>icon-restart</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-restart"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-security-camera"/>
                                            <h4>icon-security-camera</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-security-camera"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-expand"/>
                                            <h4>icon-expand</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-expand"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-collapse"/>
                                            <h4>icon-collapse</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-collapse"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-collapse-top"/>
                                            <h4>icon-collapse-top</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-collapse-top"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-globe-af"/>
                                            <h4>icon-globe-af</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-globe-af"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-global"/>
                                            <h4>icon-global</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-global"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-spray"/>
                                            <h4>icon-spray</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-spray"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-nails"/>
                                            <h4>icon-nails</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-nails"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-claw-hammer"/>
                                            <h4>icon-claw-hammer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-claw-hammer"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-classNameic-hammer"/>
                                            <h4>icon-classNameic-hammer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-classNameic-hammer"/</code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hand-saw"/>
                                            <h4>icon-hand-saw</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hand-saw"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-riflescope"/>
                                            <h4>icon-riflescope</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-riflescope"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-electrical-socket-eu"/>
                                            <h4>icon-electrical-socket-eu</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-electrical-socket-eu"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-electrical-socket-us"/>
                                            <h4>icon-electrical-socket-us</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-electrical-socket-us"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-message-forward"/>
                                            <h4>icon-message-forward</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-message-forward"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-coat-hanger"/>
                                            <h4>icon-coat-hanger</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-coat-hanger"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dress"/>
                                            <h4>icon-dress</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dress"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bathrobe"/>
                                            <h4>icon-bathrobe</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bathrobe"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shirt"/>
                                            <h4>icon-shirt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shirt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-underwear"/>
                                            <h4>icon-underwear</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-underwear"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-log-in"/>
                                            <h4>icon-log-in</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-log-in"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-log-out"/>
                                            <h4>icon-log-out</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-log-out"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-exit"/>
                                            <h4>icon-exit</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-exit"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-new-window-alt"/>
                                            <h4>icon-new-window-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-new-window-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-video-sd"/>
                                            <h4>icon-video-sd</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-video-sd"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-video-hd"/>
                                            <h4>icon-video-hd</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-video-hd"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-subtitles"/>
                                            <h4>icon-subtitles</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-subtitles"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sound-stereo"/>
                                            <h4>icon-sound-stereo</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sound-stereo"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sound-dolby"/>
                                            <h4>icon-sound-dolby</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sound-dolby"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sound-5-1"/>
                                            <h4>icon-sound-5-1</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sound-5-1"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sound-6-1"/>
                                            <h4>icon-sound-6-1</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sound-6-1"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sound-7-1"/>
                                            <h4>icon-sound-7-1</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sound-7-1"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-copyright-mark"/>
                                            <h4>icon-copyright-mark</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-copyright-mark"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-registration-mark"/>
                                            <h4>icon-registration-mark</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-registration-mark"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-radar"/>
                                            <h4>icon-radar</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-radar"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-skateboard"/>
                                            <h4>icon-skateboard</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-skateboard"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-golf-course"/>
                                            <h4>icon-golf-course</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-golf-course"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sorting"/>
                                            <h4>icon-sorting</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sorting"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sort-by-alphabet"/>
                                            <h4>icon-sort-by-alphabet</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sort-by-alphabet"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sort-by-alphabet-alt"/>
                                            <h4>icon-sort-by-alphabet-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sort-by-alphabet-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sort-by-order"/>
                                            <h4>icon-sort-by-order</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sort-by-order"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sort-by-order-alt"/>
                                            <h4>icon-sort-by-order-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sort-by-order-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sort-by-attributes"/>
                                            <h4>icon-sort-by-attributes</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sort-by-attributes"/</code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sort-by-attributes-alt"/>
                                            <h4>icon-sort-by-attributes-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sort-by-attributes-a</code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-compressed"/>
                                            <h4>icon-compressed</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-compressed"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-package"/>
                                            <h4>icon-package</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-package"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cloud-plus"/>
                                            <h4>icon-cloud-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cloud-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cloud-minus"/>
                                            <h4>icon-cloud-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cloud-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-disk-save"/>
                                            <h4>icon-disk-save</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-disk-save"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-disk-open"/>
                                            <h4>icon-disk-open</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-disk-open"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-disk-saved"/>
                                            <h4>icon-disk-saved</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-disk-saved"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-disk-remove"/>
                                            <h4>icon-disk-remove</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-disk-remove"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-disk-import"/>
                                            <h4>icon-disk-import</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-disk-import"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-disk-export"/>
                                            <h4>icon-disk-export</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-disk-export"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tower"/>
                                            <h4>icon-tower</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tower"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-send"/>
                                            <h4>icon-send</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-send"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-git-branch"/>
                                            <h4>icon-git-branch</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-git-branch"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-git-create"/>
                                            <h4>icon-git-create</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-git-create"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-git-private"/>
                                            <h4>icon-git-private</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-git-private"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-git-delete"/>
                                            <h4>icon-git-delete</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-git-delete"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-git-merge"/>
                                            <h4>icon-git-merge</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-git-merge"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-git-pull-request"/>
                                            <h4>icon-git-pull-request</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-git-pull-request"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-git-compare"/>
                                            <h4>icon-git-compare</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-git-compare"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-git-commit"/>
                                            <h4>icon-git-commit</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-git-commit"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-construction-cone"/>
                                            <h4>icon-construction-cone</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-construction-cone"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shoe-steps"/>
                                            <h4>icon-shoe-steps</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shoe-steps"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-plus"/>
                                            <h4>icon-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-minus"/>
                                            <h4>icon-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-redo"/>
                                            <h4>icon-redo</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-redo"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-undo"/>
                                            <h4>icon-undo</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-undo"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-golf"/>
                                            <h4>icon-golf</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-golf"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hockey"/>
                                            <h4>icon-hockey</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hockey"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pipe"/>
                                            <h4>icon-pipe</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pipe"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-wrench"/>
                                            <h4>icon-wrench</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-wrench"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-folder-closed"/>
                                            <h4>icon-folder-closed</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-folder-closed"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-phone-alt"/>
                                            <h4>icon-phone-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-phone-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-earphone"/>
                                            <h4>icon-earphone</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-earphone"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-floppy-disk"/>
                                            <h4>icon-floppy-disk</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-floppy-disk"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-floppy-saved"/>
                                            <h4>icon-floppy-saved</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-floppy-saved"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-floppy-remove"/>
                                            <h4>icon-floppy-remove</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-floppy-remove"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-floppy-save"/>
                                            <h4>icon-floppy-save</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-floppy-save"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-floppy-open"/>
                                            <h4>icon-floppy-open</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-floppy-open"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-translate"/>
                                            <h4>icon-translate</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-translate"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fax"/>
                                            <h4>icon-fax</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fax"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-factory"/>
                                            <h4>icon-factory</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-factory"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shop-window"/>
                                            <h4>icon-shop-window</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shop-window"/></code>
                                    </div>
                                    {/* <spa className="icon-shop"></spa> */}
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-kiosk"/>
                                            <h4>icon-kiosk</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-kiosk"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-kiosk-wheels"/>
                                            <h4>icon-kiosk-wheels</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-kiosk-wheels"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-kiosk-light"/>
                                            <h4>icon-kiosk-light</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-kiosk-light"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-kiosk-food"/>
                                            <h4>icon-kiosk-food</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-kiosk-food"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-transfer"/>
                                            <h4>icon-transfer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-transfer"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-money"/>
                                            <h4>icon-money</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-money"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-header"/>
                                            <h4>icon-header</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-header"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-blacksmith"/>
                                            <h4>icon-blacksmith</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-blacksmith"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-saw-blade"/>
                                            <h4>icon-saw-blade</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-saw-blade"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-basketball"/>
                                            <h4>icon-basketball</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-basketball"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-server"/>
                                            <h4>icon-server</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-server"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-server-plus"/>
                                            <h4>icon-server-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-server-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-server-minus"/>
                                            <h4>icon-server-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-server-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-server-ban"/>
                                            <h4>icon-server-ban</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-server-ban"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-server-flag"/>
                                            <h4>icon-server-flag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-server-flag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-server-lock"/>
                                            <h4>icon-server-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-server-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-server-new"/>
                                            <h4>icon-server-new</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-server-new"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-charging-station"/>
                                            <h4>icon-charging-station</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-charging-station"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gas-station"/>
                                            <h4>icon-gas-station</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gas-station"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-target"/>
                                            <h4>icon-target</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-target"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bed"/>
                                            <h4>icon-bed</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bed"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mosquito"/>
                                            <h4>icon-mosquito</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mosquito"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dining-set"/>
                                            <h4>icon-dining-set</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dining-set"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-plate-of-food"/>
                                            <h4>icon-plate-of-food</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-plate-of-food"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hygiene-kit"/>
                                            <h4>icon-hygiene-kit</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hygiene-kit"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-blackboard"/>
                                            <h4>icon-blackboard</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-blackboard"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-marriage"/>
                                            <h4>icon-marriage</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-marriage"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bucket"/>
                                            <h4>icon-bucket</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bucket"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-none-color-swatch"/>
                                            <h4>icon-none-color-swatch</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-none-color-swatch"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bring-forward"/>
                                            <h4>icon-bring-forward</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bring-forward"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bring-to-front"/>
                                            <h4>icon-bring-to-front</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bring-to-front"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-send-backward"/>
                                            <h4>icon-send-backward</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-send-backward"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-send-to-back"/>
                                            <h4>icon-send-to-back</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-send-to-back"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fit-frame-to-image"/>
                                            <h4>icon-fit-frame-to-image</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fit-frame-to-image"/</code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fit-image-to-frame"/>
                                            <h4>icon-fit-image-to-frame</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fit-image-to-frame"/</code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-multiple-displays"/>
                                            <h4>icon-multiple-displays</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-multiple-displays"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-handshake"/>
                                            <h4>icon-handshake</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-handshake"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-child"/>
                                            <h4>icon-child</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-child"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-baby-formula"/>
                                            <h4>icon-baby-formula</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-baby-formula"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-medicine"/>
                                            <h4>icon-medicine</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-medicine"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-atv-vehicle"/>
                                            <h4>icon-atv-vehicle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-atv-vehicle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-motorcycle"/>
                                            <h4>icon-motorcycle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-motorcycle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bed-alt"/>
                                            <h4>icon-bed-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bed-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tent"/>
                                            <h4>icon-tent</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tent"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-glasses"/>
                                            <h4>icon-glasses</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-glasses"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sunglasses"/>
                                            <h4>icon-sunglasses</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sunglasses"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-family"/>
                                            <h4>icon-family</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-family"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-education"/>
                                            <h4>icon-education</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-education"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shoes"/>
                                            <h4>icon-shoes</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shoes"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-map"/>
                                            <h4>icon-map</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-map"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cd"/>
                                            <h4>icon-cd</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cd"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-alert"/>
                                            <h4>icon-alert</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-alert"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-piggy-bank"/>
                                            <h4>icon-piggy-bank</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-piggy-bank"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-star-half"/>
                                            <h4>icon-star-half</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-star-half"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cluster"/>
                                            <h4>icon-cluster</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cluster"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-flowchart"/>
                                            <h4>icon-flowchart</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-flowchart"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-commodities"/>
                                            <h4>icon-commodities</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-commodities"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-duplicate"/>
                                            <h4>icon-duplicate</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-duplicate"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-copy"/>
                                            <h4>icon-copy</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-copy"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-paste"/>
                                            <h4>icon-paste</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-paste"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bath-bathtub"/>
                                            <h4>icon-bath-bathtub</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bath-bathtub"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bath-shower"/>
                                            <h4>icon-bath-shower</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bath-shower"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shower"/>
                                            <h4>icon-shower</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shower"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-menu-hamburger"/>
                                            <h4>icon-menu-hamburger</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-menu-hamburger"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-option-vertical"/>
                                            <h4>icon-option-vertical</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-option-vertical"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-option-horizontal"/>
                                            <h4>icon-option-horizontal</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-option-horizontal"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-currency-conversion"/>
                                            <h4>icon-currency-conversion</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-currency-conversion"</code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-ban"/>
                                            <h4>icon-user-ban</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-ban"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-lock"/>
                                            <h4>icon-user-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-flag"/>
                                            <h4>icon-user-flag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-flag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-asterisk"/>
                                            <h4>icon-user-asterisk</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-asterisk"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-alert"/>
                                            <h4>icon-user-alert</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-alert"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-key"/>
                                            <h4>icon-user-key</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-key"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-conversation"/>
                                            <h4>icon-user-conversation</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-conversation"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-database"/>
                                            <h4>icon-database</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-database"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-database-search"/>
                                            <h4>icon-database-search</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-database-search"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-list-alt"/>
                                            <h4>icon-list-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-list-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hazard-sign"/>
                                            <h4>icon-hazard-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hazard-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hazard"/>
                                            <h4>icon-hazard</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hazard"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-stop-sign"/>
                                            <h4>icon-stop-sign</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-stop-sign"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-lab"/>
                                            <h4>icon-lab</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-lab"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-lab-alt"/>
                                            <h4>icon-lab-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-lab-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ice-cream"/>
                                            <h4>icon-ice-cream</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ice-cream"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ice-lolly"/>
                                            <h4>icon-ice-lolly</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ice-lolly"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ice-lolly-tasted"/>
                                            <h4>icon-ice-lolly-tasted</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ice-lolly-tasted"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-invoice"/>
                                            <h4>icon-invoice</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-invoice"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cart-tick"/>
                                            <h4>icon-cart-tick</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cart-tick"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hourglass"/>
                                            <h4>icon-hourglass</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hourglass"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cat"/>
                                            <h4>icon-cat</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cat"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-lamp"/>
                                            <h4>icon-lamp</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-lamp"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-scale-classNameic"/>
                                            <h4>icon-scale-classNameic</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-scale-classNameic"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-eye-plus"/>
                                            <h4>icon-eye-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-eye-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-eye-minus"/>
                                            <h4>icon-eye-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-eye-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-quote"/>
                                            <h4>icon-quote</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-quote"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bitcoin"/>
                                            <h4>icon-bitcoin</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bitcoin"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-yen"/>
                                            <h4>icon-yen</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-yen"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ruble"/>
                                            <h4>icon-ruble</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ruble"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-erase"/>
                                            <h4>icon-erase</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-erase"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-podcast"/>
                                            <h4>icon-podcast</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-podcast"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-firework"/>
                                            <h4>icon-firework</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-firework"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-scale"/>
                                            <h4>icon-scale</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-scale"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-king"/>
                                            <h4>icon-king</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-king"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-queen"/>
                                            <h4>icon-queen</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-queen"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pawn"/>
                                            <h4>icon-pawn</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pawn"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bishop"/>
                                            <h4>icon-bishop</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bishop"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-knight"/>
                                            <h4>icon-knight</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-knight"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mic-mute"/>
                                            <h4>icon-mic-mute</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mic-mute"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-voicemail"/>
                                            <h4>icon-voicemail</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-voicemail"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-paragraph-alt"/>
                                            <h4>icon-paragraph-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-paragraph-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-person-walking"/>
                                            <h4>icon-person-walking</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-person-walking"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-person-wheelchair"/>
                                            <h4>icon-person-wheelchair</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-person-wheelchair"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-underground"/>
                                            <h4>icon-underground</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-underground"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-car-hov"/>
                                            <h4>icon-car-hov</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-car-hov"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-car-rental"/>
                                            <h4>icon-car-rental</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-car-rental"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-transport"/>
                                            <h4>icon-transport</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-transport"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-taxi"/>
                                            <h4>icon-taxi</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-taxi"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ice-cream-no"/>
                                            <h4>icon-ice-cream-no</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ice-cream-no"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-uk-rat-u"/>
                                            <h4>icon-uk-rat-u</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-uk-rat-u"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-uk-rat-pg"/>
                                            <h4>icon-uk-rat-pg</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-uk-rat-pg"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-uk-rat-12a"/>
                                            <h4>icon-uk-rat-12a</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-uk-rat-12a"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-uk-rat-12"/>
                                            <h4>icon-uk-rat-12</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-uk-rat-12"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-uk-rat-15"/>
                                            <h4>icon-uk-rat-15</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-uk-rat-15"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-uk-rat-18"/>
                                            <h4>icon-uk-rat-18</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-uk-rat-18"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-uk-rat-r18"/>
                                            <h4>icon-uk-rat-r18</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-uk-rat-r18"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tv"/>
                                            <h4>icon-tv</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tv"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sms"/>
                                            <h4>icon-sms</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sms"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mms"/>
                                            <h4>icon-mms</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mms"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-us-rat-g"/>
                                            <h4>icon-us-rat-g</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-us-rat-g"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-us-rat-pg"/>
                                            <h4>icon-us-rat-pg</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-us-rat-pg"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-us-rat-pg-13"/>
                                            <h4>icon-us-rat-pg-13</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-us-rat-pg-13"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-us-rat-restricted"/>
                                            <h4>icon-us-rat-restricted</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-us-rat-restricted"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-us-rat-no-one-17"/>
                                            <h4>icon-us-rat-no-one-17</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-us-rat-no-one-17"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-equalizer"/>
                                            <h4>icon-equalizer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-equalizer"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-speakers"/>
                                            <h4>icon-speakers</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-speakers"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-remote-control"/>
                                            <h4>icon-remote-control</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-remote-control"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-remote-control-tv"/>
                                            <h4>icon-remote-control-tv</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-remote-control-tv"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-shredder"/>
                                            <h4>icon-shredder</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-shredder"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-folder-heart"/>
                                            <h4>icon-folder-heart</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-folder-heart"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-person-running"/>
                                            <h4>icon-person-running</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-person-running"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-person"/>
                                            <h4>icon-person</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-person"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-voice"/>
                                            <h4>icon-voice</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-voice"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-stethoscope"/>
                                            <h4>icon-stethoscope</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-stethoscope"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-paired"/>
                                            <h4>icon-paired</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-paired"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-activity"/>
                                            <h4>icon-activity</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-activity"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-watch"/>
                                            <h4>icon-watch</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-watch"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-scissors-alt"/>
                                            <h4>icon-scissors-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-scissors-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-car-wheel"/>
                                            <h4>icon-car-wheel</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-car-wheel"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-chevron-up"/>
                                            <h4>icon-chevron-up</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-chevron-up"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-chevron-down"/>
                                            <h4>icon-chevron-down</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-chevron-down"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-superscript"/>
                                            <h4>icon-superscript</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-superscript"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-subscript"/>
                                            <h4>icon-subscript</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-subscript"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-size"/>
                                            <h4>icon-text-size</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-size"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-color"/>
                                            <h4>icon-text-color</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-color"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-text-background"/>
                                            <h4>icon-text-background</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-text-background"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-modal-window"/>
                                            <h4>icon-modal-window</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-modal-window"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-newspaper"/>
                                            <h4>icon-newspaper</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-newspaper"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tractor"/>
                                            <h4>icon-tractor</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tractor"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-camping"/>
                                            <h4>icon-camping</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-camping"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-camping-benches"/>
                                            <h4>icon-camping-benches</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-camping-benches"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-forest"/>
                                            <h4>icon-forest</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-forest"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-park"/>
                                            <h4>icon-park</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-park"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-field"/>
                                            <h4>icon-field</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-field"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mountains"/>
                                            <h4>icon-mountains</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mountains"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fees-payments"/>
                                            <h4>icon-fees-payments</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fees-payments"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-small-payments"/>
                                            <h4>icon-small-payments</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-small-payments"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mixed-buildings"/>
                                            <h4>icon-mixed-buildings</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mixed-buildings"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-industrial-zone"/>
                                            <h4>icon-industrial-zone</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-industrial-zone"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-visitor-tag"/>
                                            <h4>icon-visitor-tag</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-visitor-tag"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-businessman"/>
                                            <h4>icon-businessman</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-businessman"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-meditation"/>
                                            <h4>icon-meditation</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-meditation"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bath"/>
                                            <h4>icon-bath</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bath"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-donate"/>
                                            <h4>icon-donate</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-donate"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sauna"/>
                                            <h4>icon-sauna</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sauna"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bedroom-nightstand"/>
                                            <h4>icon-bedroom-nightstand</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bedroom-nightstand"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bedroom-lamp"/>
                                            <h4>icon-bedroom-lamp</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bedroom-lamp"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-doctor"/>
                                            <h4>icon-doctor</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-doctor"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-engineering-networks"/>
                                            <h4>icon-engineering-networks</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-engineering-networks"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-washing-machine"/>
                                            <h4>icon-washing-machine</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-washing-machine"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dryer"/>
                                            <h4>icon-dryer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dryer"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dishwasher"/>
                                            <h4>icon-dishwasher</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dishwasher"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-luggage-group"/>
                                            <h4>icon-luggage-group</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-luggage-group"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-teenager"/>
                                            <h4>icon-teenager</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-teenager"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-person-stick"/>
                                            <h4>icon-person-stick</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-person-stick"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-person-stick-old"/>
                                            <h4>icon-person-stick-old</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-person-stick-old"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-traveler"/>
                                            <h4>icon-traveler</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-traveler"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-veteran"/>
                                            <h4>icon-veteran</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-veteran"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-group-chat"/>
                                            <h4>icon-group-chat</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-group-chat"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-elections"/>
                                            <h4>icon-elections</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-elections"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-law-justice"/>
                                            <h4>icon-law-justice</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-law-justice"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-judiciary"/>
                                            <h4>icon-judiciary</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-judiciary"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-house-fire"/>
                                            <h4>icon-house-fire</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-house-fire"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-firefighters"/>
                                            <h4>icon-firefighters</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-firefighters"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-police"/>
                                            <h4>icon-police</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-police"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ambulance"/>
                                            <h4>icon-ambulance</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ambulance"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-light-beacon"/>
                                            <h4>icon-light-beacon</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-light-beacon"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-important-day"/>
                                            <h4>icon-important-day</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-important-day"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-bike-park"/>
                                            <h4>icon-bike-park</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-bike-park"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-object-align-top"/>
                                            <h4>icon-object-align-top</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-object-align-top"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-object-align-bottom"/>
                                            <h4>icon-object-align-bottom</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-object-align-bottom"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-object-align-horizontal"/>
                                            <h4>icon-object-align-horizontal</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-object-align-horizontal"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-object-align-left"/>
                                            <h4>icon-object-align-left</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-object-align-left"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-object-align-vertical"/>
                                            <h4>icon-object-align-vertical</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-object-align-vertical"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-object-align-right"/>
                                            <h4>icon-object-align-right</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-object-align-right"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-reflect-y"/>
                                            <h4>icon-reflect-y</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-reflect-y"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-reflect-x"/>
                                            <h4>icon-reflect-x</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-reflect-x"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tick"/>
                                            <h4>icon-tick</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tick"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-lawnmower"/>
                                            <h4>icon-lawnmower</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-lawnmower"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-redirect"/>
                                            <h4>icon-call-redirect</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-redirect"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-ip"/>
                                            <h4>icon-call-ip</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-ip"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-record"/>
                                            <h4>icon-call-record</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-record"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-ringtone"/>
                                            <h4>icon-call-ringtone</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-ringtone"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-traffic"/>
                                            <h4>icon-call-traffic</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-traffic"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-hold"/>
                                            <h4>icon-call-hold</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-hold"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-incoming"/>
                                            <h4>icon-call-incoming</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-incoming"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-outgoing"/>
                                            <h4>icon-call-outgoing</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-outgoing"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-video"/>
                                            <h4>icon-call-video</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-video"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-call-missed"/>
                                            <h4>icon-call-missed</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-call-missed"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-theater"/>
                                            <h4>icon-theater</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-theater"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-heartbeat"/>
                                            <h4>icon-heartbeat</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-heartbeat"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-kettlebell"/>
                                            <h4>icon-kettlebell</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-kettlebell"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fireplace"/>
                                            <h4>icon-fireplace</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fireplace"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-street-lights"/>
                                            <h4>icon-street-lights</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-street-lights"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pedestrian"/>
                                            <h4>icon-pedestrian</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pedestrian"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-flood"/>
                                            <h4>icon-flood</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-flood"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-open-water"/>
                                            <h4>icon-open-water</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-open-water"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-for-sale"/>
                                            <h4>icon-for-sale</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-for-sale"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dustbin"/>
                                            <h4>icon-dustbin</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dustbin"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-door"/>
                                            <h4>icon-door</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-door"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-camp-fire"/>
                                            <h4>icon-camp-fire</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-camp-fire"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fleur-de-lis"/>
                                            <h4>icon-fleur-de-lis</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fleur-de-lis"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temperature-settings"/>
                                            <h4>icon-temperature-settings</h4>
                                        </div>
                                        <code className="glyphicon-index__item__cod&lt;"><span className="icon-temperature-settings"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-article"/>
                                            <h4>icon-article</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-article"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sunbath"/>
                                            <h4>icon-sunbath</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sunbath"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-balanced-diet"/>
                                            <h4>icon-balanced-diet</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-balanced-diet"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ticket"/>
                                            <h4>icon-ticket</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ticket"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-parking-ticket"/>
                                            <h4>icon-parking-ticket</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-parking-ticket"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-parking-meter"/>
                                            <h4>icon-parking-meter</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-parking-meter"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-laptop"/>
                                            <h4>icon-laptop</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-laptop"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-tree-structure"/>
                                            <h4>icon-tree-structure</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-tree-structure"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-weather-warning"/>
                                            <h4>icon-weather-warning</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-weather-warning"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temperature-low"/>
                                            <h4>icon-temperature-low</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temperature-low"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temperature-high"/>
                                            <h4>icon-temperature-high</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temperature-high"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temperature-low-warning"/>
                                            <h4>icon-temperature-low-warning</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temperature-low-warning"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temperature-high-warning"/>
                                            <h4>icon-temperature-high-warning</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temperature-high-warning"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hurricane"/>
                                            <h4>icon-hurricane</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hurricane"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-storm"/>
                                            <h4>icon-storm</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-storm"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-sorted-waste"/>
                                            <h4>icon-sorted-waste</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-sorted-waste"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ear"/>
                                            <h4>icon-ear</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ear"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ear-deaf"/>
                                            <h4>icon-ear-deaf</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ear-deaf"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file-plus"/>
                                            <h4>icon-file-plus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file-plus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file-minus"/>
                                            <h4>icon-file-minus</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file-minus"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file-lock"/>
                                            <h4>icon-file-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file-cloud"/>
                                            <h4>icon-file-cloud</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file-cloud"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file-cloud-download"/>
                                            <h4>icon-file-cloud-download</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file-cloud-download"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-file-cloud-upload"/>
                                            <h4>icon-file-cloud-upload</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-file-cloud-upload"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-paragraph"/>
                                            <h4>icon-paragraph</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-paragraph"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-list-numbered"/>
                                            <h4>icon-list-numbered</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-list-numbered"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-donate-heart"/>
                                            <h4>icon-donate-heart</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-donate-heart"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-government"/>
                                            <h4>icon-government</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-government"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-maze"/>
                                            <h4>icon-maze</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-maze"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-chronicle"/>
                                            <h4>icon-chronicle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-chronicle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-structure"/>
                                            <h4>icon-user-structure</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-structure"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-recycle"/>
                                            <h4>icon-recycle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-recycle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gas"/>
                                            <h4>icon-gas</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gas"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-waste-pipe"/>
                                            <h4>icon-waste-pipe</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-waste-pipe"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-water-pipe"/>
                                            <h4>icon-water-pipe</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-water-pipe"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-parking"/>
                                            <h4>icon-parking</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-parking"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-closed"/>
                                            <h4>icon-closed</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-closed"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mouse"/>
                                            <h4>icon-mouse</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mouse"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mouse-double-click"/>
                                            <h4>icon-mouse-double-click</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mouse-double-click"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mouse-left-click"/>
                                            <h4>icon-mouse-left-click</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mouse-left-click"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mouse-right-click"/>
                                            <h4>icon-mouse-right-click</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mouse-right-click"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mouse-middle-click"/>
                                            <h4>icon-mouse-middle-click</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mouse-middle-click"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-mouse-scroll"/>
                                            <h4>icon-mouse-scroll</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-mouse-scroll"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-resize-vertical"/>
                                            <h4>icon-resize-vertical</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-resize-vertical"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-resize-horizontal"/>
                                            <h4>icon-resize-horizontal</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-resize-horizontal"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-temperature"/>
                                            <h4>icon-temperature</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-temperature"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-puzzle"/>
                                            <h4>icon-puzzle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-puzzle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-puzzle-2"/>
                                            <h4>icon-puzzle-2</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-puzzle-2"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-puzzle-3"/>
                                            <h4>icon-puzzle-3</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-puzzle-3"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-nearby-square"/>
                                            <h4>icon-nearby-square</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-nearby-square"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-nearby-circle"/>
                                            <h4>icon-nearby-circle</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-nearby-circle"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-rotate-right"/>
                                            <h4>icon-rotate-right</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-rotate-right"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-rotate-left"/>
                                            <h4>icon-rotate-left</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-rotate-left"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pictures"/>
                                            <h4>icon-pictures</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pictures"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-photo-album"/>
                                            <h4>icon-photo-album</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-photo-album"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cadastral-map"/>
                                            <h4>icon-cadastral-map</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cadastral-map"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fingerprint-scan"/>
                                            <h4>icon-fingerprint-scan</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fingerprint-scan"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fingerprint"/>
                                            <h4>icon-fingerprint</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fingerprint"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fingerprint-lock"/>
                                            <h4>icon-fingerprint-lock</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fingerprint-lock"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fingerprint-ok"/>
                                            <h4>icon-fingerprint-ok</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fingerprint-ok"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fingerprint-remove"/>
                                            <h4>icon-fingerprint-remove</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fingerprint-remove"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-fingerprint-reload"/>
                                            <h4>icon-fingerprint-reload</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-fingerprint-reload"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-pending-notifications"/>
                                            <h4>icon-pending-notifications</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-pending-notifications"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-synchronization"/>
                                            <h4>icon-synchronization</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-synchronization"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-synchronization-ban"/>
                                            <h4>icon-synchronization-ban</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-synchronization-ban"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-hash"/>
                                            <h4>icon-hash</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-hash"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-male"/>
                                            <h4>icon-gender-male</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-male"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-female"/>
                                            <h4>icon-gender-female</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-female"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-virgin-female"/>
                                            <h4>icon-gender-virgin-female</h4>
                                        </div>
                                        <code className="glyphicon-index__item__cod&lt;"><span className="icon-gender-virgin-female"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-intersex"/>
                                            <h4>icon-gender-intersex</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-intersex"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-transgender"/>
                                            <h4>icon-gender-transgender</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-transgender"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-ori-lesbian"/>
                                            <h4>icon-gender-ori-lesbian</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-ori-lesbian"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-ori-gay"/>
                                            <h4>icon-gender-ori-gay</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-ori-gay"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-ori-hetero"/>
                                            <h4>icon-gender-ori-hetero</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-ori-hetero"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-other"/>
                                            <h4>icon-gender-other</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-other"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-gender-unknown"/>
                                            <h4>icon-gender-unknown</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-gender-unknown"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-scanner"/>
                                            <h4>icon-scanner</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-scanner"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-multifunction-printer"/>
                                            <h4>icon-multifunction-printer</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-multifunction-printer"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-lasso"/>
                                            <h4>icon-lasso</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-lasso"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-view-360"/>
                                            <h4>icon-view-360</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-view-360"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-battery-charging"/>
                                            <h4>icon-battery-charging</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-battery-charging"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-battery-full"/>
                                            <h4>icon-battery-full</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-battery-full"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-battery-75"/>
                                            <h4>icon-battery-75</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-battery-75"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-battery-50"/>
                                            <h4>icon-battery-50</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-battery-50"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-battery-25"/>
                                            <h4>icon-battery-25</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-battery-25"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-battery-10"/>
                                            <h4>icon-battery-10</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-battery-10"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-satellite"/>
                                            <h4>icon-satellite</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-satellite"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-satellite-dish"/>
                                            <h4>icon-satellite-dish</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-satellite-dish"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-satellite-dish-alt"/>
                                            <h4>icon-satellite-dish-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-satellite-dish-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-auction"/>
                                            <h4>icon-auction</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-auction"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-directions"/>
                                            <h4>icon-directions</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-directions"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-race"/>
                                            <h4>icon-race</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-race"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-robot"/>
                                            <h4>icon-robot</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-robot"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-ruler-alt"/>
                                            <h4>icon-ruler-alt</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-ruler-alt"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cube-empty"/>
                                            <h4>icon-cube-empty</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cube-empty"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-cube-black"/>
                                            <h4>icon-cube-black</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-cube-black"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-move-square"/>
                                            <h4>icon-move-square</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-move-square"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-drop"/>
                                            <h4>icon-drop</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-drop"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vr-headset"/>
                                            <h4>icon-vr-headset</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vr-headset"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vr-charging"/>
                                            <h4>icon-vr-charging</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vr-charging"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vr-low-battery"/>
                                            <h4>icon-vr-low-battery</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vr-low-battery"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vr-paired"/>
                                            <h4>icon-vr-paired</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vr-paired"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vr-settings"/>
                                            <h4>icon-vr-settings</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vr-settings"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-vr-maintenance"/>
                                            <h4>icon-vr-maintenance</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-vr-maintenance"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-filter-remove"/>
                                            <h4>icon-filter-remove</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-filter-remove"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-filter-applied"/>
                                            <h4>icon-filter-applied</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-filter-applied"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-one-day"/>
                                            <h4>icon-one-day</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-one-day"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-vr"/>
                                            <h4>icon-user-vr</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-vr"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-vr-add"/>
                                            <h4>icon-user-vr-add</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-vr-add"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-user-vr-remove"/>
                                            <h4>icon-user-vr-remove</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-user-vr-remove"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dice-1"/>
                                            <h4>icon-dice-1</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dice-1"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dice-2"/>
                                            <h4>icon-dice-2</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dice-2"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dice-3"/>
                                            <h4>icon-dice-3</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dice-3"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dice-4"/>
                                            <h4>icon-dice-4</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dice-4"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dice-5"/>
                                            <h4>icon-dice-5</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dice-5"/></code>
                                    </div>
                                    <div className="glyphicon-index__item">
                                        <div className="glyphicon-index__item__info">
                                            <span className="icon-dice-6"/>
                                            <h4>icon-dice-6</h4>
                                        </div>
                                        <code className="glyphicon-index__item__code">&lt;span className="icon-dice-6"/></code>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default IconsIndex;
export {IconsIndex};