import React, { ReactNode } from 'react';
import { ApolloError } from 'apollo-client';
import { EmptyContent, Translation } from '../index';
import { APP_IS_DEVELOPMENT } from '../../util/env';
import styled from '@emotion/styled';

interface IProps {
  message?: string | ReactNode;
  error?: ApolloError;
  children?: ReactNode;
}

export const Article = styled.article`
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  width: 100%;
  padding: 64px;
`;

const FancyError = ({ message,  error = {}, children }: IProps) => (
  <Article>
    <EmptyContent
      icon={'icon-warning-sign'}
      title={message || <Translation t={'common:text.error'} />}
      subtitle={children}
    >
      {APP_IS_DEVELOPMENT && <div><pre>{JSON.stringify(error, null, 2)}</pre></div>}
    </EmptyContent>
  </Article>
);

export default FancyError;
