import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { CampointBillingsStore } from '../../../stores/Lists/CampointBillings/CampointBillingsStore';
import { CampointBillingsActionCreators } from '../../../stores/Lists/CampointBillings/CampointBillingsActionCreators';

import {
  EmptyContent,
  FormatDate,
  FormatCurrency,
  FormatDatePeriod,
  T,
  Spinner,
} from '../../../components';

import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';

class CampointBillings extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getStoreData();
  }

  componentDidMount() {
    CampointBillingsStore.addChangeListener(this.onStoreChange);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.year && newProps.year !== this.props.year) {
      this.state.showSpinner = true;
      this.setState(this.state);
      const [{ lang }] = this.props.appState;
      CampointBillingsActionCreators.getCampointBillings(newProps.year, lang);
    }
  }

  componentWillUnmount() {
    CampointBillingsStore.removeChangeListener(this.onStoreChange);
  }

  getStoreData = () => cloneDeep(CampointBillingsStore.get());

  onStoreChange = () => {
    let storeData = this.getStoreData();
    this.state.showSpinner = false;
    this.state.billingList = storeData.billingList;
    this.setState(this.state);
  };

  openPDF = billingId => {
    const [{ lang }] = this.props.appState;
    CampointBillingsActionCreators.getCampointBillingPDF(lang, billingId);
  };
  openInvoicePDF = invoiceId => {
    const [{ lang }] = this.props.appState;
    CampointBillingsActionCreators.getCampointInvoicePDF(lang, invoiceId);
  };

  render() {
    return (
      <article className="list">
        <div className="spinner-container">
          {/* SPINNER */}
          {this.state.showSpinner && <Spinner size="m" />}

          {/* DATA NOT FOUND */}
          {this.state.billingList.length < 1 && (
            <EmptyContent title={_('lists:campointbillings.info.noBillingFound')} />
          )}

          {/* SHOW DATA LIST */}
          {this.state.billingList.length > 0 && (
            <Fragment>
              <article className="billingTable">
                {/* - NEUE ANSICHT -*/}
                <section className="billingTable__head">
                  <div className="billingTable__col--big" style={{ minWidth: 150 }}>
                    <T _={'lists:campointbillings.tablefields.period'} />
                  </div>
                  <div className="billingTable__col--small" style={{ minWidth: 100 }}>
                    <T _={'lists:campointbillings.tablefields.date'} />
                  </div>
                  <div className="billingTable__col--small" style={{ minWidth: 84 }}>
                    <T _={'lists:campointbillings.tablefields.payoutNumber'} />
                  </div>
                  <div className="billingTable__col--small" style={{ minWidth: 74 }}>
                    <T _={'lists:campointbillings.tablefields.type'} />
                  </div>
                  <div
                    className="billingTable__col--small"
                    style={{ minWidth: 68, justifyContent: 'flex-end' }}
                  >
                    <T _={'lists:campointbillings.tablefields.amount'} />
                  </div>
                  <div
                    className="billingTable__col--small"
                    style={{ minWidth: 88, justifyContent: 'center' }}
                  >
                    {/*_('lists:campointbillings.tablefields.download')*/}
                  </div>
                </section>

                {this.state.billingList.map((group, groupIndex) => (
                  <div
                    className={`billingTable__row ${
                      group.feeAmount !== 0 ? 'billingTable__row--hover' : ''
                    }`}
                    key={groupIndex}
                  >
                    {group.statements.map(item => (
                      <div
                        className={`billingTable__row__p1 ${
                          group.feeAmount === 0 ? 'billingTable__row--hover' : ''
                        }`}
                        key={item.id}
                      >
                        <div className="billingTable__col--big" style={{ minWidth: 150 }}>
                          <FormatDatePeriod from={item.startDate} to={item.endDate} />
                        </div>
                        <div className="billingTable__col--small" style={{ minWidth: 100 }}>
                          <FormatDate value={item.issueDate} />
                        </div>
                        <div className="billingTable__col--small" style={{ minWidth: 84 }}>
                          {item.id}
                        </div>
                        <div className="billingTable__col--small" style={{ minWidth: 74 }}>
                          <T _={'lists:campointbillings.types.' + item.type} />
                        </div>
                        <div
                          className="billingTable__col--small"
                          style={{ minWidth: 68, justifyContent: 'flex-end' }}
                        >
                          <FormatCurrency value={item.amount} />
                        </div>
                        <div
                          className="billingTable__col--small"
                          style={{ minWidth: 88, justifyContent: 'center' }}
                        >
                          {!item.isHidden && (
                            <span
                              className="icon-download-alt billingTable__download-icon"
                              onClick={() => this.openPDF(item.id)}
                            />
                          )}
                        </div>
                      </div>
                    ))}

                    {group.feeAmount !== 0 && (
                      <div className="billingTable__row__p2">
                        <div className="billingTable__col--big" />
                        <div className="billingTable__col--small" />
                        <div className="billingTable__col--small" />
                        <div className="billingTable__col--small">
                          <T _={'lists:campointbillings.types.payoutFee'} />
                        </div>
                        <div
                          className="billingTable__col--small"
                          style={{ justifyContent: 'flex-end' }}
                        >
                          <FormatCurrency value={group.feeAmount} />
                        </div>
                        <div
                          className="billingTable__col--small"
                          style={{ justifyContent: 'center' }}
                          onClick={() => this.openInvoicePDF(group.invoiceId)}
                        >
                          <span className="icon-download-alt billingTable__download-icon" />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </article>
            </Fragment>
          )}
        </div>
      </article>
    );
  }
}

export default withAppState(CampointBillings);
