import React from 'react';
import { Link } from 'react-router-dom';
import { FinancesStore, FinancesActionCreators } from '../../../stores/Finance/Finances';
import { getFormattedDate } from '../../../util/Formatter';
import { SessionStore } from '../../../stores/Session/Session';

import { EmptyContent, Footer, FormatCurrency, Modalbox, Spinner, T } from '../../../components';

import NavSubFinances from '../NavSubFinances';
import PayoutDataList from './PayoutDataList';
import ModalPayoutAssistent from './ModalPayoutAssistent';
import ModalPayoutApply from './ModalPayoutApply';
import ModalPayoutTable from './ModalPayoutTable';
import { APP_BASE_PATH } from '../../../util/env';
import { hasRole } from '../../../util/UserData';
import { SecurityRole } from '../../../graphql/VXModels/types';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';
import { WHITE } from '../../../camtool-styles';

const STORE = 'payout';

function getStateFromStores() {
  return {
    payout: FinancesStore.get(STORE),
  };
}

class Payout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/prop-types
      isOpened: this.props.initialState || false,
      toggleStatus: 'hide',
      ...getStateFromStores(),
    };
    this.session = SessionStore.get();
    this.isLoading = true;
  }

  componentDidMount() {
    FinancesStore.addChangeListener(STORE, this.onStoreChange);
    FinancesActionCreators.getPayoutData();
  }

  componentWillUnmount() {
    FinancesStore.removeChangeListener(STORE, this.onStoreChange);
  }

  onStoreChange = () => {
    this.isLoading = false;
    this.setState(getStateFromStores());
  };

  //Old do not use, use switch
  toggle = () => {
    let newState = !this.state.isOpened;
    this.setState({ isOpened: newState });

    // eslint-disable-next-line react/no-string-refs
    let domNode = this.refs.toggleElement;
    if (domNode) {
      if (newState) {
        domNode.classList.add('show');
        domNode.classList.remove('hide');
      } else {
        domNode.classList.add('hide');
        domNode.classList.remove('show');
      }
    }
  };

  toggleOpen = () => {
    if (this.state.toggleStatus === 'hide') {
      this.setState({ toggleStatus: 'show' });
    }
  };

  toggleClose = () => {
    if (this.state.toggleStatus === 'show') {
      this.setState({ toggleStatus: 'hide' });
    }
  };

  hidePayoutAssistant = (isAssistantChanged) => {
    // reload payout page if assistant chnged
    if (isAssistantChanged) {
      this.isLoading = true;
      setTimeout(function () {
        FinancesActionCreators.getPayoutData();
      }, 500);
    }
    // hide modal box
    this.setState({
      isPayoutAssistantActive: false,
      isPayoutTableActive: false,
    });
  };

  hidePayoutTable = () => {
    // hide modal box
    this.setState({
      isPayoutAssistantActive: false,
      isPayoutTableActive: false,
    });
  };

  showPayoutAssistant = () => {
    this.setState({
      isPayoutAssistantActive: true,
      isPayoutTableActive: false,
    });
  };

  showPayoutTable = () => {
    this.setState({
      isPayoutAssistantActive: false,
      isPayoutTableActive: true,
    });
  };

  showPayoutApply = () => {
    this.setState({
      isPayoutApplyActive: true,
    });
  };

  hidePayoutApply = (isPayoutRequested) => {
    // reload payout page if assistant chnged
    if (isPayoutRequested) {
      this.isLoading = true;
      setTimeout(function () {
        FinancesActionCreators.getPayoutData();
      }, 500);
    }
    // hide modal box
    this.setState({
      isPayoutApplyActive: false,
    });
  };

  cancelPayoutRequest = () => {
    FinancesActionCreators.cancelPayoutRequest();
    this.isLoading = true;
    setTimeout(function () {
      FinancesActionCreators.getPayoutData();
    }, 500);
  };

  goTo = (target) => {
    window.location.href = target;
  };

  userPrivileged = () => {
    return hasRole(SecurityRole.VX_USER_MASTER);
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const [{ lang }] = this.props.appState;

    this.payoutPeriodBox = _('finances:payout.period', {
      postProcess: 'sprintf',
      sprintf: [
        getFormattedDate(this.state.payout.requestInfo.startDate, lang),
        getFormattedDate(this.state.payout.requestInfo.endDate, lang),
      ],
    });

    this.payoutRequestInfo = _('finances:payout.action.payoutRequest.info', {
      postProcess: 'sprintf',
      sprintf: [getFormattedDate(this.state.payout.requestInfo.date, lang)],
    });

    this.payoutRequestedInfo = _('finances:payout.action.payoutRequested.info', {
      postProcess: 'sprintf',
      sprintf: [getFormattedDate(this.state.payout.requestInfo.date, lang)],
    });

    this.payoutAssistantActiveInfo = _('finances:payout.action.assistantActive.info', {
      postProcess: 'sprintf',
      sprintf: [getFormattedDate(this.state.payout.requestInfo.date, lang)],
    });

    return (
      <main className="grid finances-payout">
        <NavSubFinances />

        <div className="grid__row">
          <div className="grid__column grid__box min-width--0">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'finances:payout.header'} />
              </div>

              <div className="grid__box__sub__header__spacer" />

              <div className="grid__box__sub__header__item--option" onClick={this.showPayoutTable}>
                <span>
                  <T _={'finances:payout.sideMenu.timeTable'} />
                </span>
              </div>
              {!this.state.payout.requestInfo.isCancellationAllowed && (
                <div
                  className="grid__box__sub__header__item--option"
                  onClick={this.showPayoutAssistant}
                  style={{ borderLeft: '1px solid white' }}
                >
                  <span>
                    <T _={'finances:payout.sideMenu.payoutAssistant'} />
                  </span>
                </div>
              )}

              {!!this.state.payout.requestInfo.isCancellationAllowed && (
                <div
                  className="grid__box__sub__header__item--option"
                  onClick={this.cancelPayoutRequest}
                  style={{ borderLeft: '1px solid white' }}
                >
                  <span>
                    <T _={'finances:payout.sideMenu.cancelPayout'} />
                  </span>
                </div>
              )}
            </div>

            {!this.userPrivileged() ? (
              <EmptyContent title={_('common:forbidden.text')} />
            ) : (
              <div className="grid__box__row spinner-container">
                {this.isLoading && <Spinner />}

                <div className="grid__box__column grid__box__sub min-width--0">
                  <div className="grid__box__sub__header">
                    <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                      <T _={'finances:payout.header'} />
                    </div>
                  </div>

                  <div className="grid__box__sub__item">
                    <div
                      className="grid__box__sub__item__content"
                      style={{ backgroundColor: WHITE }}
                    >
                      <div className="finances-payout__amount">
                        <FormatCurrency value={this.state.payout.payableAmount} />
                      </div>

                      <div
                        className={`finances-payout__notificationbox payout${this.state.payout.requestInfo.cssClass}`}
                      >
                        {this.state.payout.requestInfo.status === 'request' && (
                          <div onClick={this.showPayoutApply}>
                            <div className="notification">
                              <div className="icon-money icon" />
                              <div className="text">
                                <T _={'finances:payout.action.payoutRequest.title'} />
                              </div>
                            </div>
                            <div className="sub-notification">
                              <div className="text">{this.payoutRequestInfo}</div>
                            </div>
                          </div>
                        )}

                        {this.state.payout.requestInfo.status === 'requested' && (
                          <div>
                            <div className="notification">
                              <div className="icon-history icon" />
                              <div className="text">
                                <T _={'finances:payout.action.payoutRequested.title'} />
                              </div>
                            </div>
                            <div className="sub-notification">
                              <div className="text">{this.payoutRequestedInfo}</div>
                            </div>
                          </div>
                        )}

                        {this.state.payout.requestInfo.status === 'assistant' && (
                          <div onClick={this.showPayoutAssistant}>
                            <div className="notification">
                              <div className="icon-history icon" />
                              <div className="text">
                                <T _={'finances:payout.action.assistantActive.title'} />
                              </div>
                            </div>
                            <div className="sub-notification">
                              <div className="text">{this.payoutAssistantActiveInfo}</div>
                            </div>
                          </div>
                        )}

                        {/* -LINK TO /camtool/help */}
                        {(this.state.payout.requestInfo.status === 'error_common' ||
                          this.state.payout.requestInfo.status === 'error_internal') && (
                          <Link to={`${APP_BASE_PATH}/help`}>
                            <div className="notification">
                              <div className="icon-history icon" />
                              <div className="text">
                                <T _={'finances:payout.action.error.title'} />
                              </div>
                            </div>
                            <div className="sub-notification">
                              <div className="text">
                                <T _={'finances:payout.action.error.info'} />
                              </div>
                            </div>
                          </Link>
                        )}

                        {/* -LINK TO /camtool/account/documents */}
                        {this.state.payout.requestInfo.status === 'error_firstcheck' && (
                          <Link to={`${APP_BASE_PATH}/account/documents`}>
                            <div className="notification">
                              <div className="text">
                                <T _={'finances:payout.action.error_firstcheck.title'} />
                              </div>
                            </div>
                            <div className="sub-notification">
                              <div className="text">
                                <T _={'finances:payout.action.error_firstcheck.info'} />
                              </div>
                            </div>
                          </Link>
                        )}

                        {/* -LINK TO /camtool/account/payoutdata */}
                        {this.state.payout.requestInfo.status === 'error_payoutdata' && (
                          <Link to={`${APP_BASE_PATH}/account/payoutdata`}>
                            <div className="notification">
                              <div className="text">
                                <T _={'finances:payout.action.error_payoutdata.title'} />
                              </div>
                            </div>
                            <div className="sub-notification">
                              <div className="text">
                                <T _={'finances:payout.action.error_payoutdata.info'} />
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>

                  <PayoutDataList data={this.state.payout.payoutList} />
                </div>
              </div>
            )}
          </div>
        </div>

        <Modalbox name="modalpayouttable" state={this.state.isPayoutTableActive}>
          <ModalPayoutTable
            closeRequest={this.hidePayoutTable}
            payoutTimeTable={this.state.payout.payoutTimeTable}
          />
        </Modalbox>

        <Modalbox name="modalpayoutassistent" state={this.state.isPayoutAssistantActive}>
          <ModalPayoutAssistent closeRequest={this.hidePayoutAssistant} />
        </Modalbox>

        <Modalbox name="modalpayoutapply" state={this.state.isPayoutApplyActive}>
          {/* eslint-disable-next-line react/prop-types */}
          <ModalPayoutApply closeRequest={this.hidePayoutApply} history={this.props.history} />
        </Modalbox>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default withAppState(Payout);
