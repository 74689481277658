import React from 'react';
import PropTypes from 'prop-types';
import {
  MediaManagementStore,
  MediaManagementActionCreators,
} from '../../../stores/MediaManagement/MediaManagement';
import AbstractComponent from '../../../components/AbstractComponent';

class AbstractExplorerView extends AbstractComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      titleTooltip: null,
      editable: false,
    };
  }

  componentWillMount() {
    if (this.context.routeDispatcher)
      this.context.routeDispatcher.emit('onSelectFolder', this.state);
  }
}

AbstractExplorerView.contextTypes = {
  routeDispatcher: PropTypes.object,
};

export default AbstractExplorerView;
