import React, { FC } from 'react';
// Outside Wizard
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import Select from '../../../../../atoms/Select/Select';
// Inside Wizard
import { IContentOthersProps } from '../../../types';
import useContent from '../useContent';
import Layout from '../Layout';
import { pluralize } from '../../../utils';
import { _ } from '../../../../../util/translate';
import Hintsection from '../../../components/Hint/HintSection';

const ContentTicketshow: FC<IContentOthersProps> = ({
  state,
  dispatch,
  className,
  availableContentMinAmountsArray,
}) => {
  const [
    { contentData, redemptionType, redemptionAmount },
    { setRedemptionType, setRedemptionAmount, setContentData },
  ] = useContent('TICKET_SHOW', state, dispatch, 1);

  const handleRedemptionTypeChange = (type: string) => {
    if (type === 'GRATIS') {
      setRedemptionType(type);
      setRedemptionAmount(0);
    } else if (type === 'ON_TOP') {
      setRedemptionType(type);
      setRedemptionAmount(-1);
    } else {
      setRedemptionType('');
      setRedemptionAmount(-1);
    }
  };

  const handleVideoAmountChange = (value: string) => {
    setContentData(Number(value));
  };

  const handleRedemptionAmountChange = (amount: string) => {
    setRedemptionAmount(Number(amount));
  };

  return (
    <Layout
      className={className}
      title={_('benefits:voucher.ticket_show.title')}
      subtitle={_('benefits:voucher.ticket_show.subtitle')}
    >
      <FieldLayout
        label={_('benefits:voucher.texts.couponRedeemType')}
        error=""
        css={{ marginBottom: 24, flex: 0 }}
      >
        <Select
          value={redemptionType}
          name="voucherType"
          placeholder={_('benefits:voucher.texts.pleaseChoose')}
          onChange={(event) => handleRedemptionTypeChange(event.target.value)}
          options={[
            { label: _('benefits:voucher.texts.free'), value: 'GRATIS' },
            { label: _('benefits:voucher.texts.onTop'), value: 'ON_TOP' },
          ]}
        />
      </FieldLayout>

      {redemptionType === 'ON_TOP' && (
        <div css={{ alignItems: 'center', marginBottom: 24, flex: 0 }}>
          <span>{_('benefits:voucher.texts.from')}</span>
          <Select
            value={String(redemptionAmount)}
            name="redemptionAmount"
            css={{ maxWidth: 128, margin: 8 }}
            onChange={(event) => handleRedemptionAmountChange(event.target.value)}
            options={availableContentMinAmountsArray || []}
          />
          <span>{_('benefits:voucher.texts.userGetsOnTop')}</span>
          <Select
            value={contentData}
            name="videoAmount"
            css={{ margin: 8, flex: '0 0 auto' }}
            onChange={(event) => handleVideoAmountChange(event.target.value)}
            options={[
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
              { label: '6', value: '6' },
              { label: '7', value: '7' },
              { label: '8', value: '8' },
              { label: '9', value: '9' },
              { label: '10', value: '10' },
            ]}
          />
          <span>
            {pluralize(
              contentData,
              _('benefits:voucher.texts.ticket_show.singular'),
              _('benefits:voucher.texts.ticket_show.plural')
            )}{' '}
            {_('benefits:voucher.texts.free').toLowerCase()}
          </span>
        </div>
      )}

      <Hintsection />
    </Layout>
  );
};

export default ContentTicketshow;
