import React from 'react';
import Draft from 'draft-js';
import * as Immutable from 'immutable';
import AtomicBlock from './AtomicBlock';

const blockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(
  Immutable.Map({
    atomic: {
      element: 'figure',
      wrapper: <AtomicBlock />,
    },
  })
);

export default blockRenderMap;
