import gql from 'graphql-tag';

export const QUERY_MODEL_INSTANTMESSAGING_ACCOUNT = gql`
  query QUERY_MODEL_INSTANTMESSAGING_ACCOUNT($platform: InstantMessagingPlatformEnum!) {
    model {
      instantMessaging {
        account(platform: $platform) {
          active
          initialized
          isInitialized
          token
        }
      }
    }
  }
`;
