import React, { ReactElement } from 'react';
import { APP_BASE_PATH } from '../../../../util/env';
import { AboContentType } from '../../types';
import { _ } from '../../../../util/translate';
import NewButton from '../../../../atoms/Button/NewButton';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';

// purpose of this component is to enable abo page to scale in future in case every single abo type needs some special display logic..
const DisplayAboContextContent = (
  contentType: string,
  aboType: AboContentType,
  onClick?: () => void
): ReactElement | null => {
  const history = useHistory();

  switch (contentType) {
    case 'creatorButton':
      switch (aboType) {
        case AboContentType.Telegram:
          return null;
        default:
          // vxpagecredits, allmedia etc.
          return (
            <NewButton onClick={(): void => history.push(APP_BASE_PATH + '/website/creator')}>
              {_('benefits:texts.toCreator')}
            </NewButton>
          );
      }
    case 'newAboButton':
      switch (aboType) {
        case AboContentType.Telegram:
          return (
            <Box>
              <NewButton onClick={onClick}>{_('benefits:abo.texts.createNewAbo')}</NewButton>
            </Box>
          );
        default:
          return (
            <Box mt={2}>
              <NewButton variant="text" onClick={onClick}>
                {_('benefits:abo.texts.createNewAbo')}
              </NewButton>
            </Box>
          );
      }
    default:
      return null;
  }
};

export default DisplayAboContextContent;
