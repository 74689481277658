export const BREAKPOINT_SPHONE_CONDITION = '(max-width: 400px)';
export const BREAKPOINT_SPHONE = `@media ${BREAKPOINT_SPHONE_CONDITION}`;
export const BREAKPOINT_PHONE_CONDITION = '(max-width: 767px)'; // taken from Bootstrap (https://getbootstrap.com/docs/4.0/layout/grid/#grid-options)
export const BREAKPOINT_PHONE = `@media ${BREAKPOINT_PHONE_CONDITION}`;
export const BREAKPOINT_TABLET_CONDITION = '(max-width: 900px)';
export const BREAKPOINT_TABLET = `@media ${BREAKPOINT_TABLET_CONDITION}`;
export const BREAKPOINT_DESKTOP_CONDITION = '(max-width: 1395px)';
export const BREAKPOINT_DESKTOP = `@media ${BREAKPOINT_DESKTOP_CONDITION}`;

export const BREAKPOINT_IS_NOT_MOBILE = '(min-width: 900px)';

export const BLACK = '#000000';
export const BLACK_1 = '#141316';
export const BLACK_2 = '#262728';
export const WHITE = '#ffffff';
export const WHITE_2 = '#f2f2f2';
export const WHITE_3 = '#f5f5f5';
export const RED = '#ff4d3c';
export const RED_HOVER = '#ff9389';
export const BLUE = '#1f93e9';
export const BLUE_HOVER = '#3bacf7';
export const BLUE_VARIANT = '#2D91DB';
export const GREEN = '#43B31C';
export const GREEN_HOVER = '#66e03b';
export const YELLOW = '#ffb033';
export const ORANGE = '#e67e22';
export const CYAN = '#68c3c4';
export const MAGENTA = '#ff4ed1';
export const DARK_GRAY = '#3c3d3e';
export const DARK_GRAY_HOVER = '#2d2d2d';
export const GRAY_1 = '#525158';
export const GRAY_2 = '#888888';
export const GRAY_3 = '#c0c0c0';
export const GRAY_4 = '#cccccc';
export const GRAY_5 = '#E1E4E5';
export const GRAY_ULTRATHIN = '#dcdcde';
export const GRAY_BACKGROUND = '#e9e9e9';

export const VXGAMES2020_GREEN = '#a0d98d';
export const VXGAMES2021_TURQUOISE = '#148ba6';

export const BORDER_RADIUS = '4px';

export const BETA_PURPLE = '#5C62E1';
export const BETA_PURPLE_HOVER = '#4547BC';
