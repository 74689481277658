/* eslint-disable  react/prop-types */
import React from 'react';
import NavSubItem from '../../../../components/NavSubItem/NavSubItem';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';

const NavContainer = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
`;

const NavPictureView = ({ albumId, albumType }) => {
  // const currentCollection = window.sessionStorage.getItem('currentCollection');
  const showSales = albumType === 'shop' || albumType === 'campaign';

  return (
    <NavContainer className="mediamanagement__picture__view__nav">
      <NavSubItem
        link={`/camtool/mediamanagement/picture/album/${albumId}`}
        label={_('mediamanagement:picfolder.subnav.overview')}
      />
      <NavSubItem
        link={`/camtool/mediamanagement/picture/album/${albumId}/edit`}
        label={_('mediamanagement:picfolder.subnav.edit')}
      />
      <NavSubItem
        link={`/camtool/mediamanagement/picture/album/${albumId}/comment`}
        label={_('mediamanagement:picfolder.subnav.reviews')}
      />
      {showSales && (
        <NavSubItem
          link={`/camtool/mediamanagement/picture/album/${albumId}/sales`}
          label={_('mediamanagement:picfolder.subnav.sales')}
        />
      )}
    </NavContainer>
  );
};

export default NavPictureView;
