import React, { FC } from 'react';
import { Fieldset } from '../../../../../atoms/Fieldset';
import FieldCheckbox from '../../../../../molecules/Formik/Fields/FieldCheckbox';
import { Translation } from '../../../../../components';
import { SectionLabel } from '../../styles';
import FieldDatePicker from '../../../../../molecules/Formik/Fields/FieldDatePicker';
import { FormValues } from '../FormWrapper';
import { Box, Grid, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { RED } from '../../../../../camtool-styles';

interface PublishScheduledProps {
  editable: boolean;
  values: FormValues;
  publishScheduledOk: boolean;
}

const PublishScheduled: FC<PublishScheduledProps> = ({
  editable,
  values: { publishScheduled },
  publishScheduledOk,
}) => (
  <Fieldset
    disabled={!editable || !publishScheduled}
    css={{ marginBottom: 0 }}
    color={publishScheduledOk ? '' : RED}
    legend={
      <FieldCheckbox
        name={'publishScheduled'}
        disabled={!editable}
        label={<Translation t={'mailings:form.publishScheduled'} />}
      />
    }
  >
    <Grid item xs={12}>
      <SectionLabel>
        <Box mt={1}>
          <Translation t={'mailings:email.form.sendDate'} />
        </Box>
      </SectionLabel>
    </Grid>
    <Grid item xs={12}>
      <FieldDatePicker name={'published'} disabled={!editable || !publishScheduled} />
    </Grid>
    <Grid item xs={12}>
      {publishScheduled && !publishScheduledOk && (
        <Typography style={{ color: RED }}>
          <br />
          <InfoIcon style={{ width: '16px', marginBottom: '-5px' }} />{' '}
          <Translation t={'mailings:email.form.infoPublishedScheduled'} />
        </Typography>
      )}
    </Grid>
  </Fieldset>
);
export default PublishScheduled;
