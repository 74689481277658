import { QueryLazyOptions, useLazyQuery } from '@apollo/react-hooks';
import {
  QUERY_ATLEASTONEACTORDOC,
  QUERY_MODEL_DOCUMENTS_UPLOADASSISTANT_FLOW,
  QUERY_WELCOME_CONTENT,
} from '../../../graphql/VXModels/queries';
import { DocumentUploadAssistantTypeEnum, Query } from '../../../graphql/VXModels/types';

interface Props {
  flowType: DocumentUploadAssistantTypeEnum;
}

interface Result {
  getCurrentStep: (options?: QueryLazyOptions<Props> | undefined) => void;
  loading: boolean;
  data?: Query;
}

const useGetCurrentStep = ({ flowType }: Props): Result => {
  // pass these refetching queries as a function prop if Assistant is being used somewhere else in the future
  const [fetchWelcomeContent] = useLazyQuery<Query>(QUERY_WELCOME_CONTENT, {
    fetchPolicy: 'cache-and-network',
  });
  const [fetchActorDocuments] = useLazyQuery<Query>(QUERY_ATLEASTONEACTORDOC, {
    fetchPolicy: 'cache-and-network',
  });

  const [getCurrentStep, { data, loading }] = useLazyQuery<Query, Props>(
    QUERY_MODEL_DOCUMENTS_UPLOADASSISTANT_FLOW,
    {
      variables: { flowType },
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        fetchWelcomeContent();
        fetchActorDocuments();
      },
      onError: () => {
        fetchWelcomeContent();
        fetchActorDocuments();
      },
    }
  );
  return { getCurrentStep, data, loading };
};

export default useGetCurrentStep;
