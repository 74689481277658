import React, { FC } from 'react';
import { Icon } from '../../../atoms';
import { thumbsUp } from '../../../atoms/Icon/libraries/glyph';
import { BLUE } from '../../../camtool-styles';

interface Props {
  likes: number;
}

const LikesDisplay: FC<Props> = ({ likes }) => {
  return (
    <div css={{ fontSize: '16px', marginBottom: '17px' }}>
      <Icon css={{ color: BLUE, marginRight: '5px' }} icon={thumbsUp} />
      <span css={{ textTransform: 'uppercase' }}>{`Likes (${likes})`}</span>
    </div>
  );
};

export default LikesDisplay;
