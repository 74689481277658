var AppDispatcher = require('../../util/dispatcher/AppDispatcher').default;

var ActionTypes = require('../../util/constants/ActionTypes');
var ServerUtils = require('../../util/ServerUtils').default;

const STORE_MARKETING_SETTINGS = 'marketing.settings';
const STORE_MARKETING_ADMAILS = 'marketing.admails';
const STORE_MARKETING_ADMAILS_ARCHIVE = 'marketing.admailsarchive';
const STORE_MARKETING_FAVOURITE_USERS = 'marketing.favouriteUsers';

const STORE_MARKETING_CAMPAIGNS = 'marketing.campaigns';

var Phx = global.Phx || {};
Phx.Action = Phx.Action || {};
Phx.Action.MarketingActionCreators = {
  getMarketingSettings: function() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/marketing/settings', null, function(
      response
    ) {
      Phx.Action.MarketingActionCreators.receiveData(STORE_MARKETING_SETTINGS, response);
    });
  },

  setMarketingFlag: function(data) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/marketing/settings/setMarketingFlag',
      { data: JSON.stringify(data) },
      function(response) {
        Phx.Action.MarketingActionCreators.receiveData(STORE_MARKETING_SETTINGS, response);
      }
    );
  },

  setTubeMarketingFlag: function(data) {
    ServerUtils.request(
      'PUT',
      '/v1/camtool/user/{userId}/marketing/settings/setTubeMarketingFlag',
      { data: JSON.stringify(data) },
      function(response) {
        Phx.Action.MarketingActionCreators.receiveData(STORE_MARKETING_SETTINGS, response);
      }
    );
  },

  getAdmailInfos: function() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/marketing/admail/create', null, function(
      response
    ) {
      Phx.Action.MarketingActionCreators.receiveData(STORE_MARKETING_ADMAILS, response);
    });
  },

  createAdmail: function(data) {
    ServerUtils.request(
      'POST',
      '/v1/camtool/user/{userId}/marketing/admail/create',
      { data: JSON.stringify(data) },
      function(response) {
        Phx.Action.MarketingActionCreators.receiveData(STORE_MARKETING_ADMAILS, response);
        Phx.Action.MarketingActionCreators.getAdmailArchive('createdDate', 'desc', 20, 0);
      }
    );
  },

  getAdmailArchive: function(sort, sortDirection, limit, offset) {
    var params =
      '&sort=' + sort + '&sortDirection=' + sortDirection + '&limit=' + limit + '&offset=' + offset;
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/marketing/admail/archive?' + params,
      null,
      function(response) {
        if (offset > 0) {
          Phx.Action.MarketingActionCreators.appendData(
            STORE_MARKETING_ADMAILS_ARCHIVE,
            response.archiveEntries
          );
        } else {
          Phx.Action.MarketingActionCreators.receiveData(STORE_MARKETING_ADMAILS_ARCHIVE, {
            data: response.archiveEntries,
          });
        }
      }
    );
  },

  getNumberOfAdmailsInArchive: function() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/marketing/admail/archive/total',
      null,
      function(response) {
        Phx.Action.MarketingActionCreators.receiveData(STORE_MARKETING_ADMAILS_ARCHIVE, {
          totalEntries: response.totalEntries,
        });
      }
    );
  },

  getCampaigns: function() {
    ServerUtils.request('GET', '/v1/camtool/campaigns/all', null, function(response) {
      Phx.Action.MarketingActionCreators.receiveData(STORE_MARKETING_CAMPAIGNS, {
        totalEntries: response.totalEntries,
      });
    });
  },

  receiveData: function(store, data) {
    AppDispatcher.dispatch({
      type: ActionTypes.MARKETING_RECEIVE_DATA,
      store: store,
      data: data,
    });
  },

  appendData: function(store, data) {
    AppDispatcher.dispatch({
      type: ActionTypes.MARKETING_APPEND_DATA,
      store: store,
      data: data,
    });
  },
};

module.exports = Phx.Action.MarketingActionCreators;
