import { IPreviewItemProps } from '../../../types';
import { GRAY_2, RED } from '../../../../../camtool-styles';
import React, { FC } from 'react';
import { ERROR_RED, GRAY_8 } from '../../../styles';

// MAYBE REFACTOR INTO `IPreviewItemProps`
interface IPreviewOtherProps extends IPreviewItemProps {
  className?: string;
  title: string;
  subtitle: string;
  avatar?: string;
  icon?: string;
}

const PreviewOther: FC<IPreviewOtherProps> = props => {
  const { avatar, icon, title, subtitle, content, onClick, className, style } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(content);
    } else {
      throw new Error("Where is my `onClick` callback pal? I can't do shit without it...");
    }
  };

  return (
    <div className={className} style={style} css={{ alignItems: 'center' }}>
      <div css={{ position: 'relative' }}>
        <div
          css={{
            width: 60,
            height: 60,
            borderRadius: '50%',
            border: `1px solid ${GRAY_2}`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${avatar})`,
          }}
        />

        {icon && (
          <div
            css={{
              width: avatar ? 20 : 'auto',
              height: avatar ? 20 : 'auto',
              backgroundColor: avatar ? ERROR_RED : 'white',
              color: avatar ? 'white' : GRAY_8,
              borderRadius: '50%',
              position: 'absolute',
              right: 0,
              top: 0,
              left: avatar ? 'unset' : 0,
              bottom: avatar ? 'unset' : 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span className={icon} css={{ fontSize: avatar ? 10 : 32 }} />
          </div>
        )}
      </div>

      <div css={{ flex: 1, alignItems: 'center' }}>
        <div css={{ flex: 1, flexDirection: 'column', padding: '0 16px', fontSize: 14 }}>
          <div css={{ flex: 1, alignItems: 'center' }}>
            <span
              title={title}
              css={{
                width: 220,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inline-block',
              }}
            >
              {title}
            </span>
          </div>
          <div css={{ color: GRAY_2, marginTop: 4, fontSize: 12 }}>{subtitle}</div>
        </div>

        <button
          onClick={handleClick}
          css={{
            border: 'none',
            backgroundColor: 'transparent',
            color: RED,
            fontSize: 16,
            cursor: 'pointer',
            '&:hover': { opacity: 0.8 },
          }}
        >
          <span className="icon-remove-sign" />
        </button>
      </div>
    </div>
  );
};

export default PreviewOther;
