import React, { FC } from 'react';
import { UploadLabelProps } from './types';
import Container from './Container';
import UploadIcon from './UploadIcon';
import Label from './Label';

const UploadLabel: FC<UploadLabelProps> = ({ label, labelSize, layoutDirection, className }) => (
  <Container labelSize={labelSize} layoutDirection={layoutDirection} className={className}>
    <UploadIcon labelSize={labelSize} />
    <Label labelSize={labelSize} layoutDirection={layoutDirection}>
      {label}
    </Label>
  </Container>
);

export default UploadLabel;
