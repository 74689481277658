import { Query } from '@apollo/react-components';
import React from 'react';
import { Redirect, Switch } from 'react-router';

import { ProtectedRoute, Spinner } from '../../../components';
import AlbumViewProfileLA from '../../../components/MediaManagement/ExplorerPicture/AlbumViewProfileLA/AlbumViewProfileLA';
import { stripSlash } from '../../../util/urlHelper';

import { QUERY_PHOTO_ALBUMS_BY_TYPE } from '../../../graphql/VXModels/queries';

import AutoMails from './AutoMails/AutoMails';
import Common from './Common/Common';
import Profilepic from './Profilepic/Profilepic';
import Settings from './Settings/Settings';

const DEFAULT_ROUTE = `/common`;

// eslint-disable-next-line react/prop-types
const LA = ({ match: { url } }) => {
  const path = stripSlash(url);
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/common`} component={Common} />
      <ProtectedRoute exact path={`${path}/automails`} component={AutoMails} />
      <ProtectedRoute exact path={`${path}/settings`} component={Settings} />
      <ProtectedRoute
        exact
        path={`${path}/profilepic`}
        component={() => (
          <Query query={QUERY_PHOTO_ALBUMS_BY_TYPE} variables={{ type: 'lustagenten' }}>
            {({ loading, data, error, refetch }) => {
              if (loading) return <Spinner />;
              if (error) return console.error(error);
              const albums = (data.model.photoAlbums && data.model.photoAlbums.albums) || null;
              return (
                <Profilepic>
                  <AlbumViewProfileLA
                    album={albums[0]}
                    refetchAlbum={refetch}
                    isLoading={loading}
                  />
                </Profilepic>
              );
            }}
          </Query>
        )}
      />
      <Redirect exact to={`${path}${DEFAULT_ROUTE}`} />
    </Switch>
  );
};

export default LA;
