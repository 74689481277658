import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BLACK_1, GRAY_3, WHITE, GRAY_ULTRATHIN, RED, YELLOW } from '../../camtool-styles';
import Icon from '../Icon/Icon';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/core';

const StyledLabel = styled.label`
  width: 100%;
  flex-direction: column;
`;

const StyledTextarea = styled.textarea<Pick<Props, 'showCharCount'>>`
  padding: 10px;
  width: 100%;
  resize: none;
  border-style: none;
  background-color: transparent;
  color: ${BLACK_1};
  font-family: Roboto, sans-serif;
  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }

  ${(props): string => (props.showCharCount ? 'padding-bottom: 0;' : '')}
`;

const TextareaContainer = styled.div<
  Pick<Props, 'roundedTopLeftCorner' | 'hasError' | 'hasWarning'>
>`
  min-width: 100%;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_ULTRATHIN};
  border-radius: 4px;
  flex-direction: column;
  border-color: ${(props): string =>
    props.hasError ? RED : props.hasWarning ? YELLOW : GRAY_ULTRATHIN};
  ${(props): string => (props.roundedTopLeftCorner ? '' : 'border-top-left-radius: 0;')}
`;

const CharCount = styled.div`
  display: block !important;
  margin: 6px 10px;
  user-select: none;
  color: ${GRAY_3};
  text-align: right;
`;

const FieldMessageBase = css`
  margin-top: 7px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1rem;
  font-style: italic;
`;

const Error = styled.div`
  ${FieldMessageBase}
  color: ${RED};
`;

const Warning = styled.div`
  ${FieldMessageBase}
  color: ${YELLOW};
`;

const FieldIconBase = css`
  margin-left: 8px;
`;

const InputErrorIcon = styled(Icon)`
  ${FieldIconBase}
  color: ${RED};
`;

const InputWarningIcon = styled(Icon)`
  ${FieldIconBase}
  color: ${YELLOW};
`;

interface Props {
  name: string;
  value: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  placeholder?: string;
  maxLength?: number;
  showCharCount?: boolean;
  disabled?: boolean;
  roundedTopLeftCorner?: boolean;
  errorMessage?: string;
  hasError?: boolean;
  warningMessage?: string;
  hasWarning?: boolean;
  className?: string;
}

const TextareaField: FC<Props> = ({
  name,
  value,
  label,
  onChange,
  onBlur,
  placeholder,
  maxLength,
  errorMessage,
  hasError = !!errorMessage,
  warningMessage,
  hasWarning = !!warningMessage,
  rows = 8,
  showCharCount = false,
  disabled = false,
  roundedTopLeftCorner = true,
}) => {
  return (
    <StyledLabel>
      {!!label && label}
      <div css={{ flexDirection: 'row', alignItems: 'center' }}>
        <TextareaContainer
          roundedTopLeftCorner={roundedTopLeftCorner}
          hasWarning={hasWarning}
          hasError={hasError}
        >
          <StyledTextarea
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            rows={rows}
            maxLength={maxLength}
            disabled={disabled}
            showCharCount={showCharCount}
          />
          {showCharCount && <CharCount>{`${value.length} / ${maxLength}`}</CharCount>}
        </TextareaContainer>
        {hasError && <InputErrorIcon size="16px" icon={faExclamationCircle} />}
        {hasWarning && <InputWarningIcon size="16px" icon={faExclamationCircle} />}
      </div>
      {hasError && <Error>{errorMessage}</Error>}
      {hasWarning && <Warning>{warningMessage}</Warning>}
    </StyledLabel>
  );
};

export default TextareaField;
