import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import AutoMailGridFormItemTextValidation from './AutoMailGridFormItemTextValidation';

const MessageField = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;
  width: 100%;
`;

const AutoMailGridFormItemText: FC = () => {
  return (
    <Box p={2}>
      <MessageField>
        <Field name="text">
          {({ field, form: { setFieldValue, errors } }: FieldProps) => {
            const errorText = errors.text;
            const changeText = (value: string) => setFieldValue('text', value);

            return (
              <AutoMailGridFormItemTextValidation
                value={field.value}
                errorMessage={errorText}
                changeText={changeText}
              />
            );
          }}
        </Field>
      </MessageField>
    </Box>
  );
};

export default AutoMailGridFormItemText;
