import React, { FC } from 'react';
import { StyledSection } from '../../styles';
import InfoBox, { InfoBoxTypeEnum } from '../../../../../atoms/InfoBox';
import { Translation } from '../../../../../components';
import { MailingStatusEnum } from '../../../../../graphql/VXModels/types';

interface SendNoticeProps {
  status: MailingStatusEnum;
}

const SendNotice: FC<SendNoticeProps> = () => (
  <StyledSection css={{ flexDirection: 'row' }}>
    <InfoBox type={InfoBoxTypeEnum.NOTICE} css={{ marginBottom: 0 }}>
      <Translation t={'mailings:form.sendNotice.massSend'} />
    </InfoBox>
  </StyledSection>
);

export default SendNotice;
