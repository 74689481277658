import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { VXGAMES_TYPOGRAPHY_H3, VXGAMES_TYPOGRAPHY_PARAGRAPH } from '../../../../utils';
import { OnboardingStepperProps } from '.';

const OnboardingStepper: FC<OnboardingStepperProps> = ({ headline, subHeadline, children }) => {
  return (
    <Box flex={{ xs: '1 0 370px', md: '1 0 300px' }}>
      <Box maxWidth="50rem">
        <Typography style={VXGAMES_TYPOGRAPHY_H3} align="center" gutterBottom>
          {headline}
        </Typography>
        <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH} align="center" gutterBottom>
          {subHeadline}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export { OnboardingStepper };
