import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const OptionBox = ({
  name,
  value,
  checked,
  disabled,
  onChange,
  iconPrefix,
  label,
  sublabel,
  icon,
}) => {
  return (
    <div
      className={cx({
        [`${name}-${value}`]: true,
        disabled: disabled,
      })}
    >
      <input
        type="checkbox"
        name={name}
        value={value}
        id={`${name}-${value}`}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={`${name}-${value}`} className={icon ? 'iconset' : ''}>
        {iconPrefix && <div className={`${iconPrefix + value} iconbox`} />}
        <div className="textbox">
          <div className="label">{label}</div>
          {sublabel && <div className="sublabel">{sublabel}</div>}
        </div>
      </label>
    </div>
  );
};

OptionBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  iconPrefix: PropTypes.string,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  icon: PropTypes.string,
};

OptionBox.defaultProps = {};

export default OptionBox;
