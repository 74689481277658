import React, { FC } from 'react';
import { _ } from '../../../../../util/translate';
import { Button } from '../../../../../atoms';
import { FormikValues } from 'formik';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import RequestNewCodeButton from './RequestNewCodeButton';

interface Props {
  values: FormikValues | undefined;
  loading: boolean;
  hasError: boolean;
}

const SaveNumberButton: FC<Props> = ({ values, loading, hasError }) => {
  return hasError ? (
    <GenericEmptyContent />
  ) : (
    <div css={{ flexDirection: 'column', alignItems: 'center' }}>
      <Button
        type="submit"
        css={{ marginTop: '30px' }}
        disabled={!values?.deactivateMailAndKnock && !!values?.targetNumber.phoneNumber}
        theme={!values?.deactivateMailAndKnock ? 'gray' : 'blue'}
        loading={loading}
      >
        {_('common:button.save')}
      </Button>

      <RequestNewCodeButton />
    </div>
  );
};

export default SaveNumberButton;
