import React from 'react';
import axios, { AxiosResponse } from 'axios';
import { AssistantErrorTypeEnum } from '../types';

interface Props {
  uploadUrl: string;
  setCustomLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomError: React.Dispatch<
    React.SetStateAction<
      | {
          name: string;
          message: string;
        }
      | undefined
    >
  >;
}

interface Upload {
  upload: (file: File) => Promise<AxiosResponse<File> | undefined>;
}

export const genericUpload = ({ uploadUrl, setCustomLoading, setCustomError }: Props): Upload => {
  const upload = async (file: File): Promise<AxiosResponse<File> | undefined> => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    setCustomLoading(true); // problem is there is no uploadProgress (either make circular progress take no uploadProgress prop or pass it here with axios via new setUploadProgress parameter)
    let res;
    try {
      res = await axios.post(uploadUrl, formData);
    } catch (error) {
      setCustomError({ name: AssistantErrorTypeEnum.UPLOAD, message: 'common:uploadFailed' });
    } finally {
      setCustomLoading(false);
    }
    return res;
  };

  return { upload };
};
