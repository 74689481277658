import { QueryLazyOptions, useLazyQuery } from '@apollo/react-hooks';
import { OperationVariables } from '@apollo/react-common';
import { Query, UserList } from '../../../graphql/VXServicesTelegram/types';
import { QUERY_MASTER_USERLIST } from '../graphql/queries/master';
import { useVXServicesTelegramClient } from '../../../graphql';
import useApolloContext from './useApolloContext';

interface UseTelegramUserListResult {
  loadUsers: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
  data: UserList;
  currency: string | undefined;
  loading: boolean;
}

export const INITIAL_USER_LIMIT = 100;

export const useTelegramUserlist = (): UseTelegramUserListResult => {
  const { token } = useApolloContext();

  const [loadUsers, { data, loading }] = useLazyQuery<Query>(QUERY_MASTER_USERLIST, {
    client: useVXServicesTelegramClient(),
    variables: { token },
    fetchPolicy: 'cache-and-network',
  });

  const userList = data?.master?.userList || {
    limit: INITIAL_USER_LIMIT,
    offset: 0,
    total: 0,
    count: 0,
    users: [],
  };

  return { loadUsers, data: userList, currency: data?.master?.currency, loading };
};

export default useTelegramUserlist;
