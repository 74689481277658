import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { ApiLang } from '../../../../../graphql/VXModels/types';
import { MultiLanguageTextField } from '../../../../../molecules/MUI/MultiLanguageTextField';
import { _ } from '../../../../../util/translate';
import { sanitizeLang } from '../../../../../util/Translator';

const VoucherDescription: FC = () => {
  const languageArray: ApiLang[] = [ApiLang.de, ApiLang.en];
  const [language, setLanguage] = useState<ApiLang>(ApiLang.de);

  const changeLanguage = (nthLanguage: number): void => {
    setLanguage(languageArray[nthLanguage]);
  };

  return (
    <Field
      name={`description.${language}`}
      render={({ field, form: { setFieldValue } }: FieldProps): ReactElement => {
        const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
          setFieldValue(field.name, event.target.value);

        return (
          <MultiLanguageTextField
            language={language}
            changeLanguage={changeLanguage}
            label={{
              de: _('benefits:voucher.texts.voucherDescription', {
                lng: sanitizeLang('de'),
              }),
              en: _('benefits:voucher.texts.voucherDescription', {
                lng: sanitizeLang('en'),
              }),
            }}
            textFieldProps={{
              ...field,
              onChange: onChange,
              placeholder: _('benefits:voucher.placeholders.voucherDescription', {
                lng: sanitizeLang(language),
              }),
              multiline: true,
              rows: 6,
              rowsMax: 6,
            }}
          />
        );
      }}
    />
  );
};

export default VoucherDescription;
