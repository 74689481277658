import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import styled from '@emotion/styled';

import MarketingActionCreators from '../../../../stores/Marketing/MarketingActionCreators';
import { SessionStore } from '../../../../stores/Session/Session';
import { AlertsStore } from '../../../../stores/Alerts/AlertsStore';

import {
  T,
  Button,
  ButtonGroup,
  GroupRow,
  Textareafield,
  OptionBoxRadio,
} from '../../../../components';

import { _ } from '../../../../util/translate';
import { RED } from '../../../../camtool-styles';

const Info = styled.p`
  display: flex;
  margin-left: 110px;
  margin-top: 16px;
  color: ${RED};

  .icon-info-sign {
    margin-right: 4px;
  }
`;

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receiverOptions: this.props.receiverOptions || [],
      disabledOptions: this.props.disabledOptions || [],
      periodInDays: this.props.periodInDays || 0,
      receiver: {
        value: '',
      },
      message: '',
      spokenLangs: SessionStore.get().spokenLangs,
      openedTab: 0,
      showAdmailPreview: false,
      isFormPosted: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props, nextProps)) {
      this.state.receiverOptions = nextProps.receiverOptions;
      this.state.disabledOptions = nextProps.disabledOptions;
      this.state.periodInDays = nextProps.periodInDays;
      this.state.receiver.value = '';
      this.state.message = '';
      this.setState(this.state);
    }
  }

  submitForm = model => {
    this.state.isFormPosted = true;
    this.setState(this.state);
    MarketingActionCreators.createAdmail(model);
    AlertsStore.add({ type: 'info', message: _('common:alerts.saveSuccess') });
  };

  onChangeReceiver = e => {
    for (let i in this.state.receiverOptions) {
      if (this.state.receiverOptions[i].value === e.target.value) {
        this.state.receiver = this.state.receiverOptions[i];
      }
    }

    if (this.props.onChangePreviewData) {
      this.props.onChangePreviewData({
        message: this.state.message,
        receiver: this.state.receiver,
      });
    }
    this.setState(this.state);
  };

  onChangeMessage = e => {
    this.state.message = e.target.value;
    if (this.props.onChangePreviewData) {
      this.props.onChangePreviewData({
        message: this.state.message,
        receiver: this.state.receiver,
      });
    }
    this.setState(this.state);
  };

  onClickModalClose = () => {
    this.state.showAdmailPreview = false;
    this.setState(this.state);
  };

  onClickAdmailPreview = () => {
    this.state.showAdmailPreview = true;
    this.setState(this.state);
  };

  canSubmit = () => {
    return (
      this.state.receiver.value && this.state.message.trim() !== '' && !this.state.isFormPosted
    );
  };

  render() {
    return (
      <Formsy.Form
        ref="admailForm"
        className="grid__box__row grid__box__row--center marketing-admails-create"
        onValidSubmit={this.submitForm}
        onValid={this.onValid}
        onInvalid={this.onInvalid}
      >
        <div className="grid__box__column marketing-admails-creat__formwidth">
          {this.state.receiverOptions.length > 0 && (
            <GroupRow
              label={_('marketing:admails.create.targetGroup.label')}
              labelClass="admailForm__label"
              tooltipText={_('marketing:admails.create.targetGroup.tooltip')}
            >
              <OptionBoxRadio
                className="column3"
                optionClass="marketing-admails-option"
                name="receiver"
                value={this.state.receiver.value}
                options={this.state.receiverOptions}
                disabledOptions={this.state.disabledOptions}
                onChange={this.onChangeReceiver}
              />
            </GroupRow>
          )}

          <Info>
            <span className="icon-info-sign" />
            <T _={'marketing:admails.create.availabilityMsg'} sprintf={[this.state.periodInDays]} />
          </Info>

          <GroupRow
            label={_('marketing:admails.create.message.label')}
            labelClass="admailForm__label"
            tooltipText={_('marketing:admails.create.message.tooltip')}
          >
            <Textareafield
              name="message"
              value={this.state.message}
              placeholder={_('marketing:admails.create.message.placeholder')}
              maxLength={1000}
              onChange={this.onChangeMessage}
            />
          </GroupRow>

          <ButtonGroup position="right">
            <Button label={_('common:button.send')} type="submit" disabled={!this.canSubmit()} />
          </ButtonGroup>
        </div>
      </Formsy.Form>
    );
  }
}

Create.propTypes = {
  onChangePreviewData: PropTypes.func,
};

export default Create;
