import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';

import FancyHeader from './FancyHeader/FancyHeader';
import FancyRow from './FancyRow/FancyRow';

class FancyTable extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortedBy: 'date',
      sortDirection: 'desc',
    };
  }

  sort(data) {
    if (this.state.sortedBy && this.state.sortDirection) {
      this.props.sortCallback(data, this.state.sortedBy, this.state.sortDirection);
    }
    return data;
  }

  onSort(field, direction) {
    this.setState({
      sortedBy: field,
      sortDirection: direction,
    });
  }

  render() {
    return (
      <div className="fancy-table__container grid__box__sub__item" style={this.props.styles}>
        <article
          className="fancy-table grid__box__sub__item__content"
          style={this.props.contentStyle}
        >
          <FancyHeader
            columnFields={this.props.columnFields}
            onSort={this.onSort}
            sortDirection={this.state.sortDirection}
            sortedBy={this.state.sortedBy}
            type={this.props.type}
          />
          {this.props.rowsData
            ? this.sort(this.props.rowsData).map((row, i) => (
                <FancyRow
                  key={i}
                  rowNumber={i}
                  rowData={this.props.rowDataFilter(row)}
                  width={this.props.columnFields.map(colHead => colHead.width)}
                  type={this.props.type}
                  prizeType={this.props.prizeType}
                  hightlightRow={row.rank === this.props.modelRank}
                  hightlightClassName={'vxgames18__bg-color--' + this.props.season}
                  prizeVisible={this.props.rowDataFilter(row).prize !== 'none'}
                />
              ))
            : null}
        </article>
      </div>
    );
  }
}

FancyTable.propTypes = {
  styles: PropTypes.object,
  rowsData: PropTypes.arrayOf(PropTypes.object),
  columnFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      sortable: PropTypes.bool,
    })
  ),
  sortCallback: PropTypes.func.isRequired,
  rowDataFilter: PropTypes.func,
};

FancyTable.defaultProps = {
  rowDataFilter: row => row,
};

export default FancyTable;
export { FancyTable };
