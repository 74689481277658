import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../util/ServerUtils';
import ActionTypes from '../../util/constants/ActionTypes';

class IncomeSourcesActionCreatorsClass {
  getIncomeSourcesData() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/apps/incomeSources', null, function(
      response
    ) {
      IncomeSourcesActionCreators.receiveData(response);
    });
  }
  receiveData(data) {
    AppDispatcher.dispatch({
      type: ActionTypes.APPS_INCOMESOURCES_RECEIVE_DATA,
      data: data,
    });
  }
}

const IncomeSourcesActionCreators = new IncomeSourcesActionCreatorsClass();

export { IncomeSourcesActionCreators };
