import React from 'react';
import AbstractComponent from '../../../components/AbstractComponent';
import Modalbox from '../../Modalbox/Modalbox';
import Button from '../../Button/Button';

import girls from './img/models-werben-models_girls.png';
import vxgamesLogo from './img/models-werben-models_VX-Games-Logo.png';
import { APP_BASE_PATH } from '../../../util/env';

class InviteModelCampaign extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillMount() {
    if (this.props.isOpen) this.open();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) this.open();
      else this.close();
    }
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <Modalbox
        name="invite-model-campaign"
        state={this.state.isOpen}
        onKeyPressESC={this.close}
        className="invite-model-campaign__contaier"
      >
        <div className="modalgrid__box">
          <div className="modalgrid__box__header vxmodels-academy__header">
            Models werben Models
            <div className="seperator" />
            <div
              className="icon-remove options vxmodels-academy__header__btn"
              onClick={this.close}
            />
          </div>

          <div className="invite-model-campaign">
            <div>
              <img src={girls} />
            </div>
            <div className="invite-model-campaign__content">
              <img src={vxgamesLogo} />
              <h2 className="invite-model-campaign__text">MODELS WERBEN MODELS</h2>
              <Button
                className="button button--blue"
                label="MEHR ERFAHREN"
                onClick={() => this.props.history.push(`${APP_BASE_PATH}/marketing/campaigns`)}
              />
              <div className="invite-model-campaign__note">VXP Vergabe KW 25</div>
            </div>
          </div>
        </div>
      </Modalbox>
    );
  }
}

export default InviteModelCampaign;
export { InviteModelCampaign };
