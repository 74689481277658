import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { WHITE } from '../../camtool-styles';

interface VXModelsIconProps extends Omit<SvgIconProps, 'color'> {
  width?: string;
  color?: string;
}

const useStyles = makeStyles({
  root: ({ width }: VXModelsIconProps) => {
    return { width: width, height: 'auto' };
  },
});

const VXModelsIcon: FC<VXModelsIconProps> = ({ width = '144px', color = WHITE }) => {
  const root = useStyles({ width });

  return (
    <SvgIcon viewBox={'0 0 600 119.7'} classes={root}>
      <g>
        <path
          css={{ fill: color }}
          d="M279.9,28.7c0-0.5,0.3-0.9,0.9-0.9h1.2c0.4,0,0.9,0.3,1.1,0.9l20.7,45.6h0.3l20.6-45.6
          c0.3-0.6,0.7-0.9,1.1-0.9h1.2c0.5,0,0.9,0.3,0.9,0.9v57.6c0,0.5-0.3,0.9-0.9,0.9h-1c-0.5,0-0.9-0.3-0.9-0.9V34.8h-0.3l-19.4,42.4
          c-0.3,0.7-0.6,0.9-1.5,0.9h-0.3c-0.9,0-1.1-0.2-1.5-0.9l-19.4-42.4h-0.3v51.5c0,0.5-0.3,0.9-0.9,0.9h-1c-0.5,0-0.9-0.3-0.9-0.9
          V28.7z"
        />
        <path
          css={{ fill: color }}
          d="M344.7,75.1c-0.9-2.6-1.7-6.8-1.7-17.6c0-10.8,0.9-15,1.7-17.6c2.8-8.6,9.1-12.9,18.4-12.9
          c9.3,0,15.6,4.4,18.4,12.9c0.9,2.6,1.7,6.8,1.7,17.6c0,10.8-0.9,15-1.7,17.6c-2.8,8.6-9.1,12.9-18.4,12.9
          C353.7,88,347.4,83.7,344.7,75.1z M378.9,74.4c0.8-2.4,1.7-6.4,1.7-16.9c0-10.6-0.9-14.6-1.7-16.9c-2.4-7.3-7.8-11.1-15.8-11.1
          s-13.4,3.8-15.8,11.1c-0.8,2.4-1.7,6.4-1.7,16.9c0,10.6,0.9,14.6,1.7,16.9c2.4,7.3,7.8,11.1,15.8,11.1S376.4,81.7,378.9,74.4z"
        />
        <path
          css={{ fill: color }}
          d="M398.3,28.7c0-0.5,0.3-0.9,0.9-0.9h17.5c9.7,0,16.1,3.7,18.6,11.5c1.2,3.8,1.7,7.1,1.7,18.2
          c0,11.1-0.4,14.4-1.7,18.2c-2.5,7.9-8.9,11.5-18.6,11.5h-17.5c-0.5,0-0.9-0.3-0.9-0.9V28.7z M401.5,84.6h15.1
          c8.2,0,13.8-2.9,16-9.6c1.1-3.5,1.6-6.8,1.6-17.5s-0.4-14.1-1.6-17.5c-2.2-6.7-7.8-9.6-16-9.6h-15.1c-0.3,0-0.5,0.2-0.5,0.5v53.3
          C401,84.4,401.1,84.6,401.5,84.6z"
        />
        <path
          css={{ fill: color }}
          d="M452.6,28.7c0-0.5,0.3-0.9,0.9-0.9h32.6c0.5,0,0.9,0.3,0.9,0.9v0.8c0,0.5-0.3,0.9-0.9,0.9h-30.3
          c-0.3,0-0.5,0.2-0.5,0.5v24.4c0,0.3,0.2,0.5,0.5,0.5H482c0.5,0,0.9,0.3,0.9,0.9v0.8c0,0.5-0.3,0.9-0.9,0.9h-26.2
          c-0.3,0-0.5,0.2-0.5,0.5v25.3c0,0.3,0.2,0.5,0.5,0.5h30.3c0.5,0,0.9,0.3,0.9,0.9v0.8c0,0.5-0.3,0.9-0.9,0.9h-32.6
          c-0.5,0-0.9-0.3-0.9-0.9V28.7z"
        />
        <path
          css={{ fill: color }}
          d="M500.6,28.7c0-0.5,0.3-0.9,0.9-0.9h1c0.5,0,0.9,0.3,0.9,0.9v55.4c0,0.3,0.2,0.5,0.5,0.5h30.3
          c0.5,0,0.9,0.3,0.9,0.9v0.8c0,0.5-0.3,0.9-0.9,0.9h-32.6c-0.5,0-0.9-0.3-0.9-0.9V28.7z"
        />
        <path
          css={{ fill: color }}
          d="M544.1,81.6c-0.3-0.3-0.4-0.9-0.1-1.2l0.7-0.8c0.3-0.4,0.8-0.4,1.2-0.1c4,3.1,10.4,6,17.4,6
          c10.6,0,17-5.4,17-13.9c0-6.6-3.4-11.5-15.5-13.1l-2.6-0.3c-11.8-1.6-16.8-7.1-16.8-15.1c0-10.1,7.1-16.1,18-16.1
          c6.2,0,12.1,1.9,16,4.5c0.4,0.3,0.5,0.8,0.2,1.2l-0.6,0.8c-0.3,0.4-0.8,0.4-1.2,0.2c-4.7-2.8-9.4-4.2-14.5-4.2
          c-9.6,0-15.1,5.4-15.1,13.4c0,7,4.1,11.5,15.5,13l2.6,0.3c11.8,1.6,16.7,6.9,16.7,15.2c0,9.9-6.9,16.7-20.3,16.7
          C555.4,88,547.9,84.8,544.1,81.6z"
        />
      </g>
      <g>
        <polygon
          css={{ fill: color }}
          points="244.9,56.3 264.6,27.8 249,27.8 236.4,46 223.9,27.8 213.8,27.8 213.8,27.8 200,27.8 183.7,72.6 
          167.5,27.8 153.1,27.8 176.4,87.2 190.3,87.2 211.8,33 228.1,56.5 206.8,87.2 222.4,87.2 236.5,66.8 250.7,87.2 266.3,87.2 	"
        />
      </g>
      <g>
        <g>
          <g>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="95.0702"
              y1="74.1363"
              x2="116.6063"
              y2="91.0551"
            >
              <stop offset="0" stopColor="#9E3026" />
              <stop offset="2.397424e-02" stopColor="#A93329" />
              <stop offset="9.392506e-02" stopColor="#C43B2F" />
              <stop offset="0.1744" stopColor="#DA4234" />
              <stop offset="0.2683" stopColor="#EB4737" />
              <stop offset="0.3845" stopColor="#F64A3A" />
              <stop offset="0.5475" stopColor="#FD4C3C" />
              <stop offset="1" stopColor="#FF4D3C" />
            </linearGradient>
            <path
              css={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: 'url(#SVGID_1_)' }}
              d="M92.4,77.2l7,10.1l13.8,0l-13.8-20l5.6-8l25.7,37.2H94.6l-7.7-11.2L92.4,77.2z"
            />
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="85.9132"
              y1="35.0179"
              x2="67.6011"
              y2="21.3685"
            >
              <stop offset="0" stopColor="#9E3026" />
              <stop offset="9.923142e-02" stopColor="#BC392D" />
              <stop offset="0.2079" stopColor="#D44032" />
              <stop offset="0.3305" stopColor="#E74637" />
              <stop offset="0.473" stopColor="#F54A3A" />
              <stop offset="0.6538" stopColor="#FD4C3B" />
              <stop offset="1" stopColor="#FF4D3C" />
            </linearGradient>
            <polygon
              css={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: 'url(#SVGID_2_)' }}
              points="76.9,18.6 87,33.2 81.4,41.4 72.1,27.8"
            />
          </g>
          <polygon
            css={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FF4D3C' }}
            points="133.1,18.6 97,18.6 74.5,51.2 64.7,37 53.5,37 74.5,67.3 101.8,27.8 115.6,27.8 74.5,87.2 33.4,27.8 
              72.1,27.8 72.3,28.2 77.2,19.1 76.9,18.6 15.9,18.6 74.5,103.4 		"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default VXModelsIcon;
