import React, { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router';
import LinkElement from './LinkElement';
import LinkContent from './LinkContent';
import { IconType as IconDefinition } from '../../../../atoms/Icon/Icon';
import { useLeftMenuRootContext } from '../Root';
import { useLeftMenuGroupContext } from '../Group';
import { APP_BASE_PATH as basePath } from '../../../../util/env';
import useOnNavigateToRoute from './useOnNavigateToRoute';

interface Props {
  title: string;
  icon: IconDefinition;
  iconSize?: string;
  uri?: string;
  onClick?: () => void;
  isGroupItem?: boolean;
}

const getFullUri = (uri?: string): string | undefined => (uri ? basePath + uri : undefined);

const LeftMenuItem: FC<Props> = ({
  title,
  icon,
  iconSize,
  uri,
  onClick: onClickFromProps,
  isGroupItem = false,
  children,
}) => {
  const history = useHistory();
  const { closeAllGroups } = useLeftMenuRootContext();
  const { isPartOfGroup, openGroup } = useLeftMenuGroupContext();

  const fullUri = getFullUri(uri);
  const isInSubMenu = isPartOfGroup && !isGroupItem;

  const onItemSelect = (): void => {
    if (isPartOfGroup) {
      openGroup();
    } else {
      closeAllGroups();
    }
  };

  const onClick = (event: MouseEvent): void => {
    event.preventDefault();

    if (onClickFromProps) onClickFromProps();
    else {
      onItemSelect(); // Necessary for some edge cases where useOnNavigateToRoute does not trigger, e.g. when clicking on the menu item which is already selected

      if (fullUri) history.push(fullUri);
    }
  };

  useOnNavigateToRoute(fullUri, onItemSelect);

  return (
    <LinkElement uri={fullUri} onClick={onClick}>
      <LinkContent title={title} icon={icon} iconSize={iconSize} isInSubMenu={isInSubMenu}>
        {children}
      </LinkContent>
    </LinkElement>
  );
};

export default LeftMenuItem;
