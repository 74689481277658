import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { ClassNames } from '@emotion/core';
import styled from '@emotion/styled';

import { ProgressCircle, T } from '../../components';

import ButtonLink from '../../atoms/ButtonLink/ButtonLink';
import { BLACK_2 } from '../../camtool-styles';
import ItemContainer from './ItemContainer';
import { APP_BASE_PATH } from '../../util/env';
import { _ } from '../../util/translate';

const Container = styled.div`
  width: 100%;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
  text-align: center;
  color: #888;
  display: flex;
  flex-direction: column;
`;

const ChartLabel = styled.div`
  font-size: 16px;
  color: ${BLACK_2};
`;

interface IProps {
    commonProfile: number;
    visitXProfile: number;
    loading: boolean;
    history: any
} // prettier-ignore

const ItemProfile = memo((props: IProps) => {
  return (
    <ItemContainer loading={props.loading} title={_('ranking:newRanking.item.profile.title')}>
      <Container>
        <p css={{ width: '100%' }}>
          <T _={'ranking:newRanking.item.profile.description'} />
        </p>

        <ClassNames>
          {({ css }) => (
            <div css={{ display: 'flex', alignSelf: 'stretch', justifyContent: 'space-around' }}>
              <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Link to={`${APP_BASE_PATH}/onlineprofile/common/aboutyou`}>
                  <ProgressCircle
                    data={[props.commonProfile || 0, 100]}
                    width={100}
                    height={100}
                    containerClass={css({ marginBottom: 8, '&:hover': { opacity: 0.6 } })}
                  />
                </Link>

                {_('profiles:profileName.common')
                  .split(' ')
                  .map((text: string) => (
                    <ChartLabel key={text}>{text}</ChartLabel>
                  ))}
              </div>

              <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Link to={`${APP_BASE_PATH}/onlineprofile/visitx/common`}>
                  <ProgressCircle
                    data={[props.visitXProfile || 0, 100]}
                    width={100}
                    height={100}
                    containerClass={css({ marginBottom: 8, '&:hover': { opacity: 0.6 } })}
                  />
                </Link>
                {_('profiles:profileName.visitx')
                  .split(' ')
                  .map((text: string) => (
                    <ChartLabel key={text}>{text}</ChartLabel>
                  ))}
              </div>
            </div>
          )}
        </ClassNames>

        <div>
          <ButtonLink
            theme="link-blue"
            to={`${APP_BASE_PATH}/onlineprofile/overview`}
            css={{ textDecoration: 'none' }}
          >
            <span css={{ marginRight: 4 }}>
              <T _={'ranking:newRanking.item.profile.linkText'} />
            </span>
            <span className="icon-arrow-right" />
          </ButtonLink>
        </div>
      </Container>
    </ItemContainer>
  );
});

export default ItemProfile;
