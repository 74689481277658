import React, { FC } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import BroadcastNew from './BroadcastNew';
import BroadcastDrafts from './BroadcastDrafts';
import BroadcastArchive from './BroadcastArchive';
import { Chats as TelegramDesktopChats } from '../../packages/Telegram';
import { ChatsMobile as TelegramMobileChats } from '../../packages/Telegram';
import ChannelDescriptionRoutes from './ChannelDescriptionRoutes';
import BotRoute from './BotRoute';
import AboWizard from '../AboWizard/AboWizard';
import { AboContentType } from '../AboWizard/types';
import ThreadContainer from '../../packages/Telegram/components/Chats/Mobile/ThreadContainer';
import AutoMail from './AutoMailRoutes/AutoMail';

const Telegram: FC = () => {
  const { url } = useRouteMatch();
  const path = stripSlash(url);
  const indexRoute = `${path}/broadcast/new`;

  return (
    <Switch>
      <Redirect exact from={`${path}/`} to={indexRoute} />
      <ProtectedRoute path={`${path}/broadcast/new`} component={BroadcastNew} />
      <ProtectedRoute path={`${path}/broadcast/drafts`} component={BroadcastDrafts} />
      <ProtectedRoute path={`${path}/broadcast/archive`} component={BroadcastArchive} />

      {/* Chat */}
      <ProtectedRoute path={`${path}/chat/desktop`} component={TelegramDesktopChats} />
      <ProtectedRoute path={`${path}/chat/mobile/thread/:userId`} component={ThreadContainer} />
      <ProtectedRoute path={`${path}/chat/mobile`} component={TelegramMobileChats} />

      {/* Lifecycles */}
      <ProtectedRoute path={`${path}/automail`} component={AutoMail} />
      {/* Telegram Abos */}
      <ProtectedRoute
        path={`${path}/abos`}
        component={AboWizard}
        componentProps={{
          typesFilter: { includedAboContentTypes: [AboContentType.Telegram] },
        }}
      />
      <ProtectedRoute path={`${path}/channel-description`} component={ChannelDescriptionRoutes} />
      <ProtectedRoute path={`${path}/bot`} component={BotRoute} />
      <Redirect to={indexRoute} />
    </Switch>
  );
};

export default Telegram;
