import styled from '@emotion/styled';
import { BLACK, BLACK_2, WHITE } from '../../camtool-styles';

interface StyledButtonProps {
  isOpen: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  border: none;
  background: ${({ isOpen }): string => (isOpen ? WHITE : BLACK)};
  color: ${({ isOpen }): string => (!isOpen ? WHITE : BLACK)};
  cursor: pointer;
  align-items: center;
  padding: 0 16px;
  '&:hover': {
    background-color: ${({ isOpen }): string => (!isOpen ? WHITE : BLACK_2)};
  }
`;
