import React, { FC } from 'react';
import Media from 'react-media';
import { AboContentType, IPageProps } from '../../types';
import AboContentButton from './AboContentButton';
import { _ } from '../../../../util/translate';
import { getSelectContentTypeCallback } from '../util';

interface Props extends IPageProps {
  aboContentTypes: AboContentType[];
}

const AboContentPage: FC<Props> = ({ dispatch, aboContentTypes }) => {
  const selectContentType = getSelectContentTypeCallback(dispatch);

  return (
    <article css={{ flexDirection: 'column' }}>
      <header css={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <h2>{_(`benefits:abo.create.title`)}</h2>
      </header>

      <Media query="(min-width: 1060px)">
        {(matches) => {
          const items = aboContentTypes.length;
          const cols = matches ? 3 : 2;
          const rows = Math.ceil(items / cols);

          const renderArray = new Array(rows).fill(null);

          return (
            <section css={{ flexDirection: 'column' }}>
              {renderArray.map((nil, i) => {
                return (
                  <div
                    key={`row:${i}`}
                    css={{
                      marginTop: 16,
                      minWidth: 208 * cols + 19 * (cols - 1),
                    }}
                  >
                    {aboContentTypes
                      .reduce<AboContentType[]>((acc, val, j) => {
                        const minValue = i * cols;
                        const maxValue = (i + 1) * cols;
                        return minValue < j + 1 && j + 1 <= maxValue ? [...acc, val] : acc;
                      }, [])
                      .map((aboContentType) => (
                        <AboContentButton
                          key={aboContentType}
                          type={aboContentType}
                          title={_(`benefits:abo.${aboContentType.toLowerCase()}.name`)}
                          description={_(
                            `benefits:abo.${aboContentType.toLowerCase()}.description`
                          )}
                          onClick={selectContentType}
                        />
                      ))}
                  </div>
                );
              })}
            </section>
          );
        }}
      </Media>
    </article>
  );
};

export default AboContentPage;
