import React, { FC, useState } from 'react';
import { LeftMenuGroupId } from '../types';
import { LeftMenuGroupContextProvider } from './context';
import LeftMenuGroupContent, { LeftMenuGroupContentProps } from './LeftMenuGroupContent';
import { getNewGroupId } from './counter';

type Props = LeftMenuGroupContentProps;

const LeftMenuGroup: FC<Props> = (props) => {
  const [groupId] = useState<LeftMenuGroupId>(getNewGroupId);

  return (
    <LeftMenuGroupContextProvider groupId={groupId}>
      <LeftMenuGroupContent {...props} />
    </LeftMenuGroupContextProvider>
  );
};

export default LeftMenuGroup;
