import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

interface CharLimitProps {
  valueLength: number;
  limit: number;
}

const CharLimit: FC<CharLimitProps> = ({ valueLength, limit }) => {
  return (
    <Box>
      <Typography variant="subtitle2">{`${valueLength}/${limit}`}</Typography>
    </Box>
  );
};

export default CharLimit;
