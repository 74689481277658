import React, { ReactElement } from 'react';

const Cyclecon = (): ReactElement => {
  return (
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Im_Versand"
      data-name="Im Versand"
      transform="translate(-1642 -4466)"
    >
      <g
        id="Kreis"
        transform="translate(1642 4466)"
        fill="#2699fb"
        stroke="#2699fb"
        strokeWidth="2"
      >
        <circle cx="21" cy="21" r="21" stroke="none" />
        <circle cx="21" cy="21" r="20" fill="none" />
      </g>
      <path
        id="Path"
        d="M13.819,0,8.91,5h3.682a7.439,7.439,0,0,1-7.364,7.5,7.1,7.1,0,0,1-3.436-.875L0,13.45A9.6,9.6,0,0,0,5.228,15,9.908,9.908,0,0,0,15.046,5h3.682Z"
        transform="translate(1658.272 4482)"
        fill="#fff"
      />
      <path
        id="Path-2"
        data-name="Path"
        d="M6.136,10A7.439,7.439,0,0,1,13.5,2.5a7.1,7.1,0,0,1,3.436.875L18.728,1.55A9.6,9.6,0,0,0,13.5,0,9.908,9.908,0,0,0,3.682,10H0l4.909,5,4.909-5Z"
        transform="translate(1650 4477)"
        fill="#fff"
      />
    </g>
  );
};

export default Cyclecon;
