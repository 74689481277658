import React, { createRef, FC, memo, useEffect } from 'react';
import { css } from '@emotion/core';

interface IProps {
  isAnimated?: boolean;
  className?: string;
}

const PAUSE = 500;
const LENGTH = 1400;

const styles = css`
  .cls-1 {
    fill: url(#Gradient_1);
    fill-rule: evenodd;
  }

  .cls-2 {
    fill: url(#Gradient_2);
    fill-rule: evenodd;
  }

  .cls-3 {
    fill: #ff4d3c;
    fill-rule: evenodd;
  }

  .cls-4 {
    fill-opacity: 0;
    stroke: #fff;
    stroke-width: 26.8;
    stroke-dasharray: ${LENGTH};
  }
`;

const VXLogo: FC<IProps> = memo(({ isAnimated = true, className }) => {
  let animate = isAnimated;
  const ref = createRef();
  const startTime = new Date().getTime() - LENGTH - PAUSE;

  const update = () => {
    const offset = new Date().getTime() - startTime;
    const pos = (offset % (2 * LENGTH + PAUSE)) - LENGTH - PAUSE;
    const val = pos > 0 ? pos : pos < -LENGTH ? LENGTH : -pos;
    if (ref.current) {
      ref.current.style.strokeDashoffset = val;
    }
    if (animate) {
      window.requestAnimationFrame(update);
    }
  };

  useEffect(() => {
    if (animate) {
      window.requestAnimationFrame(update);
    }
    return () => {
      window.requestAnimationFrame(() => {
        animate = false;
      });
    };
  }, []);

  return (
    <svg
      css={styles}
      className={'spinner__svg ' + (className || '')}
      // id="vxlogo-spinner"
      // data-name="vxlogo-spinner"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 341.07 246.61"
    >
      <defs>
        <linearGradient
          id="Gradient_1"
          x1="230.4"
          y1="161.51"
          x2="293.05"
          y2="210.73"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9e3026" />
          <stop offset="0.02" stopColor="#a93329" />
          <stop offset="0.09" stopColor="#c43b2f" />
          <stop offset="0.17" stopColor="#da4234" />
          <stop offset="0.27" stopColor="#eb4737" />
          <stop offset="0.38" stopColor="#f64a3a" />
          <stop offset="0.55" stopColor="#fd4c3c" />
          <stop offset="1" stopColor="#ff4d3c" />
        </linearGradient>
        <linearGradient
          id="Gradient_2"
          x1="203.76"
          y1="47.71"
          x2="150.49"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9e3026" />
          <stop offset="0.1" stopColor="#bc392d" />
          <stop offset="0.21" stopColor="#d44032" />
          <stop offset="0.33" stopColor="#e74637" />
          <stop offset="0.47" stopColor="#f54a3a" />
          <stop offset="0.65" stopColor="#fd4c3b" />
          <stop offset="1" stopColor="#ff4d3c" />
        </linearGradient>

        <mask id="mask_1">
          <polyline
            fill="none"
            ref={ref}
            className="cls-4"
            points="115.09 39.98 170.09 117.98 243.09 13.98 315.09 13.98 170.09 222.98 26.09 13.98 170.09 12.98 307.09 210.98 235.09 211.98 207.09 171.98"
          />
        </mask>
      </defs>
      <path
        className="cls-1"
        mask="url(#mask_1)"
        d="M222.74,170.34,243,199.66h40L242.8,141.49l16.34-23.34L334,226.35H229l-22.48-32.52Z"
      />

      <polygon
        className="cls-2"
        mask="url(#mask_1)"
        points="177.52 0.01 206.83 42.47 190.74 66.15 163.51 26.77 177.52 0.01"
      />

      <polygon
        className="cls-3"
        mask="url(#mask_1)"
        points="341.07 0 235.98 0 170.5 94.8 141.95 53.51 109.48 53.51 170.5 141.75 249.99 26.76 290.04 26.76 170.5 199.65 50.99 26.81 163.5 26.76 164.29 27.89 178.41 1.3 177.51 0 0 0.03 170.5 246.61 341.07 0"
      />
    </svg>
  );
});
VXLogo.displayName = 'VXLogo';

export default VXLogo;
