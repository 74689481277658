import React from 'react';
import AbstractAlbumView from '../AbstractAlbumView';
import { AlertsStore } from '../../../../stores/Alerts/AlertsStore';
import {
  MediaManagementActionCreators,
  RESOURCE_ALBUM,
} from '../../../../stores/MediaManagement/MediaManagement';
import NavPictureView from '../../../../routes/MediaManagement/Picture/NavPictureView/NavPictureView';
import Tooltip from '../../../Tooltip/Tooltip';
import AlbumAdd from '../AlbumAdd/AlbumAdd';
import AlbumItem from '../AlbumItem/AlbumItem';
import Spinner from '../../../Spinner/Spinner';
import EmptyContent from '../../../EmptyContent/EmptyContent';
import { APP_BASE_PATH } from '../../../../util/env';
import { _ } from '../../../../util/translate';

class AlbumViewStandard extends AbstractAlbumView {
  onClickDeleteAlbum() {
    if (confirm(_('mediamanagement:picture.album.form.confirmDelete'))) {
      this.setState({ isLoading: true });
      MediaManagementActionCreators.deleteResource(
        RESOURCE_ALBUM,
        {
          routeParams: { albumId: this.props.album.id },
        },
        () => {
          AlertsStore.add({ type: 'info', message: _('common:alerts.deleteSuccess') });
          this.setState({ isLoading: false });
          this.props.history.push(
            app / camtool / components / MediaMultiUploader / MediaMultiUploader.js_BASE_PATH +
              '/mediamanagement/picture/' +
              this.props.album.albumType
          );
        },
        () => {
          AlertsStore.add({ type: 'error', message: _('common:alerts.deleteFail') });
          this.setState({ isLoading: false });
        }
      );
    }
  }

  setEditMode() {
    if (this.props.editable) {
      this.context.router.push(this.context.router.location.pathname + '/edit');
    }
  }

  isPictureDeleteAllowed(picture) {
    return this.allowDeletePicture || picture.status === 'rejected';
  }

  render() {
    this.isRejected = this.props.album.status === 'rejected';
    this.isNotRejected = this.props.album.status !== 'rejected';

    //general rules
    this.allowAddPicture = this.isNotRejected;
    this.allowDeletePicture = this.props.album.sales === 0;
    this.allowMovePictureToPool = this.isNotRejected;
    this.allowMovePictureToPos0 = this.isNotRejected;
    this.allowSelect = this.isNotRejected;
    this.allowSetAsPreview = this.isNotRejected;
    this.showStatusInChecking = this.isNotRejected;
    this.showHeader = true;

    // specific rules
    switch (this.props.album.albumType) {
      case 'pool':
        this.allowAddPicture = false;
        this.allowDeletePicture = true;
        this.allowMovePictureToPool = false;
        this.allowSetAsPreview = false;
        break;
      case 'new':
        this.allowAddPicture = false;
        this.allowMovePictureToPool = false;
        this.allowSetAsPreview = false;
        break;
      case 'shop':
        this.allowMovePictureToPool = this.isNotRejected && this.props.album.sales === 0;
        break;
      case 'free':
        break;
      case 'campaign': // deprecated
      case 'contest':
        this.allowAddPicture =
          this.isNotRejected &&
          this.props.album.photoCampaign.isUploadActive &&
          this.state.album.numberOfAllPictures - this.state.album.numberOfRejectedPictures <
            this.props.album.photoCampaign.uploadLimit;
        break;
      case 'vxhpmediapool':
        this.showHeader = false;
        this.allowSetAsPreview = false;
        break;
    }
    this.showMenu = this.isNotRejected && this.props.editable;
    this.showDeleteButton = this.isRejected && this.props.editable;
    return (
      <div className="grid__box__column grid__box__sub">
        {this.showMenu && (
          <NavPictureView albumId={this.props.album.id} albumType={this.props.album.albumType} />
        )}

        {this.showHeader && (
          <div className="grid__box__sub__header">
            <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
              {this.props.title}
              {this.isRejected
                ? `(${_('mediamanagement:picfolder.common.status' + this.props.album.status)})`
                : ''}
            </div>

            {this.props.titleTooltip && <Tooltip position="right" text={this.props.titleTooltip} />}

            <div className="grid__box__sub__header__spacer" />

            {this.showDeleteButton && (
              <div
                onClick={this.onClickDeleteAlbum}
                className="icon-bin grid__box__sub__header__item--option"
              >
                {_('common:button.delete')}
              </div>
            )}
          </div>
        )}

        <div className="grid__box__sub__item">
          <div
            className="mediamanagement__pic__album spinner-container"
            onClick={this.onContentClick}
          >
            {this.state.isLoading && <Spinner />}

            <div className="mediamanagement__pic__album__content">
              {this.allowAddPicture && (
                <AlbumAdd
                  pictureType={this.props.album.albumType}
                  targetUmaId={this.props.album.id}
                  onSubmit={this.addPicturesToAlbum}
                  title={_('mediamanagement:picfolder.contentItemAdd.title')}
                  allowMultipleSelection={this.props.allowMultipleSelection}
                />
              )}

              {this.state.album.pictures.map((picture, pictureIndex) => (
                <AlbumItem
                  key={picture.pictureId}
                  data={picture}
                  album={this.props.album}
                  showDelete={this.isPictureDeleteAllowed(picture)}
                  showMoveToPool={this.allowMovePictureToPool}
                  showInChecking={this.showStatusInChecking}
                  showDetails
                  showSetAsPreview={this.allowSetAsPreview}
                  onClickMoveToPool={this.movePictureToPool}
                  onClickSetAsPreview16={this.setAsPreview16}
                  onClickSetAsPreview18={this.setAsPreview18}
                  onClickDelete={this.deletePicture}
                  onClick={this.selectPicture}
                  sequentialNumber={pictureIndex + 1}
                />
              ))}
            </div>

            {this.state.album.pictures.length === 0 && (
              <EmptyContent
                icon="icon-folder-open"
                title={_('mediamanagement:picfolder.common.noPicturesAvailable')}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AlbumViewStandard;
export { AlbumViewStandard };
