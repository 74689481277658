import React, { FC } from 'react';
import { NavSub, NavSubItem } from '../../../components';
import { _ } from '../../../util/translate';
import { AboContentTypesFilter } from '../types';

interface Props {
  basePath: string;
  typesFilter?: AboContentTypesFilter;
}

const AboNavSub: FC<Props> = ({ basePath, typesFilter }) => {
  const hasIncludeFilterWithSingleAbo = typesFilter?.includedAboContentTypes?.length === 1;
  const singleAboType = hasIncludeFilterWithSingleAbo
    ? typesFilter?.includedAboContentTypes?.[0]
    : undefined;

  return (
    <NavSub>
      <NavSubItem
        link={`${basePath}/create`}
        icon="icon-heart"
        label={
          hasIncludeFilterWithSingleAbo
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              _(`benefits:texts.nav.createSingle.${singleAboType!}`)
            : _('benefits:texts.nav.create')
        }
      />
      <NavSubItem
        link={`${basePath}/active`}
        icon="icon-donate-heart"
        label={
          hasIncludeFilterWithSingleAbo
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              _(`benefits:texts.nav.abo.activeSingle.${singleAboType!}`)
            : _('benefits:texts.nav.abo.active')
        }
      />
      <NavSubItem
        link={`${basePath}/inactive`}
        icon="icon-folder-heart"
        label={
          hasIncludeFilterWithSingleAbo
            ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              _(`benefits:texts.nav.abo.inactiveSingle.${singleAboType!}`)
            : _('benefits:texts.nav.abo.inactive')
        }
      />
    </NavSub>
  );
};

export default AboNavSub;
