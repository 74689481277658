import { Box, createStyles, makeStyles, Paper, Container } from '@material-ui/core';
import React, { FC } from 'react';
import { FooterIcon } from './components/FooterIcon';
import { Heading } from './components/Heading';
import { Terms } from './components/Terms';
import { facebook, instagram, twitter } from './assets/icons';
import { WHITE } from '../../../../../camtool-styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '100%',
      alignItems: 'center',
      backgroundColor: WHITE,
      paddingTop: 25,
    },
    icons: {
      display: 'flex',
      justifyContent: 'center',
      gridGap: 100,
      margin: 25,
    },
  })
);

const Footer: FC = () => {
  const classes = useStyles();
  return (
    <Paper style={{ borderRadius: 'none', border: 'none' }}>
      <Container className={classes.container}>
        <Heading />
        <Box className={classes.icons}>
          <FooterIcon link="https://www.instagram.com/vxmodels" src={instagram} />
          <FooterIcon link="https://twitter.com/vxmodels" src={twitter} />
          <FooterIcon link="https://www.facebook.com/VX-Models-165698620743707/" src={facebook} />
        </Box>
      </Container>
      <Terms />
    </Paper>
  );
};

export { Footer };
