import React, { FC, ReactElement } from 'react';
import { _ } from '../../../../../util/translate';
import { Field, FieldProps } from 'formik';
import { Service0900VoiceEnum } from '../../../../../graphql/VXModels/types';
import RadioCircleButton from '../../../../../atoms/RadioCircleButton/RadioCircleButton';

const BA6VoiceSetting: FC = () => {
  return (
    <div css={{ flexDirection: 'column', marginBottom: '24px' }}>
      <h2 css={{ fontSize: '16px' }}>{_('service0900:premium.voice.label')}</h2>
      <Field name="settings.voice">
        {({ field }: FieldProps): ReactElement => (
          <div css={{ marginTop: '8px' }}>
            <RadioCircleButton
              css={{ marginRight: '40px', fontSize: '16px', alignItems: 'center' }}
              {...field}
              value={Service0900VoiceEnum.female}
              checked={field.value === Service0900VoiceEnum.female}
              label={_('enum:profile.personalGender.female')}
            />
            <RadioCircleButton
              css={{ fontSize: '16px', alignItems: 'center' }}
              {...field}
              value={Service0900VoiceEnum.male}
              checked={field.value === Service0900VoiceEnum.male}
              label={_('enum:profile.personalGender.male')}
            />
          </div>
        )}
      </Field>
    </div>
  );
};

export default BA6VoiceSetting;
