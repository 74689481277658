import * as React from 'react';
import styled from '@emotion/styled';
import { Lang } from '../../../util/constants/Lang';
import { forEach } from 'lodash';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

type Value = { [key in Lang]: string };
type Translate = string;
type Error = string;

interface DeeplTextAreaPropsInterface {
  children: any; // eslint-disable-line
  lang: string;
  initialValues: Value;
  className?: string;
  maxLength?: number | null;
  name: string;
  placeholder?: string;
  validator?: (text: string) => string[] | void;
  translated: Translate;
}

interface DeeplTextAreaStateInterface {
  error: Error;
  translate: Translate;
}

class DeepLTextArea extends React.Component<
  DeeplTextAreaPropsInterface,
  DeeplTextAreaStateInterface
> {
  public static defaultProps: Partial<DeeplTextAreaPropsInterface> = {
    lang: 'de',
    className: '',
    maxLength: null,
    placeholder: '',
    validator: () => [],
    translated: '',
  };

  public state = {
    error: '',
    translate: this.props.translated || '',
  };

  public componentDidMount(): void {
    const { translated } = this.props;
    if (typeof translated === 'object' && Object.keys(translated).length > 0) {
      this.setState({ translate: translated });
    }
  }

  public componentDidUpdate(prevProps: DeeplTextAreaPropsInterface): void {
    if (this.props.translated !== prevProps.translated) {
      this.setState({
        error: '',
        translate: this.props.translated,
      });
    }
  }

  public handleValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    this.setState(({ error }) => ({
      error: error,
      translate: value,
    }));
  };

  public findOldTextsByLang = (values: Value, lang: string): string => {
    const keys = Object.keys(values);
    let singelValue = '';
    forEach(keys, (key) => {
      if (key === lang) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        singelValue = values[key];
      }
    });
    return singelValue;
  };

  public render(): React.JSX.Element {
    const { maxLength, name, placeholder, className, initialValues, lang } = this.props;
    const { error, translate } = this.state;
    const value = translate === '' ? this.findOldTextsByLang(initialValues, lang) : translate;

    return (
      <Container className={className}>
        <div style={{ justifyContent: 'space-between' }}>
          <div style={{ alignItems: 'flex-end', padding: '0 0 2px 2px', color: '#bcbcbc' }}>
            {maxLength && maxLength > 0 ? (
              <span>
                {value ? value.length : 0} / {maxLength}
              </span>
            ) : (
              <span>{value ? value.length : 0}</span>
            )}
          </div>
        </div>
        <div>
          {this.props.children({
            maxLength,
            onChange: this.handleValueChange,
            placeholder: `${placeholder}`,
            value: value,
          })}
        </div>
        <div>{error}</div>
        <input name={`${name}_deepl`} value={translate} type="hidden" key={`${name}_deepl`} />
      </Container>
    );
  }
}

export default DeepLTextArea;
