import React, {
  createContext,
  Dispatch,
  FC,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  clearAuthTokenCookie,
  clearBackurlMobileCookie,
  getAuthTokenCookie,
  getSessionIdCookie,
  setAuthTokenCookie,
  setLangCookie,
} from './cookies';
import { APP_IS_DEVELOPMENT } from './env';
import { ApiLang } from '../graphql/VXModels/types';
import { SessionActionCreators } from '../stores/Session/Session';
import ServerUtils from './ServerUtils';
import { getBestMatchForLanguage } from './lang';
import { useWebsocketContext } from '../packages/Websocket/hooks';
import {
  RequestTopicEnum,
  ResponseTopicEnum,
  WebsocketEventEnum,
} from '../packages/Websocket/types';

const DEBUG = APP_IS_DEVELOPMENT && true;

export enum ActionEnum {
  setAuthToken = 'setAuthToken',
  clearAuthToken = 'clearAuthToken',
  setLang = 'setLang',
  setState = 'setState',
}

interface Action {
  type: ActionEnum;
  authToken?: string;
  lang?: ApiLang;
  state?: AppStateValues;
}

type AuthToken = string | null;

export interface AppStateValues {
  authToken: AuthToken;
  sessionId: AuthToken;
  lang: ApiLang;
}

interface AppStateProps {
  children: FC<{ state: AppStateValues }>;
}

export type AppStateReducer = Reducer<AppStateValues, Action>;
export type AppStateDispatch = Dispatch<Action>;
export type AppStateContextValue = [AppStateValues, AppStateDispatch];

const initialState: AppStateValues = {
  authToken: getAuthTokenCookie(),
  sessionId: getSessionIdCookie(),
  lang: getBestMatchForLanguage(),
};

const KEEP_ALIVE_TIMOUT = 30000;

const updateAuthToken = (authToken: AuthToken, sessionId: AuthToken): void => {
  if (authToken) {
    setAuthTokenCookie(authToken);
  } else {
    clearAuthTokenCookie();
    clearBackurlMobileCookie();
  }
  SessionActionCreators.setAuthToken(authToken, sessionId);
  ServerUtils.updateToken(authToken);
};

const getInitialStateWithSideEffects = (): AppStateValues => {
  const { authToken, sessionId } = initialState;
  updateAuthToken(authToken, sessionId);

  return initialState;
};

const contextDefaultValue: AppStateContextValue = [initialState, () => initialState];
const AppStateContext = createContext<AppStateContextValue>(contextDefaultValue);

const reducer: AppStateReducer = (state: AppStateValues, action: Action): AppStateValues => {
  switch (action.type) {
    case ActionEnum.setAuthToken:
      if (DEBUG) {
        console.log(action.type, `${state.authToken} -> ${action.authToken}`);
      }

      const authToken = action.authToken as string;
      const sessionId = authToken;

      updateAuthToken(authToken, sessionId);

      return { ...state, authToken, sessionId };
    case ActionEnum.clearAuthToken:
      const authTokenNew = null;
      const sessionIdNew = null;

      if (DEBUG) {
        console.log(action.type, `${state.authToken} -> ${authTokenNew}`);
      }

      updateAuthToken(authTokenNew, sessionIdNew);

      return { ...state, authToken: authTokenNew, sessionId: sessionIdNew };

    case ActionEnum.setLang:
      if (typeof action.lang !== 'string' || !Object.keys(ApiLang).includes(action.lang)) {
        throw new Error(`Invalid lang passed to setLang: ${action.lang}`);
      }

      const lang = action.lang as ApiLang;

      if (DEBUG) {
        console.log(action.type, `${state.lang} -> ${lang}`);
      }

      setLangCookie(lang); // Only set the cookie when the user logs in or changes the language intentionally, not when the language is determined automatically in the initialState

      return { ...state, lang: lang as ApiLang };
    case ActionEnum.setState:
      if (DEBUG) {
        console.log(action.type, state, action.state);
      }

      return { ...state, ...action.state };
    default:
      return state;
  }
};

export const AppState = ({ children: Children }: AppStateProps) => {
  const { subscribe, publish } = useWebsocketContext();
  const setTimeoutKeepAlive = (): NodeJS.Timer =>
    setTimeout(() => publish(RequestTopicEnum.SESSION_KEEPALIVE_REQUEST), KEEP_ALIVE_TIMOUT);

  const contextValue: AppStateContextValue = useReducer<AppStateReducer>(
    reducer,
    null,
    getInitialStateWithSideEffects
  );
  const [state] = contextValue;
  const { authToken } = state;

  useEffect(() => {
    if (authToken) {
      subscribe(ResponseTopicEnum.SESSION_DATA, setTimeoutKeepAlive);
      subscribe(ResponseTopicEnum.SESSION_KEEPALIVE_ACK, setTimeoutKeepAlive);

      publish(RequestTopicEnum.SESSION_REGISTER_REQUEST, {
        sessionId: authToken,
        authToken: authToken,
      });
    }
  }, [authToken]);

  return (
    <AppStateContext.Provider value={contextValue}>
      <Children state={state} />
    </AppStateContext.Provider>
  );
};

export const useAppState = () => useContext(AppStateContext);

export const withAppState =
    <ComponentProps extends {}>( // eslint-disable-line
    Component: React.ComponentClass<ComponentProps & { appState: AppStateContextValue }> // Use useAppState() with functional components
  ): React.FC<ComponentProps> =>
  // eslint-disable-next-line react/display-name
  ({ ...props }) => {
    const appStateValue = useAppState();

    return <Component appState={appStateValue} {...props} />;
  };

export type UseLangReturnValue = [ApiLang, (lang: ApiLang) => any];

export const useLang = (): UseLangReturnValue => {
  const [{ lang }, dispatch] = useAppState();
  const setLang = useCallback(
    (language: ApiLang) =>
      dispatch({
        type: ActionEnum.setLang,
        lang: language,
      }),
    [dispatch]
  );

  return [lang, setLang];
};
