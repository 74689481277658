import React, {FC} from 'react';
import {_} from '../../../../../util/translate';
import HelpcenterLink from "../../../../Helpcenter/HelpcenterLink";

interface IProps {
    className?: string;
}

const DailyBonusHelpcenterLink: FC<IProps> = ({className}) => (
    <a
        label={_('mediamanagement:video.dailyBonus.helpcenterLink')}
        target={'_blank'}
        href={HelpcenterLink() + '/articles/360010947940'}
        className={className}
        css={{textDecoration: 'none', color: '#1f93e9', hover: {color: '#3bacf7'}}}
    >{_('mediamanagement:video.dailyBonus.helpcenterLink')}</a>
);

export default DailyBonusHelpcenterLink;