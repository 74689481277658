import React, { FC } from 'react';
import { WHITE, GREEN, GRAY_3 } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';

interface IProps {
  active: boolean;
  blocked: boolean;
}

const OnlineStatus: FC<IProps> = ({ active, blocked }) => {
  return (
    <span
      css={{
        color: blocked && !active ? GRAY_3 : active ? WHITE : GREEN,
        fontSize: '12px',
      }}
    >
      {_('common:text.online')}
    </span>
  );
};

export default OnlineStatus;
