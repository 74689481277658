import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { ErrorResponse, onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import { subscribe } from '../../../packages/Websocket';
import { ResponseTopicEnum } from '../../../packages/Websocket/types';
import { getGraphQLEndpoint, log } from '../../../util';
import AppUtils from '../../../util/AppUtils';
import ActionTypes from '../../../util/constants/ActionTypes';
import AppDispatcher from '../../../util/dispatcher/AppDispatcher';
import { APP_IS_DEVELOPMENT } from '../../../util/env';
import { isServerErrorWithStatusCode } from '../../util';
import defaults from '../defaults';
import introspectionQueryResultData from '../introspection-result';
import resolvers from '../resolvers';
import typeDefs from '../typeDefs';
import { ApiLang } from '../types';
import { dataIdFromObject } from './dataIdFromObject';

const DEBUG = APP_IS_DEVELOPMENT && true;
const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });

export const getClient = (authToken: string | undefined, lang: ApiLang): ApolloClient<unknown> => {
  let errorTimestamps: number[] = [];
  const uri = getGraphQLEndpoint(lang);
  const withAuth = setContext((_, { headers }) => {
    return {
      uri,
      headers: {
        ...headers,
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
    };
  });

  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }: ErrorResponse) => {
      const context = operation.getContext();
      const { operationName, variables, toKey } = operation;
      const userDataStr = window.sessionStorage.getItem('userdata');
      const userData = userDataStr ? JSON.parse(userDataStr) : {};
      const userId = userData?.user?.userId || userData?.userId || null;

      if (networkError) {
        const { statusCode = null, response = null, bodyText = null } = networkError;
        if (isServerErrorWithStatusCode(networkError, 401) || userId === null) {
          AppUtils.forceLogout();
        } else {
          log('error', `[Network error]: ${networkError.message} Operation: ${operationName}`, {
            context: 'apollo.networkError',
            data: {
              operationName,
              variables,
              toKey,
              statusCode,
              bodyText,
              response: {
                headers: response?.headers || [],
                statusText: response?.statusText || null,
                statusCode: response?.statusCode || null,
                url: response?.url || null,
              },
              context,
            },
          });
        }
      } else if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }: GraphQLError, idx: number) => {
          const msg = `[GraphQL error#${idx}]: ${message} Operation: ${operationName}${
            locations ? '; Location: ' + JSON.stringify(locations) : ''
          }${path ? '; Path: ' + path : ''}`;
          log('error', msg, {
            context: 'apollo.graphQLError',
            data: {
              operationName,
              variables,
              context,
              toKey,
            },
          });
        });
      }

      const currentTimestamp = new Date().getTime();

      errorTimestamps.push(currentTimestamp);
      errorTimestamps = errorTimestamps.filter(
        (timestamp: number) => timestamp > currentTimestamp - 10000
      );

      // If there were at least 5 errors within 10 seconds, log out to prevent infinite retry loops
      if (errorTimestamps.length >= 5) {
        AppUtils.forceLogout(true); // Disable redirect to not redirect the model to the same faulty page after login (we do not want the model to instantly be logged out again after logging in)
      }
    }),
    withAuth.concat(createHttpLink()),
  ]);

  const cache = new InMemoryCache({ fragmentMatcher, dataIdFromObject });
  cache.writeData({ data: defaults });

  const client = new ApolloClient({ link, cache, typeDefs, resolvers });
  client.onResetStore(() => cache.writeData({ data: defaults }));
  client.type = 'VXModels';

  if (authToken) {
    // legacy websocket support, just delete, when websocket is changed to WebsocketProvider
    AppDispatcher.register((payload) => {
      if (payload.type === ActionTypes.GRAPHQL_WRITE_QUERY) {
        const { data, query, variables } = payload.data;
        client.writeQuery({ data, query: gql(query), variables });
        DEBUG &&
          console.log('[Websocket] < update GraphQL', {
            query,
            variables,
            data: client.readQuery({ query: gql(query), variables }),
          });
      }
    });

    subscribe(ResponseTopicEnum.GRAPHQL_WRITE_QUERY, (payload) => {
      const { data, query, variables } = payload.data;
      client.writeQuery({ data, query: gql(query), variables });
      DEBUG &&
        console.log('[Websocket] < update GraphQL', {
          query,
          variables,
          data: client.readQuery({ query: gql(query), variables }),
        });
    });
  }

  DEBUG && console.log('Apollo endpoint', client.type, uri, { authToken });

  return client;
};
