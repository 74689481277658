import { QueryLazyOptions, useLazyQuery } from '@apollo/react-hooks';
import { LazyQueryHookOptions } from '@apollo/react-hooks/lib/types';
import { Mailing, Query } from '../../../graphql/VXModels/types';
import { useVXModelsClient } from '../../../graphql/VXModels';
import { QueryResult } from '@apollo/react-common';
import { QUERY_MODEL_MAILINGS_MAILING } from '../graphql/queries/mailings';

type Variables = { id: number };

type Result = [
  (id: number, options?: QueryLazyOptions<Variables> | undefined) => void,
  Omit<QueryResult<Query, Variables>, 'data'> & { mailing: Mailing | undefined | null }
];

export const useLazyMailing = (options?: LazyQueryHookOptions<Query, Variables>): Result => {
  const client = useVXModelsClient();
  const [lazyQuery, { data, ...result }] = useLazyQuery<Query, Variables>(
    QUERY_MODEL_MAILINGS_MAILING,
    { ...options, client }
  );
  const query = (id, options) => lazyQuery({ ...options, variables: { id } });
  return [query, { mailing: data?.model?.mailings?.mailing, ...result }];
};
