import React from 'react';
import { Query } from '@apollo/react-components';

import { CmsArticle, T } from '../../../components';
import { SessionStore } from '../../../stores/Session/Session';
import Markdown from '../../../components/Markdown/Markdown';
import Spinner from '../../../components/Spinner/Spinner';
import { QUERY_CMS_ARTICLE } from '../../../graphql/VXModels/queries';


class Imprint extends React.PureComponent {
  state = { userData: SessionStore.get() };

  render() {
    return (
      <div className="grid__row">
        <div className="grid__column grid__box">
          <div className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'common:legal.imprint'} />
            </div>
          </div>
          <div className="grid__box__item">
            <div className="grid__box__item__content" style={{ minHeight: 200 }}>
              <CmsArticle id={'imprint'} className="legal__box --noselect" linkTarget="_blank" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Imprint;
