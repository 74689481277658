import React, { FC } from 'react';
import { Section } from '../../../../../atoms';
import { _ } from '../../../../../util/translate';
import BA6SettingsHeader from './BA6SettingsHeader';
import BA6EditNumber from './BA6EditNumber';
import BA6ProfileSettings from './BA6ProfileSettings';
import { Service0900PhoneData } from '../../types';

interface Props {
  storeData: Service0900PhoneData;
  onSuccess: () => void;
}

const BA6ProfileSection: FC<Props> = ({ storeData, onSuccess }) => {
  return (
    <Section css={{ flex: '1' }} title={_('service0900:PhoneSex.active.profile.title')}>
      <div
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          margin: '35px',
          flexDirection: 'column',
        }}
      >
        <div css={{ flexDirection: 'column' }}>
          <BA6SettingsHeader />
          <BA6ProfileSettings settings={storeData?.settings} />
          <BA6EditNumber storeData={storeData} onSuccess={onSuccess} />
        </div>
      </div>
    </Section>
  );
};

export default BA6ProfileSection;
