import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

interface StyleFixProps {
  spacing?: number;
}

export const useStyleFix = makeStyles<Theme, StyleFixProps>((theme: Theme) =>
  createStyles({
    gridBoundries: ({ spacing = 0 }) => ({
      paddingTop: theme.spacing(spacing),
      paddingLeft: theme.spacing(spacing),
      paddingRight: theme.spacing(spacing),
    }),
    containerBoundries: {
      marginTop: theme.spacing(1), // to give NavSub some breathing space
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginLeft: 0,
    },
    gutterFix: ({ spacing = 0 }) => ({
      marginLeft: theme.spacing(spacing) * -1,
      marginRight: theme.spacing(spacing) * -1,
    }),
  })
);
