import { mergeDeep } from 'apollo-utilities';
import { QUERY_MODEL_MAILINGS_MAILING } from './queries/mailings';

export const updateMailing = (
  cache: any,
  {
    data: {
      model: {
        mailings: { save, publish },
      },
    },
  }
): void => {
  const mailing = save || publish;
  const { id, type } = mailing;

  try {
    const cachedValue = cache.readQuery({
      query: QUERY_MODEL_MAILINGS_MAILING,
      variables: { id: id },
    });
    cache.writeQuery({
      query: QUERY_MODEL_MAILINGS_MAILING,
      variables: { id },
      data: mergeDeep(cachedValue, { model: { mailings: { mailing } } }),
    });
  } catch (e) {
    // Since we're doing this save action kinda wrong
    // we can fix it with a try catch
    // If we wanted to do it the 'right' way we'd have to change how we access mailings and add
    // new mailings...
  }
};
