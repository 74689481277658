import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import {
  MailingRecipientsFilters,
  MailingRecipientsGroupsEnum,
  Query,
} from '../../../graphql/VXModels/types';
import { useVXModelsClient } from '../../../graphql/VXModels';
import { QueryResult } from '@apollo/react-common';
import { QUERY_MODEL_MAILINGS_RECIPIENTS_FILTER } from '../graphql/queries';

type Variables = { recipientsGroup: MailingRecipientsGroupsEnum };

type Result = Omit<QueryResult<Query, Variables>, 'data'> & {
  recipientsFilters: MailingRecipientsFilters | undefined;
};

export const useMailingsRecipientsFilters = (
  recipientsGroup: MailingRecipientsGroupsEnum,
  options?: QueryHookOptions<Query, Variables>
): Result => {
  const client = useVXModelsClient();
  const { data, ...result } = useQuery<Query, Variables>(QUERY_MODEL_MAILINGS_RECIPIENTS_FILTER, {
    ...options,
    client,
    variables: { recipientsGroup },
  });

  return { recipientsFilters: data?.model?.mailings?.recipientsFilters, ...result };
};
