import React from 'react';
import PropTypes from 'prop-types';
import { APP_IS_DEVELOPMENT } from '../../util/env';
import { BLACK, RED } from '../../camtool-styles';
import { _ as translate } from '../../util/translate';

const Translation = ({ _, t, noInfo, ...rest }) => {
  const key = t || _;
  const translated = translate(key, rest);
  const style = {};
  if (key === translated) {
    style.textDecoration = `underline wavy ${RED}`;
    style.background = 'yellow';
    style.color = BLACK;
  }

  if (APP_IS_DEVELOPMENT) {
    return (
      <span
        className={`translation ${key === translated ? '--notfound' : '--ok'}`}
        style={style}
        title={key}
      >
        {translated}
      </span>
    );
  }

  return translated;
};

Translation.propTypes = {
  t: PropTypes.string,
  _: PropTypes.string, // @deprecated fallback
  sprintf: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Translation.defaultProps = {
  sprintf: null,
  noInfo: false,
};

export default Translation;
export { Translation as T };
