import React, { FC } from 'react';
import { _ } from '../../../util/translate';
import { useQuery } from '@apollo/react-hooks';
import { Query } from '../../../graphql/VXModels/types';
import { Main } from '../../../atoms/';
import { QUERY_MODEL_SETTINGS_MARKETING } from '../../../graphql/VXModels/queries';
import { Spinner } from '../../../components';
import SettingsSection from './SettingsSection';
import MarketingStatus from './MarketingStatus';
import GenericEmptyContent from '../../../atoms/GenericEmptyContent/GenericEmptyContent';

const Settings: FC = () => {
  const { data, loading, error } = useQuery<Query>(QUERY_MODEL_SETTINGS_MARKETING);
  const marketingSettings = data?.model?.settings?.marketingSettings;
  const hasVXMarketing = marketingSettings?.hasVXMarketing;

  return error ? (
    <GenericEmptyContent />
  ) : (
    <Main isMobileEnabled>
      <SettingsSection
        title={_('marketing:settings.vxmarketing.title')}
        headline={_('marketing:settings.vxmarketing.headline')}
        subHeadline={_('marketing:settings.vxmarketing.subHeadline')}
        bulletList={[
          _('marketing:settings.vxmarketing.list.firstArgument'),
          _('marketing:settings.vxmarketing.list.secondArgument'),
          _('marketing:settings.vxmarketing.list.thirdArgument'),
        ]}
      >
        {loading ? <Spinner /> : <MarketingStatus checked={hasVXMarketing} />}
      </SettingsSection>
    </Main>
  );
};

export default Settings;
