import React, { FC, useEffect, useState } from 'react';
import { _ } from '../../../util/translate';
import styled from '@emotion/styled';
import Markdown from '../../../components/Markdown/Markdown';
import NewButton from '../../../atoms/Button/NewButton';
import { MUICheckbox, MUICheckboxFormControlLabel } from '../../../components/MUI/Checkbox';
import { css } from '@emotion/core';
import { useMutation } from '@apollo/react-hooks';
import { Mutation } from '../../../graphql/VXModels/types';
import { MUTATION_MODEL_VXCASH_CREATE_WEBMASTER_ID } from '../../../graphql/VXModels/mutations';
import AlertsStore from '../../../stores/Alerts/AlertsStore';
import { makeStyles, createStyles } from '@material-ui/core';
import { useUserData } from '../../../util/UserData';

const CheckboxContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
`;

const useStyles = makeStyles(() =>
  createStyles({
    // align checkbox in MUICheckboxFormControlLabel next to label (todo: refactor MUICheckboxFormControlLabel to do this more easy)
    root: {
      padding: '1px 0px 0px 5px',
      alignSelf: 'flex-start',
    },
  })
);

interface IProps {
  headerText: string;
  subheaderText?: JSX.Element;
  labelSource: string;
  buttonText: string;
  onSuccess?: () => void;
}

const TOSContent: FC<IProps> = ({
  headerText,
  subheaderText,
  labelSource,
  buttonText,
  onSuccess,
}) => {
  const classes = useStyles();
  const userData = useUserData();

  const [createWebmasterId, { loading, data }] = useMutation<Mutation>(
    MUTATION_MODEL_VXCASH_CREATE_WEBMASTER_ID
  );

  const mutationData = data?.model?.vxcash?.createWebmaster;
  const [state, setState] = useState({
    termsConditionsOk: false,
    disabled: false,
  });

  useEffect(() => {
    if (mutationData?.status === 'INTERNAL_ERROR') {
      AlertsStore.add({
        type: 'error',
        message: _('common:error.generic'),
      });
    }

    if (mutationData?.data.wmId) {
      userData.setOverrideData({ model: { hasWebmasterId: true } });
      onSuccess?.();
    }
  }, [mutationData]);

  return (
    <>
      <h2 style={{ fontSize: '18px' }}>{headerText}</h2>
      {!!subheaderText && subheaderText}
      <CheckboxContainer>
        <MUICheckboxFormControlLabel
          checked={state.termsConditionsOk}
          control={
            <MUICheckbox
              className={classes.root}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                setState({ ...state, termsConditionsOk: e.target.checked })
              }
            />
          }
          label={
            <Markdown
              css={css`
                p {
                  padding: 0px;
                }
              `}
              source={labelSource}
              renderers={{
                // eslint-disable-next-line react/display-name
                link: (props: Record<string, never>): JSX.Element => (
                  <a href={props.href} target="_blank" rel="noopener noreferrer">
                    {props.children}
                  </a>
                ),
              }}
            />
          }
        />
      </CheckboxContainer>
      <NewButton
        size={'medium'}
        disabled={!state.termsConditionsOk || state.disabled || loading}
        loading={loading}
        onClick={(): void => {
          if (state.termsConditionsOk) createWebmasterId();
        }}
      >
        {buttonText}
      </NewButton>
    </>
  );
};

export default TOSContent;
