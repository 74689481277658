import React, { FC, ReactElement, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { QUERY_WIDGET_BULLETTEXTLIST } from '../../graphql/queries';
import * as Yup from 'yup';
import { _ } from '../../../../util/translate';
import { useUserData } from '../../../../util/UserData';
import { Spinner } from '../../../../components';
import { useLang } from '../../../../util/AppState';
import {
  useGetChannelAdvantages,
  useSetMessengerVipBullets,
  useVXServicesTelegramContext,
} from '../..';
import { Box } from '@material-ui/core';
import ChannelAdvantagesList from './ChannelAdvantagesList';
import PublishDescriptionSection from './PublishDescriptionSection';
import LanguageToggle from './LanguageToggle';
import GenericEmptyContent from '../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import {
  BulletText,
  MasterMessengerVipTextMutationsetBulletsArgs,
} from '../../../../graphql/VXServicesTelegram/types';
import PromptAlert from '../../../../atoms/PromptAlert/PromptAlert';
import PreviewChannel from './PreviewChannel';

export const CHAR_LIMIT = 80;

const validationSchema = (): Yup.Schema<{ bullets: Array<{ text: string }> }> =>
  Yup.object().shape({
    bullets: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().max(CHAR_LIMIT).required(_('common:errorForm.required')),
      })
    ),
  });

const ChannelDescription: FC = () => {
  const [lang] = useLang();
  const [channelLang, setChannelLang] = useState(lang);
  const { token } = useVXServicesTelegramContext();
  const userData = useUserData();
  const actorId = userData?.auth?.id;
  const { data, loading, error } = useGetChannelAdvantages(actorId, channelLang);
  const [saveBullets, { loading: saveLoading, error: saveError }] = useSetMessengerVipBullets({
    refetchQueries: [
      {
        query: QUERY_WIDGET_BULLETTEXTLIST,
        variables: { actorId: actorId, language: channelLang },
      },
    ],
    awaitRefetchQueries: true,
  });
  const bullets = data?.widget?.bulletTextList?.bulletTexts;
  const bulletsWithoutTypename = bullets?.map((bullet: Omit<BulletText, 'masterId' | 'id'>) => {
    if (bullet?.__typename) delete bullet.__typename;
    return bullet;
  });

  if (loading) return <Spinner />;
  if (error || saveError) return <GenericEmptyContent />;

  return (
    <Formik
      initialValues={{
        bullets: bulletsWithoutTypename,
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (
        values: MasterMessengerVipTextMutationsetBulletsArgs,
        { setSubmitting }
      ): Promise<void> => {
        await saveBullets({ variables: { token: token, bullets: values.bullets } });
        setSubmitting(false);
      }}
    >
      {({ errors, values, dirty, isSubmitting }): ReactElement => (
        <>
          <PromptAlert when={dirty} message={_('telegram:channeldescription.alert.leave')} />
          {values.bullets?.length > 1 ? (
            <Form css={{ display: 'flex', justifyContent: 'center' }}>
              <Box display="flex" flexDirection="column" alignItems="center" width="560px" px={2}>
                <Box display="flex" justifyContent="flex-end" width="100%" mt={8}>
                  <LanguageToggle
                    language={channelLang}
                    changeLanguage={setChannelLang}
                    disabled={!!errors?.bullets || dirty}
                  />
                </Box>
                <FieldArray name="list">
                  {(): ReactElement => <ChannelAdvantagesList values={values} />}
                </FieldArray>
                <PublishDescriptionSection
                  loading={saveLoading}
                  disabled={!!errors?.bullets || isSubmitting || !dirty}
                />
                <Box mt={5}>
                  <PreviewChannel actorId={actorId} />
                </Box>
              </Box>
            </Form>
          ) : (
            <GenericEmptyContent />
          )}
        </>
      )}
    </Formik>
  );
};

export default ChannelDescription;
