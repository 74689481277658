import React, { FC } from 'react';
import { Grid, Container, createStyles, makeStyles } from '@material-ui/core';
import { GridItem } from './components/GridItem';
import { VXGChallenge } from '../../../../../graphql/VXModels/types';

interface OverviewGridProps {
  challenges: VXGChallenge[];
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginBottom: 16,
    },
  })
);

const CompetitionOverviewGrid: FC<OverviewGridProps> = ({ challenges }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container spacing={2}>
        {challenges.map((challenge) => (
          <Grid item xs={12} md={6} lg={4} key={challenge.id}>
            <GridItem challenge={challenge} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export { CompetitionOverviewGrid };
