import * as React from 'react';

import { StyledInputRange, StyledInputText, StyledLabel } from './styles';
import { ApiLang } from '../../graphql/VXModels/types';

class Range extends React.PureComponent<IProps, IState> {
  public static defaultProps: Partial<IProps> = { initialValue: 0, language: ApiLang.de, label: '', max: 100, min: 0, step: 1, onChange: () => null }; // prettier-ignore

  public static formatNumber = (num: number, step: number): number =>
    (((num * 100) / (step * 100)) * (step * 100)) / 100; //* 100 because javascript has problems with decimal math (e.g. 1.9 turned to 1.89999...7 here) (http://adripofjavascript.com/blog/drips/avoiding-problems-with-decimal-math-in-javascript.html)
  constructor(props: IProps) {
    super(props);
    // Force the use of a meaningful initial value
    let initialValue = Range.formatNumber(props.initialValue, props.step);
    if (props.initialValue < props.min) {
      initialValue = props.min;
    } else if (props.initialValue > props.max) {
      initialValue = props.max;
    }

    this.state = {
      inputValue: this.replaceDotOrComma(initialValue.toFixed(2)),
      value: initialValue,
    };
  }

  public replaceDotOrComma(value: string): string {
    return this.props.language === ApiLang.de ? value.replace('.', ',') : value.replace(',', '.');
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.value !== prevState.value) {
      this.props.onChange(this.state.value);
    }
  }

  public hasLabel = (): boolean => this.props.label.length > 0;

  public setValueFromTextField = () => {
    const { min, max, step } = this.props;
    const inputValue = this.state.inputValue.replace(',', '.');
    if (inputValue.match(/^-?\d*(\.\d+)?$/)) {
      let value = Range.formatNumber(Number(inputValue), step);
      if (value > max) {
        value = max;
      } else if (value < min) {
        value = min;
      }
      this.setState({ value, inputValue: this.replaceDotOrComma(String(value)) });
    }
  };

  public handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.setValueFromTextField();
    }
  };

  public handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setValueFromTextField();
  };

  public handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: event.target.value });
  };

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value).toFixed(2);
    this.setState({ value: Number(value), inputValue: this.replaceDotOrComma(String(value)) });
  };

  public render() {
    return (
      <div css={{ alignItems: 'center', width: '100%' }}>
        <label css={{ marginRight: 16 }}>
          <StyledInputText
            value={this.state.inputValue}
            onChange={this.handleTextChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            type="text"
            data-testid="range-input-text"
            disabled={this.props.disabled}
            css={{ borderRadius: this.hasLabel() ? '2px 0 0 2px' : '2px' }}
          />
          {this.hasLabel() && <StyledLabel>{this.props.label}</StyledLabel>}
        </label>

        <StyledInputRange
          value={this.state.value}
          onChange={this.handleChange}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          type="range"
          data-testid="range-input-range"
          size={4}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

interface IState { value: number; inputValue: string; } // prettier-ignore

interface IProps { initialValue: number; language: ApiLang, min: number; max: number; step: number; label: string; disabled: boolean; onChange: (value: number) => void } // prettier-ignore

export default Range;
