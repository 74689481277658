import React from 'react';
import PropTypes from 'prop-types';
import * as Formsy from 'formsy-react';
import AbstractComponent from '../AbstractComponent';
import cloneDeep from 'lodash/cloneDeep';
import classnames from 'classnames';

import isValidDate from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import parseISO from 'date-fns/parseISO';

import { addDuration } from '../../util/DateAndTime';

/**
 * The base component that binds all Formsy Mixin methods so that they can be extended by es6 Formsy components.
 */
class AbstractFormComponent extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = Object.assign(this.state || {}, Formsy.Mixin.getInitialState.bind(this)());

    Object.getOwnPropertyNames(Formsy.Mixin).forEach((method) => {
      if (typeof Formsy.Mixin[method] !== 'function') {
        return;
      }
      if (AbstractFormComponent.ignoredMixinMethods.indexOf(method) !== -1) {
        return;
      }

      this[method] = Formsy.Mixin[method].bind(this);
    });

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Formsy.Mixin.componentWillMount.bind(this)();
  }

  componentWillUnmount() {
    Formsy.Mixin.componentWillUnmount.bind(this)();
  }

  componentWillReceiveProps(nextProps) {
    return Formsy.Mixin.componentWillReceiveProps.bind(this)(nextProps);
  }

  componentDidUpdate(prevProps) {
    return Formsy.Mixin.componentDidUpdate.bind(this)(prevProps);
  }

  onChange(event) {
    event.stopPropagation();
    const nextValue = event.target.value;
    if (nextValue !== this.getValue()) {
      if (this.props.onChange(event) !== false) {
        this.setValue(nextValue);
      }
    }
  }

  getValue() {
    return Formsy.Mixin.getValue.bind(this)();
  }

  setValue(value) {
    return Formsy.Mixin.setValue.bind(this)(cloneDeep(value));
  }

  resetValue() {
    return Formsy.Mixin.resetValue.bind(this)();
  }

  getClassNames() {
    let classes = {
      disabled: this.props.disabled,
      valid: !this.showError(),
      invalid: this.showError() && this.props.displayErrors,
      required: this.showRequired() && this.props.displayRequired,
    };

    return classnames(classes) + ' ' + this.props.className;
  }

  getCustomErrorMessage() {
    let errorMessage;

    if (this.showError()) {
      errorMessage = this.getErrorMessage();
      const errorMessages = this.getErrorMessages();
      if (errorMessages.length > 0) {
        errorMessage = errorMessages.join(', ');
      }
    }

    if (this.props.requiredMessage && this.showRequired()) {
      errorMessage = this.props.requiredMessage;
    }

    if (this.state._externalError) {
      errorMessage = this.state._externalError.join(', ');
    }

    return errorMessage;
  }
}

AbstractFormComponent.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  required: PropTypes.bool,
  requiredMessage: PropTypes.string,
  displayErrors: PropTypes.bool,
  displayRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

AbstractFormComponent.defaultProps = {
  className: '',
  validationError: '',
  validationErrors: {},
  required: false,
  requiredMessage: '',
  displayErrors: true,
  displayRequired: true,
  onChange: () => {
    return true;
  },
};

AbstractFormComponent.contextTypes = {
  formsy: PropTypes.object,
};

AbstractFormComponent.ignoredMixinMethods = [
  'getDefaultProps',
  'getInitialState',
  'getValue',
  'componentWillMount',
  'componentWillUnmount',
  'componentWillReceiveProps',
  'componentDidUpdate',
  'setValue',
  'resetValue',
];

// add some validations

// overwrite default required validator
Formsy.addValidationRule('isDefaultRequiredValue', function (values, value) {
  let isInvalid = false;
  let match;
  if (Array.isArray(value)) {
    // arrays
    isInvalid = value.length < 1;
  } else if (value && typeof value === 'object' && !!value._langs) {
    // multilang
    isInvalid = true;
    for (let lang of value._langs) {
      isInvalid = (isInvalid && value[lang] === undefined) || value[lang] === '';
    }
  } else if ((match = /^(\d{4})-(\d{2})-(\d{2})/.exec(value))) {
    // date
    isInvalid = !parseInt(match[1]) || !parseInt(match[2]) || !parseInt(match[3]);
  } else {
    // others
    isInvalid = value === undefined || value === '';
  }
  return isInvalid;
});

Formsy.addValidationRule('minLengthArray', function (values, value, minLength) {
  return Array.isArray(value) && value.length >= parseInt(minLength);
});

Formsy.addValidationRule('isValidDate', function (values, value) {
  return isValidDate(parseISO(value));
});

Formsy.addValidationRule('isBeforePeriod', function (values, value, period) {
  const beforeDate = startOfDay(addDuration(new Date(), '-' + period));
  return isBefore(parseISO(value), beforeDate);
});

Formsy.addValidationRule('isAlphanumericOrSpace', function (values, value) {
  return /^[0-9A-Z ]+$/i.test(value);
});

Formsy.addValidationRule('isAlphanumericOrDash', function (values, value) {
  return /^[0-9A-Z-]+$/i.test(value);
});

Formsy.addValidationRule('isInternationalZipcode', function (values, value) {
  return /^[0-9A-Z -]+$/i.test(value);
});

Formsy.addValidationRule('isIBAN', function (values, value) {
  return /^[0-9A-Z]{5,34}$/.test(value.trim());
});

Formsy.addValidationRule('isBIC', function (values, value) {
  return /^[0-9A-Z]{8,11}$/.test(value.trim());
});

export default AbstractFormComponent;
