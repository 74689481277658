import React, { FC } from 'react';

import { Select, Button } from '../../../../atoms';

import { AboBenefitSubType, AboRoute, AboWizardActionType, IPageProps } from '../../types';

import PageLayout from '../PageLayout';
import { _ } from '../../../../util/translate';

const AboRedeemPageVXPagesCredits: FC<IPageProps> = ({ dispatch, state }) => {
  const handleBackClick = () => {
    dispatch({ type: AboWizardActionType.GoBack, payload: {} });
  };

  const handleContinueClick = () => {
    dispatch({ type: AboWizardActionType.GoToRoute, payload: { route: AboRoute.AboDetailsPage } });
  };

  const canContinue = () => {
    return state.aboRedeemDetails.type && state.aboRedeemDetails.data.amount;
  };

  const handleRedeemTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: AboWizardActionType.SetAboRedeemDetails,
      payload: {
        aboRedeemDetails: {
          type: event.target.value as AboBenefitSubType,
          data: { amount: '' },
        },
      },
    });
  };

  const handleRedeemDetailsDataChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: AboWizardActionType.SetAboRedeemDetails,
      payload: {
        aboRedeemDetails: {
          type: state.aboRedeemDetails.type,
          data: { amount: event.target.value },
        },
      },
    });
  };

  const optionsSubscriptionType = [
    { label: _('benefits:abo.vxpagescredits.options.creditsFlat'), value: AboBenefitSubType.Flat },
    { label: _('benefits:abo.vxpagescredits.options.creditsPercent'), value: AboBenefitSubType.Percent }, // prettier-ignore
  ];

  const optionsEnterPercentage = new Array(100 / 5).fill(5).map((five, i) => {
    const value = String(five * (i + 1));
    return { value, label: `${value} %` };
  });

  return (
    <PageLayout
      title={_('benefits:abo.vxpagescredits.title')}
      subtitle={_('benefits:abo.vxpagescredits.subtitle')}
      footer={() => (
        <section css={{ justifyContent: 'space-between', padding: 32 }}>
          <Button theme="border-gray" onClick={handleBackClick} css={{ alignItems: 'center' }}>
            <span className="icon-chevron-left" css={{ fontSize: 8, marginRight: 8 }} />
            <span>{_('benefits:texts.back')}</span>
          </Button>
          <Button
            theme={canContinue() ? 'blue' : 'gray'}
            onClick={handleContinueClick}
            disabled={!canContinue()}
            css={{ alignItems: 'center' }}
          >
            <span>{_('benefits:texts.continue')}</span>
            <span className="icon-chevron-right" css={{ fontSize: 8, marginLeft: 8 }} />
          </Button>
        </section>
      )}
    >
      <section css={{ flexDirection: 'column' }}>
        <label css={{ flexDirection: 'column' }}>
          <span css={{ fontSize: 14, margin: '0 0 2px 2px' }}>
            {_('benefits:abo.texts.subscriptionType')}
          </span>

          <Select
            placeholder={_('common:dropdown.placeholder.pleaseSelect')}
            options={optionsSubscriptionType}
            onChange={handleRedeemTypeChange}
            value={state.aboRedeemDetails.type}
            css={{ width: 448 }}
          />
        </label>

        {state.aboRedeemDetails.type === AboBenefitSubType.Flat && (
          <label css={{ flexDirection: 'column', marginTop: 16 }}>
            <span css={{ fontSize: 14, margin: '0 0 2px 2px' }}>
              {_('benefits:abo.texts.enterQuantity')}
            </span>
            <div css={{ alignItems: 'center' }}>
              <Select
                placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                options={new Array(50000 / 500)
                  .fill(500)
                  .map((five, i) => ({ value: String(five * (i + 1)) }))}
                onChange={handleRedeemDetailsDataChange}
                value={state.aboRedeemDetails.data.amount}
                css={{ width: 190 }}
              />

              <p css={{ marginLeft: 8 }}>{_('benefits:abo.vxpagescredits.texts.flat')}</p>
            </div>
          </label>
        )}

        {state.aboRedeemDetails.type === AboBenefitSubType.Percent && (
          <label css={{ flexDirection: 'column', marginTop: 16 }}>
            <span css={{ fontSize: 14, margin: '0 0 2px 2px' }}>
              {_('benefits:abo.texts.enterPercentage')}
            </span>

            <div css={{ alignItems: 'center' }}>
              <Select
                placeholder={_('common:dropdown.placeholder.pleaseSelect')}
                options={optionsEnterPercentage}
                onChange={handleRedeemDetailsDataChange}
                value={state.aboRedeemDetails.data.amount}
                css={{ width: 190 }}
              />

              <p css={{ marginLeft: 8 }}>{_('benefits:abo.vxpagescredits.texts.percentage')}</p>
            </div>
          </label>
        )}
      </section>
    </PageLayout>
  );
};

export default AboRedeemPageVXPagesCredits;
