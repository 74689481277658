import { useEffect } from 'react';
import { ActionEnum, AppStateDispatch } from '../../../util/AppState';
import { getBestMatchForLanguage } from '../../../util/lang';

const useOnSuccessfulAuthAppStateEffects = (
  dispatch: AppStateDispatch,
  authToken: string | undefined | null,
  signupLanguage: string | undefined | null
) => {
  useEffect(() => {
    if (signupLanguage) {
      // Set the language even if it matches the current language from the appState as it triggers setting the cookie
      dispatch({ type: ActionEnum.setLang, lang: getBestMatchForLanguage(signupLanguage) });
    }
  }, [signupLanguage]);

  useEffect(() => {
    if (authToken) {
      dispatch({ type: ActionEnum.setAuthToken, authToken });
    }
  }, [authToken]);
};

export default useOnSuccessfulAuthAppStateEffects;
