import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AbstractComponent from '../AbstractComponent';

class Notice extends AbstractComponent {
  renderLink() {
    if (this.props.linkname) return null;

    if (this.props.link) {
      return (
        <Link class="link" to={this.props.link}>
          {this.props.linkname}
        </Link>
      );
    } else if (this.props.href) {
      return (
        <a className="link" href={this.props.href}>
          {this.props.linkname}
        </a>
      );
    } else if (this.props.onClick) {
      return (
        <div className="link" onClick={this.props.onClick}>
          {this.props.linkname}
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className={`notice-std ${this.props.className}`}>
        <div>
          <div className="notificationbox">
            <div className={`${this.props.icon ? this.props.icon : ''} iconbox`} />
            <div className="textbox">{this.props.text}</div>

            {this.renderLink()}
          </div>

          {this.props.children && (
            <div className="childrenbox">
              <div>{this.props.children}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Notice.propTypes = {
  text: PropTypes.string,
  linkname: PropTypes.string,
  link: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

Notice.defaultProps = {
  text: '',
  linkname: '',
  link: '',
  href: '',
  onClick: null,
};

export default Notice;
export { Notice };
