import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import AbstractComponent from '../AbstractComponent';

class Modalbox extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.modalRoot = null;
    this.el = document.createElement('div');
    this.el.id = 'wrappermodal';
    if (typeof props.zIndex === 'number') this.el.style.zIndex = props.zIndex;
  }

  componentDidMount() {
    if (this.props.state === true) {
      this.createModalBox();
    }
  }

  componentDidUpdate() {
    if (typeof this.props.zIndex === 'number') this.el.style.zIndex = this.props.zIndex;
    if (this.props.state === true) {
      this.createModalBox();
    } else {
      this.destroyModalBox();
    }
  }

  componentWillUnmount() {
    this.destroyModalBox();
  }

  createModalBox() {
    if (!this.modalRoot) {
      this.modalRoot = document.getElementById('wrapper');
      this.modalRoot.insertAdjacentElement('afterbegin', this.el);

      if (this.props.onKeyPressESC) {
        document.addEventListener('keydown', this.handleKeyPress);
      }
    }
  }

  destroyModalBox() {
    if (this.modalRoot) {
      if (this.props.onKeyPressESC) {
        document.removeEventListener('keydown', this.handleKeyPress);
      }

      this.props.onClose();
      this.modalRoot.removeChild(this.el);
      this.modalRoot = null;
    }
  }

  handleKeyPress(e) {
    if (this.props.noCloseOnESC) return;
    if (e.keyCode === 27 || e.code === 'Escape') {
      if (this.props.onKeyPressESC) {
        this.props.onKeyPressESC();
      }
      this.destroyModalBox();
    }
  }

  render() {
    const { state, name, className, children } = this.props;

    return state === true
      ? ReactDOM.createPortal(
          <div id={name} className={`modalgrid ${className}`}>
            <div className="modalgrid__row min-height--0">{children}</div>
          </div>,
          this.el
        )
      : null;
  }
}

Modalbox.propTypes = {
  zIndex: PropTypes.number,
  name: PropTypes.string,
  onClose: PropTypes.func,
  onKeyPressESC: PropTypes.func,
  className: PropTypes.string,
  noCloseOnESC: PropTypes.bool,
};

Modalbox.defaultProps = {
  onClose: () => {},
  onKeyPressESC: () => {},
  className: '',
  noCloseOnESC: false,
};

export const modalboxMargin = 32; // Mirrored from the .modalgrid css class -> if you change one, change both

export default Modalbox;
export { Modalbox };
