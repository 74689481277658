import {
  WHITE_2,
  BLACK,
  BLUE,
  BLUE_HOVER,
  GREEN,
  GREEN_HOVER,
  RED,
  RED_HOVER,
  WHITE,
  YELLOW,
  GRAY_5,
  GRAY_4,
  GRAY_2,
  DARK_GRAY,
} from '../../camtool-styles';
import createPalette, { Palette } from '@material-ui/core/styles/createPalette';

const palette: Palette = createPalette({
  primary: { main: BLUE, light: BLUE_HOVER, contrastText: WHITE },
  secondary: { main: GREEN, light: GREEN_HOVER, contrastText: WHITE },
  success: { main: GREEN, light: GREEN_HOVER, contrastText: BLACK },
  error: { main: RED, light: RED_HOVER, contrastText: WHITE },
  warning: { main: YELLOW, light: YELLOW, contrastText: BLACK },
  grey: {
    [100]: WHITE_2,
    [300]: GRAY_5,
    [400]: GRAY_4,
    [600]: GRAY_2,
    [800]: DARK_GRAY,
    [900]: BLACK,
  },
});

export default palette;
