import { Query } from '@apollo/react-components';
import { QUERY_CMS_ARTICLE } from '../../graphql/VXModels/queries';
import React from 'react';
import { useAppState } from '../../util/AppState';
import { withRouter } from 'react-router';
import styled from '@emotion/styled';

const Container = styled.div`
  padding: 12px 40px;
  > ul {
    padding-left: 24px;
  }
`;

const CmsArticleRevisionList = ({ id, location, ...markdownProps }) => {
  const revData = /#rev=(\d+)/.exec(location.hash);
  const rev = revData && revData[1] ? parseInt(revData[1]) : 0;
  const [appState, _] = useAppState();

  return (
    <Query query={QUERY_CMS_ARTICLE} variables={{ rev, lang: appState.lang, id }}>
      {({ data, loading, error }) => {
        if (loading) return false;
        if (error) return false;
        if (data) {
          const article = data.cms.article || { revisions: [] };
          const revisions = article.revisions.filter(r => r.rev !== article.revision.rev);
          if (revisions.length > 0)
            return (
              <Container>
                <ul>
                  {revisions.map(r => (
                    <li key={r.rev}>
                      <a href={`#rev=${r.rev}`}>{r.title}</a>
                    </li>
                  ))}
                </ul>
              </Container>
            );
        }
        return false;
      }}
    </Query>
  );
};

export default withRouter(CmsArticleRevisionList);
