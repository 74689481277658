import React, { FC } from 'react';
import Section from '../../../atoms/Grid/Section';

import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_SETTINGS_LOVENSE_TOY } from '../../../graphql/VXModels/queries';
import Spinner from '../../../components/Spinner/Spinner';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import { _ } from '../../../util/translate';

import { applyToy, toy, money } from '../../../atoms/Icon/libraries/svg';
import Background from './Assets/toy-request-background.jpg';
import ToyControlAdvantages from './ToyControlAdvantages';
import ToyControlRequirements from './ToyControlRequirements';
import ToyControlRequestButton from './ToyControlRequestButton';
import styled from '@emotion/styled';
import ThreeBadgesBanner from '../../../atoms/Banner/ThreeBadgesBanner';
import { BLACK_2 } from '../../../camtool-styles';
import { LovenseToyStatusEnum } from '../../../graphql/VXModels/types';

const SpinnerContainer = styled.div`
  position: relative;
  min-height: 150px;
  flex-grow: 1;
`;

const ToyControl: FC = () => {
  const { loading, error, data } = useQuery(QUERY_MODEL_SETTINGS_LOVENSE_TOY, {
    fetchPolicy: 'network-only',
  });
  const lovenseToyStatus = data?.model?.settings?.lovenseToySettings.status;
  const newRequestDate = data?.model?.settings?.lovenseToySettings.newRequestDate;

  const badges = {
    leftBadge: applyToy,
    leftBadgePrimaryText: _('settings:toycontrol.header.leftBadgeText'),
    centerBadge: toy,
    centerBadgePrimaryText: _('settings:toycontrol.header.centerBadgeText'),
    rightBadge: money,
    rightBadgePrimaryText: _('settings:toycontrol.header.rightBadgeText'),
  };

  return (
    <main className="grid marketing">
      <Section>
        {loading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : error ? (
          <EmptyContent icon="icon-warning-sign" title={_('common:error.generic')} />
        ) : (
          <>
            <ThreeBadgesBanner
              {...(lovenseToyStatus === LovenseToyStatusEnum.accepted ? null : badges)}
              background={Background}
              primaryHeadline={_('settings:toycontrol.header.headline')}
              secondaryHeadline={_('settings:toycontrol.header.text')}
              primaryThemeColor={BLACK_2}
              secondaryThemeColor={BLACK_2}
              iconColor={BLACK_2}
            />
            <ToyControlAdvantages lovenseToyStatus={lovenseToyStatus} />
            <ToyControlRequirements />
            <ToyControlRequestButton
              lovenseToyStatus={lovenseToyStatus}
              newRequestDate={newRequestDate}
            />
          </>
        )}
      </Section>
    </main>
  );
};

export default ToyControl;
