import gql from 'graphql-tag';

export const QUERY_WIDGET_BULLETTEXTLIST = gql`
  query VXM_CL_QUERY_WIDGET_BULLETTEXTLIST($actorId: Int, $language: String!) {
    widget(actorId: $actorId) {
      bulletTextList(language: $language) {
        bulletTexts {
          order
          text
          language
        }
      }
    }
  }
`;

export const QUERY_WIDGET_HOSTNAME = gql`
  query VXM_CL_QUERY_WIDGET_HOSTNAME($actorId: Int) {
    widget(actorId: $actorId) {
      hostname
    }
  }
`;
