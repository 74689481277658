import React, { FC } from 'react';
import { Spinner, T } from '../../../components';
import { Main, Section } from '../../../atoms';
import NavSubService0900 from '../NavSubService0900';
import PhoneCarrouselDescription from './PhoneCarrouselDescription';
import { _ } from '../../../util/translate';
import { QUERY_MODEL_SERVICE0900 } from '../../../graphql/VXModels/queries';
import { useQuery } from '@apollo/react-hooks';
import GenericEmptyContent from '../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Service0900PhoneCarouselNumber } from '../../../graphql/VXModels/types';
import { RequestWrapper } from '../PhoneSex/styles';
import UneditablePhoneNumbers from './UneditablePhoneNumbers';
import EditablePhoneNumbers from './EditablePhoneNumberFormik';
import { MAX_NO_OF_NUMBERS } from './utils';

const PhouneCarrousel: FC = () => {
  const { loading, error, data } = useQuery(QUERY_MODEL_SERVICE0900);
  const phoneCarrousel = data?.model?.service0900?.phoneCarousel;

  return (
    <Main isMobileEnabled>
      <NavSubService0900 />

      <Section title={_('service0900:carousel.sectionTitle')}>
        <div css={{ flexDirection: 'column' }}>
          <PhoneCarrouselDescription />

          {loading ? (
            <Spinner />
          ) : error ? (
            <GenericEmptyContent />
          ) : (
            <RequestWrapper css={{ margin: 0 }}>
              <div css={{ flexDirection: 'column' }}>
                <h2>
                  <T _={'service0900:carousel.activateNumberTitle'} />
                </h2>

                <div
                  css={{
                    flexDirection: 'column',
                    marginTop: 24,
                    alignItems: 'flex-start',
                  }}
                >
                  {phoneCarrousel?.targetNumbers?.map(
                    (item: Service0900PhoneCarouselNumber, index: number) =>
                      index < MAX_NO_OF_NUMBERS ? (
                        <UneditablePhoneNumbers
                          targetNumber={item.fullPhoneNumber}
                          index={index}
                          key={item.fullPhoneNumber}
                        />
                      ) : null
                  )}
                  {phoneCarrousel.targetNumbers.length < MAX_NO_OF_NUMBERS && (
                    <EditablePhoneNumbers
                      phoneCarrouselLength={phoneCarrousel?.targetNumbers?.length}
                    />
                  )}
                </div>
              </div>
            </RequestWrapper>
          )}
        </div>
      </Section>
    </Main>
  );
};

export default PhouneCarrousel;
