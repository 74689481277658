import React, { FC } from 'react';
import styled from '@emotion/styled';
import ApolloClient from 'apollo-client';
import { _ } from '../../../../util/translate';
import useSubmit from './useSubmit';
import NewButton from '../../../../atoms/Button/NewButton';

interface Props {
  apolloClientAbo: ApolloClient<Record<string, never>>;
  cancelSubsChecked: boolean;
  deactivateChecked: boolean;
  onClose: () => void;
}

const Row = styled.div`
  padding-top: 32px;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

const ButtonRow: FC<Props> = ({
  apolloClientAbo,
  cancelSubsChecked,
  deactivateChecked,
  onClose,
}) => {
  const atLeastOneCheckboxSelected = cancelSubsChecked || deactivateChecked;

  const { isSubmitting, submit } = useSubmit(
    apolloClientAbo,
    cancelSubsChecked,
    deactivateChecked,
    onClose
  );

  return (
    <Row>
      <NewButton
        onClick={submit}
        loading={isSubmitting}
        disabled={!atLeastOneCheckboxSelected || isSubmitting}
      >
        {cancelSubsChecked
          ? deactivateChecked
            ? _('benefits:abo.archive.detailsModal.irreversiblyCancelAndDisable')
            : _('benefits:abo.archive.detailsModal.irreversiblyCancel')
          : deactivateChecked
          ? _('benefits:abo.archive.detailsModal.irreversiblyDisable')
          : _('common:button.save')}
      </NewButton>

      <NewButton variant={'text'} onClick={onClose}>
        {_('common:button.cancel')}
      </NewButton>
    </Row>
  );
};

export default ButtonRow;
