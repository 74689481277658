import React, { FC, useEffect } from 'react';
import { _ } from '../../../util/translate';
import GenericModalBoxLayout from '../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { ModalWrapper } from './styles';

interface Props {
  onClose: () => void;
}

const PhoneSexSuccessModal: FC<Props> = ({ onClose }) => {
  useEffect(() => {
    const timeOut = setTimeout(onClose, 5000);
    return (): void => clearTimeout(timeOut);
  }, []);

  return (
    <GenericModalBoxLayout name="phone-success" maxWidth={700} onClose={onClose}>
      <ModalWrapper>
        <div css={{ flexDirection: 'column' }}>
          <h2 css={{ textAlign: 'center', marginBottom: '10px', fontSize: '20px' }}>
            {_('modalbox:phoneConfirmation.confirmationScreen.instructionsTitle')}
          </h2>
          <p css={{ maxWidth: '280px', fontSize: '16px' }}>
            {_('service0900:PhoneSex.modal.success.text')}
          </p>
        </div>
      </ModalWrapper>
    </GenericModalBoxLayout>
  );
};

export default PhoneSexSuccessModal;
