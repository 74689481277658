import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '190px',
      },
    },
    content: {
      textAlign: 'left',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
      },
    },
  })
);

const DetailsListItem: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography className={classes.content} variant="subtitle1" color="inherit">
        {children}
      </Typography>
    </Box>
  );
};

export default DetailsListItem;
