import React, { FC } from 'react';
import styled from '@emotion/styled';
import { User } from '../../../../graphql/VXServicesTelegram/types';
import OnlineStatus from './OnlineStatus';
import OfflineStatus from './OfflineStatus';
import BlockedStatus from './BlockedStatus';
import { BREAKPOINT_PHONE, DARK_GRAY } from '../../../../camtool-styles';
import { Box } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { makeStyles, createStyles } from '@material-ui/core';
import { Crown } from '../../../../atoms/Icon';
import IconBase from '../../../../atoms/Icon/IconBase';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: '16px',
    },
  })
);

const Wrapper = styled.div`
  flex-direction: column;
  width: 100%;
  margin-left: 32px;
  grid-gap: 5px;
  justify-content: center;
  ${BREAKPOINT_PHONE} {
    margin-left: 0px;
  }
`;

interface IProps {
  selectedUser: User;
}

const UserPlate: FC<IProps> = ({ selectedUser }) => {
  const classes = useStyles();

  return (
    <Wrapper>
      <Box display="flex">
        <span css={{ fontWeight: 'bold', fontSize: '16px', color: DARK_GRAY }}>
          {`${selectedUser.firstName || ''} ${selectedUser.lastName || ''}`.trim()}
        </span>
        <Box ml={0.5} display="flex" gridGap={'3px'}>
          {selectedUser?.premium && (
            <IconBase iconElement={<Crown />} classes={classes} viewBox="0 0 22.21 24" />
          )}
          {selectedUser?.tippingAmount > 0 && <MonetizationOnIcon fontSize="small" />}
        </Box>
      </Box>
      {selectedUser.blocked ? (
        <BlockedStatus active={false} />
      ) : selectedUser.online ? (
        <OnlineStatus active={false} blocked={selectedUser.blocked} />
      ) : (
        <OfflineStatus active={false} onlineUntil={selectedUser.onlineUntil} />
      )}
    </Wrapper>
  );
};

export default UserPlate;
