import React, { FC } from 'react';
import TelegramIcon from '@material-ui/icons/Telegram';
import { BroadcastSource } from '../../types';
import { Avatar, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WHITE } from '../../../../camtool-styles';
import VisitxIcon from '../../../../atoms/Icon/VisitxIcon';
import IconBase from '../../../../atoms/Icon/IconBase';

interface SourceIconProps {
  source: BroadcastSource;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    telegrambot: {
      backgroundColor: '#2098ca', // telegram blue
      '& .MuiSvgIcon-root': {
        color: WHITE,
      },
    },
    vxmodels: {
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.contrastText,
      },
    },
  })
);

const SourceIcon: FC<SourceIconProps> = ({ source }) => {
  const { vxmodels, telegrambot } = useStyles();
  switch (source) {
    case BroadcastSource.vxmodels:
      return (
        <Avatar classes={{ root: vxmodels }}>
          <IconBase fontSize="32px" iconElement={<VisitxIcon />} />
        </Avatar>
      );
    case BroadcastSource.telegrambot:
      return (
        <Avatar classes={{ root: telegrambot }}>
          <TelegramIcon />
        </Avatar>
      );
    default:
      return <Avatar></Avatar>;
  }
};

export default SourceIcon;
