import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import {
  MailingRecipientsGroupsEnum,
  MailingRecipientsGroupsFiltersEnum,
  MailingTypeEnum,
  Query,
} from '../../../graphql/VXModels/types';
import { useVXModelsClient } from '../../../graphql/VXModels';
import { QueryResult } from '@apollo/react-common';
import { QUERY_MODEL_MAILINGS_RECIPIENTS_COUNT } from '../graphql/queries';

type Variables = {
  type: MailingTypeEnum;
  recipientsGroup: MailingRecipientsGroupsEnum;
  filter: MailingRecipientsGroupsFiltersEnum[];
};

type Result = Omit<QueryResult<Query, Variables>, 'data'> & {
  count: number | undefined;
};

export const useMailingsRecipientsCount = (
  type: MailingTypeEnum,
  recipientsGroup: MailingRecipientsGroupsEnum,
  filter: MailingRecipientsGroupsFiltersEnum[],
  options?: QueryHookOptions<Query, Variables>
): Result => {
  const client = useVXModelsClient();
  const { data, ...result } = useQuery<Query, Variables>(QUERY_MODEL_MAILINGS_RECIPIENTS_COUNT, {
    ...options,
    client,
    variables: { type, recipientsGroup, filter },
  });

  return { count: data?.model?.mailings?.recipients?.count, ...result };
};
