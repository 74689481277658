import React from 'react';

import { MailingStatusEnum } from '../../../graphql/VXModels/types';
import { format } from '../../../atoms/I18n/DateTimeFormatter';
import InfoBox, { InfoBoxTypeEnum } from '../../../atoms/InfoBox';
import { _ } from '../../../util/translate';

interface IProps {
  status: MailingStatusEnum;
  modified: string;
  mentionsUserName: boolean;
}

const StatusBoxDetailed = ({ modified, status, mentionsUserName }: IProps) => (
  <>
    {[MailingStatusEnum.SHIPMENT_PENDING, MailingStatusEnum.SHIPMENT_PREPARED].includes(status) && (
      <InfoBox type={InfoBoxTypeEnum.WARNING}>
        <p>
          {_(`mailings:statusBox.${MailingStatusEnum.SHIPMENT_PENDING.toLowerCase()}`, {
            sprintf: [modified ? format(modified) : ''],
          })}
        </p>
      </InfoBox>
    )}

    {status === MailingStatusEnum.SHIPMENT_PROGRESS && (
      <InfoBox type={InfoBoxTypeEnum.NOTICE}>
        <p>{_(`mailings:statusBox.${MailingStatusEnum.SHIPMENT_PROGRESS.toLowerCase()}`)}</p>
      </InfoBox>
    )}

    {status === MailingStatusEnum.SHIPMENT_DONE && (
      <InfoBox type={InfoBoxTypeEnum.INFO}>
        <p>{_(`mailings:statusBox.${MailingStatusEnum.SHIPMENT_DONE.toLowerCase()}`)}</p>
      </InfoBox>
    )}

    {status === MailingStatusEnum.SHIPMENT_FAILED &&
      (mentionsUserName ? (
        <InfoBox type={InfoBoxTypeEnum.ERROR}>
          <p>{_('mailings:statusBox.mentionsUserName')}</p>
        </InfoBox>
      ) : (
        <InfoBox type={InfoBoxTypeEnum.ERROR}>
          <p>{_(`mailings:statusBox.${MailingStatusEnum.SHIPMENT_FAILED.toLowerCase()}`)}</p>
        </InfoBox>
      ))}
  </>
);

export default StatusBoxDetailed;
