import styled from '@emotion/styled';
import { leftMenuWidth, leftMenuWidthTablet } from '../../components/LeftMenu/components/Root';
import { BREAKPOINT_TABLET } from '../../camtool-styles';

const TOP_BAR_HEIGHT = 48;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;

  padding-top: ${TOP_BAR_HEIGHT}px;
  padding-left: ${leftMenuWidth}px;

  ${BREAKPOINT_TABLET} {
    padding-left: ${leftMenuWidthTablet}px;
  }
`;

export default ContentContainer;
