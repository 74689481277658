import React from 'react';
import styled from '@emotion/styled';
import { T } from '../../../components';
import { withRouter } from 'react-router';
import { _ } from '../../../util/translate';
import NewButton from '../../../atoms/Button/NewButton';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useAppState } from '../../../util/AppState';
import { NEW_MESSENGER_URL, NEW_MESSENGER_WINDOW_NAME } from '../../../config';
import { useUserData } from '../../../util/UserData';

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

interface IProps {
  loading: boolean;
}

const welcomeBackStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
  },
});

const WelcomeBack = () => {
  const [{ authToken }] = useAppState();
  const userData = useUserData();
  const isVerified = userData?.model?.account.isVerified;
  const handleClick = () =>
    window.open(`${NEW_MESSENGER_URL}?foreignSID=${authToken}`, `${NEW_MESSENGER_WINDOW_NAME}`);
  const classes = welcomeBackStyles();

  return (
    <Container>
      <h3 css={{ fontSize: 18, textAlign: 'center', padding: '0 45px' }}>
        <T _={'dashboard:app.welcomeBack.text'} />
      </h3>
      <div
        style={{
          fontSize: 80,
          color: '#888',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <span className="icon-webcam" />
      </div>
      <Box className={classes.buttonContainer}>
        <NewButton disabled={!isVerified} color={'secondary'} onClick={handleClick}>
          {_('dashboard:app.welcomeBack.buttonText')}
        </NewButton>
        {!isVerified && <Typography>{_('common:text.notVerified')}</Typography>}
      </Box>
    </Container>
  );
};

WelcomeBack.defaultProps = {
  loading: false,
};

export default withRouter(WelcomeBack);
