import React, { FC } from 'react';
import { Button, ButtonProps, createStyles, PropTypes, Theme, Typography } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import ButtonSpinner from '../Icon/ButtonSpinner';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon';
import styled from '@emotion/styled';
import { getPaletteColor } from '../../util/getPaletteColor';
import { ValidationType } from '../../util/BuildValidationObject';

type CustomColor = 'warning' | 'error' | 'beta';

interface NewButtonProps extends Omit<ButtonProps, 'color'> {
  /**
   * Color could be:
   * undefined (primary)
   * primary, secondary, warning
   * error, beta, default (grey)
   */
  color?: 'inherit' | 'default' | PropTypes.Color | CustomColor | undefined;

  /**
   * Position of the spinner
   */
  iconPosition?: 'start' | 'end';

  /**
   * The icon to display
   * You can also use startIcon and endIcon,
   * but it it will override the loading
   * spinner. To display an icon in normal
   * state and a spinner and disabled in
   * loading state do:
   * loading={loading} disabled={loading} icon={<Icon ... />} iconPosition={'start'|'end'}
   */
  icon?: JSX.Element | OverridableComponent<SvgIconTypeMap> | undefined;

  /**
   * Will display a loading spinner if true,
   * But you can also display other icons with
   * startIcon={<Icon />} and endIcon={<Icon />}
   *
   * Setting loading={true} will disable pointer
   * events for the button (but it will keep its color)
   *
   * You could set loading={loading} disabled={loading}
   * to disable it while loading is true
   */
  loading?: boolean;
  validation?: ValidationType;
}

const ValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  align-items: center;
`;

const ValidationMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 9px;
  justify-content: center;
  font-style: italic;

  > svg {
    width: 16px;
    height: 16px;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contained: ({ color }: NewButtonProps) => {
      const paletteColor = getPaletteColor(theme, color);

      return {
        backgroundColor: paletteColor?.main,
        color: paletteColor?.contrastText,
        '&:hover': {
          backgroundColor: paletteColor?.light,
        },
      };
    },
    outlined: ({ color }: NewButtonProps) => {
      const paletteColor = getPaletteColor(theme, color);

      return {
        color: paletteColor?.main,
        border: `1px solid ${paletteColor?.main}`,
        '&:hover': {
          border: `1px solid ${paletteColor?.light}`,
          backgroundColor: paletteColor?.light
            ? alpha(paletteColor?.light, theme.palette.action.hoverOpacity)
            : 'inherit',
        },
      };
    },
    text: ({ color }: NewButtonProps) => {
      const paletteColor = getPaletteColor(theme, color);

      return {
        color: paletteColor?.main,
        '&:hover': {
          backgroundColor: paletteColor?.light
            ? alpha(paletteColor?.light, theme.palette.action.hoverOpacity)
            : 'inherit',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      };
    },
    isLoading: ({ loading }: NewButtonProps) => {
      return {
        pointerEvents: loading ? 'none' : 'auto',
      };
    },
    validationClass: ({ validation }: NewButtonProps) => {
      return { color: validation?.color.main };
    },
  })
);

const NewButton: FC<NewButtonProps> = ({
  color,
  loading = false,
  icon = undefined,
  iconPosition = 'start',
  validation = undefined,
  children,
  ...props
}) => {
  const { isLoading, validationClass, ...classes } = useStyles({ color, loading, validation });
  const isColor = color && ['warning', 'error', 'beta'].includes(color);
  const button = (
    <Button
      classes={isColor ? classes : undefined}
      className={isLoading}
      color={isColor ? 'default' : color ? (color as PropTypes.Color) : 'primary'}
      variant={props.variant ? props.variant : 'contained'}
      startIcon={iconPosition === 'start' ? loading ? <ButtonSpinner /> : icon : undefined}
      endIcon={iconPosition === 'end' ? loading ? <ButtonSpinner /> : icon : undefined}
      {...props}
    >
      {children}
    </Button>
  );

  return validation?.message ? (
    <ValidationContainer>
      {button}
      <ValidationMessageContainer className={validationClass}>
        {validation.icon && validation.icon}
        <Typography variant={'body1'}>{validation.message}</Typography>
      </ValidationMessageContainer>
    </ValidationContainer>
  ) : (
    <>{button}</>
  );
};

export default NewButton;
