export default function isEU(country) {
  return (
    [
      'AL',
      'AD',
      'AT',
      'BY',
      'BE',
      'BA',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FO',
      'FI',
      'FR',
      'DE',
      'GI',
      'GR',
      'HU',
      'IS',
      'IE',
      'IT',
      'LV',
      'LI',
      'LT',
      'LU',
      'MK',
      'MT',
      'MD',
      'MC',
      'NL',
      'NO',
      'PL',
      'PT',
      'RO',
      'RU',
      'SM',
      'RS',
      'SK',
      'SI',
      'ES',
      'SE',
      'CH',
      'UA',
      'GB',
      'VA',
      'RS',
      'IM',
      'RS',
      'ME',
    ].indexOf(country) > -1
  );
}
