import styled from '@emotion/styled';
import { Field, FieldProps } from 'formik';
import React, { FC, ReactElement } from 'react';
import { FileStorageSettings } from '../../../../graphql/VXModels/types';
import { Master } from '../../../../graphql/VXServicesTelegram/types';
import { MediaUploadInputLabelSize } from '../../../../molecules/FileUpload/MediaUploadInput';
import { PreviewVideoThumbnailIconSize } from '../../../../molecules/FileUpload/PreviewFile';
import { _ } from '../../../../util/translate';
import { FieldGenericUploadAttachment } from '../Form/Fields/Upload';
import { FormikUserLifeCycleSteps } from './types';

const StyledUploadField = styled(FieldGenericUploadAttachment)`
  height: 90px;
  width: 90px;
`;

interface Props {
  name: string;
  fileStorageSettings: FileStorageSettings;
  className?: string;
  index?: number;
  mimetype?: Master['allowedMimeTypes'];
}

const AutoMailFileUploadInput: FC<Props> = React.memo(
  ({ name, fileStorageSettings, className, index, mimetype }) => (
    <>
      <Field name={`autoMailList[${name}].text`}>
        {({ form: { errors } }: FieldProps<FormikUserLifeCycleSteps>): ReactElement => {
          // first check if we got a index from mother comp if not check if file attachment got error
          const hasError = errors?.autoMailList?.[index]?.file === 'image and video only';
          const mimetypesString = mimetype?.toString() || '';

          return (
            <>
              <StyledUploadField
                css={hasError ? { borderColor: 'red', color: 'red' } : ''}
                className={className}
                name={name}
                label={_('mailings:telegram.autoMail.addFile')}
                accept={mimetypesString}
                fileStorageSettings={fileStorageSettings}
                labelSize={MediaUploadInputLabelSize.sm}
                videoThumbnailIconSize={PreviewVideoThumbnailIconSize.sm}
              />
              {hasError && (
                <div css={{ width: '100%', alignItems: 'center', color: 'red', marginTop: '10px' }}>
                  {_('UploadManager:errorMessage.pictureInvalidFilenameExtension.text')}
                </div>
              )}
            </>
          );
        }}
      </Field>
    </>
  )
);

AutoMailFileUploadInput.displayName = 'AutoMailFileUploadInput';

export default AutoMailFileUploadInput;
