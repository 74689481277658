import React, { FC, ReactElement, SetStateAction } from 'react';
import { _ } from '../../../../../util/translate';
import CountryCodeSelect from './CountryCodeSelect';
import PhoneNumberInput from './PhoneNumberInput';
import RequestCodeButton from './RequestCodeButton';
import { FormikValues, Formik, Form, FormikActions } from 'formik';
import {
  PhoneVerificationStatusEnum,
  Service0900Country,
} from '../../../../../graphql/VXModels/types';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_SERVICE0900_SAVE_TARGETNUMBER } from '../../../../../graphql/VXModels/mutations';
import { Service0900TargetNumberRequestCode, Service0900RequestFormikValues } from '../../types';
import { getRequestValidationSchema, onRequestSubmit } from '../../utils';
import { RED } from '../../../../../camtool-styles';

export interface Props {
  targetNumber: Service0900TargetNumberRequestCode;
  allowedCountries: Service0900Country[];
  onRequestSuccess: (step: PhoneVerificationStatusEnum) => void;
  setTargetNumber: (arg: SetStateAction<Service0900TargetNumberRequestCode>) => void;
}

const RequestCode: FC<Props> = ({
  targetNumber,
  allowedCountries,
  onRequestSuccess,
  setTargetNumber,
}) => {
  const initialRequestData: Service0900RequestFormikValues = {
    targetNumber,
  };

  const [savePhoneNumber, { loading, error }] = useMutation(
    MUTATION_MODEL_SERVICE0900_SAVE_TARGETNUMBER
  );

  return (
    <>
      <small css={{ color: RED, marginBottom: '30px' }}>
        {_('service0900:PhoneSex.modal.general.warning')}
      </small>
      <Formik
        initialValues={initialRequestData}
        validationSchema={getRequestValidationSchema}
        enableReinitialize
        onSubmit={(
          values,
          {
            setStatus,
            setFieldError,
            setSubmitting,
          }: FormikValues & FormikActions<Service0900RequestFormikValues>
        ): Promise<void> =>
          onRequestSubmit(
            values,
            setStatus,
            setFieldError,
            setSubmitting,
            onRequestSuccess,
            savePhoneNumber,
            setTargetNumber
          )
        }
      >
        {({ isSubmitting, errors }): ReactElement => (
          <Form css={{ flexDirection: 'column' }}>
            <div css={{ flexDirection: 'column' }}>
              <div css={{ width: '100%', justifyContent: 'flex-start' }}>
                <CountryCodeSelect allowedCountries={allowedCountries} />
                <PhoneNumberInput css={{ marginLeft: '10px', flex: '1 0 auto' }} />
              </div>
              <RequestCodeButton
                loading={loading}
                hasError={!!error}
                disabled={isSubmitting || errors?.targetNumber?.phoneNumber}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RequestCode;
