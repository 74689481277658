export enum PreviewVideoThumbnailIconSize {
  md = 'md',
  sm = 'sm',
}

export interface PreviewVideoOptions {
  thumbnailOnly?: boolean;
  thumbnailIconSize?: PreviewVideoThumbnailIconSize;
  autoPlay?: boolean;
}
