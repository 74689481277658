import { Translation } from 'vanilla-cookieconsent';
import { ConsentCategoryEnum } from './types';

const urlDataProtection = '/camtool/legal/gdpr';
const en: Translation = {
  consentModal: {
    title: 'We use cookies!',
    description: `We use cookies to make your experience on our website as pleasant as possible and to improve our product. For more information on our data collection practices, see our <a aria-label="Cookie policy" class="cc-link" href="${urlDataProtection}" target="_blank">Data protection</a><br/><br/>You can decide which cookies you allow or reject. Click on "Settings" to get a list of all categories. By clicking on "Accept all" you agree to the use of cookies.`,
    acceptAllBtn: 'Accept all',
    showPreferencesBtn: 'Settings',
  },
  preferencesModal: {
    title: 'Cookie settings',
    acceptAllBtn: 'Accept all',
    acceptNecessaryBtn: 'Accept necessary',
    savePreferencesBtn: 'Save',
    closeIconLabel: 'Close window',
    sections: [
      {
        title: 'Using cookies',
        description: 'Our cookies fall into the following categories',
      },
      {
        title: 'Necessary cookies',
        description: 'These cookies are necessary for the website to function properly.',

        //this field will generate a toggle linked to the 'necessary' category
        linkedCategory: ConsentCategoryEnum.necessary,
      },
      {
        title: 'Functional cookies',
        description:
          'These cookies improve the user experience on our website by remembering your preferred settings, such as the country selection.',
        linkedCategory: ConsentCategoryEnum.functional,
      },
      {
        title: 'Analysis and optimization',
        description:
          'These cookies are used to analyze the use of our website and to test various optimizations so that our product continues to develop.',
        linkedCategory: ConsentCategoryEnum.analytics,
      },
      {
        title: 'More information',
        description: `For more information on data protection, read our <a aria-label="Cookie policy" class="cc-link" href="${urlDataProtection}" target="_blank">Data protection</a>.`,
      },
    ],
  },
};
export default en;
