import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { Box, TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { UserFilter } from '../../../../graphql/VXServicesTelegram/types';
import { makeStyles, createStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { GRAY_2 } from '../../../../camtool-styles';

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    input: {
      borderRadius: '20px',
    },
    iconButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
);

interface TelegramSearchFilterProps {
  userFilter: UserFilter;
  setUserFilter: React.Dispatch<React.SetStateAction<UserFilter>>;
}

const TelegramSearchFilter: FC<TelegramSearchFilterProps> = ({ userFilter, setUserFilter }) => {
  const classes = useStyles();

  return (
    <Box display="flex" width="100%">
      <TextField
        fullWidth
        placeholder={_('common:input.placeholder.search')}
        value={userFilter?.name}
        onChange={(e): void => {
          e.persist();
          setUserFilter((prevFilter) => ({ ...prevFilter, ...{ name: e?.target?.value } }));
        }}
        InputProps={{
          className: classes.textField,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon htmlColor={GRAY_2} />
            </InputAdornment>
          ),
          endAdornment: userFilter?.name ? (
            <InputAdornment position="end">
              <IconButton
                classes={{ root: classes.iconButton }}
                onClick={() => setUserFilter((prevFilter) => ({ ...prevFilter, ...{ name: '' } }))}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
          classes: { root: classes.input },
        }}
      />
    </Box>
  );
};

export default TelegramSearchFilter;
