import { useQuery } from '@apollo/react-hooks';
import {
  InstantMessagingAccount,
  InstantMessagingPlatformEnum,
  Query,
} from '../graphql/VXModels/types';
import { QUERY_MODEL_INSTANTMESSAGING_ACCOUNT } from '../graphql/VXModels/queries';
import { QueryResult } from '@apollo/react-common';

import { useVXModelsClient } from '../graphql/VXModels';

export type InstantMessagingAccountResult = Omit<QueryResult<Query>, 'data'> & {
  account: InstantMessagingAccount | undefined;
};

export const useModelInstantMessagingAccount = (
  platform: InstantMessagingPlatformEnum
): InstantMessagingAccountResult => {
  const client = useVXModelsClient();
  const { data, ...result } = useQuery<Query>(QUERY_MODEL_INSTANTMESSAGING_ACCOUNT, {
    client,
    variables: { platform },
  });

  return { account: data?.model?.instantMessaging?.account, ...result };
};
