import React, { FC, Fragment, ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import styled from '@emotion/styled';
import { _ } from '../../../../../util/translate';
import RadioField from '../../../../../atoms/FormFIeldsCtrl/Fields/RadioField';
import { BillingPeriod } from '../../../types';
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';

type Props = {
  isTelegram?: boolean;
};

const InputGroup = styled.div`
  flex: 1;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  & {
    label,
    button {
      margin-right: 16px;
      flex: 1;
    }

    label:last-child,
    button:last-child {
      margin-right: 0;
    }
  }
`;

const FieldBillingPeriod: FC<Props> = ({ isTelegram }) => {
  return (
    <FieldLayout
      label={_(`benefits:abo.detailsPage.billingPeriodLabel`)}
      info={_(`benefits:abo.detailsPage.billingPeriodInfo`)}
      error=""
      css={{ marginBottom: 24 }}
    >
      <Field
        name="billingPeriod"
        render={({ field }: FieldProps): ReactElement => (
          <Fragment>
            <InputGroup>
              <RadioField
                {...field}
                value={String(BillingPeriod.Every7Days)}
                text={_(`benefits:abo.detailsPage.day.${BillingPeriod.Every7Days}`)}
                checked={Number(field.value) === BillingPeriod.Every7Days}
              />

              <RadioField
                {...field}
                value={String(BillingPeriod.Every1Months)}
                text={_(`benefits:abo.detailsPage.day.${BillingPeriod.Every1Months}`)}
                checked={Number(field.value) === BillingPeriod.Every1Months}
              />
            </InputGroup>
          </Fragment>
        )}
      />
    </FieldLayout>
  );
};

export default FieldBillingPeriod;
