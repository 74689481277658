import { Main } from '../Layout/DarkCentered';
import Spinner from '../Spinner/Spinner';
import React from 'react';

const SplashScreen = () => (
  <Main className="spinner-container">
    <Spinner noBackground={true} size={'xl'} />
  </Main>
);

export default SplashScreen;
