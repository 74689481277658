import React, { FC, ReactElement } from 'react';
import { _ } from '../../../../../util/translate';

interface Props {
  from: string;
  to: string;
}

const getFormattedDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = String(date.getUTCFullYear());
  return `${day}.${month}.${year}`;
};

const ValidPeriodLabel: FC<Props> = ({ from, to }): ReactElement => {
  // empty 'from' and 'to' not possible anymore unless added by backend, apparently (?)
  if (!from && !to) return <span>{_('benefits:voucher.texts.permanent')}</span>;
  else if (from && to) return <span>{getFormattedDate(from)} - {getFormattedDate(to)}</span>; // prettier-ignore
  else return <span>{_('benefits:voucher.texts.from')} {getFormattedDate(from || to)}</span>;
};

export default ValidPeriodLabel;
