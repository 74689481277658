import React, { FC } from 'react';
import styled from '@emotion/styled';
import GenericModalBoxLayout from '../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { DARK_GRAY } from '../../../../camtool-styles';
import { Button } from '../../../../atoms';
import { _ } from '../../../../util/translate';
import Icon from '../../../../atoms/Icon/Icon';
import { faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 116px 41px 98px;
  color: ${DARK_GRAY};
`;

const Header = styled.h2`
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  margin-bottom: 9px;
`;

const Explanation = styled.p`
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.16px;
  font-weight: 300;
  margin-bottom: 50px;
`;

const ButtonRow = styled.div`
  justify-content: center;
`;

const ButtonIcon = styled(Icon)`
  margin-right: 9px;
`;

interface Props {
  onClose: () => void;
  onConfirmDelete: () => void;
}

const ModalConfirmDelete: FC<Props> = ({ onClose, onConfirmDelete }) => {
  // Stop event propagation to file upload label
  const stopEventPropagation = (e: React.MouseEvent): void => e.stopPropagation();

  const onConfirmClick = (): void => {
    onConfirmDelete();
    onClose();
  };

  return (
    <GenericModalBoxLayout
      name="file-deletion-confirmation"
      maxWidth={784}
      onClose={(): void => onClose()}
    >
      <Container onClick={stopEventPropagation}>
        <Header>{_('components:mediaUpload.modalConfirmDelete.header')}</Header>
        <Explanation>{_('components:mediaUpload.modalConfirmDelete.explanation')}</Explanation>
        <ButtonRow>
          <Button
            size="lg"
            theme="border-gray"
            onClick={(): void => onClose()}
            css={{ marginRight: 41 }}
          >
            <ButtonIcon icon={faTimes} size="15px" /> {_('common:button.cancel')}
          </Button>
          <Button size="lg" theme="blue" onClick={onConfirmClick}>
            <ButtonIcon icon={faTrashAlt} size="15px" />{' '}
            {_('components:mediaUpload.modalConfirmDelete.deleteFile')}
          </Button>
        </ButtonRow>
      </Container>
    </GenericModalBoxLayout>
  );
};

export default ModalConfirmDelete;
