import { Box, createStyles, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { GRAY_3, WHITE } from '../../../../camtool-styles';
import { EmptyContent } from '../../../../components';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';
import { MediaUploadInput } from '../../../../molecules/FileUpload/MediaUploadInput';
import { RESPONSIVE_UPLOAD_STYLES_INPUT_HEIGHT } from '../../../../molecules/FileUpload/withResponsiveUploadStyles';
import { _ } from '../../../../util/translate';
import { SectionLabel } from '../../../Mailings';

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      height: `${RESPONSIVE_UPLOAD_STYLES_INPUT_HEIGHT + 50}px`, // + 50 because of labels - making text and attachment grow to actual 200px
    },
    paper: {
      backgroundColor: 'transparent',
      height: '100%',
    },
  })
);

interface SectionTextAndAttachmentProps {
  broadcast: Pick<Broadcast, 'text' | 'file'>;
}

const SectionTextAndAttachment: FC<SectionTextAndAttachmentProps> = ({
  broadcast: { file, text },
}) => {
  const classes = useStyles();

  return (
    <Grid container item spacing={3} className={classes.grid}>
      <Grid item xs={8}>
        <SectionLabel>{_('telegram:BroadcastShow.text.label')}:</SectionLabel>
        <Paper className={classes.paper}>
          <Box p={1} height={'100%'}>
            {text}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <SectionLabel>{_('telegram:BroadcastShow.attachment.label')}:</SectionLabel>
        <Box height={'100%'}>
          {file?.url ? (
            // todo file.type and file.mimeType which one should we use? Fallback solution for audio is file type
            <MediaUploadInput
              currentFile={{
                url: file?.url,
                type: file?.type === 'audio' ? file?.type : file?.mimeType?.split('/')[0],
                previewUrl: file?.previewPictureUrl || undefined,
              }}
              disabled
              accept={'*'}
              onRemoveFile={() => undefined}
              onSelectFile={() => undefined}
              label={''}
              css={{ height: '100%', width: '100%' }}
            />
          ) : (
            <EmptyContent
              css={{
                height: '100%',
                backgroundColor: WHITE,
                borderRadius: 4,
                border: `1px solid ${GRAY_3}`,
              }}
              icon={'icon-remove-circle'}
              title={_('telegram:BroadcastShow.attachment.empty')}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SectionTextAndAttachment;
