import React, { FC, ReactNode } from 'react';
import { Field, FieldProps } from 'formik';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { WHITE_3 } from '../../../camtool-styles';
import { InputAdornmentIcon } from '../../../atoms';
import { InputSlider } from '../../MUI';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  markLabel: {
    display: 'none',
  },
  marked: {
    marginBottom: '0',
  },
  mark: {
    display: 'none',
  },
}));

const useStylesFormControl = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

type NewPriceRange = {
  value: number;
  label: number;
};

interface FieldSliderProps {
  name: string;
  min: number;
  max: number;
  step: number | null;
  label?: ReactNode;
  disabled?: boolean;
  initValue?: number;
  marks?: Array<NewPriceRange>;
}

const FieldSlider: FC<FieldSliderProps> = ({
  name,
  label,
  min,
  max,
  step,
  initValue,
  marks,
  disabled,
}: FieldSliderProps) => {
  const classes = useStyles();
  const formControlClasses = useStylesFormControl();
  const priceRangeArray = marks ? marks.map((item) => item.value) : [];
  const isMessengerChat = name === 'sliders.messengerUserPrice';
  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps): React.ReactNode => {
        const onChange = (newValue: number | number[]): void => {
          form.setFieldValue(field.name, newValue);
        };
        const onChangeInput = (newValue: number | number[]): void => {
          const result = priceRangeArray.reduce((prev, curr) =>
            Math.abs(curr - newValue) < Math.abs(prev - newValue) ? curr : prev
          );
          form.setFieldValue(field.name, result);
        };
        return (
          <FormControlLabel
            className={formControlClasses.root}
            control={
              <InputSlider
                inputProps={{
                  name: field.name,
                  defaultValue: initValue,
                  value: field.value,
                  onChange: isMessengerChat ? onChangeInput : onChange,
                  min: min,
                  max: max,
                  disabled: disabled,
                  InputProps: {
                    endAdornment: (
                      <InputAdornmentIcon>
                        <EuroSymbolIcon />
                      </InputAdornmentIcon>
                    ),
                  },
                }}
                sliderProps={{
                  name: field.name,
                  defaultValue: initValue,
                  disabled: disabled,
                  value: field.value,
                  onChange: onChange,
                  min: min,
                  max: max,
                  step: !marks ? step : null,
                  marks: marks,
                  classes: classes,
                }}
              />
            }
            label={label}
            style={{ backgroundColor: WHITE_3, marginLeft: -4, paddingRight: 4 }}
          />
        );
      }}
    />
  );
};

export default FieldSlider;
