import React, { FC } from 'react';
import { _ } from '../../../../../util/translate';
import { Button, Icon } from '../../../../../atoms';
import { bin } from '../../../../../atoms/Icon/libraries/glyph';
import { GRAY_3, WHITE } from '../../../../../camtool-styles';
import { useDisplayState } from '../../../../../hooks';
import { Box } from '@material-ui/core';
import AutoMailGridFormItemDeleteModal from './AutoMailGridFormItemDeleteModal';
import { Field, FieldProps } from 'formik';
import { MobileUserLifeCycleStep } from '../types';

interface AutoMailGridFormItemDeleteProps {
  isVip: boolean;
}

const AutoMailGridFormItemDelete: FC<AutoMailGridFormItemDeleteProps> = ({ isVip }) => {
  const { shouldShow, show, hide } = useDisplayState();

  return (
    <Box display="flex" justifyContent="center" color={GRAY_3} my={3}>
      <Field name="id">
        {({ field: { value } }: FieldProps<Pick<MobileUserLifeCycleStep, 'id'>>) => (
          <>
            <Button
              css={{ background: WHITE, borderColor: GRAY_3, width: '140px', height: '40px' }}
              theme={'border-gray'}
              onClick={show}
            >
              <Icon icon={bin} size="14px" css={{ marginRight: '6px', color: GRAY_3 }} />
              <span css={{ fontSize: '14px' }}>{_('common:button.delete')}</span>
            </Button>
            {shouldShow && (
              <AutoMailGridFormItemDeleteModal id={value} isVip={isVip} closeModal={hide} />
            )}
          </>
        )}
      </Field>
    </Box>
  );
};

export default AutoMailGridFormItemDelete;
