import { useVXServicesTelegramClient } from '../../../graphql';
import { Query } from '../../../graphql/VXServicesTelegram/types';
import { QueryResult } from '@apollo/react-common';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { QUERY_WIDGET_HOSTNAME } from '../graphql/queries';

export interface VipUrlArgs {
  actorId: number;
}

const useGetTelegramVipUrl = (
  actorId: number,
  options?: QueryHookOptions<Query, VipUrlArgs>
): QueryResult => {
  const client = useVXServicesTelegramClient();

  return useQuery<Query, VipUrlArgs>(QUERY_WIDGET_HOSTNAME, {
    ...options,
    client,
    variables: {
      actorId: actorId,
    },
  });
};

export default useGetTelegramVipUrl;
