import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import EventEmitter from 'events';
import ActionTypes from '../../util/constants/ActionTypes';

let CHANGE_EVENT = 'IncomeSourcesStore.change';

let storeData = {
  amountDetails: [],
  quantityDetails: [],
};

class IncomeSourcesStoreClass extends EventEmitter {
  emitChange() {
    this.emit(CHANGE_EVENT);
  }
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }
  get() {
    return storeData;
  }
}

const IncomeSourcesStore = new IncomeSourcesStoreClass();

AppDispatcher.register(function(payload) {
  switch (payload.type) {
    case ActionTypes.APPS_INCOMESOURCES_RECEIVE_DATA:
      storeData = update(storeData, { $merge: payload.data });
      IncomeSourcesStore.emitChange();
      break;
    default:
  }
});

export { IncomeSourcesStore };
