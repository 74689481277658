import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const BLUE = '#1f93ea;';
const GRAY_LIGHT = '#f2f2f2;';
const GRAY = '#888888;';
const GRAY_DARK = '#484848;';
const RED = '#f85237;';
const BLACK = '#3b3b3b;';

const Container = styled.section`
  flex-direction: column;
  align-items: center;
  ${props => props.className};
`;

const Icon = styled.div`
  &:hover {
    cursor: pointer;
    background-color: ${GRAY_DARK};
    color: ${GRAY_LIGHT};
    border: 2px solid ${GRAY_DARK};
    &::before {
      font-family: 'GLYPHICONS';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\\E151';
      font-size: 20px;
      padding-left: 5px;
      padding-bottom: 2px;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  background-color: ${props => {
    if (props.status === 'completed') return BLUE;
    else return 'auto';
  }};
  color: ${props => {
    switch (props.status) {
      case 'missing':
        return GRAY;
      case 'required':
        return RED;
      case 'evaluating':
        return GRAY;
      case 'completed':
        return GRAY_LIGHT;
      default:
        return BLACK;
    }
  }};
  border: ${props => {
    switch (props.status) {
      case 'missing':
        return `2px solid ${GRAY}`;
      case 'required':
        return `2px solid ${RED}`;
      case 'evaluating':
        return `2px dashed ${GRAY}`;
      case 'completed':
        return `2px solid ${BLUE}`;
      default:
        return '2px solid transparent';
    }
  }};
`;

const ProfilesOverviewIcon = ({ className, icon, status, onClick, url, label }) => {
  const getIconClass = () => {
    if (typeof icon === 'string') return icon;
    return icon[0] || '';
  };

  const getIconStyle = () => {
    return icon[1] || null;
  };

  return (
    <Container className={className}>
      <Icon
        className={getIconClass()}
        css={getIconStyle()}
        status={status}
        onClick={event => onClick(event, url)}
      />
      <span className="profiles-overview-icon__label">{label}</span>
    </Container>
  );
};

ProfilesOverviewIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  status: PropTypes.oneOf(['completed', 'missing', 'required', 'evaluating']).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  label: PropTypes.string,
};

ProfilesOverviewIcon.defaultProps = {
  className: '',
  onClick: () => console.log('Profile overview item clicked!'),
  url: '',
  label: '',
};

export default ProfilesOverviewIcon;
