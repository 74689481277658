import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { _ } from '../../../../util/translate';
import { earphone } from '../../../../atoms/Icon/libraries/glyph';

const Service0900Item: FC = () => (
  <LeftMenuItem title={_('navigation:main.0900')} icon={earphone} uri="/service0900" />
);

export default Service0900Item;
