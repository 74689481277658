import { ITableColumn } from '../../../../../atoms/Table/types';
import { _ } from '../../../../../util/translate';

const getColumns = (): ITableColumn[] => [
  { label: _('benefits:abo.archiveColumn.status') },
  { label: _('benefits:abo.texts.username') },
  { label: _('benefits:abo.texts.startDate') },
  { label: _('benefits:abo.texts.endDate') },
];

export { getColumns };
