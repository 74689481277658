import React, { FC } from 'react';
import { Badge } from '../../../atoms/Badge';
import { GREEN } from '../../../camtool-styles';
import { Service0900ActiveIcon, Service0900InactiveIcon } from '../icons';
import { Service0900StatusEnum } from '../../../graphql/VXModels/types';
import { isActive } from '../util';

interface TopBarIconProps {
  status: Service0900StatusEnum;
}

const TopBarIcon: FC<TopBarIconProps> = ({ status }) => (
  <Badge
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    overlap={'circle'}
    color={GREEN}
    variant={'dot'}
    invisible={!isActive(status)}
  >
    {isActive(status) ? <Service0900ActiveIcon /> : <Service0900InactiveIcon />}
  </Badge>
);

export default TopBarIcon;
