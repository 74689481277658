import React from 'react';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION } from '../../../camtool-styles';

const Aside = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    display: none !important;
  }
`;

const ExplorerNavSpacer = () => {
  return <Aside className="mediamanagement__explorer__nav__spacer" />;
};

export default ExplorerNavSpacer;
export { ExplorerNavSpacer };
