import React, { FC } from 'react';

interface FooterIconProps {
  link: string;
  src: string;
}

const FooterIcon: FC<FooterIconProps> = ({ link, src }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      <img css={{ height: 20 }} src={src} alt="" />
    </a>
  );
};

export { FooterIcon };
