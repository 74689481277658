import { useReducer } from 'react';
import {
  AboBenefitSubType,
  AboContentType,
  AboRoute,
  AboWizardActionType,
  IAboWizardAction,
  IAboWizardState,
} from '../../../types';

export const STORE_INITIAL_VALUES: IAboWizardState = {
  aboContentType: AboContentType.None,
  aboRedeemDetails: { type: AboBenefitSubType.None, data: { amount: '' } },
  formValues: undefined,
  history: [AboRoute.AboContentPage],
};

const reducer = (state: IAboWizardState, action: IAboWizardAction): IAboWizardState => {
  switch (action.type) {
    case AboWizardActionType.SetAboContentType:
      if (action.payload.aboContentType && action.payload.aboContentType !== state.aboContentType) {
        return {
          ...state,
          aboRedeemDetails: STORE_INITIAL_VALUES.aboRedeemDetails, // reset details
          formValues: STORE_INITIAL_VALUES.formValues, // reset formValues
          aboContentType: action.payload.aboContentType, // set new abo content type
        };
      } else return state;
    case AboWizardActionType.SetAboRedeemDetails:
      if (action.payload.aboRedeemDetails) {
        return {
          ...state,
          aboRedeemDetails: action.payload.aboRedeemDetails,
        };
      } else return state;
    case AboWizardActionType.GoToRoute:
      if (action.payload.route) {
        return {
          ...state,
          history: [
            ...(action.payload.replace ? state.history.slice(0, -1) : state.history),
            action.payload.route,
          ],
        };
      } else return state;
    case AboWizardActionType.GoBack:
      if (state.history.length === 1) return state;
      else return { ...state, history: state.history.slice(0, state.history.length - 1) };
    case AboWizardActionType.SaveFormValues:
      if (action.payload.formValues) return { ...state, formValues: action.payload.formValues };
      else return state;
    case AboWizardActionType.CreateNewAbo:
      return STORE_INITIAL_VALUES;
    default:
      throw new Error(`Invalid 'action.type' ${action.type}`);
  }
};

const useAboReducer = () => useReducer(reducer, STORE_INITIAL_VALUES);

export default useAboReducer;
