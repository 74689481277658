import React from 'react';
import { Field, FieldProps } from 'formik';
import { Button } from '../../../atoms';
import { ButtonProps } from '../../../atoms/Button/Button';
import { InterpolationWithTheme } from '@emotion/core';

interface Props extends ButtonProps {
  name: string;
  action: string;
  css?: InterpolationWithTheme<any>;
}

const FieldActionButton = ({
  name,
  action,
  css = { textTransform: 'none', marginRight: 16 },
  ...props
}: Props) => (
  <Field
    name={name}
    render={({ form: { setFieldValue, validateForm, submitForm, isSubmitting } }: FieldProps) => (
      <Button
        {...props}
        css={css}
        disabled={isSubmitting || props.disabled}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          setFieldValue(name, action, false);
          validateForm()
            .then(() => submitForm())
            .catch((err) => console.error(err));
        }}
      />
    )}
  />
);

export default FieldActionButton;
