import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';

import { DARK_GRAY, GRAY_3, GREEN, WHITE } from '../../camtool-styles';

const StyledLabel = styled.label`
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const CheckboxDiv = styled.div`
  cursor: pointer;
  flex: 0 0 auto;
  align-self: flex-start;
  min-width: 20px;
  min-height: 20px;
  margin-left: 4px;
  padding: 2px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid ${GRAY_3};
  color: ${GREEN};
  background-color: ${WHITE};
`;

const LabelSpan = styled.span`
  margin-left: 9px;
  flex: 1 0 auto;
  font-weight: 300;
  font-size: 1.3333rem;
  color: ${DARK_GRAY};
`;

interface IFancyCheckboxProps {
  className?: string;
  name?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  checked?: boolean;
  type?: string;
  label?: string;
  children?: ReactNode;
}

const FancyCheckbox: FC<IFancyCheckboxProps> = (props) => {
  const { className, checked, name, value, onChange, onBlur, type, label, children } = props;

  const labelText = children && typeof children === 'string' ? children : label || undefined;

  return (
    <StyledLabel className={className}>
      <CheckboxDiv>
        <input
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          type={type === 'radio' ? 'radio' : 'checkbox'}
          checked={checked}
          css={{ display: 'none' }}
        />

        {checked && <span className="fas fa-check" />}
      </CheckboxDiv>

      {!!labelText ? <LabelSpan>{labelText}</LabelSpan> : children}
    </StyledLabel>
  );
};

export default FancyCheckbox;
