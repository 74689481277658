import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { Query } from '@apollo/react-components';
import { FixedSizeGrid as Grid } from 'react-window';
// Outside Wizard
import { EmptyContent, Spinner } from '../../../../../components';
import { DARK_GRAY, WHITE } from '../../../../../camtool-styles';
import { ButtonLink } from '../../../../../atoms';
// Inside Wizard
import {
  QUERY_MODEL_PHOTO_ALBUMS_SHOP,
  QUERY_MODEL_VIDEOS_PHOTO_ALBUMS_SHOP,
  QUERY_MODEL_VIDEOS_SHOP,
} from '../../../queries';
import {
  CONTAINER_HEIGHT,
  CONTAINER_WIDTH,
  TILE_HEIGHT,
  TILE_PADDING,
  TILE_WIDTH,
} from './constants';
import {
  IContentOthersProps,
  IMediaAlbum,
  IQueryData,
  RedemptionAmount,
  RedemptionType,
  MediaStatus,
} from '../../../types';
import ContentMediaItem from './ContentMediaItem';
import { _ } from '../../../../../util/translate';
import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import styled from '@emotion/styled';
import ClearIcon from '@material-ui/icons/Clear';

interface IProps extends IContentOthersProps {
  redemptionType: RedemptionType;
  redemptionAmount: RedemptionAmount;
}

const useStyles = makeStyles({
  adornedStart: {
    paddingLeft: 0,
    marginRight: 0,
  },
  positionStart: {
    marginRight: 0,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  clearIcon: {
    color: DARK_GRAY,
  },
  buttonStyle: {
    padding: '10px',
  },
});

const GridDiv = styled.div`
  display: flex;
  width: ${CONTAINER_WIDTH}px;
  height: ${CONTAINER_HEIGHT}px;
  position: relative;
  overflow: auto;
  will-change: transform;
  direction: ltr;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ContentMediaSelection: FC<IProps> = ({
  state,
  dispatch,
  redemptionAmount,
  redemptionType,
}) => {
  const [filters, setFilters] = useState(['videos']); // can include photos and/or videos
  const [hasSearchResult, setSearchResult] = useState(false); // can include photos and/or videos
  const [resultData, setResultData] = useState([]); // can include photos and/or videos
  const classes = useStyles();

  let albums2D = [];

  const getQuery = () => {
    if (filters.includes('videos') && !filters.includes('photos')) return QUERY_MODEL_VIDEOS_SHOP;
    else if (!filters.includes('videos') && filters.includes('photos'))
      return QUERY_MODEL_PHOTO_ALBUMS_SHOP;
    else return QUERY_MODEL_VIDEOS_PHOTO_ALBUMS_SHOP;
  };

  const mergeData = (data: IQueryData | undefined) => {
    if (!data) {
      return { albums: [], albumsTotal: 0 };
    }

    let albums: IMediaAlbum[] = [];
    let albumsTotal = 0;

    if (data.model.videos) {
      albums = [...albums, ...data.model.videos.albums];
      albumsTotal += data.model.videos.albumsTotal;
    }

    if (data.model.photoAlbums) {
      albums = [...albums, ...data.model.photoAlbums.albums];
      albumsTotal += data.model.photoAlbums.albumsTotal;
    }

    // Sort by creation date desc
    albums = albums.sort(({ created: created1 }, { created: created2 }) =>
      created1 === created2 ? 0 : created1 < created2 ? 1 : -1
    );

    return { albums, albumsTotal };
  };

  const filterAlbums = (albums: IMediaAlbum[]): IMediaAlbum[] => {
    return albums.filter((album) => {
      if (state.voucherContent.some((content) => content.contentId === album.id)) {
        return false;
      }
      return (
        (album.isVideoAlbum && album.video.status === MediaStatus.Online) ||
        (!album.isVideoAlbum && album.numberOfReleasedPictures > 0)
      );
    });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    if (checked) {
      setFilters((prevFilters) => [...prevFilters, value]);
    } else {
      setFilters((prevFilters) => [...prevFilters.filter((val) => val !== value)]);
    }
  };

  const handleItemClick = (album: IMediaAlbum) => {
    if (searchText.length) {
      setResultData(resultData.filter((item) => item.id !== album.id));
    }
    dispatch({
      type: 'ADD_VOUCHER_CONTENT',
      payload: {
        voucherContent: {
          contentId: album.id,
          voucherType: 'MEDIA',
          contentData: album,
          redemptionType,
          redemptionAmount,
        },
      },
    });
  };
  const [searchText, setSearchText] = useState(() => '');

  const search = (searchText: string) => {
    if (searchText.length >= 3 && albums2D) {
      setResultData(
        albums2D.flatMap((item) =>
          item.filter((ele) => {
            if (ele.titles.length) {
              if (ele.titles[0]?.text.toLowerCase().search(searchText.toLowerCase()) !== -1) {
                return ele;
              }
            }
          })
        )
      );
      setSearchResult(true);
    } else {
      setSearchResult(false);
    }
  };

  useEffect(() => {
    search(searchText);
  }, [state.voucherContent]);

  const clear = () => {
    setSearchText('');
    search('');
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    search(searchText);
  };

  return (
    <article css={{ flexDirection: 'column', backgroundColor: WHITE }}>
      {/* Filters */}
      <Box display="flex" justifyContent="center">
        <Box width="100%">
          <TextField
            fullWidth
            placeholder={_('common:input.placeholder.search')}
            value={searchText}
            onChange={(e): void => setSearchText(e.target.value)}
            onKeyDown={onKeyDown}
            InputProps={{
              classes: {
                adornedStart: classes.adornedStart,
                adornedEnd: classes.adornedEnd,
              },
              startAdornment: (
                <InputAdornment position="start" classes={{ positionStart: classes.positionStart }}>
                  <IconButton
                    onClick={() => search(searchText)}
                    classes={{ root: classes.buttonStyle }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start" classes={{ positionStart: classes.positionStart }}>
                  {searchText && (
                    <IconButton onClick={clear}>
                      <ClearIcon className={classes.clearIcon} fontSize="small" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <section
        css={{
          padding: '8px 8px 16px',
          justifyContent: 'flex-start',
          'label:not(:first-of-type)': { marginLeft: 8 },
        }}
      >
        <label css={{ cursor: 'pointer' }}>
          <input
            type="checkbox"
            name="filter-videos"
            value="videos"
            onChange={handleFilterChange}
            checked={filters.includes('videos')}
          />
          <span css={{ marginLeft: 4, userSelect: 'none' }}>
            {_('benefits:voucher.contentPage.filterVideo')}
          </span>
        </label>

        <label css={{ cursor: 'pointer' }}>
          <input
            type="checkbox"
            name="filter-photos"
            value="photos"
            onChange={handleFilterChange}
            checked={filters.includes('photos')}
          />
          <span css={{ marginLeft: 4, userSelect: 'none' }}>
            {_('benefits:voucher.contentPage.filterPhotos')}
          </span>
        </label>
      </section>

      {/* Media selector grid*/}
      <section
        className="spinner-container"
        css={{
          width: CONTAINER_WIDTH,
          height: CONTAINER_HEIGHT,
          flex: `0 0 ${CONTAINER_HEIGHT}`,
          alignContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        <Query<IQueryData> query={getQuery()}>
          {({ loading, data, error }) => {
            if (error) {
              throw new Error(error.toString());
            }

            if (loading) {
              return <Spinner />;
            }

            const mergedData: { albums: IMediaAlbum[]; albumsTotal: number } = mergeData(data);

            const albumsFiltered = filterAlbums(mergedData.albums);
            albums2D = [];
            while (albumsFiltered.length > 0) {
              albums2D.push(albumsFiltered.splice(0, 4));
            }

            if (albums2D.length < 1 && state.voucherContent.length < 1) {
              search(searchText);
              if (filters.includes('videos') && !filters.includes('photos')) {
                return (
                  <EmptyContent
                    title={_('benefits:voucher.info.noVideos')}
                    icon="icon-info-sign"
                    css={{ marginTop: 95 }}
                  >
                    <ButtonLink
                      to="/camtool/mediamanagement/video/all"
                      theme="blue"
                      css={{ marginTop: 16 }}
                    >
                      {_('benefits:voucher.texts.uploadVideos')}
                    </ButtonLink>
                  </EmptyContent>
                );
              } else if (!filters.includes('videos') && filters.includes('photos')) {
                return (
                  <EmptyContent
                    title={_('benefits:voucher.info.noPhotos')}
                    icon="icon-info-sign"
                    css={{ marginTop: 95 }}
                  >
                    <ButtonLink
                      to="/camtool/mediamanagement/picture/pool"
                      theme="blue"
                      css={{ marginTop: 16 }}
                    >
                      {_('benefits:voucher.texts.uploadPhotos')}
                    </ButtonLink>
                  </EmptyContent>
                );
              } else {
                return (
                  <EmptyContent
                    title={_('benefits:voucher.info.noVideosNoPhotos')}
                    icon="icon-info-sign"
                    css={{ marginTop: 95 }}
                  >
                    <ButtonLink
                      to="/camtool/mediamanagement/video/all"
                      theme="blue"
                      css={{ marginTop: 16 }}
                    >
                      {_('benefits:voucher.texts.uploadVideosOrPhotos')}
                    </ButtonLink>
                  </EmptyContent>
                );
              }
            }

            if (hasSearchResult && resultData.length) {
              const Cell = resultData.map((item) => (
                <>
                  <ContentMediaItem
                    // style={style}
                    album={item}
                    onClick={handleItemClick}
                    disabled={state.voucherContent.length >= state.config.media.max}
                  />
                </>
              ));
              return <GridDiv>{Cell}</GridDiv>;
            } else if (hasSearchResult && searchText.length > 0) {
              return (
                <Box display="flex" justifyContent="center" width="100%" mt={3}>
                  <Typography>{_('common:search.noMatches')}</Typography>
                </Box>
              );
            } else {
              return (
                <Grid
                  width={CONTAINER_WIDTH}
                  height={CONTAINER_HEIGHT}
                  columnWidth={TILE_WIDTH + TILE_PADDING}
                  rowHeight={TILE_HEIGHT + TILE_PADDING}
                  itemData={albums2D}
                  columnCount={albums2D[0] ? albums2D[0].length : 0}
                  rowCount={albums2D.length}
                >
                  {({ columnIndex, data: albums, rowIndex, style }) => {
                    return (
                      <ContentMediaItem
                        style={style}
                        album={albums[rowIndex][columnIndex]}
                        onClick={handleItemClick}
                        disabled={state.voucherContent.length >= state.config.media.max}
                      />
                    );
                  }}
                </Grid>
              );
            }
          }}
        </Query>
      </section>
    </article>
  );
};

export default ContentMediaSelection;
