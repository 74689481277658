import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { Query } from '../../../graphql/VXServicesTelegram/types';
import { QueryResult } from '@apollo/react-common';
import {
  Attachment,
  AttachmentTypeEnum,
  FileStorageSettings,
  FileStorageTypeEnum,
} from '../../../graphql/VXModels/types';
import { useFilePreviewPictureUrl } from '../../Telegram/hooks/useFilePreviewPictureUrl';

type Result = Omit<QueryResult<Query>, 'data'> & {
  previewUrl: string | undefined | null;
};

export const useAttachmentPreviewUrl = (
  fileStorageSettings: FileStorageSettings,
  attachment?: Attachment,
  options?: QueryHookOptions<Query>
): Result => {
  const skipVxServicesTelegram =
    fileStorageSettings.type !== FileStorageTypeEnum.VXSERVICES_TELEGRAM ||
    attachment?.type !== AttachmentTypeEnum.UUID ||
    !attachment?.payload;

  const telegramResult = useFilePreviewPictureUrl(attachment?.payload, {
    ...options,
    skip: options?.skip || skipVxServicesTelegram,
  });

  switch (fileStorageSettings.type) {
    case FileStorageTypeEnum.VXSERVICES_TELEGRAM:
      const { previewPictureUrl, ...telegramRest } = telegramResult;
      return { previewUrl: previewPictureUrl, ...telegramRest };
  }

  return { previewUrl: undefined, loading: false, called: false, error: undefined } as Result;
};
