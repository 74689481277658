import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { _ } from '../../../../util/translate';
import { useDisplayState } from '../../../../hooks';
import CancelBroadcastModal from './CancelBroadcastModal';
import { RED } from '../../../../camtool-styles';
import NewButton from '../../../../atoms/Button/NewButton';
import { chevronRight } from '../../../../atoms/Icon/libraries/glyph';
import { Icon } from '../../../../atoms/Icon';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

interface Props {
  broadcastId: number;
  shouldDelete?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: 'flex-end',
    },
  })
);

const SectionCancelBroadcast: FC<Props> = ({ broadcastId, shouldDelete = false }) => {
  const { shouldShow, show, hide } = useDisplayState();
  const classes = useStyles();

  return (
    <>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" border={`1px solid ${RED}`} p={2} borderRadius="4px">
            <Grid container alignItems="center" item xs={8}>
              <span>
                {shouldDelete
                  ? _('telegram:BroadcastShow.deleteBroadcast.text')
                  : _('telegram:BroadcastShow.cancelBroadcast.text')}
              </span>
            </Grid>
            <Grid item xs={4}>
              <NewButton
                className={classes.root}
                color={'error'}
                variant={'text'}
                onClick={show}
                fullWidth
              >
                <Typography color="error">
                  {shouldDelete
                    ? _('telegram:BroadcastShow.cancelBroadcast.button.delete')
                    : _('telegram:BroadcastShow.cancelBroadcast.button.cancel')}
                </Typography>
                <Icon icon={chevronRight} size="10px" />
              </NewButton>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {shouldShow && (
        <CancelBroadcastModal hide={hide} broadcastId={broadcastId} shouldDelete={shouldDelete} />
      )}
    </>
  );
};

export default SectionCancelBroadcast;
