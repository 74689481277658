import React from 'react';
import AbstractComponent from '../../../AbstractComponent';
import { Link } from 'react-router-dom';
import { _ } from '../../../../util/translate';

const getStyle = picture => {
  if (picture && picture.url) {
    return {
      backgroundImage: 'url(' + picture.url + ')',
    };
  }
  return {};
};

class OverviewProfilesLA extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { albums, relativePath } = this.props;
    const album = albums.reduce((reduced, album) => {
      if (album.albumType === 'lustagenten') {
        const link = relativePath + '/_sedcardLA';
        const title = 'Lustagenten';
        const pictures = album.pictures || [];

        return { ...album, link, title, pictures };
      }

      return reduced;
    }, {});

    const { mainPicture, galleryPictures, morePictureCount } = album.pictures.reduce(
      (reduced, picture) => {
        if (picture.position === 0) {
          reduced.mainPicture = picture;
        } else {
          if (reduced.galleryPictures.length < 3) {
            reduced.galleryPictures.push(picture);
          }
          reduced.morePictureCount++;
        }
        return reduced;
      },
      { mainPicture: { url: '' }, galleryPictures: [], morePictureCount: -1 }
    );

    return (
      <div className="grid__box__sub__item">
        <Link to={album.link} className="grid__box__sub__item__content profile-pic__preview__la">
          <div className="profile-pic__preview__la__editoverlay">
            <div className="profile-pic__preview__la__editoverlay__text">
              {_('mediamanagement:sedcardOverview.sedcardLA.changeText')}
            </div>
          </div>

          <div className="profile-pic__preview__la__titledummy" />

          <div className="profile-pic__preview__la__row">
            <div className="profile-pic__preview__la__profilepic">
              <div
                className="profile-pic__preview__la__profilepic__titlepic"
                style={getStyle(mainPicture)}
              />
            </div>

            <div className="profile-pic__preview__la__album">
              <div className="profile-pic__preview__la__album__menudummy" />
              <div className="profile-pic__preview__la__album__row">
                <div
                  className="profile-pic__preview__la__album__item"
                  style={getStyle(galleryPictures[0])}
                />
                <div
                  className="profile-pic__preview__la__album__item"
                  style={getStyle(galleryPictures[1])}
                />
                <div
                  className="profile-pic__preview__la__album__item"
                  style={getStyle(galleryPictures[2])}
                >
                  {morePictureCount > 0 && (
                    <div className="profile-pic__preview__la__album__item__more">
                      {_('mediamanagement:picfolder.common.morePicture', {
                        count: morePictureCount,
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

OverviewProfilesLA.propTypes = {};

OverviewProfilesLA.defaultProps = {};

export default OverviewProfilesLA;
export { OverviewProfilesLA };
