import { FileStorageSettings, FileStorageTypeEnum } from '../../../graphql/VXModels/types';
import { REACT_APP_VXSERVICES_TELEGRAM_UPLOAD_URL } from '../../../util/env';
import { useApolloContext } from './useApolloContext';

export const useFileStorageSettings = (): FileStorageSettings => {
  const { token } = useApolloContext();
  return {
    token,
    type: FileStorageTypeEnum.VXSERVICES_TELEGRAM,
    uploadEndpoint: `${REACT_APP_VXSERVICES_TELEGRAM_UPLOAD_URL}`,
  };
};

export default useFileStorageSettings;
