import { useRouteMatch } from 'react-router';
import { useEffect, useRef } from 'react';

const NONEXISTENT_PATH = '/nonexistent-path-23j453k4l5jlkrdsfjjk45j36j345j3k45jj659rjf';

const useOnNavigateToRoute = (url: string | undefined, callback: () => void): void => {
  const matches = !!useRouteMatch(url || NONEXISTENT_PATH);
  const previousMatchesRef = useRef<boolean>(false);

  useEffect(() => {
    if (matches && !previousMatchesRef.current) {
      callback();
    }

    previousMatchesRef.current = matches;
  }, [matches]);
};

export default useOnNavigateToRoute;
