import React, { FC, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { IconButton, InputAdornment, TextField, Theme, Tooltip } from '@material-ui/core';
import { Icons, Localization } from 'material-table';
import { DataManager } from './types';
// import { BLACK_2, GRAY_2, GRAY_3, WHITE } from '../../camtool-styles';

export interface ToolbarSearchProps {
  showTitle: boolean;
  localization: Localization['toolbar'];
  dataManager: DataManager;
  icons: Icons;
  searchFieldAlignment: 'left' | 'right';
  searchAutoFocus: boolean;
  searchText?: string;
  searchFieldVariant: 'standard' | 'filled' | 'outlined';
  searchFieldStyle: {};
  onSearchChanged: (searchText: string | undefined | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props) => ({
      // backgroundColor: WHITE,
      // height: 35,
      // border: `1px solid ${GRAY_3}`,
      // paddingLeft: 28,
      // paddingRight: 17.5,
      // borderRadius: 17.5,
      // width: '100%',
      // fontWeight: 200,
      // fontFamily: 'Roboto, sans-serif',
      // fontSize: 14,
      // color: BLACK_2,
      //
      // '&::placeholder': {
      //   color: GRAY_2,
      // },
    }),
  })
);

const ToolbarSearch: FC<ToolbarSearchProps> = ({
  searchAutoFocus,
  showTitle,
  localization = {},
  searchFieldAlignment,
  searchFieldVariant,
  searchFieldStyle,
  icons: { Search, ResetSearch },
  dataManager,
  onSearchChanged,
}) => {
  const { root } = useStyles();

  const [searchText, setSearchText] = useState<string>();

  const onSearchChange = (searchText: string | undefined): void => {
    dataManager.changeSearchText(searchText);
    setSearchText(searchText);
    onSearchChanged(searchText);
  };

  return (
    <TextField
      autoFocus={searchAutoFocus}
      className={searchFieldAlignment === 'left' && !showTitle ? null : root}
      value={searchText}
      onChange={(event) => onSearchChange(event.target.value)}
      placeholder={localization.searchPlaceholder || undefined}
      variant={searchFieldVariant}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title={localization.searchTooltip || undefined}>
              <Search fontSize="small" />
            </Tooltip>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={!searchText}
              onClick={() => onSearchChange('')}
              aria-label={localization.clearSearchAriaLabel || undefined}
            >
              <ResetSearch fontSize="small" aria-label="clear" />
            </IconButton>
          </InputAdornment>
        ),
        style: searchFieldStyle,
        inputProps: {
          'aria-label': localization.searchAriaLabel || undefined,
        },
      }}
    />
  );
};

export default ToolbarSearch;
