import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckableOptionIcon from './CheckableOptionIcon';
import { GRAY_2, GREEN } from '../../../camtool-styles';

interface CheckedListItemProps {
  checked: boolean;
  label: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    gutters: {
      padding: '4px 0',
    },
    icon: {
      minWidth: 25,
      fontSize: '1.3em',
    },
    label: ({ checked }: Partial<CheckedListItemProps>) => ({
      fontSize: '1.3em',
      color: checked ? GREEN : GRAY_2,
    }),
  })
);

const CheckableListItem: FC<CheckedListItemProps> = ({ checked, label }) => {
  const classes = useStyles({ checked });
  return (
    <ListItem classes={{ gutters: classes.gutters }}>
      <ListItemIcon classes={{ root: classes.icon }}>
        <CheckableOptionIcon checked={checked} />
      </ListItemIcon>
      <ListItemText classes={{ primary: classes.label }} primary={label} />
    </ListItem>
  );
};

export default CheckableListItem;
