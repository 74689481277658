import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import { _ } from '../../util/translate';
import { Grid, Theme, Box, Typography, createStyles } from '@material-ui/core';
import NewButton from '../../atoms/Button/NewButton';
import { makeStyles } from '@material-ui/core/styles';
import { validationSchemaChatSettings } from '../../routes/Settings/ChatSettings/validation/validation';
import FieldEntry from './FieldEntry';
import { Prices, ChatSettings } from '../../graphql/VXModels/types';
import { Spinner, T } from '../../components';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_CHAT_SETTINGS } from '../../graphql/VXModels/mutations';
import { QUERY_MODEL_CHAT_SETTINGS } from '../../graphql/VXModels/queries';
import { AlertsStore } from '../../stores/Alerts/AlertsStore';
import styled from '@emotion/styled';
import { useUserData } from '../../util/UserData';

interface Props {
  items: { settings: ChatSettings; sliders: Prices };
  isOnlineWithSSW: boolean;
}

const SpinnerContainer = styled.div`
  min-height: 300px;
  width: 100%;
  position: relative;
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: '40px 50px 20px',
    maxWidth: '650px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 20px 20px',
    },
  },
  marginRight: {
    marginRight: '10px',
  },
  success: {
    marginTop: '2px',
    marginRight: '4px',
    color: theme.palette.success.main,
  },
  error: {
    marginTop: '2px',
    marginRight: '4px',
    color: theme.palette.error.main,
  },
}));

const useStylesMobile = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'flex-end',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column-reverse',
      },
    },
    gridElement: {
      marginRight: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '20px',
      },
    },
  })
);

const ChatSettingsTable: FC<Props> = ({ items, isOnlineWithSSW }) => {
  const mobileClasses = useStylesMobile();
  const classes = useStyles();
  const sliderData: Prices = items?.sliders;
  const isPriceVisible= useUserData()?.model?.permissions.isChatPricesVisible;
  const [newSettings, { savedError, loading }] = useMutation(MUTATION_MODEL_CHAT_SETTINGS, {
    refetchQueries: [{ query: QUERY_MODEL_CHAT_SETTINGS }],
    awaitRefetchQueries: true,
  });

  return (
    <Formik
      validationSchema={() =>
        validationSchemaChatSettings(
          sliderData.messengerMaxPrice,
          sliderData.messengerMinPrice,
          sliderData.singleChatMaxPrice,
          sliderData.singleChatMinPrice,
          sliderData.videoChatMaxPrice,
          sliderData.videoChatMinPrice
        )
      }
      initialValues={items}
      enableReinitialize={true}
      onSubmit={async (values) => {
        const sliderVal = values.sliders;
        const settingItems = values.settings;
        //  BEGIN data structure rework for formik
        Object.keys(settingItems).map((obj) => {
          if (obj !== '__typename') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const objStatus = settingItems[obj];
            objStatus.status = objStatus.status === true ? 'enabled' : 'disabled';
          }
        });
        // END data structure rework
        await newSettings({
          variables: {
            videoChatPrice: sliderVal.videoChatUserPrice,
            singleChatPrice: sliderVal.singleChatUserPrice,
            messengerPrice: sliderVal.messengerUserPrice,
            referrer: 'chatsettings page',
            deDescription: settingItems.heatmap?.texts[0]?.text,
            enDescription: settingItems.heatmap?.texts[1]?.text,
            heatMapStatus: settingItems.heatmap.status,
            livePreviewStatus: settingItems.livePreview.status,
            voyeurInSingleChatStatus: settingItems.voyeurInSingleChat.status,
            softChatStatus: settingItems.softChat.status,
            soundForVoyeurInSingleChatStatus: settingItems.soundForVoyeurInSingleChat.status,
            freeChatStatus: settingItems.freeChat.status,
            partyChatStatus: settingItems.partyChat.status,
          },
        });

        if (savedError) {
          AlertsStore.add({ type: 'error', message: _('mailings:alert.failed') });
        } else {
          AlertsStore.add({ type: 'info', message: _('mailings:alert.saved') });
        }
      }}
    >
      {({ resetForm, dirty, errors, values }) => {
        const isValid = dirty && Object.entries(errors).length === 0;
        const setting: ChatSettings = values.settings;
        const slider: Prices = values.sliders;

        return loading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <Form>
            <Box className={classes.root}>
              <Grid container>
                <FieldEntry name={'testChat'} isActive={isOnlineWithSSW} />
                {isPriceVisible && (
                  <Grid container>
                    <Box width={1}>
                      <Box my={4}>
                        <Typography variant="h3">
                          <Box lineHeight={1.7}>{_(`settings:chatSettings.Prices.title`)}</Box>
                        </Typography>
                        <T t={`settings:chatSettings.Prices.description`} />
                      </Box>
                      <FieldEntry
                        name={'messengerUserPrice'}
                        data={slider}
                        hasSliderInputGroup={true}
                      />
                      {errors.sliders?.messengerUserPrice ? (
                        <Typography color="error">{errors.sliders.messengerUserPrice}</Typography>
                      ) : null}
                      <FieldEntry
                        name={'singleChatUserPrice'}
                        data={slider}
                        hasSliderInputGroup={true}
                        isActive={false}
                      />
                      {errors.sliders?.singleChatUserPrice ? (
                        <Typography color="error">{errors.sliders.singleChatUserPrice}</Typography>
                      ) : null}
                      <FieldEntry
                        name={'videoChatUserPrice'}
                        data={slider}
                        hasSliderInputGroup={true}
                        isActive={false}
                      />
                      {errors.sliders?.videoChatUserPrice ? (
                        <Typography color="error">{errors.sliders.videoChatUserPrice}</Typography>
                      ) : null}
                    </Box>
                  </Grid>
                )}
                <Grid container>
                  <>
                    <Box my={5}>
                      <Typography variant="h3">
                        <Box lineHeight={1.7}>{_(`settings:chatSettings.Settings.title`)}</Box>
                      </Typography>
                      <T t={`settings:chatSettings.Settings.description`} />
                    </Box>
                    <FieldEntry
                      name={'voyeurInSingleChat'}
                      data={setting.voyeurInSingleChat}
                      hasFieldSetCheckboxInput={true}
                      hasNestedFieldSetCheckbox={true}
                      nestedFieldName={'soundForVoyeurInSingleChat'}
                      nestedData={setting.soundForVoyeurInSingleChat}
                    />
                    <FieldEntry
                      name={'softChat'}
                      data={setting.softChat}
                      hasFieldSetCheckboxInput={true}
                    />
                    <FieldEntry
                      name={'livePreview'}
                      data={setting.livePreview}
                      hasFieldSetCheckboxInput={true}
                    />
                    <FieldEntry
                      name={'heatmap'}
                      data={setting.heatmap}
                      hasFieldSetCheckboxInput={true}
                      hasNestedFieldSetInput={true}
                    />
                  </>
                </Grid>
                <div className={mobileClasses.root}>
                  <NewButton
                    color="default"
                    className={mobileClasses.gridElement}
                    type="reset"
                    onClick={() => resetForm()}
                    disabled={!dirty}
                    variant="text"
                  >
                    {_('common:button.cancel')}
                  </NewButton>
                  <NewButton
                    className={mobileClasses.gridElement}
                    type="submit"
                    disabled={!isValid}
                  >
                    {_('common:button.save')}
                  </NewButton>
                </div>
              </Grid>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChatSettingsTable;
