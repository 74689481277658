import React from 'react';
import styled from '@emotion/styled';

import Button from '../../../atoms/Button/Button';

const Container = styled.div`
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
  padding: 24px 16px;
  justify-content: space-between;
`;

import Layout from './Layout';
import {APP_BASE_PATH} from "../../../util/env";

interface IProps { loading: boolean; } // prettier-ignore

const AccountDocuments = ({ loading }: IProps) => {
  const handleClick = () => this.props.history.push(`${APP_BASE_PATH}/account/documents`);

  return (
    <Layout title="Account" loading={loading}>
      <Container>
        <h3 css={{ fontSize: 18, textAlign: 'center', padding: '0 16px' }}>
          Um Dich auszuzahlen benötigen wir noch weitere Dokumente.
        </h3>

        <div css={{ margin: '16px 0', fontSize: 80, color: '#888' }}>
          <span className="icon-briefcase" />
        </div>

        <Button
          theme="blue"
          label="Jetzt hochladen"
          onClick={handleClick}
          css={{ textTransform: 'none' }}
        />
      </Container>
    </Layout>
  );
};

AccountDocuments.defaultProps = {
  loading: false,
};

export default AccountDocuments;
