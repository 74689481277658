import React, { FC, useCallback } from 'react';
import { _ } from '../../../../../util/translate';
import { BLUE_HOVER, BLUE, WHITE, DARK_GRAY, WHITE_3, BLACK } from '../../../../../camtool-styles';
import {
  ChatEventUnion,
  FileType,
  Message,
  MessageDirection,
  Tipping,
  User,
} from '../../../../../graphql/VXServicesTelegram/types';
import { Link } from 'react-router-dom';
import OnlineStatus from '../OnlineStatus';
import OfflineStatus from '../OfflineStatus';
import BlockedStatus from '../BlockedStatus';
import { Box } from '@material-ui/core';
import IntersectionVisible from 'react-intersection-visible';
import { css } from '@emotion/core';
import MessagePreview from '../MessagePreview';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import styled from '@emotion/styled';
import { getFormattedAmount } from '../../../../../util/Formatter';
import { useLang } from '../../../../../util/AppState';
import { sanitizeLang } from '../../../../../util/Translator';
import { makeStyles, createStyles } from '@material-ui/core';
import { Crown } from '../../../../../atoms/Icon';
import IconBase from '../../../../../atoms/Icon/IconBase';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: '16px',
    },
  })
);

const createChannelStyle = ({ active, order }: { active: boolean; order: number }) => css`
  flex: 0 0 auto;
  order: ${order};
  & > a {
    color: ${active ? WHITE : DARK_GRAY};
    text-decoration: none;
    width: 100%;
    font-size: 14px;
    padding: 16px;
    background: none;
    border: none;
    border-bottom: 1px solid ${WHITE_3};
    border-color: ${active ? BLUE_HOVER : '#c0c0c0'};
    background-color: ${active ? '#7EB9E4' : WHITE};
    flex-direction: column;
    &:hover {
      background-color: ${active ? BLUE_HOVER : 'rgba(0, 0, 0, 0.05)'};
      border-color: ${active ? BLUE_HOVER : 'rgba(0, 0, 0, 0.05)'};
    }
  }
`;

const Badge = styled.div<{ active: boolean }>`
  ${(props) =>
    props.active
      ? `background-color: ${WHITE}; color: ${BLACK};`
      : `background-color: ${BLUE}; color: ${WHITE};`}
  border-radius: 10px;
  width: fit-content;
  padding: 2px 6px;
  align-items: center;
  white-space: nowrap;
`;

type CustomMessage = Message & {
  complete: boolean;
};

type CustomTipping = Pick<Message, 'text' | 'direction'> &
  Tipping & {
    type: string;
    complete: boolean;
  };

export enum EventEnum {
  Message = 'Message',
  Tipping = 'Tipping',
}

type CustomChatEventUnion = CustomMessage | CustomTipping;

const lastEventReducer = (
  lastMessage: ChatEventUnion,
  intl: string,
  currency: string | undefined
): CustomChatEventUnion | null => {
  const lastMessageType = lastMessage?.__typename;

  // code after this will break if __typename of 'Tipping' or 'Message' should change someday
  switch (lastMessageType) {
    case EventEnum.Message:
      return {
        type: lastMessage?.file?.type || lastMessage?.__typename,
        text: lastMessage?.text || '',
        direction: lastMessage?.direction,
        // sometimes messages have no text but a file, however file can be loaded in much later - until then messagepreview is not reliable
        complete: !!lastMessage?.text || (!lastMessage?.text && !!lastMessage?.file?.type),
      };
    case EventEnum.Tipping:
      return {
        type: lastMessage?.__typename,
        text: _('telegram:chat.system.tippingmessage', {
          amount: getFormattedAmount(lastMessage?.amount, intl, currency),
        }),
        // tippings are always just incoming, models don't tip users
        direction: MessageDirection.in,
        complete: true,
        amount: lastMessage?.amount,
        currency: lastMessage?.currency,
        read: lastMessage?.read,
      };
    default:
      return null;
  }
};

interface IProps extends User {
  active: boolean;
  order: number;
  path: string;
  isLastChannel: boolean;
  currency: string | undefined;
  fetchMore?: () => void;
}

const ChannelItem: FC<IProps> = ({
  id,
  firstName,
  lastName,
  unreadByMaster,
  order,
  active,
  path,
  premium,
  online,
  blocked,
  onlineUntil,
  groupedChat,
  isLastChannel,
  unreadTippingAmount,
  tippingAmount,
  currency,
  fetchMore,
}) => {
  const classes = useStyles();
  const lastEvent = groupedChat?.[0]?.events?.[0];
  const [lang] = useLang();
  const intlLang = sanitizeLang(lang); // international lang ('de-DE'/'en-US')
  // can either be a Message or Tipping object for now
  const lastEventMessage = lastEventReducer(lastEvent as ChatEventUnion, intlLang, currency);
  const unread = unreadByMaster > 0;
  const completedMessage = lastEventMessage?.complete;

  // IntersectionVisible fires onShow function when in view
  const Channel = isLastChannel ? IntersectionVisible : 'div';
  const channelProps = isLastChannel ? { onShow: fetchMore } : null;

  const channelStyle = useCallback(() => createChannelStyle({ active, order }), [active, order]);
  return (
    <Channel css={channelStyle} {...channelProps}>
      <Link css={{ display: 'flex' }} to={`${path}/${id}`}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gridGap="5px">
            <Box display="flex" alignItems="flex-start">
              <span css={{ fontSize: '16px' }}>{`${firstName} ${lastName}`.trim()}</span>
              <Box display="flex" ml={0.5} gridGap={'3px'}>
                {premium && (
                  <IconBase iconElement={<Crown />} classes={classes} viewBox="0 0 22.21 24" />
                )}
                {tippingAmount > 0 && <MonetizationOnIcon fontSize="small" />}
              </Box>
            </Box>
            {/* todo [UX]: instead of showing empty preview, at least show "incoming..." or anything that shows something is about to show up */}
            {completedMessage && (
              <MessagePreview
                active={active}
                unread={unread}
                type={lastEventMessage?.type as FileType | EventEnum}
                text={lastEventMessage?.text as string}
                direction={lastEventMessage?.direction}
              />
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            {online ? (
              <OnlineStatus active={active} blocked={blocked} />
            ) : (
              <OfflineStatus active={active} onlineUntil={onlineUntil} />
            )}
            {blocked && <BlockedStatus active={active} />}
            <Box display="flex" justifyContent="flex-end" mt={1} gridGap={'3px'}>
              {unreadTippingAmount > 0 && !lastEventMessage?.read && (
                <Badge active={active}>
                  {'💸 ' +
                    // could also be displayed when tippingAmount was NOT last event message! (therefore currency information could be missing -> wrong display!)
                    getFormattedAmount(unreadTippingAmount, intlLang, currency)}
                </Badge>
              )}
              {completedMessage && unread && (
                <Badge css={{ borderRadius: '50%' }} active={active}>
                  {unreadByMaster > 99 ? '99' : unreadByMaster}
                </Badge>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
    </Channel>
  );
};

export default ChannelItem;
