import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Grid, Theme, Typography } from '@material-ui/core';
import { useSftpCredentials } from '../hooks';
import { Spinner } from '../../../components';
import { _ } from '../../../util/translate';
import { useUserData } from '../../../util/UserData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: () => ({
      '& dt': {
        minWidth: 100,
      },
    }),
  })
);

const FtpCredentials: FC = () => {
  const { root } = useStyles();
  const {
    sftpCredentials: { host, port, password },
    loading,
  } = useSftpCredentials();

  const { auth } = useUserData();

  return loading ? (
    <Spinner />
  ) : (
    <Grid container spacing={2} classes={{ root }}>
      <Grid item xs={12}>
        <Typography variant="h6">{_('mediamanagement:multiuploader.uploadftp.title')}</Typography>
      </Grid>

      <Grid
        item
        container
        component="dl" // mount a Definition List
        direction={'column'}
        xs={6}
      >
        <Grid item container direction={'row'}>
          <Typography component="dt" variant="body1">
            {_('mediamanagement:multiuploader.uploadftp.serverAddress')}:
          </Typography>
          <Typography component="dd" variant="body1">
            {host}
          </Typography>
        </Grid>
        <Grid item container direction={'row'}>
          <Typography component="dt" variant="body1">
            SFTP-Port:
          </Typography>
          <Typography component="dd" variant="body1">
            {port}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        component="dl" // mount a Definition List
        direction={'column'}
        xs={6}
      >
        <Grid item container direction={'row'}>
          <Typography component="dt" variant="body1">
            {_('mediamanagement:multiuploader.uploadftp.username')}:
          </Typography>
          <Typography component="dd" variant="body1">
            {auth?.username}
          </Typography>
        </Grid>
        <Grid item container direction={'row'}>
          <Typography component="dt" variant="body1">
            {_('mediamanagement:multiuploader.uploadftp.password')}:
          </Typography>
          <Typography component="dd" variant="body1">
            {password}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FtpCredentials;
