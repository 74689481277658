import React, { FC } from 'react';
import { _ } from '../../../util/translate';
import { Box, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { MUTATION_MODEL_SETTINGS_SETVXMARKETING } from '../../../graphql/VXModels/mutations';
import { Mutation } from '../../../graphql/VXModels/types';
import { MUICheckbox, MUICheckboxFormControlLabel } from '../../../components/MUI';
import GenericEmptyContent from '../../../atoms/GenericEmptyContent/GenericEmptyContent';
import AlertsStore from '../../../stores/Alerts/AlertsStore';
import { QUERY_MODEL_SETTINGS_MARKETING } from '../../../graphql/VXModels/queries';

interface MarketingStatusProps {
  checked: boolean | undefined;
}

const MarketingStatus: FC<MarketingStatusProps> = ({ checked }) => {
  const [toggleVXMarketing, { loading, error }] = useMutation<Mutation>(
    MUTATION_MODEL_SETTINGS_SETVXMARKETING
  );

  const onToggleVXMarketing = async (isActive: boolean | undefined): Promise<void> => {
    const { data } = await toggleVXMarketing({
      variables: { active: isActive },
      refetchQueries: [{ query: QUERY_MODEL_SETTINGS_MARKETING }],
      awaitRefetchQueries: true,
    });
    const status = data?.model?.settings?.setVXMarketing?.status;
    const alertType = status === 'OK' ? 'info' : 'error';
    const alertMessage =
      alertType === 'info' ? _('common:info.successfullySaved') : _('common:alerts.saveFail');

    AlertsStore.add({
      type: alertType,
      message: alertMessage,
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={3}>
        <Typography>{_('marketing:settings.vxmarketing.status.info')}</Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={3}>
        <MUICheckboxFormControlLabel
          disabled={loading}
          checked={checked}
          color={checked ? 'secondary' : 'error'}
          control={
            <MUICheckbox onChange={(e): Promise<void> => onToggleVXMarketing(e.target.checked)} />
          }
          label={<Typography>{_('marketing:settings.vxmarketing.status.checkbox')}</Typography>}
        />
      </Box>
      <Box>{!!error && <GenericEmptyContent />}</Box>
    </Box>
  );
};

export default MarketingStatus;
