import { Override } from './types';

export const MuiContainer: Override<'MuiContainer'> = {
  root: {
    marginLeft: 0, // our container is aligned left
  },
  disableGutters: {
    paddingLeft: 8, // hack to get styleguide applied (todo: discuss styleguide)
    paddingRight: 8, // hack to get styleguide applied (todo: discuss styleguide)
  },
};
