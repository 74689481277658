import React, { FC } from 'react';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
  // This is a dirty hack to work around the newline issue in https://phabricator.x/T18730.
  // How it works: By letting pointer events propagate, the selection is placed in the next line instead of in the atomic block.
  // Thus, no line break can be inserted where it would cause an issue.
  & > figure {
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
  }
`;

const AtomicBlock: FC = ({ children }) => <StyledDiv>{children}</StyledDiv>;

export default AtomicBlock;
