import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Button from '../../Button/Button';

const ModalContentContainer = styled.div`
  margin: auto;
  height: 208px; // need height because of IE11
  padding: 64px;
  padding-bottom: 32px;
  flex-direction: column;
  background-color: #fff;
  color: #3c3d3e;
  position: relative;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.4);
`;

const ModalCloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  font-size: 16px;
  background-color: #fff;
  color: #6c6c6c;
  border: none;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    background-color: #6c6c6c;
    color: #fff;
  }
`;

const DeleteStoryModal = ({ onClickDelete, onClickCloseButton, message }) => (
  <ModalContentContainer data-testid="delete-story-modal">
    <ModalCloseButton onClick={onClickCloseButton}>
      <span className="vxicon icon-close-line" />
    </ModalCloseButton>
    <h2 css={{ fontWeight: 'normal' }}>{message}</h2>

    <Button
      label="JETZT LÖSCHEN"
      onClick={onClickDelete}
      className="button button--red"
      css={{ padding: 0, paddingTop: 16, alignSelf: 'center' }}
    />

    <Button
      className="button link--grey"
      label="Abbrechen"
      onClick={onClickCloseButton}
      css={{ padding: 0, paddingTop: 8, alignSelf: 'center', flex: 'auto' }}
    />
  </ModalContentContainer>
);

DeleteStoryModal.propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  onClickCloseButton: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default DeleteStoryModal;
