import { PreviewVideoOptions, PreviewVideoThumbnailIconSize } from '../../PreviewFile';

const getVideoOptions = (
  videoThumbnailIconSize?: PreviewVideoThumbnailIconSize
): PreviewVideoOptions => ({
  thumbnailOnly: true,
  thumbnailIconSize: videoThumbnailIconSize,
});

export default getVideoOptions;
