import React, { FC } from 'react';
import { _ } from '../../../../../util/translate';
import CorePictureWidget from '../../../../../atoms/CorePictureWidget/CorePictureWidget';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_MEDIA_FSK_PREVIEW_PICTURES } from '../../../../../graphql/VXModels/queries';
import { Spinner } from '../../../../../components';
import { GRAY_2 } from '../../../../../camtool-styles';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Query } from '../../../../../graphql/VXModels/types';

const BA6ProfilePictureWidgets: FC = () => {
  const { loading, error, data } = useQuery<Query>(QUERY_MODEL_MEDIA_FSK_PREVIEW_PICTURES);
  const media = data?.model?.media;

  const previewPicture12 = media?.previewPicture12;
  const backgroundUrl12 = previewPicture12?.picture?.url;
  const albumId12 = previewPicture12?.album?.id;
  const pictureId12 = previewPicture12?.picture?.id;
  const isChecked12 = previewPicture12?.picture?.isChecked;

  const previewPicture16 = media?.previewPicture16;
  const backgroundUrl16 = previewPicture16?.picture?.url;
  const albumId16 = previewPicture16?.album?.id;
  const pictureId16 = previewPicture16?.picture?.id;
  const isChecked16 = previewPicture16?.picture?.isChecked;

  return loading ? (
    <Spinner />
  ) : error ? (
    <GenericEmptyContent />
  ) : (
    <div css={{ flexDirection: 'column', marginBottom: '24px' }}>
      <h2 css={{ fontSize: '16px' }}>{_('service0900:PhoneSex.active.profie.widgets.headline')}</h2>
      <small css={{ color: GRAY_2, fontSize: '12px' }}>
        {_('service0900:PhoneSex.active.profie.widgets.subText')}
      </small>
      <div>
        <CorePictureWidget
          css={{ flex: '0 0 calc(50% - 16px)', marginRight: '32px' }}
          title={_('service0900:PhoneSex.active.profie.widgets.flirtcore')}
          platformContextInfo={_('service0900:PhoneSex.active.profile.title')}
          backgroundUrl={backgroundUrl12}
          albumId={albumId12}
          pictureId={pictureId12}
          isChecked={isChecked12}
          key={albumId12}
          filteredFsk={12}
        />
        <CorePictureWidget
          css={{ flex: '0 0 calc(50% - 16px)' }}
          title={_('service0900:PhoneSex.active.profie.widgets.softcore')}
          platformContextInfo={_('service0900:PhoneSex.active.profile.title')}
          backgroundUrl={backgroundUrl16}
          albumId={albumId16}
          pictureId={pictureId16}
          isChecked={isChecked16}
          key={albumId16}
          filteredFsk={16}
        />
      </div>
    </div>
  );
};

export default BA6ProfilePictureWidgets;
