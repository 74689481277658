import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import { BLACK_1 } from '../../../camtool-styles';

const MARGIN = 8;

export const Container = styled.div`
  margin: ${MARGIN}px;
  min-width: 306px;
  width: calc(25% - 16px);
  flex: 1 0 auto;
  flex-direction: column;
  background-color: #f5f5f5;
  min-height: 328px;
  overflow: hidden;
`;

export const Content = styled.div`
  color: ${BLACK_1};
  flex: 1;
  justify-content: center;
  max-height: 280px;
  overflow-y: auto;
`;

export const Header = styled.div`
  color: #ffffff;
  background-color: #3c3d3e;
  height: 48px;
  font-size: 16px;
  text-transform: uppercase;
  padding: 0 16px;
  align-items: center;
`;

interface IProps { title: string | (() => any); loading?: boolean; children: any; } // prettier-ignore

const Layout = React.memo((props: IProps) => {
  return (
    <Container>
      {props.loading ? null : (
        <Fragment>
          <Header>{typeof props.title === 'function' ? props.title() : props.title}</Header>
          <Content>{props.children}</Content>
        </Fragment>
      )}
    </Container>
  );
});
Layout.displayName = 'Layout';

export default Layout;
