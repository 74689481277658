import i18next, { TOptions } from 'i18next';
import BackendAdapter from 'i18next-multiload-backend-adapter';
import BackendFetch from 'i18next-fetch-backend';
import postProcessor from 'i18next-sprintf-postprocessor';
import { APP_API_HOST } from './env';
import { hasScope, Scopes } from './scope';
import { ApiLang } from '../graphql/VXModels/types';

export function sanitizeLang(lang?: string) {
  switch (lang) {
    case 'de':
    case 'de_DE':
    case 'de-DE':
      return 'de-DE'; // i18next locale uses de-DE instead of de_DE
    case 'en':
    case 'en_US':
    case 'en-US':
      return 'en-US'; // i18next locale uses en-US instead of en-US
    default:
      throw new Error('Invalid language: ' + lang);
  }
}

const getOptions = async (lang: ApiLang): Promise<i18next.InitOptions> => {
  const response = await fetch(`${APP_API_HOST}/i18n/translation/stats`);
  const { categories, hash } = await response.json();

  return {
    debug: false,
    lng: sanitizeLang(lang),
    fallbackLng: hasScope([Scopes.VXModels]) ? ['de-DE', 'en-US'] : ['en-US', 'de-DE'],
    defaultNS: 'translation',
    ns: categories,
    load: 'currentOnly',
    saveMissing: false,
    backend: {
      backend: BackendFetch,
      backendOption: {
        loadPath: `${APP_API_HOST}/i18n/translation/{{lng}}?h=${hash}`,
        addPath: `${APP_API_HOST}/v1/metadata/vxmodels/translation/{{lng}}/{{ns}}`,
        allowMultiLoading: false,
        multiSeparator: '_',
        requestOptions: {
          mode: 'cors',
        },
      },
    },
  };
};

class TranslatorClass {
  private static getCurrentLanguage(): string {
    return i18next.language || (i18next.options.fallbackLng as string[])[0];
  }

  private options: i18next.InitOptions = {};

  constructor() {
    i18next.use(BackendAdapter).use(postProcessor);
  }

  public async init(lang: ApiLang) {
    this.options = await getOptions(lang);
    return i18next.init(this.options);
  }

  public t(key: string, options?: TOptions) {
    const { ns: namespaces = [] } = this.options;
    const hasValidNamespace =
      key && (namespaces as string[]).includes(key.toString().split(':')[0]);

    const translated = hasValidNamespace ? i18next.t(key, options) : key;

    // Our tooling complains about translated always being a string but we got an object in production when specifying
    // only a part of the translation key: "service0900:phoneSex.step422" instead of "service0900:phoneSex.step422.text"
    return typeof translated === 'string' ? translated : key;
  }

  public async changeLanguage(toLang: string) {
    const toLangSanitized = sanitizeLang(toLang);
    const fromLang = TranslatorClass.getCurrentLanguage();

    if (toLangSanitized && fromLang !== toLangSanitized) {
      await i18next.changeLanguage(toLangSanitized);
    }
  }
}

const Translator = new TranslatorClass();

export default Translator;
