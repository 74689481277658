import React, { FC, Fragment, useState } from 'react';
import styled from '@emotion/styled';
import ApolloClient from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { BLACK_1 } from '../../../../camtool-styles';
import { Spinner } from '../../../../components';
import { _ } from '../../../../util/translate';
import { IAbo, IAboInstance } from '../../types';
import { QUERY_ACTOR_INSTANCES } from '../../queries';
import Header from './Header';
import CancelCheckboxes from './CancelCheckboxes';
import Instances from './Instances';
import ButtonRow from './ButtonRow';
import GenericModalBoxLayout, {
  BREAKPOINT_SMALL,
} from '../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import { useParams } from 'react-router-dom';

interface Props {
  abo: IAbo;
  onClose: () => void;
  apolloClientAbo: ApolloClient<Record<string, never>>;
}

const InnerContainer = styled.div`
  flex-direction: column;
  align-items: center;
  padding: 64px 32px 32px 32px;
  color: ${BLACK_1};
  width: 100%;
  height: 700px;
  flex: 0 0 auto;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    min-height: 100vh;
    height: auto;
  }
`;

/*
######################
  This code has been more or less copied over to ModalBonusCodeDetails - if you fix/change something here also check that place!
######################
*/
const ModalAboDetails: FC<Props> = ({ apolloClientAbo, onClose, abo }) => {
  const { id: aboId } = useParams<{ id: string }>();
  const [deactivateChecked, setDeactivateChecked] = useState<boolean>(false);
  const [cancelSubsChecked, setCancelSubsChecked] = useState<boolean>(false);

  const {
    data: instancesData,
    loading: instancesLoading,
    error: instancesError,
  } = useQuery<{ actor: { instances: IAboInstance[] } }, { aboId: number }>(QUERY_ACTOR_INSTANCES, {
    variables: { aboId: Number(aboId) },
    client: apolloClientAbo,
    fetchPolicy: 'network-only',
  });

  const instances = instancesData?.actor?.instances;

  return (
    <GenericModalBoxLayout name="abo-details" onClose={onClose} maxWidth={750}>
      <InnerContainer>
        {instancesLoading ? (
          <Spinner />
        ) : instancesError ? (
          <pre>{_('common:info.errorOccurred')}</pre>
        ) : (
          <Fragment>
            <Header abo={abo} />

            <CancelCheckboxes
              abo={abo}
              cancelSubsChecked={cancelSubsChecked}
              deactivateChecked={deactivateChecked}
              onCancelSubsCheckedChange={setCancelSubsChecked}
              onDeactivateCheckedChange={setDeactivateChecked}
            />

            <Instances instances={instances} />

            <ButtonRow
              apolloClientAbo={apolloClientAbo}
              cancelSubsChecked={cancelSubsChecked}
              deactivateChecked={deactivateChecked}
              onClose={onClose}
            />
          </Fragment>
        )}
      </InnerContainer>
    </GenericModalBoxLayout>
  );
};

export default ModalAboDetails;
