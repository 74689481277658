import React, { FC } from 'react';
import { _ } from '../../../../../../util/translate';
import { Code } from '../../../../types';
import NewButton from '../../../../../../atoms/Button/NewButton';

interface Props {
  deactivate: boolean;
  deactivateCode: (code: string) => Promise<void>;
  code: Code;
}

const ModalBonusDeactivateButton: FC<Props> = ({ deactivate, deactivateCode, code }) => (
  <NewButton
    onClick={async (): Promise<void> => await deactivateCode(code.code)}
    disabled={!deactivate}
  >
    {_('benefits:abo.archive.detailsModal.irreversiblyDisable')}
  </NewButton>
);

export default ModalBonusDeactivateButton;
