import React, { createElement } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { css } from '@emotion/core';
import { APP_BASE_PATH } from '../../util/env';
import { BLUE } from '../../camtool-styles';

const styles = css`
  flex-direction: column;

  & * {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
  }

  span {
    display: inline;
  }

  ul,
  ol {
    padding-left: 24px;
  }

  li {
    padding: 2px 0;
  }

  p {
    padding: 8px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 8px 0;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
    padding: 16px 0 8px;
  }

  h3 {
    font-size: 1.17em;
    padding: 8px 0 0;
  }

  h4 {
    padding: 0;
  }

  a {
    color: ${BLUE};
  }
`;

const transformLinkUri = (uri) => {
  const js = /^javascript:(.+)/.exec(uri);
  if (js) {
    return `javascript:${js[1]}`;
  }

  return ReactMarkdown.uriTransformer(uri);
};

const transformImageUri = (uri) => uri;

const conditionalLink = (props) => (attr) => {
  const { href = '', children } = attr;
  const { scripts = {}, linkTarget: target } = props;
  const nextAttr = { ...attr, target };
  if (href.indexOf(APP_BASE_PATH + '/') === 0) {
    return <Link to={href}>{children}</Link>;
  }

  const js = /^javascript:(.+)/.exec(href);
  if (js) {
    if (scripts[js[1]]) {
      nextAttr.onClick = (ev) => {
        ev.preventDefault();
        scripts[js[1]](ev);
      };
    }
    nextAttr.href = '#';
  }

  return createElement('a', { ...nextAttr }, children);
};

const Markdown = (props) => (
  <ReactMarkdown
    css={styles}
    transformLinkUri={transformLinkUri}
    transformImageUri={transformImageUri}
    renderers={{ link: conditionalLink(props) }}
    escapeHtml={false}
    {...props}
  />
);

export default Markdown;
export { Markdown };
