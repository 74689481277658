import React, { FC } from 'react';
import { Radio, RadioProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import { GRAY_3 } from '../../../camtool-styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: GRAY_3,
    },
  })
);

const NewRadioButton: FC<RadioProps> = ({ ...props }) => {
  const classes = useStyles();
  return <Radio classes={classes} {...props}></Radio>;
};

export default NewRadioButton;
