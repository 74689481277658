import React from 'react';
import AbstractProfile from '../../AbstractProfile';
import { PROFILE_VX_AUTOMAILS } from '../../../../stores/Profile/Profile';
import { SessionStore } from '../../../../stores/Session/Session';

import NavSubVX from '../NavSubVX';

import {
  Button,
  ButtonGroup,
  Footer,
  Form,
  GroupRow,
  Markdown,
  MultiLangTextareafield,
  Spinner,
  T,
} from '../../../../components';
import { _ } from '../../../../util/translate';

const fieldNames = [
  'vxAutomailTextExistingUser1',
  'vxAutomailTextExistingUser2',
  'vxAutomailTextExistingUser3',
  'vxAutomailTextExistingUser4',
  'vxAutomailTextExistingUser5',
  'vxAutomailTextNewUser1',
  'vxAutomailTextNewUser2',
  'vxAutomailTextNewUser3',
  'vxAutomailTextNewUser4',
  'vxAutomailTextNewUser5',
];

export default class AutoMails extends AbstractProfile {
  constructor(props) {
    super(props);
    this.errorStates = {};
    this.errorClasses = {};
  }

  getResource() {
    return PROFILE_VX_AUTOMAILS;
  }

  onStoreChange() {
    super.onStoreChange();
    for (let fieldName of fieldNames) {
      if (this.storeValues[fieldName] && this.storeValues[fieldName]['flags'] !== undefined) {
        this.errorStates[fieldName] =
          this.storeValues[fieldName]['flags'] == 1
            ? _('profiles:vx.automails.status.verified')
            : _('profiles:vx.automails.status.checking');
        this.errorClasses[fieldName] =
          this.storeValues[fieldName]['flags'] == 1 ? 'verified' : 'checking';
      }
    }
    this.formsyRef.updateInputsWithError(this.errorStates);
    this.forceUpdate();
  }

  render() {
    this.spokenLangs = SessionStore.get().spokenLangs;

    this.fieldsNewUser = [];
    this.fieldsExistingUser = [];
    for (let i = 1; i <= 5; i++) {
      this.fieldsNewUser.push({ id: i, name: 'vxAutomailTextNewUser' + i });
      this.fieldsExistingUser.push({ id: i, name: 'vxAutomailTextExistingUser' + i });
    }

    return (
      <main id="vx-automails" className="vx-automails grid">
        <NavSubVX />

        <div className="grid__row">
          <Form
            className="grid__column grid__box spinner-container"
            ref="form"
            onSubmit={this.submitForm}
            onValid={this.onValid}
            onChange={this.onChange}
          >
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'profiles:visitx.automails.pagetitle.label'} />
              </div>
            </div>

            {this.state.isLoading && <Spinner />}

            <div className="grid__box__item">
              <div className="grid__box__item__content">
                <div className="warning-text">
                  <div className="warning-text__column">
                    <div className="title">
                      <T _={'profiles:visitx.automails.information.label'} />
                    </div>
                    <Markdown
                      className="subtitle"
                      source={_('marketing:automails.information.longText')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid__box__row grid__row--wrap">
              <div className="grid__box__column grid__box__sub" style={{ minWidth: 454 }}>
                <div className="grid__box__sub__header">
                  <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                    <T _={'marketing:automails.oldUsers.title'} />
                  </div>
                </div>

                <div className="grid__box__sub__item">
                  <div className="grid__box__sub__item__content">
                    {this.fieldsExistingUser.map(field => (
                      <GroupRow
                        label={_('marketing:automails.oldUsers.automailLabel'.concat(field.id))}
                        tooltip="none"
                        key={field.id}
                        errorMessage={this.getTextErrorMessage(field.name)}
                        labelClass="vx-automails__item__label"
                        className={`vx-automails__item ${
                          this.getTextErrorMessage(field.name) !== '' ? 'error' : ''
                        }`}
                      >
                        <MultiLangTextareafield
                          name={field.name}
                          value={this.storeValues[field.name].texts}
                          placeholder={_('marketing:automails.oldUsers.automail1Placeholder')}
                          langs={this.spokenLangs}
                          maxLength={2000}
                          class={this.errorClasses[field.name]}
                        />
                      </GroupRow>
                    ))}
                  </div>
                </div>
              </div>
              <div className="grid__box__column grid__box__sub" style={{ minWidth: 454 }}>
                <div className="grid__box__sub__header">
                  <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
                    <T _={'marketing:automails.newUsers.title'} />
                  </div>
                </div>

                <div className="grid__box__sub__item">
                  <div className="grid__box__sub__item__content">
                    {this.fieldsNewUser.map(field => (
                      <GroupRow
                        tooltip="none"
                        key={field.id}
                        labelClass="vx-automails__item__label"
                        errorMessage={this.getTextErrorMessage(field.name)}
                        label={_('marketing:automails.newUsers.automailLabel'.concat(field.id))}
                        className={`vx-automails__item ${
                          this.getTextErrorMessage(field.name) !== '' ? 'error' : ''
                        }`}
                      >
                        <MultiLangTextareafield
                          name={field.name}
                          value={this.storeValues[field.name].texts}
                          langs={this.spokenLangs}
                          maxLength={2000}
                          class={this.errorClasses[field.name]}
                          placeholder={_('marketing:automails.newUsers.automail1Placeholder')}
                        />
                      </GroupRow>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <ButtonGroup position="right">
              <Button
                label={_('common:button.cancel')}
                onClick={this.rejectChanges}
                type="button"
                disabled={!this.activateResetButton}
              />
              <Button className="button--blue" label={_('common:button.save')} type="submit" />
            </ButtonGroup>
          </Form>
        </div>

        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}
