import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../AbstractComponent';
import ButtonGroup from '../../ButtonGroup/ButtonGroup';
import Button from '../../Button/Button';
import AlbumViewPicturePool from '../../MediaManagement/ExplorerPicture/AlbumViewPicturePool/AlbumViewPicturePool';

class VideoPreviewPool extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      // isLoading: true,
      album: this.props.data,
      selectedPictures: null,
      canSubmit: false,
    };
  }

  onSubmit() {
    this.props.onSubmit({
      source: 'videoPreviewPool',
      selectionType: 'picture',
      pictures: this.state.selectedPictures,
    });
  }

  onSelectPicture(picture) {
    this.setState({
      selectedPictures: picture,
    });
  }

  onUnselectPicture() {
    this.setState({
      selectedPictures: null,
    });
  }

  render() {
    this.canSubmit = this.state.selectedPictures;
    return (
      <div className="mediaselector__picpool">
        <div className="mediaselector__picpool__column">
          <AlbumViewPicturePool
            album={this.state.album}
            isLoading={this.state.isLoading}
            allowDetails
            allowSelection
            onPictureSelect={this.onSelectPicture}
            onPictureUnselect={this.onUnselectPicture}
            filter={this.props.filter}
          />

          <ButtonGroup position="right">
            <Button
              disabled={!this.canSubmit}
              label="OK"
              type="submit"
              onClick={this.onSubmit}
              class="button--blue"
            />
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

VideoPreviewPool.propTypes = {
  data: PropTypes.object.isRequired,
};

export default VideoPreviewPool;
export { VideoPreviewPool };
