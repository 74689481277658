import React, { FC } from 'react';
import { Spinner } from '../../../../components';

interface CustomVideoPlayerProps {
  url: string;
  isMobile: boolean;
  previewPictureUrl?: string;
}

const CustomVideoPlayer: FC<CustomVideoPlayerProps> = ({
  url,
  isMobile,
  previewPictureUrl = '',
}) => {
  return (
    <div>
      {!url ? (
        <>
          <Spinner size="xs" />
          <div
            css={{
              minHeight: '100px',
              backgroundImage: `url(${previewPictureUrl})`,
              aspectRatio: isMobile ? '2/1' : '4/1',
              backgroundSize: 'cover',
            }}
          />
        </>
      ) : (
        <video controls={true} className="video-js">
          <source src={url} />
        </video>
      )}
    </div>
  );
};

export default CustomVideoPlayer;
