import React, { FC, ReactElement } from 'react';
import { _ } from '../../../util/translate';
import InputField from '../../../atoms/InputField/InputField';
import Button from '../../../atoms/Button/Button';
import { Formik, Form, Field, FormikValues, FormikActions } from 'formik';
import { T } from '../../../components/Translation/Translation';
import { MUTATION_MODEL_SERVICE0900_ADD_CAROUSEL_NUMBER } from '../../../graphql/VXModels/mutations';
import { useMutation } from '@apollo/react-hooks';
import { onAddNumberSubmit, updateAfterAddedNumber } from './utils';

interface Props {
  phoneCarrouselLength: number;
}

const EditablePhoneNumberFormik: FC<Props> = ({ phoneCarrouselLength = 0 }) => {
  const [addNumber, { loading }] = useMutation(MUTATION_MODEL_SERVICE0900_ADD_CAROUSEL_NUMBER, {
    update: updateAfterAddedNumber,
  });

  const initialPhoneCarouselValues = {
    targetNumber: '',
  };

  return (
    <Formik
      initialValues={initialPhoneCarouselValues}
      onSubmit={(
        values: FormikValues,
        { setFieldError, setSubmitting }: FormikActions<{ targetNumber: string }>
      ): void => {
        onAddNumberSubmit(values, setFieldError, setSubmitting, addNumber);
      }}
    >
      {({ isSubmitting }: FormikValues): ReactElement => (
        <Form css={{ flexDirection: 'column' }}>
          <div css={{ marginTop: '6px' }}>
            <p css={{ padding: 4, fontWeight: 'bold' }}>
              {_('service0900:PhoneSex.carousel.number', {
                sprintf: [phoneCarrouselLength + 1],
              })}
            </p>
          </div>
          <div css={{ flexDirection: 'column' }}>
            <Field name={`targetNumber`}>
              {({ field, form: { errors } }: FormikValues): ReactElement => (
                <InputField
                  {...field}
                  errorMessage={errors?.targetNumber}
                  placeholder="+491234567890"
                  css={{
                    padding: '2px 10px',
                    width: '600px',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                />
              )}
            </Field>
          </div>
          <Button
            css={{ marginTop: '30px', width: '190px', whiteSpace: 'nowrap' }}
            theme="gray"
            disabled={loading || isSubmitting}
            type="submit"
          >
            <span className="icon-plus" />
            <span css={{ marginLeft: 8 }}>
              <T _={'service0900:carousel.button.add_new_number'} />
            </span>
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EditablePhoneNumberFormik;
