import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Query } from '../../../graphql/VXModels/types';
import { QueryResult } from '@apollo/react-common';

const QUERY_MODEL_EXTRA_FTPCREDENTIALS = gql`
  query QUERY_MODEL_EXTRA_FTPCREDENTIALS {
    model {
      extra {
        sftpCredentials {
          host
          port
          username
          password
        }
      }
    }
  }
`;

type Result = Omit<QueryResult<Query>, 'data'> & {
  sftpCredentials: { host: string; port: number; username: string; password: string };
};

export const useSftpCredentials = (): Result => {
  const { data, ...result } = useQuery<Query>(QUERY_MODEL_EXTRA_FTPCREDENTIALS);
  return {
    sftpCredentials: {
      ...(data?.model?.extra?.sftpCredentials || {
        host: undefined,
        port: undefined,
        username: undefined,
        password: undefined,
      }),
    },
    ...result,
  };
};
