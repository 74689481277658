import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';
import swfURL from 'file-loader!./webcam.swf';
import WebcamJS from 'webcamjs';

class Camera extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      screenshot: null,
      showCameraClass: 'block',
      showPreviewClass: 'none',
    };
  }

  componentDidMount() {
    WebcamJS.set({
      width: 320,
      height: 240,
      dest_width: 960,
      dest_height: 720,
      image_format: 'png',
      // jpeg_quality: 90,
      force_flash: false,
      // flip_horiz: true,
      fps: 45,
      swfURL: swfURL,
    });

    WebcamJS.attach('#webcamjsCamera');

    WebcamJS.on('live', () => {
      this.props.onLive();
    });

    WebcamJS.on('error', () => {
      this.props.onError();
    });
  }

  componentWillUnmount() {
    WebcamJS.reset();
  }

  clear() {
    try {
      document.getElementById('webcamjsCameraResult').innerHTML = '';
    } catch (e) {
      // do nothing
    }
    this.setState({
      showPreviewClass: 'none',
      showCameraClass: 'block',
    });
    this.props.onClear();
  }

  takeSnap() {
    // console.log('onSnap');
    WebcamJS.snap(dataUri => {
      this.setState({
        showPreviewClass: 'block',
        showCameraClass: 'none',
      });
      document.getElementById('webcamjsCameraResult').innerHTML = '<img src="' + dataUri + '"/>';
      this.props.onSnap(dataUri);
    });
  }

  render() {
    return (
      <div className="camera">
        <div
          id="webcamjsCamera"
          className="webcamjsCamera"
          style={{ display: this.state.showCameraClass }}
        />

        <div id="webcamjsCameraResult" style={{ display: this.state.showPreviewClass }} />
      </div>
    );
  }
}

Camera.propTypes = {
  onSnap: PropTypes.func,
  onLive: PropTypes.func,
  onError: PropTypes.func,
  onClear: PropTypes.func,
  takeSnape: PropTypes.bool,
  clearSnap: PropTypes.bool,
};

Camera.defaultProps = {
  onSnap: () => {},
  onLive: () => {},
  onError: () => {},
  onClear: () => {},
  takeSnap: false,
  clearSnap: false,
};

Camera.resetCamera = function() {
  WebcamJS.off('live');
  WebcamJS.off('error');
  WebcamJS.reset();
};

export default Camera;
export { Camera };
