export const glass = 'icon-glass';
export const leaf = 'icon-leaf';
export const dog = 'icon-dog';
export const user = 'icon-user';
export const girl = 'icon-girl';
export const car = 'icon-car';
export const userAdd = 'icon-user-add';
export const userRemove = 'icon-user-remove';
export const film = 'icon-film';
export const magic = 'icon-magic';
export const envelope = 'icon-envelope';
export const camera = 'icon-camera';
export const heart = 'icon-heart';
export const beachUmbrella = 'icon-beach-umbrella';
export const train = 'icon-train';
export const print = 'icon-print';
export const bin = 'icon-bin';
export const music = 'icon-music';
export const alt = 'icon-music-alt';
export const empty = 'icon-heart-empty';
export const home = 'icon-home';
export const snowflake = 'icon-snowflake';
export const fire = 'icon-fire';
export const magnet = 'icon-magnet';
export const parents = 'icon-parents';
export const binoculars = 'icon-binoculars';
export const road = 'icon-road';
export const search = 'icon-search';
export const cars = 'icon-cars';
export const notes2 = 'icon-notes-2';
export const pencil = 'icon-pencil';
export const bus = 'icon-bus';
export const wifiAlt = 'icon-wifi-alt';
export const luggage = 'icon-luggage';
export const oldMan = 'icon-old-man';
export const woman = 'icon-woman';
export const file = 'icon-file';
export const coins = 'icon-coins';
export const plane = 'icon-plane';
export const notes = 'icon-notes';
export const stats = 'icon-stats';
export const charts = 'icon-charts';
export const pieChart = 'icon-pie-chart';
export const group = 'icon-group';
export const keys = 'icon-keys';
export const calendar = 'icon-calendar';
export const router = 'icon-router';
export const cameraSmall = 'icon-camera-small';
export const starEmpty = 'icon-star-empty';
export const star = 'icon-star';
export const link = 'icon-link';
export const eyeOpen = 'icon-eye-open';
export const eyeClose = 'icon-eye-close';
export const alarm = 'icon-alarm';
export const clock = 'icon-clock';
export const stopwatch = 'icon-stopwatch';
export const projector = 'icon-projector';
export const history = 'icon-history';
export const truck = 'icon-truck';
export const cargo = 'icon-cargo';
export const compass = 'icon-compass';
export const keynote = 'icon-keynote';
export const paperclip = 'icon-paperclip';
export const power = 'icon-power';
export const lightbulb = 'icon-lightbulb';
export const tag = 'icon-tag';
export const tags = 'icon-tags';
export const cleaning = 'icon-cleaning';
export const ruler = 'icon-ruler';
export const gift = 'icon-gift';
export const umbrella = 'icon-umbrella';
export const book = 'icon-book';
export const bookmark = 'icon-bookmark';
export const wifi = 'icon-wifi';
export const cup = 'icon-cup';
export const stroller = 'icon-stroller';
export const headphones = 'icon-headphones';
export const headset = 'icon-headset';
export const warningSign = 'icon-warning-sign';
export const signal = 'icon-signal';
export const retweet = 'icon-retweet';
export const refresh = 'icon-refresh';
export const roundabout = 'icon-roundabout';
export const random = 'icon-random';
export const heat = 'icon-heat';
export const repeat = 'icon-repeat';
export const display = 'icon-display';
export const logBook = 'icon-log-book';
export const addressBook = 'icon-address-book';
export const building = 'icon-building';
export const eyedropper = 'icon-eyedropper';
export const adjust = 'icon-adjust';
export const tint = 'icon-tint';
export const crop = 'icon-crop';
export const vectorPathSquare = 'icon-vector-path-square';
export const vectorPathCircle = 'icon-vector-path-circle';
export const vectorPathPolygon = 'icon-vector-path-polygon';
export const vectorPathLine = 'icon-vector-path-line';
export const vectorPathCurve = 'icon-vector-path-curve';
export const vectorPathAll = 'icon-vector-path-all';
export const font = 'icon-font';
export const italic = 'icon-italic';
export const bold = 'icon-bold';
export const textUnderline = 'icon-text-underline';
export const textStrike = 'icon-text-strike';
export const textHeight = 'icon-text-height';
export const textWidth = 'icon-text-width';
export const textResize = 'icon-text-resize';
export const leftIndent = 'icon-left-indent';
export const rightIndent = 'icon-right-indent';
export const alignLeft = 'icon-align-left';
export const alignCenter = 'icon-align-center';
export const alignRight = 'icon-align-right';
export const justify = 'icon-justify';
export const list = 'icon-list';
export const textSmaller = 'icon-text-smaller';
export const textBigger = 'icon-text-bigger';
export const embed = 'icon-embed';
export const embedClose = 'icon-embed-close';
export const table = 'icon-table';
export const messageFull = 'icon-message-full';
export const messageEmpty = 'icon-message-empty';
export const messageIn = 'icon-message-in';
export const messageOut = 'icon-message-out';
export const messagePlus = 'icon-message-plus';
export const messageMinus = 'icon-message-minus';
export const messageBan = 'icon-message-ban';
export const messageFlag = 'icon-message-flag';
export const messageLock = 'icon-message-lock';
export const messageNew = 'icon-message-new';
export const inbox = 'icon-inbox';
export const inboxPlus = 'icon-inbox-plus';
export const inboxMinus = 'icon-inbox-minus';
export const inboxLock = 'icon-inbox-lock';
export const inboxIn = 'icon-inbox-in';
export const inboxOut = 'icon-inbox-out';
export const cogwheel = 'icon-cogwheel';
export const cogwheels = 'icon-cogwheels';
export const picture = 'icon-picture';
export const adjustAlt = 'icon-adjust-alt';
export const databaseLock = 'icon-database-lock';
export const databasePlus = 'icon-database-plus';
export const databaseMinus = 'icon-database-minus';
export const databaseBan = 'icon-database-ban';
export const folderOpen = 'icon-folder-open';
export const folderPlus = 'icon-folder-plus';
export const folderMinus = 'icon-folder-minus';
export const folderLock = 'icon-folder-lock';
export const folderFlag = 'icon-folder-flag';
export const folderNew = 'icon-folder-new';
export const edit = 'icon-edit';
export const newWindow = 'icon-new-window';
export const check = 'icon-check';
export const unchecked = 'icon-unchecked';
export const moreWindows = 'icon-more-windows';
export const showBigThumbnails = 'icon-show-big-thumbnails';
export const showThumbnails = 'icon-show-thumbnails';
export const showThumbnailsWithLines = 'icon-show-thumbnails-with-lines';
export const showLines = 'icon-show-lines';
export const playlist = 'icon-playlist';
export const imac = 'icon-imac';
export const macbook = 'icon-macbook';
export const ipad = 'icon-ipad';
export const iphone = 'icon-iphone';
export const iphoneTransfer = 'icon-iphone-transfer';
export const iphoneExchange = 'icon-iphone-exchange';
export const ipod = 'icon-ipod';
export const ipodShuffle = 'icon-ipod-shuffle';
export const earPlugs = 'icon-ear-plugs';
export const record = 'icon-record';
export const stepBackward = 'icon-step-backward';
export const fastBackward = 'icon-fast-backward';
export const rewind = 'icon-rewind';
export const play = 'icon-play';
export const pause = 'icon-pause';
export const stop = 'icon-stop';
export const forward = 'icon-forward';
export const fastForward = 'icon-fast-forward';
export const stepForward = 'icon-step-forward';
export const eject = 'icon-eject';
export const facetimeVideo = 'icon-facetime-video';
export const mute = 'icon-mute';
export const volumeDown = 'icon-volume-down';
export const volumeUp = 'icon-volume-up';
export const screenshot = 'icon-screenshot';
export const move = 'icon-move';
export const more = 'icon-more';
export const brightnessReduce = 'icon-brightness-reduce';
export const brightnessIncrease = 'icon-brightness-increase';
export const plusSign = 'icon-plus-sign';
export const minusSign = 'icon-minus-sign';
export const removeSign = 'icon-remove-sign';
export const okSign = 'icon-ok-sign';
export const questionSign = 'icon-question-sign';
export const infoSign = 'icon-info-sign';
export const exclamationSign = 'icon-exclamation-sign';
export const removeCircle = 'icon-remove-circle';
export const okCircle = 'icon-ok-circle';
export const banCircle = 'icon-ban-circle';
export const download = 'icon-download';
export const upload = 'icon-upload';
export const shoppingCart = 'icon-shopping-cart';
export const lock = 'icon-lock';
export const unlock = 'icon-unlock';
export const electricity = 'icon-electricity';
export const ok = 'icon-ok';
export const remove = 'icon-remove';
export const cartIn = 'icon-cart-in';
export const cartOut = 'icon-cart-out';
export const arrowLeft = 'icon-arrow-left';
export const arrowRight = 'icon-arrow-right';
export const arrowDown = 'icon-arrow-down';
export const arrowUp = 'icon-arrow-up';
export const resizeSmall = 'icon-resize-small';
export const resizeFull = 'icon-resize-full';
export const circleArrowLeft = 'icon-circle-arrow-left';
export const circleArrowRight = 'icon-circle-arrow-right';
export const circleArrowTop = 'icon-circle-arrow-top';
export const circleArrowDown = 'icon-circle-arrow-down';
export const playButton = 'icon-play-button';
export const unshare = 'icon-unshare';
export const share = 'icon-share';
export const chevronRight = 'icon-chevron-right';
export const chevronLeft = 'icon-chevron-left';
export const bluetooth = 'icon-bluetooth';
export const euro = 'icon-euro';
export const usd = 'icon-usd';
export const gbp = 'icon-gbp';
export const retweet2 = 'icon-retweet-2';
export const moon = 'icon-moon';
export const sun = 'icon-sun';
export const cloud = 'icon-cloud';
export const direction = 'icon-direction';
export const brush = 'icon-brush';
export const pen = 'icon-pen';
export const zoomIn = 'icon-zoom-in';
export const zoomOut = 'icon-zoom-out';
export const pin = 'icon-pin';
export const albums = 'icon-albums';
export const rotationLock = 'icon-rotation-lock';
export const flash = 'icon-flash';
export const mapMarker = 'icon-map-marker';
export const anchor = 'icon-anchor';
export const conversation = 'icon-conversation';
export const chat = 'icon-chat';
export const noteEmpty = 'icon-note-empty';
export const note = 'icon-note';
export const asterisk = 'icon-asterisk';
export const divide = 'icon-divide';
export const snorkelDiving = 'icon-snorkel-diving';
export const scubaDiving = 'icon-scuba-diving';
export const oxygenBottle = 'icon-oxygen-bottle';
export const fins = 'icon-fins';
export const fishes = 'icon-fishes';
export const boat = 'icon-boat';
export const deleteIcon = 'icon-delete';
export const sheriffsStar = 'icon-sheriffs-star';
export const qrcode = 'icon-qrcode';
export const barcode = 'icon-barcode';
export const pool = 'icon-pool';
export const buoy = 'icon-buoy';
export const spade = 'icon-spade';
export const bank = 'icon-bank';
export const vcard = 'icon-vcard';
export const electricalPlug = 'icon-electrical-plug';
export const flag = 'icon-flag';
export const creditCard = 'icon-credit-card';
export const keyboardWireless = 'icon-keyboard-wireless';
export const keyboardWired = 'icon-keyboard-wired';
export const shield = 'icon-shield';
export const ring = 'icon-ring';
export const cake = 'icon-cake';
export const drink = 'icon-drink';
export const beer = 'icon-beer';
export const fastFood = 'icon-fast-food';
export const cutlery = 'icon-cutlery';
export const pizza = 'icon-pizza';
export const birthdayCake = 'icon-birthday-cake';
export const tablet = 'icon-tablet';
export const settings = 'icon-settings';
export const bullets = 'icon-bullets';
export const cardio = 'icon-cardio';
export const tShirt = 'icon-t-shirt';
export const pants = 'icon-pants';
export const sweater = 'icon-sweater';
export const fabric = 'icon-fabric';
export const leather = 'icon-leather';
export const scissors = 'icon-scissors';
export const bomb = 'icon-bomb';
export const skull = 'icon-skull';
export const celebration = 'icon-celebration';
export const teaKettle = 'icon-tea-kettle';
export const frenchPress = 'icon-french-press';
export const coffeeCup = 'icon-coffee-cup';
export const pot = 'icon-pot';
export const grater = 'icon-grater';
export const kettle = 'icon-kettle';
export const hospital = 'icon-hospital';
export const hospitalH = 'icon-hospital-h';
export const microphone = 'icon-microphone';
export const webcam = 'icon-webcam';
export const templeChristianityChurch = 'icon-temple-christianity-church';
export const templeIslam = 'icon-temple-islam';
export const templeHindu = 'icon-temple-hindu';
export const templeBuddhist = 'icon-temple-buddhist';
export const bicycle = 'icon-bicycle';
export const lifePreserver = 'icon-life-preserver';
export const shareAlt = 'icon-share-alt';
export const comments = 'icon-comments';
export const flower = 'icon-flower';
export const baseball = 'icon-baseball';
export const rugby = 'icon-rugby';
export const ax = 'icon-ax';
export const tableTennis = 'icon-table-tennis';
export const bowling = 'icon-bowling';
export const treeConifer = 'icon-tree-conifer';
export const treeDeciduous = 'icon-tree-deciduous';
export const moreItems = 'icon-more-items';
export const sort = 'icon-sort';
export const filter = 'icon-filter';
export const gamepad = 'icon-gamepad';
export const playingDices = 'icon-playing-dices';
export const calculator = 'icon-calculator';
export const tie = 'icon-tie';
export const wallet = 'icon-wallet';
export const piano = 'icon-piano';
export const sampler = 'icon-sampler';
export const podium = 'icon-podium';
export const soccerBall = 'icon-soccer-ball';
export const blog = 'icon-blog';
export const dashboard = 'icon-dashboard';
export const certificate = 'icon-certificate';
export const bell = 'icon-bell';
export const candle = 'icon-candle';
export const pushpin = 'icon-pushpin';
export const iphoneShake = 'icon-iphone-shake';
export const pinFlag = 'icon-pin-flag';
export const turtle = 'icon-turtle';
export const rabbit = 'icon-rabbit';
export const globe = 'icon-globe';
export const briefcase = 'icon-briefcase';
export const hdd = 'icon-hdd';
export const thumbsUp = 'icon-thumbs-up';
export const thumbsDown = 'icon-thumbs-down';
export const handRight = 'icon-hand-right';
export const handLeft = 'icon-hand-left';
export const handUp = 'icon-hand-up';
export const handDown = 'icon-hand-down';
export const fullscreen = 'icon-fullscreen';
export const shoppingBag = 'icon-shopping-bag';
export const bookOpen = 'icon-book-open';
export const nameplate = 'icon-nameplate';
export const nameplateAlt = 'icon-nameplate-alt';
export const vases = 'icon-vases';
export const bullhorn = 'icon-bullhorn';
export const dumbbell = 'icon-dumbbell';
export const suitcase = 'icon-suitcase';
export const fileImport = 'icon-file-import';
export const fileExport = 'icon-file-export';
export const bug = 'icon-bug';
export const crown = 'icon-crown';
export const smoking = 'icon-smoking';
export const cloudUpload = 'icon-cloud-upload';
export const cloudDownload = 'icon-cloud-download';
export const restart = 'icon-restart';
export const securityCamera = 'icon-security-camera';
export const expand = 'icon-expand';
export const collapse = 'icon-collapse';
export const collapseTop = 'icon-collapse-top';
export const globeAf = 'icon-globe-af';
export const global = 'icon-global';
export const spray = 'icon-spray';
export const nails = 'icon-nails';
export const clawHammer = 'icon-claw-hammer';
export const classicHammer = 'icon-classic-hammer';
export const handSaw = 'icon-hand-saw';
export const riflescope = 'icon-riflescope';
export const electricalSocketEu = 'icon-electrical-socket-eu';
export const electricalSocketUs = 'icon-electrical-socket-us';
export const messageForward = 'icon-message-forward';
export const coatHanger = 'icon-coat-hanger';
export const dress = 'icon-dress';
export const bathrobe = 'icon-bathrobe';
export const shirt = 'icon-shirt';
export const underwear = 'icon-underwear';
export const logIn = 'icon-log-in';
export const logOut = 'icon-log-out';
export const exit = 'icon-exit';
export const newWindowAlt = 'icon-new-window-alt';
export const videoSd = 'icon-video-sd';
export const videoHd = 'icon-video-hd';
export const subtitles = 'icon-subtitles';
export const soundStereo = 'icon-sound-stereo';
export const soundDolby = 'icon-sound-dolby';
export const sound51 = 'icon-sound-5-1';
export const sound61 = 'icon-sound-6-1';
export const sound71 = 'icon-sound-7-1';
export const copyrightMark = 'icon-copyright-mark';
export const registrationMark = 'icon-registration-mark';
export const radar = 'icon-radar';
export const skateboard = 'icon-skateboard';
export const golfCourse = 'icon-golf-course';
export const sorting = 'icon-sorting';
export const sortByAlphabet = 'icon-sort-by-alphabet';
export const sortByAlphabetAlt = 'icon-sort-by-alphabet-alt';
export const sortByOrder = 'icon-sort-by-order';
export const sortByOrderOAlt = 'icon-sort-by-order-alt';
export const sortByAttributes = 'icon-sort-by-attributes';
export const sortByAttributesAlt = 'icon-sort-by-attributes-alt';
export const compressed = 'icon-compressed';
export const packageIcon = 'icon-package';
export const cloudPlus = 'icon-cloud-plus';
export const cloudMinus = 'icon-cloud-minus';
export const diskSave = 'icon-disk-save';
export const diskOpen = 'icon-disk-open';
export const diskSaved = 'icon-disk-saved';
export const diskRemove = 'icon-disk-remove';
export const diskImport = 'icon-disk-import';
export const diskExport = 'icon-disk-export';
export const tower = 'icon-tower';
export const send = 'icon-send';
export const gitBranch = 'icon-git-branch';
export const gitCreate = 'icon-git-create';
export const gitPrivate = 'icon-git-private';
export const gitDelete = 'icon-git-delete';
export const gitMerge = 'icon-git-merge';
export const gitPullRequest = 'icon-git-pull-request';
export const gitCompare = 'icon-git-compare';
export const gitCommit = 'icon-git-commit';
export const constructionCone = 'icon-construction-cone';
export const shoeSteps = 'icon-shoe-steps';
export const plus = 'icon-plus';
export const minus = 'icon-minus';
export const redo = 'icon-redo';
export const undo = 'icon-undo';
export const golf = 'icon-golf';
export const hockey = 'icon-hockey';
export const pipe = 'icon-pipe';
export const wrench = 'icon-wrench';
export const folderClosed = 'icon-folder-closed';
export const phoneAlt = 'icon-phone-alt';
export const earphone = 'icon-earphone';
export const floppyDisk = 'icon-floppy-disk';
export const floppySaved = 'icon-floppy-saved';
export const floppyRemove = 'icon-floppy-remove';
export const floppySave = 'icon-floppy-save';
export const floppyOpen = 'icon-floppy-open';
export const translate = 'icon-translate';
export const fax = 'icon-fax';
export const factory = 'icon-factory';
export const shopWindow = 'icon-shop-window';
export const shop = 'icon-shop';
export const kiosk = 'icon-kiosk';
export const kioskWheels = 'icon-kiosk-wheels';
export const kioskLight = 'icon-kiosk-light';
export const kioskFood = 'icon-kiosk-food';
export const transfer = 'icon-transfer';
export const money = 'icon-money';
export const header = 'icon-header';
export const blacksmith = 'icon-blacksmith';
export const sawBlade = 'icon-saw-blade';
export const basketball = 'icon-basketball';
export const server = 'icon-server';
export const serverPlus = 'icon-server-plus';
export const serverMinus = 'icon-server-minus';
export const serverBan = 'icon-server-ban';
export const serverFlag = 'icon-server-flag';
export const serverLock = 'icon-server-lock';
export const serverNew = 'icon-server-new';
export const chargingStation = 'icon-charging-station';
export const gasStation = 'icon-gas-station';
export const target = 'icon-target';
export const bed = 'icon-bed';
export const mosquito = 'icon-mosquito';
export const diningSet = 'icon-dining-set';
export const plateOfFood = 'icon-plate-of-food';
export const hygieneKit = 'icon-hygiene-kit';
export const blackboard = 'icon-blackboard';
export const marriage = 'icon-marriage';
export const bucket = 'icon-bucket';
export const noneColorSwatch = 'icon-none-color-swatch';
export const bringForward = 'icon-bring-forward';
export const bringToFront = 'icon-bring-to-front';
export const sendBackward = 'icon-send-backward';
export const sendToBack = 'icon-send-to-back';
export const fitFrameToImage = 'icon-fit-frame-to-image';
export const fitImageToFrame = 'icon-fit-image-to-frame';
export const multipleDisplays = 'icon-multiple-displays';
export const handshake = 'icon-handshake';
export const child = 'icon-child';
export const babyFormula = 'icon-baby-formula';
export const medicine = 'icon-medicine';
export const atvVehicle = 'icon-atv-vehicle';
export const motorcycle = 'icon-motorcycle';
export const bedAlt = 'icon-bed-alt';
export const tent = 'icon-tent';
export const glasses = 'icon-glasses';
export const sunglasses = 'icon-sunglasses';
export const family = 'icon-family';
export const education = 'icon-education';
export const shoes = 'icon-shoes';
export const map = 'icon-map';
export const cd = 'icon-cd';
export const alert = 'icon-alert';
export const piggyBank = 'icon-piggy-bank';
export const starHalf = 'icon-star-half';
export const cluster = 'icon-cluster';
export const flowchart = 'icon-flowchart';
export const commodities = 'icon-commodities';
export const duplicate = 'icon-duplicate';
export const copy = 'icon-copy';
export const paste = 'icon-paste';
export const bathBathtub = 'icon-bath-bathtub';
export const bathShower = 'icon-bath-shower';
export const shower = 'icon-shower';
export const menuHamburger = 'icon-menu-hamburger';
export const optionVertical = 'icon-option-vertical';
export const optionHorizontal = 'icon-option-horizontal';
export const currencyConversion = 'icon-currency-conversion';
export const userBan = 'icon-user-ban';
export const userLock = 'icon-user-lock';
export const userFlag = 'icon-user-flag';
export const userAsterisk = 'icon-user-asterisk';
export const userAlert = 'icon-user-alert';
export const userKey = 'icon-user-key';
export const userConversation = 'icon-user-conversation';
export const database = 'icon-database';
export const databaseSearch = 'icon-database-search';
export const listAlt = 'icon-list-alt';
export const hazardSign = 'icon-hazard-sign';
export const hazard = 'icon-hazard';
export const stopSign = 'icon-stop-sign';
export const lab = 'icon-lab';
export const labAlt = 'icon-lab-alt';
export const iceCream = 'icon-ice-cream';
export const iceLolly = 'icon-ice-lolly';
export const iceLollyTasted = 'icon-ice-lolly-tasted';
export const invoice = 'icon-invoice';
export const cartTick = 'icon-cart-tick';
export const hourglass = 'icon-hourglass';
export const cat = 'icon-cat';
export const lamp = 'icon-lamp';
export const scaleClassic = 'icon-scale-classic';
export const eyePlus = 'icon-eye-plus';
export const eyeMinus = 'icon-eye-minus';
export const quote = 'icon-quote';
export const bitcoin = 'icon-bitcoin';
export const yen = 'icon-yen';
export const ruble = 'icon-ruble';
export const erase = 'icon-erase';
export const podcast = 'icon-podcast';
export const firework = 'icon-firework';
export const scale = 'icon-scale';
export const king = 'icon-king';
export const queen = 'icon-queen';
export const pawn = 'icon-pawn';
export const bishop = 'icon-bishop';
export const knight = 'icon-knight';
export const micMute = 'icon-mic-mute';
export const voicemail = 'icon-voicemail';
export const paragraphAlt = 'icon-paragraph-alt';
export const personWalking = 'icon-person-walking';
export const personWheelchair = 'icon-person-wheelchair';
export const underground = 'icon-underground';
export const carHov = 'icon-car-hov';
export const carRental = 'icon-car-rental';
export const transport = 'icon-transport';
export const taxi = 'icon-taxi';
export const iceCreamNo = 'icon-ice-cream-no';
export const ukRatU = 'icon-uk-rat-u';
export const ukRatPg = 'icon-uk-rat-pg';
export const ukRat12a = 'icon-uk-rat-12a';
export const ukRat12 = 'icon-uk-rat-12';
export const ukRat15 = 'icon-uk-rat-15';
export const ukRat18 = 'icon-uk-rat-18';
export const ukRatR18 = 'icon-uk-rat-r18';
export const tv = 'icon-tv';
export const sms = 'icon-sms';
export const mms = 'icon-mms';
export const usRatG = 'icon-us-rat-g';
export const usRatPg = 'icon-us-rat-pg';
export const usRatPg13 = 'icon-us-rat-pg-13';
export const usRatRestricted = 'icon-us-rat-restricted';
export const usRatNoOne17 = 'icon-us-rat-no-one-17';
export const equalizer = 'icon-equalizer';
export const speakers = 'icon-speakers';
export const remoteControl = 'icon-remote-control';
export const remoteControlTv = 'icon-remote-control-tv';
export const shredder = 'icon-shredder';
export const folderHeart = 'icon-folder-heart';
export const personRunning = 'icon-person-running';
export const person = 'icon-person';
export const voice = 'icon-voice';
export const stethoscope = 'icon-stethoscope';
export const paired = 'icon-paired';
export const activity = 'icon-activity';
export const watch = 'icon-watch';
export const scissorsAlt = 'icon-scissors-alt';
export const carWheel = 'icon-car-wheel';
export const chevronUp = 'icon-chevron-up';
export const chevronDown = 'icon-chevron-down';
export const superscript = 'icon-superscript';
export const subscript = 'icon-subscript';
export const textSize = 'icon-text-size';
export const textColor = 'icon-text-color';
export const textBackground = 'icon-text-background';
export const modalWindow = 'icon-modal-window';
export const newspaper = 'icon-newspaper';
export const tractor = 'icon-tractor';
export const camping = 'icon-camping';
export const campingBenches = 'icon-camping-benches';
export const forest = 'icon-forest';
export const park = 'icon-park';
export const field = 'icon-field';
export const mountains = 'icon-mountains';
export const feesPayments = 'icon-fees-payments';
export const smallPayments = 'icon-small-payments';
export const mixedBuildings = 'icon-mixed-buildings';
export const industrialZone = 'icon-industrial-zone';
export const visitorTag = 'icon-visitor-tag';
export const businessman = 'icon-businessman';
export const meditation = 'icon-meditation';
export const bath = 'icon-bath';
export const donate = 'icon-donate';
export const sauna = 'icon-sauna';
export const bedroomNightstand = 'icon-bedroom-nightstand';
export const bedroomLamp = 'icon-bedroom-lamp';
export const doctor = 'icon-doctor';
export const engineeringNetworks = 'icon-engineering-networks';
export const washingMachine = 'icon-washing-machine';
export const dryer = 'icon-dryer';
export const dishwasher = 'icon-dishwasher';
export const luggageGroup = 'icon-luggage-group';
export const teenager = 'icon-teenager';
export const personStick = 'icon-person-stick';
export const personStickOld = 'icon-person-stick-old';
export const traveler = 'icon-traveler';
export const veteran = 'icon-veteran';
export const groupChat = 'icon-group-chat';
export const elections = 'icon-elections';
export const lawJustice = 'icon-law-justice';
export const judiciary = 'icon-judiciary';
export const houseFire = 'icon-house-fire';
export const firefighters = 'icon-firefighters';
export const police = 'icon-police';
export const ambulance = 'icon-ambulance';
export const lightBeacon = 'icon-light-beacon';
export const importantDay = 'icon-important-day';
export const bikePark = 'icon-bike-park';
export const objectAlignTop = 'icon-object-align-top';
export const objectAlignBottom = 'icon-object-align-bottom';
export const objectAlignHorizontal = 'icon-object-align-horizontal';
export const objectAlignLeft = 'icon-object-align-left';
export const objectAlignVertical = 'icon-object-align-vertical';
export const objectAAlignRight = 'icon-object-align-right';
export const reflectY = 'icon-reflect-y';
export const reflectX = 'icon-reflect-x';
export const tick = 'icon-tick';
export const lawnmower = 'icon-lawnmower';
export const callRedirect = 'icon-call-redirect';
export const callIp = 'icon-call-ip';
export const callRecord = 'icon-call-record';
export const callRingtone = 'icon-call-ringtone';
export const callTraffic = 'icon-call-traffic';
export const callHold = 'icon-call-hold';
export const callIncoming = 'icon-call-incoming';
export const callOutgoing = 'icon-call-outgoing';
export const callVideo = 'icon-call-video';
export const callMissed = 'icon-call-missed';
export const theater = 'icon-theater';
export const heartbeat = 'icon-heartbeat';
export const kettlebell = 'icon-kettlebell';
export const fireplace = 'icon-fireplace';
export const streetLights = 'icon-street-lights';
export const pedestrian = 'icon-pedestrian';
export const flood = 'icon-flood';
export const openWater = 'icon-open-water';
export const forSale = 'icon-for-sale';
export const dustbin = 'icon-dustbin';
export const door = 'icon-door';
export const campFire = 'icon-camp-fire';
export const fleurDeLis = 'icon-fleur-de-lis';
export const temperatureSettings = 'icon-temperature-settings';
export const article = 'icon-article';
export const sunbath = 'icon-sunbath';
export const balancedDiet = 'icon-balanced-diet';
export const ticket = 'icon-ticket';
export const parkingTicket = 'icon-parking-ticket';
export const parkingMeter = 'icon-parking-meter';
export const laptop = 'icon-laptop';
export const treeStructure = 'icon-tree-structure';
export const weatherWarning = 'icon-weather-warning';
export const temperatureLow = 'icon-temperature-low';
export const temperatureHigh = 'icon-temperature-high';
export const temperatureLowWarning = 'icon-temperature-low-warning';
export const temperatureHighWarning = 'icon-temperature-high-warning';
export const hurricane = 'icon-hurricane';
export const storm = 'icon-storm';
export const sortedWaste = 'icon-sorted-waste';
export const ear = 'icon-ear';
export const earDeaf = 'icon-ear-deaf';
export const filePlus = 'icon-file-plus';
export const fileMinus = 'icon-file-minus';
export const fileLock = 'icon-file-lock';
export const fileCloud = 'icon-file-cloud';
export const fileCloudDownload = 'icon-file-cloud-download';
export const fileCloudUpload = 'icon-file-cloud-upload';
export const paragraph = 'icon-paragraph';
export const listNumbered = 'icon-list-numbered';
export const donateHeart = 'icon-donate-heart';
export const government = 'icon-government';
export const maze = 'icon-maze';
export const chronicle = 'icon-chronicle';
export const userStructure = 'icon-user-structure';
export const recycle = 'icon-recycle';
export const gas = 'icon-gas';
export const wastePipe = 'icon-waste-pipe';
export const waterPipe = 'icon-water-pipe';
export const parking = 'icon-parking';
export const closed = 'icon-closed';
export const mouse = 'icon-mouse';
export const mouseDoubleClick = 'icon-mouse-double-click';
export const mouseLeftClick = 'icon-mouse-left-click';
export const mouseRightClick = 'icon-mouse-right-click';
export const mouseMiddleClick = 'icon-mouse-middle-click';
export const mouseScroll = 'icon-mouse-scroll';
export const resizeVertical = 'icon-resize-vertical';
export const resizeHorizontal = 'icon-resize-horizontal';
export const temperature = 'icon-temperature';
export const puzzle = 'icon-puzzle';
export const puzzle2 = 'icon-puzzle-2';
export const puzzle3 = 'icon-puzzle-3';
export const nearbySquare = 'icon-nearby-square';
export const nearbyCircle = 'icon-nearby-circle';
export const rotateRight = 'icon-rotate-right';
export const rotateLeft = 'icon-rotate-left';
export const pictures = 'icon-pictures';
export const photoAlbum = 'icon-photo-album';
export const cadastralMap = 'icon-cadastral-map';
export const fingerprintScan = 'icon-fingerprint-scan';
export const fingerprint = 'icon-fingerprint';
export const fingerprintLock = 'icon-fingerprint-lock';
export const fingerprintOk = 'icon-fingerprint-ok';
export const fingerprintRemove = 'icon-fingerprint-remove';
export const fingerprintReload = 'icon-fingerprint-reload';
export const pendingNotifications = 'icon-pending-notifications';
export const synchronization = 'icon-synchronization';
export const synchronizationBan = 'icon-synchronization-ban';
export const hash = 'icon-hash';
export const genderMale = 'icon-gender-male';
export const gendeFfemale = 'icon-gender-female';
export const genderVirginFemale = 'icon-gender-virgin-female';
export const genderIntersex = 'icon-gender-intersex';
export const genderTransgender = 'icon-gender-transgender';
export const genderOriLesbian = 'icon-gender-ori-lesbian';
export const genderOriGay = 'icon-gender-ori-gay';
export const genderOriHetero = 'icon-gender-ori-hetero';
export const genderOther = 'icon-gender-other';
export const genderUnknown = 'icon-gender-unknown';
export const scanner = 'icon-scanner';
export const multifunctionPrinter = 'icon-multifunction-printer';
export const lasso = 'icon-lasso';
export const view360 = 'icon-view-360';
export const batteryCharging = 'icon-battery-charging';
export const batteryFull = 'icon-battery-full';
export const battery75 = 'icon-battery-75';
export const battery50 = 'icon-battery-50';
export const battery25 = 'icon-battery-25';
export const battery10 = 'icon-battery-10';
export const satellite = 'icon-satellite';
export const satelliteDish = 'icon-satellite-dish';
export const satelliteDishAlt = 'icon-satellite-dish-alt';
export const auction = 'icon-auction';
export const directions = 'icon-directions';
export const race = 'icon-race';
export const robot = 'icon-robot';
export const rulerAlt = 'icon-ruler-alt';
export const cubeEmpty = 'icon-cube-empty';
export const cubeBlack = 'icon-cube-black';
export const moveSquare = 'icon-move-square';
export const drop = 'icon-drop';
export const vrHeadset = 'icon-vr-headset';
export const vrCharging = 'icon-vr-charging';
export const vrLowBattery = 'icon-vr-low-battery';
export const vrPaired = 'icon-vr-paired';
export const vrSettings = 'icon-vr-settings';
export const vrMaintenance = 'icon-vr-maintenance';
export const filterRemove = 'icon-filter-remove';
export const filterApplied = 'icon-filter-applied';
export const oneDay = 'icon-one-day';
export const userVr = 'icon-user-vr';
export const userVrAdd = 'icon-user-vr-add';
export const userVrRemove = 'icon-user-vr-remove';
export const dice1 = 'icon-dice-1';
export const dice2 = 'icon-dice-2';
export const dice3 = 'icon-dice-3';
export const dice4 = 'icon-dice-4';
export const dice5 = 'icon-dice-5';
export const dice6 = 'icon-dice-6';
export const allow = 'icon-allow';
export const allowed = 'icon-allowed';
export const forbidden = 'icon-forbidden';
export const solo = 'icon-solo';
export const solof = 'icon-solof';
export const couple = 'icon-couple';
export const videopay = 'icon-videopay';
export const videofree = 'icon-videofree';
export const videoprofile = 'icon-videoprofile';
export const videoaction = 'icon-videoaction';
export const bedroom = 'icon-bedroom';
export const bathroom = 'icon-bathroom';
export const clubSMstudio = 'icon-clubSMstudio';
export const livingroom = 'icon-livingroom';
export const kitchen = 'icon-kitchen';
export const office = 'icon-office';
export const outdoor = 'icon-outdoor';
export const billingCorrected = 'icon-billing-corrected';
export const billingCancellation = 'icon-billing-cancellation';
export const billingOk = 'icon-billing-ok';
export const billingCorrectedAccountBalance = 'icon-billing-corrected-account-balance';
export const proofOfIdentity = 'icon-proofOfIdentity';
export const proofOfIdentityOfCamModel = 'icon-proofOfIdentityOfCamModel';
export const proofOfAddress = 'icon-proofOfAddress';
export const proofOfAddress3L = 'icon-proofOfAddress3L';
export const free = 'icon-free';
export const profile = 'icon-profile';
export const camapign = 'icon-campaign';
export const contest = 'icon-contest';
export const transferOwnership = 'icon-transferOwnership';
export const vatPosition = 'icon-vatPosition';
export const idShot = 'icon-idShot';
export const vatStatus = 'icon-vatStatus';
export const gemaProof = 'icon-gemaProof';
export const earphoneDown = 'icon-earphone-down';
