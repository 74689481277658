import React, { FC } from 'react';
import Icon from '../../atoms/Icon/Icon';
import { shoppingCart, money } from '../../atoms/Icon/libraries/glyph';

interface Props {
  totalSalesLabel: string;
  netLabel: string;
  amountTotal: number;
}

const MediaSalesTable: FC<Props> = ({ totalSalesLabel, netLabel, amountTotal, children }) => {
  return (
    <table css={{ width: '100%' }}>
      <thead>
        <tr css={{ lineHeight: '30px' }}>
          <th css={{ textAlign: 'left', width: '1%', whiteSpace: 'nowrap' }}>
            <Icon
              icon={shoppingCart}
              size={'14px'}
              css={{ marginRight: '5px', color: '#2f7cb4' }}
            />
            {totalSalesLabel}
          </th>
          <th></th>
          {amountTotal > 0 && (
            <>
              <th css={{ textAlign: 'left', width: '1%', whiteSpace: 'nowrap' }}>
                <Icon icon={money} size={'14px'} css={{ marginRight: '5px', color: '#5aa03e' }} />
                {netLabel}
              </th>
              <th></th>
            </>
          )}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default MediaSalesTable;
