import React from 'react';
import PropTypes from 'prop-types';
import AbstractFormSingleOptionsComponent from '../AbstractFormSingleOptionComponent';
import cx from 'classnames';
import { APP_IS_DEVELOPMENT } from '../../../../util/env';

class OptionBoxRadio extends AbstractFormSingleOptionsComponent {
  render() {
    if (OptionBoxRadio.debug && APP_IS_DEVELOPMENT) {
      console.log(this.__proto__.constructor.name, 'render', this.getValue(), this.options);
    }
    const { textboxClass = '' } = this.props;
    return (
      <div className={`option-box-radio-std ${this.getClassNames()}`}>
        {this.options.map((option) => (
          <div
            key={option.value}
            className={cx({
              [`${this.props.name}-${option.value}`]: true,
              disabled: this.props.disabled || this.isOptionDisabled(option),
              [this.props.optionClass]: true,
            })}
          >
            <input
              type="radio"
              name={this.props.name}
              value={option.value}
              id={`${this.props.name}-${option.value}`}
              checked={this.isOptionSelected(option)}
              disabled={this.props.disabled || this.isOptionDisabled(option)}
              onChange={this.onChange}
            />
            <label
              htmlFor={`${this.props.name}-${option.value}`}
              className={this.props.iconPrefix || option.icon ? 'iconset' : ''}
            >
              {(option.icon || this.props.iconPrefix) && (
                <div className={`${option.icon || this.props.iconPrefix + option.value} iconbox`} />
              )}

              <div className={`textbox ${textboxClass}`}>
                <div className="label">{option.label}</div>
                {option.sublabel && <div className="sublabel">{option.sublabel}</div>}
              </div>
            </label>
          </div>
        ))}
      </div>
    );
  }
}

OptionBoxRadio.propTypes = {
  ...AbstractFormSingleOptionsComponent.propTypes,
  iconPrefix: PropTypes.string,
  value: PropTypes.string,
  optionClass: PropTypes.string,
};

OptionBoxRadio.defaultProps = {
  ...AbstractFormSingleOptionsComponent.defaultProps,
  iconPrefix: '',
  value: '',
  optionClass: '',
};

OptionBoxRadio.debug = false;

export default OptionBoxRadio;
export { OptionBoxRadio };
