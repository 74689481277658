import React, { FC, useState } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';

import { Main } from '../../atoms/Grid';
import { ProtectedRoute, Spinner } from '../../components';
import { stripSlash } from '../../util/urlHelper';
import { QUERY_MODEL_ABO_TOKEN } from '../../graphql/VXModels/queries';

import AboCreate from './AboCreate/AboCreate';
import AboArchive from './AboArchive/Overview/AboArchive';
import { AboStatusFilter } from './AboArchive/Overview/types';
import { AboContentTypesFilter } from './types';
import { AboNavSub } from './components';

type Props = RouteComponentProps & {
  typesFilter?: AboContentTypesFilter;
};

const AboWizard: FC<Props> = (props) => {
  const { loading, error, data } = useQuery(QUERY_MODEL_ABO_TOKEN);
  const [apolloClientAbo, setApolloClientAbo] = useState<ApolloClient<{}>>();

  if (!apolloClientAbo && !loading && !error && data) {
    const { token } = data.model.abo;
    const cache = new InMemoryCache({
      dataIdFromObject: (object) => {
        switch (object.__typename) {
          case 'BenefitType':
            return `${object.__typename}.${object.id}.${(object as { name: string }).name}`; // Hack to avoid BenefitTypes with type and with subType ids to be mixed
          default:
            return defaultDataIdFromObject(object);
        }
      },
    });
    const link = new HttpLink({ uri: 'https://abo.vx-services.net', headers: { Authorization: `Bearer ${token}` }}); // prettier-ignore
    setApolloClientAbo(new ApolloClient({ cache, link }));
  }

  const {
    match: { url },
    typesFilter,
  } = props;
  const path = stripSlash(url);

  return (
    <Main>
      <AboNavSub basePath={path} typesFilter={typesFilter} />

      {apolloClientAbo ? (
        <Switch>
          <ProtectedRoute
            path={`${path}/create`}
            component={AboCreate}
            componentProps={{ apolloClientAbo, typesFilter }}
          />
          <ProtectedRoute
            path={`${path}/active`}
            component={AboArchive}
            componentProps={{ apolloClientAbo, statusFilter: AboStatusFilter.active, typesFilter }}
          />
          <ProtectedRoute
            path={`${path}/inactive`}
            component={AboArchive}
            componentProps={{
              apolloClientAbo,
              statusFilter: AboStatusFilter.inactive,
              typesFilter,
            }}
          />
          {/* Redirect for historical reasons: */}
          <Redirect from={`${path}/archive`} to={`${path}/active`} />
          <Redirect to={`${path}/create`} />
        </Switch>
      ) : (
        <Spinner />
      )}
    </Main>
  );
};

export default AboWizard;
