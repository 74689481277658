import React, { FC } from 'react';
import NavSubService0900 from '../NavSubService0900';
import PhoneSexInactive from './PhoneSexInactive/PhoneSexInactive';
import PhoneSexActive from './PhoneSexActive/PhoneSexActive';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_SERVICE0900 } from '../../../graphql/VXModels/queries';
import { Spinner } from '../../../components';
import PhoneSexSuccessModal from './PhoneSexSuccessModal';
import { Main } from '../../../atoms/Grid';
import GenericEmptyContent from '../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { useDisplayState } from '../../../hooks';
import { transform0900ForFormik } from './utils';

const PhoneSex: FC = () => {
  const { loading, error, data } = useQuery(QUERY_MODEL_SERVICE0900);
  const {
    shouldShow: showSuccessModal,
    show: openSuccessModal,
    hide: closeSuccessModal,
  } = useDisplayState();

  const onSuccess = (): void => {
    openSuccessModal();
  };

  const onClose = (): void => {
    closeSuccessModal();
  };

  if (!loading) {
    const storeData = transform0900ForFormik(data?.model?.service0900);
    const isNumberLocked = storeData?.targetNumber?.isLocked;

    return (
      <Main isMobileEnabled>
        <NavSubService0900 />
        {isNumberLocked ? (
          <PhoneSexInactive storeData={storeData} onSuccess={onSuccess} />
        ) : (
          <PhoneSexActive storeData={storeData} onSuccess={onSuccess} />
        )}
        {/* due to update of cache in second step of ModalPhoneConfirmation.tsx which rerenders everything from this point on - this SuccessModal needs to be placed here instead */}
        {showSuccessModal && <PhoneSexSuccessModal onClose={onClose} />}
      </Main>
    );
  } else if (error) {
    return <GenericEmptyContent />;
  } else return <Spinner />;
};

export default PhoneSex;
