import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../util/ServerUtils';
import ActionTypes from '../../util/constants/ActionTypes';

class OnlineTimeAndUsersActionCreatorsClass {
  getOnlineTimeAndUsersData() {
    ServerUtils.request('GET', '/v1/camtool/user/{userId}/apps/onlineTimeAndUsers', null, function(
      response
    ) {
      OnlineTimeAndUsersActionCreators.receiveData(response);
    });
  }
  receiveData(data) {
    AppDispatcher.dispatch({
      type: ActionTypes.APPS_ONLINETIMEANDUSERS_RECEIVE_DATA,
      data: data,
    });
  }
}

const OnlineTimeAndUsersActionCreators = new OnlineTimeAndUsersActionCreatorsClass();

export { OnlineTimeAndUsersActionCreators };
