import React from 'react';
import { useTheme } from '@material-ui/core';
import InfoCircle from '../atoms/Icon/Validation/InfoCircle';
import CheckCircle from '../atoms/Icon/Validation/CheckIcon';
import QuestionMarkCircle from '../atoms/Icon/Validation/QuestionMarkCircle';
import MinusCircle from '../atoms/Icon/Validation/MinusCircle';
import { getPaletteColor } from './getPaletteColor';
import { GRAY_2 } from '../camtool-styles';
import ExclamationMarkCircle from '../atoms/Icon/Validation/ExclamationMarkCircle';

export type ValidationType = {
  color: Record<string, string>;
  message: string;
  icon: JSX.Element | null;
} | null;

export type LevelType = 'default' | 'primary' | 'secondary' | 'warning' | 'error' | '';
type IconType = null | undefined;

const getIcon = (
  icon: IconType,
  level: LevelType,
  color: ValidationType['color']
): ValidationType['icon'] => {
  if (icon === undefined) {
    switch (level) {
      case 'primary':
        return <InfoCircle key={level} color={color.main} />;
      case 'secondary':
        return <CheckCircle key={level} color={color.main} />;
      case 'warning':
        return <QuestionMarkCircle key={level} color={color.main} />;
      case 'error':
        return <ExclamationMarkCircle key={level} color={color.main} />;
      case 'default':
        return <MinusCircle key={level} color={GRAY_2} />;
      default:
        return <></>;
    }
  }

  return null;
};

const BuildValidationObject = (
  level: LevelType,
  message: string | undefined,
  icon: IconType = undefined
): ValidationType => {
  const color = getPaletteColor(useTheme(), level);
  const parsedIcon = getIcon(icon, level, color);

  return message
    ? {
        color: color,
        message: message,
        icon: parsedIcon,
      }
    : null;
};

export default BuildValidationObject;
