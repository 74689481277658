import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import EmptyContent from '../EmptyContent/EmptyContent';
import LikesItemRenderer from './subcomponents/LikesItemRenderer';
import VideoPlayer from './subcomponents/VideoPlayer';
import format from 'date-fns/format';
import DateFormatter from '../Formatter/DateFormatter';
import { _ } from '../../util/translate';

function isPhoto(url) {
  return url.split('.').some((el) => el === 'jpg' || el === 'png' || el === 'gif' || el === 'svg');
}

// Firefox & Chrome need width
const StyledMediaContainer = styled.section`
  width: 293px;
  flex: 1 0 293px;
  margin-right: 16px;
  height: 520px;
  background-image: url(${(props) => props.photo});
  background-size: cover;
  background-position: center;
  align-self: flex-end;
`;

const StyledTabButton = styled.button`
  padding: 4px 16px;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${(props) => (props.active ? 'transparent' : '#bcbcbc')};
  background-color: ${(props) => (props.active ? '#1f93e9' : 'transparent')};
  color: ${(props) => (props.active ? '#fff' : '#bcbcbc')};
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  &:hover {
    background-color: #4da9ee;
    color: #fff;
    border-color: transparent;
  }
`;

const StyledListContainer = styled.div`
  width: 316px;
  height: 410px;
  flex-direction: column;
  overflow: auto;
  display: block !important;
`;

class StoryPlayer extends PureComponent {
  state = { activeList: 'views' }; // Either 'views' || 'likes'

  setActiveListToViews = () => this.setState({ activeList: 'views' });

  setActiveListToLikes = () => this.setState({ activeList: 'likes' });

  renderList = () => {
    if (this.props[this.state.activeList].length > 0) {
      switch (this.state.activeList) {
        case 'views':
          return this.props.views.map((view, i) => (
            <div key={i} css={{ alignItems: 'center', padding: '8px 0' }}>
              <span className={`icon-eye-open ${css({ paddingRight: 6 })}`} />
              <span>{view} hat Deine Story angeschaut</span>
            </div>
          ));
        case 'likes':
          return this.props.likes.map((like, i) => <LikesItemRenderer key={i} like={like} />);
        default:
          throw 'Invalid list type @renderList';
      }
    }

    return (
      <EmptyContent
        className={css({ height: '100%' })}
        icon="icon-bell"
        title={`Keine ${this.state.activeList} vorhanden`}
      />
    );
  };

  render() {
    const { className, mediaURL, timestamp, views, likes } = this.props;

    if (mediaURL && timestamp)
      return (
        <article className={className}>
          <StyledMediaContainer photo={isPhoto(mediaURL) ? mediaURL : null}>
            {!isPhoto(mediaURL) && <VideoPlayer videoSrc={mediaURL} width={293} height={520} />}
          </StyledMediaContainer>

          <section css={{ flex: '1 0 auto', flexDirection: 'column', color: '#bcbcbc' }}>
            <header
              css={{
                paddingTop: 32,
                paddingBottom: 4,
                borderBottom: '1px solid #bcbcbc',
                whiteSpace: 'nowrap',
              }}
            >
              <h4>
                <span>
                  Deine Story vom <DateFormatter date={timestamp} format="Pp" />
                </span>
                {/*<span css={{ fontWeight: 'normal' }}>*/}
                {/*({format(timestamp, 'Pp')})*/}
                {/*</span>*/}
              </h4>
            </header>

            <div style={{ paddingTop: 16, paddingBottom: 12, alignSelf: 'flex-end' }}>
              <StyledTabButton
                active={this.state.activeList === 'views'}
                onClick={this.setActiveListToViews}
                css={{ marginRight: 4 }}
              >
                <span className="icon-eye-open" />
                <span css={{ paddingRight: 4, paddingLeft: 6 }}>{views.length}</span>
                <span>Views</span>
              </StyledTabButton>

              <StyledTabButton
                active={this.state.activeList === 'likes'}
                onClick={this.setActiveListToLikes}
              >
                <span className={`icon-heart ${css({ paddingRight: 4 })}`} />
                <span css={{ paddingRight: 4, paddingLeft: 6 }}>{likes.length}</span>
                <span>Likes</span>
              </StyledTabButton>
            </div>

            <StyledListContainer>{this.renderList()}</StyledListContainer>
          </section>
        </article>
      );

    return null;
  }
}

StoryPlayer.propTypes = {
  className: PropTypes.string,
  mediaURL: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string),
  likes: PropTypes.arrayOf(PropTypes.shape({ user: PropTypes.string, comment: PropTypes.string })),
};

StoryPlayer.defaultProps = { views: [], likes: [] };

export default StoryPlayer;
