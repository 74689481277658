import React, { FC } from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import {
  VXGAMES_TYPOGRAPHY_STRONG_PARAGRAPH,
  VXGAMES_TYPOGRAPHY_PARAGRAPH,
} from '../../../../utils';
import { convertParticipatingModels } from '../utils';
import { useUserData } from '../../../../../../util/UserData';

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  })
);

interface ParticipantsProps {
  participants: VXGChallengeUserStatistic[] | undefined;
}

const Participants: FC<ParticipantsProps> = ({ participants }) => {
  const classes = useStyles();

  const userData = useUserData();
  const username = userData?.auth?.screenname;

  const participantList = convertParticipatingModels(participants, username);

  return (
    <Box className={classes.box}>
      <Typography style={VXGAMES_TYPOGRAPHY_STRONG_PARAGRAPH} gutterBottom>
        Teilnehmer sind zum Beispiel:
      </Typography>
      <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH}>{participantList}</Typography>
    </Box>
  );
};

export { Participants };
