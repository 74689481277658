import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
// Outside Wizard
import { GRAY_2, GRAY_3, WHITE, BLUE } from '../../../../../camtool-styles';
// Inside Wizard
import { IVoucherTypeButtonProps } from '../../../types';

import {
  IconChatMinutes,
  IconMedia,
  IconMessages,
  IconPhotos,
  IconShowTicket,
  IconTicketShow,
  IconVideos,
} from '../../../Icons';

const StyledButton = styled.button`
  width: 208px;
  height: 232px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_3};
  flex-direction: column;
  align-items: stretch;
  padding: 8px;
  fill: #d9ddde;
  transition: color 250ms, border-color 250ms, fill 140ms;
  &:hover {
    border-color: ${BLUE};
    color: ${BLUE};
    fill: ${BLUE};
    cursor: pointer;

    // specific style for photos and videos ticket
    .voucherVideoOrPhoto {
      fill: ${BLUE};
    }
    .Pfad_1-4 {
      stroke: ${BLUE};
    }

    div:first-of-type {
      border-color: transparent;
      color: ${BLUE};
    }
    p {
      color: ${BLUE};
    }
    span {
      color: ${BLUE};
    }
  }
`;

const StyledDiv = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  border: 1px dashed ${GRAY_3};
  background-repeat: no-repeat;
  background-position: center;
`;

function BenefitsType(props: IVoucherTypeButtonProps): ReactElement {
  const handleClick = (): void => {
    props.onClick(props.type);
  };

  return (
    <div
      css={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: 200 }}
      className={props.className}
    >
      <StyledButton onClick={handleClick}>
        <StyledDiv>
          {props.type === 'MEDIA' && <IconMedia />}
          {props.type === 'VIDEO' && <IconVideos />}
          {props.type === 'PHOTOS' && <IconPhotos />}
          {props.type === 'MESSAGES' && <IconMessages />}
          {props.type === 'CHAT_MINUTES' && <IconChatMinutes />}
          {props.type === 'SHOW_TICKET' && <IconShowTicket />}
          {props.type === 'TICKET_SHOW' && <IconTicketShow />}
        </StyledDiv>

        <p css={{ textAlign: 'center', fontWeight: 'normal', fontSize: 16, marginTop: 4 }}>
          {props.title}
        </p>

        <p css={{ textAlign: 'center', marginTop: 4, color: GRAY_2 }}>
          {props.description ? `(${props.description})` : ''}
        </p>
      </StyledButton>
    </div>
  );
}

export default BenefitsType;
