import React from 'react';
import { Redirect, Switch } from 'react-router';
import ProtectedRoute from '../../components/Routing/ProtectedRoute';
import { stripSlash } from '../../util/urlHelper';

import ProfilesOverview from './ProfilesOverview/ProfilesOverview';
import Common from './Common';
import LA from './LA';
import VX from './VX';

const OnlineProfile = ({ match: { url }, isFeatureFlagCoinsEnabled }) => {
  const path = stripSlash(url);

  return (
    <Switch>
      {!isFeatureFlagCoinsEnabled && <ProtectedRoute path={`${path}/overview`} component={ProfilesOverview} />}
      {!isFeatureFlagCoinsEnabled && <ProtectedRoute path={`${path}/common`} component={Common} />}
      {!isFeatureFlagCoinsEnabled && <ProtectedRoute path={`${path}/visitx`} component={VX} />}
      <ProtectedRoute path={`${path}/lustagenten`} component={LA} />
      <Redirect to={isFeatureFlagCoinsEnabled ? '/dashboard' : `${path}/overview`} />
    </Switch>
  );
};
export default OnlineProfile;
