import React, { FC, useEffect, useRef, Ref, ChangeEvent } from 'react';
import { Box, Tab, Tabs, TabsActions } from '@material-ui/core';
import { ApiLang } from '../../../graphql/VXModels/types';
import { Flag } from '../../index';
import { LiftedLanguageTabsProps } from './types';
import { useTabsStyles } from './styles';

const LiftedLanguageTabs: FC<LiftedLanguageTabsProps> = ({
  language,
  changeLanguage,
  customLanguageArray,
  label,
  error,
  tab,
  setTab,
}) => {
  const languageArray = customLanguageArray || [ApiLang.de, ApiLang.en];
  const classes = useTabsStyles({ error });
  const ref = useRef<Ref<TabsActions>>(null);
  console.log(customLanguageArray);
  // updates tab because underline of tab is not positioned correctly on initial render (on mobile mostly)
  useEffect(() => {
    setTimeout(() => {
      ref.current?.updateIndicator();
    }, 100);
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: ChangeEvent<{}>, newValue: number): void => {
    if (setTab) {
      setTab(newValue);
    }
    changeLanguage(newValue);
  };

  return (
    <Tabs
      classes={{ root: classes.tabs, indicator: classes.indicator }}
      value={tab}
      onChange={handleChange}
      action={ref}
    >
      {languageArray.map((lang) => (
        <Tab
          key={label[lang]}
          classes={{ root: classes.tab, selected: classes.selected }}
          label={
            <Box>
              <Flag lang={lang} /> {label[lang]}
            </Box>
          }
          // ripple effect here is buggy on desktop
          disableRipple
        />
      ))}
    </Tabs>
  );
};

export default LiftedLanguageTabs;
