import React, { FC, useState, useRef } from 'react';
import { Paper, Box, useMediaQuery, useTheme, Typography, IconButton } from '@material-ui/core';
import { WHITE } from '../../../../../camtool-styles';
import { VXGAMES_DARK_GREY, VXGAMES_TYPOGRAPHY_H2 } from '../../../utils';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InView from 'react-intersection-observer';
import { Achievement, AchievementType, CompetitionAchievementsProps } from './';
import { useAchievementSliderStyles } from '.';

const SLOT_HEIGHT = 500; // CHANGE THIS VALUE IF WIDTH OF ACHIEVEMENT CHANGES
const SNAP_SCROLL_THRESHOLD = SLOT_HEIGHT / 2;

enum ScrollDirectionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}

const CompetitionAchievements: FC<CompetitionAchievementsProps> = ({ achievements }) => {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const ref = useRef<HTMLDivElement | null>(null);
  const classes = useAchievementSliderStyles();
  const [reachedBeginning, setReachedBeginning] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);

  const scrollBy = (direction: ScrollDirectionEnum) => {
    switch (direction) {
      case ScrollDirectionEnum.RIGHT:
        if (!reachedEnd) {
          ref?.current?.scrollBy({ left: SNAP_SCROLL_THRESHOLD, behavior: 'smooth' });
        }
        break;
      case ScrollDirectionEnum.LEFT:
        if (!reachedBeginning) {
          ref?.current?.scrollBy({ left: -SNAP_SCROLL_THRESHOLD, behavior: 'smooth' });
        }
        break;
    }
  };

  return (
    <Paper variant="elevation" style={{ backgroundColor: WHITE, padding: '40px' }}>
      <Box ml={5} mb={3}>
        <Typography
          style={{ ...VXGAMES_TYPOGRAPHY_H2, ...{ color: VXGAMES_DARK_GREY } }}
          gutterBottom
        >
          {`Deine VXGames ${currentYear} Trophäen`}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <IconButton disabled={reachedBeginning} onClick={() => scrollBy(ScrollDirectionEnum.LEFT)}>
          <ArrowBackIosIcon />
        </IconButton>
        <Box className={classes.root} ref={ref} display="flex" gridGap="50px" overflow="auto">
          {achievements.map((achievement: AchievementType, index: number) => {
            const observeRequired = index === 0 || index === achievements.length - 1;

            return observeRequired ? (
              <InView
                key={index}
                as="div"
                // trigger inView if 20% of component is visible
                threshold={0.2}
                css={{
                  display: 'flex',
                  flex: `0 0 ${isMobile ? '100%' : 'calc(25% - 50px)'}`,
                  flexDirection: 'column',
                  minWidth: '120px',
                }}
                onChange={(inView: unknown) => {
                  if (achievements.length < 2) {
                    setReachedBeginning(true);
                    setReachedEnd(true);
                  }
                  if (index === 0) {
                    if (inView) {
                      setReachedBeginning(true);
                    } else setReachedBeginning(false);
                  } else if (index === achievements.length - 1) {
                    if (inView) {
                      setReachedEnd(true);
                    } else setReachedEnd(false);
                  }
                }}
              >
                <Achievement
                  type={achievement.type}
                  participationStatus={achievement.participationStatus}
                  title={achievement.title}
                  trophyDescription={achievement.trophyDescription}
                  completedDate={achievement.completeDate}
                  isMobile={isMobile}
                  key={index}
                />
              </InView>
            ) : (
              <Achievement
                type={achievement.type}
                participationStatus={achievement.participationStatus}
                title={achievement.title}
                trophyDescription={achievement.trophyDescription}
                completedDate={achievement.completeDate}
                isMobile={isMobile}
                key={index}
              />
            );
          })}
        </Box>
        <IconButton disabled={reachedEnd} onClick={() => scrollBy(ScrollDirectionEnum.RIGHT)}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

export { CompetitionAchievements };
