import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { BLACK } from '../../../../../camtool-styles';
import {
  MediaUploadInputLabelSize,
  MediaUploadInputLayoutDirection,
} from '../../../../../molecules/FileUpload/MediaUploadInput';
import getAudioOptions from '../../../../../molecules/FileUpload/MediaUploadInput/Preview/getAudioOptions';
import getVideoOptions from '../../../../../molecules/FileUpload/MediaUploadInput/Preview/getVideoOptions';
import { UploadLabel } from '../../../../../molecules/FileUpload/MediaUploadInput/UploadLabel';
import {
  PreviewFile,
  PreviewVideoThumbnailIconSize,
} from '../../../../../molecules/FileUpload/PreviewFile';
import { _ } from '../../../../../util/translate';
import { stripSlash } from '../../../../../util/urlHelper';
import { MobileUserLifeCycleStep } from '../types';

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      margin: '10px 0px', // cancels out margin of spacing prop
      paddingTop: '8px',
      borderTop: `1px solid ${BLACK}`,
    },
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    arrow: {
      alignSelf: 'center',
    },
  })
);

interface AutoMailGridPreviewItemProps {
  lifecycle: MobileUserLifeCycleStep;
}

const AutoMailGridPreviewItem: FC<AutoMailGridPreviewItemProps> = ({ lifecycle }) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const path = stripSlash(url);

  const isCompleteLifecycle =
    lifecycle?.delayMinutes &&
    lifecycle?.delayDisplayUnit &&
    lifecycle?.delaySchedule &&
    lifecycle?.text;

  return (
    <Link
      css={{
        textDecoration: 'none',
        color: 'unset',
        '&:last-of-type': {
          borderBottom: `1px solid ${BLACK}`,
          paddingBottom: '10px',
        },
      }}
      to={`${path}/${lifecycle?.id}`}
    >
      <Grid spacing={2} container className={classes.row}>
        <Grid item xs={2}>
          {lifecycle?.file ? (
            <Box width="41px" height="41px">
              <PreviewFile
                css={{ height: '100%' }}
                file={lifecycle.file}
                videoOptions={getVideoOptions(PreviewVideoThumbnailIconSize.sm)}
                audioOptions={getAudioOptions()}
              />
            </Box>
          ) : (
            <UploadLabel
              css={{ backgroundColor: 'white', padding: '6px' }}
              label=""
              labelSize={MediaUploadInputLabelSize.sm}
              layoutDirection={MediaUploadInputLayoutDirection.vertical}
            />
          )}
        </Grid>
        <Grid container item xs={8} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {isCompleteLifecycle
                ? `${lifecycle?.delayMinutes} ${_(
                    `mailings:telegram.autoMail.delayUnit.${lifecycle?.delayDisplayUnit}`
                  )} ${_('mailings:telegram.autoMail.delivery.login')}, ${_(
                    `mailings:telegram.autoMail.deliverySchedule.${lifecycle?.delaySchedule}`
                  )}`
                : _('mailings:telegram.autoMail.delivery.placeholder')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.text}>
              {isCompleteLifecycle
                ? lifecycle?.text
                : _('marketing:admails.create.message.requiredMessage')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1} className={classes.arrow}>
          <ArrowForwardIosIcon fontSize="small" />
        </Grid>
      </Grid>
    </Link>
  );
};

export default AutoMailGridPreviewItem;
