import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { _ } from '../../../../../../util/translate';
import { Box } from '@material-ui/core';
import FieldDescriptionValidation from './FieldDescriptionValidation';
import { ApiLang } from '../../../../../../graphql/VXModels/types';
import { sanitizeLang } from '../../../../../../util/Translator';

const FieldDescription: FC = () => {
  const languageArray: ApiLang[] = [ApiLang.de, ApiLang.en];
  const [language, setLanguage] = useState<ApiLang>(ApiLang.de);

  const changeLanguage = (nthLanguage: number): void => {
    setLanguage(languageArray[nthLanguage]);
  };

  return (
    <Box mb="20px" flex="1">
      <Field type="text" name={`descriptionTranslation[${language}]`}>
        {({ field, form: { errors, touched, setFieldValue } }: FieldProps): ReactElement => {
          const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
            setFieldValue(field.name, event.target.value);
          return (
            <FieldDescriptionValidation
              language={language}
              changeLanguage={changeLanguage}
              placeholder={_(`benefits:abo.detailsPage.descriptionPlaceholder`, {
                lng: sanitizeLang(language),
              })}
              onChange={onChange}
              field={field}
              errors={errors}
              touched={touched}
            />
          );
        }}
      </Field>
    </Box>
  );
};

export default FieldDescription;
