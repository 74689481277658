import React, { FC } from 'react';

import { Button, Select } from '../../../../atoms';

import {
  AboBenefitSubType,
  AboContentType,
  AboRoute,
  AboWizardActionType,
  IPageProps,
} from '../../types';

import PageLayout from '../PageLayout';
import { STORE_INITIAL_VALUES } from '../util/hooks/useAboReducer';
import { _ } from '../../../../util/translate';

const serializeAboConfiguration = (type: AboContentType, subType: AboBenefitSubType): string =>
  JSON.stringify([type, subType]);

const AboRedeemPageMedia: FC<IPageProps> = ({ dispatch, state }) => {
  const handleRedeemTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const jsonValue = event.target.value;
    const [type, subType] = jsonValue
      ? JSON.parse(jsonValue)
      : [AboContentType.Media, AboBenefitSubType.None];

    const {
      aboContentType: currentType,
      aboRedeemDetails: { type: currentSubType },
    } = state;

    const changeType = type !== currentType;

    if (changeType) {
      dispatch({ type: AboWizardActionType.SetAboContentType, payload: { aboContentType: type } });
    }
    if (changeType ? subType !== AboBenefitSubType.None : subType !== currentSubType) {
      // SetAboContentType already resets the aboRedeemDetails
      dispatch({
        type: AboWizardActionType.SetAboRedeemDetails,
        payload: {
          aboRedeemDetails: {
            type: subType,
            data: { amount: '' },
          },
        },
      });
    }
  };

  const handleRedeemDetailsTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const defaultData =
      event.target.value === 'QUANTITY' ? 1 : STORE_INITIAL_VALUES.aboRedeemDetails.data[1].data;

    dispatch({
      type: AboWizardActionType.SetAboRedeemDetails,
      payload: {
        aboRedeemDetails: {
          ...state.aboRedeemDetails,
          data: [event.target.value, { data: defaultData, access: '' }], // [set, reset to initial values]
        },
      },
    });
  };

  const handleRedeemDetailsDataChange = (value: string | number) => {
    dispatch({
      type: AboWizardActionType.SetAboRedeemDetails,
      payload: {
        aboRedeemDetails: {
          ...state.aboRedeemDetails,
          data: [
            state.aboRedeemDetails.data[0],
            { data: value, access: state.aboRedeemDetails.data[1].access },
          ],
        },
      },
    });
  };

  const handleRedeemDetailsAccessChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    dispatch({
      type: AboWizardActionType.SetAboRedeemDetails,
      payload: {
        aboRedeemDetails: {
          ...state.aboRedeemDetails,
          data: [
            state.aboRedeemDetails.data[0],
            { data: state.aboRedeemDetails.data[1].data, access: event.target.value },
          ],
        },
      },
    });
  };

  const handleBackClick = () => {
    dispatch({ type: AboWizardActionType.GoBack, payload: {} });
  };

  const handleContinueClick = () => {
    dispatch({ type: AboWizardActionType.GoToRoute, payload: { route: AboRoute.AboDetailsPage } });
  };

  const canContinue = () => {
    return (
      state.aboRedeemDetails.type === AboBenefitSubType.FlatRate ||
      state.aboRedeemDetails.type === AboBenefitSubType.Download
    );
  };

  const optionsSubscriptionType = [
    {
      label: _('benefits:abo.allmedia.options.flatRate'),
      value: serializeAboConfiguration(AboContentType.Media, AboBenefitSubType.FlatRate),
    },
    {
      label: _('benefits:abo.photos.options.flatRate'),
      value: serializeAboConfiguration(AboContentType.Photos, AboBenefitSubType.FlatRate),
    },
    {
      label: _('benefits:abo.videos.options.flatRate'),
      value: serializeAboConfiguration(AboContentType.Videos, AboBenefitSubType.FlatRate),
    },
    {
      label: _('benefits:abo.videos.options.download'),
      value: serializeAboConfiguration(AboContentType.Videos, AboBenefitSubType.Download),
    },
    // {
    //   label: _('benefits:abo.allmedia.options.newContent'),
    //   value: [AboContentType.Media, AboRedeemType.MediaNewContent],
    // },
  ];

  return (
    <PageLayout
      title={_(`benefits:abo.allmedia.title`)}
      subtitle={_(`benefits:abo.allmedia.subtitle`)}
      footer={() => (
        <section css={{ justifyContent: 'space-between', marginTop: 32 }}>
          <Button theme="border-gray" onClick={handleBackClick} css={{ alignItems: 'center' }}>
            <span className="icon-chevron-left" css={{ fontSize: 8, marginRight: 8 }} />
            <span>{_('benefits:texts.back')}</span>
          </Button>
          <Button
            theme={canContinue() ? 'blue' : 'gray'}
            onClick={handleContinueClick}
            disabled={!canContinue()}
            css={{ alignItems: 'center' }}
          >
            <span>{_('benefits:texts.continue')}</span>
            <span className="icon-chevron-right" css={{ fontSize: 8, marginLeft: 8 }} />
          </Button>
        </section>
      )}
    >
      <section css={{ flexDirection: 'column' }}>
        <label css={{ flexDirection: 'column' }}>
          <span css={{ fontSize: 14, margin: '0 0 2px 2px' }}>
            {_('benefits:abo.texts.subscriptionType')}
          </span>

          <Select
            placeholder={_('common:dropdown.placeholder.pleaseSelect')}
            options={optionsSubscriptionType}
            onChange={handleRedeemTypeChange}
            value={serializeAboConfiguration(state.aboContentType, state.aboRedeemDetails.type)}
            css={{ width: 448 }}
          />
        </label>

        {/* THIS WILL NEED A BIT OF AN UPDATE BEFORE WE CAN USE IT (VERALTET)
        {state.aboRedeemDetails.type === AboRedeemType.MediaNewContent && (
          <label css={{ flexDirection: 'column', marginTop: 16 }}>
            <span css={{ fontSize: 14 }}>Abo Optionen wählen</span>
            <StyledSelect
              onChange={handleRedeemDetailsTypeChange}
              value={state.aboRedeemDetails.data[0]}
              css={{ width: 300 }}
            >
              <option value="">Bitte wählen</option>
              <option value="PERIOD">Zeitraum</option>
              <option value="QUANTITY">Anzahl</option>
            </StyledSelect>
          </label>
        )}

        {state.aboRedeemDetails.data[0] === 'PERIOD' && (
          <label css={{ flexDirection: 'column', marginTop: 16 }}>
            <span css={{ fontSize: 14 }}>Zeitraum</span>

            <div css={{ alignItems: 'center' }}>
              <DatePicker
                from={state.aboRedeemDetails.data[1].data.split(',')[0]}
                to={state.aboRedeemDetails.data[1].data.split(',')[1]}
                onRangeChange={handleRedeemDetailsDataChange}
                disablePastDate={true}
                css={{ borderRadius: 4, border: `1px solid ${GRAY_3}`, width: 300 }}
              />

              <p css={{ margin: '0 16px' }}>Zugriff auf die letzten</p>

              <StyledSelect
                css={{ width: 150 }}
                onChange={handleRedeemDetailsAccessChange}
                value={state.aboRedeemDetails.data[1].access}
              >
                <option value="">Bitte auswählen</option>
                <option value="3">3 Monate</option>
                <option value="6">6 Monate</option>
                <option value="12">12 Monate</option>
              </StyledSelect>
            </div>
          </label>
        )}

        {state.aboRedeemDetails.data[0] === 'QUANTITY' && (
          <div css={{ flexDirection: 'column' }}>
            <label css={{ flexDirection: 'column', marginTop: 16 }}>
              <span css={{ fontSize: 14 }}>Maximale Anzahl</span>

              <Range
                min={1}
                max={10}
                step={1}
                initialValue={state.aboRedeemDetails.data[1].data}
                onChange={handleRedeemDetailsDataChange}
                label="Medien"
              />
            </label>

            <label css={{ flexDirection: 'column', marginTop: 16 }}>
              <span css={{ fontSize: 14 }}>Zugriff auf die letzten</span>

              <StyledSelect
                css={{ width: 150 }}
                onChange={handleRedeemDetailsAccessChange}
                value={state.aboRedeemDetails.data[1].access}
              >
                <option value="">Bitte auswählen</option>
                <option value="10">10 Uploads</option>
                <option value="20">20 Uploads</option>
                <option value="30">30 Uploads</option>
              </StyledSelect>
            </label>
          </div>
        )}*/}
      </section>
    </PageLayout>
  );
};

export default AboRedeemPageMedia;
