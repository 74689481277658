import React, { FC } from 'react';
import { Toolbar as MuiToolbar } from '@material-ui/core';
import { Localization, Icons } from 'material-table';
import ToolbarSearch, { ToolbarSearchProps } from './ToolbarSearch';
import { DataManager } from './types';

type ToolbarProps = {
  // actions: PropTypes.array,
  // columns: PropTypes.array,
  // columnsButton: PropTypes.bool,
  // components: PropTypes.object.isRequired,
  // getFieldValue: PropTypes.func.isRequired,
  onColumnsChanged: () => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  selectedRows: {}[];
  title: React.ReactElement | string;
  showTextRowsSelected: boolean;
  toolbarButtonAlignment: 'left' | 'right';
  // renderData: PropTypes.array,
  // data: PropTypes.array,
  // exportAllData: PropTypes.bool,
  // exportButton: PropTypes.oneOfType([
  //                                     PropTypes.bool,
  //                                     PropTypes.shape({ csv: PropTypes.bool, pdf: PropTypes.bool }),
  //                                   ]),
  // exportDelimiter: PropTypes.string,
  // exportFileName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  // exportCsv: PropTypes.func,
  // exportPdf: PropTypes.func,
  // eslint-disable-next-line @typescript-eslint/ban-types
  classes: {};
  showTitle: boolean;
  search: boolean;
  localization: Localization['toolbar'];
  dataManager: DataManager;
  icons: Icons;
} & ToolbarSearchProps;

const unwrapSearchProps = ({
  localization,
  searchAutoFocus,
  searchFieldAlignment,
  showTitle,
  searchText,
  searchFieldVariant,
  searchFieldStyle,
  onSearchChanged,
  icons,
  dataManager,
}: ToolbarProps): ToolbarSearchProps => ({
  localization,
  searchAutoFocus,
  searchFieldAlignment,
  showTitle,
  searchText,
  searchFieldVariant,
  searchFieldStyle,
  icons,
  dataManager,
  onSearchChanged,
});

const Toolbar: FC<ToolbarProps> = (props) => {
  const { title, showTitle, search } = props;
  const searchProps = unwrapSearchProps(props);

  return (
    <MuiToolbar
      // className={classNames(classes.root, {
      //   [classes.highlight]:
      //     this.props.showTextRowsSelected && selectedRows && this.props.selectedRows.length > 0,
      // })}
      disableGutters
    >
      {showTitle && title && <div>{title}</div>}
      {search && <ToolbarSearch {...searchProps} />}
      {/* no support for searchFieldAlignment yet, YAGNI */}
      {/* no support for toolbarButtonAlignment yet, YAGNI */}
      {/*{search && searchFieldAlignment === 'left' && <ToolbarSearch {...searchProps} />}*/}
      {/*{toolbarButtonAlignment === 'left' && <div></div>}*/}
      {/*/!*<div className={classes.spacer} />*!/*/}
      {/*{search && searchFieldAlignment === 'right' && <ToolbarSearch {...searchProps} />}*/}
      {/*{toolbarButtonAlignment === 'right' && <div></div>}*/}
    </MuiToolbar>
  );
};

export default Toolbar;
