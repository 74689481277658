import * as React from 'react';
import styled from '@emotion/styled';
import FlagButton from '../../atoms/FlagButton';
import { Lang } from '../../util/constants/Lang';
import { ApiLang } from '../../util/constants/ApiLang';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

type Value = { [key in Lang]: string };
type Errors = { [key in Lang]: string[] };

interface IProps {
  children: any;
  className?: string;
  lang: Lang[];
  maxLength?: number | null;
  name: string;
  uiLang?: ApiLang;
  placeholder?: string;
  initialValues?: Value;
  validator?: (text: string) => string[] | void;
}

interface IState {
  currentLanguage: Lang;
  values: Value;
  errors: Errors;
}

class MultilanguageField extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    className: '',
    maxLength: null,
    placeholder: '',
    uiLang: 'de',
    validator: () => [],
  };

  public state = {
    currentLanguage: this.props.lang[0],
    errors: this.props.lang.reduce<any>((acc: Errors, k: Lang) => ({ ...acc, [k]: [] }), {}),
    values: this.props.lang.reduce<any>((acc: Value, k: Lang) => ({ ...acc, [k]: '' }), {}),
  };

  public componentDidMount() {
    const { initialValues } = this.props;
    if (typeof initialValues === 'object' && Object.keys(initialValues).length > 0) {
      this.setState({ values: initialValues });
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.lang.length !== prevProps.lang.length) {
      this.setState({
        currentLanguage: this.props.lang[0],
        errors: this.props.lang.reduce<any>((acc: Errors, k: Lang) => ({ ...acc, [k]: [] }), {}),
        values: this.props.lang.reduce<any>(
          (acc: Value, k: Lang) => ({ ...acc, [k]: this.state.values[k] || '' }),
          {}
        ),
      });
    }
  }

  public handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState(({ errors, values, currentLanguage }) => ({
      errors: { ...errors, [currentLanguage]: this.props.validator(value) },
      values: { ...values, [currentLanguage]: value },
    }));
  };

  public handleLanguageChange = (lang: Lang) => {
    if (lang !== this.state.currentLanguage) {
      this.setState({ currentLanguage: lang });
    }
  };

  public render() {
    const { lang, maxLength, name, placeholder, className } = this.props;
    const { currentLanguage, values, errors } = this.state;

    return (
      <Container className={className}>
        <div css={{ justifyContent: 'space-between' }}>
          <div>
            {lang.map((lang) => (
              <FlagButton
                key={lang}
                active={lang === currentLanguage}
                lang={lang}
                onClick={() => this.handleLanguageChange(lang)}
                disabled={lang.length < 1}
              >
                {lang}
              </FlagButton>
            ))}
          </div>
          <div css={{ alignItems: 'flex-end', padding: '0 0 2px 2px', color: '#bcbcbc' }}>
            {maxLength && maxLength > 0 ? (
              <span>
                {values[currentLanguage] ? values[currentLanguage].length : 0} / {maxLength}
              </span>
            ) : (
              <span>{values[currentLanguage] ? values[currentLanguage].length : 0}</span>
            )}
          </div>
        </div>
        <div>
          {this.props.children({
            maxLength,
            onChange: this.handleValueChange,
            placeholder: `${placeholder} [${currentLanguage.toUpperCase()}]`,
            value: values[currentLanguage] ? values[currentLanguage] : '',
          })}
        </div>
        <div>{errors[currentLanguage][0]}</div>
        {this.props.lang.map((lang) => {
          return (
            <input
              name={`${name}_${lang}`}
              value={values[lang] || ''} // todo: check why it is undefined when add a new language
              type="hidden"
              key={`${name}_${lang}`}
            />
          );
        })}
      </Container>
    );
  }
}

export default MultilanguageField;
