import React, { ReactElement } from 'react';

const BenefitTicket = (): ReactElement => {
  return (
    <g id="Gutschein" transform="translate(-2279 895)">
      <path
        id="Pfad_11719"
        data-name="Pfad 11719"
        d="M420.1,238.069l-19.951-.125a2.005,2.005,0,0,1-1.976-2l.018-2.769a2.99,2.99,0,0,0,.04-5.98l.03-2.758a2.006,2.006,0,0,1,2-1.976l19.93.144a2.014,2.014,0,0,1,1.976,2.006l-.019,2.749a2.99,2.99,0,0,0-.042,5.98l-.007,2.758a2.006,2.006,0,0,1-2,1.976Z"
        transform="translate(1880.824 -1113.264)"
        fill="currentColor"
      />
      <path
        id="Pfad_11720"
        data-name="Pfad 11720"
        d="M0,0H24V24H0Z"
        transform="translate(2279 -895)"
        fill="none"
      />
    </g>
  );
};

export default BenefitTicket;
