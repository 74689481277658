import { useState, SetStateAction, Dispatch } from 'react';

interface DisplayState {
  shouldShow: boolean;
  setShouldShow: Dispatch<SetStateAction<boolean>>;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

const useDisplayState = (initialState = false): DisplayState => {
  const [shouldShow, setShouldShow] = useState<boolean>(initialState);

  const show = (): void => setShouldShow(true);
  const hide = (): void => setShouldShow(false);
  const toggle = (): void => setShouldShow((current) => !current);

  return { shouldShow, setShouldShow, show, hide, toggle };
};

export default useDisplayState;
