import createTypography, { Typography } from '@material-ui/core/styles/createTypography';
import palette from './palette';

const typography: Typography = createTypography(palette, {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  fontSize: 12,
  // normal text / paragraph
  body1: {
    fontSize: '14px',
    color: palette.grey[800],
  },
  // titles
  h2: {
    fontSize: 32,
    color: palette.grey[900],
    fontWeight: 400,
  },
  h3: {
    fontSize: '18px',
    color: palette.grey[800],
    fontWeight: 600,
  },
  h5: {
    fontSize: '16px',
  },
  h6: {
    fontSize: '14px',
    fontWeight: 800,
  },
  // subtitles
  subtitle1: {
    fontSize: '12px',
    color: palette.grey[800],
  },
  subtitle2: {
    fontSize: 10,
    color: palette.grey[600],
  },
  caption: {
    fontSize: '16px',
    fontWeight: 600,
  },
  button: {
    fontSize: '16px',
    textTransform: 'none',
  },
});

export default typography;
