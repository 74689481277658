import styled from '@emotion/styled';
import React, { memo } from 'react';
import { BLACK_1 } from '../../../camtool-styles';

const StyledInput = styled.input`
  display: none;
  &:checked {
    & + div {
      background-color: #1f93e9;
      color: #fff;
      &:hover {
        background-color: #3bacf7;
      }
    }
`;

const StyledDiv = styled('div')`
  padding: 0 24px;
  background-color: #fff;
  color: ${BLACK_1};
  height: 32px;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #e5e5e5;
  }
`;

interface IProps {
  name: string /** Formik uses this prop  */;
  value: string /** Formik uses this prop  */;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void /** Formik uses this prop  */;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void /** Formik uses this prop  */;
  text?: string;
  checked: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

// prettier-ignore
const CheckboxField = ({ name, value, onChange, onBlur, text, checked, children, className, disabled }: IProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onChange === "function") onChange(event)
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onBlur === "function") onBlur(event)
  };

  return (
    <label className={className}>
      <StyledInput
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        checked={checked}
        type="checkbox"
        disabled={disabled}
      />
      <StyledDiv css={{ borderColor: checked ? 'transparent' : '#c0c0c0' }}>{text || children}</StyledDiv>
    </label>
  );
};

export default memo(CheckboxField);
