import {Scopes} from '../../util/scope';
import {APP_BASE_PATH} from '../../util/env';
import {AppScope} from '../../graphql/VXModels/types';

export enum Slugs {
    HELPCENTER_VXSPLITTER = 'helpcenter-vxsplitter',
    HELPCENTER_LOVENSE = 'helpcenter-lovense',
}

type Links = Partial<
    Record<AppScope | Scopes | '_default', Partial<Record<Slugs | '_default', string>>>
>;

export const DEFAULT = '_default';

// @todo generate this from backend, perhaps?
export const links: Links = {
    [DEFAULT]: {
        [DEFAULT]: APP_BASE_PATH,
        [Slugs.HELPCENTER_VXSPLITTER]:
            'https://support.vxmodels.com',
        [Slugs.HELPCENTER_LOVENSE]:
            'https://support.vxmodels.com',
    },
};
