import React, { FC } from 'react';
import styled from '@emotion/styled';
import { vip30Logo } from '../assets';
import { _ } from '../../../../util/translate';
import { BREAKPOINT_SMALL } from '../breakpoints';

const Container = styled.div`
  flex-direction: row;
  align-self: center;
  align-items: center;
  padding: 22px 34px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    flex-direction: column;
    padding: 18px 15px 2px;
  }
`;

const VIP30Logo = styled.img`
  width: 96px;
`;

const Text = styled.div`
  max-width: 461px;
  flex-direction: column;
  margin-left: 34px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-top: 15px;
    margin-left: 0;
  }
`;

const Header = styled.h2`
  font-size: 15px;
  margin-bottom: 7px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const List = styled.ul`
  list-style-position: outside;
  padding-left: 15px;
`;

const ListItem = styled.li`
  margin-bottom: 7px;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    margin-bottom: 16px;
  }
`;

const VIP30Explanation: FC = () => (
  <Container>
    <VIP30Logo src={vip30Logo} />
    <Text>
      <Header>{_('modalbox:dailyBonus.vip30ExplainHeader')}</Header>
      <List>
        <ListItem>{_('modalbox:dailyBonus.vip30ExplainList.item1')}</ListItem>
        <ListItem>{_('modalbox:dailyBonus.vip30ExplainList.item2')}</ListItem>
        <ListItem>{_('modalbox:dailyBonus.vip30ExplainList.item3')}</ListItem>
        <ListItem>{_('modalbox:dailyBonus.vip30ExplainList.item4')}</ListItem>
      </List>
    </Text>
  </Container>
);

export default VIP30Explanation;
