import React from 'react';

import { Lang } from '../../util/constants/Lang';

import CZ_FLAG from './icons/cz.png';
import DA_FLAG from './icons/da.png';
import DE_FLAG from './icons/de.png';
import EN_FLAG from './icons/en.png';
import ES_FLAG from './icons/es.png';
import FI_FLAG from './icons/fi.png';
import FR_FLAG from './icons/fr.png';
import HU_FLAG from './icons/hu.png';
import IT_FLAG from './icons/it.png';
import NL_FLAG from './icons/nl.png';
import PL_FLAG from './icons/pl.png';
import PT_FLAG from './icons/pt.png';
import RO_FLAG from './icons/ro.png';
import RU_FLAG from './icons/ru.png';
import TR_FLAG from './icons/tr.png';
import CH_FLAG from './icons/ch.png';
import AT_FLAG from './icons/at.png';
import { _ } from '../../util/translate';

// /* prettier-ignore */
// export { CZ_FLAG, DA_FLAG, DE_FLAG, EN_FLAG, ES_FLAG, FI_FLAG, FR_FLAG, HU_FLAG, IT_FLAG, NL_FLAG, PL_FLAG, PT_FLAG, RO_FLAG, RU_FLAG, TR_FLAG, CH_FLAG, AT_FLAG };

function getFlag(lang: Lang) {
  switch (lang) {
    case 'cz':
      return CZ_FLAG;
    case 'da':
      return DA_FLAG;
    case 'de':
      return DE_FLAG;
    case 'en':
      return EN_FLAG;
    case 'es':
      return ES_FLAG;
    case 'fi':
      return FI_FLAG;
    case 'fr':
      return FR_FLAG;
    case 'hu':
      return HU_FLAG;
    case 'it':
      return IT_FLAG;
    case 'nl':
      return NL_FLAG;
    case 'pl':
      return PL_FLAG;
    case 'pt':
      return PT_FLAG;
    case 'ro':
      return RO_FLAG;
    case 'ru':
      return RU_FLAG;
    case 'tr':
      return TR_FLAG;
    case 'ch':
      return CH_FLAG;
    case 'at':
      return AT_FLAG;
  }
}

export const getLabel = (lang: Lang) => _(`enum:common.languages.${lang}`);

interface IProps {
  lang: Lang;
  className?: string;
}

const Flag = ({ lang, className }: IProps) => {
  return (
    <img src={getFlag(lang)} alt={getLabel(lang)} className={className} css={{ height: 15 }} />
  );
};

export default Flag;
