import { useQuery } from '@apollo/react-hooks';
import { Context } from '../../../graphql/VXServicesTelegram/types';
import { QUERY_CLIENT_CONTEXT } from '../graphql/queries';
import { useVXServicesTelegramClient } from '../../../graphql';

export const useApolloContext = (): Context => {
  const client = useVXServicesTelegramClient();
  const { data } = useQuery(QUERY_CLIENT_CONTEXT, { client });

  return data?.__context || { token: undefined };
};

export default useApolloContext;
