import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import ActionTypes from '../../util/constants/ActionTypes';
import { EventEmitter } from 'events';

let CHANGE_EVENT = 'SettingsStore.change';

let _settings = {
  chatSettings: {
    testChat: {
      isOnlineWithSSW: false,
    },
    chatPrices: {
      normalChatMinPrice: 1.79,
      normalChatMaxPrice: 3.0,
      normalChatPriceStep: 0.01,
      singleChatMinPrice: 2.79,
      singleChatMaxPrice: 6.0,
      singleChatPriceStep: 0.01,
      messengerRange: { min: 0, max: 0.99 },
    },
    chatOptions: {
      livePreview: 'forbidden',
      voyeurInSingleChat: '',
      soundForVoyeurInSingleChat: '',
      soundForVoyeurInSingleChatDisabled: true,
    },
    savingStatus: '',
    softChat: {
      status: 'disabled',
    },
    conversionChat: {
      status: 'notset',
    },
    heatmap: {
      status: 'enabled',
      infoText: {},
    },
    freeChat: {
      status: 'disabled',
    },
    partyChat: {
      status: 'disabled',
    },
  },
  notifications: {
    isNewsletterAllowed: true,
    isSystemMailsAllowed: true,
    isWeeklyReportsAllowed: true,
    savingStatus: '',
  },
};

class SettingsStoreClass extends EventEmitter {
  emitChange(store) {
    this.emit(CHANGE_EVENT + '.' + store);
  }
  addChangeListener(store, callback) {
    this.on(CHANGE_EVENT + '.' + store, callback);
  }
  removeChangeListener(store, callback) {
    this.removeListener(CHANGE_EVENT + '.' + store, callback);
  }
  get(store) {
    if (!_settings[store]) {
      throw new InvalidStoreException(store);
    }
    return _settings[store];
  }
}

function InvalidStoreException(store) {
  this.store = store;
  this.message = 'Invalid store';
  this.toString = function() {
    return this.message + ': ' + this.store;
  };
}

const SettingsStore = new SettingsStoreClass();

AppDispatcher.register(function(payload) {
  switch (payload.type) {
    case ActionTypes.SETTINGS_RECEIVE_DATA:
      let store = payload.store;
      _settings[store] = update(_settings[store], { $merge: payload.data });
      SettingsStore.emitChange(store);
      break;
    default:
  }
});

export { SettingsStore };
