import ApolloClient from 'apollo-client';
import axios from 'axios';
import { REACT_APP_VXSERVICES_ABO_SERVICE_UPLOAD_URL } from '../../../../../../util/env';
import { MUTATION_BUILDER_REQUEST_PICTURE_UPLOAD } from '../../../../queries';

const UPLOAD_URL = `${REACT_APP_VXSERVICES_ABO_SERVICE_UPLOAD_URL}`;

interface UploadResult {
  url: string;
}

export const uploadFile = async (uuid: string, file: File): Promise<UploadResult> => {
  const formData = new FormData();
  formData.append('uuid', uuid);
  formData.append('file', file, file.name);

  const {
    data: { url },
  } = await axios.post(`${UPLOAD_URL}?uuid=${uuid}`, formData);

  return { url };
};

export const getPictureUuid = async (apolloClientAbo: ApolloClient<any>): Promise<string> => {
  let result;

  try {
    result = await apolloClientAbo.mutate({
      mutation: MUTATION_BUILDER_REQUEST_PICTURE_UPLOAD,
    });
  } catch (err) {
    throw new Error(`Error while fetching uuid: ${err.stack}`);
  }

  const { data, errors } = result;

  if (Array.isArray(errors) && errors.length > 0)
    throw new Error(`Failed to fetch uuid: ${errors.map((err) => err.stack).join(' ')}`);

  const uuid: string | undefined = data?.builder?.requestPictureUpload || undefined;

  if (!uuid) throw new Error(`No uuid returned by service: ${JSON.stringify(data)}`);

  return uuid;
};
