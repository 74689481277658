import React, { Component } from 'react';
import { ButtonStyled } from './styles';

interface IProps {
  onClick: (optionId: string) => void;
  tagId: string;
  className: string;
  disabled?: boolean;
  children: any;
}

class Tag extends Component<IProps> {
  public static defaultProps: Partial<IProps> = { disabled: false };

  public handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(this.props.tagId);
    }
  };

  public render() {
    return (
      <ButtonStyled
        css={this.props.className}
        onClick={this.handleClick}
        disabled={this.props.disabled}
      >
        <div>{this.props.children}</div>
      </ButtonStyled>
    );
  }
}

export default Tag;
