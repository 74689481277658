import { useQuery, useMutation } from '@apollo/react-hooks';
import { Query, TooltipType, Mutation } from '../../../graphql/VXModels/types';
import { ApolloError } from 'apollo-client';
import { QUERY_MODEL_TOOLTIPSETTINGS_HASSEEN } from '../../../graphql/VXModels/queries';
import { MUTATION_MODEL_TOOLTIPSETTINGS_SETSEEN } from '../../../graphql/VXModels/mutations';
import { mergeDeep } from 'apollo-utilities';

interface HasSeenResult {
  data?: Query;
  loading: boolean;
  error?: ApolloError;
}

interface Props {
  type: TooltipType;
}

export const usePopperSeen = ({ type }: Props) => {
  const useHasSeen = (): HasSeenResult => {
    const { data, loading, error } = useQuery<Query>(QUERY_MODEL_TOOLTIPSETTINGS_HASSEEN, {
      variables: { type: type },
    });
    return { data, loading, error };
  };

  const useSetSeen = () => {
    return useMutation<Mutation>(MUTATION_MODEL_TOOLTIPSETTINGS_SETSEEN, {
      variables: { type: type },
      update: (cache, data) => {
        const hasSeen = data?.model?.tooltipSettings?.setSeen?.data?.hasSeen;
        if (hasSeen) {
          const cachedData = cache.readQuery({ query: QUERY_MODEL_TOOLTIPSETTINGS_HASSEEN });
          const mergedData = mergeDeep(cachedData, {
            model: { tooltipSettings: { hasSeen } },
          });
          cache.writeQuery({
            query: QUERY_MODEL_TOOLTIPSETTINGS_HASSEEN,
            variables: {
              type: type,
            },
            data: mergedData,
          });
        }
      },
    });
  };

  return { useHasSeen, useSetSeen };
};

export default usePopperSeen;
