import React from 'react';
import { BroadcastStatus } from '../../types';
import { getStatusColor } from '../../util';
import { LinearProgressWithLabel } from '../../../../molecules/LinearProgressWithLabel';
import { RenderColumn } from '../../../../atoms/MaterialTable/types';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';
import { _ } from '../../../../util/translate';
import { DARK_GRAY } from '../../../../camtool-styles';

const RenderColumnSent: RenderColumn<Broadcast> = (broadcast) => {
  const { revoked, status, progress } = broadcast;

  return (
    <>
      <div css={{ marginBottom: '2px', color: DARK_GRAY }}>
        {_('telegram:BroadcastList.column.status.value.' + status)}
      </div>
      <LinearProgressWithLabel
        showText={!revoked}
        variant={status === BroadcastStatus.running ? 'indeterminate' : 'determinate'}
        color={getStatusColor(status as BroadcastStatus)}
        value={
          status === BroadcastStatus.done || status === BroadcastStatus.revoked
            ? 100
            : progress * 100
        }
      />
    </>
  );
};
export default RenderColumnSent;
