import React, { FC } from 'react';
import { IStepProps } from '../../types';
import { useHasRole } from '../../../../util/UserData';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import { BREAKPOINT_BENEFITS } from './constants';

// Outside Wizard
import { Button } from '../../../../atoms';

// Inside Wizard
import ContentMedia from './ContentMedia/ContentMedia';
import ContentFreeShow from './ContentFreeShow/ContentFreeShow';
import Preview from './Preview/Preview';
import ContentVideo from './ContentVideo/ContentVideo';
import ContentTicketshow from './ContentTicketshow/ContentTicketshow';
import ContentPhoto from './ContentPhoto/ContentPhoto';
import ContentMessages from './ContentMessages/ContentMessages';
import ContentMinutes from './ContentMinutes/ContentMinutes';
import ContentCredits from './ContentCredits/ContentCredits';

const BenefitContent = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @media (max-width: ${BREAKPOINT_BENEFITS}px) {
    flex-flow: column;
  }
`;

const StepBenefitsContent: FC<IStepProps> = ({
  dispatch,
  state,
  availableContentMinAmountsArray,
}) => {
  const isInternalAccount = useHasRole(SecurityRole.VX_USER_INTERNAL_ACCOUNT);

  function handleContinueClick() {
    dispatch({ type: 'GO_TO_STEP_VOUCHER_DETAILS', payload: {} });
  }

  function handleBackClick() {
    dispatch({ type: 'GO_BACK', payload: {} });
  }

  function renderContent() {
    switch (state.currentVoucherType) {
      case 'SHOW_TICKET':
        return (
          <ContentFreeShow
            state={state}
            dispatch={dispatch}
            availableContentMinAmountsArray={availableContentMinAmountsArray}
          />
        );
      case 'VIDEO':
        return (
          <ContentVideo
            state={state}
            dispatch={dispatch}
            availableContentMinAmountsArray={availableContentMinAmountsArray}
          />
        );
      case 'TICKET_SHOW':
        return (
          <ContentTicketshow
            state={state}
            dispatch={dispatch}
            availableContentMinAmountsArray={availableContentMinAmountsArray}
          />
        );
      case 'PHOTOS':
        return (
          <ContentPhoto
            state={state}
            dispatch={dispatch}
            availableContentMinAmountsArray={availableContentMinAmountsArray}
          />
        );
      case 'MESSAGES':
        return (
          <ContentMessages
            state={state}
            dispatch={dispatch}
            availableContentMinAmountsArray={availableContentMinAmountsArray}
          />
        );
      case 'CHAT_MINUTES':
        return (
          <ContentMinutes
            state={state}
            dispatch={dispatch}
            availableContentMinAmountsArray={availableContentMinAmountsArray}
          />
        );
      case 'VXPAGES_CREDITS':
        return (
          <ContentCredits
            state={state}
            dispatch={dispatch}
            availableContentMinAmountsArray={availableContentMinAmountsArray}
          />
        );
      case 'MEDIA':
        return (
          <ContentMedia
            state={state}
            dispatch={dispatch}
            availableContentMinAmountsArray={availableContentMinAmountsArray}
          />
        );
      default:
        throw new Error('Invalid `voucherType`');
    }
  }

  return (
    <div css={{ flex: 1, flexDirection: 'column' }}>
      <BenefitContent>
        {renderContent()}

        <Preview state={state} dispatch={dispatch} />
      </BenefitContent>

      <div css={{ margin: 32, justifyContent: isInternalAccount ? 'space-between' : 'flex-end' }}>
        {isInternalAccount && (
          <Button theme="border-gray" onClick={handleBackClick}>
            <span className="icon-chevron-left" css={{ marginRight: 8, fontSize: 8 }} />
            <span>{_('benefits:voucher.texts.back')}</span>
          </Button>
        )}
        <Button
          theme={state.voucherContent.length < 1 ? 'gray' : 'blue'}
          disabled={state.voucherContent.length < 1}
          onClick={handleContinueClick}
          css={{ marginLeft: 16 }}
        >
          <span>{_('benefits:voucher.texts.continue')}</span>
          <span className="icon-chevron-right" css={{ marginLeft: 8, fontSize: 8 }} />
        </Button>
      </div>
    </div>
  );
};

export default StepBenefitsContent;
