import { EventEmitter2 } from 'eventemitter2';
import objectAssign from 'object-assign';

import { log } from '../../../util/errorHandling';
import { ChecksumWorker, IChecksumWorkerProps } from './ChecksumWorker';
import { Entry } from './Entry';
import { EntryHelper } from './EntryHelper';
import { FileHelper } from './FileHelper';
import { InvalidArgumentException } from './InvalidArgumentException';
import { InvalidMimeTypeException } from './InvalidMimeTypeException';
import { ILogger } from './Logger';
import { QueueHelper } from './QueueHelper';
import { QueueStorage } from './QueueStorage';
import { UploadWorker } from './UploadWorker';

export enum EntryState {
  UNDEF = 'undef' as any,
  QUEUED = 'queued' as any,
  RUN = 'run' as any,
  COMPLETE = 'complete' as any,
  REMOVED = 'removed' as any,
  FILE_REF_ERROR = 'file_ref_error' as any,
  AUTH_ERROR = 'auth_error' as any,
  NETWORK_ERROR = 'network_error' as any,
  RESPONSE_ERROR = 'response_error' as any,
  DUPLICATE_ERROR = 'duplicate_error' as any,
  MONOCHROME_ERROR = 'monochrome_error' as any,
  READ_ERROR = 'read_error' as any,
  PICTURE_SIZE_ERROR = 'picture_size_error' as any,
  FILE_TYPE_ERROR = 'file_type_error' as any,
}

export interface IJsUploaderProps {
  uploadType: string;
  logger: ILogger;
  options?: IOptions;
}

export interface IAppState {
  userId: number;
  sessionId: string;
  uploadType: string;
  options: IOptions;
  isActive: boolean;
  isPaused: boolean;
  currentChecksumEntry: Entry;
  currentUploadEntry: Entry;
  queue: Entry[];
}

export interface IOptions {
  uploadUrl?: string;
  allowedMimeTypes?: string;
  allowedFileExtensions?: string[];
  checksumWorkerUrl?: string;
  checksumValidateUrl?: string;
  initPaused?: boolean;
  sortMap?: any;
  sortFunction?: any | void;
  fields?: any;
  validator?: any;
}

export class JsUploader extends EventEmitter2 {
  public state: IAppState = {} as IAppState;
  private props: IJsUploaderProps;
  private logger: ILogger;
  private options: IOptions;
  private uploadType: string;

  private storage: QueueStorage;
  private checksumWorker: ChecksumWorker;
  private uploadWorker: UploadWorker;

  private queue: Entry[] = [];
  private initialized: false;

  constructor(props: IJsUploaderProps) {
    super();

    this.props = props;
    props.options = props.options || {};

    const defaultConfig: any = {
      _default: {
        checksumValidateUrl: null,
        checksumWorkerUrl: '/hashtask.min.js',
        fields: {},
        sortFunction(queue: Entry[]) {
          queue = queue || [];

          for (let i = 0; i < this.queue.length; i++) {
            this.queue[i].pos = i;
          }

          queue.sort((a: Entry, b: Entry) => {
            let sort: number;
            sort = this.options.sortMap[a.state] - this.options.sortMap[b.state];
            if (sort === 0) {
              sort = a.pos - b.pos;
            }

            return sort;
          });

          return queue;
        },
        sortMap: {
          undef: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          file_ref_error: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          auth_error: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          response_error: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          network_error: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          duplicate_error: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          read_error: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          file_type_error: 1,
          // eslint-disable-next-line @typescript-eslint/camelcase
          picture_size_error: 1,
          run: 2,
          queued: 3,
          transcoding: 4,
          complete: 5,
        },
      },
      video: {
        allowedFileExtensions: [
          '3gp',
          'asf',
          'avi',
          'divx',
          'flv',
          'm2t',
          'm2ts',
          'm4v',
          'mkv',
          'mov',
          'mp4',
          'mpeg',
          'mpg',
          'mts',
          'ts',
          'webm',
          'wlmp',
          'wmv',
        ],
        allowedMimeTypes: 'video/*',
        initPaused: true,
        checksumValidateUrl:
          'https://api.vxmodels.com/v1/camtool/user/{userId}/util/videoHashExists/{checksum}?s=jsuploader',
        uploadUrl: 'https://upload.cp1.campoints.net/video?sid={sid}',
        fields: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          uma_title: '{entry.fileName}',
          // eslint-disable-next-line @typescript-eslint/camelcase
          uma_subtype: '3',
        },
      },
      picture: {
        allowedFileExtensions: ['bmp', 'jpeg', 'jpg', 'png', 'gif'],
        allowedMimeTypes: 'image/*',
        initPaused: true,
        checksumValidateUrl:
          'https://api.vxmodels.com/v1/camtool/user/{userId}/util/pictureHashExists/{checksum}?s=jsuploader',
        uploadUrl: 'https://upload.cp1.campoints.net/picture?sid={sid}&type={type}&uma_id={umaId}',
        fields: {
          type: '14',
        },
      },
    };

    if (!props.uploadType || !defaultConfig[props.uploadType]) {
      const validUploadTypes = Object.keys(defaultConfig);
      throw new InvalidArgumentException(
        'uploadType not set or invalid, allowed [' +
          validUploadTypes.join('|') +
          ']: ' +
          props.uploadType
      );
    }

    this.uploadType = props.uploadType;
    this.options = objectAssign(
      {},
      defaultConfig._default,
      defaultConfig[props.uploadType],
      props.options
    ) as IOptions;
    this.options.fields = objectAssign(
      {},
      defaultConfig[props.uploadType].fields,
      this.options.fields || {}
    ) as IOptions;

    this.logger = (props.logger as ILogger) || null;

    this.on('updateEntryState', (entry: Entry, newState: EntryState) => {
      if (entry.state !== newState) {
        entry.state = newState;
        this.emit('updateEntry', entry);
        this.emit('updateQueue');
      }
    });

    this.on('removeEntry', (entry: Entry) => {
      if (this.state.currentUploadEntry === entry) {
        this.state.currentUploadEntry = null;
      }
    });

    this.on('updateEntry', (entry: Entry) => {
      this.storage.storeEntry(entry);
      this.emit('stateChanged');
    });

    this.on('updateQueue', () => {
      if (typeof this.options.sortFunction === 'function') {
        const sortFunction: any = this.options.sortFunction.bind(this);
        this.state.queue = sortFunction(this.queue);
      } else {
        this.state.queue = this.queue;
      }
      this.storage.storeQueueKeys(this.queue);
      if (this.queue.length === 0) {
        if (this.options.initPaused) {
          this.pauseUploads();
        } else {
          this.startUploads();
        }
      }
      this.emit('stateChanged');
    });
  }

  public init(userId: number, sessionId: string, fields?: any) {
    if (!this.initialized) {
      fields = fields || {};
      this.storage = new QueueStorage({
        uploadType: this.uploadType,
        userId,
        logger: this.logger,
      });

      this.checksumWorker = this.buildChecksumWorker(userId);
      this.uploadWorker = this.buildUploadWorker(userId, sessionId, fields);

      this.queue = this.storage.getQueue();

      this.state = {
        userId,
        sessionId,
        options: this.options,
        isActive: this.uploadWorker.isActive,
        isPaused: this.uploadWorker.isPaused,
        queue: null,
        currentChecksumEntry: this.checksumWorker.currentEntry,
        currentUploadEntry: this.uploadWorker.currentEntry,
      } as IAppState;

      this.emit('updateQueue');
      this.emit('init');

      this.initialized = true;
    }
  }

  public getAcceptedMimeTypes() {
    return this.options.allowedMimeTypes;
  }

  public addFiles(fileList: FileList) {
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (FileHelper.isValidateMimeType(file, this.options.allowedMimeTypes)) {
        const entry = EntryHelper.getOrCreateEntry(file, this.queue);
        if (QueueHelper.isEntryInQueue(entry, this.queue)) {
          // Entry is already in queue
          this.logger.debug(
            `CampointJsUploader[${this.uploadType}]::addEntry("${entry.fileName}") already in queue`,
            entry
          );
          if (
            (entry.state === EntryState.FILE_REF_ERROR || entry.state === EntryState.READ_ERROR) &&
            entry.file
          ) {
            entry.state = EntryState.QUEUED;
          }
        } else {
          // Entry is not in queue
          this.queue.push(entry);
          this.logger.debug(
            `CampointJsUploader[${this.uploadType}]::addEntry("${entry.fileName}") queued`,
            entry
          );

          if (entry.file.type) {
            entry.state = EntryState.QUEUED;
          }
        }

        this.emit('updateEntry', entry);
        this.checksumWorker.processQueue(this.queue);
        this.uploadWorker.processQueue(this.queue);
      } else {
        throw new InvalidMimeTypeException(file.type);
      }
    }
    this.emit('updateQueue');
  }

  public startUploads() {
    this.uploadWorker.startUploads();
  }

  public pauseUploads() {
    this.uploadWorker.pauseUploads();
  }

  /**
   *
   * @param entry
   * @returns {boolean}
   */
  public removeEntry(entry: Entry) {
    if (entry === this.checksumWorker.currentEntry) {
      this.checksumWorker.abortCurrentCalculation();
    }

    if (entry === this.uploadWorker.currentEntry) {
      this.uploadWorker.interrupt(true);
    }

    for (const index in this.queue) {
      if (entry.key === this.queue[parseInt(index, 10)].key) {
        this.queue.splice(parseInt(index, 10), 1);
        this.storage.removeEntry(entry.key);
        this.checksumWorker.processQueue(this.queue);
        this.emit('removeEntry', entry);
        this.uploadWorker.processQueue(this.queue);
        this.emit('updateQueue');
        return true;
      }
    }

    return false;
  }

  public prioritizeEntry(entry: Entry) {
    if (!(entry.state === EntryState.QUEUED || entry.state === EntryState.NETWORK_ERROR)) {
      return false;
    }

    for (const index in this.queue) {
      if (entry.key === this.queue[parseInt(index)].key) {
        this.queue.splice(parseInt(index), 1);
        this.queue.unshift(entry);
        this.storage.storeQueueKeys(this.queue);
        // interrupt only if needed
        const currentEntry = this.uploadWorker.currentEntry;
        if (currentEntry && currentEntry != entry) {
          this.uploadWorker.interrupt(false);
        }
        this.emit('updateQueue');
        this.uploadWorker.processQueue(this.queue);
        return true;
      }
    }

    return false;
  }

  private buildUploadWorker(userId: number, sessionId: string, fields: any) {
    const uploadWorker = new UploadWorker({
      uploadType: this.uploadType,
      uri: this.options.uploadUrl,
      logger: this.logger,
      userId,
      sessionId,
      isPaused: this.options.initPaused,
      fields: objectAssign(this.options.fields, fields),
    });

    const updateState = function () {
      this.state.currentUploadEntry = this.uploadWorker.currentEntry;
      this.state.isPaused = uploadWorker.isPaused;
      this.state.isActive = uploadWorker.isActive;
    };

    uploadWorker.on('updateEntryState', (entry: Entry, newState: EntryState) => {
      this.emit('updateEntryState', entry, newState);
    });

    uploadWorker.on('updateEntry', (entry: Entry) => {
      this.emit('updateEntry', entry);
    });

    uploadWorker.on('statePauseChanged', () => {
      updateState.call(this);
      if (!this.state.isPaused) {
        this.uploadWorker.processQueue(this.queue);
      }
      this.emit('updateQueue');
    });

    uploadWorker.on('stateChanged', () => {
      updateState.call(this);
      this.emit('stateChanged');
    });

    uploadWorker.on('error', (entry: Entry) => {
      this.uploadWorker.currentEntry = null;
      this.uploadWorker.isActive = false;
      this.uploadWorker.processQueue(this.queue);
      if (entry.state !== EntryState.DUPLICATE_ERROR) {
        this.logger.error(`Error while uploading "${entry.fileName}": ${entry.state}`, {
          context: 'JsUploader.UploadWorker.on[error]',
          data: { uploadType: this.uploadType },
        });
      }
    });

    uploadWorker.on('complete', (entry: Entry) => {
      if (this.checksumWorker.currentEntry && this.checksumWorker.currentEntry.key === entry.key) {
        this.checksumWorker.abortCurrentCalculation();
      }
      this.uploadWorker.currentEntry = null;
      this.uploadWorker.isActive = false;
      this.uploadWorker.processQueue(this.queue);
      this.emit('complete', entry);
    });

    return uploadWorker;
  }

  /**
   *
   * @param userId
   * @returns {ChecksumWorker}
   */
  private buildChecksumWorker(userId: number): ChecksumWorker {
    const checksumWorker = new ChecksumWorker({
      userId,
      workerUrl: this.options.checksumWorkerUrl,
      checksumValidateUrl: this.options.checksumValidateUrl,
      isPaused: this.options.initPaused,
      logger: this.logger,
      validator: this.options.validator,
    } as IChecksumWorkerProps);

    checksumWorker.on('updateEntryState', (entry: Entry, newState: EntryState) => {
      this.emit('updateEntryState', entry, newState);
    });

    checksumWorker.on('progress', (entry: Entry) => {
      this.emit('stateChanged');
    });

    checksumWorker.on('error', (entry: Entry) => {
      this.checksumWorker.processQueue(this.queue);
      this.emit('updateEntry', entry);

      if (entry.state !== EntryState.DUPLICATE_ERROR) {
        log('error', `ChecksumWorker Error: ${JSON.stringify(entry)}`, {
          context: 'JsUpload.ChecksumWorker.onError',
        });
      }
    });

    checksumWorker.on('complete', (entry: Entry) => {
      checksumWorker.currentEntry = null;
      if (this.queue && entry.state === EntryState.RUN) {
        setTimeout(() => {
          for (let i = 0; i < this.queue.length; i++) {
            if (this.queue[i].key === entry.key && this.queue[i].state !== EntryState.COMPLETE) {
              checksumWorker.validateChecksum(this.queue[i]);
              break;
            }
          }
        }, 2000);
      }

      checksumWorker.processQueue(this.queue);
      this.emit('updateEntry', entry);
    });

    return checksumWorker;
  }
}
