import React from 'react';
import { SessionStore } from '../../../stores/Session/Session';
import { CmsArticle, T } from '../../../components';

class Terms extends React.Component {
  state = { userData: SessionStore.get() };

  render() {
    return (
      <div className="grid__row">
        <div className="grid__column grid__box">
          <div className="grid__box__header">
            <div className="grid__box__header__title">
              <T _={'common:legal.terms'} />
            </div>
          </div>
          <div className="grid__box__item">
            <div className="grid__box__item__content">
              <CmsArticle id={'terms-models'} className="legal__box" linkTarget="_blank" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
