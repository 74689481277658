import React, { FC, useState } from 'react';
import { _ } from '../../../util/translate';
import SendButton from '../SendButton/SendButton';
import { Mutation } from '../../../graphql/VXModels/types';
import { useMutation } from '@apollo/react-hooks';
import { ExecutionResult } from 'graphql';
import gql from 'graphql-tag';
import Spinner from '../../Spinner/Spinner';

const MUTATION_MODEL_MEDIA_REPLY_TO_COMMENT = gql`
  mutation MUTATION_MODEL_MEDIA_REPLY_TO_COMMENT(
    $albumId: Int!
    $commentId: Int!
    $message: String!
  ) {
    model {
      media {
        replyToComment(albumId: $albumId, commentId: $commentId, message: $message) {
          id
          feedbacksItems {
            id
            answer
            isAnswered
          }
        }
      }
    }
  }
`;

interface Props {
  umaId: number;
  commentId: string;
}

const ReplyComment: FC<Props> = ({ umaId, commentId }) => {
  const [commentReply, setCommentReply] = useState('');

  const optimisticResponse = {
    model: {
      media: {
        replyToComment: {
          id: umaId,
          feedbacksItems: [
            {
              id: -666,
              answer: commentReply,
              isAnswered: true,
              __typename: 'MediaFeedback',
            },
          ],
          __typename: 'MediaAlbum',
        },
        __typename: 'Media',
      },
      __typename: 'Model',
    },
  };

  const [replyToComment, { loading }] = useMutation<Mutation>(
    MUTATION_MODEL_MEDIA_REPLY_TO_COMMENT
  );

  const handleReplyChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setCommentReply(event.target.value);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <input
        value={commentReply}
        css={{
          width: '100%',
          minHeight: '100%',
          padding: '6px 14px',
          borderRadius: '5px',
        }}
        onChange={handleReplyChange}
        id={`feedback${commentId}`}
        type="text"
        placeholder={_('mediamanagement:video.comments.placeholder')}
      />
      <SendButton
        css={{ position: 'absolute', right: 10 }}
        disabled={commentReply.length === 0}
        title={_('mediamanagement:video.comments.sendComment')}
        onClick={(): Promise<ExecutionResult<Mutation>> =>
          replyToComment({
            variables: {
              albumId: Number(umaId),
              commentId: commentId,
              message: commentReply,
            },
            optimisticResponse,
          })
        }
      />
    </>
  );
};

export default ReplyComment;
