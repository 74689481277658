import React, { FC, memo, useState } from 'react';
import { _ } from '../../../../../util/translate';
import Thread from './Thread';
import { InlineEditor } from '../../../../../atoms';
import { FancyError, Translation } from '../../../../../components';
import { useTelegramSendMessage, useTelegramUser, useTelegramUserMessages } from '../../../hooks';
import { User } from '../../../../../graphql/VXServicesTelegram/types';
import { useFileStorageSettings } from '../../../hooks';
import styled from '@emotion/styled';
import ThreadContainerHeader from './ThreadContainerHeader';
import ModalBlockUser from '../ModalBlockUser';
import { WHITE_2 } from '../../../../../camtool-styles';
import { useParams } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import { CHAT_FETCH_INTERVAL } from '../utils';

const RankBanner = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  top: 114px;
  left: 0;
  justify-content: center;
  background-color: #43b31c99;
  width: 100%;
  padding: 5px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
`;

const useStyles = makeStyles(() =>
  createStyles({
    paperFullScreen: {
      height: 'calc(100vh - 50px)',
      position: 'absolute',
      bottom: 0,
    },
  })
);

const limit = 50;

const InnerContainer = styled.div`
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

interface IProps {
  selectedUser: User;
}

const ThreadContainer: FC<IProps> = memo(() => {
  const classes = useStyles();
  const { userId: userIdString } = useParams<{ userId: string }>();
  const userId = Number(userIdString);
  const { data: selectedUser, currency } = useTelegramUser(userId);

  const [isBlockModalOpen, setIsBlockModalOpen] = useState<boolean>(false);
  const onClose = (): void => setIsBlockModalOpen(false);
  const onOpen = (): void => setIsBlockModalOpen(true);

  const {
    data: groupedChat,
    fetchMore,
    loading,
    error,
  } = useTelegramUserMessages(
    { userId: userId },
    { fetchPolicy: 'cache-and-network', pollInterval: CHAT_FETCH_INTERVAL }
  );

  const fileStorageSettings = useFileStorageSettings();
  const [sendMessage, { loading: sending }] = useTelegramSendMessage({ userId: userId });
  const messagesLength = groupedChat?.length;

  if (error) {
    return <FancyError error={error} />;
  }

  return (
    <Dialog classes={classes} open={true} fullScreen style={{ zIndex: 19 }}>
      {selectedUser && (
        <ThreadContainerHeader selectedUser={selectedUser} showModalWindow={onOpen} />
      )}
      {Boolean(selectedUser?.rankBadge) && (
        <RankBanner>
          <span>
            {_('telegram:chat.system.ranking')} {selectedUser?.rankBadge}
          </span>
        </RankBanner>
      )}
      <div
        css={{
          width: '100%',
          height: '100%',
          backgroundColor: WHITE_2,
          position: 'absolute',
          bottom: 0,
          paddingTop: '66px',
        }}
      >
        <div css={{ flexDirection: 'column', flex: 1 }}>
          {userId > 0 ? (
            <>
              <InnerContainer className={'spinner-container'}>
                {isBlockModalOpen && <ModalBlockUser onClose={onClose} userId={userId} />}

                <Thread
                  userId={userId}
                  groupedChat={groupedChat}
                  loading={loading}
                  currency={currency}
                  fetchMore={messagesLength > limit && fetchMore}
                />
              </InnerContainer>
              <div
                css={{
                  backgroundColor: WHITE_2,
                  padding: 8,
                  boxShadow: '0px -1px 11px -6px rgb(0 0 0 / 96%)',
                  position: 'relative',
                }}
              >
                <InlineEditor
                  emojiPicker
                  uploadPicker
                  onSubmit={({ text, fileUUID, price }) =>
                    sendMessage({ variables: { text, fileUUID, price } })
                  }
                  sending={sending}
                  fileStorageSettings={fileStorageSettings}
                />
              </div>
            </>
          ) : (
            <div
              css={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 18,
              }}
            >
              <Translation t={'telegram:chat.channel.select'} />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
});
ThreadContainer.displayName = 'ThreadContainer';

export default ThreadContainer;
