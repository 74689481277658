import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { _ } from '../../../../util/translate';

const ChannelDescriptionHeader: FC = () => {
  return (
    <Box mt={9} display="flex" flexDirection="column" alignItems="center">
      <Box>
        <Typography variant="h3" align="center">
          {_('telegram:channeldescription.header.title')}
        </Typography>
      </Box>
      <Box maxWidth="650px">
        <Typography align="center">{_('telegram:channeldescription.header.subtitle')}</Typography>
      </Box>
    </Box>
  );
};

export default ChannelDescriptionHeader;
