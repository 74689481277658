import React, { FC, ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import { _ } from '../../../../../util/translate';
import { Range } from '../../../../../atoms';
import { MIN_PRICE } from '../constants';

interface Props {
  currencyLabel: string;
}

const FieldPrice: FC<Props> = ({ currencyLabel }) => {
  return (
    <Field
      name="price"
      render={({ field, form: { values, setFieldValue } }: FieldProps): ReactElement => {
        const handleChange = (value: number): void => setFieldValue(field.name, Number(value));

        return (
          <FieldLayout
            label={_('benefits:abo.detailsPage.priceLabel')}
            error=""
            css={{ marginBottom: 24 }}
          >
            <Range
              label={currencyLabel}
              min={MIN_PRICE}
              max={200}
              step={0.01}
              initialValue={field.value}
              onChange={handleChange}
              disabled={values.priceDiscounted > 0}
            />
          </FieldLayout>
        );
      }}
    />
  );
};

export default FieldPrice;
