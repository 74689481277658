import React, { FC, ReactNode } from 'react';
import { Field, FieldProps } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { WHITE_3 } from '../../../camtool-styles';
import MUICheckbox from '../../../components/MUI/Checkbox/MUICheckbox';

interface FieldCheckboxProps {
  name: string;
  disabled?: boolean;
  label?: ReactNode;
  value?: string | boolean;
  checkboxColor?: string;
}

const FieldCheckbox: FC<FieldCheckboxProps> = ({
  name,
  label,
  disabled,
  checkboxColor,
  value = true,
}: FieldCheckboxProps) => {
  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps): React.ReactNode => (
        <FormControlLabel
          control={
            <MUICheckbox
              name={field.name}
              value={value}
              color={checkboxColor ? checkboxColor : 'primary'}
              disabled={disabled}
              size={'medium'}
              checked={field.value || false}
              onChange={(e): void => {
                form.setFieldValue(field.name, field.value ? value : null);
                field.onChange(e);
              }}
            />
          }
          label={label}
          style={{ backgroundColor: WHITE_3, marginLeft: -4, paddingRight: 4, paddingLeft: 4 }}
        />
      )}
    />
  );
};

export default FieldCheckbox;
