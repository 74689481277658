import { css } from '@emotion/core';

const oneDotEightEm = css({ fontSize: '1.8em' });

export default (icon, gender = 'female', status) => {
  switch (icon) {
    case 'common_gender':
      return [`icon-gender-${gender}`, oneDotEightEm];
    case 'common_sexuality':
      return [`icon-gender-ori-hetero`, oneDotEightEm];
    case 'common_languages':
      return 'fas fa-user-graduate fa-2x';
    case 'common_tags':
      return [`icon-paragraph`, oneDotEightEm];
    case 'common_characteristics':
      return [`icon-${gender === 'female' ? 'girl' : 'user'}`, oneDotEightEm];
    case 'common_relationship':
      return 'fas fa-heartbeat fa-2x';
    case 'common_birthday':
      return [`vxactor-fact-birthday`, oneDotEightEm];
    case 'common_job':
      return 'fas fa-toolbox fa-2x';
    case 'common_country':
      return [`vxactor-fact-country`, oneDotEightEm];
    case 'common_zipcode':
      return [`vxactor-fact-distance`, oneDotEightEm];
    case 'common_city':
      return [`icon-comments`, oneDotEightEm];
    case 'common_appearance':
      return [`icon-sunglasses`, oneDotEightEm];
    case 'common_skin_type':
      return [`icon-sampler`, oneDotEightEm];
    case 'common_figure':
      return [`vxactor-fact-body`, oneDotEightEm];
    case 'common_height':
      return [`vxactor-fact-${gender}-height`, oneDotEightEm];
    case 'common_weight':
      return [`vxactor-fact-weight`, oneDotEightEm];
    case 'common_hair_Length':
      return [`vxactor-fact-hair-${gender}`, oneDotEightEm];
    case 'common_hair_color':
      return [`icon-sampler`, oneDotEightEm];
    case 'common_eye_color':
      return [`vxactor-fact-eyes-color`, oneDotEightEm];
    case 'common_breast_size':
      return [`vxactor-fact-breast-size`, oneDotEightEm];
    case 'common_pubic_hair':
      return [`vxactor-fact-shave`, oneDotEightEm];
    case 'common_beard':
      return 'fas fa-cut fa-2x';
    case 'common_hairiness':
      return 'fas fa-cut fa-2x';
    case 'common_penis_size':
      return [`vxactor-fact-penis-size`, oneDotEightEm];
    case 'common_body_modifications':
      return [`vxactor-fact-body-decorations`, oneDotEightEm];
    case 'common_international_nickname':
      return [`icon-${gender === 'female' ? 'girl' : 'user'}`, oneDotEightEm];
    case 'common_text_about_you':
      return [`icon-${gender === 'female' ? 'girl' : 'user'}`, oneDotEightEm];
    case 'common_interview':
      return [`icon-quote`, oneDotEightEm];

    case 'partner_gender':
      return [`icon-gender-${gender}`, oneDotEightEm];
    case 'partner_appearance':
      return [`icon-underwear`, oneDotEightEm];
    case 'partner_birthdate':
      return [`vxactor-fact-birthday`, oneDotEightEm];
    case 'partner_body_modification':
      return [`vxactor-fact-body-decorations`, oneDotEightEm];
    case 'partner_breast_size':
      return [`vxactor-fact-breast-size`, oneDotEightEm];
    case 'partner_eye_color':
      return [`vxactor-fact-eyes-color`, oneDotEightEm];
    case 'partner_figure':
      return [`vxactor-fact-body`, oneDotEightEm];
    case 'partner_hair_color':
      return [`icon-sampler`, oneDotEightEm];
    case 'partner_hair_Length':
      return [`vxactor-fact-hair-${gender}`, oneDotEightEm];
    case 'partner_hairiness':
      return 'fas fa-cut fa-2x';
    case 'partner_height':
      return [`vxactor-fact-${gender}-height`, oneDotEightEm];
    case 'partner_penis_size':
      return [`vxactor-fact-penis-size`, oneDotEightEm];
    case 'partner_pubic_hair':
      return [`vxactor-fact-shave`, oneDotEightEm];
    case 'partner_skin_type':
      return [`icon-sampler`, oneDotEightEm];
    case 'partner_weight':
      return [`vxactor-fact-weight`, oneDotEightEm];

    case 'vx_show_location':
      return [`icon-webcam`, oneDotEightEm];
    case 'vx_show_type':
      return 'fas fa-comments fa-2x';
    case 'vx_text_chat_motto':
      return [`icon-uk-rat-18`, oneDotEightEm];
    case 'vx_text_scheduler':
      return [`icon-webcam`, oneDotEightEm];
    case 'vx_sexual_inclinations':
      return [`icon-gender-ori-lesbian`, oneDotEightEm];
    case 'vx_clothing':
      return [`icon-underwear`, oneDotEightEm];
    case 'vx_toys':
      return [`vxactor-fact-penis-size`, oneDotEightEm];
    case 'vx_preferred_practices':
      return [`vxactor-person-nympho`, oneDotEightEm];

    case 'vx_automail_new_user1':
    case 'vx_automail_new_user2':
    case 'vx_automail_new_user3':
    case 'vx_automail_new_user4':
    case 'vx_automail_new_user5':
    case 'vx_automail_existing_user1':
    case 'vx_automail_existing_user2':
    case 'vx_automail_existing_user3':
    case 'vx_automail_existing_user4':
    case 'vx_automail_existing_user5':
    case 'vx_automails':
      return [`icon-message-new`, oneDotEightEm];

    case 'vx_interview_book':
    case 'vx_interview_city':
    case 'vx_interview_date':
    case 'vx_interview_enterprise':
    case 'vx_interview_erotic_experience':
    case 'vx_interview_extraordinary_sex':
    case 'vx_interview_food':
    case 'vx_interview_hobbies':
    case 'vx_interview_hot_experience':
    case 'vx_interview_island':
    case 'vx_interview_music':
    case 'vx_interview_partner':
    case 'vx_interview_party':
    case 'vx_interview_personal_admiration':
    case 'vx_interview_sex_go':
    case 'vx_interview_sex_nogo':
    case 'vx_interview_sexual_curiosity':
    case 'vx_interview_sexual_esteem':
    case 'vx_interview_sexual_fantasies':
    case 'vx_interview_sexual_position':
    case 'vx_interview_sport':
    case 'vx_interview_traits':
      return [`icon-comments`, oneDotEightEm];
    case 'vx_sedcard12':
      return [
        '',
        css({
          fontSize: '1.8em',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundImage: `url(${
            status === 'required'
              ? require('./icons/fsk12_red.svg')
              : status === 'completed'
              ? require('./icons/fsk12_white.svg')
              : require('./icons/fsk12_gray.svg')
          })`,
          '&:hover': { backgroundImage: 'none' },
        }),
      ];
    case 'vx_sedcard16':
      return [
        '',
        css({
          fontSize: '1.8em',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundImage: `url(${
            status === 'required'
              ? require('./icons/fsk16_red.svg')
              : status === 'completed'
              ? require('./icons/fsk16_white.svg')
              : require('./icons/fsk16_gray.svg')
          })`,
          '&:hover': { backgroundImage: 'none' },
        }),
      ];
    case 'vx_sedcard18':
      return [
        '',
        css({
          fontSize: '1.8em',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundImage: `url(${
            status === 'required'
              ? require('./icons/fsk18_red.svg')
              : status === 'completed'
              ? require('./icons/fsk18_white.svg')
              : require('./icons/fsk18_gray.svg')
          })`,
          '&:hover': { backgroundImage: 'none' },
        }),
      ];

    case 'la_nickname':
      return [`icon-${gender === 'female' ? 'girl' : 'user'}`, oneDotEightEm];

    case 'la_textPreferences':
      return [`icon-donate`, oneDotEightEm];
    case 'la_textPerfectDate':
      return [`icon-drink`, oneDotEightEm];
    case 'la_textExperience':
      return [`icon-rabbit`, oneDotEightEm];

    case 'la_automails':
      return [`icon-message-new`, oneDotEightEm];
    case 'la_profile_album':
      return [`icon-picture`, oneDotEightEm];

    case 'fl_nickname':
      return [`icon-${gender === 'female' ? 'girl' : 'user'}`, oneDotEightEm];
    case 'fl_alcohol':
      return [`icon-drink`, oneDotEightEm];
    case 'fl_education':
      return 'fas fa-user-graduate fa-2x';
    case 'fl_origin':
      return [`vxactor-fact-languages`, oneDotEightEm];
    case 'fl_religion':
      return [`vxactor-person-goth`, oneDotEightEm];
    case 'fl_smoke':
      return [`icon-smoking`, oneDotEightEm];
    case 'fl_turnover':
      return 'fas fa-hand-holding-usd fa-2x';
    case 'fl_textAboutMe':
      return [`icon-${gender === 'female' ? 'girl' : 'user'}`, oneDotEightEm];
    case 'fl_automails':
      return [`icon-message-new`, oneDotEightEm];
    case 'fl_profile_album':
      return [`icon-picture`, oneDotEightEm];

    case 'fetish_role':
      return [`vxactor-person-submissive`, oneDotEightEm];
    case 'fetish_practices':
      return [`vxactor-person-nympho`, oneDotEightEm];
    case 'fetish_experiences':
      return [`vxactor-person-bdsm`, oneDotEightEm];
    case 'fetish_toys':
      return [`vxactor-fact-penis-size`, oneDotEightEm];
    case 'fetish_gays_specials':
      return [`vxactor-person-sensitive`, oneDotEightEm];
    case 'fetish_text_other_fetish':
      return [`vxactor-person-bdsm`, oneDotEightEm];
    case 'fetish_text_taboos':
      return [`icon-thumbs-down`, oneDotEightEm];

    case 'service0900_text_description':
      return [`icon-earphone`, oneDotEightEm];

    case 'vxhp_news':
      return [`icon-imac`, oneDotEightEm];

    default:
      return 'ICON-MISSING';
  }
};
