import React, { FC, ReactElement, useCallback } from 'react';
import { Avatar, createStyles, makeStyles, SvgIcon, SvgIconProps } from '@material-ui/core';

interface IconBaseProps extends Omit<SvgIconProps, 'fontSize'> {
  wrapInAvatar?: boolean;
  backgroundColor?: string;
  // check if your iconElement has fill="currentColor" to enable changing colors on the fly
  iconElement: ReactElement;
  // small is 12px, default 20px, large 30px and string any custom px size you want to set (try to avoid custom size as much as possible!)
  fontSize?: 'small' | 'large' | 'default' | string;
}

const useIconStyles = makeStyles(() =>
  createStyles({
    root: (props: { customFontSize: string }) => ({
      fontSize: props.customFontSize,
    }),
  })
);

const useAvatarStyles = makeStyles(() =>
  createStyles({
    root: (props: { backgroundColor: string }) => ({
      backgroundColor: props.backgroundColor,
      border: '1px solid',
      width: '35px',
      height: '35px',
    }),
  })
);

const IconBase: FC<IconBaseProps> = ({
  iconElement,
  wrapInAvatar = false,
  backgroundColor = 'transparent',
  fontSize = 'default',
  ...props
}) => {
  const calculateFontSize = useCallback(() => {
    switch (fontSize) {
      case 'small':
        return '12px';
      case 'large':
        return '30px';
      case 'default':
        return '20px';
      default:
        return fontSize;
    }
  }, [fontSize]);
  const customFontSize = calculateFontSize();
  const classes = useIconStyles({ customFontSize });
  const avatarClasses = useAvatarStyles({ backgroundColor });

  return wrapInAvatar ? (
    <Avatar classes={{ root: avatarClasses.root }}>
      <SvgIcon classes={{ root: classes.root }} {...props}>
        {iconElement}
      </SvgIcon>
    </Avatar>
  ) : (
    <SvgIcon classes={{ root: classes.root }} {...props}>
      {iconElement}
    </SvgIcon>
  );
};

export default IconBase;
IconBase.muiName = 'SvgIcon';
