import React, { Fragment } from 'react';
import AbstractComponent from '../../../components/AbstractComponent';
import ServerUtils from '../../../util/ServerUtils';
import Modalbox from '../../Modalbox/Modalbox';
import ButtonGroup from '../../ButtonGroup/ButtonGroup';
import Button from '../../Button/Button';
import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';

class ModalEmailBounced extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isVerificationEmailSent: false,
    };
  }

  sendVerificationLink() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/sendVerificationLinkToResetEmail',
      null,
      () => {
        this.setState({ isVerificationEmailSent: true });
      }
    );
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <Modalbox name="modalEmailBounced" state={this.state.isOpen} onKeyPressESC={this.close}>
        <div className="modalgrid__box">
          <div className="modalgrid__box__header">
            <div className="icon-info-sign" />
            <div>{_('modalEmailBounced:header.title')}</div>
            <div className="seperator" />
            <div className="icon-remove options" onClick={this.close} />
          </div>

          <div className="modalgrid__box__content modal-email-bounced">
            {/*TO SEND A VERIFICATION MAIL IS NOT ALLOWED*/}
            {!this.state.isVerificationEmailSent && !this.props.isEmailResettable && (
              <Fragment>
                <span
                  className="icon-info-sign modal-email-bounced__watermark"
                  style={{ top: 18 }}
                />
                <div className="modal-email-bounced__text">
                  <div className="modal-email-bounced__item">
                    {_('modalEmailBounced:info.emailNotResettable.text1')}
                    {_('modalEmailBounced:info.emailNotResettable.text2')}
                  </div>
                </div>

                <ButtonGroup position="center" className="modal-email-bounced__buttons">
                  <Button
                    className="button--blue"
                    label={_('modalEmailBounced:button.changeEmail.label')}
                    type="button"
                    href={`${APP_BASE_PATH}/account/personaldata`}
                  />
                </ButtonGroup>
              </Fragment>
            )}

            {/*TO SEND A VERIFICATION MAIL IS ALLOWED*/}
            {!this.state.isVerificationEmailSent && this.props.isEmailResettable && (
              <Fragment>
                <span className="icon-info-sign modal-email-bounced__watermark" />
                <div className="modal-email-bounced__text">
                  <h3 className="modal-email-bounced__title">
                    {_('modalEmailBounced:info.emailResettable.title')}
                  </h3>
                  <div className="modal-email-bounced__item">
                    {_('modalEmailBounced:info.emailResettable.text1', {
                      postProcess: 'sprintf',
                      sprintf: [window.userSessionData.user.email],
                    })}
                  </div>
                  <div className="modal-email-bounced__item">
                    {_('modalEmailBounced:info.emailResettable.text2')}
                  </div>
                </div>

                <ButtonGroup position="center" className="modal-email-bounced__buttons">
                  <Button
                    className="button--blue "
                    label={_('modalEmailBounced:button.sendVerificationLink.label')}
                    type="button"
                    onClick={this.sendVerificationLink}
                  />
                </ButtonGroup>
                <ButtonGroup position="center" className="modal-email-bounced__buttons">
                  <Button
                    className="link--grey"
                    label={_('modalEmailBounced:button.changeEmail.label')}
                    type="button"
                    href={`${APP_BASE_PATH}/account/personaldata`}
                  />
                </ButtonGroup>
              </Fragment>
            )}

            {/*VERIFICATION EMAIL WAS SENT*/}
            {this.state.isVerificationEmailSent && (
              <Fragment>
                <span className="icon-info-sign modal-email-bounced__watermark" />
                <div className="modal-email-bounced__text">
                  <div className="modal-email-bounced__item">
                    {_('modalEmailBounced:info.emailSent.text1', {
                      postProcess: 'sprintf',
                      sprintf: [window.userSessionData.user.email],
                    })}
                  </div>
                  <div className="modal-email-bounced__item">
                    {_('modalEmailBounced:info.emailSent.text2')}
                  </div>
                </div>

                <ButtonGroup position="center" className="modal-email-bounced__buttons">
                  <Button
                    className="button--blue"
                    label={_('modalEmailBounced:button.sendLinkAgain.label')}
                    type="button"
                    onClick={this.sendVerificationLink}
                  />
                </ButtonGroup>
                <ButtonGroup position="center" className="modal-email-bounced__buttons">
                  <Button
                    className="link--grey"
                    label={_('modalEmailBounced:button.changeEmail.label')}
                    type="button"
                    href={`${APP_BASE_PATH}/account/personaldata`}
                  />
                </ButtonGroup>
              </Fragment>
            )}
          </div>
        </div>
      </Modalbox>
    );
  }
}

ModalEmailBounced.propTypes = {};

ModalEmailBounced.defaultProps = {};

export default ModalEmailBounced;
export { ModalEmailBounced };
