import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { ButtonLink } from '../../../../atoms';
import { EmptyContent } from '../../../../components';
import { AboStatusFilter } from './types';
import { stripSlash } from '../../../../util/urlHelper';

interface Props {
  currentPath: string;
  statusFilter: AboStatusFilter;
}

const getAboCreationRoute = (path: string): string => {
  const withoutTrailingSlash = stripSlash(path);
  const split = withoutTrailingSlash.split('/');
  const parentPath = split.slice(0, -1).join('/');

  return parentPath + '/create';
};

const CreateAboEmptyContent: FC<Props> = ({ currentPath, statusFilter }) => (
  <EmptyContent title={_(`benefits:abo.archive.emptyContent.${statusFilter}`)} icon="icon-ticket">
    <ButtonLink to={getAboCreationRoute(currentPath)} theme="blue" css={{ marginTop: 16 }}>
      {_('benefits:abo.texts.createNewAbo')}
    </ButtonLink>
  </EmptyContent>
);

export default CreateAboEmptyContent;
