import React, { FC, ChangeEvent } from 'react';
import { Slider as MUISlider, SliderProps as MUISliderProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import { GRAY_3, WHITE } from '../../../camtool-styles';

const RAIL_HEIGHT = 16;
const TRACK_HEIGHT = 16 + 1.2;
const THUMB_ALIGN_CENTER = (RAIL_HEIGHT - 1) / 2;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: `${RAIL_HEIGHT}px`,
    },
    rail: {
      height: `${RAIL_HEIGHT}px`,
      backgroundColor: WHITE,
      border: `1px solid ${GRAY_3}`,
    },
    track: {
      height: `${TRACK_HEIGHT}px`,
    },
    thumb: {
      height: '30px',
      marginTop: `-${THUMB_ALIGN_CENTER}px`,
      borderRadius: '0%',
      '&.Mui-disabled': {
        height: '24px',
      },
    },
  })
);

export interface SliderProps extends Omit<MUISliderProps, 'onChange'> {
  onChange: (
    value: number | number[],
    event?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

const Slider: FC<SliderProps> = ({ name, value, onChange, min, max, step, classes, ...props }) => {
  const atomClasses = useStyles();
  const mergedClasses = { ...classes, ...atomClasses };

  return (
    <MUISlider
      name={name}
      value={value}
      onChange={(event, value): void => {
        onChange(value);
      }}
      step={step}
      min={min}
      max={max}
      classes={mergedClasses}
      {...props}
    />
  );
};

export default Slider;
