import * as Yup from 'yup';
import { _ } from '../../../../util/translate';

export const MAX_ABO_NAME_LENGTH = 30;
export const MAX_ABO_DESCRIPTION_LENGTH = 200;

export const getValidationSchema = (): Yup.ObjectSchema<object> =>
  Yup.object().shape({
    nameTranslation: Yup.object().shape(
      {
        de: Yup.string().when('en', {
          is: (nameEN) => nameEN?.length < 1,
          then: Yup.string()
            .max(MAX_ABO_NAME_LENGTH, _('benefits:abo.detailsPage.formErrors.nameTooLong'))
            .required(_('benefits:abo.detailsPage.formErrors.nameRequired')),
          otherwise: Yup.string(),
        }),
        en: Yup.string().when('de', {
          is: (nameDE) => nameDE?.length < 1,
          then: Yup.string()
            .max(MAX_ABO_NAME_LENGTH, _('benefits:abo.detailsPage.formErrors.nameTooLong'))
            .required(_('benefits:abo.detailsPage.formErrors.nameRequired')),
          otherwise: Yup.string(),
        }),
      },
      [['de', 'en']]
    ),
    descriptionTranslation: Yup.object().shape(
      {
        de: Yup.string().when('en', {
          is: (descriptionEN) => descriptionEN?.length < 1,
          then: Yup.string()
            .max(
              MAX_ABO_DESCRIPTION_LENGTH,
              _('benefits:abo.detailsPage.formErrors.descriptionTooLong')
            )
            .required(_('benefits:abo.detailsPage.formErrors.descriptionRequired')),
          otherwise: Yup.string(),
        }),
        en: Yup.string().when('de', {
          is: (descriptionDE) => descriptionDE?.length < 1,
          then: Yup.string()
            .max(
              MAX_ABO_DESCRIPTION_LENGTH,
              _('benefits:abo.detailsPage.formErrors.descriptionTooLong')
            )
            .required(_('benefits:abo.detailsPage.formErrors.descriptionRequired')),
          otherwise: Yup.string(),
        }),
      },
      [['de', 'en']]
    ),
  });
