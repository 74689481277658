import React from 'react';
import PropTypes from 'prop-types';

const ButtonGroup = ({ children, className, position }) => {
  if (!children) return null;
  return <div className={`btn-group ${className} ${position}`}>{children}</div>;
};

ButtonGroup.propTypes = {
  position: PropTypes.oneOf(['left', 'center', 'right', 'space-between']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ButtonGroup.defaultProps = {
  position: '',
  className: '',
};

export default ButtonGroup;
export { ButtonGroup };
