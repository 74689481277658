import React from 'react';
import PropTypes from 'prop-types';

import AbstractComponent from '../AbstractComponent';
import Tooltip from '../Tooltip/Tooltip';

class GroupColumn extends AbstractComponent {
  state = { check: '' };

  render() {
    const { errorMessage, className, label, tooltip, tooltipPosition, tooltipText, labelClass } = this.props; // prettier-ignore
    const { check } = this.state;

    return (
      <div className="groupcolumn__outside-container">
        {errorMessage && <div className="groupcolumn__errorMessage">{errorMessage}</div>}

        <article className={`groupcolumn__content${check} ${className ? className : ''}`}>
          {label && (
            <div className="label">
              <label className={labelClass}>{label}</label>
              <div className="spacer" />
            </div>
          )}

          <section className={`${tooltip === 'clear' ? tooltip : ''}`}>
            <div>{this.props.children}</div>
          </section>

          {tooltip !== 'none' && tooltip !== 'clear' && (
            <aside className="tooltip">
              <Tooltip position={tooltipPosition} text={tooltipText} />
            </aside>
          )}
        </article>
      </div>
    );
  }
}

GroupColumn.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};

GroupColumn.defaultProps = {
  errorMessage: '',
  className: '',
  label: '',
  labelClass: '',
  tooltip: '',
  tooltipPosition: '',
  tooltipText: '',
  children: null,
};

export default GroupColumn;
export { GroupColumn };
