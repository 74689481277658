import { Box } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Form, Formik } from 'formik';
import React, { FC, ReactElement } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import NewButton from '../../../../../atoms/Button/NewButton';
import GenericEmptyContent from '../../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { GRAY_2, WHITE_2 } from '../../../../../camtool-styles';
import { Spinner } from '../../../../../components';
import { _ } from '../../../../../util/translate';
import {
  useTelegramGetAutoMails,
  useTelegramSendAutoMail,
  useVXServicesTelegramContext,
} from '../../../../Telegram';
import useTelegramGetMimeTypes from '../../../../Telegram/hooks/useTelegramMimetypes';
import { MobileUserLifeCycleStep } from '../types';
import {
  getInitialDummyEntry,
  saveAutoMail,
  transformLifeCycleForFormik,
  validationSchemaMobile,
} from '../utils';
import AutoMailGridFormItemAttachment from './AutoMailGridFormItemAttachment';
import AutoMailGridFormItemDelayUnit from './AutoMailGridFormItemDelayUnit';
import AutoMailGridFormItemDelete from './AutoMailGridFormItemDelete';
import AutoMailGridFormItemDisplayUnit from './AutoMailGridFormItemDisplayUnit';
import AutoMailGridFormItemText from './AutoMailGridFormItemText';

interface AutoMailGridFormItemProps {
  isVip: boolean;
}

const AutoMailGridFormItem: FC<AutoMailGridFormItemProps> = ({ isVip }) => {
  const { id } = useParams();
  const { token } = useVXServicesTelegramContext();
  const history = useHistory();
  const lifeCycleId = id !== 'null' ? Number(id) : undefined;
  const { loading, error, data } = useTelegramGetAutoMails(isVip, lifeCycleId);
  const [saveLifeCycle] = useTelegramSendAutoMail();
  const initialDummyEntry = getInitialDummyEntry(isVip) as MobileUserLifeCycleStep[];

  const {
    loading: loadingMimetypes,
    error: errorMimetypes,
    data: mimetypes,
  } = useTelegramGetMimeTypes(isVip);

  const allowedMimetype =
    !loadingMimetypes && !errorMimetypes ? mimetypes?.master?.allowedMimeTypes : [];

  if (loading) return <Spinner />;
  if (error) return <GenericEmptyContent />;

  const initialValue = lifeCycleId
    ? transformLifeCycleForFormik(data?.master?.userLifeCycle)
    : initialDummyEntry;

  return (
    <Formik
      validationSchema={validationSchemaMobile(allowedMimetype ? allowedMimetype : [''])}
      initialValues={initialValue[0]}
      // reset formik's dirty attribute once lifecycle has been saved and refetched
      enableReinitialize={true}
      onSubmit={async (values, { setSubmitting }): Promise<void> => {
        await saveAutoMail(
          values as MobileUserLifeCycleStep,
          saveLifeCycle,
          token,
          isVip,
          lifeCycleId
        );
        // workaround to not being able to refetch newly created lifecycle via params -> navigate one route back...
        history.push('.');
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, dirty, errors }): ReactElement => {
        const isDisabled = isSubmitting || !dirty || Object.keys(errors).length > 0;

        return (
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              gridGap="10px"
              bgcolor={WHITE_2}
              width="100%"
              m={2}
            >
              <Box borderBottom={`1px solid ${GRAY_2}`} p={2}>
                <Link
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: GRAY_2,
                    fontSize: '14px',
                  }}
                  // link to parent route
                  to={'.'}
                >
                  <ChevronLeftIcon fontSize="small" />
                  {_('userManagement:details.backToOverview')}
                </Link>
              </Box>
              <AutoMailGridFormItemDisplayUnit />
              <AutoMailGridFormItemDelayUnit />
              <AutoMailGridFormItemText />
              <AutoMailGridFormItemAttachment mimetypes={allowedMimetype} />
              <AutoMailGridFormItemDelete isVip={isVip} />
              <Box position="sticky" bottom="29px">
                <NewButton
                  disabled={isDisabled}
                  style={{ width: '100%' }}
                  type="submit"
                  size="large"
                >
                  {_('common:button.save')}
                </NewButton>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AutoMailGridFormItem;
