import { Area } from 'react-easy-crop/types';
import { createImage, rotateSize } from '../../../util/createImage';

/**
 * To work around firefox down-sampling implementation,
 * introduce a intermediate image buffer, that holds a
 * dynamically blurred version of the image, to help
 * pre-filter pixels for the down-sampling.
 *
 * As the canvas can not be arbitrary big,
 * the canvas is capped in size to 4049 px
 *
 * In case firefox will support `imageSmoothingQuality = 'high';`
 * this step won't be necessary anymore
 *
 * @see https://stackoverflow.com/a/17862644
 */
const getDownSampledImageForFirefox = (
  image: HTMLImageElement,
  sourceWidth: number,
  targetWidth: number
) => {
  const longestImageDimension = Math.max(
    1, // must be at least 1x1
    Math.max(image.width, image.height)
  );

  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size
  const maxSizeDownscaleFactor = Math.min(8192, longestImageDimension) / longestImageDimension;
  const downsizeCanvas = document.createElement('canvas');
  downsizeCanvas.width = image.width * maxSizeDownscaleFactor;
  downsizeCanvas.height = image.height * maxSizeDownscaleFactor;
  const downsizeCtx = downsizeCanvas.getContext('2d');

  if (!downsizeCtx) {
    return null;
  }

  const isCanvasFilterSupported = typeof downsizeCtx.filter !== 'undefined';
  const useManualDownSampling = isCanvasFilterSupported && /firefox/i.test(navigator.userAgent);
  if (!useManualDownSampling) {
    return null;
  }
  // Draw blurred image based on resolution differences
  // This basically pre-filters the data for the a down-sampling step
  const steps = ((sourceWidth * maxSizeDownscaleFactor) / targetWidth) >> 1;
  downsizeCtx.filter = `blur(${steps}px)`;
  downsizeCtx.drawImage(image, 0, 0, downsizeCanvas.width, downsizeCanvas.height);
  downsizeCtx.filter = 'none';

  return downsizeCanvas;
};

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  enforceTargetImageDimensions: boolean,
  targetImageDimension: { width: number; height: number },
  rotation = 0
): Promise<string | null> {
  const image = await createImage(imageSrc);

  if (!enforceTargetImageDimensions) {
    const largestDimension = Math.max(pixelCrop.width, pixelCrop.height);
    const maxAllowedExtend = 3840;
    const downscaleFactor = Math.min(largestDimension, maxAllowedExtend) / largestDimension;
    targetImageDimension = {
      width: pixelCrop.width * downscaleFactor,
      height: pixelCrop.height * downscaleFactor,
    };
  }

  const downsizeCanvas = getDownSampledImageForFirefox(
    image,
    pixelCrop.width,
    targetImageDimension.width
  );
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // Enable higher quality interpolation
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  const imageCenter = { x: image.width / 2, y: image.height / 2 };
  ctx.translate(+imageCenter.x, +imageCenter.y);
  ctx.rotate((rotation / 180) * Math.PI);
  ctx.translate(-imageCenter.x, -imageCenter.y);

  // draw rotated image
  ctx.drawImage(downsizeCanvas ?? image, 0, 0, image.width, image.height);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // VARIANT 1: Return as Base64 string
  return canvas.toDataURL('image/jpeg');

  // VARIANT 2: Return as a Blob
  // return new Promise((resolve) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file));
  //   }, 'image/jpeg');
  // });
}
