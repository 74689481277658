import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import {
  VXGAMES_TYPOGRAPHY_H2,
  VXGAMES_TYPOGRAPHY_SUBHEADING,
  VXGAMES_PRIMARY,
} from '../../../../utils';

const CompetitionCongratulation: FC = () => {
  return (
    <>
      <Typography
        style={VXGAMES_TYPOGRAPHY_SUBHEADING}
        gutterBottom
      >{`Auslosung findet in wenigen Tagen nach Ende der Herausforderung statt, die Gewinner werden autm. benachrichtigt`}</Typography>
      <Typography style={{ ...VXGAMES_TYPOGRAPHY_H2, ...{ color: VXGAMES_PRIMARY } }} gutterBottom>
        {'Herzlichen Glückwunsch! Du hast die Herausforderung geschafft!'}
      </Typography>
    </>
  );
};

export { CompetitionCongratulation };
