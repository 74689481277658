import { Override } from './types';

export const MuiFormHelperText: Override<'MuiFormHelperText'> = {
  root: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  contained: {
    marginLeft: 0,
    marginRight: 0,
  },
};
