import React, { FC, ReactElement, ReactNode, CSSProperties, useRef } from 'react';
import styled from '@emotion/styled';
import { ClickAwayListener } from '@material-ui/core';
import { Popover } from '../../../atoms';
import { StyledButton } from '../styles';
import { useDisplayState } from '../../../hooks';
import { _ } from '../../../util/translate';

interface TopBarPopoverProps {
  children: ReactElement;
  icon: ReactNode;
  paperStyle?: CSSProperties;
}

const Container = styled.div`
  position: relative;
`;

const TopBarPopoverElement: FC<TopBarPopoverProps> = ({ paperStyle, icon, children }) => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const { shouldShow: showPopover, hide: hidePopover, toggle: togglePopover } = useDisplayState();

  const handleClose = (): void => {
    hidePopover();
  };
  const toggle = (): void => togglePopover();

  return (
    <ClickAwayListener onClickAway={hidePopover}>
      <Container>
        <StyledButton
          ref={anchorEl}
          data-popper={'service0900'}
          onClick={toggle}
          isOpen={showPopover}
        >
          <div className="topbar__button">{icon}</div>
        </StyledButton>
        <Popover
          style={paperStyle}
          id={showPopover ? 'simple-popover' : undefined}
          open={showPopover}
          anchorEl={anchorEl.current}
          onClose={handleClose}
        >
          {!!children && React.cloneElement(children, { handleClose, open: showPopover })}
        </Popover>
      </Container>
    </ClickAwayListener>
  );
};

export default TopBarPopoverElement;
