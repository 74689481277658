import React, { FC } from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import { WHITE, BLACK, BLUE } from '../../../camtool-styles';
import VXMSwitch from './VXMSwitch.svg';
import { _ } from '../../../util/translate';

interface IProps {
  onClickCloseModal?: () => void;
  hideButton?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    color: BLACK,
    padding: 16,
    height: '100%',
  },
  container: {
    color: BLACK,
    padding: 16,
  },
  svg: {
    margin: 'auto 0',
  },
  content: {
    color: BLACK,
    height: '100%',
    margin: 'auto 0',
  },
  heading: {
    color: BLACK,
    marginTop: 14,
    marginBottom: 30,
    textAlign: 'center',
  },
  bulletPoints: {
    display: 'flex',
    marginTop: 8,
  },
  bulletPointsText: {
    color: BLACK,
    paddingLeft: 4,
    paddingBottom: 20,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    color: WHITE,
    marginTop: 16,
    marginBottom: 16,
  },
  link: {
    color: BLUE,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const NewVXMSwitch: FC<IProps> = (props) => {
  const bulletpointsDescriptions: Array<string> = [
    'dashboard:app.switchVXMBeta.bulletpoint1',
    'dashboard:app.switchVXMBeta.bulletpoint2',
    'dashboard:app.switchVXMBeta.bulletpoint3',
  ];
  const {
    root,
    container,
    svg,
    content,
    heading,
    bulletPoints,
    bulletPointsText,
    buttons,
    button,
  } = useStyles();

  return (
    <Box className={root}>
      <Typography variant="h3" className={heading}>
        {_('dashboard:app.switchVXMBeta.heading')}
      </Typography>
      <Grid container spacing={3} className={container}>
        <Grid item xs={12} md={7} className={content}>
          <Box>
            {bulletpointsDescriptions.map((item, i) => {
              return (
                <Box className={bulletPoints} key={i}>
                  <StarIcon style={{ color: 'ffb033' }} />
                  <Typography className={bulletPointsText}>{_(item)}</Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={12} md={5} className={svg}>
          <img src={VXMSwitch}></img>
        </Grid>
      </Grid>
      {!props.hideButton && (
        <Box className={buttons}>
          <Button className={button} href={'https://beta.vxmodels.com/dashboard'}>
            {_('dashboard:app.switchVXMBeta.button')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NewVXMSwitch;
