import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';
import { _ } from '../../../../util/translate';

const getStatusText = data => {
  if (data.isRejected) {
    return _('mediamanagement:picfolder.contentItemStatus.pictureRejected');
  }
  if (!data.isChecked && !data.isRejected) {
    return _('mediamanagement:picfolder.contentItemStatus.inchecking');
  }
  return _('mediamanagement:picfolder.contentItemStatus.accepted');
};

class AlbumItemDetails extends AbstractComponent {
  onClickHideDetails(event) {
    event.stopPropagation();
    this.props.onClickHideDetails(this.props.data);
  }

  onClickDetails(event) {
    event.stopPropagation();
  }

  render() {
    const { data } = this.props;
    return (
      <div
        className="mediamanagement__pic__album__content__item__details"
        onClick={this.onClickDetails}
      >
        {/*Close Button*/}
        <div
          className="mediamanagement__pic__album__content__item__details__close"
          onClick={this.onClickHideDetails}
        />

        {/*Bild Status*/}
        <div>
          <div>{_('mediamanagement:picfolder.contentItemDetails.status')}:</div>
          <div>{getStatusText(data)}</div>
        </div>

        {/*Bild ID*/}
        <div>
          <div>{_('mediamanagement:picfolder.contentItemDetails.pictureId')}:</div>
          <div>{data.pictureId}</div>
        </div>

        {/*FSK Stufe*/}
        <div>
          <div>{_('mediamanagement:picfolder.contentItemDetails.rating')}:</div>
          <div className="icon-fsk-{this.props.data.fsk}">
            {_('mediamanagement:picfolder.contentItemDetails.fsk')} {data.fsk}
          </div>
        </div>

        {/*Social Icon*/}
        {data.isSocial && (
          <div>
            <div />
            <div className="icon-thumbs-up">
              {_('mediamanagement:picfolder.contentItemDetails.isSocial')}
            </div>
          </div>
        )}

        {/*Wenn das Bild als Preview gesetzt ist*/}
        {(data.isPreviewPic16 || data.isPreviewPic18) && (
          <div>
            <div />
            <div className="icon-picture">
              {_('mediamanagement:picfolder.contentItemDetails.isPreviewPicture')}
            </div>
          </div>
        )}

        {/*VX Brand*/}
        {data.isVisitXBrand && (
          <div>
            <div />
            <div className="">
              {_('mediamanagement:picfolder.contentItemDetails.isVisitXBrand')} VX VISIT-X Brand
            </div>
          </div>
        )}
      </div>
    );
  }
}

AlbumItemDetails.propTypes = {
  data: PropTypes.object.isRequired,
  onClickHideDetails: PropTypes.func.isRequired,
};

export default AlbumItemDetails;
export { AlbumItemDetails };
