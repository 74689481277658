import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { VXCashStore } from '../../../stores/VXCash/VXCashStore';
import { VXCashActionCreators } from '../../../stores/VXCash/VXCashActionCreators';
import {
  AbstractComponent,
  Footer,
  GroupRow,
  Markdown,
  Spinner,
  T,
  Tooltip,
} from '../../../components';
import { _ } from '../../../util/translate';

const STORE = 'adlinks';

class InviteModel extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = this.getStateFromStores();
  }

  getStateFromStores() {
    return VXCashStore.get(STORE);
  }

  onStoreChange() {
    const stateFromStore = this.getStateFromStores();
    stateFromStore.isDataLoading = false;
    this.setState(stateFromStore);
  }

  componentWillMount() {
    VXCashStore.addChangeListener(STORE, this.onStoreChange);
    VXCashActionCreators.getVXCashAdlinks();
  }

  componentWillUnmount() {
    VXCashStore.removeChangeListener(STORE, this.onStoreChange);
  }

  render() {
    return (
      <main className="grid vxcash-links">
        <div className="grid__row">
          <div className="grid__column grid__box">
            <div className="grid__box__header">
              <div className="grid__box__header__title">
                <T _={'vxcash:nav.page.invite.models'} />
              </div>
            </div>

            <div className="grid__box__row spinner-container">
              {this.state.isDataLoading ? <Spinner /> : null}

              <div className="grid__box__column grid__box__sub">
                <div className="grid__box__sub__item">
                  <div className="grid__box__sub__item__content">
                    <GroupRow tooltip="none">
                      <Markdown className="" source={_('vxcash:adlink.model.body.title')} />

                      <Markdown className="" source={_('vxcash:adlink.model.body.info')} />
                    </GroupRow>

                    <GroupRow>
                      <div className="invite-model__copy-tool__label">
                        <T _={'vxcash:adlink.model.body.vxtool.tooltip'} />
                      </div>
                    </GroupRow>

                    <div>
                      {/* todo: use <LinkBox /> component instead */}
                      <div className="invite-mode__product-tool">
                        <div>
                          <span className="icon-tool">Tool</span>
                          <span className="name">
                            https://www.werdecamgirl.de/w/{this.state.userName}
                          </span>
                          <aside className="tooltip">
                            <Tooltip
                              position="bottom"
                              text={_('vxcash:adlink.model.body.vxtool.tooltip')}
                            />
                          </aside>
                        </div>
                      </div>
                      <div className="copylink">
                        <CopyToClipboard
                          text={`https://www.werdecamgirl.de/w/${this.state.userName}`}
                        >
                          <div>
                            <span>
                              <T _={'vxcash:adlink.button.copylink.name'} />
                            </span>
                          </div>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div style={{ flexDirection: 'column' }}>
                      <div className="invite-model__foot__title">
                        <T _={'vxcash:adlink.model.foot.title'} />
                      </div>

                      <Markdown className="" source={_('vxcash:adlink.model.foot.info')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid__row grid__box">
          <Footer className="grid__box__item" />
        </div>
      </main>
    );
  }
}

export default InviteModel;
export { InviteModel };
