import React, { FC } from 'react';
import MUISwitch, { SwitchProps } from '@material-ui/core/Switch';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core';
import { GRAY_2, GREEN, WHITE } from '../../camtool-styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 40,
      height: 20,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(20px)',
        color: WHITE,
        '& + $track': {
          backgroundColor: GREEN,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: GREEN,
        border: '6px solid ${WHITE}',
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 20 / 2,
      border: 'none',
      backgroundColor: GRAY_2,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
);

const Switch: FC<SwitchProps> = ({ color, ...props }) => {
  const { focusVisible, ...classes } = useStyles({ color });
  return <MUISwitch focusVisibleClassName={focusVisible} classes={classes} {...props} />;
};

export default Switch;
