import React, { FC } from 'react';
import CancelIcon from '../../../../atoms/Icon/CancelIcon';
import PendingIcon from '../../../../atoms/Icon/PendingIcon';
import DoneIcon from '../../../../atoms/Icon/DoneIcon';
import CycleIcon from '../../../../atoms/Icon/CycleIcon';
import FailedIcon from '../../../../atoms/Icon/FailedIcon';
import DeletedIcon from '../../../../atoms/Icon/DeletedIcon';
import { BroadcastStatus } from '../../types';
import { Avatar } from '@material-ui/core';
import IconBase from '../../../../atoms/Icon/IconBase';

interface StatusIconProps {
  status: BroadcastStatus;
}

const StatusIcon: FC<StatusIconProps> = ({ status }) => {
  switch (status) {
    case BroadcastStatus.pending:
      return (
        <Avatar>
          <IconBase fontSize="42px" iconElement={<PendingIcon />} viewBox="0 0 42 42" />
        </Avatar>
      );
    case BroadcastStatus.canceled:
      return (
        <Avatar>
          <IconBase fontSize="42px" iconElement={<CancelIcon />} viewBox="0 0 42 42" />
        </Avatar>
      );
    case BroadcastStatus.revoked:
      return (
        <Avatar>
          <IconBase fontSize="42px" iconElement={<DeletedIcon />} viewBox="0 0 42 42" />
        </Avatar>
      );
    case BroadcastStatus.done:
      return (
        <Avatar>
          <IconBase fontSize="42px" iconElement={<DoneIcon />} viewBox="0 0 42 42" />
        </Avatar>
      );
    case BroadcastStatus.running:
      return (
        <Avatar>
          <IconBase fontSize="42px" iconElement={<CycleIcon />} viewBox="0 0 42 42" />
        </Avatar>
      );
    default:
      // BroadcastStatus.failed
      return (
        <Avatar>
          <IconBase fontSize="42px" iconElement={<FailedIcon />} viewBox="0 0 42 42" />
        </Avatar>
      );
  }
};

export default StatusIcon;
