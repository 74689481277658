import gql from 'graphql-tag';

const FRAGMENT_MODEL_MEDIA_ALBUMS = gql`
  fragment MediaAlbums on MediaAlbums {
    albumsTotal
    albums {
      id
      isVideoAlbum
      numberOfReleasedPictures
      created
      price
      titles {
        language
        text
      }
      previewPicture16 {
        url
      }
      previewPicture18 {
        url
      }
      video {
        duration
        status
      }
    }
  }
`;

// Our backend cannot handle more than approximately 500 albums. After that, it will run into a "memory size exhausted"
// error. If we truly need more than 500 albums, we will have to use something like the useVXPagesAlbumData hook.
const ALBUM_COUNT = 400;

export const QUERY_MODEL_VIDEOS_PHOTO_ALBUMS_SHOP = gql`
  query QUERY_MODEL_VIDEOS_SHOP {
    model {
      photoAlbums(limit: ${ALBUM_COUNT / 2}, type: shop) {
        ...MediaAlbums
      }
      videos(limit: ${ALBUM_COUNT / 2}, type: shop) {
        ...MediaAlbums
      }
    }
  }
  ${FRAGMENT_MODEL_MEDIA_ALBUMS}
`;

export const QUERY_MODEL_VIDEOS_SHOP = gql`
  query QUERY_MODEL_VIDEOS_SHOP {
    model {
      videos(limit: ${ALBUM_COUNT}, type: shop) {
        ...MediaAlbums
      }
    }
  }
  ${FRAGMENT_MODEL_MEDIA_ALBUMS}
`;

export const QUERY_MODEL_PHOTO_ALBUMS_SHOP = gql`
  query QUERY_MODEL_PHOTO_ALBUMS_SHOP {
    model {
      photoAlbums(limit: ${ALBUM_COUNT}, type: shop) {
        ...MediaAlbums
      }
    }
  }
  ${FRAGMENT_MODEL_MEDIA_ALBUMS}
`;

export const QUERY_MODEL_BONUS_CODE_TOKEN = gql`
  {
    model {
      bonuscodes {
        token
        availableContentMinAmounts {
          value
          label
        }
      }
    }
  }
`;

/***************************************************************/
/** BONUS CODE QUERIES *****************************************/
/***************************************************************/

export const GENERATE_BONUS_CODE_CODE = gql`
  query GENERATE_BONUSCODE_CODE($length: Int) {
    builder {
      generateCode(length: $length)
    }
  }
`;

// CAREFUL bonusCodes IS DEPRECATED - MOVE TO bonusCodes_v2 NEXT
export const QUERY_BONUS_CODE_LIST = gql`
  {
    bonusCodes {
      code
      redeemCount
      status
      valid
      config {
        id
        name
        comment
        limitConfig
        limitInstance
        validFrom
        validTo
        creatorId
        subrefs
        conditions {
          firstPayment
          ignoreReferences
          internal
          personalized
          registeredDate
          registeredPlatform
          requireMobileAuth
          secondAboAllowed
          platforms {
            pfmId
            pfmSubref
          }
        }
        texts {
          type
          language
          value
        }

        benefits {
          __typename
          ... on BenefitMedia {
            type
            id
            albumId
          }

          ... on BenefitVoucherVideo {
            id
            type
            actorIds
            voucherVideoAmount
            voucherVideoAmountStaggering {
              from
              amount
            }
          }

          ... on BenefitVoucherPhoto {
            id
            type
            actorIds
            voucherPhotoAmount
            voucherPhotoAmountStaggering {
              from
              amount
            }
          }

          ... on BenefitFreeMinutes {
            id
            type
            actorId
            freeMinutesCount
            freeMinutesCountStaggering {
              from
              amount
            }
          }

          ... on BenefitFreeMessages {
            id
            type
            actorId
            freeMessagesCount
            freeMessagesCountStaggering {
              from
              amount
            }
          }

          ... on BenefitBonusCredits {
            id
            type
            bonusFlatCredits
            bonusTopUpCreditsStaggering {
              from
              amount
            }
          }

          ... on BenefitLiveShow {
            id
            type
          }
        }
      }

      redemptions {
        items {
          usageId
          user {
            id
            name
          }
          date
          pfmId
        }
      }
    }
  }
`;

export const QUERY_BONUS_CODE = gql`
  query QUERY_BONUS_CODE($code: String!) {
    bonusCode(code: $code) {
      code
      redeemCount
      status
      valid
      config {
        id
        name
        comment
        limitConfig
        limitInstance
        validFrom
        validTo
        creatorId
        subrefs
        conditions {
          firstPayment
          ignoreReferences
          internal
          personalized
          registeredDate
          registeredPlatform
          requireMobileAuth
          secondAboAllowed
          platforms {
            pfmId
            pfmSubref
          }
        }
        texts {
          type
          language
          value
        }

        benefits {
          __typename
          ... on BenefitMedia {
            type
            id
            albumId
          }

          ... on BenefitVoucherVideo {
            id
            type
            actorIds
            voucherVideoAmount
            voucherVideoAmountStaggering {
              from
              amount
            }
          }

          ... on BenefitLiveShow {
            id
            type
          }

          ... on BenefitVoucherPhoto {
            id
            type
            actorIds
            voucherPhotoAmount
            voucherPhotoAmountStaggering {
              from
              amount
            }
          }

          ... on BenefitFreeMinutes {
            id
            type
            actorId
            freeMinutesCount
            freeMinutesCountStaggering {
              from
              amount
            }
          }

          ... on BenefitFreeMessages {
            id
            type
            actorId
            freeMessagesCount
            freeMessagesCountStaggering {
              from
              amount
            }
          }

          ... on BenefitBonusCredits {
            id
            type
            bonusFlatCredits
            bonusTopUpCreditsStaggering {
              from
              amount
            }
          }
        }
      }

      redemptions {
        items {
          usageId
          user {
            id
            name
          }
          date
          pfmId
        }
      }
    }
  }
`;

export const QUERY_BONUS_CODE_AVAILABLE = gql`
  query QUERY_BONUSCODE_AVAILABLE($code: String!) {
    builder {
      isCodeAvailable(code: $code)
    }
  }
`;
