import { Subscription } from '@reactivex/rxjs/dist/package/src/internal/Subscription';
import { CancelTokenSource } from 'axios';
import * as tus from 'tus-js-client';

export type FilesHandler = (files: File[]) => void;

export type FileId = string;

export enum StateEnum {
  verifying = 'verifying',
  waiting = 'waiting',
  uploading = 'uploading',
  afterUpload = 'afterUpload',
  transcoding = 'transcoding',
  finished = 'finished',
  error = 'error',
  canceled = 'canceled',
}

export enum MediaTypeEnum {
  picture = 'picture',
  video = 'video',
  all = 'all',
}

export interface FileWrapper {
  id: FileId;
  file: File;
  mediaType: MediaTypeEnum;
  md5hash?: string;
  preview?: string;
  verified?: boolean;
  errorMessage?: ErrorMessageEnum;
}

export interface QueueItem {
  id: FileId;
  displayName: string;
  fileId: FileId;
  state: StateEnum;
  mediaType: MediaTypeEnum;
  md5hash?: string;
  preview?: string;
  verified?: boolean;
  progress: number;
  errorMessage?: ErrorMessageEnum;
  cancelTokenSource?: CancelTokenSource | undefined;
}

export type UserData = {
  userId?: string;
  sessionId?: string;
  picturePoolUmaId?: number;
};

export interface ContextState {
  userData: UserData;
  queue: QueueItem[];
  externalQueue: QueueItem[];
  files: Record<string, FileWrapper>;
  currentUpload: FileId | null;
  uploader: { uploader: tus.Upload; id: string }[];
}

export type QueueItemIdFn = (id: FileId) => void;

export interface ContextFunctions {
  addFiles: (files: File[]) => void;
  addFile: (file: File) => void;
  removeQueueItem: QueueItemIdFn;
  cancelQueueItem: QueueItemIdFn;
  retryQueueItem: QueueItemIdFn;
  subscribe: (observer: Record<string, unknown>) => void;
  queueInit: () => void;
  updateUserData: (userData: UserData) => void;
  queueSubscribe: (observer: Record<string, unknown>) => Subscription;
  filesSubscribe: (observer: Record<string, unknown>) => Subscription;
  updateExternalQueue: (externalQueue: QueueItem[]) => void;
  clearQueue: () => void;
}

export enum ActionEnum {
  updateFileWrapper = 'updateFileWrapper',
  calcMd5Hash = 'calcMd5Hash',
  md5hashCalculated = 'md5hashCalculated',
  verified = 'verified',
  fileAdded = 'fileAdded',
  updateQueueItem = 'updateQueueItem',
  updateCurrentUpload = 'updateCurrentUpload',
  uploadFailed = 'uploadFailed',
  uploadFinished = 'uploadFinished',
  updateTusUploader = 'updateTusUploader',
}

export enum ErrorMessageEnum {
  dbError = 'dbError', // oracle error 20100 (also duplicate content, perhaps ???)
  sessionCheckFailed = 'sessionCheckFailed', // SessionCheck failed (not applicable in this context)
  unknownError = 'unknownError',
  pictureAlbumNotFound = 'pictureAlbumNotFound', // uma_id not found (not applicable in this context)
  pictureInvalidFilenameExtension = 'pictureInvalidFilenameExtension', // Filename extension not accepted
  pictureMaxFilesizeExceeded = 'pictureMaxFilesizeExceeded', // upload aborted due to max upload size reached
  pictureDimensionsTooSmall = 'pictureDimensionsTooSmall', // dimensions too small (min 520px)
  pictureDuplicateContent = 'pictureDuplicateContent', // duplicate content
  pictureTooHomogeneous = 'pictureTooHomogeneous',
  pictureRatioWrong = 'pictureRatioWrong',
  videoDuplicateContent = 'videoDuplicateContent',
  videoLengthTooShort = 'videoLengthTooShort',
}

export interface QueueFilter {
  mediaType?: MediaTypeEnum;
}

type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type FileWrapperPayload = { fileWrapper: FileWrapper };
export type QueueItemPayload = { queueItem: Partial<QueueItem> & { id: FileId } };
export type TusUploaderPayload = { uploader: { uploader: tus.Upload; id: string }[] };

export type ActionPayloadMap = {
  [ActionEnum.updateFileWrapper]: FileWrapperPayload;
  [ActionEnum.calcMd5Hash]: FileWrapperPayload;
  [ActionEnum.md5hashCalculated]: FileWrapperPayload;
  [ActionEnum.fileAdded]: FileWrapperPayload;
  [ActionEnum.updateQueueItem]: QueueItemPayload;
  [ActionEnum.updateTusUploader]: TusUploaderPayload;
  [ActionEnum.verified]: QueueItemPayload;
  [ActionEnum.uploadFinished]: QueueItemPayload;
  [ActionEnum.updateCurrentUpload]: FileId | null;
};

export type ActionType = keyof ActionMap<ActionPayloadMap>;
export type ActionPayload = ActionPayloadMap[ActionType];
export type Action = ActionMap<ActionPayloadMap>[ActionType];
