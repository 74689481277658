import React, { FC } from 'react';
import { _ } from '../../../../../../util/translate';
import NewButton from '../../../../../../atoms/Button/NewButton';

interface Props {
  onClose: (event: React.MouseEvent) => void;
}

const ModalBonusCancelButton: FC<Props> = ({ onClose }) => {
  return (
    <NewButton variant="text" onClick={onClose} disabled={false}>
      {_('common:button.cancel')}
    </NewButton>
  );
};

export default ModalBonusCancelButton;
