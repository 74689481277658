import { useMutation, MutationTuple, MutationHookOptions } from '@apollo/react-hooks';
import { MUTATION_MASTER_MESSENGERVIPTEXT_SETBULLETS } from '../graphql/mutations';
import {
  MasterMessengerVipTextMutationsetBulletsArgs,
  Mutation,
} from '../../../graphql/VXServicesTelegram/types';
import { useVXServicesTelegramClient } from '../../../graphql/VXServicesTelegram/hooks';

const useSetMessengerVipBullets = (
  options?: MutationHookOptions<Mutation, Record<string, any>> | undefined
): MutationTuple<
  Mutation,
  MasterMessengerVipTextMutationsetBulletsArgs & { token: string | null | undefined }
> => {
  const client = useVXServicesTelegramClient();

  return useMutation<Mutation>(MUTATION_MASTER_MESSENGERVIPTEXT_SETBULLETS, {
    client,
    ...options,
  });
};

export default useSetMessengerVipBullets;
