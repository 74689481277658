import React, { FC, memo, useMemo, useState } from 'react';
import ApolloClient from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';
import { css } from '@emotion/core';
import { Redirect, RouteComponentProps, useHistory } from 'react-router';
import { Section } from '../../../../atoms';
import { ProtectedRoute, Spinner } from '../../../../components';
import { PageContainer } from '../../../BenefitsWizard/styles';
import { QUERY_GET_ACTOR_ABOS } from '../../queries';
import { AboContentTypesFilter, IAbo } from '../../types';
import { _ } from '../../../../util/translate';
import ModalAboDetails from '../Details/ModalAboDetails';
import AboTable from './Table/AboTable';
import { AboStatusFilter } from './types';
import { filterAboList, sortAboList } from './util';
import CreateAboEmptyContent from './CreateAboEmptyContent';
import GenericEmptyContent from '../../../../atoms/GenericEmptyContent/GenericEmptyContent';

interface Props {
  apolloClientAbo: ApolloClient<Record<string, never>>;
  statusFilter: AboStatusFilter;
  typesFilter?: AboContentTypesFilter;
}

const sectionStyles = css`
  position: relative;
  padding: 35px 25px;
  flex: 1 1 600px;
  max-height: calc(100vh - 253px);
`;

const AboArchive: FC<Props & RouteComponentProps> = ({
  apolloClientAbo,
  statusFilter,
  typesFilter,
  match: { path },
}) => {
  const {
    data: aboData,
    loading,
    error,
  } = useQuery(QUERY_GET_ACTOR_ABOS, {
    fetchPolicy: 'network-only',
    client: apolloClientAbo,
  });
  const history = useHistory();

  const aboDataAbos = (aboData && aboData.actor && aboData.actor.abos) || [];

  const filteredAboList = useMemo<IAbo[]>(
    () => sortAboList(filterAboList(aboDataAbos, statusFilter, typesFilter)),
    [JSON.stringify(aboDataAbos), statusFilter, JSON.stringify(typesFilter)]
  );

  const [aboDetails, setAboDetails] = useState<IAbo>();

  const showAboDetails = (abo: IAbo): void => {
    setAboDetails(abo);
    history.push(`${path}/modalabo/${abo.id}`);
  };
  const hideAboDetails = (): void => {
    // setAboDetails(undefined);
    history.push(`${path}`);
  };

  return (
    <>
      <ProtectedRoute
        path={`${path}/modalabo/:id`}
        component={ModalAboDetails}
        componentProps={{
          abo: aboDetails,
          onClose: hideAboDetails,
          apolloClientAbo,
        }}
      />
      <Redirect to={path} />
      <PageContainer>
        <Section title={_(`benefits:abo.archive.title.${statusFilter}`)} styles={sectionStyles}>
          {loading ? (
            <Spinner />
          ) : error ? (
            <GenericEmptyContent />
          ) : filteredAboList.length === 0 ? (
            <CreateAboEmptyContent currentPath={path} statusFilter={statusFilter} />
          ) : (
            <AboTable
              abos={filteredAboList}
              onShowAboDetailsClick={showAboDetails}
              displayDeactivationDate={statusFilter === AboStatusFilter.inactive}
            />
          )}
        </Section>
      </PageContainer>
    </>
  );
};

export default memo(AboArchive);
