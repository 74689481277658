import React, { FC } from 'react';
import TablePagination, { TablePaginationProps } from '@material-ui/core/TablePagination';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINT_PHONE_CONDITION } from '../../camtool-styles';

type PaginationProps = TablePaginationProps & { count: number };

const useStyles = makeStyles(() =>
  createStyles({
    root: (props: { isMobile: boolean }) => ({
      '& .MuiTablePagination-toolbar': {
        flex: '0 0 auto',
        ...(props.isMobile ? null : { justifyContent: 'flex-end' }),
        flexWrap: 'nowrap',
        '& div': {
          flex: '0 0 auto',
          flexWrap: 'nowrap',
        },
      },
    }),
  })
);

const Pagination: FC<PaginationProps> = (props) => {
  // noSsr to avoid applying styles too early (at state where isMobile would initially be false)
  const isMobile = useMediaQuery(BREAKPOINT_PHONE_CONDITION, { noSsr: true });
  const { root } = useStyles({ isMobile });
  return props.count > 0 ? <TablePagination className={root} {...props} /> : null;
};

export default Pagination;
