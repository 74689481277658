import React, { FC } from 'react';
import styled from '@emotion/styled';
import { YELLOW } from '../../../../camtool-styles';
import Icon from '../../../../atoms/Icon/Icon';
import { exclamationSign } from '../../../../atoms/Icon/libraries/glyph';
import { T } from '../../../../components';

const Container = styled.div`
  margin-top: 8px;
  color: ${YELLOW};
  align-items: center;
  padding-left: 1px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 6px;
`;

const InReviewMessage: FC = () => (
  <Container>
    <StyledIcon icon={exclamationSign} size="16px" />
    <T t="profiles:textRatingStatus.new" />
  </Container>
);

export default InReviewMessage;
