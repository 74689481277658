import React, { FC, useEffect, useState } from 'react';
import { EmptyContent, FancyError, Spinner } from '../../../components';
import { MailingStatusEnum } from '../../../graphql/VXModels/types';
import { FormRouter } from './index';
import { useMailing } from '../hooks';

interface EditMessageProps {
  id: number;
}

const POLL_INTERVAL = 5000;
const STATUS_WITH_POLLING: MailingStatusEnum[] = [
  MailingStatusEnum.SHIPMENT_PROGRESS,
  MailingStatusEnum.SHIPMENT_PENDING,
  MailingStatusEnum.SHIPMENT_PREPARED,
];

const EditMessage: FC<EditMessageProps> = ({ id }) => {
  const [pollInterval, setPollInterval] = useState<number | undefined>();
  const { mailing, loading, error } = useMailing(Number(id), { pollInterval });
  const status: MailingStatusEnum | undefined = mailing?.status;
  const hasPolling = status && STATUS_WITH_POLLING.includes(status);

  useEffect(() => setPollInterval(hasPolling ? POLL_INTERVAL : undefined), [hasPolling]);

  return error ? (
    <FancyError error={error} />
  ) : loading ? (
    <Spinner />
  ) : mailing ? (
    <FormRouter {...mailing} />
  ) : (
    <EmptyContent title={'Mailing not found'} />
  );
};

export default EditMessage;
