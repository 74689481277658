import { QueryHookOptions, useQuery } from '@apollo/react-hooks';

import { MessageList, User } from '../../../graphql/VXServicesTelegram/types';
import { QUERY_MASTER_USER } from '../graphql/queries';
import { useVXServicesTelegramClient } from '../../../graphql';
import { useVXServicesTelegramContext } from './index';

export const useTelegramUser = (
  id: number,
  options?: QueryHookOptions<MessageList & { initial: boolean }, IUserChannel>
) => {
  const { token } = useVXServicesTelegramContext();
  const { data, ...rest } = useQuery<User, { token: string; id: number }>(QUERY_MASTER_USER, {
    ...options,
    client: useVXServicesTelegramClient(),
    variables: { token, id },
  });

  return {
    data: (data && data.master && data.master.user) || {},
    currency: data?.master?.currency,
    ...rest,
  };
};

export default useTelegramUser;
