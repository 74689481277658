import styled from '@emotion/styled';
import { ITableColumn } from '../types';
import { getTableColumnStyles } from '../utils';
import { css } from '@emotion/core';

interface IProps {
  columns: ITableColumn[];
  index: number;
}

const styles = css`
  align-items: center;
`;

const TableHeaderColumn = styled.div(({ columns, index }: IProps) => [
  styles,
  getTableColumnStyles(columns, index, true),
]);

export default TableHeaderColumn;
