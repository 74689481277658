import React, { FC } from 'react';
import { useService0900ShortInfo } from '../hooks';
import { Service0900StatusEnum, TooltipType } from '../../../graphql/VXModels/types';
import TopBarIcon from './TopBarIcon';
import TopBarPopoverContent from './TopBarPopoverContent';
import TopBarPopoverElement from '../../../components/TopBar/components/TopBarPopoverElement';
import { useLocation } from 'react-router-dom';
import Popper from '../../../atoms/Popper/Popper';
import { _ } from '../../../util/translate';

const TopBar: FC = () => {
  const {
    data: { status = Service0900StatusEnum.notSet, isTargetNumberLocked = true, displayPlatform },
  } = useService0900ShortInfo();
  const location = useLocation();

  return status === Service0900StatusEnum.notSet || isTargetNumberLocked ? null : (
    <Popper
      type={TooltipType.BA6_INIT_TOOLTIP_SHOWN}
      isForbiddenRoute={location.pathname.indexOf('service0900/phone-sex') < 0}
      anchorElSelector={'service0900'}
      description={_('tooltips:service0900.topbar.description')}
    >
      <TopBarPopoverElement
        icon={<TopBarIcon status={status} />}
        paperStyle={{ minWidth: 260, maxWidth: 290 }}
      >
        <TopBarPopoverContent status={status} displayPlatform={displayPlatform} />
      </TopBarPopoverElement>
    </Popper>
  );
};

export default TopBar;
