import React, { FC } from 'react';
import { Main } from '../../components/Layout/DarkCentered';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import { stripSlash } from '../../util/urlHelper';
import { getStartpagePasswordResetUrl } from '../../util/startpage';
import { useLang } from '../../util/AppState';
import Verify from './Verify';
import ExternalRedirect from '../../components/Routing/ExternalRedirect';

const PasswordReset: FC<RouteChildrenProps> = ({ match: { url } }) => {
  const path = stripSlash(url);
  const [lang] = useLang();

  return (
    <Main>
      <Switch>
        <Route exact path={`${path}/verify/:hash`} component={Verify} />
        <ExternalRedirect to={getStartpagePasswordResetUrl(lang)} />
      </Switch>
    </Main>
  );
};

export default PasswordReset;
