import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import { GRAY_3 } from '../../camtool-styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 32,
      height: 32,
      marginRight: -14,
      backgroundColor: GRAY_3,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  })
);

const InputAdornmentIcon: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Avatar variant="rounded" className={classes.root}>
      {children}
    </Avatar>
  );
};

export default InputAdornmentIcon;
