import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BLUE } from '../../../camtool-styles';

const styles = css`
  opacity: 0.7;
  overflow: hidden; // Fixes a flickering issue
  align-items: center;
`;

const TableHeaderItem = styled.div(
  ({ sortable, isSortSelected }: { sortable: boolean; isSortSelected: boolean }) => [
    styles,
    sortable
      ? css`
          &:hover {
            color: ${BLUE};
            opacity: 1;
          }
        `
      : {},
    isSortSelected
      ? {
          color: BLUE,
          opacity: 1,
        }
      : {},
    { cursor: sortable ? 'pointer' : 'default' },
  ]
);

export default TableHeaderItem;
