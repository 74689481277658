import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const labelClass = css({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#dcdcde',
  borderRadius: 2,
  paddingTop: 8,
  paddingRight: 32,
  paddingBottom: 8,
  paddingLeft: 32,
  transition: 'background-color 300ms, color 300ms',
  whiteSpace: 'nowrap',
  '& input': {
    display: 'none',
  },
  ':hover': {
    cursor: 'pointer',
    color: '#fff',
  },
});

const labelOnClass = css({ marginRight: 8, ':hover': { backgroundColor: '#66e03b' } });
const labelOffClass = css({ ':hover': { backgroundColor: '#ff9389' } });

const OnOffStatus = ({
  status,
  name,
  labelOn,
  labelOnActive,
  labelOff,
  labelOffActive,
  onChange,
}) => {
  if (status === 'on' || status === 'off') {
    return (
      <div data-testid="on-off-status">
        <label
          css={[
            labelClass,
            labelOnClass,
            status === 'on' ? css({ backgroundColor: '#43b31c', color: '#fff' }) : '',
          ]}
        >
          <input
            type="radio"
            name={name}
            checked={status.toLowerCase() === 'on'}
            onChange={onChange}
            value="on"
          />
          {status === 'on' && labelOnActive ? labelOnActive : labelOn}
        </label>

        <label
          css={[
            labelClass,
            labelOffClass,
            status === 'off' ? css({ backgroundColor: '#ff4d3c', color: '#fff' }) : '',
          ]}
        >
          <input
            type="radio"
            name={name}
            checked={status.toLowerCase() === 'off'}
            onChange={onChange}
            value="off"
          />
          {status === 'off' && labelOffActive ? labelOffActive : labelOff}
        </label>
      </div>
    );
  }

  return null;
};

OnOffStatus.propTypes = {
  status: PropTypes.oneOf(['on', 'off']).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  labelOnActive: PropTypes.string,
  labelOffActive: PropTypes.string,
};

OnOffStatus.defaultProps = {
  labelOn: 'On',
  labelOff: 'Off',
  labelOnActive: null,
  labelOffActive: null,
};

export default OnOffStatus;
