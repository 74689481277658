import React, { FC, useMemo, ReactElement } from 'react';
import { _ } from '../../../../../util/translate';
import { StyledNumberInput, StyledSelect } from '../styles';
import { Field, FieldProps } from 'formik';
import { MobileUserLifeCycleStep } from '../types';
import { Box, Typography } from '@material-ui/core';
import { getDeliveryOptions } from '../utils';

const AutoMailGridFormItemDisplayUnit: FC = () => {
  const deliveryOptions = useMemo(() => getDeliveryOptions(), []);

  return (
    <>
      <Box display="flex" flexDirection="column" p={2}>
        <Typography variant="caption">
          {_('mailings:telegram.autoMail.th.deliveryOptions.free')}
        </Typography>
        <Box display="flex" gridGap="10px">
          <Field name="delayMinutes">
            {({
              field,
              form: { errors, touched },
            }: FieldProps<Pick<MobileUserLifeCycleStep, 'delayMinutes'>>): ReactElement => {
              const errorDelayMinutes = errors.delayMinutes;
              const touchedDelayMinutes = touched.delayMinutes;

              return (
                <StyledNumberInput
                  error={!!touchedDelayMinutes && !!errorDelayMinutes}
                  {...field}
                  type="number"
                  placeholder="0"
                  min="0"
                  max="999"
                />
              );
            }}
          </Field>
          <Field name="delayDisplayUnit">
            {({
              field,
              form: { errors, touched },
            }: FieldProps<Pick<MobileUserLifeCycleStep, 'delayDisplayUnit'>>): ReactElement => {
              const errorDisplayUnit = errors.delayDisplayUnit;
              const touchedDisplayUnit = touched.delayDisplayUnit;

              return (
                <StyledSelect
                  hasError={!!touchedDisplayUnit && !!errorDisplayUnit}
                  {...field}
                  options={deliveryOptions}
                  placeholder={_('common:text.pleaseSelect')}
                />
              );
            }}
          </Field>
        </Box>
      </Box>
    </>
  );
};

export default AutoMailGridFormItemDisplayUnit;
