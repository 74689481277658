import React from 'react';
import { SessionStore } from '../../stores/Session/Session';

import NavSub from '../../components/NavSub/NavSub';
import NavSubItem from '../../components/NavSubItem/NavSubItem';
import { APP_BASE_PATH } from '../../util/env';
import { _ } from '../../util/translate';

class NavSubFinances extends React.Component {
  constructor(props) {
    super(props);
    this.session = SessionStore.get();
  }

  render() {
    return (
      <NavSub>
        <NavSubItem
          link={`${APP_BASE_PATH}/finances_internal/overview`}
          icon="icon-charts"
          label={_('finances:overview.header')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/finances_internal/payout`}
          icon="icon-money"
          label={_('finances:payout.header')}
        />
        <NavSubItem
          link={`${APP_BASE_PATH}/finances_internal/invoice`}
          icon="icon-invoice"
          label={_('finances:billing.header')}
        />
      </NavSub>
    );
  }
}

export default NavSubFinances;
export { NavSubFinances };
