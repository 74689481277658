import {useAppState} from "../../util/AppState";

const HelpcenterRootLink = 'https://helpcenter.vxmodels.com/hc';
const HelpcenterLink = () => {
    const [{lang}] = useAppState();
    let newLang = 'de';
    if (lang === 'en') {
        newLang = 'en-us';
    }
    return HelpcenterRootLink + '/' + newLang;
};

export default HelpcenterLink;
