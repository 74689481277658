import React, { FC } from 'react';

import {
  MailingRecipientsGroupsEnum,
  MailingStatusEnum,
  MailingTypeEnum,
} from '../../../graphql/VXModels/types';
import { FormRouter } from './index';
import { FancyError, Spinner } from '../../../components';
import { useMailingDefaults } from '../hooks';
import { BuildUrl } from '../types';

interface NewMessageProps {
  buildUrl: BuildUrl;
  type: MailingTypeEnum;
  group: MailingRecipientsGroupsEnum;
}

const NewMessage: FC<NewMessageProps> = ({ type, group, buildUrl }) => {
  const { data, loading, error } = useMailingDefaults(group);
  return error ? (
    <FancyError error={error} />
  ) : loading ? (
    <Spinner />
  ) : (
    <FormRouter
      type={type}
      buildUrl={buildUrl}
      status={MailingStatusEnum.DRAFT}
      recipientsFilters={data?.model?.mailings?.recipientsFilters}
      recipientsGroups={{ options: [], selected: group }}
    />
  );
};

export default NewMessage;
