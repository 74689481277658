import { useVXModelsRestApi } from './useVXModelsRestApi';

export const DEFAULT_OFFSET = 1; // @todo crazy behaviour: limit starts with 1, we need to fix that in backend
export const DEFAULT_LIMIT = 20;

// https://api.vxmodels.com/v1/camtool/user/4692886/media/videoalbum?type=all&sort=uploaded&sortDirection=desc&limit=20&offset=1
export const useVideos = ({
  type = 'all',
  sort = 'uploaded',
  sortDirection = 'desc',
  limit = DEFAULT_LIMIT,
  offset = DEFAULT_OFFSET,
}) => {
  const url = '/media/videoalbum';
  const { doGet, ...state } = useVXModelsRestApi({
    url,
    queryParams: { type, sort, sortDirection, limit, offset },
    reducer: (data, action) => {
      if (action.queryParams.offset > 1) {
        return { ...data, entries: data.entries.concat(action.payload.data.entries) };
      }
      return action.payload.data;
    },
  });

  return { doGet, ...state };
};

// https://api.vxmodels.com/v1/camtool/user/4692886/media/videoalbum/1505947
export const useVideo = ({ umaId }) => {
  const buildUrl = umaId => `/media/videoalbum/${umaId}`;
  const url = buildUrl(umaId);

  const { doGet, doDelete, doPut, ...state } = useVXModelsRestApi({
    url,
    reducer: (data, action) => action.payload.data,
  });

  const getUrl = umaId => (umaId ? buildUrl(umaId) : url);

  const reloadVideo = ({ umaId }) => doGet({ url: getUrl(umaId) });
  const updateVideo = ({ umaId, data }) => doPut({ url: getUrl(umaId), requestData: data });
  const deleteVideo = ({ umaId }) =>
    doDelete({
      url: getUrl(umaId),
      reducer: (data, action) => {
        console.log('deleteVideo data, action', data, action);
        return {};
      },
    });

  return { reloadVideo, updateVideo, deleteVideo, ...state };
};

// https://api.vxmodels.com/v1/camtool/user/1334158/media/videoalbum/1336715/sales
export const useVideoSales = ({ umaId }) => {
  const url = `/media/videoalbum/${umaId}/sales`;
  const { ...state } = useVXModelsRestApi({
    url,
    reducer: (data, action) => action.payload.data,
  });

  return { ...state };
};
