import React from 'react';
import { ContentState, ContentBlock } from 'draft-js';

const Audio = ({ src }: { src: string }) => <div />;

const Image = ({ src }: { src: string }) => {
  return <img src={src} alt="" css={{ maxWidth: 470 }} />;
};

const Video = ({ src }: { src: string }) => <div />;

interface IMediaComponent {
  contentState: ContentState;
  block: ContentBlock;
}

const MediaComponent = (props: IMediaComponent) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src } = entity.getData();
  const type = entity.getType();

  if (type === 'audio') {
    return <Audio src={src} />;
  } else if (type === 'image') {
    return <Image src={src} />;
  } else if (type === 'video') {
    return <Video src={src} />;
  }
};

export default MediaComponent;
