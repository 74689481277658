import { getVXServiceTelegramClient } from '../../../graphql';
import { QUERY_ALLOWED_MIME_TYPES } from '../../../packages/Telegram/graphql/queries/uploaderMimeTypes';

export async function getAllowedMimeTypesForUploader(): Promise<string[]> {
  const client = getVXServiceTelegramClient();

  const { data: mimeTypesData } = await client.query({
    query: QUERY_ALLOWED_MIME_TYPES,
  });

  const {
    config: { allowedMimeTypes },
  } = mimeTypesData;

  return allowedMimeTypes;
}
