import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { getFormattedDate } from '../../../../util/Formatter';
import { IAbo } from '../../types';
import { useLang } from '../../../../util/AppState';

interface IProps {
  abo: IAbo;
}

const Header: FC<IProps> = ({ abo }) => {
  const [lang] = useLang();

  return (
    <div css={{ flexDirection: 'column', textAlign: 'center' }}>
      <h2 css={{ fontSize: 24 }}>
        {abo.benefits[0] ? abo.benefits[0].type.translation[lang] : _('benefits:texts.custom')}{' '}
        {_('common:text.subscription')} ({_('common:text.start')}{' '}
        {getFormattedDate(abo.createdAt, lang)})
      </h2>
      <p css={{ fontSize: 16, marginTop: 8 }}>
        {`${_('benefits:abo.archiveColumn.duration')}: ${abo.benefitInterval} ${_(
          'common:text.days'
        )}`}
        {abo.renew
          ? `, ${_('benefits:abo.text.autoRenew')}`
          : `, ${_('benefits:abo.text.autoRenewNo')}`}
      </p>
    </div>
  );
};

export default Header;
