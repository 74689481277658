import React from 'react';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import { APP_IS_DEVELOPMENT } from '../util/env';

/**
 * The base component that autobinds all the methods to this.
 */
class AbstractComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach(method => {
      if (typeof this[method] !== 'function') {
        return;
      }
      this[method] = this[method].bind(this);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdateProps = !isEqual(this.props, nextProps);
    const shouldUpdateState = !isEqual(this.state, nextState);
    const debug = this.__proto__.constructor.debug && APP_IS_DEVELOPMENT;
    if (debug && shouldUpdateProps) {
      const details = {};
      const diff = reduce(
        this.props,
        function(result, value, key) {
          return isEqual(value, nextProps[key]) ? result : result.concat(key);
        },
        []
      );
      for (let entry of diff) {
        details[entry] = {
          current: this.props[entry],
          next: nextProps[entry],
        };
      }
      console.log(this.__proto__.constructor.name, 'shouldComponentUpdate', 'props', diff, details);
    }

    if (debug && shouldUpdateState) {
      const details = {};
      const diff = reduce(
        this.state,
        function(result, value, key) {
          return isEqual(value, nextState[key]) ? result : result.concat(key);
        },
        []
      );
      for (let entry of diff) {
        details[entry] = {
          current: this.state[entry],
          next: nextState[entry],
        };
      }
      console.log(this.__proto__.constructor.name, 'shouldComponentUpdate', 'state', diff, details);
    }

    return shouldUpdateProps || shouldUpdateState;
  }
}

export default AbstractComponent;
