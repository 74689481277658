import { APP_IS_DEVELOPMENT, APP_API_HOST, APP_SCOPE, REACT_APP_VERSION, APP_IS_PRODUCTION } from './env'
import { clearAuthTokenCookie, clearBackurlMobileCookie, getSessionIdCookie } from './cookies';

const apiEndpoint = `${APP_API_HOST}/v1/public/log`;
const inDebounce = {};
const countDebounce = {};

const keyDebounce = (key, func, delay, notify = 100, threshold = 5, shutdownThreshold = 200) => {
  const context = this;

  // eslint-disable-next-line no-undef
  const args = arguments;

  countDebounce[key] = countDebounce[key] ? countDebounce[key] + 1 : 1;

  if (inDebounce[key]) {
    if (countDebounce[key] === threshold) {
      const message = `Possible Loop: ${key}`;
      log('warning', message, { context: 'ErrorHandling.debounce' });
      console.log(message);
    }
    if (countDebounce[key] > threshold && countDebounce[key] % notify === 0) {
      const message = `Message occured ${countDebounce[key]} times: ${key}`;
      log('warning', message, { context: 'ErrorHandling.debounce' });
      console.log(message);
      func.apply(context, args);
    }
    if (countDebounce[key] === shutdownThreshold) {
      const message = `Loop shutdown, clear cookies: ${key}`;
      log('warning', message, { context: 'ErrorHandling.debounce' });
      console.log(message);
      clearAuthTokenCookie();
      clearBackurlMobileCookie();
    }
  }

  clearTimeout(inDebounce[key]);

  if (countDebounce[key] < threshold) {
    func.apply(context, args);
    inDebounce[key] = setTimeout(() => {
      countDebounce[key] = 0;
    }, delay);
  } else {
    inDebounce[key] = setTimeout(() => {
      countDebounce[key] = 0;
      func.apply(context, args);
    }, delay);
  }
};

export function log(level, message, context) {
  const ctx = context || {};
  const userDataStr = window.sessionStorage.getItem('userdata');
  const userData = JSON.parse(userDataStr) || {};
  const userId = userData.user?.userId || userData.userId || null;
  const sessionId = userData.sessionId || getSessionIdCookie();
  const lang = userData.gui?.lang || null;

  if (!ctx.stacktrace && ctx.err instanceof Error) {
    ctx.stacktrace = getStackTrace({ offset: 2, ctx: ctx.err });
    delete ctx.stacktrace;
  }

  try {
    const logObject = {
      level: level.toLowerCase(),
      message,
      context: {
        ...ctx,
        userId,
        sessionId,
        data: {
          ...(ctx.data || {}),
          uri: window.location.pathname,
          scope: APP_SCOPE,
          userId,
          sessionId,
          lang,
          userAgent: navigator.userAgent,
          version: REACT_APP_VERSION,
        },
      },
    };

    switch (level) {
      case 'debug':
        if (!APP_IS_PRODUCTION) {
          const { message, context } = logObject;
          console.log(`[${level}] ${message}`, context);
        }
        break;
      case 'info':
      default:
        fetch(apiEndpoint, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(logObject),
        })
          .then((response) => response.json())
          .then((json) => APP_IS_DEVELOPMENT && console.log('Remote logged', json));
    }
  } catch (err) {
    // prevent loop
    APP_IS_DEVELOPMENT && console.log('Could not remote log:', level, message, context, err);
  }
}

export function getStackTrace({ offset = 0, error } = {}) {
  let stack;
  if (error) {
    stack = error.stack;
  } else {
    stack = new Error().stack || '';
  }
  return stack
    .split('\n')
    .map((line) => line.trim())
    .splice(stack[0] === 'Error' ? 2 + offset : 1 + offset)
    .join('\n');
}

// this method will proxy your custom method with the original one
function consoleProxy(context, method, level) {
  return function () {
    try {
      const firstArgument = arguments[0];
      const message =
        firstArgument instanceof Error
          ? firstArgument.toString()
          : firstArgument === undefined
          ? ''
          : firstArgument;

      const data = {
        arguments: JSON.stringify(
          Array.from(arguments)
            .slice(1)
            .map((argument) => (argument instanceof Error ? argument.toString() : argument))
        ),
      };

      let lvl;
      if (message && message.toString().indexOf('Error') === 0) {
        lvl = 'error';
      } else if (message && message.toString().indexOf('Warning') === 0) {
        lvl = 'warning';
      } else {
        lvl = level;
      }

      keyDebounce(
        message,
        () => {
          log(lvl, message, {
            context: 'ErrorHandling.console.' + method.name,
            stacktrace: getStackTrace({ offset: 2 }),
            data,
          });
          method.apply(context, arguments);
        },
        1000
      );
    } catch (err) {
      log('error', err.toString(), { context: 'ErrorHandling.consoleProxy.catchError' });
    }
  };
}

export function init() {
  if (!APP_IS_DEVELOPMENT) {
    // do not spam dev log !!!
    window.onerror = (message, source, lineno, colno, error) => {
      console.log('window.onerror', message, source, lineno, colno, error);
      log('critical', error.toString(), {
        context: 'ErrorHandling.window.onerror',
        data: { source, lineno, colno },
        stacktrace: getStackTrace({ error }),
      });
    };
    console.error = consoleProxy(console, console.error, 'error');
    console.warn = consoleProxy(console, console.warn, 'warning');
    console.info = consoleProxy(console, console.info, 'notice');
  }
}
