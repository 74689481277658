import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from '../../util/Formatter';
import { useLang } from '../../util/AppState';

const FormatDate = ({ className, value }) => {
  const [lang] = useLang();

  return <span className={className}>{getFormattedDate(value, lang)}</span>;
};

FormatDate.defaultProps = {
  className: '',
  value: '',
};

FormatDate.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
};

export default FormatDate;
