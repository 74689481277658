import React from 'react';
import AbstractComponent from '../../AbstractComponent';
import ServerUtils from '../../../util/ServerUtils';
import { _ } from '../../../util/translate';
import styled from '@emotion/styled';
import { BREAKPOINT_DESKTOP_CONDITION, BREAKPOINT_TABLET_CONDITION } from '../../../camtool-styles';

const Container = styled.div`
  @media ${BREAKPOINT_TABLET_CONDITION} {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

class UploadFTP extends AbstractComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        serverAddress: null,
        serverPort: null,
        userName: null,
        password: null,
      },
    };
  }

  componentWillMount() {
    this.getFtpCredentials();
  }

  getFtpCredentials() {
    ServerUtils.request(
      'GET',
      '/v1/camtool/user/{userId}/media/ftp/credentials',
      null,
      (response) => {
        this.setState({
          data: {
            serverAddress: response.ftp_server,
            userName: response.ftp_username,
            password: response.ftp_password,
            serverPort: response.ftp_port,
          },
        });
      }
    );
  }

  render() {
    const data = this.state.data;
    return (
      <div className="grid__box__row mediamultiuploader__ftp">
        <div className="grid__box__column grid__box__sub">
          <div className="grid__box__sub__header">
            <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
              {_('mediamanagement:multiuploader.uploadftp.title')}
            </div>
          </div>

          <Container className="grid__box__sub__item mediamultiuploader__ftp__content">
            <Container className="grid__box__sub__item__content mediamultiuploader__ftp__content__lable">
              <div className="mediamultiuploader__ftp__content__lable__value">
                {_('mediamanagement:multiuploader.uploadftp.serverAddress')}:
              </div>
              <div className="mediamultiuploader__ftp__content__lable__value">
                {_('mediamanagement:multiuploader.uploadftp.ftpPort')}:
              </div>
            </Container>

            <Container className="grid__box__sub__item__content mediamultiuploader__ftp__content__item">
              <div className="mediamultiuploader__ftp__content__item__value">
                {data.serverAddress}
              </div>
              <div className="mediamultiuploader__ftp__content__item__value">{data.serverPort}</div>
            </Container>

            <Container className="grid__box__sub__item__content mediamultiuploader__ftp__content__lable">
              <div className="mediamultiuploader__ftp__content__lable__value">
                {_('mediamanagement:multiuploader.uploadftp.username')}:
              </div>
              <div className="mediamultiuploader__ftp__content__lable__value">
                {_('mediamanagement:multiuploader.uploadftp.password')}:
              </div>
            </Container>

            <Container className="grid__box__sub__item__content mediamultiuploader__ftp__content__item">
              <div className="mediamultiuploader__ftp__content__item__value">{data.userName}</div>
              <div className="mediamultiuploader__ftp__content__item__value">{data.password}</div>
            </Container>
          </Container>
        </div>
      </div>
    );
  }
}

export default UploadFTP;
export { UploadFTP };
