import { QueryHookOptions, useMutation } from '@apollo/react-hooks';
import { Mutation } from '../../../graphql/VXServicesTelegram/types';
import { MUTATION_MASTER_SEND } from '../graphql/mutations';
import { QUERY_MASTER_USER_GROUPED_CHAT } from '../graphql/queries';
import { useVXServicesTelegramClient } from '../../../graphql';
import { useVXServicesTelegramContext } from './index';

interface ISendMessage {
  userId: number;
}

export const useTelegramSendMessage = (
  { userId }: { userId: number },
  options?: QueryHookOptions<Mutation, ISendMessage>
) => {
  const { token } = useVXServicesTelegramContext();
  const variables = { token, userId };
  return useMutation(MUTATION_MASTER_SEND, {
    ...options,
    client: useVXServicesTelegramClient(),
    variables,
    // todo: update cache for user message.file.url to instantly load new videos
    update: (cache, mutationResult) => {
      const {
        data: {
          master: { send },
        },
      } = mutationResult;
      const data = cache.readQuery({ query: QUERY_MASTER_USER_GROUPED_CHAT, variables });
      // todo?: check if groupedChat[0].events[0] is actually correct for message that has been typed at 23:59 and sent at 0:00 of next day
      data.master.user.groupedChat?.[0]?.events.unshift(send);
    },
  });
};

export default useTelegramSendMessage;
