import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.bgColor};
  z-index: 999;
  justify-content: center;
`;

class Modal extends Component {
  static propTypes = { onBackgroundClick: PropTypes.func };
  static defaultProps = { onBackgroundClick: null };

  modalRoot = document.createElement('div');

  componentDidMount() {
    this.modalRoot.setAttribute('id', 'modal-root'); // not really necessary.
    document.body.appendChild(this.modalRoot);
  }

  componentWillUnmount() {
    document.body.removeChild(this.modalRoot);
  }

  handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget && this.props.onBackgroundClick) {
      this.props.onBackgroundClick();
    }
  };

  render() {
    return createPortal(
      <ModalBackground
        className={this.props.className}
        bgColor={this.props.backgroundColor}
        onClick={this.handleBackgroundClick}
      >
        {this.props.children}
      </ModalBackground>,
      this.modalRoot
    );
  }
}

Modal.propTypes = {
  onBackgroundClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

Modal.defaultProps = {
  onBackgroundClick: () => {},
  backgroundColor: 'rgba(0, 0, 0, 0.65)',
};

export default Modal;
