import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { Box, Typography } from '@material-ui/core';
import { useTelegramBotStyles } from './styles';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ReplayIcon from '@material-ui/icons/Replay';

interface CodeBoxProps {
  onClick: () => void;
  code: number | undefined;
}

const CodeBox: FC<CodeBoxProps> = ({ onClick, code }) => {
  const classes = useTelegramBotStyles();

  return (
    <Box>
      <Box className={classes.codeBoxOuter}>
        <Box className={classes.codeBoxInner}>{code ? code : '------'}</Box>
      </Box>
      <Box
        className={classes.showCode}
        onClick={() => {
          onClick();
        }}
      >
        {code ? <ReplayIcon /> : <VisibilityOffIcon />}
        <Typography className={classes.showCodeText}>
          {code ? _('mailings:telegram.vxmbot.newcode') : _('mailings:telegram.vxmbot.showcode')}
        </Typography>
      </Box>
    </Box>
  );
};

export default CodeBox;
