import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import {
  VXGAMES_TYPOGRAPHY_H2,
  VXGAMES_TYPOGRAPHY_SUBHEADING,
  VXGAMES_PRIMARY,
  VXGAMES_TYPOGRAPHY_PARAGRAPH,
} from '../../../../utils';
import { CompetitionPromptProps } from '.';

const CompetitionPrompt: FC<CompetitionPromptProps> = ({ conditionText, leftOverText }) => {
  return (
    <>
      <Typography style={VXGAMES_TYPOGRAPHY_SUBHEADING} gutterBottom>
        {'Status der Herausforderung'}
      </Typography>
      <Typography style={{ ...VXGAMES_TYPOGRAPHY_H2, ...{ color: VXGAMES_PRIMARY } }} gutterBottom>
        {`${leftOverText} bis Abschluss der Herausforderung`}
      </Typography>
      <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH}>{conditionText}</Typography>
    </>
  );
};

export { CompetitionPrompt };
