import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import {BLACK_1} from "../../../camtool-styles";

const MARGIN = 8;

export const Container = styled.div`
  margin: ${MARGIN}px;
  min-width: calc(500px - ${MARGIN * 2}px);
  background-color: #f5f5f5;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: stretch;
  min-height: 328px;
`;

export const Content = styled.div`
  color: ${BLACK_1};
  flex: 1 1 auto;
  justify-content: center;
`;

export const Header = styled.div`
  color: #ffffff;
  background-color: #3c3d3e;
  height: 48px;
  font-size: 16px;
  text-transform: uppercase;
  padding: 0 16px;
  align-items: center;
`;

interface IProps { title: string | (() => any); loading?: boolean; children: any; className?: string; } // prettier-ignore

const Layout = React.memo((props: IProps) => {
  const renderHeader = () => {
    switch (typeof props.title) {
      case 'function':
        return <Header>{props.title()}</Header>;
      case 'string':
        return <Header>{props.title}</Header>;
      default:
        return null;
    }
  };

  return (
    <Container className={props.className}>
      {props.loading ? null : (
        <Fragment>
          {renderHeader()}
          <Content>{props.children}</Content>
        </Fragment>
      )}
    </Container>
  );
});

export default Layout;
