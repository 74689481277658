import React, { FC } from 'react';
import GenericModalWindowLayout from '../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import styled from '@emotion/styled';
import Button from '../../../../atoms/Button/Button';
import { _ } from '../../../../util/translate';
import { useTelegramBlockUser } from '../../hooks';
import EmptyContent from '../../../../components/EmptyContent/EmptyContent';
import { DARK_GRAY } from '../../../../camtool-styles';

const ListItem = styled.li`
  margin-bottom: 10px;
  font-size: 16px;
`;

const ModalContainer = styled.div`
  align-items: center;
  flex-direction: column;
  padding: 80px 80px;
  color: ${DARK_GRAY};
`;

interface IProps {
  onClose: () => void;
  userId: number;
}

const ModalBlockUser: FC<IProps> = ({ onClose, userId }) => {
  const [blockUser, { loading, error }] = useTelegramBlockUser({
    userId,
  });

  const onBlock = async (): Promise<void> => {
    const { errors } = await blockUser();
    if (!(Array.isArray(errors) && errors.length > 0)) {
      onClose();
    }
  };
  return (
    <GenericModalWindowLayout name={'block-user'} maxWidth={740} onClose={onClose}>
      <ModalContainer>
        <h2 css={{ marginBottom: '16px', fontSize: '20px' }}>
          {_('telegram:chat.modal.blockUser')}
        </h2>
        <ul>
          <ListItem>{_('telegram:chat.modal.firstArgument')}</ListItem>
          <ListItem>{_('telegram:chat.modal.secondArgument')}</ListItem>
          <ListItem>{_('telegram:chat.modal.thirdArgument')}</ListItem>
        </ul>
        {error && (
          <EmptyContent
            css={{ flex: '0 0 auto' }}
            icon="icon-warning-sign"
            title={_('common:error.generic')}
          />
        )}
        <div css={{ marginTop: '30px' }}>
          <Button
            css={{ marginRight: '20px', alignSelf: 'center' }}
            theme="border-gray"
            onClick={onClose}
          >
            {_('common:button.cancel')}
          </Button>
          <Button loading={loading} disabled={loading} onClick={() => onBlock()}>
            {_('telegram:chat.modal.button.block')}
          </Button>
        </div>
      </ModalContainer>
    </GenericModalWindowLayout>
  );
};

export default ModalBlockUser;
