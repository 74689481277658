import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import { ClassNames } from '@emotion/core';

import AbstractMultiLangComponent from '../AbstractMultiLangComponent';
import MaxValueLength from '../../Display/MaxValueLength/MaxValueLength';

class MultiLangTextareafield extends AbstractMultiLangComponent {
  render() {
    const { maxLength, minLength, name, rows } = this.props;
    const { selectedLang, validLangs } = this.state;
    const currentLangValue = this.getLangValue(selectedLang) || '';
    const placeholder = `${this.props.placeholder} [${
      selectedLang ? selectedLang.toUpperCase() : selectedLang
    }]`;

    return (
      <div
        className={cc([
          'multilangtextareafield',
          this.getClassNames(),
          { 'maxvaluelength-anchorframe': maxLength > 0 },
        ])}
      >
        <div className="multilangtextareafield__languages">
          {Object.keys(validLangs).map(lang => (
            <div
              key={lang}
              onClick={this.changeSelectedLang.bind(this, lang)}
              className={cc({
                multilangtextareafield__language__entry: true,
                'multilangtextareafield__language__entry--active': lang === selectedLang,
              })}
            >
              <div
                className={`flag-${lang} multilangtextareafield__language__entry__icon`}
                title={validLangs[lang]}
              />
              <div className="multilangtextareafield__language__entry__name">
                {validLangs[lang]}
              </div>
            </div>
          ))}
        </div>

        <div className="multilangtextareafield__column">
          <textarea
            name={name}
            placeholder={placeholder}
            onChange={this.onChange}
            value={currentLangValue}
            maxLength={maxLength}
            rows={rows}
          />
        </div>

        {maxLength > 0 && (
          <MaxValueLength min={minLength} max={maxLength} value={currentLangValue.length} />
        )}
      </div>
    );
  }
}

MultiLangTextareafield.propTypes = Object.assign(AbstractMultiLangComponent.propTypes, {
  rows: PropTypes.number,
});

MultiLangTextareafield.defaultProps = Object.assign(AbstractMultiLangComponent.defaultProps, {
  rows: 8,
});

export default MultiLangTextareafield;
export { MultiLangTextareafield };
