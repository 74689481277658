import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CompetitionOnboarding, CompetitionOverviewGrid } from '../..';
import GenericEmptyContent from '../../../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import { Main } from '../../../../../../atoms/Grid';
import { Spinner } from '../../../../../../components';
import { Query, VXGChallengeschallengesArgs } from '../../../../../../graphql/VXModels/types';
import { QUERY_MODEL_VXCHALLENGE_CHALLENGES } from '../../../../graphql';
import { Box } from '@material-ui/core';
import { bannerUniversal } from '../../CompetitionOnboarding/assets';
import { VXGAMES_CURRENT_DATE } from '../../../../utils';

const VXGamesOverview: FC = () => {
  const currentYear = VXGAMES_CURRENT_DATE.getFullYear();

  const { loading, error, data } = useQuery<Query, VXGChallengeschallengesArgs>(
    QUERY_MODEL_VXCHALLENGE_CHALLENGES
  );

  if (loading) return <Spinner />;
  if (error) return <GenericEmptyContent />;

  const challenges = data?.model?.vxChallenges?.challenges.filter(
    (challenge) => new Date(challenge.startDate).getFullYear() == currentYear
  );

  const isTOCAccepted = data?.model?.vxChallenges?.challenges?.[0].userStatistic?.isTocAccepted;

  return (
    <Main isMobileEnabled>
      {isTOCAccepted ? (
        <Box mb={2}>
          <img css={{ width: '100%' }} src={bannerUniversal} />
        </Box>
      ) : (
        <Box mb={2}>
          <CompetitionOnboarding />
        </Box>
      )}
      <CompetitionOverviewGrid challenges={challenges} />
    </Main>
  );
};

export default VXGamesOverview;
