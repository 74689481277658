import React from 'react';
import PropTypes from 'prop-types';

const Separator = ({ className, title }) => (
  <article className={`separator ${className}`}>
    <section>
      {title && <header>{title}</header>}
      <footer />
    </section>
  </article>
);

Separator.propTypes = { className: PropTypes.string, title: PropTypes.string };

Separator.defaultProps = { className: '', title: '' };

export default Separator;
export { Separator };
