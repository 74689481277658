import { Resolvers } from 'apollo-client/core/types';
// import { calcFileMetaMd5 } from '../../../util/FileUtils';
// import { StateEnum } from '../types';
// import { FRAGMENT_UPLOADMANAGER_QUEUE_ITEM } from './fragments';
// import { App } from '../../../app';
// import ApolloClient from 'apollo-client';
// import { getMediaTypeByFile } from '../util';

export const resolvers: Resolvers = {
  // // Query
  // Query: {
  //   app: (rootValue) => ({ ...rootValue, __typename: 'App' }),
  // },
  //
  // App: {
  //   uploadManager: (rootValue) => ({ ...rootValue, __typename: 'UploadManager' }),
  // },
  //
  // UploadManager: {
  //   queue: (rootValue, args) => ({ ...rootValue, ...args, __typename: 'UploadManagerQueue' }),
  // },
  //
  // UploadManagerQueue: {
  //   count: ({ filter }, args, context, info) => {
  //     console.log(
  //       '-------- resolver app > uploadManager > queue > count',
  //       filter,
  //       args,
  //       context,
  //       info
  //     );
  //     return 0;
  //   },
  //   items: ({ filter }, args, context, info) => {
  //     console.log(
  //       '-------- resolver app > uploadManager > queue > items',
  //       filter,
  //       args,
  //       context,
  //       info
  //     );
  //     return [];
  //   },
  // },
  //
  // // Mutations
  // Mutation: {
  //   app: (rootValue) => ({ ...rootValue, __typename: 'AppMutation' }),
  // },
  //
  // AppMutation: {
  //   uploadManager: (rootValue) => ({
  //     ...rootValue,
  //     __typename: 'UploadManagerMutation',
  //   }),
  // },
  //
  // UploadManagerMutation: {
  //   addFiles: (
  //     rootValue,
  //     { files = [] }: { files: File[] },
  //     { client }: { client: ApolloClient<{}> }
  //   ) => {
  //     // FRAGMENT_UPLOADMANAGER_QUEUE_ITEM
  //
  //     files.forEach((file) => {
  //       const id = calcFileMetaMd5(file);
  //       const cached = client.readFragment({
  //         id,
  //         fragment: FRAGMENT_UPLOADMANAGER_QUEUE_ITEM,
  //       });
  //
  //       const type = getMediaTypeByFile(file);
  //       client.writeFragment({
  //         id,
  //         fragment: FRAGMENT_UPLOADMANAGER_QUEUE_ITEM,
  //         data: {
  //           __typename: 'UploadManagerQueueItem',
  //           id,
  //           type,
  //           state: StateEnum.waiting,
  //           displayName: file.name,
  //         },
  //       });
  //     });
  // const newEntries: QueueItem[] = payload.files
  //   .filter((file) => !hashs.includes(calcFileMetaMd5(file)))
  //   .map((file) => {
  //     const md5hash = calcFileMd5(file);
  //     return {
  //       hash: calcFileMetaMd5(file),
  //       state: StateEnum.waiting,
  //       displayName: file.name,
  //       file,
  //       meta: {
  //         mediaType: getMediaTypeByFile(file),
  //         md5hash: md5hash,
  //         preview: URL.createObjectURL(file),
  //         verified: false,
  //       },
  //     };
  //   });
  // hash: calcFileMetaMd5(file),
  //   state: StateEnum.waiting,
  //   displayName: file.name,
  //   file,
  //   meta: {
  //   mediaType: getMediaTypeByFile(file),
  //     md5hash: md5hash,
  //     preview: URL.createObjectURL(file),
  //     verified: false,
  // },
  //   },
  // },
  // Mailings: {
  //   draft: (mailing, _, { cache }) => {
  //     const data = cache.readQuery({ query: QUERY_MODEL_MAILINGS_DRAFT });
  //     console.log('pegaito a la pared', data);
  //
  //     return {
  //       targetGroup: ['TEST'],
  //       subject: 'none',
  //       textHTML: '<h1>klk papa</h1>',
  //       releaseDate: new Date(Date.UTC(2019, 16, 1)).toISOString(),
  //     };
  //   },
  // },
};
