import React, { FC } from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { VXGAMES_TYPOGRAPHY_ORANGE_H2, VXGAMES_TYPOGRAPHY_PARAGRAPH } from '../../../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'center',
      paddingTop: 27,
      height: 100,
      width: '100%',
      justifyContent: 'center',
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: -10,
      paddingLeft: 30,
    },
  })
);

interface TotalProps {
  number: number | undefined;
  text: string;
  icon: string;
}

const Total: FC<TotalProps> = ({ number, text, icon }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img css={{ height: 60 }} src={icon} alt="" />
      <Box className={classes.box}>
        <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH}>{text}</Typography>
        <Typography style={VXGAMES_TYPOGRAPHY_ORANGE_H2}>
          {number} {number !== 1 ? 'Models' : 'Model'}
        </Typography>
      </Box>
    </Box>
  );
};

export { Total };
