import React, { FC } from 'react';
import { GRAY_1 } from '../../../camtool-styles';
import { GroupedLikes, Maybe, MediaFeedback } from '../../../graphql/VXModels/types';
import LikesDetails from './LikesDetails';
import LikesDisplay from './LikesDisplay';

interface Props {
  likes: number;
  feedbackComments: Maybe<MediaFeedback>[];
  groupedLikes: GroupedLikes[];
  type: 'photo' | 'video';
}

const LikesOverview: FC<Props> = ({ likes, feedbackComments, groupedLikes, type }) => {
  return (
    <div css={{ flexDirection: 'column', borderBottom: `1px solid ${GRAY_1}` }}>
      <LikesDisplay likes={likes} />
      <LikesDetails
        feedbackComments={feedbackComments}
        likes={likes}
        groupedLikes={groupedLikes}
        type={type}
      />
    </div>
  );
};

export default LikesOverview;
