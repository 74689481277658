import AppDispatcher from '../../../util/dispatcher/AppDispatcher';
import ServerUtils from '../../../util/ServerUtils';
import ActionTypes from '../../../util/constants/ActionTypes';
import { getUserData } from '../../../util/UserData';

class CampointBillingsActionCreatorsClass {
  getCampointBillings(year, lang) {
    ServerUtils.request(
      'GET',
      `/v1/camtool/user/{userId}/lists/campointbillings/getdata?year=${year}&lang=${lang}`,
      null,
      function(response) {
        CampointBillingsActionCreators.receiveData(response);
      }
    );
  }

  getCampointBillingPDF(lang, billingId) {
    ServerUtils.request(
      'GET',
      `/v1/camtool/user/{userId}/finances/statement/${lang}/${billingId}`,
      { options: { blob: true } },
      function(response) {
        ServerUtils.download(
          response,
          'CP' + getUserData().auth.id + '_' + billingId + '.pdf',
          'application/pdf'
        );
      }
    );
  }

  getCampointInvoicePDF(lang, invoiceId) {
    ServerUtils.request(
      'GET',
      `/v1/camtool/user/{userId}/finances/invoice/${lang}/${invoiceId}`,
      { options: { blob: true } },
      function(response) {
        ServerUtils.download(
          response,
          'CPI' + getUserData().auth.id + '_' + invoiceId + '.pdf',
          'application/pdf'
        );
      }
    );
  }

  receiveData(data) {
    AppDispatcher.dispatch({
      type: ActionTypes.LISTS_CAMPOINTBILLINGS_RECEIVE_DATA,
      data: data,
    });
  }
}

const CampointBillingsActionCreators = new CampointBillingsActionCreatorsClass();

export { CampointBillingsActionCreators };
