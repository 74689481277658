export const defaults = {
  app: {
    __typename: 'App',
    uploadManager: {
      __typename: 'UploadManager',
      queue: {
        __typename: 'UploadManagerQueue',
        items: [],
      },
      fileMeta: {
        __typename: 'UploadManagerFileMeta',
      },
    },
  },
};
