import React, { FC, useEffect, useRef, useState } from 'react';
import Translator from '../../util/Translator';
import { ApiLang } from '../../graphql/VXModels/types';

interface IProps {
  lang: ApiLang;
}

const TranslationConfig: FC<IProps> = ({ lang, children }) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const previousLang = useRef<ApiLang>(lang);

  // Init translator
  useEffect(() => {
    Translator.init(lang).then(
      () => setIsInitialized(true),
      (err: any) => console.log('Initializing the Translator failed', err)
    );
  }, []);

  // Update language
  useEffect(() => {
    if (lang !== previousLang.current) {
      previousLang.current = lang;
      Translator.changeLanguage(lang);
    }
  }, [lang]);

  return isInitialized ? children : null;
};

export default TranslationConfig;
