import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { IAboInstance } from '../../../types';
import { Table } from '../../../../../atoms/Table';
import { getColumns } from './columns';
import InstanceTableRow from './InstanceTableRow';

interface Props {
  instances: IAboInstance[];
}

const Container = styled.div`
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  max-height: 100%;
`;

const HorizontalScroll = styled.div`
  display: block !important;
  overflow-x: auto;
  flex: 0 1 auto;
  max-height: 100%;
`;

const StyledTable = styled(Table)`
  min-width: 415px;
  flex: 1 0 0;
  max-height: 100%;
`;

const InstancesTable: FC<Props> = ({ instances }) => {
  const columns = useMemo(() => getColumns(), []);

  return (
    <Container>
      <HorizontalScroll>
        <StyledTable columns={columns}>
          {instances.map((instance) => (
            <InstanceTableRow instance={instance} columns={columns} key={instance.id} />
          ))}
        </StyledTable>
      </HorizontalScroll>
    </Container>
  );
};

export default InstancesTable;
