import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { Query } from '../../../graphql/VXServicesTelegram/types';
import { useVXServicesTelegramContext } from './index';
import { useVXServicesTelegramClient } from '../../../graphql/VXServicesTelegram';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_MASTER_FILE_PREVIEW_PICTURE_URL } from '../graphql/queries';
import { QueryResult } from '@apollo/react-common';

interface Variables {
  uuid?: string;
}

type Result = Omit<QueryResult<Query, Variables>, 'data'> & {
  previewPictureUrl: string | undefined | null;
};

export const useFilePreviewPictureUrl = (
  uuid?: string,
  options?: QueryHookOptions<Query, Variables>
): Result => {
  const { token } = useVXServicesTelegramContext();
  const client = useVXServicesTelegramClient();
  const { data, ...result } = useQuery<Query, Variables & { token: string }>(
    QUERY_MASTER_FILE_PREVIEW_PICTURE_URL,
    {
      client,
      ...options,
      variables: { token: token!, uuid },
      skip: !token || options?.skip,
    }
  );

  return { previewPictureUrl: data?.master?.file?.previewPictureUrl, ...result };
};
