import React, { FC } from 'react';
// Outside Wizard
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import Select from '../../../../../atoms/Select/Select';
// Inside Wizard
import { IContentOthersProps } from '../../../types';
import { pluralize } from '../../../utils';
import useContent from '../useContent';
import Layout from '../Layout';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';
import { _ } from '../../../../../util/translate';

const ContentPhoto: FC<IContentOthersProps> = ({
  state,
  dispatch,
  className,
  availableContentMinAmountsArray,
}) => {
  const [
    { contentData, redemptionType, redemptionAmount },
    { setRedemptionType, setRedemptionAmount, setContentData },
  ] = useContent('PHOTOS', state, dispatch, 1);
  const isInternalAccount = useHasRole(SecurityRole.VX_USER_INTERNAL_ACCOUNT);

  const handleRedemptionTypeChange = (type: string) => {
    if (type === 'GRATIS') {
      setRedemptionType(type);
      setRedemptionAmount(0);
    } else if (type === 'ON_TOP') {
      setRedemptionType(type);
      setRedemptionAmount(-1);
    } else {
      setRedemptionType('');
      setRedemptionAmount(-1);
    }
  };

  const handlePhotoAmountChange = (value: string) => {
    setContentData(Number(value));
  };

  const handleRedemptionAmountChange = (amount: string) => {
    setRedemptionAmount(Number(amount));
  };

  return (
    <Layout
      className={className}
      title={_('benefits:voucher.photos.title')}
      subtitle={_('benefits:voucher.photos.subtitle')}
    >
      <FieldLayout
        label={_('benefits:voucher.texts.couponRedeemType')}
        error=""
        css={{ marginBottom: 24, flex: 0 }}
      >
        <Select
          value={redemptionType}
          name="voucherType"
          placeholder={_('benefits:voucher.texts.pleaseChoose')}
          onChange={(event) => handleRedemptionTypeChange(event.target.value)}
          options={[
            { label: _('benefits:voucher.texts.free'), value: 'GRATIS' },
            { label: _('benefits:voucher.texts.onTop'), value: 'ON_TOP' },
          ]}
        />
      </FieldLayout>

      {redemptionType === 'GRATIS' && (
        <FieldLayout
          label={_('benefits:voucher.texts.specifyAmount')}
          error=""
          css={{ flex: 0, marginBottom: 24, alignItems: 'flex-start' }}
        >
          <div css={{ flex: 0, alignItems: 'center' }}>
            <Select
              value={contentData}
              name="photoAmount"
              css={{ marginRight: 8, flex: '0 0 auto' }}
              onChange={(event) => handlePhotoAmountChange(event.target.value)}
              options={[
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '5', value: '5' },
                { label: '8', value: '8' },
                { label: '10', value: '10' },
              ]}
            />
            <span>
              {pluralize(
                contentData,
                _('benefits:voucher.texts.photoAlbum'),
                _('benefits:voucher.texts.photoAlbums')
              )}{' '}
              {_('benefits:voucher.texts.free').toLowerCase()}
            </span>
          </div>
        </FieldLayout>
      )}

      {redemptionType === 'ON_TOP' && (
        <div css={{ alignItems: 'center', marginBottom: 24, flex: 0 }}>
          <span>{_('benefits:voucher.texts.from')}</span>
          <Select
            value={String(redemptionAmount)}
            name="redemptionAmount"
            css={{ maxWidth: 128, margin: 8 }}
            onChange={(event) => handleRedemptionAmountChange(event.target.value)}
            options={availableContentMinAmountsArray}
          />
          <span>{_('benefits:voucher.texts.userGetsOnTop')}</span>
          <Select
            value={contentData}
            name="photoAmount"
            css={{ margin: 8, flex: '0 0 auto' }}
            onChange={(event) => handlePhotoAmountChange(event.target.value)}
            options={[
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '5', value: '5' },
              { label: '8', value: '8' },
              { label: '10', value: '10' },
            ]}
          />
          <span>
            {pluralize(
              contentData,
              _('benefits:voucher.texts.photoAlbum'),
              _('benefits:voucher.texts.photoAlbums')
            )}{' '}
            {_('benefits:voucher.texts.free').toLowerCase()}
          </span>
        </div>
      )}
    </Layout>
  );
};

export default ContentPhoto;
