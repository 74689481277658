import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GRAY_2 } from '../../../camtool-styles';
import { ITableColumn, TableSortOrderEnum } from '../types';
import { TableHeaderColumn, TableHeaderItem, TableHeaderItemSort } from './index';
import { SerializedStyles } from '@emotion/core';

interface IProps {
  columns: ITableColumn[];
  toggleSort?: (column: ITableColumn, index: number) => void;
  headerItemStyles?: SerializedStyles;
  bodyScrollbarWidth: number;
}

interface IContainerProps {
  extraPaddingRight: number;
}

const Container = styled.div<IContainerProps>`
  flex: 0 0 37px;
  padding: 0 ${(props): number => 8 + props.extraPaddingRight}px 0 8px;
  color: ${GRAY_2};
`;

const TableHeader: FC<IProps> = ({ columns, toggleSort, headerItemStyles, bodyScrollbarWidth }) => (
  <Container extraPaddingRight={bodyScrollbarWidth}>
    {columns.map((column, index) => {
      const { label = '', sortable = false, sort = TableSortOrderEnum.none } = column;

      const isSortSelected = sort !== TableSortOrderEnum.none;

      return (
        <TableHeaderColumn columns={columns} index={index} key={index}>
          {typeof label === 'function' ? (
            label()
          ) : (
            <TableHeaderItem
              isSortSelected={isSortSelected}
              sortable={sortable}
              onClick={toggleSort ? (): void => toggleSort(column, index) : null}
              css={headerItemStyles}
            >
              <span>{label}</span>
              {sortable && (
                <TableHeaderItemSort
                  sort={sort}
                  isSortSelected={isSortSelected}
                  className={isSortSelected ? 'icon-sort-by-attributes' : 'icon-sorting'}
                />
              )}
            </TableHeaderItem>
          )}
        </TableHeaderColumn>
      );
    })}
  </Container>
);

export default TableHeader;
