import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: () => ({
      flexDirection: 'column',
      '& div': {
        flex: 1,
      },
      '& .MuiToolbar-root, .MTablePaginationInner-root-7': {
        fontSize: 12,
      },
      '& .MuiTypography-caption': {
        fontSize: 12,
      },
    }),
  })
);

const Container: FC = ({ children }) => {
  const classes = useStyles();

  return <div className={'MTableContainer-root ' + classes.root}>{children}</div>;
};

export default Container;
