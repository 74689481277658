import { mergeDeep } from 'apollo-utilities';
import { DataProxy } from 'apollo-cache';
import { QUERY_MASTER_USER } from './queries';

export const updateUserBlockStatus = (
  isBlocked = false,
  cache: DataProxy,
  token: string | null | undefined,
  userId: number
): void => {
  const queryVariables = { token: token, id: userId };
  const cachedValue = cache.readQuery({
    query: QUERY_MASTER_USER,
    variables: queryVariables,
  });
  const mergedCache = mergeDeep(cachedValue, { master: { user: { blocked: isBlocked } } });
  cache.writeQuery({
    query: QUERY_MASTER_USER,
    variables: queryVariables,
    data: mergedCache,
  });
};
