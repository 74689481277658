import { stripSlash } from '../../util/urlHelper';
import { Redirect, Route, Switch } from 'react-router';
import Footer from '../../components/Footer/Footer';
import React from 'react';
import Video from './Video';
import Picture from './Picture';

import styled from '@emotion/styled';
import { BREAKPOINT_TABLET_CONDITION } from '../../camtool-styles';

const URL_PICTURE = '/picture';
const URL_VIDEO = '/video';

const Main = styled.main`
  display: flex;
  box-sizing: border-box;
  word-break: break-word;

  @media ${BREAKPOINT_TABLET_CONDITION} {
    flex: 0 0 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

// eslint-disable-next-line react/prop-types
const MediaManagement = ({ match: { url } }) => {
  const path = stripSlash(url);
  const indexRoute = path + URL_VIDEO;

  return (
    <Main className="mediamanagement__pic grid">
      <Switch>
        <Redirect exact from={`${path}/`} to={indexRoute} />
        <Route path={path + URL_VIDEO} component={Video} />
        <Route path={path + URL_PICTURE} component={Picture} />
        <Redirect to={indexRoute} />
      </Switch>

      <div className="grid__row grid__box">
        <Footer className="grid__box__item" />
      </div>
    </Main>
  );
};

export default MediaManagement;
