import equal from 'fast-deep-equal';
import { Subject } from 'rxjs';
import { log } from '../../../util/errorHandling';
import { calcFileMd5, calcFileMetaMd5 } from '../../../util/FileUtils';
import * as tus from 'tus-js-client';
import {
  Action,
  ActionEnum,
  ActionPayload,
  ContextFunctions,
  ContextState,
  FileId,
  FileWrapper,
  FileWrapperPayload,
  QueueItem,
  QueueItemPayload,
  StateEnum,
  TusUploaderPayload,
  UserData,
} from '../types';
import { getMediaTypeByFile } from '../util';
import { UPLOAD_CANCELED } from '../util/constants';
import { handleUpload, startOrResumeUpload } from './handler/upload';
import { handleMd5hashCalculated } from './handler/verify';

const userDataObs = new Subject<UserData>();
const queueObs = new Subject<QueueItem[]>();
const actionObs = new Subject<Action>();

const initialState: ContextState = {
  userData: { userId: undefined, sessionId: undefined },
  queue: [],
  externalQueue: [],
  files: {},
  currentUpload: null,
  uploader: [],
};

export const dispatch = (type: ActionEnum, payload: ActionPayload): void =>
  actionObs.next({ type, payload } as Action);

export let state: ContextState = initialState;

export const triggerUpdateQueue = (): void => {
  const { queue, externalQueue } = state;
  queue.forEach((i) => i.md5hash?.toUpperCase());
  const hashsInQueue = queue.map((i) => i.md5hash);
  const externalStatesMap = externalQueue
    .filter((i) => hashsInQueue.includes(i.md5hash))
    .reduce<Record<string, StateEnum>>(
      (states, i) => ({ ...states, [i.md5hash as string]: i.state }),
      {}
    );

  const externalItemsNotInQueue = externalQueue.filter((i) => !hashsInQueue.includes(i.md5hash));
  const mergedQueue = [
    ...queue.map((i) => ({ ...i, state: externalStatesMap[i.md5hash as string] || i.state })),
    ...externalItemsNotInQueue,
  ] as QueueItem[];

  queueObs.next(mergedQueue);
};

export function getCurrentUpload(): FileId | null {
  return state.currentUpload;
}

export function getState(): ContextState {
  return { ...state };
}

export function getFileWrapper(id: FileId): FileWrapper | undefined {
  return state.files[id];
}

export function getUserData(): UserData {
  return { ...state.userData };
}

export const getNextUploadQueueItem = (): QueueItem | undefined => {
  return state.queue.find(
    ({ verified, state }) => verified === true && state === StateEnum.waiting
  );
};

export const getCurrentUploadQueueItem = (): QueueItem | undefined => {
  return state.queue.find(
    ({ verified, state }) => verified === true && state === StateEnum.uploading
  );
};

const addFile = (file: File): void => {
  const id = calcFileMetaMd5(file);
  const { queue } = state;
  const ids = queue
    .filter((queueItem) =>
      [
        StateEnum.verifying,
        StateEnum.waiting,
        StateEnum.uploading,
        StateEnum.transcoding,
        StateEnum.canceled,
      ].includes(queueItem.state)
    )
    .map((queueItem) => queueItem.id);

  if (!ids.includes(id)) {
    const { files } = state;
    const fileWrapper = files[id] || {
      id,
      file,
      mediaType: getMediaTypeByFile(file),
      preview: URL.createObjectURL(file),
    };

    state = {
      ...state,
      queue: [
        ...queue,
        {
          id,
          displayName: file.name,
          state: StateEnum.verifying,
          mediaType: fileWrapper.mediaType,
          fileId: fileWrapper.id,
          preview: fileWrapper?.preview,
          md5hash: fileWrapper?.md5hash?.toUpperCase(),
          verified: fileWrapper?.verified,
          progress: 0,
          errorMessage: fileWrapper?.errorMessage,
        },
      ],
      files: { ...files, [fileWrapper.id]: fileWrapper },
    };

    triggerUpdateQueue();
    dispatch(ActionEnum.fileAdded, { fileWrapper });
  }
};

export const removeQueueItem = (id: FileId): void => {
  const { queue, uploader } = state;
  const idx = queue.findIndex((item) => item.id == id);
  queue.splice(idx, 1);
  const uploaderIdx = uploader.findIndex((uploader) => uploader.id == id);
  uploader.splice(uploaderIdx, 1);
  state = { ...state, queue: [...queue], uploader: [...uploader] };
  triggerUpdateQueue();
};

const getExpectedUploader = (id: FileId) => {
  const uploader = state.uploader;
  const upload = uploader.find((upload) => upload.id === id);
  return upload?.uploader;
};

const retryQueueItem = (id: FileId): void => {
  const uploader = getExpectedUploader(id);
  if (uploader) {
    startOrResumeUpload(uploader);
    triggerUpdateQueue();
  }
};

const cancelQueueItem = async (id: FileId): Promise<void> => {
  const { queue } = state;
  const queueItem = queue.find((item) => item.id == id);
  if (queueItem !== undefined) {
    //abort generic axios upload
    const { cancelTokenSource } = queueItem;
    if (cancelTokenSource) {
      cancelTokenSource.cancel(UPLOAD_CANCELED);
    }

    //abort tus upload
    const uploader = getExpectedUploader(id);
    if (uploader) {
      uploader.abort();
      updateCurrentUpload(null);
      updateQueueItem({ ...queueItem, state: StateEnum.canceled });
      dispatch(ActionEnum.uploadFailed, { queueItem });
    }
    triggerUpdateQueue();
  }
};

const UploadManagerStore: ContextState & ContextFunctions & { initialState: ContextState } = {
  addFiles: (files: File[]) => files.forEach((file: File) => UploadManagerStore.addFile(file)),
  addFile,
  removeQueueItem,
  cancelQueueItem,
  retryQueueItem,
  updateUserData: (userData: UserData) => userDataObs.next(userData),
  queueInit: () => triggerUpdateQueue(),
  updateExternalQueue: (externalQueue) => {
    if (!equal(state.externalQueue, externalQueue)) {
      state = { ...state, externalQueue: [...externalQueue] };
      triggerUpdateQueue();
    }
  },
  queueSubscribe: (observer) => queueObs.subscribe(observer),
  filesSubscribe: (observer) => actionObs.subscribe(observer),
  clearQueue: () => {
    state = { ...state, queue: [] };
    triggerUpdateQueue();
  },
  initialState,
  ...state,
};

export const handleUpdateUserData = (userData: UserData): void => {
  state = {
    ...state,
    userData: { ...state.userData, ...userData },
  };
};

export const updateUploader = (uploader: tus.Upload, id: FileId): void =>
  dispatch(ActionEnum.updateTusUploader, { uploader: [...state.uploader, { uploader, id }] });

export const updateQueueItem = (queueItem: Partial<QueueItem> & { id: FileId }): void =>
  dispatch(ActionEnum.updateQueueItem, { queueItem });

export const updateFileWrapper = (fileWrapper: FileWrapper): void =>
  dispatch(ActionEnum.updateFileWrapper, { fileWrapper });

export const updateCurrentUpload = (currentUpload: FileId | null): void =>
  dispatch(ActionEnum.updateCurrentUpload, currentUpload);

const handleUpdateFileWrapper = ({ type, payload }: Action) => {
  if (type === ActionEnum.updateFileWrapper) {
    const { fileWrapper } = payload as FileWrapperPayload;
    const { id } = fileWrapper;
    const nextFileWrapper = { ...state.files[id], ...fileWrapper };
    if (!equal(state.files[id], nextFileWrapper)) {
      state = { ...state, files: { ...state.files, [id]: nextFileWrapper } };
      const { verified, errorMessage, preview, md5hash } = state.files[id];
      updateQueueItem({ id, verified, preview, errorMessage, md5hash });
    }
  }
};

const handleUpdateUploader = ({ type, payload }: Action) => {
  if (type === ActionEnum.updateTusUploader) {
    const { uploader } = payload as TusUploaderPayload;
    state = { ...state, uploader };
    console.log(state);
  }
};

const handleUpdateQueueItem = ({ type, payload }: Action) => {
  if (type === ActionEnum.updateQueueItem) {
    const { queueItem } = payload as QueueItemPayload;
    const { id } = queueItem;
    const queue = [...state.queue];
    const index = state.queue.findIndex((item) => item.id == id);
    queue[index] = { ...queue[index], ...queueItem };
    if (!equal(state.queue, queue)) {
      state = { ...state, queue };
      triggerUpdateQueue();
    }
  }
};

const handleUpdateCurrentUpload = ({ type, payload }: Action) => {
  if (type === ActionEnum.updateCurrentUpload) {
    state = { ...state, currentUpload: payload as FileId | null };
  }
};

async function handleFileAdded({ type, payload }: Action): Promise<void> {
  if (type === ActionEnum.fileAdded) {
    const { fileWrapper } = payload as FileWrapperPayload;
    const { file, md5hash } = fileWrapper;
    const fallBackMd5Hash = await calcFileMd5(file);
    const fallbackMd5IsError = typeof fallBackMd5Hash !== 'string' && fallBackMd5Hash?.error;
    log(
      'info',
      '[UploadManager] file added, MD5Hash: ' +
        JSON.stringify(md5hash) +
        ' , Calculated MD5Hash: ' +
        JSON.stringify(fallBackMd5Hash),
      {
        context: 'UploadManager',
      }
    );

    if (fallbackMd5IsError) {
      log(
        'warning',
        '[UploadManager] MD5 calculation failed with error: ' + JSON.stringify(fallBackMd5Hash),
        {
          context: 'UploadManager',
          data: {
            file: {
              name: file.name,
              size: file.size,
            },
          },
        }
      );
    }

    const md5hashOrFallback: string =
      typeof fallBackMd5Hash === 'string'
        ? md5hash?.toUpperCase() || fallBackMd5Hash.toUpperCase()
        : 'failedMd5CalculationInBrowser';

    dispatch(ActionEnum.md5hashCalculated, {
      fileWrapper: {
        ...fileWrapper,
        md5hash: md5hashOrFallback,
      },
    });
  }
}

userDataObs.subscribe(handleUpdateUserData);

actionObs.subscribe(handleUpdateFileWrapper);
actionObs.subscribe(handleUpdateQueueItem);
actionObs.subscribe(handleUpdateUploader);
actionObs.subscribe(handleUpdateCurrentUpload);

actionObs.subscribe(handleFileAdded);
actionObs.subscribe(handleMd5hashCalculated);
actionObs.subscribe(handleUpload);
actionObs.subscribe();

export { UploadManagerStore };
