import React, { FC } from 'react';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';
import { Grid, Paper } from '@material-ui/core';
import { _ } from '../../../../util/translate';
import { SectionLabel } from '../../../Mailings/components';
import { FormatCurrency } from '../../../../components';
import SalesStatsItem from './SalesStatsItem';
import Icon from '../../../../atoms/Icon/Icon';
import { BLUE, DARK_GRAY, GREEN } from '../../../../camtool-styles';
import { GaugeIcon, NumberFormat } from '../../../../atoms';

interface SectionSalesStatsProps {
  broadcast: Broadcast;
}

const SectionSalesStats: FC<SectionSalesStatsProps> = ({ broadcast }) => {
  const { salesCount, paidContent, price, sentCount } = broadcast;

  return paidContent ? (
    <Grid container item spacing={3}>
      <Grid item xs>
        <SectionLabel>{_('telegram:BroadcastShow.salesStats.label')}</SectionLabel>
        <Paper style={{ backgroundColor: 'transparent' }}>
          <Grid container spacing={3}>
            <Grid item xs>
              <SalesStatsItem
                primary={_('telegram:BroadcastShow.salesCount.label')}
                secondary={salesCount}
                icon={<Icon icon={'icon-shopping-cart'} style={{ color: BLUE }} />}
              />
            </Grid>
            <Grid item xs>
              <SalesStatsItem
                primary={_('telegram:BroadcastShow.price.label')}
                secondary={<FormatCurrency value={price} />}
                icon={<Icon icon={'icon-money'} style={{ color: GREEN }} />}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  ) : null;
};

export default SectionSalesStats;
