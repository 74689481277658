import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import EventEmitter from 'events';
import ActionTypes from '../../util/constants/ActionTypes';

let CHANGE_EVENT = 'SalesStatisticsStore.change';

let storeData = {
  turnoverDetails: [],
  selectedAmount: 0,
  selectedChatTime: 0,
  selectedVideoSales: 0,
  selectedTel0900Time: 0,
};

class SalesStatisticsStoreClass extends EventEmitter {
  emitChange() {
    this.emit(CHANGE_EVENT);
  }
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }
  get() {
    return storeData;
  }
}

const SalesStatisticsStore = new SalesStatisticsStoreClass();

AppDispatcher.register(function(payload) {
  switch (payload.type) {
    case ActionTypes.APPS_SALESSTATISTICS_RECEIVE_DATA:
      storeData = update(storeData, { $merge: payload.data });
      SalesStatisticsStore.emitChange();
      break;
    default:
  }
});

export { SalesStatisticsStore };
