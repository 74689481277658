import qs from 'query-string';

import { JsUploaderManager } from '../stores/JsUploader/JsUploader';
import { log } from './errorHandling';

import AppDispatcher from './dispatcher/AppDispatcher';
import * as ActionTypes from './constants/ActionTypes';
import { APP_BASE_PATH } from './env';
import { clearAuthTokenCookie, clearBackurlMobileCookie } from './cookies';
import { _ } from './translate';

function registerConfirmExit() {
  const confirmExit = function (e) {
    return _('mediamanagement:multiuploader.reloadMessage');
  };

  if (window.attachEvent) {
    // IE < 11
    window.beforeunload = confirmExit;
  } else {
    window.onbeforeunload = confirmExit;
  }
}

function getRedirectQueryString() {
  const redir = location.pathname + location.search;

  return redir && redir !== '/' ? `?${qs.stringify({ redir })}` : '';
}

class AppUtilsClass {
  init() {
    AppDispatcher.register(({ type }) => {
      switch (type) {
        case ActionTypes.CAMTOOL_RELOAD:
          this.forceReload();
          break;
      }
    });

    this.registerWindowEvents();
  }

  unregisterConfirmExit() {
    if (window.attachEvent) {
      // IE < 11
      window.beforeunload = undefined;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  forceReload() {
    log('notice', 'Force an application reload', { context: 'AppUtils.forceReload' });
    this.unregisterConfirmExit();
    location.reload(true);
  }

  forceLogout(disableRedirect = false) {
    log('notice', 'Force an application logout', { context: 'AppUtils.forceLogout' });
    clearAuthTokenCookie();
    clearBackurlMobileCookie();
    this.unregisterConfirmExit();
    window.location.href = `${APP_BASE_PATH}/logout${
      disableRedirect ? '' : getRedirectQueryString()
    }`;
  }

  registerWindowEvents() {
    JsUploaderManager.on('onbusy', () => {
      // console.log(this.__proto__.constructor.name, 'registerWindowEvents', 'onbusy');
      registerConfirmExit();
    });
    JsUploaderManager.on('onidle', () => {
      // console.log(this.__proto__.constructor.name, 'registerWindowEvents', 'onidle');
      this.unregisterConfirmExit();
    });
  }
}

const AppUtils = new AppUtilsClass();

export default AppUtils;
