import React from 'react';
import { Redirect, Switch } from 'react-router';
import { stripSlash } from '../../../util/urlHelper';
import ProtectedRoute from '../../../components/Routing/ProtectedRoute';
import { VXGamesChallenge } from '../components/';
import VXGamesOverview from '../components/shared/VXGames/VXGamesOverview/VXGamesOverview';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const VXGames = ({ match: { url } }) => {
  const path = stripSlash(url);
  const overviewRoute = `${path}/overview`;
  const vxGamesChallengeRoute = `${path}/:challengeId`;

  return (
    <Switch>
      <ProtectedRoute exact path={overviewRoute} component={VXGamesOverview} />
      <ProtectedRoute exact path={vxGamesChallengeRoute} component={VXGamesChallenge} />
      <Redirect to={overviewRoute} />
    </Switch>
  );
};

export default VXGames;
