import React, { FC, useEffect } from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-common';
import { ClientsNamespace } from './types';
import { ApolloMultipleClientsContext } from './ApolloMultipleClientsContext';
import { useClients } from './hooks';

interface ApolloMultipleClientsProviderProps {
  defaultNamespace: ClientsNamespace;
}

export const ApolloMultipleClientsProvider: FC<ApolloMultipleClientsProviderProps> = ({
  defaultNamespace = 'VXModels',
  children,
}) => {
  const clients = useClients();

  useEffect(() => {
    if (!clients || Object.keys(clients).length === 0) {
      throw new Error('You need to define at least one client');
    }
  }, []);

  const getClient = (namespace: ClientsNamespace): ApolloClient<{}> => {
    const client = clients[namespace];
    if (!client) throw new Error(`Could not find client for namespace "${namespace}"`);
    return client;
  };

  const defaultClient = getClient(defaultNamespace);
  if (!defaultClient) throw new Error('Default client definition missing');

  return (
    <ApolloMultipleClientsContext.Provider value={{ getClient }}>
      {defaultClient ? <ApolloProvider client={defaultClient}>{children}</ApolloProvider> : null}
    </ApolloMultipleClientsContext.Provider>
  );
};
