import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Section } from '../../../atoms/';

interface SettingsSectionProps {
  title: string;
  headline: string;
  subHeadline: string;
  bulletList: Array<string>;
  className?: string;
}

const SettingsSection: FC<SettingsSectionProps> = ({
  title,
  headline,
  subHeadline,
  bulletList,
  className,
  children,
}) => {
  return (
    <Section className={className} title={title} styles={{ padding: '25px' }}>
      <Typography variant="h3" gutterBottom>
        {headline}
      </Typography>
      <Typography variant="subtitle1">{subHeadline}</Typography>
      <Box pl={2} mt={2}>
        <ul>
          {bulletList.map((argument, index) => (
            <li key={argument.substring(0, 4) + index}>
              <Typography>{argument}</Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Box height="100%" mt={2}>
        {children}
      </Box>
    </Section>
  );
};

export default SettingsSection;
