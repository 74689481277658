import React, { FC } from 'react';
import styled from '@emotion/styled';
import { RED, YELLOW } from '../../../camtool-styles';
import Button from '../../../atoms/Button/Button';
import { QUERY_MODEL_SETTINGS_LOVENSE_TOY } from '../../../graphql/VXModels/queries';
import { MUTATION_MODEL_SETTINGS_REQUEST_LOVENSE_TOY } from '../../../graphql/VXModels/mutations';
import { useMutation } from '@apollo/react-hooks';
import Spinner from '../../../components/Spinner/Spinner';
import { LovenseToyStatusEnum } from '../../../graphql/VXModels/types';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import { _ } from '../../../util/translate';
import { useLang } from '../../../util/AppState';
import { getFormattedDate } from '../../../util/Formatter';

interface Props {
  lovenseToyStatus: string;
  newRequestDate: Date;
}

const RequestSection = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 auto;
  margin-bottom: 65px;
`;

const StyledSpan = styled.span`
  margin-top: 10px;
`;

const SpinnerContainer = styled.div`
  position: relative;
  min-height: 120px;
  min-width: 50px;
  flex-grow: 1;
`;

const ToyControlRequestButton: FC<Props> = ({ lovenseToyStatus, newRequestDate }) => {
  const [requestToyControl, { loading, error }] = useMutation(
    MUTATION_MODEL_SETTINGS_REQUEST_LOVENSE_TOY,
    {
      refetchQueries: [{ query: QUERY_MODEL_SETTINGS_LOVENSE_TOY }],
      awaitRefetchQueries: true,
    }
  );

  const [lang] = useLang();
  return (
    <RequestSection>
      <>
        {lovenseToyStatus === LovenseToyStatusEnum.notSet ? (
          <>
            {!loading ? (
              <Button theme="blue" onClick={() => requestToyControl()}>
                {_('settings:toycontrol.button.request.activate')}
              </Button>
            ) : error ? (
              <EmptyContent icon="icon-warning-sign" title={_('common:error.generic')} />
            ) : (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
          </>
        ) : lovenseToyStatus === LovenseToyStatusEnum.requested ? (
          <>
            <Button theme="gray" css={{ pointerEvents: 'none' }}>
              {_('settings:toycontrol.button.request.sent')}
            </Button>
            <StyledSpan css={{ color: YELLOW }}>
              {_('settings:toycontrol.button.request.text')}
            </StyledSpan>
          </>
        ) : lovenseToyStatus === LovenseToyStatusEnum.rejected ? (
          <>
            <Button theme="red" disabled={true}>
              {_('settings:toycontrol.button.denied.rejected')}
            </Button>
            <StyledSpan css={{ color: RED }}>
              {_('settings:toycontrol.button.denied.text', {
                sprintf: [getFormattedDate(newRequestDate, lang)],
              })}
            </StyledSpan>
          </>
        ) : lovenseToyStatus === LovenseToyStatusEnum.notAllowed ? null : null}
      </>
    </RequestSection>
  );
};

export default ToyControlRequestButton;
