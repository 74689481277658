import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import AbstractComponent from '../../AbstractComponent';
import BigIconButton from '../../BigIconButton/BigIconButton';
import globRegex from 'glob-regex';

import { getStackTrace, log } from '../../../util/errorHandling';
import styled from '@emotion/styled';
import { BREAKPOINT_PHONE_CONDITION } from '../../../camtool-styles';
import { isMimeType } from '../../../packages/UploadManager/util';

const Col = styled.div`
  outline: none;
  @media ${BREAKPOINT_PHONE_CONDITION} {
    flex: 1 1 auto;
    justify-content: center;
  }
  > div {
    outline: none;
  }
`;

const BigIconButtonStyled = styled(BigIconButton)`
  @media ${BREAKPOINT_PHONE_CONDITION} {
    flex: 1 1 auto !important;
  }
`;

class UploadField extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      showDragOver: false,
    };
  }

  onDrop(files) {
    this.setState({
      errorMessage: '',
      showDragOver: false,
    });
    this.props.onUpload(files);
  }

  dragEnter(event) {
    event.stopPropagation();
    const file = event.dataTransfer.items[0];
    try {
      const type = file.type;
      if (type) {
        this.setState({ showDragOver: isMimeType(file, this.props.acceptedMimeTypes) });
      }
    } catch (err) {
      log('notice', err.message, { context: 'UploadField.dragEnter', err });
      this.setState({ showDragOver: false });
    }
  }

  dragLeave(event) {
    event.stopPropagation();
    this.setState({ showDragOver: false });
  }

  isClickDisabled() {
    // <!--Workaround:prevent upload-click on errorMessage close-click -->
    return this.state.errorMessage.length > 0;
  }

  render() {
    return (
      <Col className="uploadfield">
        <Dropzone
          className="uploadfield__dropzone"
          onDragEnter={this.dragEnter}
          onDragLeave={this.dragLeave}
          onDrop={this.onDrop}
          disableClick={this.isClickDisabled()}
          accept={this.props.acceptedMimeTypes}
          multiple={this.props.multiple}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <BigIconButtonStyled
                label={this.props.label}
                info={this.props.subtitle}
                sizeInfo={this.props.sizeInfo}
                iconMain="icon-disk-open"
                iconLeft="icon-picture"
                iconRight="icon-camera"
              />
              <div
                className={classNames({
                  uploadfield__dropzone__area: true,
                  'uploadfield__dropzone__area--show': this.state.showDragOver,
                })}
              />
            </div>
          )}
        </Dropzone>
      </Col>
    );
  }
}

UploadField.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  sizeInfo: PropTypes.string,
  acceptedMimeTypes: PropTypes.string,
  multiple: PropTypes.bool,
  onUpload: PropTypes.func,
};

UploadField.defaultProps = {
  label: '',
  subtitle: '',
  sizeInfo: '',
  acceptedMimeTypes: undefined,
  multiple: false,
  onUpload: () => {},
};

UploadField.debug = false;

export default UploadField;
export { UploadField };
