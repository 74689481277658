import React, { FC } from 'react';
import { Icon } from '../../../atoms';
import { chat } from '../../../atoms/Icon/libraries/glyph';
import { _ } from '../../../util/translate';
import { YELLOW } from '../../../camtool-styles';

interface Props {
  commentAmount: number;
}

const CommentsDisplay: FC<Props> = ({ commentAmount }) => {
  return (
    <div css={{ marginTop: '20px', fontSize: '15px' }}>
      <Icon css={{ color: YELLOW, marginRight: '5px' }} icon={chat} />
      <span css={{ textTransform: 'uppercase' }}>{`${_(
        'mediamanagement:video.nav.comments'
      )} (${commentAmount})`}</span>
    </div>
  );
};

export default CommentsDisplay;
