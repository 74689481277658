import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { newWindow } from '../../../../../atoms/Icon/libraries/glyph';

const AutoMailsItem: FC = () => (
  <LeftMenuItem
    title={_('navigation:telegram.automails')}
    icon={newWindow}
    uri="/telegram/automail"
  />
);

export default AutoMailsItem;
