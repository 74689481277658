import React from 'react';
import styled from '@emotion/styled';
import Button from '../../Button/Button';
import DateFormatter from '../../Formatter/DateFormatter';

const Container = styled.section`
  position: relative;
  outline: none;
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100% - 30px);
  bottom: 0;
  cursor: default;
`;

const Content = styled.section`
  flex-wrap: wrap;
  background-color: #fff;
  position: absolute;
  bottom: 1px;
  padding: 8px;
`;

const RejectedInfo = styled.section`
  flex-direction: column;
  padding: 8px 0;
`;

const ActionsWindow = ({
  open,
  timestamp,
  status,
  rejectedReason,
  children,
  clickHandler,
  keyPressHandler,
  buttonLabel,
  buttonClickHandler,
}) => {
  if (open) {
    return (
      <Container
        data-testid="action-window"
        onClick={clickHandler}
        role="button"
        tabIndex={0}
        onKeyPress={keyPressHandler}
      >
        <Content>
          <div>
            Story von <DateFormatter format="Pp" date={timestamp} /> ({status})
          </div>

          {status === 'offline' && rejectedReason && (
            <RejectedInfo data-testid="rejected-reason-div">
              <h4>Ablehnungsgrund</h4>
              <div>{rejectedReason}</div>
            </RejectedInfo>
          )}

          {children}

          <Button
            className="button button--red"
            label={buttonLabel}
            onClick={buttonClickHandler}
            css={{ padding: '8px 0' }}
          />
        </Content>
      </Container>
    );
  }

  return null;
};

export default ActionsWindow;
