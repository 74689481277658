import React from 'react';
import PropTypes from 'prop-types';

const LegendItem = ({ text, status }) => (
  <div className="profiles-overview__legend">
    <div className={`profiles-overview__legend__icon profiles-overview__legend__icon--${status}`} />
    <div className="profiles-overview__legend__text">{text}</div>
  </div>
);

LegendItem.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['completed', 'missing', 'required', 'evaluating']).isRequired,
};

export default LegendItem;
