import { useApolloClient } from '../ApolloMultipleClientsContext';
import { ClientsNamespace } from '../types';
import { ApiLang } from './types';
import { useMemo } from 'react';
import { getVXModelsClient } from '../index';
import ApolloClient from 'apollo-client';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { QUERY_MODEL_TVSHOWS_REPLACEMENTOFFER } from './queries';
import { MUTATION_MODEL_TVSHOWS_SETREPLACEMENTOFFER } from './mutations/modelTvshowsSetReplacementOffer';
import { mergeDeep } from 'apollo-utilities';

export const useVXModelsClient = (): ApolloClient<{}> => useApolloClient(ClientsNamespace.VXModels);

export const useCreateVXModelsClient = (
  authToken: string | null,
  lang: ApiLang
): ApolloClient<{}> =>
  useMemo(() => getVXModelsClient(authToken || undefined, lang), [authToken, lang]);

export const useModelTvshowsReplacementOfferQuery = () => {
  const client = useVXModelsClient();
  const { data, ...rest } = useQuery(QUERY_MODEL_TVSHOWS_REPLACEMENTOFFER, {
    client,
    displayName: 'useModelTvshowsReplacementOfferQuery',
    fetchPolicy: 'cache-and-network',
  });

  return { replacementOffer: data?.model?.tvshows?.replacementOffer, ...rest };
};

export const useModelTvshowsSetReplacementOfferMutation = () => {
  const client = useVXModelsClient();
  const [mutate, result] = useMutation(MUTATION_MODEL_TVSHOWS_SETREPLACEMENTOFFER, {
    client,
  });

  const setReplacementOffer = (value: boolean) =>
    mutate({
      variables: { value },
      optimisticResponse: () => ({
        __typename: 'Mutation',
        model: {
          __typename: 'ModelMutation',
          tvshows: {
            __typename: 'VXTVShowMutation',
            setReplacementOffer: {
              __typename: 'GenericResponse',
              status: 'OK',
              data: {},
              error: '',
            },
          },
        },
      }),

      update: (proxy) => {
        const cachedData = proxy.readQuery({ query: QUERY_MODEL_TVSHOWS_REPLACEMENTOFFER });
        const mergedData = mergeDeep(cachedData, {
          model: { tvshows: { replacementOffer: value } },
        });
        proxy.writeQuery({ query: QUERY_MODEL_TVSHOWS_REPLACEMENTOFFER, data: mergedData });
      },
    });

  return [setReplacementOffer, result];
};
