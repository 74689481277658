import React from 'react';
import cc from 'classcat';

import AbstractFormMultiOptionsComponent from '../AbstractFormMultiOptionsComponent';
import MaxValueLength from '../../Display/MaxValueLength/MaxValueLength';
import OptionBox from '../OptionBox';

class OptionBoxCheckbox extends AbstractFormMultiOptionsComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    event.stopPropagation();
    const value = event.target.value;

    if (this.getValue().indexOf(value) === -1) {
      this.addValue(value);
      if (value === 'noModifications') this.removeAllBut(value);
    } else {
      this.removeValue(value);
    }
  }

  render() {
    const { maxLength, name, iconPrefix, icon, disabled } = this.props;
    const selectedOptions = this.getSelectedOptions();
    const maxReached = maxLength > 0 && selectedOptions.length >= maxLength;

    return (
      <div
        className={cc([
          'option-box-checkbox-std',
          { 'maxvaluelength-anchorframe': maxLength > 0 },
          this.getClassNames(),
        ])}
      >
        {maxLength > 0 && <MaxValueLength max={maxLength} value={selectedOptions.length} />}
        {this.options.map((option) => {
          const att = {
            name,
            value: option.value,
            disabled: disabled || (maxReached && !this.isOptionSelected(option)),
            checked: this.isOptionSelected(option), // ? 'checked' : undefined
            onChange: this.onChange,
            icon,
            iconPrefix,
            label: option.label,
            sublabel: option.sublabel,
          };

          return <OptionBox key={option.value} {...att} />;
        })}
      </div>
    );
  }
}

export default OptionBoxCheckbox;
export { OptionBoxCheckbox };
