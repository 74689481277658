import React, { Component } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { Tag } from '../../atoms';
import { IOption, IOptions } from './MultipleOptions';

const Container = styled.article`
  display: flex;
  justify-content: space-around;
`;

const Left = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  &:before {
    font-family: GLYPHICONS;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 128px;
    content: '\\E036';
    position: absolute;
    left: 0;
    border: 1px dashed #dcdcde;
    border-radius: 50%;
    padding: 32px;
    color: #1f93e9;
  }
`;

const Right = styled.section`
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-height: 372px;
  align-items: center;
`;

interface IProps {
  allOptions: IOptions;
  currentOptions: IOptions;
  addOption: (option: IOption, callback?: (newLanguages: IOptions) => void) => void;
  removeOption: (option: IOption, callback?: (newLanguages: IOptions) => void) => void;
  translator: (label: string) => string;
}

class AttributesPicker extends Component<IProps> {
  public handleClickAllTag = (optionId: string) => {
    this.props.addOption(this.props.allOptions.filter(opt => opt.value === optionId)[0]);
  };

  public handleClickCurrentTag = (optionId: string) => {
    this.props.removeOption(this.props.allOptions.filter(opt => opt.value === optionId)[0]);
  };

  public getAllValidOptions = (): IOptions => {
    return this.props.allOptions.filter(opt => {
      for (const currentOpt of this.props.currentOptions) {
        if (opt.value === currentOpt.value) {
          return false;
        }
      }
      return true;
    });
  };

  public getTop = (i: number) => {
    switch (i) {
      case 0:
        return -40;
      case 2:
        return 40;
      case 1:
      default:
        return 0;
    }
  };

  public render() {
    return (
      <Container>
        <Left>
          {this.props.currentOptions.map((opt, i) => (
            <Tag
              className={css`
                margin: 4px;
                z-index: 1;
                position: absolute;
                right: ${i === 1 ? 156 : 138}px;
                top: calc(calc(50% - 32px) + ${this.getTop(i)}px);
                white-space: nowrap;
                .icon-remove {
                  color: #bcbcbc;
                  margin-left: 8px;
                }
                &:hover {
                  .icon-remove {
                    color: #ff4d3c;
                  }
                }
              `}
              onClick={this.handleClickCurrentTag}
              tagId={opt.value}
              key={opt.value}
            >
              {this.props.translator ? this.props.translator(opt.label) : opt.label}
              <div className="icon-remove" />
            </Tag>
          ))}
        </Left>

        <Right>
          {this.getAllValidOptions().map(opt => (
            <Tag
              className={css`
                margin: 4px;
                &[disabled] {
                  pointer-events: none;
                  color: #bcbcbc;
                }
                .icon-plus-sign {
                  color: #bcbcbc;
                  margin-left: 8px;
                }
                &:hover {
                  .icon-plus-sign {
                    color: #525158;
                  }
                }
              `}
              onClick={this.handleClickAllTag}
              tagId={opt.value}
              disabled={this.props.currentOptions.length >= 3}
              key={opt.value}
            >
              {this.props.translator ? this.props.translator(opt.label) : opt.label}
              <div className="icon-plus-sign" />
            </Tag>
          ))}
        </Right>
      </Container>
    );
  }
}

export default AttributesPicker;
