/* eslint-disable  react/prop-types*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BLACK, RED, WHITE, YELLOW, BREAKPOINT_TABLET_CONDITION } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';
import defaultPicture from './__assets__/profile_title_pic_bg.jpg';

const Status = styled.div`
  font-size: 16px;
  right: 16px;
  top: 16px;
  position: absolute;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${YELLOW};
  color: ${BLACK};
`;

const InfoBoxContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const InfoBoxMainText = styled.div`
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 20px;
  color: ${WHITE};
`;

const InfoBoxSubText = styled.div`
  -ms-flex: 0 0 auto
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  color: ${WHITE};
  margin-top: 20px;
  font-size: 14px;
`;

const RejectedText = styled.div`
  -ms-flex: 0 0 auto
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0 16px;
`;

const ProfilePicContainer = styled.div`
  @media ${BREAKPOINT_TABLET_CONDITION} {
    min-height: 440px;
  }
`;

const TitelPicture = ({ url, onClick, status, info, ageRating }) => {
  const getColor = () => {
    if (status === 'rejected') return RED;
    if (status === 'unverified') return YELLOW;
    return WHITE;
  };

  return (
    <ProfilePicContainer
      className="profile-pic__view__profile__vx__item__titlepic"
      css={{ backgroundImage: `url('${url || defaultPicture}')` }}
      onClick={onClick}
    >
      {/*Bild Ändern Button*/}
      <div className="profile-pic__view__profile__vx__item__titlepic__change">
        <div className="profile-pic__view__profile__vx__item__titlepic__change__icon" />
        <div className="profile-pic__view__profile__vx__item__titlepic__change__text">
          {_('mediamanagement:sedcardVX.titlePictureChange')}
        </div>
      </div>

      {/*Infobox falls noch kein Titlebild gesetzt ist*/}
      {!url && (
        <InfoBoxContainer>
          <div
            css={{
              width: 106,
              height: 188,
              marginBottom: 16,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url('${require(`./__assets__/fsk${ageRating}_white.svg`)}')`,
            }}
          />
          <InfoBoxMainText>
            <span className="icon-disk-open" css={{ marginRight: 12 }} />
            {_('dashboard:app.profilePicture.header')}
          </InfoBoxMainText>
          <InfoBoxSubText>
            <ul>
              <li>{_(`mediamanagement:sedcardVX.profilePictureSubline1.age${ageRating}`)}</li>
              <li>{_(`mediamanagement:sedcardVX.profilePictureSubline2.age${ageRating}`)}</li>
            </ul>
          </InfoBoxSubText>
        </InfoBoxContainer>
      )}

      {status === 'rejected' && (
        <div className="profile-pic__view__profile__vx__item__titlepic__rejected">
          <RejectedText>{_('mediamanagement:sedcardVX.titlePictureRejected')}</RejectedText>
          <RejectedText>{info}</RejectedText>
        </div>
      )}

      {status === 'unverified' && (
        <Status css={{ backgroundColor: getColor() }}>{_('common:text.checking')}</Status>
      )}
      {status === 'rejected' && (
        <Status css={{ backgroundColor: getColor() }}>{_('common:text.rejected')}</Status>
      )}
    </ProfilePicContainer>
  );
};

TitelPicture.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(['rejected', 'unverified', 'ok']),
  className: PropTypes.string,
};

TitelPicture.defaultProps = {
  url: '',
  onClick: null,
  status: 'unverified',
  className: '',
};

export default TitelPicture;
