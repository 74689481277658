import React, { FC } from 'react';
import { Section } from '../../../../atoms';
import { _ } from '../../../../util/translate';
import { Service0900StatusEnum } from '../../../../graphql/VXModels/types';
import GenericWarningText from '../../../../atoms/GenericWarningText/GenericWarningText';
import { earphone } from '../../../../atoms/Icon/libraries/glyph';
import Icon from '../../../../atoms/Icon/Icon';
import { GRAY_4 } from '../../../../camtool-styles';
import styled from '@emotion/styled';

const Headline = styled.h2`
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${GRAY_4};
`;

interface Props {
  status: Service0900StatusEnum | undefined;
}

const ActivateNumberSection: FC<Props> = () => {
  return (
    <Section css={{ flex: '1 0 auto' }} title={_('service0900:PhoneSex.active.activate.title')}>
      <div
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20px',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Headline>{_('service0900:phoneSex.pleaseNote')}</Headline>
        <p css={{ marginBottom: '70px', maxWidth: '450px', textAlign: 'center', fontSize: '16px' }}>
          <span css={{ marginBottom: '8px' }}>
            {_('service0900:PhoneSex.active.activate.info1')}
          </span>
          <span css={{ fontSize: '18px' }}>{_('service0900:PhoneSex.active.activate.info2')}</span>
          <Icon icon={earphone} css={{ margin: '0px 4px' }} size={'15px'} />
          <span css={{ fontSize: '18px' }}>{_('service0900:PhoneSex.active.activate.info3')}</span>
        </p>
        <GenericWarningText
          css={{ maxWidth: '415px' }}
          text={_('service0900:phoneSex.settings.statusLabel')}
        />
      </div>
    </Section>
  );
};

export default ActivateNumberSection;
