import React from 'react';
import { graphql } from '@apollo/react-hoc';
import * as compose from 'lodash.flowright';
import { withRouter } from 'react-router';
import NavPictureView from '../../../../routes/MediaManagement/Picture/NavPictureView/NavPictureView';
import AlbumAdd from '../../../../components/MediaManagement/ExplorerPicture/AlbumAdd/AlbumAdd';
import AlbumItem from '../../../../components/MediaManagement/ExplorerPicture/AlbumItem/AlbumItem';
import AbstractAlbumView from '../../../../components/MediaManagement/ExplorerPicture/AbstractAlbumView';
import { Tooltip, Spinner, EmptyContent } from '../../../../components';
import {
  MediaManagementActionCreators,
  RESOURCE_ALBUM,
} from '../../../../stores/MediaManagement/MediaManagement';
import { AlertsStore } from '../../../../stores/Alerts/AlertsStore';
import { JsUploaderPictureStore } from '../../../../stores/JsUploader/JsUploader';
import {
  MUTATION_MODEL_MEDIA_DELETE_PICTURE,
  MUTATION_MODEL_MEDIA_SET_PREVIEW_PICTURE,
  MUTATION_MODEL_MEDIA_MOVE_PICTURES_TO_ALBUM,
} from '../../../../graphql/VXModels/mutations';
import { RED } from '../../../../camtool-styles';
import { hasRole } from '../../../../util/UserData';
import { SecurityRole } from '../../../../graphql/VXModels/types';
import { _ } from '../../../../util/translate';
import { APP_BASE_PATH } from '../../../../util/env';
import { alignCenter } from '../../../../atoms/Icon/libraries/glyph';

class AlbumViewStandard extends AbstractAlbumView {
  onClickDeleteAlbum() {
    if (confirm(_('mediamanagement:picture.album.form.confirmDelete'))) {
      this.setState({ isLoading: true });
      MediaManagementActionCreators.deleteResource(
        RESOURCE_ALBUM,
        {
          routeParams: { albumId: this.props.album.id },
        },
        () => {
          AlertsStore.add({ type: 'info', message: _('common:alerts.deleteSuccess') });
          this.setState({ isLoading: false });
          this.props.history.push(
            APP_BASE_PATH + '/mediamanagement/picture/' + this.props.album.albumType
          );
        },
        () => {
          AlertsStore.add({ type: 'error', message: _('common:alerts.deleteFail') });
          this.setState({ isLoading: false });
        }
      );
    }
  }

  setEditMode() {
    if (this.props.editable) {
      this.context.router.push(this.context.router.location.pathname + '/edit');
    }
  }

  isPictureDeleteAllowed(picture) {
    return this.allowDeletePicture || picture.status === 'rejected';
  }

  componentDidMount() {
    JsUploaderPictureStore.on('complete', () => {
      if (this.props.album && this.props.album.albumType === 'pool') this.props.refetchAlbum();
    });
  }

  render() {
    this.isRejected = this.props.album.status === 'rejected';
    this.isNotRejected = this.props.album.status !== 'rejected';

    //general rules
    this.allowAddPicture = this.isNotRejected;
    this.allowDeletePicture = this.props.album.sales === 0;
    this.allowMovePictureToPool = this.isNotRejected;
    this.allowMovePictureToPos0 = this.isNotRejected;
    this.allowSelect = this.isNotRejected;
    this.allowSetAsPreview = this.isNotRejected;
    this.showStatusInChecking = this.isNotRejected;
    this.showHeader = true;

    // specific rules
    switch (this.props.album.albumType) {
      case 'pool':
        this.allowAddPicture = false;
        this.allowDeletePicture = true;
        this.allowMovePictureToPool = false;
        this.allowSetAsPreview = false;
        break;
      case 'new':
        this.allowAddPicture = false;
        this.allowMovePictureToPool = false;
        this.allowSetAsPreview = false;
        break;
      case 'shop':
        this.allowMovePictureToPool = this.isNotRejected && this.props.album.sales === 0;
        break;
      case 'free':
        break;
      case 'campaign':
      case 'contest':
        this.allowAddPicture =
          this.isNotRejected &&
          this.props.album.contest?.isUploadActive &&
          this.state.album.numberOfAllPictures - this.state.album.numberOfRejectedPictures <
            this.props.album.contest.uploadLimit;

        break;
      case 'vxhpmediapool':
        this.showHeader = false;
        this.allowSetAsPreview = false;
        break;
    }
    this.showMenu = this.isNotRejected && this.props.editable;
    this.showDeleteButton = this.isRejected && this.props.editable;

    if (this.props.album.albumType !== 'pool' && this.props.album?.numberOfAllPictures >= 99) {
      this.allowAddPicture = false;
      this.showUploadError = true;
    }

    this.props.setCurrentAlbumType(this.props.album.albumType);

    return (
      <div className="grid__box__column grid__box__sub">
        {this.showMenu && (
          <NavPictureView albumId={this.props.album.id} albumType={this.props.album.albumType} />
        )}

        {this.showHeader && (
          <div className="grid__box__sub__header">
            <div className="grid__box__sub__header__item grid__box__sub__header__item--active">
              {this.props.title}
              {this.isRejected
                ? `(${_('mediamanagement:picfolder.common.status' + this.props.album.status)})`
                : ''}
            </div>

            {this.props.titleTooltip && <Tooltip position="right" text={this.props.titleTooltip} />}

            <div className="grid__box__sub__header__spacer" />

            {this.showDeleteButton && (
              <div
                onClick={this.onClickDeleteAlbum}
                className="icon-bin grid__box__sub__header__item--option"
              >
                {_('common:button.delete')}
              </div>
            )}
          </div>
        )}

        <div className="grid__box__sub__item">
          <div
            className="mediamanagement__pic__album spinner-container"
            onClick={this.onContentClick}
          >
            {this.props.album.albumType === 'shop' &&
              hasRole(SecurityRole.VX_USER_VXMODELS) &&
              this.props.album.pictures.filter((picture) => picture.status === 'online').length <
                4 && (
                <p css={{ color: RED, textAlign: 'center' }}>
                  <span
                    className="icon-warning-sign"
                    css={{
                      fontSize: '35px',
                      margin: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  />
                  <span css={{ textAlign: 'center', margin: '0 50px' }}>
                    {_('mediamanagement:picfolder.atLeast4Pics')}
                  </span>
                </p>
              )}

            {this.state.isLoading && <Spinner />}

            <div className="mediamanagement__pic__album__content">
              {this.allowAddPicture && (
                <AlbumAdd
                  pictureType={this.props.album.albumType}
                  targetUmaId={this.props.album.id}
                  onSubmit={this.addPicturesToAlbum_GRAPHQL}
                  title={_('mediamanagement:picfolder.contentItemAdd.title')}
                  allowMultipleSelection={this.props.allowMultipleSelection}
                />
              )}
              {this.showUploadError && (
                <p css={{ color: RED, width: '100%', textAlign: 'center', padding: '15px 0' }}>
                  <span
                    className="icon-warning-sign"
                    css={{ fontSize: '35px', margin: '5px calc(50% - 35px)' }}
                  />
                  <span>{_('mediamanagement:uploadCount.over99Pics.text')}</span>
                </p>
              )}

              {this.props.album.pictures.map((picture, pictureIndex) => (
                <AlbumItem
                  key={picture.pictureId}
                  data={picture}
                  album={picture.album}
                  showDelete={this.isPictureDeleteAllowed(picture)}
                  showMoveToPool={this.allowMovePictureToPool}
                  showInChecking={this.showStatusInChecking}
                  showDetails
                  showSetAsPreview={this.allowSetAsPreview}
                  onClickMoveToPool={this.movePictureToPool_GRAPHQL}
                  onClickSetAsPreview16={({ albumId, pictureId }) => {
                    this.props
                      .setPreviewPicture({ variables: { albumId, pictureId, fsk: 16 } })
                      .then((_) => this.props.refetchAlbum())
                      .catch((err) => console.error(err));
                  }}
                  onClickSetAsPreview18={({ albumId, pictureId }) => {
                    this.props
                      .setPreviewPicture({ variables: { albumId, pictureId, fsk: 18 } })
                      .then((_) => this.props.refetchAlbum())
                      .catch((err) => console.error(err));
                  }}
                  onClickDelete={this.deletePicture_GRAPHQL}
                  onClick={this.selectPicture}
                  sequentialNumber={pictureIndex + 1}
                />
              ))}
            </div>

            {this.state.album.pictures.length === 0 && (
              <EmptyContent
                icon="icon-folder-open"
                title={_('mediamanagement:picfolder.common.noPicturesAvailable')}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(MUTATION_MODEL_MEDIA_DELETE_PICTURE, { name: 'deletePicture' }),
  graphql(MUTATION_MODEL_MEDIA_SET_PREVIEW_PICTURE, { name: 'setPreviewPicture' }),
  graphql(MUTATION_MODEL_MEDIA_MOVE_PICTURES_TO_ALBUM, { name: 'movePicturesToAlbum' })
)(withRouter(AlbumViewStandard));
