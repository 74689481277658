import styled from '@emotion/styled';
import { GRAY_3, WHITE } from '../../../camtool-styles';

interface StyledLabelProps {
  disabled: boolean;
}

export const StyledLabel = styled.label<StyledLabelProps>`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ disabled }): string => (disabled ? '' : 'cursor: pointer;')};
  background: ${WHITE};
  color: ${GRAY_3};
  border: 1px solid ${GRAY_3};
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;
