import React, { FC } from 'react';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { GRAY_2, GREEN } from '../../../camtool-styles';
import { SvgIconProps } from '@material-ui/core';

interface CheckedOptionIconProps extends SvgIconProps {
  checked: boolean;
}

const CheckableOptionIcon: FC<CheckedOptionIconProps> = ({ checked = false, ...props }) =>
  checked ? (
    <CheckCircleIcon {...props} style={{ color: GREEN }} />
  ) : (
    <RadioButtonUncheckedIcon {...props} style={{ color: GRAY_2 }} />
  );

export default CheckableOptionIcon;
