import { AboContentTypesFilter, IAbo } from '../../types';
import { AboStatusFilter } from './types';

export const filterAboList = (
  aboList: IAbo[],
  statusFilter: AboStatusFilter,
  typesFilter?: AboContentTypesFilter
): IAbo[] => {
  return aboList.filter((abo) => {
    const hasCorrectStatus = statusFilter === AboStatusFilter.active ? abo.active : !abo.active;
    const aboContentTypes = abo.benefits.map((benefit) => benefit.type.name);

    const hasCorrectContentType =
      !typesFilter ||
      (typesFilter.includedAboContentTypes
        ? typesFilter.includedAboContentTypes.filter((type) => aboContentTypes.includes(type))
            .length > 0
        : !typesFilter.excludedAboContentTypes ||
          typesFilter.excludedAboContentTypes.filter((type) => aboContentTypes.includes(type))
            .length === 0);

    return hasCorrectStatus && hasCorrectContentType;
  });
};

export const sortAboList = (aboList: IAbo[]): IAbo[] =>
  [...aboList].sort(
    (a: IAbo, b: IAbo) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
