import { useState, useEffect } from 'react';

const SECOND = 1000; // 1000 milliseconds
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

interface CountdownInterface {
  days: number;
  hours: number;
  minutes: number;
}

function getClientLocale() {
  if (typeof Intl !== 'undefined') {
    try {
      return Intl.NumberFormat().resolvedOptions().locale;
    } catch (err) {
      console.error('Cannot get locale from Intl');
    }
  }
}

const calculateDiff = (timestamp: string) => {
  const parsedDate = Date.parse(timestamp);
  const diffDate = Math.max(parsedDate - Date.now(), 0);

  if (diffDate > 0) {
    const days = Math.trunc(diffDate / DAY);
    const hours = Math.trunc((diffDate % DAY) / HOUR);
    const minutes = Math.trunc((diffDate % HOUR) / MINUTE);
    return { days, hours, minutes };
  } else return null;
};

const useCountdown = (endDate: string): CountdownInterface | null => {
  const [countdown, setCountdown] = useState<CountdownInterface | null>(null);

  useEffect(() => {
    const updateCountdown = () => setCountdown(endDate ? calculateDiff(endDate) : null);

    updateCountdown();
    const interval = setInterval(updateCountdown, 60000);

    return () => clearInterval(interval);
  }, [endDate]);

  return countdown;
};

export default useCountdown;
