import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { GRAY_2 } from '../../camtool-styles';

interface IProps {
  icon?: string;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  className?: string;
}

const Container = styled.aside`
  flex: 1 0 200px;
  justify-content: center;
  align-items: center;
  color: ${GRAY_2};
`;

const Content = styled.div`
  width: 100%;
  flex: 0 1 auto;
  flex-direction: column;
`;

const IconDiv = styled.div`
  flex: 0 0 auto;
  justify-content: center;

  &:before {
    font-size: 40px;
    padding-bottom: 15px;
  }
`;

const Title = styled.div`
  flex: 0 0 auto;
  justify-content: center;
  text-align: center;
  font-size: 19px;
`;

const Subtitle = styled.div`
  flex: 0 0 auto;
  justify-content: center;
`;

const EmptyContent: FC<IProps> = ({
  icon = 'icon-history',
  title,
  subtitle,
  className,
  children,
}) => (
  <Container className={className}>
    <Content>
      <IconDiv className={icon} />
      <Title>{title}</Title>
      <Subtitle>
        <div>{subtitle}</div>
        {children}
      </Subtitle>
    </Content>
  </Container>
);

export default EmptyContent;
export { EmptyContent };
