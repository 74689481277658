/* eslint-disable react/prop-types  */
import React from 'react';
import PropTypes from 'prop-types';
import { _ } from '../../util/translate';
import styled from '@emotion/styled';
import { BREAKPOINT_TABLET_CONDITION } from '../../camtool-styles';

const PicViewProfilePoolContainer = styled.div`
  @media ${BREAKPOINT_TABLET_CONDITION} {
    flex: 1 1 auto;
  }
`;

const DraggablePicture = ({
  position,
  pictureId,
  pictureURL,
  onClickCallback,
  isChecked,
  isRejected,
  isPlaceholder,
}) => (
  <div
    data-position={position}
    draggable={!isPlaceholder}
    className="profile-pic__view__profile__vx__foo__pool__item draggablepictures__el"
    style={pictureURL ? { backgroundImage: `url(${pictureURL})` } : {}}
    onClick={() => onClickCallback(position)}
  >
    <div className="profile-pic__view__profile__vx__foo__pool__item__change">
      <div className="profile-pic__view__profile__vx__foo__pool__item__change__icon" />
    </div>

    {pictureId && !isChecked ? (
      <div className="profile-pic__view__profile__vx__foo__pool__item__warning-sign">
        {_('mediamanagement:picfolder.contentItemStatus.inchecking')}
      </div>
    ) : (
      <div className="profile-pic__view__profile__vx__foo__pool__item__overlay">
        <div className="profile-pic__view__profile__vx__foo__pool__item__overlay__status" />
        <div className="profile-pic__view__profile__vx__foo__pool__item__overlay__name" />
        <div className="profile-pic__view__profile__vx__foo__pool__item__overlay__spacer" />
        <div className="profile-pic__view__profile__vx__foo__pool__item__overlay__lang" />
      </div>
    )}

    {isRejected && (
      <div className="profile-pic__view__profile__vx__foo__pool__item__rejected">
        <div className="profile-pic__view__profile__vx__foo__pool__item__rejected__text">
          {_('mediamanagement:picfolder.contentItemStatus.pictureRejected')}
        </div>
      </div>
    )}
  </div>
);

class DraggablePictures extends React.Component {
  static *keyGenerator(label) {
    let index = 0;
    while (true) yield `${label}-${index++}`;
  }

  constructor(props) {
    super(props);
    this.state = {
      pictures: this.props.pictures || [],
    };

    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.swapElements = this.swapElements.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);

    this.picsContainer = [];
  }

  componentDidMount() {
    Array.from(this.picsContainer.children).forEach((pic) => {
      if (pic.getAttribute('draggable') === 'false') return;
      pic.addEventListener('dragover', this.handleDragOver);
      pic.addEventListener('drop', this.handleOnDrop);
      pic.addEventListener('dragstart', this.handleDragStart);
      pic.addEventListener('dragleave', (e) =>
        e.currentTarget.classList.remove('draggablepictures__droptarget')
      );
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({ pictures: nextProps.pictures });
  }

  componentDidUpdate(prevProps, prevState) {
    Array.from(this.picsContainer.children).forEach((pic) => {
      if (pic.getAttribute('draggable') === 'false') return;

      pic.addEventListener('dragover', this.handleDragOver);
      pic.addEventListener('drop', this.handleOnDrop);
      pic.addEventListener('dragstart', this.handleDragStart);
      pic.addEventListener('dragleave', (e) =>
        e.currentTarget.classList.remove('draggablepictures__droptarget')
      );
    });
  }

  componentWillUnmount() {
    Array.from(this.picsContainer.children).forEach((pic) => {
      if (pic.getAttribute('draggable') === 'false') return;

      pic.removeEventListener('dragover', this.handleDragOver);
      pic.removeEventListener('drop', this.handleOnDrop);
      pic.removeEventListener('dragstart', this.handleDragStart);
    });
  }

  swapElements(el1, el2) {
    const { pictures } = this.state;
    this.props.movePictureTo(pictures[el1].pictureId, el2);
  }

  handleDragStart(e) {
    e.dataTransfer.setData('text', e.target.getAttribute('data-position'));
    e.dataTransfer.effectAllowed = 'move';
  }

  handleDragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    if (e.dataTransfer.effectAllowed === 'move') {
      e.currentTarget.classList.add('draggablepictures__droptarget');
    }
  }

  handleOnDrop(e) {
    e.preventDefault();
    if (e.dataTransfer.effectAllowed === 'move') {
      e.currentTarget.classList.remove('draggablepictures__droptarget');
      this.swapElements(
        e.dataTransfer.getData('text'),
        e.currentTarget.getAttribute('data-position')
      );
    }
  }

  render() {
    const { onPictureClick } = this.props;
    const { pictures } = this.state;
    const getPlaceholderKey = DraggablePictures.keyGenerator('placeholder');

    return (
      <PicViewProfilePoolContainer className="profile-pic__view__profile__vx__foo__pool">
        <div
          ref={(r) => {
            this.picsContainer = r;
          }}
          className="profile-pic__view__profile__vx__foo__pool__row"
        >
          {[null, null, null, null].map((picture, i) =>
            typeof pictures[i] === 'undefined' ? (
              <DraggablePicture
                key={getPlaceholderKey.next().value}
                isPlaceholder
                onClickCallback={onPictureClick}
                position={i}
              />
            ) : (
              <DraggablePicture
                position={i}
                key={pictures[i].pictureId}
                pictureId={pictures[i].pictureId}
                pictureURL={pictures[i].url}
                onClickCallback={onPictureClick}
                isChecked={pictures[i].isChecked}
                isRejected={pictures[i].isRejected}
              />
            )
          )}
        </div>
      </PicViewProfilePoolContainer>
    );
  }
}

DraggablePictures.propTypes = {
  pictures: PropTypes.array.isRequired,
  movePictureTo: PropTypes.func.isRequired,
  onPictureClick: PropTypes.func.isRequired,
};

export default DraggablePictures;
export { DraggablePictures };
