import * as React from 'react';
import { Notice } from '../../../../components/Notice/Notice';
import { _ } from '../../../../util/translate';

const Hintsection: React.FunctionComponent = () => {
  return (
    <Notice
      css={{ marginBottom: 0 }}
      icon="icon-warning-sign"
      text={_('benefits:voucher.texts.ticket_show.info')}
    />
  );
};

export default Hintsection;
