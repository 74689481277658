import gql from 'graphql-tag';
import { FRAGMENT_MAILING } from '../../../../graphql/VXModels/fragments';

export const QUERY_MODEL_MAILINGS_DRAFT = gql`
  query GET_DRAFT {
    model {
      mailings {
        draft @client {
          id
          releaseDate
          subject
          targetGroup
          textHTML
        }
      }
    }
  }
`;
export const QUERY_MODEL_MAILINGS_DEFAULTS = gql`
  query QUERY_MODEL_MAILINGS_DEFAULTS($group: MailingRecipientsGroupsEnum!) {
    model {
      mailings {
        recipientsFilters(group: $group) {
          options {
            label
            value
          }
          selected
        }
      }
    }
  }
`;
export const QUERY_MODEL_MAILINGS_MAILING = gql`
  query QUERY_MODEL_MAILINGS_MAILING($id: Int!) {
    model {
      mailings {
        mailing(id: $id) {
          ...Mailing
        }
      }
    }
  }
  ${FRAGMENT_MAILING}
`;
export const QUERY_MODEL_MAILINGS_FORM = gql`
  query QUERY_MODEL_MAILINGS_FORM($type: MailingTypeEnum!) {
    model {
      mailings {
        fileStorageSettings(type: $type) {
          type
          uploadEndpoint
          token
        }
        recipientsGroups(type: $type) {
          options {
            label
            value
          }
          selected
        }
      }
    }
  }
`;
