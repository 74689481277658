import { Redirect, Route } from 'react-router';
import React from 'react';
import { useUserData } from '../../util/UserData';

const defaultVerify = ({ auth: { id: userId } }) => {
  return !!userId;
};

const ProtectedRoute = ({
  component: Component,
  componentProps = {},
  redirectTo = '/login',
  verify = undefined,
  fallbackComponent: FallbackComponent = null,
  ...rest
}) => {
  const userData = useUserData();
  return (
    <Route
      {...rest}
      render={props =>
        (verify ? (
          verify(userData)
        ) : (
          defaultVerify(userData)
        )) ? (
          <Component {...props} {...componentProps} />
        ) : FallbackComponent ? (
          <FallbackComponent {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
