import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import { Box, Typography } from '@material-ui/core';
import GenericModalBoxLayout from '../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import styled from '@emotion/styled';
import { BLACK } from '../../../../camtool-styles';
import { useCancelBroadcast } from '../../hooks';
import GenericEmptyContent from '../../../../atoms/GenericEmptyContent/GenericEmptyContent';
import NewButton from '../../../../atoms/Button/NewButton';
import { Icon } from '../../../../atoms/Icon';
import { removeSign } from '../../../../atoms/Icon/libraries/glyph';

export const SpinnerContainer = styled.div`
  position: relative;
  width: 25px;
`;

export const ModalContainer = styled.div`
  flex: 1 0 auto;
  flex-direction: column;
  padding: 52px 12px;
  border-radius: 2px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${BLACK};
  min-height: 300px;
`;

interface Props {
  hide: () => void;
  broadcastId: number;
  shouldDelete?: boolean;
}

const CancelBroadcastModal: FC<Props> = ({ hide, broadcastId, shouldDelete = false }) => {
  const [cancelBroadcast, { loading, error }] = useCancelBroadcast(broadcastId, shouldDelete);
  return (
    <GenericModalBoxLayout name="cancelTelegramMail" onClose={hide} maxWidth={580}>
      <ModalContainer>
        <div css={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h3 css={{ marginBottom: '20px' }}>
            {shouldDelete
              ? _('telegram:BroadcastShow.modal.revokeHeadline')
              : _('telegram:BroadcastShow.modal.headline')}
          </h3>
          <p css={{ marginBottom: '30px', maxWidth: '60%', textAlign: 'center' }}>
            <Typography>
              {shouldDelete
                ? _('telegram:BroadcastShow.modal.revokeText')
                : _('telegram:BroadcastShow.modal.text')}
            </Typography>
          </p>
          <div>
            {error ? (
              <GenericEmptyContent />
            ) : (
              <>
                <Box mr={2}>
                  <NewButton variant={'outlined'} color={'default'} onClick={hide}>
                    {_('common:button.cancel')}
                  </NewButton>
                </Box>
                <NewButton
                  disabled={loading}
                  loading={loading}
                  icon={<Icon icon={removeSign} />}
                  color={'error'}
                  onClick={async (): Promise<void> => {
                    await cancelBroadcast(); // fires refetch which causes rerender - therefore modal is unmounted/closed
                  }}
                >
                  {shouldDelete
                    ? _('telegram:BroadcastShow.cancelBroadcast.button.delete')
                    : _('telegram:BroadcastShow.cancelBroadcast.button.cancel')}
                </NewButton>
              </>
            )}
          </div>
        </div>
      </ModalContainer>
    </GenericModalBoxLayout>
  );
};

export default CancelBroadcastModal;
