import React, { FC } from 'react';
import { createStyles, makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { useLang } from '../../../util/AppState';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const baseLangConfig = {
  thousandSeparator: false,
  decimalScale: 2,
};

const langConfig = {
  de: { ...baseLangConfig, decimalSeparator: ',' },
  en: { ...baseLangConfig, decimalSeparator: '.' },
};

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      textAlign: 'center',
      padding: 0,
    },
    // fix weird border in front of center of endAdornment either via <fieldset> with notchedOutline class or via adornedEnd class with paddingRight...
    notchedOutline: {
      right: '-6px',
    },
  })
);

export type NumberInputProps = TextFieldProps & NumberFormatProps;

const NumberInput: FC<NumberInputProps> = ({
  name,
  error,
  helperText,
  onChange,
  showDecimal = true,
  pattern = '^d+(?:[.|,]d{1,2})?$',
  ...props
}) => {
  const [lang] = useLang();
  const langProps = langConfig[lang] || langConfig.en;
  const inputClasses = useStyles();
  const format = showDecimal ? null : { format: '###' }; // '###' invalidates separators etc. and only allows numbers up to 999 (todo: pass format as prop)

  const InputProps = {
    classes: {
      notchedOutline: inputClasses.notchedOutline,
      input: inputClasses.input,
    },
  };
  const mergedInputProps = { InputProps: { ...props.InputProps, ...InputProps } };
  const mergedProps = { ...props, ...mergedInputProps };

  return (
    <NumberFormat
      name={name}
      error={error}
      helperText={helperText}
      onValueChange={({ floatValue }: number): void => onChange?.(floatValue)}
      pattern={pattern}
      {...format}
      {...mergedProps}
      type={'text'}
      lang={lang}
      customInput={TextField}
      isNumericString={true}
      fixedDecimalScale={showDecimal}
      allowedDecimalSeparators={[',', '.']}
      {...langProps}
    />
  );
};

export default NumberInput;
