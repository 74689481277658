import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../introspection-result';
import { dataIdFromObject } from './dataIdFromObject';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { ApolloClient } from 'apollo-client';
import resolvers from '../resolvers';
import defaults from '../defaults';
import { APP_IS_DEVELOPMENT, REACT_APP_VXSERVICES_TELEGRAM_HOST } from '../../../util/env';
import { concat } from 'apollo-link';

const DEBUG = APP_IS_DEVELOPMENT && true;
const uri = REACT_APP_VXSERVICES_TELEGRAM_HOST;
export const getClient = (
  context: { token: string | undefined } = { token: undefined }
): ApolloClient<NormalizedCacheObject> => {
  const cache = new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({ introspectionQueryResultData }),
    dataIdFromObject,
  });
  const httpLink = new HttpLink({uri, headers: {}}); // prettier-ignore
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 7,
      retryIf: (error, _operation) => !!error,
    },
  });
  const client = new ApolloClient({ link: concat(retryLink, httpLink), cache, resolvers });

  context.token = context.token ?? '';
  defaults.__context = { ...defaults.__context, ...context };
  cache.writeData({ data: defaults });
  client.onResetStore(() => cache.writeData({ data: defaults }));
  client.type = 'VXServicesTelegram';

  DEBUG && console.log('Apollo endpoint', client.type, uri);

  return client;
};
