import React, { FC } from 'react';
import { Button } from '../../../../atoms';
import { _ } from '../../../../util/translate';
import GenericModalBoxLayout from '../../../../components/GenericModalBoxLayout/GenericModalBoxLayout';
import Icon from '../../../../atoms/Icon/Icon';
import { remove, bin } from '../../../../atoms/Icon/libraries/glyph';
import { ModalContainer } from './styles';
import { ButtonGroup } from '../../../../components';
import { DARK_GRAY, WHITE, GRAY_3 } from '../../../../camtool-styles';

interface Props {
  deleteAutoMailRow: (index: number) => void;
  closeModal: () => void;
  index: number;
}

const AutoMailDeleteModal: FC<Props> = ({ deleteAutoMailRow, index, closeModal }) => {
  return (
    <GenericModalBoxLayout maxWidth={700} name={'delete-automail'} onClose={closeModal}>
      <ModalContainer css={{ padding: '90px 12px' }}>
        <div
          css={{
            color: DARK_GRAY,
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <h2 css={{ marginBottom: '6px' }}>{_('mailings:telegram.autoMail.delete.headline')}</h2>
          <p css={{ marginBottom: '50px' }}>{_('mailings:telegram.autoMail.delete.subHeadline')}</p>

          <ButtonGroup position={'space-between'}>
            <Button theme={'border-gray'} onClick={closeModal} css={{ marginRight: '20px' }}>
              <Icon icon={remove} size={'12px'} css={{ marginRight: '3px', color: GRAY_3 }} />
              <span>{_('common:button.cancel')}</span>
            </Button>
            <Button
              theme={'blue'}
              onClick={(): void => {
                deleteAutoMailRow(index);
                closeModal();
              }}
            >
              <Icon icon={bin} size={'12px'} css={{ color: WHITE, marginRight: '3px' }} />
              <span css={{ color: WHITE }}>{_('mailings:telegram.autoMail.delete.message')}</span>
            </Button>
          </ButtonGroup>
        </div>
      </ModalContainer>
    </GenericModalBoxLayout>
  );
};

export default AutoMailDeleteModal;
