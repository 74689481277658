import styled from '@emotion/styled';
import { RED, WHITE_2 } from '../../../../camtool-styles';

const LeftMenuItemBadge = styled.div`
  background-color: ${RED};
  color: ${WHITE_2};
  width: 15px;
  height: 15px;
  font-size: 10px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

export default LeftMenuItemBadge;
