import React, { ReactNode, ReactElement } from 'react';
import styled from '@emotion/styled';
import { DARK_GRAY, GRAY_3 } from '../../camtool-styles';

interface CheckboxProps {
  name?: string;
  label?: ReactNode;
  className?: string;
  checked: boolean;
  children?: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const StyledLabel = styled.label<Pick<CheckboxProps, 'disabled'>>`
  user-select: none;
  align-items: center;
  color: ${(props): string => (props.disabled ? GRAY_3 : DARK_GRAY)};
`;

const StyledInput = styled.input`
  align-self: center;
  margin-right: 8px;
`;

/**
 * @deprecated Use MUICheckbox instead.
 */
const Checkbox = ({ label, children, ...props }: CheckboxProps): ReactElement => (
  <StyledLabel disabled={props.disabled}>
    <StyledInput {...props} type="checkbox" />
    {label || children}
  </StyledLabel>
);

export default Checkbox;
