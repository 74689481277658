import React, { FC, ReactElement } from 'react';
import { Box, createStyles, Grid, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles/withStyles/withStyles';
import { makeStyles } from '@material-ui/styles';

type ClassKey = 'root';

const iconSize = 36;

interface StatProps {
  classes?: Partial<ClassNameMap<ClassKey>>;
  icon: ReactElement;
  label: string;
  value: string | number | undefined;
  footer?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      '& .MuiSvgIcon-root': {
        fontSize: iconSize,
      },
    },
    footer: {
      marginTop: -16,
    },
  })
);

const Stat: FC<StatProps> = ({ classes, icon, label, value, footer }) => {
  const { icon: iconClass, footer: footerClass } = useStyles();
  return (
    <Grid classes={{ root: classes?.root }} container spacing={2}>
      {icon && (
        <Grid item classes={{ root: iconClass }}>
          <Box
            width={iconSize}
            height={'100%'}
            paddingLeft={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {icon}
          </Box>
        </Grid>
      )}
      <Grid item container direction={'column'} xs>
        <Grid item>
          <Typography variant="subtitle1">{label}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2">{value}</Typography>
        </Grid>
        <Grid item>
          {footer && (
            <Typography classes={{ root: footerClass }} variant={'subtitle2'} align={'right'}>
              {footer}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Stat;
