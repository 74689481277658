import React, {FC} from 'react';
import qs from 'query-string';
import {Redirect, RouteComponentProps} from 'react-router';
import {APP_BASE_PATH, APP_IS_DEVELOPMENT, APP_IS_STAGE} from '../../util/env';
import {useAppState} from '../../util/AppState';
import LoginFormView from './components/LoginFormView';
import ExternalRedirect from '../../components/Routing/ExternalRedirect';
import {getStartpageLoginUrl} from '../../util/startpage';

export const LOGIN_PATH = `${APP_BASE_PATH}/login`;
export const LOGOUT_PATH = `${APP_BASE_PATH}/logout`;

const sanitizeRedir = (redir?: string): string | undefined =>
    redir?.startsWith(LOGIN_PATH) || redir?.startsWith(LOGOUT_PATH) ? APP_BASE_PATH : redir;

const Login: FC<RouteComponentProps> = ({location: {search}}) => {
    const [{authToken, lang}] = useAppState();
    const queryParams = qs.parse(search, {ignoreQueryPrefix: true});
    const sanitizedRedir = sanitizeRedir(queryParams.redir);

    return authToken ? (
        <Redirect to={sanitizedRedir || `${APP_BASE_PATH}/`}/>
    ) : APP_IS_DEVELOPMENT || APP_IS_STAGE ? (
        <LoginFormView/>
    ) : (
        <ExternalRedirect to={getStartpageLoginUrl(lang, sanitizedRedir)}/>
    );
};

export default Login;
