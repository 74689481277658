import styled from '@emotion/styled';
import { css } from '@emotion/core';
import React, { memo } from 'react';
import { BLACK_1, RED, RED_HOVER, WHITE } from '../../../camtool-styles';

const inputStyleBlue = css`
display: none;
  &:checked {
    & + div {
      background-color: #1f93e9;
      color: #fff;
      &:hover {
        background-color: #3bacf7;
      }
    }
`;

const inputStyleRed = css`
display: none;
  &:checked {
    & + div {
      background-color: ${RED};
      color: ${WHITE};
      &:hover {
        background-color: ${RED_HOVER};
      }
    }
`;

const StyledInput = styled.input``;

const StyledDiv = styled('div')`
  padding: 0 24px;
  background-color: #fff;
  color: ${BLACK_1};
  height: 32px;
  border: 1px solid #c0c0c0;
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #e5e5e5;
  }
`;

interface IProps {
  name: string /** Controled by Formik  */;
  value: string /** Controled by Formik  */;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void /** Controled by Formik  */;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void /** Controled by Formik  */;
  text: string;
  checked: boolean;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  activeTheme?: 'RED' | 'BLUE';
}

// prettier-ignore
const RadioField = ({ name, value, onChange, onBlur, text, checked, children, className, disabled, activeTheme }: IProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event);

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(typeof  onBlur === "function") onBlur(event);
  };

  return (
    <label className={className} css={{ whiteSpace: 'nowrap' }}>
      <StyledInput
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        checked={checked}
        type="radio"
        disabled={disabled}
        css={activeTheme === 'RED' ? inputStyleRed : inputStyleBlue}
      />
      <StyledDiv css={{ borderColor: checked ? 'transparent' : '#c0c0c0' }}>{text || children}</StyledDiv>
    </label>
  );
};

export default memo(RadioField);
