import AppDispatcher from '../../util/dispatcher/AppDispatcher';
import React from 'react';
import update from 'immutability-helper';
import EventEmitter from 'events';
import ActionTypes from '../../util/constants/ActionTypes';

let CHANGE_EVENT = 'OnlineTimeAndUsersStore.change';

let storeData = {
  statDetails: [],
  selectedChatTime: 0,
  selectedUsers: 0,
};

class OnlineTimeAndUsersStoreClass extends EventEmitter {
  emitChange() {
    this.emit(CHANGE_EVENT);
  }
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }
  get() {
    return storeData;
  }
}

const OnlineTimeAndUsersStore = new OnlineTimeAndUsersStoreClass();

AppDispatcher.register(function(payload) {
  switch (payload.type) {
    case ActionTypes.APPS_ONLINETIMEANDUSERS_RECEIVE_DATA:
      storeData = update(storeData, { $merge: payload.data });
      OnlineTimeAndUsersStore.emitChange();
      break;
    default:
  }
});

export { OnlineTimeAndUsersStore };
