import { Entry } from './Entry';

export class QueueHelper {
  /**
   *
   * @param checkEntry
   * @param queue
   * @returns {boolean}
   */
  public static isEntryInQueue(checkEntry: Entry, queue: Entry[]): boolean {
    for (const entry of queue) {
      if (
        (checkEntry.file.type && entry.file === checkEntry.file) ||
        (entry.file.name === checkEntry.file.name && entry.binaryLength === checkEntry.binaryLength)
      ) {
        return true;
      }
    }

    return false;
  }
}
