import React, { FC } from 'react';
import ChannelItem from './ChannelItem';
import { User } from '../../../../../graphql/VXServicesTelegram/types';
import { WHITE, GRAY_2, GRAY_3 } from '../../../../../camtool-styles';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { _ } from '../../../../../util/translate';

const List = styled.div`
  overflow-y: auto;
  flex-direction: column;
  border-right: 1px solid ${GRAY_3};
  width: 320px;
  background-color: ${WHITE};
  min-height: 100%;
`;

interface IProps {
  selectedUser: User;
  path: string;
  currency: string | undefined;
  users: Array<User>;
}

const ChannelList: FC<IProps> = ({ selectedUser, currency, path, users }) => {
  if (Array.isArray(users) && users.length > 0) {
    const selectedUserId = selectedUser ? selectedUser.id || 0 : 0;

    return (
      <List>
        <div
          css={{
            flexDirection: 'column',
            overflowY: 'auto',
            overflowX: 'hidden',
            // reduce scrollbar width for firefox since scrollbar.scss style only applies to chrome
            scrollbarWidth: 'thin',
          }}
        >
          {users.map((user: User, idx: number) => {
            const isLastChannel = idx === users.length - 1;

            return (
              <ChannelItem
                key={user.id}
                path={path}
                order={idx}
                isLastChannel={isLastChannel}
                currency={currency}
                active={selectedUserId === user.id}
                {...user}
              />
            );
          })}
        </div>
      </List>
    );
  } else
    return (
      <List>
        <Box p={5} textAlign="center">
          <span css={{ color: GRAY_2 }}>{_('telegram:channelList.empty')}</span>
        </Box>
      </List>
    );
};

export default ChannelList;
