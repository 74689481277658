import { useQuery } from '@apollo/react-hooks';

import { QUERY_MASTER } from '../graphql/queries';
import { useVXServicesTelegramClient } from '../../../graphql';
import { useVXServicesTelegramContext } from './index';

export const useTelegramUser = () => {
  const { token } = useVXServicesTelegramContext();
  const { data, ...rest } = useQuery(QUERY_MASTER, {
    client: useVXServicesTelegramClient(),
    variables: { token },
  });

  return { data: data || {}, ...rest };
};

export default useTelegramUser;
