import { useLazyQuery } from '@apollo/react-hooks';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BLACK_2, GRAY_2, RED, WHITE } from '../../../camtool-styles';
import { QUERY_AUTH_DEEPLINK_BETA_FEED } from '../../../graphql/VXModels/queries';
import { Query, VXModelsMobileTargetEnum } from '../../../graphql/VXModels/types';
import NewVXMSwitch from '../../../routes/Dashboard/Notifications/NewVXMSwitch';
import { REACT_APP_BASE_PATH as basePath } from '../../../util/env';
import { _ } from '../../../util/translate';
import GenericModalBoxLayout from '../../GenericModalBoxLayout/GenericModalBoxLayout';
import FeedIntro from './assets/images/Feed-Intro-1.png';
import FeedPosting from './assets/images/Feed-Posting.png';

interface IProps {
  onClickCloseModal: () => void;
}

const useIsForbiddenModalTermsRoute = (): boolean => {
  const matches = [
    useRouteMatch(`${basePath}/legal/terms`),
    useRouteMatch(`${basePath}/legal/gdpr`),
    useRouteMatch(`${basePath}/legal/houserule`),
    useRouteMatch(`${basePath}/legal/imprint`),
    useRouteMatch(`${basePath}/legal/terms-of-use`),
  ];

  return matches.reduce<boolean>((accumulator, match) => accumulator || !!match, false);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500,
    background: BLACK_2,
    color: WHITE,
    borderRadius: '5px',
  },
  gridNav: {
    paddingTop: 4,
  },
  gridNavHeading: {
    paddingTop: 16,
    paddingLeft: 16,
    paddingBottom: 16,
    color: RED,
  },

  gridContentHeading: {
    paddingTop: 16,
    paddingBottom: 8,
  },
  gridContentParagraph: {
    paddingBottom: 10,
  },
  gridNavFeature: {
    color: GRAY_2,
    marginLeft: '12px',
    marginRight: '12px',
    paddingLeft: 12,
    fontSize: '16px',
    borderLeft: '4px solid transparent',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gridNavFeatureActive: {
    color: WHITE,
    marginLeft: 12,
    paddingLeft: 12,
    fontSize: '16px',
    borderLeft: `4px solid ${WHITE}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  gridItem: {
    background: WHITE,
    color: BLACK_2,
    borderRadius: '0 5px 5px 0',
    padding: '16px',
  },
  gridItemTablet: {
    background: WHITE,
    color: BLACK_2,
    borderRadius: '0 0 5px 5px',
    padding: '16px',
  },
  gridFeatureHeading: {
    paddingTop: 16,
    paddingBottom: 16,
    color: RED,
  },
  gridFeatureHeadingMobile: {
    paddingTop: 16,
    paddingBottom: 16,
    margin: '8px 12px 0 12px',
    color: RED,
  },
  gridImage: {
    width: 504,
  },
  gridImageTablet: {
    width: '100%',
  },
  featureDescriptionMobile: {
    margin: '8px 12px 0 12px',
  },
  button: {
    color: WHITE,
    marginTop: 16,
    marginBottom: 16,
  },
  buttonFirst: {
    marginRight: 15,
    marginTop: 16,
    marginBottom: 16,
  },
  buttongroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    heigth: 'auto',
    justifyContent: 'flex-end',
    color: RED,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const ModalNewFeatures: FC<IProps> = ({ onClickCloseModal }) => {
  const isForbiddenModalRoute = useIsForbiddenModalTermsRoute();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const [featureName, setFeatureName] = useState<string>('feature1');
  const [deepLinkFeed, setDeepLinkFeed] = useState('');
  const classes = useStyles();

  const [getDeepLinkFeed, { loading }] = useLazyQuery<Query>(QUERY_AUTH_DEEPLINK_BETA_FEED, {
    fetchPolicy: 'network-only',
    variables: { target: VXModelsMobileTargetEnum.feed },
    onCompleted: (res) => {
      const link = res?.auth?.betaDeeplink || '';
      setDeepLinkFeed(link);
    },
  });

  const nextStep = () => {
    getDeepLinkFeed();
    setFeatureName('feature3');
  };

  // Content for "New Feature Information"
  const features = [
    {
      name: 'feature1',
      title: <>{_('modalbox:featureModal.featureOne.title')}</>,
      content: (
        <>
          <img src={FeedIntro} css={{ width: '100%' }} alt="" />
          <Typography variant="h3" className={classes.gridContentHeading}>
            {_('modalbox:featureModal.featureOne.title')}
          </Typography>
          <Typography variant="body1" className={classes.gridContentParagraph}>
            {_('modalbox:featureModal.featureOne.description')}
          </Typography>
          <Typography variant="body1">
            {_('modalbox:featureModal.featureOne.descriptionTwo')}
          </Typography>
          <Box className={classes.buttongroup}>
            <Button className={classes.buttonFirst} onClick={() => setFeatureName('feature2')}>
              {_('common:button.next')}
            </Button>
          </Box>
        </>
      ),
    },
    {
      name: 'feature2',
      title: 'VXModels new Design',
      content: (
        <>
          <NewVXMSwitch hideButton={true} />
          <Box className={classes.buttongroup}>
            <Button className={classes.button} onClick={nextStep}>
              {_('common:button.next')}
            </Button>
          </Box>
        </>
      ),
    },
    {
      name: 'feature3',
      title: <>{_('modalbox:featureModal.featureTwo.title')}</>,
      content: (
        <>
          <img src={FeedPosting} css={{ width: '100%' }} alt="" />
          <Typography variant="h3" className={classes.gridContentHeading}>
            {_('modalbox:featureModal.featureTwo.title')}
          </Typography>
          <Typography variant="body1" className={classes.gridContentParagraph}>
            {_('modalbox:featureModal.featureTwo.description')}
          </Typography>
          <Typography variant="body1">
            {_('modalbox:featureModal.featureOne.descriptionTwo')}
          </Typography>

          <Box className={classes.buttongroup}>
            <Button variant="outlined" className={classes.buttonFirst} onClick={onClickCloseModal}>
              {_('common:button.close')}
            </Button>
            <Button
              className={classes.button}
              href={deepLinkFeed}
              target={'_blank'}
              onClick={onClickCloseModal}
              disabled={loading && !deepLinkFeed}
            >
              {_('modalbox:featureModal.button.primary')}
            </Button>
          </Box>
        </>
      ),
    },
  ];

  const renderFeatureDescription = features.map((feature) => {
    if (feature.name === featureName) {
      return (
        <Box key={feature.name}>
          <Box className={isMobile ? classes.featureDescriptionMobile : ''}>{feature.content}</Box>
        </Box>
      );
    }
  });

  const featureList = features.map((feature) => {
    return (
      <Box
        key={feature.name}
        onClick={() => setFeatureName(feature.name)}
        className={
          featureName === feature.name ? classes.gridNavFeatureActive : classes.gridNavFeature
        }
        mb={2}
      >
        {feature.title}
      </Box>
    );
  });

  return (
    <>
      {!isForbiddenModalRoute && (
        <GenericModalBoxLayout
          name={'new-feature'}
          maxWidth={779}
          onClose={onClickCloseModal}
          zIndex={999999}
          hasTransparentBackground={true}
        >
          <Grid container className={classes.root}>
            <Grid item sm={12} md={4} className={classes.gridNav}>
              <Typography className={classes.gridNavHeading} variant="h2">
                New Features
              </Typography>
              {/* renders Feature "Nav" */}
              {!isMobile && features && featureList}
            </Grid>
            <Grid
              item
              sm={12}
              md={8}
              className={isTablet ? classes.gridItemTablet : classes.gridItem}
            >
              {/* renders Feature "Description" */}
              {renderFeatureDescription}
            </Grid>
          </Grid>
        </GenericModalBoxLayout>
      )}
    </>
  );
};

export default ModalNewFeatures;
