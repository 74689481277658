import React, { FC, ReactElement } from 'react';
import { Field, FieldProps } from 'formik';
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import { _ } from '../../../../../util/translate';
import FancyCheckbox from '../../../../../atoms/FancyCheckbox/FancyCheckbox';

const FieldAutoRenew: FC = () => {
  return (
    <Field
      name="autoRenew"
      components
      render={({ field }: FieldProps): ReactElement => {
        return (
          <div css={{ marginBottom: 24 }}>
            <FieldLayout label={_('benefits:abo.detailsPage.renewalLabel')} error="">
              <FancyCheckbox
                {...field}
                checked={field.value}
                label={_('benefits:abo.detailsPage.renewalInfo')}
              />
            </FieldLayout>
          </div>
        );
      }}
    />
  );
};

export default FieldAutoRenew;
