import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
// Outside Wizard
import { BLACK_1, GRAY_3 } from '../../../../camtool-styles';

const StyledTextarea = styled('textarea')`
  padding: 10px;
  width: 100%;
  background-color: #fff;
  color: ${BLACK_1};
  border: 1px solid ${GRAY_3};
  border-radius: 4px;
  resize: none;
`;

interface FormikTextareaProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  toolbar?: (name?: string, value?: string) => ReactElement;
  placeholder?: string;
  showEditIcon?: boolean;
  className?: string;
}

const FormikTextarea: FC<FormikTextareaProps> = (props) => (
  <div css={{ flexDirection: 'column', position: 'relative' }}>
    {typeof props.toolbar === 'function' ? props.toolbar(props.name, props.value) : null}

    <StyledTextarea
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      rows={10}
      className={props.className}
    />

    {props.showEditIcon && (
      <span
        className="icon-edit"
        css={{
          position: 'absolute',
          right: 6,
          bottom: 8,
          color: '#c0c0c0',
        }}
      />
    )}
  </div>
);

export default FormikTextarea;
