import React, { FC } from 'react';
import { useAppState } from '../../util/AppState';
import { format as formatFunc, formatRelative, parseJSON } from 'date-fns';
import { de, enGB } from 'date-fns/locale';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';

enum FormatEnum {
  DATETIME = 'datetime',
  DATE = 'date',
}

interface IProps {
  value: string;
  relative?: boolean;
  format?: FormatEnum | string;
}

const mapping = {
  de: {
    datetime: 'Pp',
    date: 'dd.MM.yyyy',
    locale: de,
  },
  en: {
    datetime: 'Pp',
    date: 'dd/MM/yyyy',
    locale: enGB,
  },
};

const doFormat = (value: string, relative = false, format: string = FormatEnum.DATETIME) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [{ lang }] = useAppState();
  const langMapping = mapping[lang];
  const formatStr = langMapping[format] || format;

  try {
    if (relative) {
      return formatRelative(parseJSON(value), new Date(), { locale: langMapping.locale });
    }

    return formatFunc(parseJSON(value), formatStr, { locale: langMapping.locale });
  } catch (err) {
    console.error(err);
    return value;
  }
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      wordBreak: 'normal',
    },
  })
);
const DateTimeFormatter: FC<IProps> = ({
  value,
  relative = false,
  format = FormatEnum.DATETIME,
}) => {
  const classes = useStyles();
  return <Box className={classes.root}>{doFormat(value, relative, format)}</Box>;
};

export default DateTimeFormatter;
export { doFormat as format };
