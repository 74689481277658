import styled from '@emotion/styled';

export const PromptBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  user-select: none;
  cursor: default;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
`;
