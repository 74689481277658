import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import Range from '../../../atoms/Range/Range';
import { T } from '../../../components';
import debounce from '../../../util/debounce';

import { APP_BASE_PATH } from '../../../util/env';
import { _ } from '../../../util/translate';
import { ApiLang } from '../../../graphql/VXModels/types';
import { Content, Header } from '../WidgetsMedium/Layout';

const Container = styled.div`
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

interface IPrice {
  videoChatMinPrice: number;
  videoChatMaxPrice: number;
  videoChatUserPrice: number;
  singleChatMinPrice: number;
  singleChatMaxPrice: number;
  singleChatUserPrice: number;
}

interface IVariables { variables: { videoChatUserPrice?: number; singleChatUserPrice?: number; referrer?: string } } // prettier-ignore

interface IProps { loading: boolean; language: ApiLang, prices: IPrice; changePriceMutation: (variables: IVariables) => void; mutationInProgress: boolean; disabled: boolean; small: false; } // prettier-ignore

class ChatSettings extends React.PureComponent<IProps> {
  public static defaultProps: Partial<IProps> = {
    loading: false,
    language: ApiLang.de,
    disabled: true,
  };

  public state = { loadingVideoChatPriceChange: false, loadingSingleChatChange: false };

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.mutationInProgress === true && this.props.mutationInProgress === false) {
      this.setState({ loadingVideoChatPriceChange: false, loadingSingleChatChange: false });
    }
  }

  public handleVideoChatPriceChange = (value: number) => {
    this.setState({ loadingVideoChatPriceChange: true });
    this.props.changePriceMutation({
      variables: { videoChatUserPrice: value, referrer: 'dashboard' },
    });
  };

  public handleSingleChatChange = (value: number) => {
    this.setState({ loadingSingleChatChange: true });
    this.props.changePriceMutation({
      variables: { singleChatUserPrice: value, referrer: 'dashboard' },
    });
  };

  public render() {
    const prices = this.props.prices || {};
    return (
      <Container>
        {this.props.loading ? (
          <Fragment></Fragment>
        ) : (
          <Fragment>
            <h3 css={{ fontSize: 18, textAlign: 'center' }}>
              <T _={'dashboard:app.chatSettings.title'} />
            </h3>

            <div css={{ flexDirection: 'column', padding: '16px 32px', width: '100%' }}>
              <div
                css={{
                  flexDirection: 'column',
                  margin: '8px 0',
                  opacity: this.state.loadingVideoChatPriceChange || this.props.disabled ? 0.5 : 1,
                }}
              >
                <p css={{ fontSize: 14 }}>
                  <T _={'dashboard:app.chatSettings.videoChatPrice'} />
                </p>
                <Range
                  language={this.props.language}
                  initialValue={prices.videoChatUserPrice}
                  min={prices.videoChatMinPrice}
                  max={prices.videoChatMaxPrice}
                  step={0.01}
                  label="€"
                  // user can click and hold slider still for 1 second before firing mutation
                  onChange={debounce(this.handleVideoChatPriceChange, 1000)}
                  disabled={this.state.loadingVideoChatPriceChange || this.props.disabled}
                />
              </div>

              <div
                css={{
                  flexDirection: 'column',
                  margin: '8px 0',
                  opacity: this.state.loadingSingleChatChange || this.props.disabled ? 0.5 : 1,
                }}
              >
                <p css={{ fontSize: 14 }}>
                  <T _={'dashboard:app.chatSettings.privateChatPrice'} />
                  <br />
                  {!this.props.small && (
                    <small css={{ color: '#8e8e8e' }}>
                      <T _={'dashboard:app.chatSettings.privateChatDescription'} />
                    </small>
                  )}
                </p>
                <Range
                  language={this.props.language}
                  initialValue={prices.singleChatUserPrice}
                  min={prices.singleChatMinPrice}
                  max={prices.singleChatMaxPrice}
                  step={0.01}
                  label="€"
                  // user can click and hold slider still for 1 second before firing mutation
                  onChange={debounce(this.handleSingleChatChange, 1000)}
                  disabled={this.state.loadingSingleChatChange || this.props.disabled}
                />
              </div>
            </div>
            <ButtonContainer>
              <p css={{ marginTop: 4, color: '#8e8e8e', textAlign: 'center' }}>
                <Link to={`${APP_BASE_PATH}/settings/chatsettings`} css={{ color: '#8e8e8e' }}>
                  <T _={'dashboard:app.chatSettings.otherSettings'} />
                </Link>
              </p>
            </ButtonContainer>
          </Fragment>
        )}
      </Container>
    );
  }
}

export default ChatSettings;
