import AbstractFormMultiOptionsComponent from '../AbstractFormMultiOptionsComponent';
import React from 'react';
import { _ } from '../../../../util/translate';

class OptionBoxRadioGroups extends AbstractFormMultiOptionsComponent {
  changeValue(event) {
    let groupIdx = parseInt(event.currentTarget.name.replace(/.+\[(\d+)\]$/, '$1'));
    let values = [];
    if (this.getValue()) {
      values = this.getValue().slice(0);
    }
    Object.keys(this.props.options[groupIdx]).forEach(function(value) {
      let idx = values.indexOf(value);
      if (idx > -1) {
        values.splice(idx, 1);
      }
    });
    values.push(event.currentTarget.value);
    this.setValue(values);

    if (this.props.onChange) {
      this.props.onChange(event, values);
    }
  }

  renderChoiceBox(value, label, name, groupIdx, boxIdx) {
    let render = [];
    let values = this.getValue();

    if (boxIdx > 0) {
      render.push(
        <div key={groupIdx + '.or' + boxIdx} className="or-text">
          <span>oder</span>
        </div>
      );
    }

    render.push(
      <div key={groupIdx + '.' + boxIdx} className="eintrag">
        <input
          type="radio"
          name={name}
          value={value}
          id={name + '_' + boxIdx}
          onChange={this.changeValue}
          checked={values ? values.indexOf(value) > -1 : false}
        />
        <label htmlFor={name + '_' + boxIdx}>
          <span>{_(label)}</span>
        </label>
      </div>
    );

    return render;
  }

  renderGroup(groupData, identifier, groupIndex) {
    let render = [];
    let fieldName = identifier + '[' + groupIndex + ']';
    let boxIdx = 0;

    for (let value in groupData) {
      if (groupData.hasOwnProperty(value)) {
        render.push(this.renderChoiceBox(value, groupData[value], fieldName, groupIndex, boxIdx));
        boxIdx++;
      }
    }
    return render;
  }

  render() {
    return (
      <div className={'option-box-radio-groups-std ' + this.getClassNames()}>
        {this.props.options.map((groupData, key) => {
          return this.renderGroup(groupData, this.props.name, key);
        })}
      </div>
    );
  }
}

export default OptionBoxRadioGroups;
export { OptionBoxRadioGroups };
