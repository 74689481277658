/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import AbstractFormMultiOptionsComponent from '../AbstractFormMultiOptionsComponent';
import MaxValueLength from '../../Display/MaxValueLength/MaxValueLength';
import { InputfieldAutocomplete, Button } from '../../../index';
import { _ } from '../../../../util/translate';
import styled from '@emotion/styled';
import {
  BREAKPOINT_DESKTOP_CONDITION,
  BREAKPOINT_TABLET_CONDITION,
} from '../../../../camtool-styles';

const TagListInputAutoCompleteInput = styled.div`
  @media ${BREAKPOINT_DESKTOP_CONDITION} {
    flex-wrap: wrap;
  }
`;

class TagListInputAutocomplete extends AbstractFormMultiOptionsComponent {
  componentWillMount() {
    super.componentWillMount();
  }

  onTagChange(tag) {
    if (TagListInputAutocomplete.debug)
      console.log(this.__proto__.constructor.name, 'onTagChange', tag);
  }

  onTagSelect(tag) {
    this.addValue(tag);
  }

  onClickAdd(ev) {
    ev.stopPropagation();
    const autocomplete = this.refs.autocomplete;
    autocomplete.deleteList();
    const addValue = autocomplete.node.value.toString();
    if (addValue) {
      let commaFound = addValue.indexOf(',');
      if (commaFound < 0) {
        this.addValue(addValue);
      } else {
        const thisTags = addValue.split(',');
        for (let i = 0; i < thisTags.length; i++) {
          let tag = thisTags[i].trim();
          if (tag) {
            this.addValue(tag);
            break;
          }
        }
      }
      autocomplete.node.value = '';
    }
  }

  isDisabled() {
    return this.getValue().length >= parseInt(this.props.maxLength);
  }

  render() {
    const { maxLength, autocompleteUrl, placeholder } = this.props;
    const selectedTags = this.getValue();
    const maxReached = maxLength > 0 && selectedTags.length >= maxLength;
    const labelAddButton =
      this.props.labelAddButton || _('components:tagListInputAutocomplete.labelAddButton');

    return (
      <div
        css={{ marginBottom: 8 }}
        className={cc([
          'taglistinputautocomplete',
          { 'maxvaluelength-anchorframe': maxLength > 0 },
          this.getClassNames(),
        ])}
      >
        {maxLength > 0 && <MaxValueLength min={0} max={maxLength} value={selectedTags.length} />}

        <div>
          <TagListInputAutoCompleteInput className="taglistinputautocomplete__input">
            <div className="taglistinputautocomplete__input__autocomplete">
              <InputfieldAutocomplete
                /* eslint-disable-next-line react/no-string-refs */
                ref="autocomplete"
                autocompleteUrl={autocompleteUrl}
                placeholder={placeholder}
                onChange={this.onTagChange}
                onSelect={this.onTagSelect}
              />
            </div>

            <div className="taglistinputautocomplete__input__button">
              <Button
                label={labelAddButton}
                type="button"
                onClick={this.onClickAdd}
                disabled={this.isDisabled()}
              />
            </div>
          </TagListInputAutoCompleteInput>

          <div className="tag-list">
            {selectedTags.map((tag) => (
              <div key={tag}>
                <div>
                  <div>{tag}</div>
                  <div className="icon-remove" onClick={this.removeValue.bind(this, tag)} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

TagListInputAutocomplete.debug = false;

TagListInputAutocomplete.propTypes = {
  autocompleteUrl: PropTypes.string.isRequired,
  labelAddButton: PropTypes.string,
};

export default TagListInputAutocomplete;
export { TagListInputAutocomplete };
