import { Translation } from 'vanilla-cookieconsent';
import { ConsentCategoryEnum } from './types';

const urlDataProtection = '/camtool/legal/gdpr';
const de: Translation = {
  consentModal: {
    title: 'Wir nutzen Cookies!',
    description: `Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website so angenehm wie möglich zu gestalten und um unser Produkt zu verbessern. Weitere Informationen über unsere Datenerfassungspraktiken finden Sie in unserer <a aria-label="Cookie policy" class="cc-link" href="${urlDataProtection}" target="_blank">Datenschutzerklärung</a>.
        <br> <br> Sie können entscheiden welche Cookies Sie zulassen oder ablehnen. Klicken Sie auf "Einstellungen", um eine Liste aller Kategorien angezeigt zu bekommen. Durch Klicken auf "Alle Akzeptieren" erklären Sie sich mit der Verwendung der Cookies einverstanden.`,
    acceptAllBtn: 'Alle annehmen',
    showPreferencesBtn: 'Einstellungen',
  },
  preferencesModal: {
    title: 'Cookie-Einstellungen',
    acceptAllBtn: 'Alle annehmen',
    acceptNecessaryBtn: 'Alle ablehnen',
    savePreferencesBtn: 'Speichern',
    closeIconLabel: 'Fenster schließen',
    sections: [
      {
        title: 'Cookie Nutzung',
        description: 'Unsere Cookies fallen in folgende Kategorien',
      },
      {
        title: 'Essentielle Cookies',
        description:
          'Diese Cookies sind notwendig für den einwandfreien technischen Betrieb der Webseite.',

        //this field will generate a toggle linked to the 'necessary' category
        linkedCategory: ConsentCategoryEnum.necessary,
      },
      {
        title: 'Funktionale Cookies',
        description:
          'Diese Cookies verbessern die Nutzererfahrung auf unserer Website, indem sie sich deine bevorzugten Einstellungen merken, wie zB. die Länderauswahl.',
        linkedCategory: ConsentCategoryEnum.functional,
      },
      {
        title: 'Analyse und Optimierung',
        description:
          'Diese Cookies werden verwendet um die Nutzung unserer Website mit zu analysieren und verschiedene Optimierungen zu testen, so dass sich unser Produkt stets weiter entwickelt.',
        linkedCategory: ConsentCategoryEnum.analytics,
      },
      {
        title: 'Mehr Informationen',
        description: `Für weitere Informationen zum Thema Datenschutz, lies unsere <a href="${urlDataProtection}" target="_blank">Datenschutzrichtlinien</a>.`,
      },
    ],
  },
};
export default de;
