import React from 'react';
import PropTypes from 'prop-types';

import AbstractComponent from '../../../AbstractComponent';

class RangeTextField extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = { typedText: this.props.value };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.updateText(nextProps.value);
    }
  }

  handleOnChange(event) {
    this.updateText(event.target.value);
  }

  handleOnKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newVal = this.props.onEnterPress(this.state.typedText);
      if (newVal) this.updateText(newVal);
    }
  }

  handleOnBlur(event) {
    const newVal = this.props.onEnterPress(this.state.typedText);
    if (newVal) this.updateText(newVal);
  }

  updateText(value) {
    this.setState({ typedText: value });
  }

  render() {
    return (
      <input
        value={this.state.typedText}
        onChange={this.handleOnChange}
        className={`range-text-field ${this.props.className}`}
        placeholder={this.props.placeholder}
        size={this.props.size}
        type={this.props.type}
        name={this.props.name}
        disabled={this.props.disabled || this.props.disabledInputOnly}
        onKeyPress={this.handleOnKeyPress}
        onBlur={this.handleOnBlur}
      />
    );
  }
}

RangeTextField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOf(['text', 'password']),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  disabledInputOnly: PropTypes.bool,
};

RangeTextField.defaultProps = {
  value: '',
  onBlur: () => {},
  placeholder: '',
  className: '',
  size: 20,
  type: 'text',
  name: '',
  disabled: false,
  disabledInputOnly: false,
};

export default RangeTextField;
export { RangeTextField };
