import {
  BLACK_1,
  DARK_GRAY,
  GRAY_3,
  GRAY_ULTRATHIN,
  RED,
  WHITE,
  WHITE_2,
} from '../../../camtool-styles';
import { Override } from './types';

export const MuiOutlinedInput: Override<'MuiOutlinedInput'> = {
  root: {
    backgroundColor: WHITE,
    minHeight: 32,
    color: DARK_GRAY,
    borderColor: GRAY_3,
    '&$disabled': {
      backgroundColor: WHITE_2,
    },
    '&$disabled $notchedOutline': {
      borderColor: GRAY_ULTRATHIN,
    },
    '&$focused $notchedOutline': {
      borderWidth: 1,
      borderColor: BLACK_1,
    },
    '&:hover $notchedOutline': {
      borderColor: BLACK_1,
    },
    '&$error': {
      color: RED,
      '& div.MuiAvatar-root': {
        backgroundColor: RED,
      },
    },
    '&$error $notchedOutline': {
      borderColor: RED,
    },
  },
  notchedOutline: {
    borderColor: GRAY_3,
  },
  input: {
    padding: '0 10px',
  },
  adornedEnd: {
    paddingRight: '8px',
  },
};
