import React, { Fragment, useState } from 'react';
import {
  ErrorBox,
  Form,
  Input,
  Label,
  SuccessBox,
  WindowCompilation,
} from '../../components/Layout/DarkCentered';
import { MUTATION_AUTH_SET_PASSWORD } from '../../graphql/VXModels/mutations';
import { QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD } from '../../graphql/VXModels/queries';
import { stripSlash } from '../../util/urlHelper';
import ButtonLink from '../../atoms/ButtonLink/ButtonLink';
import { Translation } from '../../components';
import { APP_BASE_PATH } from '../../util/env';
import { withRouter } from 'react-router';
import Button from '../../atoms/Button/Button';
import { RED } from '../../camtool-styles';
import { _ } from '../../util/translate';
import { ResponseStatus, SignupField } from '../../graphql/VXModels/types';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import ValidationErrorMessage from './ValidationErrorMessage';

const NewPasswordForm = ({
  match: {
    url,
    params: { hash },
  },
}) => {
  const path = stripSlash(url);
  const [validationErrors, setValidationErrors] = useState([]);
  const [setNewPassword, { loading, data }] = useMutation(MUTATION_AUTH_SET_PASSWORD);
  const apolloClient = useApolloClient();

  const internalErrorMessage = _('common:error.generic');
  const password1Errors = validationErrors.filter(error => error.field === 'password1');
  const passwordConfirmErrors = validationErrors.filter(error => error.field === 'passwordConfirm');

  const setPassword = async password => {
    try {
      await setNewPassword({
        variables: { hash, password },
      });
    } catch (errors) {
      return [{ message: internalErrorMessage }];
    }
  };

  const doBackendValidation = async password => {
    try {
      const { data, errors } = await apolloClient.query({
        query: QUERY_VALIDATION_VALIDATE_SIGNUP_FIELD,
        variables: { field: SignupField.password, value: password },
      });

      const validationData = data?.validation?.validateSignupField;
      if (!validationData?.isValid) {
        return validationData.messages.map(message => ({ field: 'password1', message }));
      } else if (errors) {
        return [{ message: internalErrorMessage }];
      } else {
        return [];
      }
    } catch (errors) {
      return [{ message: internalErrorMessage }];
    }
  };

  const validate = async (password, passwordConfirm) => {
    if (password !== passwordConfirm) {
      return [{ field: 'passwordConfirm', message: _('passwordreset:error.userpwnotidentical') }];
    }

    return await doBackendValidation(password);
  };

  const doSubmit = async (password, passwordConfirm) => {
    const validationErrors = await validate(password, passwordConfirm);

    if (validationErrors.length > 0) return validationErrors;

    return await setPassword(password);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const password = formData.get('password1');
    const passwordConfirm = formData.get('passwordConfirm');
    const errors = await doSubmit(password, passwordConfirm);

    setValidationErrors(Array.isArray(errors) ? errors : []);
  };
  const status = data?.auth?.setPassword?.status;

  if (status === ResponseStatus.OK) {
    return (
      <WindowCompilation
        name={'login-window'}
        title={_('account:passwords.messages.successfullySaved')}
      >
        <SuccessBox>
          <Translation _={'account:passwords.messages.successfullySaved2'} />
        </SuccessBox>

        <ButtonLink to={APP_BASE_PATH + '/login'} css={{ marginTop: 16 }}>
          <Translation _={'common:text.logInNow'} />
        </ButtonLink>
      </WindowCompilation>
    );
  } else if (status === ResponseStatus.FAILED) {
    return (
      <WindowCompilation name={'login-window'} title={_('errorpage:content.headline')}>
        <ErrorBox>
          <Translation _={'passwordforget:content.errorText'} />
        </ErrorBox>

        <ButtonLink to={path + '/../..'}>
          <Translation _={'common:button.back'} />
        </ButtonLink>
      </WindowCompilation>
    );
  } else {
    return (
      <WindowCompilation name={'login-window'} title={_('account:passwords.box.normalPass.title')}>
        <Form onSubmit={handleSubmit} name="change-password-form" acceptCharset="UTF-8">
          {status === 'INVALID_DATA' ? (
            <ErrorBox>
              <Translation _="passwordforget:error.invalidPassword" />
            </ErrorBox>
          ) : validationErrors.some(error => !error.field) ? (
            <ErrorBox>
              {validationErrors
                .filter(error => !error.field)
                .map((error, index, messages) => (
                  <Fragment key={index}>
                    {error.message}
                    {index < messages.length - 1 && <br />}
                  </Fragment>
                ))}
            </ErrorBox>
          ) : null}

          <Label htmlFor="password1">
            <span>{_('account:passwords.fields.newPassword')}</span>
            <Input id="password1" name="password1" type="password" className="window__input" />
            <ValidationErrorMessage errors={password1Errors} />
          </Label>
          <Label htmlFor="passwordConfirm">
            <span>{_('account:passwords.fields.repeatPassword')}</span>
            <Input
              id="passwordConfirm"
              name="passwordConfirm"
              type="password"
              className="window__input"
            />
            <ValidationErrorMessage errors={passwordConfirmErrors} />
          </Label>
          <Button
            type={'submit'}
            label={_('account:passwords.box.normalPass.title')}
            loading={loading}
            disabled={loading}
            css={{ marginTop: 16 }}
          />
        </Form>
      </WindowCompilation>
    );
  }
};

export default withRouter(NewPasswordForm);
