import { VoucherType } from './types';
import gql from 'graphql-tag';

export function pluralize(num: number, singular: string, plural: string): string {
  return num < 2 ? singular : plural;
}

export function getScrollbarWidth(): number {
  return window.innerWidth - document.documentElement.clientWidth;
}

/**
 * Transform a DateTime ISO string into a ATOM DateTime string
 *
 * UTC time is defined with a capital letter Z (as in a JS ISO String).
 * ATOM format is the time relative to UTC. For that remove the Z, milliseconds
 * and add +HH:MM or -HH:MM
 *
 * @param isoDateTime e.g. "2019-08-22T13:51:53.347Z"
 * @param isStartTime do we send time "00:00:00" or "23:59:59"
 * @return DateTime string in ATOM format "2019-08-22T13:51:53-00:00"
 */
export function dateTimeISOtoATOM(isoDateTime: string, isStartTime?: boolean) {
  if (isoDateTime.length < 24) return '';

  const time = isStartTime ? '00:00:00.000Z' : '23:59:59.999Z';
  const date = isoDateTime.split('T')[0];
  const isoDateTimeEdited = `${date}T${time}`;
  return isoDateTimeEdited.split('.')[0] + '-00:00';
}

/**
 * Extract Date from ISO DateTime string
 * @param isoDateTime string e.g. "2019-08-22T13:51:53.347Z"
 * @return date string e.g. "2019-08-22"
 */
export function extractDateFromDateTimeISO(isoDateTime: string): string {
  if (isoDateTime.length != 24) return '';

  // extract
  const dateString: string = isoDateTime.split('T')[0];

  // validate
  if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(dateString)) return '';

  return dateString;
}

export const VOUCHER_TYPES: VoucherType[] = [
  'MEDIA',
  // 'VIDEO',
  // 'PHOTOS',
  'MESSAGES',
  'CHAT_MINUTES',
  'SHOW_TICKET',
];

export const BACKEND_TO_FRONTEND_VOUCHER_TYPES: { [key: string]: string } = {
  media: 'MEDIA',
  voucherVideo: 'VIDEO',
  voucherPhoto: 'PHOTOS',
  freeMessages: 'MESSAGES',
  freeMinutes: 'CHAT_MINUTES',
  liveShow: 'TICKET_SHOW',
  // '': 'SHOW_TICKET',
  // BenefitPremiumAbo: '',
  // BenefitVIPAbo: '',
};

export const ICONS = {
  CHAT_MINUTES: 'icon-coins',
  MESSAGES: 'icon-conversation',
  PHOTOS: 'icon-picture',
  VIDEO: 'icon-play-button',
  SHOW_TICKET: 'icon-ticket',
};

export const QUERY_MODEL_AVATAR = gql`
  query QUERY_MODEL_AVATAR {
    model {
      avatar {
        picture {
          url
        }
      }
    }
  }
`;

export interface IQueryModelAvatarProps {
  model: {
    avatar: {
      picture: {
        url: string;
      };
    };
  };
}
