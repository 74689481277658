import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Table } from '../../../../atoms/Table';
import { getColumns } from './columns';
import { Code } from '../../types';
import BonusCodesTableRow from './BonusCodesTableRow';

interface Props {
  active: boolean;
  benefits: Code[];
}

const ScrollWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledTable = styled(Table)`
  min-width: 840px;
`;

const BonusCodesTable: FC<Props> = ({ active, benefits }) => {
  const columns = getColumns();

  return (
    <ScrollWrapper>
      <StyledTable columns={columns}>
        {benefits.map((benefit) => (
          <BonusCodesTableRow
            active={active}
            benefit={benefit}
            columns={columns}
            key={benefit.code}
          />
        ))}
      </StyledTable>
    </ScrollWrapper>
  );
};

export default BonusCodesTable;
