import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../../../AbstractComponent';

class FancyTextItem extends AbstractComponent {
  render() {
    return (
      <div
        className={[
          'fancy-table__row__item',
          this.props.customClass ? this.props.customClass : '',
        ].join(' ')}
        style={this.props.styles}
      >
        {this.props.children}
      </div>
    );
  }
}

FancyTextItem.propTypes = {
  styles: PropTypes.object,
};

FancyTextItem.defaultProps = {};

export default FancyTextItem;
export { FancyTextItem };
