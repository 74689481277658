import gql from 'graphql-tag';

export const MUTATION_MASTER_MESSENGERVIPTEXT_SETBULLETS = gql`
  mutation VXM_CL_MUTATION_MASTER_MESSENGERVIPTEXT_SETBULLETS(
    $token: String!
    $bullets: [MessengerVipBulletTextInput!]!
  ) {
    master(token: $token) {
      messengerVipText {
        setBullets(bullets: $bullets) {
          success
          reason
        }
      }
    }
  }
`;
