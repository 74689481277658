import React from 'react';
import styled from '@emotion/styled';

export const HiddenInput = styled.input`
  // It's not possible to trigger clicks programmatically when hiding via display property, so we use visibility
  visibility: hidden;
  height: 0;
  width: 0;
`;

interface Props {
  accept: string;
  disabled: boolean;
  onSelectFile: (file: File) => void;
}

// Explanations:
// video/mp4,video/x-m4v: MP4 on Safari, https://stackoverflow.com/a/19323498
// video/x-matroska:      MKV on Chrome, https://www.matroska.org/technical/notes.html#mime-types
const fixAccept = (accept: string): string =>
  accept.replace('video/*', 'video/mp4,video/x-m4v,video/x-matroska,video/*');

const HiddenFileInput = React.forwardRef<HTMLInputElement, Props>(
  ({ onSelectFile, disabled, accept }, ref) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const file = e.target.files?.[0] || undefined;

      if (file) onSelectFile(file);

      // Reset the input, so that selecting the same file directly after removing it will trigger another change event
      e.target.value = '';
    };

    return (
      <HiddenInput
        ref={ref}
        type="file"
        disabled={disabled}
        onChange={onChange}
        accept={fixAccept(accept)}
      />
    );
  }
);

HiddenFileInput.displayName = 'HiddenFileInput';

export default HiddenFileInput;
