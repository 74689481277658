import styled from '@emotion/styled';
import { FormikErrors } from 'formik';
import React, { memo } from 'react';
import { DARK_GRAY, RED } from '../../camtool-styles';
import { GRAY_8 } from '../../routes/BenefitsWizard/styles';

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  p {
    color: ${DARK_GRAY};
  }
  small {
    color: ${GRAY_8};
  }
`;

interface IProps {
  label?: string;
  info?: string;
  error?: string | FormikErrors<any>;
  children?: any;
  className?: string;
}

const FieldLayout = ({ label, info, error, children, className }: IProps) => {
  return (
    <div css={{ width: '100%', flexDirection: 'column', flex: '1 0 auto' }} className={className}>
      {label && (
        <StyledLabel css={{ padding: '0 4px', marginBottom: 8 }}>
          <p css={{ fontWeight: 'bold' }}>{label}</p>
          <small>{info}</small>
        </StyledLabel>
      )}

      <div css={{ alignItems: 'center', position: 'relative' }}>
        <div
          css={{
            flex: 1,
            flexDirection: 'column',
            border: `1px solid ${error ? RED : 'none'}`,
            borderRadius: 4,
          }}
        >
          {children}
        </div>

        {error && (
          <div css={{ position: 'absolute', right: -24 }}>
            <span className="icon-exclamation-sign" css={{ color: RED }} />
          </div>
        )}
      </div>

      {error && <div css={{ color: RED, padding: 4 }}>{error}</div>}
    </div>
  );
};

export default memo(FieldLayout);
