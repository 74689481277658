import React, { FC } from 'react';
import { _ } from '../../util/translate';
import { Main, WindowCompilation } from '../../components/Layout/DarkCentered';

const ErrorInvalidCode: FC = () => {
  return (
    <Main>
      <div>
        <WindowCompilation title={_('common:errorPage.invalidVerificationCode.outroText')}>
          <div></div>
        </WindowCompilation>
      </div>
    </Main>
  );
};

export default ErrorInvalidCode;
