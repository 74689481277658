import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Divider, List } from '@material-ui/core';

import { BLUE, GRAY_2 } from '../../../camtool-styles';
import { _ } from '../../../util/translate';
import CheckableListItem from './CheckableListItem';
import StatusSwitch from './StatusSwitch';
import {
  SecurityRole,
  Service0900DisplayPlatformEnum,
  Service0900StatusEnum,
} from '../../../graphql/VXModels/types';
import styled from '@emotion/styled';
import { isActive } from '../util';
import { useLazyService0900ShortInfo } from '../hooks';
import { useHasRole } from '../../../util/UserData';

interface TopBarPopoverContentProps {
  status: Service0900StatusEnum;
  displayPlatform: Service0900DisplayPlatformEnum[];

  open?: boolean;
  handleClose?: () => void;
}

const Header = styled.h3`
  font-weight: normal;
  color: ${GRAY_2};
`;

const TopBarPopoverContent: FC<TopBarPopoverContentProps> = ({
  status,
  displayPlatform,
  open = false,
  handleClose,
}) => {
  const hasRoleVXModels = useHasRole(SecurityRole.VX_USER_VXMODELS);

  if (!handleClose) throw new Error('handleClose prop missing in TopBarPopoverContent');
  const [getLazyStatus] = useLazyService0900ShortInfo({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (open) {
      getLazyStatus();
    }
  }, [open]);

  const active = isActive(status);
  const displayBas = displayPlatform.includes(Service0900DisplayPlatformEnum.BAS);
  const displayVisitx = displayPlatform.includes(Service0900DisplayPlatformEnum.VISITX);

  return (
    <>
      <Header>{_('service0900:topbar.header')}</Header>
      <StatusSwitch status={status} />
      {/* Webcam deactivated, due we have not onlineState.videoChat status yet */}
      {/*<div>*/}
      {/*  <a href={''} style={{ color: BLUE, textDecoration: 'none', fontSize: '1.2em' }}>*/}
      {/*    {_('service0900:topbar.activateWebcam')}*/}
      {/*  </a>*/}
      {/*</div>*/}
      {hasRoleVXModels && (
        <>
          <Divider style={{ margin: '18px 0' }} />
          <div>{_('service0900:topbar.infoText')}</div>
          <List>
            <CheckableListItem checked={active && displayBas} label={'BeiAnrufSex'} />
            <CheckableListItem checked={active && displayVisitx} label={'VISIT-X'} />
          </List>
          <div>
            <Link
              to={'/camtool/service0900'}
              style={{ color: BLUE, textDecoration: 'none', fontSize: '0.9em' }}
              onClick={handleClose}
            >
              {_('service0900:topbar.moreSettings')}
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default TopBarPopoverContent;
