import ApolloClient from 'apollo-client';
import React, { FC, useEffect, useState } from 'react';
// Outside Wizard
import { ButtonLink } from '../../../atoms';
import { EmptyContent, Spinner } from '../../../components';
import { PageContainer, Section, SectionContent, SectionTitle } from '../styles';
import useBonusCode from '../hooks/useBonusCode';
import { Code, CodeStatus } from '../types';
import { _ } from '../../../util/translate';
import BonusCodesTable from './Table/BonusCodesTable';

const BonusCodesArchive: FC<{ bonusCodeClient: ApolloClient<Record<string, unknown>> }> = ({
  bonusCodeClient,
}) => {
  const [loading, setLoading] = useState(true);
  const [codeList, setCodeList] = useState<Code[]>([]);
  const { getCodesList } = useBonusCode(bonusCodeClient);

  const requestCodeList = (): void => {
    if (typeof getCodesList === 'function') {
      setLoading(true);
      getCodesList().then((list) => {
        setCodeList(list);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    requestCodeList();
  }, []);

  const activeVouchers = [];
  const inactiveVouchers = [];

  codeList.sort((a, b) => {
    const aValidFrom = new Date(a.config.validFrom).getTime();
    const bValidFrom = new Date(b.config.validFrom).getTime();

    if (aValidFrom > bValidFrom) return -1;
    else if (aValidFrom < bValidFrom) return 1;
    else return 0;
  });

  for (const code of codeList) {
    if (code.status === CodeStatus.Inactive) {
      inactiveVouchers.push(code);
    } else {
      activeVouchers.push(code);
    }
  }

  return (
    <PageContainer>
      <Section>
        <SectionTitle>{_('benefits:voucher.archive.titleActive')}</SectionTitle>
        <SectionContent
          className="spinner-container"
          css={{ minHeight: 400, padding: '32px 16px' }}
        >
          {loading && <Spinner />}

          {!loading && activeVouchers.length < 1 && (
            <EmptyContent title={_('benefits:voucher.archive.emptyContent')} icon="icon-ticket">
              <ButtonLink to="/camtool/vouchers/create" theme="blue" css={{ marginTop: 16 }}>
                {_('benefits:voucher.texts.createVoucher')}
              </ButtonLink>
            </EmptyContent>
          )}

          {!loading && activeVouchers.length > 0 && (
            <BonusCodesTable active={true} benefits={activeVouchers} />
          )}
        </SectionContent>
      </Section>

      {inactiveVouchers.length > 0 && (
        <Section>
          <SectionTitle>{_('benefits:voucher.archive.titleInactive')}</SectionTitle>
          <SectionContent
            className="spinner-container"
            css={{ minHeight: 400, padding: '32px 16px' }}
          >
            <BonusCodesTable active={false} benefits={inactiveVouchers} />
          </SectionContent>
        </Section>
      )}
    </PageContainer>
  );
};

export default BonusCodesArchive;
