import React, { memo } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { T } from '../../components';
import ItemContainer from './ItemContainer';
import { APP_BASE_PATH } from '../../util/env';

const Container = styled.div`
  padding: 16px 0;
  color: #888;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
`;

const Top = styled.div`
  padding: 16px 32px;
  border-bottom: 2px solid #eee;
  margin-bottom: 16px;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 4px;
`;

const AvatarPlaceholder = styled.span`
  flex: 1;
  border: 1px solid #888;
  font-size: 76px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-decoration: none;
  color: #888;
  overflow: hidden;
  &:hover {
    color: #aaa;
  }
`;

interface Props {
  onlineRank: number;
  modelName: string;
  pictureURL: string;
  loading: boolean;
}

const ItemRank = memo((props: Props) => {
  return (
    <ItemContainer loading={props.loading}>
      <Container>
        <Top>
          <AvatarContainer>
            {props.pictureURL ? (
              <img src={props.pictureURL} css={{ width: '100%' }} />
            ) : (
              <Link to={`${APP_BASE_PATH}/onlineprofile/visitx/profilepic/_sedcardVX16`}>
                <AvatarPlaceholder className="icon-woman" />
              </Link>
            )}
          </AvatarContainer>
          <p css={{ fontSize: 16 }}>{props.modelName}</p>
        </Top>

        <div css={{ flexDirection: 'column' }}>
          <p css={{ color: '#262728', fontSize: 14 }}>
            <T _={'ranking:newRanking.item.liveRank.text'} />
          </p>
          <div css={{ alignItems: 'center', justifyContent: 'center' }}>
            <p css={{ color: '#262728', fontSize: 64, fontWeight: 'bold' }}>{props.onlineRank}</p>
          </div>
        </div>
      </Container>
    </ItemContainer>
  );
});

ItemRank.displayName = 'ItemRank';

export default ItemRank;
