import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { common, grey } from '@material-ui/core/colors';
import { BLUE } from '../../camtool-styles';

const gridItemStyles = makeStyles({
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  stretch: {
    height: '100%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0px',
    minHeight: '308px',
    border: 'none',
  },
  tileHeader: {
    backgroundColor: grey[800],
    color: common.white,
    fontSize: '15px',
    textTransform: 'uppercase',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: ' 0 24px',
  },
  blueTileHeader: {
    backgroundColor: BLUE,
    color: common.white,
    fontSize: '15px',
    textTransform: 'uppercase',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: ' 0 24px',
  },
  titleIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
  },
  smallTile: {
    height: '100%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    maxHeight: '76px',
    width: '100%',
    borderRadius: '0px',
    justifyContent: 'start',
    '& .MuiCardContent-root': {
      width: '100%',
    },
  },
  noCardLayout: {
    height: '100%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    width: '100%',
    borderRadius: '0px',
    justifyContent: 'start',
    '& .MuiCardContent-root': {
      padding: '0px',
      width: '100%',
      minHeight: '308px',
    },
  },
  noSmallCardLayout: {
    height: '100%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    width: '100%',
    borderRadius: '0px',
    justifyContent: 'start',
    '& .MuiCardContent-root': {
      padding: '0px',
      width: '100%',
    },
  },
});

interface OwnProps {
  title?: ReactNode | JSX.Element;
  content: ReactNode | JSX.Element;
  icon?: ReactNode;
  centerItems?: boolean;
  noHeader?: boolean;
  noCardLayout?: boolean;
  noSmallCardLayout?: boolean;
  infoTile?: boolean;
}

type Props = OwnProps;

const GridTileItem: FunctionComponent<Props> = ({
  noHeader,
  centerItems,
  icon,
  title,
  infoTile,
  content,
  noCardLayout,
  noSmallCardLayout,
  ...rest
}) => {
  const classes = gridItemStyles();
  return (
    <Grid className={classes.item} item {...rest} zeroMinWidth={true}>
      {noHeader ? (
        <Fragment>
          {noSmallCardLayout ? (
            <Card
              className={classes.noSmallCardLayout}
              style={centerItems ? { alignItems: 'center' } : {}}
              variant={'elevation'}
              elevation={0}
            >
              <CardContent style={{ flex: 1, padding: 0 }}>{content}</CardContent>
            </Card>
          ) : (
            <Card
              className={classes.smallTile}
              style={centerItems ? { alignItems: 'center' } : {}}
              variant={'elevation'}
              elevation={0}
            >
              <CardContent style={{ flex: 1 }}>{content}</CardContent>
            </Card>
          )}
        </Fragment>
      ) : noCardLayout ? (
        <Card
          className={classes.noCardLayout}
          style={centerItems ? { alignItems: 'center' } : {}}
          variant={'elevation'}
          elevation={0}
        >
          <CardContent style={{ flex: 1 }}>{content}</CardContent>
        </Card>
      ) : (
        <>
          <Box className={infoTile ? classes.blueTileHeader : classes.tileHeader}>
            {icon && <Box className={classes.titleIcon}>{icon}</Box>}
            <Box>{title}</Box>
          </Box>
          <Card
            variant={'elevation'}
            elevation={0}
            className={classes.stretch}
            style={centerItems ? { alignItems: 'center' } : {}}
          >
            <CardContent style={{ flex: 1 }}>{content}</CardContent>
          </Card>
        </>
      )}
    </Grid>
  );
};

GridTileItem.displayName = 'GridTileItem';

export default GridTileItem;
