import React, { FC } from 'react';
import { checkmark } from '../Icon/libraries/svg';
import styled from '@emotion/styled';
import { BLACK_2 } from '../../camtool-styles';

const AdvantageContainer = styled.div`
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;
    margin: 40px 0px;
}
`;

const AdvantagesSection = styled.div`
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: center;
  flex: 0 0 auto;
  align-items: flex-start;
`;

const AdvantageContent = styled.div`
  flex-direction: column;
  max-width: 475px;
  color: ${BLACK_2};
  max-width: 530px;
`;

const UnorderedList = styled.ul`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  list-style-position: inside;
  align-self: center;
`;

const StyledListItem = styled.li`
  list-style-image: url(${checkmark});
  margin-bottom: 10px;
  font-size: 14px;
`;

const HeadLine = styled.h2`
  text-align: center;
  font-size: 25px;
  margin-bottom: 20px;
`;

interface AdvantagesListProps {
  listItemArray: string[];
  headline: string;
  subHeadline?: string;
}

const AdvantagesList: FC<AdvantagesListProps> = ({ listItemArray, headline, subHeadline = '' }) => {
  return (
    <AdvantageContainer>
      <AdvantagesSection>
        <AdvantageContent>
          <HeadLine>{headline}</HeadLine>
          {subHeadline && <p css={{ marginBottom: '20px', textAlign: 'center' }}>{subHeadline}</p>}
          <UnorderedList>
            {listItemArray.map((item: string, index: number) => (
              <StyledListItem key={index}>{item}</StyledListItem>
            ))}
          </UnorderedList>
        </AdvantageContent>
      </AdvantagesSection>
    </AdvantageContainer>
  );
};

export default AdvantagesList;
