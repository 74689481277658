import { useMemo } from 'react';
import { getVXServiceTelegramClient, useApolloClient } from '../index';
import { ClientsNamespace } from '../types';
import ApolloClient from 'apollo-client';

export const useCreateVXServicesTelegramClient = (
  telegramToken: string | undefined
): ApolloClient<unknown> =>
  useMemo(() => getVXServiceTelegramClient({ token: telegramToken }), [telegramToken]);

export const useVXServicesTelegramClient = (): ApolloClient<unknown> =>
  useApolloClient(ClientsNamespace.VXServicesTelegram);
