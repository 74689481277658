import React, { FC } from 'react';
import { _ } from '../../../util/translate';

interface Props {
  guestLogin: string;
  text: string;
}

const UserComment: FC<Props> = ({ guestLogin, text }) => {
  return (
    <div css={{ flexDirection: 'column', flex: '1 1 auto' }}>
      <span css={{ marginBottom: '5px', fontWeight: 'bold' }}>
        {_('mediamanagement:comments.comment.from')}{' '}
        {guestLogin ? guestLogin : _('mediamanagement:video.comments.deletedUser')}
      </span>
      <span
        css={{
          display: 'block', // to make ::before and ::after work properly
          lineHeight: '16px',
          '::before': { content: 'open-quote' },
          '::after': { content: 'close-quote' },
        }}
      >{`${text}`}</span>
    </div>
  );
};

export default UserComment;
