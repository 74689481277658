export const PADDING_FOR_SCROLLBAR = 18;
export const CONTAINER_WIDTH = 536;
export const CONTAINER_CONTENT_WIDTH = CONTAINER_WIDTH - PADDING_FOR_SCROLLBAR;
export const CONTAINER_HEIGHT = 298;
export const TILE_PADDING = 8;
export const IMAGE_WIDTH = (CONTAINER_CONTENT_WIDTH - TILE_PADDING * 5) / 4;
export const IMAGE_HEIGHT = IMAGE_WIDTH / (4 / 3);
export const FOOTER_HEIGHT = 34;
export const TILE_WIDTH = IMAGE_WIDTH;
export const TILE_HEIGHT = IMAGE_HEIGHT + FOOTER_HEIGHT;
