import axios from 'axios';
import CryptoJS from 'crypto-js';
import gql from 'graphql-tag';

import md5Webworker from 'md5-webworker';

import { getVXServiceTelegramClient } from '../graphql';
import {
  ApiLang,
  Attachment,
  AttachmentInput,
  AttachmentInputMeta,
  AttachmentTypeEnum,
  Dimensions,
  FileStorageSettings,
  FileStorageTypeEnum,
} from '../graphql/VXModels/types';
import { FileType as PreviewFileType } from '../molecules/FileUpload/PreviewFile';
import { isFileType } from '../molecules/FileUpload/PreviewFile/utils';

export type MultiLangText = Record<ApiLang, string>;

interface VerifyCheck {
  verified: boolean | undefined;
  name: string;
  message: MultiLangText;
}

interface VerifyResult {
  md5hash: string;
  verified: boolean | undefined;
  checks: VerifyCheck[];
}

const MUTATION_MASTER_REQUEST_FILE_UPLOAD = gql`
  mutation MUTATION_MASTER_REQUEST_FILE_UPLOAD($token: String!) {
    master(token: $token) {
      requestFileUpload {
        id
        uuid
        type
        exists
        url
        __typename
      }
    }
  }
`;

export function calcFileMetaMd5(file: File): string {
  return CryptoJS.MD5(`${file.name + file.size + file.lastModified}`).toString();
}

export async function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export async function calcFileMd5(file: File): Promise<string | { error: string }> {
  // use webworker based md5 calc due out-of-mem problems on using FileReader based inline calculation
  return md5Webworker(file);
}

export async function verifyFile(file: File): Promise<VerifyResult> {
  const md5hash = await calcFileMd5(file);

  return {
    md5hash,
    verified: true,
    checks: [],
  };
}

export async function getDimensions(file: File): Promise<Dimensions> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    switch (file.type.split('/')[0]) {
      case 'image':
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            const { height, width } = img;
            // console.log('dimensions of ' + file.name, { height, width });
            resolve({ height, width });
          };
          img.src = reader.result as string;
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
        break;
      case 'video':
        const video = document.createElement('video');
        video.addEventListener('loadedmetadata', (event) => {
          const { videoWidth: width, videoHeight: height } = video;
          video.remove();
          resolve({ height, width });
        });
        video.src = URL.createObjectURL(file);
        break;
      case 'audio':
        resolve({ width: 1, height: 1 });
        break;
      default:
        resolve({ width: -1, height: -1 });
    }
  });
}

export async function fileToInputAttachment(file: File): Promise<AttachmentInput & { file: File }> {
  const meta: AttachmentInputMeta = {
    filename: file.name,
    type: file.type,
    size: file.size,
    hash: await calcFileMd5(file),
    dimensions: await getDimensions(file),
  };

  return {
    type: AttachmentTypeEnum.BASE64,
    payload: await getBase64(file),
    meta,
    file,
  };
}

export type AttachmentInputOrFile = AttachmentInput & { file: File };

export const isAttachment = (value: Attachment | AttachmentInputOrFile): value is Attachment =>
  value.hasOwnProperty('url');

export const getPreviewFileTypeFromAttachment = (attachment: Attachment): PreviewFileType => {
  if (attachment.meta.type !== undefined) {
    const fileType = attachment.meta.type.split('/')[0];
    return isFileType(fileType) ? fileType : PreviewFileType.unknown;
  }
  return PreviewFileType.unknown;
};

const fileStorageTypeAttachmentTypeMapping = {
  [FileStorageTypeEnum.VXSERVICES_TELEGRAM]: AttachmentTypeEnum.UUID,
  [FileStorageTypeEnum.USER_MEDIA_WRAPPER]: AttachmentTypeEnum.URL,
  _default: AttachmentTypeEnum.URL,
};

export async function fileToUploadedInputAttachment(
  fileStorageSettings: FileStorageSettings,
  file: File,
  updateProgressBarValue?: (progress: number) => void
): Promise<AttachmentInputOrFile> {
  const client = getVXServiceTelegramClient();
  const { token, uploadEndpoint } = fileStorageSettings;

  const {
    data: {
      master: {
        requestFileUpload: { uuid },
      },
    },
  } = await client.mutate({
    mutation: MUTATION_MASTER_REQUEST_FILE_UPLOAD,
    variables: { token },
  });

  const formData = new FormData();
  formData.append('uuid', uuid);
  formData.append('file', file, file.name);

  const config = {
    onUploadProgress: (progressEvent: ProgressEvent): void => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      updateProgressBarValue?.(percentCompleted);
    },
    params: { uuid },
  };

  const {
    data: { url, hash, dimensions, dimension },
  } = await axios.post(uploadEndpoint, formData, config);

  const isAudio = file.type.split('/')[0] === 'audio';

  const audioDimension = { width: 10, height: 10 };

  return {
    type: fileStorageTypeAttachmentTypeMapping[fileStorageSettings.type] || AttachmentTypeEnum.URL,
    payload: fileStorageSettings.type === FileStorageTypeEnum.VXSERVICES_TELEGRAM ? uuid : url,
    meta: {
      filename: file.name,
      type: file.type,
      size: file.size,
      hash,
      dimensions: (isAudio ? audioDimension : dimensions) || dimension,
      previewUrl: url,
    },
    file,
  } as AttachmentInputOrFile;
}
