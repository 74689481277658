import React, { FC } from 'react';
import { Grid, Avatar } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { DateTimeFormatter } from '../../../../atoms/I18n';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';
import { ShortInfo } from '../../../../molecules/ShortInfo';
import { LinearProgressWithLabel } from '../../../../molecules/LinearProgressWithLabel';
import { _ } from '../../../../util/translate';
import { BroadcastStatus } from '../../types';
import { calcSource, getSourceFriendlyName, getStatusColor } from '../../util';
import StatusAvatar from './StatusIcon';
import SourceIcon from './SourceIcon';
import GroupIcon from '../../../../atoms/Icon/GroupIcon';

interface SectionBroadcastStatsProps {
  broadcast: Required<Broadcast>;
}

const SectionBroadcastStats: FC<SectionBroadcastStatsProps> = ({ broadcast }) => {
  const { timestamp, sentCount = 0, usersCount, revoked, status } = broadcast;
  const source = calcSource(broadcast);

  return (
    <Grid container alignItems="center" item spacing={3}>
      <Grid item xs={3}>
        <ShortInfo
          avatar={<StatusAvatar status={status} />}
          primary={_('telegram:Broadcast.status.value.' + status)}
          secondary={
            <LinearProgressWithLabel
              showText={!revoked}
              variant={status === BroadcastStatus.running ? 'indeterminate' : 'determinate'}
              color={getStatusColor(status)}
              value={
                status === BroadcastStatus.done || status === BroadcastStatus.revoked
                  ? 100
                  : (sentCount / usersCount) * 100
              }
            />
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
      </Grid>
      <Grid item xs={3}>
        <ShortInfo
          avatar={
            <Avatar>
              <GroupIcon />
            </Avatar>
          }
          primary={_('telegram:BroadcastShow.sent.label')}
          secondary={`${revoked ? 0 : sentCount}`}
        />
      </Grid>
      <Grid item xs={3}>
        <ShortInfo
          avatar={<SourceIcon source={source} />}
          primary={_('telegram:BroadcastShow.source.label')}
          secondary={getSourceFriendlyName(source)}
        />
      </Grid>
      <Grid item xs={3}>
        <ShortInfo
          avatar={
            <Avatar>
              <ScheduleIcon />
            </Avatar>
          }
          primary={_('telegram:BroadcastShow.timestamp.label')}
          secondary={<DateTimeFormatter value={timestamp} format={'datetime'} />}
        />
      </Grid>
    </Grid>
  );
};

export default SectionBroadcastStats;
