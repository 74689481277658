import React, { FC, ReactElement } from 'react';
import { StylesProvider } from '@material-ui/core/styles';
import { OutlinedInputClassKey, TextField, TextFieldProps } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import styled from '@emotion/styled';
import { GRAY_2 } from '../../camtool-styles';
import { ValidationType } from '../../util/BuildValidationObject';
import { makeStyles, createStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
export interface NewInputProps extends Omit<TextFieldProps, 'color' | 'classes'> {
  validation?: ValidationType;
  charLimitHelper?: ReactElement;
  hasTabs?: boolean;
  // classes for stylings in InputProps not for TextField root!
  classes?: Partial<ClassNameMap<OutlinedInputClassKey>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      borderTopLeftRadius: '0px',
    },
  })
);

const useCharlimitClasses = makeStyles(() =>
  createStyles({
    textField: {
      display: 'flex',
      gap: '6px',
    },
    textArea: {
      '& > div': {
        position: 'absolute',
        bottom: '7px',
        right: '7px',
      },
      '& > svg': {
        position: 'absolute',
        top: '7px',
        right: '7px',
      },
    },
  })
);

// decompose color prop for avoiding console warnings related to DOM attributes
const StyledTextField = styled(({ color, ...otherProps }) => <TextField {...otherProps} />)`
  label.focused {
    color: ${(props): string => props.color};
  }

  p.MuiFormHelperText-root {
    color: ${(props): string => props.color};
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${(props): string => props.color};
    }
    &:hover fieldset {
      border-color: ${(props): string => props.color};
    }
    &.Mui-focused fieldset {
      border-color: ${(props): string => props.color};
    }
    &.Mui-error fieldset {
      border-color: ${(props): string => props.color};
    }
  }

  .MuiInputBase-input {
    color: ${(props): string => props.color};
  }

  .MuiOutlinedInput-input::placeholder {
    color: ${GRAY_2};
    opacity: 1;
  }
`;

const NewInputField: FC<NewInputProps> = ({
  placeholder,
  defaultValue,
  validation,
  onChange,
  charLimitHelper,
  multiline,
  hasTabs = false,
  classes = null,
  ...props
}) => {
  const tabClasses = useStyles();
  const textFieldWithTabStyling = hasTabs ? tabClasses : null;
  const mergedClasses = { classes: { ...textFieldWithTabStyling, ...classes } };
  const charLimitClasses = useCharlimitClasses();
  const textFieldWithCharLimitStyling = multiline
    ? { classes: { positionEnd: charLimitClasses.textArea } }
    : { classes: { positionEnd: charLimitClasses.textField } };
  const helperText = validation?.message;

  return (
    // injectFirst to inject material-ui stylings (via <style>) first into DOM so emotion can overwrite that later
    <StylesProvider injectFirst>
      <StyledTextField
        color={validation?.color.main}
        fullWidth
        error={!!validation?.message}
        helperText={helperText}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment
              {...(charLimitHelper ? textFieldWithCharLimitStyling : null)}
              position="end"
            >
              {[charLimitHelper, validation?.icon] ?? undefined}
            </InputAdornment>
          ),
          ...mergedClasses,
        }}
        multiline={multiline}
        {...props}
      />
    </StylesProvider>
  );
};

export default NewInputField;
