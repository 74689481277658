import React from 'react';

import { getFormattedDuration } from '../../../util/Formatter';

import DefaultWidget from './DefaultWidget';
import { _ } from '../../../util/translate';
import PropTypes from 'prop-types';

const ChatHoldingTimes = React.memo(({ loading, className, chatHoldingTime }) => (
  <DefaultWidget
    loading={loading}
    className={className}
    iconName="stopwatch"
    iconColor="#1f93e9"
    label={_('dashboard:app.chatHoldingTimes.header')}
    data={getFormattedDuration(!!chatHoldingTime ? chatHoldingTime : 0)}
    footnote={_('dashboard:app.chatHoldingTimes.footer')}
  />
));

ChatHoldingTimes.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.any,
  chatHoldingTime: PropTypes.any,
};

ChatHoldingTimes.displayName = 'ChatHoldingTimes';

export default ChatHoldingTimes;
