import React, { FC, useState, useEffect } from 'react';
import { BLACK_2, WHITE } from '../../../../../camtool-styles';
import { fadeIn, fadeOut } from '../../../../../util/animations/keyframes';
import styled from '@emotion/styled';
import { User } from '../../../../../graphql/VXServicesTelegram/types';
import ToolTipOptions from '../ToolTipOptions';
import Icon from '../../../../../atoms/Icon/Icon';
import { threeDotsFull } from '../../../../../atoms/Icon/libraries/svg';
import UserPlate from '../UserPlate';

const ToolTip = styled.div<IToolTip>`
  position: absolute;
  top: 60px;
  right: 2px;
  z-index: 5;
  background: ${WHITE};
  padding: 5px 1px 5px 1px;
  width: 120px;
  justify-content: center;
  box-shadow: rgb(52 52 111 / 30%) 0px 3px 6px 0px;
  cursor: pointer;
  visibility: ${(props): string => (props.show ? 'visible' : 'hidden')};
  animation: ${(props): string => (props.show ? fadeIn : fadeOut)} 0.2s linear};
  transition: visibility 0.2s linear;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    transform: rotate(225deg);
    right: 17px;
    border-width: 5px;
    border-style: solid;
    border-color: ${WHITE};
    box-shadow: 0.2px 0.2px 0px ${BLACK_2};
  }
`;

const Bar = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 66px;
  flex: 1 0 auto;
  background-color: ${WHITE};
`;

const Options = styled.div`
  cursor: pointer;
  padding-right: 22px;
`;

const optionsID = 'userOptions';
const optionsIconID = 'userOptionsIcon';

interface IToolTip {
  show: boolean;
}

interface IProps {
  selectedUser: User;
  showModalWindow: () => void;
}

const ThreadContainerHeader: FC<IProps> = ({ selectedUser, showModalWindow }) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const onClose = (event: MouseEvent): void => {
    const id = (event.target as Element).id;
    if (id !== optionsID && id !== optionsIconID) {
      setShowToolTip(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClose);

    return () => window.removeEventListener('click', onClose);
  }, []);

  return (
    <Bar>
      <UserPlate selectedUser={selectedUser} />
      {!selectedUser.premium ? (
        <>
          <Options
            id={optionsID}
            onClick={() => setShowToolTip((currentShowToolTip: boolean) => !currentShowToolTip)}
          >
            <Icon id={optionsIconID} size="16px" css={{ margin: '25px 0' }} icon={threeDotsFull} />
          </Options>
          <ToolTip show={showToolTip}>
            <ToolTipOptions showModalWindow={showModalWindow} selectedUser={selectedUser} />
          </ToolTip>
        </>
      ) : null}
    </Bar>
  );
};

export default ThreadContainerHeader;
