import { AbstractRestApiStoreClass, AbstractRestApiActionCreatorsClass } from '../AbstractRestApi';

const CAMPAIGNS = 'CAMPAIGNS';
const STATS = 'STATS';

let config = {
  name: 'Campaigns',
  resources: {
    CAMPAIGNS: {
      uri: {
        GET: '/v1/camtool/user/{userId}/media/campaigns',
      },
      defaultData: {
        filter: {},
        campaigns: [],
      },
    },
  },
};

class CampaignStoreClass extends AbstractRestApiStoreClass {}
class CampaignActionCreatorsClass extends AbstractRestApiActionCreatorsClass {}

const CampaignStore = new CampaignStoreClass(config);
const CampaignActionCreators = new CampaignActionCreatorsClass(config);

export { CampaignStore, CampaignActionCreators, CAMPAIGNS };
