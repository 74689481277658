import React, { FC } from 'react';
import ModalPhoneRequestSteps from './ModalPhoneConfirmation/ModalPhoneRequestSteps';
import { RequestWrapper } from '../styles';
import InsertNumber from './InsertNumber';
import EditNumber from './EditNumber';
import { _ } from '../../../../util/translate';
import { Service0900PhoneData } from '../types';
import { useDisplayState } from '../../../../hooks';

interface Props {
  storeData: Service0900PhoneData;
  onSuccess: () => void;
}

const PhoneSexRequestSection: FC<Props> = ({ storeData, onSuccess }) => {
  const { shouldShow: showModal, show, hide } = useDisplayState();

  const closeModal = (): void => {
    hide();
  };

  const openModal = (): void => {
    show();
  };

  return (
    <RequestWrapper>
      <div css={{ flexDirection: 'column', alignItems: 'center' }}>
        <h2 css={{ marginBottom: '25px' }}>
          {_('service0900:PhoneSex.inactive.request.headline')}
        </h2>
        {storeData?.targetNumber?.phoneNumber ? (
          <EditNumber storeData={storeData} openModal={openModal} />
        ) : (
          <InsertNumber openModal={openModal} />
        )}
      </div>
      {showModal && (
        <ModalPhoneRequestSteps
          storeData={storeData}
          closeModal={closeModal}
          onSuccess={onSuccess}
        />
      )}
    </RequestWrapper>
  );
};

export default PhoneSexRequestSection;
