import React from 'react';
import Button from '../../atoms/Button/Button';
import { withRouter } from 'react-router';

const NavButton = ({ to, children, history, ...rest }) => (
  <Button
    onClick={ev => {
      ev.preventDefault();
      history.push(to);
    }}
    {...rest}
  >
    {children}
  </Button>
);

export default withRouter(NavButton);
