import {MailingRecipientsGroupsEnum, MailingTypeEnum} from '../../graphql/VXModels/types';

export const NEW_MAILING = `/new`;
export const REQUEST_TELEGRAM = `/request-telegram`;
export const ARCHIVE = '/archive';
export const SELECT_ID = `/:id(\\d+)`;
export const NEWSLETTER_ARCHIVE = `/newsletter`;
export const PREMIUMABO_ARCHIVE = `/premium`;
export const NEWSLETTER_ARCHIVE_FULL = `${ARCHIVE}${NEWSLETTER_ARCHIVE}`;
export const PREMIUMABO_ARCHIVE_FULL = `${ARCHIVE}${PREMIUMABO_ARCHIVE}`;
export const NEWSLETTER_ARCHIVE_OLD = `/newsletter-archive`;
export const PREMIUMABO_ARCHIVE_OLD = `/premiumabo-archive`;
export const AUTO_MAIL = `/auto-mail`;
export const CHATS = `/chats`;
export const ABOS = `/abos`;

export const type = MailingTypeEnum.TELEGRAM;
