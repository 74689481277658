import React from 'react';

import PhotoGalleryStore from '../../../../stores/PhotoGallery/PhotoGalleryStore';

import AlbumViewStandard from './AlbumViewStandard';

import { extractTitle } from './index';

const AlbumView = ({ album, refetchAlbum, setCurrentAlbumType }) => {
  const showGallery = picture => {
    PhotoGalleryStore.openPhotoGallery([picture.url]);
  };

  return (
    <AlbumViewStandard
      title={extractTitle(album.albumType, album.titles)}
      album={album}
      refetchAlbum={refetchAlbum}
      editable={true}
      onPictureClick={showGallery}
      allowMultipleSelection={false}
      setCurrentAlbumType={setCurrentAlbumType}
    />
  );
};

export default AlbumView;
