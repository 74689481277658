import React, { FC, useEffect, useCallback, ReactElement } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MuiPopper from '@material-ui/core/Popper';
import { DARK_GRAY, WHITE } from '../../camtool-styles';
import Button from '../Button/Button';
import Backdrop from '@material-ui/core/Backdrop';
import { _ } from '../../util/translate';
import { TooltipType } from '../../graphql/VXModels/types';
import usePopperSeen from '../../packages/Service0900/hooks/usePopperSeen';
import { useDisplayState } from '../../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      color: DARK_GRAY,
      maxWidth: '330px',
      lineHeight: 1.5,
    },
    popper: {
      zIndex: 20,
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: WHITE,
      border: '1px solid',
      borderRadius: '5px',
      padding: '10px',
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${WHITE} transparent`,
        },
      },
    },
    arrow: {
      position: 'absolute',
      fontSize: 12,
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        borderStyle: 'solid',
      },
    },
  })
);

interface Props {
  description: string;
  type: TooltipType;
  isForbiddenRoute?: boolean;
  anchorElSelector: string;
  children: ReactElement;
}

const Popper: FC<Props> = React.memo(
  ({ description, type, isForbiddenRoute = false, anchorElSelector, children }) => {
    const anchorEl = document.querySelector(`[data-popper=${anchorElSelector}]`);
    const { shouldShow: showPopper, show: openPopper, hide: hidePopper } = useDisplayState();
    const { useHasSeen, useSetSeen } = usePopperSeen({
      type: type,
    });
    const { data, loading } = useHasSeen();
    const [markAsRead] = useSetSeen();

    // avoiding unnecessary rerender
    const onClick = useCallback(() => {
      hidePopper();
    }, []);

    // avoiding unnecessary rerender
    const onRead = useCallback(() => {
      markAsRead();
    }, []);

    const hasSeen = data?.model?.tooltipSettings?.hasSeen;
    const classes = useStyles();
    const [arrowRef, setArrowRef] = React.useState(null);

    useEffect(() => {
      if (!loading && !hasSeen) {
        openPopper();
      }
    }, [hasSeen]);

    return (
      <>
        {children}
        {anchorEl && isForbiddenRoute
          ? null
          : !loading && (
              <Backdrop open={showPopper} onClick={onClick}>
                <MuiPopper
                  className={classes.popper}
                  placement="bottom"
                  open={showPopper}
                  anchorEl={anchorEl}
                  modifiers={{
                    arrow: {
                      enabled: true,
                      element: arrowRef,
                    },
                  }}
                >
                  <span className={classes.arrow} ref={setArrowRef} />
                  <div className={classes.paper}>{description}</div>
                  <Button theme={'link-blue'} onClick={onRead}>
                    {_('common:button.markRead')}
                  </Button>
                </MuiPopper>
              </Backdrop>
            )}
      </>
    );
  }
);

Popper.displayName = 'Popper';

export default Popper;
