import styled from '@emotion/styled';
import { MediaUploadInputLabelSize, MediaUploadInputLayoutDirection } from '../types';
import { UploadLabelProps } from './types';

const getLabelFontSize = (
  labelSize: MediaUploadInputLabelSize,
  layoutDirection: MediaUploadInputLayoutDirection
): number => {
  switch (labelSize) {
    case MediaUploadInputLabelSize.md:
      return 20;
    case MediaUploadInputLabelSize.sm:
      switch (layoutDirection) {
        case MediaUploadInputLayoutDirection.horizontal:
          return 16;
        case MediaUploadInputLayoutDirection.vertical:
          return 14;
      }
  }
};

const getMarginTop = (labelSize: MediaUploadInputLabelSize): number => {
  switch (labelSize) {
    case MediaUploadInputLabelSize.md:
      return 15;
    case MediaUploadInputLabelSize.sm:
      return 8;
  }
};

const Label = styled.span<Pick<UploadLabelProps, 'labelSize' | 'layoutDirection'>>`
  font-size: ${(props): number => getLabelFontSize(props.labelSize, props.layoutDirection)}px;
  ${(props): string =>
    props.layoutDirection === MediaUploadInputLayoutDirection.vertical
      ? `margin-top: ${getMarginTop(props.labelSize)}px`
      : 'margin-left: 8px'};
  user-select: none;
  text-align: center;
`;

export default Label;
