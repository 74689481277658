import React from 'react';
import styled from '@emotion/styled';

export const Article = styled.article({
  flexDirection: 'column',
  maxWidth: 830,
  margin: '0 auto',
  width: '100%',
  padding: 64,
});

export default Article;
