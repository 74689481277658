import React, { FC } from 'react';
import Numbers0900Section from './Numbers0900Section';
import ActivateNumberSection from './ActivateNumberSection';
import BA6ProfileSection from './BA6ProfileSettings/BA6ProfileSection';
import { Service0900PhoneData } from '../types';

interface Props {
  storeData: Service0900PhoneData;
  onSuccess: () => void;
}

const PhoneSexActive: FC<Props> = ({ storeData, onSuccess }) => {
  return (
    <>
      <div css={{ flexWrap: 'wrap' }}>
        <Numbers0900Section visitX0900Numbers={storeData?.visitx0900Numbers} />
        <ActivateNumberSection status={storeData?.status} />
      </div>
      <BA6ProfileSection storeData={storeData} onSuccess={onSuccess} />
    </>
  );
};

export default PhoneSexActive;
