import React from 'react';
import InlinePreviewFile from './InlinePreviewFile';
import { GRAY_3, WHITE } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';
import { RenderColumn } from '../../../../atoms/MaterialTable/types';
import { Broadcast } from '../../../../graphql/VXServicesTelegram/types';
import { Box } from '@material-ui/core';

const RenderColumnFile: RenderColumn<Broadcast> = ({ file }) => {
  return file ? (
    <InlinePreviewFile file={file} />
  ) : (
    <Box
      display="flex !important"
      border={1}
      bgcolor={WHITE}
      borderColor={GRAY_3}
      height="57px"
      width="100%"
      alignItems="center"
      justifyContent="center"
      m="auto"
    >
      {_('telegram:BroadcastList.column.file.empty')}
    </Box>
  );
};

export default RenderColumnFile;
