import styled from '@emotion/styled';
import React, { FC } from 'react';
import { BLACK, DARK_GRAY } from '../../../../camtool-styles';

const TippingStamp = styled.span`
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 4px -2px ${BLACK};
  font-weight: bold;
  width: fit-content;
  align-self: center;
  border-radius: 20px;
  padding: 10px 12px;
  margin: 10px 0px;
  color: ${DARK_GRAY};
  align-items: center;
  gap: 3px;
`;

const TippingBubble: FC = ({ children }) => {
  return <TippingStamp>{children}</TippingStamp>;
};

export default TippingBubble;
