import React, { FC } from 'react';
import { checkmark } from '../../../atoms/Icon/libraries/svg';
import styled from '@emotion/styled';
import { APP_BASE_PATH } from '../../../util/env';
import { NavLink } from 'react-router-dom';
import { LovenseToyStatusEnum } from '../../../graphql/VXModels/types';
import { _ } from '../../../util/translate';

interface IProps {
  lovenseToyStatus: string;
}

const DynamicAdvantageContainer = styled.div`
  margin-top: 40px;
  align-items: center;
  ${({ isAccepted }) =>
    isAccepted
      ? `flex-direction: row;
   justify-content: space-around;
   flex-wrap: wrap;
   width: 75%;
   align-self: center;
  `
      : `flex-direction: column;
justify-content: center;
flex-wrap: no-wrap;
`}
`;

const VideoDiv = styled.div`
  position: relative;
  width: 460px;
  height: 310px;
  flex: 0 0 auto;
  justify-content: center;
`;

const AdvantagesSection = styled.div`
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: ${(props) => (!props.isAccepted ? 'flex-start' : 'center')};
  height: 150px;
  width: 460px;
  flex: 0 0 auto;
  align-items: flex-start;
`;

const AdvantageContent = styled.div`
  flex-direction: column;
  height: 70%;
  margin-bottom: 30px;
`;

const UnorderedList = styled.ul`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  list-style-position: inside;
`;

const StyledListItem = styled.li`
  list-style: none;
  background-image: url(${checkmark});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 14px;
  padding: 0 0 6px 14px;
`;

const ToyControlAdvantages: FC<IProps> = ({ lovenseToyStatus }) => {
  const isAccepted = lovenseToyStatus === LovenseToyStatusEnum.accepted;
  return (
    <DynamicAdvantageContainer isAccepted={isAccepted}>
      {isAccepted && (
        <VideoDiv>
          <iframe
            src="https://player.vimeo.com/video/340161994"
            width="100%"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </VideoDiv>
      )}
      <AdvantagesSection isAccepted={isAccepted}>
        <AdvantageContent>
          <h2 css={!isAccepted ? { marginBottom: '33px' } : { marginBottom: '15px' }}>
            {_('settings:toycontrol.advantages.headline')}
          </h2>
          <UnorderedList>
            <StyledListItem>{_('settings:toycontrol.advantages.argument.one')}</StyledListItem>
            <StyledListItem>{_('settings:toycontrol.advantages.argument.two')}</StyledListItem>
            <StyledListItem>
              {_('settings:toycontrol.advantages.argument.three')}{' '}
              {isAccepted ? (
                <NavLink to={`${APP_BASE_PATH}/finances/overview`}>
                  {_('settings:toycontrol.advantages.argument.livechats')}
                </NavLink>
              ) : (
                <span>{_('settings:toycontrol.advantages.argument.livechats')}</span>
              )}
            </StyledListItem>
          </UnorderedList>
        </AdvantageContent>
      </AdvantagesSection>
    </DynamicAdvantageContainer>
  );
};

export default ToyControlAdvantages;
