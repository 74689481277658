import React, { FC, useState } from 'react';
import { Paper, Container, Box, IconButton } from '@material-ui/core';
import { bannerUniversal, OnboardingStepper, useStepperStyles } from '.';
import { WHITE } from '../../../../../camtool-styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import FirstStepChild from './OnboardingStepper/FirstStepChild';
import SecondStepChild from './OnboardingStepper/SecondStepChild';
import LastStepChild from './OnboardingStepper/LastStepChild';
import NewButton from '../../../../../atoms/Button/NewButton';

const CompetitionOnboarding: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStepperStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepContent = [
    {
      headline: '💯 Mehr Transparenz',
      subHeadline:
        'Bleibe up-to-date mit Hilfe vieler Zusatzinfos und Statistiken. Auf deinem Dashboard kannst Du immer den aktuellen Stand der Challenge einsehen.',
      children: <FirstStepChild />,
    },
    {
      headline: '👏 Gewinnchancen für Alle',
      subHeadline: `Egal ob Newbie oder Profi, jeder kann bei den VXGames gewinnen! Unter allen Teilnehmerinnen, die eine Herausforderung erfolgreich abgeschlossen haben, werden Geldprämien im Gesamtwert von über 30.000 € verlost.`,
      children: <SecondStepChild />,
    },
    {
      headline: '😎 Bist du bereit für Deine erste Herausforderung?',
      subHeadline:
        'Bestätige nur noch die Teilnahmebedingungen und starte mit Deiner ersten Herausforderung.',
      children: <LastStepChild />,
    },
  ];

  return (
    <Container style={{ padding: '25px', fontSize: '0' }}>
      <img style={{ width: '100%' }} src={bannerUniversal} />
      <Paper variant="elevation" style={{ backgroundColor: WHITE, borderRadius: '0px' }}>
        <Box display="flex" alignItems="center" flexDirection="column" p={5}>
          <OnboardingStepper {...stepContent[activeStep]} />
          <Box display="flex" flexDirection={'column'} mt={2} maxWidth="300px">
            {activeStep < stepContent.length - 1 && (
              <NewButton onClick={handleNext}>Weiter</NewButton>
            )}
            <MobileStepper
              classes={classes}
              variant="dots"
              steps={3}
              position="static"
              activeStep={activeStep}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export { CompetitionOnboarding };
