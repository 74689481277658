import React, { ChangeEvent, FC, ReactElement } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import { IFormData } from '../../../types';
import { _ } from '../../../../../util/translate';
import { Box, Typography } from '@material-ui/core';
import { MUICheckbox, MUICheckboxFormControlLabel } from '../../../../../components/MUI';
import { InputSlider } from '../../../../../molecules';

const VoucherAmount: FC<Pick<FormikProps<IFormData>, 'values' | 'setFieldValue'>> = ({
  values,
  setFieldValue,
}) => {
  return (
    <>
      <FieldLayout label={_('common:text.amount')}>
        <Field
          name="voucherUseUnlimited"
          components
          render={({ field }: FieldProps): ReactElement => {
            function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
              setFieldValue('voucherUseUnlimited', e.target.checked);
            }

            return (
              <div>
                <MUICheckboxFormControlLabel
                  style={{ margin: 0 }}
                  color="secondary"
                  checked={values.voucherUseUnlimited}
                  control={
                    <MUICheckbox
                      style={{ padding: 0, alignSelf: 'flex-start' }}
                      name={field.name}
                      value={field.value}
                      onChange={handleChange}
                      onBlur={field.onBlur}
                    />
                  }
                  label={
                    <Box display="flex" flexDirection="column" ml={1}>
                      <Typography variant="body1">
                        {_('benefits:voucher.detailsPage.quantityUnlimitedTitle')}
                      </Typography>
                      <Typography variant="subtitle2">
                        {_('benefits:voucher.detailsPage.quantityUnlimitedDescription')}
                      </Typography>
                    </Box>
                  }
                />
              </div>
            );
          }}
        />
      </FieldLayout>

      {/* Number of voucher use allowed */}
      <Field
        name="voucherUseMax"
        render={({ field }: FieldProps): ReactElement => {
          const handleChange = (
            newValue: number | number[],
            event?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ): void => {
            setFieldValue('voucherUseMax', newValue);
          };
          const sliderProps = {
            value: field.value,
            onChange: handleChange,
            min: 1,
            max: 200,
            step: 1,
            disabled: values.voucherUseUnlimited,
          };

          return (
            <FieldLayout
              label={_('benefits:voucher.texts.maximalNumber')}
              error=""
              css={{ display: values.voucherUseUnlimited ? 'none !important' : 'flex' }}
            >
              <InputSlider
                inputProps={{ ...sliderProps, ...{ showDecimal: false } }}
                sliderProps={{ ...sliderProps }}
              />
            </FieldLayout>
          );
        }}
      />
    </>
  );
};

export default VoucherAmount;
