import { Dispatch } from 'react';
import {
  AboBenefitSubType,
  AboContentType,
  AboContentTypesFilter,
  AboRoute,
  AboWizardActionType,
  IAboWizardAction,
} from '../../types';

const TYPES: AboContentType[] = [
  AboContentType.VXPagesCredits,
  AboContentType.Media,
  AboContentType.FreeMessages,
  AboContentType.Telegram,
  AboContentType.Custom,
];

const TYPES_WITHOUT_REDEEM_PAGE: AboContentType[] = [
  AboContentType.Telegram,
  AboContentType.Custom,
];

const TYPES_WITH_PICTURE_UPLOAD: AboContentType[] = [AboContentType.Telegram];

export const isContentTypeWithoutRedeemPage = (type: AboContentType): boolean =>
  TYPES_WITHOUT_REDEEM_PAGE.includes(type);

export const isContentTypeWithPictureUpload = (type: AboContentType): boolean =>
  TYPES_WITH_PICTURE_UPLOAD.includes(type);

export const getFilteredContentTypes = (typesFilter?: AboContentTypesFilter): AboContentType[] =>
  TYPES.filter(
    (type) =>
      !typesFilter ||
      (typesFilter.includedAboContentTypes
        ? typesFilter.includedAboContentTypes.includes(type)
        : !typesFilter.excludedAboContentTypes ||
          !typesFilter.excludedAboContentTypes.includes(type))
  );

export const getSelectContentTypeCallback = (dispatch: Dispatch<IAboWizardAction>) => (
  aboContentType: AboContentType,
  replace = false
): void => {
  dispatch({ type: AboWizardActionType.SetAboContentType, payload: { aboContentType } });

  if (isContentTypeWithoutRedeemPage(aboContentType)) {
    // Since some abo types do not have a `AboRoute.AboRedeemPage`, we set the aboRedeemDetails right here
    // and navigate to `AboRoute.AboDetailsPage` instead of to `AboRoute.AboRedeemPage`
    dispatch({
      type: AboWizardActionType.SetAboRedeemDetails,
      payload: {
        aboRedeemDetails: {
          data: { amount: '' },
          type: AboBenefitSubType.Custom,
        },
      },
    });
    dispatch({
      type: AboWizardActionType.GoToRoute,
      payload: { route: AboRoute.AboDetailsPage, replace },
    });
  } else {
    dispatch({
      type: AboWizardActionType.GoToRoute,
      payload: { route: AboRoute.AboRedeemPage, replace },
    });
  }
};
