import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import * as Formsy from 'formsy-react';
import AbstractFormComponent from '../Form/AbstractFormComponent';
import { forEach } from 'lodash';

class AbstractDeepLComponent extends AbstractFormComponent {
  constructor(props) {
    props.value._translate = cloneDeep(props.translated);
    super(props);

    this.state = Object.assign(this.state, {
      translate: this.props.translated,
    });

    this.onChange = this.onChange.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onChange = this.onChange.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.setTranslateValue = this.setTranslateValue.bind(this);
    this.getTranslateValue = this.getTranslateValue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    nextProps.value._translate = cloneDeep(nextProps.value.translate);
    if (!isEqual(this.props.translated, nextProps.value.translate)) {
      this.setState({
        translate: nextProps.value.translate,
      });
    }

    if (!this.state._value._translate) {
      this.setState({
        _translate: cloneDeep(nextProps.value),
      });
    }

    super.componentWillReceiveProps.bind(this)(nextProps);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdateProps = !isEqual(this.props, nextProps);
    const shouldUpdateState = !isEqual(this.state, nextState);

    return shouldUpdateProps || shouldUpdateState;
  }

  getValue() {
    let translateValues = super.getValue();
    return typeof translateValues === 'object'
      ? this.findAndRemoveOldLangs(translateValues, this.props.langs[0])
      : {};
  }

  onChange(event) {
    this.setTranslateValue('translate', event.target.value);
    this.props.onChange(event);
  }

  getTranslateValue() {
    const translateValue = this.getValue();
    return translateValue['translate']
      ? translateValue['translate']
      : translateValue['translated'] || '';
  }

  findAndRemoveOldLangs(values, lang = 'de') {
    const keys = Object.keys(values);
    forEach(keys, (key) => {
      if (key === lang || key === 'translated') {
        values.translate = values[key];
      }
      if (key !== 'translate' && key !== '_translate') {
        delete values[key];
      }
    });
    return values;
  }

  setTranslateValue(target, value) {
    const translateValue = this.getValue();
    translateValue._translate = translateValue._translate || this.props.translated;
    if (translateValue[target] !== value) {
      delete translateValue?.translated;
      translateValue[target] = value;
      this.setValue(
        Object.entries(translateValue).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
      );
      this.forceUpdate(); // because of shouldComponentUpdate, we have to forceUpdate here
    }
  }
}

AbstractDeepLComponent.propTypes = {
  requiredMessage: PropTypes.string,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  translated: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

AbstractDeepLComponent.defaultProps = Object.assign(AbstractFormComponent.defaultProps || {}, {
  placeholder: 'default deepl placeholder',
  minLength: 0,
  maxLength: 0,
  translated: '',
  onChange: () => {},
});

// Validators
Formsy.addValidationRule('isValidDeepLField', function (values, value) {
  return !!value && !!value.translate;
});

export default AbstractDeepLComponent;
