import React, { ReactElement } from 'react';

const CancelIcon = (): ReactElement => {
  return (
    <g id="Abgebrochen" transform="translate(-1642 -4590)">
      <circle id="Kreis" cx="21" cy="21" r="21" transform="translate(1642 4590)" fill="#ff4d3c" />
      <rect
        id="Rechteck_1128"
        data-name="Rechteck 1128"
        width="3"
        height="18"
        transform="translate(1668.806 4603.576) rotate(45)"
        fill="#fff"
      />
      <rect
        id="Rechteck_1130"
        data-name="Rechteck 1130"
        width="3"
        height="18"
        transform="translate(1670.928 4616.303) rotate(135)"
        fill="#fff"
      />
    </g>
  );
};

export default CancelIcon;
