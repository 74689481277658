var AppDispatcher = require('../../util/dispatcher/AppDispatcher').default;

var React = require('react');
var update = require('immutability-helper');

var EventEmitter = require('events').EventEmitter;
var ActionTypes = require('../../util/constants/ActionTypes');

var CHANGE_EVENT = 'MarketingStore.change';

var _marketing = {
  'marketing.settings': {
    issetMarketingFlag: true,
    isMarketingDisabled: false,
    issetTubeMarketingFlag: false,
    isTubeMarketingDisabled: true,
    isLessThan20Videos: true,
  },
  'marketing.admails': {},
  'marketing.admailsarchive': {
    totalEntries: -1,
    data: [],
    sorting: {},
    pagination: {},
  },
  'marketing.favouriteUsers': {
    totalEntries: -1,
    data: [],
    sorting: {},
    pagination: {},
  },
  'marketing.campaigns': {},
};

var Phx = global.Phx || {};
Phx.Stores = Phx.Stores || {};
Phx.Stores.MarketingStore = Object.assign({}, EventEmitter.prototype, {
  emitChange: function(store) {
    this.emit(CHANGE_EVENT + '.' + store);
  },
  addChangeListener: function(store, callback) {
    this.on(CHANGE_EVENT + '.' + store, callback);
  },
  removeChangeListener: function(store, callback) {
    this.removeListener(CHANGE_EVENT + '.' + store, callback);
  },
  get: function(store) {
    if (!_marketing[store]) {
      throw new InvalidStoreException(store);
    }
    return _marketing[store];
  },
});

function InvalidStoreException(store) {
  this.STORE = store;
  this.message = 'Invalid STORE';
  this.toString = function() {
    return this.message + ': ' + this.store;
  };
}

AppDispatcher.register(function(payload) {
  switch (payload.type) {
    case ActionTypes.MARKETING_RECEIVE_DATA:
      var store = payload.store;
      _marketing[store] = update(_marketing[store], { $merge: payload.data });
      Phx.Stores.MarketingStore.emitChange(store);
      break;
    case ActionTypes.MARKETING_APPEND_DATA:
      var store = payload.store;
      _marketing[store].data = update(_marketing[store].data, { $push: payload.data });
      Phx.Stores.MarketingStore.emitChange(store);
      break;
    default:
    // do nothing
  }
});

module.exports = Phx.Stores.MarketingStore;
