import React, { FC, memo, useState } from 'react';
import Thread from './Thread';
import { _ } from '../../../../../util/translate';
import { InlineEditor } from '../../../../../atoms';
import { FancyError, Translation } from '../../../../../components';
import { useTelegramSendMessage, useTelegramUserMessages } from '../../../hooks';
import { User } from '../../../../../graphql/VXServicesTelegram/types';
import { useFileStorageSettings } from '../../../hooks';
import styled from '@emotion/styled';
import ThreadContainerHeader from './ThreadContainerHeader';
import ModalBlockUser from '../ModalBlockUser';
import { WHITE_2 } from '../../../../../camtool-styles';
import { CHAT_FETCH_INTERVAL } from '../utils';

const RankBanner = styled.div`
  display: flex;
  justify-content: center;
  background-color: #43b31c66;
  width: 100%;
  padding: 5px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  isolation: isolate;
`;

const limit = 50;

const InnerContainer = styled.div`
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

interface IProps {
  selectedUser: User;
  currency: string | undefined;
}

const ThreadContainer: FC<IProps> = memo(({ selectedUser, currency }) => {
  const [isBlockModalOpen, setIsBlockModalOpen] = useState<boolean>(false);
  const onClose = (): void => setIsBlockModalOpen(false);
  const onOpen = (): void => setIsBlockModalOpen(true);

  const userId = selectedUser.id;
  const {
    data: groupedChat,
    fetchMore,
    loading,
    error,
  } = useTelegramUserMessages(
    { userId },
    { fetchPolicy: 'cache-and-network', pollInterval: CHAT_FETCH_INTERVAL }
  );

  const fileStorageSettings = useFileStorageSettings();
  const [sendMessage, { loading: sending }] = useTelegramSendMessage({ userId });
  const messagesLength = groupedChat?.length;

  if (error) {
    return <FancyError error={error} />;
  }

  return (
    <div css={{ flexDirection: 'column', flex: 1 }}>
      {userId > 0 ? (
        <>
          <InnerContainer className={'spinner-container'}>
            <ThreadContainerHeader selectedUser={selectedUser} showModalWindow={onOpen} />
            {isBlockModalOpen && <ModalBlockUser onClose={onClose} userId={userId} />}
            {Boolean(selectedUser?.rankBadge) && (
              <RankBanner>
                <span>
                  {_('telegram:chat.system.ranking')} {selectedUser?.rankBadge}
                </span>
              </RankBanner>
            )}
            <Thread
              userId={userId}
              groupedChat={groupedChat}
              loading={loading}
              currency={currency}
              fetchMore={messagesLength > limit && fetchMore}
            />
          </InnerContainer>
          <div
            css={{
              backgroundColor: WHITE_2,
              padding: 8,
              boxShadow: '0px -1px 11px -6px rgb(0 0 0 / 96%)',
              position: 'relative',
            }}
          >
            <InlineEditor
              emojiPicker
              uploadPicker
              onSubmit={({ text, fileUUID, price }) =>
                sendMessage({ variables: { text, fileUUID, price } })
              }
              sending={sending}
              fileStorageSettings={fileStorageSettings}
            />
          </div>
        </>
      ) : (
        <div
          css={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 18,
          }}
        >
          <Translation t={'telegram:chat.channel.select'} />
        </div>
      )}
    </div>
  );
});
ThreadContainer.displayName = 'ThreadContainer';

export default ThreadContainer;
