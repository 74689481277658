import React, { FC, useEffect, useState } from 'react';
import { IContentOthersProps, RedemptionAmount, RedemptionType } from '../../../types';
import { _ } from '../../../../../util/translate';
import { useHasRole } from '../../../../../util/UserData';
import { SecurityRole } from '../../../../../graphql/VXModels/types';
import Layout from '../Layout';
import FieldLayout from '../../../../../atoms/FormFIeldsCtrl/FieldLayout';
import Select from '../../../../../atoms/Select/Select';
import ContentMediaSelection from './ContentMediaSelection';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/react-hooks';
import {
  AVAILABLE_MIN_AMOUNTS,
  QUERY_ATLEASTONEACTORDOC,
} from '../../../../../graphql/VXModels/queries';

const SelectRow = styled.div`
  flex-direction: row;
`;

const ContentMedia: FC<IContentOthersProps> = ({
  state,
  dispatch,
  className,
  availableContentMinAmountsArray,
}) => {
  const [redemptionType, setRedemptionType] = useState<RedemptionType>(
    state.voucherContent.length > 0 ? state.voucherContent[0].redemptionType : ''
  );
  const [redemptionAmount, setRedemptionAmount] = useState<RedemptionAmount>(
    state.voucherContent.length > 0 ? state.voucherContent[0].redemptionAmount : -1
  );

  const areRedemptionSettingsValid =
    !!redemptionType &&
    (redemptionType === 'GRATIS' ? redemptionAmount === 0 : redemptionAmount !== -1);

  const handleRedemptionTypeChange = (type: string): void => {
    let newRedemptionType: RedemptionType = '';
    let newRedemptionAmount: RedemptionAmount = -1;

    if (type === 'GRATIS') {
      newRedemptionType = type;
      newRedemptionAmount = 0;
    } else if (type === 'ON_TOP') {
      newRedemptionType = type;
      newRedemptionAmount = -1;
    }

    setRedemptionType(newRedemptionType);
    setRedemptionAmount(newRedemptionAmount);
  };

  const handleRedemptionAmountChange = (amount: string): void => {
    setRedemptionAmount(Number(amount));
  };

  useEffect(() => {
    if (areRedemptionSettingsValid) {
      // Update all existing media contents with the new redemption settings
      dispatch({
        type: 'CHANGE_EXISTING_BENEFIT_VALUES',
        payload: {
          voucherType: 'MEDIA',
          values: {
            redemptionType,
            redemptionAmount,
          },
        },
      });
    }
  }, [redemptionType, redemptionAmount, areRedemptionSettingsValid]);

  return (
    <Layout
      className={className}
      title={_('benefits:voucher.media.title')}
      subtitle={_('benefits:voucher.media.subtitle')}
    >
      <SelectRow>
        <FieldLayout
          label={_('benefits:voucher.texts.couponRedeemType')}
          error=""
          css={{ marginBottom: 24, flex: '1 0 0', marginRight: 8, maxWidth: 'calc(50% - 8px)' }}
        >
          <Select
            value={redemptionType}
            name="voucherType"
            placeholder={_('benefits:voucher.texts.pleaseChoose')}
            onChange={(event): void => handleRedemptionTypeChange(event.target.value)}
            options={[
              { label: _('benefits:voucher.texts.free'), value: 'GRATIS' },
              { label: _('benefits:voucher.texts.onTop'), value: 'ON_TOP' },
            ]}
          />
        </FieldLayout>

        {redemptionType === 'ON_TOP' && (
          <FieldLayout
            label={_('benefits:voucher.texts.minimumTopUpAmount')}
            error=""
            css={{ marginBottom: 24, flex: '1 0 0', marginLeft: 8 }}
          >
            <Select
              value={String(redemptionAmount)}
              name="redemptionAmount"
              onChange={(event): void => handleRedemptionAmountChange(event.target.value)}
              options={availableContentMinAmountsArray || []}
            />
          </FieldLayout>
        )}
      </SelectRow>

      {areRedemptionSettingsValid && (
        <FieldLayout
          label={_('benefits:voucher.texts.chooseContent')}
          error=""
          css={{ flex: '0 1 auto', marginBottom: 24, alignItems: 'flex-start' }}
        >
          <ContentMediaSelection
            state={state}
            dispatch={dispatch}
            redemptionType={redemptionType}
            redemptionAmount={redemptionAmount}
          />
        </FieldLayout>
      )}
    </Layout>
  );
};

export default ContentMedia;
