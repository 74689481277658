import React from 'react';
import { withRouter } from 'react-router-dom';

import { getFormattedAmount } from '../../../util/Formatter';

import DefaultWidget from './DefaultWidget';
import { APP_BASE_PATH } from '../../../util/env';
import { useLang } from '../../../util/AppState';
import { _ } from '../../../util/translate';

const Finance = React.memo(({ loading, className, amount, error, history }) => {
  const goToFinancesPayout = () => history.push(APP_BASE_PATH + '/finances/payout');
  const [lang] = useLang();

  return (
    <DefaultWidget
      loading={loading}
      onClick={goToFinancesPayout}
      className={className}
      tabIndex={1}
      css={{ cursor: 'pointer' }}
      iconName="money"
      iconColor="#43B31C"
      label={_('dashboard:app.finance.header')}
      data={getFormattedAmount(!!amount ? amount : 0, lang)}
      error={error}
    />
  );
});

export default withRouter(Finance);
