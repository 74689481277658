import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../../components';

class IFrame extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.frameWindow = React.createRef();

    this.onLoad = this.onLoad.bind(this);
    this.onMessage = this.onMessage.bind(this);
  }

  componentWillMount() {
    window.addEventListener('message', this.onMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }

  onLoad() {
    this.frameWindow.current.contentWindow.postMessage(1, '*');
    this.setState({ loading: false });
  }

  onMessage({ origin, data }) {
    // console.log(this.__proto__.constructor.name, 'onMessage', origin, data);
    if (origin.indexOf('.smartvx.x') !== -1 || origin.indexOf('www.vxtool.net') !== -1) {
      // console.log(this.__proto__.constructor.name, 'onMessage', data);
      this.frameWindow.current.style.minHeight = data.height + 'px';
    }
  }

  render() {
    const { allowFullScreen, display, height, scrolling, src, style, width } = this.props;
    const { loading } = this.state;

    return (
      <div className="spinner-container">
        {loading && <Spinner />}
        <iframe
          ref={this.frameWindow}
          frameBorder="0"
          src={src}
          target="_parent"
          allowFullScreen={allowFullScreen}
          style={{
            display,
            flex: 1,
            border: 'none',
            ...style,
          }}
          height={Math.max(300, height)} // Cross-origin plugin content from  must have a visible size larger than 400 x 300 pixels, or it will be blocked. Invisible content is always blocked
          width={Math.max(400, width)} // Cross-origin plugin content from  must have a visible size larger than 400 x 300 pixels, or it will be blocked. Invisible content is always blocked
          onLoad={this.onLoad}
          scrolling={scrolling === 'yes' ? 'yes' : 'no'}
        />
      </div>
    );
  }
}

IFrame.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  display: PropTypes.string,
  height: PropTypes.number,
  styles: PropTypes.object,
  allowFullScreen: PropTypes.bool,
};

IFrame.defaultProps = {
  width: '100%',
  height: '100%',
  display: 'flex',
  styles: {},
  allowFullScreen: false,
};

export default IFrame;
export { IFrame };
