import React from 'react';
import PropTypes from 'prop-types';
import AbstractFormComponent from '../AbstractFormComponent';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';
import clone from 'lodash/clone';
import { translateOptions } from '../../../atoms/Select/utils';

const objectAssign = require('object-assign');
require('@babel/polyfill');

function getSelectionState(values, options) {
  let state = {
    _selectedOptions: [],
    _unselectedOptions: [],
  };

  for (let option of options) {
    const idx = values.indexOf(option.value);
    if (idx !== -1) {
      state._selectedOptions[idx] = option;
    } else {
      state._unselectedOptions.push(option);
    }
  }

  return state;
}

class AbstractFormMultiOptionsComponent extends AbstractFormComponent {
  constructor(props) {
    super(props);
    this.options = translateOptions(props.options);
    //this.state = Object.assign(
    this.state = objectAssign(this.state || {}, getSelectionState(this.props.value, this.options));

    this.getValue = this.getValue.bind(this);
  }

  addValue(value) {
    const values = clone(this.getValue());
    if (this.props.maxLength === 0 || values.length < this.props.maxLength) {
      values.push(value);

      if (this.props.onAddValue) {
        this.props.onAddValue(values, value);
      }

      if (value !== 'noModifications') {
        // if the new value is not noModifications, make sure noModifications is
        // not inside the values array
        this.setValue(values.filter(val => val !== 'noModifications'));
      } else {
        this.setValue(values);
      }
    }
  }

  addOption(option) {
    this.addValue(option.value);
  }

  removeValue(value) {
    const values = clone(this.getValue());
    remove(values, function(v) {
      return v === value;
    });

    if (this.props.onRemoveValue) {
      this.props.onRemoveValue(values, value);
    }

    this.setValue(values);
    this.setState(getSelectionState(values, this.options));
  }

  removeAllBut(value) {
    const values = [value];
    this.setValue(values);
    this.setState(getSelectionState(values, this.options));
  }

  removeOption(option) {
    this.removeValue(option.value);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.options, nextProps.options)) {
      this.options = translateOptions(nextProps.options);
    }

    if (this.props.value != nextProps.value) {
      this.setState(getSelectionState(nextProps.value, this.options));
    }
    super.componentWillReceiveProps(nextProps);
  }

  isOptionDisabled(option) {
    return this.isOptionSelected(option);
  }

  isOptionSelected(option) {
    let values = this.getValue();
    return values.indexOf(option.value) !== -1;
  }

  isDisabled() {
    let values = this.getValue();

    return !!(
      values.length >= parseInt(this.props.maxLength) || values.length >= this.options.length
    );
  }

  getValue() {
    let values = super.getValue();
    return Array.isArray(values) ? values : []; // emtpy array from REST API is parsed as empty object, this will fix that
  }

  setValue(values) {
    this.setState(getSelectionState(values, this.options), () => super.setValue(values));
  }

  getSelectedOptions() {
    return this.state._selectedOptions;
  }

  getUnselectedOptions() {
    return this.state._unselectedOptions;
  }
}

AbstractFormMultiOptionsComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired, // emtpy array from REST API is parsed as empty object, so object is also allowed here
  options: PropTypes.array.isRequired,
  onRemoveValue: PropTypes.func,
  onAddValue: PropTypes.func,
  requiredMessage: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};

//AbstractFormMultiOptionsComponent.defaultProps = Object.assign(AbstractFormComponent.defaultProps || {}, {
AbstractFormMultiOptionsComponent.defaultProps = objectAssign(
  AbstractFormComponent.defaultProps || {},
  {
    value: [],
    options: [],
    minLength: 0,
    maxLength: 0,
  }
);

export default AbstractFormMultiOptionsComponent;
