import * as ActionTypes from '../../util/constants/ActionTypes';
import ServerDispatcher from '../../util/dispatcher/ServerDispatcher';
import { EventEmitter } from 'events';
import ServerUtils from '../../util/ServerUtils';
import { isFunctionalAccepted } from '../../util/CookieConsent';

const URL_FETCH_COUNTY_POSTALCODE =
  '/v1/metadata/vxmodels/geodata/country/{country}/postalcode/{postalCode}';
const URL_FETCH_COUNTY_ONLY = '/v1/metadata/vxmodels/geodata/country/{country}';

const CHANGE_EVENT = 'geoService.change';
const WHOLE_COUNTRY = '__country__';
const CACHE_NAMESPACE = 'cache.geoData';

class GeoServiceStoreClass extends EventEmitter {
  constructor() {
    super();
    this.geoData = {};
    ServerDispatcher.register((payload) => {
      switch (payload.type) {
        case ActionTypes.GEODATA_RECEIVE_DATA:
          let geoData = payload.data;
          if (!Object.is(this.geoData, geoData)) {
            this.geoData = geoData;
            this.emitChange();
          }
          break;
      }
    });
  }

  emitChange() {
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  get() {
    return this.geoData;
  }
}

class GeoServiceActionCreatorsClass {
  constructor() {
    this.lastQuery = { country: null, postalCode: null };
  }

  readResource(countryCode, postalCode, noCache) {
    postalCode = postalCode || WHOLE_COUNTRY;
    const query = { country: countryCode, postalCode: postalCode };
    const cache = JSON.parse(window.localStorage.getItem(CACHE_NAMESPACE) || '{}');
    const changed =
      this.lastQuery.country !== query.country || this.lastQuery.postalCode !== query.postalCode;

    if (changed && countryCode) {
      this.lastQuery = query;
      const url =
        postalCode === WHOLE_COUNTRY ? URL_FETCH_COUNTY_ONLY : URL_FETCH_COUNTY_POSTALCODE;
      const cacheKey = countryCode + '.' + postalCode;
      if (!noCache && cache[cacheKey]) {
        ServerDispatcher.dispatch({
          type: ActionTypes.GEODATA_RECEIVE_DATA,
          data: cache[cacheKey],
        });
      } else {
        const params = {
          routeParams: query,
          options: { useResponseData: false },
        };
        ServerUtils.request(
          'GET',
          url,
          params,
          (response) => {
            cache[cacheKey] = response;
            if (isFunctionalAccepted()) {
              window.localStorage.setItem(CACHE_NAMESPACE, JSON.stringify(cache));
            }
            ServerDispatcher.dispatch({
              type: ActionTypes.GEODATA_RECEIVE_DATA,
              data: response,
            });
          },
          (err, response) => {
            cache[cacheKey] = response;
            if (isFunctionalAccepted()) {
              window.localStorage.setItem(CACHE_NAMESPACE, JSON.stringify(cache));
            }
          }
        );
      }
    }
  }
}

const GeoServiceStore = new GeoServiceStoreClass();
const GeoServiceActionCreators = new GeoServiceActionCreatorsClass();

export { GeoServiceStore, GeoServiceActionCreators };
