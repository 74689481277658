import React, { ReactElement } from 'react';

const SharePhone = (): ReactElement => {
  return (
    <>
      <path id="Pfad_11713" data-name="Pfad 11713" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Pfad_11714"
        data-name="Pfad 11714"
        d="M18.089,1.011,7.181,1A2.179,2.179,0,0,0,5.01,3.182V22.816A2.179,2.179,0,0,0,7.181,25H18.089a2.188,2.188,0,0,0,2.182-2.182V3.182A2.179,2.179,0,0,0,18.089,1.011Zm0,19.623H7.181V5.363H18.089Zm-4.581-6.3v1.909L17,12.977,13.507,9.726v1.854a5.927,5.927,0,0,0-5.236,5.127A5.9,5.9,0,0,1,13.507,14.329Z"
        transform="translate(-0.641 -1)"
        fill="currentColor"
      />
    </>
  );
};

export default SharePhone;
