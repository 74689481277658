import React from 'react';
import AbstractComponent from '../../components/AbstractComponent';

class ActorIconsIndex extends AbstractComponent {
  render() {
    return (
      <main className="vxgames2017-overview grid">

        <div className="grid__row">
          <div className="grid__column grid__box">

            <div className="grid__box__item">
              <div className="grid__box__item__content">

                <div className="glyphicon-index">

                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-place-1" />
                      <h4>vxactor-award-place-1</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-place-1" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-place-2" />
                      <h4>vxactor-award-place-2</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-place-2" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-place-3" />
                      <h4>vxactor-award-place-3</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-place-3" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-chat-place-1" />
                      <h4>vxactor-award-chat-place-1</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-chat-place-1" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-chat-place-2" />
                      <h4>vxactor-award-chat-place-2</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-chat-place-2" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-chat-place-3" />
                      <h4>vxactor-award-chat-place-3</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-chat-place-3" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-hetero" />
                      <h4>vxactor-hetero</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span vxactor-hetero />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-transgender" />
                      <h4>vxactor-transgender</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-transgender" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-fetish-check" />
                      <h4>vxactor-award-fetish-check</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-fetish-check" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-eyes-color" />
                      <h4>vxactor-fact-eyes-color</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-eyes-color" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-excellent-active" />
                      <h4>vxactor-award-user-excellent-active</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-user-excellent-active" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-active" />
                      <h4>vxactor-award-user-active</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-user-active" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-top10" />
                      <h4>vxactor-award-user-top10</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-user-top10" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-top50" />
                      <h4>vxactor-award-user-top50</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-user-top50" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-top100" />
                      <h4>vxactor-award-user-top100</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-user-top100" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-fav-active" />
                      <h4>vxactor-award-fav-active</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-fav-active" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-fav-very-active" />
                      <h4>vxactor-award-fav-very-active</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-fav-very-active" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-fav-excellent-active" />
                      <h4>vxactor-award-fav-excellent-active</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-fav-excellent-active" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-new-user" />
                      <h4>vxactor-award-new-user</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-new-user" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-new-user-de" />
                      <h4>vxactor-award-new-user-de</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-new-user-de" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-hd" />
                      <h4>vxactor-award-user-hd</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-user-hd" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-good" />
                      <h4>vxactor-award-user-good</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-user-good" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-verygood" />
                      <h4>vxactor-award-user-verygood</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-user-verygood" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-excellent" />
                      <h4>vxactor-award-user-excellent</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className=vxactor-award-user-excellent />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-ranking-1" />
                      <h4>vxactor-award-user-ranking-1</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className=vxactor-award-user-ranking-1 />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-ranking-2" />
                      <h4>vxactor-award-user-ranking-2</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className=vxactor-award-user-ranking-2 />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-user-ranking-3" />
                      <h4>vxactor-award-user-ranking-3</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className=vxactor-award-user-ranking-3 />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-video-top10" />
                      <h4>vxactor-award-video-top10</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-video-top10" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-video-top50" />
                      <h4>vxactor-award-video-top50</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-video-top50" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-award-video-top100" />
                      <h4>vxactor-award-video-top100</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-award-video-top100" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-age" />
                      <h4>vxactor-fact-age</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className=vxactor-fact-age />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-birthday" />
                      <h4>vxactor-fact-birthday</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-birthday" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-body-decorations" />
                      <h4>vxactor-fact-body-decorations</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-body-decorations" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-body" />
                      <h4>vxactor-fact-body</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-body" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-breast-size" />
                      <h4>vxactor-fact-breast-size</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-breast-size" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-country" />
                      <h4>vxactor-fact-country</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-country" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-distance" />
                      <h4>vxactor-fact-distance</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-distance" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-female-body" />
                      <h4>vxactor-fact-female-body</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-female-body" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-female-height" />
                      <h4>vxactor-fact-female-height</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-female-height" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-femalevxactor" />
                      <h4>vxactor-fact-femalevxactor</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-femalevxactor" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-hair-female" />
                      <h4>vxactor-fact-hair-female</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-hair-female" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-hair-male" />
                      <h4>vxactor-fact-hair-male</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-hair-male" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-languages" />
                      <h4>vxactor-fact-languages</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-languages" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-male-body" />
                      <h4>vxactor-fact-male-body</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-male-body" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-male-height" />
                      <h4>vxactor-fact-male-height</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-male-height" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-malevxactor" />
                      <h4>vxactor-fact-malevxactor</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-malevxactor" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-orientation" />
                      <h4>vxactor-fact-orientation</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-orientation" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-penis-size" />
                      <h4>vxactor-fact-penis-size</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-penis-size" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-relationship" />
                      <h4>vxactor-fact-relationship</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-relationship" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-sex" />
                      <h4>vxactor-fact-sex</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className=vxactor-fact-sex />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-shave" />
                      <h4>vxactor-fact-shave</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-shave" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-star-sign" />
                      <h4>vxactor-fact-star-sign</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-star-sign" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-fact-weight" />
                      <h4>vxactor-fact-weight</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-fact-weight" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-bookworm" />
                      <h4>vxactor-person-bookworm</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-bookworm" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-coffee" />
                      <h4>vxactor-person-coffee</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-coffee" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-tattooed" />
                      <h4>vxactor-person-tattooed</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-tattooed" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-princess" />
                      <h4>vxactor-person-princess</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-princess" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-night_owl" />
                      <h4>vxactor-person-night_owl</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-night_owl" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-movie_junkie" />
                      <h4>vxactor-person-movie_junkie</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-movie_junkie" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-photographer" />
                      <h4>vxactor-person-photographer</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-photographer" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-artsy" />
                      <h4>vxactor-person-artsy</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-artsy" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-animal_lover" />
                      <h4>vxactor-person-animal_lover</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-animal_lover" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-maker" />
                      <h4>vxactor-person-maker</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-maker" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-diva" />
                      <h4>vxactor-person-diva</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-diva" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-adventurer" />
                      <h4>vxactor-person-adventurer</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-adventurer" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-partier" />
                      <h4>vxactor-person-partier</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-partier" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-hipstep" />
                      <h4>vxactor-person-hipstep</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-hipstep" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-tree_hugger" />
                      <h4>vxactor-person-tree_hugger</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-tree_hugger" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-daredevil" />
                      <h4>vxactor-person-daredevil</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-daredevil" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-spitfire" />
                      <h4>vxactor-person-spitfire</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-spitfire" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-wholesome" />
                      <h4>vxactor-person-wholesome</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-wholesome" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-smart" />
                      <h4>vxactor-person-smart</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-smart" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-sweet" />
                      <h4>vxactor-person-sweet</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-sweet" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-fashionista" />
                      <h4>vxactor-person-fashionista</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-fashionista" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-foodie" />
                      <h4>vxactor-person-foodie</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-foodie" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-athletic" />
                      <h4>vxactor-person-athletic</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-athletic" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-beach_bum" />
                      <h4>vxactor-person-beach_bum</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-beach_bum" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-music_snob" />
                      <h4>vxactor-person-music_snob</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-music_snob" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-goth" />
                      <h4>vxactor-person-goth</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-goth" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-rockstar" />
                      <h4>vxactor-person-rockstar</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-rockstar" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-bdsm" />
                      <h4>vxactor-person-bdsm</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-bdsm" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-chaotic" />
                      <h4>vxactor-person-chaotic</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-chaotic" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-crazy" />
                      <h4>vxactor-person-crazy</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-crazy" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-curvy" />
                      <h4>vxactor-person-curvy</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-curvy" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-domina" />
                      <h4>vxactor-person-domina</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-domina" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-educated" />
                      <h4>vxactor-person-educated</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-educated" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-experienced" />
                      <h4>vxactor-person-experienced</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-experienced" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-honest" />
                      <h4>vxactor-person-honest</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-honest" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-inexperienced" />
                      <h4>vxactor-person-inexperienced</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className=vxactor-person-inexperienced />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-listener" />
                      <h4>vxactor-person-listener</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-listener" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-natural" />
                      <h4>vxactor-person-natural</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-natural" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-naughty" />
                      <h4>vxactor-person-naughty</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-naughty" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-no_taboos" />
                      <h4>vxactor-person-no_taboos</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-no_taboos" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-nympho" />
                      <h4>vxactor-person-nympho</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-nympho" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-patryanimal" />
                      <h4>vxactor-person-patryanimal</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-patryanimal" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-passionate" />
                      <h4>vxactor-person-passionate</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-passionate" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-romantic" />
                      <h4>vxactor-person-romantic</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-romantic" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-seductive" />
                      <h4>vxactor-person-seductive</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-seductive" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-sensitive" />
                      <h4>vxactor-person-sensitive</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-sensitive" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-sharp" />
                      <h4>vxactor-person-sharp</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-sharp" />'}
                    </code>
                  </div>
                  <div className="glyphicon-index__item">
                    <div className="glyphicon-index__item__info">
                      <span className="vxactor-person-submissive" />
                      <h4>vxactor-person-submissive</h4>
                    </div>
                    <code className="glyphicon-index__item__code">
                      {'<span className="vxactor-person-submissive" />'}
                    </code>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ActorIconsIndex;
