import React from 'react';
import styled from '@emotion/styled';
import { styled as muiStyled } from '@material-ui/core/styles';
import ModalBonusDeactivateLabel from './ModalBonusDeactivateLabel';
import { MUICheckbox, MUICheckboxFormControlLabel } from '../../../../../../components/MUI';
import ModalBonusWarningOverlay from './ModalBonusWarningOverlay';
import { _ } from '../../../../../../util/translate';

interface Props {
  name: string;
  title: string;
  subtitle: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  className?: string;
}

const CheckBoxesDiv = styled.div`
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  margin-top: 34px;
  flex: 0 0 auto;
`;

const Container = styled.div`
  padding-left: 4px;
  margin-right: -16px;
  width: 100%;
`;

const StyledMUICheckbox = muiStyled(MUICheckbox)({
  alignSelf: 'start',
});

const ModalBonusDeactivateCheckbox: React.FC<Props> = ({
  name,
  title,
  subtitle,
  onChange,
  checked,
  className,
}) => {
  return (
    <CheckBoxesDiv>
      <Container className={className}>
        <MUICheckboxFormControlLabel
          color="secondary"
          checked={checked}
          control={<StyledMUICheckbox onChange={onChange} name={name} />}
          label={<ModalBonusDeactivateLabel title={title} subtitle={subtitle} />}
        />
        {checked && (
          <ModalBonusWarningOverlay
            title={_('benefits:abo.archive.detailsModal.irreversibleWarning')}
          />
        )}
      </Container>
    </CheckBoxesDiv>
  );
};

export default ModalBonusDeactivateCheckbox;
