import React from 'react';

import AbstractComponent from '../../AbstractComponent';
import FancyHeaderItem from './FancyHeaderItem/FancyHeaderItem';

class FancyHeader extends AbstractComponent {
  render() {
    let colHeaders = [];
    let type = this.props.type;
    return (
      <header
        className={[
          'fancy-table__header',
          type === 'competition' || type === 'stats' ? 'fancy-table_header--dark' : '',
        ].join(' ')}
        style={type === 'stats' ? { paddingLeft: 24, paddingRight: 24 } : {}}
      >
        {this.props.columnFields.forEach((col, i) =>
          colHeaders.push(
            <FancyHeaderItem
              key={i}
              name={col.name}
              label={col.label}
              sortable={col.sortable}
              styles={{ width: col.width + 'px' }}
              onSort={this.props.onSort}
              sortedBy={this.props.sortedBy}
              sortDirection={this.props.sortDirection}
              type={this.props.type}
            />
          )
        )}
        {colHeaders}
      </header>
    );
  }
}

export default FancyHeader;
export { FancyHeader };
