import React, { FC } from 'react';
import { TableCell, TableRow } from '../../../../../atoms/Table';
import { ITableColumn } from '../../../../../atoms/Table/types';
import { IAbo } from '../../../types';
import { useLang } from '../../../../../util/AppState';
import { GREEN, RED } from '../../../../../camtool-styles';
import { _ } from '../../../../../util/translate';
import { getFormattedDate } from '../../../../../util/Formatter';
import { FormatCurrency } from '../../../../../components';
import { ApiLang } from '../../../../../graphql/VXModels/types';
import useTelegramMaster from '../../../../../packages/Telegram/hooks/useTelegramMaster';

interface IProps {
  abo: IAbo;
  columns: ITableColumn[];
  onShowAboDetailsClick: (abo: IAbo) => void;
  displayDeactivationDate: boolean;
}

const getFormattedDeactivationDate = (
  deactivationDate: string | undefined | null,
  lang: ApiLang
): string => (deactivationDate ? getFormattedDate(deactivationDate, lang) : '-');

const AboTableRow: FC<IProps> = ({
  abo,
  columns,
  onShowAboDetailsClick,
  displayDeactivationDate,
}) => {
  const [lang] = useLang();

  const firstBenefit = abo?.benefits?.[0];
  const typeTranslation = firstBenefit?.type?.translation;
  const typeText = typeTranslation ? typeTranslation[lang] || typeTranslation.en : '';
  const subTypeTranslation = firstBenefit?.subType?.translation;
  const subTypeText = subTypeTranslation ? subTypeTranslation[lang] || subTypeTranslation.en : '';

  const isTelegram = firstBenefit?.type?.name === 'Telegram';

  const { data } = useTelegramMaster();
  const currency = data?.master?.currency;

  return (
    <TableRow onClick={(): void => onShowAboDetailsClick(abo)} css={{ cursor: 'pointer' }}>
      <TableCell columns={columns} index={0}>
        <div>
          <span
            className={abo.active ? 'icon-ok' : 'icon-remove'}
            css={{
              color: abo.active ? GREEN : RED,
              marginRight: 4,
            }}
          />
          <span>{_(`benefits:voucher.status.${abo.active ? 'active' : 'inactive'}`)}</span>
        </div>
      </TableCell>
      <TableCell columns={columns} index={1}>{`${typeText} - ${subTypeText}`}</TableCell>
      <TableCell columns={columns} index={2}>
        {abo.name}
      </TableCell>
      <TableCell columns={columns} index={3}>
        {abo.subscriptionCount || '-'}
      </TableCell>
      <TableCell columns={columns} index={4}>
        <FormatCurrency value={abo.price} currency={isTelegram ? currency : 'EUR'} />
      </TableCell>
      <TableCell columns={columns} index={5}>
        {displayDeactivationDate
          ? getFormattedDeactivationDate(abo.deactivatedAt, lang)
          : getFormattedDate(abo.createdAt, lang)}
      </TableCell>
      <TableCell columns={columns} index={6}>
        {abo.renew
          ? abo.paymentInterval > 1
            ? _('benefits:abo.archiveColumnText.everyXDays', {
                sprintf: [abo.paymentInterval],
              })
            : _('benefits:abo.archiveColumnText.everyDay')
          : _('common:text.no').toLowerCase()}
      </TableCell>
    </TableRow>
  );
};

export default AboTableRow;
