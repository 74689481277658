import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedPeriod } from '../../util/Formatter';
import { useLang } from '../../util/AppState';

const FormatDatePeriod = ({ className, from, to, separator }) => {
  const [lang] = useLang();

  return (
    <span className={`${className}`}>{getFormattedPeriod(from, to, lang, false, separator)}</span>
  );
};

FormatDatePeriod.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  separator: PropTypes.string,
};

FormatDatePeriod.defaultProps = {
  className: '',
  separator: '',
};

export default FormatDatePeriod;
export { FormatDatePeriod };
