import React, { FC } from 'react';
import { LeftMenuItem } from '../../../components';
import { _ } from '../../../../../util/translate';
import { notes2 } from '../../../../../atoms/Icon/libraries/glyph';

const ProfileOverviewItem: FC = () => {
  return (
    <LeftMenuItem
      title={_('navigation:main.profile.overview')}
      icon={notes2}
      uri="/onlineprofile/overview"
    />
  );
};

export default ProfileOverviewItem;
