import React, { FC } from 'react';
import { Markdown, T } from '../../components';
import { _ } from '../../util/translate';
import {
  WHITE_2,
  WHITE_3,
  BLACK_2,
  DARK_GRAY,
  BREAKPOINT_PHONE_CONDITION,
  BLUE,
} from '../../camtool-styles';
import { Icon } from '../../atoms';
import { headset } from '../Icon/libraries/glyph';
import { Box } from '@material-ui/core';
import { useMedia } from 'react-media';
interface Props {
  msgTranslationKey: string;
  showNote?: boolean;
  noteTranslationKey?: string;
  email: string;
}

const Contact: FC<Props> = ({
  msgTranslationKey = '',
  noteTranslationKey = '',
  showNote = !!noteTranslationKey,
  email,
}) => {
  const isMobile = useMedia({ query: BREAKPOINT_PHONE_CONDITION });

  return (
    <div className="grid__row">
      <div css={{ backgroundColor: `${WHITE_3}` }} className="grid__column grid__box">
        <div css={{ flexDirection: 'column', color: DARK_GRAY }}>
          {/* 1 */}
          <Box
            display="flex"
            p={2}
            borderBottom={`1px solid ${WHITE_2}`}
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            gridGap={{ xs: '10px', md: '0px' }}
          >
            <Icon
              icon={headset}
              size={isMobile ? '32px' : '24px'}
              css={{ color: BLACK_2, marginRight: '16px' }}
            />
            <div css={{ alignItems: 'center' }}>
              <p>
                <T _={msgTranslationKey} />{' '}
                <a href={`mailto:${email}`} css={{ whiteSpace: 'nowrap', color: BLUE }}>
                  {email}
                </a>
              </p>
            </div>
          </Box>

          {/* 2 */}
          {showNote ? (
            <div css={{ padding: '16px', color: '#7c7c7c' }}>
              <Markdown>{_(noteTranslationKey)}</Markdown>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Contact;
