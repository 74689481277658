import {Entry} from "./Entry";
import * as CryptoJs from "crypto-js";

export class EntryHelper {
    /**
     *
     * @param file
     * @param queue
     * @returns {Entry}
     */
    public static getOrCreateEntry(file: File, queue: Entry[]): Entry {
        let entry: Entry;

        for (let i = 0; i < queue.length; i++) {
            if (
                queue[i].file == file ||
                (queue[i].fileName == file.name && queue[i].binaryLength == file.size)
            ) {
                entry = queue[i];
                entry.file = file;
            }
        }

        if (!entry) {
            entry = new Entry();
            entry.key = EntryHelper.calcKeyFromFile(file);
            entry.file = file;
            entry.fileName = file.name;
            entry.binaryLength = file.size;
            entry.fileLastModified = file.lastModifiedDate ? file.lastModifiedDate.getTime() : 0;
        }

        return entry;
    }

    /**
     *
     * @param file
     * @returns {string}
     */
    public static calcKeyFromFile(file: File) {
        return CryptoJs.MD5(file.name).toString();
    }
}