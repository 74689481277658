import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Modal } from '../../atoms';
import { Dot } from './styles';
import {
  updateQueryAfterMarkRead,
  VXMN_QUERY_NOTIFICATIONS,
  NOTIFICATIONS_LIMIT,
  VXMN_MUTATION_NOTIFICATIONS_MARKREAD,
} from '../../util/NotificationsHelper';
import Translation from '../Translation/Translation';
import { WHITE, BLACK_2, GRAY_2 } from '../../camtool-styles';
import NotificationContent from './NotificationContent';
import { useVXServicesNotificationsClient } from '../../graphql';
import { useLang } from '../../util/AppState';
import { Query, Mutation } from '@apollo/react-components';

const MessageCountDiv = styled.div`
  font-size: 0.9em;
  color: ${GRAY_2};
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const DotDiv = styled.div`
  margin-bottom: 8px;
  justify-content: center;
  font-size: 2em;
`;

const NotificationBox = () => {
  const client = useVXServicesNotificationsClient();
  const [lang] = useLang();
  const [currentMessage, setCurrentMessage] = useState(0);

  const buildVariables = (after) => {
    return {
      lang,
      filter: { preset: 'SNAG_BAR', sort: 'updated_at_desc' },
      count: NOTIFICATIONS_LIMIT,
      after,
    };
  };

  return (
    <Query client={client} query={VXMN_QUERY_NOTIFICATIONS} variables={buildVariables('')}>
      {({ loading, error, data, fetchMore }) => {
        if (!data || loading) {
          return null;
        }

        const edges = data?.vxmnQueryNotifications?.pagination?.edges ?? [];
        const messages = edges.map((edge) => edge.node) ?? [];

        if (messages.length === 0 || currentMessage >= messages.length) {
          return null;
        }
        const currentMessageObject = messages[currentMessage];

        return (
          <Mutation
            client={client}
            mutation={VXMN_MUTATION_NOTIFICATIONS_MARKREAD}
            update={updateQueryAfterMarkRead(
              'vxmnQueryNotifications',
              VXMN_QUERY_NOTIFICATIONS,
              buildVariables('')
            )}
          >
            {(markRead) => {
              return (
                <Modal
                  css={{
                    overflowY: 'auto',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                  }}
                >
                  <NotificationContent
                    notification={{
                      ...currentMessageObject,
                      onMarkRead: (data) => {
                        markRead(data);
                        setCurrentMessage(currentMessage + 1);
                      },
                    }}
                    css={{
                      height: 'fit-content',
                      width: '1000px',
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      flexDirection: 'column',
                      position: 'relative',
                    }}
                  >
                    {messages.length > 1 && (
                      <DotDiv>
                        {messages.map((item, i) => (
                          <Dot key={item.id} active={i === currentMessage} />
                        ))}
                      </DotDiv>
                    )}
                    {messages.length > 1 && (
                      <MessageCountDiv>
                        <Translation
                          _={'notifications:text.cursorText'}
                          sprintf={{ num: currentMessage + 1, total: messages.length }}
                        />
                      </MessageCountDiv>
                    )}
                  </NotificationContent>
                </Modal>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default NotificationBox;
