import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { ApiLang } from '../../../../../graphql/VXModels/types';
import { _ } from '../../../../../util/translate';
import VoucherNameValidation from './VoucherNameValidation';

const VoucherName: FC = () => {
  const languageArray: ApiLang[] = [ApiLang.de, ApiLang.en];
  const [language, setLanguage] = useState<ApiLang>(ApiLang.de);

  const changeLanguage = (nthLanguage: number): void => {
    setLanguage(languageArray[nthLanguage]);
  };

  return (
    <Field
      name={`name.${language}`}
      render={({ field, form: { touched, errors, setFieldValue } }: FieldProps): ReactElement => {
        const error =
          touched.name?.[language] && errors.name?.[language] ? errors.name?.[language] : '';
        const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void =>
          setFieldValue(field.name, event.target.value);

        return (
          <VoucherNameValidation
            field={field}
            language={language}
            changeLanguage={changeLanguage}
            onChange={onChange}
            error={error}
          />
        );
      }}
    />
  );
};

export default VoucherName;
