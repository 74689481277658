import React, { memo } from 'react';
import { areEqual } from 'react-window';
import styled from '@emotion/styled';
// Outside Wizard
import { BLACK_1, GRAY_2, WHITE } from '../../../../../camtool-styles';
import { getFormattedDate, getFormattedDuration } from '../../../../../util/Formatter';
// Inside Wizard
import { IMediaAlbum } from '../../../types';
import { useLang } from '../../../../../util/AppState';
import { _ } from '../../../../../util/translate';
import { IMAGE_HEIGHT, IMAGE_WIDTH, TILE_WIDTH } from './constants';

const ContentMediaItemContainer = styled.a`
  text-decoration: none;
  margin-left: 8px;
  color: ${WHITE};
`;

const Image = styled.div`
  position: relative;
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT}px;
  background-size: cover;
  background-position: center;
`;

const Metadata = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: flex-end;
  padding: 2px 4px;
  font-size: 12px;
  align-items: center;
`;

interface IContentMediaItemProps {
  album: IMediaAlbum;
  onClick: (album: IMediaAlbum) => void;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: object;
  disabled?: boolean;
}

const ContentMediaItem = memo((props: IContentMediaItemProps) => {
  const { album, onClick, className, style, disabled } = props;
  const [lang] = useLang();

  if (!album) {
    return null;
  }

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (onClick && !disabled) {
      onClick(album);
    }
  };

  const previewUrl =
    (album.previewPicture16 && album.previewPicture16.url) ||
    (album.previewPicture18 && album.previewPicture18.url);

  return (
    <ContentMediaItemContainer
      onClick={handleClick}
      className={className}
      style={style ? style : undefined}
      css={{ cursor: disabled ? 'default' : 'pointer', opacity: disabled ? 0.5 : 1 }}
    >
      <div css={{ flexDirection: 'column' }}>
        <Image
          css={{
            backgroundImage: previewUrl ? `url(${previewUrl})` : undefined,
          }}
        >
          {!disabled && (
            <div
              css={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: WHITE,
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 16,
                opacity: 0,
                '&:hover': { opacity: 1 },
              }}
            >
              {_('benefits:voucher.texts.add')}
            </div>
          )}

          <Metadata>
            <span
              className={`icon-${album.isVideoAlbum ? 'play-button' : 'picture'}`}
              css={{
                marginRight: 4,
                fontSize: '0.8em',
              }}
            />
            <span>
              {album.isVideoAlbum
                ? getFormattedDuration(album.video.duration)
                : album.numberOfReleasedPictures}
            </span>
          </Metadata>
        </Image>

        <div css={{ flexDirection: 'column', fontSize: 14, width: TILE_WIDTH }}>
          <div>
            <span
              title={album.titles[0] ? album.titles[0].text : ''}
              css={{
                color: BLACK_1,
                width: TILE_WIDTH,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'inline-block',
              }}
            >
              {album.titles[0] ? album.titles[0].text : ''}
            </span>
          </div>

          <div css={{ color: GRAY_2 }}>
            <span>
              {_('benefits:voucher.texts.vom')} {getFormattedDate(album.created, lang)}
            </span>
          </div>
        </div>
      </div>
    </ContentMediaItemContainer>
  );
}, areEqual);

ContentMediaItem.displayName = 'ContentMediaItem';

export default ContentMediaItem;
