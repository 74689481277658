import { FC, ReactElement } from 'react';
import { Code } from '../../../types';
import { _ } from '../../../../../util/translate';

interface Props {
  code: Code;
}

const TargetGroupsLabel: FC<Props> = ({ code }): ReactElement => {
  if (code.config.conditions.firstPayment) return _('benefits:voucher.targetGroup.newCustomers');
  else return _('benefits:voucher.targetGroup.allCustomers');
};

export default TargetGroupsLabel;
