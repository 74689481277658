import React, { FC } from 'react';
import { File, FileType } from '../../../../graphql/VXServicesTelegram/types';
import { Lottie } from '../../../../atoms/Lottie';
import { GRAY_3, GRAY_4 } from '../../../../camtool-styles';
import { _ } from '../../../../util/translate';
import { PreviewImage } from '../../../../molecules/FileUpload/PreviewFile/PreviewImage';
import { PreviewVideo } from '../../../../molecules/FileUpload/PreviewFile/PreviewVideo';
import { PreviewAudio } from '../../../../molecules/FileUpload/PreviewFile/PreviewAudio';

interface InlinePreviewFileProps {
  file: Pick<File, 'type' | 'previewPictureUrl' | 'url'>;
}

const InlinePreviewFile: FC<InlinePreviewFileProps> = ({
  file: { type, previewPictureUrl, url },
}) => {
  switch (type) {
    case FileType.picture:
      return (
        <PreviewImage
          css={{ height: '57px', border: `1px solid ${GRAY_3}`, overflow: 'hidden' }}
          url={previewPictureUrl || url}
        />
      );
    case FileType.sticker:
      return <Lottie path={url} height={40} />;
    case FileType.video:
      return (
        <PreviewVideo
          css={{ height: '57px' }}
          url={url}
          thumbnailUrl={previewPictureUrl || undefined}
          options={{ thumbnailOnly: true }}
        />
      );
    case FileType.audio:
      return (
        <PreviewAudio
          url={url}
          options={{ thumbnailOnly: true }}
          width={57}
          height={57}
          hasBorder={true}
        />
      );
    default:
    case FileType.undefined:
      return (
        <span css={{ color: GRAY_4, fontStyle: 'italic' }}>
          {_('telegram:BroadcastList.column.file.empty')}
        </span>
      );
  }
};

export default InlinePreviewFile;
