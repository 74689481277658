import React, { FC } from 'react';
import { LeftMenuItem } from '../../components';
import { _ } from '../../../../util/translate';
import { star } from '../../../../atoms/Icon/libraries/glyph';

const VXGamesItem: FC = () => (
  <LeftMenuItem title={_('navigation:main.vxgames')} icon={star} uri="/vxgames" />
);

export default VXGamesItem;
