import React, { ReactElement } from 'react';

const AudioMessageIcon = (): ReactElement => {
  return (
    <g transform="translate(0 63.866)">
      <g transform="translate(34 -63.866)" clipPath="url(#clip-path)">
        <path
          data-name="Pfad 11717"
          d="M15.05,18.633h0a4.366,4.366,0,0,0,4.366-4.366v-.99H17.134a.575.575,0,1,1,0-1.151h2.281V10.97H17.134a.575.575,0,1,1,0-1.15h2.281V8.709H17.134a.575.575,0,1,1,0-1.151h2.281V6.4H17.134a.575.575,0,1,1,0-1.15h2.281V4.366a4.366,4.366,0,0,0-8.731,0v.886h2.251a.575.575,0,0,1,0,1.15H10.684V7.558h2.251a.575.575,0,0,1,0,1.151H10.684V9.819h2.251a.575.575,0,0,1,0,1.15H10.684v1.157h2.251a.575.575,0,0,1,0,1.151H10.684v.99a4.366,4.366,0,0,0,4.366,4.366"
          transform="translate(-7.709 0)"
          fill={'#000'}
        />
        <path
          data-name="Pfad 11718"
          d="M13.053,41.925a6.272,6.272,0,0,0,1.573-4.277v-6a.7.7,0,0,0-1.393,0l0,6.064A4.923,4.923,0,0,1,12.014,41a6.377,6.377,0,0,1-4.7,1.728A6.377,6.377,0,0,1,2.615,41a4.923,4.923,0,0,1-1.222-3.287V31.647a.7.7,0,0,0-1.393,0v6a6.271,6.271,0,0,0,1.574,4.277,7.32,7.32,0,0,0,5.016,2.156V46.11H3.638a.7.7,0,0,0,0,1.393h7.353a.7.7,0,0,0,0-1.393H8.037V44.081a7.32,7.32,0,0,0,5.016-2.156"
          transform="translate(0 -22.331)"
          fill={'#000'}
        />
      </g>
      <path
        d="M50,12.693V.949a.9.9,0,0,1,1.81,0V12.693a.9.9,0,0,1-1.81,0Zm-22,0V.949a.9.9,0,0,1,1.81,0V12.693a.9.9,0,0,1-1.81,0Zm38-.8V.949a.9.9,0,0,1,1.81,0V11.9a.9.9,0,0,1-1.81,0Zm-19,0V.949a.9.9,0,0,1,1.81,0V11.9a.9.9,0,0,1-1.81,0Zm-6,0V.949a.905.905,0,0,1,1.811,0V11.9A.905.905,0,0,1,41,11.9Zm-28,0V.949a.9.9,0,0,1,1.81,0V11.9a.9.9,0,0,1-1.81,0ZM44,11.3V1.949a.9.9,0,0,1,1.81,0V11.3a.9.9,0,1,1-1.81,0Zm-19,0V1.949a.9.9,0,0,1,1.81,0V11.3a.9.9,0,1,1-1.81,0Zm-6,0V1.949a.9.9,0,0,1,1.81,0V11.3a.9.9,0,1,1-1.81,0Zm50-.594V2.948a.9.9,0,0,1,1.81,0v7.758a.9.9,0,0,1-1.81,0Zm-10,0V2.948a.9.9,0,0,1,1.81,0v7.758a.9.9,0,0,1-1.81,0Zm-24,0V2.948a.9.9,0,0,1,1.81,0v7.758a.9.9,0,0,1-1.81,0Zm21-.595V3.949a.9.9,0,1,1,1.81,0v6.162a.9.9,0,1,1-1.81,0Zm-24,0V3.949a.9.9,0,1,1,1.81,0v6.162a.9.9,0,1,1-1.81,0Zm-22,0V3.949a.9.9,0,1,1,1.81,0v6.162a.9.9,0,1,1-1.81,0Zm28-.595V4.949a.9.9,0,1,1,1.81,0V9.517a.9.9,0,1,1-1.81,0Zm-22,0V4.949a.9.9,0,1,1,1.81,0V9.517a.9.9,0,1,1-1.81,0Zm56-.8V4.949a.9.9,0,1,1,1.81,0V8.72a.9.9,0,0,1-1.81,0Zm-10,0V4.949a.9.9,0,1,1,1.81,0V8.72a.9.9,0,0,1-1.81,0Zm-9-.8V4.949a.9.9,0,1,1,1.81,0V7.923a.9.9,0,0,1-1.81,0ZM22,7.328V5.949a.9.9,0,1,1,1.81,0V7.328a.9.9,0,1,1-1.81,0Zm59-.284a1,1,0,1,1,1,1A1,1,0,0,1,81,7.044Zm-3,0a1,1,0,1,1,1,1A1,1,0,0,1,78,7.044Zm-3,0a1,1,0,1,1,1,1A1,1,0,0,1,75,7.044Zm-68,0a1,1,0,1,1,1,1A1,1,0,0,1,7,7.044Zm-4,0a1,1,0,1,1,1,1A1,1,0,0,1,3,7.044Zm-3,0a1,1,0,1,1,1,1A1,1,0,0,1,0,7.044Z"
        transform="translate(0 -33.581)"
        fill={'#000'}
      />
    </g>
  );
};

export default AudioMessageIcon;
