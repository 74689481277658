import qs from 'querystring';
import { ApiLang } from '../graphql/VXModels/types';

export const getStartpageLoginUrl = (lang: ApiLang, redir?: string): string => {
  const queryStringArgs = qs.stringify(redir ? { redir } : {});
  const queryString = queryStringArgs ? `?${queryStringArgs}` : '';
  return `https://www.vxmodels.com/${lang}/login${queryString}`;
};

export const getStartpageSignupUrl = (lang: ApiLang): string =>
  `https://www.vxmodels.com/${lang}/signup`;

export const getStartpagePasswordResetUrl = (lang: ApiLang): string =>
  `https://www.vxmodels.com/${lang}/passwordreset`;
