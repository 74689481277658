import React from 'react';
import PropTypes from 'prop-types';
import AbstractComponent from '../AbstractComponent';

import Tooltip from '../Tooltip/Tooltip';

class GroupColumnNew extends AbstractComponent {
  state = { check: '' };

  render() {
    const { errorMessage, className, label, labelClass, children } = this.props;
    const { check } = this.state;

    return (
      <div className="groupcolumnnew__outside-container {this.props.containerClassName}">
        {errorMessage && <div className="groupcolumnnew__errorMessage">{errorMessage}</div>}

        <article className={`groupcolumnnew__content ${check} ${className ? className : ''}`}>
          {label && (
            <div className={`label ${labelClass ? labelClass : ''}`}>
              <label>{label}</label>
              <div className="spacer" />
            </div>
          )}

          {children}

          {this.props.tooltip !== 'none' && this.props.tooltip !== 'clear' && (
            <aside className="tooltip">
              <Tooltip position={this.props.tooltipPosition} text={this.props.tooltipText} />
            </aside>
          )}
        </article>
      </div>
    );
  }
}

GroupColumnNew.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};

GroupColumnNew.defaultProps = {
  errorMessage: '',
  className: '',
  label: '',
  labelClass: '',
  tooltip: '',
  tooltipPosition: 'right',
  tooltipText: '',
  children: null,
};

export default GroupColumnNew;
export { GroupColumnNew };
