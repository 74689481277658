import styled from '@emotion/styled';

export const StyledSection = styled.section`
  flex-direction: column;
  margin-bottom: 24px;
  word-break: normal;
  justify-content: flex-start;
`;
export const StyledDiv = styled.div`
  border-radius: 4px;
  border: 1px solid #c0c0c0;
  background: white;
  padding: 12px;
  color: #c0c0c0;
  font-size: 14px;
`;
export const Receivers = styled.div`
  align-self: flex-start;
  padding-right: 256px;
  font-size: 14px;
`;
export const SectionLabel = styled.h4`
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 8px;
  display: inline-flex;
  flex-direction: column;
  small {
    color: #999;
  }
`;
export const Article = styled.article`
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  width: 100%;
  padding: 64px;
`;
