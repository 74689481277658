import gql from 'graphql-tag';

export const GET_JWT_AUTHTOKEN = gql`
  query GET_JWT_AUTHTOKEN {
    auth {
      id
      accessToken
      refreshToken
    }
  }
`;
