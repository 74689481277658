import { useQuery } from '@apollo/react-hooks';
import { QueryHookOptions } from '@apollo/react-hooks/lib/types';
import { MailingRecipientsGroupsEnum, Query } from '../../../graphql/VXModels/types';
import { useVXModelsClient } from '../../../graphql/VXModels';
import { QUERY_MODEL_MAILINGS_DEFAULTS } from '../graphql/queries/mailings';

type Variables = { group: MailingRecipientsGroupsEnum };

export const useMailingDefaults = (
  group: MailingRecipientsGroupsEnum,
  options?: QueryHookOptions<Query, Variables>
) => {
  const client = useVXModelsClient();

  return useQuery<Query, Variables>(QUERY_MODEL_MAILINGS_DEFAULTS, {
    ...options,
    client,
    variables: { group },
  });
};
