import React, { ChangeEvent, FC } from 'react';
import { ApiLang } from '../../../../../graphql/VXModels/types';
import { MultiLanguageTextField } from '../../../../../molecules/MUI/MultiLanguageTextField';
import { _ } from '../../../../../util/translate';
import BuildValidationObject from '../../../../../util/BuildValidationObject';
import { sanitizeLang } from '../../../../../util/Translator';

interface VouchernameValidationProps {
  field: {
    onChange: {
      (e: React.ChangeEvent<any>): void;
      <T = unknown>(field: T): T extends React.ChangeEvent<any> ? void : (e: unknown) => void;
    };
    onBlur: {
      (e: React.FocusEvent<any>): void;
      <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    value: any;
    name: string;
  };
  language: ApiLang;
  changeLanguage: (nthLanguage: number) => void;
  onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  error: string;
}

const VoucherNameValidation: FC<VouchernameValidationProps> = ({
  field,
  language,
  changeLanguage,
  onChange,
  error,
}) => {
  const validation = BuildValidationObject('error', error);

  return (
    <MultiLanguageTextField
      language={language}
      changeLanguage={changeLanguage}
      label={{
        de: _('benefits:voucher.texts.voucherName', {
          lng: sanitizeLang('de'),
        }),
        en: _('benefits:voucher.texts.voucherName', {
          lng: sanitizeLang('en'),
        }),
      }}
      textFieldProps={{
        ...field,
        onChange: onChange,
        placeholder: _('benefits:voucher.placeholders.voucherName', {
          lng: sanitizeLang(language),
        }),
        validation: validation,
      }}
    />
  );
};

export default VoucherNameValidation;
