import React, { FC } from 'react';
import { challengeBackground, CompetitionBannerProps, useBannerStyles } from '.';
import { Paper, Grid, Box, Typography } from '@material-ui/core';
import { WHITE } from '../../../../../camtool-styles';
import NewButton from '../../../../../atoms/Button/NewButton';
import {
  competitionImageMapping,
  VXGAMES_TYPOGRAPHY_H1,
  VXGAMES_TYPOGRAPHY_H3,
  VXGAMES_TYPOGRAPHY_H5,
  VXGAMES_TYPOGRAPHY_PARAGRAPH,
} from '../../../utils';
import { useHistory } from 'react-router-dom';
import VXCountdown from '../Countdown/VXCountdown';

const CompetitionBanner: FC<CompetitionBannerProps> = ({
  type,
  title,
  subTitle,
  description,
  reward,
  redirectUri,
  startDate,
  endDate,
}) => {
  const classes = useBannerStyles();
  const history = useHistory();

  return (
    <Paper
      style={{
        backgroundColor: WHITE,
        backgroundImage: `url(${challengeBackground})`,
        backgroundSize: 'cover',
        border: 'none',
      }}
    >
      <Grid container spacing={2} style={{ position: 'relative' }}>
        <Grid
          container
          item
          xs={12}
          md={4}
          className={classes.root}
          justify="center"
          style={{ padding: '3rem' }}
        >
          <Typography style={VXGAMES_TYPOGRAPHY_H1} gutterBottom>
            {title}
          </Typography>
          <Typography style={VXGAMES_TYPOGRAPHY_H3} gutterBottom>
            {subTitle}
          </Typography>
          <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH} gutterBottom>
            {description}
          </Typography>
          <Box my={2}>
            <Typography style={VXGAMES_TYPOGRAPHY_H5} gutterBottom>
              Das Preisgeld:
            </Typography>
            <Typography style={VXGAMES_TYPOGRAPHY_PARAGRAPH} gutterBottom>
              {reward} - Nimmst du die Herausforderung an?
            </Typography>
          </Box>
          {redirectUri && (
            <Box>
              <NewButton
                variant="outlined"
                color="inherit"
                onClick={() => history.push(redirectUri)}
              >
                Jetzt starten
              </NewButton>
            </Box>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={8}
          className={classes.root}
          justify="center"
          alignItems="center"
        >
          <img css={{ maxWidth: '100%' }} src={competitionImageMapping[type].detailImage} />
        </Grid>
        <VXCountdown startDateISOString={startDate} endDateISOString={endDate} />
      </Grid>
    </Paper>
  );
};

export { CompetitionBanner };
