import React, { FC } from 'react';
import { Typography, Box } from '@material-ui/core';
import { VXGAMES_TYPOGRAPHY_H2, VXGAMES_TYPOGRAPHY_PARAGRAPH } from '../../../../utils';
import { trophy } from '../Assets';
import { VXGChallengeUserStatusEnum } from '../../../../../../graphql/VXModels/types';
import { CompetitionPrizeDisplayProps } from '.';

const CompetitionPrizeDisplay: FC<CompetitionPrizeDisplayProps> = ({
  reward,
  participationStatus,
  competitionFinished,
  numberOfWinners,
}) => {
  const isWinner = participationStatus === VXGChallengeUserStatusEnum.winner;

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="center"
      alignItems="center"
      gridGap="44px"
      my={2}
    >
      <img src={trophy} />
      <Box display="flex" flexDirection="column">
        <Typography align="center" style={VXGAMES_TYPOGRAPHY_PARAGRAPH}>
          {isWinner
            ? 'Du hast gewonnen:'
            : competitionFinished
            ? `Jeweils ${numberOfWinners}x VXGames Geldpaket im Wert von`
            : `Wir verlosen ${numberOfWinners}x das VXGames Geldpaket`}
        </Typography>
        <Typography align="center" style={VXGAMES_TYPOGRAPHY_H2}>
          {reward}
        </Typography>
      </Box>
    </Box>
  );
};

export { CompetitionPrizeDisplay };
