import React, { FC } from 'react';
import { AboContentType, AboRoute } from '../../types';
import { isContentTypeWithoutRedeemPage } from '../util';
import Breadcrumb from './Breadcrumb';
import { _ } from '../../../../util/translate';

interface Props {
  currentPage: AboRoute;
  aboContentTypes: AboContentType[];
}

const SectionTitle: FC<Props> = ({ currentPage, aboContentTypes }) => {
  return aboContentTypes.length === 1 && isContentTypeWithoutRedeemPage(aboContentTypes[0]) ? (
    <span>{_(`benefits:texts.nav.createSingle.${aboContentTypes[0]}`)}</span>
  ) : (
    <Breadcrumb currentPage={currentPage} />
  );
};

export default SectionTitle;
