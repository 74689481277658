import React from 'react';
import { graphql } from '@apollo/react-hoc';
import * as compose from 'lodash.flowright';

import { MediaManagementActionCreators, RESOURCE_ALBUM } from '../../../../stores/MediaManagement/MediaManagement'; // prettier-ignore
import { MUTATION_MODEL_MEDIA_DELETE_PICTURE, MUTATION_MODEL_MEDIA_MOVE_PICTURES_TO_ALBUM, MUTATION_MODEL_MEDIA_SET_PREVIEW_PICTURE } from '../../../../graphql/VXModels/mutations'; // prettier-ignore

import { Markdown, Spinner, Modalbox, MediaSelector, Camera } from '../../../../components';

import AbstractAlbumView from '../AbstractAlbumView';
import AlbumItem from '../AlbumItem/AlbumItem';
import AlbumAdd from '../AlbumAdd/AlbumAdd';
import { _ } from '../../../../util/translate';

const getStyle = (picture) => {
  if (picture && (picture.urlOriginal || picture.url)) {
    return {
      backgroundImage: `url(${picture.urlOriginal || picture.url})`,
    };
  }
  return {};
};

class AlbumViewProfileFL extends AbstractAlbumView {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: '',
      isLoading: false,
      album: props.album,
    };

    this.showMediaSelectorMainPicture = this.showMediaSelectorMainPicture.bind(this);
    this.closeMediaSelector = this.closeMediaSelector.bind(this);
    this.refreshAlbum = this.refreshAlbum.bind(this);
    this.prepareMainProfilePicture = this.prepareMainProfilePicture.bind(this);
    this.getPicturePoolFilter = this.getPicturePoolFilter.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
  }

  showMediaSelectorMainPicture() {
    this.setState({ showModal: 'mainPicture' });
  }

  closeMediaSelector() {
    Camera.resetCamera();
    this.setState({ showModal: '' });
  }

  getTitle(type) {
    return type;
  }

  getAlbumFSK = (albumType) => {
    switch (albumType) {
      case 'profile12':
        return 12;
      case 'profile16':
        return 16;
      case 'profile18':
      default:
        return 18;
    }
  };

  prepareMainProfilePicture(data, callback) {
    if (this.mainPicture.pictureId) {
      this.deletePicture(this.mainPicture, () => {
        callback(data);
      });
    } else {
      callback(data);
    }
  }

  deletePicture(picture, callback) {
    super.deletePicture(picture, callback);
    this.props.refetchAlbum();
  }

  refreshAlbum(nextPicture) {
    MediaManagementActionCreators.readResource(RESOURCE_ALBUM, {
      routeParams: {
        albumId: this.props.album.id,
      },
    });
  }

  getPicturePoolFilter() {
    return (picture) => {
      return !picture.isRejected && (picture.fsk <= 12 || !picture.isChecked);
    };
  }

  addPicturesToAlbum(pictures) {
    super.addPicturesToAlbum(pictures);
    this.props.refetchAlbum();
  }

  render() {
    this.isRejected = this.state.album.status === 'rejected';
    this.isNotRejected = this.state.album.status !== 'rejected';

    this.mainPicture = {};
    this.galleryPictures = [];

    for (let picture of this.state.album.pictures) {
      if (picture.position === 0) {
        this.mainPicture = picture;
        this.mainPicture['isRejected'] =
          typeof this.mainPicture.rejectionReason === 'string'
            ? !!this.mainPicture.rejectionReason
            : this.mainPicture.rejectionReason.hasOwnProperty('de');
      } else {
        this.galleryPictures.push(picture);
      }
    }

    const isInChecking =
      this.mainPicture.albumId > 0 && !this.mainPicture.isRejected && !this.mainPicture.isChecked;

    return (
      <div className="grid__box__column grid__box__sub profile-pic__view__profile__fl">
        <div className="profile-pic__view__profile__fl__row">
          <div className="profile-pic__view__profile__fl__header">
            <div className="profile-pic__view__profile__fl__nav">
              <div className="nav-sub__item">
                {!this.props.link && !this.props.href && (
                  <div className="nav-sub__item__column nav-sub__item__column--nolink nav-sub__item__column--active">
                    <div className="nav-sub__item__column__title">
                      {_('mediamanagement:sedcardOverview.sedcardFL.header')}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="profile-pic__view__profile__fl__titledummy" />
          </div>

          <div className="profile-pic__view__profile__fl__text">
            <Markdown
              className="profile-pic__view__profile__fl__text__row"
              source={_('mediamanagement:sedcardFL.description')}
            />
          </div>
        </div>

        <div className="profile-pic__view__profile__fl__content spinner-container">
          {this.state.isLoading && <Spinner align="top" />}

          <div className="profile-pic__view__profile__profilepic">
            <div
              className="profile-pic__view__profile__profilepic__titlepic"
              onClick={this.showMediaSelectorMainPicture}
              style={getStyle(this.mainPicture)}
            >
              <div className="profile-pic__view__profile__fl__album__item__change">
                <div className="profile-pic__view__profile__fl__album__item__change__icon" />
              </div>

              {/* Anzeigen wenn Bild Abgelehnt */}
              {this.mainPicture.isRejected && (
                <div className="profile-pic__view__profile__vx__foo__pool__item__rejected">
                  <div className="profile-pic__view__profile__vx__foo__pool__item__rejected__text">
                    {_('mediamanagement:sedcardFL.mainPictureRejected')}
                  </div>
                </div>
              )}

              {/* Anzeigen wenn Bild Geprüft wird */}
              {isInChecking && (
                <div className="profile-pic__view__profile__vx__foo__pool__item__checking">
                  <div className="profile-pic__view__profile__vx__foo__pool__item__checking__text">
                    {_('mediamanagement:sedcardFL.mainPictureChecking')}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="profile-pic__view__profile__fl__album">
            <div className="profile-pic__view__profile__fl__album__menudummy" />
            <div className="profile-pic__view__profile__fl__album__row">
              <AlbumAdd
                pictureType={AbstractAlbumView.toLegacyPictureType(this.state.album.albumType)}
                targetUmaId={this.state.album.id}
                onSubmit={this.addPicturesToAlbum}
                editor="landscape_4_3"
                title={_('mediamanagement:picfolder.contentItemAdd.title')}
                picturePoolFilter={this.getPicturePoolFilter()}
                containerClass="mediamanagement__pic__album__content__item__container"
              />

              {this.galleryPictures.map((picture) => (
                <AlbumItem
                  key={picture.pictureId}
                  data={picture}
                  album={this.state.album}
                  showDelete
                  showMoveToPool
                  showInChecking
                  showDetails
                  onClickMoveToPool={this.movePictureToPool_GRAPHQL}
                  onClickDelete={this.deletePicture_GRAPHQL}
                  containerClass="mediamanagement__pic__album__content__item__container"
                />
              ))}
            </div>
          </div>
        </div>

        <Modalbox
          name="modalsingleuploader"
          state={!!this.state.showModal}
          onKeyPressESC={this.closeMediaSelector}
          onClose={this.onModalClose}
          title={this.getTitle(this.state.showModal)}
        >
          <MediaSelector
            type="picture"
            initialTab="pool"
            editor="landscape_4_3"
            targetUmaId={this.props.album.id}
            targetParams={{ position: 0 }}
            pictureType="sedcard"
            fsk={this.getAlbumFSK(this.props.album.albumType)}
            onClose={this.closeMediaSelector}
            onSubmit={this.refreshAlbum}
            onBeforeUploadPicture={this.prepareMainProfilePicture}
            picturePoolFilter={this.getPicturePoolFilter()}
          />
        </Modalbox>
      </div>
    );
  }
}

export default compose(
  graphql(MUTATION_MODEL_MEDIA_DELETE_PICTURE, { name: 'deletePicture' }),
  graphql(MUTATION_MODEL_MEDIA_SET_PREVIEW_PICTURE, { name: 'setPreviewPicture' }),
  graphql(MUTATION_MODEL_MEDIA_MOVE_PICTURES_TO_ALBUM, { name: 'movePicturesToAlbum' })
)(AlbumViewProfileFL);
