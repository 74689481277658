import { FC } from 'react';
import { Code } from '../../../types';
import { _ } from '../../../../../util/translate';
import { BACKEND_TO_FRONTEND_VOUCHER_TYPES } from '../../../utils';

interface Props {
  code: Code;
}

const TypeLabel: FC<Props> = ({ code }) => {
  if (
    code &&
    code.config &&
    code.config.benefits &&
    code.config.benefits.length > 0 &&
    code.config.benefits[0].type
  ) {
    const benefitType = code.config.benefits[0].type;
    if (benefitType) {
      return BACKEND_TO_FRONTEND_VOUCHER_TYPES[benefitType]
        ? _(`benefits:voucher.${BACKEND_TO_FRONTEND_VOUCHER_TYPES[benefitType].toLowerCase()}.name`)
        : benefitType;
    }
  }

  return null;
};

export default TypeLabel;
