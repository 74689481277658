module.exports = {
  SERVER_UTIL_UPDATE_STORE: 'SERVER_UTIL_UPDATE_STORE',
  FINANCE_RECEIVE_DATA: 'FINANCE_RECEIVE_DATA',
  PROFILE_RECEIVE_DATA: 'PROFILE_RECEIVE_DATA',
  PROFILE_LOADING_STATE: 'PROFILE_LOADING_STATE',
  APP_RECEIVE_DATA: 'APP_RECEIVE_DATA',
  CONTENT_PICTURE_RECEIVE_LIST: 'CONTENT_PICTURE_RECEIVE_LIST',
  SETTINGS_RECEIVE_DATA: 'SETTINGS_RECEIVE_DATA',
  DASHBOARD_RECEIVE_DATA: 'DASHBOARD_RECEIVE_DATA',
  GEODATA_RECEIVE_DATA: 'GEODATA_RECEIVE_DATA',
  GEODATA_LOADING_STATE: 'GEODATA_LOADING_STATE',

  MARKETING_RECEIVE_DATA: 'MARKETING_RECEIVE_DATA',
  MARKETING_APPEND_DATA: 'MARKETING_APPEND_DATA',

  APPS_SALESSTATISTICS_RECEIVE_DATA: 'APPS_SALESSTATISTICS_RECEIVE_DATA',
  APPS_CALENDAR_RECEIVE_DATA: 'APPS_CALENDAR_RECEIVE_DATA',
  APPS_NOTIFICATIONS_RECEIVE_DATA: 'APPS_NOTIFICATIONS_RECEIVE_DATA',
  APPS_ALERTS_RECEIVE_DATA: 'APPS_ALERTS_RECEIVE_DATA',
  APPS_INCOMESOURCES_RECEIVE_DATA: 'APPS_INCOMESOURCES_RECEIVE_DATA',
  APPS_ONLINETIMEANDUSERS_RECEIVE_DATA: 'APPS_ONLINETIMEANDUSERS_RECEIVE_DATA',
  APPS_CHATRATINGS_RECEIVE_DATA: 'APPS_CHATRATINGS_RECEIVE_DATA',
  APPS_RANKINGPOINTS_RECEIVE_DATA: 'APPS_RANKINGPOINTS_RECEIVE_DATA',
  APPS_MESSENGER_RECEIVE_DATA: 'APPS_MESSENGER_RECEIVE_DATA',
  APPS_CHATHOLDINGTIME_RECEIVE_DATA: 'APPS_CHATHOLDINGTIME_RECEIVE_DATA',

  ACCOUNT_RECEIVE_DATA: 'ACCOUNT_RECEIVE_DATA',

  REG_TOUR_RECEIVE_DATA: 'REG_TOUR_RECEIVE_DATA',

  MEDIA_MANAGEMENT_RECEIVE_DATA: 'MEDIA_MANAGEMENT_RECEIVE_DATA',
  MEDIA_MANAGEMENT_APPEND_DATA: 'MEDIA_MANAGEMENT_APPEND_DATA',
  MEDIA_MANAGEMENT_DELETE_DATA: 'MEDIA_MANAGEMENT_DELETE_DATA',
  MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA: 'MEDIA_MANAGEMENT_VIDEO_TRANSCODING_DATA',

  SERVICE0900_RECEIVE_DATA: 'SERVICE0900_RECEIVE_DATA',
  SERVICE0900_APPEND_DATA: 'SERVICE0900_APPEND_DATA',
  SERVICE0900_DELETE_DATA: 'SERVICE0900_DELETE_DATA',

  MESSENGER_OPEN_CHANNEL: 'MESSENGER_OPEN_CHANNEL',

  TAGS_RECEIVE_DATA: 'TAGS_RECEIVE_DATA',

  VXCASH_RECEIVE_DATA: 'VXCASH_RECEIVE_DATA',

  // new general userdata store
  RECEIVE_DATA: 'USERDATA_RECEIVE_DATA',
  LOADING_STATE: 'USERDATA_LOADING_STATE',

  LISTS_VXCASHUSERS_RECEIVE_DATA: 'LISTS_VXCASHUSERS_RECEIVE_DATA',
  LISTS_VXCASHUSERS_APPEND_DATA: 'LISTS_VXCASHUSERS_APPEND_DATA',
  LISTS_VXFAVOURITES_RECEIVE_DATA: 'LISTS_VXFAVOURITES_RECEIVE_DATA',
  LISTS_VXFAVOURITES_APPEND_DATA: 'LISTS_VXFAVOURITES_APPEND_DATA',
  LISTS_VXCASHBILLINGS_RECEIVE_DATA: 'LISTS_VXCASHBILLINGS_RECEIVE_DATA',
  LISTS_CAMPOINTBILLINGS_RECEIVE_DATA: 'LISTS_CAMPOINTBILLINGS_RECEIVE_DATA',

  // new verified enum, all others may be out of date

  WEBSOCKET_CONNECTED: 'WEBSOCKET_CONNECTED',
  WEBSOCKET_RECEIVE_MESSAGE: 'WEBSOCKET_RECEIVE_MESSAGE',
  WEBSOCKET_DISCONNECTED: 'WEBSOCKET_DISCONNECTED',

  CAMTOOL_RELOAD: 'CAMTOOL_RELOAD',

  SESSION_DATA_RECEIVE: 'SESSION_DATA_RECEIVE',
  SESSION_KILL: 'SESSION_KILL',
  SESSION_ERROR: 'SESSION_ERROR',

  ALERTS_SHOW_DATA: 'ALERTS_SHOW_DATA',

  USERSTORE_DATA_RECEIVE: 'USERSTORE_DATA_RECEIVE',

  NOTIFICATIONS_DATA_RECEIVE: 'NOTIFICATIONS_DATA_RECEIVE',

  GRAPHQL_WRITE_QUERY: 'GRAPHQL_WRITE_QUERY',
};
