import React, { FC } from 'react';
import { _ } from '../../../../util/translate';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { FilterData, Filters } from './Desktop/Chats';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { BLUE } from '../../../../camtool-styles';
import { Crown } from '../../../../atoms/Icon';
import IconBase from '../../../../atoms/Icon/IconBase';
import BlockIcon from '@material-ui/icons/Block';
import { UserFilter } from '../../../../graphql/VXServicesTelegram/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
      fontSize: '12px',
      border: `1px solid ${BLUE}`,
      color: BLUE,
      backgroundColor: 'transparent',
    },
    deleteIcon: {
      color: BLUE,
      '&:hover': {
        color: 'rgba(31, 147, 233, 0.4)',
      },
    },
  })
);

const displayLabel = (label: Filters) => {
  switch (label) {
    case 'premium':
      return <IconBase iconElement={<Crown />} viewBox="0 0 22.21 24" />;
    case 'blocked':
      return <BlockIcon />;
    case 'onlyTipping':
      return <MonetizationOnIcon />;
    default:
      null;
  }
};

interface FilterBarProps {
  filterData: FilterData[];
  uiFilter: UserFilter;
  handleDelete: (chipToDelete: Filters) => () => void;
}

const FilterBar: FC<FilterBarProps> = ({ filterData, handleDelete, uiFilter }) => {
  const classes = useStyles();
  return (
    <Paper component="ul" className={classes.root}>
      <Box display="flex" alignItems="center">
        <Typography>{_('telegram:chat.filterbar.filtered')} </Typography>
      </Box>
      {filterData.map((data) => {
        return (
          <li key={data.key}>
            <Chip
              label={
                <Box display="flex" alignItems="center">
                  {displayLabel(data.label)}
                  <span>
                    {_(
                      `telegram:chat.filterbar.${
                        data.label === 'online' && uiFilter.online === false
                          ? 'offline'
                          : data.label
                      }`
                    )}
                  </span>
                </Box>
              }
              onDelete={handleDelete(data.label)}
              classes={{
                root: classes.chip,
                deleteIcon: classes.deleteIcon,
              }}
            />
          </li>
        );
      })}
    </Paper>
  );
};

export default FilterBar;
