import {EntryState} from "./JsUploader";

export class Entry {
    public key: string;
    public pos: number = 0;

    public state: EntryState = EntryState.UNDEF;

    public file: File;

    public fileName: string;
    public fileLastModified: Date;

    public checksum: string;
    public checksumProgress: number = 0;

    public written: number = 0;
    public contentLength: number = 0;

    public binaryPos: number = 0;
    public binaryLength: number = 0;

    public etag: string;
    public boundary: string;

    public umaId: number = 0;

    public uploadProgress: number = 0;
    public uploadRate: number = 0; // in Bytes/sec

    public fields: any = {};

    [key: string]: any;
}