import { Override } from './types';
import { GRAY_3, WHITE_3 } from '../../../camtool-styles';

export const MuiPaper: Override<'MuiPaper'> = {
  root: {
    display: 'block',
    backgroundColor: WHITE_3,
  },
  outlined: {
    border: `1px solid ${GRAY_3}`,
  },
};
