import { ReactNode } from 'react';

export interface ITableColumn {
  label?: string | (() => ReactNode); // String or render function
  sortable?: boolean;
  sort?: TableSortOrderEnum;
  align?: TableColumnAlignmentEnum;
  width?: number;
  horizontalPadding?: number;
  horizontalPaddingStart?: number;
  horizontalPaddingEnd?: number;
}

export enum TableSortOrderEnum {
  none = 'none',
  asc = 'asc',
  desc = 'desc',
}

export enum TableColumnAlignmentEnum {
  center = 'center',
  start = 'start',
  end = 'end',
}
