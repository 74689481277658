import React, { FC } from 'react';

interface Props {
  title?: string;
  className?: string;
  loading?: boolean;
}

const ItemContainer: FC<Props> = ({ title, children, className, loading }) => {
  return (
    <div
      className={className}
      css={{
        backgroundColor: '#FFF',
        color: '#262728',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        margin: 16,
        padding: 16,
        width: 284,
      }}
    >
      {title ? (
        <header
          css={{
            padding: 4,
            borderBottom: '2px solid #EEE',
            justifyContent: 'center',
            flex: '0 0 auto',
          }}
        >
          <h2 css={{ textAlign: 'center' }}>{title}</h2>
        </header>
      ) : null}

      <div css={{ flex: '1 0 auto' }}>{loading ? null : children}</div>
    </div>
  );
};

export default ItemContainer;
