import React from 'react';

import {
  ChartBar,
  AbstractComponent,
  FormatNumber,
  FormatCurrency,
  FormatDuration,
  T,
  EmptyContent,
} from '../../../components';

import { SalesStatisticsStore } from '../../../stores/SalesStatistics/SalesStatisticsStore';
import { SalesStatisticsActionCreators } from '../../../stores/SalesStatistics/SalesStatisticsActionCreators';
import { withAppState } from '../../../util/AppState';
import { _ } from '../../../util/translate';
import { Box, Container, Grid, Hidden } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import TheatersIcon from '@material-ui/icons/Theaters';
import CallIcon from '@material-ui/icons/Call';
import IconAndTextComponent from './IconAndTextComponent';
import MoneyBillIcon from '../../../atoms/Icon/VXCash/MoneyBillIcon';
import { GREEN } from '../../../camtool-styles';

const styles = createStyles({
  salesStatisticsContainer: {},
  contentContainer: {},
  moneyBill: {
    color: GREEN,
    fontSize: '36px',
  },
  clockIcon: {
    fontSize: '36px',
    color: '#1f93e9',
  },
  filmIcon: {
    fontSize: '36px',
    color: '#ff4d3c',
  },
  phoneIcon: {
    fontSize: '36px',
    color: '#ffb033',
  },
  selectedAmount: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  contentSpacing: {
    margin: '16px 0',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    ['@media (max-width:900px)']: {
      alignItems: 'center',
    },
  },
  salesInformations: {
    marginLeft: '8px',
  },
});

class SalesStatistics extends AbstractComponent {
  constructor(props) {
    super(props);
    this.state = SalesStatisticsStore.get();
  }

  componentDidMount() {
    SalesStatisticsStore.addChangeListener(this.onStoreChange);
    SalesStatisticsActionCreators.getSalesStatisticsData();
  }

  componentWillUnmount() {
    SalesStatisticsStore.removeChangeListener(this.onStoreChange);
  }

  onStoreChange() {
    this.setState(SalesStatisticsStore.get());
  }

  showValueForDate(entry) {
    this.setState({
      selectedAmount: entry.turnover,
      selectedChatTime: entry.vchatSeconds,
      selectedVideoSales: entry.contentSales,
      selectedTel0900Time: entry.tel0900Seconds,
      applyCssClassToday: false,
      selectedDate: entry.date,
    });
  }

  render() {
    const { classes, loading } = this.props;
    return (
      <Container maxWidth={false}>
        {this.props.userAllowed ? (
          <Grid
            container
            direction={'column'}
            justify={'center'}
            alignItems={'center'}
            style={{ minHeight: '308px' }}
          >
            <Grid container item xs={12} direction={'row'} justify={'center'} alignItems={'center'}>
              <IconAndTextComponent
                mobileSize={12}
                tabletSize={6}
                icon={<MoneyBillIcon className={classes.moneyBill} />}
                rightBoxText={_('dashboard:app.salesStatistics.on')}
                date={this.state.selectedDate}
                formattedNumber={
                  <FormatCurrency
                    className={classes.selectedAmount}
                    value={this.state.selectedAmount}
                  />
                }
              />

              <IconAndTextComponent
                mobileSize={12}
                tabletSize={6}
                icon={<MoneyBillIcon className={classes.moneyBill} />}
                rightBoxText={_('dashboard:app.salesStatistics.currentMonth')}
                date={_('dashboard:app.salesStatistics.currentMonth')}
                formattedNumber={
                  <FormatCurrency
                    className={classes.selectedAmount}
                    value={this.state.totalTurnover}
                  />
                }
              />
            </Grid>
            <Grid container item xs={12} direction={'row'} justify={'center'} alignItems={'center'}>
              <IconAndTextComponent
                mobileSize={12}
                tabletSize={4}
                icon={<QueryBuilderIcon className={classes.clockIcon}></QueryBuilderIcon>}
                rightBoxText={_('dashboard:app.salesStatistics.videochats')}
                date={_('dashboard:app.salesStatistics.currentMonth')}
                formattedNumber={
                  <FormatDuration
                    className={classes.selectedAmount}
                    value={this.state.selectedChatTime}
                  />
                }
              />

              <IconAndTextComponent
                mobileSize={12}
                tabletSize={4}
                icon={<TheatersIcon className={classes.filmIcon}></TheatersIcon>}
                rightBoxText={_('dashboard:app.salesStatistics.contentsales')}
                formattedNumber={
                  <FormatNumber
                    value={this.state.selectedVideoSales}
                    className={classes.selectedAmount}
                  />
                }
              />

              <IconAndTextComponent
                mobileSize={12}
                tabletSize={4}
                icon={<CallIcon className={classes.phoneIcon}></CallIcon>}
                rightBoxText={_('dashboard:app.salesStatistics.service0900')}
                formattedNumber={
                  <FormatDuration
                    className={classes.selectedAmount}
                    value={this.state.selectedTel0900Time}
                  />
                }
              />
            </Grid>
            <Grid container item xs={12} direction={'row'} justify={'center'} alignItems={'center'}>
              <Grid item xs={12} zeroMinWidth={true}>
                <Box className={classes.chartContainer}>
                  <ChartBar
                    name="sales"
                    onSelect={this.showValueForDate}
                    values={this.state.turnoverDetails}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
            <Grid item xs={12}>
              <Box></Box>
              <EmptyContent icon="icon-pie-chart" title={_('common:forbidden.text')} />
              <Box></Box>
            </Grid>
          </Grid>
        )}
      </Container>
    );
  }
}

export default withStyles(styles)(withAppState(SalesStatistics));
