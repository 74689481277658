import { FRAGMENT_CONTEXT } from './fragments';
import { Resolvers } from 'apollo-client';

const resolvers: Resolvers = {
  Query: {
    __context: (root, variables, { cache, getCacheKey }) => {
      const id = getCacheKey({ __typename: 'Context', id: 'context' });
      const context = cache.readFragment({ fragment: FRAGMENT_CONTEXT, id });
      return context;
    },
  },
  Mutation: {
    __context: (root, { context: nextContext }, { cache, getCacheKey }): Resolvers => {
      console.log('cache', cache);

      const id = getCacheKey({ __typename: 'Context', id: 'context' });
      console.log('variables', id, nextContext);

      const currentContext = cache.readFragment({ fragment: FRAGMENT_CONTEXT, id }) || {};
      console.log('currentContext', id, currentContext);

      const data = { ...currentContext, ...nextContext };
      console.log('nextContext', id, data);

      cache.writeData({ id, data: data });

      const updatedContext = cache.readFragment({ fragment: FRAGMENT_CONTEXT, id });

      console.log('updatedContext', id, updatedContext);

      return updatedContext;
    },
  },
};
export default resolvers;
