import { QueryLazyOptions, useLazyQuery } from '@apollo/react-hooks';
import {
  Query,
  Service0900DisplayPlatformEnum,
  Service0900StatusEnum,
} from '../../../graphql/VXModels/types';
import { QUERY_MODEL_SERVICE0900_TOPBAR } from '../graphql/queries';
import { QueryResult } from '@apollo/react-common';

type LazyService0900StatusResult = [
  (options?: QueryLazyOptions<Record<string, any>> | undefined) => void,
  Omit<QueryResult, 'data'> & {
    data: {
      status: Service0900StatusEnum | undefined;
      displayPlatform: Service0900DisplayPlatformEnum[];
    };
  }
];

export const useLazyService0900ShortInfo = (
  options?: QueryLazyOptions<Record<string, any>>
): LazyService0900StatusResult => {
  const [getStatus, { data, ...result }] = useLazyQuery<Query>(
    QUERY_MODEL_SERVICE0900_TOPBAR,
    options
  );
  const status = data?.model?.service0900?.status;
  const displayPlatform = data?.model?.service0900?.displayPlatform || [];

  return [getStatus, { data: { status, displayPlatform }, ...result }];
};
