import { Main, Section } from '../../atoms';
import NewButton from '../../atoms/Button/NewButton';
import React, { ReactElement, ReactNode } from 'react';
import {
  AppBar,
  Paper,
  PropTypes,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@material-ui/core';
import ButtonSpinner from '../../atoms/Icon/ButtonSpinner';
import { DARK_GRAY } from '../../camtool-styles';
import BuildValidationObject, { LevelType } from '../../util/BuildValidationObject';
import { MUICheckbox, MUICheckboxFormControlLabel, CustomColor } from '../../components/MUI';

function a11yProps(index: any): Record<string, any> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps): ReactElement {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && [children]}
    </div>
  );
}

const FormElementsIndex = (): ReactNode => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Main>
      <Section title={'Checkboxes'}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell>Unchecked</TableCell>
                <TableCell>Checked</TableCell>
                <TableCell>Indeterminate (Unclicked)</TableCell>
                <TableCell>Indeterminate (Clicked)</TableCell>
                <TableCell>Unchecked (Disabled)</TableCell>
                <TableCell>Checked (Disabled)</TableCell>
                <TableCell>Indeterminate (Disabled)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['', 'primary', 'secondary', 'warning', 'error', 'default'].map((color, i) => {
                const castedColor = color as 'default' | CustomColor | undefined;

                return (
                  <TableRow key={i}>
                    <TableCell>
                      {!color ? 'No Color (Default)' : color[0].toUpperCase() + color.slice(1)}
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        checked
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        indeterminate
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        checked
                        indeterminate
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        disabled
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        disabled
                        checked
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        disabled
                        indeterminate
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell>Colored Labels</TableCell>
                <TableCell>Unchecked</TableCell>
                <TableCell>Checked</TableCell>
                <TableCell>Indeterminate (Unclicked)</TableCell>
                <TableCell>Indeterminate (Clicked)</TableCell>
                <TableCell>Unchecked (Disabled)</TableCell>
                <TableCell>Checked (Disabled)</TableCell>
                <TableCell>Indeterminate (Disabled)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['primary', 'secondary', 'warning', 'error'].map((color, i) => {
                const castedColor = color as 'default' | CustomColor | undefined;

                return (
                  <TableRow key={i}>
                    <TableCell>
                      {!color ? 'No Color (Default)' : color[0].toUpperCase() + color.slice(1)}
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        coloredLabel
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        coloredLabel
                        checked
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        coloredLabel
                        indeterminate
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        coloredLabel
                        checked
                        indeterminate
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        coloredLabel
                        disabled
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        coloredLabel
                        disabled
                        checked
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                    <TableCell>
                      <MUICheckboxFormControlLabel
                        color={castedColor}
                        coloredLabel
                        disabled
                        indeterminate
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableHead>
              <TableRow>
                {['Sizes', 'Small', 'Medium (Default)', 'Large'].map((value, i) => (
                  <TableCell key={i}>{value}</TableCell>
                ))}
                {[0, 1, 2, 3].map((i) => (
                  <TableCell key={i}>-</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>-</TableCell>
                {['small', 'medium', 'large'].map((size, i) => {
                  const castedSize = size as 'small' | 'medium' | 'large' | undefined;
                  return (
                    <TableCell key={i}>
                      <MUICheckboxFormControlLabel
                        size={castedSize}
                        checked
                        label={'Label'}
                        control={<MUICheckbox />}
                      />
                    </TableCell>
                  );
                })}
                {[0, 1, 2, 3].map((i) => (
                  <TableCell key={i}>-</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Section>
      <AppBar position="static" css={{ backgroundColor: `${DARK_GRAY} !important` }}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Buttons" {...a11yProps(0)} />
          <Tab label="Buttons (Spinner)" {...a11yProps(1)} />
          <Tab label="Buttons (Text, Spinner)" {...a11yProps(2)} />
          <Tab label="Buttons (Validation)" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell align={'center'}>Medium</TableCell>
                <TableCell align={'center'}>Medium (O)</TableCell>
                <TableCell align={'center'}>Large</TableCell>
                <TableCell align={'center'}>Large (O)</TableCell>
                <TableCell align={'center'}>Medium (D)</TableCell>
                <TableCell align={'center'}>Medium (O, D)</TableCell>
                <TableCell align={'center'}>Large (D)</TableCell>
                <TableCell align={'center'}>Large (O, D)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['', 'primary', 'secondary', 'warning', 'error', 'default'].map((color, i) => {
                const castedColor = color as 'default' | PropTypes.Color | CustomColor | undefined;

                return (
                  <TableRow key={i}>
                    <TableCell>
                      {!color ? 'No Color (Default)' : color[0].toUpperCase() + color.slice(1)}
                    </TableCell>
                    <TableCell>
                      <NewButton color={i === 0 ? undefined : castedColor}>Text</NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton color={i === 0 ? undefined : castedColor} variant={'outlined'}>
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton color={i === 0 ? undefined : castedColor} size={'large'}>
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        color={i === 0 ? undefined : castedColor}
                        variant={'outlined'}
                        size={'large'}
                      >
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton disabled>Text</NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton variant={'outlined'} disabled>
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton size={'large'} disabled>
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton variant={'outlined'} size={'large'} disabled>
                        Text
                      </NewButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer component={Paper}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell align={'center'}>Medium</TableCell>
                <TableCell align={'center'}>Medium (O)</TableCell>
                <TableCell align={'center'}>Large</TableCell>
                <TableCell align={'center'}>Large (O)</TableCell>
                <TableCell align={'center'}>Medium (D)</TableCell>
                <TableCell align={'center'}>Medium (O, D)</TableCell>
                <TableCell align={'center'}>Large (D)</TableCell>
                <TableCell align={'center'}>Large (O, D)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['', 'primary', 'secondary', 'warning', 'error', 'default'].map((color, i) => {
                const castedColor = color as 'default' | PropTypes.Color | CustomColor | undefined;

                return (
                  <TableRow key={i}>
                    <TableCell>
                      {!color ? 'No Color (Default)' : color[0].toUpperCase() + color.slice(1)}
                    </TableCell>
                    <TableCell>
                      <NewButton icon={<ButtonSpinner />} color={i === 0 ? undefined : castedColor}>
                        T
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        icon={<ButtonSpinner />}
                        color={i === 0 ? undefined : castedColor}
                        variant={'outlined'}
                      >
                        T
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        icon={<ButtonSpinner />}
                        color={i === 0 ? undefined : castedColor}
                        size={'large'}
                      >
                        T
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        icon={<ButtonSpinner />}
                        color={i === 0 ? undefined : castedColor}
                        variant={'outlined'}
                        size={'large'}
                      >
                        T
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <NewButton icon={<ButtonSpinner />} disabled>
                          T
                        </NewButton>
                      ) : (
                        'Siehe Oben'
                      )}
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <NewButton icon={<ButtonSpinner />} variant={'outlined'} disabled>
                          T
                        </NewButton>
                      ) : (
                        'Siehe Oben'
                      )}
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <NewButton icon={<ButtonSpinner />} size={'large'} disabled>
                          T
                        </NewButton>
                      ) : (
                        'Siehe Oben'
                      )}
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <NewButton
                          icon={<ButtonSpinner />}
                          variant={'outlined'}
                          size={'large'}
                          disabled
                        >
                          T
                        </NewButton>
                      ) : (
                        'Siehe Oben'
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TableContainer component={Paper}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell align={'center'}>Medium</TableCell>
                <TableCell align={'center'}>Large</TableCell>
                <TableCell align={'center'}>Medium (D)</TableCell>
                <TableCell align={'center'}>Large (D)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['', 'primary', 'secondary', 'warning', 'error', 'default'].map((color, i) => {
                const castedColor = color as 'default' | PropTypes.Color | CustomColor | undefined;

                return (
                  <TableRow key={i}>
                    <TableCell>
                      {!color ? 'No Color (Default)' : color[0].toUpperCase() + color.slice(1)}
                    </TableCell>
                    <TableCell>
                      <NewButton variant={'text'} color={i === 0 ? undefined : castedColor}>
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        variant={'text'}
                        size={'large'}
                        color={i === 0 ? undefined : castedColor}
                      >
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <NewButton
                          variant={'text'}
                          disabled
                          color={i === 0 ? undefined : castedColor}
                        >
                          Text
                        </NewButton>
                      ) : (
                        'Siehe Oben'
                      )}
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <NewButton
                          variant={'text'}
                          size={'large'}
                          disabled
                          color={i === 0 ? undefined : castedColor}
                        >
                          Text
                        </NewButton>
                      ) : (
                        'Siehe Oben'
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell align={'center'}>Medium</TableCell>
                <TableCell align={'center'}>Large</TableCell>
                <TableCell align={'center'}>Medium (D)</TableCell>
                <TableCell align={'center'}>Large (D)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['', 'primary', 'secondary', 'warning', 'error', 'default'].map((color, i) => {
                const castedColor = color as 'default' | PropTypes.Color | CustomColor | undefined;

                return (
                  <TableRow key={i}>
                    <TableCell>
                      {!color ? 'No Color (Default)' : color[0].toUpperCase() + color.slice(1)}
                    </TableCell>
                    <TableCell>
                      <NewButton
                        icon={<ButtonSpinner />}
                        variant={'text'}
                        color={i === 0 ? undefined : castedColor}
                      >
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        icon={<ButtonSpinner />}
                        variant={'text'}
                        size={'large'}
                        color={i === 0 ? undefined : castedColor}
                      >
                        Text
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <NewButton
                          icon={<ButtonSpinner />}
                          variant={'text'}
                          disabled
                          color={i === 0 ? undefined : castedColor}
                        >
                          Text
                        </NewButton>
                      ) : (
                        'Siehe Oben'
                      )}
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <NewButton
                          icon={<ButtonSpinner />}
                          variant={'text'}
                          size={'large'}
                          disabled
                          color={i === 0 ? undefined : castedColor}
                        >
                          Text
                        </NewButton>
                      ) : (
                        'Siehe Oben'
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableContainer component={Paper}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell align={'center'}>Medium</TableCell>
                <TableCell align={'center'}>Medium (0)</TableCell>
                <TableCell align={'center'}>Medium (T)</TableCell>
                <TableCell align={'center'}>Large</TableCell>
                <TableCell align={'center'}>Large (O)</TableCell>
                <TableCell align={'center'}>Large (T)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {['primary', 'secondary', 'warning', 'error', 'default', null].map((color, i) => {
                const castedColor = color as LevelType;

                return (
                  <TableRow key={i}>
                    <TableCell>
                      {!color ? 'No Icon' : color[0].toUpperCase() + color.slice(1)}
                    </TableCell>
                    <TableCell>
                      <NewButton
                        validation={BuildValidationObject(
                          !color ? 'default' : castedColor,
                          'Lorem Ipsum',
                          !color ? null : undefined
                        )}
                      >
                        Registrieren
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        validation={BuildValidationObject(
                          !color ? 'primary' : castedColor,
                          'Lorem Ipsum',
                          !color ? null : undefined
                        )}
                        variant={'outlined'}
                      >
                        Registrieren
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        validation={BuildValidationObject(
                          !color ? 'secondary' : castedColor,
                          'Lorem Ipsum',
                          !color ? null : undefined
                        )}
                        variant={'text'}
                      >
                        Registrieren
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        validation={BuildValidationObject(
                          !color ? 'warning' : castedColor,
                          'Lorem Ipsum',
                          !color ? null : undefined
                        )}
                        size={'large'}
                      >
                        Registrieren
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        variant={'outlined'}
                        size={'large'}
                        validation={BuildValidationObject(
                          !color ? 'error' : castedColor,
                          'Lorem Ipsum',
                          !color ? null : undefined
                        )}
                      >
                        Registrieren
                      </NewButton>
                    </TableCell>
                    <TableCell>
                      <NewButton
                        variant={'text'}
                        size={'large'}
                        validation={BuildValidationObject(
                          !color ? 'default' : castedColor,
                          'Lorem Ipsum',
                          !color ? null : undefined
                        )}
                      >
                        Registrieren
                      </NewButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </Main>
  );
};

export default FormElementsIndex;
