import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MoneyBillIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox={'0 0 15 11'}>
    <path d="M0,11V4H11v7Zm2.54-1H8.46a1.64,1.64,0,0,1,.21-.84,1.28,1.28,0,0,1,.55-.53A1.66,1.66,0,0,1,10,8.46V6.54a1.66,1.66,0,0,1-.78-.17,1.22,1.22,0,0,1-.55-.53A1.64,1.64,0,0,1,8.46,5H2.54a1.54,1.54,0,0,1-.21.84,1.27,1.27,0,0,1-.55.53A1.66,1.66,0,0,1,1,6.54V8.46a1.66,1.66,0,0,1,.78.17,1.33,1.33,0,0,1,.55.53A1.54,1.54,0,0,1,2.54,10ZM12,9V3H2V2H13V9Zm2-2V1H4V0H15V7ZM6.68,8.4a1.44,1.44,0,0,1-1,.44A1.47,1.47,0,0,1,4.57,8.4a1.5,1.5,0,0,1,0-2.1,1.47,1.47,0,0,1,1.06-.44,1.48,1.48,0,0,1,1,2.54Zm-1,0a1.1,1.1,0,0,0,.53-.14,1.09,1.09,0,0,0,.38-.39,1.07,1.07,0,0,0,0-1.06,1,1,0,0,0-.38-.38,1,1,0,0,0-.53-.14,1.06,1.06,0,0,0-1.06,1A1.06,1.06,0,0,0,5.63,8.41Z" />
  </SvgIcon>
);

export default MoneyBillIcon;
