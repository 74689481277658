import { AbstractRestApiStoreClass, AbstractRestApiActionCreatorsClass } from '../AbstractRestApi';
import { SessionStore } from '../Session/Session';

// define resources
const PROFILE_LA = 'PROFILE_LA';
const PROFILE_FL = 'PROFILE_FL';
const PROFILE_PARTNER = 'PROFILE_PARTNER';
const PROFILE_INTERVIEW = 'PROFILE_INTERVIEW';
const PROFILE_VX_AUTOMAILS = 'PROFILE_VX_AUTOMAILS';
const PROFILE_VX_FETISH = 'PROFILE_VX_FETISH';
const PROFILE_VX_OVERVIEW = 'PROFILE_VX_OVERVIEW';
const PROFILE_COMMON_APPEARANCE = 'PROFILE_COMMON_APPEARANCE';
const PROFILE_COMMON_ABOUTYOU = 'PROFILE_COMMON_ABOUTYOU';

let config = {
  name: 'Profile',
  resources: {
    PROFILE_VX_OVERVIEW: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/vx/overview',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/vx/overview',
      },

      defaultData: {
        profileLanguages: [],
        isProfileLanguagesDisabled: false,
        vxClothing: [],
        vxOnlineSchedule: {
          texts: {},
        },
        vxPreferredPractices: [],
        vxPreferredSexType: [],
        vxShowLocation: '',
        vxShowText: {
          texts: {},
        },
        vxShowType: [],
        isVxShowTypeDisabled: false,
        vxToys: [],
        formValues: [],
      },
    },
    PROFILE_COMMON_ABOUTYOU: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/common/aboutyou',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/common/aboutyou',
      },
      defaultData: {
        modelBirthdate: '',
        isBirthdayDisabled: false,
        modelGender: '',
        isGenderDisabled: false,
        profileAboutMe: {
          texts: {},
        },
        profileCharacteristics: [],
        profileCity: '',
        profileCountry: '',
        profileGeohashCalculated: '',
        profileGeohashCustom: '',
        profileLanguages: [],
        textInputLanguages: [],
        profileOccupation: '',
        profileRelationshipState: '',
        profileSexuality: '',
        profileTags: [],
        profileZipCode: '',
        formValues: {
          modelGender: [],
          profileSexuality: [],
          profileLanguages: [],
          profileTags: [],
          profileCharacteristics: [],
          profileRelationshipState: [],
          profileCountry: [],
        },
      },
    },
    PROFILE_COMMON_APPEARANCE: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/common/appearance',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/common/appearance',
      },

      defaultData: {
        modelAppearance: '',
        modelBeard: '',
        modelBodyModification: [''],
        modelBreastSize: '',
        isBreastSizeDisabled: false,
        modelEyeColor: '',
        modelFigure: '',
        modelHairColor: '',
        isHairColorDisabled: false,
        modelHairLength: '',
        modelHairness: '',
        modelHeight: 0,
        modelPenisSize: '',
        modelPubicHair: '',
        modelSkinType: '',
        modelWeight: 0,
        formValues: [],
      },
    },
    PROFILE_VX_FETISH: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/vx/fetish',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/vx/fetish',
      },

      defaultData: {
        userId: 0,
        isFetishUnlockedBySupport: false,
        profileFetishState: '',
        fetishExperiences: [],
        fetishGaySpecials: [],
        fetishRole: '',
        fetishTaboos: {
          texts: {},
        },
        fetishText: {
          texts: {},
        },
        fetishToys: [],
        fetishTypes: [],
        formValues: [],
      },
    },
    PROFILE_LA: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/la',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/la',
      },

      defaultData: {
        userId: 0,
        laSettingsProfileActive: SessionStore.get().profile.isLAProfileActive,
        laSettingsAutomessagingActive: false,
        laSettingsLiveDatesActive: false,
        laSettingsActionDatesActive: false,
        laPreferences: {
          texts: {},
        },
        laPerfectDate: {
          texts: {},
        },
        laSexualExperiences: {
          texts: {},
        },
        laNickname: '',
        laNicknameStatus: '',
        laWelcomeText1: {
          texts: {},
        },
        laWelcomeText2: {
          texts: {},
        },
        laWelcomeText3: {
          texts: {},
        },
        laMailAttachmentUmaId: 0,
        laWelcomeText1Picture: {},
        laWelcomeText2Picture: {},
        laWelcomeText3Picture: {},
        formValues: [],
      },
    },
    PROFILE_FL: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/fl',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/fl',
      },

      defaultData: {
        userId: 0,
        flSettingsProfileActive: SessionStore.get().profile.isFLProfileActive,
        flSettingsAutomessagingActive: false,
        flNickname: '',
        flNicknameStatus: '',
        flAboutMeText: {
          texts: {},
        },
        flWelcomeText1: {
          texts: {},
        },
        flWelcomeText2: {
          texts: {},
        },
        flWelcomeText3: {
          texts: {},
        },
        flMailAttachmentUmaId: 0,
        flWelcomeText1Picture: {},
        flWelcomeText2Picture: {},
        flWelcomeText3Picture: {},
        formValues: [],
      },
    },
    PROFILE_INTERVIEW: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/vx/interview',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/vx/interview',
      },

      defaultData: {
        profileLanguages: [],
        interviewBook: {
          texts: {},
        },
        interviewCity: {
          texts: {},
        },
        interviewDate: {
          texts: {},
        },
        interviewEnterprise: {
          texts: {},
        },
        interviewEroticExperience: {
          texts: {},
        },
        interviewExtraordinarySex: {
          texts: {},
        },
        interviewFood: {
          texts: {},
        },
        interviewHobbies: {
          texts: {},
        },
        interviewHotExperience: {
          texts: {},
        },
        interviewIsland: {
          texts: {},
        },
        interviewMusic: {
          texts: {},
        },
        interviewPartner: {
          texts: {},
        },
        interviewParty: {
          texts: {},
        },
        interviewPersonalAdmiration: {
          texts: {},
        },
        interviewSexGo: {
          texts: {},
        },
        interviewSexNoGo: {
          texts: {},
        },
        interviewSexualCuriosity: {
          texts: {},
        },
        interviewSexualEsteem: {
          texts: {},
        },
        interviewSexualFantasies: {
          texts: {},
        },
        interviewSexualPosition: {
          texts: {},
        },
        interviewSport: {
          texts: {},
        },
        interviewTraits: {
          texts: {},
        },
      },
    },
    PROFILE_PARTNER: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/common/partner',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/common/partner',
      },

      defaultData: {
        partnerAppearance: '',
        partnerBirthdate: '',
        partnerBodyModification: [],
        partnerBreastSize: '',
        partnerEyeColor: '',
        partnerFigure: '',
        partnerGender: '',
        partnerHairColor: '',
        partnerHairLength: '',
        partnerHairness: '',
        partnerHeight: 0,
        partnerPenisSize: '',
        partnerPubicHair: '',
        partnerSkinType: '',
        partnerWeight: 0,
        profileCoupleState: '',
        formValues: [],
      },
    },
    PROFILE_VX_AUTOMAILS: {
      uri: {
        GET: '/v1/camtool/user/{userId}/onlineprofile/vx/automails',
        PUT: '/v1/camtool/user/{userId}/onlineprofile/vx/automails',
      },

      defaultData: {
        vxAutomailTextNewUser1: {
          texts: {},
        },
        vxAutomailTextNewUser2: {
          texts: {},
        },
        vxAutomailTextNewUser3: {
          texts: {},
        },
        vxAutomailTextNewUser4: {
          texts: {},
        },
        vxAutomailTextNewUser5: {
          texts: {},
        },
        vxAutomailTextExistingUser1: {
          texts: {},
        },
        vxAutomailTextExistingUser2: {
          texts: {},
        },
        vxAutomailTextExistingUser3: {
          texts: {},
        },
        vxAutomailTextExistingUser4: {
          texts: {},
        },
        vxAutomailTextExistingUser5: {
          texts: {},
        },
      },
    },
  },
};

class ProfileStoreClass extends AbstractRestApiStoreClass {}
class ProfileActionCreatorsClass extends AbstractRestApiActionCreatorsClass {}

const ProfileStore = new ProfileStoreClass(config);
const ProfileActionCreators = new ProfileActionCreatorsClass(config);

export {
  ProfileStore,
  ProfileActionCreators,
  PROFILE_LA,
  PROFILE_FL,
  PROFILE_VX_AUTOMAILS,
  PROFILE_VX_FETISH,
  PROFILE_VX_OVERVIEW,
  PROFILE_INTERVIEW,
  PROFILE_PARTNER,
  PROFILE_COMMON_APPEARANCE,
  PROFILE_COMMON_ABOUTYOU,
};
